import styled from 'styled-components';

import { Card as BaseCard } from '@npm/core/ui/components/molecules/Card';

export const IdToggleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  && button {
    min-width: unset;
    margin-top: 0;
    padding: 0;
  }
`;
export const Card = styled(BaseCard)`
  height: auto;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`;
