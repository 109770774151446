import React, { type ComponentProps } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { GetStarted as BaseGetStarted } from '@npm/core/ui/components/molecules/GetStarted';

export const FormationDocumenGetStartedContent = () => {
  return (
    <>
      <Text colorVariant="secondary">
        Upload a PDF document evidencing the legal formation of this entity.
      </Text>
      <Text colorVariant="secondary">
        Examples of formation documents are articles of incorporation,
        certificate of limited partnership and LLC operating agreement. The
        uploaded document should be executed, if applicable.
      </Text>
    </>
  );
};

export const FormationDocumentGetStarted = ({
  onGetStarted,
}: ComponentProps<typeof BaseGetStarted>) => {
  return (
    <BaseGetStarted
      onGetStarted={onGetStarted}
      title="Formation Document"
      content={<FormationDocumenGetStartedContent />}
      withBullets
    />
  );
};
