import Lottie from 'lottie-react';
import styled from 'styled-components';

import arrowAnimationData from './lottie-animated-arrow.json';

export const ScrollIndicator = styled(Lottie).attrs({
  animationData: arrowAnimationData,
  loop: true,
})<{ $bottomOffset: number; $hasReachedBottom: boolean }>`
  position: absolute;
  bottom: ${({ $bottomOffset, theme }) => $bottomOffset + theme.spacing.sm}px;
  right: calc(50% - 16px);
  z-index: ${({ theme }) => theme.zIndex.fixed};
  width: 32px;
  height: 32px;
  opacity: ${({ $hasReachedBottom }) => ($hasReachedBottom ? 0 : 1)};
  transition: opacity 1s ease-in-out;
  background-color: ${({ theme }) => theme.color.general.layout.two};
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  box-shadow: ${({ theme }) => theme.shadow.level2};
  cursor: ${({ $hasReachedBottom }) =>
    $hasReachedBottom ? 'auto' : 'pointer'};

  svg path {
    stroke: ${({ theme }) => theme.color.info.typography.primary};
  }
`;
