import {
  type CbLabelComponent,
  type IconNames,
  type LabelVariant,
} from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  // common
  partially_filled: 'partially_filled',
  filled: 'filled',

  // secondmarket only
  incomplete: 'incomplete',
  not_posted_to_marketplace: 'not_posted_to_marketplace',
  live_in_marketplace: 'live_in_marketplace',
  matched: 'matched',
  pending_settlement: 'pending_settlement',
  cancelled: 'cancelled',
  expired: 'expired',

  // event only
  not_filled: 'not_filled',
  pending: 'pending',
  submitted: 'submitted',
} as const;

type Keys = keyof typeof Items;
export type OrderItemStateCode = Keys;

export type TooltipMap = Partial<Record<Keys, string>>;

export const OrderItemLabelMap: Record<Keys, LabelVariant> = {
  partially_filled: 'info',
  filled: 'info',

  incomplete: 'error',
  not_posted_to_marketplace: 'warning',
  live_in_marketplace: 'info',
  matched: 'info',
  pending_settlement: 'warning',
  cancelled: 'general',
  expired: 'general',

  not_filled: 'general',
  pending: 'warning',
  submitted: 'success',
};

export const OrderItemIconMap: Partial<Record<Keys, IconNames>> = {
  partially_filled: 'check',
  filled: 'check',

  incomplete: 'alert-triangle',
  not_posted_to_marketplace: 'clock',
  live_in_marketplace: 'pulsing-dot',
  matched: 'arrows-exchange-horizontal',
  pending_settlement: 'clock',
  cancelled: 'x-close',
  expired: 'calendar',

  not_filled: 'x-close',
  pending: 'clock',
};

export const OrderItemTooltipMap: Partial<Record<Keys, React.ReactNode>> = {
  incomplete:
    'This order is incomplete because your order is missing a price per share. Once you confirm your order, we will review and post to the marketplace on your behalf.',
  not_posted_to_marketplace:
    'This order is currently under review by NPM and will be posted to the marketplace once approved. If any additional information is required, a representative will reach out to you.',
};

type Attributes = 'events' | 'secondmarket' | 'filter_visibility' | 'help';

export const CbOrderItemState = {
  code: Codebooks.ORDER_ITEM_STATE,
  items: Items,
  labels: OrderItemLabelMap,
  getAttrByName: (item: CodebookItem, name: Attributes) =>
    item.attributes?.find(q => q.name === name),
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem | undefined,
    withIcon?: boolean
  ) => {
    const variant = OrderItemLabelMap[(codebookItem?.code as Keys) ?? 'none'];
    const iconName = withIcon && OrderItemIconMap[codebookItem?.code as Keys];
    return Component({
      variant,
      label: codebookItem?.name,
      ...(iconName
        ? { iconProps: { name: iconName } }
        : { style: { display: 'inline', whiteSpace: 'normal' } }),
    });
  },
  getTooltipMap: (codebooks: CodebookItem[] | undefined): TooltipMap => {
    return (codebooks ?? []).reduce<TooltipMap>((acc, item) => {
      const helpAttribute = CbOrderItemState.getAttrByName(item, 'help');
      if (helpAttribute) {
        acc[item.code] = helpAttribute.value;
      }
      return acc;
    }, {});
  },
};
