/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CompanyAliasesApi,
  InternalCompanyAliasIndexErrorCodes,
  InternalCompanyAliasIndexErrorTypes,
  InternalCompanyAliasCreateErrorCodes,
  InternalShowCompanyAliasErrorCodes,
  InternalShowCompanyAliasErrorTypes,
  InternalUpdateCompanyAliasErrorCodes,
  InternalDeleteCompanyAliasErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CompanyAliasesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalCompanyAliasIndexMethod = CompanyAliasesApi['internalCompanyAliasIndex'];
export type InternalCompanyAliasIndexResponseType = MethodResult<InternalCompanyAliasIndexMethod>;

export const useInternalCompanyAliasIndex = (
  variables: Parameters<InternalCompanyAliasIndexMethod>[0],
  config?: UseQueryConfig<
    InternalCompanyAliasIndexMethod,
    InternalCompanyAliasIndexErrorTypes
  >
) => {
  return useQuery<
    InternalCompanyAliasIndexMethod,
    InternalCompanyAliasIndexErrorTypes
  >(
    (...args) => api.internalCompanyAliasIndex(...args),
    InternalCompanyAliasIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalCompanyAliasIndex'],
        ...apiQueryConfig.InternalCompanyAliasIndex,
        ...config?.queryConfig
      },
    },
    'InternalCompanyAliasIndex',
  );
};

export const useInternalCompanyAliasIndexInfinite = (
  variables: Parameters<InternalCompanyAliasIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalCompanyAliasIndexMethod,
    InternalCompanyAliasIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalCompanyAliasIndexMethod,
    InternalCompanyAliasIndexErrorTypes
  >(
    (...args) => api.internalCompanyAliasIndex(...args),
    InternalCompanyAliasIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalCompanyAliasIndex', 'InternalCompanyAliasIndexInfinite'],
        ...apiQueryConfig.InternalCompanyAliasIndex,
        ...config?.queryConfig,
      },
    },
    'InternalCompanyAliasIndex',
  );
};

export const useInternalCompanyAliasIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalCompanyAliasIndexMethod>[0],
  config?: UseQueryConfig<
    InternalCompanyAliasIndexMethod,
    InternalCompanyAliasIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalCompanyAliasIndexMethod,
    InternalCompanyAliasIndexErrorTypes
  >(
    (...args) => api.internalCompanyAliasIndex(...args),
    InternalCompanyAliasIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalCompanyAliasIndex'],
        ...apiQueryConfig.InternalCompanyAliasIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalCompanyAliasIndex',
  );
};


export type InternalCompanyAliasCreateMethod = CompanyAliasesApi['internalCompanyAliasCreate'];

export const useInternalCompanyAliasCreate = () => {
  return useMutation<InternalCompanyAliasCreateMethod>(
    (...args) => api.internalCompanyAliasCreate(...args),
    InternalCompanyAliasCreateErrorCodes,
    'InternalCompanyAliasCreate',
    '/api/internal/company_aliases',
    'post'
  );
};

export type InternalShowCompanyAliasMethod = CompanyAliasesApi['internalShowCompanyAlias'];
export type InternalShowCompanyAliasResponseType = MethodResult<InternalShowCompanyAliasMethod>;

export const useInternalShowCompanyAlias = (
  variables: Parameters<InternalShowCompanyAliasMethod>[0],
  config?: UseQueryConfig<
    InternalShowCompanyAliasMethod,
    InternalShowCompanyAliasErrorTypes
  >
) => {
  return useQuery<
    InternalShowCompanyAliasMethod,
    InternalShowCompanyAliasErrorTypes
  >(
    (...args) => api.internalShowCompanyAlias(...args),
    InternalShowCompanyAliasErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowCompanyAlias'],
        ...apiQueryConfig.InternalShowCompanyAlias,
        ...config?.queryConfig
      },
    },
    'InternalShowCompanyAlias',
  );
};

export const useInternalShowCompanyAliasInfinite = (
  variables: Parameters<InternalShowCompanyAliasMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalShowCompanyAliasMethod,
    InternalShowCompanyAliasErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalShowCompanyAliasMethod,
    InternalShowCompanyAliasErrorTypes
  >(
    (...args) => api.internalShowCompanyAlias(...args),
    InternalShowCompanyAliasErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowCompanyAlias', 'InternalShowCompanyAliasInfinite'],
        ...apiQueryConfig.InternalShowCompanyAlias,
        ...config?.queryConfig,
      },
    },
    'InternalShowCompanyAlias',
  );
};

export const useInternalShowCompanyAliasLazy = (baseOptions?: {
  variables?: Parameters<InternalShowCompanyAliasMethod>[0],
  config?: UseQueryConfig<
    InternalShowCompanyAliasMethod,
    InternalShowCompanyAliasErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalShowCompanyAliasMethod,
    InternalShowCompanyAliasErrorTypes
  >(
    (...args) => api.internalShowCompanyAlias(...args),
    InternalShowCompanyAliasErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalShowCompanyAlias'],
        ...apiQueryConfig.InternalShowCompanyAlias,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalShowCompanyAlias',
  );
};


export type InternalUpdateCompanyAliasMethod = CompanyAliasesApi['internalUpdateCompanyAlias'];

export const useInternalUpdateCompanyAlias = () => {
  return useMutation<InternalUpdateCompanyAliasMethod>(
    (...args) => api.internalUpdateCompanyAlias(...args),
    InternalUpdateCompanyAliasErrorCodes,
    'InternalUpdateCompanyAlias',
    '/api/internal/company_aliases/{id}',
    'put'
  );
};

export type InternalDeleteCompanyAliasMethod = CompanyAliasesApi['internalDeleteCompanyAlias'];

export const useInternalDeleteCompanyAlias = () => {
  return useMutation<InternalDeleteCompanyAliasMethod>(
    (...args) => api.internalDeleteCompanyAlias(...args),
    InternalDeleteCompanyAliasErrorCodes,
    'InternalDeleteCompanyAlias',
    '/api/internal/company_aliases/{id}',
    'delete'
  );
};
