import React, { type ComponentProps, useMemo } from 'react';

import {
  Select,
  SELECT_PAGE_SIZE,
  useSelectAsync,
} from '@npm/core/ui/components/atoms/Select';
import { onPopupScroll } from '@npm/core/ui/components/atoms/Select/Select.utils';
import {
  CbSubjectType,
  useBrokerageFirmRoutingBrokerIndexInfinite,
} from '@npm/data-access';

import { useCurrentRole } from '../../../auth/user/role/hooks/useCurrentRole';

type Props = ComponentProps<typeof Select>;

export const RoutingBrokerSearch = (props: Props) => {
  const role = useCurrentRole();

  const brokerageFirmId =
    (role?.subject_type?.code === CbSubjectType.items.BROKERAGE_FIRM ||
      // there is bug on BE returning wrong code
      role?.subject_type?.code === 'BrokerageFirm') &&
    role?.subject?.id;

  const [{ searchTerm }, selectAsyncProps] = useSelectAsync();

  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useBrokerageFirmRoutingBrokerIndexInfinite(
    {
      brokerageFirmId,
      ...(searchTerm ? { search: searchTerm } : {}),
      size: SELECT_PAGE_SIZE,
    },
    {
      queryConfig: { enabled: !!brokerageFirmId },
      onError: Select.onError,
    }
  );

  const options = useMemo(() => {
    if (!data) return null;

    const mergedRoutingBrokers = data.pages.reduce((mergedArray, page) => {
      return mergedArray.concat(page.routing_brokers);
    }, []);

    return mergedRoutingBrokers.map(({ name, id }) => ({
      label: name,
      value: id,
    }));
  }, [data]);

  return (
    <Select
      onPopupScroll={e =>
        onPopupScroll(e, hasNextPage && !isFetchingNextPage && fetchNextPage)
      }
      infiniteLoading={isFetchingNextPage}
      variant="search"
      showSearch
      loading={isLoading}
      error={error}
      placeholder={
        options ? `All (${data.pages[0].pagination.total_records})` : 'All'
      }
      options={options}
      value={options?.length ? props.value : null}
      {...props}
      {...selectAsyncProps}
    />
  );
};
