import { sanitize } from 'dompurify';

// Set the default policy to make sure that third-party libraries that do not support
// Trusted Types still work. See https://web.dev/trusted-types/#use-a-default-policy
// Browsers that do not support Trusted Types will not enforce the default policy.
if (window.trustedTypes && window.trustedTypes.createPolicy) {
  window.trustedTypes.createPolicy('default', {
    createHTML: string => sanitize(string),

    // Skip for now
    createScriptURL: url => url, // We use CSP script-src instead
    createScript: string => string,
  });
}

document.addEventListener(
  'securitypolicyviolation',
  console.error.bind(console)
);
