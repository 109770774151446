import React from 'react';
import { type FormInstance } from 'antd';
import { useTheme } from 'styled-components';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { CheckList } from '@npm/core/ui/components/molecules/CheckList';
import { CbAccountType } from '@npm/data-access';

import {
  type OptInEntityColorVariant,
  type OptInIcon,
} from '../../OptIn.enums';
import { type OptInFormValues } from '../../OptInEntitySelection/OptInEntitySelection.types';

import {
  individualBenefits,
  institutionBenefits,
} from './EntityBenefits.config';
import * as S from './EntityBenefits.style';

interface Props {
  form: FormInstance<OptInFormValues>;
  icon: OptInIcon;
  colorVariant: OptInEntityColorVariant;
  title: string;
}

const entityType = CbAccountType.items.OrganizationAccount;
const indivitualType = CbAccountType.items.PersonAccount;

export const EntityBenefits = ({ icon, title, colorVariant, form }: Props) => {
  const theme = useTheme();
  const accountType = form.getFieldValue('accountType') as string;
  const getColor = (color: OptInEntityColorVariant) =>
    theme.color[color]?.typography.primary ??
    theme.color.general.typography.primary;

  let benefits = [];
  if (accountType === indivitualType) benefits = individualBenefits;
  if (accountType === entityType) benefits = institutionBenefits;

  return (
    <S.Card $backgroundVariant={colorVariant}>
      <Flex align="center" gap="xs">
        <Icon name={icon} color={getColor(colorVariant)} size="md" />
        <Heading variant="h2" as="span" color={colorVariant}>
          {title}
        </Heading>
      </Flex>
      <Margin bottom="md" />
      <CheckList listItems={benefits} />
    </S.Card>
  );
};
