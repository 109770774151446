import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Zendesk, { ZendeskAPI } from 'react-zendesk';

import { ZENDESK_PUBLIC_KEY } from './zendesk.env';
import { useZendesk } from './zendesk.hooks';
import { createZendeskStore } from './zendesk.store';
import { ZendeskCustomFieldName } from './zendesk.types';
import { createZendeskMessengerHandler, setCustomField } from './zendesk.utils';

import * as S from './zendesk.styles';

export const ZendeskWidget = () => {
  const {
    isInitialized,
    setInitialized,
    isOpen,
    setOpen,
    setUnreadMessageCount,
  } = createZendeskStore();

  const { toggle, user, account, workstation, pageUrl } = useZendesk();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (isInitialized && !isLoggedIn && user) {
      ZendeskAPI('messenger', 'loginUser', (cb: (jwt: string) => void) => {
        cb(user.zendesk_jwt);
        setIsLoggedIn(true);
      });
      setCustomField(ZendeskCustomFieldName.Email, user.email);
    }
  }, [isInitialized, isLoggedIn]);

  useEffect(() => {
    // set custom fields
    if (isInitialized && workstation) {
      setCustomField(
        ZendeskCustomFieldName.Workstation,
        workstation?.type?.code
      );
      setCustomField(ZendeskCustomFieldName.PageUrl, pageUrl);
      setCustomField(ZendeskCustomFieldName.AccountName, account.name);
      setCustomField(
        ZendeskCustomFieldName.AccountExternalId,
        account.external_id
      );
    }
  }, [isInitialized, pageUrl, workstation, account]);

  useEffect(() => {
    if (isInitialized) {
      createZendeskMessengerHandler('open', () => {
        setOpen(true);
        setUnreadMessageCount(0);
      });
    }
  }, [isInitialized]);

  useEffect(() => {
    if (isInitialized) {
      createZendeskMessengerHandler('close', () => {
        setOpen(false);
      });
    }
  }, [isInitialized]);

  useEffect(() => {
    if (isInitialized) {
      createZendeskMessengerHandler('unreadMessages', value => {
        setUnreadMessageCount(value);
      });
    }
  }, [isInitialized]);

  if (!ZENDESK_PUBLIC_KEY) return null;

  return (
    <S.WidgetMask $show={isOpen} onClick={toggle}>
      <Zendesk
        defer
        zendeskKey={ZENDESK_PUBLIC_KEY}
        onLoaded={() => {
          ZendeskAPI('messenger', 'close');
          setTimeout(() => {
            setInitialized();
          }, 2000);
        }}
      />
    </S.WidgetMask>
  );
};

export const ZendeskWidgetSafe = () => (
  <ErrorBoundary fallbackRender={() => <></>}>
    <ZendeskWidget />
  </ErrorBoundary>
);
