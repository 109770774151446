/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActionStatusResult } from '../model';
// @ts-ignore
import { AdminIssuerGroupedDuplicateSecurityOcfId } from '../model';
// @ts-ignore
import { AdminSectorSummarySecuritiesSectors } from '../model';
// @ts-ignore
import { InvestorAccountSecurities } from '../model';
// @ts-ignore
import { NewlyAddedSecurities } from '../model';
// @ts-ignore
import { PortfolioStats } from '../model';
// @ts-ignore
import { Security } from '../model';
// @ts-ignore
import { SecurityCreateRequest } from '../model';
// @ts-ignore
import { SecurityIndex } from '../model';
// @ts-ignore
import { SecuritySimple } from '../model';
// @ts-ignore
import { SecurityUpdateRequest } from '../model';
/**
 * SecuritiesApi - axios parameter creator
 * @export
 */
export const SecuritiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all securities in an account
         * @param {string} accountId accounts &#x60;npm_guid&#x60;
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSecurityIndex: async (accountId: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountSecurityIndex', 'accountId', accountId)
            const localVarPath = changeUrl(`/api/investor/accounts/{account_id}/securities`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gets stats on your portfolio
         * @param {Array<string>} accountIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPortfolioStats: async (accountIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountIds' is not null or undefined
            assertParamExists('adminPortfolioStats', 'accountIds', accountIds)
            const localVarPath = changeUrl(`/api/admin/portfolio_stats`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountIds) {
                localVarQueryParameter['account_ids'] = accountIds.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists securities where the certificate ID is non-unique
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDuplicateSecurityIds: async (xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/admin/non_unique_security_ids`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lists all securities created recently grouped by date
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {Array<string>} [issuerIds] 
         * @param {Array<string>} [securityTypes] 
         * @param {Array<string>} [accountIds] 
         * @param {Array<string>} [sectors] 
         * @param {boolean} [hasMarks] 
         * @param {Array<string>} [stockClassTypes] 
         * @param {'oldest' | 'newest'} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newSecuritiesByDate: async (xWorkstation?: string, page?: number, size?: number, endDate?: string, startDate?: string, issuerIds?: Array<string>, securityTypes?: Array<string>, accountIds?: Array<string>, sectors?: Array<string>, hasMarks?: boolean, stockClassTypes?: Array<string>, sortBy?: 'oldest' | 'newest', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/admin/new_securities`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (issuerIds) {
                localVarQueryParameter['issuer_ids'] = issuerIds.join(COLLECTION_FORMATS.csv);
            }

            if (securityTypes) {
                localVarQueryParameter['security_types'] = securityTypes.join(COLLECTION_FORMATS.csv);
            }

            if (accountIds) {
                localVarQueryParameter['account_ids'] = accountIds.join(COLLECTION_FORMATS.csv);
            }

            if (sectors) {
                localVarQueryParameter['sectors'] = sectors.join(COLLECTION_FORMATS.csv);
            }

            if (hasMarks !== undefined) {
                localVarQueryParameter['has_marks'] = hasMarks;
            }

            if (stockClassTypes) {
                localVarQueryParameter['stock_class_types'] = stockClassTypes.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lists summary of recently created securities grouped by sector
         * @param {string} [xWorkstation] 
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newSecuritiesBySector: async (xWorkstation?: string, endDate?: string, startDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/admin/new_securities/sector_summary`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gets stats on your portfolio
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioStats: async (xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/investor/portfolio_stats`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List securities
         * @param {string} issuerNpmGuid npm_guid
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {Array<string>} [accountIds] 
         * @param {Array<string>} [securityTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityAdminList: async (issuerNpmGuid: string, xWorkstation?: string, page?: number, size?: number, accountIds?: Array<string>, securityTypes?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerNpmGuid' is not null or undefined
            assertParamExists('securityAdminList', 'issuerNpmGuid', issuerNpmGuid)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_npm_guid}/securities`)
                .replace(`{${"issuer_npm_guid"}}`, encodeURIComponent(String(issuerNpmGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (accountIds) {
                localVarQueryParameter['account_ids'] = accountIds.join(COLLECTION_FORMATS.csv);
            }

            if (securityTypes) {
                localVarQueryParameter['security_types'] = securityTypes.join(COLLECTION_FORMATS.csv);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets security by id
         * @param {string} id npm_guid
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityAdminShow: async (id: string, xWorkstation?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('securityAdminShow', 'id', id)
            const localVarPath = changeUrl(`/api/admin/securities/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates a security under the given issuer
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {SecurityCreateRequest} securityCreateRequest 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityCreate: async (accountId: string, companyId: string, securityCreateRequest: SecurityCreateRequest, xWorkstation?: string, xNPMUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('securityCreate', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('securityCreate', 'companyId', companyId)
            // verify required parameter 'securityCreateRequest' is not null or undefined
            assertParamExists('securityCreate', 'securityCreateRequest', securityCreateRequest)
            const localVarPath = changeUrl(`/api/investor/accounts/{account_id}/companies/{company_id}/securities`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(securityCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary destroy the security under an issuer for an account
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} id ocf_id
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityDestroy: async (accountId: string, companyId: string, id: string, xWorkstation?: string, xNPMUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('securityDestroy', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('securityDestroy', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('securityDestroy', 'id', id)
            const localVarPath = changeUrl(`/api/investor/accounts/{account_id}/companies/{company_id}/securities/{id}`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lists all securities under an issuer for an account
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityIndex: async (accountId: string, companyId: string, xWorkstation?: string, xNPMUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('securityIndex', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('securityIndex', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/investor/accounts/{account_id}/companies/{company_id}/securities`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all securities under all investors accounts
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {boolean} [onlyUnmarked] filter on securities which do not have a mark for the current quarter
         * @param {string} [companyId] issuers &#x60;npm_guid&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityIndexAll: async (page?: number, size?: number, onlyUnmarked?: boolean, companyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/investor/securities`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (onlyUnmarked !== undefined) {
                localVarQueryParameter['only_unmarked'] = onlyUnmarked;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['company_id'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get security
         * @param {string} id the &#x60;npm_guid&#x60; on the security
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityInvestorShow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('securityInvestorShow', 'id', id)
            const localVarPath = changeUrl(`/api/investor/securities/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the security under an issuer for an account
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} id ocf_id
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityShow: async (accountId: string, companyId: string, id: string, xWorkstation?: string, xNPMUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('securityShow', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('securityShow', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('securityShow', 'id', id)
            const localVarPath = changeUrl(`/api/investor/accounts/{account_id}/companies/{company_id}/securities/{id}`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a security under the given issuer
         * @param {string} accountId the accounts &#x60;npm_guid&#x60; that this security is held in
         * @param {string} companyId the issuers &#x60;npm_guid&#x60; that this security is for
         * @param {string} id ocf_id
         * @param {SecurityUpdateRequest} securityUpdateRequest 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityUpdate: async (accountId: string, companyId: string, id: string, securityUpdateRequest: SecurityUpdateRequest, xWorkstation?: string, xNPMUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('securityUpdate', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('securityUpdate', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('securityUpdate', 'id', id)
            // verify required parameter 'securityUpdateRequest' is not null or undefined
            assertParamExists('securityUpdate', 'securityUpdateRequest', securityUpdateRequest)
            const localVarPath = changeUrl(`/api/investor/accounts/{account_id}/companies/{company_id}/securities/{id}`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(securityUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecuritiesApi - functional programming interface
 * @export
 */
export const SecuritiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecuritiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all securities in an account
         * @param {string} accountId accounts &#x60;npm_guid&#x60;
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountSecurityIndex(accountId: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestorAccountSecurities>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountSecurityIndex(accountId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gets stats on your portfolio
         * @param {Array<string>} accountIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPortfolioStats(accountIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPortfolioStats(accountIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lists securities where the certificate ID is non-unique
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findDuplicateSecurityIds(xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminIssuerGroupedDuplicateSecurityOcfId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findDuplicateSecurityIds(xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lists all securities created recently grouped by date
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {Array<string>} [issuerIds] 
         * @param {Array<string>} [securityTypes] 
         * @param {Array<string>} [accountIds] 
         * @param {Array<string>} [sectors] 
         * @param {boolean} [hasMarks] 
         * @param {Array<string>} [stockClassTypes] 
         * @param {'oldest' | 'newest'} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newSecuritiesByDate(xWorkstation?: string, page?: number, size?: number, endDate?: string, startDate?: string, issuerIds?: Array<string>, securityTypes?: Array<string>, accountIds?: Array<string>, sectors?: Array<string>, hasMarks?: boolean, stockClassTypes?: Array<string>, sortBy?: 'oldest' | 'newest', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewlyAddedSecurities>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newSecuritiesByDate(xWorkstation, page, size, endDate, startDate, issuerIds, securityTypes, accountIds, sectors, hasMarks, stockClassTypes, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lists summary of recently created securities grouped by sector
         * @param {string} [xWorkstation] 
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newSecuritiesBySector(xWorkstation?: string, endDate?: string, startDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSectorSummarySecuritiesSectors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newSecuritiesBySector(xWorkstation, endDate, startDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gets stats on your portfolio
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfolioStats(xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioStats(xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List securities
         * @param {string} issuerNpmGuid npm_guid
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {Array<string>} [accountIds] 
         * @param {Array<string>} [securityTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async securityAdminList(issuerNpmGuid: string, xWorkstation?: string, page?: number, size?: number, accountIds?: Array<string>, securityTypes?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityAdminList(issuerNpmGuid, xWorkstation, page, size, accountIds, securityTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets security by id
         * @param {string} id npm_guid
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async securityAdminShow(id: string, xWorkstation?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Security>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityAdminShow(id, xWorkstation, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates a security under the given issuer
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {SecurityCreateRequest} securityCreateRequest 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async securityCreate(accountId: string, companyId: string, securityCreateRequest: SecurityCreateRequest, xWorkstation?: string, xNPMUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecuritySimple>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityCreate(accountId, companyId, securityCreateRequest, xWorkstation, xNPMUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary destroy the security under an issuer for an account
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} id ocf_id
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async securityDestroy(accountId: string, companyId: string, id: string, xWorkstation?: string, xNPMUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityDestroy(accountId, companyId, id, xWorkstation, xNPMUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lists all securities under an issuer for an account
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async securityIndex(accountId: string, companyId: string, xWorkstation?: string, xNPMUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityIndex(accountId, companyId, xWorkstation, xNPMUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all securities under all investors accounts
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {boolean} [onlyUnmarked] filter on securities which do not have a mark for the current quarter
         * @param {string} [companyId] issuers &#x60;npm_guid&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async securityIndexAll(page?: number, size?: number, onlyUnmarked?: boolean, companyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityIndexAll(page, size, onlyUnmarked, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get security
         * @param {string} id the &#x60;npm_guid&#x60; on the security
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async securityInvestorShow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Security>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityInvestorShow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get the security under an issuer for an account
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} id ocf_id
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async securityShow(accountId: string, companyId: string, id: string, xWorkstation?: string, xNPMUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Security>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityShow(accountId, companyId, id, xWorkstation, xNPMUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a security under the given issuer
         * @param {string} accountId the accounts &#x60;npm_guid&#x60; that this security is held in
         * @param {string} companyId the issuers &#x60;npm_guid&#x60; that this security is for
         * @param {string} id ocf_id
         * @param {SecurityUpdateRequest} securityUpdateRequest 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async securityUpdate(accountId: string, companyId: string, id: string, securityUpdateRequest: SecurityUpdateRequest, xWorkstation?: string, xNPMUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Security>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityUpdate(accountId, companyId, id, securityUpdateRequest, xWorkstation, xNPMUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SecuritiesApi - factory interface
 * @export
 */
export const SecuritiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecuritiesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all securities in an account
         * @param {string} accountId accounts &#x60;npm_guid&#x60;
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSecurityIndex(accountId: string, page?: number, size?: number, options?: any): AxiosPromise<InvestorAccountSecurities> {
            return localVarFp.accountSecurityIndex(accountId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gets stats on your portfolio
         * @param {Array<string>} accountIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPortfolioStats(accountIds: Array<string>, options?: any): AxiosPromise<PortfolioStats> {
            return localVarFp.adminPortfolioStats(accountIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists securities where the certificate ID is non-unique
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDuplicateSecurityIds(xWorkstation?: string, options?: any): AxiosPromise<AdminIssuerGroupedDuplicateSecurityOcfId> {
            return localVarFp.findDuplicateSecurityIds(xWorkstation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lists all securities created recently grouped by date
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {Array<string>} [issuerIds] 
         * @param {Array<string>} [securityTypes] 
         * @param {Array<string>} [accountIds] 
         * @param {Array<string>} [sectors] 
         * @param {boolean} [hasMarks] 
         * @param {Array<string>} [stockClassTypes] 
         * @param {'oldest' | 'newest'} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newSecuritiesByDate(xWorkstation?: string, page?: number, size?: number, endDate?: string, startDate?: string, issuerIds?: Array<string>, securityTypes?: Array<string>, accountIds?: Array<string>, sectors?: Array<string>, hasMarks?: boolean, stockClassTypes?: Array<string>, sortBy?: 'oldest' | 'newest', options?: any): AxiosPromise<NewlyAddedSecurities> {
            return localVarFp.newSecuritiesByDate(xWorkstation, page, size, endDate, startDate, issuerIds, securityTypes, accountIds, sectors, hasMarks, stockClassTypes, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lists summary of recently created securities grouped by sector
         * @param {string} [xWorkstation] 
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newSecuritiesBySector(xWorkstation?: string, endDate?: string, startDate?: string, options?: any): AxiosPromise<AdminSectorSummarySecuritiesSectors> {
            return localVarFp.newSecuritiesBySector(xWorkstation, endDate, startDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gets stats on your portfolio
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioStats(xWorkstation?: string, options?: any): AxiosPromise<PortfolioStats> {
            return localVarFp.portfolioStats(xWorkstation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List securities
         * @param {string} issuerNpmGuid npm_guid
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {Array<string>} [accountIds] 
         * @param {Array<string>} [securityTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityAdminList(issuerNpmGuid: string, xWorkstation?: string, page?: number, size?: number, accountIds?: Array<string>, securityTypes?: Array<string>, options?: any): AxiosPromise<SecurityIndex> {
            return localVarFp.securityAdminList(issuerNpmGuid, xWorkstation, page, size, accountIds, securityTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets security by id
         * @param {string} id npm_guid
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityAdminShow(id: string, xWorkstation?: string, page?: number, size?: number, options?: any): AxiosPromise<Security> {
            return localVarFp.securityAdminShow(id, xWorkstation, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates a security under the given issuer
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {SecurityCreateRequest} securityCreateRequest 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityCreate(accountId: string, companyId: string, securityCreateRequest: SecurityCreateRequest, xWorkstation?: string, xNPMUser?: string, options?: any): AxiosPromise<SecuritySimple> {
            return localVarFp.securityCreate(accountId, companyId, securityCreateRequest, xWorkstation, xNPMUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary destroy the security under an issuer for an account
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} id ocf_id
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityDestroy(accountId: string, companyId: string, id: string, xWorkstation?: string, xNPMUser?: string, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.securityDestroy(accountId, companyId, id, xWorkstation, xNPMUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lists all securities under an issuer for an account
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityIndex(accountId: string, companyId: string, xWorkstation?: string, xNPMUser?: string, options?: any): AxiosPromise<SecurityIndex> {
            return localVarFp.securityIndex(accountId, companyId, xWorkstation, xNPMUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all securities under all investors accounts
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {boolean} [onlyUnmarked] filter on securities which do not have a mark for the current quarter
         * @param {string} [companyId] issuers &#x60;npm_guid&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityIndexAll(page?: number, size?: number, onlyUnmarked?: boolean, companyId?: string, options?: any): AxiosPromise<SecurityIndex> {
            return localVarFp.securityIndexAll(page, size, onlyUnmarked, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get security
         * @param {string} id the &#x60;npm_guid&#x60; on the security
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityInvestorShow(id: string, options?: any): AxiosPromise<Security> {
            return localVarFp.securityInvestorShow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the security under an issuer for an account
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} id ocf_id
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityShow(accountId: string, companyId: string, id: string, xWorkstation?: string, xNPMUser?: string, options?: any): AxiosPromise<Security> {
            return localVarFp.securityShow(accountId, companyId, id, xWorkstation, xNPMUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a security under the given issuer
         * @param {string} accountId the accounts &#x60;npm_guid&#x60; that this security is held in
         * @param {string} companyId the issuers &#x60;npm_guid&#x60; that this security is for
         * @param {string} id ocf_id
         * @param {SecurityUpdateRequest} securityUpdateRequest 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityUpdate(accountId: string, companyId: string, id: string, securityUpdateRequest: SecurityUpdateRequest, xWorkstation?: string, xNPMUser?: string, options?: any): AxiosPromise<Security> {
            return localVarFp.securityUpdate(accountId, companyId, id, securityUpdateRequest, xWorkstation, xNPMUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for accountSecurityIndex operation in SecuritiesApi.
 * @export
 * @interface SecuritiesApiAccountSecurityIndexRequest
 */
export interface SecuritiesApiAccountSecurityIndexRequest {
    /**
     * accounts &#x60;npm_guid&#x60;
     * @type {string}
     * @memberof SecuritiesApiAccountSecurityIndex
     */
    readonly accountId: string

    /**
     * 
     * @type {number}
     * @memberof SecuritiesApiAccountSecurityIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SecuritiesApiAccountSecurityIndex
     */
    readonly size?: number
}

/**
 * Request parameters for adminPortfolioStats operation in SecuritiesApi.
 * @export
 * @interface SecuritiesApiAdminPortfolioStatsRequest
 */
export interface SecuritiesApiAdminPortfolioStatsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof SecuritiesApiAdminPortfolioStats
     */
    readonly accountIds: Array<string>
}

/**
 * Request parameters for findDuplicateSecurityIds operation in SecuritiesApi.
 * @export
 * @interface SecuritiesApiFindDuplicateSecurityIdsRequest
 */
export interface SecuritiesApiFindDuplicateSecurityIdsRequest {
    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiFindDuplicateSecurityIds
     */
    readonly xWorkstation?: string
}

/**
 * Request parameters for newSecuritiesByDate operation in SecuritiesApi.
 * @export
 * @interface SecuritiesApiNewSecuritiesByDateRequest
 */
export interface SecuritiesApiNewSecuritiesByDateRequest {
    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiNewSecuritiesByDate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {number}
     * @memberof SecuritiesApiNewSecuritiesByDate
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SecuritiesApiNewSecuritiesByDate
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiNewSecuritiesByDate
     */
    readonly endDate?: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiNewSecuritiesByDate
     */
    readonly startDate?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof SecuritiesApiNewSecuritiesByDate
     */
    readonly issuerIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof SecuritiesApiNewSecuritiesByDate
     */
    readonly securityTypes?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof SecuritiesApiNewSecuritiesByDate
     */
    readonly accountIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof SecuritiesApiNewSecuritiesByDate
     */
    readonly sectors?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof SecuritiesApiNewSecuritiesByDate
     */
    readonly hasMarks?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof SecuritiesApiNewSecuritiesByDate
     */
    readonly stockClassTypes?: Array<string>

    /**
     * 
     * @type {'oldest' | 'newest'}
     * @memberof SecuritiesApiNewSecuritiesByDate
     */
    readonly sortBy?: 'oldest' | 'newest'
}

/**
 * Request parameters for newSecuritiesBySector operation in SecuritiesApi.
 * @export
 * @interface SecuritiesApiNewSecuritiesBySectorRequest
 */
export interface SecuritiesApiNewSecuritiesBySectorRequest {
    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiNewSecuritiesBySector
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiNewSecuritiesBySector
     */
    readonly endDate?: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiNewSecuritiesBySector
     */
    readonly startDate?: string
}

/**
 * Request parameters for portfolioStats operation in SecuritiesApi.
 * @export
 * @interface SecuritiesApiPortfolioStatsRequest
 */
export interface SecuritiesApiPortfolioStatsRequest {
    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiPortfolioStats
     */
    readonly xWorkstation?: string
}

/**
 * Request parameters for securityAdminList operation in SecuritiesApi.
 * @export
 * @interface SecuritiesApiSecurityAdminListRequest
 */
export interface SecuritiesApiSecurityAdminListRequest {
    /**
     * npm_guid
     * @type {string}
     * @memberof SecuritiesApiSecurityAdminList
     */
    readonly issuerNpmGuid: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityAdminList
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {number}
     * @memberof SecuritiesApiSecurityAdminList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SecuritiesApiSecurityAdminList
     */
    readonly size?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof SecuritiesApiSecurityAdminList
     */
    readonly accountIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof SecuritiesApiSecurityAdminList
     */
    readonly securityTypes?: Array<string>
}

/**
 * Request parameters for securityAdminShow operation in SecuritiesApi.
 * @export
 * @interface SecuritiesApiSecurityAdminShowRequest
 */
export interface SecuritiesApiSecurityAdminShowRequest {
    /**
     * npm_guid
     * @type {string}
     * @memberof SecuritiesApiSecurityAdminShow
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityAdminShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {number}
     * @memberof SecuritiesApiSecurityAdminShow
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SecuritiesApiSecurityAdminShow
     */
    readonly size?: number
}

/**
 * Request parameters for securityCreate operation in SecuritiesApi.
 * @export
 * @interface SecuritiesApiSecurityCreateRequest
 */
export interface SecuritiesApiSecurityCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityCreate
     */
    readonly accountId: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityCreate
     */
    readonly companyId: string

    /**
     * 
     * @type {SecurityCreateRequest}
     * @memberof SecuritiesApiSecurityCreate
     */
    readonly securityCreateRequest: SecurityCreateRequest

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityCreate
     */
    readonly xNPMUser?: string
}

/**
 * Request parameters for securityDestroy operation in SecuritiesApi.
 * @export
 * @interface SecuritiesApiSecurityDestroyRequest
 */
export interface SecuritiesApiSecurityDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityDestroy
     */
    readonly accountId: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityDestroy
     */
    readonly companyId: string

    /**
     * ocf_id
     * @type {string}
     * @memberof SecuritiesApiSecurityDestroy
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityDestroy
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityDestroy
     */
    readonly xNPMUser?: string
}

/**
 * Request parameters for securityIndex operation in SecuritiesApi.
 * @export
 * @interface SecuritiesApiSecurityIndexRequest
 */
export interface SecuritiesApiSecurityIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityIndex
     */
    readonly accountId: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityIndex
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityIndex
     */
    readonly xNPMUser?: string
}

/**
 * Request parameters for securityIndexAll operation in SecuritiesApi.
 * @export
 * @interface SecuritiesApiSecurityIndexAllRequest
 */
export interface SecuritiesApiSecurityIndexAllRequest {
    /**
     * 
     * @type {number}
     * @memberof SecuritiesApiSecurityIndexAll
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SecuritiesApiSecurityIndexAll
     */
    readonly size?: number

    /**
     * filter on securities which do not have a mark for the current quarter
     * @type {boolean}
     * @memberof SecuritiesApiSecurityIndexAll
     */
    readonly onlyUnmarked?: boolean

    /**
     * issuers &#x60;npm_guid&#x60;
     * @type {string}
     * @memberof SecuritiesApiSecurityIndexAll
     */
    readonly companyId?: string
}

/**
 * Request parameters for securityInvestorShow operation in SecuritiesApi.
 * @export
 * @interface SecuritiesApiSecurityInvestorShowRequest
 */
export interface SecuritiesApiSecurityInvestorShowRequest {
    /**
     * the &#x60;npm_guid&#x60; on the security
     * @type {string}
     * @memberof SecuritiesApiSecurityInvestorShow
     */
    readonly id: string
}

/**
 * Request parameters for securityShow operation in SecuritiesApi.
 * @export
 * @interface SecuritiesApiSecurityShowRequest
 */
export interface SecuritiesApiSecurityShowRequest {
    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityShow
     */
    readonly accountId: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityShow
     */
    readonly companyId: string

    /**
     * ocf_id
     * @type {string}
     * @memberof SecuritiesApiSecurityShow
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityShow
     */
    readonly xNPMUser?: string
}

/**
 * Request parameters for securityUpdate operation in SecuritiesApi.
 * @export
 * @interface SecuritiesApiSecurityUpdateRequest
 */
export interface SecuritiesApiSecurityUpdateRequest {
    /**
     * the accounts &#x60;npm_guid&#x60; that this security is held in
     * @type {string}
     * @memberof SecuritiesApiSecurityUpdate
     */
    readonly accountId: string

    /**
     * the issuers &#x60;npm_guid&#x60; that this security is for
     * @type {string}
     * @memberof SecuritiesApiSecurityUpdate
     */
    readonly companyId: string

    /**
     * ocf_id
     * @type {string}
     * @memberof SecuritiesApiSecurityUpdate
     */
    readonly id: string

    /**
     * 
     * @type {SecurityUpdateRequest}
     * @memberof SecuritiesApiSecurityUpdate
     */
    readonly securityUpdateRequest: SecurityUpdateRequest

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SecuritiesApiSecurityUpdate
     */
    readonly xNPMUser?: string
}

/**
 * SecuritiesApi - object-oriented interface
 * @export
 * @class SecuritiesApi
 * @extends {BaseAPI}
 */
export class SecuritiesApi extends BaseAPI {
    /**
     * 
     * @summary Get all securities in an account
     * @param {SecuritiesApiAccountSecurityIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuritiesApi
     */
    public accountSecurityIndex(requestParameters: SecuritiesApiAccountSecurityIndexRequest, options?: AxiosRequestConfig) {
        return SecuritiesApiFp(this.configuration).accountSecurityIndex(requestParameters.accountId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gets stats on your portfolio
     * @param {SecuritiesApiAdminPortfolioStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuritiesApi
     */
    public adminPortfolioStats(requestParameters: SecuritiesApiAdminPortfolioStatsRequest, options?: AxiosRequestConfig) {
        return SecuritiesApiFp(this.configuration).adminPortfolioStats(requestParameters.accountIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists securities where the certificate ID is non-unique
     * @param {SecuritiesApiFindDuplicateSecurityIdsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuritiesApi
     */
    public findDuplicateSecurityIds(requestParameters: SecuritiesApiFindDuplicateSecurityIdsRequest = {}, options?: AxiosRequestConfig) {
        return SecuritiesApiFp(this.configuration).findDuplicateSecurityIds(requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lists all securities created recently grouped by date
     * @param {SecuritiesApiNewSecuritiesByDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuritiesApi
     */
    public newSecuritiesByDate(requestParameters: SecuritiesApiNewSecuritiesByDateRequest = {}, options?: AxiosRequestConfig) {
        return SecuritiesApiFp(this.configuration).newSecuritiesByDate(requestParameters.xWorkstation, requestParameters.page, requestParameters.size, requestParameters.endDate, requestParameters.startDate, requestParameters.issuerIds, requestParameters.securityTypes, requestParameters.accountIds, requestParameters.sectors, requestParameters.hasMarks, requestParameters.stockClassTypes, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lists summary of recently created securities grouped by sector
     * @param {SecuritiesApiNewSecuritiesBySectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuritiesApi
     */
    public newSecuritiesBySector(requestParameters: SecuritiesApiNewSecuritiesBySectorRequest = {}, options?: AxiosRequestConfig) {
        return SecuritiesApiFp(this.configuration).newSecuritiesBySector(requestParameters.xWorkstation, requestParameters.endDate, requestParameters.startDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gets stats on your portfolio
     * @param {SecuritiesApiPortfolioStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuritiesApi
     */
    public portfolioStats(requestParameters: SecuritiesApiPortfolioStatsRequest = {}, options?: AxiosRequestConfig) {
        return SecuritiesApiFp(this.configuration).portfolioStats(requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List securities
     * @param {SecuritiesApiSecurityAdminListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuritiesApi
     */
    public securityAdminList(requestParameters: SecuritiesApiSecurityAdminListRequest, options?: AxiosRequestConfig) {
        return SecuritiesApiFp(this.configuration).securityAdminList(requestParameters.issuerNpmGuid, requestParameters.xWorkstation, requestParameters.page, requestParameters.size, requestParameters.accountIds, requestParameters.securityTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets security by id
     * @param {SecuritiesApiSecurityAdminShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuritiesApi
     */
    public securityAdminShow(requestParameters: SecuritiesApiSecurityAdminShowRequest, options?: AxiosRequestConfig) {
        return SecuritiesApiFp(this.configuration).securityAdminShow(requestParameters.id, requestParameters.xWorkstation, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates a security under the given issuer
     * @param {SecuritiesApiSecurityCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuritiesApi
     */
    public securityCreate(requestParameters: SecuritiesApiSecurityCreateRequest, options?: AxiosRequestConfig) {
        return SecuritiesApiFp(this.configuration).securityCreate(requestParameters.accountId, requestParameters.companyId, requestParameters.securityCreateRequest, requestParameters.xWorkstation, requestParameters.xNPMUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary destroy the security under an issuer for an account
     * @param {SecuritiesApiSecurityDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuritiesApi
     */
    public securityDestroy(requestParameters: SecuritiesApiSecurityDestroyRequest, options?: AxiosRequestConfig) {
        return SecuritiesApiFp(this.configuration).securityDestroy(requestParameters.accountId, requestParameters.companyId, requestParameters.id, requestParameters.xWorkstation, requestParameters.xNPMUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lists all securities under an issuer for an account
     * @param {SecuritiesApiSecurityIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuritiesApi
     */
    public securityIndex(requestParameters: SecuritiesApiSecurityIndexRequest, options?: AxiosRequestConfig) {
        return SecuritiesApiFp(this.configuration).securityIndex(requestParameters.accountId, requestParameters.companyId, requestParameters.xWorkstation, requestParameters.xNPMUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all securities under all investors accounts
     * @param {SecuritiesApiSecurityIndexAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuritiesApi
     */
    public securityIndexAll(requestParameters: SecuritiesApiSecurityIndexAllRequest = {}, options?: AxiosRequestConfig) {
        return SecuritiesApiFp(this.configuration).securityIndexAll(requestParameters.page, requestParameters.size, requestParameters.onlyUnmarked, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get security
     * @param {SecuritiesApiSecurityInvestorShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuritiesApi
     */
    public securityInvestorShow(requestParameters: SecuritiesApiSecurityInvestorShowRequest, options?: AxiosRequestConfig) {
        return SecuritiesApiFp(this.configuration).securityInvestorShow(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the security under an issuer for an account
     * @param {SecuritiesApiSecurityShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuritiesApi
     */
    public securityShow(requestParameters: SecuritiesApiSecurityShowRequest, options?: AxiosRequestConfig) {
        return SecuritiesApiFp(this.configuration).securityShow(requestParameters.accountId, requestParameters.companyId, requestParameters.id, requestParameters.xWorkstation, requestParameters.xNPMUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a security under the given issuer
     * @param {SecuritiesApiSecurityUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuritiesApi
     */
    public securityUpdate(requestParameters: SecuritiesApiSecurityUpdateRequest, options?: AxiosRequestConfig) {
        return SecuritiesApiFp(this.configuration).securityUpdate(requestParameters.accountId, requestParameters.companyId, requestParameters.id, requestParameters.securityUpdateRequest, requestParameters.xWorkstation, requestParameters.xNPMUser, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AccountSecurityIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountSecurityIndexErrorCodes = [
];

export type AdminPortfolioStatsErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AdminPortfolioStatsErrorCodes = [
];

export type FindDuplicateSecurityIdsErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const FindDuplicateSecurityIdsErrorCodes = [
];

export type NewSecuritiesByDateErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const NewSecuritiesByDateErrorCodes = [
    422,
];

export type NewSecuritiesBySectorErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const NewSecuritiesBySectorErrorCodes = [
];

export type PortfolioStatsErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PortfolioStatsErrorCodes = [
];

export type SecurityAdminListErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecurityAdminListErrorCodes = [
];

export type SecurityAdminShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecurityAdminShowErrorCodes = [
];

export type SecurityCreateErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecurityCreateErrorCodes = [
    422,
];

export type SecurityDestroyErrorTypes =

    | {
        status: 400;
    }

    | {
        status: 404;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecurityDestroyErrorCodes = [
    400,
    404,
    422,
];

export type SecurityIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecurityIndexErrorCodes = [
];

export type SecurityIndexAllErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecurityIndexAllErrorCodes = [
];

export type SecurityInvestorShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecurityInvestorShowErrorCodes = [
];

export type SecurityShowErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecurityShowErrorCodes = [
    404,
];

export type SecurityUpdateErrorTypes =

    | {
        status: 404;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecurityUpdateErrorCodes = [
    404,
    422,
];


