/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  StakeholdersApi,
  StakeholderIndexErrorCodes,
  StakeholderIndexErrorTypes,
  StakeholderCreateErrorCodes,
  StakeholderShowErrorCodes,
  StakeholderShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new StakeholdersApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type StakeholderIndexMethod = StakeholdersApi['stakeholderIndex'];
export type StakeholderIndexResponseType = MethodResult<StakeholderIndexMethod>;

export const useStakeholderIndex = (
  variables: Parameters<StakeholderIndexMethod>[0],
  config?: UseQueryConfig<
    StakeholderIndexMethod,
    StakeholderIndexErrorTypes
  >
) => {
  return useQuery<
    StakeholderIndexMethod,
    StakeholderIndexErrorTypes
  >(
    (...args) => api.stakeholderIndex(...args),
    StakeholderIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StakeholderIndex'],
        ...apiQueryConfig.StakeholderIndex,
        ...config?.queryConfig
      },
    },
    'StakeholderIndex',
  );
};

export const useStakeholderIndexInfinite = (
  variables: Parameters<StakeholderIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    StakeholderIndexMethod,
    StakeholderIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    StakeholderIndexMethod,
    StakeholderIndexErrorTypes
  >(
    (...args) => api.stakeholderIndex(...args),
    StakeholderIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StakeholderIndex', 'StakeholderIndexInfinite'],
        ...apiQueryConfig.StakeholderIndex,
        ...config?.queryConfig,
      },
    },
    'StakeholderIndex',
  );
};

export const useStakeholderIndexLazy = (baseOptions?: {
  variables?: Parameters<StakeholderIndexMethod>[0],
  config?: UseQueryConfig<
    StakeholderIndexMethod,
    StakeholderIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    StakeholderIndexMethod,
    StakeholderIndexErrorTypes
  >(
    (...args) => api.stakeholderIndex(...args),
    StakeholderIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['StakeholderIndex'],
        ...apiQueryConfig.StakeholderIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'StakeholderIndex',
  );
};


export type StakeholderCreateMethod = StakeholdersApi['stakeholderCreate'];

export const useStakeholderCreate = () => {
  return useMutation<StakeholderCreateMethod>(
    (...args) => api.stakeholderCreate(...args),
    StakeholderCreateErrorCodes,
    'StakeholderCreate',
    '/api/admin/issuers/{issuer_id}/stakeholders',
    'post'
  );
};

export type StakeholderShowMethod = StakeholdersApi['stakeholderShow'];
export type StakeholderShowResponseType = MethodResult<StakeholderShowMethod>;

export const useStakeholderShow = (
  variables: Parameters<StakeholderShowMethod>[0],
  config?: UseQueryConfig<
    StakeholderShowMethod,
    StakeholderShowErrorTypes
  >
) => {
  return useQuery<
    StakeholderShowMethod,
    StakeholderShowErrorTypes
  >(
    (...args) => api.stakeholderShow(...args),
    StakeholderShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StakeholderShow'],
        ...apiQueryConfig.StakeholderShow,
        ...config?.queryConfig
      },
    },
    'StakeholderShow',
  );
};

export const useStakeholderShowInfinite = (
  variables: Parameters<StakeholderShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    StakeholderShowMethod,
    StakeholderShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    StakeholderShowMethod,
    StakeholderShowErrorTypes
  >(
    (...args) => api.stakeholderShow(...args),
    StakeholderShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StakeholderShow', 'StakeholderShowInfinite'],
        ...apiQueryConfig.StakeholderShow,
        ...config?.queryConfig,
      },
    },
    'StakeholderShow',
  );
};

export const useStakeholderShowLazy = (baseOptions?: {
  variables?: Parameters<StakeholderShowMethod>[0],
  config?: UseQueryConfig<
    StakeholderShowMethod,
    StakeholderShowErrorTypes
  >
}) => {
  return useLazyQuery<
    StakeholderShowMethod,
    StakeholderShowErrorTypes
  >(
    (...args) => api.stakeholderShow(...args),
    StakeholderShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['StakeholderShow'],
        ...apiQueryConfig.StakeholderShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'StakeholderShow',
  );
};

