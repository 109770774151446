import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { TooltipInfoIcon } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';

type Props = {
  icon: React.ReactNode;
  label: string;
  tooltip?: string;
  onClick?: () => void;
  onHover?: () => void;
  onClear?: () => void;
};

export const ChartLegendItem = ({
  icon,
  label,
  tooltip,
  onClick,
  onHover,
  onClear,
}: Props) => {
  return (
    <Flex
      gap={'xs'}
      align={'center'}
      onClick={onClick}
      onMouseEnter={onHover}
      onMouseLeave={onClear}
      style={onClick ? { cursor: 'pointer' } : undefined}
    >
      {icon}
      <Text size={'sm'} colorVariant={'primary'}>
        {label}
      </Text>
      {tooltip && <TooltipInfoIcon title={tooltip} />}
    </Flex>
  );
};
