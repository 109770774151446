import { parsePhoneNumber } from 'libphonenumber-js';

export const formatPhoneNumber = (text?: string) => {
  if (!text) {
    return text;
  }
  try {
    const phoneNumber = parsePhoneNumber(text, 'US');
    if (phoneNumber.country === 'US') {
      return phoneNumber.formatNational();
    }
    return phoneNumber.formatInternational();
  } catch {
    return text;
  }
};
