import { withErrorBoundary } from 'react-error-boundary';

import { ErrorSkeleton } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { type CompanyPricingApi, type VenusApi } from '@npm/data-access';

import { type CompanyOverviewMaxValues } from '../TapeDPricing/TapeDPricing.types';

import { CompanyOverviewSummaryChart } from './CompanyOverviewSummaryChart';

type Props = {
  isCovered: boolean | undefined;
  tapeDData: CompanyPricingApi.IntrinsicValueIndex;
  bidOfferData: CompanyPricingApi.HistoricalPriceIndex;
  primaryRoundsData: VenusApi.PrimaryOcfValuationIndex;
  valuations409Data: VenusApi.FairMarketValueIndex;
  movingAverageData: CompanyPricingApi.MovingAveragePrices;
  yAxisMaxValues: CompanyOverviewMaxValues;
};

export const CompanyOverviewSummary = withErrorBoundary(
  ({
    tapeDData,
    bidOfferData,
    primaryRoundsData,
    valuations409Data,
    movingAverageData,
    yAxisMaxValues,
    isCovered,
  }: Props) => {
    if (
      !shouldShowCompanyOverviewSummary(
        tapeDData,
        bidOfferData,
        valuations409Data,
        movingAverageData
      )
    ) {
      return null;
    }

    return (
      <CompanyOverviewSummaryChart
        tapeDData={tapeDData.intrinsic_values}
        primaryRoundsData={primaryRoundsData.primary_rounds}
        bidOfferData={
          isCovered
            ? bidOfferData.historical_prices
            : { ...bidOfferData.historical_prices, primary_rounds: [] }
        }
        valuation409AData={isCovered ? valuations409Data['409as'] : undefined}
        movingAverageData={movingAverageData.prices}
        yAxisMaxValues={yAxisMaxValues}
      />
    );
  },
  {
    FallbackComponent: ErrorSkeleton,
  }
);

const shouldShowCompanyOverviewSummary = (
  tapeDData: CompanyPricingApi.IntrinsicValueIndex | undefined,
  bidOfferData: CompanyPricingApi.HistoricalPriceIndex | undefined,
  // primaryRoundsData: VenusApi.PrimaryOcfValuationIndex | undefined,
  valuations409Data: VenusApi.FairMarketValueIndex | undefined,
  movingAverageData: CompanyPricingApi.MovingAveragePrices | undefined
) => {
  let valuation = 0;

  if (tapeDData?.intrinsic_values?.length) {
    valuation++;
  }

  if (
    bidOfferData?.historical_prices?.aggregated_bids?.length ||
    bidOfferData?.historical_prices?.aggregated_asks?.length
  ) {
    valuation++;
  }

  if (valuations409Data?.['409as']?.length) {
    valuation++;
  }

  if (movingAverageData?.prices) {
    valuation++;
  }

  return valuation >= 2;
};
