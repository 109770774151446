import React, { memo, useCallback } from 'react';

import { DarkModeSwitcher } from '@npm/core/ui/components/organisms/DarkModeSwitcher';
import {
  Content,
  SidebarLayout,
} from '@npm/core/ui/components/organisms/Layout';
import { useCurrentRoute } from '@npm/core/ui/hooks/useCurrentRoute';
import {
  RoleSwitcher,
  useUserContextStore,
  WorkstationSwitcher,
} from '@npm/features/auth/user/context';
import { OboButton } from '@npm/features/auth/user/context/Obo/OboButton';
import { useObo } from '@npm/features/auth/user/role';
import { useLogoRedirectLink } from '@npm/features/logo';

import { InvestorMenu } from './InvestorMenu';

type Props = {
  children: React.ReactNode;
  showPageHeader?: boolean;
  showHamburgerMenu?: boolean;
  collapsible?: boolean;
};

export const InvestorLayout = memo(function InvestorLayout({
  children,
  showPageHeader = true,
  showHamburgerMenu = true,
  collapsible,
}: Props) {
  const { isObo } = useObo();
  const isNewUser = useUserContextStore(store => store.isNewUser);
  const { route } = useCurrentRoute();

  const logoLinkUrl = useLogoRedirectLink();

  const renderMenu = useCallback(
    (isSidebarCollapsed: boolean) => (
      <>
        <WorkstationSwitcher isCollapsed={isSidebarCollapsed} />
        {isObo ? (
          <OboButton isCollapsed={isSidebarCollapsed} />
        ) : (
          <RoleSwitcher isCollapsed={isSidebarCollapsed} />
        )}
        <InvestorMenu />
        <DarkModeSwitcher isCollapsed={isSidebarCollapsed} />
      </>
    ),
    [isObo]
  );

  if (route?.layout?.noLayout) {
    return <>{children}</>;
  }

  return (
    <SidebarLayout
      logoLinkUrl={logoLinkUrl}
      renderMenu={renderMenu}
      mobileMenuType={isNewUser ? 'basic' : null}
      showHamburgerMenu={
        route?.layout?.noHamburgerMenu != null
          ? !route.layout.noHamburgerMenu
          : showHamburgerMenu
      }
      collapsible={collapsible}
    >
      <Content showPageHeader={showPageHeader}>{children}</Content>
    </SidebarLayout>
  );
});
