import styled from 'styled-components';

import {
  BOTTOM_NAVIGATION_BAR_MIN_HEIGHT,
  BOTTOM_NAVIGATION_BAR_MIN_HEIGHT_MOBILE,
} from '@npm/core/ui/components/atoms/BottomNavigation';

export const IconContainer = styled.div<{ $isMobile?: boolean }>`
  position: fixed;
  right: ${({ theme }) => theme.spacing.md}px;
  bottom: ${({ $isMobile }) =>
    $isMobile
      ? BOTTOM_NAVIGATION_BAR_MIN_HEIGHT_MOBILE
      : BOTTOM_NAVIGATION_BAR_MIN_HEIGHT}px;
`;
