import { useCallback, useState } from 'react';

import { type Holding } from '@npm/data-access';

import { type HoldingDeleteModalProps } from './HoldingDeleteModal';

export const useHoldingDeleteModal = (): [
  {
    openDeleteModal: (holding: Holding) => void;
  },
  Pick<HoldingDeleteModalProps, 'onCancel' | 'open' | 'holding'>
] => {
  const [open, setOpen] = useState(false);
  const [holding, setHolding] = useState<Holding>(null);

  const openDeleteModal = useCallback(
    (holding: Holding) => {
      setHolding(holding);
      setOpen(true);
    },
    [setOpen]
  );

  const closeModal = useCallback(() => {
    setOpen(false);
    setHolding(null);
  }, [setOpen]);

  return [
    { openDeleteModal },
    {
      holding,
      open: open,
      onCancel: closeModal,
    },
  ];
};
