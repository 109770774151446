import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import {
  type QueryKey,
  type UseInfiniteQueryOptions as UseReactInfiniteQueryOptions,
  type UseQueryOptions as UseReactQueryOptions,
  useInfiniteQuery as useReactInfiniteQuery,
  useQuery as useReactQuery,
} from '@tanstack/react-query';
import { type AxiosRequestConfig, type AxiosResponse } from 'axios';
import { isEqual, isNull, isUndefined, omitBy } from 'lodash';

import { DatadogService } from '@npm/utils';

import { queryClient } from '../../npm';
import { apiMutationConfig, apiRoleConfig, getAxiosInstance } from '../config';
import { type MutationKey, type QueryKey as QueryKeyType } from '../generated';

export type extractGeneric<Type> = Type extends AxiosResponse<infer X>
  ? X
  : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getApiError = (err: any, knowStatuses: number[]) => {
  if (knowStatuses.includes(err?.status ?? -1)) {
    return err;
  }

  if (knowStatuses.includes(err?.response?.status ?? -1)) {
    return err?.response;
  }

  return {
    status: -1,
    originalStatus: err?.status ?? err?.response?.status,
    data: err?.data ?? err?.response?.data,
  };
};

const getRoleRelatedHeaderAsAnObject = (key: QueryKeyType) => {
  const axiosInstance = getAxiosInstance();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const headers = (axiosInstance?.defaults?.headers || {}) as any;

  if (apiRoleConfig[key]?.includeRoleInCacheKey === false) {
    return {};
  }

  return {
    workstation: headers['X-Workstation'],
    roleId: headers['X-User-Role-Id'],
    acrossAccount: headers['X-Across-Accounts'],
    oboAccountId: headers['X-Obo-Account-Id'],
    oboUserId: headers['X-Obo-User-Id'],
  };
};

const removeEmptyValues = <T extends {}>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  val?: T | any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): T[] | T | null | undefined | any => {
  if (!val) {
    return val;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isDefined = (v: any) =>
    isUndefined(v) ||
    isNull(v) ||
    v === '' ||
    v === 'null' ||
    (typeof v === 'number' && isNaN(v));

  if (Array.isArray(val)) {
    return val.map(v => removeEmptyValues(v)).filter(v => !isDefined(v));
  } else if (val.constructor === Object) {
    for (const key in val) {
      // eslint-disable-next-line
      const value = val[key] as unknown as any;
      if (!value) {
        continue;
      }

      if (value.constructor === Object) {
        val[key] = removeEmptyValues(value);
      } else if (Array.isArray(value)) {
        val[key] = value
          .map(v => removeEmptyValues(v))
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter(v => !isDefined(v)) as unknown as any;
      }
    }

    return omitBy(val, isDefined) as T;
  }

  return val;
};

const handleCacheInvalidation = (apiKey: MutationKey) => {
  try {
    const def = apiMutationConfig[apiKey];
    if (def && def.invalidateQueries && def.invalidateQueries.length) {
      // invalidate cache
      def.invalidateQueries.forEach(key => {
        queryClient.resetQueries([key]);
      });
    }
  } catch (e) {
    console.warn(e);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericMethod = (...params: any[]) => Promise<AxiosResponse<any, any>>;

/**
 * A type helper util to get result type of methods returning promises
 *
 * Note `MethodResult` is often used in place of react-query `TData` but
 * `query.data` can be `undefined` (and `query.error` can be `null`)
 * - current typing of `onComplete/onError` handlers params don't count with that
 */
export type MethodResult<TMethod extends GenericMethod> = extractGeneric<
  Awaited<ReturnType<TMethod>>
>;

/**
 * Options for react-query `useQuery` with `queryKey` required
 *
 * Meant to be used by the Base API hooks to make sure cache ids are always set
 */
type UseReactQueryOptionsWithKey<TMethod extends GenericMethod, TError> = Omit<
  UseReactQueryOptions<MethodResult<TMethod>, TError>,
  'queryKey'
> & { queryKey: QueryKey };

/**
 * useQuery config type with `queryConfig` resp. `queryKey` optional
 *
 * Meant to be used by specific (generated) api hooks (these pass `queryKey`
 * but don't require it from the client code).
 */
export type UseQueryConfig<TMethod extends GenericMethod, TError> = {
  onComplete?: (data: MethodResult<TMethod>) => void;
  onError?: (error: TError) => void;
  axiosConfig?: AxiosRequestConfig;
  queryConfig?: UseReactQueryOptions<MethodResult<TMethod>, TError>;
};

/**
 * Wrapper over react-query providing expected API/behaviour
 */
export const useQuery = <TMethod extends GenericMethod, TError>(
  method: TMethod,
  knowStatuses: number[],
  variables: Parameters<TMethod>[0],
  config: {
    onComplete?: (data: MethodResult<TMethod>) => void;
    onError?: (error: TError) => void;
    axiosConfig?: AxiosRequestConfig;
    queryConfig: UseReactQueryOptionsWithKey<TMethod, TError>;
  },
  key: QueryKeyType
) => {
  const query = useReactQuery<MethodResult<TMethod>, TError>({
    queryFn: () =>
      method(removeEmptyValues(variables), config.axiosConfig).then(res => {
        return res.data;
      }),
    ...config.queryConfig,
    queryKey: [
      ...config.queryConfig.queryKey,
      variables,
      removeEmptyValues(getRoleRelatedHeaderAsAnObject(key)),
    ],
  });

  const handleError = useErrorHandler();

  // Since `queryFn` isn't called in caching scenarios (no then/catch), handle
  // onComplete/onError when status/result change.
  useEffect(() => {
    if (query.status === 'success') {
      if (config?.onComplete) config.onComplete(query.data);
    } else if (query.status === 'error') {
      console.log('err', query);
      const apiError = getApiError(query.error, knowStatuses);
      if (config.onError) {
        config.onError(apiError);
      } else {
        handleError(apiError);
      }
    }
    // no config & knowStatuses in deps intended - care only for initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.status, query.data, query.error]);

  return query;
};

type UseReactInfiniteQueryOptionsWithKey<
  TMethod extends GenericMethod,
  TError
> = Omit<
  UseReactInfiniteQueryOptions<MethodResult<TMethod>, TError>,
  'queryKey'
> & { queryKey: QueryKey };

export type UseInfiniteQueryConfig<TMethod extends GenericMethod, TError> = {
  onComplete?: (data: MethodResult<TMethod>[]) => void;
  onError?: (error: TError) => void;
  axiosConfig?: AxiosRequestConfig;
  queryConfig?: UseReactInfiniteQueryOptions<MethodResult<TMethod>, TError>;
};

/**
 * Wrapper over react-query providing expected API/behaviour
 */
export const useInfiniteQuery = <TMethod extends GenericMethod, TError>(
  method: TMethod,
  knowStatuses: number[],
  variables: Parameters<TMethod>[0],
  config: {
    onComplete?: (data: MethodResult<TMethod>[]) => void;
    onError?: (error: TError) => void;
    axiosConfig?: AxiosRequestConfig;
    queryConfig: UseReactInfiniteQueryOptionsWithKey<TMethod, TError>;
  },
  key: QueryKeyType
) => {
  const query = useReactInfiniteQuery<MethodResult<TMethod>, TError>({
    queryFn: ({ pageParam }) =>
      method(
        removeEmptyValues({ ...variables, ...pageParam }),
        config.axiosConfig
      ).then(res => res.data),
    ...config.queryConfig,
    queryKey: [
      ...config.queryConfig.queryKey,
      variables,
      removeEmptyValues(getRoleRelatedHeaderAsAnObject(key)),
    ],
    getNextPageParam: lastPage =>
      lastPage.pagination == null ||
      lastPage.pagination.size * lastPage.pagination.page >=
        lastPage.pagination.total_records
        ? undefined
        : {
            size: lastPage.pagination?.size ?? variables.size,
            page: (lastPage.pagination?.page ?? variables.page) + 1,
          },
    getPreviousPageParam: firstPage =>
      firstPage.pagination?.size == null ||
      firstPage.pagination?.page == null ||
      firstPage.pagination.page === 1
        ? undefined
        : {
            size: firstPage.pagination.size,
            page: firstPage.pagination.page - 1,
          },
  });

  const handleError = useErrorHandler();

  // Since `queryFn` isn't called in caching scenarios (no then/catch), handle
  // onComplete/onError when status/result change.
  useEffect(() => {
    if (query.status === 'success') {
      if (config?.onComplete) {
        config.onComplete(query.data.pages);
      }
    } else if (query.status === 'error') {
      const apiError = getApiError(query.error, knowStatuses);
      if (config.onError) {
        config.onError(apiError);
      } else {
        handleError(apiError);
      }
    }
    // no config & knowStatuses in deps intended - care only for initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.status, query.data, query.error]);

  return query;
};

/**
 * Wrapper over react-query providing expected API/behaviour
 *
 * Most customisation revolves around the execution function - see `execute`
 * (react-query doesn't provide an execution function - the nearest
 * approximation is `refetch` but that doesn't support variables and caching)
 */
export const useLazyQuery = <TMethod extends GenericMethod, TError>(
  method: TMethod,
  knowStatuses: number[],
  variablesArg: Parameters<TMethod>[0] | undefined,
  config: {
    onComplete?: (data: MethodResult<TMethod>) => void;
    onError?: (error: TError) => void;
    axiosConfig?: AxiosRequestConfig;
    queryConfig: UseReactQueryOptionsWithKey<TMethod, TError>;
  },
  key: QueryKeyType
) => {
  const [variables, setVariables] =
    useState<Parameters<TMethod>[0]>(variablesArg);

  // Update `variables` when a new `variablesArg` value passed into the hook
  useEffect(() => {
    if (!isEqual(variablesArg, variables)) {
      setVariables(variablesArg);
    }
    // No `variables` in deps intended - update only when `variablesArg` change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variablesArg]);

  // The `enabled` is a react-query param for postponing request (until `true`)
  // here it's meant to get enabled when `execute` is called.
  //
  // No need to disable it again since react-query reloads only when request
  // params change, and that happens only when `variables` change.
  const [enabled, setEnabled] = useState(false);

  const query = useReactQuery<MethodResult<TMethod>, TError>({
    queryFn: () =>
      method(removeEmptyValues(variables), config.axiosConfig).then(res => {
        return res.data;
      }),
    ...config.queryConfig,
    queryKey: [
      ...config.queryConfig.queryKey,
      removeEmptyValues(variables),
      removeEmptyValues(getRoleRelatedHeaderAsAnObject(key)),
    ],
    enabled,
  });

  const resolveRef =
    useRef<(result: MethodResult<TMethod> | undefined) => void>();
  const rejectRef = useRef<(error: TError) => void>();
  // make sure the main useEffect is always triggered after `execute` call
  const [callIterator, setCallIterator] = useState(0);

  /**
   * Lazy Query execution function
   *
   * This function enables the query, sets variables (if any) and resolve/reject
   * refs of the returned promise, which are used in the useEffect below.
   *
   * When variables don't change, react-query doesn't do anything - just
   * resolve/reject the existing data/error.
   * (The check on loading status is important to let the 1st execution run,
   * note the initial status is always loading until 1st query is finished.)
   */
  const execute = useMemo(
    () => (options?: { variables?: Parameters<TMethod>[0] }) => {
      // potential improvement: proper cancellation of pending requests
      if (resolveRef.current) resolveRef.current(undefined);

      const request = new Promise<MethodResult<TMethod> | undefined>(
        (resolve, reject) => {
          resolveRef.current = resolve;
          rejectRef.current = reject;
        }
      );

      setVariables(prevVariables =>
        options?.variables && !isEqual(options?.variables, prevVariables)
          ? options.variables
          : prevVariables
      );
      setEnabled(true);
      setCallIterator(count => ++count);

      return request;
    },
    []
  );

  const handleError = useErrorHandler();

  // Since `queryFn` isn't called in caching scenarios (no then/catch), handle
  // onComplete/onError and resolve/reject of `execute` when status/result change.
  useEffect(() => {
    if (query.status === 'success') {
      if (resolveRef.current) resolveRef.current(query.data);
      if (config?.onComplete) config.onComplete(query.data);
    } else if (query.status === 'error') {
      const apiError = getApiError(query.error, knowStatuses);
      if (config.onError) {
        config.onError(apiError);
        if (resolveRef.current) resolveRef.current(undefined);
      } else {
        handleError(apiError);
        if (rejectRef.current) rejectRef.current(query.error);
      }
    }
    // no config & knowStatuses in deps intended - care only for initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callIterator, query.status, query.data, query.error]);

  return useMemo(
    () =>
      [
        execute,
        {
          ...query,
          // original `isLoading` is true before 1st load resolves no matter if `enabled`
          isLoading: query.isLoading && query.isFetching,
        },
      ] as const,
    [execute, query]
  );
};

export const useMutation = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TMethod extends (...params: any[]) => Promise<any>
>(
  method: TMethod,
  knowStatuses: number[],
  apiKey: MutationKey,
  apiUrl: string,
  apiMethod: string
) => {
  const [isLoading, setLoading] = useState(false);

  const execute = useCallback(
    async (
      variables: Parameters<TMethod>[0],
      config?: {
        onComplete?: (
          data: extractGeneric<Awaited<ReturnType<TMethod>>>
        ) => void;
        shouldRemoveEmptyValues?: boolean;
        axiosConfig?: AxiosRequestConfig;
      }
    ) => {
      setLoading(true);
      return method(
        config?.shouldRemoveEmptyValues ?? true
          ? removeEmptyValues(variables)
          : variables,
        config?.axiosConfig
      )
        .then(res => {
          DatadogService.addAction(`call api (${apiMethod})[${apiUrl}]`);
          handleCacheInvalidation(apiKey);
          if (config?.onComplete) {
            config.onComplete(res.data);
          }
          return res.data;
        })
        .catch(err => {
          const apiError = getApiError(err, knowStatuses);
          return Promise.reject(apiError);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    isLoading,
    execute,
  };
};
