import styled from 'styled-components';

import { Table as BaseTable } from '@npm/core/ui/components/molecules/Table';

export const SIGNERS_TABLE_MAX_WIDTH = 800;

export const Table = styled(BaseTable)`
  max-width: ${SIGNERS_TABLE_MAX_WIDTH}px;

  .ant-table-container table > thead > tr:first-child th:last-child,
  tr.ant-table-row td:last-of-type {
    text-align: left !important;
  }

  .ant-table-placeholder {
    display: none;
  }

  &&& .ant-table.ant-table-empty .ant-table-tbody {
    height: unset;
    min-height: unset;
  }
`;

export const NoDataTitle = styled.p<{ $hasError: boolean }>`
  margin-top: ${({ theme }) => theme.spacing.md}px;
  font-size: ${({ theme }) => theme.typography.size.md}px;

  color: ${({ theme, $hasError }) =>
    $hasError
      ? theme.color.error.typography.primary
      : theme.color.general.typography.primary};
`;

export const Disclaimer = styled.p`
  margin: ${({ theme }) => `${theme.spacing.lg}px 0`};
  font-size: ${({ theme }) => theme.typography.size.xs}px;
  line-height: ${({ theme }) => theme.typography.height.xs}px;
  color: ${({ theme }) => theme.color.general.typography.secondary};
`;
