/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  DocumentSigningApi,
  DocumentSigningErrorCodes,
  DocumentSigningCompleteErrorCodes,
  DocumentSigningCompleteErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new DocumentSigningApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type DocumentSigningMethod = DocumentSigningApi['documentSigning'];

export const useDocumentSigning = () => {
  return useMutation<DocumentSigningMethod>(
    (...args) => api.documentSigning(...args),
    DocumentSigningErrorCodes,
    'DocumentSigning',
    '/api/document-signing',
    'post'
  );
};

export type DocumentSigningCompleteMethod = DocumentSigningApi['documentSigningComplete'];
export type DocumentSigningCompleteResponseType = MethodResult<DocumentSigningCompleteMethod>;

export const useDocumentSigningComplete = (
  variables: Parameters<DocumentSigningCompleteMethod>[0],
  config?: UseQueryConfig<
    DocumentSigningCompleteMethod,
    DocumentSigningCompleteErrorTypes
  >
) => {
  return useQuery<
    DocumentSigningCompleteMethod,
    DocumentSigningCompleteErrorTypes
  >(
    (...args) => api.documentSigningComplete(...args),
    DocumentSigningCompleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['DocumentSigningComplete'],
        ...apiQueryConfig.DocumentSigningComplete,
        ...config?.queryConfig
      },
    },
    'DocumentSigningComplete',
  );
};

export const useDocumentSigningCompleteInfinite = (
  variables: Parameters<DocumentSigningCompleteMethod>[0],
  config?: UseInfiniteQueryConfig<
    DocumentSigningCompleteMethod,
    DocumentSigningCompleteErrorTypes
  >
) => {
  return useInfiniteQuery<
    DocumentSigningCompleteMethod,
    DocumentSigningCompleteErrorTypes
  >(
    (...args) => api.documentSigningComplete(...args),
    DocumentSigningCompleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['DocumentSigningComplete', 'DocumentSigningCompleteInfinite'],
        ...apiQueryConfig.DocumentSigningComplete,
        ...config?.queryConfig,
      },
    },
    'DocumentSigningComplete',
  );
};

export const useDocumentSigningCompleteLazy = (baseOptions?: {
  variables?: Parameters<DocumentSigningCompleteMethod>[0],
  config?: UseQueryConfig<
    DocumentSigningCompleteMethod,
    DocumentSigningCompleteErrorTypes
  >
}) => {
  return useLazyQuery<
    DocumentSigningCompleteMethod,
    DocumentSigningCompleteErrorTypes
  >(
    (...args) => api.documentSigningComplete(...args),
    DocumentSigningCompleteErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['DocumentSigningComplete'],
        ...apiQueryConfig.DocumentSigningComplete,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'DocumentSigningComplete',
  );
};

