import { Codebooks } from './codebooks.types';

const Items = {
  buyer: 'buyer',
  seller: 'seller',
} as const;

export const CbIssuerAccountRole = {
  code: Codebooks.ISSUER_ACCOUNT_ROLE,
  items: Items,
};
