import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { CbAccountType } from '@npm/data-access';

import {
  type WithSupportTicketType,
  ZendeskContactButton,
} from '../../../app/zendesk';
import {
  TaxInformationForm,
  TaxInformationReadOnly,
  useTaxInformationForm,
} from '../../../onboardingAndSubmissionSteps/TaxInformation';
import { taxInformationUtils } from '../../../onboardingAndSubmissionSteps/TaxInformation/TaxInformation.utils';
import { type PostOnboardingContext } from '../../PostOnboarding.types';
import { StepContainer } from '../../StepContainer';

const { StepState } = Wizard;

type Props = {
  stepProps: Wizard.StepProps;
} & WithSupportTicketType;

export const TaxInformationPostOnboarding = ({
  stepProps,
  zendeskTicketGroupId,
}: Props) => {
  const { isMobile, isTablet } = useBreakpoints();
  const isTabletOrMobile = isMobile || isTablet;

  const { account, stepState } = useWizardStore(
    (s: WizardStore<PostOnboardingContext>) => ({
      account: s.data.account,
      stepState: s.activeStepState,
    })
  );
  const isIndividualAccount =
    account?.type?.code === CbAccountType.items.PersonAccount;
  const title = taxInformationUtils(isIndividualAccount);

  const { form, data, isLoading, isUpdating, handleSubmit, isW9Disabled } =
    useTaxInformationForm(account?.id, {
      onUpdate: () => {
        Notification.success({
          message: `You have updated your ${title}`,
        });
        stepProps.handleStepComplete();
      },
    });

  return (
    <StepContainer
      stepProps={stepProps}
      isLoading={isLoading}
      isUpdating={isUpdating}
      handleSubmit={handleSubmit}
      data-cy={CypressDataIds.PostOnboarding.TaxInformation.Step}
    >
      {stepState === StepState.EDIT && (
        <>
          <Margin bottom={isTabletOrMobile ? 'md' : 'xl'}>
            <Alert
              type={'info'}
              message={
                'If you are uncertain which Tax Form you should choose, please consult your tax advisor and return to this step.'
              }
            />
          </Margin>
          <TaxInformationForm
            form={form}
            type={account.type?.name?.toLocaleLowerCase()}
            handleValuesChange={() => stepProps.setFormHasUnsavedChanges(true)}
            isW9Disabled={isW9Disabled}
          />
        </>
      )}
      {stepState === StepState.FILLED && (
        <>
          <Margin bottom="lg">
            <Alert
              type="info"
              message={
                <div>
                  If you have entered the wrong information,{' '}
                  <ZendeskContactButton
                    zendeskTicketGroupId={zendeskTicketGroupId}
                  >
                    please contact us
                  </ZendeskContactButton>
                </div>
              }
              closable
            />
          </Margin>
          <TaxInformationReadOnly taxDocumentName={data?.tax_document?.name} />
        </>
      )}
    </StepContainer>
  );
};
