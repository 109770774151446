import styled from 'styled-components';

import { InputTextStyles } from '../../atoms/Typography';
import { CompanyLogo } from '../CompanyLogo';

export const CompanySearchLogo = styled(CompanyLogo)`
  & > * + * {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
  p {
    ${InputTextStyles};
  }
`;
