/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CodebookIndex } from '../model';
// @ts-ignore
import { ErrorValidation } from '../model';
/**
 * CodebookApi - axios parameter creator
 * @export
 */
export const CodebookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the codebook for a specified type
         * @param {'TASK_STATUS' | 'INVESTOR_TYPE' | 'VENUS_FAIR_MARKET_VALUE' | 'US_STATES' | 'COUNTRY' | 'SUBREGION' | 'CURRENCY'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codebook: async (type: 'TASK_STATUS' | 'INVESTOR_TYPE' | 'VENUS_FAIR_MARKET_VALUE' | 'US_STATES' | 'COUNTRY' | 'SUBREGION' | 'CURRENCY', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('codebook', 'type', type)
            const localVarPath = changeUrl(`/api/codebooks/`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodebookApi - functional programming interface
 * @export
 */
export const CodebookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CodebookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the codebook for a specified type
         * @param {'TASK_STATUS' | 'INVESTOR_TYPE' | 'VENUS_FAIR_MARKET_VALUE' | 'US_STATES' | 'COUNTRY' | 'SUBREGION' | 'CURRENCY'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async codebook(type: 'TASK_STATUS' | 'INVESTOR_TYPE' | 'VENUS_FAIR_MARKET_VALUE' | 'US_STATES' | 'COUNTRY' | 'SUBREGION' | 'CURRENCY', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodebookIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.codebook(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CodebookApi - factory interface
 * @export
 */
export const CodebookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CodebookApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the codebook for a specified type
         * @param {'TASK_STATUS' | 'INVESTOR_TYPE' | 'VENUS_FAIR_MARKET_VALUE' | 'US_STATES' | 'COUNTRY' | 'SUBREGION' | 'CURRENCY'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codebook(type: 'TASK_STATUS' | 'INVESTOR_TYPE' | 'VENUS_FAIR_MARKET_VALUE' | 'US_STATES' | 'COUNTRY' | 'SUBREGION' | 'CURRENCY', options?: any): AxiosPromise<CodebookIndex> {
            return localVarFp.codebook(type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for codebook operation in CodebookApi.
 * @export
 * @interface CodebookApiCodebookRequest
 */
export interface CodebookApiCodebookRequest {
    /**
     * 
     * @type {'TASK_STATUS' | 'INVESTOR_TYPE' | 'VENUS_FAIR_MARKET_VALUE' | 'US_STATES' | 'COUNTRY' | 'SUBREGION' | 'CURRENCY'}
     * @memberof CodebookApiCodebook
     */
    readonly type: 'TASK_STATUS' | 'INVESTOR_TYPE' | 'VENUS_FAIR_MARKET_VALUE' | 'US_STATES' | 'COUNTRY' | 'SUBREGION' | 'CURRENCY'
}

/**
 * CodebookApi - object-oriented interface
 * @export
 * @class CodebookApi
 * @extends {BaseAPI}
 */
export class CodebookApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the codebook for a specified type
     * @param {CodebookApiCodebookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodebookApi
     */
    public codebook(requestParameters: CodebookApiCodebookRequest, options?: AxiosRequestConfig) {
        return CodebookApiFp(this.configuration).codebook(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }
}

export type CodebookErrorTypes =

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CodebookErrorCodes = [
    422,
];


