import { useEffect } from 'react';

import { useUserContextStore } from '../../auth/user/context';

// e2e tests helper that sets data attribute on body to indicate if user is logged in
export const useUserLoggedInDataAttribute = () => {
  const user = useUserContextStore(store => store.user);

  useEffect(() => {
    const USER_LOGGED_IN_DATA_ATTRIBUTE = 'data-user-logged-in';
    if (!user) {
      document.body.removeAttribute(USER_LOGGED_IN_DATA_ATTRIBUTE);
    } else {
      document.body.setAttribute(USER_LOGGED_IN_DATA_ATTRIBUTE, '');
    }
  }, [user]);
};
