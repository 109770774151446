/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  BuyerSurveyApi,
  BuyerSurveyListErrorCodes,
  BuyerSurveyListErrorTypes,
  BuyerSurveyCreateErrorCodes,
  BuyerSurveyShowErrorCodes,
  BuyerSurveyShowErrorTypes,
  BuyerSurveyUpdateErrorCodes,
  BuyerSurveyDestroyErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new BuyerSurveyApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type BuyerSurveyListMethod = BuyerSurveyApi['buyerSurveyList'];
export type BuyerSurveyListResponseType = MethodResult<BuyerSurveyListMethod>;

export const useBuyerSurveyList = (
  variables: Parameters<BuyerSurveyListMethod>[0],
  config?: UseQueryConfig<
    BuyerSurveyListMethod,
    BuyerSurveyListErrorTypes
  >
) => {
  return useQuery<
    BuyerSurveyListMethod,
    BuyerSurveyListErrorTypes
  >(
    (...args) => api.buyerSurveyList(...args),
    BuyerSurveyListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BuyerSurveyList'],
        ...apiQueryConfig.BuyerSurveyList,
        ...config?.queryConfig
      },
    },
    'BuyerSurveyList',
  );
};

export const useBuyerSurveyListInfinite = (
  variables: Parameters<BuyerSurveyListMethod>[0],
  config?: UseInfiniteQueryConfig<
    BuyerSurveyListMethod,
    BuyerSurveyListErrorTypes
  >
) => {
  return useInfiniteQuery<
    BuyerSurveyListMethod,
    BuyerSurveyListErrorTypes
  >(
    (...args) => api.buyerSurveyList(...args),
    BuyerSurveyListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BuyerSurveyList', 'BuyerSurveyListInfinite'],
        ...apiQueryConfig.BuyerSurveyList,
        ...config?.queryConfig,
      },
    },
    'BuyerSurveyList',
  );
};

export const useBuyerSurveyListLazy = (baseOptions?: {
  variables?: Parameters<BuyerSurveyListMethod>[0],
  config?: UseQueryConfig<
    BuyerSurveyListMethod,
    BuyerSurveyListErrorTypes
  >
}) => {
  return useLazyQuery<
    BuyerSurveyListMethod,
    BuyerSurveyListErrorTypes
  >(
    (...args) => api.buyerSurveyList(...args),
    BuyerSurveyListErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BuyerSurveyList'],
        ...apiQueryConfig.BuyerSurveyList,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BuyerSurveyList',
  );
};


export type BuyerSurveyCreateMethod = BuyerSurveyApi['buyerSurveyCreate'];

export const useBuyerSurveyCreate = () => {
  return useMutation<BuyerSurveyCreateMethod>(
    (...args) => api.buyerSurveyCreate(...args),
    BuyerSurveyCreateErrorCodes,
    'BuyerSurveyCreate',
    '/api/accounts/{account_id}/buyer-surveys',
    'post'
  );
};

export type BuyerSurveyShowMethod = BuyerSurveyApi['buyerSurveyShow'];
export type BuyerSurveyShowResponseType = MethodResult<BuyerSurveyShowMethod>;

export const useBuyerSurveyShow = (
  variables: Parameters<BuyerSurveyShowMethod>[0],
  config?: UseQueryConfig<
    BuyerSurveyShowMethod,
    BuyerSurveyShowErrorTypes
  >
) => {
  return useQuery<
    BuyerSurveyShowMethod,
    BuyerSurveyShowErrorTypes
  >(
    (...args) => api.buyerSurveyShow(...args),
    BuyerSurveyShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BuyerSurveyShow'],
        ...apiQueryConfig.BuyerSurveyShow,
        ...config?.queryConfig
      },
    },
    'BuyerSurveyShow',
  );
};

export const useBuyerSurveyShowInfinite = (
  variables: Parameters<BuyerSurveyShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    BuyerSurveyShowMethod,
    BuyerSurveyShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    BuyerSurveyShowMethod,
    BuyerSurveyShowErrorTypes
  >(
    (...args) => api.buyerSurveyShow(...args),
    BuyerSurveyShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BuyerSurveyShow', 'BuyerSurveyShowInfinite'],
        ...apiQueryConfig.BuyerSurveyShow,
        ...config?.queryConfig,
      },
    },
    'BuyerSurveyShow',
  );
};

export const useBuyerSurveyShowLazy = (baseOptions?: {
  variables?: Parameters<BuyerSurveyShowMethod>[0],
  config?: UseQueryConfig<
    BuyerSurveyShowMethod,
    BuyerSurveyShowErrorTypes
  >
}) => {
  return useLazyQuery<
    BuyerSurveyShowMethod,
    BuyerSurveyShowErrorTypes
  >(
    (...args) => api.buyerSurveyShow(...args),
    BuyerSurveyShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BuyerSurveyShow'],
        ...apiQueryConfig.BuyerSurveyShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BuyerSurveyShow',
  );
};


export type BuyerSurveyUpdateMethod = BuyerSurveyApi['buyerSurveyUpdate'];

export const useBuyerSurveyUpdate = () => {
  return useMutation<BuyerSurveyUpdateMethod>(
    (...args) => api.buyerSurveyUpdate(...args),
    BuyerSurveyUpdateErrorCodes,
    'BuyerSurveyUpdate',
    '/api/accounts/{account_id}/buyer-surveys/{id}',
    'put'
  );
};

export type BuyerSurveyDestroyMethod = BuyerSurveyApi['buyerSurveyDestroy'];

export const useBuyerSurveyDestroy = () => {
  return useMutation<BuyerSurveyDestroyMethod>(
    (...args) => api.buyerSurveyDestroy(...args),
    BuyerSurveyDestroyErrorCodes,
    'BuyerSurveyDestroy',
    '/api/accounts/{account_id}/buyer-surveys/{id}',
    'delete'
  );
};
