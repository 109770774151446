import React from 'react';
import { Form } from 'antd';
import { useTheme } from 'styled-components';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Grid } from '@npm/core/ui/components/atoms/Grid';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import {
  type StepperItemVariant,
  StepperItem,
} from '@npm/core/ui/components/atoms/StepperItem';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  CbAccountType,
  useAccountOnboardingStatusShow,
} from '@npm/data-access';
import { type UserStore } from '@npm/features/auth/user/context/store/user/user.store';

import { type OptInFormValues } from '../../OptInEntitySelection/OptInEntitySelection.types';

import { type Step, stepsEntity, stepsIndividual } from './TransferList.types';

interface Props {
  user: UserStore['user'];
}

const entityType = CbAccountType.items.OrganizationAccount;
const individualType = CbAccountType.items.PersonAccount;

export const TransferList = ({ user }: Props) => {
  const theme = useTheme();
  const { isMobile } = useBreakpoints();
  const form = Form.useFormInstance<OptInFormValues>();
  const { accountType, selectedEntity } = form.getFieldsValue([
    'accountType',
    'selectedEntity',
  ]);

  const { data: onboardingStatus, isLoading: isOnboardingLoading } =
    useAccountOnboardingStatusShow({
      id:
        accountType === entityType ? selectedEntity : user?.person?.account_id,
    });

  const computeCopyStatus = (codebookName?: string): StepperItemVariant => {
    if (!codebookName) {
      return 'done';
    }
    if (!onboardingStatus) {
      return 'default';
    }
    const statusObject = onboardingStatus[codebookName];
    if (statusObject && statusObject.code === 'completed') {
      return 'done';
    }
    return 'waiting';
  };

  let steps: Step[];

  if (accountType === entityType) {
    steps = stepsEntity;
  }

  if (accountType === individualType) {
    steps = stepsIndividual;
  }

  if (isOnboardingLoading) {
    return <Skeleton.Base />;
  }

  return (
    <>
      <Text
        marginBottom={theme.spacing.sm}
        color="general"
        size="sm"
        style={{ marginTop: theme.spacing.lg }}
      >
        {accountType === entityType
          ? 'What we are going to transfer'
          : 'We are going to use the following data-points:'}
      </Text>

      <Grid cols={{ default: { count: isMobile ? 1 : 2, size: '1fr' } }}>
        {steps.map(step => (
          <StepperItem
            title={step.label}
            variant={computeCopyStatus(step.codebookName)}
            size="sm"
            isActive={false}
            tooltip={step.tooltip}
            key={step.label}
          />
        ))}
      </Grid>

      <Margin bottom={'lg'} />
    </>
  );
};
