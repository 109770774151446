import { usePathMatch } from './usePathMatch';

export const useParams = <
  Params extends { [K in keyof Params]?: string }
>() => {
  const match = usePathMatch<Params>();
  return (match?.params ?? {}) as Partial<Params>;
};

export class ParamNotFoundError extends Error {
  status: number;

  constructor(message: string) {
    super(message);
    this.status = 404;
  }
}
