import React from 'react';
import { type ValidateStatus } from 'antd/es/form/FormItem';
import axios from 'axios';

import { CbBankAccountType } from '@npm/data-access';

import { type CbBankAccountItemCode } from '../BankAccountInformation.types';

import { BrokerageFormFields } from './BrokerageFormFields';
import { DomesticFormFields } from './DomesticFormFields';
import { InternationalFormFields } from './InternationalFormFields';

export const getAccountTypeFormFields = (
  bankType: CbBankAccountItemCode,
  onAbaNumberValidStatusChange: (status: ValidateStatus) => void,
  allowAlternateBeneficiary = false
) => {
  if (bankType === undefined) {
    return null;
  }

  const typeItems = CbBankAccountType.items;

  switch (bankType) {
    case typeItems.DOMESTIC_BANK:
    case typeItems.DOMESTIC_CREDIT_UNION:
      return (
        <DomesticFormFields
          allowAlternateBeneficiary={allowAlternateBeneficiary}
          onAbaNumberValidStatusChange={onAbaNumberValidStatusChange}
        />
      );
    case typeItems.INTERNATIONAL_BANK:
      return (
        <InternationalFormFields
          allowAlternateBeneficiary={allowAlternateBeneficiary}
        />
      );
    case typeItems.BROKERAGE_ACCOUNT:
      return (
        <BrokerageFormFields
          allowAlternateBeneficiary={allowAlternateBeneficiary}
          onAbaNumberValidStatusChange={onAbaNumberValidStatusChange}
        />
      );
    default:
      return null;
  }
};

export const fetchBankNameByAbaNumber = async (
  abaNumber: string
): Promise<{ name: string }> => {
  const response = await axios(
    `https://www.routingnumbers.info/api/name.json?rn=${abaNumber}`
  );
  return response.data;
};
