import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {} as const;

type AttributeName =
  | 'active'
  | 'answer_set'
  | 'name'
  | 'question'
  | 'entity'
  | 'individual'
  | 'help'
  | 'help_link'
  | 'disable';

export const CbAccreditationLabel = {
  code: Codebooks.ACCREDITATION_LABEL,
  items: Items,
  getAttributeValueByName: (item: CodebookItem, name: AttributeName) =>
    item.attributes?.find(q => q.name === name)?.value,
};
