/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BrokerageFirmsUserIndex } from '../model';
// @ts-ignore
import { DateRangeContract } from '../model';
// @ts-ignore
import { ErrorValidation } from '../model';
// @ts-ignore
import { OktaServiceCreate } from '../model';
// @ts-ignore
import { OktaServiceCreateRequestContract } from '../model';
// @ts-ignore
import { OktaServiceShow } from '../model';
// @ts-ignore
import { OktaServiceShowRequestContract } from '../model';
// @ts-ignore
import { User } from '../model';
// @ts-ignore
import { UserCreateRequestContract } from '../model';
// @ts-ignore
import { UserUpdateRequestContract } from '../model';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary lists all users in the specified brokerage firm
         * @param {number} brokerageFirmId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {Array<string>} [userStatus] 
         * @param {DateRangeContract} [lastLoginRange] 
         * @param {Array<string>} [roleType] 
         * @param {Array<string>} [subscriptionPlan] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerageFirmUserIndex: async (brokerageFirmId: number, page?: number, size?: number, search?: string, userStatus?: Array<string>, lastLoginRange?: DateRangeContract, roleType?: Array<string>, subscriptionPlan?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brokerageFirmId' is not null or undefined
            assertParamExists('brokerageFirmUserIndex', 'brokerageFirmId', brokerageFirmId)
            const localVarPath = changeUrl(`/api/brokerage-firms/{brokerage_firm_id}/users`)
                .replace(`{${"brokerage_firm_id"}}`, encodeURIComponent(String(brokerageFirmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (userStatus) {
                localVarQueryParameter['user_status'] = userStatus.join(COLLECTION_FORMATS.csv);
            }

            if (lastLoginRange !== undefined) {
                localVarQueryParameter['last_login_range'] = lastLoginRange;
            }

            if (roleType) {
                localVarQueryParameter['role_type'] = roleType.join(COLLECTION_FORMATS.csv);
            }

            if (subscriptionPlan) {
                localVarQueryParameter['subscription_plan'] = subscriptionPlan.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User downloads only their own login history csv
         * @param {Array<string>} [loginType] 
         * @param {number} [timezoneOffset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginHistoryShow: async (loginType?: Array<string>, timezoneOffset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/user/login-history`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (loginType) {
                localVarQueryParameter['login_type'] = loginType;
            }

            if (timezoneOffset !== undefined) {
                localVarQueryParameter['timezone_offset'] = timezoneOffset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary brokerage administrator downloads given user login history
         * @param {number} userId 
         * @param {Array<string>} [loginType] 
         * @param {number} [timezoneOffset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginHistoryShowUser: async (userId: number, loginType?: Array<string>, timezoneOffset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('loginHistoryShowUser', 'userId', userId)
            const localVarPath = changeUrl(`/api/user/{user_id}/login-history`)
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (loginType) {
                localVarQueryParameter['login_type'] = loginType.join(COLLECTION_FORMATS.csv);
            }

            if (timezoneOffset !== undefined) {
                localVarQueryParameter['timezone_offset'] = timezoneOffset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary triggers user activation email
         * @param {OktaServiceCreateRequestContract} oktaServiceCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oktaUserActivate: async (oktaServiceCreateRequestContract: OktaServiceCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oktaServiceCreateRequestContract' is not null or undefined
            assertParamExists('oktaUserActivate', 'oktaServiceCreateRequestContract', oktaServiceCreateRequestContract)
            const localVarPath = changeUrl(`/api/user/activate`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oktaServiceCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns user activation url
         * @param {OktaServiceShowRequestContract} oktaServiceShowRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oktaUserVerify: async (oktaServiceShowRequestContract: OktaServiceShowRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oktaServiceShowRequestContract' is not null or undefined
            assertParamExists('oktaUserVerify', 'oktaServiceShowRequestContract', oktaServiceShowRequestContract)
            const localVarPath = changeUrl(`/api/user/verify`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oktaServiceShowRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates a new user
         * @param {UserCreateRequestContract} userCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreate: async (userCreateRequestContract: UserCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateRequestContract' is not null or undefined
            assertParamExists('userCreate', 'userCreateRequestContract', userCreateRequestContract)
            const localVarPath = changeUrl(`/api/users`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns current active user details
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userShowCurrent: async (xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/user`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the user specified in the path
         * @param {number} id 
         * @param {UserUpdateRequestContract} userUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate: async (id: number, userUpdateRequestContract: UserUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userUpdate', 'id', id)
            // verify required parameter 'userUpdateRequestContract' is not null or undefined
            assertParamExists('userUpdate', 'userUpdateRequestContract', userUpdateRequestContract)
            const localVarPath = changeUrl(`/api/users/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary lists all users in the specified brokerage firm
         * @param {number} brokerageFirmId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {Array<string>} [userStatus] 
         * @param {DateRangeContract} [lastLoginRange] 
         * @param {Array<string>} [roleType] 
         * @param {Array<string>} [subscriptionPlan] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerageFirmUserIndex(brokerageFirmId: number, page?: number, size?: number, search?: string, userStatus?: Array<string>, lastLoginRange?: DateRangeContract, roleType?: Array<string>, subscriptionPlan?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrokerageFirmsUserIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerageFirmUserIndex(brokerageFirmId, page, size, search, userStatus, lastLoginRange, roleType, subscriptionPlan, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User downloads only their own login history csv
         * @param {Array<string>} [loginType] 
         * @param {number} [timezoneOffset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginHistoryShow(loginType?: Array<string>, timezoneOffset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginHistoryShow(loginType, timezoneOffset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary brokerage administrator downloads given user login history
         * @param {number} userId 
         * @param {Array<string>} [loginType] 
         * @param {number} [timezoneOffset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginHistoryShowUser(userId: number, loginType?: Array<string>, timezoneOffset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginHistoryShowUser(userId, loginType, timezoneOffset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary triggers user activation email
         * @param {OktaServiceCreateRequestContract} oktaServiceCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oktaUserActivate(oktaServiceCreateRequestContract: OktaServiceCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OktaServiceCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oktaUserActivate(oktaServiceCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns user activation url
         * @param {OktaServiceShowRequestContract} oktaServiceShowRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oktaUserVerify(oktaServiceShowRequestContract: OktaServiceShowRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OktaServiceShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oktaUserVerify(oktaServiceShowRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates a new user
         * @param {UserCreateRequestContract} userCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCreate(userCreateRequestContract: UserCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCreate(userCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns current active user details
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userShowCurrent(xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userShowCurrent(xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the user specified in the path
         * @param {number} id 
         * @param {UserUpdateRequestContract} userUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdate(id: number, userUpdateRequestContract: UserUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdate(id, userUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary lists all users in the specified brokerage firm
         * @param {number} brokerageFirmId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {Array<string>} [userStatus] 
         * @param {DateRangeContract} [lastLoginRange] 
         * @param {Array<string>} [roleType] 
         * @param {Array<string>} [subscriptionPlan] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerageFirmUserIndex(brokerageFirmId: number, page?: number, size?: number, search?: string, userStatus?: Array<string>, lastLoginRange?: DateRangeContract, roleType?: Array<string>, subscriptionPlan?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<BrokerageFirmsUserIndex> {
            return localVarFp.brokerageFirmUserIndex(brokerageFirmId, page, size, search, userStatus, lastLoginRange, roleType, subscriptionPlan, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User downloads only their own login history csv
         * @param {Array<string>} [loginType] 
         * @param {number} [timezoneOffset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginHistoryShow(loginType?: Array<string>, timezoneOffset?: number, options?: any): AxiosPromise<void> {
            return localVarFp.loginHistoryShow(loginType, timezoneOffset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary brokerage administrator downloads given user login history
         * @param {number} userId 
         * @param {Array<string>} [loginType] 
         * @param {number} [timezoneOffset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginHistoryShowUser(userId: number, loginType?: Array<string>, timezoneOffset?: number, options?: any): AxiosPromise<void> {
            return localVarFp.loginHistoryShowUser(userId, loginType, timezoneOffset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary triggers user activation email
         * @param {OktaServiceCreateRequestContract} oktaServiceCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oktaUserActivate(oktaServiceCreateRequestContract: OktaServiceCreateRequestContract, options?: any): AxiosPromise<OktaServiceCreate> {
            return localVarFp.oktaUserActivate(oktaServiceCreateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns user activation url
         * @param {OktaServiceShowRequestContract} oktaServiceShowRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oktaUserVerify(oktaServiceShowRequestContract: OktaServiceShowRequestContract, options?: any): AxiosPromise<OktaServiceShow> {
            return localVarFp.oktaUserVerify(oktaServiceShowRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates a new user
         * @param {UserCreateRequestContract} userCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreate(userCreateRequestContract: UserCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<User> {
            return localVarFp.userCreate(userCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns current active user details
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userShowCurrent(xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<User> {
            return localVarFp.userShowCurrent(xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the user specified in the path
         * @param {number} id 
         * @param {UserUpdateRequestContract} userUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate(id: number, userUpdateRequestContract: UserUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<User> {
            return localVarFp.userUpdate(id, userUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for brokerageFirmUserIndex operation in UserApi.
 * @export
 * @interface UserApiBrokerageFirmUserIndexRequest
 */
export interface UserApiBrokerageFirmUserIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiBrokerageFirmUserIndex
     */
    readonly brokerageFirmId: number

    /**
     * 
     * @type {number}
     * @memberof UserApiBrokerageFirmUserIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof UserApiBrokerageFirmUserIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof UserApiBrokerageFirmUserIndex
     */
    readonly search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof UserApiBrokerageFirmUserIndex
     */
    readonly userStatus?: Array<string>

    /**
     * 
     * @type {DateRangeContract}
     * @memberof UserApiBrokerageFirmUserIndex
     */
    readonly lastLoginRange?: DateRangeContract

    /**
     * 
     * @type {Array<string>}
     * @memberof UserApiBrokerageFirmUserIndex
     */
    readonly roleType?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof UserApiBrokerageFirmUserIndex
     */
    readonly subscriptionPlan?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof UserApiBrokerageFirmUserIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiBrokerageFirmUserIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiBrokerageFirmUserIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiBrokerageFirmUserIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiBrokerageFirmUserIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiBrokerageFirmUserIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for loginHistoryShow operation in UserApi.
 * @export
 * @interface UserApiLoginHistoryShowRequest
 */
export interface UserApiLoginHistoryShowRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserApiLoginHistoryShow
     */
    readonly loginType?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof UserApiLoginHistoryShow
     */
    readonly timezoneOffset?: number
}

/**
 * Request parameters for loginHistoryShowUser operation in UserApi.
 * @export
 * @interface UserApiLoginHistoryShowUserRequest
 */
export interface UserApiLoginHistoryShowUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiLoginHistoryShowUser
     */
    readonly userId: number

    /**
     * 
     * @type {Array<string>}
     * @memberof UserApiLoginHistoryShowUser
     */
    readonly loginType?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof UserApiLoginHistoryShowUser
     */
    readonly timezoneOffset?: number
}

/**
 * Request parameters for oktaUserActivate operation in UserApi.
 * @export
 * @interface UserApiOktaUserActivateRequest
 */
export interface UserApiOktaUserActivateRequest {
    /**
     * 
     * @type {OktaServiceCreateRequestContract}
     * @memberof UserApiOktaUserActivate
     */
    readonly oktaServiceCreateRequestContract: OktaServiceCreateRequestContract
}

/**
 * Request parameters for oktaUserVerify operation in UserApi.
 * @export
 * @interface UserApiOktaUserVerifyRequest
 */
export interface UserApiOktaUserVerifyRequest {
    /**
     * 
     * @type {OktaServiceShowRequestContract}
     * @memberof UserApiOktaUserVerify
     */
    readonly oktaServiceShowRequestContract: OktaServiceShowRequestContract
}

/**
 * Request parameters for userCreate operation in UserApi.
 * @export
 * @interface UserApiUserCreateRequest
 */
export interface UserApiUserCreateRequest {
    /**
     * 
     * @type {UserCreateRequestContract}
     * @memberof UserApiUserCreate
     */
    readonly userCreateRequestContract: UserCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof UserApiUserCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for userShowCurrent operation in UserApi.
 * @export
 * @interface UserApiUserShowCurrentRequest
 */
export interface UserApiUserShowCurrentRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUserShowCurrent
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserShowCurrent
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserShowCurrent
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserShowCurrent
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserShowCurrent
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserShowCurrent
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for userUpdate operation in UserApi.
 * @export
 * @interface UserApiUserUpdateRequest
 */
export interface UserApiUserUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUserUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UserUpdateRequestContract}
     * @memberof UserApiUserUpdate
     */
    readonly userUpdateRequestContract: UserUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof UserApiUserUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary lists all users in the specified brokerage firm
     * @param {UserApiBrokerageFirmUserIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public brokerageFirmUserIndex(requestParameters: UserApiBrokerageFirmUserIndexRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).brokerageFirmUserIndex(requestParameters.brokerageFirmId, requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.userStatus, requestParameters.lastLoginRange, requestParameters.roleType, requestParameters.subscriptionPlan, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User downloads only their own login history csv
     * @param {UserApiLoginHistoryShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public loginHistoryShow(requestParameters: UserApiLoginHistoryShowRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).loginHistoryShow(requestParameters.loginType, requestParameters.timezoneOffset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary brokerage administrator downloads given user login history
     * @param {UserApiLoginHistoryShowUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public loginHistoryShowUser(requestParameters: UserApiLoginHistoryShowUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).loginHistoryShowUser(requestParameters.userId, requestParameters.loginType, requestParameters.timezoneOffset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary triggers user activation email
     * @param {UserApiOktaUserActivateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public oktaUserActivate(requestParameters: UserApiOktaUserActivateRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).oktaUserActivate(requestParameters.oktaServiceCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns user activation url
     * @param {UserApiOktaUserVerifyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public oktaUserVerify(requestParameters: UserApiOktaUserVerifyRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).oktaUserVerify(requestParameters.oktaServiceShowRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates a new user
     * @param {UserApiUserCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCreate(requestParameters: UserApiUserCreateRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userCreate(requestParameters.userCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns current active user details
     * @param {UserApiUserShowCurrentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userShowCurrent(requestParameters: UserApiUserShowCurrentRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userShowCurrent(requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the user specified in the path
     * @param {UserApiUserUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdate(requestParameters: UserApiUserUpdateRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdate(requestParameters.id, requestParameters.userUpdateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type BrokerageFirmUserIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BrokerageFirmUserIndexErrorCodes = [
    401,
    422,
];

export type LoginHistoryShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const LoginHistoryShowErrorCodes = [
];

export type LoginHistoryShowUserErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const LoginHistoryShowUserErrorCodes = [
];

export type OktaUserActivateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const OktaUserActivateErrorCodes = [
];

export type OktaUserVerifyErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const OktaUserVerifyErrorCodes = [
];

export type UserCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const UserCreateErrorCodes = [
    401,
];

export type UserShowCurrentErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 500;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const UserShowCurrentErrorCodes = [
    401,
    500,
];

export type UserUpdateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const UserUpdateErrorCodes = [
    401,
];


