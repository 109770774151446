import { useMemo } from 'react';

import { useUserRole } from '../context/userRole.context';

export const useCurrentWorkstation = () => {
  const userRole = useUserRole();
  return useMemo(() => {
    return userRole?.workstation || null;
  }, [userRole]);
};
