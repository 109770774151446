import { type FormInstance } from 'antd';
import { type ValidateErrorEntity } from 'rc-field-form/lib/interface';

type ApiValidationError = {
  data: {
    error_code: string;
    error_fields: {
      field_name: string;
      field_error_messages: string[];
    }[];
  };
};

export const handleValidationError = (
  form: FormInstance,
  error: ValidateErrorEntity,
  scrollToFirstError = true,
  scrollMode?: 'always' | 'if-needed'
) => {
  console.warn('Validation failed:', error);
  if (!scrollToFirstError) return;
  const firstErrorFieldName = error?.errorFields?.[0]?.name[0];
  if (firstErrorFieldName) {
    form.scrollToField(firstErrorFieldName, {
      behavior: 'smooth',
      block: 'center',
      scrollMode: scrollMode,
    });
  }
};

export const handleApiValidationError = (
  form: FormInstance,
  error: ApiValidationError
) => {
  if (error?.data?.error_code === 'validation_error') {
    const errorFields = error.data.error_fields;
    const fieldNames = errorFields?.map(field => field.field_name);
    const fieldErrorMessages = errorFields?.flatMap(
      field => field.field_error_messages
    );

    if (fieldNames?.length) {
      form.setFields([{ name: fieldNames, errors: fieldErrorMessages }]);
      if (fieldNames?.[0]) {
        form.scrollToField(fieldNames[0], {
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }
};
