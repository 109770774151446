import React, { useEffect, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Helmet as ReactHelmet } from 'react-helmet';

import { APP_CFG_METADATA_IN_BROWSER_TITLE } from '@npm/config';

import { usePathMetadata } from '../../../services/pathMetadata';

import { useHelmetStore } from './Helmet.store';

type Props = {
  pageTitle: string | null;
  children?: React.ReactNode;
};

export const Helmet = ({ children, pageTitle }: Props) => {
  const pathMetadata = usePathMetadata();
  const extraValues = useHelmetStore(s => s.extraValues);
  const setPageTitle = useHelmetStore(s => s.setPageTitle);

  useEffect(() => {
    setPageTitle(pageTitle);
  }, [pageTitle]);

  const extra = useMemo(() => {
    return Object.values(extraValues).join(' ');
  }, [extraValues]);

  if (APP_CFG_METADATA_IN_BROWSER_TITLE !== 'true') {
    return (
      <ReactHelmet>
        <title>{pageTitle ?? 'Nasdaq Private Market'}</title>
        {children}
      </ReactHelmet>
    );
  }

  return (
    <ReactHelmet>
      <title>
        {`NPM ${pathMetadata.screenId} [${
          pageTitle ?? pathMetadata.screenName
        }] ${extra}`}
      </title>
    </ReactHelmet>
  );
};
