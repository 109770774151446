import {
  type CbLabelComponent,
  type IconNames,
  type LabelVariant,
} from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const items = {
  soft_ioi: 'soft_ioi',
  ioi: 'ioi',
  firm: 'firm',
} as const;

export type OrderEntryTypeCode = keyof typeof items;

const labelMap: Record<OrderEntryTypeCode, LabelVariant> = {
  soft_ioi: 'general',
  ioi: 'info',
  firm: 'info',
};

const iconMap: Record<OrderEntryTypeCode, IconNames> = {
  soft_ioi: 'alert-triangle',
  ioi: 'circle-check',
  firm: 'circle-check-filled',
};

export const CbOrderEntryType = {
  code: Codebooks.ORDER_ENTRY_TYPE,
  items,
  getLabel: (Component: CbLabelComponent, codebookItem: CodebookItem) =>
    Component({
      variant: labelMap[codebookItem?.code ?? 'soft_ioi'],
      label: codebookItem?.name,
      iconProps: { name: iconMap[codebookItem?.code ?? 'soft_ioi'] },
      round: true,
    }),
};
