import styled from 'styled-components';

import { ellipsisTextOverflow, resetButtonStyles } from '../../../utils/style';
import { Flex } from '../common';
import { SmallTextBoldVariationSettings, Text } from '../Typography';

import { type StepperItemSize, type StepperItemVariant } from './StepperItem';

const STEPPER_ITEM_HEIGHT = 38;

export const StepperItem = styled.button<{
  $variant: StepperItemVariant;
  $size: StepperItemSize;
  $isActive: boolean;
  $isExpanded: boolean;
  $isClickable: boolean;
  $hasDescription: boolean;
}>`
  ${resetButtonStyles}
  width: 100%;
  overflow: hidden;
  min-height: ${STEPPER_ITEM_HEIGHT}px;
  padding: ${({ theme, $size, $hasDescription }) =>
    $size === 'sm'
      ? `${$hasDescription ? theme.spacing.sm + 'px' : 0} ${theme.spacing.sm}px`
      : `${theme.spacing.md}px`};
  color: ${({ theme }) => theme.color.general.typography.primary};
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  text-align: left;
  transition: all 0.2s;

  ${({ theme, $isActive }) =>
    $isActive && `background-color: ${theme.color.general.layout.two};`};

  ${({ theme, $isClickable }) =>
    $isClickable &&
    `
    &:hover {
      background-color: ${theme.color.info.backgrounds.secondaryHover};
    }
  `}
`;

export const TitleSm = styled(Text)`
  max-width: 100%;
  ${ellipsisTextOverflow}
`;

export const ExtraWrapper = styled.div<{
  $variant: StepperItemVariant;
  $isActive: boolean;
}>`
  flex-shrink: 0;
  margin-top: 1px;

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }

  color: ${({ $variant, $isActive, theme }) => {
    if (($variant === 'done' || $isActive) && $variant !== 'error') {
      return theme.color.info.typography.primary;
    } else if ($variant === 'default') {
      return theme.color.general.typography.tertiary;
    } else if ($variant === 'error') {
      return theme.color.error.typography.primary;
    } else {
      return 'inherit';
    }
  }};

  ${({ $isActive }) => $isActive && SmallTextBoldVariationSettings}
`;

export const TooltipIconWrapper = styled(Flex)`
  margin-left: ${({ theme }) => theme.spacing.xs}px;
`;
