/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  TasksApi,
  TaskListErrorCodes,
  TaskListErrorTypes,
  TaskCreateErrorCodes,
  TaskShowErrorCodes,
  TaskShowErrorTypes,
  TaskSubmitResultsErrorCodes,
  TaskSummaryErrorCodes,
  TaskSummaryErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new TasksApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type TaskListMethod = TasksApi['taskList'];
export type TaskListResponseType = MethodResult<TaskListMethod>;

export const useTaskList = (
  variables: Parameters<TaskListMethod>[0],
  config?: UseQueryConfig<
    TaskListMethod,
    TaskListErrorTypes
  >
) => {
  return useQuery<
    TaskListMethod,
    TaskListErrorTypes
  >(
    (...args) => api.taskList(...args),
    TaskListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TaskList'],
        ...apiQueryConfig.TaskList,
        ...config?.queryConfig
      },
    },
    'TaskList',
  );
};

export const useTaskListInfinite = (
  variables: Parameters<TaskListMethod>[0],
  config?: UseInfiniteQueryConfig<
    TaskListMethod,
    TaskListErrorTypes
  >
) => {
  return useInfiniteQuery<
    TaskListMethod,
    TaskListErrorTypes
  >(
    (...args) => api.taskList(...args),
    TaskListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TaskList', 'TaskListInfinite'],
        ...apiQueryConfig.TaskList,
        ...config?.queryConfig,
      },
    },
    'TaskList',
  );
};

export const useTaskListLazy = (baseOptions?: {
  variables?: Parameters<TaskListMethod>[0],
  config?: UseQueryConfig<
    TaskListMethod,
    TaskListErrorTypes
  >
}) => {
  return useLazyQuery<
    TaskListMethod,
    TaskListErrorTypes
  >(
    (...args) => api.taskList(...args),
    TaskListErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['TaskList'],
        ...apiQueryConfig.TaskList,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'TaskList',
  );
};


export type TaskCreateMethod = TasksApi['taskCreate'];

export const useTaskCreate = () => {
  return useMutation<TaskCreateMethod>(
    (...args) => api.taskCreate(...args),
    TaskCreateErrorCodes,
    'TaskCreate',
    '/api/tasks/',
    'post'
  );
};

export type TaskShowMethod = TasksApi['taskShow'];
export type TaskShowResponseType = MethodResult<TaskShowMethod>;

export const useTaskShow = (
  variables: Parameters<TaskShowMethod>[0],
  config?: UseQueryConfig<
    TaskShowMethod,
    TaskShowErrorTypes
  >
) => {
  return useQuery<
    TaskShowMethod,
    TaskShowErrorTypes
  >(
    (...args) => api.taskShow(...args),
    TaskShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TaskShow'],
        ...apiQueryConfig.TaskShow,
        ...config?.queryConfig
      },
    },
    'TaskShow',
  );
};

export const useTaskShowInfinite = (
  variables: Parameters<TaskShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    TaskShowMethod,
    TaskShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    TaskShowMethod,
    TaskShowErrorTypes
  >(
    (...args) => api.taskShow(...args),
    TaskShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TaskShow', 'TaskShowInfinite'],
        ...apiQueryConfig.TaskShow,
        ...config?.queryConfig,
      },
    },
    'TaskShow',
  );
};

export const useTaskShowLazy = (baseOptions?: {
  variables?: Parameters<TaskShowMethod>[0],
  config?: UseQueryConfig<
    TaskShowMethod,
    TaskShowErrorTypes
  >
}) => {
  return useLazyQuery<
    TaskShowMethod,
    TaskShowErrorTypes
  >(
    (...args) => api.taskShow(...args),
    TaskShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['TaskShow'],
        ...apiQueryConfig.TaskShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'TaskShow',
  );
};


export type TaskSubmitResultsMethod = TasksApi['taskSubmitResults'];

export const useTaskSubmitResults = () => {
  return useMutation<TaskSubmitResultsMethod>(
    (...args) => api.taskSubmitResults(...args),
    TaskSubmitResultsErrorCodes,
    'TaskSubmitResults',
    '/api/tasks/{task_id}/submit_result',
    'post'
  );
};

export type TaskSummaryMethod = TasksApi['taskSummary'];
export type TaskSummaryResponseType = MethodResult<TaskSummaryMethod>;

export const useTaskSummary = (
  variables: Parameters<TaskSummaryMethod>[0],
  config?: UseQueryConfig<
    TaskSummaryMethod,
    TaskSummaryErrorTypes
  >
) => {
  return useQuery<
    TaskSummaryMethod,
    TaskSummaryErrorTypes
  >(
    (...args) => api.taskSummary(...args),
    TaskSummaryErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TaskSummary'],
        ...apiQueryConfig.TaskSummary,
        ...config?.queryConfig
      },
    },
    'TaskSummary',
  );
};

export const useTaskSummaryInfinite = (
  variables: Parameters<TaskSummaryMethod>[0],
  config?: UseInfiniteQueryConfig<
    TaskSummaryMethod,
    TaskSummaryErrorTypes
  >
) => {
  return useInfiniteQuery<
    TaskSummaryMethod,
    TaskSummaryErrorTypes
  >(
    (...args) => api.taskSummary(...args),
    TaskSummaryErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TaskSummary', 'TaskSummaryInfinite'],
        ...apiQueryConfig.TaskSummary,
        ...config?.queryConfig,
      },
    },
    'TaskSummary',
  );
};

export const useTaskSummaryLazy = (baseOptions?: {
  variables?: Parameters<TaskSummaryMethod>[0],
  config?: UseQueryConfig<
    TaskSummaryMethod,
    TaskSummaryErrorTypes
  >
}) => {
  return useLazyQuery<
    TaskSummaryMethod,
    TaskSummaryErrorTypes
  >(
    (...args) => api.taskSummary(...args),
    TaskSummaryErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['TaskSummary'],
        ...apiQueryConfig.TaskSummary,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'TaskSummary',
  );
};

