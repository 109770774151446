import styled from 'styled-components';

import { Card } from '@npm/core/ui/components/molecules/Card';

export const ConfirmWrapper = styled.div<{ $offsetBottom: number }>`
  position: absolute;
  bottom: ${({ $offsetBottom }) => $offsetBottom}px;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  left: 0px;
  right: 0px;
  box-sizing: border-box;
  margin: ${({ theme }) => theme.spacing.lg}px;
`;

export const StyledCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.color.general.borders.secondary};
  box-shadow: ${({ theme }) => theme.shadow.level3};
`;
