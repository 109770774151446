import { Codebooks } from './codebooks.types';

const Items = {
  none: 'none',
  no_owner: 'no_owner',
  owner_charity_non_profit: 'owner_charity_non_profit',
  owner_pooled_investment_vehicle: 'owner_pooled_investment_vehicle',
} as const;

export const CbBeneficialOwnerExemption = {
  code: Codebooks.BENEFICIAL_OWNER_EXEMPTION,
  items: Items,
};
