/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountsSpouseUpdateRequestContract } from '../model';
// @ts-ignore
import { Signer } from '../model';
// @ts-ignore
import { SpouseUpdate } from '../model';
/**
 * SpouseApi - axios parameter creator
 * @export
 */
export const SpouseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary show spouse person on the specified account
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSpouseShow: async (accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountSpouseShow', 'accountId', accountId)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/spouse`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates spouse on the account
         * @param {number} accountId 
         * @param {AccountsSpouseUpdateRequestContract} accountsSpouseUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSpouseUpdate: async (accountId: number, accountsSpouseUpdateRequestContract: AccountsSpouseUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountSpouseUpdate', 'accountId', accountId)
            // verify required parameter 'accountsSpouseUpdateRequestContract' is not null or undefined
            assertParamExists('accountSpouseUpdate', 'accountsSpouseUpdateRequestContract', accountsSpouseUpdateRequestContract)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/spouse`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountsSpouseUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpouseApi - functional programming interface
 * @export
 */
export const SpouseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpouseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary show spouse person on the specified account
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountSpouseShow(accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Signer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountSpouseShow(accountId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates spouse on the account
         * @param {number} accountId 
         * @param {AccountsSpouseUpdateRequestContract} accountsSpouseUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountSpouseUpdate(accountId: number, accountsSpouseUpdateRequestContract: AccountsSpouseUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpouseUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountSpouseUpdate(accountId, accountsSpouseUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpouseApi - factory interface
 * @export
 */
export const SpouseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpouseApiFp(configuration)
    return {
        /**
         * 
         * @summary show spouse person on the specified account
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSpouseShow(accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Signer> {
            return localVarFp.accountSpouseShow(accountId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates spouse on the account
         * @param {number} accountId 
         * @param {AccountsSpouseUpdateRequestContract} accountsSpouseUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSpouseUpdate(accountId: number, accountsSpouseUpdateRequestContract: AccountsSpouseUpdateRequestContract, options?: any): AxiosPromise<SpouseUpdate> {
            return localVarFp.accountSpouseUpdate(accountId, accountsSpouseUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for accountSpouseShow operation in SpouseApi.
 * @export
 * @interface SpouseApiAccountSpouseShowRequest
 */
export interface SpouseApiAccountSpouseShowRequest {
    /**
     * 
     * @type {number}
     * @memberof SpouseApiAccountSpouseShow
     */
    readonly accountId: number

    /**
     * 
     * @type {string}
     * @memberof SpouseApiAccountSpouseShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SpouseApiAccountSpouseShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SpouseApiAccountSpouseShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SpouseApiAccountSpouseShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SpouseApiAccountSpouseShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SpouseApiAccountSpouseShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for accountSpouseUpdate operation in SpouseApi.
 * @export
 * @interface SpouseApiAccountSpouseUpdateRequest
 */
export interface SpouseApiAccountSpouseUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof SpouseApiAccountSpouseUpdate
     */
    readonly accountId: number

    /**
     * 
     * @type {AccountsSpouseUpdateRequestContract}
     * @memberof SpouseApiAccountSpouseUpdate
     */
    readonly accountsSpouseUpdateRequestContract: AccountsSpouseUpdateRequestContract
}

/**
 * SpouseApi - object-oriented interface
 * @export
 * @class SpouseApi
 * @extends {BaseAPI}
 */
export class SpouseApi extends BaseAPI {
    /**
     * 
     * @summary show spouse person on the specified account
     * @param {SpouseApiAccountSpouseShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpouseApi
     */
    public accountSpouseShow(requestParameters: SpouseApiAccountSpouseShowRequest, options?: AxiosRequestConfig) {
        return SpouseApiFp(this.configuration).accountSpouseShow(requestParameters.accountId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates spouse on the account
     * @param {SpouseApiAccountSpouseUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpouseApi
     */
    public accountSpouseUpdate(requestParameters: SpouseApiAccountSpouseUpdateRequest, options?: AxiosRequestConfig) {
        return SpouseApiFp(this.configuration).accountSpouseUpdate(requestParameters.accountId, requestParameters.accountsSpouseUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AccountSpouseShowErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountSpouseShowErrorCodes = [
    404,
];

export type AccountSpouseUpdateErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountSpouseUpdateErrorCodes = [
    404,
];


