import React, { type ComponentProps } from 'react';

import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { GetStarted } from '@npm/core/ui/components/molecules/GetStarted';
import { Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';

import {
  DisabledTextForBroker,
  IndividualAccreditationForm,
  IndividualAccreditationReadOnly,
  SignatureModal,
  useIndividualAccreditationForm,
} from '../../../accreditation';
import { useUserContextStore } from '../../../auth/user/context';
import { useCurrentWorkstation, useObo } from '../../../auth/user/role';
import { StepContainer } from '../../StepContainer';
import { useAutoScrollingFormProps } from '../steps.hooks';

const { StepState } = Wizard;

type Props = {
  stepProps: Wizard.StepProps;
  personId?: number;
} & Pick<
  ComponentProps<typeof IndividualAccreditationReadOnly>,
  'zendeskTicketGroupId'
>;

export const IndividualAccreditationPostOnboarding = ({
  stepProps,
  personId,
  zendeskTicketGroupId,
}: Props) => {
  const autoScrollingFormProps = useAutoScrollingFormProps();
  const { isObo } = useObo();
  const workstation = useCurrentWorkstation();
  const isActingAsBroker = useUserContextStore(
    store => isObo || workstation?.type?.code === 'brokerage'
  );
  const stepState = useWizardStore(s => s.activeStepState);

  const {
    form,
    signatureForm,
    isLoading,
    isUpdating,
    handleSubmit,
    handleOpenSignModal,
    isSignatureModalOpen,
    closeSignModal,
    data,
  } = useIndividualAccreditationForm(personId, {
    onUpdate: () => {
      Notification.success({
        message: 'You have updated your Accredited Investor Status',
      });
      stepProps.handleStepComplete();
    },
  });

  const formComponent = isActingAsBroker ? (
    <GetStarted content={<DisabledTextForBroker />} />
  ) : (
    <IndividualAccreditationForm
      form={form}
      handleValuesChange={() => stepProps.setFormHasUnsavedChanges(true)}
      {...autoScrollingFormProps}
    />
  );

  return (
    <>
      <StepContainer
        stepProps={stepProps}
        isLoading={isLoading && !isActingAsBroker}
        isUpdating={isUpdating}
        handleSubmit={isActingAsBroker ? null : handleOpenSignModal}
      >
        {stepState === StepState.EDIT && formComponent}
        {stepState === StepState.FILLED && (
          <IndividualAccreditationReadOnly
            data={data}
            zendeskTicketGroupId={zendeskTicketGroupId}
          />
        )}
      </StepContainer>
      <SignatureModal
        variant="individual"
        form={signatureForm}
        isOpen={isSignatureModalOpen}
        handleSubmit={handleSubmit}
        handleCancel={closeSignModal}
        isLoading={isLoading || isUpdating}
      />
    </>
  );
};
