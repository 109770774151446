import styled, { type DefaultTheme, css } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { useBreakpoint } from '@npm/core/ui/utils/style';

export const ToggleButton = styled(Button)<{
  $horizontalPadding?: keyof DefaultTheme['spacing'];
}>`
  && {
    padding: 0px;

    ${({ theme, $horizontalPadding }) => css`
      background-color: ${theme.color.general.layout.one};
      padding: 0
        ${$horizontalPadding
          ? $horizontalPadding === 'lg'
            ? theme.spacing[$horizontalPadding] / 2
            : theme.spacing[$horizontalPadding]
          : 0}px;

      &:not(:last-child) {
        margin-right: ${theme.spacing.xs}px;
      }
    `}}

  ${({ theme, $horizontalPadding }) => useBreakpoint(theme).mobile`
      border-left: ${
        $horizontalPadding
          ? `1px solid ${theme.color.general.borders.primary}`
          : null
      }
  `}

    :active,
    :focus,
    :hover {
      outline: none !important;
      box-shadow: none !important;
    }
  }
`;
