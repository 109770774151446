import React, { type ReactElement } from 'react';

import { type IconProps } from '@npm/utils';

import * as S from './IconBackground.style';

type Props = {
  children: ReactElement<IconProps> | ReactElement<IconProps>[];
};
export const IconBackground = ({ children }: Props) => {
  return <S.IconBackground>{children}</S.IconBackground>;
};
