import type { HistoryEvent } from '@npm/core/ui/components/molecules/Link/useHistoryEventListener';
import { eventBus } from '@npm/core/ui/utils/eventBus';
import { buildQueryParamsString } from '@npm/core/ui/utils/url';
import {
  type AccountForBrokerage,
  type AccountShowAggregate,
  type RepresentativeAggregate,
  type UserRoleAggregate,
  type Workstation,
  CbWorkstationType,
  getAxiosInstance,
} from '@npm/data-access';

import { getBrokerageWorkstationLandingPage } from '../../../../workstations/workstationLandingPage.utils';
import { parseRedirectUrl, UrlParams } from '../../context';
import { Header } from '../../context/store/role/role.store';
import type { UserRoleContextType } from '../userRole.types';

export const extractContextVariables = (
  context: UserRoleContextType | null
) => {
  if (!context) {
    return {
      roleId: null,
      oboUserId: null,
      oboAccountId: null,
      allAccounts: null,
      oboPersonId: null,
      subjectId: null,
    };
  }

  const roleId = (context?.subRole as unknown as { id?: number })?.id;
  const subjectId = (
    context?.subRole as unknown as { subject?: { id?: number } }
  )?.subject?.id;
  const oboAccountId =
    context?.workstationType === 'brokerage' && context?.subRole?.type === 'obo'
      ? context.subRole.account.id
      : null;
  const oboUserId =
    context?.workstationType === 'brokerage' && context?.subRole?.type === 'obo'
      ? context.subRole.representative.user_id
      : null;
  const allAccounts =
    context?.workstationType === 'investor' &&
    context?.subRole?.type === 'all-accounts';

  const oboPersonId =
    context?.workstationType === 'brokerage' && context?.subRole?.type === 'obo'
      ? context.subRole.representative.person_id
      : null;

  return {
    roleId,
    oboUserId,
    oboAccountId,
    allAccounts,
    oboPersonId,
    subjectId,
  };
};

export const setAxiosRoleHeaders = (context: UserRoleContextType | null) => {
  const axiosInstance = getAxiosInstance();

  delete axiosInstance.defaults.headers[Header.OboAccountId];
  delete axiosInstance.defaults.headers[Header.OboUserId];
  delete axiosInstance.defaults.headers[Header.AcrossAccounts];
  delete axiosInstance.defaults.headers[Header.UserRoleId];
  delete axiosInstance.defaults.headers[Header.Workstation];

  if (!context) {
    return;
  }

  const { roleId, oboAccountId, oboUserId, allAccounts } =
    extractContextVariables(context);

  axiosInstance.defaults.headers[Header.Workstation] = context.workstationType;
  if (roleId) {
    axiosInstance.defaults.headers[Header.UserRoleId] = roleId;
  }
  if (oboAccountId) {
    axiosInstance.defaults.headers[Header.OboAccountId] = oboAccountId;
  }
  if (oboUserId) {
    axiosInstance.defaults.headers[Header.OboUserId] = oboUserId;
  }
  if (allAccounts) {
    axiosInstance.defaults.headers[Header.AcrossAccounts] = allAccounts ? 1 : 0;
  }
};

export const setRole = (
  role: UserRoleAggregate,
  workstation: Workstation | undefined,
  config?: {
    redirectTo?: string;
    urlUpdateType?: 'push' | 'replace';
  }
) => {
  // fallback to workstation saved as active on backend
  const isSwitchingToAllAccountsMode =
    role === null &&
    workstation.type?.code === CbWorkstationType.items.investor;
  const [redirectPath, redirectQuery] = parseRedirectUrl(config?.redirectTo);

  const queryParamsString =
    buildQueryParamsString(
      {
        [UrlParams.ALL_ACCOUNTS_MODE]:
          workstation.type?.code === CbWorkstationType.items.investor
            ? Number(isSwitchingToAllAccountsMode)
            : null,
        [UrlParams.ROLE_ID]: !isSwitchingToAllAccountsMode ? role.id : null,
        [UrlParams.SUBJECT_ID]: !isSwitchingToAllAccountsMode
          ? role?.subject?.id
          : null,
        // reset OBO headers
        [UrlParams.OBO_ACCOUNT_ID]: undefined,
        [UrlParams.OBO_USER_ID]: undefined,
        [UrlParams.OBO_PERSON_ID]: undefined,
      },
      !config?.redirectTo
    ) + redirectQuery;

  eventBus.dispatch<HistoryEvent>('HISTORY_EVENT', {
    type: config?.urlUpdateType ?? 'push',
    location: {
      pathname: redirectPath || window.location.pathname,
      search: queryParamsString,
    },
  });
};

export const setObo = async (
  payload: {
    account: AccountForBrokerage | AccountShowAggregate;
    representative: RepresentativeAggregate;
  },
  config?: {
    redirectTo?: string;
  }
) => {
  /**
   * When updating this logic, please retest
   * https://nasdaq-private-market.atlassian.net/browse/NFE-4799
   * https://nasdaq-private-market.atlassian.net/browse/NFE-4901
   */

  const [redirectPath, redirectQuery] = parseRedirectUrl(config?.redirectTo);

  const queryParamsString =
    buildQueryParamsString(
      {
        [UrlParams.OBO_PERSON_ID]: payload?.representative?.person_id,
        [UrlParams.OBO_USER_ID]: payload?.representative?.user_id,
        [UrlParams.OBO_ACCOUNT_ID]: payload?.account?.id,
      },
      true
    ) + redirectQuery;

  eventBus.dispatch<HistoryEvent>('HISTORY_EVENT', {
    type: 'push',
    location: {
      pathname: redirectPath || window.location.pathname,
      search: queryParamsString,
    },
  });
};

export const leaveObo = () => {
  const isSecondMarket = window.location.pathname.includes('second-market');
  const redirectTo = isSecondMarket
    ? window.location.pathname
    : getBrokerageWorkstationLandingPage(
        null // `role` is not needed here because Account Manager cannot enter/leave the OBO mode
      );

  const [redirectPath, redirectQuery] = parseRedirectUrl(redirectTo);

  const queryParamsString =
    buildQueryParamsString(
      {
        [UrlParams.OBO_PERSON_ID]: null,
        [UrlParams.OBO_USER_ID]: null,
        [UrlParams.OBO_ACCOUNT_ID]: null,
      },
      true
    ) + redirectQuery;

  eventBus.dispatch<HistoryEvent>('HISTORY_EVENT', {
    type: 'push',
    location: {
      pathname: redirectPath || window.location.pathname,
      search: queryParamsString,
    },
  });
};
