import { type FC } from 'react';
import { type SkeletonProps, Skeleton } from 'antd';
import type SkeletonAvatar from 'antd/lib/skeleton/Avatar';
import type SkeletonButton from 'antd/lib/skeleton/Button';
import type SkeletonImage from 'antd/lib/skeleton/Image';
import type SkeletonInput from 'antd/lib/skeleton/Input';
import type SkeletonNode from 'antd/lib/skeleton/Node';
import styled from 'styled-components';

type ExternalSkeletonProps = {
  Button: typeof SkeletonButton;
  Avatar: typeof SkeletonAvatar;
  Input: typeof SkeletonInput;
  Image: typeof SkeletonImage;
  Node: typeof SkeletonNode;
} & FC<SkeletonProps>;

export const SkeletonStyled = styled<ExternalSkeletonProps>(Skeleton)<{
  $noMargin?: boolean;
}>`
  // this was added because of recent antd update removing default margin which broke skeletons layout on many places
  // however adding it here makes some other issues, margin should be handled by parent components
  // $noMargin is temporary solution to override this, but in the end:
  // TODO: remove this margin and handle margin everywhere by parent components
  margin-top: ${({ theme, $noMargin }) => ($noMargin ? 0 : theme.spacing.md)}px;

  .ant-skeleton-paragraph > li::after {
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.color.general.skeleton.primary} 25%,
      ${({ theme }) => theme.color.general.skeleton.secondary} 37%,
      ${({ theme }) => theme.color.general.skeleton.primary} 63%
    );
  }
`;
