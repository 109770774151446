import { useMemo } from 'react';
import type { ChartDataset } from 'chart.js';
import { useTheme } from 'styled-components';

import { transparentise } from '@npm/core/ui/utils/style';

import { sliceDataset } from '../../CompanyOverview.utils';
import type { CompanyOverviewChartDataSetEffect } from '../../TapeDPricing/TapeDPricing.types';
import { getValuations409AColor } from '../Valuations409A.utils';

import {
  type Valuation409ADataPoint,
  type Valuation409ADataValue,
} from './Valuations409AChart.types';
import { getValuations409AChartData } from './Valuations409AChart.utils';

type HookOptions = {
  currentMinDate: Date;
  currentMaxDate: Date;
  effect?: CompanyOverviewChartDataSetEffect;
};

export const useValuations409ADataSet = (
  data: Valuation409ADataValue[],
  options: HookOptions
): ChartDataset<'line', Valuation409ADataPoint[]> => {
  const theme = useTheme();

  const { currentMinDate, currentMaxDate, effect } = options;

  return useMemo(() => {
    const chartData = getValuations409AChartData(data);

    const slicedData = sliceDataset(
      chartData,
      currentMinDate,
      currentMaxDate,
      new Date()
    );

    const borderColor = getValuations409AColor(theme);

    return {
      label: '409A Valuation',
      data: slicedData,
      stepped: 'before',
      spanGaps: true,
      borderColor:
        effect === 'dim' ? transparentise(borderColor, 0.3) : borderColor,
      borderWidth: effect === 'highlight' ? 3 : 1,
    };
  }, [data, currentMinDate, currentMaxDate, effect]);
};
