import type { ReactNode } from 'react';
import React from 'react';

import { BottomNavigation } from '@npm/core/ui/components/atoms/BottomNavigation';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useLayoutConfig } from '@npm/core/ui/components/organisms/Layout';
import { type IssuerEntityAggregate } from '@npm/data-access';

import { SecondMarketTabs } from './SecondMarketTabs';

import * as S from './SecondMarketHeader.styles';

type Props = {
  rightControls: ReactNode;
  onCompanySelect: (companyId: number) => void;
  selectedCompany: IssuerEntityAggregate | undefined;
};

export const SecondMarketHeader = ({
  rightControls,
  onCompanySelect,
  selectedCompany,
}: Props) => {
  const { isMobile, isTablet } = useLayoutConfig();
  const { header } = useLayoutConfig();

  return (
    <>
      <S.Container $appHeaderHeight={header.height}>
        <S.Left>
          <S.CompanySelect
            onChange={val => onCompanySelect(val ? Number(val) : undefined)}
            placeholder="Search Company"
            suffixIcon={<Icon name="chevron-down" />}
            value={selectedCompany?.id}
            requestCoverageVariant="row"
          />
        </S.Left>
        <S.Middle>
          <SecondMarketTabs selectedCompany={selectedCompany} />
        </S.Middle>
        <S.Right>{!isMobile && !isTablet && rightControls}</S.Right>
      </S.Container>
      {(isMobile || isTablet) && !!rightControls && (
        <BottomNavigation
          nextButton={rightControls}
          getScrollableElement={() =>
            document.querySelector('#ant-layout') as HTMLElement
          }
          hideOnScrollDown
        />
      )}
    </>
  );
};
