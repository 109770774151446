import { type ComponentProps } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type OrderItemUnitCode,
  type SecondmarketOrderItemShow,
} from '@npm/data-access';

import { useUserContextStore } from '../../auth/user/context';
import { parseOrderSize, parseOrderSizeRange } from '../OrderSizeToggle';

import { getApplicableSizeType } from './OrderSizeDisplay.utils';

type Props = {
  orderItem?: Pick<
    SecondmarketOrderItemShow,
    'minimum_quantity' | 'quantity' | 'price' | 'unit'
  >;
  quantity?: number;
  minimumQuantity?: number;
  pps?: number;
  quantityUnit?: OrderItemUnitCode;
  compact?: boolean;
  currency?: string;
} & Pick<
  ComponentProps<typeof Text>,
  'size' | 'color' | 'colorVariant' | 'weight'
>;

export const OrderSizeDisplay = ({
  orderItem,
  quantity = orderItem?.quantity,
  minimumQuantity = orderItem?.minimum_quantity,
  pps = orderItem?.price,
  quantityUnit = orderItem?.unit?.code as OrderItemUnitCode,
  size = 'sm',
  color,
  colorVariant = 'primary',
  weight,
  compact,
  currency = 'USD',
}: Props) => {
  const sizeType = getApplicableSizeType(
    useUserContextStore(store => store.sizeType),
    pps,
    quantityUnit
  );

  const orderSize = minimumQuantity
    ? parseOrderSizeRange({
        sizeType,
        minimumQuantity,
        quantity,
        pps,
        quantityUnit,
      })
    : parseOrderSize({ sizeType, quantity, pps, quantityUnit });

  if (sizeType === 'Shares') {
    return (
      <Text.Quantity
        size={size}
        color={color}
        colorVariant={colorVariant}
        weight={weight}
        value={orderSize}
        compact={compact}
        unit="Shares"
      />
    );
  }

  return (
    <Text.Price
      compact={compact}
      size={size}
      color={color}
      colorVariant={colorVariant}
      currency={currency}
      value={orderSize}
      {...(!compact && {
        // not showing any decimal points for Size (USD) if not compact
        formatOptions: {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        },
      })}
    />
  );
};
