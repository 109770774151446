/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  BackgroundCheckApi,
  AccountBackgroundCheckShowErrorCodes,
  AccountBackgroundCheckShowErrorTypes,
  AccountBackgroundCheckShowByIdErrorCodes,
  AccountBackgroundCheckShowByIdErrorTypes,
  BackgroundCheckShowLatestErrorCodes,
  BackgroundCheckShowLatestErrorTypes,
  BackgroundCheckIndexErrorCodes,
  BackgroundCheckIndexErrorTypes,
  BackgroundCheckShowByIdErrorCodes,
  BackgroundCheckShowByIdErrorTypes,
  BackgroundCheckUpdateErrorCodes,
  BrokerageFirmBackgroundCheckIndexErrorCodes,
  BrokerageFirmBackgroundCheckIndexErrorTypes,
  BrokerageFirmBackgroundCheckStatisticShowErrorCodes,
  BrokerageFirmBackgroundCheckStatisticShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new BackgroundCheckApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountBackgroundCheckShowMethod = BackgroundCheckApi['accountBackgroundCheckShow'];
export type AccountBackgroundCheckShowResponseType = MethodResult<AccountBackgroundCheckShowMethod>;

export const useAccountBackgroundCheckShow = (
  variables: Parameters<AccountBackgroundCheckShowMethod>[0],
  config?: UseQueryConfig<
    AccountBackgroundCheckShowMethod,
    AccountBackgroundCheckShowErrorTypes
  >
) => {
  return useQuery<
    AccountBackgroundCheckShowMethod,
    AccountBackgroundCheckShowErrorTypes
  >(
    (...args) => api.accountBackgroundCheckShow(...args),
    AccountBackgroundCheckShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountBackgroundCheckShow'],
        ...apiQueryConfig.AccountBackgroundCheckShow,
        ...config?.queryConfig
      },
    },
    'AccountBackgroundCheckShow',
  );
};

export const useAccountBackgroundCheckShowInfinite = (
  variables: Parameters<AccountBackgroundCheckShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountBackgroundCheckShowMethod,
    AccountBackgroundCheckShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountBackgroundCheckShowMethod,
    AccountBackgroundCheckShowErrorTypes
  >(
    (...args) => api.accountBackgroundCheckShow(...args),
    AccountBackgroundCheckShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountBackgroundCheckShow', 'AccountBackgroundCheckShowInfinite'],
        ...apiQueryConfig.AccountBackgroundCheckShow,
        ...config?.queryConfig,
      },
    },
    'AccountBackgroundCheckShow',
  );
};

export const useAccountBackgroundCheckShowLazy = (baseOptions?: {
  variables?: Parameters<AccountBackgroundCheckShowMethod>[0],
  config?: UseQueryConfig<
    AccountBackgroundCheckShowMethod,
    AccountBackgroundCheckShowErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountBackgroundCheckShowMethod,
    AccountBackgroundCheckShowErrorTypes
  >(
    (...args) => api.accountBackgroundCheckShow(...args),
    AccountBackgroundCheckShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountBackgroundCheckShow'],
        ...apiQueryConfig.AccountBackgroundCheckShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountBackgroundCheckShow',
  );
};


export type AccountBackgroundCheckShowByIdMethod = BackgroundCheckApi['accountBackgroundCheckShowById'];
export type AccountBackgroundCheckShowByIdResponseType = MethodResult<AccountBackgroundCheckShowByIdMethod>;

export const useAccountBackgroundCheckShowById = (
  variables: Parameters<AccountBackgroundCheckShowByIdMethod>[0],
  config?: UseQueryConfig<
    AccountBackgroundCheckShowByIdMethod,
    AccountBackgroundCheckShowByIdErrorTypes
  >
) => {
  return useQuery<
    AccountBackgroundCheckShowByIdMethod,
    AccountBackgroundCheckShowByIdErrorTypes
  >(
    (...args) => api.accountBackgroundCheckShowById(...args),
    AccountBackgroundCheckShowByIdErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountBackgroundCheckShowById'],
        ...apiQueryConfig.AccountBackgroundCheckShowById,
        ...config?.queryConfig
      },
    },
    'AccountBackgroundCheckShowById',
  );
};

export const useAccountBackgroundCheckShowByIdInfinite = (
  variables: Parameters<AccountBackgroundCheckShowByIdMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountBackgroundCheckShowByIdMethod,
    AccountBackgroundCheckShowByIdErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountBackgroundCheckShowByIdMethod,
    AccountBackgroundCheckShowByIdErrorTypes
  >(
    (...args) => api.accountBackgroundCheckShowById(...args),
    AccountBackgroundCheckShowByIdErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountBackgroundCheckShowById', 'AccountBackgroundCheckShowByIdInfinite'],
        ...apiQueryConfig.AccountBackgroundCheckShowById,
        ...config?.queryConfig,
      },
    },
    'AccountBackgroundCheckShowById',
  );
};

export const useAccountBackgroundCheckShowByIdLazy = (baseOptions?: {
  variables?: Parameters<AccountBackgroundCheckShowByIdMethod>[0],
  config?: UseQueryConfig<
    AccountBackgroundCheckShowByIdMethod,
    AccountBackgroundCheckShowByIdErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountBackgroundCheckShowByIdMethod,
    AccountBackgroundCheckShowByIdErrorTypes
  >(
    (...args) => api.accountBackgroundCheckShowById(...args),
    AccountBackgroundCheckShowByIdErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountBackgroundCheckShowById'],
        ...apiQueryConfig.AccountBackgroundCheckShowById,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountBackgroundCheckShowById',
  );
};


export type BackgroundCheckShowLatestMethod = BackgroundCheckApi['backgroundCheckShowLatest'];
export type BackgroundCheckShowLatestResponseType = MethodResult<BackgroundCheckShowLatestMethod>;

export const useBackgroundCheckShowLatest = (
  variables: Parameters<BackgroundCheckShowLatestMethod>[0],
  config?: UseQueryConfig<
    BackgroundCheckShowLatestMethod,
    BackgroundCheckShowLatestErrorTypes
  >
) => {
  return useQuery<
    BackgroundCheckShowLatestMethod,
    BackgroundCheckShowLatestErrorTypes
  >(
    (...args) => api.backgroundCheckShowLatest(...args),
    BackgroundCheckShowLatestErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BackgroundCheckShowLatest'],
        ...apiQueryConfig.BackgroundCheckShowLatest,
        ...config?.queryConfig
      },
    },
    'BackgroundCheckShowLatest',
  );
};

export const useBackgroundCheckShowLatestInfinite = (
  variables: Parameters<BackgroundCheckShowLatestMethod>[0],
  config?: UseInfiniteQueryConfig<
    BackgroundCheckShowLatestMethod,
    BackgroundCheckShowLatestErrorTypes
  >
) => {
  return useInfiniteQuery<
    BackgroundCheckShowLatestMethod,
    BackgroundCheckShowLatestErrorTypes
  >(
    (...args) => api.backgroundCheckShowLatest(...args),
    BackgroundCheckShowLatestErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BackgroundCheckShowLatest', 'BackgroundCheckShowLatestInfinite'],
        ...apiQueryConfig.BackgroundCheckShowLatest,
        ...config?.queryConfig,
      },
    },
    'BackgroundCheckShowLatest',
  );
};

export const useBackgroundCheckShowLatestLazy = (baseOptions?: {
  variables?: Parameters<BackgroundCheckShowLatestMethod>[0],
  config?: UseQueryConfig<
    BackgroundCheckShowLatestMethod,
    BackgroundCheckShowLatestErrorTypes
  >
}) => {
  return useLazyQuery<
    BackgroundCheckShowLatestMethod,
    BackgroundCheckShowLatestErrorTypes
  >(
    (...args) => api.backgroundCheckShowLatest(...args),
    BackgroundCheckShowLatestErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BackgroundCheckShowLatest'],
        ...apiQueryConfig.BackgroundCheckShowLatest,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BackgroundCheckShowLatest',
  );
};


export type BackgroundCheckIndexMethod = BackgroundCheckApi['backgroundCheckIndex'];
export type BackgroundCheckIndexResponseType = MethodResult<BackgroundCheckIndexMethod>;

export const useBackgroundCheckIndex = (
  variables: Parameters<BackgroundCheckIndexMethod>[0],
  config?: UseQueryConfig<
    BackgroundCheckIndexMethod,
    BackgroundCheckIndexErrorTypes
  >
) => {
  return useQuery<
    BackgroundCheckIndexMethod,
    BackgroundCheckIndexErrorTypes
  >(
    (...args) => api.backgroundCheckIndex(...args),
    BackgroundCheckIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BackgroundCheckIndex'],
        ...apiQueryConfig.BackgroundCheckIndex,
        ...config?.queryConfig
      },
    },
    'BackgroundCheckIndex',
  );
};

export const useBackgroundCheckIndexInfinite = (
  variables: Parameters<BackgroundCheckIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    BackgroundCheckIndexMethod,
    BackgroundCheckIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    BackgroundCheckIndexMethod,
    BackgroundCheckIndexErrorTypes
  >(
    (...args) => api.backgroundCheckIndex(...args),
    BackgroundCheckIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BackgroundCheckIndex', 'BackgroundCheckIndexInfinite'],
        ...apiQueryConfig.BackgroundCheckIndex,
        ...config?.queryConfig,
      },
    },
    'BackgroundCheckIndex',
  );
};

export const useBackgroundCheckIndexLazy = (baseOptions?: {
  variables?: Parameters<BackgroundCheckIndexMethod>[0],
  config?: UseQueryConfig<
    BackgroundCheckIndexMethod,
    BackgroundCheckIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    BackgroundCheckIndexMethod,
    BackgroundCheckIndexErrorTypes
  >(
    (...args) => api.backgroundCheckIndex(...args),
    BackgroundCheckIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BackgroundCheckIndex'],
        ...apiQueryConfig.BackgroundCheckIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BackgroundCheckIndex',
  );
};


export type BackgroundCheckShowByIdMethod = BackgroundCheckApi['backgroundCheckShowById'];
export type BackgroundCheckShowByIdResponseType = MethodResult<BackgroundCheckShowByIdMethod>;

export const useBackgroundCheckShowById = (
  variables: Parameters<BackgroundCheckShowByIdMethod>[0],
  config?: UseQueryConfig<
    BackgroundCheckShowByIdMethod,
    BackgroundCheckShowByIdErrorTypes
  >
) => {
  return useQuery<
    BackgroundCheckShowByIdMethod,
    BackgroundCheckShowByIdErrorTypes
  >(
    (...args) => api.backgroundCheckShowById(...args),
    BackgroundCheckShowByIdErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BackgroundCheckShowById'],
        ...apiQueryConfig.BackgroundCheckShowById,
        ...config?.queryConfig
      },
    },
    'BackgroundCheckShowById',
  );
};

export const useBackgroundCheckShowByIdInfinite = (
  variables: Parameters<BackgroundCheckShowByIdMethod>[0],
  config?: UseInfiniteQueryConfig<
    BackgroundCheckShowByIdMethod,
    BackgroundCheckShowByIdErrorTypes
  >
) => {
  return useInfiniteQuery<
    BackgroundCheckShowByIdMethod,
    BackgroundCheckShowByIdErrorTypes
  >(
    (...args) => api.backgroundCheckShowById(...args),
    BackgroundCheckShowByIdErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BackgroundCheckShowById', 'BackgroundCheckShowByIdInfinite'],
        ...apiQueryConfig.BackgroundCheckShowById,
        ...config?.queryConfig,
      },
    },
    'BackgroundCheckShowById',
  );
};

export const useBackgroundCheckShowByIdLazy = (baseOptions?: {
  variables?: Parameters<BackgroundCheckShowByIdMethod>[0],
  config?: UseQueryConfig<
    BackgroundCheckShowByIdMethod,
    BackgroundCheckShowByIdErrorTypes
  >
}) => {
  return useLazyQuery<
    BackgroundCheckShowByIdMethod,
    BackgroundCheckShowByIdErrorTypes
  >(
    (...args) => api.backgroundCheckShowById(...args),
    BackgroundCheckShowByIdErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BackgroundCheckShowById'],
        ...apiQueryConfig.BackgroundCheckShowById,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BackgroundCheckShowById',
  );
};


export type BackgroundCheckUpdateMethod = BackgroundCheckApi['backgroundCheckUpdate'];

export const useBackgroundCheckUpdate = () => {
  return useMutation<BackgroundCheckUpdateMethod>(
    (...args) => api.backgroundCheckUpdate(...args),
    BackgroundCheckUpdateErrorCodes,
    'BackgroundCheckUpdate',
    '/api/background-checks/{id}',
    'put'
  );
};

export type BrokerageFirmBackgroundCheckIndexMethod = BackgroundCheckApi['brokerageFirmBackgroundCheckIndex'];
export type BrokerageFirmBackgroundCheckIndexResponseType = MethodResult<BrokerageFirmBackgroundCheckIndexMethod>;

export const useBrokerageFirmBackgroundCheckIndex = (
  variables: Parameters<BrokerageFirmBackgroundCheckIndexMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmBackgroundCheckIndexMethod,
    BrokerageFirmBackgroundCheckIndexErrorTypes
  >
) => {
  return useQuery<
    BrokerageFirmBackgroundCheckIndexMethod,
    BrokerageFirmBackgroundCheckIndexErrorTypes
  >(
    (...args) => api.brokerageFirmBackgroundCheckIndex(...args),
    BrokerageFirmBackgroundCheckIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmBackgroundCheckIndex'],
        ...apiQueryConfig.BrokerageFirmBackgroundCheckIndex,
        ...config?.queryConfig
      },
    },
    'BrokerageFirmBackgroundCheckIndex',
  );
};

export const useBrokerageFirmBackgroundCheckIndexInfinite = (
  variables: Parameters<BrokerageFirmBackgroundCheckIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    BrokerageFirmBackgroundCheckIndexMethod,
    BrokerageFirmBackgroundCheckIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    BrokerageFirmBackgroundCheckIndexMethod,
    BrokerageFirmBackgroundCheckIndexErrorTypes
  >(
    (...args) => api.brokerageFirmBackgroundCheckIndex(...args),
    BrokerageFirmBackgroundCheckIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmBackgroundCheckIndex', 'BrokerageFirmBackgroundCheckIndexInfinite'],
        ...apiQueryConfig.BrokerageFirmBackgroundCheckIndex,
        ...config?.queryConfig,
      },
    },
    'BrokerageFirmBackgroundCheckIndex',
  );
};

export const useBrokerageFirmBackgroundCheckIndexLazy = (baseOptions?: {
  variables?: Parameters<BrokerageFirmBackgroundCheckIndexMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmBackgroundCheckIndexMethod,
    BrokerageFirmBackgroundCheckIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    BrokerageFirmBackgroundCheckIndexMethod,
    BrokerageFirmBackgroundCheckIndexErrorTypes
  >(
    (...args) => api.brokerageFirmBackgroundCheckIndex(...args),
    BrokerageFirmBackgroundCheckIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BrokerageFirmBackgroundCheckIndex'],
        ...apiQueryConfig.BrokerageFirmBackgroundCheckIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BrokerageFirmBackgroundCheckIndex',
  );
};


export type BrokerageFirmBackgroundCheckStatisticShowMethod = BackgroundCheckApi['brokerageFirmBackgroundCheckStatisticShow'];
export type BrokerageFirmBackgroundCheckStatisticShowResponseType = MethodResult<BrokerageFirmBackgroundCheckStatisticShowMethod>;

export const useBrokerageFirmBackgroundCheckStatisticShow = (
  variables: Parameters<BrokerageFirmBackgroundCheckStatisticShowMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmBackgroundCheckStatisticShowMethod,
    BrokerageFirmBackgroundCheckStatisticShowErrorTypes
  >
) => {
  return useQuery<
    BrokerageFirmBackgroundCheckStatisticShowMethod,
    BrokerageFirmBackgroundCheckStatisticShowErrorTypes
  >(
    (...args) => api.brokerageFirmBackgroundCheckStatisticShow(...args),
    BrokerageFirmBackgroundCheckStatisticShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmBackgroundCheckStatisticShow'],
        ...apiQueryConfig.BrokerageFirmBackgroundCheckStatisticShow,
        ...config?.queryConfig
      },
    },
    'BrokerageFirmBackgroundCheckStatisticShow',
  );
};

export const useBrokerageFirmBackgroundCheckStatisticShowInfinite = (
  variables: Parameters<BrokerageFirmBackgroundCheckStatisticShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    BrokerageFirmBackgroundCheckStatisticShowMethod,
    BrokerageFirmBackgroundCheckStatisticShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    BrokerageFirmBackgroundCheckStatisticShowMethod,
    BrokerageFirmBackgroundCheckStatisticShowErrorTypes
  >(
    (...args) => api.brokerageFirmBackgroundCheckStatisticShow(...args),
    BrokerageFirmBackgroundCheckStatisticShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmBackgroundCheckStatisticShow', 'BrokerageFirmBackgroundCheckStatisticShowInfinite'],
        ...apiQueryConfig.BrokerageFirmBackgroundCheckStatisticShow,
        ...config?.queryConfig,
      },
    },
    'BrokerageFirmBackgroundCheckStatisticShow',
  );
};

export const useBrokerageFirmBackgroundCheckStatisticShowLazy = (baseOptions?: {
  variables?: Parameters<BrokerageFirmBackgroundCheckStatisticShowMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmBackgroundCheckStatisticShowMethod,
    BrokerageFirmBackgroundCheckStatisticShowErrorTypes
  >
}) => {
  return useLazyQuery<
    BrokerageFirmBackgroundCheckStatisticShowMethod,
    BrokerageFirmBackgroundCheckStatisticShowErrorTypes
  >(
    (...args) => api.brokerageFirmBackgroundCheckStatisticShow(...args),
    BrokerageFirmBackgroundCheckStatisticShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BrokerageFirmBackgroundCheckStatisticShow'],
        ...apiQueryConfig.BrokerageFirmBackgroundCheckStatisticShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BrokerageFirmBackgroundCheckStatisticShow',
  );
};

