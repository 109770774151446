import styled, { css } from 'styled-components';

export const BadgeCircle = styled.div`
  ${({ theme }) => css`
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: ${theme.color.info.typography.primary};

    && > p {
      color: ${theme.color.info.backgrounds.secondaryDefault};
    }
  `}
`;
