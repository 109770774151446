import type { ChartDataset } from 'chart.js';

import { type BidOfferData } from '../../BidOfferHistory/components/BidOfferHistoryChart/BidOfferHistoryChart.types';
import { type MovingAverageValue } from '../../MutualFundMarks/components/MutualFundMarksChart/MutualFundMarksChart.types';
import { type PrimaryRoundValue } from '../../PrimaryRounds/components/PrimaryRoundsChart/PrimaryRoundsChart.types';
import { type TapeDValue } from '../../TapeD/TapeD.types';
import { type Valuation409ADataValue } from '../../Valuations409A/Valuations409AChart/Valuations409AChart.types';

export type CompanyOverviewSummaryChartDataPoint = {
  x: string;
  y: number;
};

export type SummaryChartDatasets = {
  tapeDDataset: ChartDataset<'line', CompanyOverviewSummaryChartDataPoint[]>;
  bidsDataset: ChartDataset<'line', CompanyOverviewSummaryChartDataPoint[]>;
  offersDataset: ChartDataset<'line', CompanyOverviewSummaryChartDataPoint[]>;
  primaryRoundsDataset: ChartDataset<
    'line',
    CompanyOverviewSummaryChartDataPoint[]
  >;
  valuations409ADataset?:
    | ChartDataset<'line', CompanyOverviewSummaryChartDataPoint[]>
    | undefined;
  movingAverageDataset: ChartDataset<
    'line',
    CompanyOverviewSummaryChartDataPoint[]
  >;
};

export type CompanyOverviewSummaryChartSortedData = {
  tapeDData: TapeDValue[];
  bidsData: BidOfferData['aggregated_bids'];
  asksData: BidOfferData['aggregated_asks'];
  primaryRoundsData: PrimaryRoundValue[];
  valuations409AData: Valuation409ADataValue[];
  movingAverageData: MovingAverageValue[];
};

export enum CompanyOverviewSummaryChartDataSetKey {
  TAPE_D = 'TAPE_D',
  BIDS = 'BIDS',
  ASKS = 'ASKS',
  PRIMARY_ROUNDS = 'PRIMARY_ROUNDS',
  VALUATIONS_409A = 'VALUATIONS_409A',
  MOVING_AVERAGE = 'MOVING_AVERAGE',
}

export type SummaryChartTooltipData = {
  tapeDValue?: number;
  bidValue?: number;
  offerValue?: number;
  primaryRoundValue?: number;
  valuation409AValue?: number;
  movingAverageValue?: number;
};
