import { Image as ImageBase } from 'antd';
import styled, { css } from 'styled-components';

const placeholderCss = css`
  ${({ theme }) => css`
    background: ${theme.color.general.backgrounds.muted};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.color.general.typography.tertiary};
    overflow: hidden; // solves squeezing of ratio when size small
    line-height: 0;

    & > svg {
      width: 66.667%;
      height: 66.667%;
      min-width: 66.667%;
    }
  `}
`;

export const Image = styled(ImageBase)`
  // if src is null, hide the empty img with border
  visibility: hidden;
  &[src] {
    visibility: unset;
  }

  // placeholder while loding
  .ant-image,
  .ant-image-placeholder {
    ${placeholderCss}
  }
`;

// placeholder in case of error
export const Placeholder = styled.span`
  ${placeholderCss};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const Container = styled.div<{ $borderRadius?: number }>`
  line-height: initial;
  position: relative;

  .ant-image {
    display: flex;
  }

  & .ant-image-error {
    visibility: hidden;
  }

  & img,
  .ant-image-placeholder,
  ${Placeholder} {
    overflow: hidden;
    border-radius: ${({ $borderRadius }) => $borderRadius}px;
  }
`;
