import {
  type HoldingAssetTypeKey,
  type HoldingCreateForm,
} from './HoldingForm.types';

const BASE_FIELDS: (keyof HoldingCreateForm)[] = [
  'id',
  'type',
  'certificate_number',
  'quantity',
  'vested_qty',
  'registered_name',
];

export const VISIBLE_FIELDS: {
  [key in HoldingAssetTypeKey]?: (keyof HoldingCreateForm)[];
} = {
  CommonStock: [...BASE_FIELDS, 'acquisition_date', 'cost_basis', 'series'],
  PreferredStock: [...BASE_FIELDS, 'acquisition_date', 'cost_basis', 'series'],
  Unit: [...BASE_FIELDS, 'acquisition_date', 'cost_basis', 'series'],
  RestrictedStockUnit: [...BASE_FIELDS, 'grant_date', 'cost_basis', 'series'],
  Option: [
    ...BASE_FIELDS,
    'strike_price',
    'plan',
    'expiration_date',
    'grant_date',
    'grant_type',
  ],
  Warrant: [
    ...BASE_FIELDS,
    'series',
    'strike_price',
    'expiration_date',
    'grant_date',
  ],
  SingleLayerSpv: [
    'id',
    'type',
    'certificate_number',
    'quantity',
    'registered_name',
    'acquisition_date',
    'cost_basis',
  ],
  DoubleLayerSpv: [
    'id',
    'type',
    'certificate_number',
    'quantity',
    'registered_name',
    'acquisition_date',
    'cost_basis',
  ],
};
