/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  HistoricalPricesApi,
  HistoricalPricesIndexErrorCodes,
  HistoricalPricesIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new HistoricalPricesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type HistoricalPricesIndexMethod = HistoricalPricesApi['historicalPricesIndex'];
export type HistoricalPricesIndexResponseType = MethodResult<HistoricalPricesIndexMethod>;

export const useHistoricalPricesIndex = (
  variables: Parameters<HistoricalPricesIndexMethod>[0],
  config?: UseQueryConfig<
    HistoricalPricesIndexMethod,
    HistoricalPricesIndexErrorTypes
  >
) => {
  return useQuery<
    HistoricalPricesIndexMethod,
    HistoricalPricesIndexErrorTypes
  >(
    (...args) => api.historicalPricesIndex(...args),
    HistoricalPricesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['HistoricalPricesIndex'],
        ...apiQueryConfig.HistoricalPricesIndex,
        ...config?.queryConfig
      },
    },
    'HistoricalPricesIndex',
  );
};

export const useHistoricalPricesIndexInfinite = (
  variables: Parameters<HistoricalPricesIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    HistoricalPricesIndexMethod,
    HistoricalPricesIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    HistoricalPricesIndexMethod,
    HistoricalPricesIndexErrorTypes
  >(
    (...args) => api.historicalPricesIndex(...args),
    HistoricalPricesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['HistoricalPricesIndex', 'HistoricalPricesIndexInfinite'],
        ...apiQueryConfig.HistoricalPricesIndex,
        ...config?.queryConfig,
      },
    },
    'HistoricalPricesIndex',
  );
};

export const useHistoricalPricesIndexLazy = (baseOptions?: {
  variables?: Parameters<HistoricalPricesIndexMethod>[0],
  config?: UseQueryConfig<
    HistoricalPricesIndexMethod,
    HistoricalPricesIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    HistoricalPricesIndexMethod,
    HistoricalPricesIndexErrorTypes
  >(
    (...args) => api.historicalPricesIndex(...args),
    HistoricalPricesIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['HistoricalPricesIndex'],
        ...apiQueryConfig.HistoricalPricesIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'HistoricalPricesIndex',
  );
};

