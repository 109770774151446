import styled from 'styled-components';

import { Heading } from '../../atoms/Typography/Heading/Heading.styles';

export const Message = styled.div<{
  $type: 'info' | 'success' | 'warning' | 'error';
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}px;
  padding: ${({ theme }) => theme.spacing.md}px;
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  background-color: ${({ theme }) => theme.color.general.layout.two};
  border: 1px solid ${({ theme, $type }) => theme.color[$type].borders.primary};

  color: ${({ theme, $type }) => theme.color[$type].typography.primary};

  & ${Heading} {
    color: ${({ theme, $type }) => theme.color[$type].typography.primary};
  }
`;
