import React, { type ComponentProps } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';

type Props = {
  onClick: () => void;
} & Pick<ComponentProps<typeof NoDataArea>, 'outlined'>;

export const NoRepresentativeCta = ({ onClick, outlined }: Props) => {
  return (
    <NoDataArea
      outlined={outlined}
      title="No representative added, please select a different account"
      actionButton={<Button onClick={onClick}>Go Back</Button>}
    />
  );
};
