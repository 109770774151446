import { type ComponentProps } from 'react';

import { type Table } from '@npm/core/ui/components/molecules/Table';
import { CypressDataIds } from '@npm/core/ui/constants';
import { usePagination } from '@npm/core/ui/hooks/pagination/usePagination';
import { useBrokerageFirmAccountIndex } from '@npm/data-access';

import { type SelectAccountListProps } from '../SelectAccount.types';

import { getColumns } from './SelectAccountTable.columns';

import * as S from '../../../OboModal.styles';

type Props = {} & SelectAccountListProps & ComponentProps<typeof Table>;

export const SelectAccountTable = ({
  variables,
  setVariables,
  handleSelect,
  goToRepresentativeStep,
  selectedAccount,
}: Props) => {
  const { data, isLoading } = useBrokerageFirmAccountIndex(variables);

  const { updatePaginationVariables, pagination } = usePagination(
    'oboAccounts',
    {
      variables,
      setVariables,
      paginationData: data?.pagination,
      resetQueryOnUnmount: false,
    }
  );

  return (
    <S.AccountsTable
      dataSource={data?.accounts}
      columns={getColumns(selectedAccount, goToRepresentativeStep)}
      rowSelection={{
        type: 'radio',
        selectedRowKeys: [selectedAccount?.id],
        onChange: (_, a) => {
          const account = a[0];
          if (account.id !== selectedAccount?.id) {
            handleSelect(account);
          }
        },
      }}
      loading={isLoading}
      pagination={pagination}
      onChange={updatePaginationVariables}
      data-cy={CypressDataIds.OboModal.SelectAccount.Table}
    />
  );
};
