import React from 'react';

import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';

import * as S from '../SelectAccountCard/SelectAccountCard.styles';

const commonSkeletonProps = {
  rows: 1,
  style: {
    height: 8,
  },
};

export const SelectAccountLoadingCard = () => {
  return (
    <div style={{ pointerEvents: 'none' }}>
      <S.ItemButton>
        <Skeleton.Base
          title={false}
          paragraph={{ ...commonSkeletonProps, width: '50%' }}
          noMargin
        />
        <Skeleton.Base
          title={false}
          paragraph={{ ...commonSkeletonProps, width: '80%' }}
          noMargin
        />
        <Skeleton.Base
          title={false}
          paragraph={{ ...commonSkeletonProps, width: '40%' }}
          noMargin
        />
        <Skeleton.Base
          title={false}
          paragraph={{ ...commonSkeletonProps, width: '100%' }}
          noMargin
        />
      </S.ItemButton>
    </div>
  );
};
