import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { type IssuerEntityApiIssuerEntityIndexRequest } from '@npm/data-access';

import { type FiltersToggleButton } from '../../../../filters/filters/FilterPanel/components';

import { type FilterTabKey, FilterTabs } from './filters/FilterTabs';

import * as S from './CustomCollapsedContent.styles';
import { DRAWER_HEADER_HEIGHT } from '@npm/core/ui/components/organisms/Drawer/Drawer.styles';

type Props = {
  closeDrawer: () => void;
  isShownInsideDrawer: boolean;
  filtersToggleButtonProps: React.ComponentProps<typeof FiltersToggleButton>;
  showFilterTabs: boolean;
  variables: IssuerEntityApiIssuerEntityIndexRequest;
  handleTabsChange: (key: FilterTabKey) => void;
};

export const CustomCollapsedContent = ({
  closeDrawer,
  isShownInsideDrawer,
  filtersToggleButtonProps,
  showFilterTabs,
  variables,
  handleTabsChange,
}: Props) => {
  const filtersToggleButton = (
    <S.FiltersToggleButton
      {...filtersToggleButtonProps}
      showOnlyIconOnMobile={false}
      size="md"
    />
  );

  return (
    <>
      {isShownInsideDrawer ? (
        <>
          <Flex
            align="center"
            justify="space-between"
            style={{ height: DRAWER_HEADER_HEIGHT }}
          >
            <Flex align="center">
              <S.CloseButton variant="text" onClick={closeDrawer} size="sm">
                <Icon name="chevron-left" />
              </S.CloseButton>
              <Heading variant="h3" marginBottom={0}>
                Top of Book
              </Heading>
            </Flex>
            {filtersToggleButton}
          </Flex>
          <S.Divider />
        </>
      ) : (
        <Flex align="center" justify="space-between">
          <Text size="sm" colorVariant="primary">
            Top of Book
          </Text>
          {filtersToggleButton}
        </Flex>
      )}
      {showFilterTabs && (
        <>
          <FilterTabs variables={variables} handleChange={handleTabsChange} />
          <S.Divider />
        </>
      )}
    </>
  );
};
