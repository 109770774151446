import React from 'react';

import { type AccountType, CbAccountType } from '@npm/data-access';

import { NewOrganizationAccountDrawer } from './NewOrganizationAccountDrawer';
import { NewPersonAccountDrawer } from './NewPersonAccountDrawer';

type Props = {
  accountType: AccountType;
} & React.ComponentProps<typeof NewPersonAccountDrawer> &
  React.ComponentProps<typeof NewOrganizationAccountDrawer>;

export const NewAccountDrawer = ({ accountType, ...props }: Props) => {
  const Drawer =
    accountType === CbAccountType.items.PersonAccount
      ? NewPersonAccountDrawer
      : NewOrganizationAccountDrawer;

  return <Drawer {...props} />;
};
