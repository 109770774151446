import styled from 'styled-components';

import { Form as BaseForm } from '@npm/core/ui/components/atoms/Form';
import { InputFormItem as BaseInputFormItem } from '@npm/core/ui/components/atoms/FormItem';
import { useBreakpoint } from '@npm/core/ui/utils/style';

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
    flex-direction: column;
    align-items: stretch;
  `}
`;

export const Form = styled(BaseForm)`
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`;

export const InputFormItem = styled(BaseInputFormItem)`
  flex-direction: column;
  align-items: flex-start;
  padding-right: 0;

  & > .ant-form-item-control {
    flex: 1;
  }
`;

export const ConfirmationSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`;
