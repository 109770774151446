import { createGlobalStyle } from 'styled-components';

import {
  SmallTextBoldVariationSettings,
  SmallTextStyles,
} from '../components/atoms/Typography';
import { useBreakpoint } from '../utils/style';

import { StyledNotification } from '../components/atoms/Notification/Notification.styles';

import './globals.css';

export const GlobalStyle = createGlobalStyle`
  html,
  body,
  body > div:first-child {
    margin: 0;
    height: 100%;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    background: ${({ theme }) =>
      theme.color.general.layout.zero};    & > .application {
      height: 100%;
    }
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    body {
      overflow-x: hidden;
    }
  `}
  
  div,
  header,
  footer {
    box-sizing: border-box;
  }  a {
    color: ${({ theme }) => theme.color.info.typography.primary};
  }  // Antd notification styles
  ${StyledNotification}

  // Antd Dropdown Global Styles
  .ant-select-dropdown {
    background-color: ${({ theme }) => theme.color.general.layout.two};
    padding: ${({ theme }) => theme.spacing.xs}px 0;
    box-shadow: ${({ theme }) => theme.shadow.level1};
  }  // Date picker container
  .ant-picker-dropdown {
    background-color: ${({ theme }) => theme.color.general.layout.two};
    box-shadow: ${({ theme }) => theme.shadow.level1};
  }  .ant-select-item {
    padding: ${({ theme }) => `6px ${theme.spacing.lg / 2}px`};
    align-items: center;
    color: ${({ theme }) => theme.color.general.typography.primary};
    transition: background 0s ease;
  }  .ant-select-item-empty {
    padding: ${({ theme }) => `6px ${theme.spacing.lg / 2}px`};
  }  .ant-select-item-option-content {
    ${SmallTextStyles};
    overflow: auto;
    white-space: normal;
  }  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${({ theme }) =>
      theme.color.info.backgrounds.secondaryHover};
    color: ${({ theme }) => theme.color.general.typography.primary};
  }  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${({ theme }) =>
      theme.color.info.backgrounds.secondaryHover};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    color: ${({ theme }) => theme.color.general.typography.primary};
  }  .ant-picker-panel-container {
    background-color: ${({ theme }) => theme.color.general.layout.one};
    & .ant-picker-panel {
      border-bottom: none;
      & .ant-picker-header {
        border-bottom-color: ${({ theme }) =>
          theme.color.transparent};        & .ant-picker-header-view button {
          ${SmallTextBoldVariationSettings};
          color: ${({ theme }) => theme.color.general.typography.primary};
        }
        & button {
          color: ${({ theme }) => theme.color.general.typography.secondary};
        }
      }
      & .ant-picker-body {
        & thead tr th {
          color: ${({ theme }) => theme.color.general.typography.primary};
          ${SmallTextStyles};
          ${SmallTextBoldVariationSettings};
        }
        & .ant-picker-cell {
          color: ${({ theme }) => theme.color.general.borders.secondary};
        }
        & .ant-picker-cell.ant-picker-cell-in-view:not(.ant-picker-cell-disabled) {
          color: ${({ theme }) =>
            theme.color.general.typography
              .primary};          &:hover > .ant-picker-cell-inner {
            background-color: ${({ theme }) => theme.color.general.layout.two};
          }
        }
        & .ant-picker-cell.ant-picker-cell-disabled {
          color: ${({ theme }) => theme.color.general.borders.secondary};
          &:before {
            background-color: ${({ theme }) => theme.color.general.layout.two};
          }
          & .ant-picker-cell-inner {
            &:before {
              border-color: ${({ theme }) =>
                theme.color.general.borders.secondary};
            }
          }
        }
      }
      & .ant-picker-footer {
        border-top-color: ${({ theme }) => theme.color.transparent};
      }
    }
  }  .ant-drawer .ant-picker-clear {
    svg {
      color: ${({ theme }) => theme.color.general.borders.secondary};
    }
    background: transparent;
  }  .ant-modal-mask {
    background: ${({ theme }) => theme.color.general.overlay.hard};
  }  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: ${({ theme }) => theme.shadow.level3};
  }
`;
