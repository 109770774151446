/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  EdgarApi,
  EdgarCiksIndexErrorCodes,
  EdgarCiksIndexErrorTypes,
  EdgarFilingsIndexErrorCodes,
  EdgarFilingsIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new EdgarApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type EdgarCiksIndexMethod = EdgarApi['edgarCiksIndex'];
export type EdgarCiksIndexResponseType = MethodResult<EdgarCiksIndexMethod>;

export const useEdgarCiksIndex = (
  variables: Parameters<EdgarCiksIndexMethod>[0],
  config?: UseQueryConfig<
    EdgarCiksIndexMethod,
    EdgarCiksIndexErrorTypes
  >
) => {
  return useQuery<
    EdgarCiksIndexMethod,
    EdgarCiksIndexErrorTypes
  >(
    (...args) => api.edgarCiksIndex(...args),
    EdgarCiksIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EdgarCiksIndex'],
        ...apiQueryConfig.EdgarCiksIndex,
        ...config?.queryConfig
      },
    },
    'EdgarCiksIndex',
  );
};

export const useEdgarCiksIndexInfinite = (
  variables: Parameters<EdgarCiksIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    EdgarCiksIndexMethod,
    EdgarCiksIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    EdgarCiksIndexMethod,
    EdgarCiksIndexErrorTypes
  >(
    (...args) => api.edgarCiksIndex(...args),
    EdgarCiksIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EdgarCiksIndex', 'EdgarCiksIndexInfinite'],
        ...apiQueryConfig.EdgarCiksIndex,
        ...config?.queryConfig,
      },
    },
    'EdgarCiksIndex',
  );
};

export const useEdgarCiksIndexLazy = (baseOptions?: {
  variables?: Parameters<EdgarCiksIndexMethod>[0],
  config?: UseQueryConfig<
    EdgarCiksIndexMethod,
    EdgarCiksIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    EdgarCiksIndexMethod,
    EdgarCiksIndexErrorTypes
  >(
    (...args) => api.edgarCiksIndex(...args),
    EdgarCiksIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EdgarCiksIndex'],
        ...apiQueryConfig.EdgarCiksIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EdgarCiksIndex',
  );
};


export type EdgarFilingsIndexMethod = EdgarApi['edgarFilingsIndex'];
export type EdgarFilingsIndexResponseType = MethodResult<EdgarFilingsIndexMethod>;

export const useEdgarFilingsIndex = (
  variables: Parameters<EdgarFilingsIndexMethod>[0],
  config?: UseQueryConfig<
    EdgarFilingsIndexMethod,
    EdgarFilingsIndexErrorTypes
  >
) => {
  return useQuery<
    EdgarFilingsIndexMethod,
    EdgarFilingsIndexErrorTypes
  >(
    (...args) => api.edgarFilingsIndex(...args),
    EdgarFilingsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EdgarFilingsIndex'],
        ...apiQueryConfig.EdgarFilingsIndex,
        ...config?.queryConfig
      },
    },
    'EdgarFilingsIndex',
  );
};

export const useEdgarFilingsIndexInfinite = (
  variables: Parameters<EdgarFilingsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    EdgarFilingsIndexMethod,
    EdgarFilingsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    EdgarFilingsIndexMethod,
    EdgarFilingsIndexErrorTypes
  >(
    (...args) => api.edgarFilingsIndex(...args),
    EdgarFilingsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EdgarFilingsIndex', 'EdgarFilingsIndexInfinite'],
        ...apiQueryConfig.EdgarFilingsIndex,
        ...config?.queryConfig,
      },
    },
    'EdgarFilingsIndex',
  );
};

export const useEdgarFilingsIndexLazy = (baseOptions?: {
  variables?: Parameters<EdgarFilingsIndexMethod>[0],
  config?: UseQueryConfig<
    EdgarFilingsIndexMethod,
    EdgarFilingsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    EdgarFilingsIndexMethod,
    EdgarFilingsIndexErrorTypes
  >(
    (...args) => api.edgarFilingsIndex(...args),
    EdgarFilingsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EdgarFilingsIndex'],
        ...apiQueryConfig.EdgarFilingsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EdgarFilingsIndex',
  );
};

