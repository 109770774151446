import React from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography/Text';

export const DisabledTextForBroker = () => {
  return (
    <Text size="default" colorVariant="secondary">
      Brokers can’t perform this operation on behalf of their clients, please,
      contact them so they can conduct the accreditation process
    </Text>
  );
};
