import React from 'react';
import { isNil } from 'lodash';

import {
  type NumberFormatOptions,
  formatAsCompactNumber,
  formatQuantity,
  formatRange,
} from '../../../../utils/formatters';
import { TextCurrency } from '../TextCurrency';

import { Text } from './Text';
import { EMPTY_VALUE } from './Text.constants';
import { type PriceOrQuantityValue, type Props } from './Text.types';

type QuantityProps = Omit<Props, 'children' | 'valueLength'> & {
  value: PriceOrQuantityValue;
  compact?: boolean;
  colorVariant?: Props['colorVariant'];
  formatOptions?: NumberFormatOptions;
  unit?: string;
};

export const Quantity = ({
  value,
  formatOptions,
  size = 'sm',
  compact,
  colorVariant = 'primary',
  unit,
  ...props
}: QuantityProps) => {
  const formatValue = (value: QuantityProps['value']) => {
    if (Array.isArray(value))
      return formatRange(value, {
        ...formatOptions,
        ...(compact && {
          subFormatter: val => formatAsCompactNumber(val, formatOptions),
        }),
      });

    if (isNil(value)) return EMPTY_VALUE;

    return compact
      ? formatAsCompactNumber(value, formatOptions)
      : formatQuantity(value, formatOptions);
  };

  const formattedValue = formatValue(value);

  return (
    <Text
      as={'span'}
      size={size}
      colorVariant={colorVariant}
      valueLength={formattedValue.length}
      {...props}
    >
      {formattedValue}
      {unit && formattedValue !== EMPTY_VALUE && (
        <TextCurrency
          color="general"
          colorVariant="secondary"
          style={{ textTransform: 'capitalize' }}
        >
          {unit}
        </TextCurrency>
      )}
    </Text>
  );
};
