import { useEffect } from 'react';

import { DatadogService } from '@npm/utils';

import { useLocation } from '../components/molecules/Link';
import { useCurrentRoute } from '../hooks/useCurrentRoute';
import { transformQueryStringToObj } from '../utils/url';

export const useDatadogPageTracking = () => {
  // Track every route change with useLocation API
  const location = useLocation();
  const currentRoute = useCurrentRoute();

  useEffect(() => {
    let viewName = currentRoute?.route?.path || '/';
    const qs = transformQueryStringToObj(location.search);
    if (qs['step']) {
      viewName = viewName + `[step=${qs['step']}]`;
    }
    if (qs['substep']) {
      viewName = viewName + `[substep=${qs['substep']}]`;
    }
    if (qs['tab']) {
      viewName = viewName + `[tab=${qs['tab']}]`;
    }
    if (viewName) {
      DatadogService.startView(viewName);
    }
  }, [location.pathname, location.search, currentRoute]);
};
