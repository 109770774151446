/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EventMessage } from '../model';
/**
 * EventMessageApi - axios parameter creator
 * @export
 */
export const EventMessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary show event messages for specified event
         * @param {number} eventId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventEventMessageShow: async (eventId: number, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('eventEventMessageShow', 'eventId', eventId)
            const localVarPath = changeUrl(`/api/events/{event_id}/event-messages`)
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary show event messages for specified event
         * @param {number} eventId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventEventMessageSingular: async (eventId: number, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('eventEventMessageSingular', 'eventId', eventId)
            const localVarPath = changeUrl(`/api/events/{event_id}/event-message`)
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventMessageApi - functional programming interface
 * @export
 */
export const EventMessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventMessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary show event messages for specified event
         * @param {number} eventId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventEventMessageShow(eventId: number, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventEventMessageShow(eventId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary show event messages for specified event
         * @param {number} eventId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventEventMessageSingular(eventId: number, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventEventMessageSingular(eventId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventMessageApi - factory interface
 * @export
 */
export const EventMessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventMessageApiFp(configuration)
    return {
        /**
         * 
         * @summary show event messages for specified event
         * @param {number} eventId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventEventMessageShow(eventId: number, type?: string, options?: any): AxiosPromise<EventMessage> {
            return localVarFp.eventEventMessageShow(eventId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary show event messages for specified event
         * @param {number} eventId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventEventMessageSingular(eventId: number, type?: string, options?: any): AxiosPromise<EventMessage> {
            return localVarFp.eventEventMessageSingular(eventId, type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for eventEventMessageShow operation in EventMessageApi.
 * @export
 * @interface EventMessageApiEventEventMessageShowRequest
 */
export interface EventMessageApiEventEventMessageShowRequest {
    /**
     * 
     * @type {number}
     * @memberof EventMessageApiEventEventMessageShow
     */
    readonly eventId: number

    /**
     * 
     * @type {string}
     * @memberof EventMessageApiEventEventMessageShow
     */
    readonly type?: string
}

/**
 * Request parameters for eventEventMessageSingular operation in EventMessageApi.
 * @export
 * @interface EventMessageApiEventEventMessageSingularRequest
 */
export interface EventMessageApiEventEventMessageSingularRequest {
    /**
     * 
     * @type {number}
     * @memberof EventMessageApiEventEventMessageSingular
     */
    readonly eventId: number

    /**
     * 
     * @type {string}
     * @memberof EventMessageApiEventEventMessageSingular
     */
    readonly type?: string
}

/**
 * EventMessageApi - object-oriented interface
 * @export
 * @class EventMessageApi
 * @extends {BaseAPI}
 */
export class EventMessageApi extends BaseAPI {
    /**
     * 
     * @summary show event messages for specified event
     * @param {EventMessageApiEventEventMessageShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventMessageApi
     */
    public eventEventMessageShow(requestParameters: EventMessageApiEventEventMessageShowRequest, options?: AxiosRequestConfig) {
        return EventMessageApiFp(this.configuration).eventEventMessageShow(requestParameters.eventId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary show event messages for specified event
     * @param {EventMessageApiEventEventMessageSingularRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventMessageApi
     */
    public eventEventMessageSingular(requestParameters: EventMessageApiEventEventMessageSingularRequest, options?: AxiosRequestConfig) {
        return EventMessageApiFp(this.configuration).eventEventMessageSingular(requestParameters.eventId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }
}

export type EventEventMessageShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 442;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EventEventMessageShowErrorCodes = [
    401,
    442,
];

export type EventEventMessageSingularErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 442;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EventEventMessageSingularErrorCodes = [
    401,
    442,
];


