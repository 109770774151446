import styled, { type DefaultTheme } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { centerFlex, useBreakpoint } from '@npm/core/ui/utils/style';

export const paddingLg = (
  theme: DefaultTheme,
  position: 'top' | 'bottom' | 'left' | 'right'
) => `margin-${position}: ${theme.spacing.lg - 2}px`;

export const Container = styled.div`
  background-image: url(${'/public/assets/oktaBackground.png'});
  background-size: cover;
  ${centerFlex};
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.color.general.layout.one};
  overflow: auto;
`;

export const Content = styled.div`
  width: 400px;
  border: 1px solid ${({ theme }) => theme.color.general.borders.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  box-shadow: ${({ theme }) => theme.shadow.level2};
  background-color: ${({ theme }) => theme.color.general.layout.one};
  overflow: auto;

  ${({ theme }) => useBreakpoint(theme).mobile`
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    flex-direction: column;
  `}
`;

export const LogoContainer = styled.div`
  ${centerFlex};
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.xl}px;
  padding-bottom: ${({ theme }) => theme.spacing.xl}px;
  border-bottom: 1px solid
    ${({ theme }) => theme.color.general.borders.secondary};
`;

export const MainPadding = styled.div`
  padding: ${({ theme }) => `${theme.spacing.xl}px ${theme.spacing.xxl + 8}px`};

  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: ${({ theme }) => `${theme.spacing.md}px ${theme.spacing.lg}px`};
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  `}
`;

export const FullWidthButton = styled(Button)`
  height: 50px !important;
  width: 100% !important;
`;
