import React from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Statistics } from '@npm/core/ui/components/organisms/Statistics';
import { type WatchlistIndex } from '@npm/data-access';

import {
  getMinimumSizeRange,
  getTotalStats,
} from './WatchlistStatistics.utils';

type WatchlistStatsProps = {
  data: WatchlistIndex;
  isLoading?: boolean;
  companyName?: string;
};

export const WatchlistStatistics = ({
  data,
  isLoading,
  companyName,
}: WatchlistStatsProps) => {
  const { title, count } = getTotalStats(data, !!companyName);
  return (
    <Margin bottom={'md'}>
      <Statistics
        isLoading={isLoading}
        config={[
          {
            title: `Minimum Size (USD) Range • ${
              companyName ? `${companyName}` : 'All Markets'
            }`,
            value: getMinimumSizeRange(data),
            icon: <Icon name="star" />,
            variant: 'success',
            currency: 'USD',
          },
          {
            title: `${title} • ${
              companyName ? `${companyName}` : 'All Markets'
            }`,
            value: count,
            icon: <Icon name="star" />,
            variant: 'info',
          },
        ]}
      />
    </Margin>
  );
};
