import { type pathMetadataList } from '@npm/core/ui/services/pathMetadata';

type PathMetadata = typeof issuerGeneralMetadata;

export const registerIssuerGeneralMetadata = (metadata: PathMetadata) => {
  issuerGeneralMetadata.forEach(m => {
    metadata.push(m);
  });
};

export const issuerGeneralMetadata = [
  {
    pathRegex: /.*issuer-workstation\/programs\/expired-programs.*/,
    metadata: {
      screenId: 'NFE-562',
      screenName: 'Programs - Expired Programs',
    },
  },
  {
    pathRegex:
      /.*issuer-workstation\/programs\/active-programs\/\d+.*&tab=agreements/,
    metadata: {
      screenId: 'NFE-594',
      screenName: 'Active Programs - Detail - Agreements',
    },
  },
  {
    pathRegex:
      /.*issuer-workstation\/programs\/active-programs\/\d+.*&tab=holdings/,
    metadata: {
      screenId: 'NFE-598',
      screenName: 'Active Programs - Detail - Holdings',
    },
  },
  {
    pathRegex: /.*issuer-workstation\/programs\/active-programs\/\d+.*/,
    metadata: {
      screenId: 'NFE-582',
      screenName: 'Active Programs - Detail',
    },
  },
  {
    pathRegex: /.*issuer-workstation\/programs\/active-programs.*/,
    metadata: {
      screenId: 'NFE-561',
      screenName: 'Active Programs',
    },
  },
  {
    pathRegex: /.*issuer-workstation\/marketplace\/unverified-holdings.*/,
    metadata: {
      screenId: 'NFE-569',
      screenName: 'Marketplace - Unverified Holdings',
    },
  },
  {
    pathRegex: /.*issuer-workstation\/marketplace\/holdings.*/,
    metadata: {
      screenId: 'NFE-568',
      screenName: 'Marketplace - Holdings',
    },
  },
  {
    pathRegex: /.*issuer-workstation\/marketplace\/matches\/\d+.*\.*waive/,
    metadata: {
      screenId: 'NFE-982',
      screenName: 'Marketplace - Matches - Detail - Waive',
    },
  },
  {
    pathRegex: /.*issuer-workstation\/marketplace\/matches\/\d+.*\.*block/,
    metadata: {
      screenId: 'NFE-982',
      screenName: 'Marketplace - Matches - Detail - Block',
    },
  },
  {
    pathRegex: /.*issuer-workstation\/marketplace\/matches\/\d+.*\.*assign/,
    metadata: {
      screenId: 'NFE-574',
      screenName: 'Marketplace - Matches - Detail - Assign',
    },
  },
  {
    pathRegex: /.*issuer-workstation\/marketplace\/matches\/\d+.*/,
    metadata: {
      screenId: 'NFE-572',
      screenName: 'Marketplace - Matches - Detail',
    },
  },
  {
    pathRegex: /.*issuer-workstation\/marketplace\/matches.*/,
    metadata: {
      screenId: 'NFE-571',
      screenName: 'Marketplace - Matches',
    },
  },
  {
    pathRegex: /.*issuer-workstation\/marketplace\/agreements.*/,
    metadata: {
      screenId: 'NFE-576',
      screenName: 'Marketplace - Agreements',
    },
  },
  {
    pathRegex: /.*issuer-workstation\/accounts\/\d+.*/,
    metadata: {
      screenId: 'NFE-585',
      screenName: 'Account - Detail',
    },
  },
  {
    pathRegex: /.*issuer-workstation\/general\/accounts.*/,
    metadata: {
      screenId: 'NFE-563',
      screenName: 'General - Accounts',
    },
  },
  {
    pathRegex: /.*issuer-workstation\/general\/dashboard\/.*tab=financing.*/,
    metadata: {
      screenId: 'NFE-560',
      screenName: 'General - Dashboard',
    },
  },
  {
    pathRegex: /.*issuer-workstation\/general\/dashboard.*/,
    metadata: {
      screenId: 'NFE-560',
      screenName: 'General - Dashboard',
    },
  },
] as typeof pathMetadataList;
