import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { EMPTY_VALUE, Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type ExtendedColumnType,
  TABLE_BREAKPOINTS,
} from '@npm/core/ui/components/molecules/Table';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import { type VenusApi } from '@npm/data-access';

import { TypeCellTooltip } from './TypeCellTooltip';

export const getInvestmentDataColumns = ({
  onFilingsClick,
  isMobile,
  filingIdLoading,
}: {
  onFilingsClick?: (filingId: number) => void;
  isMobile: boolean;
  filingIdLoading: number | null;
}): ExtendedColumnType<VenusApi.FinancialActivity>[] => {
  return [
    {
      title: 'Type',
      key: 'type',
      render: (_, financialActivity) => (
        <Flex align="center">
          {financialActivity?.name}{' '}
          <TypeCellTooltip financialActivity={financialActivity} />
        </Flex>
      ),
    },
    {
      title: 'Date',
      key: 'date',
      render: (_, { date }) =>
        formatDate(date, {
          dateFormat: DATE_FORMATS.DATE,
        }) || EMPTY_VALUE,
    },
    {
      title: 'Investment Amount',
      key: 'investment_amount',
      render: (_, { investment_amount }) => (
        <Text.Price value={investment_amount} compact />
      ),
      responsive: TABLE_BREAKPOINTS.TABLET_SM,
    },
    {
      title: 'PPS',
      key: 'price_per_share',
      render: (_, { price_per_share }) => (
        <Text.Price
          value={price_per_share}
          formatOptions={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
          compact
        />
      ),
      responsive: TABLE_BREAKPOINTS.TABLET_SM,
    },
    {
      title: 'Estimated Valuation',
      key: 'valuation',
      render: (_, { valuation }) => (
        <Text.Price
          size="sm"
          colorVariant="primary"
          value={valuation || undefined}
          compact
        />
      ),
      align: 'right',
      responsive: TABLE_BREAKPOINTS.TABLET_SM,
    },
    {
      key: 'filings',
      render: (_, { filing_id }) =>
        filing_id != null && (
          <Button
            variant="outline"
            icon={<Icon name="file" />}
            onClick={() => onFilingsClick(filing_id)}
            loading={filingIdLoading === filing_id}
            block
          >
            {isMobile ? undefined : 'View Document'}
          </Button>
        ),
      align: 'right',
      width: isMobile ? 60 : 160,
    },
  ];
};
