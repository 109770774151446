import React from 'react';

import { ReadOnly } from '@npm/core/ui/components/molecules/ReadOnly';
import { type Organization, type Person } from '@npm/data-access';

import { prepareReadonlyData } from './TaxIdReadOnly.utils';

export type Props = {
  taxId: Organization['tax_id'] | Person['tax_id'];
  isIndividual: boolean;
  exempt?: Organization['tax_id_exempt'] | Person['tax_id_exempt'];
};

export const TaxIdReadOnly = ({ taxId, isIndividual, exempt }: Props) => {
  return <ReadOnly items={prepareReadonlyData(taxId, isIndividual, exempt)} />;
};
