/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  GenerateStnaApi,
  GenerateStnaCreateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new GenerateStnaApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type GenerateStnaCreateMethod = GenerateStnaApi['generateStnaCreate'];

export const useGenerateStnaCreate = () => {
  return useMutation<GenerateStnaCreateMethod>(
    (...args) => api.generateStnaCreate(...args),
    GenerateStnaCreateErrorCodes,
    'GenerateStnaCreate',
    '/api/generate-stnas',
    'post'
  );
};
