/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FeeConfigurationIndex } from '../model';
/**
 * FeeConfigurationApi - axios parameter creator
 * @export
 */
export const FeeConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary retrieve fee configuration for specified event
         * @param {number} eventId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feeConfigurationIndex: async (eventId: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('feeConfigurationIndex', 'eventId', eventId)
            const localVarPath = changeUrl(`/api/fee-configurations`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (eventId !== undefined) {
                localVarQueryParameter['event_id'] = eventId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeeConfigurationApi - functional programming interface
 * @export
 */
export const FeeConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeeConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary retrieve fee configuration for specified event
         * @param {number} eventId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feeConfigurationIndex(eventId: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeeConfigurationIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feeConfigurationIndex(eventId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeeConfigurationApi - factory interface
 * @export
 */
export const FeeConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeeConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary retrieve fee configuration for specified event
         * @param {number} eventId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feeConfigurationIndex(eventId: number, page?: number, size?: number, options?: any): AxiosPromise<FeeConfigurationIndex> {
            return localVarFp.feeConfigurationIndex(eventId, page, size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for feeConfigurationIndex operation in FeeConfigurationApi.
 * @export
 * @interface FeeConfigurationApiFeeConfigurationIndexRequest
 */
export interface FeeConfigurationApiFeeConfigurationIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof FeeConfigurationApiFeeConfigurationIndex
     */
    readonly eventId: number

    /**
     * 
     * @type {number}
     * @memberof FeeConfigurationApiFeeConfigurationIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof FeeConfigurationApiFeeConfigurationIndex
     */
    readonly size?: number
}

/**
 * FeeConfigurationApi - object-oriented interface
 * @export
 * @class FeeConfigurationApi
 * @extends {BaseAPI}
 */
export class FeeConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary retrieve fee configuration for specified event
     * @param {FeeConfigurationApiFeeConfigurationIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeeConfigurationApi
     */
    public feeConfigurationIndex(requestParameters: FeeConfigurationApiFeeConfigurationIndexRequest, options?: AxiosRequestConfig) {
        return FeeConfigurationApiFp(this.configuration).feeConfigurationIndex(requestParameters.eventId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }
}

export type FeeConfigurationIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const FeeConfigurationIndexErrorCodes = [
    401,
];


