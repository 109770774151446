import styled from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Select } from '@npm/core/ui/components/atoms/Select';

export const SelectOption = styled(Select.Option)`
  line-height: ${({ theme }) => theme.typography.height.sm}px;
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;
`;

export const OptionButton = styled(Button)`
  &&.ant-btn {
    padding-left: 0;
  }
  &&.ant-btn:hover,
  &&.ant-btn:focus {
    background: transparent;
  }
`;
