import styled from 'styled-components';

import { centerFlex } from '../../../../utils/style';

export const SquareIconWrapper = styled.div`
  ${centerFlex}
  height: ${({ theme }) => theme.spacing.lg}px;
  width: ${({ theme }) => theme.spacing.lg}px;
  color: ${({ theme }) => theme.color.info.typography.primary};
  background-color: ${({ theme }) =>
    theme.color.info.backgrounds.secondaryDefault};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
`;
