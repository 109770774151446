import React from 'react';

import { Helmet } from '@npm/core/ui/components/atoms/Helmet';
import { type RouteTitleProps } from '@npm/core/ui/routes/router.types';

const MAIN_TITLE = 'Agreements';

export const AgreementsTitle = ({ raw }: RouteTitleProps) => {
  // for breadcrumbs
  if (raw) {
    return <>{MAIN_TITLE}</>;
  }

  return (
    <>
      <Helmet pageTitle={MAIN_TITLE}></Helmet>
      {MAIN_TITLE}
    </>
  );
};
