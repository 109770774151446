import { Flex } from '@npm/core/ui/components/atoms/common';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { type UserRoleAggregate } from '@npm/data-access';

import { AccountButton } from './AccountButton';
import type { ExtraColumnsType } from './AccountsTable';
import { NoDataArea } from './NoDataArea';

type Props = {
  data: UserRoleAggregate[] | undefined;
  isLoading: boolean;
  isAnyFilterApplied: boolean;
  onClick: (role: UserRoleAggregate) => void;
  noDataAreaTitle?: string;
  extraColumns?: ExtraColumnsType;
};

export const AccountCards = ({
  data,
  isLoading,
  isAnyFilterApplied,
  onClick,
  noDataAreaTitle,
  extraColumns,
}: Props) => {
  if (isLoading) return <Skeleton.Base />;

  if (data?.length === 0)
    return (
      <NoDataArea
        isAnyFilterApplied={isAnyFilterApplied}
        title={noDataAreaTitle}
      />
    );

  return (
    <Flex direction="column" gap="sm">
      {data?.map((userRole, i) => (
        <AccountButton
          userRole={userRole}
          onClick={() => onClick(userRole)}
          key={userRole.id}
          extraColumns={extraColumns}
          index={i}
        />
      ))}
    </Flex>
  );
};
