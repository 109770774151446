type PathMetadata = typeof investorLiveProgramsMetadata;

export const registerInvestorLiveProgramsMetadata = (
  metadata: PathMetadata
) => {
  investorLiveProgramsMetadata.forEach(m => {
    metadata.push(m);
  });
};

export const investorLiveProgramsMetadata = [
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/sign-nda.*/,
    metadata: {
      screenId: 'NFE-502',
      screenName: 'Programs - NDAs - Signing NDAs',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+.*tab=holding.*/,
    metadata: {
      screenId: 'NFE-603',
      screenName:
        'Programs - Live Programs - Summary of the program - Holdings',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+.*tab=agreements.*/,
    metadata: {
      screenId: 'NFE-602',
      screenName:
        'Programs - Live Programs - Summary of the program - List of Agreements ',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+.*tab=faqs.*/,
    metadata: {
      screenId: 'NFE-643',
      screenName: 'Programs - Live Programs - Summary of the program - FAQ',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+.*tab=documents.*/,
    metadata: {
      screenId: 'NFE-642',
      screenName:
        'Programs - Live Programs - Summary of the program - Data Room',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submission.*[?&]step=holding_verification.*?/,
    metadata: {
      screenId: 'NFE-639',
      screenName: 'Submission - Manage Holdings',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submission.*[?&]step=manage_holdings.*?/,
    metadata: {
      screenId: 'NFE-639',
      screenName: 'Submission - Manage Holdings',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submission.*[?&]step=order_amount.*?/,
    metadata: {
      screenId: 'NFE-610',
      screenName: 'Submission - Place a sell bid',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submission.*[?&]step=bid_schedule.*?/,
    metadata: {
      screenId: 'NFE-604',
      screenName: 'Submission - Place a buy bid',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submission.*[?&]substep=personal_information_state.*?/,
    metadata: {
      screenId: 'NFE-606',
      screenName: 'Submission - Legal Information',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submission.*[?&]substep=entity_information_state.*?/,
    metadata: {
      screenId: 'NFE-606',
      screenName: 'Submission - Legal Information',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submission.*[?&]substep=spouse_information_state.*?/,
    metadata: {
      screenId: 'NFE-606',
      screenName: 'Submission - Legal Information',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submission.*[?&]substep=individual_tax_state.*?/,
    metadata: {
      screenId: 'NFE-607',
      screenName: 'Submission - Identity Verification',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submission.*[?&]substep=entity_tax_state.*?/,
    metadata: {
      screenId: 'NFE-607',
      screenName: 'Submission - Identity Verification',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submission.*[?&]substep=representative_photo_state.*?/,
    metadata: {
      screenId: 'NFE-607',
      screenName: 'Submission - Identity Verification',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submission.*[?&]substep=entity_formation_document_state.*?/,
    metadata: {
      screenId: 'NFE-607',
      screenName: 'Submission - Identity Verification',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submission.*[?&]substep=tax_information_state.*?/,
    metadata: {
      screenId: 'NFE-608',
      screenName: 'Submission - Tax Document',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submission.*[?&]substep=bank_account_state.*?/,
    metadata: {
      screenId: 'NFE-612',
      screenName: 'Submission - Payment Instructions',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submission.*[?&]substep=accredited_investor_state.*?/,
    metadata: {
      screenId: 'NFE-1427',
      screenName: 'Submission - Accreditation',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submission.*[?&]substep=add_signers.*?/,
    metadata: {
      screenId: 'NFE-1428',
      screenName: 'Submission - Authorized Signers',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submissions\/\d+\/orders\/\d+.*/,
    metadata: {
      screenId: 'NFE-641',
      screenName: 'Submission card',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/live-programs\/\d+\/submissions\/\d+.*/,
    metadata: {
      screenId: 'NFE-641',
      screenName: 'Submission card',
    },
  },
  {
    pathRegex: /.*investor-workstation\/programs\/live-programs\/\d+.*/,
    metadata: {
      screenId: 'NFE-600',
      screenName: 'Programs - Live Programs - Program Overview',
    },
  },
  {
    pathRegex: /.*investor-workstation\/programs\/live-programs.*/,
    metadata: {
      screenId: 'NFE-500',
      screenName: 'Programs - Live Programs',
    },
  },
];
