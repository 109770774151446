import { Codebooks } from './codebooks.types';

const Items = {
  Created: 'created',
  Started: 'started',
  Failed: 'failed',
  Retrying: 'retrying',
  Ended: 'ended',
} as const;

export type CbJobStatusCode = typeof Items[keyof typeof Items];

export const CbJobStatus = {
  code: Codebooks.JOB_STATUS,
  items: Items,
};
