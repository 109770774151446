import { Checkbox as AntCheckbox } from 'antd';
import styled from 'styled-components';

export const StyledCheckboxGroup = styled(AntCheckbox.Group)<{
  $isColumn?: boolean;
}>`
  &&.ant-checkbox-group {
    ${({ theme, $isColumn }) =>
      $isColumn &&
      `
        display: flex;
        flex-direction: column;

        && label {
            margin-left: 0;
            margin-bottom: ${theme.spacing.sm}px;
        }
    `}
  }
`;
