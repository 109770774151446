import React, { type ReactNode } from 'react';
import { type SelectProps as AntSelectProps, Select as AntSelect } from 'antd';

import { Icon } from '../Icon';

import { NoData } from './NoData/NoData';

import * as S from './InlineSelect.styles';

type Props = Omit<
  AntSelectProps,
  'showSearch' | 'filterOption' | 'notFoundContent'
> & {
  notFoundContent?: ReactNode | string;
  label?: string;
  readOnly?: boolean;
};

export const InlineSelect = ({
  children,
  notFoundContent = 'No data',
  readOnly,
  ...rest
}: Props) => {
  return (
    <S.StyledSelect
      $readOnly={readOnly}
      suffixIcon={<Icon name="chevron-down" size="xs" />}
      dropdownRender={dropdown => (
        <S.StyledDropdown>{dropdown}</S.StyledDropdown>
      )}
      filterOption={false}
      showSearch={false}
      notFoundContent={
        typeof notFoundContent === 'string' ? (
          <NoData text={notFoundContent} />
        ) : (
          notFoundContent
        )
      }
      {...rest}
      {...(readOnly ? { open: false } : {})}
    >
      {children}
    </S.StyledSelect>
  );
};

InlineSelect.Option = AntSelect.Option;
