import { useState } from 'react';

import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';

import { AccountInfo } from './AccountInfo';

import * as S from './AccountInfoDropdown.styles';

type Props = {
  children: React.ReactNode;
  actions?: React.ReactNode;
} & Partial<React.ComponentProps<typeof Dropdown>>;

export const AccountInfoDropdown = ({
  children,
  actions,
  ...dropdownProps
}: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleActionClick = (
    event:
      | React.TouchEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // prevent from triggering the parent
    event.preventDefault();

    setIsDropdownOpen(false);
  };

  return (
    <Dropdown
      open={isDropdownOpen}
      onOpenChange={setIsDropdownOpen}
      fixedPosition
      zIndex="aboveAll"
      overlay={
        <S.ContentWrapper>
          <S.InfoWrapper>
            <AccountInfo
              accountPanelProps={{
                omitContainerStyles: true,
                titleColor: 'success',
              }}
            />
          </S.InfoWrapper>
          {actions && (
            <S.ActionsWrapper
              onClick={handleActionClick}
              onTouchEnd={handleActionClick}
            >
              {actions}
            </S.ActionsWrapper>
          )}
        </S.ContentWrapper>
      }
      {...dropdownProps}
    >
      {children}
    </Dropdown>
  );
};
