import { type FallbackProps } from 'react-error-boundary';

import { ErrorSkeleton } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { Card } from '@npm/core/ui/components/molecules/Card';

import { CAP_TABLE_BANNER_HEADING } from './CapTableBanner';

export const CapTableBannerErrorSkeleton = (props: FallbackProps) => {
  return (
    <Card header={{ title: CAP_TABLE_BANNER_HEADING }}>
      <ErrorSkeleton {...props} dense canGoBack={false} />
    </Card>
  );
};
