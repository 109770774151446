import { useMemo } from 'react';

import { type VenusApi } from '@npm/data-access';

import { CHART_ID } from './Valuations409AChart';
import {
  type Valuation409ADataPoint,
  type Valuation409ADataValue,
} from './Valuations409AChart.types';

export const useValuations409AMinAndMaxDate = (
  data: Valuation409ADataValue[]
) => {
  return useMemo(() => {
    const dates = data
      .map(({ filing_date }) =>
        filing_date ? new Date(filing_date).valueOf() : null
      )
      .filter(date => date != null);

    const minDate = new Date(Math.min(...dates));
    const maxDate = new Date();

    return [minDate, maxDate];
  }, [data]);
};

export const sortValuations409AData = (
  data: VenusApi.FairMarketValue[] | undefined,
  order: 'asc' | 'desc' = 'asc'
) => {
  if (data == null) {
    return [];
  }

  return [...data].sort((a, b) => {
    if (order === 'asc') {
      return new Date(a.filing_date) < new Date(b.filing_date) ? -1 : 1;
    }
    return new Date(a.filing_date) > new Date(b.filing_date) ? -1 : 1;
  });
};

export const getValuations409AChartData = (
  data: Valuation409ADataValue[]
): Valuation409ADataPoint[] => {
  return data.map(row => ({
    x: row.filing_date,
    y: row.price_per_share,
    raw: row,
  }));
};

export const TOOLTIP_HEIGHT = '\n\n\n\n';
export const TOOLTIP_WIDTH = ' '.repeat(75);

export const getTooltip = (canvas: HTMLCanvasElement) => {
  return canvas.parentNode.querySelector(`#${CHART_ID}`) as HTMLElement;
};
