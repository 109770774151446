import {
  type CbLabelComponent,
  type IconNames,
  type LabelVariant,
} from '@npm/utils';
import { capitalize } from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  initial: 'initial',
  action_required: 'action_required',
  in_progress: 'in_progress',
  in_contract: 'in_contract',
  rejected: 'rejected',
  complete: 'complete',
} as const;

type Keys = keyof typeof Items;

const LabelMap: Record<Keys, LabelVariant> = {
  initial: 'info',
  action_required: 'info',
  in_progress: 'warning',
  in_contract: 'success',
  rejected: 'error',
  complete: 'success',
};

const IconMap: Record<Keys, IconNames> = {
  initial: 'info-circle',
  action_required: 'info-circle',
  in_progress: 'info-circle',
  in_contract: 'check',
  rejected: 'x-close',
  complete: 'info-circle',
};

type Attributes = 'filter_visibility';

export const CbNegotiationStatus = {
  code: Codebooks.NEGOTIATION_STATE,
  items: Items,
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem,
    canTakeAction = false
  ) => {
    if (canTakeAction) {
      return Component({
        variant: 'warning',
        label: 'Take Action',
        iconProps: { name: 'clock' },
      });
    }

    return Component({
      variant: LabelMap[(codebookItem?.code as Keys) ?? 'none'],
      label: capitalize(codebookItem?.name),
      iconProps: { name: IconMap[(codebookItem?.code as Keys) ?? ''] },
    });
  },
  getAttrByName: (item: CodebookItem, name: Attributes) =>
    item.attributes?.find(q => q.name === name),
};
