/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DestroySuccess } from '../model';
// @ts-ignore
import { ErrorValidation } from '../model';
// @ts-ignore
import { IssuerEntitiesPreferredBuyerAggregate } from '../model';
// @ts-ignore
import { IssuerEntitiesPreferredBuyerCreateRequestContract } from '../model';
/**
 * PreferredBuyerApi - axios parameter creator
 * @export
 */
export const PreferredBuyerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create preferred buyer account
         * @param {number} issuerEntityId 
         * @param {IssuerEntitiesPreferredBuyerCreateRequestContract} issuerEntitiesPreferredBuyerCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preferredBuyerCreate: async (issuerEntityId: number, issuerEntitiesPreferredBuyerCreateRequestContract: IssuerEntitiesPreferredBuyerCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerEntityId' is not null or undefined
            assertParamExists('preferredBuyerCreate', 'issuerEntityId', issuerEntityId)
            // verify required parameter 'issuerEntitiesPreferredBuyerCreateRequestContract' is not null or undefined
            assertParamExists('preferredBuyerCreate', 'issuerEntitiesPreferredBuyerCreateRequestContract', issuerEntitiesPreferredBuyerCreateRequestContract)
            const localVarPath = changeUrl(`/api/issuer-entities/{issuer_entity_id}/preferred-buyers`)
                .replace(`{${"issuer_entity_id"}}`, encodeURIComponent(String(issuerEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issuerEntitiesPreferredBuyerCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary set a specific preferred buyer to not preferred
         * @param {number} id 
         * @param {number} issuerEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preferredBuyerDestroy: async (id: number, issuerEntityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('preferredBuyerDestroy', 'id', id)
            // verify required parameter 'issuerEntityId' is not null or undefined
            assertParamExists('preferredBuyerDestroy', 'issuerEntityId', issuerEntityId)
            const localVarPath = changeUrl(`/api/issuer-entities/{issuer_entity_id}/preferred-buyers/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"issuer_entity_id"}}`, encodeURIComponent(String(issuerEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PreferredBuyerApi - functional programming interface
 * @export
 */
export const PreferredBuyerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PreferredBuyerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create preferred buyer account
         * @param {number} issuerEntityId 
         * @param {IssuerEntitiesPreferredBuyerCreateRequestContract} issuerEntitiesPreferredBuyerCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async preferredBuyerCreate(issuerEntityId: number, issuerEntitiesPreferredBuyerCreateRequestContract: IssuerEntitiesPreferredBuyerCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuerEntitiesPreferredBuyerAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.preferredBuyerCreate(issuerEntityId, issuerEntitiesPreferredBuyerCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary set a specific preferred buyer to not preferred
         * @param {number} id 
         * @param {number} issuerEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async preferredBuyerDestroy(id: number, issuerEntityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroySuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.preferredBuyerDestroy(id, issuerEntityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PreferredBuyerApi - factory interface
 * @export
 */
export const PreferredBuyerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PreferredBuyerApiFp(configuration)
    return {
        /**
         * 
         * @summary create preferred buyer account
         * @param {number} issuerEntityId 
         * @param {IssuerEntitiesPreferredBuyerCreateRequestContract} issuerEntitiesPreferredBuyerCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preferredBuyerCreate(issuerEntityId: number, issuerEntitiesPreferredBuyerCreateRequestContract: IssuerEntitiesPreferredBuyerCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<IssuerEntitiesPreferredBuyerAggregate> {
            return localVarFp.preferredBuyerCreate(issuerEntityId, issuerEntitiesPreferredBuyerCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary set a specific preferred buyer to not preferred
         * @param {number} id 
         * @param {number} issuerEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preferredBuyerDestroy(id: number, issuerEntityId: number, options?: any): AxiosPromise<DestroySuccess> {
            return localVarFp.preferredBuyerDestroy(id, issuerEntityId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for preferredBuyerCreate operation in PreferredBuyerApi.
 * @export
 * @interface PreferredBuyerApiPreferredBuyerCreateRequest
 */
export interface PreferredBuyerApiPreferredBuyerCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof PreferredBuyerApiPreferredBuyerCreate
     */
    readonly issuerEntityId: number

    /**
     * 
     * @type {IssuerEntitiesPreferredBuyerCreateRequestContract}
     * @memberof PreferredBuyerApiPreferredBuyerCreate
     */
    readonly issuerEntitiesPreferredBuyerCreateRequestContract: IssuerEntitiesPreferredBuyerCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof PreferredBuyerApiPreferredBuyerCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof PreferredBuyerApiPreferredBuyerCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof PreferredBuyerApiPreferredBuyerCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof PreferredBuyerApiPreferredBuyerCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof PreferredBuyerApiPreferredBuyerCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof PreferredBuyerApiPreferredBuyerCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for preferredBuyerDestroy operation in PreferredBuyerApi.
 * @export
 * @interface PreferredBuyerApiPreferredBuyerDestroyRequest
 */
export interface PreferredBuyerApiPreferredBuyerDestroyRequest {
    /**
     * 
     * @type {number}
     * @memberof PreferredBuyerApiPreferredBuyerDestroy
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PreferredBuyerApiPreferredBuyerDestroy
     */
    readonly issuerEntityId: number
}

/**
 * PreferredBuyerApi - object-oriented interface
 * @export
 * @class PreferredBuyerApi
 * @extends {BaseAPI}
 */
export class PreferredBuyerApi extends BaseAPI {
    /**
     * 
     * @summary create preferred buyer account
     * @param {PreferredBuyerApiPreferredBuyerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreferredBuyerApi
     */
    public preferredBuyerCreate(requestParameters: PreferredBuyerApiPreferredBuyerCreateRequest, options?: AxiosRequestConfig) {
        return PreferredBuyerApiFp(this.configuration).preferredBuyerCreate(requestParameters.issuerEntityId, requestParameters.issuerEntitiesPreferredBuyerCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary set a specific preferred buyer to not preferred
     * @param {PreferredBuyerApiPreferredBuyerDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreferredBuyerApi
     */
    public preferredBuyerDestroy(requestParameters: PreferredBuyerApiPreferredBuyerDestroyRequest, options?: AxiosRequestConfig) {
        return PreferredBuyerApiFp(this.configuration).preferredBuyerDestroy(requestParameters.id, requestParameters.issuerEntityId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type PreferredBuyerCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PreferredBuyerCreateErrorCodes = [
    401,
    422,
];

export type PreferredBuyerDestroyErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PreferredBuyerDestroyErrorCodes = [
    401,
];


