import React, { useMemo } from 'react';

import { ReadOnly } from '@npm/core/ui/components/molecules/ReadOnly';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type DocumentAggregate } from '@npm/data-access';

import { prepareReadOnlyData } from './GovernmentIdReadOnly.utils';

type Props = {
  data: DocumentAggregate;
};

export const GovernmentIdReadOnly = ({ data }: Props) => {
  const { isDesktop } = useBreakpoints();

  const readOnlyData = useMemo(
    () => prepareReadOnlyData(data, isDesktop),
    [data, isDesktop]
  );

  return <ReadOnly items={readOnlyData} />;
};
