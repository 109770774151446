import React from 'react';

import { Label } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import * as S from './styles';

type Props = {
  totalRecords: number;
};

export const TotalRecordsLabel = ({ totalRecords }: Props) => {
  return (
    <S.ResultsLabelContainer>
      <Label variant="general" round>
        <Text size="sm" colorVariant="secondary">
          {totalRecords} {totalRecords === 1 ? 'result' : 'results'}
        </Text>
      </Label>
    </S.ResultsLabelContainer>
  );
};
