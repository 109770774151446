import { format as formatDate } from 'date-fns';

import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { DateTimePickerInput } from '@npm/core/ui/components/atoms/Input';
import { CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import { DATE_FORMATS, getTimezoneShort } from '@npm/core/ui/utils/formatters';

type Props = {
  value: Date | undefined;
  onItemChange: () => void;
  key: string;
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const PublishedAtFormSection = ({
  value,
  onItemChange,
  ...props
}: Props) => {
  return (
    <CollapsibleFormSection.Item
      label={`Published At (${getTimezoneShort()})`}
      preview={getPublishedAtPreview(value)}
      {...props}
    >
      <FormItem name="orderPublishedDate" marginBottom="sm">
        <DateTimePickerInput
          max={new Date()}
          defaultTime="endOfDay"
          allowClear
        />
      </FormItem>
    </CollapsibleFormSection.Item>
  );
};

export const getPublishedAtPreview = (value: Date | undefined) =>
  value ? formatDate(value, DATE_FORMATS.FULL) : 'Now';
