import { useMemo } from 'react';

import { type CodebookItem } from '@npm/data-access';
import { CbAssetType } from '@npm/data-access';

const ALLOWED_ASSET_TYPE_CODES = [
  CbAssetType.items.AnyShareClass,
  CbAssetType.items.CommonStock,
  CbAssetType.items.PreferredStock,
];

export function useAllowedAssetTypeOptions(assetTypes: CodebookItem[]) {
  return useMemo(() => {
    const allowedAssetTypes = assetTypes.filter(x =>
      ALLOWED_ASSET_TYPE_CODES.some(ea => x.code === ea)
    );

    return allowedAssetTypes?.map(asset => ({
      label: asset?.name,
      value: asset?.code,
    }));
  }, [assetTypes]);
}

export const getAssetTypePreview = (
  options: { value: string; label: string }[],
  val?: string
) => options.find(i => i.value === val)?.label;

export const NOTE_MAX_LENGTH = 500;
export const NOTE_PREVIEW_MAX_LENGTH = 35;

export const getNotePreview = (note: string, maxChars: number): string => {
  if (note?.length > maxChars) {
    return `${note?.substring(0, maxChars)}...`;
  }
  return note;
};
