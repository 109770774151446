import type { ReactNode } from 'react';
import React from 'react';

import { BottomNavigation } from '@npm/core/ui/components/atoms/BottomNavigation';
import { useLayoutConfig } from '@npm/core/ui/components/organisms/Layout';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import * as S from './SecondMarketHeader.styles';

type Props = {
  rightControls: ReactNode;
  companySelect: ReactNode;
  tabs: ReactNode;
};

export const IndividualSecondMarketHeader = ({
  rightControls,
  companySelect,
  tabs,
}: Props) => {
  const { isMobile, isTablet } = useBreakpoints();
  const { header } = useLayoutConfig();

  return (
    <>
      <S.Container $appHeaderHeight={header.height}>
        <S.Left>
          <S.IndividualCompanySelect>{companySelect}</S.IndividualCompanySelect>
        </S.Left>
        <S.Middle>{tabs}</S.Middle>
        <S.Right>{!isMobile && !isTablet && rightControls}</S.Right>
      </S.Container>
      {(isMobile || isTablet) && !!rightControls && (
        <BottomNavigation
          nextButton={rightControls}
          getScrollableElement={() =>
            document.querySelector('#ant-layout') as HTMLElement
          }
          hideOnScrollDown
        />
      )}
    </>
  );
};
