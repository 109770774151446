import { Space } from 'antd';
import { useTheme } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import { type VenusApi } from '@npm/data-access';

import { CorporateProfileSkeleton } from '../skeletons';

import * as S from './CompanyReport.styles';

type Props = {
  companyProfile?: VenusApi.CompanyProfile;
  isLoading?: boolean;
};

export const CompanyReport = ({ companyProfile, isLoading }: Props) => {
  const theme = useTheme();

  if (isLoading) return <CorporateProfileSkeleton />;

  return (
    <Space direction="vertical" size="middle">
      <Space direction="horizontal">
        <Label
          variant="info"
          style={{ padding: `${theme.spacing.xs}px` }}
          icon={
            <Icon
              name="lightning"
              color={theme.color.info.typography.primary}
            />
          }
        >
          New
        </Label>

        <Heading variant="h1" as="h3">
          Equity Research Report
        </Heading>
      </Space>

      <Space direction="horizontal">
        <Text size="default" colorVariant="tertiary">
          Last Updated
        </Text>

        <Text>
          {formatDate(companyProfile.sacra_date, {
            dateFormat: DATE_FORMATS.DATE,
          })}
        </Text>

        <Text
          size="default"
          colorVariant="tertiary"
          style={{ marginLeft: '-2px' }}
        >
          • Powered by
        </Text>

        <S.LinkProvider href="https://sacra.com/" target="_blank">
          Sacra
        </S.LinkProvider>
      </Space>

      <Text>{companyProfile.sacra_text}</Text>

      <Button
        iconPosition="right"
        size="lg"
        icon={<Icon name={'external-link'} size={'sm'} />}
        target="_blank"
        href={companyProfile.sacra_url}
        style={{ maxWidth: '140px' }}
      >
        Access Report
      </Button>
    </Space>
  );
};
