import React, { useMemo, useState } from 'react';
import type { PaginationProps } from 'antd';

import { Table } from '@npm/core/ui/components/molecules/Table';
import { useLocalPagination } from '@npm/core/ui/hooks/pagination/usePagination';
import { type CapTableApi, type CompanyPricingApi } from '@npm/data-access';

import {
  DocumentViewerModal,
  useDocumentViewer,
} from '../../../../../documents/DocumentViewer';
import { sortMtmData } from '../MutualFundMarksChart/MutualFundMarksChart.utils';

import { type ReportingPeriodFilterItem } from './ReportingPeriodFilter/ReportingPeriodFilter.types';
import { getMutualFundMarksColumns } from './MutualFundMarks.columns';
import { ReportingPeriodFilter } from './ReportingPeriodFilter';

import * as S from './MutualFundMarksTable.styles';

type Props = {
  data: CompanyPricingApi.Mtm[];
};

export const MutualFundMarksTable = ({ data }: Props) => {
  const { documentViewerModalProps, showDocumentPreview } = useDocumentViewer();

  const [selectedReportingPeriod, setSelectedReportingPeriod] =
    useState<ReportingPeriodFilterItem>(null);

  const sortedData = useMemo(() => sortMtmData(data, 'desc'), [data]);

  const filteredData = useMemo(() => {
    if (!selectedReportingPeriod) {
      return sortedData;
    }

    return sortedData.filter(
      item =>
        item.reporting_period >= selectedReportingPeriod.startDate &&
        item.reporting_period <= selectedReportingPeriod.endDate
    );
  }, [sortedData, selectedReportingPeriod]);

  const [localPagination, setLocalPagination] = useState<
    Pick<CapTableApi.SecurityIndexRequestContract, 'page' | 'size'>
  >({ page: 1, size: 5 });

  const { pagination } = useLocalPagination({
    paginationData: {
      ...localPagination,
      total_records: filteredData.length,
    },
  });

  const dataSource = useMemo(() => {
    return filteredData.map((item, index) => ({
      ...item,
      rowKey: `${item.mutual_fund_series_name}-${item.mutual_fund_parent_name}-${item.mutual_fund_asset_name}-${item.filing_date}`,
    }));
  }, [filteredData]);

  const paginationOnChange = (paginationProps: PaginationProps) =>
    setLocalPagination(oldVariables => ({
      page:
        oldVariables.size !== paginationProps.pageSize
          ? 1
          : paginationProps.current,
      size: paginationProps.pageSize,
    }));

  return (
    <S.Card
      variant="secondMarket"
      header={{
        title: 'Mutual Funds Mark to Market',
        label: (
          <ReportingPeriodFilter
            selectedReportingPeriod={selectedReportingPeriod}
            onSelect={reportingPeriod => {
              setLocalPagination(localPagination => ({
                ...localPagination,
                page: 1,
              }));
              setSelectedReportingPeriod(reportingPeriod);
            }}
          />
        ),
      }}
      noContentPadding
    >
      <DocumentViewerModal {...documentViewerModalProps} />
      <Table<CompanyPricingApi.Mtm>
        dataSource={dataSource}
        rowKey={'rowKey'}
        columns={getMutualFundMarksColumns(showDocumentPreview)}
        outlined={false}
        expandable={{
          columnWidth: '20px',
          showExpandColumn: true,
          rowExpandable: () => true,
        }}
        onChange={paginationOnChange}
        pagination={pagination}
      />
    </S.Card>
  );
};
