import { useCallback, useState } from 'react';

import { useFrontendPaginationWithQuery } from '@npm/core/ui/hooks/pagination/usePagination';
import { type VenusApi } from '@npm/data-access';

import { getTableRowClassName } from './InvestmentDataTable/InvestmentDataTable.utils';

export const useExpandInvestmentTableRowByStatClick = (
  visibleRows: VenusApi.FinancialActivity[]
) => {
  const [defaultExpandedRowKeys, setDefaultExpandedRowKeys] =
    useState<string[]>();

  const { pagination, updatePaginationVariables, query, resetQuery } =
    useFrontendPaginationWithQuery('finActivities');

  const findAndScrollToRow = useCallback(
    (recordIndex: number, recordId: number) => {
      const currentPage = query || pagination?.current;
      const page = Math.ceil(
        (recordIndex + 1) /
          (pagination?.pageSize || pagination?.defaultPageSize)
      );

      if (page !== currentPage) {
        resetQuery();
        updatePaginationVariables({
          ...pagination,
          current: page,
        });
      }

      setTimeout(() => {
        document
          .querySelector(`.${getTableRowClassName(recordId)}`)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    },
    [pagination, query]
  );

  const onStatItemClick = useCallback(
    (recordId: number) => {
      if (recordId) {
        const recordIndex = visibleRows?.findIndex(
          activity => activity.id === recordId
        );

        if (recordIndex >= 0) {
          const rowKey =
            visibleRows?.[recordIndex]?.id?.toString() + recordIndex;
          setDefaultExpandedRowKeys(rowKey ? [rowKey] : []);
          findAndScrollToRow(recordIndex, recordId);
        }
      }
    },
    [findAndScrollToRow, visibleRows]
  );

  return {
    pagination: {
      ...pagination,
      current: query || pagination?.current,
      total: visibleRows?.length,
    },
    setPagination: updatePaginationVariables,
    defaultExpandedRowKeys,
    onStatItemClick,
  };
};
