import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

/**
 * A custom React hook that extends the useEffect hook to use deep comparison for dependencies.
 *
 * @param {Function} effect
 * @param {Array} dependencies
 */

export const useDeepCompareEffect = (effect, dependencies) => {
  const currentDependenciesRef = useRef();
  if (!isEqual(currentDependenciesRef.current, dependencies)) {
    currentDependenciesRef.current = dependencies;
  }
  useEffect(effect, [currentDependenciesRef.current]);
};

export default useDeepCompareEffect;
