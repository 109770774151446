import React, { useCallback } from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { VALIDATION_RULES } from '@npm/core/ui/components/atoms/FormItem';
import { ReCaptchaFormItem } from '@npm/core/ui/components/atoms/ReCaptchaFormItem';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { AlertContainer } from '@npm/core/ui/components/molecules/AlertContainer';
import { type RegistrationCreateRequestContract } from '@npm/data-access';

import * as S from './OktaForm.styles';

type FormValues = RegistrationCreateRequestContract;

type Props = {
  onSignIn: () => void;
  onSubmit: (values: RegistrationCreateRequestContract) => void;
  isLoading?: boolean;
};

export const OktaForm = ({ onSubmit, isLoading, onSignIn }: Props) => {
  const [form] = Form.useForm<FormValues>();

  const onSubmitHandler = useCallback(async () => {
    let values: FormValues = null;
    try {
      values = await form.validateFields();
    } catch (e) {
      console.error(e);
      return;
    }

    await onSubmit({
      ...values,
    });
  }, [form, onSubmit]);

  const onLearnMoreClick = () =>
    window.open('https://www.npm.com/privacy-policy/');

  return (
    <>
      <S.Header>
        <S.Heading variant="h3" marginBottom="lg">
          Sign Up
        </S.Heading>
        <Text size="sm">
          Enter your{' '}
          <Text size="sm" as="span" weight="bold" colorVariant="primary">
            legal name
          </Text>{' '}
          below and provide your email address to gain access to this portal.
        </Text>
      </S.Header>
      <AlertContainer />
      <Form form={form} onFinish={onSubmitHandler}>
        <S.FormItem
          rules={[VALIDATION_RULES.required('First name')]}
          name="first"
          label="First Name"
        >
          <S.Input disabled={isLoading} placeholder="John" />
        </S.FormItem>
        <S.FormItem name="middle" label="Middle Name">
          <S.Input disabled={isLoading} />
        </S.FormItem>
        <S.FormItem
          rules={[VALIDATION_RULES.required('Last name')]}
          name="last"
          label="Last Name"
        >
          <S.Input disabled={isLoading} placeholder="Smith" />
        </S.FormItem>
        <S.FormItem
          rules={[
            VALIDATION_RULES.required('Email'),
            {
              ...VALIDATION_RULES.email(),
              validateTrigger: 'onSubmit',
            },
          ]}
          name="email"
          label="Email"
        >
          <S.Input disabled={isLoading} placeholder="johnsmith123@npm.com" />
        </S.FormItem>
        <S.FormItem
          name="recaptcha"
          rules={[VALIDATION_RULES.required('Captcha')]}
        >
          <ReCaptchaFormItem theme="light" />
        </S.FormItem>
      </Form>

      <S.SubmitBtnContainer>
        <S.FullWidthButton loading={isLoading} onClick={form.submit}>
          Submit
        </S.FullWidthButton>
      </S.SubmitBtnContainer>

      <S.BottomLinks>
        <S.TextLink size="sm" onClick={onSignIn}>
          Sign In
        </S.TextLink>
        <S.TextLink size="sm" onClick={onLearnMoreClick}>
          Learn More about NPM
        </S.TextLink>
      </S.BottomLinks>
    </>
  );
};
