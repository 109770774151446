import { Form } from '@npm/core/ui/components/atoms/Form';

import { FilterPanel } from '../../../../../filters';
import { FilterDropdown } from '../../../../../filters/filters/CollapsibleFilterPanel/FilterDropdown';
import type { MutualFundFilterValues } from '../../MutualFundMarks.types';

import { getMutualFundMarksFilterItems } from './MutualFundMarksFilter.config';
import { type MutualFundMarksFilterFormValues } from './MutualFundMarksFilter.types';

type Props = {
  filterValues: MutualFundFilterValues;
  activeFilters: number;
  onSubmit: (values: MutualFundMarksFilterFormValues) => void;
};

export const MutualFundMarksFilter = ({
  filterValues,
  activeFilters,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm<MutualFundMarksFilterFormValues>();

  return (
    <FilterDropdown totalActiveFilters={activeFilters}>
      <FilterPanel
        defaultColumnProps={{ span: 24 }}
        form={form}
        filters={getMutualFundMarksFilterItems(filterValues)}
        handleSubmit={onSubmit}
        totalActiveFilters={activeFilters}
      />
    </FilterDropdown>
  );
};
