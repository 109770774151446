/* eslint-disable @typescript-eslint/no-explicit-any */
import create from 'zustand';

export interface PreservableQueryParamsStore {
  preservableQueryParams: Record<string, Record<string, any>>;
  setPreservableQueryParams: (
    group: string,
    preservableQueryParams: Record<string, any>,
    overwriteGroupParams?: boolean
  ) => void;
  getPreservableQueryParams: (route: string) => Record<string, any>;
}

export const usePreservableQueryParamsStore =
  create<PreservableQueryParamsStore>((set, get) => ({
    preservableQueryParams: {},
    setPreservableQueryParams: (
      group,
      preservableQueryParams,
      overwriteGroupParams = true
    ) => {
      const currentPreservableQueryParams = get().preservableQueryParams;

      set({
        preservableQueryParams: {
          ...currentPreservableQueryParams,
          [group]: {
            ...(!overwriteGroupParams && currentPreservableQueryParams[group]),
            ...preservableQueryParams,
          },
        },
      });
    },
    getPreservableQueryParams: route => {
      const workstationFromPathname = route.split('/')[1];

      let res = {};
      Object.getOwnPropertyNames(get().preservableQueryParams).forEach(i => {
        if (i !== workstationFromPathname && i !== 'role') {
          return;
        }
        res = { ...res, ...(get().preservableQueryParams[i] || {}) };
      });
      return res;
    },
  }));
