import React, { type ComponentProps } from 'react';

import { IssuanceSelect } from '../../smartSelects';
import { type FilterItemProps } from '../FilterPanel';

type Props = FilterItemProps & ComponentProps<typeof IssuanceSelect>;

export const IssuanceFilter = ({ name, onFilterSubmit, ...rest }: Props) => {
  return (
    <IssuanceSelect
      onSelect={() => {
        onFilterSubmit?.(name);
      }}
      onClear={() => onFilterSubmit?.(name)}
      {...rest}
    />
  );
};
