import styled from 'styled-components';

import { centerFlex, useBreakpoint } from '../../../../../utils/style';

export const HAMBURGER_BUTTON_DIMENSION = 40;

export const HamburgerButton = styled.button`
  z-index: ${({ theme }) => theme.zIndex.drawer};
  position: fixed;
  top: ${({ theme }) => theme.spacing.md}px;
  right: ${({ theme }) => theme.spacing.md}px;
  width: ${HAMBURGER_BUTTON_DIMENSION}px;
  height: ${HAMBURGER_BUTTON_DIMENSION}px;
  ${centerFlex};
  background: ${({ theme }) => theme.color.general.layout.two};
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  box-shadow: ${({ theme }) => theme.shadow.level3};

  svg {
    color: ${({ theme }) => theme.color.general.typography.primary};
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    top: ${theme.spacing.sm}px;
    right: ${theme.spacing.sm}px;
  `}
`;
