import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  ACCOUNT: 'account',
  BROKERAGE_FIRM: 'brokerage_firm',
  EVENT: 'event',
  ISSUER_ENTITY: 'issuer_entity',
  SUBJECT: 'subject',
} as const;

export const CbSubjectType = {
  code: Codebooks.SUBJECT_TYPE,
  items: Items,
  getCbByCode: (code: string, cbs: CodebookItem[]) =>
    cbs.find(cb => cb.code === code),
};
