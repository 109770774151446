import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useTheme } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';

import { Result } from '../../routes/components/ErrorPath/ErrorPath.styles';

export const LogoutPage = () => {
  const { oktaAuth } = useOktaAuth();
  const theme = useTheme();

  const logout = async () => {
    await oktaAuth.signOut({
      revokeRefreshToken: true,
      revokeAccessToken: true,
      clearTokensBeforeRedirect: true,
    });
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <Result
      icon={
        <Icon
          name="logout"
          size="xxl"
          color={theme.color.general.typography.tertiary}
        />
      }
      title={
        <Heading marginBottom="xs" variant="h1">
          You are about to be logged off
        </Heading>
      }
      subTitle={
        <Text size="sm" colorVariant="secondary">
          If you aren&apos;t redirected automatically, please press button below
        </Text>
      }
      extra={
        <Button variant={'outline'} onClick={logout}>
          Logout
        </Button>
      }
    />
  );
};

export default LogoutPage;
