/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  ConvertibleIssuancesApi,
  ListConvertibleIssuancesErrorCodes,
  ListConvertibleIssuancesErrorTypes,
  GetConvertibleIssuanceErrorCodes,
  GetConvertibleIssuanceErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new ConvertibleIssuancesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type ListConvertibleIssuancesMethod = ConvertibleIssuancesApi['listConvertibleIssuances'];
export type ListConvertibleIssuancesResponseType = MethodResult<ListConvertibleIssuancesMethod>;

export const useListConvertibleIssuances = (
  variables: Parameters<ListConvertibleIssuancesMethod>[0],
  config?: UseQueryConfig<
    ListConvertibleIssuancesMethod,
    ListConvertibleIssuancesErrorTypes
  >
) => {
  return useQuery<
    ListConvertibleIssuancesMethod,
    ListConvertibleIssuancesErrorTypes
  >(
    (...args) => api.listConvertibleIssuances(...args),
    ListConvertibleIssuancesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListConvertibleIssuances'],
        ...apiQueryConfig.ListConvertibleIssuances,
        ...config?.queryConfig
      },
    },
    'ListConvertibleIssuances',
  );
};

export const useListConvertibleIssuancesInfinite = (
  variables: Parameters<ListConvertibleIssuancesMethod>[0],
  config?: UseInfiniteQueryConfig<
    ListConvertibleIssuancesMethod,
    ListConvertibleIssuancesErrorTypes
  >
) => {
  return useInfiniteQuery<
    ListConvertibleIssuancesMethod,
    ListConvertibleIssuancesErrorTypes
  >(
    (...args) => api.listConvertibleIssuances(...args),
    ListConvertibleIssuancesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListConvertibleIssuances', 'ListConvertibleIssuancesInfinite'],
        ...apiQueryConfig.ListConvertibleIssuances,
        ...config?.queryConfig,
      },
    },
    'ListConvertibleIssuances',
  );
};

export const useListConvertibleIssuancesLazy = (baseOptions?: {
  variables?: Parameters<ListConvertibleIssuancesMethod>[0],
  config?: UseQueryConfig<
    ListConvertibleIssuancesMethod,
    ListConvertibleIssuancesErrorTypes
  >
}) => {
  return useLazyQuery<
    ListConvertibleIssuancesMethod,
    ListConvertibleIssuancesErrorTypes
  >(
    (...args) => api.listConvertibleIssuances(...args),
    ListConvertibleIssuancesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['ListConvertibleIssuances'],
        ...apiQueryConfig.ListConvertibleIssuances,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'ListConvertibleIssuances',
  );
};


export type GetConvertibleIssuanceMethod = ConvertibleIssuancesApi['getConvertibleIssuance'];
export type GetConvertibleIssuanceResponseType = MethodResult<GetConvertibleIssuanceMethod>;

export const useGetConvertibleIssuance = (
  variables: Parameters<GetConvertibleIssuanceMethod>[0],
  config?: UseQueryConfig<
    GetConvertibleIssuanceMethod,
    GetConvertibleIssuanceErrorTypes
  >
) => {
  return useQuery<
    GetConvertibleIssuanceMethod,
    GetConvertibleIssuanceErrorTypes
  >(
    (...args) => api.getConvertibleIssuance(...args),
    GetConvertibleIssuanceErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetConvertibleIssuance'],
        ...apiQueryConfig.GetConvertibleIssuance,
        ...config?.queryConfig
      },
    },
    'GetConvertibleIssuance',
  );
};

export const useGetConvertibleIssuanceInfinite = (
  variables: Parameters<GetConvertibleIssuanceMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetConvertibleIssuanceMethod,
    GetConvertibleIssuanceErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetConvertibleIssuanceMethod,
    GetConvertibleIssuanceErrorTypes
  >(
    (...args) => api.getConvertibleIssuance(...args),
    GetConvertibleIssuanceErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetConvertibleIssuance', 'GetConvertibleIssuanceInfinite'],
        ...apiQueryConfig.GetConvertibleIssuance,
        ...config?.queryConfig,
      },
    },
    'GetConvertibleIssuance',
  );
};

export const useGetConvertibleIssuanceLazy = (baseOptions?: {
  variables?: Parameters<GetConvertibleIssuanceMethod>[0],
  config?: UseQueryConfig<
    GetConvertibleIssuanceMethod,
    GetConvertibleIssuanceErrorTypes
  >
}) => {
  return useLazyQuery<
    GetConvertibleIssuanceMethod,
    GetConvertibleIssuanceErrorTypes
  >(
    (...args) => api.getConvertibleIssuance(...args),
    GetConvertibleIssuanceErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetConvertibleIssuance'],
        ...apiQueryConfig.GetConvertibleIssuance,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetConvertibleIssuance',
  );
};

