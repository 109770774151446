import React from 'react';

import * as S from './PulsingDot.styles';

type Props = {
  color?: 'info' | 'success';
};

export const PulsingDot = ({ color = 'info' }: Props) => {
  return <S.Dot $color={color} />;
};
