import { CbWorkstationType } from '@npm/data-access';

import { useCurrentWorkstation } from './useCurrentWorkstation';
import { useObo } from './useObo';

export const useIsInvestor = () => {
  const workstation = useCurrentWorkstation();
  const { isObo } = useObo();
  const isInvestorWS =
    workstation?.type?.code === CbWorkstationType.items.investor;
  return isInvestorWS && !isObo;
};
