import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import { MarkAllReadButton } from './components/MarkAllReadButton/MarkAllReadButton';
import { NotificationCategorySelect } from './components/NotificationCategorySelect/NotificationCategorySelect';
import { NotificationList } from './components/NotificationList/NotificationList';
import { NotificationSettingsModal } from './components/NotificationSettingsModal/NotificationSettingsModal';

import * as S from './NotificationDrawer.styles';
import { NoPaddingButton } from './NotificationDrawer.styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const NotificationDrawer = ({ isOpen, onClose }: Props) => {
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
  const [notificationCategory, setNotificationCategory] = useState<
    string | undefined
  >(undefined);

  const theme = useTheme();
  const { isMobile, isSmTablet, isTablet } = useBreakpoints();

  const hasFloatingMenuButton = isMobile || isSmTablet || isTablet;

  return (
    <>
      <S.Drawer
        open={isOpen}
        onClose={onClose}
        isFullHeight={true}
        placement={!hasFloatingMenuButton ? 'left' : 'right'}
        zIndex={theme.zIndex.aboveAll}
        destroyOnClose={true}
        title={
          <Flex align={'center'} justify="space-between" style={{ flex: 1 }}>
            <Flex align={'center'} justify="flex-start" gap={'sm'}>
              <Text>Notifications</Text>
              <NotificationCategorySelect onChange={setNotificationCategory} />
            </Flex>
            <Flex align={'center'} justify="flex-end" gap={'xs'}>
              <MarkAllReadButton />
              <NoPaddingButton
                variant="text"
                icon={<Icon name="settings" />}
                onClick={() => setSettingsModalOpen(true)}
              />
            </Flex>
          </Flex>
        }
      >
        <NotificationList notificationCategory={notificationCategory} />
      </S.Drawer>
      <NotificationSettingsModal
        visible={isSettingsModalOpen}
        setVisible={setSettingsModalOpen}
      />
    </>
  );
};
