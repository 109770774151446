import { useCallback, useEffect, useMemo } from 'react';
import { isMatch } from 'lodash';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { handleValidationError } from '@npm/core/ui/utils/form';
import {
  type OrganizationAggregate,
  useOrganizationShow,
  useOrganizationUpdate,
} from '@npm/data-access';

import { getContactInformationInitialValues } from '../ContactInfoForm/ContactInformationForm.utils';

import { type EntityInfoFormType } from './EntityInformation.types';

export const useEntityInformationForm = (
  id: OrganizationAggregate['id'],
  {
    onUpdate,
  }: {
    onUpdate?: () => void;
  }
) => {
  const { withShowApiErrorMiddleware } = useAlerts();

  const [form] = Form.useForm<EntityInfoFormType>();

  const { data, isLoading } = useOrganizationShow(
    {
      id,
    },
    { queryConfig: { enabled: !!id } }
  );

  const { execute: updateOrganization, isLoading: isUpdating } =
    useOrganizationUpdate();

  const initialValues = useMemo(
    () => getContactInformationInitialValues(data?.contact_information),
    [data]
  );

  const handleSubmit = useCallback(async () => {
    let values: EntityInfoFormType;

    try {
      values = await form.validateFields();

      if (isMatch(initialValues, values)) {
        onUpdate?.();
        return;
      }
    } catch (errorInfo) {
      handleValidationError(form, errorInfo);
      return;
    }

    try {
      await withShowApiErrorMiddleware(updateOrganization)(
        {
          id,
          organizationUpdateRequestContract: {
            contact_information: {
              id: data?.contact_information?.id,
              ...values,
            },
          },
        },
        { nullValuesHandling: 'KEEP_EMPTY_STRINGS' }
      );

      onUpdate?.();
    } catch (e) {
      console.error(e);
    }
  }, [
    form,
    updateOrganization,
    id,
    data?.contact_information?.id,
    onUpdate,
    initialValues,
  ]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  return {
    form,
    data,
    isLoading,
    isUpdating,
    handleSubmit,
  };
};
