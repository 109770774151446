import React, { type ComponentProps } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { GetStarted as BaseGetStarted } from '@npm/core/ui/components/molecules/GetStarted';

import { taxInformationUtils } from '../TaxInformation.utils';

type Props = { isIndividual: boolean } & ComponentProps<typeof BaseGetStarted>;

export const TaxInformationGetStartedContent = (
  props: Partial<React.ComponentProps<typeof Text>>
) => {
  return (
    <Text colorVariant="secondary" {...props}>
      Select form for this account&apos;s taxpayer status.
    </Text>
  );
};

export const TaxInformationGetStarted = ({
  isIndividual,
  onGetStarted,
}: Props) => {
  return (
    <BaseGetStarted
      onGetStarted={onGetStarted}
      title={taxInformationUtils(isIndividual)}
      content={<TaxInformationGetStartedContent />}
    />
  );
};
