import React, { type ComponentProps } from 'react';

import { useLayoutStore } from '../../organisms/Layout/Layout.store';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { useScrollDirection } from '../Tabs/StickyTabs.utils';

import * as S from './BottomNavigation.styles';

type Props = {
  onNext?: React.MouseEventHandler<HTMLElement> | (() => void);
  nextButtonProps?: ComponentProps<typeof Button> & {
    'data-cy'?: string;
  };
  onBack?: React.MouseEventHandler<HTMLElement>;
  backButtonProps?: ComponentProps<typeof Button> & {
    iconVariant?: 'arrow' | 'close';
    'data-cy'?: string;
  };
  nextButton?: React.ReactNode;
  backButton?: React.ReactNode;
  className?: string;
  hideOnScrollDown?: boolean;
  getScrollableElement?: () => HTMLElement;

  // shorthand props (coming from original ActionBar in Wizard)
  nextText?: string;
  backText?: string;
  backIconVariant?: 'arrow' | 'close';
};

export const BottomNavigation: React.FC<Props> = ({
  onNext,
  nextButtonProps = {},
  onBack,
  backButtonProps = {},
  nextButton,
  backButton,
  className,
  nextText = 'Next',
  backText = 'Back',
  backIconVariant,
  hideOnScrollDown = false,
  getScrollableElement = () => document.querySelector('body') as HTMLElement,
}: Props) => {
  const maxWidth = useLayoutStore(store => store.maxWidth);
  const fullWidth = useLayoutStore(store => store.fullWidth);

  const scrollDirection = useScrollDirection(getScrollableElement);

  return (
    <S.Wrapper
      className={className}
      $hide={hideOnScrollDown && scrollDirection === 'down'}
    >
      <S.InnerWrapper maxWidth={maxWidth} fullWidth={fullWidth}>
        {backButton ||
          (onBack && (
            <Button
              onClick={onBack}
              variant="text"
              icon={
                backIconVariant === 'close' ? (
                  <Icon name="x-close" size="sm" />
                ) : (
                  <Icon name="arrow-narrow-left" />
                )
              }
              {...backButtonProps}
            >
              {backButtonProps.children ?? backText}
            </Button>
          ))}
        {nextButton ||
          (onNext && (
            <Button onClick={onNext} {...nextButtonProps}>
              {nextButtonProps.children ?? nextText}
            </Button>
          ))}
      </S.InnerWrapper>
    </S.Wrapper>
  );
};
