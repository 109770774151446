import styled from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';

export const ScrollIndicatorButton = styled(Button)<{ $opacity: number }>`
  && {
    position: absolute;
    bottom: ${({ theme }) => theme.spacing.sm}px;
    right: 50%;
    transform: translateX(50%);
    background-color: ${({ theme }) => theme.color.general.layout.two};
    border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
    opacity: ${({ $opacity }) => $opacity};
    box-shadow: ${({ theme }) => theme.shadow.level3};
    border-radius: ${({ theme }) => theme.borderRadius.lg}px;
    ${({ $opacity }) => $opacity === 0 && `pointer-events: none;`}
  }
`;
