import React, { useEffect } from 'react';

import { useWizardStore } from '../Wizard.store';
import {
  type StepDefinition,
  type StepState,
  WizardStoreActionTypes,
} from '../Wizard.types';

type Props = {
  step: StepDefinition;
  initialStepState?: StepState;
  handleStepComplete?: () => void;
  setFormHasUnsavedChanges?: (value: boolean) => void;
  handleCloseFormAttempt?: (callback?: () => void) => void;
};

export const WizardStep = ({
  step,
  initialStepState,
  handleStepComplete,
  setFormHasUnsavedChanges,
  handleCloseFormAttempt,
}: Props) => {
  const dispatch = useWizardStore(s => s.dispatch);

  const setStepState = (state: StepState) => {
    dispatch({
      type: WizardStoreActionTypes.UPDATE_ACTIVE_STEP_STATE,
      payload: state,
    });
  };

  useEffect(() => {
    if (initialStepState) setStepState(initialStepState);
  }, [initialStepState]);

  return (
    <>
      {step.component({
        step,
        handleStepComplete: () => {
          handleStepComplete?.();
          setFormHasUnsavedChanges?.(false);
        },
        setFormHasUnsavedChanges,
        handleCloseFormAttempt,
      })}
    </>
  );
};
