import styled from 'styled-components';

import { Modal as ModalBase } from '@npm/core/ui/components/molecules/Modal';

export const Modal = styled(ModalBase)`
  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;
  }
`;
