import { Codebooks } from './codebooks.types';

const Items = {
  newest: 'newest',
  oldest: 'oldest',
} as const;

export type HoldingSortKey = keyof typeof Items;

export const CbHoldingSort = {
  code: Codebooks.HOLDING_SORT,
  items: Items,
};
