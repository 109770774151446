/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Task } from '../model';
// @ts-ignore
import { TaskCreateRequestContract } from '../model';
// @ts-ignore
import { TaskIndex } from '../model';
// @ts-ignore
import { TaskSubmitRequestContract } from '../model';
// @ts-ignore
import { TaskSummary } from '../model';
/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a new task and assign to user1
         * @param {TaskCreateRequestContract} [taskCreateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCreate: async (taskCreateRequestContract?: TaskCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/tasks/`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list tasks assigned to current user
         * @param {string} [taskType] 
         * @param {boolean} [allTasks] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskList: async (taskType?: string, allTasks?: boolean, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/tasks/`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (taskType !== undefined) {
                localVarQueryParameter['task_type'] = taskType;
            }

            if (allTasks !== undefined) {
                localVarQueryParameter['all_tasks'] = allTasks;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['company_id'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get individual task
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskShow: async (taskId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('taskShow', 'taskId', taskId)
            const localVarPath = changeUrl(`/api/tasks/{task_id}`)
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary submit latest changes for a given task
         * @param {string} taskId 
         * @param {TaskSubmitRequestContract} [taskSubmitRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskSubmitResults: async (taskId: string, taskSubmitRequestContract?: TaskSubmitRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('taskSubmitResults', 'taskId', taskId)
            const localVarPath = changeUrl(`/api/tasks/{task_id}/submit_result`)
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskSubmitRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list IssuanceTask summary for each company
         * @param {string} taskType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskSummary: async (taskType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskType' is not null or undefined
            assertParamExists('taskSummary', 'taskType', taskType)
            const localVarPath = changeUrl(`/api/tasks_summary/`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (taskType !== undefined) {
                localVarQueryParameter['task_type'] = taskType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a new task and assign to user1
         * @param {TaskCreateRequestContract} [taskCreateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskCreate(taskCreateRequestContract?: TaskCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskCreate(taskCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list tasks assigned to current user
         * @param {string} [taskType] 
         * @param {boolean} [allTasks] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskList(taskType?: string, allTasks?: boolean, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskList(taskType, allTasks, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get individual task
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskShow(taskId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskShow(taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary submit latest changes for a given task
         * @param {string} taskId 
         * @param {TaskSubmitRequestContract} [taskSubmitRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskSubmitResults(taskId: string, taskSubmitRequestContract?: TaskSubmitRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskSubmitResults(taskId, taskSubmitRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list IssuanceTask summary for each company
         * @param {string} taskType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskSummary(taskType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskSummary(taskType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TasksApiFp(configuration)
    return {
        /**
         * 
         * @summary create a new task and assign to user1
         * @param {TaskCreateRequestContract} [taskCreateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCreate(taskCreateRequestContract?: TaskCreateRequestContract, options?: any): AxiosPromise<Task> {
            return localVarFp.taskCreate(taskCreateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list tasks assigned to current user
         * @param {string} [taskType] 
         * @param {boolean} [allTasks] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskList(taskType?: string, allTasks?: boolean, companyId?: number, options?: any): AxiosPromise<TaskIndex> {
            return localVarFp.taskList(taskType, allTasks, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get individual task
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskShow(taskId: string, options?: any): AxiosPromise<Task> {
            return localVarFp.taskShow(taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary submit latest changes for a given task
         * @param {string} taskId 
         * @param {TaskSubmitRequestContract} [taskSubmitRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskSubmitResults(taskId: string, taskSubmitRequestContract?: TaskSubmitRequestContract, options?: any): AxiosPromise<Task> {
            return localVarFp.taskSubmitResults(taskId, taskSubmitRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list IssuanceTask summary for each company
         * @param {string} taskType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskSummary(taskType: string, options?: any): AxiosPromise<TaskSummary> {
            return localVarFp.taskSummary(taskType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for taskCreate operation in TasksApi.
 * @export
 * @interface TasksApiTaskCreateRequest
 */
export interface TasksApiTaskCreateRequest {
    /**
     * 
     * @type {TaskCreateRequestContract}
     * @memberof TasksApiTaskCreate
     */
    readonly taskCreateRequestContract?: TaskCreateRequestContract
}

/**
 * Request parameters for taskList operation in TasksApi.
 * @export
 * @interface TasksApiTaskListRequest
 */
export interface TasksApiTaskListRequest {
    /**
     * 
     * @type {string}
     * @memberof TasksApiTaskList
     */
    readonly taskType?: string

    /**
     * 
     * @type {boolean}
     * @memberof TasksApiTaskList
     */
    readonly allTasks?: boolean

    /**
     * 
     * @type {number}
     * @memberof TasksApiTaskList
     */
    readonly companyId?: number
}

/**
 * Request parameters for taskShow operation in TasksApi.
 * @export
 * @interface TasksApiTaskShowRequest
 */
export interface TasksApiTaskShowRequest {
    /**
     * 
     * @type {string}
     * @memberof TasksApiTaskShow
     */
    readonly taskId: string
}

/**
 * Request parameters for taskSubmitResults operation in TasksApi.
 * @export
 * @interface TasksApiTaskSubmitResultsRequest
 */
export interface TasksApiTaskSubmitResultsRequest {
    /**
     * 
     * @type {string}
     * @memberof TasksApiTaskSubmitResults
     */
    readonly taskId: string

    /**
     * 
     * @type {TaskSubmitRequestContract}
     * @memberof TasksApiTaskSubmitResults
     */
    readonly taskSubmitRequestContract?: TaskSubmitRequestContract
}

/**
 * Request parameters for taskSummary operation in TasksApi.
 * @export
 * @interface TasksApiTaskSummaryRequest
 */
export interface TasksApiTaskSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof TasksApiTaskSummary
     */
    readonly taskType: string
}

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
    /**
     * 
     * @summary create a new task and assign to user1
     * @param {TasksApiTaskCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public taskCreate(requestParameters: TasksApiTaskCreateRequest = {}, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).taskCreate(requestParameters.taskCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list tasks assigned to current user
     * @param {TasksApiTaskListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public taskList(requestParameters: TasksApiTaskListRequest = {}, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).taskList(requestParameters.taskType, requestParameters.allTasks, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get individual task
     * @param {TasksApiTaskShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public taskShow(requestParameters: TasksApiTaskShowRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).taskShow(requestParameters.taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary submit latest changes for a given task
     * @param {TasksApiTaskSubmitResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public taskSubmitResults(requestParameters: TasksApiTaskSubmitResultsRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).taskSubmitResults(requestParameters.taskId, requestParameters.taskSubmitRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list IssuanceTask summary for each company
     * @param {TasksApiTaskSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public taskSummary(requestParameters: TasksApiTaskSummaryRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).taskSummary(requestParameters.taskType, options).then((request) => request(this.axios, this.basePath));
    }
}

export type TaskCreateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const TaskCreateErrorCodes = [
];

export type TaskListErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const TaskListErrorCodes = [
];

export type TaskShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const TaskShowErrorCodes = [
];

export type TaskSubmitResultsErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const TaskSubmitResultsErrorCodes = [
];

export type TaskSummaryErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const TaskSummaryErrorCodes = [
];


