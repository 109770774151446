/* eslint-disable no-template-curly-in-string */

/**
 * This file is not the nicest one, but it is on purpose.
 * It has the same exports as ./config.ts which is being replaced by this one during production build.
 * Why we have here only strings with the format ${ENV_NAME} is because our docker is build as ENV independent docker image.
 * On docker start all ENVs are replaced by their correct values.
 * And for that replacement we need to have specific format that envsubst command understand.
 */

export const GOOGLE_PLACES_API_KEY = '${NX_GOOGLE_PLACES_API_KEY}';
