import React, { type ComponentProps, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';

import { Search } from '@npm/core/ui/components/atoms/Search';

import { type FilterItemProps } from '../FilterPanel';

type Props = FilterItemProps &
  ComponentProps<typeof Search> & { filterOnChange?: boolean };

export const SearchFilter = ({
  name,
  onFilterSubmit,
  filterOnChange,
  ...rest
}: Props) => {
  const handleSearch = useCallback(() => {
    onFilterSubmit?.(name, !filterOnChange);
  }, [onFilterSubmit, name, filterOnChange]);

  const debouncedSearch = useMemo(
    () => debounce(handleSearch, 300),
    [handleSearch]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    rest.onChange?.(e);
    debouncedSearch();
  };

  return (
    <Search
      name={name}
      onSearch={handleSearch}
      {...{
        ...rest,
        ...(filterOnChange && { onChange: handleChange }),
      }}
    />
  );
};
