import React, { useEffect } from 'react';
import { Form } from 'antd';

import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { CypressDataIds } from '@npm/core/ui/constants';
import { type UserRoleAggregate, useUserRoleIndex } from '@npm/data-access';

import { useCurrentWorkstation } from '../../../role';
import { useCurrentRole } from '../../../role/hooks/useCurrentRole';
import { USER_ROLE_INDEX_BASE_PARAMS, useUserContextStore } from '../../store';
import { roleDescriptionMapper } from '../RoleSwitcher.constants';
import { RoleSwitcherButton } from '../RoleSwitcherButton';

type Props = {
  disabled: boolean;
  isCollapsed: boolean;
  openOboModal: () => void;
};

export const BrokerageRoleSwitcher = ({
  disabled,
  isCollapsed,
  openOboModal,
}: Props) => {
  const [form] = Form.useForm();

  const workstation = useCurrentWorkstation();
  const currentRole = useCurrentRole();
  const setRole = useUserContextStore(store => store.setRole);

  const { data } = useUserRoleIndex(USER_ROLE_INDEX_BASE_PARAMS);
  const userRoles = (data?.user_roles ?? []).filter(
    userRole => userRole?.subject?.id === currentRole?.subject?.id
  );

  useEffect(() => {
    form.setFieldsValue({ role: JSON.stringify(currentRole) });
  }, [currentRole, form]);

  const handleRoleSelect = (nextRole: UserRoleAggregate | null) => {
    if (!nextRole) {
      openOboModal();
      return;
    }

    setRole(nextRole, workstation);
  };

  if (!currentRole) return null;

  const trigger = (
    <RoleSwitcherButton disabled={disabled} isCollapsed={isCollapsed} />
  );
  if (disabled) return trigger;

  return (
    <Dropdown
      fixedPosition
      zIndex="aboveAll"
      menuProps={{
        selectable: true,
        style: { maxHeight: 300, overflow: 'auto' },
      }}
      items={[
        ...userRoles.map(userRole => {
          const key = userRole.id;
          const selected = key === currentRole?.id;

          return {
            key,
            selected,
            label: userRole.role_name?.name,
            description: roleDescriptionMapper[userRole.role_name?.code],
            onClick: () => handleRoleSelect(userRole),
            'data-dd-action-name': 'Brokerage Role Button',
            'data-dd-privacy': 'mask',
          };
        }),
        {
          label: 'Act on Behalf of an account',
          description: 'Select an account',
          onClick: () => handleRoleSelect(null),
          'data-cy': CypressDataIds.OboModal.OpenButton,
          'data-dd-action-name': 'OBO Button',
        },
      ]}
    >
      {trigger}
    </Dropdown>
  );
};
