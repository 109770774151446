import React from 'react';

import { CbEventStatus } from '@npm/data-access';

import { Programs } from '../common/Programs';

export const ActivePrograms = () => {
  return (
    <Programs
      eventsStatus={CbEventStatus.items.ACTIVE}
      showAccessProgramInfo={false}
      noDataText="There are no Active Programs."
    />
  );
};
