import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import { basicPageLayoutConfig } from './BasicPageLayout.config';

export const useBasicPageLayoutConfig = () => {
  const breakpoints = useBreakpoints();

  const { isDarkMode } = useDarkModeContext();
  const imagesSuffix = isDarkMode ? ('dark' as const) : ('light' as const);

  const layoutConfig = (() => {
    if (breakpoints.isMobile) return basicPageLayoutConfig.mobile;
    if (breakpoints.isSmTablet) return basicPageLayoutConfig.smTablet;
    if (breakpoints.isTablet) return basicPageLayoutConfig.tablet;
    return basicPageLayoutConfig.desktop;
  })();

  return {
    layoutConfig,
    imagesSuffix,
    ...breakpoints,
  };
};
