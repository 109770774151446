import React from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';

export const CorporateProfileSkeleton = () => {
  return (
    <>
      <Margin bottom="lg">
        <Skeleton.Base />
      </Margin>
      <Skeleton.Base />
    </>
  );
};
