import styled from 'styled-components';

import { Card } from '../Card';

export const CardWithBackground = styled(Card)<{
  $src: string;
  $size?: string;
  $position?: string;
  $color?: string;
}>`
  background-image: url('${({ $src }) => $src}');
  background-size: ${({ $size }) => $size || 'cover'};
  background-repeat: no-repeat;
  background-position: ${({ $position }) => $position || 'center'};
  background-color: ${({ $color, theme }) =>
    $color || theme.color.general.layout.one};
`;
