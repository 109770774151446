import React from 'react';
import { type FormItemProps } from 'antd';

import { type ThemeSpacing } from '../common';

import * as S from './FormItem.styles';

type Props = {
  labelPosition?: 'top' | 'float';
  marginBottom?: ThemeSpacing;
} & FormItemProps;

export const FormItem: React.FC<Props> = ({
  label,
  labelPosition = 'top',
  marginBottom,
  ...restFormItemProps
}) => {
  return (
    <S.FormItem
      label={label}
      labelCol={labelPosition === 'top' ? { span: 24 } : undefined}
      data-cy={restFormItemProps.name}
      data-dd-action-name={`Form Item - ${label || restFormItemProps?.name}`}
      $marginBottom={marginBottom}
      {...restFormItemProps}
    />
  );
};
