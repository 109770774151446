import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';

export const SummaryChartTooltipRowHeight = 36;

type Props = {
  icon: JSX.Element;
  label: string;
  value: number;
};

export const SummaryChartTooltipRow = ({ icon, label, value }: Props) => {
  return (
    <Flex
      align={'center'}
      justify={'space-between'}
      gap={'sm'}
      style={{ height: `${SummaryChartTooltipRowHeight}px` }}
    >
      <Flex align={'center'} gap={'xs'}>
        {icon}
        <Text size={'sm'} colorVariant={'secondary'}>
          {label}
        </Text>
      </Flex>

      <Text.Price
        value={value}
        compact
        currencyAsSymbol
        formatOptions={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
      />
    </Flex>
  );
};
