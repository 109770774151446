import React, { memo, useMemo } from 'react';

import { Logo, LogoShort } from '../../../atoms/Logo';
import { useLayoutConfig } from '../Layout.hooks';

import * as S from './Topbar.styles';

export type TopbarItem = {
  component: React.ReactNode;
  position: 'left' | 'right';
};

type Props = {
  config: TopbarItem[] | undefined;
};

export const Topbar = memo(({ config }: Props) => {
  const layoutConfig = useLayoutConfig();
  const { isMobile, isTablet } = layoutConfig;

  const leftChildren = useMemo(
    () => config?.filter(c => c.position === 'left').map(c => c.component),
    [config]
  );
  const rightChildren = useMemo(
    () => config?.filter(c => c.position === 'right').map(c => c.component),
    [config]
  );

  return (
    <>
      <S.StyledHeader $layoutConfig={layoutConfig} className="noprint">
        {leftChildren}
        <S.LogoWrapper>
          {isMobile || isTablet ? <LogoShort /> : <Logo />}
        </S.LogoWrapper>
        <S.Right>{rightChildren}</S.Right>
      </S.StyledHeader>
      <S.HeightFiller
        $headerHeight={layoutConfig.header.height}
        className="noprint"
      />
    </>
  );
});

Topbar.displayName = 'Topbar';
