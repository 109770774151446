import axios from 'axios';

import { APP_CFG_API_URL } from '@npm/config';

const instance = axios.create({
  baseURL: APP_CFG_API_URL,
});

export const changeUrl = (url: string) => {
  return url.replace('/api/', '/api/mtm/');
};
export const getAxiosInstance = () => {
  return instance;
};
