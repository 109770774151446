import * as S from './ProgressStep.styles';

type Props = {
  percent: number;
  stepBackground?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const ProgressStep = ({ percent, stepBackground }: Props) => {
  return (
    <S.ProgressStep>
      <S.ProgressStepContainer>
        <S.ProgressStepPercent $percent={percent} />
        <S.ProgressStepBackground $backgroundColor={stepBackground} />
      </S.ProgressStepContainer>
    </S.ProgressStep>
  );
};
