/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocumentSigningCreateRequestContract } from '../model';
// @ts-ignore
import { DocusignSigning } from '../model';
/**
 * DocumentSigningApi - axios parameter creator
 * @export
 */
export const DocumentSigningApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates a docusign envelope and returns the url
         * @param {DocumentSigningCreateRequestContract} documentSigningCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentSigning: async (documentSigningCreateRequestContract: DocumentSigningCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentSigningCreateRequestContract' is not null or undefined
            assertParamExists('documentSigning', 'documentSigningCreateRequestContract', documentSigningCreateRequestContract)
            const localVarPath = changeUrl(`/api/document-signing`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentSigningCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a result from the callback with the user id and the signing_token
         * @param {number} submissionId 
         * @param {number} userId 
         * @param {string} signingToken 
         * @param {string} [event] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentSigningComplete: async (submissionId: number, userId: number, signingToken: string, event?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('documentSigningComplete', 'submissionId', submissionId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('documentSigningComplete', 'userId', userId)
            // verify required parameter 'signingToken' is not null or undefined
            assertParamExists('documentSigningComplete', 'signingToken', signingToken)
            const localVarPath = changeUrl(`/api/document-signing/{submission_id}`)
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (signingToken !== undefined) {
                localVarQueryParameter['signing_token'] = signingToken;
            }

            if (event !== undefined) {
                localVarQueryParameter['event'] = event;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentSigningApi - functional programming interface
 * @export
 */
export const DocumentSigningApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentSigningApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates a docusign envelope and returns the url
         * @param {DocumentSigningCreateRequestContract} documentSigningCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentSigning(documentSigningCreateRequestContract: DocumentSigningCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocusignSigning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentSigning(documentSigningCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a result from the callback with the user id and the signing_token
         * @param {number} submissionId 
         * @param {number} userId 
         * @param {string} signingToken 
         * @param {string} [event] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentSigningComplete(submissionId: number, userId: number, signingToken: string, event?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentSigningComplete(submissionId, userId, signingToken, event, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentSigningApi - factory interface
 * @export
 */
export const DocumentSigningApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentSigningApiFp(configuration)
    return {
        /**
         * 
         * @summary creates a docusign envelope and returns the url
         * @param {DocumentSigningCreateRequestContract} documentSigningCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentSigning(documentSigningCreateRequestContract: DocumentSigningCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<DocusignSigning> {
            return localVarFp.documentSigning(documentSigningCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a result from the callback with the user id and the signing_token
         * @param {number} submissionId 
         * @param {number} userId 
         * @param {string} signingToken 
         * @param {string} [event] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentSigningComplete(submissionId: number, userId: number, signingToken: string, event?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.documentSigningComplete(submissionId, userId, signingToken, event, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for documentSigning operation in DocumentSigningApi.
 * @export
 * @interface DocumentSigningApiDocumentSigningRequest
 */
export interface DocumentSigningApiDocumentSigningRequest {
    /**
     * 
     * @type {DocumentSigningCreateRequestContract}
     * @memberof DocumentSigningApiDocumentSigning
     */
    readonly documentSigningCreateRequestContract: DocumentSigningCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof DocumentSigningApiDocumentSigning
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentSigningApiDocumentSigning
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentSigningApiDocumentSigning
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentSigningApiDocumentSigning
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentSigningApiDocumentSigning
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentSigningApiDocumentSigning
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for documentSigningComplete operation in DocumentSigningApi.
 * @export
 * @interface DocumentSigningApiDocumentSigningCompleteRequest
 */
export interface DocumentSigningApiDocumentSigningCompleteRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentSigningApiDocumentSigningComplete
     */
    readonly submissionId: number

    /**
     * 
     * @type {number}
     * @memberof DocumentSigningApiDocumentSigningComplete
     */
    readonly userId: number

    /**
     * 
     * @type {string}
     * @memberof DocumentSigningApiDocumentSigningComplete
     */
    readonly signingToken: string

    /**
     * 
     * @type {string}
     * @memberof DocumentSigningApiDocumentSigningComplete
     */
    readonly event?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentSigningApiDocumentSigningComplete
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentSigningApiDocumentSigningComplete
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentSigningApiDocumentSigningComplete
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentSigningApiDocumentSigningComplete
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentSigningApiDocumentSigningComplete
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentSigningApiDocumentSigningComplete
     */
    readonly xUserRoleId?: string
}

/**
 * DocumentSigningApi - object-oriented interface
 * @export
 * @class DocumentSigningApi
 * @extends {BaseAPI}
 */
export class DocumentSigningApi extends BaseAPI {
    /**
     * 
     * @summary creates a docusign envelope and returns the url
     * @param {DocumentSigningApiDocumentSigningRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentSigningApi
     */
    public documentSigning(requestParameters: DocumentSigningApiDocumentSigningRequest, options?: AxiosRequestConfig) {
        return DocumentSigningApiFp(this.configuration).documentSigning(requestParameters.documentSigningCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a result from the callback with the user id and the signing_token
     * @param {DocumentSigningApiDocumentSigningCompleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentSigningApi
     */
    public documentSigningComplete(requestParameters: DocumentSigningApiDocumentSigningCompleteRequest, options?: AxiosRequestConfig) {
        return DocumentSigningApiFp(this.configuration).documentSigningComplete(requestParameters.submissionId, requestParameters.userId, requestParameters.signingToken, requestParameters.event, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type DocumentSigningErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 403;
        data: DocusignSigning;
    }

    | {
        status: 442;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const DocumentSigningErrorCodes = [
    401,
    403,
    442,
];

export type DocumentSigningCompleteErrorTypes =
    | {
        status: 302;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const DocumentSigningCompleteErrorCodes = [
    302,
];


