/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPartnerCapTablePartnerUploadFileDownloadUrl } from '../model';
// @ts-ignore
import { GetCapTablePartnerUploadFileValidations422Response } from '../model';
/**
 * CapTablePartnerUploadFileApi - axios parameter creator
 * @export
 */
export const CapTablePartnerUploadFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary provides a download url for a file version associated with a cap table partner upload
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {number} uploadId An auto-incremented id of a cap table partner upload.
         * @param {number} fileId An auto-incremented id of a cap table partner upload file. This can be the file id of ANY file version associated with the cap table partner upload.
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapTablePartnerUploadFileDownloadUrls: async (issuerId: string, uploadId: number, fileId: number, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('getCapTablePartnerUploadFileDownloadUrls', 'issuerId', issuerId)
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('getCapTablePartnerUploadFileDownloadUrls', 'uploadId', uploadId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getCapTablePartnerUploadFileDownloadUrls', 'fileId', fileId)
            const localVarPath = changeUrl(`/api/admin/partner/issuers/{issuer_id}/cap_table_partner_uploads/{upload_id}/files/{file_id}/download_urls`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)))
                .replace(`{${"upload_id"}}`, encodeURIComponent(String(uploadId)))
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary validates a file version associated with a cap table partner upload
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {number} uploadId An auto-incremented id of a cap table partner upload.
         * @param {number} fileId An auto-incremented id of a cap table partner upload file. This can be the file id of ANY file version associated with the cap table partner upload.
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapTablePartnerUploadFileValidations: async (issuerId: string, uploadId: number, fileId: number, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('getCapTablePartnerUploadFileValidations', 'issuerId', issuerId)
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('getCapTablePartnerUploadFileValidations', 'uploadId', uploadId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getCapTablePartnerUploadFileValidations', 'fileId', fileId)
            const localVarPath = changeUrl(`/api/admin/partner/issuers/{issuer_id}/cap_table_partner_uploads/{upload_id}/files/{file_id}/validations`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)))
                .replace(`{${"upload_id"}}`, encodeURIComponent(String(uploadId)))
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CapTablePartnerUploadFileApi - functional programming interface
 * @export
 */
export const CapTablePartnerUploadFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CapTablePartnerUploadFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary provides a download url for a file version associated with a cap table partner upload
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {number} uploadId An auto-incremented id of a cap table partner upload.
         * @param {number} fileId An auto-incremented id of a cap table partner upload file. This can be the file id of ANY file version associated with the cap table partner upload.
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCapTablePartnerUploadFileDownloadUrls(issuerId: string, uploadId: number, fileId: number, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPartnerCapTablePartnerUploadFileDownloadUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCapTablePartnerUploadFileDownloadUrls(issuerId, uploadId, fileId, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary validates a file version associated with a cap table partner upload
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {number} uploadId An auto-incremented id of a cap table partner upload.
         * @param {number} fileId An auto-incremented id of a cap table partner upload file. This can be the file id of ANY file version associated with the cap table partner upload.
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCapTablePartnerUploadFileValidations(issuerId: string, uploadId: number, fileId: number, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCapTablePartnerUploadFileValidations(issuerId, uploadId, fileId, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CapTablePartnerUploadFileApi - factory interface
 * @export
 */
export const CapTablePartnerUploadFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CapTablePartnerUploadFileApiFp(configuration)
    return {
        /**
         * 
         * @summary provides a download url for a file version associated with a cap table partner upload
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {number} uploadId An auto-incremented id of a cap table partner upload.
         * @param {number} fileId An auto-incremented id of a cap table partner upload file. This can be the file id of ANY file version associated with the cap table partner upload.
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapTablePartnerUploadFileDownloadUrls(issuerId: string, uploadId: number, fileId: number, xWorkstation?: string, options?: any): AxiosPromise<AdminPartnerCapTablePartnerUploadFileDownloadUrl> {
            return localVarFp.getCapTablePartnerUploadFileDownloadUrls(issuerId, uploadId, fileId, xWorkstation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary validates a file version associated with a cap table partner upload
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {number} uploadId An auto-incremented id of a cap table partner upload.
         * @param {number} fileId An auto-incremented id of a cap table partner upload file. This can be the file id of ANY file version associated with the cap table partner upload.
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapTablePartnerUploadFileValidations(issuerId: string, uploadId: number, fileId: number, xWorkstation?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getCapTablePartnerUploadFileValidations(issuerId, uploadId, fileId, xWorkstation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCapTablePartnerUploadFileDownloadUrls operation in CapTablePartnerUploadFileApi.
 * @export
 * @interface CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileDownloadUrlsRequest
 */
export interface CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileDownloadUrlsRequest {
    /**
     * An NPM GUID of an issuer.
     * @type {string}
     * @memberof CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileDownloadUrls
     */
    readonly issuerId: string

    /**
     * An auto-incremented id of a cap table partner upload.
     * @type {number}
     * @memberof CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileDownloadUrls
     */
    readonly uploadId: number

    /**
     * An auto-incremented id of a cap table partner upload file. This can be the file id of ANY file version associated with the cap table partner upload.
     * @type {number}
     * @memberof CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileDownloadUrls
     */
    readonly fileId: number

    /**
     * 
     * @type {string}
     * @memberof CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileDownloadUrls
     */
    readonly xWorkstation?: string
}

/**
 * Request parameters for getCapTablePartnerUploadFileValidations operation in CapTablePartnerUploadFileApi.
 * @export
 * @interface CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileValidationsRequest
 */
export interface CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileValidationsRequest {
    /**
     * An NPM GUID of an issuer.
     * @type {string}
     * @memberof CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileValidations
     */
    readonly issuerId: string

    /**
     * An auto-incremented id of a cap table partner upload.
     * @type {number}
     * @memberof CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileValidations
     */
    readonly uploadId: number

    /**
     * An auto-incremented id of a cap table partner upload file. This can be the file id of ANY file version associated with the cap table partner upload.
     * @type {number}
     * @memberof CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileValidations
     */
    readonly fileId: number

    /**
     * 
     * @type {string}
     * @memberof CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileValidations
     */
    readonly xWorkstation?: string
}

/**
 * CapTablePartnerUploadFileApi - object-oriented interface
 * @export
 * @class CapTablePartnerUploadFileApi
 * @extends {BaseAPI}
 */
export class CapTablePartnerUploadFileApi extends BaseAPI {
    /**
     * 
     * @summary provides a download url for a file version associated with a cap table partner upload
     * @param {CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileDownloadUrlsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CapTablePartnerUploadFileApi
     */
    public getCapTablePartnerUploadFileDownloadUrls(requestParameters: CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileDownloadUrlsRequest, options?: AxiosRequestConfig) {
        return CapTablePartnerUploadFileApiFp(this.configuration).getCapTablePartnerUploadFileDownloadUrls(requestParameters.issuerId, requestParameters.uploadId, requestParameters.fileId, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary validates a file version associated with a cap table partner upload
     * @param {CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileValidationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CapTablePartnerUploadFileApi
     */
    public getCapTablePartnerUploadFileValidations(requestParameters: CapTablePartnerUploadFileApiGetCapTablePartnerUploadFileValidationsRequest, options?: AxiosRequestConfig) {
        return CapTablePartnerUploadFileApiFp(this.configuration).getCapTablePartnerUploadFileValidations(requestParameters.issuerId, requestParameters.uploadId, requestParameters.fileId, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }
}

export type GetCapTablePartnerUploadFileDownloadUrlsErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetCapTablePartnerUploadFileDownloadUrlsErrorCodes = [
    404,
];

export type GetCapTablePartnerUploadFileValidationsErrorTypes =

    | {
        status: 404;
    }

    | {
        status: 422;
        data: GetCapTablePartnerUploadFileValidations422Response;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetCapTablePartnerUploadFileValidationsErrorCodes = [
    404,
    422,
];


