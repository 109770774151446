import { useEffect, useMemo } from 'react';
import type { FormInstance } from 'antd';
import { isEqual } from 'lodash';

import { Form } from '@npm/core/ui/components/atoms/Form';

import { useUserContextStore } from '../../auth/user/context';

export const usePersonFormInitialValues = (form: FormInstance) => {
  const user = useUserContextStore(state => state.user);
  const isAnonymous = Form.useWatch('anonymous', form);

  const personInfoFormInitialValues = useMemo(
    () => ({
      first: user?.person?.first,
      middle: user?.person?.middle,
      last: user?.person?.last,
      email: user?.person?.email,
    }),
    [user]
  );

  useEffect(() => {
    const personFields = ['first', 'middle', 'last', 'email'];
    const arePersonFieldsTouchedByUser = !isEqual(
      form.getFieldsValue(personFields),
      personInfoFormInitialValues
    );

    if (isAnonymous && !form.isFieldsTouched(personFields)) {
      form.setFieldsValue(personInfoFormInitialValues);
      return;
    }

    if (!isAnonymous && !arePersonFieldsTouchedByUser) {
      form.resetFields(personFields);
    }
  }, [personInfoFormInitialValues, isAnonymous, form]);
};
