import React, { useMemo, useState } from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { type MenuItemGroup } from '@npm/core/ui/components/organisms/Layout';
import { useSidebarStore } from '@npm/core/ui/components/organisms/Layout/Sidebar/Sidebar.store';

import { useUserContextStore } from '../../auth/user/context';
import { useAreNotificationsEnabled } from '../hooks';
import { useNotificationContext } from '../NotificationContext/NotificationContext';

export const useNotificationMenuItem = () => {
  const user = useUserContextStore(s => s.user);
  const unreadNotificationCount = useNotificationContext();
  const [isNotificationDrawerVisible, setNotificationDrawerVisible] =
    useState(false);
  const isMenuCollapsed = useSidebarStore(store => store.isCollapsed);

  const areNotificationsEnabled = useAreNotificationsEnabled();

  const notificationMenuItem = useMemo(() => {
    if (!user || !areNotificationsEnabled) {
      return null;
    }

    return {
      label: 'Notifications',
      title: 'Notifications',
      key: 'notifications',
      icon: <Icon name="bell" />,
      actionItemsCount: unreadNotificationCount,
    } satisfies MenuItemGroup;
  }, [user, unreadNotificationCount, areNotificationsEnabled, isMenuCollapsed]);

  return useMemo(() => {
    return {
      notificationDrawerProps: {
        isOpen: isNotificationDrawerVisible,
        onClose: () => setNotificationDrawerVisible(false),
      },
      notificationMenuItem,
      handleNotificationMenuItemClick: (key: string) => {
        if (key === 'notifications') {
          setNotificationDrawerVisible(true);
          return true;
        }

        return false;
      },
    };
  }, [
    notificationMenuItem,
    isNotificationDrawerVisible,
    setNotificationDrawerVisible,
  ]);
};
