import React from 'react';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';

import { ChartLegendItem } from '../../../../components/ChartLegendItem';

import { LineIcon, PointIcon } from './MutualFundMarksLegend.styles';

export const MutualFundMarksLegend = () => {
  return (
    <Margin top="sm" bottom="sm">
      <Flex gap="md" align="center" justify="center">
        <ChartLegendItem icon={<LineIcon />} label={'90-Day Moving Average'} />

        <ChartLegendItem icon={<PointIcon $color="info" />} label={'Common'} />
        <ChartLegendItem
          icon={<PointIcon $color="success" />}
          label={'Preferred'}
        />
      </Flex>
    </Margin>
  );
};
