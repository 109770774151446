import { Codebooks } from './codebooks.types';

const Items = {
  Personal: 'PERSONAL',
  Business: 'BUSINESS',
  Other: 'OTHER',
} as const;

export type CbEmailTypeCode = typeof Items[keyof typeof Items];

export const CbEmailType = {
  code: Codebooks.EMAIL,
  items: Items,
};
