import styled from 'styled-components';

import { InputFormItem as FormItemBase } from '@npm/core/ui/components/atoms/FormItem';
import { TableVertical as TableVerticalBase } from '@npm/core/ui/components/molecules/TableVertical';

export const StyledEmailSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing.lg}px;
`;

export const Container = styled.div`
  & > div:first-child {
    padding-top: 0;
  }
`;

export const Section = styled.div`
  padding: ${({ theme }) => theme.spacing.lg}px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.general.borders.primary};
`;

export const EmailSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing.lg}px;
`;

export const FormItem = styled(FormItemBase).attrs({
  labelPosition: 'top',
})`
  & {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`;

export const TableVertical = styled(TableVerticalBase)`
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  > * > *:first-child {
    flex-basis: 50px;
    flex-grow: 0;
  }
`;
