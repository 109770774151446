import { useRef } from 'react';

type Event = React.PointerEvent<HTMLDivElement | HTMLButtonElement>;
// Threshold in pixels to distinguish between a click and a scroll
const threshold = 10;

export const useHandleApplePencilTouch = (
  onPress: ((arg?: never) => void) | undefined
) => {
  const startYRef = useRef<number | null>(null);

  const onPointerDown = (e: Event) => {
    // Get the initial touch position
    startYRef.current = e.clientY;
  };

  const onPointerUp = (e: Event) => {
    if (!startYRef.current) return;

    // Get the final touch position
    const endY = e.clientY;

    // Scrolling, so we ignore this touch event
    if (Math.abs(startYRef.current - endY) > threshold) {
      return;
    }

    // If the touch was a pen, we trigger the click
    if (e.pointerType === 'pen') {
      onPress?.();
    }
  };

  return {
    onPointerDown,
    onPointerUp,
  };
};
