import React, { type ComponentProps, useMemo } from 'react';

import {
  Select,
  SELECT_PAGE_SIZE,
  useSelectAsync,
} from '@npm/core/ui/components/atoms/Select';
import { onPopupScroll } from '@npm/core/ui/components/atoms/Select/Select.utils';
import {
  usePermissionedAccountIndex,
  usePermissionedAccountIndexInfinite,
} from '@npm/data-access';

import { renderAccountOption, SelectedAccount } from '../components';

type Props = ComponentProps<typeof Select> & {
  eventId: number;
};

export const PermissionedAccountSearch = ({
  eventId,
  value,
  ...props
}: Props) => {
  const [{ searchTerm }, selectAsyncProps] = useSelectAsync();

  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePermissionedAccountIndexInfinite(
    {
      eventId,
      ...(searchTerm ? { search: searchTerm } : {}),
      size: SELECT_PAGE_SIZE,
    },
    { onError: Select.onError, queryConfig: { enabled: !!eventId } }
  );

  const mergedAccounts = useMemo(() => {
    return (
      data?.pages.reduce((mergedArray, page) => {
        return mergedArray.concat(page.permissioned_accounts);
      }, []) ?? null
    );
  }, [data]);

  // fetch selected account if there is value and it is not in the list or list is just fetching
  const shouldFetchSelectedAccount = !!(
    value &&
    (!mergedAccounts || !mergedAccounts.find(account => account?.id === value))
  );

  const { data: selectedAccountData } = usePermissionedAccountIndex(
    {
      eventId,
      search: value,
    },
    { queryConfig: { enabled: shouldFetchSelectedAccount } }
  );

  const options = useMemo(() => {
    if (!mergedAccounts) return null;

    let accounts = mergedAccounts;

    if (
      value &&
      !mergedAccounts.find(account => account?.id === value) &&
      selectedAccountData?.permissioned_accounts?.length
    ) {
      accounts = [
        selectedAccountData.permissioned_accounts[0],
        ...mergedAccounts,
      ];
    }

    return accounts.map(account => ({
      label: (
        <SelectedAccount
          accountName={account?.name}
          id={account?.external_id}
          brokerageFirmName={account?.brokerage_firm?.name}
        />
      ),
      value: account?.id,
      account,
    }));
  }, [mergedAccounts, selectedAccountData, value]);

  const shouldDisplayValue =
    options?.length &&
    !!value &&
    options?.find(option => option.value === value);

  return (
    <Select
      variant="search"
      showSearch
      value={shouldDisplayValue ? value : null}
      onPopupScroll={e =>
        onPopupScroll(e, hasNextPage && !isFetchingNextPage && fetchNextPage)
      }
      loading={isLoading}
      infiniteLoading={isFetchingNextPage}
      error={error}
      placeholder={
        options ? `All (${data.pages[0].pagination.total_records})` : 'All'
      }
      {...props}
      {...selectAsyncProps}
    >
      {options?.map(renderAccountOption)}
    </Select>
  );
};
