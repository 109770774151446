import React from 'react';

import { ReadOnly } from '@npm/core/ui/components/molecules/ReadOnly';
import { type OrganizationAggregate } from '@npm/data-access';

import { prepareReadonlyData } from './ContactInformationReadOnly.utils';

type Props = {
  data: OrganizationAggregate;
  onUpdate?: () => void;
  updateButtonText?: string;
};

export const ReadOnlyContactInformation = ({
  data,
  onUpdate,
  updateButtonText,
}: Props) => {
  return (
    <ReadOnly
      items={prepareReadonlyData(data)}
      onUpdate={onUpdate}
      updateButtonText={updateButtonText || 'Update Contact Information'}
    />
  );
};
