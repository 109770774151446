import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {} as const;
type AttributeName = 'entity' | 'individual' | 'subtext';
export const CbAccreditationQuestion = {
  code: Codebooks.ACCREDITATION_QUESTION,
  items: Items,
  getAttributeValueByName: (item: CodebookItem, name: AttributeName) =>
    item.attributes?.find(q => q.name === name)?.value,
};
