import React, { useRef } from 'react';

export const useAfterViewUpdate = () => {
  const ref = useRef<(() => void) | null>(null);
  React.useLayoutEffect(() => {
    if (ref.current) {
      ref.current();
      ref.current = null;
    }
  });
  return fn => (ref.current = fn);
};
