export const MarketFeedDataIds = {
  Table: {
    Actions: {
      OtherActions: 'market-feed-other-actions-btn',
      StartNegotiation: 'start-negotiation-button',
      InNegotiation: 'in-negotiation-button',
      CancelOrder: 'cancel-order-button',
      EditOrder: 'edit-order-button',
    },
    Name: 'market-depth-table',
  },
};

export const WatchlistDataIds = {
  Table: {
    EditButton: 'watchlist-edit-button',
    DeleteButton: 'watchlist-delete-button',
  },
  Drawer: {
    AddDrawer: 'add-watchlist-drawer',
    EditDrawer: 'edit-watchlist-drawer',
    OpenButton: 'watchlist-drawer-open-button',
    Form: {
      AccountSearch: 'watchlist-form-account-search',
      AssetTypeSelect: 'watchlist-form-asset-type-select',
    },
  },
};

export const NegotiationsDataIds = {
  Negotiations: {
    Actions: {
      Accept: 'negotiation-action-accept',
      Reject: 'negotiation-action-reject',
      Counter: 'negotiation-action-counter',
    },
    ActionDrawer: {
      ConfirmationCheckbox: 'negotiation-action-drawer-confirmation',
      Submit: 'negotiation-action-drawer-submit',
      SizeInput: 'negotiation-action-drawer-size-input',
      PpsInput: 'negotiation-action-drawer-pps-input',
    },
    CurrentProposal: {
      Table: 'negotiation-current-proposal-table',
      Card: 'negotiation-current-proposal-card',
      Buttons: {
        Undo: 'negotiation-current-proposal-button-undo',
        TakeAction: 'negotiation-current-proposal-button-take-action',
      },
    },
    NegotiationTracker: {
      Table: 'negotiation-negotiation-tracker-table',
      Card: 'negotiation-negotiation-tracker-card',
    },
  },
};
