import styled from 'styled-components';

import { useBreakpoint } from '../../../utils/style';

const SPACING = '2px';

export const ScrollWrapper = styled.div<{
  $height: number | string;
}>`
  height: ${({ $height }) =>
    typeof $height === 'string' ? $height : $height + 'px'};
`;

export const ContentWrapper = styled.div`
  height: auto;
  max-height: 100%;
  overflow: hidden;
  // overflow: hidden is cutting off borders of the form elements.
  // to fix this, we need to add some spacing from sides and reposition it with
  // negative margin.
  padding: 0 ${SPACING};
  margin: 0 -${SPACING};

  ${({ theme }) => useBreakpoint(theme).mobile`
    margin: 0;
  `}
`;
