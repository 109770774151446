/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DestroySuccess } from '../model';
// @ts-ignore
import { OrderItemShowAggregate } from '../model';
/**
 * OrderItemsApi - axios parameter creator
 * @export
 */
export const OrderItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete a specific order item
         * @param {number} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemDestroy: async (orderItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderItemId' is not null or undefined
            assertParamExists('orderItemDestroy', 'orderItemId', orderItemId)
            const localVarPath = changeUrl(`/api/order-items/{order_item_id}`)
                .replace(`{${"order_item_id"}}`, encodeURIComponent(String(orderItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieves the details of an order item
         * @param {number} orderItemId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemShow: async (orderItemId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderItemId' is not null or undefined
            assertParamExists('orderItemShow', 'orderItemId', orderItemId)
            const localVarPath = changeUrl(`/api/order-items/{order_item_id}`)
                .replace(`{${"order_item_id"}}`, encodeURIComponent(String(orderItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderItemsApi - functional programming interface
 * @export
 */
export const OrderItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete a specific order item
         * @param {number} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderItemDestroy(orderItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroySuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderItemDestroy(orderItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary retrieves the details of an order item
         * @param {number} orderItemId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderItemShow(orderItemId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemShowAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderItemShow(orderItemId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderItemsApi - factory interface
 * @export
 */
export const OrderItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderItemsApiFp(configuration)
    return {
        /**
         * 
         * @summary delete a specific order item
         * @param {number} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemDestroy(orderItemId: number, options?: any): AxiosPromise<DestroySuccess> {
            return localVarFp.orderItemDestroy(orderItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieves the details of an order item
         * @param {number} orderItemId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemShow(orderItemId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<OrderItemShowAggregate> {
            return localVarFp.orderItemShow(orderItemId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orderItemDestroy operation in OrderItemsApi.
 * @export
 * @interface OrderItemsApiOrderItemDestroyRequest
 */
export interface OrderItemsApiOrderItemDestroyRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderItemsApiOrderItemDestroy
     */
    readonly orderItemId: number
}

/**
 * Request parameters for orderItemShow operation in OrderItemsApi.
 * @export
 * @interface OrderItemsApiOrderItemShowRequest
 */
export interface OrderItemsApiOrderItemShowRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderItemsApiOrderItemShow
     */
    readonly orderItemId: number

    /**
     * 
     * @type {string}
     * @memberof OrderItemsApiOrderItemShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof OrderItemsApiOrderItemShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof OrderItemsApiOrderItemShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof OrderItemsApiOrderItemShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof OrderItemsApiOrderItemShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof OrderItemsApiOrderItemShow
     */
    readonly xUserRoleId?: string
}

/**
 * OrderItemsApi - object-oriented interface
 * @export
 * @class OrderItemsApi
 * @extends {BaseAPI}
 */
export class OrderItemsApi extends BaseAPI {
    /**
     * 
     * @summary delete a specific order item
     * @param {OrderItemsApiOrderItemDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderItemsApi
     */
    public orderItemDestroy(requestParameters: OrderItemsApiOrderItemDestroyRequest, options?: AxiosRequestConfig) {
        return OrderItemsApiFp(this.configuration).orderItemDestroy(requestParameters.orderItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieves the details of an order item
     * @param {OrderItemsApiOrderItemShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderItemsApi
     */
    public orderItemShow(requestParameters: OrderItemsApiOrderItemShowRequest, options?: AxiosRequestConfig) {
        return OrderItemsApiFp(this.configuration).orderItemShow(requestParameters.orderItemId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type OrderItemDestroyErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: DestroySuccess;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const OrderItemDestroyErrorCodes = [
    401,
    422,
];

export type OrderItemShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const OrderItemShowErrorCodes = [
    401,
];


