import styled from 'styled-components';

import { Flex } from '../common';
import { Text } from '../Typography';

export const StepItem = styled.div`
  background-color: ${({ theme }) => theme.color.general.layout.zero};
  flex: 1;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xs}px;
  padding-right: ${({ theme }) => theme.spacing.sm}px;
  padding-left: ${({ theme }) => theme.spacing.xs}px;
  position: relative;
  text-wrap: nowrap;

  &.active {
    background-color: ${({ theme }) => theme.color.info.typography.primary};

    &:after {
      background-color: ${({ theme }) => theme.color.info.typography.primary};
    }
  }

  &.disabled {
    background-color: ${({ theme }) => theme.color.general.backgrounds.muted};

    &:after {
      background-color: ${({ theme }) => theme.color.general.backgrounds.muted};
    }
  }

  &:not(:last-child) {
    &:after {
      content: '';
      z-index: 1;
      position: absolute;
      top: 0px;
      right: -6px;
      width: 23px;
      height: 100%;
      background-color: ${({ theme }) =>
        theme.color.general.typography.negative};
      background-color: ${({ theme }) => theme.color.general.layout.two};
      clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 50% 100%, 75% 50%, 50% 0%);
    }
  }
`;

export const StepsWrapper = styled(Flex)`
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  width: 100%;
  overflow: hidden;
`;

export const StepItemLabel = styled(Text).attrs({
  weight: 'bold',
})`
  &.default {
    color: ${({ theme }) => theme.color.general.typography.tertiary};
  }

  &.active {
    color: ${({ theme }) => theme.color.general.typography.negative};
  }

  &.complete {
    color: ${({ theme }) => theme.color.info.typography.primary};
  }

  &.disabled {
    color: ${({ theme }) => theme.color.general.typography.tertiary};
  }
`;

export const StepItemIcon = styled.span`
  padding: ${({ theme }) => theme.spacing.xs}px;
  vertical-align: middle;
`;
