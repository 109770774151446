import styled from 'styled-components';

export const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 54px;
  padding: ${({ theme }) => theme.spacing.md}px;
  gap: ${({ theme }) => theme.spacing.xs}px;
`;

export const SectionSubTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.sm}px ${theme.spacing.md}px`};
  background-color: ${({ theme }) => theme.color.general.layout.zero};
  gap: ${({ theme }) => theme.spacing.xs}px;
`;

export const ItemsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.md}px;
  padding-top: 0;
`;

export const ItemWrapper = styled.div<{
  $areChildrenExpanded: boolean;
  $isActive: boolean;
}>`
  ${({ theme, $areChildrenExpanded, $isActive }) =>
    ($areChildrenExpanded || $isActive) &&
    `background-color: ${theme.color.general.layout.zero};
     border-radius: ${theme.borderRadius.lg}px;
     border: 1px solid ${theme.color.info.borders.primary};
    `}
`;
