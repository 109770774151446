import React, { type ComponentProps, useMemo } from 'react';

import { Select, SELECT_PAGE_SIZE } from '@npm/core/ui/components/atoms/Select';
import { onPopupScroll } from '@npm/core/ui/components/atoms/Select/Select.utils';
import { useIssuanceIndexInfinite } from '@npm/data-access';

import { useCurrentRole } from '../../../auth/user/role/hooks/useCurrentRole';

type Props = ComponentProps<typeof Select>;

export const IssuanceSelect = (props: Props) => {
  const role = useCurrentRole();
  const issuerEntityId = role?.subject?.id;

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    error,
  } = useIssuanceIndexInfinite(
    {
      issuerEntityId,
      size: SELECT_PAGE_SIZE,
    },
    { queryConfig: { enabled: !!issuerEntityId } }
  );

  const options = useMemo(() => {
    if (!data) return null;

    const issuances = data.pages.flatMap(page => page.issuances);

    return [
      ...issuances.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    ];
  }, [data]);

  return (
    <Select
      allowClear
      loading={isLoading}
      infiniteLoading={isFetchingNextPage}
      onPopupScroll={e =>
        onPopupScroll(e, hasNextPage && !isFetchingNextPage && fetchNextPage)
      }
      placeholder={
        options
          ? `Select (${data?.pages[0]?.pagination?.total_records ?? 0})`
          : 'Select'
      }
      error={error}
      options={options}
      {...props}
    />
  );
};
