import React from 'react';
import { type InputNumberProps } from 'antd';

import {
  formatNumToAddComma,
  parseFormattedNumberWithComma,
} from '../../../utils/formatters';

import { StyledInputNumber } from './InputNumber.styles';

export const getSupportedInputMode = (
  prefferedInputMode: InputNumberProps['inputMode']
) => {
  if (
    prefferedInputMode === 'decimal' &&
    navigator.language?.toLowerCase() !== 'en-us'
  ) {
    // We use US format, so comma is not a valid decimal separator.
    // It is safer to open the text keyboard that includes both comma and dot.
    return 'text';
  }
  return prefferedInputMode;
};

export const InputNumber = (props: InputNumberProps) => {
  return (
    <StyledInputNumber
      controls={false}
      formatter={value => {
        if (value == null) return '';
        return formatNumToAddComma(value);
      }}
      parser={parseFormattedNumberWithComma}
      type="text"
      pattern="[0-9,.]*"
      {...props}
      inputMode={getSupportedInputMode(props.inputMode ?? 'numeric')}
    />
  );
};
