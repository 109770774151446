import styled from 'styled-components';

import { useBreakpoint } from '../../../../utils/style';
import { Text } from '../../../atoms/Typography';

const FORM_BLOCK_MAX_WIDTH = 800;
const PADDING_TOP = 80;
const PADDING_TOP_M = 40;
const GAP = 40;

export const Wrapper = styled.div<{
  $isDisabled?: boolean;
  $minHeight: number | string;
}>`
  max-width: ${FORM_BLOCK_MAX_WIDTH}px;
  min-height: ${({ $minHeight }) =>
    `${typeof $minHeight === 'number' ? `${$minHeight}px` : $minHeight}`};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: ${PADDING_TOP}px;
  padding-bottom: ${GAP}px;
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.3 : 1)};

  ${({ theme }) => useBreakpoint(theme).tablet`
    padding-top: ${PADDING_TOP_M}px;
    padding-bottom: ${GAP}px;
  `}

  .ant-radio-group {
    font-size: unset;
  }
`;

export const MarginSpacer = styled.div<{ $margin?: boolean }>`
  margin-bottom: ${({ theme, $margin = false }) =>
    $margin ? 0 : theme.spacing.lg}px;
`;

export const Subtitle = styled(Text)`
  max-width: 50%;

  ${({ theme }) => useBreakpoint(theme).tablet`
    max-width: 100%;
  `}
`;
