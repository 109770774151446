import React, { useState } from 'react';
import { type FormInstance, type FormProps, Radio } from 'antd';
import { useTheme } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { ButtonCard } from '@npm/core/ui/components/atoms/ButtonCard';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { InfoMessage } from '@npm/core/ui/components/molecules/InfoMessage';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { CbOrganizationTypeV2, useCodebook } from '@npm/data-access';

type Props = {
  form: FormInstance<unknown>;
  handleValuesChange?: FormProps['onValuesChange'];
  handleOpenSignModal?: () => Promise<void>;
};

export const EntityAccreditationForm = ({
  form,
  handleValuesChange,
  handleOpenSignModal,
}: Props) => {
  const theme = useTheme();
  const { isMobile } = useBreakpoints();

  const {
    data: accreditationEntityAnswerOptionsData,
    isLoading: isAccreditationEntityAnswerOptionsLoading,
  } = useCodebook({
    type: CbOrganizationTypeV2.code,
    attribute: [{ name: 'active', value: 'true' }],
  });

  const [itemsToShow, setItemsToShow] = useState(5);
  const handleSeeMoreOptionsClick = () => {
    setItemsToShow(
      accreditationEntityAnswerOptionsData?.codebooks?.length || 0
    );
  };

  const handleCancelFollowUpQuestionModal = () => {
    setIsFollowUpQuestionModalOpen(false);
    form.resetFields(['org_type']);
  };

  const getFollowUpAnswerClickHandler = (value: 'yes' | 'no') => async () => {
    form.setFieldValue('entity_total_assets', value);
    setIsFollowUpQuestionModalOpen(false);
    await handleOpenSignModal();
  };

  const [isFollowUpQuestionModalOpen, setIsFollowUpQuestionModalOpen] =
    useState(false);

  if (isAccreditationEntityAnswerOptionsLoading) {
    return <Skeleton.Base />;
  }

  return (
    <>
      <Flex direction="column" gap={isMobile ? 'sm' : 'lg'}>
        <InfoMessage
          iconName="badge"
          heading="Become an Accredited Investor"
          description={
            <Text>
              The purpose of this questionnaire is to obtain information
              relating to whether your entity is an &quot;Accredited Investor
              &quot; as defined in Rule 501(a) of Regulation D, the minimum
              requirement to purchase private company securities. Once
              completed, you’ll have the option to complete other investor
              sophistication forms so that we can qualify you for certain other
              products and services.
            </Text>
          }
          type="info"
        />
        <Heading variant={isMobile ? 'h1' : 'h3'}>
          Select the option that best defines your type of organization
        </Heading>
        <Form form={form} onValuesChange={handleValuesChange}>
          <Form.Item name="entity_total_assets" noStyle />
          <Form.Item name="org_type">
            <Radio.Group>
              <Flex direction="column" gap={isMobile ? 'sm' : 'md'}>
                {accreditationEntityAnswerOptionsData?.codebooks
                  ?.slice(0, itemsToShow)
                  .map(codebookItem => (
                    <ButtonCard
                      onClick={() => {
                        const followUpQuestion =
                          CbOrganizationTypeV2.getAttributeValueByName(
                            codebookItem,
                            'follow_up'
                          );
                        if (followUpQuestion) {
                          setIsFollowUpQuestionModalOpen(true);
                        } else {
                          form.resetFields(['entity_total_assets']);
                        }
                      }}
                      key={codebookItem.code}
                      variant="radio"
                      label={codebookItem.name}
                      value={codebookItem.code}
                    />
                  ))}
                {itemsToShow <
                  (accreditationEntityAnswerOptionsData?.codebooks?.length ||
                    0) && (
                  <Button
                    onClick={handleSeeMoreOptionsClick}
                    variant="outline"
                    icon={<Icon name="chevron-down" />}
                  >
                    See more options
                  </Button>
                )}
              </Flex>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Flex>

      {/*Follow-up question modal*/}
      <Modal
        size="md"
        title="Institutional Account Status"
        open={isFollowUpQuestionModalOpen}
        onCancel={handleCancelFollowUpQuestionModal}
        footer={
          <Flex
            style={{ flexGrow: 1 }}
            justify={'space-between'}
            direction={isMobile ? 'column-reverse' : 'row'}
          >
            <Button
              variant="text"
              icon={<Icon name="chevron-left" />}
              onClick={handleCancelFollowUpQuestionModal}
            >
              Back to form
            </Button>
            <Flex
              gap={isMobile ? 'sm' : 'xs'}
              direction={isMobile ? 'column-reverse' : 'row'}
            >
              <Button
                variant="outline"
                icon={<Icon name="x-close" />}
                onClick={getFollowUpAnswerClickHandler('no')}
              >
                No
              </Button>
              <Button
                variant="default"
                icon={<Icon name="check" />}
                onClick={getFollowUpAnswerClickHandler('yes')}
              >
                Yes
              </Button>
            </Flex>
          </Flex>
        }
      >
        {!isMobile && <Divider type={'horizontal'} marginTop={0} />}
        <Text>Does this Entity have total assets of at least $50 million?</Text>
        {!isMobile && (
          <Divider
            type={'horizontal'}
            marginTop={theme.spacing.md}
            marginBottom={0}
          />
        )}
      </Modal>
    </>
  );
};
