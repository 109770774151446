import { useEffect } from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Input, TextArea } from '@npm/core/ui/components/atoms/Input';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { IconCircle } from '@npm/core/ui/components/molecules/IconCircle';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { handleValidationError } from '@npm/core/ui/utils/form';

import { type ZendeskTicketGroupID, useZendesk } from '../widget';
import { getTicketGroupConfig } from '../widget/zendesk.utils';

import * as S from './ZendeskContactSupport.styles';

type Values = {
  question: string;
  details: string;
};

type Props = {
  zendeskTicketGroupId: ZendeskTicketGroupID;
  isOpen: boolean;
  onCancel: () => void;
};

export const ZendeskSupportModal = ({
  zendeskTicketGroupId,
  isOpen,
  onCancel,
}: Props) => {
  const [form] = Form.useForm<Values>();
  const {
    pageName = '',
    createCustomerRequest,
    createRequestLoading,
  } = useZendesk();
  const { showAlert } = useAlerts();

  useEffect(() => {
    if (zendeskTicketGroupId) {
      const ticketGroupConfigTitle =
        getTicketGroupConfig(zendeskTicketGroupId)?.title;
      const isConfigTitleOnly =
        getTicketGroupConfig(zendeskTicketGroupId)?.configTitleOnly;

      // set question field if title from config is available
      ticketGroupConfigTitle &&
        form.setFieldsValue({
          question: isConfigTitleOnly
            ? ticketGroupConfigTitle
            : `Question about: ${pageName} ${ticketGroupConfigTitle}`,
        });
    }
  }, [zendeskTicketGroupId]);

  const handleSubmit = async () => {
    let values: Values;

    try {
      values = await form.validateFields();
    } catch (err) {
      return handleValidationError(form, err);
    }

    const { success, message } = await createCustomerRequest(
      zendeskTicketGroupId,
      `${values.question}: \n\n${values.details}`
    );

    if (success) {
      Notification.success({ message });
      form.resetFields();
      onCancel();
    } else {
      showAlert(message, {
        type: 'error',
      });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title={
        <S.ModalTitle>
          <IconCircle name="lifebuoy" />
          <span>Help</span>
        </S.ModalTitle>
      }
      okText={'Send'}
      okButtonProps={{
        loading: createRequestLoading,
        onClick: handleSubmit,
      }}
      cancelText="Cancel"
      cancelButtonProps={{
        variant: 'text',
      }}
      width={594}
      open={isOpen}
      onCancel={handleCancel}
    >
      <Form form={form}>
        <FormItem
          name="question"
          label="Question"
          rules={[VALIDATION_RULES.required()]}
          validateTrigger="onSubmit"
        >
          <Input placeholder="Briefly describe your question" />
        </FormItem>
        <Margin bottom="md" />
        <FormItem
          name="details"
          label="Details"
          rules={[VALIDATION_RULES.required()]}
          validateTrigger="onSubmit"
        >
          <TextArea placeholder="Fill in details here, please, be as specific as possible." />
        </FormItem>
      </Form>
    </Modal>
  );
};
