import { useTheme } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';

import { useUnlockPremium } from '../../../auth/user/components';

import * as S from './PremiumTrialBanner.styles';

export const PremiumTrialBanner = () => {
  const theme = useTheme();

  const unlockPremiumAccount = useUnlockPremium();

  return (
    <S.Card>
      <Heading variant="h2" marginBottom="sm">
        You are on a Premium Data Trial
      </Heading>
      <Flex gap="md" $wrap>
        {['Tape D™ Pricing', 'Cap Table & Waterfall', 'Source Documents'].map(
          item => (
            <Flex gap="xs" align="center" key={item}>
              <Icon
                name="check"
                size="xs"
                color={theme.color.info.typography.primary}
              />
              <Text size="sm" style={{ whiteSpace: 'nowrap' }}>
                {item}
              </Text>
            </Flex>
          )
        )}
      </Flex>
      <Margin top="md">
        <Button
          variant="default"
          icon={<Icon name="chevron-right" />}
          iconPosition="right"
          onClick={unlockPremiumAccount}
        >
          Upgrade to Premium
        </Button>
      </Margin>
    </S.Card>
  );
};
