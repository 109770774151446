import { useMemo } from 'react';

import type { WithDate } from '../../../CompanyOverview.types';
import {
  getFirstDayOfMonth,
  getMinimumDate,
} from '../../../CompanyOverview.utils';

export const useBidOfferHistoryChartDateLimits = (
  ...dataArrays: WithDate[][]
) => {
  return useMemo(() => {
    const minDate = getMinimumDate(...dataArrays);
    const maxDate = new Date();

    return [getFirstDayOfMonth(minDate), maxDate];
  }, [...dataArrays]);
};
