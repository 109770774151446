import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { CbEventStatus, useEventList } from '@npm/data-access';

import { useUserContextStore } from '../../auth/user/context';

import { IconBox } from './IconBox';

type Props = {
  modalProps: React.ComponentProps<typeof Modal>;
  accountId?: number;
};

const getFooterButtonsProps = (
  hasLivePrograms: boolean,
  continueOnboarding: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  goToLivePrograms: () => void,
  isLoading: boolean,
  isMobile: boolean,
  hasAccessToMarketplace: boolean
): Partial<Props['modalProps']> => {
  const continueButtonText = `Continue with ${
    hasAccessToMarketplace ? 'Marketplace Onboarding' : 'Accreditation'
  }`;

  if (isLoading) {
    return {
      footer: (
        <Skeleton.Button
          block={isMobile}
          style={{ width: !isMobile ? '160px' : '100%' }}
        />
      ),
    };
  }

  if (hasLivePrograms) {
    return {
      okText: 'Continue to Live Programs',
      okButtonProps: {
        icon: <Icon name="external-link" size="xs" />,
      },
      onOk: goToLivePrograms,
      cancelText: continueButtonText,
      onCancel: continueOnboarding,
    };
  } else {
    return {
      okText: continueButtonText,
      onOk: continueOnboarding,
      okButtonProps: {
        icon: <Icon name="external-link" size="xs" />,
      },
    };
  }
};

export const AMLKYCCompletedModal = ({ modalProps, accountId }: Props) => {
  const history = useHistory();
  const { isMobile } = useBreakpoints();

  const isEntityNpmsInvestor = useUserContextStore(
    store => store.isEntityNpmsInvestor
  );
  const isIndividualNpmsInvestor = useUserContextStore(
    store => store.isIndividualNpmsInvestor
  );
  const { data, isLoading } = useEventList(
    { accountId, eventStatus: CbEventStatus.items.ACTIVE, size: 0 },
    { queryConfig: { enabled: !!accountId && modalProps.open } }
  );
  const hasLivePrograms = data?.pagination?.total_records > 0;
  const hasAccessToMarketplace =
    isEntityNpmsInvestor || isIndividualNpmsInvestor;

  const goToLivePrograms = () => {
    history.push('/investor-workstation/programs/live-programs');
  };

  return (
    <Modal
      {...modalProps}
      title="Congratulations!"
      size="md"
      {...getFooterButtonsProps(
        hasLivePrograms,
        modalProps.onCancel,
        goToLivePrograms,
        isLoading,
        isMobile,
        hasAccessToMarketplace
      )}
    >
      {isLoading ? (
        <Skeleton.Base />
      ) : (
        <Flex>
          <IconBox />
          <Text size="sm">
            {!hasAccessToMarketplace
              ? `Congratulations on completing AML KYC. If you are purchasing shares, please proceed to accreditation. Otherwise, click 'Continue to Live Programs.'`
              : `Your AML/KYC submission has been received and is under review. ${
                  hasLivePrograms
                    ? `Click
            on "Continue to Live Programs" to participate in an event. You can
            also choose to continue with Marketplace onboarding to unlock full
            marketplace capabilities via the SecondMarket Trading platform.`
                    : `Click on "Continue with Marketplace Onboarding" to unlock full marketplace capabilities via the SecondMarket Trading platform.`
                }`}
          </Text>
        </Flex>
      )}
    </Modal>
  );
};
