import styled, { css } from 'styled-components';

import { useBreakpoint } from '@npm/core/ui/utils/style';

export const Card = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.color.general.borders.primary};
    border-radius: ${theme.borderRadius.lg}px;
    padding: ${theme.spacing.lg}px;

    background: ${theme.isDarkMode
          ? 'url(/public/assets/company-data/taped-dark.png)'
          : 'url(/public/assets/company-data/taped-light.png)'}
        right center / auto 100% no-repeat,
      radial-gradient(
        circle at -200% -200%,
        ${theme.isDarkMode
          ? theme.color.info.backgrounds.primaryDefault
          : 'transparent'},
        transparent 75%
      ),
      ${theme.color.general.layout.one};

    ${useBreakpoint(theme).tablet`
      background-position: right -200px center, 0 0;
    `}

    ${useBreakpoint(theme).smTablet`
      padding: ${theme.spacing.md}px;
      background-position: right -400px center, 0 0;
    `}
  `}
`;
