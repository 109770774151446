import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { Link } from '@npm/core/ui/components/molecules/Link';

import * as S from './about.styles';

const AboutPage = () => {
  return (
    <S.Wrapper>
      <Heading variant="h1">About</Heading>
      <p>
        {"Information about this software and it's licence can be found "}
        <Link to="https://www.nasdaqprivatemarket.com/">here.</Link>
      </p>
      <section style={{ marginTop: '10px' }}>
        <Heading variant="h2">Other Licences</Heading>
        <p>
          Caniuse-lite: Used under license from caniuse.com.
          <br />
          Spdx-exceptions: Copyright © 2010-2015 Linux Foundation and its
          Contributors. Licensed under the Creative Commons Attribution License
          3.0 Unported. All other rights are expressly reserved.
        </p>
      </section>
    </S.Wrapper>
  );
};

export default AboutPage;
