import { type AccountsSignerCreateRequestContract } from '@npm/data-access';

export const ADD_AUTHORIZED_SIGNER_FORM_FIELDS_ENUM = {
  FIRST: 'first',
  MIDDLE: 'middle',
  LAST: 'last',
  EMAIL: 'email',
  ACCEPT_DISCLAIMER: 'accept-disclaimer',
};

export type FormValues = Omit<
  AccountsSignerCreateRequestContract,
  'role_type'
> & {
  'accept-disclaimer': boolean;
};
