import React from 'react';

import { type CbLabelProps, type CbRenderElement } from '@npm/utils';

import { Icon } from '../Icon';
import { Text } from '../Typography';

import { Label } from './Label';

export const CbLabel = (props: CbLabelProps): CbRenderElement => {
  if (!props) return null;

  const { variant = 'general', iconProps, label, round, style } = props;

  if (!label) return <Text size="sm">--</Text>;

  const icon = iconProps ? <Icon {...iconProps} /> : undefined;

  return (
    <Label round={round} icon={icon} variant={variant} style={style}>
      {label}
    </Label>
  );
};
