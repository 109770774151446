import React, { useEffect } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { PAGE_SIZE_HARD_LIMIT } from '@npm/core/ui/components/molecules/Table';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  type UserWithRolesForAccount,
  useAccountRepresentativeIndex,
} from '@npm/data-access';

import { AccountName } from '../../../../../../account/components/AccountName';
import {
  type OboModalState,
  type SetOboModalState,
} from '../../OboModal.types';

import { type SelectRepresentativeListProps } from './SelectRepresentative.types';
import { SelectRepresentativeCards } from './SelectRepresentativeCards';
import { SelectRepresentativeTable } from './SelectRepresentativeTable';

import * as S from '../../OboModal.styles';

type Props = {
  oboModalState: OboModalState;
  setOboModalState: SetOboModalState;
  handleComplete: (nextOboState: OboModalState) => void;
  goBack: () => void;
};

export const SelectRepresentative = ({
  oboModalState,
  setOboModalState,
  goBack,
  handleComplete,
}: Props) => {
  const { isMobile } = useBreakpoints();

  const { data, isLoading } = useAccountRepresentativeIndex(
    { accountId: oboModalState.account?.id, size: PAGE_SIZE_HARD_LIMIT },
    { queryConfig: { enabled: !!oboModalState.account?.id } }
  );

  const handleSelect = (representative: UserWithRolesForAccount) => {
    setOboModalState(prev => ({ ...prev, representative }));
  };

  useEffect(() => {
    if (!oboModalState.account) {
      goBack();
    }
  }, [oboModalState.account]);

  const commonListProps: SelectRepresentativeListProps = {
    data: data?.representatives,
    isLoading,
    selectedRepresentative: oboModalState.representative,
    handleSelect,
    goBack,
  };

  return (
    <S.InnerWrapper>
      <Margin bottom="sm">
        <Alert
          message={
            <>
              <Text size="sm" color="info" marginBottom="sm">
                Representatives for
              </Text>
              <AccountName account={oboModalState.account} isActive={false} />
            </>
          }
        />
      </Margin>
      {isMobile ? (
        <SelectRepresentativeCards
          {...commonListProps}
          oboModalState={oboModalState}
          handleComplete={handleComplete}
        />
      ) : (
        <SelectRepresentativeTable {...commonListProps} />
      )}
    </S.InnerWrapper>
  );
};
