import { flatten, sortBy, uniq } from 'lodash';

export type Datapoint = {
  // date or number
  x: number | string;
  y: number | undefined;
};

// creates whitespace datapoints for multiple datasets so they can be displayed on the same chart
export const addWhitespaceLabels = (...datasets: Datapoint[][]) => {
  const allLabels = sortBy(
    uniq(
      flatten(datasets.map(dataset => dataset.map(datapoint => datapoint.x)))
    ),
    i => i
  );

  return datasets.map(dataset => addWhitespace(dataset, allLabels));
};

const addWhitespace = (
  dataset: Datapoint[],
  allLabels: (string | number)[]
): Datapoint[] => {
  const datasetWithWhitespace: Datapoint[] = [];

  let datasetIndex = 0;
  for (let i = 0; i < allLabels.length; i++) {
    const currentDatapoint = dataset[datasetIndex];
    const currentLabel = allLabels[i];

    if (
      currentDatapoint != null &&
      currentLabel != null &&
      currentDatapoint.x === currentLabel
    ) {
      datasetWithWhitespace.push({ ...currentDatapoint });
      datasetIndex++;
    } else if (currentLabel != null) {
      datasetWithWhitespace.push({ x: currentLabel, y: undefined });
    }
  }

  return datasetWithWhitespace;
};
