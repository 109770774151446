import { type ComponentProps } from 'react';

import { type Modal } from '@npm/core/ui/components/molecules/Modal';
import { type TableVerticalRowType } from '@npm/core/ui/components/molecules/TableVertical';
import { type DocumentSimple, type Holding } from '@npm/data-access';

import {
  getHoldingItem,
  getIsHoldingAttributeVisible,
} from '../Holdings.utils';
import { type HoldingsList } from '../List';

export const getHoldingRows = ({
  onDocumentClick,
  openModal,
  config,
  visibleRowCount = 3,
}: {
  onDocumentClick?: (doc: DocumentSimple) => void;
  openModal?: (props?: ComponentProps<typeof Modal>) => void;
  config?: ComponentProps<typeof HoldingsList>['config'] & {
    showOfferPrice?: boolean;
    showRemainingQuantity?: boolean;
  };
  visibleRowCount?: number;
}): TableVerticalRowType<Holding>[] => {
  const rows: TableVerticalRowType<Holding>[] = [
    {
      ...getHoldingItem('remaining_quantity')(),
      hidden: !config.showRemainingQuantity,
    },
    {
      ...getHoldingItem('quantity')(),
      hidden: !config.showQuantity,
    },
    {
      ...getHoldingItem('vested_qty')(),
      hidden: !config.showVestedQuantity,
    },
    {
      ...getHoldingItem('class')(),
      hidden: (_, { asset }) =>
        !config.showClass ||
        !getIsHoldingAttributeVisible(asset?.type?.code, 'class'),
    },
    {
      ...getHoldingItem('series')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'series'),
    },
    {
      ...getHoldingItem('cost_basis')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'cost_basis'),
    },
    {
      ...getHoldingItem('offer_price')(),
      hidden: !config.showOfferPrice,
    },
    {
      ...getHoldingItem('asset')(),
      hidden: !config.showAsset,
    },
    {
      ...getHoldingItem('account')(),
      hidden: !config.showAccount,
    },
    {
      ...getHoldingItem('grant_type')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'grant_type'),
    },
    {
      ...getHoldingItem('strike_price')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'strike_price'),
    },
    {
      ...getHoldingItem('state')({ onShowInstructions: openModal }),
      title: 'Status',
      hidden: !config.showVerifiedOwnership,
    },
    {
      ...getHoldingItem('plan')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'plan'),
    },
    {
      ...getHoldingItem('registered_name')(),
    },
    {
      ...getHoldingItem('proof_of_ownership_document')({
        onClick: onDocumentClick,
      }),
      hidden: !config.showProofOfOwnership,
    },
    {
      ...getHoldingItem('acquisition_date')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'acquisition_date'),
    },
    {
      ...getHoldingItem('grant_date')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'grant_date'),
    },
    {
      ...getHoldingItem('expiration_date')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'expiration_date'),
    },
    {
      ...getHoldingItem('issuance')(),
      hidden: !config.showIssuance,
    },
  ];

  return rows.map(item => ({
    ...item,
    collapsed: (_, index) => index >= visibleRowCount,
  }));
};
