import React from 'react';
import {
  type ColorGroup,
  type DefaultTheme,
  useTheme,
} from 'styled-components';

import { type IconProps, icons, staticColorIcons } from '@npm/utils';

import { IconBackground } from './IconBackground';
import { PulsingDot } from './PulsingDot';

import * as S from './Icon.styles';

export const Icon = ({
  name,
  size = 24,
  color,
  strokeWidth,
  variant = 'default',
  className,
}: IconProps) => {
  const theme = useTheme();

  const IconComponent = icons[name];

  const colorValue = getColorValue(color, theme);

  const sizeValue = typeof size === 'string' ? theme?.icon.size[size] : size;
  const defaultStrokeWidth =
    sizeValue > 24 ? theme?.icon.strokeWidth.lg : theme?.icon.strokeWidth.md;

  const icon =
    name === 'pulsing-dot' ? (
      <PulsingDot />
    ) : (
      <S.SvgIcon
        as={IconComponent}
        width={sizeValue}
        height={sizeValue}
        $keepOriginalColor={staticColorIcons.includes(name)}
        $color={colorValue}
        $strokeWidth={strokeWidth || defaultStrokeWidth || 1.5}
        className={className}
      />
    );

  if (variant === 'square') {
    return <IconBackground>{icon}</IconBackground>;
  }

  return icon;
};

const getColorValue = (
  color: ColorGroup | string | undefined,
  theme: DefaultTheme
) => {
  if (!color) {
    return undefined;
  }

  if (theme.color?.[color]) {
    return theme.color[color as ColorGroup].typography.primary;
  }

  return color;
};
