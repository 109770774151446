import styled from 'styled-components';

const STEP_HEIGHT = 100;

export const StepWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${STEP_HEIGHT}px;
  background-color: ${({ theme }) => theme.color.general.layout.two};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  padding: ${({ theme }) => theme.spacing.sm}px;

  &:first-child {
    background-color: ${({ theme }) =>
      theme.color.success.backgrounds.secondaryDefault};
    border: solid 1px ${({ theme }) => theme.color.success.borders.primary};

    svg {
      color: ${({ theme }) => theme.color.success.typography.primary};
    }
  }

  p {
    text-align: center;
  }

  svg {
    color: ${({ theme }) => theme.color.general.typography.secondary};
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }
`;
