import { Codebooks } from './codebooks.types';

const Items = {
  event: 'event',
} as const;

export const CbResourceType = {
  code: Codebooks.RESOURCE_TYPE,
  items: Items,
};
