import styled from 'styled-components';

import { useBreakpoint } from '@npm/core/ui/utils/style';

export const Wrapper = styled.div`
  display: flex;

  ${({ theme }) => useBreakpoint(theme).mobile`
    flex-direction: column-reverse;
    gap: ${theme.spacing.sm}px;
    align-items: stretch;
    padding: ${theme.spacing.md}px;

  `}
`;
