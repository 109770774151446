import { useCallback, useEffect } from 'react';
import { useWizard } from 'react-use-wizard';
import { useIsFirstRender } from 'usehooks-ts';

import { usePreviousDistinct } from '@npm/utils';

import { useWizardStore } from './Wizard.store';
import {
  type WizardHistoryState,
  WizardStoreActionTypes,
} from './Wizard.types';
import { updateHistoryState } from './Wizard.utils';

export const useWizardHistoryState = (startIndex = 0) => {
  const isFirstRender = useIsFirstRender();
  const prevHistoryStateStep = usePreviousDistinct(
    window.history.state?.activeStep
  );
  const { activeStep, goToStep } = useWizard();
  const { isStepperMobileOpen, dispatch } = useWizardStore(s => ({
    isStepperMobileOpen: s.isStepperMobileOpen,
    dispatch: s.dispatch,
  }));

  useEffect(() => {
    const currentState: WizardHistoryState = window.history.state;

    // handle page reload - stay on the same step
    if (isFirstRender && currentState?.activeStep !== undefined) {
      goToStep(currentState.activeStep);
      return;
    }

    // first time entering the wizard without any step saved in history
    if (
      !currentState ||
      (currentState.activeStep === undefined && !prevHistoryStateStep)
    ) {
      updateHistoryState('replace', { activeStep: startIndex ?? 0 });
      goToStep(startIndex);
      // update history state as active step changes
    } else if (currentState.activeStep !== activeStep) {
      updateHistoryState('push', { activeStep });
    }
  }, [activeStep]);

  // handle browser's back/forward button click
  const handlePopstate = useCallback(() => {
    const historyState: WizardHistoryState = window.history.state;

    if (!historyState) return;

    if (historyState.activeStep !== undefined) {
      goToStep(historyState.activeStep);
    }

    if (historyState.isStepperMobileOpen !== isStepperMobileOpen) {
      dispatch({ type: WizardStoreActionTypes.TOGGLE_STEPPER_MOBILE_OPEN });
    }
  }, [dispatch, goToStep, isStepperMobileOpen]);

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [handlePopstate]);
};
