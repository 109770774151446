/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DestroySuccess } from '../model';
// @ts-ignore
import { ErrorValidation } from '../model';
// @ts-ignore
import { SecondmarketInvestorOrderItemShow } from '../model';
// @ts-ignore
import { SecondmarketOrderItemIndex } from '../model';
// @ts-ignore
import { SecondmarketOrderItemShow } from '../model';
// @ts-ignore
import { SecondmarketOrderItemUpdateRequestContract } from '../model';
/**
 * SecondmarketOrderItemApi - axios parameter creator
 * @export
 */
export const SecondmarketOrderItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary retrieves the details of a secondmarket order item for an investor
         * @param {number} id 
         * @param {Array<'remaining_quantity' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'contact_information'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorOrderItemShow: async (id: number, includeField?: Array<'remaining_quantity' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'contact_information'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('investorOrderItemShow', 'id', id)
            const localVarPath = changeUrl(`/api/secondmarket/investor/order-items/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cancels a secondmarket order item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketOrderItemCancel: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('secondmarketOrderItemCancel', 'id', id)
            const localVarPath = changeUrl(`/api/secondmarket-order-items/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list OrderItems for Secondmarket
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [issuerEntityId] 
         * @param {number} [parentOrderItemId] 
         * @param {boolean} [itemsForOrderbook] include order items from other brokerage firms with live status in result
         * @param {boolean} [findLive] filter for order items in states that indicate live status (pending w/ remaining_qty &gt; 0)
         * @param {string} [orderBuySell] filter for buy or sell order item types
         * @param {string} [placedAfter] 
         * @param {string} [expiringBefore] 
         * @param {Array<string>} [orderEntryType] filter for firm or ioi order items
         * @param {boolean} [findUserAccountWatchlist] filter for order items in obo account watchlist
         * @param {Array<string>} [state] 
         * @param {boolean} [hideOwnBrokerageFirm] 
         * @param {string} [sort] 
         * @param {number} [sizeRangeStart] 
         * @param {number} [sizeRangeEnd] 
         * @param {Array<string>} [atsPool] 
         * @param {boolean} [ownOrdersOnly] filter for order items that belong to my current account
         * @param {string} [orderItemUnit] filter for USD or SHARES order items. SHARES items must have size and PPS
         * @param {boolean} [withPrice] filter for order items that have a price
         * @param {boolean} [portfolioOnly] filter for order items in the user portfolio
         * @param {Array<'type_totals' | 'issuer_entity' | 'holding' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'contact_information' | 'note'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketOrderItemIndex: async (page?: number, size?: number, search?: string, issuerEntityId?: number, parentOrderItemId?: number, itemsForOrderbook?: boolean, findLive?: boolean, orderBuySell?: string, placedAfter?: string, expiringBefore?: string, orderEntryType?: Array<string>, findUserAccountWatchlist?: boolean, state?: Array<string>, hideOwnBrokerageFirm?: boolean, sort?: string, sizeRangeStart?: number, sizeRangeEnd?: number, atsPool?: Array<string>, ownOrdersOnly?: boolean, orderItemUnit?: string, withPrice?: boolean, portfolioOnly?: boolean, includeField?: Array<'type_totals' | 'issuer_entity' | 'holding' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'contact_information' | 'note'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/secondmarket-order-items`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }

            if (parentOrderItemId !== undefined) {
                localVarQueryParameter['parent_order_item_id'] = parentOrderItemId;
            }

            if (itemsForOrderbook !== undefined) {
                localVarQueryParameter['items_for_orderbook'] = itemsForOrderbook;
            }

            if (findLive !== undefined) {
                localVarQueryParameter['find_live'] = findLive;
            }

            if (orderBuySell !== undefined) {
                localVarQueryParameter['order_buy_sell'] = orderBuySell;
            }

            if (placedAfter !== undefined) {
                localVarQueryParameter['placed_after'] = placedAfter;
            }

            if (expiringBefore !== undefined) {
                localVarQueryParameter['expiring_before'] = expiringBefore;
            }

            if (orderEntryType) {
                localVarQueryParameter['order_entry_type'] = orderEntryType.join(COLLECTION_FORMATS.csv);
            }

            if (findUserAccountWatchlist !== undefined) {
                localVarQueryParameter['find_user_account_watchlist'] = findUserAccountWatchlist;
            }

            if (state) {
                localVarQueryParameter['state'] = state.join(COLLECTION_FORMATS.csv);
            }

            if (hideOwnBrokerageFirm !== undefined) {
                localVarQueryParameter['hide_own_brokerage_firm'] = hideOwnBrokerageFirm;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (sizeRangeStart !== undefined) {
                localVarQueryParameter['size_range_start'] = sizeRangeStart;
            }

            if (sizeRangeEnd !== undefined) {
                localVarQueryParameter['size_range_end'] = sizeRangeEnd;
            }

            if (atsPool) {
                localVarQueryParameter['ats_pool'] = atsPool.join(COLLECTION_FORMATS.csv);
            }

            if (ownOrdersOnly !== undefined) {
                localVarQueryParameter['own_orders_only'] = ownOrdersOnly;
            }

            if (orderItemUnit !== undefined) {
                localVarQueryParameter['order_item_unit'] = orderItemUnit;
            }

            if (withPrice !== undefined) {
                localVarQueryParameter['with_price'] = withPrice;
            }

            if (portfolioOnly !== undefined) {
                localVarQueryParameter['portfolio_only'] = portfolioOnly;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieves the details of a secondmarket order item
         * @param {number} id 
         * @param {Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'issuer_entity' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketOrderItemShow: async (id: number, includeField?: Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'issuer_entity' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('secondmarketOrderItemShow', 'id', id)
            const localVarPath = changeUrl(`/api/secondmarket-order-items/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates the details of a secondmarket order item
         * @param {number} id 
         * @param {SecondmarketOrderItemUpdateRequestContract} secondmarketOrderItemUpdateRequestContract 
         * @param {Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic'>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketOrderItemUpdate: async (id: number, secondmarketOrderItemUpdateRequestContract: SecondmarketOrderItemUpdateRequestContract, includeField?: Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('secondmarketOrderItemUpdate', 'id', id)
            // verify required parameter 'secondmarketOrderItemUpdateRequestContract' is not null or undefined
            assertParamExists('secondmarketOrderItemUpdate', 'secondmarketOrderItemUpdateRequestContract', secondmarketOrderItemUpdateRequestContract)
            const localVarPath = changeUrl(`/api/secondmarket-order-items/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secondmarketOrderItemUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecondmarketOrderItemApi - functional programming interface
 * @export
 */
export const SecondmarketOrderItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecondmarketOrderItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary retrieves the details of a secondmarket order item for an investor
         * @param {number} id 
         * @param {Array<'remaining_quantity' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'contact_information'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investorOrderItemShow(id: number, includeField?: Array<'remaining_quantity' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'contact_information'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecondmarketInvestorOrderItemShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investorOrderItemShow(id, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cancels a secondmarket order item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secondmarketOrderItemCancel(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroySuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secondmarketOrderItemCancel(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list OrderItems for Secondmarket
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [issuerEntityId] 
         * @param {number} [parentOrderItemId] 
         * @param {boolean} [itemsForOrderbook] include order items from other brokerage firms with live status in result
         * @param {boolean} [findLive] filter for order items in states that indicate live status (pending w/ remaining_qty &gt; 0)
         * @param {string} [orderBuySell] filter for buy or sell order item types
         * @param {string} [placedAfter] 
         * @param {string} [expiringBefore] 
         * @param {Array<string>} [orderEntryType] filter for firm or ioi order items
         * @param {boolean} [findUserAccountWatchlist] filter for order items in obo account watchlist
         * @param {Array<string>} [state] 
         * @param {boolean} [hideOwnBrokerageFirm] 
         * @param {string} [sort] 
         * @param {number} [sizeRangeStart] 
         * @param {number} [sizeRangeEnd] 
         * @param {Array<string>} [atsPool] 
         * @param {boolean} [ownOrdersOnly] filter for order items that belong to my current account
         * @param {string} [orderItemUnit] filter for USD or SHARES order items. SHARES items must have size and PPS
         * @param {boolean} [withPrice] filter for order items that have a price
         * @param {boolean} [portfolioOnly] filter for order items in the user portfolio
         * @param {Array<'type_totals' | 'issuer_entity' | 'holding' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'contact_information' | 'note'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secondmarketOrderItemIndex(page?: number, size?: number, search?: string, issuerEntityId?: number, parentOrderItemId?: number, itemsForOrderbook?: boolean, findLive?: boolean, orderBuySell?: string, placedAfter?: string, expiringBefore?: string, orderEntryType?: Array<string>, findUserAccountWatchlist?: boolean, state?: Array<string>, hideOwnBrokerageFirm?: boolean, sort?: string, sizeRangeStart?: number, sizeRangeEnd?: number, atsPool?: Array<string>, ownOrdersOnly?: boolean, orderItemUnit?: string, withPrice?: boolean, portfolioOnly?: boolean, includeField?: Array<'type_totals' | 'issuer_entity' | 'holding' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'contact_information' | 'note'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecondmarketOrderItemIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secondmarketOrderItemIndex(page, size, search, issuerEntityId, parentOrderItemId, itemsForOrderbook, findLive, orderBuySell, placedAfter, expiringBefore, orderEntryType, findUserAccountWatchlist, state, hideOwnBrokerageFirm, sort, sizeRangeStart, sizeRangeEnd, atsPool, ownOrdersOnly, orderItemUnit, withPrice, portfolioOnly, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary retrieves the details of a secondmarket order item
         * @param {number} id 
         * @param {Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'issuer_entity' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secondmarketOrderItemShow(id: number, includeField?: Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'issuer_entity' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecondmarketOrderItemShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secondmarketOrderItemShow(id, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates the details of a secondmarket order item
         * @param {number} id 
         * @param {SecondmarketOrderItemUpdateRequestContract} secondmarketOrderItemUpdateRequestContract 
         * @param {Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic'>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secondmarketOrderItemUpdate(id: number, secondmarketOrderItemUpdateRequestContract: SecondmarketOrderItemUpdateRequestContract, includeField?: Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecondmarketOrderItemShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secondmarketOrderItemUpdate(id, secondmarketOrderItemUpdateRequestContract, includeField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SecondmarketOrderItemApi - factory interface
 * @export
 */
export const SecondmarketOrderItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecondmarketOrderItemApiFp(configuration)
    return {
        /**
         * 
         * @summary retrieves the details of a secondmarket order item for an investor
         * @param {number} id 
         * @param {Array<'remaining_quantity' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'contact_information'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorOrderItemShow(id: number, includeField?: Array<'remaining_quantity' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'contact_information'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<SecondmarketInvestorOrderItemShow> {
            return localVarFp.investorOrderItemShow(id, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cancels a secondmarket order item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketOrderItemCancel(id: number, options?: any): AxiosPromise<DestroySuccess> {
            return localVarFp.secondmarketOrderItemCancel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list OrderItems for Secondmarket
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [issuerEntityId] 
         * @param {number} [parentOrderItemId] 
         * @param {boolean} [itemsForOrderbook] include order items from other brokerage firms with live status in result
         * @param {boolean} [findLive] filter for order items in states that indicate live status (pending w/ remaining_qty &gt; 0)
         * @param {string} [orderBuySell] filter for buy or sell order item types
         * @param {string} [placedAfter] 
         * @param {string} [expiringBefore] 
         * @param {Array<string>} [orderEntryType] filter for firm or ioi order items
         * @param {boolean} [findUserAccountWatchlist] filter for order items in obo account watchlist
         * @param {Array<string>} [state] 
         * @param {boolean} [hideOwnBrokerageFirm] 
         * @param {string} [sort] 
         * @param {number} [sizeRangeStart] 
         * @param {number} [sizeRangeEnd] 
         * @param {Array<string>} [atsPool] 
         * @param {boolean} [ownOrdersOnly] filter for order items that belong to my current account
         * @param {string} [orderItemUnit] filter for USD or SHARES order items. SHARES items must have size and PPS
         * @param {boolean} [withPrice] filter for order items that have a price
         * @param {boolean} [portfolioOnly] filter for order items in the user portfolio
         * @param {Array<'type_totals' | 'issuer_entity' | 'holding' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'contact_information' | 'note'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketOrderItemIndex(page?: number, size?: number, search?: string, issuerEntityId?: number, parentOrderItemId?: number, itemsForOrderbook?: boolean, findLive?: boolean, orderBuySell?: string, placedAfter?: string, expiringBefore?: string, orderEntryType?: Array<string>, findUserAccountWatchlist?: boolean, state?: Array<string>, hideOwnBrokerageFirm?: boolean, sort?: string, sizeRangeStart?: number, sizeRangeEnd?: number, atsPool?: Array<string>, ownOrdersOnly?: boolean, orderItemUnit?: string, withPrice?: boolean, portfolioOnly?: boolean, includeField?: Array<'type_totals' | 'issuer_entity' | 'holding' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'contact_information' | 'note'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<SecondmarketOrderItemIndex> {
            return localVarFp.secondmarketOrderItemIndex(page, size, search, issuerEntityId, parentOrderItemId, itemsForOrderbook, findLive, orderBuySell, placedAfter, expiringBefore, orderEntryType, findUserAccountWatchlist, state, hideOwnBrokerageFirm, sort, sizeRangeStart, sizeRangeEnd, atsPool, ownOrdersOnly, orderItemUnit, withPrice, portfolioOnly, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieves the details of a secondmarket order item
         * @param {number} id 
         * @param {Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'issuer_entity' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketOrderItemShow(id: number, includeField?: Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'issuer_entity' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<SecondmarketOrderItemShow> {
            return localVarFp.secondmarketOrderItemShow(id, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates the details of a secondmarket order item
         * @param {number} id 
         * @param {SecondmarketOrderItemUpdateRequestContract} secondmarketOrderItemUpdateRequestContract 
         * @param {Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic'>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketOrderItemUpdate(id: number, secondmarketOrderItemUpdateRequestContract: SecondmarketOrderItemUpdateRequestContract, includeField?: Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic'>, options?: any): AxiosPromise<SecondmarketOrderItemShow> {
            return localVarFp.secondmarketOrderItemUpdate(id, secondmarketOrderItemUpdateRequestContract, includeField, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for investorOrderItemShow operation in SecondmarketOrderItemApi.
 * @export
 * @interface SecondmarketOrderItemApiInvestorOrderItemShowRequest
 */
export interface SecondmarketOrderItemApiInvestorOrderItemShowRequest {
    /**
     * 
     * @type {number}
     * @memberof SecondmarketOrderItemApiInvestorOrderItemShow
     */
    readonly id: number

    /**
     * 
     * @type {Array<'remaining_quantity' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'contact_information'>}
     * @memberof SecondmarketOrderItemApiInvestorOrderItemShow
     */
    readonly includeField?: Array<'remaining_quantity' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'contact_information'>

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiInvestorOrderItemShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiInvestorOrderItemShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiInvestorOrderItemShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiInvestorOrderItemShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiInvestorOrderItemShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiInvestorOrderItemShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for secondmarketOrderItemCancel operation in SecondmarketOrderItemApi.
 * @export
 * @interface SecondmarketOrderItemApiSecondmarketOrderItemCancelRequest
 */
export interface SecondmarketOrderItemApiSecondmarketOrderItemCancelRequest {
    /**
     * 
     * @type {number}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemCancel
     */
    readonly id: number
}

/**
 * Request parameters for secondmarketOrderItemIndex operation in SecondmarketOrderItemApi.
 * @export
 * @interface SecondmarketOrderItemApiSecondmarketOrderItemIndexRequest
 */
export interface SecondmarketOrderItemApiSecondmarketOrderItemIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly issuerEntityId?: number

    /**
     * 
     * @type {number}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly parentOrderItemId?: number

    /**
     * include order items from other brokerage firms with live status in result
     * @type {boolean}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly itemsForOrderbook?: boolean

    /**
     * filter for order items in states that indicate live status (pending w/ remaining_qty &gt; 0)
     * @type {boolean}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly findLive?: boolean

    /**
     * filter for buy or sell order item types
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly orderBuySell?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly placedAfter?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly expiringBefore?: string

    /**
     * filter for firm or ioi order items
     * @type {Array<string>}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly orderEntryType?: Array<string>

    /**
     * filter for order items in obo account watchlist
     * @type {boolean}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly findUserAccountWatchlist?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly state?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly hideOwnBrokerageFirm?: boolean

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly sort?: string

    /**
     * 
     * @type {number}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly sizeRangeStart?: number

    /**
     * 
     * @type {number}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly sizeRangeEnd?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly atsPool?: Array<string>

    /**
     * filter for order items that belong to my current account
     * @type {boolean}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly ownOrdersOnly?: boolean

    /**
     * filter for USD or SHARES order items. SHARES items must have size and PPS
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly orderItemUnit?: string

    /**
     * filter for order items that have a price
     * @type {boolean}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly withPrice?: boolean

    /**
     * filter for order items in the user portfolio
     * @type {boolean}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly portfolioOnly?: boolean

    /**
     * 
     * @type {Array<'type_totals' | 'issuer_entity' | 'holding' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'contact_information' | 'note'>}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly includeField?: Array<'type_totals' | 'issuer_entity' | 'holding' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'contact_information' | 'note'>

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for secondmarketOrderItemShow operation in SecondmarketOrderItemApi.
 * @export
 * @interface SecondmarketOrderItemApiSecondmarketOrderItemShowRequest
 */
export interface SecondmarketOrderItemApiSecondmarketOrderItemShowRequest {
    /**
     * 
     * @type {number}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemShow
     */
    readonly id: number

    /**
     * 
     * @type {Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'issuer_entity' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker'>}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemShow
     */
    readonly includeField?: Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'issuer_entity' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker'>

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for secondmarketOrderItemUpdate operation in SecondmarketOrderItemApi.
 * @export
 * @interface SecondmarketOrderItemApiSecondmarketOrderItemUpdateRequest
 */
export interface SecondmarketOrderItemApiSecondmarketOrderItemUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemUpdate
     */
    readonly id: number

    /**
     * 
     * @type {SecondmarketOrderItemUpdateRequestContract}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemUpdate
     */
    readonly secondmarketOrderItemUpdateRequestContract: SecondmarketOrderItemUpdateRequestContract

    /**
     * 
     * @type {Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic'>}
     * @memberof SecondmarketOrderItemApiSecondmarketOrderItemUpdate
     */
    readonly includeField?: Array<'user_counterparty_active_negotiation_id' | 'order' | 'holding' | 'contact_information' | 'note' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic'>
}

/**
 * SecondmarketOrderItemApi - object-oriented interface
 * @export
 * @class SecondmarketOrderItemApi
 * @extends {BaseAPI}
 */
export class SecondmarketOrderItemApi extends BaseAPI {
    /**
     * 
     * @summary retrieves the details of a secondmarket order item for an investor
     * @param {SecondmarketOrderItemApiInvestorOrderItemShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecondmarketOrderItemApi
     */
    public investorOrderItemShow(requestParameters: SecondmarketOrderItemApiInvestorOrderItemShowRequest, options?: AxiosRequestConfig) {
        return SecondmarketOrderItemApiFp(this.configuration).investorOrderItemShow(requestParameters.id, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cancels a secondmarket order item
     * @param {SecondmarketOrderItemApiSecondmarketOrderItemCancelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecondmarketOrderItemApi
     */
    public secondmarketOrderItemCancel(requestParameters: SecondmarketOrderItemApiSecondmarketOrderItemCancelRequest, options?: AxiosRequestConfig) {
        return SecondmarketOrderItemApiFp(this.configuration).secondmarketOrderItemCancel(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list OrderItems for Secondmarket
     * @param {SecondmarketOrderItemApiSecondmarketOrderItemIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecondmarketOrderItemApi
     */
    public secondmarketOrderItemIndex(requestParameters: SecondmarketOrderItemApiSecondmarketOrderItemIndexRequest = {}, options?: AxiosRequestConfig) {
        return SecondmarketOrderItemApiFp(this.configuration).secondmarketOrderItemIndex(requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.issuerEntityId, requestParameters.parentOrderItemId, requestParameters.itemsForOrderbook, requestParameters.findLive, requestParameters.orderBuySell, requestParameters.placedAfter, requestParameters.expiringBefore, requestParameters.orderEntryType, requestParameters.findUserAccountWatchlist, requestParameters.state, requestParameters.hideOwnBrokerageFirm, requestParameters.sort, requestParameters.sizeRangeStart, requestParameters.sizeRangeEnd, requestParameters.atsPool, requestParameters.ownOrdersOnly, requestParameters.orderItemUnit, requestParameters.withPrice, requestParameters.portfolioOnly, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieves the details of a secondmarket order item
     * @param {SecondmarketOrderItemApiSecondmarketOrderItemShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecondmarketOrderItemApi
     */
    public secondmarketOrderItemShow(requestParameters: SecondmarketOrderItemApiSecondmarketOrderItemShowRequest, options?: AxiosRequestConfig) {
        return SecondmarketOrderItemApiFp(this.configuration).secondmarketOrderItemShow(requestParameters.id, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates the details of a secondmarket order item
     * @param {SecondmarketOrderItemApiSecondmarketOrderItemUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecondmarketOrderItemApi
     */
    public secondmarketOrderItemUpdate(requestParameters: SecondmarketOrderItemApiSecondmarketOrderItemUpdateRequest, options?: AxiosRequestConfig) {
        return SecondmarketOrderItemApiFp(this.configuration).secondmarketOrderItemUpdate(requestParameters.id, requestParameters.secondmarketOrderItemUpdateRequestContract, requestParameters.includeField, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InvestorOrderItemShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InvestorOrderItemShowErrorCodes = [
    401,
];

export type SecondmarketOrderItemCancelErrorTypes =

    | {
        status: 422;
        data: DestroySuccess;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecondmarketOrderItemCancelErrorCodes = [
    422,
];

export type SecondmarketOrderItemIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecondmarketOrderItemIndexErrorCodes = [
    401,
];

export type SecondmarketOrderItemShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecondmarketOrderItemShowErrorCodes = [
    401,
];

export type SecondmarketOrderItemUpdateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecondmarketOrderItemUpdateErrorCodes = [
    401,
    422,
];


