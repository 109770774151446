import styled, { css } from 'styled-components';

import {
  preserveHorizontalFocusBorder,
  preserveVerticalFocusBorder,
  useBreakpoint,
} from '../../../utils/style';
import { Skeleton } from '../Skeleton';
import { SmallTextStyles } from '../Typography';

import { type CardBaseProps, type GeneralLayoutColor } from './CardBase.types';

const LeftRightPadding = css`
  padding-left: ${({ theme }) => theme.spacing.md}px;
  padding-right: ${({ theme }) => theme.spacing.md}px;
  ${({ theme }) => useBreakpoint(theme).mobile`
      padding-left: ${theme.spacing.sm}px;
      padding-right: ${theme.spacing.sm}px;
  `}
`;
const LeftRightPaddingSmall = css`
  padding-left: ${({ theme }) => theme.spacing.sm}px;
  padding-right: ${({ theme }) => theme.spacing.sm}px;
`;
const FlexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div.attrs({ className: 'card-header' })``;

export const Footer = styled.div<{ $variant: CardBaseProps['variant'] }>`
  ${({ $variant }) => ($variant ? LeftRightPaddingSmall : LeftRightPadding)};
  padding-top: ${({ theme }) => theme.spacing.md}px;
  padding-bottom: ${({ theme }) => theme.spacing.sm}px;
  ${FlexColumn};
`;

export const Container = styled.div`
  ${FlexColumn};
  justify-content: space-between;
  flex: 1;
  ${preserveHorizontalFocusBorder}
  ${preserveVerticalFocusBorder}
`;

export const Wrapper = styled.div<{
  $fullHeight?: boolean;
  $flex?: boolean;
  $outlined: boolean;
  $variant: CardBaseProps['variant'];
  $hasFooter?: boolean;
  $bgColour: GeneralLayoutColor;
}>`
  ${FlexColumn};
  box-shadow: none;
  background: ${({ theme, $bgColour }) =>
    theme.color.general.layout[$bgColour]};
  color: ${({ theme }) => theme.color.general.typography.primary};
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  ${({ theme, $outlined }) =>
    $outlined && `border: 1px solid ${theme.color.general.borders.primary};`};

  ${({ $flex }) =>
    $flex !== false &&
    `
    flex: 1;
  `}
  ${({ $fullHeight }) => $fullHeight && 'height: 100%;'}
  .ant-skeleton-paragraph {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  ${({ $variant }) => $variant === 'secondMarket' && secondMarketVariantCss};
  ${({ $variant }) => $variant === 'drawer' && drawerVariantCss};
`;

export const Content = styled.div.attrs({ className: 'card-content' })<{
  $variant: CardBaseProps['variant'];
  $hasHeader: boolean;
  $hasFooter: boolean;
  $noContentPadding?: boolean;
}>`
  ${FlexColumn};
  flex: 1;
  ${({ $variant }) =>
    $variant === 'compact' ? LeftRightPaddingSmall : LeftRightPadding};

  ${({ theme, $hasHeader, $hasFooter }) => `
    padding-top: ${$hasHeader ? 0 : theme.spacing.md}px;
    padding-bottom: ${$hasFooter ? 0 : theme.spacing.md}px;

   ${
     !$hasHeader
       ? `
       overflow: hidden;
       border-top-left-radius: ${theme.borderRadius.lg}px;
       border-top-right-radius: ${theme.borderRadius.lg}px;
     `
       : ''
   }
   ${
     !$hasFooter
       ? `
       overflow: hidden;
       border-bottom-left-radius: ${theme.borderRadius.lg}px;
       border-bottom-right-radius: ${theme.borderRadius.lg}px;
     `
       : ''
   }
  `}

  ${({ theme, $hasHeader, $hasFooter }) => useBreakpoint(theme).mobile`
    padding-top: ${$hasHeader ? 0 : theme.spacing.sm}px;
    padding-bottom: ${$hasFooter ? 0 : theme.spacing.sm}px;
  `}

  ${({ $noContentPadding }) =>
    $noContentPadding &&
    css`
      & {
        padding: 0;
      }
    `}
`;

export const ContentLoading = styled(Skeleton.Base).attrs({
  paragraph: {
    rows: 3,
  },
  title: false,
})`
  padding: ${({ theme }) => theme.spacing.md}px;
`;

export const FooterContentLoading = styled(Skeleton.Base).attrs({
  paragraph: {
    rows: 1,
  },
  title: false,
})`
  .ant-skeleton-content .ant-skeleton-paragraph {
    display: flex;
    justify-content: flex-end;

    > li {
      height: 40px;
      width: 20% !important;
      ${({ theme }) => useBreakpoint(theme).mobile`
        width: 100% !important;
    `}
    }
  }
`;

const drawerVariantCss = css<{ $hasFooter?: boolean }>`
  ${({ theme, $hasFooter }) => css`
    box-shadow: none;

    ${Content} {
      background: ${theme.color.general.layout.two};
      border-radius: ${theme.borderRadius.lg}px;
      border: 1px solid ${theme.color.general.borders.primary};
      ${!$hasFooter && 'padding-bottom: 0;'};
    }

    ${Footer} {
      padding-left: 0;
      padding-right: 0;
    }
  `};
`;

const secondMarketVariantCss = css`
  ${({ theme }) => css`
    ${Header} {
      .card-header-content {
        padding: 10px ${theme.spacing.sm}px;
        border-top-left-radius: ${theme.borderRadius.lg}px;
        border-top-right-radius: ${theme.borderRadius.lg}px;
        padding: ${theme.spacing.lg / 2}px ${theme.spacing.md}px;

        h3 {
          ${SmallTextStyles};
        }

        ${() =>
          useBreakpoint(theme).mobile`${css`
            border-bottom: 0;
            padding: ${theme.spacing.lg / 2}px ${theme.spacing.sm}px;
          `}`};
      }
    }

    ${Footer} {
      padding-top: ${theme.spacing.sm}px;
    }
  `}
`;
