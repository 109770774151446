import styled from 'styled-components';

import { Form as BaseForm } from '@npm/core/ui/components/atoms/Form';
import { useBreakpoint } from '@npm/core/ui/utils/style';

import { SIGNERS_TABLE_MAX_WIDTH } from '../ReadOnly/ReadOnly.styles';

export const Form = styled(BaseForm)`
  max-width: ${SIGNERS_TABLE_MAX_WIDTH}px;
`;

export const FormWrap = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`;

export const CheckboxWrap = styled.div`
  margin: ${({ theme }) => theme.spacing.xxl}px 0;
`;

export const ButtonsWrap = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.md}px;
  }

  button {
    ${({ theme }) => useBreakpoint(theme).tablet`
    width: 100%;
  `}
  }

  ${({ theme }) => useBreakpoint(theme).tablet`
    flex-direction: column;

    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: ${theme.spacing.md}px;
    }
  `}
`;
