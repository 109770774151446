import {
  type CodebookItem,
  type UserRoleAggregate,
  type Workstation,
} from '@npm/data-access';

import { setRole } from '../../../role/context/userRole.helpers';
import { type StoreCreateArgs } from '../user-context.store';
import {
  findHighestSponsorshipType,
  hasEntityNpmsAccount,
  hasIndividualNpmsAccount,
  hasNpmSettlementAccount,
  hasSpouseOrGuestRolesOnly,
  hasSpouseRolesOnly,
} from '../user-context.utils';

export enum Header {
  Workstation = 'X-Workstation',
  AcrossAccounts = 'X-Across-Accounts',
  UserRoleId = 'X-User-Role-Id',
  OboAccountId = 'X-Obo-Account-Id',
  OboUserId = 'X-Obo-User-Id',
}

export type RoleStore = {
  setRole: (
    role: UserRoleAggregate,
    workstation: Workstation | undefined,
    config?: {
      isAllAccounts?: boolean;
      redirectTo?: string;
      isObo?: boolean;
      urlUpdateType?: 'push' | 'replace';
    }
  ) => void;

  // undefined - not loaded yet
  // null - does not apply in the current context
  investorAccounts: UserRoleAggregate[] | null | undefined;
  setInvestorAccounts: (accounts: UserRoleAggregate[] | null) => void;
  setHasActiveEvents: (hasAccountWithActiveEvents: boolean | null) => void;
  isIndividualNpmsInvestor: boolean | null | undefined;
  isEntityNpmsInvestor: boolean | null | undefined;
  isNpmSettlementInvestor: boolean | null | undefined;
  sponsorshipType: CodebookItem | null | undefined;
  isSpouseOrGuestRolesOnly: boolean | null | undefined;
  isSpouseRolesOnly: boolean | null | undefined;
  hasActiveEvents: boolean | null | undefined;
  isPermissionsCheckOn: boolean;
  switchOffPermissionsCheck: () => void;
  roleSwitcher: {
    disabled: boolean;
  };
  setRoleSwitcher: (options: {
    disabled: boolean;
    tooltipText?: string;
  }) => void;
};

export const createRoleStore: (...a: StoreCreateArgs) => RoleStore = (
  set,
  get
) => ({
  investorAccounts: undefined,
  isIndividualNpmsInvestor: undefined,
  isEntityNpmsInvestor: undefined,
  isNpmSettlementInvestor: undefined,
  sponsorshipType: undefined,
  isSpouseOrGuestRolesOnly: undefined,
  isSpouseRolesOnly: undefined,
  hasActiveEvents: undefined,
  setInvestorAccounts: investorAccounts => {
    const isEntityNpmsInvestor = hasEntityNpmsAccount(investorAccounts);
    const isIndividualNpmsInvestor =
      hasIndividualNpmsAccount(investorAccounts) && !isEntityNpmsInvestor;
    const isSpouseRolesOnly = hasSpouseRolesOnly(investorAccounts);
    const isSpouseOrGuestRolesOnly =
      isSpouseRolesOnly || hasSpouseOrGuestRolesOnly(investorAccounts);
    const isNpmSettlementInvestor = hasNpmSettlementAccount(investorAccounts);

    set({
      ...get(),
      investorAccounts,
      isIndividualNpmsInvestor,
      isEntityNpmsInvestor,
      isNpmSettlementInvestor,
      sponsorshipType: findHighestSponsorshipType(investorAccounts),
      isSpouseRolesOnly,
      isSpouseOrGuestRolesOnly,
    });
  },
  setHasActiveEvents: hasActiveEvents => {
    set({
      ...get(),
      hasActiveEvents,
    });
  },
  setRole: async (role, ws, config) => {
    setRole(role, ws, config);
  },
  isPermissionsCheckOn: true,
  switchOffPermissionsCheck: () => {
    set({
      ...get(),
      isPermissionsCheckOn: false,
    });
  },
  roleSwitcher: {
    disabled: false,
  },
  setRoleSwitcher: ({ disabled }) => {
    set({
      ...get(),
      roleSwitcher: {
        disabled,
      },
    });
  },
});
