import { useEffect } from 'react';

import {
  type User,
  type WorkstationIndex,
  CbRoleType,
  CbWorkstationType,
} from '@npm/data-access';

import {
  InitialLoadingPart,
  useInitialLoadingStore,
} from '../../../../../app/initialLoading';
import { useGetWorkstationLandingPage } from '../../../../../workstations/workstationLandingPage.hooks';
import { useUserContextStore } from '../../../context';

const getWorkstationObjectBasedOnUser = (
  activeUserRole: User['active_role'],
  availableWorkstations: WorkstationIndex['workstations']
) => {
  return availableWorkstations.find(ws => {
    // workstation types are the same
    const workstationCodesMatch =
      ws.type.code === activeUserRole?.workstation?.code;

    // brokerage workstations have the same type code, but different subject ids
    if (
      activeUserRole?.workstation?.code === CbWorkstationType.items.brokerage
    ) {
      const subjectIdsMatch =
        ws.user_role?.subject?.id === activeUserRole?.subject?.id;
      return workstationCodesMatch && subjectIdsMatch;
    }

    return workstationCodesMatch;
  });
};

export const useInitialRoleHandler = (
  needRole: boolean,
  workstations: WorkstationIndex['workstations'],
  queryParams: {
    roleId?: number;
    allAccounts?: boolean;
  }
) => {
  const context = useUserContextStore();
  const setRole = useUserContextStore(store => store.setRole);
  const user = useUserContextStore(store => store.user);
  const finishLoadingPartOfApp = useInitialLoadingStore(
    store => store.finishLoadingPart
  );

  const getWorkstationLandingPage = useGetWorkstationLandingPage();

  useEffect(() => {
    if (
      !!queryParams?.roleId ||
      queryParams?.allAccounts === true ||
      !needRole
    ) {
      finishLoadingPartOfApp(InitialLoadingPart.UserRoles);
      return;
    }

    if (!workstations) {
      return;
    }

    const activeUserRole = user.active_role;

    const isInvestorAllAccountsMode =
      activeUserRole?.workstation?.code === CbWorkstationType.items.investor &&
      user?.across_accounts;

    const isNewInvestorAccount =
      activeUserRole?.workstation?.code === CbWorkstationType.items.investor &&
      activeUserRole?.role_name?.code === CbRoleType.items.NEW_USER;

    const activeWorkstation = getWorkstationObjectBasedOnUser(
      user?.active_role,
      workstations
    );

    getWorkstationLandingPage(
      activeUserRole?.workstation?.code,
      context,
      activeUserRole
    )
      .then(redirectTo => {
        setRole(
          isInvestorAllAccountsMode || isNewInvestorAccount
            ? null
            : activeUserRole,
          activeWorkstation,
          {
            redirectTo: isNewInvestorAccount ? null : redirectTo,
          }
        );
        finishLoadingPartOfApp(InitialLoadingPart.UserRoles);
      })
      .catch(() => {
        finishLoadingPartOfApp(InitialLoadingPart.UserRoles);
      });
  }, [
    user,
    needRole,
    queryParams?.roleId,
    queryParams?.allAccounts,
    workstations,
    context,
  ]);
};
