import type { ChartArea } from 'chart.js';

import { type XY } from './ChartTooltip.types';

export const getSideTooltipPosition = (
  point: XY,
  chartArea: ChartArea,
  tooltipWidth: number,
  tooltipHeight: number,
  pointPadding: number
) => {
  const chartMid = chartArea.left + (chartArea.right - chartArea.left) / 2;
  const isLeftSide = point.x < chartMid;

  const x = point.x;
  const y = point.y;

  let tooltipStartX = isLeftSide
    ? x + pointPadding
    : x - tooltipWidth - pointPadding;

  let tooltipStartY = y - tooltipHeight / 2;

  // check overflow left side
  if (tooltipStartX < chartArea.left) {
    tooltipStartX = chartArea.left;
  }
  // check overflow right side
  if (tooltipStartX + tooltipWidth > chartArea.right) {
    tooltipStartX = chartArea.right - tooltipWidth;
  }
  // check overflow top
  if (tooltipStartY < chartArea.top) {
    tooltipStartY = chartArea.top;
  }
  // check overflow bottom
  if (tooltipStartY + tooltipHeight > chartArea.bottom) {
    tooltipStartY = chartArea.bottom - tooltipHeight;
  }

  return {
    x: tooltipStartX,
    y: tooltipStartY,
  };
};
