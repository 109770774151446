import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { type VenusApi } from '@npm/data-access';

import * as S from './TypeCellTooltip.styles';

const VALUATION_TOOLTIP_TEXT =
  'Valuation sourced from company issued Press Release or media mention';
const UNCLASSIFIED_FINANCING_TOOLTIP_TEXT =
  'Capital raises with no supported stock class filings';

type Props = {
  financialActivity?: VenusApi.FinancialActivity;
};

export const TypeCellTooltip = ({ financialActivity }: Props) => {
  const theme = useTheme();

  const tooltipText = useMemo(() => {
    const activityClass = financialActivity?.class?.toLocaleLowerCase();

    if (activityClass === 'valuation') {
      return VALUATION_TOOLTIP_TEXT;
    } else if (activityClass === 'investment') {
      if (financialActivity.unclassified) {
        return UNCLASSIFIED_FINANCING_TOOLTIP_TEXT;
      }

      return null;
    }
    return null;
  }, [financialActivity]);

  if (!tooltipText) {
    return null;
  }

  return (
    <Tooltip title={tooltipText}>
      <S.Wrapper>
        <Icon
          name="info-circle"
          color={theme.color.info.typography.primary}
          size="xs"
        />
      </S.Wrapper>
    </Tooltip>
  );
};
