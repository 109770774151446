import styled from 'styled-components';

import {
  SmallTextBoldVariationSettings,
  SmallTextStyles,
} from '../../../atoms/Typography';

export const StyledLink = styled.a`
  display: inline-flex;
  align-items: center;
  ${SmallTextStyles}
  ${SmallTextBoldVariationSettings}
  color: ${({ theme }) => theme.color.info.typography.primary};

  &:hover {
    color: ${({ theme }) => theme.color.info.backgrounds.primaryHover};
  }

  svg {
    margin-top: -1px;
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }
`;
