import { type AutoScrollingForm } from '@npm/core/ui/components/organisms/AutoScrollingForm';
import {
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
  WIZARD_BODY_ID,
} from '@npm/core/ui/components/organisms/WizardNew/Layout';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

type AutoScrollingFormProps = React.ComponentProps<typeof AutoScrollingForm>;

export const useAutoScrollingFormProps = (): Pick<
  AutoScrollingFormProps,
  | 'scrollableParentId'
  | 'extraTopPadding'
  | 'scrollEnabled'
  | 'questionMinHeight'
> => {
  const { isMobile, isTablet } = useBreakpoints();

  return {
    scrollableParentId: WIZARD_BODY_ID,
    scrollEnabled: true,
    extraTopPadding:
      isMobile || isTablet ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT * 2,
    questionMinHeight: 0,
  };
};
