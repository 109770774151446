import styled, { css } from 'styled-components';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  BORDER_THICKNESS as FOCUS_BORDER_THICKNESS,
  centerFlex,
  getFocusBorderStyles,
} from '@npm/core/ui/utils/style';

const BUTTON_MIN_HEIGHT = 46;
const LOADING_SPINNER_WRAPPER_ID = 'loading-spinner-wrapper';

const selectedStyle = css`
  && {
    pointer-events: none;
    background: ${({ theme }) => theme.color.general.layout.zero};
    border: 2px solid ${({ theme }) => theme.color.info.borders.primary};
    padding: ${({ theme }) => theme.spacing.sm}px;

    h3,
    p,
    span,
    svg {
      color: ${({ theme }) => theme.color.info.typography.primary};
      border-color: ${({ theme }) => theme.color.info.typography.primary};
      background: inherit;
    }
  }
`;

export const ItemButton = styled.button<{
  $isReadOnly?: boolean;
  $isSelected?: boolean;
  $isLoading?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: ${BUTTON_MIN_HEIGHT}px;
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;
  padding: ${({ theme }) => theme.spacing.sm}px;
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  background: ${({ theme }) => theme.color.general.layout.one};
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  text-align: left;

  ${({ $isReadOnly, $isSelected }) =>
    ($isReadOnly || $isSelected) && selectedStyle}
  ${({ $isSelected }) => $isSelected && `&& { pointer-events: all; }`}
  ${({ $isLoading }) =>
    $isLoading &&
    `
      pointer-events: none;

      & > *:not(#${LOADING_SPINNER_WRAPPER_ID}) {
        opacity: 0.2;
      }
    `}

  &:hover {
    border-color: ${({ theme }) => theme.color.info.borders.primary};
  }

  ${getFocusBorderStyles()}
  &:focus {
    background: ${({ theme }) => theme.color.general.layout.zero};
  }

  &:first-of-type {
    margin-top: ${FOCUS_BORDER_THICKNESS};
  }
`;

export const LoadingSpinnerWrapper = styled.div.attrs({
  id: LOADING_SPINNER_WRAPPER_ID,
})`
  position: absolute;
  inset: 0;
  ${centerFlex}
`;

export const FlexWrap = styled.div`
  display: flex;
  align-items: center;

  span {
    display: inline-block;

    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.xs}px;
    }
  }
`;

export const FlexBox = styled.div`
  display: flex;
`;

export const IconWrap = styled.div`
  align-self: flex-start;
  margin-right: ${({ theme }) => theme.spacing.sm}px;
  padding-top: ${({ theme }) => theme.spacing.xs / 2}px;
  ${centerFlex}

  // fallback - if not specified directly on the Icon component
  svg {
    color: ${({ theme }) => theme.color.general.typography.primary};
  }
`;

export const DotSeparatorWrapper = styled(Text)`
  margin: 0 ${({ theme }) => theme.spacing.xs}px;
  margin-top: -${({ theme }) => theme.spacing.xs / 2}px;
`;
