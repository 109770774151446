import { memo } from 'react';

import { CbWorkstationType } from '@npm/data-access';
import { useCurrentWorkstation, useObo } from '@npm/features/auth/user/role';
import { BrokerageMenu } from '@npm/workstations/brokerage/components/Layout/BrokerageMenu';
import { InvestorMenu } from '@npm/workstations/investor/components/Layout/InvestorMenu';

export const SecondMarketMenu = memo(function SecondMarketMenu() {
  const workstation = useCurrentWorkstation();
  const { isObo } = useObo();

  if (workstation?.type?.code === CbWorkstationType.items.brokerage && !isObo) {
    return <BrokerageMenu />;
  }

  if (workstation?.type?.code === CbWorkstationType.items.investor || isObo) {
    return <InvestorMenu />;
  }

  return null;
});
