import React from 'react';

import { getPageSize } from '@npm/core/ui/components/molecules/Table';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  type AccountApiBrokerageFirmAccountIndexRequest,
  type AccountForBrokerage,
} from '@npm/data-access';

import {
  FILTER_QUERY_KEY,
  usePersistedFilters,
} from '../../../../../../filters/filters';
import { useCurrentRole } from '../../../../role/hooks/useCurrentRole';
import {
  type OboModalState,
  type SetOboModalState,
} from '../../OboModal.types';

import { SelectAccountFilter } from './SelectAccountFilter/SelectAccountFilter';
import { SelectAccountCards } from './SelectAccountCards';
import { SelectAccountTable } from './SelectAccountTable';

import * as S from '../../OboModal.styles';

type Props = {
  active?: boolean;
  selectedAccount: OboModalState['account'];
  setOboModalState: SetOboModalState;
  goToRepresentativeStep: () => void;
  handleComplete: (nextOboState: OboModalState) => void;
};

export const SelectAccount = ({
  selectedAccount,
  setOboModalState,
  goToRepresentativeStep,
  handleComplete,
  active,
}: Props) => {
  const role = useCurrentRole();
  const { isMobile } = useBreakpoints();

  const [{ variables: filterValues, setVariables }, filterPanelProps] =
    usePersistedFilters<AccountApiBrokerageFirmAccountIndexRequest>(
      FILTER_QUERY_KEY.oboAccounts,
      {
        page: 1,
        size: getPageSize(isMobile ? 'cards' : 'table'),
        brokerageFirmId: role?.subject?.id,
      },
      { resetQueryOnUnmount: false }
    );

  const handleSelect = (account: OboModalState['account']) => {
    setOboModalState({
      account,
      representative: (account as AccountForBrokerage).representative,
    });
  };

  const commonListProps = {
    handleSelect,
    selectedAccount,
    goToRepresentativeStep,
    variables: filterValues,
    setVariables,
  };

  // we are using active prop instead of unmounting to keep the variables state
  if (!active) return null;

  return (
    <S.InnerWrapper>
      <S.FilterToolbarWrapper>
        <SelectAccountFilter {...filterPanelProps} variables={filterValues} />
      </S.FilterToolbarWrapper>
      {isMobile ? (
        <SelectAccountCards
          {...commonListProps}
          handleComplete={handleComplete}
        />
      ) : (
        <SelectAccountTable {...commonListProps} />
      )}
    </S.InnerWrapper>
  );
};
