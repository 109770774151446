import {
  type OrderBuySellCode,
  type SecondmarketInvestorOrderItemShow,
} from '@npm/data-access';

import { inverseOrderSide } from './inverseOrderSide';

export const getMyOrderSide = (
  orderItem: SecondmarketInvestorOrderItemShow,
  isMyOrder: boolean
) => {
  return getMyOrderSideFromString(
    orderItem?.order_side?.code as OrderBuySellCode,
    isMyOrder
  );
};

export const getMyOrderSideFromString = (
  orderSide: OrderBuySellCode,
  isMyOrder: boolean
) => (isMyOrder ? orderSide : inverseOrderSide(orderSide));
