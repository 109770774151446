/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AdvisorReferralsApi,
  AdvisorReferralIndexErrorCodes,
  AdvisorReferralIndexErrorTypes,
  AdvisorReferralCreateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AdvisorReferralsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AdvisorReferralIndexMethod = AdvisorReferralsApi['advisorReferralIndex'];
export type AdvisorReferralIndexResponseType = MethodResult<AdvisorReferralIndexMethod>;

export const useAdvisorReferralIndex = (
  variables: Parameters<AdvisorReferralIndexMethod>[0],
  config?: UseQueryConfig<
    AdvisorReferralIndexMethod,
    AdvisorReferralIndexErrorTypes
  >
) => {
  return useQuery<
    AdvisorReferralIndexMethod,
    AdvisorReferralIndexErrorTypes
  >(
    (...args) => api.advisorReferralIndex(...args),
    AdvisorReferralIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AdvisorReferralIndex'],
        ...apiQueryConfig.AdvisorReferralIndex,
        ...config?.queryConfig
      },
    },
    'AdvisorReferralIndex',
  );
};

export const useAdvisorReferralIndexInfinite = (
  variables: Parameters<AdvisorReferralIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AdvisorReferralIndexMethod,
    AdvisorReferralIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AdvisorReferralIndexMethod,
    AdvisorReferralIndexErrorTypes
  >(
    (...args) => api.advisorReferralIndex(...args),
    AdvisorReferralIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AdvisorReferralIndex', 'AdvisorReferralIndexInfinite'],
        ...apiQueryConfig.AdvisorReferralIndex,
        ...config?.queryConfig,
      },
    },
    'AdvisorReferralIndex',
  );
};

export const useAdvisorReferralIndexLazy = (baseOptions?: {
  variables?: Parameters<AdvisorReferralIndexMethod>[0],
  config?: UseQueryConfig<
    AdvisorReferralIndexMethod,
    AdvisorReferralIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AdvisorReferralIndexMethod,
    AdvisorReferralIndexErrorTypes
  >(
    (...args) => api.advisorReferralIndex(...args),
    AdvisorReferralIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AdvisorReferralIndex'],
        ...apiQueryConfig.AdvisorReferralIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AdvisorReferralIndex',
  );
};


export type AdvisorReferralCreateMethod = AdvisorReferralsApi['advisorReferralCreate'];

export const useAdvisorReferralCreate = () => {
  return useMutation<AdvisorReferralCreateMethod>(
    (...args) => api.advisorReferralCreate(...args),
    AdvisorReferralCreateErrorCodes,
    'AdvisorReferralCreate',
    '/api/advisor-referrals',
    'post'
  );
};
