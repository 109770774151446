import { Tooltip } from 'chart.js';

Tooltip.positioners.average = function (items) {
  if (!items.length) {
    return false;
  }

  let i, len;
  let x = 0;
  let y = 0;
  let count = 0;

  for (i = 0, len = items.length; i < len; ++i) {
    const el = items[i]?.element;
    // don't use position of bars
    if (!el || 'enableBorderRadius' in el) continue;
    if (el.hasValue()) {
      const pos = el.tooltipPosition(false);
      x += pos.x;
      y += pos.y;
      ++count;
    }
  }

  return {
    x: x / count,
    y: y / count,
  };
};
