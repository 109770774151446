/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  VenusFilingApi,
  VenusFilingPreviewXodErrorCodes,
  VenusFilingPreviewXodErrorTypes,
  VenusFilingErrorCodes,
  VenusFilingErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new VenusFilingApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type VenusFilingPreviewXodMethod = VenusFilingApi['venusFilingPreviewXod'];
export type VenusFilingPreviewXodResponseType = MethodResult<VenusFilingPreviewXodMethod>;

export const useVenusFilingPreviewXod = (
  variables: Parameters<VenusFilingPreviewXodMethod>[0],
  config?: UseQueryConfig<
    VenusFilingPreviewXodMethod,
    VenusFilingPreviewXodErrorTypes
  >
) => {
  return useQuery<
    VenusFilingPreviewXodMethod,
    VenusFilingPreviewXodErrorTypes
  >(
    (...args) => api.venusFilingPreviewXod(...args),
    VenusFilingPreviewXodErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['VenusFilingPreviewXod'],
        ...apiQueryConfig.VenusFilingPreviewXod,
        ...config?.queryConfig
      },
    },
    'VenusFilingPreviewXod',
  );
};

export const useVenusFilingPreviewXodInfinite = (
  variables: Parameters<VenusFilingPreviewXodMethod>[0],
  config?: UseInfiniteQueryConfig<
    VenusFilingPreviewXodMethod,
    VenusFilingPreviewXodErrorTypes
  >
) => {
  return useInfiniteQuery<
    VenusFilingPreviewXodMethod,
    VenusFilingPreviewXodErrorTypes
  >(
    (...args) => api.venusFilingPreviewXod(...args),
    VenusFilingPreviewXodErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['VenusFilingPreviewXod', 'VenusFilingPreviewXodInfinite'],
        ...apiQueryConfig.VenusFilingPreviewXod,
        ...config?.queryConfig,
      },
    },
    'VenusFilingPreviewXod',
  );
};

export const useVenusFilingPreviewXodLazy = (baseOptions?: {
  variables?: Parameters<VenusFilingPreviewXodMethod>[0],
  config?: UseQueryConfig<
    VenusFilingPreviewXodMethod,
    VenusFilingPreviewXodErrorTypes
  >
}) => {
  return useLazyQuery<
    VenusFilingPreviewXodMethod,
    VenusFilingPreviewXodErrorTypes
  >(
    (...args) => api.venusFilingPreviewXod(...args),
    VenusFilingPreviewXodErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['VenusFilingPreviewXod'],
        ...apiQueryConfig.VenusFilingPreviewXod,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'VenusFilingPreviewXod',
  );
};


export type VenusFilingMethod = VenusFilingApi['venusFiling'];
export type VenusFilingResponseType = MethodResult<VenusFilingMethod>;

export const useVenusFiling = (
  variables: Parameters<VenusFilingMethod>[0],
  config?: UseQueryConfig<
    VenusFilingMethod,
    VenusFilingErrorTypes
  >
) => {
  return useQuery<
    VenusFilingMethod,
    VenusFilingErrorTypes
  >(
    (...args) => api.venusFiling(...args),
    VenusFilingErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['VenusFiling'],
        ...apiQueryConfig.VenusFiling,
        ...config?.queryConfig
      },
    },
    'VenusFiling',
  );
};

export const useVenusFilingInfinite = (
  variables: Parameters<VenusFilingMethod>[0],
  config?: UseInfiniteQueryConfig<
    VenusFilingMethod,
    VenusFilingErrorTypes
  >
) => {
  return useInfiniteQuery<
    VenusFilingMethod,
    VenusFilingErrorTypes
  >(
    (...args) => api.venusFiling(...args),
    VenusFilingErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['VenusFiling', 'VenusFilingInfinite'],
        ...apiQueryConfig.VenusFiling,
        ...config?.queryConfig,
      },
    },
    'VenusFiling',
  );
};

export const useVenusFilingLazy = (baseOptions?: {
  variables?: Parameters<VenusFilingMethod>[0],
  config?: UseQueryConfig<
    VenusFilingMethod,
    VenusFilingErrorTypes
  >
}) => {
  return useLazyQuery<
    VenusFilingMethod,
    VenusFilingErrorTypes
  >(
    (...args) => api.venusFiling(...args),
    VenusFilingErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['VenusFiling'],
        ...apiQueryConfig.VenusFiling,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'VenusFiling',
  );
};

