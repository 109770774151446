import { Margin } from '@npm/core/ui/components/atoms/common';
import { type Accreditation, CbAccreditationLevel } from '@npm/data-access';

import { type WithSupportTicketType } from '../../../app/zendesk';
import { AccreditedInvestorStatus } from '../../components/AccreditedInvestorStatus';

import { FilledFormView } from './FilledFormView';

type Props = {
  data: Accreditation | undefined;
} & WithSupportTicketType;

export const IndividualAccreditationReadOnly = ({
  data,
  zendeskTicketGroupId,
}: Props) => {
  const isFailed =
    data?.level?.code === CbAccreditationLevel.items.not_available;

  if (!data) return null;

  return (
    <>
      <Margin bottom="lg">
        <AccreditedInvestorStatus level={data.level} isFailed={isFailed} />
      </Margin>
      <FilledFormView
        answers={data?.answers}
        isFailed={isFailed}
        zendeskTicketGroupId={zendeskTicketGroupId}
      />
    </>
  );
};
