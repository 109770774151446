import { CheckOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';

import { Card } from '@npm/core/ui/components/molecules/Card';
import { getFocusBorderStyles } from '@npm/core/ui/utils/style';

// workarounds the existing Card/Image - consider creating standalone components
export const StyledCompanyCard = styled(Card)<{
  $isSelected?: boolean;
  $isSelectable?: boolean;
}>`
  position: relative;
  overflow: hidden;


  body.tab-focus ${getFocusBorderStyles()};

  &,
  .card-content,
  .card-header-content {
    background-color: ${({ theme }) => theme.color.general.layout.two};
  }

  ${({ $isSelected, theme }) =>
    $isSelected
      ? css`
          box-shadow: 0 0 0 2px ${theme.color.info.borders.primary};
        `
      : css`
          box-shadow: 0 0 0 1px ${theme.color.general.borders.primary};
        `}

  ${({ $isSelectable }) => $isSelectable && `&:hover { cursor: pointer; }`}

  .card-content,
  .card-header-content {
    padding: ${({ theme }) => theme.spacing.sm}px;
  }

  .card-content {
    padding-top: 0;
  }
`;

export const IsSelectedIcon = styled(CheckOutlined)`
  position: absolute;
  top: -2px;
  right: -2px;
  height: 20px;
  width: 20px;
  font-size: ${({ theme }) => theme.typography.size.xs}px;
  background-color: ${({ theme }) => theme.color.info.borders.primary};
  color: ${({ theme }) => theme.color.general.layout.one};
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.md}px;
`;
