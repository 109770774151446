/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  IssuersApi,
  InvestorIssuersIndexErrorCodes,
  InvestorIssuersIndexErrorTypes,
  IssuersIndexErrorCodes,
  IssuersIndexErrorTypes,
  IssuerShowErrorCodes,
  IssuerShowErrorTypes,
  InvestorIssuerShowErrorCodes,
  InvestorIssuerShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new IssuersApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InvestorIssuersIndexMethod = IssuersApi['investorIssuersIndex'];
export type InvestorIssuersIndexResponseType = MethodResult<InvestorIssuersIndexMethod>;

export const useInvestorIssuersIndex = (
  variables: Parameters<InvestorIssuersIndexMethod>[0],
  config?: UseQueryConfig<
    InvestorIssuersIndexMethod,
    InvestorIssuersIndexErrorTypes
  >
) => {
  return useQuery<
    InvestorIssuersIndexMethod,
    InvestorIssuersIndexErrorTypes
  >(
    (...args) => api.investorIssuersIndex(...args),
    InvestorIssuersIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorIssuersIndex'],
        ...apiQueryConfig.InvestorIssuersIndex,
        ...config?.queryConfig
      },
    },
    'InvestorIssuersIndex',
  );
};

export const useInvestorIssuersIndexInfinite = (
  variables: Parameters<InvestorIssuersIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InvestorIssuersIndexMethod,
    InvestorIssuersIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InvestorIssuersIndexMethod,
    InvestorIssuersIndexErrorTypes
  >(
    (...args) => api.investorIssuersIndex(...args),
    InvestorIssuersIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorIssuersIndex', 'InvestorIssuersIndexInfinite'],
        ...apiQueryConfig.InvestorIssuersIndex,
        ...config?.queryConfig,
      },
    },
    'InvestorIssuersIndex',
  );
};

export const useInvestorIssuersIndexLazy = (baseOptions?: {
  variables?: Parameters<InvestorIssuersIndexMethod>[0],
  config?: UseQueryConfig<
    InvestorIssuersIndexMethod,
    InvestorIssuersIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InvestorIssuersIndexMethod,
    InvestorIssuersIndexErrorTypes
  >(
    (...args) => api.investorIssuersIndex(...args),
    InvestorIssuersIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InvestorIssuersIndex'],
        ...apiQueryConfig.InvestorIssuersIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InvestorIssuersIndex',
  );
};


export type IssuersIndexMethod = IssuersApi['issuersIndex'];
export type IssuersIndexResponseType = MethodResult<IssuersIndexMethod>;

export const useIssuersIndex = (
  variables: Parameters<IssuersIndexMethod>[0],
  config?: UseQueryConfig<
    IssuersIndexMethod,
    IssuersIndexErrorTypes
  >
) => {
  return useQuery<
    IssuersIndexMethod,
    IssuersIndexErrorTypes
  >(
    (...args) => api.issuersIndex(...args),
    IssuersIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuersIndex'],
        ...apiQueryConfig.IssuersIndex,
        ...config?.queryConfig
      },
    },
    'IssuersIndex',
  );
};

export const useIssuersIndexInfinite = (
  variables: Parameters<IssuersIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    IssuersIndexMethod,
    IssuersIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    IssuersIndexMethod,
    IssuersIndexErrorTypes
  >(
    (...args) => api.issuersIndex(...args),
    IssuersIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuersIndex', 'IssuersIndexInfinite'],
        ...apiQueryConfig.IssuersIndex,
        ...config?.queryConfig,
      },
    },
    'IssuersIndex',
  );
};

export const useIssuersIndexLazy = (baseOptions?: {
  variables?: Parameters<IssuersIndexMethod>[0],
  config?: UseQueryConfig<
    IssuersIndexMethod,
    IssuersIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    IssuersIndexMethod,
    IssuersIndexErrorTypes
  >(
    (...args) => api.issuersIndex(...args),
    IssuersIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IssuersIndex'],
        ...apiQueryConfig.IssuersIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IssuersIndex',
  );
};


export type IssuerShowMethod = IssuersApi['issuerShow'];
export type IssuerShowResponseType = MethodResult<IssuerShowMethod>;

export const useIssuerShow = (
  variables: Parameters<IssuerShowMethod>[0],
  config?: UseQueryConfig<
    IssuerShowMethod,
    IssuerShowErrorTypes
  >
) => {
  return useQuery<
    IssuerShowMethod,
    IssuerShowErrorTypes
  >(
    (...args) => api.issuerShow(...args),
    IssuerShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerShow'],
        ...apiQueryConfig.IssuerShow,
        ...config?.queryConfig
      },
    },
    'IssuerShow',
  );
};

export const useIssuerShowInfinite = (
  variables: Parameters<IssuerShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    IssuerShowMethod,
    IssuerShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    IssuerShowMethod,
    IssuerShowErrorTypes
  >(
    (...args) => api.issuerShow(...args),
    IssuerShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerShow', 'IssuerShowInfinite'],
        ...apiQueryConfig.IssuerShow,
        ...config?.queryConfig,
      },
    },
    'IssuerShow',
  );
};

export const useIssuerShowLazy = (baseOptions?: {
  variables?: Parameters<IssuerShowMethod>[0],
  config?: UseQueryConfig<
    IssuerShowMethod,
    IssuerShowErrorTypes
  >
}) => {
  return useLazyQuery<
    IssuerShowMethod,
    IssuerShowErrorTypes
  >(
    (...args) => api.issuerShow(...args),
    IssuerShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IssuerShow'],
        ...apiQueryConfig.IssuerShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IssuerShow',
  );
};


export type InvestorIssuerShowMethod = IssuersApi['investorIssuerShow'];
export type InvestorIssuerShowResponseType = MethodResult<InvestorIssuerShowMethod>;

export const useInvestorIssuerShow = (
  variables: Parameters<InvestorIssuerShowMethod>[0],
  config?: UseQueryConfig<
    InvestorIssuerShowMethod,
    InvestorIssuerShowErrorTypes
  >
) => {
  return useQuery<
    InvestorIssuerShowMethod,
    InvestorIssuerShowErrorTypes
  >(
    (...args) => api.investorIssuerShow(...args),
    InvestorIssuerShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorIssuerShow'],
        ...apiQueryConfig.InvestorIssuerShow,
        ...config?.queryConfig
      },
    },
    'InvestorIssuerShow',
  );
};

export const useInvestorIssuerShowInfinite = (
  variables: Parameters<InvestorIssuerShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    InvestorIssuerShowMethod,
    InvestorIssuerShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    InvestorIssuerShowMethod,
    InvestorIssuerShowErrorTypes
  >(
    (...args) => api.investorIssuerShow(...args),
    InvestorIssuerShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorIssuerShow', 'InvestorIssuerShowInfinite'],
        ...apiQueryConfig.InvestorIssuerShow,
        ...config?.queryConfig,
      },
    },
    'InvestorIssuerShow',
  );
};

export const useInvestorIssuerShowLazy = (baseOptions?: {
  variables?: Parameters<InvestorIssuerShowMethod>[0],
  config?: UseQueryConfig<
    InvestorIssuerShowMethod,
    InvestorIssuerShowErrorTypes
  >
}) => {
  return useLazyQuery<
    InvestorIssuerShowMethod,
    InvestorIssuerShowErrorTypes
  >(
    (...args) => api.investorIssuerShow(...args),
    InvestorIssuerShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InvestorIssuerShow'],
        ...apiQueryConfig.InvestorIssuerShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InvestorIssuerShow',
  );
};

