import { useMemo } from 'react';

import { useUserRole } from '../context/userRole.context';

export const useObo = () => {
  const userRole = useUserRole();

  return useMemo(() => {
    if (
      !(
        userRole?.workstationType === 'brokerage' &&
        userRole?.subRole?.type === 'obo'
      )
    ) {
      return {
        isObo: false,
        oboAccountId: null,
        oboUserId: null,
        oboRepresentative: null,
        oboAccount: null,
      };
    }

    return {
      isObo: true,
      oboAccountId: userRole?.subRole?.account?.id,
      oboUserId: userRole?.subRole?.representative?.user_id,
      oboAccount: userRole?.subRole?.account,
      oboRepresentative: userRole?.subRole?.representative,
    };
  }, [userRole]);
};
