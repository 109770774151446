import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import {
  centerFlex,
  preserveHorizontalFocusBorder,
  useBreakpoint,
} from '../../../../utils/style';
import { EXPANDABLE_PANEL_HEADER_HEIGHT } from '../../../atoms/ExpandablePanel';
import {
  DESKTOP_HEIGHT as PROGRESS_BAR_HEIGHT_DESKTOP,
  MOBILE_HEIGHT as PROGRESS_BAR_HEIGHT_MOBILE,
} from '../../../atoms/ProgressBar';

import {
  CONTENT_PADDING,
  CONTENT_PADDING_MOBILE,
  FOOTER_HEIGHT,
  FOOTER_HEIGHT_MOBILE,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
  SIDEBAR_WIDTH,
  WIZARD_CONTENT_ID,
  WIZARD_PROGRESS_WRAPPER_ID,
  WIZARD_SIDEBAR_ID,
} from './WizardLayout.constants';

export const Wrapper = styled.div<{ $hasProgressBar: boolean }>`
  height: ${({ $hasProgressBar }) =>
    `calc(100% - ${$hasProgressBar ? PROGRESS_BAR_HEIGHT_DESKTOP : 0}px)`};
  background: ${({ theme }) => theme.color.general.layout.one};
  padding: ${CONTENT_PADDING};
  position: relative;

  ${({ theme, $hasProgressBar }) => useBreakpoint(theme).tablet`
    height: calc(100% - ${$hasProgressBar ? PROGRESS_BAR_HEIGHT_MOBILE : 0}px);
    padding: ${CONTENT_PADDING_MOBILE};
  `}
  flex: 1 1 0;
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */
`;

export const HeaderWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: ${HEADER_HEIGHT};

  button {
    ${centerFlex};
    display: inline-flex;
  }

  ${({ theme }) => useBreakpoint(theme).tablet`
      height: ${HEADER_HEIGHT_MOBILE};
  `}
`;

const getContentHeight = (
  size: 'desktop' | 'mobile',
  hasExpandableArea: boolean
) => {
  return css`
    height: calc(
      100% - ${size === 'desktop' ? FOOTER_HEIGHT : FOOTER_HEIGHT_MOBILE} -
        ${hasExpandableArea ? EXPANDABLE_PANEL_HEADER_HEIGHT : 0}px
    );
  `;
};

export const ContentWrapperInner = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;

  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */
`;

export const ContentWrapper = styled.div<{ $hasExpandableArea: boolean }>`
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto;

  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */

  background: ${({ theme }) => theme.color.general.layout.one};
  ${({ $hasExpandableArea }) => getContentHeight('desktop', $hasExpandableArea)}

  ${({ theme, $hasExpandableArea }) => useBreakpoint(theme).tablet`
    ${getContentHeight('mobile', $hasExpandableArea)}
  `}

  .ant-modal-content & {
    height: 100%;
  }
`;

export const Content = styled.div.attrs({ id: WIZARD_CONTENT_ID })<{
  $outlined: boolean;
}>`
  padding: 0;
  ${preserveHorizontalFocusBorder};
  height: 100%;
  overflow-y: auto;
  ${({ theme, $outlined }) =>
    $outlined &&
    css`
      border: 1px solid ${theme.color.general.borders.primary};
      border-radius: ${theme.borderRadius.lg}px;
    `};
`;

export const Sidebar = styled(motion.div).attrs({ id: WIZARD_SIDEBAR_ID })`
  width: ${SIDEBAR_WIDTH};
  align-self: flex-start;
  height: 100%;
  margin-right: ${({ theme }) => theme.spacing.md}px;
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  overflow-x: hidden;
  overflow-y: auto;
  flex-shrink: 0;
  padding-bottom: ${({ theme }) =>
    `calc(${FOOTER_HEIGHT} + ${theme.spacing.md}px)`};

  // TODO: UI: on tablet there should be large padding around the menu
  ${({ theme }) => useBreakpoint(theme).tablet`
    position: absolute;
    inset: 0;
    width: 100%;
    padding-bottom: calc(${FOOTER_HEIGHT_MOBILE} + ${theme.spacing.sm}px);
  `}
`;

export const AfterBodyWrapper = styled.div`
  margin-top: ${CONTENT_PADDING};
`;

export const ProgressWrapper = styled.div.attrs({
  id: WIZARD_PROGRESS_WRAPPER_ID,
})`
  background: ${({ theme }) => theme.color.general.layout.one};
  padding: 0 ${({ theme }) => theme.spacing.md}px;
`;

export const ExpandablePanelWrapper = styled.div<{
  $maxWidth: string;
}>`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  left: calc(
    ((100vw - min(${({ $maxWidth }) => $maxWidth}px, 100vw)) / 2) + 100px
  );
  bottom: calc(${FOOTER_HEIGHT} / 2);
  transform: translateY(${EXPANDABLE_PANEL_HEADER_HEIGHT / 2}px);
  width: min(calc(100% - ${({ theme }) => 2 * theme.spacing.sm}px), 100%);
  max-width: min(522px, 100%);

  ${({ theme }) => useBreakpoint(theme).tablet`
    bottom: ${FOOTER_HEIGHT};
    left: ${theme.spacing.md * 2}px;
    transform: none;
    z-index: initial;
  `}

  ${({ theme }) => useBreakpoint(theme).mobile`
    bottom: ${FOOTER_HEIGHT_MOBILE};
    left: ${theme.spacing.sm}px;
  `}
`;
