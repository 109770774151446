import React, { useMemo, useState } from 'react';

import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { truncate } from '@npm/core/ui/utils/formatters';
import { CbNotificationCategory, useCodebook } from '@npm/data-access';

import { NoPaddingButton } from '../../NotificationDrawer.styles';

type OptionType = {
  label: string;
  value: string | undefined;
};

const ALL_OPTION: OptionType = {
  label: 'All',
  value: undefined,
};

type Props = {
  onChange: (value: string | undefined) => void;
};

export const NotificationCategorySelect = ({ onChange }: Props) => {
  const [value, setValue] = useState<OptionType>(ALL_OPTION);

  const { data, isLoading } = useCodebook(
    {
      type: CbNotificationCategory.code,
    },
    {
      onError: () => {
        // ignore error, in the worst case we will show only 'All' option
      },
    }
  );

  const dropDownItems = useMemo(() => {
    const codebookItems = data?.codebooks || [];

    const items = [
      ALL_OPTION,
      ...codebookItems.map(item => ({
        label: item.name,
        value: item.code,
      })),
    ] satisfies OptionType[];

    return items.map(item => {
      return {
        label: item.label,
        onClick: () => {
          setValue(item);
          onChange(item.value);
        },
      };
    });
  }, [data]);

  return (
    <Dropdown items={dropDownItems} zIndex={'aboveAll'}>
      <NoPaddingButton
        variant="text"
        icon={<Icon name="chevron-down" />}
        size="sm"
        style={{ justifyContent: 'flex-start' }}
        loading={isLoading}
      >
        {truncate(value.label, 10)}
      </NoPaddingButton>
    </Dropdown>
  );
};
