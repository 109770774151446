import { Codebooks } from './codebooks.types';

const Items = {
  ACTIVE: 'active',
  EXPIRED: 'expired',
  UPCOMING: 'upcoming',
} as const;

export const CbEventStatus = {
  code: Codebooks.EVENT_STATUS,
  items: Items,
};
