import React from 'react';

import { MiddleEllipsis } from '@npm/core/ui/components/atoms/MiddleEllipsis';

import { SelectedAccountContainer, SelectedAccountFirm } from './styles';

type Props = {
  accountName: string;
  brokerageFirmName?: string;
  id: string | number;
};

export const SelectedAccount = ({
  accountName,
  brokerageFirmName,
  id,
}: Props) => {
  return (
    <SelectedAccountContainer data-dd-privacy="mask">
      <MiddleEllipsis
        text={accountName}
        secondaryText={brokerageFirmName}
        suffix={<SelectedAccountFirm>&nbsp;-&nbsp;{id}</SelectedAccountFirm>}
      />
    </SelectedAccountContainer>
  );
};
