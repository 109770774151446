import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import {
  type ZendeskTicketGroupID,
  ZendeskContactButton,
} from '../../../../app/zendesk';

type Props = {
  zendeskTicketGroupId: ZendeskTicketGroupID;
};

export const UploadedDocumentViewAlert = ({ zendeskTicketGroupId }: Props) => {
  return (
    <Alert
      type="info"
      message={
        <>
          <Text size="sm" colorVariant="primary" marginBottom="sm">
            This account has provided an Accreditation Questionnaire offline and
            can be found attached here.
          </Text>
          <Text size="sm" colorVariant="secondary">
            If there are any inaccuracies or if the form needs to be updated,
            please{' '}
            <ZendeskContactButton zendeskTicketGroupId={zendeskTicketGroupId}>
              contact us
            </ZendeskContactButton>
            .
          </Text>
        </>
      }
      closable
    />
  );
};
