import { Codebooks } from './codebooks.types';

const Items = {
  Signed: 'signed',
  Unsigned: 'unsigned',
} as const;

export type NdaStatusCode = typeof Items[keyof typeof Items];

export const CbNdaStatus = {
  code: Codebooks.NDA_STATUS,
  items: Items,
};
