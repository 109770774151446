import { Icon } from '../../atoms/Icon';
import { Loader } from '../../atoms/Loader';
import { CompanySearchLogo } from '../CompanySearchLogo';

import * as S from './CompanyTag.styles';

export type CompanyDescription = {
  name?: string;
  logo_url?: string;
};

type Props = {
  company: CompanyDescription | undefined;
  onClose?: () => void;
};

export const CompanyTag = ({ company, onClose }: Props) => {
  if (!company)
    return (
      <S.Tag>
        <Loader size={'small'} />
      </S.Tag>
    );

  return (
    <S.Tag>
      <CompanySearchLogo
        url={company?.logo_url}
        size="sm"
        name={company?.name}
        title={company?.name}
      />
      {onClose && (
        <S.IconWrapper onClick={onClose}>
          <Icon name={'circle-x-filled'} size={'sm'} />
        </S.IconWrapper>
      )}
    </S.Tag>
  );
};
