import React from 'react';
import { type FormInstance, type FormProps } from 'antd/es/form/Form';
import { useTheme } from 'styled-components';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  InputFormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Switch } from '@npm/core/ui/components/atoms/Switch';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Heading } from '@npm/core/ui/components/atoms/Typography';

import { useCurrentRole } from '../../../auth/user/role/hooks/useCurrentRole';
import { UserRolesFormItem } from '../../../users/CreateNewUser/Form/UserRolesFormItem';
import { PersonInfoForm } from '../../../users/CreateNewUser/PersonInfoForm';
import { accountIdValidator } from '../NewAccountDrawer.utils';
import { type AccountCreate } from '../NewOrganizationAccountDrawer.hooks';

import * as S from '../NewAccountDrawer.styles';

type Props = {
  form: FormInstance<AccountCreate>;
  includeRepresentativeFields?: boolean;
} & FormProps;

export const NewOrganizationAccountForm = ({
  form,
  includeRepresentativeFields = true,
  ...formProps
}: Props) => {
  const theme = useTheme();
  const isAnonymous = Form.useWatch('anonymous', form);
  const role = useCurrentRole();

  return (
    <S.Form form={form} {...formProps}>
      <Margin bottom="xxl">
        <Flex align="center">
          <Margin right="sm">
            <InputFormItem name="anonymous">
              <Switch
                checked={isAnonymous}
                title="Create anonymous account"
                titleAfter
              />
            </InputFormItem>
          </Margin>
          <Tooltip title="TBD">
            <Flex align="center">
              <Icon
                size="sm"
                name="info-circle"
                color={theme.color.info.typography.primary}
              />
            </Flex>
          </Tooltip>
        </Flex>
      </Margin>
      <Margin bottom="lg">
        <Alert
          message={
            isAnonymous
              ? 'For privacy purposes the account name will be Anonymous'
              : 'Enter the legal name of the new entity account and assign a unique ID'
          }
          description={
            isAnonymous
              ? 'Assign a unique ID (The Unique ID can match the customer’s ID at your brokerage firm – if you do not have one, please input any alphanumeric value). Once you click “Save”, a new Anonymous account will be created in your brokerage workstation, and you can add users to the account.'
              : '(The Unique ID can match the customer’s ID at your brokerage firm – if you do not have one, please input any alphanumeric value). Once you click “Continue”, a new Entity account will be created in your brokerage workstation, and you can add users to the account.'
          }
        />
      </Margin>
      {!isAnonymous && (
        <InputFormItem
          name="name"
          label="Legal Name of Entity"
          rules={[VALIDATION_RULES.required('Legal name')]}
        >
          <Input placeholder="Legal Name of Entity" />
        </InputFormItem>
      )}
      <InputFormItem
        name="external_id"
        label="Account ID"
        extra="Must be alphanumeric value"
        rules={[VALIDATION_RULES.required('Account ID'), accountIdValidator]}
      >
        <Input prefix={`${role.subject?.name} -`} placeholder="000000" />
      </InputFormItem>
      {includeRepresentativeFields && (
        <>
          <Divider marginBottom={theme.spacing.lg} />
          <Heading marginBottom="md" variant="h3">
            Add a representative
          </Heading>
          <PersonInfoForm
            form={form}
            defaultColumnCount={1}
            includeEmailField
          />
          {!isAnonymous && (
            <Margin top="lg">
              <UserRolesFormItem
                name="user_account_role_type"
                label="Representative Role"
              />
            </Margin>
          )}
        </>
      )}
    </S.Form>
  );
};
