import React, { type ComponentProps } from 'react';
import { useTheme } from 'styled-components';

import { type IconNames } from '@npm/utils';

import { Button } from '../../atoms/Button';
import { Margin } from '../../atoms/common';
import { Icon } from '../../atoms/Icon';
import { Heading, Text } from '../../atoms/Typography';
import { Modal } from '../../molecules/Modal';

import * as S from './SuccessModal.styles';

type Props = ComponentProps<typeof Modal> & {
  title: string;
  subTitle?: string;
  icon?: IconNames;
  iconSize?: React.ComponentProps<typeof Icon>['size'];
};

export const SuccessModal = ({
  title,
  subTitle,
  onCancel,
  icon = 'badge',
  iconSize,
  ...rest
}: Props) => {
  const theme = useTheme();

  return (
    <Modal {...rest} onCancel={onCancel} okText={null} footer={null} size="md">
      <S.ContentWrapper>
        <Margin bottom="md">
          <Icon
            name={icon}
            color={theme.color.info.typography.primary}
            size={iconSize || 'md'}
          />
        </Margin>
        <Heading variant="h2" marginBottom="sm">
          {title}
        </Heading>
        {subTitle && (
          <Text size="default" colorVariant="secondary" marginBottom="md">
            {subTitle}
          </Text>
        )}
        <Button onClick={onCancel} blockOnMobile>
          Close
        </Button>
      </S.ContentWrapper>
    </Modal>
  );
};
