import {
  type CodebookAttribute,
  type CodebookItem,
  Codebooks,
} from './codebooks.types';

interface BuyerSurveyAttribute extends CodebookAttribute {
  name: 'question' | 'help';
}
export interface BuyerSurveyCodebookItem extends CodebookItem {
  attributes: BuyerSurveyAttribute[];
}

const Items = {} as const;

export const CbBuyerSurvey = {
  code: Codebooks.BUYER_SURVEY,
  items: Items,
};
