import { type OrderSizeType } from '../../../../../order';
import { type StoreCreateArgs } from '../user-context.store';

export type SupportStore = {
  sizeType: OrderSizeType;
  toggleSizeType: () => void;
  updateSizeType: (val: OrderSizeType) => void;
};

export const createSupportStore: (...a: StoreCreateArgs) => SupportStore = (
  set,
  get
) => ({
  sizeType: 'USD',
  toggleSizeType: () => {
    set({
      ...get(),
      sizeType: get().sizeType === 'Shares' ? 'USD' : 'Shares',
    });
  },
  updateSizeType: val => {
    set({
      ...get(),
      sizeType: val,
    });
  },
});
