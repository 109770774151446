import styled from 'styled-components';

import { useBreakpoint } from '../../../utils/style';

export const Container = styled.div`
  display: flex;
  justify-content: stretch;
  width: 100%;

  ${({ theme }) => useBreakpoint(theme).tablet`
      flex-direction: column;
    `}
`;

export const Column = styled.div`
  flex: 1 1 0;

  &:not(:first-child) {
    padding-left: ${({ theme }) => theme.spacing.md}px;
    border-left: 1px solid
      ${({ theme }) => theme.color.general.borders.secondary};

    ${({ theme }) => useBreakpoint(theme).tablet`
      padding-left: 0;
      border-left: none;
    `}
  }

  &:not(:last-child) {
    padding-right: ${({ theme }) => theme.spacing.md}px;

    ${({ theme }) => useBreakpoint(theme).tablet`
      padding-right: 0;
      border-bottom: 1px solid ${theme.color.general.borders.primary};
    `}
  }
`;
