import { useEmailSend } from '@npm/data-access';
import { useOktaUserClaims } from '@npm/okta';

import { useCurrentRole } from '../../auth/user/role/hooks/useCurrentRole';

type Props = {
  userId: number;
  email: {
    subject: string;
    body: string;
  };
};

export const useEmail = () => {
  const { execute: executeSendEmail } = useEmailSend();

  const oktaUserClaims = useOktaUserClaims();
  const role = useCurrentRole();

  const from =
    `${role?.subject?.name.toLowerCase()}@npm.com` || oktaUserClaims?.email;

  return {
    from,
    executeSendEmail: ({ userId, email: { subject, body } }: Props) =>
      executeSendEmail({
        emailCreateRequestContract: {
          user_id: userId,
          from,
          email_type: 'portal_invite',
          email_subject: subject,
          email_body: body,
        },
      }),
  };
};
