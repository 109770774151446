import * as S from './RadioSwitch.styles';

type Props = {
  children: React.ReactNode;
  defaultValue: string;
};

export const RadioSwitch = ({ children, defaultValue }: Props) => {
  return (
    <S.RadioSwitchWrapper defaultValue={defaultValue}>
      {children}
    </S.RadioSwitchWrapper>
  );
};
