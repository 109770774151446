import { type CodebookItem, Codebooks } from './codebooks.types';

export enum InstitutionalBuyerAssets {
  AT_LEAST_100_MILLION = 'at_least_100_million',
  LESS_THAN_100_MILLION = 'less_than_100_million',
}
export const CbQualifiedInstitutionalBuyerType = {
  code: Codebooks.QUALIFIED_INSTITUTIONAL_BUYER_TYPE,
  groupByAttributes: (
    items: CodebookItem[],
    attributes: InstitutionalBuyerAssets[]
  ): { [key in InstitutionalBuyerAssets]: CodebookItem[] } => {
    return items.reduce((acc, item, index) => {
      if (index === 0) {
        attributes.forEach(attribute => {
          acc[attribute] = [];
        });
      }
      const filteredAttributes = item.attributes?.filter(
        attribute =>
          !!attributes.find(
            attr => attr === attribute.name && attribute.value === 'true'
          )
      );
      filteredAttributes.forEach(attribute => {
        acc[attribute.name].push(item);
      });
      return acc;
    }, {} as { [key in InstitutionalBuyerAssets]: CodebookItem[] });
  },
};
