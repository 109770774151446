import { useFeatureFlags } from '../app/featureFlags/featureFlags.context';
import { usePermissions } from '../auth/permissions/usePermissions';

export const useAreNotificationsEnabled = () => {
  const { isEnabled } = useFeatureFlags();
  const { BRO_READ_ONLY, BRO_ACCOUNT_MANAGER } = usePermissions();

  return (
    isEnabled({
      type: 'allow-if-enabled',
      flag: 'NOTIFICATIONS',
    }) &&
    !BRO_READ_ONLY &&
    !BRO_ACCOUNT_MANAGER
  );
};
