import { css } from 'styled-components';

export const TooltipStyles = css`
  .bold {
    font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  }
  .md {
    font-size: ${({ theme }) => theme.typography.size.md}px;
    line-height: ${({ theme }) => theme.typography.height.md}px;
  }
  .primary {
    color: ${({ theme }) => theme.color.general.typography.primary};
  }
  .secondary {
    color: ${({ theme }) => theme.color.general.typography.secondary};
  }
  .tertiary {
    color: ${({ theme }) => theme.color.general.typography.tertiary};
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  .column {
    display: flex;
    flex-direction: column;
  }
  .space {
    flex: 1;
    min-width: ${({ theme }) => theme.spacing.md}px;
    text-align: center;
  }

  .html-chart-tooltip {
    opacity: 0.9;
    background-color: ${({ theme }) => theme.color.general.layout.two};
    border: 1px solid ${({ theme }) => theme.color.general.borders.secondary};
    color: ${({ theme }) => theme.color.general.typography.secondary};
    padding: 8px 8px;
    border-radius: 8px;
    box-shadow: 0 4px 8px -2px rgba(25, 65, 133, 0.08),
      0px 2px 4px -2px rgba(25, 65, 133, 0.04);
    font-size: 12px;
    line-height: 16px;

    white-space: nowrap;

    display: none;
    position: absolute;
    pointer-events: none;
  }
  .html-chart-legend {
    color: ${({ theme }) => theme.color.general.typography.secondary};
    padding: 0px 4px;
    font-size: 12px;
    line-height: 16px;

    white-space: nowrap;

    display: none;
    position: absolute;
    pointer-events: none;
  }

  .html-chart-cursor {
    display: none;
    z-index: 3;
    position: absolute;
    pointer-events: none;

    .outer {
      height: 11px;
      width: 11px;
      border-radius: 50%;
      position: relative;
      padding: 3px; 3px;
    }

    .inner {
      height: 5px;
      width: 5px;
      border-radius: 50%;
    }
  }

  // always show above "point"
  .magnet-tooltip {
    transform: translateY(-100%) translateY(-12px) translateX(-50%);
  }
  .x-axis-tooltip {
    top: 0px;
    transform: translateY(-100%) translateY(-4px) translateX(-50%);
  }
  .legend-tooltip {
    display: block;
    top: 0px;
  }
`;
