import React, { useRef, useState } from 'react';
import { type ImageProps } from 'antd';

import { Icon } from '../Icon';

import * as S from './Image.styles';

type Props = ImageProps & {
  borderRadius?: number;
};

export const Image = React.memo(
  ({ height, width, borderRadius, ...rest }: Props) => {
    const containerRef = useRef(null);
    const [isError, setIsError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const placeholder = (
      <S.Placeholder>
        <Icon name="photo-x" />
      </S.Placeholder>
    );

    return (
      <S.Container $borderRadius={borderRadius} ref={containerRef}>
        <S.Image
          onError={() => setIsError(true)}
          onLoad={() => {
            setIsError(false);
            setIsLoaded(true);
          }}
          // placeholder while loading
          placeholder={!isLoaded && placeholder}
          style={{ height, width }}
          height={height}
          width={width}
          {...rest}
        />
        {isError && placeholder}
      </S.Container>
    );
  }
);
