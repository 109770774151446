import {
  formatContactInformation,
  formatPhoneNumber,
} from '@npm/core/ui/utils/formatters';
import { type OrganizationAggregate, type Person } from '@npm/data-access';

export const prepareReadonlyData = (data: OrganizationAggregate | Person) => {
  if (data?.contact_information) {
    const { contact_information } = data;

    let values = [];

    if ((data as Person).first || (data as Person).last) {
      const { first, middle, last }: Person = data;
      values = [
        {
          label: 'First Name',
          value: first,
        },
        {
          label: 'Middle Name',
          value: middle || '-',
        },
        {
          label: 'Last Name',
          value: last,
        },
      ];
    }

    return [
      ...values,
      {
        label: 'Address',
        value: formatContactInformation(contact_information),
      },
      {
        label: 'Phone',
        value: formatPhoneNumber(contact_information?.phone),
      },
    ].map(item => ({ ...item, maskInDatadog: true }));
  }

  return [];
};
