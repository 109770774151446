import styled from 'styled-components';

import { Select as SelectBase } from '@npm/core/ui/components/atoms/Select';

import { TooltipFormItem as TooltipFormItemBase } from '../../TooltipFormItem';

export const TooltipFormItem = styled(TooltipFormItemBase)`
  &.ant-col .ant-form-item-control {
    height: 0;
  }
`;

export const Select = styled(SelectBase)`
  && {
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: auto;
    }

    .ant-select-selection-item {
      white-space: normal;
      word-break: break-word;
      line-height: ${({ theme }) => theme.typography.height.md}px;
    }
  }
`;
