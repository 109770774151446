import {
  type TypographyItemProps,
  type TypographyStyledComponentProps,
} from './typography.types';

export const getAdditionalProps: (
  config: Partial<TypographyItemProps>
) => Partial<TypographyItemProps & TypographyStyledComponentProps> = ({
  onClick,
  href,
  target,
  buttonType,
}) => {
  if (onClick) {
    return {
      onClick: e => {
        e.stopPropagation();
        onClick(e);
      },
      as: 'button',
      type: buttonType || 'button',
    };
  }

  if (href || target) {
    return {
      href,
      target,
      as: 'a',
    };
  }

  return {};
};
