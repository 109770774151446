import React from 'react';
import { some } from 'lodash';

import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import {
  type NegotiationAccounts,
  type NegotiationActionFlags,
  type OboModalState,
  type SetOboModalState,
} from '../../OboModal.types';

import { SelectNegotiationAccountCards } from './SelectNegotiationAccountCards';
import { SelectNegotiationAccountTable } from './SelectNegotiationAccountTable';

import * as S from '../../OboModal.styles';

type Props = {
  selectedAccount: OboModalState['account'];
  setOboModalState: SetOboModalState;
  goToRepresentativeStep: () => void;
  handleComplete: (nextOboState: OboModalState) => void;
  negotiationAccounts: NegotiationAccounts;
  negotiationActionFlags: NegotiationActionFlags;
};

export const SelectNegotiationAccount = ({
  selectedAccount,
  setOboModalState,
  goToRepresentativeStep,
  handleComplete,
  negotiationAccounts,
  negotiationActionFlags,
}: Props) => {
  const { isMobile } = useBreakpoints();

  const handleSelect = (nextOboState: OboModalState) => {
    setOboModalState({
      account: nextOboState.account,
      representative: some(nextOboState.representative, Boolean)
        ? nextOboState.representative
        : undefined,
    });
  };

  const commonListProps = {
    handleSelect,
    selectedAccount,
    accounts: negotiationAccounts,
    actionFlags: negotiationActionFlags,
    goToRepresentativeStep,
  };

  return (
    <S.InnerWrapper>
      {isMobile ? (
        <SelectNegotiationAccountCards
          {...commonListProps}
          handleComplete={handleComplete}
        />
      ) : (
        <SelectNegotiationAccountTable {...commonListProps} />
      )}
    </S.InnerWrapper>
  );
};
