import React from 'react';
import { useTheme } from 'styled-components';

import { CypressDataIds } from '../../../../../constants';
import { Icon } from '../../../../atoms/Icon';

import * as S from './HamburgerButton.styles';

type Props = {
  isOpen: boolean;
  handleClick?: () => void;
};

export const HamburgerButton = ({ isOpen, handleClick }: Props) => {
  const { color } = useTheme();

  return (
    <S.HamburgerButton
      onClick={handleClick}
      data-cy={CypressDataIds.MenuMobile.Button}
    >
      {isOpen ? (
        <Icon
          name="x-close"
          size="xs"
          color={color.general.typography.primary}
        />
      ) : (
        <Icon
          name="menu-2"
          size="sm"
          color={color.general.typography.primary}
        />
      )}
    </S.HamburgerButton>
  );
};
