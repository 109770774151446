import { useEffect, useRef } from 'react';

type NumberOrUndefined = number | undefined;

const isNewValueDefinedAndDifferentFromPreviousOne = (
  prev: NumberOrUndefined,
  next: NumberOrUndefined
) => {
  return prev !== next && next !== undefined;
};

export const usePreviousValueIfNewIsUndefined = (
  newValue: NumberOrUndefined
) => {
  const currentValueRef = useRef<NumberOrUndefined>(undefined);

  useEffect(() => {
    if (
      isNewValueDefinedAndDifferentFromPreviousOne(
        currentValueRef.current,
        newValue
      )
    ) {
      currentValueRef.current = newValue;
    }
  }, [newValue]);

  return isNewValueDefinedAndDifferentFromPreviousOne(
    currentValueRef.current,
    newValue
  )
    ? newValue
    : currentValueRef.current;
};
