import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useIssuerEntityShowLazy } from '@npm/data-access';

import { useUserContextStore } from '../../../auth/user/context';
import { useCurrentWorkstation, usePremiumPlan } from '../../../auth/user/role';
import { CompanyOverviewTabs } from '../../CompanyOverview/CompanyOverviewTabs.config';

type Props = {
  companyNpmGuid: string;
};

export const CompaniesDataTableViewDetailButton = ({
  companyNpmGuid,
}: Props) => {
  const [fetchEntity, { isLoading, variables }] = useIssuerEntityShowLazy();

  const { isDesktop } = useBreakpoints();
  const history = useHistory();

  const { isPremiumContentUnlocked } = usePremiumPlan();

  const isSponsoredInvestor = useUserContextStore(
    store => !!store.sponsorshipType
  );

  const workstation = useCurrentWorkstation();

  const isBroker = workstation?.type?.code === 'brokerage';

  const handleCompanySelect = (companyId: number) => {
    if (!companyId) return;

    const routeSuffix = !isPremiumContentUnlocked
      ? `&tab=${CompanyOverviewTabs.FinancingHistory}`
      : '';

    if (isBroker) {
      history.push(
        `/second-market/company-data?companyId=${companyId}${routeSuffix}`
      );
    } else if (isSponsoredInvestor) {
      history.push(
        `/second-market/sponsored-investor/company-data?companyId=${companyId}${routeSuffix}`
      );
    } else {
      history.push(
        `/investor-workstation/company-data?companyId=${companyId}${routeSuffix}`
      );
    }
  };

  return (
    <Button
      variant="outline"
      loading={isLoading && variables.id === companyNpmGuid}
      icon={isDesktop ? undefined : <Icon name="chevron-right" />}
      onClick={async () => {
        const company = await fetchEntity({
          variables: { id: companyNpmGuid },
        });

        handleCompanySelect(company?.id);
      }}
      {...(isDesktop && { style: { minWidth: '130px' } })}
    >
      {!isDesktop ? null : 'View Details'}
    </Button>
  );
};
