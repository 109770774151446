import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type NegotiationIndexItemAggregate } from '@npm/data-access';

import * as S from './HoldingDetails.styles';

type Props = {
  negotiation: NegotiationIndexItemAggregate;
  certificateId?: string;
};

export const HoldingDetails = ({ certificateId, negotiation }: Props) => {
  return (
    <Flex align="center" justify="space-between" gap="sm">
      <S.HoldingDetailsWrapper>
        <S.StyledIcon size="xs" name="file-text" />
        {!!certificateId && (
          <>
            <Text color="info" size="sm">
              {certificateId}
            </Text>
            <S.Seperator>·</S.Seperator>
          </>
        )}
        <Text colorVariant="secondary" size="sm">
          {negotiation?.asset_type?.name}
        </Text>
      </S.HoldingDetailsWrapper>
    </Flex>
  );
};
