import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import {
  Tooltip,
  TooltipInfoIcon,
} from '@npm/core/ui/components/atoms/Tooltip';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { VenusApi } from '@npm/data-access';

import * as S from './CapTableBanner.styles';

export const CAP_TABLE_BANNER_HEADING = 'Waterfall Scenario Analysis';

type Props = {
  venusCompanyId: number;
  companyName?: string;
};

export const CapTableBanner = ({ venusCompanyId, companyName }: Props) => {
  const { isMobile } = useBreakpoints();

  const { data, isLoading } = VenusApi.useCompanyWaterfallLink({
    id: venusCompanyId.toString(),
  });

  if (isLoading || !data?.waterfall_link) {
    return null;
  }

  return (
    <S.Card>
      <Flex direction="column" gap={isMobile ? 'sm' : 'md'} align="flex-start">
        <Flex
          direction={isMobile ? 'column' : 'row'}
          gap="sm"
          align={isMobile ? 'flex-start' : 'center'}
        >
          <Label variant="info">New</Label>
          <Flex gap="sm" align="center">
            <Heading variant="h0">{CAP_TABLE_BANNER_HEADING}</Heading>
            <TooltipInfoIcon title="Screen deals faster with a downloadable Excel model that automatically calculates distribution price based on conversion prices, liquidation multiples, seniority, participation (none, full, capped), and estimated issued share counts." />
          </Flex>
        </Flex>
        <Text style={{ maxWidth: 420 }}>
          Understand the value of different shares based on {companyName}’s
          potential exit scenarios and its unique capital structure.
        </Text>
        <Tooltip
          title={
            isLoading || data?.waterfall_link
              ? undefined
              : 'Temporarily not available'
          }
        >
          <Button
            size="lg"
            icon={<Icon name="download" />}
            onClick={() => {
              window.open(data?.waterfall_link);
            }}
          >
            Download Excel Model
          </Button>
        </Tooltip>
      </Flex>
    </S.Card>
  );
};
