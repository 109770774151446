// naming follows the keys defined in the spec's permissions table
// https://nasdaq-private-market.atlassian.net/wiki/spaces/NFE/pages/94601217/BL004+Roles#Investor-workstation
import { type CodebookItem, type UserRoleAggregate } from '@npm/data-access';

export enum Permission {
  INV_MENU_AGREEMENTS = 'INV_MENU_AGREEMENTS',
  INV_AGREEMENTS_SIGN = 'INV_AGREEMENTS_SIGN',
  INV_MENU_ONBOARDING = 'INV_MENU_ONBOARDING',
  INV_MENU_NDA = 'INV_MENU_NDA',
  INV_MENU_OTHER = 'INV_MENU_OTHER',
  INV_WRITE = 'INV_WRITE',
  INV_AOBO = 'INV_AOBO',
  ISS_MENU_AGREEMENTS = 'ISS_MENU_AGREEMENTS',
  ISS_AGREEMENTS_SIGN = 'ISS_AGREEMENTS_SIGN',
  ISS_MENU_PROGRAMS = 'ISS_MENU_PROGRAMS',
  ISS_DATAROOM_UPLOAD = 'ISS_DATAROOM_UPLOAD',
  ISS_WRITE = 'ISS_WRITE',
  ISS_MENU_OTHER = 'ISS_MENU_OTHER',
  BRO_OBO = 'BRO_OBO',
  BRO_ACCOUNT_MANAGER = 'BRO_ACCOUNT_MANAGER',
  BRO_EXTERNALIZE_ORDERS = 'BRO_EXTERNALIZE_ORDERS',
  BRO_MENU_OTHER = 'BRO_MENU_OTHER',
  BRO_EDIT_ORDERS = 'BRO_EDIT_ORDERS',
  BRO_NEGOTIATE = 'BRO_NEGOTIATE',
  BRO_READ_ONLY = 'BRO_READ_ONLY',
}

export type PermissionContext = {
  workstation:
    | {
        type?: CodebookItem;
      }
    | null
    | undefined;
  getIsAccountCentric: () => boolean;
  role:
    | {
        role_name?: CodebookItem;
        subject?: {
          type?: CodebookItem;
        };
      }
    | null
    | undefined;
  obo:
    | {
        account: { id?: number; type?: { code?: string } };
        representative: { user_id?: number };
      }
    | null
    | undefined;
  sponsorshipType: CodebookItem | null | undefined;
  isIndividualNpmsInvestor: boolean | null | undefined;
  isEntityNpmsInvestor: boolean | null | undefined;
  hasActiveEvents: boolean | null | undefined;
  isSpouseOrGuestRolesOnly: boolean | null | undefined;
  isSpouseRolesOnly: boolean | null | undefined;
  isNpmSettlementInvestor: boolean | null | undefined;
  investorAccounts: UserRoleAggregate[] | null | undefined;
};
export type PermissionType = `${Permission}`;
export type RbacFn = (userContext: PermissionContext) => boolean;
