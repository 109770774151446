import { omit } from 'lodash';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { PAGE_SIZE_HARD_LIMIT } from '@npm/core/ui/components/molecules/Table';
import { handleValidationError } from '@npm/core/ui/utils/form';
import {
  type Account,
  type AccountsPaymentInformationCreateRequestContractBankAccount,
  type PaymentInformation,
  CbPaymentInformationType,
  useAccountPaymentInformationCreate,
  useAccountPaymentInformationIndex,
} from '@npm/data-access';

import { ALTERNATE_BENEFICIARY_ENUM } from './BankAccountInformation.types';

type FormFields = AccountsPaymentInformationCreateRequestContractBankAccount & {
  alternate_beneficiary: boolean;
};

export const useSubmissionBankAccountInformation = (
  account: Account,
  submissionId: number
) => {
  const { data, isLoading, isFetching, refetch } =
    useAccountPaymentInformationIndex(
      {
        accountId: account?.id,
        submissionId,
      },
      {
        queryConfig: {
          enabled: !!account.id && !!submissionId,
          refetchOnMount: 'always',
        },
      }
    );

  return {
    data,
    isLoading,
    isFetching,
    refetch,
  };
};

export const useBankAccountInformationForm = (
  account: Account,
  {
    onUpdate,
  }: Partial<{ onUpdate: (paymentInfo: PaymentInformation) => void }> = {}
) => {
  const { withShowApiErrorMiddleware } = useAlerts();

  const { execute, isLoading: isUpdating } =
    useAccountPaymentInformationCreate();
  const [form] = Form.useForm<FormFields>();

  const { data, isLoading } = useAccountPaymentInformationIndex(
    { accountId: account?.id, size: PAGE_SIZE_HARD_LIMIT },
    {
      queryConfig: {
        enabled: !!account?.id,
      },
    }
  );

  const handleSubmit = async () => {
    let values: FormFields;

    try {
      values = await form.validateFields();
    } catch (e) {
      handleValidationError(form, e);
      return;
    }

    try {
      const response: PaymentInformation = await withShowApiErrorMiddleware(
        execute
      )({
        accountId: account?.id,
        accountsPaymentInformationCreateRequestContract: {
          type: `${CbPaymentInformationType.code}.${CbPaymentInformationType.items.WIRE_INFORMATION}`,
          bank_account: omit(values, ALTERNATE_BENEFICIARY_ENUM),
          alternate_beneficiary: !!values.alternate_beneficiary,
        },
      });

      form.resetFields();
      onUpdate?.(response);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    form,
    data,
    isLoading,
    isUpdating,
    handleSubmit,
  };
};
