import { LabelWithActionItemsCount } from '@npm/core/ui/components/organisms/Layout/Navigation/LabelWithActionItemsCount';
import { CbHoldingState, useHoldingIndex } from '@npm/data-access';

export const HoldingsTitleWithPreVerifyCount = () => {
  const { data } = useHoldingIndex({
    includeSpvs: true,
    holdingState: [CbHoldingState.items.pending],
    preVerified: false,
    page: 1,
    size: 0,
  });

  return (
    <LabelWithActionItemsCount
      label="Holdings"
      count={data?.pagination?.total_records}
    />
  );
};
