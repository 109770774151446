/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  Company409AsApi,
  Company409AsErrorCodes,
  Company409AsErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new Company409AsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type Company409AsMethod = Company409AsApi['company409As'];
export type Company409AsResponseType = MethodResult<Company409AsMethod>;

export const useCompany409As = (
  variables: Parameters<Company409AsMethod>[0],
  config?: UseQueryConfig<
    Company409AsMethod,
    Company409AsErrorTypes
  >
) => {
  return useQuery<
    Company409AsMethod,
    Company409AsErrorTypes
  >(
    (...args) => api.company409As(...args),
    Company409AsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['Company409As'],
        ...apiQueryConfig.Company409As,
        ...config?.queryConfig
      },
    },
    'Company409As',
  );
};

export const useCompany409AsInfinite = (
  variables: Parameters<Company409AsMethod>[0],
  config?: UseInfiniteQueryConfig<
    Company409AsMethod,
    Company409AsErrorTypes
  >
) => {
  return useInfiniteQuery<
    Company409AsMethod,
    Company409AsErrorTypes
  >(
    (...args) => api.company409As(...args),
    Company409AsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['Company409As', 'Company409AsInfinite'],
        ...apiQueryConfig.Company409As,
        ...config?.queryConfig,
      },
    },
    'Company409As',
  );
};

export const useCompany409AsLazy = (baseOptions?: {
  variables?: Parameters<Company409AsMethod>[0],
  config?: UseQueryConfig<
    Company409AsMethod,
    Company409AsErrorTypes
  >
}) => {
  return useLazyQuery<
    Company409AsMethod,
    Company409AsErrorTypes
  >(
    (...args) => api.company409As(...args),
    Company409AsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['Company409As'],
        ...apiQueryConfig.Company409As,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'Company409As',
  );
};

