import type { useLocation } from '../../components/molecules/Link';
import { type getLocationDescriptorObject } from '../../components/molecules/Link/Link.utils';
import { type useCurrentRoute } from '../../hooks/useCurrentRoute';

import { type PageHistoryStack } from './pageHistory.types';

export const pageHistoryService = {
  areHistoryItemsEquals: (
    item1: PageHistoryStack['pageHistory'][number] | undefined,
    item2: PageHistoryStack['pageHistory'][number] | undefined
  ) => {
    return (
      item1?.location.pathname === item2?.location.pathname &&
      item1?.location.search === item2?.location.search
    );
  },
  getNewVersion: (
    currentLocation: ReturnType<typeof useLocation>,
    currentRoute: ReturnType<typeof useCurrentRoute>,
    newRoute: ReturnType<typeof getLocationDescriptorObject>,
    pageTitle: string | null
  ) => {
    // if new route has pageHistory, I'm using it (breadcrumbs)
    if ((newRoute?.state as PageHistoryStack)?.pageHistory != null) {
      return (newRoute?.state as PageHistoryStack)?.pageHistory;
    }

    if (currentRoute?.route?.pageHistory?.doNotIncludeInHistory) {
      return (currentLocation?.state as PageHistoryStack)?.pageHistory;
    }

    if (currentRoute?.route?.pageHistory?.isRoot === true) {
      return [
        {
          title: pageTitle,
          location: {
            pathname: currentLocation?.pathname,
            key: currentLocation?.key,
            hash: currentLocation?.hash,
            search: currentLocation?.search,
          },
        },
      ];
    }

    let pageHistory =
      (currentLocation?.state as PageHistoryStack)?.pageHistory || [];

    // clone is needed to not update current state
    pageHistory = [...pageHistory];

    // prevent adding route to history, if only query params are changed
    const sameRoute = newRoute?.pathname === currentLocation?.pathname;

    if (
      pageHistory[pageHistory.length - 1]?.location?.pathname ===
        currentLocation?.pathname ||
      sameRoute
    ) {
      // do not put the same URL into the history
      return pageHistory;
    }

    if (pageTitle && currentLocation) {
      pageHistory.push({
        title: pageTitle,
        location: {
          pathname: currentLocation?.pathname,
          key: currentLocation?.key,
          hash: currentLocation?.hash,
          search: currentLocation?.search,
        },
      });
    }

    // remove duplicate subtrees
    // 1,2,4,1,2,4 => 1,2,4
    // 1,2,3,2,2,3 => 1,2,3,2,2,3
    // 1,2,3,2,3 => 1,2,3
    if (pageHistory?.length >= 4) {
      let middleIndex = Math.ceil(pageHistory?.length / 2);
      while (middleIndex <= pageHistory?.length - 2) {
        let rightIndex = pageHistory?.length - 1;
        let leftIndex = middleIndex - 1;

        let lastSameIndex = rightIndex;
        while (rightIndex >= middleIndex) {
          if (
            pageHistoryService.areHistoryItemsEquals(
              pageHistory[leftIndex],
              pageHistory[rightIndex]
            )
          ) {
            lastSameIndex = rightIndex;
            rightIndex--;
            leftIndex--;
          } else {
            lastSameIndex = pageHistory?.length - 1;
            break;
          }
        }

        if (lastSameIndex < pageHistory?.length - 1) {
          return pageHistory.slice(0, lastSameIndex);
        }

        middleIndex++;
      }
    }

    return pageHistory;
  },
  getCurrent: (
    currentLocation: ReturnType<typeof useLocation>,
    currentRoute: ReturnType<typeof useCurrentRoute>
  ) => {
    if (currentRoute?.route?.pageHistory?.isRoot) {
      return [];
    }

    return (currentLocation?.state as PageHistoryStack)?.pageHistory || [];
  },
};
