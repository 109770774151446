import React from 'react';

import { Helmet } from '@npm/core/ui/components/atoms/Helmet';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useParams } from '@npm/core/ui/hooks/useParams';
import { type RouteTitleProps } from '@npm/core/ui/routes/router.types';
import { capitalize, truncate } from '@npm/core/ui/utils/formatters';

export const RofrManageTitle = ({ raw }: RouteTitleProps) => {
  const { isMobile } = useBreakpoints();
  const { matchId, action } = useParams<{
    matchId: string;
    action: string;
  }>();

  // for breadcrumbs
  if (raw) {
    const title = `${capitalize(action)} Match #${matchId}`;
    return <>{isMobile ? truncate(title) : title}</>;
  }

  return (
    <Helmet pageTitle={`${capitalize(action)} Match #${matchId}`}></Helmet>
  );
};
