import { type ComponentProps } from 'react';
import styled from 'styled-components';

import { useBreakpoint } from '../../../utils/style';

import { type ReadOnly } from './ReadOnly';

export const CONTENT_WIDTH = 800;

export const Content = styled.div<{
  $marginBottom?: ComponentProps<typeof ReadOnly>['contentMarginBottom'];
}>`
  width: ${CONTENT_WIDTH}px;
  max-width: 100%;
  margin-bottom: ${({ theme, $marginBottom = 'xl' }) =>
    typeof $marginBottom === 'string'
      ? theme.spacing[$marginBottom]
      : $marginBottom}px;

  ${({ theme }) => useBreakpoint(theme).smDesktop`
    width: 100%;
  `}
`;

export const DataItem = styled.div`
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacing.sm}px;
  padding-top: ${({ theme }) => theme.spacing.sm}px;

  & > * {
    flex: 0 0 calc(50% - ${({ theme }) => theme.spacing.sm}px);

    &:first-child {
      margin-right: ${({ theme }) => theme.spacing.md}px;
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid
      ${({ theme }) => theme.color.general.borders.primary};
  }
`;
