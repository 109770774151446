import React, { memo, useEffect } from 'react';

import { useBreakpoints } from '../../../hooks/useBreakpoints';

import { type TopbarItem, Topbar } from './Topbar/Topbar';
import { LAYOUT_WRAPPER_ID } from './Layout.constants';
import { useLayoutStore } from './Layout.store';

import * as S from './Layout.styles';

type Props = {
  topBarConfig?: TopbarItem[];
  children: React.ReactNode;
  customTopbar?: React.ReactNode;
  noPadding?: boolean;
  backgroundElement?: React.ReactNode;
};

export const Layout = memo(
  ({
    children,
    topBarConfig,
    customTopbar,
    noPadding: noPaddingProp = false,
    backgroundElement,
  }: Props) => {
    const bgColor = useLayoutStore(store => store.background);
    const noPadding = useLayoutStore(store => store.noPadding);
    const layoutFlex = useLayoutStore(store => store.layoutFlex);
    const setHeaderHeight = useLayoutStore(store => store.setHeaderHeight);
    const { isMobile, isTablet } = useBreakpoints();

    useEffect(() => {
      // if topBarConfig is provided, the default topbar is rendered -> store the header height
      setHeaderHeight(
        topBarConfig
          ? isMobile || isTablet
            ? S.APP_HEADER_HEIGHT_MOBILE
            : S.APP_HEADER_HEIGHT
          : 0
      );
    }, [isMobile, isTablet, setHeaderHeight, topBarConfig]);

    return (
      <S.Wrapper id={LAYOUT_WRAPPER_ID} $bg={bgColor} $withFlex={layoutFlex}>
        {backgroundElement}
        {customTopbar ?? (topBarConfig && <Topbar config={topBarConfig} />)}
        <S.WrapperContent $noPadding={noPadding || noPaddingProp}>
          {children}
        </S.WrapperContent>
      </S.Wrapper>
    );
  }
);

Layout.displayName = 'Layout';
