import { Codebooks } from './codebooks.types';

export type DocumentIDCategory =
  | typeof Items['drivers_license']
  | typeof Items['passport']
  | typeof Items['other'];

const Items = {
  drivers_license: 'drivers_license',
  passport: 'passport',
  other: 'other',
  loa: 'loa',
  constituent_document: 'constituent_document',
  proof_of_ownership_document: 'proof_of_ownership_document',
  transaction_confirmation: 'transaction_confirmation',
  signed_agreement_document: 'signed_agreement_document',
  signed_order_document: 'signed_order_document',
  signed_marketplace_agreement_document:
    'signed_marketplace_agreement_document',
  signed_paying_agent_service_document: 'signed_paying_agent_service_document',
} as const;

type Keys = keyof typeof Items;

const Labels: Partial<Record<Keys, string>> = {
  drivers_license: `US driver's license`,
  passport: `Passport`,
  other: `Other valid, unexpired government issued photo ID`,
};

export const CbDocumentType = {
  code: Codebooks.DOCUMENT_TYPE,
  items: Items,
  documentIdItems: [
    Items.drivers_license,
    Items.passport,
    Items.other,
  ] as DocumentIDCategory[],
  getLabel: (code: string) => Labels[code as Keys],
};
