import styled from 'styled-components';

import { Modal } from '@npm/core/ui/components/molecules/Modal';

export const StyledModal = styled(Modal)`
  &&& .ant-modal-content {
    padding: ${({ theme }) => theme.spacing.md}px
      ${({ theme }) => theme.spacing.xl}px;
  }

  &&& .ant-modal-body {
    position: relative;
    overflow: visible;
  }
`;
