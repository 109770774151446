import React from 'react';
import { type FormInstance } from 'antd';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';

import { OptInMode, OptInStep } from '../../OptIn.enums';
import { useOptInForm } from '../../OptIn.hooks';
import { type OptInFormValues } from '../../OptInEntitySelection/OptInEntitySelection.types';

import * as S from './OptInFooter.styles';

interface Props {
  form: FormInstance<OptInFormValues>;
  optInMode: OptInMode;
  setOptInStep: (step: OptInStep) => void;
}

export const OptInFooter = ({ form, optInMode, setOptInStep }: Props) => {
  const { handleCreateAccount, handleCopyAccount } = useOptInForm(form);

  return (
    <S.ButtonsContainer>
      <Button
        variant="text"
        onClick={() => setOptInStep(OptInStep.ACCOUNT_TYPE_SELECTION)}
        blockOnMobile
        icon={<Icon name="chevron-left" size="xs" />}
      >
        Back
      </Button>
      <Button
        blockOnMobile
        onClick={
          optInMode === OptInMode.COPY_ACCOUNT
            ? handleCopyAccount
            : handleCreateAccount
        }
      >
        {optInMode === OptInMode.COPY_ACCOUNT
          ? 'Link account'
          : 'Create New Account'}
      </Button>
    </S.ButtonsContainer>
  );
};
