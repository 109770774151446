import {
  InputFormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { DATE_FORMATS, parseDateToString } from '@npm/core/ui/utils/formatters';
import { useUserContextStore } from '@npm/features/auth/user/context';

import * as S from './Signature.styles';

export const Signature = () => {
  const getFullName = useUserContextStore(store => store.getFullName);
  const fullLegalName = getFullName();

  return (
    <S.Container>
      <InputFormItem
        name="signature"
        label="Signature"
        rules={[
          VALIDATION_RULES.matchSpecificValue(
            fullLegalName,
            `Signature must match your full legal name "${fullLegalName}"`
          ),
        ]}
        validateTrigger="onBlur"
        extra={`Your full legal name “${fullLegalName}”`}
        data-dd-privacy="mask"
      >
        <Input placeholder="Signature" />
      </InputFormItem>
      <S.DateText size="xs" color="general" colorVariant="tertiary">
        Dated {parseDateToString(new Date(), DATE_FORMATS.FULL)}
      </S.DateText>
    </S.Container>
  );
};
