import styled from 'styled-components';

import {
  centerFlex,
  centerSelfAbsolute,
  preserveHorizontalFocusBorder,
  useBreakpoint,
} from '../../../../utils/style';
import { BOTTOM_NAVIGATION_BAR_MIN_HEIGHT_MOBILE } from '../../../atoms/BottomNavigation';
import { Button } from '../../../atoms/Button';
import { CardBase } from '../../../atoms/CardBase';
import { EXPANDABLE_PANEL_HEADER_HEIGHT } from '../../../atoms/ExpandablePanel';

import {
  BODY_WIDTH,
  EXIT_BUTTON_WIDTH,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
  MAX_CONTENT_WIDTH,
  SIDEBAR_WIDTH,
  STEPS_TOGGLE_BUTTON_WIDTH,
  SUBHEADER_HEIGHT,
} from './WizardLayout.constants';

export const FullScreenWrapper = styled.div`
  ${centerFlex}
  flex-direction: column;
  max-width: 100%;
`;

export const MaxWidthContainer = styled.div`
  z-index: ${({ theme }) => theme.zIndex.base};
  width: ${MAX_CONTENT_WIDTH}px;
  max-width: 100%;
  padding: 0 ${({ theme }) => theme.spacing.md}px;
`;

export const Header = styled.div<{
  $noBorder?: boolean;
  $withMargin?: boolean;
}>`
  z-index: ${({ theme }) => theme.zIndex.fixed};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT}px;
  border-bottom: 1px solid
    ${({ theme, $noBorder }) =>
      $noBorder ? 'transparent' : theme.color.general.borders.primary};

  ${({ theme, $withMargin }) =>
    $withMargin && `margin-bottom: ${theme.spacing.md}px`};

  ${({ theme }) => useBreakpoint(theme).tablet`
    padding: 0 ${theme.spacing.sm}px;
    align-self: stretch;
    z-index: ${({ theme }) => theme.zIndex.base};
  `}

  ${({ theme }) => useBreakpoint(theme).mobile`
    height: ${HEADER_HEIGHT_MOBILE}px;
  `}
`;

export const SubHeader = styled(Header)`
  height: ${SUBHEADER_HEIGHT}px;
  background-color: ${({ theme }) => theme.color.general.layout.zero};
  ${preserveHorizontalFocusBorder}

  ${({ theme }) => useBreakpoint(theme).tablet`
    background-color: transparent;
  `}
`;

export const TitleWrapper = styled.div`
  ${centerSelfAbsolute}
  ${centerFlex}
  gap: ${({ theme }) => theme.spacing.md}px;

  ${({ theme }) => useBreakpoint(theme).tablet`
    position: relative;
    transform: unset;
    top: 0;
    left: 0;
    overflow: hidden;
    max-width: ${({ theme }) =>
      `calc(100% - ${theme.spacing.sm}px - ${EXIT_BUTTON_WIDTH}px - ${STEPS_TOGGLE_BUTTON_WIDTH}px)`};
  `}
`;

export const ExitButton = styled(Button)`
  width: ${EXIT_BUTTON_WIDTH}px;
  flex-shrink: 0;
`;

export const StepsToggleButton = styled(Button)`
  width: ${STEPS_TOGGLE_BUTTON_WIDTH}px;
  flex-shrink: 0;
  margin-left: auto;
`;

export const Row = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xl}px;

  ${({ theme }) => useBreakpoint(theme).tablet`
    flex-direction: column;
  `}
`;

export const MainContainer = styled(Row)`
  height: calc(100vh - ${HEADER_HEIGHT}px);
`;

export const Sidebar = styled.div`
  width: ${SIDEBAR_WIDTH}px;
  margin-top: ${HEADER_HEIGHT}px;
  flex-shrink: 0;
  overflow: hidden auto;

  ${({ theme }) => useBreakpoint(theme).tablet`
    width: 100%;
    padding-bottom: ${EXPANDABLE_PANEL_HEADER_HEIGHT + theme.spacing.sm}px;
    margin-top: 0;
  `}
`;

export const SidebarSectionCard = styled(CardBase)`
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  background-color: ${({ theme }) => theme.color.general.layout.one};
`;

export const Body = styled.div`
  flex-grow: 1;
  max-width: 100%;
  overflow-y: auto;
  padding-top: ${HEADER_HEIGHT}px;
  ${preserveHorizontalFocusBorder}

  ${({ theme }) => useBreakpoint(theme).tablet`
    padding-bottom: ${EXPANDABLE_PANEL_HEADER_HEIGHT + theme.spacing.md}px;
    padding-top: 0;
  `}
`;

export const InnerBody = styled.div<{ $wide?: boolean }>`
  width: ${({ $wide }) => ($wide ? 'auto' : BODY_WIDTH + 'px')};
`;

export const ExpandablePanelWrapper = styled.div<{
  $maxWidth: string;
}>`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.dropdown};
  left: 50%;
  bottom: 24px;
  width: min(calc(100% - ${({ theme }) => 2 * theme.spacing.sm}px), 100%);
  max-width: min(522px, 100%);
  transform: translateX(-50%);

  ${({ theme }) => useBreakpoint(theme).tablet`
    bottom: 0;
    left: ${theme.spacing.sm}px;
    width: min(calc(100% - ${({ theme }) => 2 * theme.spacing.sm}px), 100%);
    max-width: 100%;
    transform: translateX(0);
  `}

  ${({ theme }) => useBreakpoint(theme).mobile`
    bottom: ${BOTTOM_NAVIGATION_BAR_MIN_HEIGHT_MOBILE}px;
  `}
`;
