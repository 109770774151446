import React from 'react';

import { type AccountShowAggregate } from '@npm/data-access';

import { Label } from '../../atoms/Label';

import * as S from './AccountBadge.styles';

type Props = {
  account: AccountShowAggregate;
};

export const AccountBadge = ({ account }: Props) => {
  if (!account) return null;

  return (
    <S.Badge>
      <S.AccountName size="sm" colorVariant="primary">
        {account.name}
      </S.AccountName>
      <Label variant="info">{account.brokerage_firm?.name}</Label>
    </S.Badge>
  );
};
