import React, { type ComponentProps } from 'react';

import {
  type FilterItemProps,
  IssuerEntitySectorsSelect,
} from '../../../../../filters';

type Props = FilterItemProps & ComponentProps<typeof IssuerEntitySectorsSelect>;

export const IssuerEntitySectorsFilter = ({
  name,
  onFilterSubmit,
  onChange,
  ...rest
}: Props) => {
  return (
    <IssuerEntitySectorsSelect
      onChange={(...args) => {
        onChange(...args);
        onFilterSubmit?.(name);
      }}
      {...rest}
    />
  );
};
