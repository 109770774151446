import styled from 'styled-components';

import { useBreakpoint } from '../../../../utils/style';
import { FOOTER_HEIGHT, FOOTER_HEIGHT_MOBILE } from '../Layout';

export const Placeholder = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  height: ${FOOTER_HEIGHT};
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.dropdown};
  background: transparent;

  ${({ theme }) => useBreakpoint(theme).tablet`
    height: ${FOOTER_HEIGHT_MOBILE};
  `}
`;
