/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorValidation } from '../model';
// @ts-ignore
import { Workstation } from '../model';
// @ts-ignore
import { WorkstationCreateRequestContract } from '../model';
// @ts-ignore
import { WorkstationIndex } from '../model';
/**
 * WorkstationApi - axios parameter creator
 * @export
 */
export const WorkstationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary selects a role based on the selected workstation
         * @param {WorkstationCreateRequestContract} workstationCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workstationCreate: async (workstationCreateRequestContract: WorkstationCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workstationCreateRequestContract' is not null or undefined
            assertParamExists('workstationCreate', 'workstationCreateRequestContract', workstationCreateRequestContract)
            const localVarPath = changeUrl(`/api/workstations`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workstationCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list workstations for the current account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workstationIndex: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/workstations`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkstationApi - functional programming interface
 * @export
 */
export const WorkstationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkstationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary selects a role based on the selected workstation
         * @param {WorkstationCreateRequestContract} workstationCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workstationCreate(workstationCreateRequestContract: WorkstationCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workstation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workstationCreate(workstationCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list workstations for the current account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workstationIndex(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkstationIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workstationIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkstationApi - factory interface
 * @export
 */
export const WorkstationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkstationApiFp(configuration)
    return {
        /**
         * 
         * @summary selects a role based on the selected workstation
         * @param {WorkstationCreateRequestContract} workstationCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workstationCreate(workstationCreateRequestContract: WorkstationCreateRequestContract, options?: any): AxiosPromise<Workstation> {
            return localVarFp.workstationCreate(workstationCreateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list workstations for the current account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workstationIndex(options?: any): AxiosPromise<WorkstationIndex> {
            return localVarFp.workstationIndex(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for workstationCreate operation in WorkstationApi.
 * @export
 * @interface WorkstationApiWorkstationCreateRequest
 */
export interface WorkstationApiWorkstationCreateRequest {
    /**
     * 
     * @type {WorkstationCreateRequestContract}
     * @memberof WorkstationApiWorkstationCreate
     */
    readonly workstationCreateRequestContract: WorkstationCreateRequestContract
}

/**
 * WorkstationApi - object-oriented interface
 * @export
 * @class WorkstationApi
 * @extends {BaseAPI}
 */
export class WorkstationApi extends BaseAPI {
    /**
     * 
     * @summary selects a role based on the selected workstation
     * @param {WorkstationApiWorkstationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkstationApi
     */
    public workstationCreate(requestParameters: WorkstationApiWorkstationCreateRequest, options?: AxiosRequestConfig) {
        return WorkstationApiFp(this.configuration).workstationCreate(requestParameters.workstationCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list workstations for the current account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkstationApi
     */
    public workstationIndex(options?: AxiosRequestConfig) {
        return WorkstationApiFp(this.configuration).workstationIndex(options).then((request) => request(this.axios, this.basePath));
    }
}

export type WorkstationCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 403;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const WorkstationCreateErrorCodes = [
    401,
    403,
];

export type WorkstationIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const WorkstationIndexErrorCodes = [
    401,
];


