import React from 'react';
import ReactQuill from 'react-quill';

import * as S from './WysiwygInput.styles';

import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
  ],
};

type Props = {
  value?: string;
  onChange?: (e: unknown) => void;
  placeholder?: string;
};

export const WysiwygInput: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
}) => {
  const onChangeInternal = (newValue: string) => {
    const computedValue = newValue === '<p><br></p>' ? '' : newValue;
    onChange?.(computedValue);
  };
  return (
    <S.Wrapper>
      <ReactQuill
        theme="snow"
        modules={modules}
        value={value}
        onChange={onChangeInternal}
        placeholder={placeholder}
      />
    </S.Wrapper>
  );
};
