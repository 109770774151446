import React, { type ComponentProps, useRef, useState } from 'react';

import { useConfirmOnLeave } from '@npm/core/ui/components/molecules/Link';

import { AccountSwitcher } from '../../../auth/user/context/RoleSwitcher/AccountSwitcher';
import { OboModal } from '../../../auth/user/obo';
import { type OboDefinition } from '../../../auth/user/role/userRole.types';

import { OrderPlacementDrawer } from './OrderPlacementDrawer';

type Props = {
  isOrderPlacementOpen: boolean;
  setIsOrderPlacementOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isInvestor: boolean;
  obo: OboDefinition | null;
  setOboOverride: React.Dispatch<React.SetStateAction<OboDefinition>>;
  isOboModalOpen?: boolean;
  setIsOboModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
} & Pick<
  ComponentProps<typeof OrderPlacementDrawer>,
  'disabledFields' | 'initialValues' | 'source'
>;

export const OrderPlacement = ({
  isOrderPlacementOpen,
  setIsOrderPlacementOpen,
  isOboModalOpen,
  setIsOboModalOpen,
  obo,
  setOboOverride,
  isInvestor,
  ...drawerProps
}: Props) => {
  const confirmOnLeave = useConfirmOnLeave();
  const hasUnsavedChangesBeforeOboModalOpen = useRef<boolean>();
  const [isAccountSwitcherOpen, setIsAccountSwitcherOpen] = useState(false);

  return (
    <>
      <OrderPlacementDrawer
        {...drawerProps}
        isOpen={isOrderPlacementOpen}
        onClose={() => setIsOrderPlacementOpen(false)}
        onAccountSwitch={() => {
          if (isInvestor) {
            setIsAccountSwitcherOpen(true);
          } else {
            // reset unsaved changes before opening the OBO modal to switch account,
            // changing the filters or pagination inside the modal would trigger opening the "discard changes" modal, which is not desired
            confirmOnLeave.setHasUnsavedChanges(false);
            hasUnsavedChangesBeforeOboModalOpen.current =
              confirmOnLeave.hasUnsavedChanges;
            setIsOboModalOpen(true);
          }
        }}
        isInvestor={isInvestor}
        obo={obo}
        setOboOverride={setOboOverride}
        confirmOnLeaveProps={confirmOnLeave}
      />
      {isInvestor ? (
        <AccountSwitcher
          title="Switch Account"
          isOpen={isAccountSwitcherOpen}
          onSubmit={() => setIsOrderPlacementOpen(true)}
          onClose={() => {
            setIsAccountSwitcherOpen(false);
          }}
        />
      ) : (
        // BRO WS
        <OboModal
          open={isOboModalOpen}
          setOboOverride={setOboOverride}
          onSuccess={() => setIsOrderPlacementOpen(true)}
          onCancel={() => {
            setIsOboModalOpen(false);
            // set the unsaved changes state back to the previous value (before OBO modal was opened), delay slightly to avoid triggering the "discard changes" modal unnecessarily
            setTimeout(() => {
              confirmOnLeave.setHasUnsavedChanges(
                hasUnsavedChangesBeforeOboModalOpen.current
              );
            }, 100);
          }}
        />
      )}
    </>
  );
};
