import { useMemo } from 'react';
import { type ChartDataset } from 'chart.js';
import { useTheme } from 'styled-components';

import { transparentise } from '@npm/core/ui/utils/style';

import { type CompanyOverviewChartSeries } from '../../../CompanyOverview.types';
import { sliceDataset } from '../../../CompanyOverview.utils';
import type { CompanyOverviewChartDataSetEffect } from '../../../TapeDPricing/TapeDPricing.types';
import { getPrimaryRoundsColor } from '../../PrimaryRounds.utils';

import {
  type PrimaryRoundsChartDataPoint,
  type PrimaryRoundValue,
} from './PrimaryRoundsChart.types';
import { convertPrimaryRoundsToDataSet } from './PrimaryRoundsChart.utils';

export const getMinimumDate = (array: PrimaryRoundValue[]) => {
  if (!array.length) return null;

  return new Date(
    Math.min(...array.map(item => new Date(item.investment_date).getTime()))
  );
};

export const usePrimaryRoundDateLimits = (data: PrimaryRoundValue[]) => {
  return useMemo(() => {
    const minDate = getMinimumDate(data);
    const maxDate = new Date();

    return [minDate, maxDate];
  }, [data]);
};

type HookOptions = {
  series: CompanyOverviewChartSeries;
  currentMinDate: Date;
  currentMaxDate: Date;
  effect?: CompanyOverviewChartDataSetEffect;
};

export const usePrimaryRoundDataset = (
  sortedData: PrimaryRoundValue[],
  options: HookOptions
): ChartDataset<'line', PrimaryRoundsChartDataPoint[]> => {
  const theme = useTheme();

  const { series, currentMinDate, currentMaxDate, effect } = options;

  return useMemo(() => {
    const { primaryRoundsData } = convertPrimaryRoundsToDataSet(
      sortedData,
      series
    );

    const data = sliceDataset(
      primaryRoundsData,
      currentMinDate,
      currentMaxDate,
      new Date()
    );

    const borderColor = getPrimaryRoundsColor(theme);

    return {
      label: 'Primary Rounds',
      data,
      stepped: 'before',
      spanGaps: true,
      borderWidth: effect === 'highlight' ? 3 : 2,
      pointRadius: 3,
      pointHoverRadius: 4,
      borderColor:
        effect === 'dim' ? transparentise(borderColor, 0.3) : borderColor,
    };
  }, [sortedData, series, theme, currentMinDate, currentMaxDate, effect]);
};
