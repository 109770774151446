import React from 'react';

import { type IconNames } from '@npm/utils';

import { Flex } from '../../../../atoms/common';
import { Icon } from '../../../../atoms/Icon';
import { Heading } from '../../../../atoms/Typography';

import * as S from './Card.styles';

const ICON_SIZE = 40;

type Props = {
  title: string;
  icon: IconNames;
  content: React.ReactNode;
  buttons: React.ReactNode;
};

export const Card = ({ title, icon, content, buttons }: Props) => {
  return (
    <S.Card>
      <S.CardContent>
        <S.IconWrapper>
          <Icon size={ICON_SIZE} name={icon} />
        </S.IconWrapper>
        <Flex direction={'column'} gap={'md'}>
          <S.ContentText>
            <Heading variant={'h2'}>{title}</Heading>
            {content}
          </S.ContentText>
          <S.ContentButtons>{buttons}</S.ContentButtons>
        </Flex>
      </S.CardContent>
    </S.Card>
  );
};
