import React, { type ComponentProps, type ReactNode } from 'react';
import { Affix } from 'antd';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { useLayoutConfig } from '@npm/core/ui/components/organisms/Layout';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import { FilterPanel } from '../FilterPanel';

import { FilterDropdown } from './FilterDropdown';

import * as S from './CollapsibleFilterPanel.styles';

export const COLLAPSIBLE_FILTER_PANEL_DESKTOP_CONTAINER_CLASS =
  'collapsible-filter-panel-desktop-container';

export const EXTRA_CONTENT_CARD_MOBILE = 'extra-content-card-mobile';

type Props<T> = ComponentProps<typeof FilterPanel<T>> & {
  filterDropdownProps?: ComponentProps<typeof FilterDropdown>;
  filterTabs?: React.ReactNode;
  extraContent?: ReactNode;
  responsive?: boolean;
  // `false` means not sticky
  affixOffsetTop?: number | false;
};

export const CollapsibleFilterPanel = <T,>({
  filterDropdownProps,
  filterTabs,
  totalRecords,
  extraContent,
  responsive = true,
  affixOffsetTop: customAffixOffsetTop,
  ...filterPanelProps
}: Props<T>) => {
  const { isMobile } = useBreakpoints();
  const { header } = useLayoutConfig();
  const affixOffsetTop =
    customAffixOffsetTop === false
      ? null
      : customAffixOffsetTop ?? header.height;

  const filterPanel = (
    <FilterPanel
      defaultColumnProps={{ span: 24 }}
      totalRecords={isMobile ? undefined : totalRecords}
      {...filterPanelProps}
    />
  );

  return isMobile && responsive ? (
    <S.ContainerMobile>
      {filterPanel}
      {(filterTabs || extraContent) && (
        <Affix offsetTop={affixOffsetTop}>
          <Card
            variant="secondMarket"
            noContentPadding
            className={EXTRA_CONTENT_CARD_MOBILE}
          >
            <Flex style={{ width: '100%' }}>
              <S.InnerBox>{filterTabs}</S.InnerBox>
              {extraContent}
            </Flex>
          </Card>
        </Affix>
      )}
    </S.ContainerMobile>
  ) : (
    <S.ContainerDesktop
      className={COLLAPSIBLE_FILTER_PANEL_DESKTOP_CONTAINER_CLASS}
    >
      <S.InnerBox>
        <FilterDropdown {...filterDropdownProps}>{filterPanel}</FilterDropdown>
        {filterTabs}
      </S.InnerBox>
      {extraContent}
    </S.ContainerDesktop>
  );
};
