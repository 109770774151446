/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  EventApi,
  EventListErrorCodes,
  EventListErrorTypes,
  EventShowErrorCodes,
  EventShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new EventApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type EventListMethod = EventApi['eventList'];
export type EventListResponseType = MethodResult<EventListMethod>;

export const useEventList = (
  variables: Parameters<EventListMethod>[0],
  config?: UseQueryConfig<
    EventListMethod,
    EventListErrorTypes
  >
) => {
  return useQuery<
    EventListMethod,
    EventListErrorTypes
  >(
    (...args) => api.eventList(...args),
    EventListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventList'],
        ...apiQueryConfig.EventList,
        ...config?.queryConfig
      },
    },
    'EventList',
  );
};

export const useEventListInfinite = (
  variables: Parameters<EventListMethod>[0],
  config?: UseInfiniteQueryConfig<
    EventListMethod,
    EventListErrorTypes
  >
) => {
  return useInfiniteQuery<
    EventListMethod,
    EventListErrorTypes
  >(
    (...args) => api.eventList(...args),
    EventListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventList', 'EventListInfinite'],
        ...apiQueryConfig.EventList,
        ...config?.queryConfig,
      },
    },
    'EventList',
  );
};

export const useEventListLazy = (baseOptions?: {
  variables?: Parameters<EventListMethod>[0],
  config?: UseQueryConfig<
    EventListMethod,
    EventListErrorTypes
  >
}) => {
  return useLazyQuery<
    EventListMethod,
    EventListErrorTypes
  >(
    (...args) => api.eventList(...args),
    EventListErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EventList'],
        ...apiQueryConfig.EventList,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EventList',
  );
};


export type EventShowMethod = EventApi['eventShow'];
export type EventShowResponseType = MethodResult<EventShowMethod>;

export const useEventShow = (
  variables: Parameters<EventShowMethod>[0],
  config?: UseQueryConfig<
    EventShowMethod,
    EventShowErrorTypes
  >
) => {
  return useQuery<
    EventShowMethod,
    EventShowErrorTypes
  >(
    (...args) => api.eventShow(...args),
    EventShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventShow'],
        ...apiQueryConfig.EventShow,
        ...config?.queryConfig
      },
    },
    'EventShow',
  );
};

export const useEventShowInfinite = (
  variables: Parameters<EventShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    EventShowMethod,
    EventShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    EventShowMethod,
    EventShowErrorTypes
  >(
    (...args) => api.eventShow(...args),
    EventShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventShow', 'EventShowInfinite'],
        ...apiQueryConfig.EventShow,
        ...config?.queryConfig,
      },
    },
    'EventShow',
  );
};

export const useEventShowLazy = (baseOptions?: {
  variables?: Parameters<EventShowMethod>[0],
  config?: UseQueryConfig<
    EventShowMethod,
    EventShowErrorTypes
  >
}) => {
  return useLazyQuery<
    EventShowMethod,
    EventShowErrorTypes
  >(
    (...args) => api.eventShow(...args),
    EventShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EventShow'],
        ...apiQueryConfig.EventShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EventShow',
  );
};

