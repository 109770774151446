import isEqual from 'lodash/isEqual';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';

import { CompaniesDataSortingOptions } from './CompaniesDataSorting.config';
import { type CompaniesDataSort } from './CompaniesDataSorting.types';

import * as S from './CompaniesDataSorting.styles';

type Props = {
  sort?: CompaniesDataSort;
  onChange?: (sort: CompaniesDataSort) => void;
};

export const CompaniesDataSorting = ({
  sort = CompaniesDataSortingOptions.lrValuationHighToLow.sort,
  onChange,
}: Props) => {
  return (
    <Dropdown
      menuProps={{
        selectedKeys: [JSON.stringify(sort)],
      }}
      items={Object.entries(CompaniesDataSortingOptions).map(
        ([key, { label, icon, sort: configSort }]) => ({
          key: key,
          label: label,
          icon: <Icon name={icon} />,
          itemIcon: (
            <S.CheckIcon
              $selected={
                configSort.sortBy === sort.sortBy &&
                configSort.sortDirection === sort.sortDirection
              }
            />
          ),
          onClick: () => onChange(configSort),
        })
      )}
    >
      <Button
        variant="outline"
        size="md"
        icon={<Icon name="chevron-down" />}
        iconPosition="right"
      >
        {Object.values(CompaniesDataSortingOptions).find(config =>
          isEqual(config.sort, sort)
        )?.label || CompaniesDataSortingOptions.lrValuationHighToLow.label}
      </Button>
    </Dropdown>
  );
};
