import styled from 'styled-components';

export const TextContent = styled.div`
  text-align: left !important;

  > li {
    text-align: left !important;
  }

  > ol {
    margin-top: ${({ theme }) => theme.spacing.sm}px;
    padding-left: ${({ theme }) => theme.spacing.md}px;
  }
`;
