/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  OptionPlansApi,
  InternalOptionPlanIndexErrorCodes,
  InternalOptionPlanIndexErrorTypes,
  InternalShowOptionPlanErrorCodes,
  InternalShowOptionPlanErrorTypes,
  InternalUpdateOptionPlanErrorCodes,
  OptionPlansDeleteErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new OptionPlansApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalOptionPlanIndexMethod = OptionPlansApi['internalOptionPlanIndex'];
export type InternalOptionPlanIndexResponseType = MethodResult<InternalOptionPlanIndexMethod>;

export const useInternalOptionPlanIndex = (
  variables: Parameters<InternalOptionPlanIndexMethod>[0],
  config?: UseQueryConfig<
    InternalOptionPlanIndexMethod,
    InternalOptionPlanIndexErrorTypes
  >
) => {
  return useQuery<
    InternalOptionPlanIndexMethod,
    InternalOptionPlanIndexErrorTypes
  >(
    (...args) => api.internalOptionPlanIndex(...args),
    InternalOptionPlanIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalOptionPlanIndex'],
        ...apiQueryConfig.InternalOptionPlanIndex,
        ...config?.queryConfig
      },
    },
    'InternalOptionPlanIndex',
  );
};

export const useInternalOptionPlanIndexInfinite = (
  variables: Parameters<InternalOptionPlanIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalOptionPlanIndexMethod,
    InternalOptionPlanIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalOptionPlanIndexMethod,
    InternalOptionPlanIndexErrorTypes
  >(
    (...args) => api.internalOptionPlanIndex(...args),
    InternalOptionPlanIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalOptionPlanIndex', 'InternalOptionPlanIndexInfinite'],
        ...apiQueryConfig.InternalOptionPlanIndex,
        ...config?.queryConfig,
      },
    },
    'InternalOptionPlanIndex',
  );
};

export const useInternalOptionPlanIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalOptionPlanIndexMethod>[0],
  config?: UseQueryConfig<
    InternalOptionPlanIndexMethod,
    InternalOptionPlanIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalOptionPlanIndexMethod,
    InternalOptionPlanIndexErrorTypes
  >(
    (...args) => api.internalOptionPlanIndex(...args),
    InternalOptionPlanIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalOptionPlanIndex'],
        ...apiQueryConfig.InternalOptionPlanIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalOptionPlanIndex',
  );
};


export type InternalShowOptionPlanMethod = OptionPlansApi['internalShowOptionPlan'];
export type InternalShowOptionPlanResponseType = MethodResult<InternalShowOptionPlanMethod>;

export const useInternalShowOptionPlan = (
  variables: Parameters<InternalShowOptionPlanMethod>[0],
  config?: UseQueryConfig<
    InternalShowOptionPlanMethod,
    InternalShowOptionPlanErrorTypes
  >
) => {
  return useQuery<
    InternalShowOptionPlanMethod,
    InternalShowOptionPlanErrorTypes
  >(
    (...args) => api.internalShowOptionPlan(...args),
    InternalShowOptionPlanErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowOptionPlan'],
        ...apiQueryConfig.InternalShowOptionPlan,
        ...config?.queryConfig
      },
    },
    'InternalShowOptionPlan',
  );
};

export const useInternalShowOptionPlanInfinite = (
  variables: Parameters<InternalShowOptionPlanMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalShowOptionPlanMethod,
    InternalShowOptionPlanErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalShowOptionPlanMethod,
    InternalShowOptionPlanErrorTypes
  >(
    (...args) => api.internalShowOptionPlan(...args),
    InternalShowOptionPlanErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowOptionPlan', 'InternalShowOptionPlanInfinite'],
        ...apiQueryConfig.InternalShowOptionPlan,
        ...config?.queryConfig,
      },
    },
    'InternalShowOptionPlan',
  );
};

export const useInternalShowOptionPlanLazy = (baseOptions?: {
  variables?: Parameters<InternalShowOptionPlanMethod>[0],
  config?: UseQueryConfig<
    InternalShowOptionPlanMethod,
    InternalShowOptionPlanErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalShowOptionPlanMethod,
    InternalShowOptionPlanErrorTypes
  >(
    (...args) => api.internalShowOptionPlan(...args),
    InternalShowOptionPlanErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalShowOptionPlan'],
        ...apiQueryConfig.InternalShowOptionPlan,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalShowOptionPlan',
  );
};


export type InternalUpdateOptionPlanMethod = OptionPlansApi['internalUpdateOptionPlan'];

export const useInternalUpdateOptionPlan = () => {
  return useMutation<InternalUpdateOptionPlanMethod>(
    (...args) => api.internalUpdateOptionPlan(...args),
    InternalUpdateOptionPlanErrorCodes,
    'InternalUpdateOptionPlan',
    '/api/internal/option_plans/{id}',
    'put'
  );
};

export type OptionPlansDeleteMethod = OptionPlansApi['optionPlansDelete'];

export const useOptionPlansDelete = () => {
  return useMutation<OptionPlansDeleteMethod>(
    (...args) => api.optionPlansDelete(...args),
    OptionPlansDeleteErrorCodes,
    'OptionPlansDelete',
    '/api/option_plans/{id}',
    'delete'
  );
};
