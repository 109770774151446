import styled from 'styled-components';

export const IconWrapper = styled.div`
  position: relative;
`;

export const AbsoluteWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(60%, -60%);
`;
