import { withErrorBoundary } from 'react-error-boundary';
import { useTheme } from 'styled-components';
import useResizeObserver from 'use-resize-observer';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { ErrorSkeleton } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { type CompanyPricingApi } from '@npm/data-access';

import { type CompanyOverviewMaxValues } from '../TapeDPricing/TapeDPricing.types';

import { BidOfferHistoryChart } from './components/BidOfferHistoryChart/BidOfferHistoryChart';
import { Levels } from './components/Levels/Levels';
import { getBidsColor, getOffersColor } from './BidOfferHistory.utils';

import { LevelWrapper } from './BidOfferHistory.styles';

type Props = {
  isCovered: boolean;
  data: CompanyPricingApi.HistoricalPriceIndex;
  yAxisMaxValues: CompanyOverviewMaxValues;
};

export const BidOfferHistory = withErrorBoundary(
  ({ isCovered, data, yAxisMaxValues }: Props) => {
    const { ref: containerRef, width: containerWidth } = useResizeObserver();
    const theme = useTheme();

    return (
      <Flex direction="column" gap="md">
        <BidOfferHistoryChart
          data={
            isCovered
              ? data.historical_prices
              : { ...data.historical_prices, primary_rounds: [] }
          }
          yAxisMaxValues={yAxisMaxValues}
        />
        {/* containerWidth and containerRef is here because table with scroll={{y:240}} breaks responsiveness */}
        <LevelWrapper key={containerWidth} ref={containerRef}>
          <Levels
            title="Bid Levels"
            tooltip="Weighted-average bid price, computed monthly by NPM, based on visible orders and other trading book activity."
            items={data?.historical_prices?.aggregated_bids}
            noDataTitle={'No Bids'}
            icon={<Icon name="segment" size="xs" color={getBidsColor(theme)} />}
          />
          <Levels
            title="Offer Levels"
            tooltip="Weighted-average offer price, computed monthly by NPM, based on visible orders and other trading book activity."
            items={data?.historical_prices?.aggregated_asks}
            noDataTitle={'No Offers'}
            icon={
              <Icon name="segment" size="xs" color={getOffersColor(theme)} />
            }
          />
        </LevelWrapper>
      </Flex>
    );
  },
  {
    FallbackComponent: ErrorSkeleton,
  }
);
