import React, { type ComponentProps } from 'react';
import { type DefaultTheme } from 'styled-components';

import { Label } from '@npm/core/ui/components/atoms/Label';

import * as S from './styles';

type Props = ComponentProps<typeof Label> & {
  isClear?: boolean;
  bgColor: keyof DefaultTheme['color']['general']['layout'];
};

export const FilterChip = ({
  isClear,
  bgColor,
  children,
  ...labelProps
}: Props) => (
  // using wrapper because of incorrectly using transient ($) props on Label component
  <S.FilterChip $isClear={isClear} $bgColor={bgColor}>
    <Label variant="info" round {...labelProps}>
      {children}
    </Label>
  </S.FilterChip>
);
