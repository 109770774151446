import React from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Button } from '@npm/core/ui/components/atoms/Button';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography/Text';
import { FullScreenLoadingWithPortal } from '@npm/core/ui/components/molecules/FullScreenLoading';
import { type AccountShowAggregate, CbRoleType } from '@npm/data-access';

import {
  type WithSupportTicketType,
  ZendeskContactButton,
} from '../../../../app/zendesk';
import { ONBOARDING_AGREEMENTS_TEXT_CONTENT } from '../../OnboardingAgreements.config';
import { type OnboardingAgreementType } from '../../OnboardingAgreements.types';

import * as S from './OnboardingAgreementSignCta.styles';

type Props = {
  type: OnboardingAgreementType;
  onClick: () => void;
  isRedirectingToDocusign?: boolean;
  account: AccountShowAggregate | undefined;
} & WithSupportTicketType;

// will replace `OnboardingAgreementGetStarted` component
// for now, this is only used in INV account onboarding where "get started" step states had been removed
export const OnboardingAgreementSignCta = ({
  type,
  onClick,
  isRedirectingToDocusign,
  zendeskTicketGroupId,
  account,
}: Props) => {
  if (isRedirectingToDocusign) {
    return (
      <FullScreenLoadingWithPortal loadingTitle="We're taking you to DocuSign" />
    );
  }

  const isDisabled =
    account?.user_role?.role_name?.code !== CbRoleType.items.ADMIN_AND_SIGNER;

  return (
    <>
      <Margin bottom="lg">
        <Alert
          message={
            <>
              If you have any questions, please{' '}
              <ZendeskContactButton zendeskTicketGroupId={zendeskTicketGroupId}>
                contact us
              </ZendeskContactButton>
              .
            </>
          }
          closable
        />
      </Margin>
      <S.StyledCard>
        <Text marginBottom="lg" colorVariant="secondary">
          {ONBOARDING_AGREEMENTS_TEXT_CONTENT.get_started[type]}
        </Text>
        <Tooltip
          title={
            isDisabled
              ? 'To sign this agreement, you must be an Account Admin & Signer.'
              : undefined
          }
        >
          <Button
            icon={<Icon name="redirect" />}
            onClick={onClick}
            blockOnMobile
            disabled={isDisabled}
          >
            Sign
          </Button>
        </Tooltip>
      </S.StyledCard>
    </>
  );
};
