export const slideInFromRightAndOut = {
  initial: { x: '100%' },
  animate: { x: 0 },
  transition: { duration: 0.3 },
  exit: { x: '100%' },
};
export const slideInFromLeftAndOut = {
  initial: { x: '-100%' },
  animate: { x: 0 },
  transition: { duration: 0.3 },
  exit: { x: '-100%' },
};
export const chipPresence = {
  initial: { opacity: 0, scale: 0 },
  animate: { opacity: 1, scale: 1 },
  transition: { duration: 0.25, type: 'spring' },
  exit: { opacity: 0, scale: 0 },
};
export const slideInFromLeft = {
  initial: { x: -500, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  transition: { duration: 0.25, delay: 0.15 },
};

export const slideInFromTop = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -50, transition: { duration: 0.3 } },
  transition: { duration: 0.5, ease: [0.25, 0.1, 0.25, 1] },
};

export const expandCollapse = {
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
};
export const rollUp = {
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 1, height: 0 },
  },
  transition: { duration: 1, ease: [0.04, 0.62, 0.23, 0.98] },
};
export const fadeInAndOut = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.25 },
  exit: { opacity: 0 },
};
export const filtersCollapse = {
  initial: 'collapsed',
  animate: 'expanded',
  variants: {
    collapsed: { height: 0, opacity: 0.3 },
    expanded: { height: 'auto', opacity: 1 },
  },
  transition: {
    duration: 0.3,
    ease: 'easeIn',
  },
};
