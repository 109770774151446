import { startCase } from 'lodash';

import {
  type CbLabelComponent,
  type IconNames,
  type LabelVariant,
} from '@npm/utils';

import { type CodebookItem } from './codebooks.types';

const Items = {
  filled: 'filled',
  live: 'live',
  expired: 'expired',
  in_contract: 'in_contract',
} as const;

export const NegotiationOrderStatusItemIconMap: Partial<
  Record<Keys, IconNames>
> = {
  in_contract: 'check',
};

type Keys = keyof typeof Items;

const LabelMap: Record<Keys, LabelVariant> = {
  filled: 'success',
  live: 'success',
  expired: 'general',
  in_contract: 'info',
};

export const CbNegotiationOrderStatus = {
  code: 'NEGOTIATION_ORDER_STATUS',
  items: Items,
  getLabel: (Component: CbLabelComponent, codebookItem: CodebookItem) => {
    const iconName =
      NegotiationOrderStatusItemIconMap[codebookItem?.code as Keys];
    return Component({
      ...(iconName
        ? { iconProps: { name: iconName } }
        : { style: { display: 'inline', whiteSpace: 'normal' } }),
      variant: LabelMap[(codebookItem?.code as Keys) ?? 'none'],
      label: startCase(codebookItem?.name),
    });
  },
};
