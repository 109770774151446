import React, { type ComponentProps, useMemo } from 'react';
import { Col, Row } from 'antd';
import { type ColProps } from 'antd/lib/grid/col';
import { useTheme } from 'styled-components';

import { CheckboxGroup } from '@npm/core/ui/components/atoms/CheckboxGroup';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import {
  type CodebookItems,
  type CodebookShow,
  useCodebook,
} from '@npm/data-access';

import { Checkbox } from '@npm/core/ui/components/atoms/ButtonCard/ButtonCard.styles';

type Props = {
  colProps?: ColProps;
  codebook: CodebookItems;
  prefix?: string;
  filterOptions?: (
    value: CodebookShow,
    index: number,
    array: CodebookShow[]
  ) => boolean;
} & ComponentProps<typeof CheckboxGroup>;

export const CodebooksCheckbox = ({
  colProps,
  codebook,
  prefix,
  filterOptions,
  ...checkboxGroupProps
}: Props) => {
  const theme = useTheme();

  const { data, isLoading } = useCodebook(
    {
      type: codebook,
      prefix,
    },
    { queryConfig: { enabled: !!codebook } }
  );

  const options = useMemo(() => {
    if (!data) return null;

    let codebooks = data.codebooks;
    if (filterOptions) {
      codebooks = codebooks.filter(filterOptions);
    }

    return [
      ...codebooks.map(({ name, code }) => ({
        label: name,
        value: code,
      })),
    ];
  }, [data, filterOptions]);

  if (isLoading) return <Skeleton.Base title={false} />;

  return (
    <CheckboxGroup {...checkboxGroupProps}>
      <Row gutter={[theme.spacing.sm, theme.spacing.sm]}>
        {options.map(({ label, value }) => (
          <Col span={8} {...colProps} key={value}>
            <Checkbox key={value} value={value} label={label} />
          </Col>
        ))}
      </Row>
    </CheckboxGroup>
  );
};
