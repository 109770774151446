import React, { Suspense, useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Switch } from 'react-router';
import {
  QueryClientProvider,
  QueryErrorResetBoundary,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Spin } from 'antd';
import { ThemeProvider } from 'styled-components';
import { type PushReplaceHistory, QueryParamProvider } from 'use-query-params';

import { ErrorSkeleton } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { JiraBadge } from '@npm/core/ui/components/atoms/JiraBadge';
import { Loader } from '@npm/core/ui/components/atoms/Loader';
import { AlertContainerProvider } from '@npm/core/ui/components/molecules/AlertContainer';
import { FullScreenLoading } from '@npm/core/ui/components/molecules/FullScreenLoading';
import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { useHistoryEventListener } from '@npm/core/ui/components/molecules/Link/useHistoryEventListener';
import { UnsupportedResolution } from '@npm/core/ui/components/molecules/UnsupportedResolutionScreen';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';
import { useDatadogPageTracking } from '@npm/core/ui/hooks/useDatadogPageTracking';
import { useResizeEffect } from '@npm/core/ui/hooks/useResizeEffect';
import { useTabFocusHandler } from '@npm/core/ui/hooks/useTabFocusHandler';
import { pathMetadataList } from '@npm/core/ui/services/pathMetadata';
import {
  darkTheme,
  GlobalStyle,
  lightTheme,
  MetaThemeColor,
} from '@npm/core/ui/themes';
import { getQueryClient, initErrorHandling } from '@npm/data-access';
import { useUserLoggedInDataAttribute } from '@npm/features/app/e2e/useUserLoggedInDataAttribute';
import { withFeatureFlagsContext } from '@npm/features/app/featureFlags/featureFlags.context';
import { withInitialLoading } from '@npm/features/app/initialLoading';
import { withUser } from '@npm/features/app/userLoading';
import { withPerson } from '@npm/features/app/userLoading/withPerson';
import {
  ZendeskContactSupport,
  ZendeskWidgetSafe,
} from '@npm/features/app/zendesk';
import { withUserRoleContext } from '@npm/features/auth/user/role/context/userRole.context';
import { withDefaultRedirects } from '@npm/features/auth/user/role/hoc/withDefaultRedirects';
import { initNDAHandling } from '@npm/features/NDAs/NDAInterceptor';
import { withNotificationContext } from '@npm/features/notifications';
import { initOktaAuthorization, withRedirectToActivationPage } from '@npm/okta';
import { initTOSHandling } from '@npm/onboarding/config';
import { registerOnboardingGeneralMetadata } from '@npm/onboarding/router';
import { registerPreplatformGeneralMetadata } from '@npm/preplatform/router';
import { registerSecondMarketGeneralMetadata } from '@npm/second-market/router';
import { registerBrokerageGeneralMetadata } from '@npm/workstations/brokerage/router';
import {
  registerInvestorGeneralMetadata,
  registerInvestorLiveProgramsMetadata,
} from '@npm/workstations/investor/router';
import {
  registerIssuerGeneralMetadata,
  registerIssuerProgramsMetadata,
} from '@npm/workstations/issuer/router';

import { Router } from './routes/Router';
import { LoginCallback } from './LoginCallback';
import {
  registerApeVueInterceptors,
  registerCapTableInterceptors,
  registerCompanyPricingInterceptors,
  registerInterceptors,
  registerMTMInterceptors,
  registerVenusInterceptors,
} from './utils';

import 'antd/dist/antd.min.css';

registerSecondMarketGeneralMetadata(pathMetadataList);
registerPreplatformGeneralMetadata(pathMetadataList);
registerOnboardingGeneralMetadata(pathMetadataList);
registerBrokerageGeneralMetadata(pathMetadataList);
registerInvestorLiveProgramsMetadata(pathMetadataList);
registerInvestorGeneralMetadata(pathMetadataList);
registerIssuerProgramsMetadata(pathMetadataList);
registerIssuerGeneralMetadata(pathMetadataList);

registerInterceptors([
  initOktaAuthorization(),
  initTOSHandling,
  initNDAHandling,
  initErrorHandling,
]);
registerCapTableInterceptors([initOktaAuthorization()]);
registerMTMInterceptors([initOktaAuthorization()]);
registerVenusInterceptors([initOktaAuthorization()]);
registerCompanyPricingInterceptors([initOktaAuthorization()]);
registerApeVueInterceptors([initOktaAuthorization({ allow401: true })]);

const AppContent = withInitialLoading(
  withRedirectToActivationPage(
    withUser(
      withFeatureFlagsContext(
        withUserRoleContext(
          withNotificationContext(
            withDefaultRedirects(
              withPerson(() => {
                return (
                  <Suspense fallback={<FullScreenLoading />}>
                    <Router />
                  </Suspense>
                );
              })
            )
          )
        )
      )
    )
  )
);

export const App = () => {
  const { isDarkMode } = useDarkModeContext();

  const themeMode = useMemo(
    () =>
      isDarkMode ? { ...darkTheme, isDarkMode } : { ...lightTheme, isDarkMode },
    [isDarkMode]
  );
  const history = useHistory();

  const updatedHistory = useMemo(() => {
    return {
      push: to => history.push(to),
      replace: to => history.replace(to),
    } as PushReplaceHistory;
  }, [history?.push, history?.replace]);

  useEffect(() => {
    Spin.setDefaultIndicator(<Loader />);
  }, []);

  useTabFocusHandler();

  useResizeEffect(() => {
    // set viewport height unit to fix issue with real mobile devices
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  useDatadogPageTracking();
  useHistoryEventListener();
  useUserLoggedInDataAttribute();

  return (
    <QueryParamProvider ReactRouterRoute={Route} history={updatedHistory}>
      <ThemeProvider theme={themeMode}>
        <GlobalStyle />
        <MetaThemeColor />
        <QueryClientProvider client={getQueryClient()}>
          <AlertContainerProvider>
            <QueryErrorResetBoundary>
              <JiraBadge />
              <ZendeskContactSupport />
              <ZendeskWidgetSafe />
              <ErrorBoundary FallbackComponent={ErrorSkeleton}>
                <UnsupportedResolution>
                  <Switch>
                    <Route
                      path="/auth/auth/oktaoauth/callback"
                      component={LoginCallback}
                    />
                    <AppContent />
                  </Switch>
                </UnsupportedResolution>
              </ErrorBoundary>
            </QueryErrorResetBoundary>
          </AlertContainerProvider>
          <ReactQueryDevtools
            initialIsOpen={false}
            toggleButtonProps={{ style: { right: 20, left: 'auto' } }}
          />
        </QueryClientProvider>
      </ThemeProvider>
    </QueryParamProvider>
  );
};
