import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { differenceBy } from 'lodash';

import { chipPresence } from '../../../utils/animations';
import { Chip } from '../../atoms/Chip';
import { Search } from '../../atoms/Search';
import { Select, SelectNoData } from '../../atoms/Select';
import { Text } from '../../atoms/Typography';

import * as S from './MultiSelectWithSearch.styles';

type Option = { label: string; value: string | number };

type Props = {
  options: Option[];
  id?: string;
  isLoading?: boolean;
  onChange?: (options: Option['value'][]) => void;
  initialValues?: Option['value'][];
  disabled?: boolean;
  subtitle?: string;
  getPopupContainer?: () => HTMLElement;
  style?: React.CSSProperties;
};

export const MultiSelectWithSearch = ({
  id,
  onChange,
  isLoading,
  initialValues,
  disabled,
  subtitle,
  getPopupContainer,
  style,
  ...props
}: Props) => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const [searchVal, setSearchVal] = useState('');
  const [options, setOptions] = useState<Option[]>(props.options);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>();

  useEffect(() => {
    if (!isLoading && initialValues?.length && !selectedOptions?.length) {
      setSelectedOptions(
        props.options?.filter(o => initialValues?.includes(o.value))
      );
    }
  }, [isLoading]);

  const handleSearch = val => {
    setSearchVal(val);
    setOptions(
      props.options.filter(({ label }) =>
        label.toLowerCase().includes(val.toLowerCase())
      )
    );
  };

  const handleChange = (options: Option[]) => {
    setSelectedOptions(options);
    onChange?.(options?.map(o => o.value));
  };

  const handleSelect = (_, option) => {
    setSearchVal('');
    if (!selectedOptions) {
      handleChange([option]);
    } else if (!selectedOptions.find(({ value }) => value === option.value)) {
      handleChange([...selectedOptions, option]);
    }
  };

  const handleRemoveItem = item => {
    setSearchVal('');
    handleChange((selectedOptions ?? []).filter(({ value }) => value !== item));
  };

  // omit selected options
  const filteredOptions = useMemo(() => {
    return differenceBy(options, selectedOptions ?? [], 'value');
  }, [options, selectedOptions]);

  return (
    <S.Wrap ref={wrapRef}>
      <S.StyledAutoComplete
        id={id}
        getPopupContainer={
          getPopupContainer ?? (() => wrapRef.current ?? document.body)
        }
        value={searchVal}
        options={filteredOptions}
        onSearch={handleSearch}
        onSelect={handleSelect}
        disabled={disabled}
        style={style}
        dropdownRender={isLoading ? Select.LoadingStateDropdown : undefined}
        notFoundContent={
          <SelectNoData
            text="We couldn't find any results with your search"
            showIcon={true}
          />
        }
      >
        <Search />
      </S.StyledAutoComplete>

      {selectedOptions && selectedOptions.length > 0 && (
        <S.SelectedChipsWrap>
          {subtitle && (
            <Text size="sm" colorVariant="primary" marginBottom="lg">
              {subtitle}
            </Text>
          )}
          <AnimatePresence>
            {selectedOptions?.map(({ label, value }) => (
              <S.ChipWrap key={value} {...chipPresence}>
                <Chip onClose={() => handleRemoveItem(value)} closable>
                  {label}
                </Chip>
              </S.ChipWrap>
            ))}
          </AnimatePresence>
        </S.SelectedChipsWrap>
      )}
    </S.Wrap>
  );
};
