/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalIssuanceIndex } from '../model';
// @ts-ignore
import { InternalIssuanceShow } from '../model';
// @ts-ignore
import { InternalIssuanceUpdateRequestContract } from '../model';
/**
 * IssuancesApi - axios parameter creator
 * @export
 */
export const IssuancesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get issuances for a company
         * @param {string} companyId 
         * @param {string} [date] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalIssuanceIndex: async (companyId: string, date?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalIssuanceIndex', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/issuances`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary remove an Issuance
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalIssuancesRemove: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalIssuancesRemove', 'id', id)
            const localVarPath = changeUrl(`/api/internal/issuances/{id}/remove`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get an issuance by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowIssuance: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalShowIssuance', 'id', id)
            const localVarPath = changeUrl(`/api/internal/issuances/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update an issuance
         * @param {number} id 
         * @param {InternalIssuanceUpdateRequestContract} [internalIssuanceUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateIssuance: async (id: number, internalIssuanceUpdateRequestContract?: InternalIssuanceUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateIssuance', 'id', id)
            const localVarPath = changeUrl(`/api/internal/issuances/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalIssuanceUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IssuancesApi - functional programming interface
 * @export
 */
export const IssuancesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IssuancesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get issuances for a company
         * @param {string} companyId 
         * @param {string} [date] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalIssuanceIndex(companyId: string, date?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalIssuanceIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalIssuanceIndex(companyId, date, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary remove an Issuance
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalIssuancesRemove(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalIssuancesRemove(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get an issuance by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalShowIssuance(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalIssuanceShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalShowIssuance(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update an issuance
         * @param {number} id 
         * @param {InternalIssuanceUpdateRequestContract} [internalIssuanceUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateIssuance(id: number, internalIssuanceUpdateRequestContract?: InternalIssuanceUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalIssuanceShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateIssuance(id, internalIssuanceUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IssuancesApi - factory interface
 * @export
 */
export const IssuancesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IssuancesApiFp(configuration)
    return {
        /**
         * 
         * @summary get issuances for a company
         * @param {string} companyId 
         * @param {string} [date] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalIssuanceIndex(companyId: string, date?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalIssuanceIndex> {
            return localVarFp.internalIssuanceIndex(companyId, date, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary remove an Issuance
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalIssuancesRemove(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalIssuancesRemove(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get an issuance by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowIssuance(id: number, options?: any): AxiosPromise<InternalIssuanceShow> {
            return localVarFp.internalShowIssuance(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update an issuance
         * @param {number} id 
         * @param {InternalIssuanceUpdateRequestContract} [internalIssuanceUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateIssuance(id: number, internalIssuanceUpdateRequestContract?: InternalIssuanceUpdateRequestContract, options?: any): AxiosPromise<InternalIssuanceShow> {
            return localVarFp.internalUpdateIssuance(id, internalIssuanceUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for internalIssuanceIndex operation in IssuancesApi.
 * @export
 * @interface IssuancesApiInternalIssuanceIndexRequest
 */
export interface IssuancesApiInternalIssuanceIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof IssuancesApiInternalIssuanceIndex
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof IssuancesApiInternalIssuanceIndex
     */
    readonly date?: string

    /**
     * 
     * @type {string}
     * @memberof IssuancesApiInternalIssuanceIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof IssuancesApiInternalIssuanceIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalIssuancesRemove operation in IssuancesApi.
 * @export
 * @interface IssuancesApiInternalIssuancesRemoveRequest
 */
export interface IssuancesApiInternalIssuancesRemoveRequest {
    /**
     * 
     * @type {number}
     * @memberof IssuancesApiInternalIssuancesRemove
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof IssuancesApiInternalIssuancesRemove
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof IssuancesApiInternalIssuancesRemove
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalShowIssuance operation in IssuancesApi.
 * @export
 * @interface IssuancesApiInternalShowIssuanceRequest
 */
export interface IssuancesApiInternalShowIssuanceRequest {
    /**
     * 
     * @type {number}
     * @memberof IssuancesApiInternalShowIssuance
     */
    readonly id: number
}

/**
 * Request parameters for internalUpdateIssuance operation in IssuancesApi.
 * @export
 * @interface IssuancesApiInternalUpdateIssuanceRequest
 */
export interface IssuancesApiInternalUpdateIssuanceRequest {
    /**
     * 
     * @type {number}
     * @memberof IssuancesApiInternalUpdateIssuance
     */
    readonly id: number

    /**
     * 
     * @type {InternalIssuanceUpdateRequestContract}
     * @memberof IssuancesApiInternalUpdateIssuance
     */
    readonly internalIssuanceUpdateRequestContract?: InternalIssuanceUpdateRequestContract
}

/**
 * IssuancesApi - object-oriented interface
 * @export
 * @class IssuancesApi
 * @extends {BaseAPI}
 */
export class IssuancesApi extends BaseAPI {
    /**
     * 
     * @summary get issuances for a company
     * @param {IssuancesApiInternalIssuanceIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuancesApi
     */
    public internalIssuanceIndex(requestParameters: IssuancesApiInternalIssuanceIndexRequest, options?: AxiosRequestConfig) {
        return IssuancesApiFp(this.configuration).internalIssuanceIndex(requestParameters.companyId, requestParameters.date, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary remove an Issuance
     * @param {IssuancesApiInternalIssuancesRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuancesApi
     */
    public internalIssuancesRemove(requestParameters: IssuancesApiInternalIssuancesRemoveRequest, options?: AxiosRequestConfig) {
        return IssuancesApiFp(this.configuration).internalIssuancesRemove(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get an issuance by ID
     * @param {IssuancesApiInternalShowIssuanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuancesApi
     */
    public internalShowIssuance(requestParameters: IssuancesApiInternalShowIssuanceRequest, options?: AxiosRequestConfig) {
        return IssuancesApiFp(this.configuration).internalShowIssuance(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update an issuance
     * @param {IssuancesApiInternalUpdateIssuanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuancesApi
     */
    public internalUpdateIssuance(requestParameters: IssuancesApiInternalUpdateIssuanceRequest, options?: AxiosRequestConfig) {
        return IssuancesApiFp(this.configuration).internalUpdateIssuance(requestParameters.id, requestParameters.internalIssuanceUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InternalIssuanceIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalIssuanceIndexErrorCodes = [
    401,
    404,
];

export type InternalIssuancesRemoveErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalIssuancesRemoveErrorCodes = [
    401,
    404,
];

export type InternalShowIssuanceErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalShowIssuanceErrorCodes = [
    404,
];

export type InternalUpdateIssuanceErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalUpdateIssuanceErrorCodes = [
    404,
];


