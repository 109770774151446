import styled, { type DefaultTheme, css } from 'styled-components';

import { type TypographyStyledComponentProps } from '../typography.types';

import { TextCurrencyStyles } from '../typography.styles';

export const TextCurrency = styled.p<
  TypographyStyledComponentProps & {
    $marginLeft?: number | keyof DefaultTheme['spacing'];
  }
>`
  ${TextCurrencyStyles}

  ${({ theme, $marginLeft }) =>
    $marginLeft
      ? css`
          && {
            margin-left: ${typeof $marginLeft === 'string'
              ? theme.spacing[$marginLeft]
              : $marginLeft}px;
          }
        `
      : css`
          margin-left: ${theme.spacing.xs / 2}px;
        `};
`;
