import { type ComponentProps } from 'react';
import { css } from 'styled-components';

import { useBreakpoint } from '../../../utils/style';

import type { Text as TextComponent } from './Text';
import type { TypographyStyledComponentProps } from './typography.types';

type FontVariationSettings = {
  width: number;
  weight: number;
  opticalSize: number;
  grad: number;
  xtra: number;
};

// text with length greater than this will be shrunk
const MAX_NORMAL_TEXT_LENGTH = 8;

const getFontVariationSettings = ({
  width = 114,
  weight = 400,
  opticalSize = 24,
  grad = -100,
  xtra = 500,
}: Partial<FontVariationSettings>) => `
  font-variation-settings: 'wdth' ${width}, 'wght' ${weight}, 'opsz' ${opticalSize}, 'GRAD' ${grad},
    'slnt' 0, 'XTRA' ${xtra}, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712,
    'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
`;

const textColorStyle = css<TypographyStyledComponentProps>`
  ${({ theme, $color, $colorVariant }) =>
    $color
      ? css`
          && {
            color: ${theme.color[$color].typography[
              $colorVariant || 'primary'
            ] || theme.color[$color].typography.primary};
          }
        `
      : css`
          color: ${theme.color.general.typography.primary};
        `};
`;

const marginBottomStyle = css<TypographyStyledComponentProps>`
  margin: 0;
  ${({ theme, $marginBottom }) =>
    $marginBottom
      ? css`
          && {
            margin-bottom: ${typeof $marginBottom === 'string'
              ? theme.spacing[$marginBottom]
              : $marginBottom}px;
          }
        `
      : css`
          margin-bottom: 0;
        `};
`;

const hyperlinkStyle = css<
  TypographyStyledComponentProps & {
    as: ComponentProps<typeof TextComponent>['as'];
  }
>`
  ${({ theme, as }) =>
    (as === 'a' || as === 'button') &&
    `&& {
      color: ${theme.color.info.typography.primary};
      text-align: left;

      &:hover,
      &:active {
        color: ${theme.color.info.backgrounds.primaryHover};
      }
    }
  `}
`;

export const Heading0Styles = css<TypographyStyledComponentProps>`
  font-size: ${({ theme }) => theme.typography.size.xxxl}px;
  line-height: ${({ theme }) => theme.typography.height.xxxl}px;
  letter-spacing: -0.01em;

  ${textColorStyle}
  ${marginBottomStyle}
  ${hyperlinkStyle}
  ${getFontVariationSettings({ weight: 500 })}

  ${({ theme }) => useBreakpoint(theme).mobile`
    font-size: ${theme.typography.size.xl}px;
    line-height: ${theme.typography.height.xl}px;
  `}
`;

export const Heading1Styles = css<TypographyStyledComponentProps>`
  font-size: ${({ theme }) => theme.typography.size.xxl}px;
  line-height: ${({ theme }) => theme.typography.height.xxl}px;
  letter-spacing: -0.01em;

  ${textColorStyle}
  ${marginBottomStyle}
  ${hyperlinkStyle}
  ${getFontVariationSettings({ weight: 500 })}

  ${({ theme }) => useBreakpoint(theme).mobile`
    font-size: ${theme.typography.size.lg}px;
    line-height: ${theme.typography.height.lg}px;
  `}
`;

export const Heading2Styles = css<TypographyStyledComponentProps>`
  font-size: ${({ theme }) => theme.typography.size.lg}px;
  line-height: ${({ theme }) => theme.typography.height.lg}px;

  ${textColorStyle}
  ${marginBottomStyle}
  ${hyperlinkStyle}
  ${getFontVariationSettings({ weight: 455 })}

  ${({ theme }) => useBreakpoint(theme).mobile`
    font-size: ${theme.typography.size.md}px;
    line-height: ${theme.typography.height.md}px;
  `}
`;

export const Heading3Styles = css<TypographyStyledComponentProps>`
  font-size: ${({ theme }) => theme.typography.size.md}px;
  line-height: ${({ theme }) => theme.typography.height.md}px;

  ${textColorStyle}
  ${marginBottomStyle}
  ${hyperlinkStyle}
  ${getFontVariationSettings({ weight: 455 })}

  ${({ theme }) => useBreakpoint(theme).mobile`
    font-size: ${theme.typography.size.sm}px;
    line-height: ${theme.typography.height.sm}px;
  `}
`;

export const TextNumberStyles = css<TypographyStyledComponentProps>`
  font-size: ${({ theme }) => theme.typography.size.xxl}px;
  line-height: ${({ theme }) => theme.typography.height.xl}px;

  ${textColorStyle}
  ${marginBottomStyle}
  ${hyperlinkStyle}
  ${getFontVariationSettings({ weight: 500 })}
`;

export const TextCurrencyStyles = css<TypographyStyledComponentProps>`
  font-size: ${({ theme }) => theme.typography.size.xs}px;
  line-height: ${({ theme }) => theme.typography.height.xs}px;
  text-transform: uppercase;
  letter-spacing: 0.03em;

  ${textColorStyle}
  ${marginBottomStyle}
  ${hyperlinkStyle}
  ${getFontVariationSettings({
    weight: 600,
    grad: 0,
    width: 46,
    opticalSize: 11,
  })}

  ${({ theme }) => useBreakpoint(theme).mobile`
    font-size: ${theme.typography.size.xxs}px;
    line-height: ${theme.typography.height.xxs}px;
  `}
`;

export const InputTextStyles = css`
  font-size: ${({ theme }) => theme.typography.size.md}px;
  line-height: ${({ theme }) => theme.typography.height.md}px;
  color: ${({ theme }) => theme.color.general.typography.primary};

  // putting 18 instead of 16 for better appearance in Safari
  ${getFontVariationSettings({ width: 46, grad: 0, opticalSize: 18 })}

  ${({ theme }) => useBreakpoint(theme).mobile`
    font-size: ${theme.typography.size.md}px;
    line-height: ${theme.typography.height.md}px;
  `}
`;

export const BodyTextStyles = css<TypographyStyledComponentProps>`
  font-size: ${({ theme }) => theme.typography.size.md}px;
  line-height: ${({ theme }) => theme.typography.height.md}px;

  ${textColorStyle}
  ${marginBottomStyle}
  ${hyperlinkStyle}

  ${getFontVariationSettings({
    width: 100,
    grad: 0,
    xtra: 468,
    // putting 18 instead of 16 for better appearance in Safari
    opticalSize: 18,
  })}

  ${({ theme }) => useBreakpoint(theme).mobile`
    font-size: ${theme.typography.size.sm}px;
    line-height: ${theme.typography.height.sm}px;

    ${getFontVariationSettings({
      width: 46,
      grad: 0,
      opticalSize: 16,
    })}
  `}
`;

export const SmallTextStyles = css<TypographyStyledComponentProps>`
  font-size: ${({ theme }) => theme.typography.size.sm}px;
  line-height: ${({ theme }) => theme.typography.height.sm}px;

  ${textColorStyle}
  ${marginBottomStyle}
  ${hyperlinkStyle}
  ${getFontVariationSettings({ width: 46, opticalSize: 11 })}

  ${({ theme }) => useBreakpoint(theme).mobile`
    font-size: ${theme.typography.size.xs}px;
    line-height: ${theme.typography.height.xs}px;
  `}
`;

export const ExtraSmallTextStyles = css<TypographyStyledComponentProps>`
  font-size: ${({ theme }) => theme.typography.size.xs}px;
  line-height: ${({ theme }) => theme.typography.height.xs}px;

  ${textColorStyle}
  ${marginBottomStyle}
  ${hyperlinkStyle}
  ${getFontVariationSettings({ width: 46, opticalSize: 11 })}

  ${({ theme }) => useBreakpoint(theme).mobile`
    font-size: ${theme.typography.size.xxs}px;
    line-height: ${theme.typography.height.xxs}px;
  `}
`;

export const XXSmallTextStyles = css<TypographyStyledComponentProps>`
  font-size: ${({ theme }) => theme.typography.size.xxs}px;
  line-height: ${({ theme }) => theme.typography.height.xxs}px;

  ${textColorStyle}
  ${marginBottomStyle}
  ${hyperlinkStyle}
  ${getFontVariationSettings({ width: 46, grad: 0, opticalSize: 11 })}
`;

export const BodyTextBoldVariationSettings = `
  ${getFontVariationSettings({
    width: 46,
    grad: 0,
    opticalSize: 18,
    weight: 600,
  })}
`;
export const BodyTextBoldVariationSettingsMobile = `
  ${getFontVariationSettings({
    width: 46,
    grad: 0,
    opticalSize: 16,
    weight: 600,
  })}
`;

export const SmallTextBoldVariationSettings = `
  ${getFontVariationSettings({
    width: 46,
    grad: 0,
    opticalSize: 11,
    weight: 600,
  })}
`;

export const applyFontSizeShrinkCss = (textLength: number, fontSize: number) =>
  textLength > MAX_NORMAL_TEXT_LENGTH &&
  css`
    font-size: ${fontSize * (MAX_NORMAL_TEXT_LENGTH / textLength)}px;
  `;
