import { type OnboardingAgreementType } from './OnboardingAgreements.types';

export const ONBOARDING_AGREEMENTS_TEXT_CONTENT: Record<
  string,
  Record<OnboardingAgreementType, string>
> = {
  title: {
    paying_agent_service_agreement: 'Paying Agent Services Agreement',
    marketplace_agreement: 'Marketplace Agreement',
  },
  get_started: {
    paying_agent_service_agreement:
      'Please sign the attached Paying Agent Services Agreement which covers the Terms and Conditions for the provision of our transaction settlement and money transmission services to you.',
    marketplace_agreement:
      'Please sign the attached MarketPlace Agreement which covers the Terms and Conditions for access to our platform and provision of our products and services to you.',
  },
  read_only: {
    paying_agent_service_agreement:
      'Thank you for completing the Paying Agent Services Agreement. You can access a copy of this agreement at any time.',
    marketplace_agreement:
      'Thank you for completing the Marketplace Agreement. You can access a copy of this agreement at any time.',
  },
};
