import { type CbLabelComponent } from '@npm/utils';
import { capitalize } from '@npm/utils';

import { type Codebook } from '../generated';

import { Codebooks } from './codebooks.types';

const items = {
  buy: 'buy',
  sell: 'sell',
} as const;

export type OrderBuySellCode = keyof typeof items;

export const CbOrderBuySell = {
  code: Codebooks.ORDER_BUY_SELL,
  items,
  getLabel: (
    Component: CbLabelComponent,
    side: Codebook | string,
    showDot = false,
    round = true
  ) => {
    const code = typeof side === 'string' ? side : side?.code;
    const name = typeof side === 'string' ? capitalize(side) : side?.name;

    return Component({
      variant: code === 'buy' ? 'success' : 'error',
      label: name,
      iconProps: showDot ? { name: 'circle', size: 'xxs' } : undefined,
      round,
    });
  },
};
