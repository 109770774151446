import styled from 'styled-components';

import { SmallTextStyles } from '../Typography';

import { FormItem as FormItemBase } from './FormItem';

/** Style for input fields */
export const InputFormItem = styled(FormItemBase).attrs(() => ({
  labelPosition: 'top',
}))`
  /** Label above the input field */
  .ant-form-item-label > label {
    ${SmallTextStyles}
    margin-bottom: ${({ theme }) => theme.spacing.xs}px;

    .ant-form-item-optional {
      color: ${({ theme }) => theme.color.general.typography.secondary};
      text-transform: capitalize;
    }
  }

  /** Help text below input field */
  .ant-form-item-explain,
  .ant-form-item-extra {
    ${SmallTextStyles}
    color: ${({ theme }) => theme.color.general.typography.secondary};
    margin-top: ${({ theme }) => theme.spacing.xs}px;
  } /** Label below the input field in an error box */
  .ant-form-item-explain-error {
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    padding: ${({ theme }) =>
      `${theme.spacing.xs}px ${theme.spacing.lg / 2}px`};
    background-color: ${({ theme }) =>
      theme.color.error.backgrounds.secondaryDefault};
    color: ${({ theme }) => theme.color.error.typography.primary};
    border: 1px solid ${({ theme }) => theme.color.error.borders.secondary};
    font-size: ${({ theme }) => theme.typography.size.xs}px;
    line-height: ${({ theme }) => theme.typography.height.xxs}px;
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.xs / 2}px;
    }
  }
`;
