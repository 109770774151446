import { useWizard } from 'react-use-wizard';

import { Margin } from '../../../../atoms/common';
import { StepperItem } from '../../../../atoms/StepperItem';
import { Text } from '../../../../atoms/Typography/Text';
import { useWizardStore } from '../../Wizard.store';
import { type WizardStore } from '../../Wizard.types';

import { useHandleSidebarItemClick } from './Sidebar.hooks';

import * as S from '../WizardLayout.styles';

type Props = {
  title?: React.ReactNode;
  isStepCompleted?: <T>(ctx: WizardStore<T>, step: number) => boolean;
  handleCloseFormAttempt?: (callback?: () => void) => void;
};

export const Sidebar = ({
  title,
  isStepCompleted,
  handleCloseFormAttempt,
}: Props) => {
  const { activeStep } = useWizard();
  const wizardStore = useWizardStore();
  const handleItemClick = useHandleSidebarItemClick(handleCloseFormAttempt);

  return (
    <S.SidebarSectionCard>
      {title}
      {wizardStore.steps.map(({ key, title, description }, idx) => {
        const isActive = idx === activeStep;

        return (
          <Margin key={key} bottom="sm">
            <StepperItem
              title={title}
              description={description}
              icon={
                isStepCompleted &&
                isStepCompleted(wizardStore, idx) ? undefined : (
                  <Text
                    size="xs"
                    color={isActive ? 'info' : 'general'}
                    weight={isActive ? 'bold' : 'default'}
                  >
                    {idx + 1}
                  </Text>
                )
              }
              onClick={() => handleItemClick(idx)}
              isActive={isActive}
              variant={
                isStepCompleted && isStepCompleted(wizardStore, idx)
                  ? 'done'
                  : 'default'
              }
            />
          </Margin>
        );
      })}
    </S.SidebarSectionCard>
  );
};
