import React from 'react';

import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { Flex } from '../../atoms/common';
import { Icon } from '../../atoms/Icon';

import * as S from './TwoColumnsModalContent.styles';

interface Props {
  contentLeft: React.ReactNode;
  contentRight: React.ReactNode;
  onCancel: () => void;
}

export const TwoColumnsModalContent = ({
  contentLeft,
  contentRight,
  onCancel,
}: Props) => {
  const { isMobile, isTablet } = useBreakpoints();

  return (
    <>
      {isMobile && (
        <S.MobileButton
          icon={<Icon name="chevron-left" />}
          size="lg"
          htmlType="button"
          variant="text"
          onClick={onCancel}
        >
          Back
        </S.MobileButton>
      )}
      <Flex justify="space-between" gap="md">
        {contentLeft}
        {!isTablet && !isMobile && contentRight}
      </Flex>
    </>
  );
};
