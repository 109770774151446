import React, { type ReactNode, useMemo, useState } from 'react';
import { type CheckboxChangeEvent } from 'antd/es/checkbox';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Checkbox } from '@npm/core/ui/components/atoms/Checkbox';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import {
  type CodebookShow,
  CbBeneficialOwnerEntityExemption,
  CbBeneficialOwnerExemption,
  mapCbItemsForSelectOptions,
  useCodebook,
} from '@npm/data-access';

import * as S from './ExemptionQuestion.styles';

type Props = {
  codebookCode:
    | typeof CbBeneficialOwnerExemption.code
    | typeof CbBeneficialOwnerEntityExemption.code;
  heading: string;
  selectLabel: ReactNode;
  checkboxLabel: string;
  onChange?: (value: string) => void;
};

type SelectedTooltipProps = {
  selectedValue: string | null;
  data: CodebookShow[];
};

const OptionAlert = ({ selectedValue, data }: SelectedTooltipProps) => {
  const selectedHelpText = useMemo(() => {
    const selectedCb = data?.find(i => i.code === selectedValue);
    return selectedCb?.attributes?.find(attr => attr.name === 'help').value;
  }, [data, selectedValue]);

  if (selectedHelpText) {
    return (
      <Margin top="md">
        <Alert type="info" message={selectedHelpText} />
      </Margin>
    );
  }

  return null;
};

export const ExemptionQuestion = ({
  codebookCode,
  onChange,
  heading,
  selectLabel,
  checkboxLabel,
}: Props) => {
  const [checkbox, setCheckbox] = useState<boolean>(true);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const { data, isLoading } = useCodebook({ type: codebookCode });

  const options = useMemo(
    () =>
      data?.codebooks
        ? mapCbItemsForSelectOptions(
            data.codebooks.filter(i =>
              codebookCode === 'BENEFICIAL_OWNER_ENTITY_EXEMPTION'
                ? i.code !== CbBeneficialOwnerEntityExemption.items.none
                : i.code !== CbBeneficialOwnerExemption.items.none &&
                  i.code !== CbBeneficialOwnerExemption.items.no_owner
            )
          )
        : [],
    [codebookCode, data]
  );

  const handleSelectChange = (value: string) => {
    onChange?.(value);
    setSelectedValue(value);
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      onChange?.(
        codebookCode === 'BENEFICIAL_OWNER_ENTITY_EXEMPTION'
          ? CbBeneficialOwnerEntityExemption.items.none
          : CbBeneficialOwnerExemption.items.none
      );
      setSelectedValue(null);
      setCheckbox(!e.target.checked);
    } else {
      onChange?.(null);
      setCheckbox(!e.target.checked);
    }
  };

  return (
    <>
      <Heading marginBottom="md" variant="h2">
        {heading}
      </Heading>
      <S.TooltipFormItem label={selectLabel}>
        <S.Select
          showSearch={false}
          value={selectedValue}
          loading={isLoading}
          options={options}
          disabled={!checkbox}
          onChange={handleSelectChange}
        />
      </S.TooltipFormItem>
      <OptionAlert selectedValue={selectedValue} data={data?.codebooks} />
      <Margin top="md">
        <Checkbox
          defaultChecked={false}
          onChange={handleCheckboxChange}
          label={checkboxLabel}
        />
      </Margin>
    </>
  );
};
