import React, {
  type ComponentProps,
  createContext,
  useContext,
  useReducer,
} from 'react';

import { type Alert } from '../../atoms/Alert';

type AlertContainerType = {
  id: string;
  alerts: ComponentProps<typeof Alert>[];
};

type Action =
  | { type: 'ADD_CONTAINER'; containerId: string }
  | { type: 'REMOVE_CONTAINER'; containerId: string }
  | {
      type: 'ADD_ALERT';
      containerId?: string;
      alert: ComponentProps<typeof Alert>;
    }
  | { type: 'REMOVE_ALERT'; containerId: string; index: number }
  | { type: 'CLEAR_ALERTS'; containerId?: string };

interface AlertContainerContext {
  alertContainers: AlertContainerType[];
  dispatch: React.Dispatch<Action>;
}

const alertContainerReducer = (
  alertContainers: AlertContainerType[],
  action: Action
): AlertContainerType[] => {
  switch (action.type) {
    case 'ADD_CONTAINER':
      return [...alertContainers, { id: action.containerId, alerts: [] }];
    case 'REMOVE_CONTAINER':
      return alertContainers.filter(
        container => container.id !== action.containerId
      );
    case 'ADD_ALERT': {
      // add alert to the latest container if action.containerId not provided
      const containerId =
        action.containerId || alertContainers[alertContainers.length - 1]?.id;

      return alertContainers.map(container => {
        if (container.id !== containerId) {
          return container;
        }

        // dont add if same message already exists
        // however create new array so effect (scrollIntoView) is fired
        if (
          container.alerts.some(alert => alert.message === action.alert.message)
        ) {
          return {
            ...container,
            alerts: [...container.alerts],
          };
        }

        return {
          ...container,
          alerts: [...container.alerts, action.alert],
        };
      });
    }
    case 'REMOVE_ALERT': {
      return alertContainers.map(container => {
        if (container.id === action.containerId) {
          return {
            ...container,
            alerts: container.alerts.filter(
              (_, index) => index !== action.index
            ),
          };
        }
        return container;
      });
    }
    case 'CLEAR_ALERTS':
      return alertContainers.map(container =>
        // clear all alerts if containerId not provided
        !action.containerId || container.id === action.containerId
          ? { ...container, alerts: [] }
          : container
      );
    default:
      return alertContainers;
  }
};

export const useAlertsContext = () => {
  const context = useContext(AlertContainerContext);

  if (!context) {
    throw new Error('useAlerts must be used within an AlertProvider');
  }
  return context;
};

export const AlertContainerContext = createContext<
  AlertContainerContext | undefined
>(undefined);

export const AlertContainerProvider: React.FC = ({ children }) => {
  const [alertContainers, dispatch] = useReducer(alertContainerReducer, []);

  return (
    <AlertContainerContext.Provider value={{ alertContainers, dispatch }}>
      {children}
    </AlertContainerContext.Provider>
  );
};
