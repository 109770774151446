/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountsBeneficialOwnershipAssessmentShow } from '../model';
// @ts-ignore
import { AccountsBeneficialOwnershipAssessmentUpdateAggregate } from '../model';
// @ts-ignore
import { AccountsBeneficialOwnershipAssessmentUpdateRequestContract } from '../model';
// @ts-ignore
import { DestroySuccess } from '../model';
/**
 * BeneficialOwnershipAssessmentApi - axios parameter creator
 * @export
 */
export const BeneficialOwnershipAssessmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary reset the beneficial ownership assessment
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBeneficialOwnershipAssessmentDestroy: async (id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountBeneficialOwnershipAssessmentDestroy', 'id', id)
            const localVarPath = changeUrl(`/api/accounts/{id}/beneficial-ownership-assessment`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns beneficial ownership assessment details for an account
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBeneficialOwnershipAssessmentShow: async (id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountBeneficialOwnershipAssessmentShow', 'id', id)
            const localVarPath = changeUrl(`/api/accounts/{id}/beneficial-ownership-assessment`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates the beneficial ownership assessment
         * @param {number} id 
         * @param {AccountsBeneficialOwnershipAssessmentUpdateRequestContract} accountsBeneficialOwnershipAssessmentUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBeneficialOwnershipAssessmentUpdate: async (id: number, accountsBeneficialOwnershipAssessmentUpdateRequestContract: AccountsBeneficialOwnershipAssessmentUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountBeneficialOwnershipAssessmentUpdate', 'id', id)
            // verify required parameter 'accountsBeneficialOwnershipAssessmentUpdateRequestContract' is not null or undefined
            assertParamExists('accountBeneficialOwnershipAssessmentUpdate', 'accountsBeneficialOwnershipAssessmentUpdateRequestContract', accountsBeneficialOwnershipAssessmentUpdateRequestContract)
            const localVarPath = changeUrl(`/api/accounts/{id}/beneficial-ownership-assessment`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountsBeneficialOwnershipAssessmentUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BeneficialOwnershipAssessmentApi - functional programming interface
 * @export
 */
export const BeneficialOwnershipAssessmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BeneficialOwnershipAssessmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary reset the beneficial ownership assessment
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountBeneficialOwnershipAssessmentDestroy(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroySuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountBeneficialOwnershipAssessmentDestroy(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns beneficial ownership assessment details for an account
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountBeneficialOwnershipAssessmentShow(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsBeneficialOwnershipAssessmentShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountBeneficialOwnershipAssessmentShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates the beneficial ownership assessment
         * @param {number} id 
         * @param {AccountsBeneficialOwnershipAssessmentUpdateRequestContract} accountsBeneficialOwnershipAssessmentUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountBeneficialOwnershipAssessmentUpdate(id: number, accountsBeneficialOwnershipAssessmentUpdateRequestContract: AccountsBeneficialOwnershipAssessmentUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsBeneficialOwnershipAssessmentUpdateAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountBeneficialOwnershipAssessmentUpdate(id, accountsBeneficialOwnershipAssessmentUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BeneficialOwnershipAssessmentApi - factory interface
 * @export
 */
export const BeneficialOwnershipAssessmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BeneficialOwnershipAssessmentApiFp(configuration)
    return {
        /**
         * 
         * @summary reset the beneficial ownership assessment
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBeneficialOwnershipAssessmentDestroy(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<DestroySuccess> {
            return localVarFp.accountBeneficialOwnershipAssessmentDestroy(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns beneficial ownership assessment details for an account
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBeneficialOwnershipAssessmentShow(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AccountsBeneficialOwnershipAssessmentShow> {
            return localVarFp.accountBeneficialOwnershipAssessmentShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates the beneficial ownership assessment
         * @param {number} id 
         * @param {AccountsBeneficialOwnershipAssessmentUpdateRequestContract} accountsBeneficialOwnershipAssessmentUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBeneficialOwnershipAssessmentUpdate(id: number, accountsBeneficialOwnershipAssessmentUpdateRequestContract: AccountsBeneficialOwnershipAssessmentUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AccountsBeneficialOwnershipAssessmentUpdateAggregate> {
            return localVarFp.accountBeneficialOwnershipAssessmentUpdate(id, accountsBeneficialOwnershipAssessmentUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for accountBeneficialOwnershipAssessmentDestroy operation in BeneficialOwnershipAssessmentApi.
 * @export
 * @interface BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentDestroyRequest
 */
export interface BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentDestroyRequest {
    /**
     * 
     * @type {number}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentDestroy
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentDestroy
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentDestroy
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentDestroy
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentDestroy
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentDestroy
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentDestroy
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for accountBeneficialOwnershipAssessmentShow operation in BeneficialOwnershipAssessmentApi.
 * @export
 * @interface BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentShowRequest
 */
export interface BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentShowRequest {
    /**
     * 
     * @type {number}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentShow
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for accountBeneficialOwnershipAssessmentUpdate operation in BeneficialOwnershipAssessmentApi.
 * @export
 * @interface BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentUpdateRequest
 */
export interface BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentUpdate
     */
    readonly id: number

    /**
     * 
     * @type {AccountsBeneficialOwnershipAssessmentUpdateRequestContract}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentUpdate
     */
    readonly accountsBeneficialOwnershipAssessmentUpdateRequestContract: AccountsBeneficialOwnershipAssessmentUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * BeneficialOwnershipAssessmentApi - object-oriented interface
 * @export
 * @class BeneficialOwnershipAssessmentApi
 * @extends {BaseAPI}
 */
export class BeneficialOwnershipAssessmentApi extends BaseAPI {
    /**
     * 
     * @summary reset the beneficial ownership assessment
     * @param {BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeneficialOwnershipAssessmentApi
     */
    public accountBeneficialOwnershipAssessmentDestroy(requestParameters: BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentDestroyRequest, options?: AxiosRequestConfig) {
        return BeneficialOwnershipAssessmentApiFp(this.configuration).accountBeneficialOwnershipAssessmentDestroy(requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns beneficial ownership assessment details for an account
     * @param {BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeneficialOwnershipAssessmentApi
     */
    public accountBeneficialOwnershipAssessmentShow(requestParameters: BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentShowRequest, options?: AxiosRequestConfig) {
        return BeneficialOwnershipAssessmentApiFp(this.configuration).accountBeneficialOwnershipAssessmentShow(requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates the beneficial ownership assessment
     * @param {BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeneficialOwnershipAssessmentApi
     */
    public accountBeneficialOwnershipAssessmentUpdate(requestParameters: BeneficialOwnershipAssessmentApiAccountBeneficialOwnershipAssessmentUpdateRequest, options?: AxiosRequestConfig) {
        return BeneficialOwnershipAssessmentApiFp(this.configuration).accountBeneficialOwnershipAssessmentUpdate(requestParameters.id, requestParameters.accountsBeneficialOwnershipAssessmentUpdateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AccountBeneficialOwnershipAssessmentDestroyErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountBeneficialOwnershipAssessmentDestroyErrorCodes = [
];

export type AccountBeneficialOwnershipAssessmentShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountBeneficialOwnershipAssessmentShowErrorCodes = [
    401,
    404,
];

export type AccountBeneficialOwnershipAssessmentUpdateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountBeneficialOwnershipAssessmentUpdateErrorCodes = [
    401,
];


