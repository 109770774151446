import styled, { css } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { useBreakpoint } from '@npm/core/ui/utils/style';

export const PremiumLockButton = styled(Button)<{
  $hovered?: boolean;
  $width?: string;
}>`
  font-size: ${({ theme }) => theme.typography.size.sm}px;
  svg {
    ${({ theme, $hovered }) =>
      !$hovered &&
      css`
        color: ${theme.color.grey_50};
      `}
    
    transition: color 0.3s ease-in-out;

  ${({ theme }) => useBreakpoint(theme).mobile`
    margin: 0;
  `}
`;

export const PremiumLockButtonContent = styled.span<{
  $hovered?: boolean;
}>`
  max-width: ${({ $hovered }) => ($hovered ? '7rem' : '0')};
  transition: max-width 0.3s ease-in-out;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;

  ${({ $hovered }) =>
    $hovered &&
    css`
      transition-delay: 0.1s;
    `}
`;
