import { type AccountType } from '@npm/data-access';

import { getWorkstationFromPathname } from '../auth/user/context';

interface GetWorkstationAccountDetailPathParams {
  pathname: string;
  accountId: number;
  accountType?: AccountType;
  isAccountCentric?: boolean;
  accountCentricId?: number;
}

export const getWorkstationAccountDetailPath = ({
  pathname,
  accountId,
  accountType,
  isAccountCentric = true,
  accountCentricId,
}: GetWorkstationAccountDetailPathParams) => {
  const workstation = getWorkstationFromPathname(pathname);

  const workstationToAccountDetailPathMap = {
    investor:
      isAccountCentric && accountCentricId === accountId
        ? `/investor-workstation/account/detail`
        : `/investor-workstation/accounts/${accountId}`,
    brokerage: `/brokerage-workstation/${
      accountType === 'PersonAccount' ? 'individuals' : 'entities'
    }/${accountId}`,
    issuer: `/issuer-workstation/accounts/${accountId}`,
  };
  return workstation ? workstationToAccountDetailPathMap[workstation] : '/404';
};
