import styled from 'styled-components';

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => theme.spacing.lg / 4}px
    ${({ theme }) => theme.spacing.sm}px;

  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  background: ${({ theme }) => theme.color.general.layout.zero};
`;
