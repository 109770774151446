import { type CardProps } from '../Card';

import * as S from './CardWithBackground.styles';

export type CardWithBackgroundProps = CardProps & {
  src: string;
  size?: string;
  position?: string;
  color?: string;
};

export const CardWithBackground = ({
  src,
  size,
  position,
  color,
  ...rest
}: CardWithBackgroundProps) => {
  return (
    <S.CardWithBackground
      {...rest}
      $size={size}
      $src={src}
      $position={position}
      $color={color}
    />
  );
};
