import { Codebooks } from './codebooks.types';

const Items = {
  ORG_501: 'org_501',
  BANK: 'bank',
  ERISA: 'erisa',
  FAMILY_OFFICE: 'family_office',
  INS_ACC: 'ins_acc',
  SELF_ERISA: 'self_erisa',
  INV_ADV: 'inv_adv',
  PENSION_FUND: 'pension_fund',
  RUR: 'rur',
  INS: 'ins',
  NON_FAM: 'non_fam',
  LIM: 'lim',
  EMPLOYEE: 'employee',
  SAVING_LOAN: 'saving_loan',
  MAS: 'mas',
  PART: 'part',
  INV: 'inv',
  INV_40: 'inv_40',
  SMALL_BUS: 'small_bus',
  RUR_BUS: 'rur_bus',
  CORPORATION: 'corporation',
  OTHER: 'other',
  ENT_ACC: 'ent_acc',
  BUS: 'bus',
} as const;

export const CbOrganizationType = {
  code: Codebooks.ORGANIZATION_TYPE,
  items: Items,
};
