import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useOnClickOutside } from 'usehooks-ts';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import { ReviewTermsModal } from './ReviewTermsModal';

import * as S from './ConfirmDialog.styles';

type Props = {
  setShowConfirm: (show: boolean) => void;
  handleConfirmation: () => void;
  setIsAcceptButtonDisabled?: (isDisabled: boolean) => void;
  label: string;
  offsetBottom?: number;
  reviewTerms: string;
};

export const ConfirmDialog = ({
  setShowConfirm,
  handleConfirmation,
  setIsAcceptButtonDisabled,
  label,
  offsetBottom = 85,
  reviewTerms,
}: Props) => {
  const [showReviewModalTerms, setShowReviewModalTerms] = useState(false);
  const confirmContainerRef = useRef<HTMLDivElement>(null);
  const handleClickOutsideConfirm = () => {
    if (showReviewModalTerms) return;
    setShowConfirm(false);
    setIsAcceptButtonDisabled?.(false);
  };
  useOnClickOutside(confirmContainerRef, handleClickOutsideConfirm);

  return (
    <>
      <S.ConfirmWrapper ref={confirmContainerRef} $offsetBottom={offsetBottom}>
        <motion.div
          initial={{ y: 200, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          layout
        >
          <S.StyledCard>
            <Flex gap="sm">
              <Text size="sm">{label}</Text>
            </Flex>

            <Margin top="md">
              <Flex gap="sm">
                <Button size="md" onClick={handleConfirmation}>
                  I Confirm
                </Button>
                <Button
                  size="md"
                  onClick={() => setShowReviewModalTerms(true)}
                  variant="text"
                >
                  Review Terms
                </Button>
              </Flex>
            </Margin>
          </S.StyledCard>
        </motion.div>
      </S.ConfirmWrapper>
      <ReviewTermsModal
        isOpen={showReviewModalTerms}
        message={reviewTerms}
        onSubmit={() => setShowReviewModalTerms(false)}
      />
    </>
  );
};
