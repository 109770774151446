import { ValueVisibilityToggler } from '@npm/core/ui/components/atoms/ValueVisibilityToggler';

import { type Props } from './TaxIdReadOnly';

export const prepareReadonlyData = (
  taxId: Props['taxId'],
  isIndividual: boolean,
  exempt?: Props['exempt']
) => {
  return [
    {
      label: isIndividual
        ? 'U.S. Tax ID'
        : 'U.S. EIN (Employer Identification Number)',
      value: exempt ? (
        'Exempt'
      ) : (
        <ValueVisibilityToggler
          value={taxId}
          mask="X"
          visibleCharsNumber={4}
          maskAnchor="start"
        />
      ),
    },
  ];
};
