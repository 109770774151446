import { type ForwardRefExoticComponent } from 'react';
import { type SwitchProps, Switch as AntSwitch } from 'antd';
import styled from 'styled-components';

import { centerFlex, getFocusBorderStyles } from '../../../utils/style';
import { ExtraSmallTextStyles } from '../Typography';

const SWITCH_HANDLE_SIZE = 18;
const SWITCH_HEIGHT = 22;

export const Container = styled.div`
  display: flex;
  & > *:last-child {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
`;

// [Antd Typings] Ant'd internal types are not exported, need to re-type.
export const Switch = styled<ForwardRefExoticComponent<SwitchProps>>(AntSwitch)`
  ${ExtraSmallTextStyles}
  height: ${SWITCH_HEIGHT}px;
  color: ${({ theme }) => theme.color.grey_0};
  background-image: none; // disable bg image for background-color to show
  background-color: ${({ theme }) => theme.color.general.backgrounds.primary};

  .ant-switch-handle {
    width: ${SWITCH_HANDLE_SIZE}px;
    height: ${SWITCH_HANDLE_SIZE}px;

    &::before {
      border-radius: 50%;
    }
  }

  .ant-switch-inner {
    ${centerFlex}
    > svg {
      color: ${({ theme }) => theme.color.grey_0} !important;
    }
  }

  body.tab-focus ${getFocusBorderStyles()};

  &.ant-switch-checked {
    background-color: ${({ theme }) => theme.color.blue_60};

    .ant-switch-handle {
      left: calc(100% - ${SWITCH_HANDLE_SIZE}px - 2px);
    }
  }
`;
