import { type Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { type ColumnType } from '@npm/core/ui/components/molecules/Table';
import { type Signer } from '@npm/data-access';

// visible on all resolutions except
export const MAIN_COLUMN_BREAKPOINTS: Breakpoint[] = [
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  'xxl',
];

// visible only on large resolutions, then hidden and visible only in expandable area
export const SECONDARY_COLUMN_BREAKPOINTS: Breakpoint[] = ['lg'];

export const getSignerColumns = (): ColumnType<Signer>[] => [
  {
    key: 'first',
    title: 'First Name',
    responsive: MAIN_COLUMN_BREAKPOINTS,
    render: (_, { first }) => first,
  },
  {
    key: 'middle',
    title: 'Middle Name',
    responsive: SECONDARY_COLUMN_BREAKPOINTS,
    render: (_, { middle }) => middle || '--',
  },
  {
    key: 'last',
    title: 'Last Name',
    responsive: MAIN_COLUMN_BREAKPOINTS,
    render: (_, { last }) => last,
  },
  {
    key: 'email',
    title: 'Email',
    responsive: SECONDARY_COLUMN_BREAKPOINTS,
    render: (_, { email }) => email,
  },
];
