import React from 'react';
import { useForm } from 'antd/es/form/Form';

import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { useConfirmOnLeave } from '@npm/core/ui/components/molecules/Link';
import { DiscardModal } from '@npm/core/ui/components/organisms/DiscardModal';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { WatchlistDataIds } from '@npm/core/ui/constants';
import { handleValidationError } from '@npm/core/ui/utils/form';
import {
  type WatchlistUpdateRequestContract,
  useWatchlistShow,
  useWatchlistUpdate,
} from '@npm/data-access';

import {
  type FormValues,
  UpsertWatchlistForm,
} from './Form/UpsertWatchlistForm';

type Props = {
  open: boolean;
  onClose: () => void;
  issuerEntityId: number;
  accountId: number;
};

export const EditWatchlistDrawer = ({
  open,
  onClose,
  issuerEntityId,
  accountId,
}: Props) => {
  const [form] = useForm<FormValues>();
  const { withShowApiErrorMiddleware } = useAlerts();
  const { setHasUnsavedChanges, onCloseAttempt, discardModalProps } =
    useConfirmOnLeave();

  const { execute, isLoading } = useWatchlistUpdate();
  const { data: watchlist } = useWatchlistShow(
    {
      issuerEntityId,
      watchlistAccountId: accountId,
      includeField: ['account', 'issuer_entity', 'note'],
    },
    { queryConfig: { enabled: !!issuerEntityId } }
  );

  const handleCloseConfirm = () => {
    form.resetFields();
    setHasUnsavedChanges(false);
    onClose();
  };

  const handleSubmit = async (values: WatchlistUpdateRequestContract) => {
    try {
      await form.validateFields();
    } catch (err) {
      handleValidationError(form, err);
      return;
    }

    try {
      await withShowApiErrorMiddleware(execute)(
        {
          issuerEntityId,
          watchlistUpdateRequestContract: {
            watchlist_account_id: accountId,
            ...values,
          },
        },
        { nullValuesHandling: 'KEEP_ALL' }
      );
      handleCloseConfirm();
      Notification.success({
        message: 'Watchlist was successfully updated.',
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Drawer
        data-cy={WatchlistDataIds.Drawer.EditDrawer}
        open={open}
        title={'Edit Watchlist'}
        buttonText={'Edit'}
        buttonLoading={isLoading}
        isDisabled={isLoading}
        onClose={() => onCloseAttempt(handleCloseConfirm)}
        onSubmit={form.submit}
        destroyOnClose
      >
        <UpsertWatchlistForm
          form={form}
          onFinish={handleSubmit}
          onValuesChange={() => setHasUnsavedChanges(true)}
          watchlist={watchlist}
        />
      </Drawer>
      <DiscardModal {...discardModalProps} />
    </>
  );
};
