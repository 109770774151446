import { type DefaultTheme } from 'styled-components';

export const breakpoints = {
  mobile: 575,
  smTablet: 767,
  tablet: 991,
  smDesktop: 1199,
  desktop: 1599,
};

export const commonThemeConfig: Pick<
  DefaultTheme,
  'breakpoint' | 'spacing' | 'typography' | 'borderRadius' | 'zIndex' | 'icon'
> = {
  /*
   * There are 3 main breakpoints:
   * Up to 575px is mobile
   * Up to 991px is tablet
   * 992px and above is desktop
   *
   * In most cases, using only main breakpoints should be sufficient
   * Other breakpoints should be used only if some extra fine-tuning needed
   *
   * Values of the breakpoints reflect ANTD breakpoints ('xs', ...)
   *
   * */ breakpoint: {
    mobile: breakpoints.mobile + 'px', // 'xs' (up to 575px is mobile and 'xs')
    smTablet: breakpoints.smTablet + 'px', // 'sm'
    tablet: breakpoints.tablet + 'px', // 'md'
    smDesktop: breakpoints.smDesktop + 'px', // 'lg'
    desktop: breakpoints.desktop + 'px', // 'xl'
    // above: 'xxl'
  },
  spacing: {
    xs: 4,
    sm: 8,
    md: 16,
    lg: 24,
    xl: 32,
    xxl: 40,
    xxxl: 64,
  },
  borderRadius: {
    sm: 4,
    md: 8,
    lg: 16,
  },
  zIndex: {
    base: 0,
    dropdown: 10,
    fixed: 20,
    drawer: 30,
    aboveDrawer: 40,
    aboveAll: 50,
  },
  typography: {
    fontFamily: 'Roboto Flex',
    size: {
      xxs: 10,
      xs: 12,
      sm: 14,
      md: 16,
      lg: 18,
      xl: 20,
      xxl: 24,
      xxxl: 32,
    },
    height: {
      xxs: 14,
      xs: 16,
      sm: 20,
      md: 22,
      lg: 24,
      xl: 26,
      xxl: 30,
      xxxl: 42,
    },
    fontWeight: {
      regular: 400,
      medium: 500,
      semiBold: 600,
    },
  },
  icon: {
    size: {
      xxs: 12,
      xs: 16,
      sm: 20,
      md: 24,
      lg: 32,
      xl: 40,
      xxl: 48,
    },
    strokeWidth: {
      md: 1.5,
      lg: 2,
    },
  },
};
export const commonColors = {
  transparent: 'transparent',
  // Grey
  grey_100: '#091C28',
  grey_90: '#0F222F',
  grey_80: '#182E3D',
  grey_70: '#1D384A',
  grey_60: '#4B6579',
  grey_50: '#7B8FA3',
  grey_40: '#BBCBD9',
  grey_30: '#E0EAF2',
  grey_20: '#F0F6FA',
  grey_10: '#F8FBFD',
  grey_0: '#FFFFFF',
  // Blue
  blue_100: '#00162D',
  blue_90: '#052D4E',
  blue_80: '#024176',
  blue_70: '#0066BD',
  blue_60: '#0177DA',
  blue_50: '#3799EB',
  blue_40: '#77B0FF',
  blue_30: '#B8D6FF',
  blue_20: '#D1E6FF',
  blue_10: '#EFF4FF',
  // Green
  green_100: '#071517',
  green_90: '#112E2F',
  green_80: '#174B44',
  green_70: '#017757',
  green_60: '#25966D',
  green_50: '#44BA81',
  green_40: '#5FEFA5',
  green_30: '#91F7C2',
  green_20: '#B0FCD5',
  green_10: '#E9FFF3',
  // Red
  red_100: '#150D12',
  red_90: '#361C25',
  red_80: '#772134',
  red_70: '#BC2A3C',
  red_60: '#D4394C',
  red_50: '#FF5B63',
  red_40: '#FF989D',
  red_30: '#FFBBBF',
  red_20: '#FFD7D9',
  red_10: '#FFF5F5',
  // Yellow
  yellow_100: '#14150F',
  yellow_90: '#282516',
  yellow_80: '#48380C',
  yellow_70: '#714D07',
  yellow_60: '#9C6700',
  yellow_50: '#D99000',
  yellow_40: '#F4AE26',
  yellow_30: '#FFC555',
  yellow_20: '#FFE4AF',
  yellow_10: '#FFF6E5',
  // Orange
  orange_100: '#15120E',
  orange_90: '#302416',
  orange_80: '#54310D',
  orange_70: '#864204',
  orange_60: '#C25D00',
  orange_50: '#F87B07',
  orange_40: '#FFA049',
  orange_30: '#FFC28A',
  orange_20: '#FFDDBE',
  orange_10: '#FEF9F4',
};
export const lightTheme: DefaultTheme = {
  color: {
    ...commonColors,
    // grey colors
    general: {
      typography: {
        primary: commonColors.grey_100,
        secondary: commonColors.grey_60,
        tertiary: commonColors.grey_50,
        muted: commonColors.grey_40,
        negative: commonColors.grey_0,
      },
      backgrounds: {
        primary: commonColors.grey_50,
        muted: commonColors.grey_30,
      },
      layout: {
        zero: commonColors.grey_20,
        one: commonColors.grey_0,
        two: commonColors.grey_10,
        three: commonColors.grey_90,
      },
      borders: {
        primary: commonColors.grey_30,
        secondary: commonColors.grey_40,
      },
      overlay: {
        hard: 'rgba(241, 249, 255, 0.9)',
        soft: 'rgba(241, 249, 255, 0.5)',
      },
      skeleton: {
        primary: commonColors.grey_20,
        secondary: commonColors.grey_10,
      },
    },
    // blue colors
    info: {
      typography: {
        primary: commonColors.blue_70,
      },
      backgrounds: {
        primaryDefault: commonColors.blue_60,
        primaryHover: commonColors.blue_50,
        secondaryDefault: commonColors.blue_10,
        secondaryHover: commonColors.blue_20,
      },
      borders: {
        primary: commonColors.blue_70,
        secondary: commonColors.blue_20,
      },
    },
    // green colors
    success: {
      typography: {
        primary: commonColors.green_70,
      },
      backgrounds: {
        primaryDefault: commonColors.green_60,
        primaryHover: commonColors.green_50,
        secondaryDefault: commonColors.green_10,
        secondaryHover: commonColors.green_20,
      },
      borders: {
        primary: commonColors.green_60,
        secondary: commonColors.green_20,
      },
    },
    // orange colors
    warning: {
      typography: {
        primary: commonColors.orange_60,
      },
      backgrounds: {
        primaryDefault: commonColors.orange_50,
        primaryHover: commonColors.orange_40,
        secondaryDefault: commonColors.orange_10,
        secondaryHover: commonColors.orange_20,
      },
      borders: {
        primary: commonColors.orange_60,
        secondary: commonColors.orange_20,
      },
    },
    // red colors
    error: {
      typography: {
        primary: commonColors.red_60,
      },
      backgrounds: {
        primaryDefault: commonColors.red_60,
        primaryHover: commonColors.red_50,
        secondaryDefault: commonColors.red_10,
        secondaryHover: commonColors.red_20,
      },
      borders: {
        primary: commonColors.red_70,
        secondary: commonColors.red_20,
      },
    },
    component: {
      tab: {
        wrapGradient:
          'linear-gradient(270deg, rgba(91, 104, 135, 0.10) 0%, rgba(91, 104, 135, 0.00) 100%)',
      },
      button: {
        typography: {
          disabled: commonColors.grey_0,
        },
      },
      icon: {
        backgrounds: {
          circle: commonColors.blue_20,
        },
      },
    },
  },
  // shadows
  shadow: {
    level1: '0px 1px 6px -2px rgba(91, 104, 135, 0.1)',
    level2: '0px 3px 12px -2px rgba(91, 104, 135, 0.15)',
    level3: '0px 6px 18px -2px rgba(91, 104, 135, 0.2)',
  },
  ...commonThemeConfig,
};
export const darkTheme: DefaultTheme = {
  color: {
    ...commonColors,
    // grey colors
    general: {
      typography: {
        primary: commonColors.grey_0,
        secondary: commonColors.grey_40,
        tertiary: commonColors.grey_50,
        muted: commonColors.grey_60,
        negative: commonColors.grey_100,
      },
      backgrounds: {
        primary: commonColors.grey_60,
        muted: commonColors.grey_70,
      },
      layout: {
        zero: commonColors.grey_100,
        one: commonColors.grey_90,
        two: commonColors.grey_80,
        three: commonColors.grey_0,
      },
      borders: {
        primary: commonColors.grey_70,
        secondary: commonColors.grey_60,
      },
      overlay: {
        hard: 'rgba(5, 25, 37, 0.9)',
        soft: 'rgba(5, 25, 37, 0.5)',
      },
      skeleton: {
        primary: commonColors.grey_100,
        secondary: commonColors.grey_80,
      },
    },
    // blue colors
    info: {
      typography: {
        primary: commonColors.blue_40,
      },
      backgrounds: {
        primaryDefault: commonColors.blue_60,
        primaryHover: commonColors.blue_50,
        secondaryDefault: commonColors.blue_100,
        secondaryHover: commonColors.blue_90,
      },
      borders: {
        primary: commonColors.blue_40,
        secondary: commonColors.blue_80,
      },
    },
    // green colors
    success: {
      typography: {
        primary: commonColors.green_40,
      },
      backgrounds: {
        primaryDefault: commonColors.green_70,
        primaryHover: commonColors.green_60,
        secondaryDefault: commonColors.green_100,
        secondaryHover: commonColors.green_90,
      },
      borders: {
        primary: commonColors.green_40,
        secondary: commonColors.green_80,
      },
    },
    // orange colors
    warning: {
      typography: {
        primary: commonColors.orange_50,
      },
      backgrounds: {
        primaryDefault: commonColors.orange_60,
        primaryHover: commonColors.orange_50,
        secondaryDefault: commonColors.orange_100,
        secondaryHover: commonColors.orange_90,
      },
      borders: {
        primary: commonColors.orange_50,
        secondary: commonColors.orange_80,
      },
    },
    // red colors
    error: {
      typography: {
        primary: commonColors.red_50,
      },
      backgrounds: {
        primaryDefault: commonColors.red_60,
        primaryHover: commonColors.red_50,
        secondaryDefault: commonColors.red_100,
        secondaryHover: commonColors.red_90,
      },
      borders: {
        primary: commonColors.red_50,
        secondary: commonColors.red_80,
      },
    },
    component: {
      tab: {
        wrapGradient:
          'linear-gradient(270deg, rgba(6, 17, 24, 0.60) 0%, rgba(6, 17, 24, 0.00) 100%)',
      },
      button: {
        typography: {
          disabled: commonColors.grey_50,
        },
      },
      icon: {
        backgrounds: {
          circle: commonColors.blue_80,
        },
      },
    },
  },
  // shadows
  shadow: {
    level1: '0px 1px 6px -2px rgba(6, 17, 24, 0.4)',
    level2: '0px 3px 12px -2px rgba(6, 17, 24, 0.6)',
    level3: '0px 6px 18px -2px rgba(6, 17, 24, 0.8)',
  },
  ...commonThemeConfig,
};
