import { forwardRef } from 'react';
import { type RadioProps as AntRadioProps, Radio as AntRadio } from 'antd';

import { StyledRadio } from './Radio.styles';

export interface RadioProps extends AntRadioProps {
  label: string | React.ReactNode;
}

export const Radio = forwardRef<HTMLElement, RadioProps>(function Radio(
  { children, ...props },
  ref
) {
  return (
    <StyledRadio {...props} ref={ref}>
      {children ?? props.label}
    </StyledRadio>
  );
});

export const RadioGroup = AntRadio.Group;
