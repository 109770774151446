import React from 'react';

import { Steps } from '@npm/core/ui/components/atoms/Steps';
import {
  type NegotiationIndexItemAggregate,
  CbOrderBuySell,
} from '@npm/data-access';

import { getOpportunityState } from '../../../utils/getOpportunityState';

import { getStepsItems } from './TransactionPhase.utils';

type Props = {
  negotiation: NegotiationIndexItemAggregate;
  isRofrBlocked?: boolean;
};

export const TransactionPhase = ({ negotiation, isRofrBlocked }: Props) => {
  const opportunityState = getOpportunityState(
    negotiation,
    negotiation?.match_state,
    negotiation?.has_transaction_confirmation_docs,
    isRofrBlocked
  );
  const side = negotiation?.investor
    ? CbOrderBuySell.items.buy
    : CbOrderBuySell.items.sell;

  return <Steps items={getStepsItems(opportunityState, side)} />;
};
