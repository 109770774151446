import React, { type FC, useMemo, useState } from 'react';
import { Radio } from 'antd';
import { sanitize } from 'dompurify';
import { sortBy } from 'lodash';

import { VALIDATION_RULES } from '@npm/core/ui/components/atoms/FormItem';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { WysiwygContent } from '@npm/core/ui/components/atoms/WysiwygContent';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import {
  type CodebookApiCodebookRequest,
  type CodebookItem,
  CbAccreditationLabel,
  useCodebook,
} from '@npm/data-access';

import * as S from './LabelItem.styles';

type Props = {
  label: CodebookItem;
  onChange?: () => void;
  variant?: 'select' | 'radio';
  showDropdownBelowInput?: boolean;
};

export const LabelItem: FC<Props> = ({
  label,
  onChange,
  variant = 'select',
  showDropdownBelowInput,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getAttributeValueByName } = CbAccreditationLabel;

  const answerSetCode = getAttributeValueByName(label, 'answer_set');
  const { data: answers } = useCodebook({
    type: answerSetCode?.toUpperCase() as CodebookApiCodebookRequest['type'],
  });
  const helpLink = getAttributeValueByName(label, 'help_link');
  const help = getAttributeValueByName(label, 'help');

  const sortedAndFilteredAnswers = useMemo(() => {
    const filteredAnswers = answers?.codebooks?.filter(
      a =>
        getAttributeValueByName(a, 'disable') !== 'true' &&
        getAttributeValueByName(a, 'active') !== 'false'
    );

    return answerSetCode === 'yes_or_no'
      ? sortBy(filteredAnswers, item => item.code !== 'yes')
      : filteredAnswers;
  }, [answerSetCode, answers]);

  if (!answers) return null;

  return (
    <>
      <S.StyledFormItem
        labelPosition="top"
        label={label.name}
        name={label.code}
        rules={[VALIDATION_RULES.required()]}
        extra={
          helpLink ? (
            <Text size="sm" color="info" onClick={() => setIsModalOpen(true)}>
              {helpLink}
            </Text>
          ) : null
        }
        $showRadioGroup={variant === 'radio'}
        $showItemsInRow={answerSetCode === 'yes_or_no' && variant === 'radio'}
      >
        {variant === 'select' ? (
          <S.StyledSelect
            showDropdownBelowInput={showDropdownBelowInput}
            options={sortedAndFilteredAnswers.map(a => {
              return {
                value: JSON.stringify({
                  field: label,
                  answer: a,
                }),
                label: a.name,
              };
            })}
            onChange={onChange}
          />
        ) : (
          <Radio.Group onChange={onChange}>
            {sortedAndFilteredAnswers.map(a => (
              <S.StyledButtonCard
                key={a.code}
                variant="radio"
                value={JSON.stringify({ field: label, answer: a })}
                label={a.name}
                showDefaultInput
              />
            ))}
          </Radio.Group>
        )}
      </S.StyledFormItem>
      {helpLink && (
        <Modal
          title={helpLink}
          okText="Understood"
          open={isModalOpen}
          onOk={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
        >
          <WysiwygContent
            dangerouslySetInnerHTML={{
              __html: sanitize(help, { RETURN_TRUSTED_TYPE: true }),
            }}
          />
        </Modal>
      )}
    </>
  );
};
