import axios, { type AxiosInstance } from 'axios';

import { APP_CFG_API_URL } from '@npm/config';

const instance = axios.create({
  baseURL: APP_CFG_API_URL,
});

export const getAxiosInstance = () => {
  return instance;
};

export const changeUrl = (url: string) => {
  return url.replace('/api/', '/api/venus-service/');
};

export const initErrorHandling = (axios: AxiosInstance) => {
  axios.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      return Promise.reject({
        status: error?.response?.status,
        data: error?.response?.data || error,
      });
    }
  );
};
