import React, { useState } from 'react';

import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  type RegistrationCreateRequestContract,
  useRegistrationCreate,
} from '@npm/data-access';
import { oktaAuth } from '@npm/okta';

import { NpmLogo } from './NpmLogo';
import { OktaForm } from './OktaForm';
import { SuccessMessage } from './SuccessMessage';

import * as S from './OktaSignUp.styles';

export const OktaSignUp = () => {
  const [success, setSuccess] = useState(false);

  const { withShowApiErrorMiddleware } = useAlerts();

  const { execute, isLoading } = useRegistrationCreate();

  const signInRedirect = async () => {
    await oktaAuth.signOut({
      revokeRefreshToken: true,
      revokeAccessToken: true,
      clearTokensBeforeRedirect: true,
    });
  };

  const onSubmit = async (values: RegistrationCreateRequestContract) => {
    try {
      await withShowApiErrorMiddleware(execute)({
        registrationCreateRequestContract: {
          ...values,
        },
      });
    } catch (e) {
      setSuccess(false);
      return;
    }
    setSuccess(true);
  };

  return (
    <S.Container>
      <S.Content>
        <S.LogoContainer>
          <NpmLogo />
        </S.LogoContainer>

        <S.MainPadding>
          {success ? (
            <SuccessMessage onBack={signInRedirect} />
          ) : (
            <OktaForm
              isLoading={isLoading}
              onSubmit={onSubmit}
              onSignIn={signInRedirect}
            />
          )}
        </S.MainPadding>
      </S.Content>
    </S.Container>
  );
};
