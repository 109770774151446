/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  IssuerEntityApi,
  AccountIssuerEntityIndexErrorCodes,
  AccountIssuerEntityIndexErrorTypes,
  IssuerEntityFiltersIndexErrorCodes,
  IssuerEntityFiltersIndexErrorTypes,
  IssuerEntityIndexErrorCodes,
  IssuerEntityIndexErrorTypes,
  IssuerEntityShowErrorCodes,
  IssuerEntityShowErrorTypes,
  PublicIssuerEntityIndexErrorCodes,
  PublicIssuerEntityIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new IssuerEntityApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountIssuerEntityIndexMethod = IssuerEntityApi['accountIssuerEntityIndex'];
export type AccountIssuerEntityIndexResponseType = MethodResult<AccountIssuerEntityIndexMethod>;

export const useAccountIssuerEntityIndex = (
  variables: Parameters<AccountIssuerEntityIndexMethod>[0],
  config?: UseQueryConfig<
    AccountIssuerEntityIndexMethod,
    AccountIssuerEntityIndexErrorTypes
  >
) => {
  return useQuery<
    AccountIssuerEntityIndexMethod,
    AccountIssuerEntityIndexErrorTypes
  >(
    (...args) => api.accountIssuerEntityIndex(...args),
    AccountIssuerEntityIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountIssuerEntityIndex'],
        ...apiQueryConfig.AccountIssuerEntityIndex,
        ...config?.queryConfig
      },
    },
    'AccountIssuerEntityIndex',
  );
};

export const useAccountIssuerEntityIndexInfinite = (
  variables: Parameters<AccountIssuerEntityIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountIssuerEntityIndexMethod,
    AccountIssuerEntityIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountIssuerEntityIndexMethod,
    AccountIssuerEntityIndexErrorTypes
  >(
    (...args) => api.accountIssuerEntityIndex(...args),
    AccountIssuerEntityIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountIssuerEntityIndex', 'AccountIssuerEntityIndexInfinite'],
        ...apiQueryConfig.AccountIssuerEntityIndex,
        ...config?.queryConfig,
      },
    },
    'AccountIssuerEntityIndex',
  );
};

export const useAccountIssuerEntityIndexLazy = (baseOptions?: {
  variables?: Parameters<AccountIssuerEntityIndexMethod>[0],
  config?: UseQueryConfig<
    AccountIssuerEntityIndexMethod,
    AccountIssuerEntityIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountIssuerEntityIndexMethod,
    AccountIssuerEntityIndexErrorTypes
  >(
    (...args) => api.accountIssuerEntityIndex(...args),
    AccountIssuerEntityIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountIssuerEntityIndex'],
        ...apiQueryConfig.AccountIssuerEntityIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountIssuerEntityIndex',
  );
};


export type IssuerEntityFiltersIndexMethod = IssuerEntityApi['issuerEntityFiltersIndex'];
export type IssuerEntityFiltersIndexResponseType = MethodResult<IssuerEntityFiltersIndexMethod>;

export const useIssuerEntityFiltersIndex = (
  variables: Parameters<IssuerEntityFiltersIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityFiltersIndexMethod,
    IssuerEntityFiltersIndexErrorTypes
  >
) => {
  return useQuery<
    IssuerEntityFiltersIndexMethod,
    IssuerEntityFiltersIndexErrorTypes
  >(
    (...args) => api.issuerEntityFiltersIndex(...args),
    IssuerEntityFiltersIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityFiltersIndex'],
        ...apiQueryConfig.IssuerEntityFiltersIndex,
        ...config?.queryConfig
      },
    },
    'IssuerEntityFiltersIndex',
  );
};

export const useIssuerEntityFiltersIndexInfinite = (
  variables: Parameters<IssuerEntityFiltersIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    IssuerEntityFiltersIndexMethod,
    IssuerEntityFiltersIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    IssuerEntityFiltersIndexMethod,
    IssuerEntityFiltersIndexErrorTypes
  >(
    (...args) => api.issuerEntityFiltersIndex(...args),
    IssuerEntityFiltersIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityFiltersIndex', 'IssuerEntityFiltersIndexInfinite'],
        ...apiQueryConfig.IssuerEntityFiltersIndex,
        ...config?.queryConfig,
      },
    },
    'IssuerEntityFiltersIndex',
  );
};

export const useIssuerEntityFiltersIndexLazy = (baseOptions?: {
  variables?: Parameters<IssuerEntityFiltersIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityFiltersIndexMethod,
    IssuerEntityFiltersIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    IssuerEntityFiltersIndexMethod,
    IssuerEntityFiltersIndexErrorTypes
  >(
    (...args) => api.issuerEntityFiltersIndex(...args),
    IssuerEntityFiltersIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IssuerEntityFiltersIndex'],
        ...apiQueryConfig.IssuerEntityFiltersIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IssuerEntityFiltersIndex',
  );
};


export type IssuerEntityIndexMethod = IssuerEntityApi['issuerEntityIndex'];
export type IssuerEntityIndexResponseType = MethodResult<IssuerEntityIndexMethod>;

export const useIssuerEntityIndex = (
  variables: Parameters<IssuerEntityIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityIndexMethod,
    IssuerEntityIndexErrorTypes
  >
) => {
  return useQuery<
    IssuerEntityIndexMethod,
    IssuerEntityIndexErrorTypes
  >(
    (...args) => api.issuerEntityIndex(...args),
    IssuerEntityIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityIndex'],
        ...apiQueryConfig.IssuerEntityIndex,
        ...config?.queryConfig
      },
    },
    'IssuerEntityIndex',
  );
};

export const useIssuerEntityIndexInfinite = (
  variables: Parameters<IssuerEntityIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    IssuerEntityIndexMethod,
    IssuerEntityIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    IssuerEntityIndexMethod,
    IssuerEntityIndexErrorTypes
  >(
    (...args) => api.issuerEntityIndex(...args),
    IssuerEntityIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityIndex', 'IssuerEntityIndexInfinite'],
        ...apiQueryConfig.IssuerEntityIndex,
        ...config?.queryConfig,
      },
    },
    'IssuerEntityIndex',
  );
};

export const useIssuerEntityIndexLazy = (baseOptions?: {
  variables?: Parameters<IssuerEntityIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityIndexMethod,
    IssuerEntityIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    IssuerEntityIndexMethod,
    IssuerEntityIndexErrorTypes
  >(
    (...args) => api.issuerEntityIndex(...args),
    IssuerEntityIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IssuerEntityIndex'],
        ...apiQueryConfig.IssuerEntityIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IssuerEntityIndex',
  );
};


export type IssuerEntityShowMethod = IssuerEntityApi['issuerEntityShow'];
export type IssuerEntityShowResponseType = MethodResult<IssuerEntityShowMethod>;

export const useIssuerEntityShow = (
  variables: Parameters<IssuerEntityShowMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityShowMethod,
    IssuerEntityShowErrorTypes
  >
) => {
  return useQuery<
    IssuerEntityShowMethod,
    IssuerEntityShowErrorTypes
  >(
    (...args) => api.issuerEntityShow(...args),
    IssuerEntityShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityShow'],
        ...apiQueryConfig.IssuerEntityShow,
        ...config?.queryConfig
      },
    },
    'IssuerEntityShow',
  );
};

export const useIssuerEntityShowInfinite = (
  variables: Parameters<IssuerEntityShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    IssuerEntityShowMethod,
    IssuerEntityShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    IssuerEntityShowMethod,
    IssuerEntityShowErrorTypes
  >(
    (...args) => api.issuerEntityShow(...args),
    IssuerEntityShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityShow', 'IssuerEntityShowInfinite'],
        ...apiQueryConfig.IssuerEntityShow,
        ...config?.queryConfig,
      },
    },
    'IssuerEntityShow',
  );
};

export const useIssuerEntityShowLazy = (baseOptions?: {
  variables?: Parameters<IssuerEntityShowMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityShowMethod,
    IssuerEntityShowErrorTypes
  >
}) => {
  return useLazyQuery<
    IssuerEntityShowMethod,
    IssuerEntityShowErrorTypes
  >(
    (...args) => api.issuerEntityShow(...args),
    IssuerEntityShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IssuerEntityShow'],
        ...apiQueryConfig.IssuerEntityShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IssuerEntityShow',
  );
};


export type PublicIssuerEntityIndexMethod = IssuerEntityApi['publicIssuerEntityIndex'];
export type PublicIssuerEntityIndexResponseType = MethodResult<PublicIssuerEntityIndexMethod>;

export const usePublicIssuerEntityIndex = (
  variables: Parameters<PublicIssuerEntityIndexMethod>[0],
  config?: UseQueryConfig<
    PublicIssuerEntityIndexMethod,
    PublicIssuerEntityIndexErrorTypes
  >
) => {
  return useQuery<
    PublicIssuerEntityIndexMethod,
    PublicIssuerEntityIndexErrorTypes
  >(
    (...args) => api.publicIssuerEntityIndex(...args),
    PublicIssuerEntityIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PublicIssuerEntityIndex'],
        ...apiQueryConfig.PublicIssuerEntityIndex,
        ...config?.queryConfig
      },
    },
    'PublicIssuerEntityIndex',
  );
};

export const usePublicIssuerEntityIndexInfinite = (
  variables: Parameters<PublicIssuerEntityIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    PublicIssuerEntityIndexMethod,
    PublicIssuerEntityIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    PublicIssuerEntityIndexMethod,
    PublicIssuerEntityIndexErrorTypes
  >(
    (...args) => api.publicIssuerEntityIndex(...args),
    PublicIssuerEntityIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PublicIssuerEntityIndex', 'PublicIssuerEntityIndexInfinite'],
        ...apiQueryConfig.PublicIssuerEntityIndex,
        ...config?.queryConfig,
      },
    },
    'PublicIssuerEntityIndex',
  );
};

export const usePublicIssuerEntityIndexLazy = (baseOptions?: {
  variables?: Parameters<PublicIssuerEntityIndexMethod>[0],
  config?: UseQueryConfig<
    PublicIssuerEntityIndexMethod,
    PublicIssuerEntityIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    PublicIssuerEntityIndexMethod,
    PublicIssuerEntityIndexErrorTypes
  >(
    (...args) => api.publicIssuerEntityIndex(...args),
    PublicIssuerEntityIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['PublicIssuerEntityIndex'],
        ...apiQueryConfig.PublicIssuerEntityIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'PublicIssuerEntityIndex',
  );
};

