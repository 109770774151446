import styled from 'styled-components';

export const NoDataContainer = styled.div.attrs({
  className: 'ant-select-item',
})`
  display: flex;
  cursor: pointer;
  padding: 0 !important;
  svg {
    color: ${({ theme }) => theme.color.warning.typography.primary};
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }
`;
