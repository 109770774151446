import { type FormInstance } from 'antd';
import { type FormProps } from 'antd/es/form/Form';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { CbBeneficialOwnerEntityExemption } from '@npm/data-access';

import { type BeneficialOwnersExemptions } from '../../BeneficialOwnership.types';
import { EntityExemptionQuestion, OwnerExemptionQuestion } from '../Questions';

import * as S from './ExemptForm.styles';

type Props = {
  onFinish: (v: BeneficialOwnersExemptions) => void;
  form: FormInstance<BeneficialOwnersExemptions>;
  accountName: string;
  handleValuesChange?: FormProps['onValuesChange'];
};

export const ExemptForm = ({
  form,
  onFinish,
  accountName,
  handleValuesChange,
}: Props) => {
  const entityExemption = Form.useWatch('entity_exemption', form);

  const handleEntityExemptionChange = (value: string) => {
    if (value === null) {
      form.resetFields(['owner_exemption']);
    }
  };

  return (
    <S.Container>
      <S.Form
        form={form}
        onFinish={onFinish}
        onValuesChange={handleValuesChange}
      >
        <Flex direction="column">
          <S.MaxWidthFull>
            <Margin bottom="xl">
              <FormItem
                name="entity_exemption"
                rules={[
                  {
                    ...VALIDATION_RULES.required(),
                    validateTrigger: 'onSubmit',
                  },
                ]}
              >
                <EntityExemptionQuestion
                  onChange={handleEntityExemptionChange}
                  accountName={accountName}
                />
              </FormItem>
            </Margin>
          </S.MaxWidthFull>
          <S.MaxWidthFull>
            <FormItem shouldUpdate>
              {entityExemption ===
                CbBeneficialOwnerEntityExemption.items.none && (
                <Margin top="xl">
                  <FormItem
                    name="owner_exemption"
                    rules={[
                      {
                        ...VALIDATION_RULES.required(),
                        validateTrigger: 'onSubmit',
                      },
                    ]}
                  >
                    <OwnerExemptionQuestion accountName={accountName} />
                  </FormItem>
                </Margin>
              )}
            </FormItem>
          </S.MaxWidthFull>
        </Flex>
      </S.Form>
    </S.Container>
  );
};
