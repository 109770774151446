import styled from 'styled-components';

import { EXPANDABLE_PANEL_HEADER_CLASS } from '../../../atoms/ExpandablePanel';
import {
  SmallTextBoldVariationSettings,
  SmallTextStyles,
} from '../../../atoms/Typography';

export const Wrapper = styled.div`
  .${EXPANDABLE_PANEL_HEADER_CLASS} {
    justify-content: center;
  }
`;

export const Link = styled.a`
  display: inline-flex;
  align-items: center;
  ${SmallTextStyles}
  ${SmallTextBoldVariationSettings}
  color: ${({ theme }) => theme.color.info.typography.primary};

  &:hover {
    color: ${({ theme }) => theme.color.info.backgrounds.primaryHover};
  }

  svg {
    margin-top: -1px;
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }
`;
