import { Margin } from '../../atoms/common';
import { Icon } from '../../atoms/Icon';
import { Heading, Text } from '../../atoms/Typography';

import * as S from './UnsupportedResolutionScreen.styles';

type Props = {
  heading: string;
  message: string;
  name: 'small-screen' | 'rotated-screen';
};

export const UnsupportedResolutionContent = ({
  heading,
  message,
  name,
}: Props) => (
  <>
    <Text color="warning">
      <Icon name={name} size="xxl" />
    </Text>
    <Margin bottom="sm" top="sm">
      <Heading variant="h2">{heading}</Heading>
    </Margin>
    <S.Message size="sm">{message}</S.Message>
  </>
);
