/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  FeeConfigurationApi,
  FeeConfigurationIndexErrorCodes,
  FeeConfigurationIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new FeeConfigurationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type FeeConfigurationIndexMethod = FeeConfigurationApi['feeConfigurationIndex'];
export type FeeConfigurationIndexResponseType = MethodResult<FeeConfigurationIndexMethod>;

export const useFeeConfigurationIndex = (
  variables: Parameters<FeeConfigurationIndexMethod>[0],
  config?: UseQueryConfig<
    FeeConfigurationIndexMethod,
    FeeConfigurationIndexErrorTypes
  >
) => {
  return useQuery<
    FeeConfigurationIndexMethod,
    FeeConfigurationIndexErrorTypes
  >(
    (...args) => api.feeConfigurationIndex(...args),
    FeeConfigurationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FeeConfigurationIndex'],
        ...apiQueryConfig.FeeConfigurationIndex,
        ...config?.queryConfig
      },
    },
    'FeeConfigurationIndex',
  );
};

export const useFeeConfigurationIndexInfinite = (
  variables: Parameters<FeeConfigurationIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    FeeConfigurationIndexMethod,
    FeeConfigurationIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    FeeConfigurationIndexMethod,
    FeeConfigurationIndexErrorTypes
  >(
    (...args) => api.feeConfigurationIndex(...args),
    FeeConfigurationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FeeConfigurationIndex', 'FeeConfigurationIndexInfinite'],
        ...apiQueryConfig.FeeConfigurationIndex,
        ...config?.queryConfig,
      },
    },
    'FeeConfigurationIndex',
  );
};

export const useFeeConfigurationIndexLazy = (baseOptions?: {
  variables?: Parameters<FeeConfigurationIndexMethod>[0],
  config?: UseQueryConfig<
    FeeConfigurationIndexMethod,
    FeeConfigurationIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    FeeConfigurationIndexMethod,
    FeeConfigurationIndexErrorTypes
  >(
    (...args) => api.feeConfigurationIndex(...args),
    FeeConfigurationIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['FeeConfigurationIndex'],
        ...apiQueryConfig.FeeConfigurationIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'FeeConfigurationIndex',
  );
};

