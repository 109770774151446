import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import * as S from './AddAuthorizedSignerCta.styles';

type Props = {
  onClick: () => void;
  accountName: string;
};

export const AddAuthorizedSignerCta = ({ onClick, accountName }: Props) => {
  return (
    <S.StyledCard>
      <Text marginBottom="md">
        Please, note that the signers need to be permissioned to sign on behalf
        of {accountName}
      </Text>
      <Button variant="outline" icon={<Icon name="plus" />} onClick={onClick}>
        Add an Authorized Signer
      </Button>
    </S.StyledCard>
  );
};
