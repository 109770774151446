import { CbOnboardingStatus } from '@npm/data-access';

import {
  type GetSectionsConfigFn,
  type OnboardingSectionConfig,
  parentStepKeyToTitleMap,
  sectionKeyToTitleMap,
} from './PostOnboarding.types';
import { filterOnboardingSectionsConfig } from './PostOnboarding.utils';
import {
  AuthorizedSignersPostOnboarding,
  BankAccountInformationPostOnboarding,
  GovernmentIDPostOnboarding,
  IndividualAccreditationPostOnboarding,
  IndividualInformationPostOnboarding,
  IndividualTaxIdPostOnboarding,
  MarketplaceAgreementPostOnboarding,
  PayingAgentAgreementPostOnboarding,
  SpousalInformationPostOnboarding,
  TaxInformationPostOnboarding,
} from './steps';

export const getIndividualOnboardingSections: GetSectionsConfigFn = ({
  onboardingStatus,
  personId,
}) => {
  const config: OnboardingSectionConfig[] = [
    {
      key: 'aml_kyc',
      title: sectionKeyToTitleMap.aml_kyc,
      tooltip:
        'In order to help the government fight the funding of terrorism and money laundering activities, U.S. federal law requires all financial institutions to obtain, verify, and record information that identifies each person or entity with whom we conduct securities transactions.',
      showProgress: true,
      items: [
        {
          key: 'personal_information',
          title: parentStepKeyToTitleMap.personal_information,
          component: () => null,
          tooltip:
            'This information is collected to populate agreements and confirm your identity to keep you and NPM safe.',
          substeps: [
            {
              key: 'personal_information_state',
              title: CbOnboardingStatus.titleMap.personal_information_state,
              component: stepProps => (
                <IndividualInformationPostOnboarding
                  stepProps={stepProps}
                  personId={personId}
                />
              ),
              isEditable: true,
            },
            {
              key: 'tax_information_state',
              title: CbOnboardingStatus.titleMap.tax_information_state,
              component: stepProps => (
                <TaxInformationPostOnboarding
                  stepProps={stepProps}
                  zendeskTicketGroupId={
                    'post_onboarding_individual_tax_information_state'
                  }
                />
              ),
            },
            {
              key: 'spouse_information_state',
              title: CbOnboardingStatus.titleMap.spouse_information_state,
              component: stepProps => (
                <SpousalInformationPostOnboarding stepProps={stepProps} />
              ),
              isEditable: true,
            },
          ],
        },
        {
          key: 'identity_verification',
          title: parentStepKeyToTitleMap.identity_verification,
          component: () => null,
          tooltip:
            'You will be required to provide a US Tax ID and a valid, unexpired government issued photo ID. This information is used to populate transaction documentation and satisfy any applicable regulatory requirements. If you do not have a US Tax ID, please chick the checkbox next to "I do not have a US Tax ID.',
          substeps: [
            {
              key: 'individual_tax_state',
              title: CbOnboardingStatus.titleMap.individual_tax_state,
              component: stepProps => (
                <IndividualTaxIdPostOnboarding
                  zendeskTicketGroupId={'post_onboarding_individual_tax_id'}
                  stepProps={stepProps}
                  personId={personId}
                />
              ),
            },
            {
              key: 'representative_photo_state',
              title: CbOnboardingStatus.titleMap.representative_photo_state,
              component: stepProps => (
                <GovernmentIDPostOnboarding
                  zendeskTicketGroupId={
                    'post_onboarding_individual_government_id'
                  }
                  stepProps={stepProps}
                />
              ),
            },
          ],
        },
        {
          key: 'authorized_signer_state',
          title: CbOnboardingStatus.titleMap.authorized_signer_state,
          component: stepProps => (
            <AuthorizedSignersPostOnboarding stepProps={stepProps} />
          ),
          isEditable: true,
        },
      ],
    },
    {
      key: 'agreements',
      title: sectionKeyToTitleMap.agreements,
      subTitle: 'Section Required for Marketplace',
      tooltip: 'Legal agreements required for broker-dealer services.',
      subTitleTooltip: '',
      items: [
        {
          key: 'marketplace_agreement_state',
          title: CbOnboardingStatus.titleMap.marketplace_agreement_state,
          component: stepProps => (
            <MarketplaceAgreementPostOnboarding
              zendeskTicketGroupId={
                'post_onboarding_individual_marketplace_agreement'
              }
              stepProps={stepProps}
            />
          ),
        },
        {
          key: 'paying_agent_agreement_state',
          title: CbOnboardingStatus.titleMap.paying_agent_agreement_state,
          component: stepProps => (
            <PayingAgentAgreementPostOnboarding
              zendeskTicketGroupId={
                'post_onboarding_individual_paying_agent_agreement'
              }
              stepProps={stepProps}
            />
          ),
        },
      ],
    },
    {
      key: 'other_requirements',
      title: sectionKeyToTitleMap.other_requirements,
      subTitle: 'Section Required for Marketplace',
      tooltip:
        "Required information to complete the settlement process and to evaluate the individual's level of financial sophistication.",
      items: [
        {
          key: 'accredited_investor_state',
          title: CbOnboardingStatus.titleMap.accredited_investor_state,
          titleTooltip: (
            <>
              Investors in private company securities must be, at minimum, an
              accredited investor within the meaning of Rule 501 of Regulation
              D. Please complete this step to establish this account’s status as
              an accredited investor. <br /> <br />
              Note: This step is not required if you are participating in a
              company sponsored tender offer.
            </>
          ),
          component: stepProps => (
            <IndividualAccreditationPostOnboarding
              zendeskTicketGroupId={'post_onboarding_individual_accreditation'}
              stepProps={stepProps}
              personId={personId}
            />
          ),
        },
        {
          key: 'bank_account_state',
          title: CbOnboardingStatus.titleMap.bank_account_state,
          component: stepProps => (
            <BankAccountInformationPostOnboarding stepProps={stepProps} />
          ),
          isEditable: true,
        },
      ],
    },
  ];

  return filterOnboardingSectionsConfig(config, onboardingStatus);
};
