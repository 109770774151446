export const transparentise = (hexCode: string, opacity: number): string => {
  const hex = hexCode.replace('#', '');
  const r = parseInt(
    hex.length === 3 ? hex[0]!.repeat(2) : hex.slice(0, 2),
    16
  );
  const g = parseInt(
    hex.length === 3 ? hex[1]!.repeat(2) : hex.slice(2, 4),
    16
  );
  const b = parseInt(
    hex.length === 3 ? hex[2]!.repeat(2) : hex.slice(4, 6),
    16
  );
  return `rgba(${r},${g},${b},${opacity})`;
};
