import { type QueryObserverOptions } from '@tanstack/react-query';

import { type MutationKey, type QueryKey } from '../generated/hooks';

// import { SUBMISSION_QUERY_KEYS } from './react-query.constants';

export const apiQueryConfig: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in QueryKey]?: QueryObserverOptions<any, any>;
} = {
  Codebook: { cacheTime: Infinity, staleTime: Infinity },
};

/* Configuration for mutations
   Properties:
    - invalidateQueries: list of QueryKeys that has to be invalidated after successful mutation execution
*/
export const apiMutationConfig: {
  [key in MutationKey]?: { invalidateQueries: QueryKey[] };
} = {};
