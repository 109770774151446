import React from 'react';
import { type RouteProps } from 'react-router';
import { SecureRoute } from '@okta/okta-react';

import { FullScreenLoading } from '@npm/core/ui/components/molecules/FullScreenLoading';
import {
  type PermissionContext,
  type RbacFn,
  performChecks,
} from '@npm/core/ui/models/permissions';

import { useUserContextStore } from '../../../auth/user/context';
import { useObo, useUserRole } from '../../../auth/user/role';
import { extractContextVariables } from '../../../auth/user/role/context/userRole.helpers';
import type { UserRoleDefinition } from '../../../auth/user/role/userRole.types';
import { ErrorPath } from '../ErrorPath';

type Props = RouteProps & {
  permissionsChecks?: RbacFn[];
  isLoading?: boolean;
};

const UnauthorizedError = () => (
  <ErrorPath heading="Unauthorized" status="403" />
);

export const ProtectedRoute = ({
  component,
  permissionsChecks = [],
  isLoading,
  ...rest
}: Props) => {
  const userContext = useUserContextStore();
  const userRole = useUserRole();
  const { oboRepresentative, oboAccount } = useObo();
  const { oboUserId, oboAccountId } = extractContextVariables(userRole);
  const role = userRole?.subRole as unknown as UserRoleDefinition;
  const checkContext = {
    ...userContext,
    workstation: userRole?.workstation,
    obo:
      oboAccountId && oboUserId
        ? {
            account: oboAccount,
            representative: oboRepresentative,
          }
        : undefined,
    role,
    getIsAccountCentric: () =>
      userRole?.workstationType === 'investor' &&
      userRole?.subRole?.type === 'account-centric',
  } satisfies PermissionContext;

  const renderComponent = () => {
    if (
      !performChecks(permissionsChecks, checkContext) &&
      userContext.isPermissionsCheckOn
    ) {
      if (isLoading) {
        return FullScreenLoading;
      }

      return UnauthorizedError;
    }

    return component;
  };

  return <SecureRoute {...rest} component={renderComponent()} />;
};
