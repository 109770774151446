import { withErrorBoundary } from 'react-error-boundary';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { ErrorSkeleton } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { type CompanyPricingApi } from '@npm/data-access';

import { type CompanyOverviewMaxValues } from '../TapeDPricing/TapeDPricing.types';

import { TapeDChart } from './components/TapeDChart';
import { TapeDTable } from './components/TapeDTable';

type Props = {
  data: CompanyPricingApi.IntrinsicValueIndex;
  yAxisMaxValues: CompanyOverviewMaxValues;
};

export const TapeD = withErrorBoundary(
  ({ data, yAxisMaxValues }: Props) => {
    return (
      <Flex direction="column" gap="md">
        {!!data?.intrinsic_values?.length && (
          <TapeDChart
            data={data.intrinsic_values}
            yAxisMaxValues={yAxisMaxValues}
          />
        )}

        <TapeDTable items={data.intrinsic_values} />
      </Flex>
    );
  },
  {
    FallbackComponent: ErrorSkeleton,
  }
);
