import React, { type ComponentProps } from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { type FileContentType } from '@npm/core/ui/utils/file';

type Props = {
  content_type?: string;
};
export const DocumentIcon = ({ content_type }: Props) => {
  const icons: Record<FileContentType, ComponentProps<typeof Icon>['name']> = {
    'application/pdf': 'file-pdf',
    'image/jpeg': 'photo',
    'image/png': 'photo',
    'text/plain': 'file-text',
  };

  const name = icons[content_type] || 'file';

  return <Icon name={name} size="xs" />;
};
