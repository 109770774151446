import styled from 'styled-components';

import { Flex } from '../../atoms/common';
import { Icon } from '../../atoms/Icon';
import { Text } from '../../atoms/Typography';

const SWITCHER_WRAPPER_HEIGHT = 52;
const SWITCH_WRAPPER_MINIMUM_WIDTH = 96;

export const DarkModeSwitcherWrapper = styled(Flex).attrs({
  align: 'center',
  justify: 'center',
})`
  border-top: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  width: 100%;
  height: ${SWITCHER_WRAPPER_HEIGHT}px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const DarkModeSwitcherInnerWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.sm}px
    ${({ theme }) => theme.spacing.md}px;
  width: 100%;
`;

export const DarkModeSwitcherLabel = styled(Text).attrs({
  size: 'sm',
  colorVariant: 'primary',
})`
  margin-left: 12px; // No theme value for this, implemented as per .ant-menu .ant-menu-item .ant-menu-title-content
`;

export const DarkModeSwitcherIcon = styled(Icon).attrs({
  size: 'xs',
})`
  color: ${({ theme }) => theme.color.general.typography.tertiary};
`;

export const DarkModeSwitcherSwitchWrapper = styled.div`
  min-width: ${SWITCH_WRAPPER_MINIMUM_WIDTH}px;
  white-space: nowrap;
`;
