/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Accreditation } from '../model';
// @ts-ignore
import { PersonsAccreditationCreateRequestContract } from '../model';
/**
 * PersonAccreditationApi - axios parameter creator
 * @export
 */
export const PersonAccreditationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates or updates the accreditation for the person
         * @param {number} personId 
         * @param {PersonsAccreditationCreateRequestContract} personsAccreditationCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAccreditationCreate: async (personId: number, personsAccreditationCreateRequestContract: PersonsAccreditationCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('personAccreditationCreate', 'personId', personId)
            // verify required parameter 'personsAccreditationCreateRequestContract' is not null or undefined
            assertParamExists('personAccreditationCreate', 'personsAccreditationCreateRequestContract', personsAccreditationCreateRequestContract)
            const localVarPath = changeUrl(`/api/persons/{person_id}/accreditation`)
                .replace(`{${"person_id"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personsAccreditationCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletes an accrediation for the person
         * @param {number} id 
         * @param {number} personId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAccreditationDelete: async (id: number, personId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('personAccreditationDelete', 'id', id)
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('personAccreditationDelete', 'personId', personId)
            const localVarPath = changeUrl(`/api/persons/{person_id}/accreditations/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"person_id"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns the latest accreditation for the account
         * @param {number} personId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAccreditationShow: async (personId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('personAccreditationShow', 'personId', personId)
            const localVarPath = changeUrl(`/api/persons/{person_id}/accreditation`)
                .replace(`{${"person_id"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns the accreditation with the given id for the person
         * @param {number} personId 
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAccreditationShowById: async (personId: number, id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('personAccreditationShowById', 'personId', personId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('personAccreditationShowById', 'id', id)
            const localVarPath = changeUrl(`/api/persons/{person_id}/accreditations/{id}`)
                .replace(`{${"person_id"}}`, encodeURIComponent(String(personId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonAccreditationApi - functional programming interface
 * @export
 */
export const PersonAccreditationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonAccreditationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates or updates the accreditation for the person
         * @param {number} personId 
         * @param {PersonsAccreditationCreateRequestContract} personsAccreditationCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personAccreditationCreate(personId: number, personsAccreditationCreateRequestContract: PersonsAccreditationCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Accreditation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personAccreditationCreate(personId, personsAccreditationCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deletes an accrediation for the person
         * @param {number} id 
         * @param {number} personId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personAccreditationDelete(id: number, personId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personAccreditationDelete(id, personId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns the latest accreditation for the account
         * @param {number} personId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personAccreditationShow(personId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Accreditation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personAccreditationShow(personId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns the accreditation with the given id for the person
         * @param {number} personId 
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personAccreditationShowById(personId: number, id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Accreditation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personAccreditationShowById(personId, id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonAccreditationApi - factory interface
 * @export
 */
export const PersonAccreditationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonAccreditationApiFp(configuration)
    return {
        /**
         * 
         * @summary creates or updates the accreditation for the person
         * @param {number} personId 
         * @param {PersonsAccreditationCreateRequestContract} personsAccreditationCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAccreditationCreate(personId: number, personsAccreditationCreateRequestContract: PersonsAccreditationCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Accreditation> {
            return localVarFp.personAccreditationCreate(personId, personsAccreditationCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deletes an accrediation for the person
         * @param {number} id 
         * @param {number} personId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAccreditationDelete(id: number, personId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.personAccreditationDelete(id, personId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns the latest accreditation for the account
         * @param {number} personId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAccreditationShow(personId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Accreditation> {
            return localVarFp.personAccreditationShow(personId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns the accreditation with the given id for the person
         * @param {number} personId 
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personAccreditationShowById(personId: number, id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Accreditation> {
            return localVarFp.personAccreditationShowById(personId, id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for personAccreditationCreate operation in PersonAccreditationApi.
 * @export
 * @interface PersonAccreditationApiPersonAccreditationCreateRequest
 */
export interface PersonAccreditationApiPersonAccreditationCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof PersonAccreditationApiPersonAccreditationCreate
     */
    readonly personId: number

    /**
     * 
     * @type {PersonsAccreditationCreateRequestContract}
     * @memberof PersonAccreditationApiPersonAccreditationCreate
     */
    readonly personsAccreditationCreateRequestContract: PersonsAccreditationCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for personAccreditationDelete operation in PersonAccreditationApi.
 * @export
 * @interface PersonAccreditationApiPersonAccreditationDeleteRequest
 */
export interface PersonAccreditationApiPersonAccreditationDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof PersonAccreditationApiPersonAccreditationDelete
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PersonAccreditationApiPersonAccreditationDelete
     */
    readonly personId: number

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationDelete
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationDelete
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationDelete
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationDelete
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationDelete
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationDelete
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for personAccreditationShow operation in PersonAccreditationApi.
 * @export
 * @interface PersonAccreditationApiPersonAccreditationShowRequest
 */
export interface PersonAccreditationApiPersonAccreditationShowRequest {
    /**
     * 
     * @type {number}
     * @memberof PersonAccreditationApiPersonAccreditationShow
     */
    readonly personId: number

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for personAccreditationShowById operation in PersonAccreditationApi.
 * @export
 * @interface PersonAccreditationApiPersonAccreditationShowByIdRequest
 */
export interface PersonAccreditationApiPersonAccreditationShowByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof PersonAccreditationApiPersonAccreditationShowById
     */
    readonly personId: number

    /**
     * 
     * @type {number}
     * @memberof PersonAccreditationApiPersonAccreditationShowById
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationShowById
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationShowById
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationShowById
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationShowById
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationShowById
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof PersonAccreditationApiPersonAccreditationShowById
     */
    readonly xUserRoleId?: string
}

/**
 * PersonAccreditationApi - object-oriented interface
 * @export
 * @class PersonAccreditationApi
 * @extends {BaseAPI}
 */
export class PersonAccreditationApi extends BaseAPI {
    /**
     * 
     * @summary creates or updates the accreditation for the person
     * @param {PersonAccreditationApiPersonAccreditationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonAccreditationApi
     */
    public personAccreditationCreate(requestParameters: PersonAccreditationApiPersonAccreditationCreateRequest, options?: AxiosRequestConfig) {
        return PersonAccreditationApiFp(this.configuration).personAccreditationCreate(requestParameters.personId, requestParameters.personsAccreditationCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deletes an accrediation for the person
     * @param {PersonAccreditationApiPersonAccreditationDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonAccreditationApi
     */
    public personAccreditationDelete(requestParameters: PersonAccreditationApiPersonAccreditationDeleteRequest, options?: AxiosRequestConfig) {
        return PersonAccreditationApiFp(this.configuration).personAccreditationDelete(requestParameters.id, requestParameters.personId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns the latest accreditation for the account
     * @param {PersonAccreditationApiPersonAccreditationShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonAccreditationApi
     */
    public personAccreditationShow(requestParameters: PersonAccreditationApiPersonAccreditationShowRequest, options?: AxiosRequestConfig) {
        return PersonAccreditationApiFp(this.configuration).personAccreditationShow(requestParameters.personId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns the accreditation with the given id for the person
     * @param {PersonAccreditationApiPersonAccreditationShowByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonAccreditationApi
     */
    public personAccreditationShowById(requestParameters: PersonAccreditationApiPersonAccreditationShowByIdRequest, options?: AxiosRequestConfig) {
        return PersonAccreditationApiFp(this.configuration).personAccreditationShowById(requestParameters.personId, requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type PersonAccreditationCreateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PersonAccreditationCreateErrorCodes = [
];

export type PersonAccreditationDeleteErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PersonAccreditationDeleteErrorCodes = [
];

export type PersonAccreditationShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PersonAccreditationShowErrorCodes = [
    401,
    404,
];

export type PersonAccreditationShowByIdErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PersonAccreditationShowByIdErrorCodes = [
    401,
    404,
];


