import styled from 'styled-components';

import { SmallTextBoldVariationSettings, SmallTextStyles } from '../Typography';
import { WysiwygStyles } from '../WysiwygContent';

const EDITOR_MIN_HEIGHT = 80;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.general.layout.one};
  position: relative;

  & .ql-toolbar {
    border: 1px solid ${({ theme }) => theme.color.general.borders.secondary};
    border-top-left-radius: ${({ theme }) => theme.borderRadius.sm}px;
    border-top-right-radius: ${({ theme }) => theme.borderRadius.sm}px;

    /* Toolbar icon color */
    & .ql-stroke {
      fill: none;
      stroke: ${({ theme }) => theme.color.general.typography.primary};
    }

    & .ql-fill {
      fill: ${({ theme }) => theme.color.general.typography.primary};
      stroke: none;
    }

    & .ql-picker {
      color: ${({ theme }) => theme.color.general.typography.primary};
    }

    /* Toolbar active states */
    & button:hover,
    & button:focus,
    & button.ql-active,
    & .ql-picker-label:hover,
    & .ql-picker-label.ql-active,
    & .ql-picker-item:hover,
    & .ql-picker-item.ql-selected {
      color: ${({ theme }) => theme.color.info.typography.primary};

      & .ql-stroke {
        fill: none;
        stroke: ${({ theme }) => theme.color.info.typography.primary};
      }

      & .ql-fill {
        fill: ${({ theme }) => theme.color.info.typography.primary};
        stroke: none;
      }

      & .ql-picker {
        color: ${({ theme }) => theme.color.info.typography.primary};
      }
    }
  }

  & .ql-container {
    ${WysiwygStyles}
    border: 1px solid ${({ theme }) => theme.color.general.borders.secondary};
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;

    & .ql-editor {
      min-height: ${EDITOR_MIN_HEIGHT}px;
      background-color: ${({ theme }) => theme.color.general.layout.two};

      &:focus {
        border-color: ${({ theme }) => theme.color.info.borders.primary};
        box-shadow: ${({ theme }) => theme.shadow.level1},
          0 0 0 1px ${({ theme }) => theme.color.info.borders.primary};
      }

      & strong {
        ${SmallTextBoldVariationSettings}
      }
    }

    &:hover {
      border-color: ${({ theme }) => theme.color.info.borders.primary};

      & .ql-editor {
        background-color: ${({ theme }) => theme.color.general.layout.one};
        box-shadow: ${({ theme }) => theme.shadow.level1},
          0 0 0 1px ${({ theme }) => theme.color.info.borders.primary};
      }
    }
  }

  /* Quill dropdown */
  & .ql-toolbar .ql-picker.ql-expanded .ql-picker-options,
  & .ql-toolbar .ql-picker.ql-expanded .ql-picker-label {
    background-color: ${({ theme }) => theme.color.general.layout.one};
    border: 1px solid ${({ theme }) => theme.color.general.borders.secondary};
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    border-color: ${({ theme }) => theme.color.general.borders.secondary};
  }

  /* Quill placeholder */
  & .quill > .ql-container > .ql-editor.ql-blank::before {
    ${SmallTextStyles}
    color: ${({ theme }) => theme.color.general.typography.secondary};
    font-style: normal;
  }
`;
