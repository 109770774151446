import React, { type FocusEvent, useCallback } from 'react';
import { type InputProps as InputPropsAnt } from 'antd';

import * as S from './Input.styles';

export type InputProps = InputPropsAnt & {
  readOnly?: boolean;
};

export const Input = (props: InputProps) => {
  const { onBlur: originalBlur, onChange } = props;

  const onBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const normalizedValue = value.replace(/\s{2,}/g, ' ').trim();
      const trimmedEvent = {
        ...event,
        target: {
          ...event.target,
          value: normalizedValue,
        },
      };
      onChange?.(trimmedEvent);
      originalBlur?.(trimmedEvent);
    },
    [onChange, originalBlur]
  );

  return (
    <S.StyledInput
      readOnly={props.readOnly}
      $readOnly={props.readOnly}
      onKeyUp={event => {
        // Blur and close the virtual keyboard on Enter/Return
        const key = event.code || event.keyCode;
        if (key === 'Enter' || key === 13) {
          (event.target as HTMLInputElement).blur();
        }
      }}
      {...props}
      onBlur={onBlur}
    />
  );
};
