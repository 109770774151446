import { type VenusApi } from '@npm/data-access';

const setFilingIdsFromInvestments = (
  investments: VenusApi.InvestmentExternal[],
  activity: VenusApi.FinancialActivity,
  callBack: (filingId: number) => void
) => {
  for (const investment of investments ?? []) {
    if (investment?.id === activity?.id) {
      const filingId = investment?.filing_id;
      if (filingId) {
        callBack(filingId);
      }
    }
  }
};

export const getFilteredFilingIdsSet = (
  activity: VenusApi.FinancialActivity,
  investments: VenusApi.InvestmentExternal[]
) => {
  const filingIds = new Set<number>();

  if (activity.class === 'investment') {
    setFilingIdsFromInvestments(investments, activity, filingId =>
      filingIds.add(filingId)
    );
  }

  return filingIds;
};

export const getTableRowClassName = (recordId: number) => {
  return `financing-activity-row-${recordId}`;
};
