import { type ComponentProps } from 'react';
import addWeeks from 'date-fns/addWeeks';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import endOfYear from 'date-fns/endOfYear';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import startOfYear from 'date-fns/startOfYear';
import subMonths from 'date-fns/subMonths';
import subWeeks from 'date-fns/subWeeks';

import { type Select } from '@npm/core/ui/components/atoms/Select';

const DateStringOptions = [
  'today',
  'thisWeekStart',
  'thisWeekEnd',
  'lastWeekStart',
  'nextWeekEnd',
  'lastWeek',
  'last2Weeks',
  'thisMonthStart',
  'thisMonthEnd',
  'lastMonth',
  'thisYearStart',
  'thisYearEnd',
  'last6Months',
  'last1Year',
] as const;

export type DateStringOption = typeof DateStringOptions[number];

const DATE_OPTIONS: Record<
  DateStringOption,
  { label: string; fn: (date: Date | number) => Date }
> = {
  today: {
    label: 'Today',
    fn: (now: Date) => now,
  },
  thisWeekStart: {
    label: 'This Week',
    fn: startOfWeek,
  },
  thisWeekEnd: {
    label: 'This Week',
    fn: endOfWeek,
  },
  lastWeekStart: {
    label: 'Last Week',
    fn: (now: Date) => startOfWeek(subWeeks(now, 1)),
  },
  nextWeekEnd: {
    label: 'Next Week',
    fn: (now: Date) => endOfWeek(addWeeks(now, 1)),
  },
  lastWeek: {
    label: 'Last 7 Days',
    fn: (now: Date) => subWeeks(now, 1),
  },
  last2Weeks: {
    label: 'Last 14 Days',
    fn: (now: Date) => subWeeks(now, 2),
  },
  thisMonthStart: {
    label: 'This Month',
    fn: startOfMonth,
  },
  thisMonthEnd: {
    label: 'This Month',
    fn: endOfMonth,
  },
  lastMonth: {
    label: 'Last Month',
    fn: (now: Date) => subMonths(now, 1),
  },
  thisYearStart: {
    label: 'This Year',
    fn: startOfYear,
  },
  thisYearEnd: {
    label: 'This Year',
    fn: endOfYear,
  },
  last6Months: {
    label: 'Last 6 Months',
    fn: (now: Date) => subMonths(now, 6),
  },
  last1Year: {
    label: 'Last Year',
    fn: (now: Date) => subMonths(now, 12),
  },
};

export const generateDateOptions = (
  options: DateStringOption[]
): ComponentProps<typeof Select>['options'] => {
  return options.map(option => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const thisOption = DATE_OPTIONS[option];
    const date = thisOption?.fn(now);

    return {
      label: thisOption.label,
      value: date ? date.toISOString() : undefined,
    };
  });
};
