/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  IssuerEntityLogoApi,
  IssuerEntityLogoShowErrorCodes,
  IssuerEntityLogoShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new IssuerEntityLogoApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type IssuerEntityLogoShowMethod = IssuerEntityLogoApi['issuerEntityLogoShow'];
export type IssuerEntityLogoShowResponseType = MethodResult<IssuerEntityLogoShowMethod>;

export const useIssuerEntityLogoShow = (
  variables: Parameters<IssuerEntityLogoShowMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityLogoShowMethod,
    IssuerEntityLogoShowErrorTypes
  >
) => {
  return useQuery<
    IssuerEntityLogoShowMethod,
    IssuerEntityLogoShowErrorTypes
  >(
    (...args) => api.issuerEntityLogoShow(...args),
    IssuerEntityLogoShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityLogoShow'],
        ...apiQueryConfig.IssuerEntityLogoShow,
        ...config?.queryConfig
      },
    },
    'IssuerEntityLogoShow',
  );
};

export const useIssuerEntityLogoShowInfinite = (
  variables: Parameters<IssuerEntityLogoShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    IssuerEntityLogoShowMethod,
    IssuerEntityLogoShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    IssuerEntityLogoShowMethod,
    IssuerEntityLogoShowErrorTypes
  >(
    (...args) => api.issuerEntityLogoShow(...args),
    IssuerEntityLogoShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityLogoShow', 'IssuerEntityLogoShowInfinite'],
        ...apiQueryConfig.IssuerEntityLogoShow,
        ...config?.queryConfig,
      },
    },
    'IssuerEntityLogoShow',
  );
};

export const useIssuerEntityLogoShowLazy = (baseOptions?: {
  variables?: Parameters<IssuerEntityLogoShowMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityLogoShowMethod,
    IssuerEntityLogoShowErrorTypes
  >
}) => {
  return useLazyQuery<
    IssuerEntityLogoShowMethod,
    IssuerEntityLogoShowErrorTypes
  >(
    (...args) => api.issuerEntityLogoShow(...args),
    IssuerEntityLogoShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IssuerEntityLogoShow'],
        ...apiQueryConfig.IssuerEntityLogoShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IssuerEntityLogoShow',
  );
};

