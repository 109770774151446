import { Codebooks } from './codebooks.types';

const Items = {
  ISO: 'ISO',
  NSO: 'NSO',
  Other: 'Other',
} as const;

export const CbGrantType = {
  code: Codebooks.GRANT_TYPE,
  items: Items,
};
