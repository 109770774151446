import { Form } from '@npm/core/ui/components/atoms/Form';
import { VenusApi } from '@npm/data-access';

import { CollapsibleFilterPanel } from '../../../filters';

import { getCompaniesDataFilters } from './CompaniesDataFilter.config';
import { type CompaniesDataFilterFormValues } from './CompaniesDataFilter.types';

const { useCompaniesOverviewsSectors } = VenusApi;

type Props = {
  handleSubmit: (values: CompaniesDataFilterFormValues) => void;
  variables?: VenusApi.CompaniesOverviewIndexRequestContract;
  initialFilterValues?: Partial<VenusApi.CompaniesOverviewIndexRequestContract>;
  totalActiveFilters: number;
  totalRecords: number | undefined;
};

export const CompaniesDataFilter = ({
  totalActiveFilters,
  handleSubmit,
  initialFilterValues,
  totalRecords,
}: Props) => {
  const [form] = Form.useForm<CompaniesDataFilterFormValues>();

  const sectorQuery = useCompaniesOverviewsSectors({});

  return (
    <CollapsibleFilterPanel
      responsive={false}
      form={form}
      initialFilterValues={initialFilterValues}
      filters={getCompaniesDataFilters({ sectorQuery })}
      handleSubmit={handleSubmit}
      totalActiveFilters={totalActiveFilters}
      totalRecords={totalRecords}
    />
  );
};
