/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SecondmarketBidAskItemIndex } from '../model';
/**
 * SecondmarketBidAskItemApi - axios parameter creator
 * @export
 */
export const SecondmarketBidAskItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list bid and ask items in secondmarket
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [issuerEntityId] 
         * @param {number} [parentOrderItemId] 
         * @param {boolean} [itemsForOrderbook] include order items from other brokerage firms with live status in result
         * @param {boolean} [findLive] filter for order items in states that indicate live status (pending w/ remaining_qty &gt; 0)
         * @param {string} [orderBuySell] filter for buy or sell order item types
         * @param {string} [placedAfter] 
         * @param {string} [expiringBefore] 
         * @param {Array<string>} [orderEntryType] filter for firm or ioi order items
         * @param {boolean} [findUserAccountWatchlist] filter for order items in obo account watchlist
         * @param {Array<string>} [state] 
         * @param {boolean} [hideOwnBrokerageFirm] 
         * @param {string} [sort] 
         * @param {number} [sizeRangeStart] 
         * @param {number} [sizeRangeEnd] 
         * @param {Array<string>} [atsPool] 
         * @param {boolean} [ownOrdersOnly] filter for order items that belong to my current account
         * @param {string} [orderItemUnit] filter for USD or SHARES order items. SHARES items must have size and PPS
         * @param {boolean} [withPrice] filter for order items that have a price
         * @param {Array<'order_type' | 'unit' | 'asset'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketBidAskItemIndex: async (page?: number, size?: number, search?: string, issuerEntityId?: number, parentOrderItemId?: number, itemsForOrderbook?: boolean, findLive?: boolean, orderBuySell?: string, placedAfter?: string, expiringBefore?: string, orderEntryType?: Array<string>, findUserAccountWatchlist?: boolean, state?: Array<string>, hideOwnBrokerageFirm?: boolean, sort?: string, sizeRangeStart?: number, sizeRangeEnd?: number, atsPool?: Array<string>, ownOrdersOnly?: boolean, orderItemUnit?: string, withPrice?: boolean, includeField?: Array<'order_type' | 'unit' | 'asset'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/secondmarket/bid-ask-items`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }

            if (parentOrderItemId !== undefined) {
                localVarQueryParameter['parent_order_item_id'] = parentOrderItemId;
            }

            if (itemsForOrderbook !== undefined) {
                localVarQueryParameter['items_for_orderbook'] = itemsForOrderbook;
            }

            if (findLive !== undefined) {
                localVarQueryParameter['find_live'] = findLive;
            }

            if (orderBuySell !== undefined) {
                localVarQueryParameter['order_buy_sell'] = orderBuySell;
            }

            if (placedAfter !== undefined) {
                localVarQueryParameter['placed_after'] = placedAfter;
            }

            if (expiringBefore !== undefined) {
                localVarQueryParameter['expiring_before'] = expiringBefore;
            }

            if (orderEntryType) {
                localVarQueryParameter['order_entry_type'] = orderEntryType.join(COLLECTION_FORMATS.csv);
            }

            if (findUserAccountWatchlist !== undefined) {
                localVarQueryParameter['find_user_account_watchlist'] = findUserAccountWatchlist;
            }

            if (state) {
                localVarQueryParameter['state'] = state.join(COLLECTION_FORMATS.csv);
            }

            if (hideOwnBrokerageFirm !== undefined) {
                localVarQueryParameter['hide_own_brokerage_firm'] = hideOwnBrokerageFirm;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (sizeRangeStart !== undefined) {
                localVarQueryParameter['size_range_start'] = sizeRangeStart;
            }

            if (sizeRangeEnd !== undefined) {
                localVarQueryParameter['size_range_end'] = sizeRangeEnd;
            }

            if (atsPool) {
                localVarQueryParameter['ats_pool'] = atsPool.join(COLLECTION_FORMATS.csv);
            }

            if (ownOrdersOnly !== undefined) {
                localVarQueryParameter['own_orders_only'] = ownOrdersOnly;
            }

            if (orderItemUnit !== undefined) {
                localVarQueryParameter['order_item_unit'] = orderItemUnit;
            }

            if (withPrice !== undefined) {
                localVarQueryParameter['with_price'] = withPrice;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecondmarketBidAskItemApi - functional programming interface
 * @export
 */
export const SecondmarketBidAskItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecondmarketBidAskItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary list bid and ask items in secondmarket
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [issuerEntityId] 
         * @param {number} [parentOrderItemId] 
         * @param {boolean} [itemsForOrderbook] include order items from other brokerage firms with live status in result
         * @param {boolean} [findLive] filter for order items in states that indicate live status (pending w/ remaining_qty &gt; 0)
         * @param {string} [orderBuySell] filter for buy or sell order item types
         * @param {string} [placedAfter] 
         * @param {string} [expiringBefore] 
         * @param {Array<string>} [orderEntryType] filter for firm or ioi order items
         * @param {boolean} [findUserAccountWatchlist] filter for order items in obo account watchlist
         * @param {Array<string>} [state] 
         * @param {boolean} [hideOwnBrokerageFirm] 
         * @param {string} [sort] 
         * @param {number} [sizeRangeStart] 
         * @param {number} [sizeRangeEnd] 
         * @param {Array<string>} [atsPool] 
         * @param {boolean} [ownOrdersOnly] filter for order items that belong to my current account
         * @param {string} [orderItemUnit] filter for USD or SHARES order items. SHARES items must have size and PPS
         * @param {boolean} [withPrice] filter for order items that have a price
         * @param {Array<'order_type' | 'unit' | 'asset'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secondmarketBidAskItemIndex(page?: number, size?: number, search?: string, issuerEntityId?: number, parentOrderItemId?: number, itemsForOrderbook?: boolean, findLive?: boolean, orderBuySell?: string, placedAfter?: string, expiringBefore?: string, orderEntryType?: Array<string>, findUserAccountWatchlist?: boolean, state?: Array<string>, hideOwnBrokerageFirm?: boolean, sort?: string, sizeRangeStart?: number, sizeRangeEnd?: number, atsPool?: Array<string>, ownOrdersOnly?: boolean, orderItemUnit?: string, withPrice?: boolean, includeField?: Array<'order_type' | 'unit' | 'asset'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecondmarketBidAskItemIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secondmarketBidAskItemIndex(page, size, search, issuerEntityId, parentOrderItemId, itemsForOrderbook, findLive, orderBuySell, placedAfter, expiringBefore, orderEntryType, findUserAccountWatchlist, state, hideOwnBrokerageFirm, sort, sizeRangeStart, sizeRangeEnd, atsPool, ownOrdersOnly, orderItemUnit, withPrice, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SecondmarketBidAskItemApi - factory interface
 * @export
 */
export const SecondmarketBidAskItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecondmarketBidAskItemApiFp(configuration)
    return {
        /**
         * 
         * @summary list bid and ask items in secondmarket
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [issuerEntityId] 
         * @param {number} [parentOrderItemId] 
         * @param {boolean} [itemsForOrderbook] include order items from other brokerage firms with live status in result
         * @param {boolean} [findLive] filter for order items in states that indicate live status (pending w/ remaining_qty &gt; 0)
         * @param {string} [orderBuySell] filter for buy or sell order item types
         * @param {string} [placedAfter] 
         * @param {string} [expiringBefore] 
         * @param {Array<string>} [orderEntryType] filter for firm or ioi order items
         * @param {boolean} [findUserAccountWatchlist] filter for order items in obo account watchlist
         * @param {Array<string>} [state] 
         * @param {boolean} [hideOwnBrokerageFirm] 
         * @param {string} [sort] 
         * @param {number} [sizeRangeStart] 
         * @param {number} [sizeRangeEnd] 
         * @param {Array<string>} [atsPool] 
         * @param {boolean} [ownOrdersOnly] filter for order items that belong to my current account
         * @param {string} [orderItemUnit] filter for USD or SHARES order items. SHARES items must have size and PPS
         * @param {boolean} [withPrice] filter for order items that have a price
         * @param {Array<'order_type' | 'unit' | 'asset'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketBidAskItemIndex(page?: number, size?: number, search?: string, issuerEntityId?: number, parentOrderItemId?: number, itemsForOrderbook?: boolean, findLive?: boolean, orderBuySell?: string, placedAfter?: string, expiringBefore?: string, orderEntryType?: Array<string>, findUserAccountWatchlist?: boolean, state?: Array<string>, hideOwnBrokerageFirm?: boolean, sort?: string, sizeRangeStart?: number, sizeRangeEnd?: number, atsPool?: Array<string>, ownOrdersOnly?: boolean, orderItemUnit?: string, withPrice?: boolean, includeField?: Array<'order_type' | 'unit' | 'asset'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<SecondmarketBidAskItemIndex> {
            return localVarFp.secondmarketBidAskItemIndex(page, size, search, issuerEntityId, parentOrderItemId, itemsForOrderbook, findLive, orderBuySell, placedAfter, expiringBefore, orderEntryType, findUserAccountWatchlist, state, hideOwnBrokerageFirm, sort, sizeRangeStart, sizeRangeEnd, atsPool, ownOrdersOnly, orderItemUnit, withPrice, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for secondmarketBidAskItemIndex operation in SecondmarketBidAskItemApi.
 * @export
 * @interface SecondmarketBidAskItemApiSecondmarketBidAskItemIndexRequest
 */
export interface SecondmarketBidAskItemApiSecondmarketBidAskItemIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly issuerEntityId?: number

    /**
     * 
     * @type {number}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly parentOrderItemId?: number

    /**
     * include order items from other brokerage firms with live status in result
     * @type {boolean}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly itemsForOrderbook?: boolean

    /**
     * filter for order items in states that indicate live status (pending w/ remaining_qty &gt; 0)
     * @type {boolean}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly findLive?: boolean

    /**
     * filter for buy or sell order item types
     * @type {string}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly orderBuySell?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly placedAfter?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly expiringBefore?: string

    /**
     * filter for firm or ioi order items
     * @type {Array<string>}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly orderEntryType?: Array<string>

    /**
     * filter for order items in obo account watchlist
     * @type {boolean}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly findUserAccountWatchlist?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly state?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly hideOwnBrokerageFirm?: boolean

    /**
     * 
     * @type {string}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly sort?: string

    /**
     * 
     * @type {number}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly sizeRangeStart?: number

    /**
     * 
     * @type {number}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly sizeRangeEnd?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly atsPool?: Array<string>

    /**
     * filter for order items that belong to my current account
     * @type {boolean}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly ownOrdersOnly?: boolean

    /**
     * filter for USD or SHARES order items. SHARES items must have size and PPS
     * @type {string}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly orderItemUnit?: string

    /**
     * filter for order items that have a price
     * @type {boolean}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly withPrice?: boolean

    /**
     * 
     * @type {Array<'order_type' | 'unit' | 'asset'>}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly includeField?: Array<'order_type' | 'unit' | 'asset'>

    /**
     * 
     * @type {string}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SecondmarketBidAskItemApiSecondmarketBidAskItemIndex
     */
    readonly xUserRoleId?: string
}

/**
 * SecondmarketBidAskItemApi - object-oriented interface
 * @export
 * @class SecondmarketBidAskItemApi
 * @extends {BaseAPI}
 */
export class SecondmarketBidAskItemApi extends BaseAPI {
    /**
     * 
     * @summary list bid and ask items in secondmarket
     * @param {SecondmarketBidAskItemApiSecondmarketBidAskItemIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecondmarketBidAskItemApi
     */
    public secondmarketBidAskItemIndex(requestParameters: SecondmarketBidAskItemApiSecondmarketBidAskItemIndexRequest = {}, options?: AxiosRequestConfig) {
        return SecondmarketBidAskItemApiFp(this.configuration).secondmarketBidAskItemIndex(requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.issuerEntityId, requestParameters.parentOrderItemId, requestParameters.itemsForOrderbook, requestParameters.findLive, requestParameters.orderBuySell, requestParameters.placedAfter, requestParameters.expiringBefore, requestParameters.orderEntryType, requestParameters.findUserAccountWatchlist, requestParameters.state, requestParameters.hideOwnBrokerageFirm, requestParameters.sort, requestParameters.sizeRangeStart, requestParameters.sizeRangeEnd, requestParameters.atsPool, requestParameters.ownOrdersOnly, requestParameters.orderItemUnit, requestParameters.withPrice, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type SecondmarketBidAskItemIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecondmarketBidAskItemIndexErrorCodes = [
    401,
];


