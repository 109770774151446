import React from 'react';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { type SpinProps } from 'antd';

import { CypressDataIds } from '../../../constants';

import * as S from './Loader.styles';

type Props = { height?: string } & SpinProps;

export const Loader: React.FC<Props> = ({
  height,
  size = 'large',
  ...rest
}: Props) => {
  return (
    <S.Wrapper $wrapperHeight={height}>
      <S.Spin
        data-cy={CypressDataIds.Loading.Loader}
        size={size}
        {...rest}
        indicator={<LoadingOutlined size={16} />}
      />
    </S.Wrapper>
  );
};
