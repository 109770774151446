// slice from text until it fits into parent container
export const getTruncatedText = (
  originalText: string,
  textContainer: HTMLSpanElement | null, // text container with suffix
  textInner: HTMLSpanElement | null, // text to truncate
  width: number // parent container width
) => {
  let textLength = originalText.length;
  let newText = originalText;
  if (textInner) textInner.textContent = originalText;

  if (
    textContainer &&
    textInner &&
    Math.floor(textContainer.scrollWidth) > Math.ceil(width)
  ) {
    while (textLength > 5) {
      textLength -= 2;
      newText =
        originalText.slice(0, textLength / 2) +
        '…' +
        originalText.slice(-textLength / 2);
      textInner.textContent = newText;

      if (textContainer.scrollWidth <= width) {
        break;
      }
    }
  }

  return newText;
};

// this craziness is ChatGPT generated, the documentation too, the most important thing is that it seems to work.

/**
 * This function takes a 'fullText' string and a 'textToFind' string, and tries to split 'fullText' in two parts based on the following logic:
 * 1. If the character "•" exists in 'fullText' and it's either before "..." or "..." doesn't exist, 'fullText' is split by "•". "•" and everything after it forms part2, everything before "•" forms part1.
 * 2. If "•" doesn't exist or is located after "...", the function tries to find the longest matching substring from the end of 'textToFind' within 'fullText' and splits it at the found substring.
 * 3. If no match was found, the entire 'fullText' forms part1 and part2 is an empty string.
 *
 * Example 1: splitOnText("Account Name • Brokerage Firm", "Brokerage Firm") -> [ 'Account Name', '• Brokerage Firm' ]
 * Example 2: splitOnText("Account Name...Firm", "Brokerage Firm") -> [ 'Account Name...', 'Firm' ]
 * Example 3: splitOnText("Account Name", "Brokerage Firm") -> [ 'Account Name', '' ]
 *
 * @param {string} fullText - The full text to split
 * @param {string} textToFind - The text to find within the full text
 * @returns {[string, string]} - An array with two strings: part1 and part2 of the split text
 */
export const splitByBulletOrEllipsis = (
  fullText: string,
  textToFind?: string
): [string, string] => {
  if (!textToFind) return [fullText, ''];
  let part1 = '';
  let part2 = '';

  // Find position of "•" and "..."
  const hyphenPosition = fullText.indexOf('•');
  const ellipsisPosition = fullText.indexOf('...');

  // If "•" exists and it's either before "..." or "..." doesn't exist, split by "•"
  if (
    hyphenPosition !== -1 &&
    (ellipsisPosition === -1 || hyphenPosition < ellipsisPosition)
  ) {
    part1 = fullText.substring(0, hyphenPosition).trim();
    part2 = fullText.substring(hyphenPosition).trim();
    return [part1 + '\xa0', part2];
  }

  // Find longest substring match starting from the end of the fullText
  for (let i = textToFind.length; i > 0; i--) {
    const sub = textToFind.slice(-i);
    const position = fullText.lastIndexOf(sub);

    // Check if the substring was found and if it is closer to the end of fullText
    if (position !== -1 && position + sub.length === fullText.length) {
      part1 = fullText.substring(0, position);
      part2 = fullText.substring(position);
      break;
    }
  }

  // If no match was found, the entire text is part 1
  if (part1 === '' && part2 === '') {
    part1 = fullText;
  }

  return [part1, part2];
};
