import { type ComponentProps } from 'react';
import { type ColorGroup, type Variant } from 'styled-components';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { formatAsCompactNumber } from '@npm/core/ui/utils/formatters';
import { type IssuerEntityAggregate } from '@npm/data-access';

import { type CompanyCard } from '../CompanyCard';

import * as S from './Content.styles';

type Props = {
  isSelected?: boolean;
  company?: IssuerEntityAggregate;
} & Pick<ComponentProps<typeof CompanyCard>, 'variant'>;

type LabelProps = {
  colorVariant: Variant;
  label?: string;
  denomination?: string;
};

type PriceProps = {
  value?: number;
  colorGroup?: ColorGroup;
  denomination?: string;
  compact?: boolean;
};

const Label = ({ colorVariant, label, denomination }: LabelProps) => (
  <Text size="xs" colorVariant={colorVariant}>
    {label}{' '}
    {denomination && (
      <S.Currency colorVariant={colorVariant}>{denomination}</S.Currency>
    )}
  </Text>
);

const Total = ({
  label = 'Total Size',
  colorVariant,
  value,
}: LabelProps & PriceProps) => (
  <S.Total>
    <Text size="xxs" colorVariant="tertiary">
      {label}
    </Text>
    <Text size="xxs" colorVariant={colorVariant}>
      {value ? formatAsCompactNumber(value) : '--'}
    </Text>
  </S.Total>
);

export const Content = ({ isSelected, variant, company }: Props) => {
  const labelColorVariant = isSelected ? 'primary' : 'tertiary';
  const valueColorVariant = isSelected ? 'primary' : 'secondary';

  const brokerLeftColumn = (
    <S.LeftCol>
      <Label colorVariant={labelColorVariant} label="Highest Bid" />
      <Text.Price
        value={company?.bid_price_high}
        currency={company?.denomination?.name}
        color="success"
        size="default"
      />
      <Total
        colorVariant={valueColorVariant}
        value={company?.bid_price_total}
      />
    </S.LeftCol>
  );

  const brokerRightColumn = (
    <S.RightCol>
      <Label colorVariant={labelColorVariant} label="Lowest Offer" />
      <Text.Price
        value={company?.ask_price_low}
        currency={company?.denomination?.name}
        color="error"
        size="default"
      />
      <Total
        colorVariant={valueColorVariant}
        value={company?.offer_price_total}
      />
    </S.RightCol>
  );

  const investorLeftColumn = (
    <S.LeftColCentered style={{ whiteSpace: 'nowrap' }}>
      <Label
        colorVariant={labelColorVariant}
        label="Last Round Est. Valuation"
      />
      <Text.Price
        value={company?.issuer_entity_statistic?.latest_valuation}
        currency={company?.denomination?.name}
        compact
        color="info"
        size="default"
      />
    </S.LeftColCentered>
  );

  const investorRightColumn = (
    <S.RightColCentered>
      <Label
        colorVariant={labelColorVariant}
        label="Last Round Price Per Share"
      />
      <Text.Price
        value={company?.issuer_entity_statistic?.last_round_pps}
        currency={company?.denomination?.name}
        color="info"
        size="default"
      />
    </S.RightColCentered>
  );

  return (
    <S.Container>
      {variant === 'broker' ? brokerLeftColumn : investorLeftColumn}
      <S.Divider />
      {variant === 'broker' ? brokerRightColumn : investorRightColumn}
    </S.Container>
  );
};
