import { Codebooks } from './codebooks.types';

const Items = {
  all_time: 'all_time',
  last_month: 'last_month',
  last_six_months: 'last_six_months',
  last_year: 'last_year',
} as const;

export type TranasactionDate = typeof Items[keyof typeof Items];

export const CbTransactionDate = {
  code: Codebooks.TRANSACTION_DATE,
  items: Items,
};
