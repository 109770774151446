import { type ComponentProps } from 'react';
import { type ModalProps } from 'antd/lib/modal';

import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { CypressDataIds } from '@npm/core/ui/constants';
import { composeDataId } from '@npm/core/ui/utils/cypress';
import { type Holding, CbHoldingState } from '@npm/data-access';

import { type StatusChangeButtons } from './StatusChangeButtons';

import * as S from './styles';

type Props = {
  holding: Holding;
  onShowInstructions?: (props: ModalProps) => void;
  onStatusChange?: ComponentProps<typeof StatusChangeButtons>['onClick'];
  getTooltip?: (holding: Holding) => string | null;
  align?: React.CSSProperties['alignItems'];
};

export const HoldingStatus = ({
  holding,
  onShowInstructions,
  onStatusChange,
  getTooltip,
  align = 'flex-start',
}: Props) => {
  if (!holding.state) return null;

  const tooltipText = getTooltip?.(holding);

  return (
    <S.Column
      data-cy={composeDataId(
        CypressDataIds.Holdings.Properties.State,
        holding.id
      )}
      $align={align}
    >
      <Tooltip title={tooltipText}>
        {CbHoldingState.getLabel(CbLabel, holding.state, true)}
      </Tooltip>
      {holding.state.code === CbHoldingState.items.needs_verification &&
        (onShowInstructions || onStatusChange) && (
          <Text
            as="button"
            size="xs"
            onClick={() => {
              if (onStatusChange) {
                onStatusChange({
                  type: 'reject',
                  id: holding.id,
                  certificate_number: holding.certificate_number,
                });
              } else {
                onShowInstructions({
                  title: `Correction Instructions for ${holding.certificate_number}`,
                  children: holding.correction_instructions,
                });
              }
            }}
            style={{ marginLeft: 'auto' }}
            data-cy={CypressDataIds.Holdings.Button.CorrectionInstructions}
          >
            Correction Instructions
          </Text>
        )}
    </S.Column>
  );
};
