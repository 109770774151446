import { type ComponentProps } from 'react';

import {
  type FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';

import * as S from './TooltipFormItem.styles';

type Props = ComponentProps<typeof FormItem> & { required?: boolean };

export const TooltipFormItem = ({
  children,
  required = true,
  ...rest
}: Props) => {
  return (
    <S.FormItem
      rules={required ? [VALIDATION_RULES.required()] : undefined}
      {...rest}
    >
      {children}
    </S.FormItem>
  );
};
