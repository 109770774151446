import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';

import * as S from './PointLineIcon.styles';

type Props = {
  color: string;
};

export const PointLineIcon = ({ color }: Props) => (
  <Flex align={'center'}>
    <S.CircleIcon $color={color} />
    <S.LineIcon $color={color} />
  </Flex>
);
