import { useTheme } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { Table } from '@npm/core/ui/components/molecules/Table';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import { type VenusApi } from '@npm/data-access';

import {
  DocumentViewerModal,
  useDocumentViewer,
} from '../../../documents/DocumentViewer';
import { useHandleFilingClick } from '../CompanyOverview.hooks';
import { PointLineIcon } from '../components/PointLineIcon';
import {
  TableScrollIndicator,
  useScrollIndicatorClick,
} from '../components/TableScrollIndicator';

import { getValuations409AColor } from './Valuations409A.utils';

const TABLE_MAX_HEIGHT = 280;
const TABLE_ID = 'valuations-table';

type Props = {
  venusCompanyId: number | undefined;
  issuerEntityId: number | undefined;
  data: VenusApi.FairMarketValueIndex | undefined;
};

export const Valuations409ATable = ({
  venusCompanyId,
  issuerEntityId,
  data,
}: Props) => {
  const { isMobile } = useBreakpoints();
  const theme = useTheme();
  const showScrollIndicator = data?.['409as']?.length > 5;

  const { tableProps, scrollIndicatorProps } = useScrollIndicatorClick<
    VenusApi.FairMarketValueIndex['409as'][0]
  >(TABLE_ID, TABLE_MAX_HEIGHT, showScrollIndicator);

  const { documentViewerModalProps, showDocumentPreview } = useDocumentViewer();

  const { handleFilingClick, filingIdLoading } = useHandleFilingClick(
    venusCompanyId,
    issuerEntityId,
    showDocumentPreview
  );

  return (
    <Card
      variant="secondMarket"
      header={{
        title: (
          <Flex direction="row" gap={'sm'} align={'center'}>
            <PointLineIcon color={getValuations409AColor(theme)} />
            <Heading variant="h3">
              409A Valuations{' '}
              {data?.['409as'] && (
                <span
                  style={{ color: theme.color.general.typography.tertiary }}
                >
                  ({data['409as'].length})
                </span>
              )}
            </Heading>
          </Flex>
        ),
      }}
      style={{ position: 'relative' }}
      noContentPadding
    >
      <Table
        dataSource={data?.['409as'] ?? []}
        columns={[
          {
            key: 'filing_date',
            title: 'Filing Date',
            render: (_, { filing_date }) =>
              formatDate(filing_date, {
                dateFormat: DATE_FORMATS.DATE,
              }),
          },
          {
            key: 'price_per_share',
            title: 'PPS',
            render: (_, { price_per_share }) => (
              <Text.Price value={price_per_share} />
            ),
          },
          {
            key: 'actions',
            title: '',
            render: (_, { filing_id }) => (
              <Button
                variant="text"
                size="md"
                icon={<Icon name="file" />}
                onClick={() => handleFilingClick(filing_id)}
                loading={filingIdLoading === filing_id}
                block
              >
                {isMobile ? undefined : 'View Document'}
              </Button>
            ),
            width: isMobile ? 60 : 160,
          },
        ]}
        outlined={false}
        {...tableProps}
      />
      {showScrollIndicator && (
        <TableScrollIndicator {...scrollIndicatorProps} />
      )}
      <DocumentViewerModal {...documentViewerModalProps} />
    </Card>
  );
};
