import React, { type ComponentProps } from 'react';
import { useTheme } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { BigNumberValue } from '@npm/core/ui/components/molecules/BigNumberValue';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { TableVertical } from '@npm/core/ui/components/molecules/TableVertical';
import { type Watchlist, CbWorkstationType } from '@npm/data-access';

import { useUserContextStore } from '../../../../../../auth/user/context';
import {
  useCurrentWorkstation,
  useObo,
} from '../../../../../../auth/user/role';
import { type WatchlistConfig } from '../../../../useWatchlistConfig.hooks';
import { type ActionConfigProps } from '../../../WatchlistList.types';

import * as S from './WatchlistCard.styles';

type Props = {
  watchlist?: Watchlist;
  rows: WatchlistConfig;
  isLoading?: boolean;
  header?: {
    companyLogoProps?: {
      linkProps: Omit<
        ComponentProps<typeof CompanyLogo>['linkProps'],
        'companyId'
      >;
    };
  };
} & ActionConfigProps;

export const WatchlistCard = ({
  watchlist,
  rows,
  isLoading,
  onEditClick,
  onDeleteClick,
  header,
}: Props) => {
  const theme = useTheme();

  const workstation = useCurrentWorkstation();
  const { isObo } = useObo();
  const isInvWS =
    workstation?.type?.code === CbWorkstationType.items.investor && !isObo;
  const isSponsoredInvestor = useUserContextStore(
    store => !!store.sponsorshipType
  );

  return (
    <Card
      variant="secondMarket"
      style={{ marginBottom: theme.spacing.sm }}
      isLoading={isLoading}
      header={{
        title: (
          <CompanyLogo
            url={watchlist?.issuer_entity?.logo_url}
            name={watchlist?.issuer_entity?.name}
            size="xl"
            linkProps={{
              ...header?.companyLogoProps?.linkProps,
              companyId: watchlist?.issuer_entity?.id,
              prefix:
                isInvWS && isSponsoredInvestor
                  ? '/second-market/sponsored-investor'
                  : isInvWS
                  ? '/investor-workstation'
                  : undefined,
            }}
          />
        ),
      }}
      {...(watchlist?.user_can_edit_watchlist && {
        footer: (
          <Flex>
            <Button
              block
              variant="outline"
              onClick={() =>
                onEditClick(watchlist?.issuer_entity_id, watchlist?.account_id)
              }
            >
              Edit
            </Button>
            <Margin left={'xs'}>
              <Button
                variant="text"
                color="error"
                icon={<Icon name="trash" />}
                onClick={() =>
                  onDeleteClick(
                    watchlist?.issuer_entity_id,
                    watchlist?.account_id,
                    watchlist?.issuer_entity?.name
                  )
                }
              />
            </Margin>
          </Flex>
        ),
      })}
    >
      <S.CardContent>
        <S.CardRow>
          <BigNumberValue
            variant="lg"
            compactFormat
            title="Size"
            value={watchlist?.minimum_size ?? 0}
            currency={'USD'}
          />
        </S.CardRow>
        <TableVertical<Watchlist> rows={rows} data={watchlist} />
      </S.CardContent>
    </Card>
  );
};
