import { useCallback, useState } from 'react';

import { useDocumentShow } from '@npm/data-access';

import { type Document } from './DocumentViewer.types';

const getNpmDocumentId = (doc: Document) =>
  doc?.type === 'with-npm-id' ? doc.id : null;

export const useDocumentViewer = () => {
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const [previewDocument, setPreviewDocument] = useState<Document>(null);
  const [documentMetadata, setDocumentMetadata] = useState<{
    displayName: string | null | undefined;
    downloadable: boolean | null | undefined;
  }>();

  useDocumentShow(
    {
      id: getNpmDocumentId(previewDocument),
    },
    {
      onComplete: data => {
        setDocumentMetadata({
          downloadable: data?.downloadable,
          displayName: data?.display_name,
        });
      },
      queryConfig: {
        enabled: !!getNpmDocumentId(previewDocument),
      },
    }
  );

  const showDocumentPreview = useCallback(
    (document: Document | null | undefined) => {
      if (document) {
        setPreviewModalOpen(true);
        setPreviewDocument(document);
      }
    },
    [setPreviewModalOpen, setPreviewDocument]
  );

  const showNpmDocument = useCallback(
    (
      id?:
        | number
        | {
            id?: number;
          }
    ) => {
      const documentId = typeof id === 'number' ? id : id?.id;

      if (documentId) {
        showDocumentPreview({
          type: 'with-npm-id',
          id: documentId,
        });
      }
    },
    [showDocumentPreview]
  );

  const onClose = useCallback(() => {
    setPreviewModalOpen(false);
    setPreviewDocument(null);
    setDocumentMetadata(null);
  }, [setPreviewModalOpen, setPreviewDocument]);

  return {
    showDocumentPreview,
    showNpmDocument,
    documentViewerModalProps: {
      handleCancel: onClose,
      document: previewDocument,
      documentMetadata,
      isOpen: isPreviewModalOpen,
    },
  };
};
