import React, { type ComponentProps } from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { type FormItem } from '@npm/core/ui/components/atoms/FormItem';

import { TooltipFormItem } from '../TooltipFormItem';

export const SpacedFormItem = (props: ComponentProps<typeof FormItem>) => {
  return (
    <Margin bottom="lg">
      <TooltipFormItem {...props}>{props.children}</TooltipFormItem>
    </Margin>
  );
};
