import styled from 'styled-components';

import { Card as CardBase } from '@npm/core/ui/components/molecules/Card';
import { useBreakpoint } from '@npm/core/ui/utils/style';

export const Card = styled(CardBase)`
  position: relative;
  overflow: hidden;
  background-image: ${({ theme }) =>
    theme.isDarkMode
      ? 'url(/public/assets/company-data/graph-dark.png)'
      : 'url(/public/assets/company-data/graph-light.png)'};
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;

  ${({ theme }) => useBreakpoint(theme).tablet`
    min-height: 250px;
    background-size: 75%;
  `}

  ${({ theme }) => useBreakpoint(theme).mobile`
    background-size: contain;
  `}
`;
