/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IssuancesConvertibleIndex } from '../model';
// @ts-ignore
import { IssuancesConvertibleShowAggregate } from '../model';
/**
 * ConvertibleIssuancesApi - axios parameter creator
 * @export
 */
export const ConvertibleIssuancesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get convertible issuance with ocf_id
         * @param {string} issuerNpmGuid 
         * @param {string} ocfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConvertibleIssuance: async (issuerNpmGuid: string, ocfId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerNpmGuid' is not null or undefined
            assertParamExists('getConvertibleIssuance', 'issuerNpmGuid', issuerNpmGuid)
            // verify required parameter 'ocfId' is not null or undefined
            assertParamExists('getConvertibleIssuance', 'ocfId', ocfId)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_npm_guid}/issuances/convertibles/{ocf_id}`)
                .replace(`{${"issuer_npm_guid"}}`, encodeURIComponent(String(issuerNpmGuid)))
                .replace(`{${"ocf_id"}}`, encodeURIComponent(String(ocfId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all convertible issuances
         * @param {string} issuerNpmGuid 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [stakeholderOcfId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConvertibleIssuances: async (issuerNpmGuid: string, page?: number, size?: number, stakeholderOcfId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerNpmGuid' is not null or undefined
            assertParamExists('listConvertibleIssuances', 'issuerNpmGuid', issuerNpmGuid)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_npm_guid}/issuances/convertibles`)
                .replace(`{${"issuer_npm_guid"}}`, encodeURIComponent(String(issuerNpmGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (stakeholderOcfId !== undefined) {
                localVarQueryParameter['stakeholder_ocf_id'] = stakeholderOcfId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConvertibleIssuancesApi - functional programming interface
 * @export
 */
export const ConvertibleIssuancesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConvertibleIssuancesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get convertible issuance with ocf_id
         * @param {string} issuerNpmGuid 
         * @param {string} ocfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConvertibleIssuance(issuerNpmGuid: string, ocfId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuancesConvertibleShowAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConvertibleIssuance(issuerNpmGuid, ocfId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all convertible issuances
         * @param {string} issuerNpmGuid 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [stakeholderOcfId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConvertibleIssuances(issuerNpmGuid: string, page?: number, size?: number, stakeholderOcfId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuancesConvertibleIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConvertibleIssuances(issuerNpmGuid, page, size, stakeholderOcfId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConvertibleIssuancesApi - factory interface
 * @export
 */
export const ConvertibleIssuancesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConvertibleIssuancesApiFp(configuration)
    return {
        /**
         * 
         * @summary get convertible issuance with ocf_id
         * @param {string} issuerNpmGuid 
         * @param {string} ocfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConvertibleIssuance(issuerNpmGuid: string, ocfId: string, options?: any): AxiosPromise<IssuancesConvertibleShowAggregate> {
            return localVarFp.getConvertibleIssuance(issuerNpmGuid, ocfId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all convertible issuances
         * @param {string} issuerNpmGuid 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [stakeholderOcfId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConvertibleIssuances(issuerNpmGuid: string, page?: number, size?: number, stakeholderOcfId?: string, options?: any): AxiosPromise<IssuancesConvertibleIndex> {
            return localVarFp.listConvertibleIssuances(issuerNpmGuid, page, size, stakeholderOcfId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getConvertibleIssuance operation in ConvertibleIssuancesApi.
 * @export
 * @interface ConvertibleIssuancesApiGetConvertibleIssuanceRequest
 */
export interface ConvertibleIssuancesApiGetConvertibleIssuanceRequest {
    /**
     * 
     * @type {string}
     * @memberof ConvertibleIssuancesApiGetConvertibleIssuance
     */
    readonly issuerNpmGuid: string

    /**
     * 
     * @type {string}
     * @memberof ConvertibleIssuancesApiGetConvertibleIssuance
     */
    readonly ocfId: string
}

/**
 * Request parameters for listConvertibleIssuances operation in ConvertibleIssuancesApi.
 * @export
 * @interface ConvertibleIssuancesApiListConvertibleIssuancesRequest
 */
export interface ConvertibleIssuancesApiListConvertibleIssuancesRequest {
    /**
     * 
     * @type {string}
     * @memberof ConvertibleIssuancesApiListConvertibleIssuances
     */
    readonly issuerNpmGuid: string

    /**
     * 
     * @type {number}
     * @memberof ConvertibleIssuancesApiListConvertibleIssuances
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ConvertibleIssuancesApiListConvertibleIssuances
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ConvertibleIssuancesApiListConvertibleIssuances
     */
    readonly stakeholderOcfId?: string
}

/**
 * ConvertibleIssuancesApi - object-oriented interface
 * @export
 * @class ConvertibleIssuancesApi
 * @extends {BaseAPI}
 */
export class ConvertibleIssuancesApi extends BaseAPI {
    /**
     * 
     * @summary get convertible issuance with ocf_id
     * @param {ConvertibleIssuancesApiGetConvertibleIssuanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConvertibleIssuancesApi
     */
    public getConvertibleIssuance(requestParameters: ConvertibleIssuancesApiGetConvertibleIssuanceRequest, options?: AxiosRequestConfig) {
        return ConvertibleIssuancesApiFp(this.configuration).getConvertibleIssuance(requestParameters.issuerNpmGuid, requestParameters.ocfId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all convertible issuances
     * @param {ConvertibleIssuancesApiListConvertibleIssuancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConvertibleIssuancesApi
     */
    public listConvertibleIssuances(requestParameters: ConvertibleIssuancesApiListConvertibleIssuancesRequest, options?: AxiosRequestConfig) {
        return ConvertibleIssuancesApiFp(this.configuration).listConvertibleIssuances(requestParameters.issuerNpmGuid, requestParameters.page, requestParameters.size, requestParameters.stakeholderOcfId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type GetConvertibleIssuanceErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetConvertibleIssuanceErrorCodes = [
];

export type ListConvertibleIssuancesErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const ListConvertibleIssuancesErrorCodes = [
    404,
];


