import React, { useRef } from 'react';
import { useTheme } from 'styled-components';

import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { Search } from '@npm/core/ui/components/atoms/Search';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useCurrentRoute } from '@npm/core/ui/hooks/useCurrentRoute';
import {
  type UserRoleAggregate,
  CbWorkstationType,
  useUserRoleIndex,
} from '@npm/data-access';

import { useGetWorkstationLandingPage } from '../../../../../workstations/workstationLandingPage.hooks';
import { useCurrentRole } from '../../../role/hooks/useCurrentRole';
import { useCurrentWorkstation } from '../../../role/hooks/useCurrentWorkstation';
import { USER_ROLE_INDEX_BASE_PARAMS, useUserContextStore } from '../../store';
import { RoleSwitcherButton } from '../RoleSwitcherButton';

import * as S from './IssueSwitcher.styles';

const SEARCH_INPUT_USER_ROLES_THRESHOLD = 5;

type Props = {
  disabled: boolean;
  isCollapsed: boolean;
};

export const IssuerSwitcher = ({ disabled, isCollapsed }: Props) => {
  const theme = useTheme();
  const { isMobile } = useBreakpoints();

  const currentRoute = useCurrentRoute();

  // total issuer roles count without search filter applied
  const issuerRolesTotalCount = useRef<number>();

  const setRole = useUserContextStore(store => store.setRole);
  const workstation = useCurrentWorkstation();
  const currentRole = useCurrentRole();
  const context = useUserContextStore();
  const { data, isLoading } = useUserRoleIndex(
    {
      ...USER_ROLE_INDEX_BASE_PARAMS,
    },
    {
      queryConfig: {
        onSuccess: data => {
          if (!issuerRolesTotalCount.current) {
            issuerRolesTotalCount.current = data.pagination?.total_records;
          }
        },
      },
    }
  );
  const [searchTerm, setSearchTerm] = React.useState('');
  const getWorkstationLandingPage = useGetWorkstationLandingPage();

  const filteredUserRoles = React.useMemo(() => {
    if (!searchTerm) return data?.user_roles ?? [];
    return (data?.user_roles ?? []).filter(userRole =>
      userRole.subject?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [data, searchTerm]);

  const handleRoleSelect = async (nextRole: UserRoleAggregate | null) => {
    const isLocalScope = currentRoute?.route.isLocalScope;
    const firstParentPath = currentRoute?.parents?.[0]?.path;
    const defaultRedirect = await getWorkstationLandingPage(
      CbWorkstationType.items.issuer,
      context,
      nextRole
    );
    const redirectTo = isLocalScope
      ? firstParentPath || defaultRedirect
      : defaultRedirect;

    setRole(nextRole, workstation, { redirectTo });
  };

  if (isLoading) return null;

  const isTriggerDisabled = disabled || data?.user_roles?.length < 2;
  const trigger = (
    <RoleSwitcherButton
      disabled={isTriggerDisabled}
      isCollapsed={isCollapsed}
    />
  );

  if (isTriggerDisabled) return trigger;

  const shouldShowSearchInput =
    data?.user_roles?.length > SEARCH_INPUT_USER_ROLES_THRESHOLD;

  return (
    <Dropdown
      placement="bottom"
      fixedPosition
      zIndex="aboveAll"
      menuProps={{
        selectable: true,
        style: S.getMenuStyle(theme, shouldShowSearchInput),
      }}
      overlayHeader={
        shouldShowSearchInput && (
          <S.IssueSwitcherSearchWrapper>
            <Search
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Search for a company"
            />
          </S.IssueSwitcherSearchWrapper>
        )
      }
      noDataArea={
        shouldShowSearchInput && (
          <NoDataArea
            style={!isMobile ? { maxWidth: '220px' } : undefined}
            verticalPadding={15}
            title={
              "We couldn't find any results with your search criteria, please, try with different attributes."
            }
          />
        )
      }
      items={filteredUserRoles.map(userRole => {
        const key = userRole.id;
        const selected = key === currentRole?.id;

        return {
          key,
          selected,
          label: userRole.subject?.name,
          description: userRole.role_name?.name,
          icon: (
            <CompanyLogo
              url={userRole.subject?.logo_url}
              title={userRole.subject?.name}
              size="sm"
            />
          ),
          onClick: () => handleRoleSelect(userRole),
          'data-dd-privacy': 'mask',
          'data-dd-action-name': 'Issuer Role Button',
        };
      })}
      dataCy={CypressDataIds.RoleSwitcher.Drawer.Content}
    >
      {trigger}
    </Dropdown>
  );
};
