import { useMemo } from 'react';

import { useUserContextStore } from '../../context';
import { useUserRole } from '../context/userRole.context';

export const useCurrentPersonId = () => {
  const userRole = useUserRole();
  const user = useUserContextStore(s => s.user);

  const oboPersonId =
    userRole?.workstationType === 'brokerage' &&
    userRole?.subRole?.type === 'obo'
      ? userRole?.subRole?.representative?.person_id
      : undefined;

  return useMemo(() => {
    return (
      oboPersonId ||
      (userRole?.workstationType === 'investor' ? user?.person?.id : null)
    );
  }, [oboPersonId, user, userRole?.workstationType]);
};
