import Big from 'big.js';

import {
  type OrderItemUnitCode,
  type SecondmarketOrderItemShow,
  CbOrderItemUnit,
} from '@npm/data-access';

import type { OrderSizeType } from '../order.types';

type NullableNumber = number | undefined;

export const toUSD = (quantity: number, pps: number) =>
  new Big(quantity).times(pps).toNumber();
export const toShares = (quantity: number, pps: number) =>
  Math.round(quantity / pps);

type ParserArgs = {
  sizeType: OrderSizeType;
  quantity?: NullableNumber;
  minimumQuantity?: NullableNumber;
  pps?: NullableNumber;
  quantityUnit?: OrderItemUnitCode;
  orderItem?: Pick<
    SecondmarketOrderItemShow,
    'minimum_quantity' | 'quantity' | 'price' | 'unit'
  >;
};

export const parseOrderSizeRange = ({
  sizeType,
  orderItem,
  minimumQuantity = orderItem?.minimum_quantity,
  quantity = orderItem?.quantity,
  pps = orderItem?.price,
  quantityUnit = orderItem?.unit?.code as OrderItemUnitCode,
}: ParserArgs): [number, number] | undefined => {
  const quantities = [minimumQuantity, quantity] as [number, number];
  if (!quantities.some(Boolean)) return undefined;
  if (quantityUnit) {
    if (
      (sizeType === 'Shares' &&
        quantityUnit === CbOrderItemUnit.items.SHARES) ||
      (sizeType === 'USD' && quantityUnit === CbOrderItemUnit.items.USD)
    ) {
      return quantities;
    } else if (!pps) {
      return undefined;
    }
  }

  return sizeType === 'USD'
    ? (quantities.map(q => (q && pps ? toUSD(q, pps) : q)) as [number, number])
    : quantities;
};

export const parseOrderSize = ({
  sizeType,
  orderItem,
  quantity = orderItem?.quantity,
  pps = orderItem?.price,
  quantityUnit = orderItem?.unit?.code as OrderItemUnitCode,
}: Omit<ParserArgs, 'minimumQuantity'>) => {
  if (quantityUnit) {
    if (
      (sizeType === 'Shares' &&
        quantityUnit === CbOrderItemUnit.items.SHARES) ||
      (sizeType === 'USD' && quantityUnit === CbOrderItemUnit.items.USD)
    ) {
      return quantity;
    } else if (!pps) {
      return undefined;
    }
  }

  if (!(quantity && pps)) return quantity;

  return sizeType === 'USD' ? toUSD(quantity, pps) : quantity;
};

export const sizeInShares = (
  sizeType: OrderSizeType,
  quantity: NullableNumber,
  pps: NullableNumber
) => {
  if (sizeType !== 'Shares' && !pps) return undefined; // Cannot convert without PPS
  if (!quantity) return quantity;
  return sizeType === 'Shares' ? Math.round(quantity) : toShares(quantity, pps);
};

export const getReversedOrderSizeType = (currentSizeType: OrderSizeType) =>
  currentSizeType === 'Shares' ? 'USD' : 'Shares';

export const reverseOrderSize = (
  reverseSizeType: OrderSizeType,
  quantity: NullableNumber,
  pps: NullableNumber
) => {
  if (!(quantity && pps)) return null;

  return reverseSizeType === 'USD'
    ? toUSD(quantity, pps)
    : toShares(quantity, pps);
};

export const normalizeSize = ({
  sizeType,
  minimumQuantity,
  quantity,
  pricePerShare,
}: {
  sizeType: OrderSizeType;
  minimumQuantity?: number;
  quantity?: number;
  pricePerShare?: number;
}) => {
  const normalizedMinSizeShares = sizeInShares(
    sizeType,
    minimumQuantity,
    pricePerShare
  );

  const normalizedSizeShares = sizeInShares(sizeType, quantity, pricePerShare);

  // (Min.) Size (USD) should be recalculated based on rounded size in shares
  // rather than taken from the input fields.
  // However, if PPS is undefined, we display the input values.
  const normalizedMinSizeUsd =
    normalizedMinSizeShares && pricePerShare
      ? toUSD(normalizedMinSizeShares, pricePerShare)
      : sizeType === 'USD'
      ? minimumQuantity
      : undefined;

  const normalizedSizeUsd =
    normalizedSizeShares && pricePerShare
      ? toUSD(normalizedSizeShares, pricePerShare)
      : sizeType === 'USD'
      ? quantity
      : undefined;

  return {
    Shares: [normalizedMinSizeShares, normalizedSizeShares],
    USD: [normalizedMinSizeUsd, normalizedSizeUsd],
  } satisfies { [K in OrderSizeType]: [number, number] };
};
