import { useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useLocation } from 'react-router';

import { useCurrentRoute } from '@npm/core/ui/hooks/useCurrentRoute';
import {
  type WorkstationIndex,
  CbWorkstationType,
  getApiErrorCode,
  useAccountIndex,
  useUserRoleIndex,
} from '@npm/data-access';

import {
  InitialLoadingPart,
  useInitialLoadingStore,
} from '../../../../../app/initialLoading';
import {
  USER_ROLE_INDEX_BASE_PARAMS,
  useUserContextStore,
} from '../../../context';

const isErrorPath = (location: string) => {
  const regex = /^\/([4-5]\d{2})/g;
  return regex.test(location);
};

export const useLoadInvestorAccounts = (
  workstationsData: WorkstationIndex,
  workstationDataLoading: boolean
) => {
  const handleError = useErrorHandler();

  const finishLoadingPartOfApp = useInitialLoadingStore(
    store => store.finishLoadingPart
  );

  const setInvestorAccounts = useUserContextStore(
    store => store.setInvestorAccounts
  );

  const investorAccounts = useUserContextStore(store => store.investorAccounts);

  const setHasActiveEvents = useUserContextStore(
    store => store.setHasActiveEvents
  );

  const currentRoute = useCurrentRoute();
  const { pathname } = useLocation();

  const canAccessInvWs = workstationsData?.workstations.find(
    w => w.type?.code === CbWorkstationType.items.investor
  );

  const shouldLoadInvestorAccounts = !(
    currentRoute?.route?.needUser === false ||
    currentRoute?.route?.needLogin === false ||
    isErrorPath(pathname) ||
    !canAccessInvWs
  );

  // removed check of investorAccounts - we want to be sure that we
  // are able to invalidate the cache and reload the data after opt-in
  const { data: invWsUserRoles, isLoading: areInvWsUserRolesLoading } =
    useUserRoleIndex(USER_ROLE_INDEX_BASE_PARAMS, {
      queryConfig: {
        enabled: !workstationDataLoading && shouldLoadInvestorAccounts,
      },
      roles: {
        acrossAccount: true,
        workstation: 'investor',
      },
    });

  const {
    data: accountsWithActiveEvents,
    isLoading: areAccountsWithActiveEventsLoading,
  } = useAccountIndex(
    {
      hasActiveEvents: true,
      size: 0,
    },
    {
      queryConfig: {
        enabled: shouldLoadInvestorAccounts,
      },
      onError: e => {
        // ignoring 441 because it's handled in userContext
        if (getApiErrorCode(e) !== 441) {
          handleError(e);
        } else {
          finishLoadingPartOfApp(InitialLoadingPart.InvestorAccounts);
        }
      },
    }
  );

  const hasAccountWithLiveEvents =
    !!accountsWithActiveEvents?.pagination?.total_records;

  useEffect(() => {
    if (
      currentRoute?.route?.needUser === false ||
      currentRoute?.route?.needLogin === false ||
      isErrorPath(pathname)
    ) {
      finishLoadingPartOfApp(InitialLoadingPart.InvestorAccounts);
    }

    if (workstationDataLoading) {
      return;
    }

    if (!shouldLoadInvestorAccounts) {
      setInvestorAccounts(null);
      setHasActiveEvents(null);
      finishLoadingPartOfApp(InitialLoadingPart.InvestorAccounts);
      return;
    }

    if (areInvWsUserRolesLoading || areAccountsWithActiveEventsLoading) {
      return;
    }

    setInvestorAccounts(invWsUserRoles?.user_roles);
    setHasActiveEvents(hasAccountWithLiveEvents);
    finishLoadingPartOfApp(InitialLoadingPart.InvestorAccounts);
  }, [
    currentRoute?.route,
    pathname,
    invWsUserRoles,
    areInvWsUserRolesLoading,
    hasAccountWithLiveEvents,
    areAccountsWithActiveEventsLoading,
    workstationDataLoading,
    shouldLoadInvestorAccounts,
  ]);

  if (
    currentRoute?.route?.needUser === false ||
    currentRoute?.route?.needLogin === false ||
    isErrorPath(pathname)
  ) {
    return {
      isLoading: false,
    };
  }

  // user roles are loading or in stores there are wrong data (because of useEffect)
  const isLoading =
    canAccessInvWs &&
    (areInvWsUserRolesLoading ||
      workstationDataLoading ||
      investorAccounts !== invWsUserRoles?.user_roles);

  return {
    isLoading,
  };
};
