import { EMPTY_VALUE } from '@npm/core/ui/components/atoms/Typography';
import { type VenusApi } from '@npm/data-access';

export const getInvestmentName = (
  company: VenusApi.Company,
  stockClass: VenusApi.Company['stock_classes'][number]
) => {
  return company
    ? `${company.name} ${stockClass ? `- ${stockClass?.name} ` : ''}`
    : EMPTY_VALUE;
};

export const useFilteredFinancialActivityData = (
  data: VenusApi.FinancialActivityIndex | undefined
) => {
  return data?.financial_activity.filter(activity => isValidEntry(activity));
};

const isValidEntry = (activity: VenusApi.FinancialActivity) => {
  return (
    !activity.unclassified &&
    activity.class === 'investment' &&
    activity.investment_amount &&
    activity.date
  );
};
