import { type ComponentProps } from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import { StepWrapper } from './Step.styles';

type StepProps = {
  iconName: ComponentProps<typeof Icon>['name'];
  label: React.ReactNode;
};

export const Step = ({ label, iconName }: StepProps) => (
  <StepWrapper>
    <Icon name={iconName} />
    <Text size="xs" colorVariant="primary">
      {label}
    </Text>
  </StepWrapper>
);
