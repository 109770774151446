import { Spin as SpinBase } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div<{ $wrapperHeight?: string }>`
  display: grid;
  place-content: center;
  ${({ $wrapperHeight = '100%' }) => `height: ${$wrapperHeight};`}
`;
export const Spin = styled(SpinBase)`
  width: ${({ theme }) => theme.typography.height.xs}px;
  .ant-spin-dot {
    color: ${({ theme }) => theme.color.info.typography.primary};
  }
`;
