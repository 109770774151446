import React, { type ComponentProps, useMemo } from 'react';

import { Select } from '@npm/core/ui/components/atoms/Select';
import { onPopupScroll } from '@npm/core/ui/components/atoms/Select/Select.utils';
import { useIssuerEntityInvestorIndexInfinite } from '@npm/data-access';

type Props = ComponentProps<typeof Select>;

export const IssuerEntityInvestorsSearch = (props: Props) => {
  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    error,
    refetch,
  } = useIssuerEntityInvestorIndexInfinite({}, { onError: Select.onError });

  const options = useMemo(() => {
    if (!data) return null;

    const issuerEntityInvestors = data.pages.flatMap(
      page => page.issuer_entity_investors
    );

    return [
      ...issuerEntityInvestors.map(investor => ({
        label: investor,
        value: investor,
      })),
    ];
  }, [data]);

  return (
    <Select
      variant="search"
      allowClear
      loading={isLoading}
      infiniteLoading={isFetchingNextPage}
      onPopupScroll={e =>
        onPopupScroll(e, hasNextPage && !isFetchingNextPage && fetchNextPage)
      }
      placeholder="Search"
      options={options}
      filterOption={true}
      error={error}
      refetch={refetch}
      {...props}
    />
  );
};
