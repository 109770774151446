import React, { type ComponentProps } from 'react';

import { NoDataArea } from '../OrderPlacementForm.styles';

type Props = {
  companyName: string;
} & ComponentProps<typeof NoDataArea>;

export const NoHoldingsFound = ({ companyName, ...rest }: Props) => {
  return (
    <NoDataArea
      outlined
      iconColor="error"
      heading="No holdings found"
      title={`Click below to add a holding in ${companyName}`}
      onClickText="Add new holding"
      {...rest}
    />
  );
};
