import React, { memo } from 'react';
import { Route, Switch } from 'react-router';

import { routes } from '@npm/core/ui/routes/router.constants';
import { CbWorkstationType } from '@npm/data-access';
import { useCurrentWorkstation, useObo } from '@npm/features/auth/user/role';
import { ErrorPath } from '@npm/features/routes/components/ErrorPath';
import {
  OnboardingRouter,
  registerRoutes as registerOnboardingRoutes,
} from '@npm/onboarding/router';
import {
  PreplatformRouter,
  registerRoutes as registerPreplatformRoutes,
} from '@npm/preplatform/router';
import {
  registerRoutes as registerSecondMarketRoutes,
  SecondMarketRouter,
} from '@npm/second-market/router';
import {
  BrokerageRouter,
  registerRoutes as registerBrokerageRoutes,
} from '@npm/workstations/brokerage/router';
import {
  InvestorRouter,
  registerRoutes as registerIssuerRoutes,
} from '@npm/workstations/investor/router';
import {
  IssuerRouter,
  registerRoutes as registerInvestorRoutes,
} from '@npm/workstations/issuer/router';

import IndexPage from '../pages';

import { GlobalRouter } from './GlobalRouter';
import { registerRoutes as registerGlobalRoutes } from './routes.global';

registerGlobalRoutes(routes);
registerBrokerageRoutes(routes);
registerIssuerRoutes(routes);
registerInvestorRoutes(routes);
registerOnboardingRoutes(routes);
registerSecondMarketRoutes(routes);
registerPreplatformRoutes(routes);

export const Router = memo(function Router() {
  const workstation = useCurrentWorkstation();
  const { isObo } = useObo();

  return (
    <Switch>
      <Route path="/" component={IndexPage} exact />
      <Route path="/brokerage-workstation">
        <BrokerageRouter
          enabled={
            workstation?.type?.code === CbWorkstationType.items.brokerage ||
            isObo
          }
        />
      </Route>
      <Route path="/investor-workstation">
        <InvestorRouter
          enabled={
            workstation?.type?.code === CbWorkstationType.items.investor ||
            isObo
          }
        />
      </Route>
      <Route path="/issuer-workstation">
        <IssuerRouter
          enabled={workstation?.type?.code === CbWorkstationType.items.issuer}
        />
      </Route>
      <Route path={['/onboarding', '/post-onboarding']}>
        <OnboardingRouter />
      </Route>
      <Route path="/second-market">
        <SecondMarketRouter />
      </Route>
      <Route path="/preplatform">
        <PreplatformRouter />
      </Route>
      <GlobalRouter />
      <Route>
        <ErrorPath
          heading="Error"
          title="Sorry, the page you visited does not exist."
          status="404"
        />
      </Route>
    </Switch>
  );
});
