import { memo } from 'react';
import { type SiderProps } from 'antd';

import { useLayoutConfig } from '../Layout.hooks';

import { useSidebarStore } from './Sidebar.store';

import * as S from './Sidebar.styles';
import {
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
} from './Sidebar.styles';

type Props = {
  className?: string;
} & Omit<SiderProps, 'theme'>;

export const Sidebar = memo(function Sidebar({
  className,
  collapsible,
  width = SIDEBAR_EXPANDED_WIDTH,
  children,
  ...siderProps
}: Props) {
  const open = useSidebarStore(store => store.open);

  const layoutConfig = useLayoutConfig();
  const { isMobile, isTablet } = layoutConfig;

  return !isMobile && !isTablet && open ? (
    <>
      <S.Wrapper
        $layoutConfig={layoutConfig}
        $isCollapsible={collapsible}
        className={`noprint ${className}`}
        collapsedWidth={SIDEBAR_COLLAPSED_WIDTH}
        trigger={null} // controlled collapse
        width={width}
        {...siderProps}
      >
        {children}
      </S.Wrapper>
      <div
        className="noprint"
        style={{
          minWidth: collapsible ? SIDEBAR_COLLAPSED_WIDTH : Number(width),
        }}
      />
      <S.SidebarTooltipGlobalStyle />
    </>
  ) : null;
});

export { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_EXPANDED_WIDTH };
