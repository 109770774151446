import React from 'react';

import { NoRepresentativeCta } from '../NoRepresentativeCta';
import { type SelectRepresentativeListProps } from '../SelectRepresentative.types';

import { columns } from './SelectRepresentativeTable.columns';

import * as S from '../../../OboModal.styles';

type Props = SelectRepresentativeListProps;

export const SelectRepresentativeTable = ({
  data,
  isLoading,
  handleSelect,
  selectedRepresentative,
  goBack,
}: Props) => {
  return (
    <S.RepresentativesTable
      rowKey={r => r.user_id}
      dataSource={data}
      columns={columns}
      rowSelection={{
        type: 'radio',
        selectedRowKeys: [selectedRepresentative?.user_id],
        onChange: (_, a) => handleSelect(a[0]),
      }}
      loading={isLoading}
      noDataArea={
        !isLoading ? <NoRepresentativeCta onClick={goBack} /> : undefined
      }
    />
  );
};
