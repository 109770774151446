import React from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { useNotificationBulkUpdate } from '@npm/data-access';

import { NoPaddingButton } from '../../NotificationDrawer.styles';

export const MarkAllReadButton = () => {
  const { withShowApiErrorMiddleware } = useAlerts();
  const { execute, isLoading } = useNotificationBulkUpdate();

  const handleClick = async () => {
    try {
      await withShowApiErrorMiddleware(execute)({
        notificationUpdateRequestContract: {
          mark_as_read: true,
        },
      });
    } catch (e) {
      console.error(e);
      return;
    }
  };

  return (
    <NoPaddingButton
      variant="text"
      icon={<Icon name="check" />}
      style={{ justifyContent: 'flex-end' }}
      loading={isLoading}
      disabled={isLoading}
      onClick={handleClick}
    >
      Mark all as Read
    </NoPaddingButton>
  );
};
