import React, { type ReactElement } from 'react';
import { type PaginationProps } from 'antd';

import { Table } from '@npm/core/ui/components/molecules/Table';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type UnpackArrayType } from '@npm/core/ui/utils/unpack.type';
import { type WatchlistIndex } from '@npm/data-access';

import { type WatchlistConfig } from '../../../useWatchlistConfig.hooks';

export const WATCHLIST_PAGINATION_QUERY_KEY = 'watchlist';

type Props = {
  columns: WatchlistConfig;
  data: WatchlistIndex;
  isLoading: boolean;
  pagination: PaginationProps;
  updatePaginationVariables: (pagination: PaginationProps) => void;
  noDataArea: ReactElement;
};

export const WatchlistTable = ({
  columns,
  data,
  isLoading,
  pagination,
  updatePaginationVariables,
  noDataArea,
}: Props) => {
  const { isSmTablet } = useBreakpoints();

  return (
    <div>
      <Table<UnpackArrayType<WatchlistIndex, 'watchlists'>>
        dataSource={data?.watchlists}
        columns={columns}
        loading={isLoading}
        pagination={pagination}
        onChange={updatePaginationVariables}
        outlined={false}
        expandable={{
          showExpandColumn: isSmTablet,
          rowExpandable: () => isSmTablet,
        }}
        noDataArea={noDataArea}
      />
    </div>
  );
};
