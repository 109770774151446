import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  DATE_FORMATS,
  formatDate,
  shorten,
} from '@npm/core/ui/utils/formatters';

import { type PrimaryRoundValue } from '../PrimaryRoundsChart.types';

import * as S from './PrimaryRoundsChartTooltip.styles';

type Props = {
  value: PrimaryRoundValue | undefined;
};

export const PrimaryRoundsChartTooltip = ({ value }: Props) => {
  if (!value) {
    return null;
  }

  return (
    <S.Wrapper>
      <Flex direction="column" gap="sm" align="stretch">
        <Flex direction="row" gap={'xs'} justify="space-between" align="center">
          <Text size={'sm'} weight={'bold'} colorVariant={'primary'}>
            {shorten(value.round_name, 22)}
          </Text>
          <Text size="xxs" align="end">
            Round Date
            <br />
            {formatDate(value.investment_date, {
              dateFormat: DATE_FORMATS.DATE,
            })}
          </Text>
        </Flex>
        <S.ContentWrapper>
          <Flex align={'center'} direction={'column'}>
            <Text size={'xs'} colorVariant={'secondary'}>
              Price Per Share
            </Text>
            <Text.Price
              size={'default'}
              colorVariant={'primary'}
              value={value.price_per_share}
              currencyAsSymbol
              formatOptions={{
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }}
            />
          </Flex>
          <Flex align={'center'} direction={'column'}>
            <Text size={'xs'} colorVariant={'secondary'}>
              Est. Valuation
            </Text>
            <Text.Price
              size={'default'}
              colorVariant={'primary'}
              value={value.valuation}
              compact
              currencyAsSymbol
            />
          </Flex>
        </S.ContentWrapper>
      </Flex>
    </S.Wrapper>
  );
};
