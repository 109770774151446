export const opportunityState = {
  match: 'match',
  transfer: 'transfer',
  getPaid: 'getPaid',
  rejected: 'rejected',
  complete: 'complete',
  rofrRejected: 'rofrRejected',
} as const;

export type OpportunityStates = keyof typeof opportunityState;
