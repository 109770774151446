import React, { type ComponentProps } from 'react';

import {
  type FilterItemProps,
  IssuerEntityInvestorsSearch,
} from '../../../../../filters';

type Props = FilterItemProps &
  ComponentProps<typeof IssuerEntityInvestorsSearch>;

export const IssuerEntityInvestorsFilter = ({
  name,
  onFilterSubmit,
  onChange,
  ...rest
}: Props) => {
  return (
    <IssuerEntityInvestorsSearch
      onChange={(...args) => {
        onChange(...args);
        onFilterSubmit?.(name);
      }}
      {...rest}
    />
  );
};
