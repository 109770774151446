import React, { type ReactElement } from 'react';
import { type AutoCompleteProps, AutoComplete } from 'antd';
import { type DefaultOptionType } from 'antd/lib/select';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

import { Search } from '../../atoms/Search';

import { getAddressFields } from './AddressAutocomplete.utils';

export type AddressAutocompleteFields = {
  address_line_1?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
};

type Props = {
  onSelect: (addressId: string, address: AddressAutocompleteFields) => void;
  placeholder?: string;
  // `AddressAutocomplete` is used as a custom form control, `value` and `onChange` need to be specified as props
  // for AntD to make the form item work correctly inside `Form`
  value?: string;
  onChange?: (value: string) => void;
};

export const AddressAutocomplete = ({
  onSelect,
  placeholder = 'Search',
  onChange,
  value: propValue,
  ...props
}: Props & Omit<AutoCompleteProps, 'placeholder'>): ReactElement => {
  const { suggestions, setValue, value } = usePlacesAutocomplete({
    debounce: 300,
  });

  /**
   * Called when an option is selected from the dropdown.
   *
   * @param value - A stringified JSON object with two keys: `description` and `placeId`
   */
  const handleSelect = async (value: string): Promise<void> => {
    const { description, placeId } = JSON.parse(value);
    /**
     * Sets value to be the text displayed on the option selected
     * When user selects a place, we can replace the keyword without request data from API
     * by setting the second parameter to "false"
     */
    setValue(description, false);

    try {
      const details = await getDetails({
        placeId,
        fields: ['adr_address', 'address_components'],
      });

      const { adr_address, address_components } = details;

      const countryFromAddressComponents = address_components.find(component =>
        component.types.includes('country')
      );
      const stateFromAddressComponents = address_components.find(component =>
        component.types.includes('administrative_area_level_1')
      );

      if (adr_address) {
        const addressComponent = getAddressFields(
          adr_address,
          countryFromAddressComponents.short_name,
          stateFromAddressComponents.short_name
        );

        onSelect(placeId, addressComponent);
        setValue('');
      }

      onChange?.(value);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChange = (value: string | number): void => {
    setValue(value.toString());
  };

  const options: DefaultOptionType[] = suggestions.data.map(
    ({ description, place_id: placeId }) => ({
      label: description?.toString(),
      value: JSON.stringify({ description, placeId }),
      'data-dd-action-name': 'Address Autocomplete Option',
    })
  );

  return (
    <AutoComplete
      value={value}
      onChange={handleChange}
      onSelect={handleSelect}
      options={options}
      autoClearSearchValue
      data-dd-action-name="Address Autocomplete Input"
      {...props}
    >
      <Search autoComplete="off" placeholder={placeholder} />
    </AutoComplete>
  );
};
