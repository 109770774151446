/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  SectorsApi,
  CompanySectorsErrorCodes,
  CompanySectorsErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new SectorsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type CompanySectorsMethod = SectorsApi['companySectors'];
export type CompanySectorsResponseType = MethodResult<CompanySectorsMethod>;

export const useCompanySectors = (
  variables: Parameters<CompanySectorsMethod>[0],
  config?: UseQueryConfig<
    CompanySectorsMethod,
    CompanySectorsErrorTypes
  >
) => {
  return useQuery<
    CompanySectorsMethod,
    CompanySectorsErrorTypes
  >(
    (...args) => api.companySectors(...args),
    CompanySectorsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanySectors'],
        ...apiQueryConfig.CompanySectors,
        ...config?.queryConfig
      },
    },
    'CompanySectors',
  );
};

export const useCompanySectorsInfinite = (
  variables: Parameters<CompanySectorsMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanySectorsMethod,
    CompanySectorsErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanySectorsMethod,
    CompanySectorsErrorTypes
  >(
    (...args) => api.companySectors(...args),
    CompanySectorsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanySectors', 'CompanySectorsInfinite'],
        ...apiQueryConfig.CompanySectors,
        ...config?.queryConfig,
      },
    },
    'CompanySectors',
  );
};

export const useCompanySectorsLazy = (baseOptions?: {
  variables?: Parameters<CompanySectorsMethod>[0],
  config?: UseQueryConfig<
    CompanySectorsMethod,
    CompanySectorsErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanySectorsMethod,
    CompanySectorsErrorTypes
  >(
    (...args) => api.companySectors(...args),
    CompanySectorsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanySectors'],
        ...apiQueryConfig.CompanySectors,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanySectors',
  );
};

