import React from 'react';
import ReCaptcha from 'react-google-recaptcha';

import { useDarkModeContext } from '../../../context/DarkModeContext';

import { GOOGLE_RECAPTCHA_SITE_KEY } from './recaptcha.env';

type Props = {
  onChange?: (val: string | null) => void;
  theme?: 'light' | 'dark';
};

export const ReCaptchaFormItem = ({ onChange, theme }: Props) => {
  const captchaRef = React.createRef<ReCaptcha>();

  const { isDarkMode } = useDarkModeContext();

  return (
    <ReCaptcha
      key={theme ?? (isDarkMode ? 'dark' : 'light')}
      theme={theme ?? (isDarkMode ? 'dark' : 'light')}
      sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
      ref={captchaRef}
      size="normal"
      onChange={val => onChange?.(val)}
      onErrored={() => onChange?.(null)}
      onExpired={() => onChange?.(null)}
    />
  );
};
