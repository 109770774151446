/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DestroySuccess } from '../model';
// @ts-ignore
import { FolderAggregate } from '../model';
// @ts-ignore
import { FolderCreateRequestContract } from '../model';
// @ts-ignore
import { FolderIndex } from '../model';
// @ts-ignore
import { FolderUpdateRequestContract } from '../model';
/**
 * FoldersApi - axios parameter creator
 * @export
 */
export const FoldersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a folder for the given owner
         * @param {FolderCreateRequestContract} folderCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderCreate: async (folderCreateRequestContract: FolderCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderCreateRequestContract' is not null or undefined
            assertParamExists('folderCreate', 'folderCreateRequestContract', folderCreateRequestContract)
            const localVarPath = changeUrl(`/api/folders`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(folderCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a specific folder
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('folderDestroy', 'id', id)
            const localVarPath = changeUrl(`/api/folders/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list folders
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [ownerType] limits results to folders of the given owner type
         * @param {number} [accountId] limits results to account and event folders of the given account ID
         * @param {number} [eventId] limits results to event folders of the given event ID
         * @param {number} [issuerEntityId] requires event type owner, limits results to folders of events with given issuer entity id
         * @param {number} [parentId] limits the results to a specific parent id
         * @param {boolean} [detailedChildren] causes the folder children folders to be returned in full detail
         * @param {boolean} [detailedDocuments] causes the folders documents to be returned in full detail
         * @param {boolean} [emptyFolders] causes the response to include empty folders
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderIndex: async (page?: number, size?: number, ownerType?: string, accountId?: number, eventId?: number, issuerEntityId?: number, parentId?: number, detailedChildren?: boolean, detailedDocuments?: boolean, emptyFolders?: boolean, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/folders`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (ownerType !== undefined) {
                localVarQueryParameter['owner_type'] = ownerType;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (eventId !== undefined) {
                localVarQueryParameter['event_id'] = eventId;
            }

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parent_id'] = parentId;
            }

            if (detailedChildren !== undefined) {
                localVarQueryParameter['detailed_children'] = detailedChildren;
            }

            if (detailedDocuments !== undefined) {
                localVarQueryParameter['detailed_documents'] = detailedDocuments;
            }

            if (emptyFolders !== undefined) {
                localVarQueryParameter['empty_folders'] = emptyFolders;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary show a specific folder
         * @param {number} id 
         * @param {boolean} [detailedChildren] causes the folder children folders to be returned in full detail
         * @param {boolean} [detailedDocuments] causes the folders documents to be returned in full detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderShow: async (id: number, detailedChildren?: boolean, detailedDocuments?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('folderShow', 'id', id)
            const localVarPath = changeUrl(`/api/folders/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (detailedChildren !== undefined) {
                localVarQueryParameter['detailed_children'] = detailedChildren;
            }

            if (detailedDocuments !== undefined) {
                localVarQueryParameter['detailed_documents'] = detailedDocuments;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a specific folder
         * @param {number} id 
         * @param {FolderUpdateRequestContract} folderUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderUpdate: async (id: number, folderUpdateRequestContract: FolderUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('folderUpdate', 'id', id)
            // verify required parameter 'folderUpdateRequestContract' is not null or undefined
            assertParamExists('folderUpdate', 'folderUpdateRequestContract', folderUpdateRequestContract)
            const localVarPath = changeUrl(`/api/folders/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(folderUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FoldersApi - functional programming interface
 * @export
 */
export const FoldersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FoldersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a folder for the given owner
         * @param {FolderCreateRequestContract} folderCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderCreate(folderCreateRequestContract: FolderCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderCreate(folderCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a specific folder
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroySuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list folders
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [ownerType] limits results to folders of the given owner type
         * @param {number} [accountId] limits results to account and event folders of the given account ID
         * @param {number} [eventId] limits results to event folders of the given event ID
         * @param {number} [issuerEntityId] requires event type owner, limits results to folders of events with given issuer entity id
         * @param {number} [parentId] limits the results to a specific parent id
         * @param {boolean} [detailedChildren] causes the folder children folders to be returned in full detail
         * @param {boolean} [detailedDocuments] causes the folders documents to be returned in full detail
         * @param {boolean} [emptyFolders] causes the response to include empty folders
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderIndex(page?: number, size?: number, ownerType?: string, accountId?: number, eventId?: number, issuerEntityId?: number, parentId?: number, detailedChildren?: boolean, detailedDocuments?: boolean, emptyFolders?: boolean, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderIndex(page, size, ownerType, accountId, eventId, issuerEntityId, parentId, detailedChildren, detailedDocuments, emptyFolders, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary show a specific folder
         * @param {number} id 
         * @param {boolean} [detailedChildren] causes the folder children folders to be returned in full detail
         * @param {boolean} [detailedDocuments] causes the folders documents to be returned in full detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderShow(id: number, detailedChildren?: boolean, detailedDocuments?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderShow(id, detailedChildren, detailedDocuments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a specific folder
         * @param {number} id 
         * @param {FolderUpdateRequestContract} folderUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderUpdate(id: number, folderUpdateRequestContract: FolderUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderUpdate(id, folderUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FoldersApi - factory interface
 * @export
 */
export const FoldersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FoldersApiFp(configuration)
    return {
        /**
         * 
         * @summary create a folder for the given owner
         * @param {FolderCreateRequestContract} folderCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderCreate(folderCreateRequestContract: FolderCreateRequestContract, options?: any): AxiosPromise<FolderAggregate> {
            return localVarFp.folderCreate(folderCreateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a specific folder
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderDestroy(id: number, options?: any): AxiosPromise<DestroySuccess> {
            return localVarFp.folderDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list folders
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [ownerType] limits results to folders of the given owner type
         * @param {number} [accountId] limits results to account and event folders of the given account ID
         * @param {number} [eventId] limits results to event folders of the given event ID
         * @param {number} [issuerEntityId] requires event type owner, limits results to folders of events with given issuer entity id
         * @param {number} [parentId] limits the results to a specific parent id
         * @param {boolean} [detailedChildren] causes the folder children folders to be returned in full detail
         * @param {boolean} [detailedDocuments] causes the folders documents to be returned in full detail
         * @param {boolean} [emptyFolders] causes the response to include empty folders
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderIndex(page?: number, size?: number, ownerType?: string, accountId?: number, eventId?: number, issuerEntityId?: number, parentId?: number, detailedChildren?: boolean, detailedDocuments?: boolean, emptyFolders?: boolean, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<FolderIndex> {
            return localVarFp.folderIndex(page, size, ownerType, accountId, eventId, issuerEntityId, parentId, detailedChildren, detailedDocuments, emptyFolders, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary show a specific folder
         * @param {number} id 
         * @param {boolean} [detailedChildren] causes the folder children folders to be returned in full detail
         * @param {boolean} [detailedDocuments] causes the folders documents to be returned in full detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderShow(id: number, detailedChildren?: boolean, detailedDocuments?: boolean, options?: any): AxiosPromise<FolderAggregate> {
            return localVarFp.folderShow(id, detailedChildren, detailedDocuments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a specific folder
         * @param {number} id 
         * @param {FolderUpdateRequestContract} folderUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderUpdate(id: number, folderUpdateRequestContract: FolderUpdateRequestContract, options?: any): AxiosPromise<FolderAggregate> {
            return localVarFp.folderUpdate(id, folderUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for folderCreate operation in FoldersApi.
 * @export
 * @interface FoldersApiFolderCreateRequest
 */
export interface FoldersApiFolderCreateRequest {
    /**
     * 
     * @type {FolderCreateRequestContract}
     * @memberof FoldersApiFolderCreate
     */
    readonly folderCreateRequestContract: FolderCreateRequestContract
}

/**
 * Request parameters for folderDestroy operation in FoldersApi.
 * @export
 * @interface FoldersApiFolderDestroyRequest
 */
export interface FoldersApiFolderDestroyRequest {
    /**
     * 
     * @type {number}
     * @memberof FoldersApiFolderDestroy
     */
    readonly id: number
}

/**
 * Request parameters for folderIndex operation in FoldersApi.
 * @export
 * @interface FoldersApiFolderIndexRequest
 */
export interface FoldersApiFolderIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof FoldersApiFolderIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof FoldersApiFolderIndex
     */
    readonly size?: number

    /**
     * limits results to folders of the given owner type
     * @type {string}
     * @memberof FoldersApiFolderIndex
     */
    readonly ownerType?: string

    /**
     * limits results to account and event folders of the given account ID
     * @type {number}
     * @memberof FoldersApiFolderIndex
     */
    readonly accountId?: number

    /**
     * limits results to event folders of the given event ID
     * @type {number}
     * @memberof FoldersApiFolderIndex
     */
    readonly eventId?: number

    /**
     * requires event type owner, limits results to folders of events with given issuer entity id
     * @type {number}
     * @memberof FoldersApiFolderIndex
     */
    readonly issuerEntityId?: number

    /**
     * limits the results to a specific parent id
     * @type {number}
     * @memberof FoldersApiFolderIndex
     */
    readonly parentId?: number

    /**
     * causes the folder children folders to be returned in full detail
     * @type {boolean}
     * @memberof FoldersApiFolderIndex
     */
    readonly detailedChildren?: boolean

    /**
     * causes the folders documents to be returned in full detail
     * @type {boolean}
     * @memberof FoldersApiFolderIndex
     */
    readonly detailedDocuments?: boolean

    /**
     * causes the response to include empty folders
     * @type {boolean}
     * @memberof FoldersApiFolderIndex
     */
    readonly emptyFolders?: boolean

    /**
     * 
     * @type {string}
     * @memberof FoldersApiFolderIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof FoldersApiFolderIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof FoldersApiFolderIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof FoldersApiFolderIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof FoldersApiFolderIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof FoldersApiFolderIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for folderShow operation in FoldersApi.
 * @export
 * @interface FoldersApiFolderShowRequest
 */
export interface FoldersApiFolderShowRequest {
    /**
     * 
     * @type {number}
     * @memberof FoldersApiFolderShow
     */
    readonly id: number

    /**
     * causes the folder children folders to be returned in full detail
     * @type {boolean}
     * @memberof FoldersApiFolderShow
     */
    readonly detailedChildren?: boolean

    /**
     * causes the folders documents to be returned in full detail
     * @type {boolean}
     * @memberof FoldersApiFolderShow
     */
    readonly detailedDocuments?: boolean
}

/**
 * Request parameters for folderUpdate operation in FoldersApi.
 * @export
 * @interface FoldersApiFolderUpdateRequest
 */
export interface FoldersApiFolderUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof FoldersApiFolderUpdate
     */
    readonly id: number

    /**
     * 
     * @type {FolderUpdateRequestContract}
     * @memberof FoldersApiFolderUpdate
     */
    readonly folderUpdateRequestContract: FolderUpdateRequestContract
}

/**
 * FoldersApi - object-oriented interface
 * @export
 * @class FoldersApi
 * @extends {BaseAPI}
 */
export class FoldersApi extends BaseAPI {
    /**
     * 
     * @summary create a folder for the given owner
     * @param {FoldersApiFolderCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public folderCreate(requestParameters: FoldersApiFolderCreateRequest, options?: AxiosRequestConfig) {
        return FoldersApiFp(this.configuration).folderCreate(requestParameters.folderCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a specific folder
     * @param {FoldersApiFolderDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public folderDestroy(requestParameters: FoldersApiFolderDestroyRequest, options?: AxiosRequestConfig) {
        return FoldersApiFp(this.configuration).folderDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list folders
     * @param {FoldersApiFolderIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public folderIndex(requestParameters: FoldersApiFolderIndexRequest = {}, options?: AxiosRequestConfig) {
        return FoldersApiFp(this.configuration).folderIndex(requestParameters.page, requestParameters.size, requestParameters.ownerType, requestParameters.accountId, requestParameters.eventId, requestParameters.issuerEntityId, requestParameters.parentId, requestParameters.detailedChildren, requestParameters.detailedDocuments, requestParameters.emptyFolders, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary show a specific folder
     * @param {FoldersApiFolderShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public folderShow(requestParameters: FoldersApiFolderShowRequest, options?: AxiosRequestConfig) {
        return FoldersApiFp(this.configuration).folderShow(requestParameters.id, requestParameters.detailedChildren, requestParameters.detailedDocuments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a specific folder
     * @param {FoldersApiFolderUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public folderUpdate(requestParameters: FoldersApiFolderUpdateRequest, options?: AxiosRequestConfig) {
        return FoldersApiFp(this.configuration).folderUpdate(requestParameters.id, requestParameters.folderUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type FolderCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const FolderCreateErrorCodes = [
    401,
    422,
];

export type FolderDestroyErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const FolderDestroyErrorCodes = [
    401,
];

export type FolderIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const FolderIndexErrorCodes = [
    401,
];

export type FolderShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 442;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const FolderShowErrorCodes = [
    401,
    442,
];

export type FolderUpdateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const FolderUpdateErrorCodes = [
    401,
    422,
];


