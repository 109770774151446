import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';

export const PremiumFeatureLabel = () => {
  return (
    <Label variant="warning" icon={<Icon name="diamond" />}>
      Premium Feature
    </Label>
  );
};
