import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  padding-left: ${({ theme }) => theme.spacing.xl}px;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.sm}px;

  svg {
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }
`;
