import React from 'react';

import { Icon } from '../../Icon';
import { Text } from '../../Typography';

import * as S from './NoData.styles';

type Props = {
  text: string;
  showIcon?: boolean;
};

export const NoData = ({ text, showIcon = false }: Props) => {
  return (
    <S.NoDataContainer>
      {showIcon && <Icon name="warning" size="sm" />}
      <Text size="sm" color="general">
        {text}
      </Text>
    </S.NoDataContainer>
  );
};
