/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  S2SOrderItemAuditsApi,
  S2SOrderItemAuditsIndexErrorCodes,
  S2SOrderItemAuditsIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new S2SOrderItemAuditsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type S2SOrderItemAuditsIndexMethod = S2SOrderItemAuditsApi['s2SOrderItemAuditsIndex'];
export type S2SOrderItemAuditsIndexResponseType = MethodResult<S2SOrderItemAuditsIndexMethod>;

export const useS2SOrderItemAuditsIndex = (
  variables: Parameters<S2SOrderItemAuditsIndexMethod>[0],
  config?: UseQueryConfig<
    S2SOrderItemAuditsIndexMethod,
    S2SOrderItemAuditsIndexErrorTypes
  >
) => {
  return useQuery<
    S2SOrderItemAuditsIndexMethod,
    S2SOrderItemAuditsIndexErrorTypes
  >(
    (...args) => api.s2SOrderItemAuditsIndex(...args),
    S2SOrderItemAuditsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S2SOrderItemAuditsIndex'],
        ...apiQueryConfig.S2SOrderItemAuditsIndex,
        ...config?.queryConfig
      },
    },
    'S2SOrderItemAuditsIndex',
  );
};

export const useS2SOrderItemAuditsIndexInfinite = (
  variables: Parameters<S2SOrderItemAuditsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    S2SOrderItemAuditsIndexMethod,
    S2SOrderItemAuditsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    S2SOrderItemAuditsIndexMethod,
    S2SOrderItemAuditsIndexErrorTypes
  >(
    (...args) => api.s2SOrderItemAuditsIndex(...args),
    S2SOrderItemAuditsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S2SOrderItemAuditsIndex', 'S2SOrderItemAuditsIndexInfinite'],
        ...apiQueryConfig.S2SOrderItemAuditsIndex,
        ...config?.queryConfig,
      },
    },
    'S2SOrderItemAuditsIndex',
  );
};

export const useS2SOrderItemAuditsIndexLazy = (baseOptions?: {
  variables?: Parameters<S2SOrderItemAuditsIndexMethod>[0],
  config?: UseQueryConfig<
    S2SOrderItemAuditsIndexMethod,
    S2SOrderItemAuditsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    S2SOrderItemAuditsIndexMethod,
    S2SOrderItemAuditsIndexErrorTypes
  >(
    (...args) => api.s2SOrderItemAuditsIndex(...args),
    S2SOrderItemAuditsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['S2SOrderItemAuditsIndex'],
        ...apiQueryConfig.S2SOrderItemAuditsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'S2SOrderItemAuditsIndex',
  );
};

