import React from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { ErrorSkeleton } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type NegotiationIndexItemAggregate,
  type OrderBuySellCode,
  type SecondmarketInvestorOrderItemShow,
  CbNegotiationStatus,
  CbOrderBuySell,
  useMatchIndex,
  useSecondmarketOrderItemShow,
} from '@npm/data-access';

import { opportunityState } from '../../../../../../Opportunities.types';
import { getMyOrderSideFromString } from '../../../../../../utils';
import { getOpportunityState } from '../../../../../../utils/getOpportunityState';
import { TransactionPhase } from '../../../../../components/TransactionPhase';
import { HoldingDetails } from '../../../HoldingDetails/HoldingDetails';
import {
  getTransactionAgreementState,
  transactionAgreementState,
} from '../../../TransactionAgreements/TransactionAgreements.types';

import * as S from '../../MyOpportunities.styles';

type Props = {
  orderItem: SecondmarketInvestorOrderItemShow;
  negotiation: NegotiationIndexItemAggregate;
  handleOpportunitySelect: (id: number) => void;
  activeOpportunityId: number;
  orderSide: OrderBuySellCode;
  isMyOrder?: boolean;
  fullWidth?: boolean;
};

export const OpportunityItem = withErrorBoundary(
  ({
    orderItem,
    negotiation,
    handleOpportunitySelect,
    activeOpportunityId,
    isMyOrder,
    orderSide,
    fullWidth = false,
  }: Props) => {
    const { data: matchesData, isLoading: isMatchesDataLoading } =
      useMatchIndex(
        {
          orderItemId: [
            isMyOrder
              ? negotiation?.completed_negotiation_order_item_id
              : negotiation?.completed_negotiation_counterparty_order_item_id,
          ],
        },
        {
          queryConfig: {
            enabled: isMyOrder
              ? !!negotiation?.completed_negotiation_order_item_id
              : !!negotiation?.completed_negotiation_counterparty_order_item_id,
          },
        }
      );

    const { data: resultingOrder, isLoading: isResultingOrderLoading } =
      useSecondmarketOrderItemShow(
        {
          id: isMyOrder
            ? negotiation?.completed_negotiation_order_item_id
            : negotiation?.completed_negotiation_counterparty_order_item_id,
        },
        {
          queryConfig: {
            enabled: isMyOrder
              ? !!negotiation?.completed_negotiation_order_item_id
              : !!negotiation?.completed_negotiation_counterparty_order_item_id,
          },
        }
      );

    if (
      (isMatchesDataLoading || isResultingOrderLoading) &&
      !!negotiation?.completed_negotiation_counterparty_order_item_id
    ) {
      return <Skeleton.Base />;
    }

    const renderPriceDetails = (price, quantity, isRejected = false) => {
      const mainColorVariant = isRejected ? 'tertiary' : 'primary';
      const secondaryColorVariant = isRejected ? 'tertiary' : 'secondary';

      return (
        <>
          <span>
            <Text.Price
              weight="bold"
              value={price}
              colorVariant={mainColorVariant}
            />
            &nbsp;
            <Text as="span" size="xxs" colorVariant={secondaryColorVariant}>
              /&nbsp;sh
            </Text>
          </span>

          <S.Operator>
            <Text weight="bold" colorVariant={secondaryColorVariant} as="span">
              ×
            </Text>
          </S.Operator>

          <Text.Quantity
            weight="bold"
            as="span"
            value={quantity}
            colorVariant={mainColorVariant}
          />

          <S.Operator>
            <Text colorVariant={secondaryColorVariant} as="span" weight="bold">
              =
            </Text>
          </S.Operator>

          <Text.Price
            weight="bold"
            value={price * quantity}
            colorVariant={mainColorVariant}
          />
        </>
      );
    };

    const myOrderSide = getMyOrderSideFromString(orderSide, isMyOrder);

    const orderSideLabel =
      myOrderSide === CbOrderBuySell.items.buy ? 'Buy' : 'Sell';

    const shouldShowTurnChips =
      !negotiation?.can_user_take_action_as_stakeholder &&
      !negotiation?.can_user_take_action_as_investor &&
      negotiation?.state?.code === CbNegotiationStatus.items.in_progress;

    const agreementState = getTransactionAgreementState(matchesData);

    const isRofrBlocked =
      agreementState === transactionAgreementState.rofrBlocked;

    const state = getOpportunityState(
      negotiation,
      negotiation?.match_state,
      negotiation?.has_transaction_confirmation_docs,
      isRofrBlocked
    );

    return (
      <S.StepsWrapper
        fullWidth={fullWidth}
        className={activeOpportunityId === negotiation?.id ? 'active' : null}
        onClick={() => handleOpportunitySelect(negotiation?.id)}
      >
        <Flex gap="sm">
          <Label
            variant={
              state === opportunityState.rofrRejected ||
              state === opportunityState.rejected
                ? null
                : myOrderSide === CbOrderBuySell.items.buy
                ? 'success'
                : 'error'
            }
            round
          >
            {orderSideLabel}
          </Label>
          <Text size="sm" colorVariant="primary">
            #{negotiation?.id}
          </Text>

          {negotiation?.can_user_take_action_as_stakeholder && (
            <S.ActionLabelWrapper>
              <Label variant={'warning'}>Respond to Buyer</Label>
            </S.ActionLabelWrapper>
          )}

          {negotiation?.can_user_take_action_as_investor && (
            <S.ActionLabelWrapper>
              <Label variant={'warning'}>Respond to Seller</Label>
            </S.ActionLabelWrapper>
          )}

          {state === opportunityState.rofrRejected && (
            <S.ActionLabelWrapper>
              <Label icon={<Icon name="x-close" />}>ROFR Exercised</Label>
            </S.ActionLabelWrapper>
          )}

          {shouldShowTurnChips && (
            <S.ActionLabelWrapper>
              {myOrderSide === CbOrderBuySell.items.buy ? (
                <Label icon={<Icon name="clock" />}>Seller’s Turn</Label>
              ) : (
                <Label icon={<Icon name="clock" />}>Buyer’s Turn</Label>
              )}
            </S.ActionLabelWrapper>
          )}

          {state === opportunityState.rejected && (
            <S.ActionLabelWrapper>
              <Label
                icon={<Icon name="x-close" size={9} />}
                variant={'general'}
              >
                Rejected
              </Label>
            </S.ActionLabelWrapper>
          )}
        </Flex>

        <TransactionPhase
          negotiation={negotiation}
          isRofrBlocked={isRofrBlocked}
        />

        <S.ResultWrapper gap="xs">
          {(state === opportunityState.match ||
            state === opportunityState.rejected ||
            state === opportunityState.rofrRejected) &&
            renderPriceDetails(
              negotiation?.current_proposal_tracker?.price,
              negotiation?.current_proposal_tracker?.amount,
              state === opportunityState.rejected ||
                state === opportunityState.rofrRejected
            )}
          {state === opportunityState.transfer &&
            renderPriceDetails(resultingOrder?.price, resultingOrder?.quantity)}
          {(state === opportunityState.getPaid ||
            state === opportunityState.complete) &&
            renderPriceDetails(resultingOrder?.price, resultingOrder?.quantity)}
        </S.ResultWrapper>

        {myOrderSide === CbOrderBuySell.items.sell && (
          <Flex justify="space-between" align="center">
            <Text size="xs" colorVariant="tertiary">
              My Holding:
            </Text>
            <HoldingDetails
              certificateId={negotiation?.holding_certificate_id}
              negotiation={negotiation}
            />
          </Flex>
        )}
      </S.StepsWrapper>
    );
  },
  { FallbackComponent: ErrorSkeleton }
);
