import styled from 'styled-components';

import { useBreakpoint } from '../../../utils/style';
import { Button } from '../../atoms/Button';
import { FormItem as FormItemBase } from '../../atoms/FormItem';
import { Grid as GridBase } from '../../atoms/Grid';

import { MULTI_SELECT_MAX_WIDTH } from '../MultiSelectWithSearch/MultiSelectWithSearch.styles';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const Grid = styled(GridBase).attrs<{ $cols: number; $rows: number }>(
  ({ $cols, $rows }) => ({
    cols: {
      mobile: { count: 1, size: '1fr' },
      default: { count: $cols, size: '1fr' },
    },
    rows: {
      mobile: { count: 1, size: '1fr' },
      default: {
        count: $rows,
        size: '1fr',
      },
    },
    gap: { default: '16px' },
  })
)<{ $cols: number; $rows: number }>``;

export const FormItem = styled(FormItemBase)`
  & > .ant-form-item-label {
    margin-right: 0;
  }

  .ant-form-item-control-input {
    margin-bottom: ${({ theme }) => theme.spacing.lg / 2}px;
  }

  .ant-input-status-success:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input {
    border-color: ${({ theme }) => theme.color.general.borders.secondary};
  }

  .ant-form-item-explain-success,
  .ant-form-item-explain {
    color: ${({ theme }) => theme.color.general.typography.secondary};
  }

  &.ant-form-item-has-error {
    .ant-radio-wrapper,
    .ant-checkbox-wrapper {
      border-color: transparent;
      box-shadow: 0 0 0 2px ${({ theme }) => theme.color.error.borders.primary};
    }
  }
`;

export const MultiSelectFormItem = styled(FormItemBase)`
  &&& .ant-form-item-explain-error {
    max-width: ${MULTI_SELECT_MAX_WIDTH}px;
  }
`;

export const NextButton = styled(Button)`
  && {
    margin-top: ${({ theme }) => theme.spacing.lg}px;
    width: 80px;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;

    ${({ theme }) => useBreakpoint(theme).mobile`
      width: 100%;
      margin-top: ${theme.spacing.md}px;
    `}
  }
`;
