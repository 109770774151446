import { Layout } from 'antd';
import styled, { createGlobalStyle, css } from 'styled-components';

import { Button } from '../../../atoms/Button';
import { type useLayoutConfig } from '../Layout.hooks';

import { BUTTON_HEIGHT_MD } from '../../../atoms/Button/Button.styles';
import { MENU_ITEM_HEIGHT } from '../Layout.styles';

export const SIDEBAR_EXPANDED_WIDTH = 260;
export const SIDEBAR_COLLAPSED_WIDTH = 64;
export const SIDEBAR_COLLAPSED_ITEM_WIDTH = 48;

export const SIDEBAR_TOOLTIP_CLASSNAME = 'npm-sidebar-tooltip';

export const Wrapper = styled(Layout.Sider)<{
  $layoutConfig: ReturnType<typeof useLayoutConfig>;
  $isCollapsible?: boolean;
}>`
  position: fixed;
  top: ${({ $layoutConfig }) => $layoutConfig.header.height}px;
  z-index: ${({ theme, $isCollapsible }) =>
    $isCollapsible ? theme.zIndex.aboveDrawer : theme.zIndex.base};

  min-width: ${({ width }) => width}px;
  min-height: calc(
    100vh - ${({ $layoutConfig }) => $layoutConfig.header.height}px
  );
  height: calc(100vh - ${({ $layoutConfig }) => $layoutConfig.header.height}px);
  overflow: visible; // so that OboDropdown is not cut off

  background: ${({ theme }) => theme.color.general.layout.zero};
  box-shadow: ${({ theme, collapsed, $isCollapsible }) =>
    collapsed || !$isCollapsible ? 'unset' : theme.shadow.level1};
  border-right: 1px solid ${({ theme }) => theme.color.general.borders.primary};

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing.md}px
      ${({ theme }) => theme.spacing.sm}px;

    .ant-btn:not(.ant-btn-disabled, .ant-btn-loading):hover {
      background: ${({ theme }) =>
        theme.color.info.backgrounds.secondaryHover} !important;
    }
  }
`;

export const SidebarTopContainer = styled.div<{ $isCollapsed?: boolean }>`
  ${({ theme, $isCollapsed }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${theme.spacing.sm}px;
    margin-bottom: ${theme.spacing.md}px;
    min-height: ${BUTTON_HEIGHT_MD}px;

    flex-direction: row;
    ${$isCollapsed &&
    css`
      align-items: flex-start;
      flex-direction: column;
    `}
  `}
`;

export const SidebarCollapseButton = styled(Button)<{ $isCollapsed?: boolean }>`
  ${({ theme, $isCollapsed }) => css`
    &&& {
      width: ${$isCollapsed
        ? SIDEBAR_COLLAPSED_ITEM_WIDTH
        : BUTTON_HEIGHT_MD}px;
      height: ${$isCollapsed ? MENU_ITEM_HEIGHT : BUTTON_HEIGHT_MD}px;
      border-radius: ${theme.borderRadius.lg}px;
      :active:not(.ant-btn-disabled) {
        background-color: ${theme.color.general.layout.zero};
        box-shadow: none;
      }

      > svg {
        color: ${theme.color.general.typography.tertiary};
      }
    }
  `}
`;

export const SidebarTooltipGlobalStyle = createGlobalStyle`
  .${SIDEBAR_TOOLTIP_CLASSNAME} {
    .ant-tooltip-content {
      margin-left: 12px;
    }
  }
`;
