import styled from 'styled-components';

const BOXED_CONTENT_WRAPPER_PADDING = 12;

export const DrawerSectionHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.sm}px;
`;

export const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.sm}px;

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }
`;

export const BoxedContentWrapper = styled.div`
  padding: ${BOXED_CONTENT_WRAPPER_PADDING}px;
  background-color: ${({ theme }) => theme.color.general.layout.two};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
`;

export const InformationalChipWrapper = styled.div`
  margin-left: auto;
`;
