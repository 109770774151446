import { type Signer } from '@npm/data-access';

export const prepareReadonlyData = (spouse?: Signer) => {
  if (!spouse) {
    return [{ label: 'Spouse', value: 'I do not have a spouse' }];
  }

  const { first, last, middle, email } = spouse;

  return [
    {
      label: 'Spouse',
      value: 'I have a spouse',
    },
    {
      label: 'First Name',
      value: first,
    },
    {
      label: 'Middle Name',
      value: middle || '-',
    },
    {
      label: 'Last Name',
      value: last,
    },
    {
      label: 'Email',
      value: email,
    },
  ];
};
