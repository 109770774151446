import React, { type ComponentProps } from 'react';

import { PermissionedAccountSearch } from '../../smartSelects/PermissionedAccountSearch';
import { type FilterItemProps } from '../FilterPanel';

type Props = FilterItemProps & ComponentProps<typeof PermissionedAccountSearch>;

// Used in ISS Event-tracking tabs (Program Detail)
export const PermissionedAccountFilter = ({
  name,
  onFilterSubmit,
  ...rest
}: Props) => {
  return (
    <PermissionedAccountSearch
      onSelect={() => {
        onFilterSubmit?.(name);
      }}
      onClear={() => onFilterSubmit?.(name)}
      {...rest}
    />
  );
};
