import styled, { css } from 'styled-components';

import { centerFlex, useBreakpoint } from '../../../utils/style';
import { Modal as BaseModal } from '../../molecules/Modal';
import { Checkbox as BaseCheckbox } from '../Checkbox';
import { Radio as BaseRadio } from '../Radio';
import { SmallTextStyles } from '../Typography';

const PLAIN_CONTENT_WRAPPER_ID = 'plain-content-wrapper';

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  flex: 1 1 auto;
`;

export const Icon = styled.span`
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  color: ${({ theme }) => theme.color.general.typography.secondary};

  ${({ theme }) => useBreakpoint(theme).mobile`
    margin-bottom: ${theme.spacing.sm}px;
  `}
`;

export const InfoIconWrap = styled.div`
  ${centerFlex}
  && svg {
    height: 16px;
    width: 16px;
    margin-left: ${({ theme }) => theme.spacing.sm}px;
    color: ${({ theme }) => theme.color.info.typography.primary};
  }
`;

const CommonStyles = css`
  padding: ${({ theme }) => `${theme.spacing.md}px`};
`;

const StyledInput = ({ variant }) => css`
  display: flex;
  flex-wrap: nowrap;
  gap: ${({ theme }) => theme.spacing.md}px;
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  background-color: ${({ theme }) => theme.color.general.layout.one};
  padding: ${({ theme }) => theme.spacing.xs}px;
  border: 1px solid ${({ theme }) => theme.color.general.borders.secondary};
  transition: all 0.2s ease-in-out;

  &.ant-${variant}-wrapper {
    margin-left: 0;
    margin-right: 0;
    height: inherit;
    display: flex;
    align-items: center;

    & > span {
      padding: 0;
    }
  }

  .ant-${variant} {
    display: flex;
    justify-content: flex-end;
    padding: ${({ theme }) => theme.spacing.xs}px;
    top: 0;
  }

  &.ant-${variant}-wrapper:after {
    display: none;
  }

  &.ant-${variant}-wrapper-checked {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.color.info.typography.primary};

    & h2,
    svg,
    #${PLAIN_CONTENT_WRAPPER_ID} p {
      color: ${({ theme }) => theme.color.info.typography.primary};
    }

    &.ant-${variant}-wrapper-disabled {
      box-shadow: 0 0 0 2px
        ${({ theme }) => theme.color.general.typography.secondary};
    }
  }

  .ant-${variant}-inner, .ant-${variant}-input {
    width: 25px;
    height: 25px;
  }

  .ant-${variant}-input:focus + .ant-${variant}-inner {
    border-color: ${({ theme }) => theme.color.info.borders.primary};
  }

  .ant-${variant}-inner {
    background-color: ${({ theme }) => theme.color.general.layout.two};
    border-color: ${({ theme }) => theme.color.general.borders.secondary};
  }

  .ant-${variant}-checked .ant-${variant}-inner {
    background-color: ${({ theme }) =>
      theme.color.info.backgrounds.secondaryDefault};
    border-color: ${({ theme }) => theme.color.info.typography.primary};
  }

  .ant-${variant}-disabled {
    &.ant-${variant}-checked {
      .ant-${variant}-inner {
        border-color: ${({ theme }) =>
          theme.color.general.typography.secondary};
      }
    }
  }

  .ant-${variant}-disabled
    .ant-${variant}-inner,
    .ant-${variant}-disabled:not(.ant-${variant}-checked)
    .ant-${variant}-inner {
    border-color: ${({ theme }) =>
      theme.color.general.borders.secondary} !important;
  }

  .ant-${variant}-checked::after {
    border: 1px solid ${({ theme }) => theme.color.transparent};
  }

  &.ant-${variant}-wrapper:hover {
    &:not(.ant-${variant}-wrapper-disabled) {
      border-color: transparent;
      box-shadow: 0 0 0 2px
        ${({ theme }) => theme.color.info.typography.primary};
    }

    .ant-${variant}-inner {
      border-color: ${({ theme }) => theme.color.info.typography.primary};
    }
  }

  &.ant-${variant}-wrapper-disabled {
    border-color: ${({ theme }) => theme.color.general.borders.primary};
    background-color: ${({ theme }) => theme.color.general.layout.two};

    .ant-${variant}-disabled + span,
    h2,
    p {
      color: ${({ theme }) => theme.color.general.typography.tertiary};
    }

    & svg {
      color: ${({ theme }) => theme.color.general.borders.secondary};
    }

    .ant-${variant}-checked .ant-${variant}-inner {
      background-color: ${({ theme }) => theme.color.general.layout.two};
      border-color: ${({ theme }) => theme.color.general.typography.secondary};
    }
  }
`;

export const RadioButtonCard = styled(BaseRadio)<{ $isPlain: boolean }>`
  ${() => StyledInput({ variant: 'radio' })}
  ${CommonStyles}
  .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.75);
    background-color: ${({ theme }) => theme.color.info.typography.primary};
  }

  .ant-radio-disabled {
    &.ant-radio-checked {
      .ant-radio-inner::after {
        background-color: ${({ theme }) =>
          theme.color.general.typography.secondary};
      }
    }
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }
`;

export const CheckboxButtonCard = styled(BaseCheckbox)<{ $isPlain: boolean }>`
  ${() => StyledInput({ variant: 'checkbox' })}
  ${CommonStyles}
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.color.info.borders.primary};
    }

    .ant-checkbox-inner:after {
      left: 30%;
      border: 1px solid;
      border-color: ${({ theme }) => theme.color.info.borders.primary};
      border-top: 0;
      border-left: 0;
    }
  }

  .ant-checkbox-disabled {
    &.ant-checkbox-checked {
      .ant-checkbox-inner::after {
        border-color: ${({ theme }) =>
          theme.color.general.typography.secondary};
      }

      .ant-checkbox-inner {
        border-color: ${({ theme }) =>
          theme.color.general.typography.secondary} !important;
      }
    }
  }
`;

export const PlainContentWrapper = styled.div.attrs({
  id: PLAIN_CONTENT_WRAPPER_ID,
})`
  display: flex;
  align-items: center;
  align-self: flex-start;
`;

export const Modal = styled(BaseModal)`
  & > .ant-modal-content {
    .ant-modal-body {
      padding: ${({ theme }) => theme.spacing.md}px;
      color: ${({ theme }) => theme.color.general.typography.secondary};
      min-height: 0;
    }

    .ant-modal-footer {
      border-top: none;

      .ant-btn {
        width: 100%;
        border-radius: ${({ theme }) => theme.borderRadius.md}px;
      }
    }
  }
`;

const commonDefaultInputStyles = css`
  display: flex;
  align-items: center;

  .ant-radio,
  .ant-checkbox {
    top: 0;
  }

  span {
    ${SmallTextStyles}
    color: ${({ theme }) => theme.color.general.typography.primary};
  }
`;

export const Radio = styled(BaseRadio)`
  ${commonDefaultInputStyles}
`;

export const Checkbox = styled(BaseCheckbox)`
  ${commonDefaultInputStyles}
`;
