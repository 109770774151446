import { Codebooks } from './codebooks.types';

const items = {
  internal: 'internal',
  ats_i: 'ats_i',
  ats_sm: 'ats_sm',
} as const;

export type ATSPoolCode = keyof typeof items;

export const CbATSPool = {
  code: Codebooks.ATS_POOL,
  items: items,
};
