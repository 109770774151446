import { useEffect, useRef, useState } from 'react';

import { TAB_BTN_HEIGHT } from './Tabs.styles';

// 40 = height of the tabs, 2 = border, 16 = padding
export const STICKY_TABS_HEIGHT = TAB_BTN_HEIGHT + 2 + 16;

export const useScrollDirection = (getScrollElement?: () => HTMLElement) => {
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>();
  const lastScrollTop = useRef(0);

  useEffect(() => {
    const scrollElement = getScrollElement?.();

    const handleScroll = () => {
      const st =
        scrollElement?.scrollTop ||
        window.pageYOffset ||
        document.documentElement.scrollTop;

      if (st > lastScrollTop.current) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
      lastScrollTop.current = st <= 0 ? 0 : st;
    };

    (scrollElement ?? window).addEventListener('scroll', handleScroll);
    return () => {
      (scrollElement ?? window).removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollDirection;
};
