import * as S from './BulletPoints.styles';

export type BulletPointsProps = {
  title?: string;
  bulletPoints: (string | React.ReactNode)[];
};

export const BulletPoints = ({ title, bulletPoints }: BulletPointsProps) => {
  return (
    <>
      {title && <S.BulletPointsTitle>{title}</S.BulletPointsTitle>}
      <S.BulletPointsList>
        {bulletPoints.map((bulletPoint, index) => (
          <S.BulletPointsListItem
            key={typeof bulletPoint === 'string' ? bulletPoint : index}
          >
            {bulletPoint}
          </S.BulletPointsListItem>
        ))}
      </S.BulletPointsList>
    </>
  );
};
