import { useEffect, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { CypressDataIds } from '@npm/core/ui/constants';
import { DATE_FORMATS, parseDateToString } from '@npm/core/ui/utils/formatters';
import {
  type BeneficialOwnersContactInformationCreateRequestContract,
  useAccountBeneficialOwnerCreate,
  useBeneficialOwnerContactInformationCreate,
} from '@npm/data-access';

import {
  type FromGetStartedFuncs,
  type IndividualCreateRequest,
  BoaFromGetStartedAction,
} from '../../../BeneficialOwnership.types';
import { AddIndividualDrawer } from '../../Drawers';

type Props = {
  controlPersonOnly?: boolean;
  accountId: number;
  disabled?: boolean;
  disableControlPerson?: boolean;
} & Pick<FromGetStartedFuncs, 'onGetStarted'>;

export const AddIndividualButton = ({
  controlPersonOnly,
  accountId,
  disableControlPerson,
  disabled,
  onGetStarted,
}: Props) => {
  const [params, setQueryParams] = useQueryParams({
    fromGetStarted: StringParam,
  });
  const { withShowApiErrorMiddleware } = useAlerts();
  const [open, setOpen] = useState(false);
  const { execute, isLoading } = useAccountBeneficialOwnerCreate();
  const { execute: contactInfoUpdate } =
    useBeneficialOwnerContactInformationCreate();

  const handleAddIndividual = async (values: IndividualCreateRequest) => {
    const { first, middle, last, role, date_of_birth, ...rest } = values;
    let res = null;

    try {
      res = await withShowApiErrorMiddleware(execute)({
        accountId: accountId,
        accountsBeneficialOwnerCreateRequestContract: {
          date_of_birth:
            date_of_birth ||
            parseDateToString(new Date(), DATE_FORMATS.DATE_NUMERIC),
          first,
          last,
          middle,
          role,
        },
      });
    } catch (error) {
      throw Error(error);
    }

    try {
      await withShowApiErrorMiddleware(contactInfoUpdate)({
        beneficialOwnersContactInformationCreateRequestContract: {
          ...(rest as BeneficialOwnersContactInformationCreateRequestContract),
        },
        beneficialOwnerId: res.id,
      });
    } catch (error) {
      throw Error(error);
    }
  };

  useEffect(() => {
    if (params.fromGetStarted === BoaFromGetStartedAction.Individual) {
      setOpen(true);
      setQueryParams({ fromGetStarted: undefined }, 'replaceIn');
    }
  }, [params.fromGetStarted]);

  return (
    <>
      <Button
        disabled={disabled}
        onClick={
          onGetStarted
            ? () => onGetStarted(BoaFromGetStartedAction.Individual)
            : () => setOpen(true)
        }
        variant="outline"
        icon={<Icon name="plus" />}
        data-cy={
          CypressDataIds.PostOnboarding.BeneficialOwnershipAssessment
            .AddIndividualButton
        }
      >
        Add Individual
      </Button>
      <AddIndividualDrawer
        disableControlPerson={disableControlPerson}
        isLoading={isLoading}
        open={open}
        onClose={() => setOpen(false)}
        onAddIndividual={handleAddIndividual}
        controlPersonOnly={controlPersonOnly}
      />
    </>
  );
};
