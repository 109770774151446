import React from 'react';
import { Radio } from 'antd';

import { useDarkModeContext } from '../../../context/DarkModeContext';
import { RadioSwitch } from '../../atoms/RadioSwitch/RadioSwitch';

import * as S from './DarkModeSwitch.styles';

export const DarkModeSwitch = () => {
  const { isDarkMode, toggleDarkMode } = useDarkModeContext();

  return (
    <S.DarkModeSwitcherWrapper>
      <RadioSwitch defaultValue={isDarkMode ? 'dark' : 'light'}>
        <Radio.Button
          onClick={() => {
            if (!isDarkMode) {
              toggleDarkMode();
            }
          }}
          value="dark"
        >
          Dark
        </Radio.Button>
        <Radio.Button
          onClick={() => {
            if (isDarkMode) {
              toggleDarkMode();
            }
          }}
          value="light"
        >
          Light
        </Radio.Button>
      </RadioSwitch>
    </S.DarkModeSwitcherWrapper>
  );
};
