import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import { InfoTooltip } from '../Form/InfoTooltip';

import * as S from '../Form/ExemptionComplete/ExemptionComplete.styles';

type Props = {
  text: string;
  answer: string;
  margin?: boolean;
  tooltipText?: string;
};

export const ExemptAnswer = ({
  text,
  answer,
  margin = true,
  tooltipText,
}: Props) => {
  return (
    <Margin bottom={margin ? 'xxl' : undefined}>
      <Text size="sm">
        {text}
        <Margin left="sm" as="span" style={{ display: 'inline-block' }}>
          {tooltipText && <InfoTooltip title={tooltipText} />}
        </Margin>{' '}
      </Text>
      <Margin top="md" bottom="xl">
        <Flex direction="row">
          <S.QuestionText>
            <Text size="sm">Category</Text>
          </S.QuestionText>
          <S.QuestionText>
            <S.ExemptionText size="sm" colorVariant="primary">
              {answer}
            </S.ExemptionText>
          </S.QuestionText>
        </Flex>
      </Margin>
    </Margin>
  );
};
