import React from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { DrawerSection } from '@npm/core/ui/components/molecules/DrawerSection';

import { useObo } from '../../role';
import { AccountInfo } from '../AccountInfo';

type Props = {
  transactionRole?: 'Stakeholder' | 'Investor' | null;
  onAccountSwitch?: () => void;
  accountId?: number;
  showTitle?: boolean;
  content?: React.ReactNode;
  isObo?: boolean;
};

export const YourRoleDrawerSection = ({
  transactionRole,
  onAccountSwitch,
  accountId,
  showTitle = true,
  content: customContent,
  isObo: isOboProp,
}: Props) => {
  const { isObo: isOboMode } = useObo();
  const isObo = isOboProp || isOboMode;

  const content = customContent ? (
    <>{customContent}</>
  ) : (
    <>
      {isObo && (
        <Text size="sm" marginBottom="sm">
          Acting on behalf of
        </Text>
      )}
      <AccountInfo
        accountPanelProps={{
          showTitle: false,
          showRepresentative: false,
          onAccountSwitch,
        }}
        accountId={accountId}
      />
    </>
  );

  return showTitle ? (
    <DrawerSection
      iconName="user"
      title={
        <>
          <span>Your Role</span>
          <Text colorVariant="secondary" as="span">
            &nbsp;&nbsp;•&nbsp;&nbsp;
            {transactionRole}
          </Text>
        </>
      }
      content={content}
    />
  ) : (
    content
  );
};
