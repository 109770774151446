import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { expandCollapse } from '@npm/core/ui/utils/animations';

import {
  type OnboardingSectionConfig,
  type PostOnboardingContext,
  type SectionKey,
} from '../../PostOnboarding.types';
import { ProgressRing } from '../ProgressRing';
import { SidebarItem } from '../SidebarItem';

import * as S from './SidebarSection.styles';
import { SidebarSectionCard } from '@npm/core/ui/components/organisms/WizardNew/Layout/WizardLayout.styles';

type Props = OnboardingSectionConfig & {
  sectionKey: SectionKey;
  isActive: boolean;
  handleCloseFormAttempt: (callback?: () => void) => void;
};

export const SidebarSection = ({
  sectionKey,
  title,
  subTitle,
  tooltip,
  subTitleTooltip,
  showProgress,
  items,
  isActive,
  handleCloseFormAttempt,
}: Props) => {
  const sectionProgress = useWizardStore(
    (s: WizardStore<PostOnboardingContext>) =>
      s.data.sectionsProgress?.[sectionKey]
  );
  const [isExpanded, setIsExpanded] = useState(isActive);
  const theme = useTheme();

  useEffect(() => {
    if (isActive) {
      setIsExpanded(true);
    }
  }, [isActive]);

  return (
    <SidebarSectionCard outlined={true} noContentPadding>
      {subTitle && (
        <S.SectionSubTitleWrapper>
          <Text size="sm" colorVariant="primary">
            {subTitle}
          </Text>{' '}
          {subTitleTooltip && (
            <Tooltip size="sm" title={subTitleTooltip}>
              <Flex>
                <Icon
                  size="xs"
                  name="info-circle"
                  color={theme.color.info.typography.primary}
                />
              </Flex>
            </Tooltip>
          )}
        </S.SectionSubTitleWrapper>
      )}
      <Flex
        align="center"
        justify="space-between"
        onClick={() => setIsExpanded(!isExpanded)}
        style={{ cursor: 'pointer' }}
      >
        <S.SectionTitleWrapper>
          <Text size="sm" colorVariant="primary">
            {title}
          </Text>
          {tooltip && (
            <Tooltip size="sm" title={tooltip}>
              <Flex>
                <Icon
                  size="xs"
                  name="info-circle"
                  color={theme.color.info.typography.primary}
                />
              </Flex>
            </Tooltip>
          )}
        </S.SectionTitleWrapper>
        {showProgress ? (
          <ProgressRing percent={sectionProgress} />
        ) : (
          <Margin right="xs">
            <Button
              variant="text"
              onClick={() => setIsExpanded(!isExpanded)}
              icon={<Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} />}
            />
          </Margin>
        )}
      </Flex>
      <div style={{ overflow: 'hidden' }}>
        <AnimatePresence initial={false}>
          {isExpanded && (
            <motion.div {...expandCollapse}>
              <S.ItemsWrapper>
                {items.map(item => (
                  <SidebarItem
                    key={item.key}
                    step={item}
                    handleCloseFormAttempt={handleCloseFormAttempt}
                    testId={`stepper-item-${item.key}`}
                  />
                ))}
              </S.ItemsWrapper>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </SidebarSectionCard>
  );
};
