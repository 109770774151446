import { useMemo } from 'react';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { TooltipInfoIcon } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { VenusApi } from '@npm/data-access';

const { useCompaniesLatestStockClassDetails } = VenusApi;

type Props = {
  venusId: number;
  inputValue?: number;
};

export const EstLastPreferredPPS = ({ venusId, inputValue }: Props) => {
  const { data: latestStockClassData } = useCompaniesLatestStockClassDetails(
    { id: `${venusId}` },
    {
      onError: error => {
        // Do not destroy the entire form if fetching the latest stock class details fails
        console.error(error);
      },
    }
  );

  const ppsDifferencePercentage = useMemo(() => {
    if (!latestStockClassData?.price_per_share || !inputValue) {
      return null;
    }
    return Math.round(
      (inputValue * 100) / latestStockClassData.price_per_share - 100
    );
  }, [latestStockClassData, inputValue]);

  if (!latestStockClassData?.price_per_share) {
    return null;
  }

  return (
    <Margin top="xs">
      <Flex align="center" gap="xs">
        <Text size="sm">Est. Last Preferred PPS</Text>
        <TooltipInfoIcon title="Estimated Last Preferred Price Per Share" />
        <Text.Price
          value={latestStockClassData.price_per_share}
          currency="USD"
          size="sm"
          color="info"
          colorVariant="primary"
          currencyColor="info"
          currencyColorVariant="primary"
        />
        {ppsDifferencePercentage !== 0 && ppsDifferencePercentage && (
          <Text
            size="sm"
            color={ppsDifferencePercentage > 0 ? 'success' : 'error'}
            colorVariant="primary"
            as="div"
          >
            <Flex align="center">
              <Icon
                name={
                  ppsDifferencePercentage > 0
                    ? 'arrow-narrow-45-up'
                    : 'arrow-narrow-45-down'
                }
                size="sm"
              />
              <span>{Math.abs(ppsDifferencePercentage)}%</span>
            </Flex>
          </Text>
        )}
      </Flex>
    </Margin>
  );
};
