import React, { type ComponentProps } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { GetStarted as BaseGetStarted } from '@npm/core/ui/components/molecules/GetStarted';

export const BuyerSurveyGetStarted = ({
  onGetStarted,
}: ComponentProps<typeof BaseGetStarted>) => (
  <BaseGetStarted
    onGetStarted={onGetStarted}
    title="Buyer Survey"
    content={
      <>
        <Text size="sm">
          Please complete the survey below. Completing the Buyer Survey helps us
          understand your financial experience and objectives, and is a required
          step to receive investment opportunities from us.
        </Text>
        <Text size="sm">
          <Text size="sm" weight="bold" as="span">
            Note:
          </Text>{' '}
          This survey is not required if you are participating in company
          sponsored tender offer.
        </Text>
      </>
    }
  />
);
