import { useCallback } from 'react';
import queryString from 'query-string';

import { useHistory } from '@npm/core/ui/components/molecules/Link';
import type {
  SecondmarketOrderItemSimple,
  Workstation,
} from '@npm/data-access';
import { CbWorkstationType, useNegotiationIndexLazy } from '@npm/data-access';

import { useFeatureFlags } from '../../app/featureFlags/featureFlags.context';
import { useUserContextStore } from '../../auth/user/context';
import { useCurrentWorkstation, useObo } from '../../auth/user/role';
import { type IS_EDIT_MODAL_VISIBLE_QUERY_KEY } from '../client-orders/ClientOrderDetail/OrderData';
import { OPPORTUNITY_ID_QUERY_KEY } from '../opportunities/components/negotiationDetail/components/MyOpportunities';

type BuildClientOrderDetailUrlParams = {
  orderItemId: number | undefined;
  parentOrderItemId?: number;
  workstation: Workstation | null;
  isObo: boolean;
  isIndividualNpmsInvestor?: boolean;
  isEntityNpmsInvestor?: boolean;
  isSponsoredInvestor?: boolean;
  isOpportunitiesEnabled?: boolean;
};

type BuildOrderBookUrlParams = {
  orderItemId: number | undefined;
};

export const buildClientOrderDetailUrl = ({
  orderItemId,
  workstation,
  isObo,
  isSponsoredInvestor = false,
  isEntityNpmsInvestor = false,
  isIndividualNpmsInvestor = false,
  isOpportunitiesEnabled = false,
}: BuildClientOrderDetailUrlParams) => {
  const isInvWS = workstation.type?.code === CbWorkstationType.items.investor;
  if (orderItemId == null) return undefined;

  if (
    isOpportunitiesEnabled &&
    isEntityNpmsInvestor &&
    isSponsoredInvestor &&
    isInvWS &&
    !isObo
  ) {
    return `/second-market/sponsored-investor/opportunity/${orderItemId}`;
  }

  if (isOpportunitiesEnabled && isIndividualNpmsInvestor && isInvWS && !isObo) {
    return `/second-market/investor/opportunity/${orderItemId}`;
  }

  if (isSponsoredInvestor && isInvWS && !isObo) {
    return `/second-market/sponsored-investor/orders/${orderItemId}`;
  }

  return `/second-market/client-orders/${orderItemId}`;
};

type ClientOrderDetailQueryParams = {
  [IS_EDIT_MODAL_VISIBLE_QUERY_KEY]?: 1 | 0;
  [OPPORTUNITY_ID_QUERY_KEY]?: number;
};

export const useClientOrderDetailUrl = () => {
  const workstation = useCurrentWorkstation();
  const { isObo } = useObo();

  const isIndividualNpmsInvestor = useUserContextStore(
    store => store.isIndividualNpmsInvestor
  );

  const isEntityNpmsInvestor = useUserContextStore(
    store => store.isEntityNpmsInvestor
  );

  const isSponsoredInvestor = useUserContextStore(
    store => !!store.sponsorshipType
  );

  const { isEnabled } = useFeatureFlags();
  const isOpportunitiesEnabled = isEnabled({
    type: 'allow-if-enabled',
    flag: 'V2_EMPLOYEE_RETAIL',
  });

  const [fetchNegotiations] = useNegotiationIndexLazy();

  const history = useHistory();

  return useCallback(
    async function navigate(
      orderItem: SecondmarketOrderItemSimple,
      queryParams: Omit<
        ClientOrderDetailQueryParams,
        typeof OPPORTUNITY_ID_QUERY_KEY
      > = {}
    ) {
      const extendedQueryParams = { ...queryParams };

      const builderContext = {
        workstation,
        isObo,
        isIndividualNpmsInvestor,
        isEntityNpmsInvestor,
        isSponsoredInvestor,
        isOpportunitiesEnabled,
      };

      let url = buildClientOrderDetailUrl({
        orderItemId: orderItem.id,
        ...builderContext,
      });

      if (
        url &&
        url.includes('opportunity') &&
        orderItem.parent_order_item_id != null
      ) {
        let opportunityId: number | undefined;
        try {
          const data = await fetchNegotiations({
            variables: {
              orderItemId: orderItem.parent_order_item_id,
            },
          });

          if (data?.negotiations?.length === 1) {
            opportunityId = data.negotiations[0]?.id;
          } else {
            const negotiation = data?.negotiations?.find(
              one =>
                one.completed_negotiation_order_item_id === orderItem.id ||
                one.completed_negotiation_counterparty_order_item_id ===
                  orderItem.id
            );

            opportunityId = negotiation?.id;
          }
        } finally {
          url = buildClientOrderDetailUrl({
            orderItemId: orderItem.parent_order_item_id,
            ...builderContext,
          });
          if (opportunityId) {
            extendedQueryParams[OPPORTUNITY_ID_QUERY_KEY] = opportunityId;
          }
        }
      }

      if (url) {
        history.push(`${url}?${queryString.stringify(extendedQueryParams)}`);
        return true;
      }
      return false;
    },
    [
      workstation,
      isObo,
      isIndividualNpmsInvestor,
      isEntityNpmsInvestor,
      isSponsoredInvestor,
      isOpportunitiesEnabled,
      fetchNegotiations,
      history,
    ]
  );
};

export const buildOrderBookUrl = ({ orderItemId }: BuildOrderBookUrlParams) => {
  if (orderItemId == null) return undefined;
  return `/second-market/market-feed?openOrderById=${orderItemId}`;
};
