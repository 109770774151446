import styled from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { RadioGroup } from '@npm/core/ui/components/atoms/Radio';
import { Tabs } from '@npm/core/ui/components/atoms/Tabs';
import { useBreakpoint } from '@npm/core/ui/utils/style';

export const Wrapper = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  min-height: 38px;
`;

export const RangeTabsWrapper = styled.div`
  flex: 1;
`;

export const RangeTabs = styled(Tabs)`
  padding: ${({ theme }) => theme.spacing.sm}px;
  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: 0 ${theme.spacing.sm}px;
  `}
`;

export const SeriesRadioGroup = styled(RadioGroup)`
  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: ${theme.spacing.sm}px ${theme.spacing.md}px;
  `}
`;
