import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';

import * as S from '../../CreateNewAccount/FooterButtons/FooterButtons.styles';

type Props = {
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onOk: () => void;
  isLoading: boolean;
};

export const FooterButtons = ({ onCancel, onOk, isLoading }: Props) => {
  return (
    <S.Wrapper>
      <Button variant="text" onClick={onCancel} disabled={isLoading}>
        Cancel
      </Button>
      <Button onClick={onOk} disabled={isLoading} loading={isLoading}>
        Save & Continue
      </Button>
    </S.Wrapper>
  );
};
