import { type CodebookItem, Codebooks } from './codebooks.types';

const items = {
  none: 'none',
  level_one: 'level_one',
  level_two: 'level_two',
  level_three: 'level_three',
} as const;

export type CbAccountSponsorshipTypeCode = keyof typeof items;

const codeToNumber = (
  code: CbAccountSponsorshipTypeCode | null | undefined
) => {
  switch (code) {
    case 'level_one':
      return 1;
    case 'level_two':
      return 2;
    case 'level_three':
      return 3;
    default:
      return 0;
  }
};

export const CbAccountSponsorshipType = {
  code: Codebooks.ACCOUNT_SPONSORSHIP_TYPE,
  items: items,
  codeToNumber,
  isSponsoredAccount: (sponsorshipType: CodebookItem | null | undefined) =>
    codeToNumber(sponsorshipType?.code as CbAccountSponsorshipTypeCode) > 0,
  isSponsoredAccountLevel2: (
    sponsorshipType: CodebookItem | null | undefined
  ) => codeToNumber(sponsorshipType?.code as CbAccountSponsorshipTypeCode) >= 2,
  isSponsoredAccountLevel3: (
    sponsorshipType: CodebookItem | null | undefined
  ) => codeToNumber(sponsorshipType?.code as CbAccountSponsorshipTypeCode) >= 3,
};
