import { Codebooks } from './codebooks.types';

const Items = {
  MTM: 'mtm',
  Venus: 'venus',
} as const;

export type CbFilingSourceCode = typeof Items[keyof typeof Items];

export const CbFilingSource = {
  code: Codebooks.FILING_SOURCE,
  items: Items,
};
