import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Chart } from 'chart.js';
import { throttle } from 'lodash';
import styled from 'styled-components';

import { CardBase } from '@npm/core/ui/components/atoms/CardBase';
import {
  CrosshairPlugin,
  formatAxisNumber,
  useChartOptions,
} from '@npm/core/ui/components/atoms/Charts';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';

import { useCompanyOverviewChartFilter } from '../../../CompanyOverview.hooks';
import {
  type CompanyOverviewChartSeries,
  type HoveredPoint,
} from '../../../CompanyOverview.types';
import {
  formatMonthAndYearForChartLabel,
  getMonthlyXLabels,
  sortArrayByDateField,
} from '../../../CompanyOverview.utils';
import { CompanyOverviewChartFilters } from '../../../components/CompanyOverviewChartFilters/CompanyOverviewChartFilters';
import { type CompanyOverviewMaxValues } from '../../../TapeDPricing/TapeDPricing.types';

import { BidOfferHistoryChartLegend } from './components/BidOfferHistoryChartLegend';
import { BidOfferHistoryChartTooltip } from './components/BidOfferHistoryChartTooltip/BidOfferHistoryChartTooltip';
import { useOrderPriceDataSet } from './hooks/useOrderPriceDataSet';
import { usePrimaryRoundDataSet } from './hooks/usePrimaryRoundDataSet';
import { useBidOfferHistoryChartDateLimits } from './BidOfferHistoryChart.hooks';
import {
  type BidOfferData,
  type ChartMode,
  type SelectedPointData,
} from './BidOfferHistoryChart.types';
import {
  BID_OFFER_CHART_HTML_ID,
  getBidOffersChartTooltip,
  getDistanceOfThePointFromTheLine,
  showBidOfferHistoryChartTooltip,
} from './BidOfferHistoryChart.utils';

import { TooltipStyles } from '@npm/core/ui/components/atoms/Charts/styles/Tooltip.styles';

type Props = {
  data: BidOfferData;
  yAxisMaxValues: CompanyOverviewMaxValues;
  mode?: ChartMode;
};

export const BidOfferHistoryChart = ({
  data,
  mode = 'bids_offers',
  yAxisMaxValues,
}: Props) => {
  // Dark-mode context used to fix issues with switching between dark and light mode
  const { isDarkMode } = useDarkModeContext();

  const [activeItem, setActiveItem] = useState<SelectedPointData>(undefined);
  const [series, setSeries] = useState<CompanyOverviewChartSeries>('PPS');

  // Data need to be sorted as they don't come from API in correct order

  const [sortedBids, sortedAsks, sortedTrades, sortedValuations] =
    useMemo(() => {
      if (mode === 'trades') {
        return [
          [],
          [],
          sortArrayByDateField(data.aggregated_trades),
          sortArrayByDateField(data.primary_rounds),
        ];
      }
      return [
        sortArrayByDateField(data.aggregated_bids),
        sortArrayByDateField(data.aggregated_asks),
        [],
        sortArrayByDateField(data.primary_rounds),
      ];
    }, [data, mode]);

  const [minDate, maxDate] = useBidOfferHistoryChartDateLimits(
    sortedBids,
    sortedAsks,
    sortedTrades,
    sortedValuations
  );

  const {
    setCurrentPage,
    currentMinDate,
    currentMaxDate,
    currentPage,
    pageCount,
    range,
    setRange,
  } = useCompanyOverviewChartFilter(minDate, maxDate);

  const labels = useMemo(() => {
    return getMonthlyXLabels(currentMinDate, currentMaxDate);
  }, [currentMinDate, currentMaxDate]);

  const datasetOptions = {
    series,
    currentMinDate,
    currentMaxDate,
  };

  const bidsDataSet = useOrderPriceDataSet(sortedBids, 'bid', datasetOptions);
  const offersDataSet = useOrderPriceDataSet(sortedAsks, 'ask', datasetOptions);
  const tradesDataSet = useOrderPriceDataSet(
    sortedTrades,
    'trade',
    datasetOptions
  );

  const valuationData = usePrimaryRoundDataSet(
    sortedValuations,
    datasetOptions
  );

  const options = useChartOptions();

  const canvasRef = useRef<HTMLCanvasElement>();

  const chartRef = useRef<Chart<'line', { x: string; y: number }[], string>>();

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  };

  const resetTooltip = useCallback(() => {
    if (!chartRef.current) return;

    getBidOffersChartTooltip(chartRef.current.canvas).style.opacity = '0';

    (chartRef.current as unknown as HoveredPoint).hoveredRaw = {
      raw: null,
      nearestSegment: null,
    };

    chartRef.current.update('none');
  }, []);

  const handleMouseMove = useCallback(
    throttle((event: React.MouseEvent) => {
      if (!chartRef.current) return;

      const TOLERANCE = 10;

      const rect = chartRef.current.canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;
      const mouseP = { x: mouseX, y: mouseY };

      let minDistance = Infinity;
      let nearestSegment = null;

      chartRef.current.data.datasets.forEach((dataset, datasetIndex) => {
        const meta = chartRef.current.getDatasetMeta(datasetIndex);
        const points = meta.data;

        for (let i = 0; i < points.length - 1; i++) {
          const p1 = points[i];
          const p2 = points[i + 1];

          let distance: number;
          if (dataset.stepped === 'before') {
            distance = Math.min(
              // horizontal line
              getDistanceOfThePointFromTheLine(mouseP, p1, {
                ...p2,
                y: p1.y,
              }),
              // vertical line
              getDistanceOfThePointFromTheLine(mouseP, { ...p1, x: p2.x }, p2)
            );
          } else {
            distance = getDistanceOfThePointFromTheLine(mouseP, p1, p2);
          }

          if (distance < minDistance && distance < TOLERANCE) {
            minDistance = distance;
            nearestSegment = { datasetIndex, p1, p2, distance };
          }
        }

        if (nearestSegment) {
          const data = (
            nearestSegment.p1?.raw as {
              raw: unknown;
            }
          )?.raw;

          showBidOfferHistoryChartTooltip(
            {
              canvas: chartRef.current?.canvas,
            },
            data,
            setActiveItem,
            nearestSegment
          );
          (chartRef.current as unknown as HoveredPoint).hoveredRaw = {
            raw: data,
            nearestSegment,
          };
          chartRef.current.update('none');
        } else {
          resetTooltip();
        }
      });
    }, 100),
    [resetTooltip]
  );

  const yAxisMaxValue =
    series === 'PPS'
      ? yAxisMaxValues.maximumPPS
      : yAxisMaxValues.maximumValuation;

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    chartRef.current = new Chart<'line', { x: string; y: number }[], string>(
      ctx,
      {
        type: 'line',
        data: {
          labels,
          datasets:
            mode === 'trades'
              ? [valuationData, tradesDataSet]
              : [valuationData, bidsDataSet, offersDataSet],
        },
        options: options({
          interaction: {
            intersect: false,
            mode: 'point',
          },
          scales: {
            y: {
              max: yAxisMaxValue,
              beginAtZero: true,
              grace: '10%',
              ticks: {
                callback: value =>
                  `$${formatAxisNumber(value, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}`,
              },
            },
            x: {
              min: currentMinDate.getTime(),
              max: currentMaxDate.getTime(),
              type: 'time',
              time: {
                unit: 'month',
              },
              ticks: {
                callback: formatMonthAndYearForChartLabel,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        }),
        plugins: [CrosshairPlugin],
      }
    );

    chartRef.current.update('none');

    return () => destroyChart();
  }, [
    bidsDataSet,
    offersDataSet,
    tradesDataSet,
    valuationData,
    mode,
    labels,
    isDarkMode,
    yAxisMaxValue,
    options,
    currentMinDate,
    currentMaxDate,
  ]);

  return (
    <CardBase noContentPadding={true}>
      <Flex direction="column" gap="xs">
        <CompanyOverviewChartFilters
          series={series}
          onChangeSeries={setSeries}
          range={range}
          onChangeRange={range => {
            setRange(range);
            resetTooltip();
          }}
          pagination={{
            page: currentPage,
            totalPages: pageCount,
            onPageChange: val => {
              setCurrentPage(val);
              resetTooltip();
            },
          }}
        />
        <Body>
          <Container>
            <canvas ref={canvasRef} onMouseMove={handleMouseMove}></canvas>
            <div
              id={BID_OFFER_CHART_HTML_ID}
              className="html-chart-tooltip"
              style={{ opacity: activeItem ? 0.9 : 0 }}
            >
              <BidOfferHistoryChartTooltip point={activeItem} />
            </div>
          </Container>
        </Body>
        <Margin bottom="sm">
          <BidOfferHistoryChartLegend mode={mode} />
        </Margin>
      </Flex>
    </CardBase>
  );
};

// Padding must be set outside the relative container to ensure the tooltip is positioned correctly
const Body = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.md}px
    ${({ theme }) => theme.spacing.md}px;
`;

const Container = styled.div`
  position: relative;
  ${TooltipStyles};
  height: 400px;
  width: 100%;
`;
