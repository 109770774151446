import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { type FormInstance } from 'antd';
import { type FormProps } from 'antd/es/form/Form';
import { type ValidateStatus } from 'antd/es/form/FormItem';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { CypressDataIds } from '@npm/core/ui/constants';
import { abaFormItemValidationRules } from '@npm/core/ui/utils/validators';
import { Codebooks } from '@npm/data-access';
import { type AccountForIndex } from '@npm/data-access';

import { CodebooksSelect } from '../../../filters';
import {
  COMMON_FORM_FIELDS_ENUMS,
  DOMESTIC_FORM_FIELDS_ENUM,
} from '../BankAccountInformation.types';

import {
  fetchBankNameByAbaNumber,
  getAccountTypeFormFields,
} from './BankAccountInformationForm.utils';

import * as S from './BankAccountInformationForm.styles';

type Props = {
  form: FormInstance;
  account: AccountForIndex;
  allowAlternateBeneficiary?: boolean;
  handleValuesChange?: FormProps['onValuesChange'];
};

export const BankAccountInformationForm = ({
  form,
  account,
  allowAlternateBeneficiary = false,
  handleValuesChange,
}: Props) => {
  const [validAbaNumberForBankNameFetch, setValidAbaNumberForBankNameFetch] =
    useState<string>();

  const bankType = Form.useWatch(
    [COMMON_FORM_FIELDS_ENUMS.BANK_ACCOUNT_TYPE],
    form
  );

  useEffect(() => {
    if (account?.name) {
      form.setFieldsValue({
        [COMMON_FORM_FIELDS_ENUMS.BENEFICIARY_NAME]: account.name,
      });
    }
  }, [account, form]);

  const { data: bankNameData } = useQuery({
    queryKey: ['ThirdPartyAbaNumberApi', validAbaNumberForBankNameFetch],
    queryFn: async () =>
      fetchBankNameByAbaNumber(validAbaNumberForBankNameFetch),
    enabled: !!validAbaNumberForBankNameFetch,
  });

  useEffect(() => {
    if (bankNameData?.name) {
      form.setFieldsValue({
        [DOMESTIC_FORM_FIELDS_ENUM.BANK_NAME]: bankNameData?.name,
      });
    }
  }, [form, bankNameData?.name]);

  const onAbaNumberValidStatusChange = (status: ValidateStatus) => {
    const abaNumber = form.getFieldValue(DOMESTIC_FORM_FIELDS_ENUM.ABA_NUMBER);
    // @FIXME: Remove this once we have upgraded to antd >= 5.3.3, as it has a bug with the validation status
    // for more details see https://github.com/ant-design/ant-design/issues/41384
    const isAbaNumberValidExtraCheck = abaFormItemValidationRules.every(
      rule => {
        if (rule.len) {
          return rule.len === abaNumber.length;
        }

        if (rule.pattern) {
          return rule.pattern.test(abaNumber);
        }

        return true;
      }
    );

    setValidAbaNumberForBankNameFetch(
      status === 'success' && isAbaNumberValidExtraCheck ? abaNumber : undefined
    );
  };

  return (
    <Form form={form} onValuesChange={handleValuesChange}>
      <S.FormItem
        name={[COMMON_FORM_FIELDS_ENUMS.BANK_ACCOUNT_TYPE]}
        label="Bank Type"
        data-cy={CypressDataIds.PostOnboarding.BankAccountInformation.BankType}
      >
        <CodebooksSelect
          codebook={Codebooks.BANK_ACCOUNT_TYPE}
          allowClear={false}
          placeholder="Select"
        />
      </S.FormItem>
      {getAccountTypeFormFields(
        bankType,
        onAbaNumberValidStatusChange,
        allowAlternateBeneficiary
      )}
    </Form>
  );
};
