import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { DrawerSection } from '@npm/core/ui/components/molecules/DrawerSection';

import { Step } from './components';

export const NextStepsSection = ({
  orderType = 'IOI',
  orderSide,
}: {
  orderType?: string;
  orderSide: 'buy' | 'sell';
}) => {
  return (
    <DrawerSection
      iconName="arrow-narrow-right"
      title="Next Steps"
      content={
        <Flex gap="sm">
          <Step
            label={
              <>
                Submit
                <br />
                {orderType}
              </>
            }
            iconName="circle-check"
          />
          <Step
            label={
              <>
                Under
                <br />
                Review
              </>
            }
            iconName="clock"
          />
          <Step
            label={
              <>
                Sign
                <br />
                Agreements
              </>
            }
            iconName="edit"
          />
          {orderSide === 'sell' ? (
            <Step
              label={
                <>
                  Get
                  <br />
                  Paid
                </>
              }
              iconName="hand-dollar-up"
            />
          ) : (
            <Step
              label={
                <>
                  Get
                  <br />
                  Shares
                </>
              }
              iconName="chart-pie"
            />
          )}
        </Flex>
      }
    />
  );
};
