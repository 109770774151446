import { Radio as AntRadio } from 'antd';
import styled from 'styled-components';

export const RadioGroup = styled(AntRadio.Group)`
  margin-top: ${({ theme }) => theme.spacing.md}px;

  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`;
