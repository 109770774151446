import React from 'react';

import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { getFullImageUrl } from '@npm/core/ui/utils/path/Path.utils';
import { type IssuerEntityAggregate } from '@npm/data-access';

import { useCurrentAccount } from '../../../../auth/user/role';
import { useWatchlistButton, WatchlistButton } from '../../../../watchlist';
import { LogoSkeleton } from '../skeletons';

import * as S from './CompanyHeading.styles';

type Props = {
  issuerEntity?: IssuerEntityAggregate;
  isLoading?: boolean;
};

export const CompanyHeading = ({ issuerEntity, isLoading }: Props) => {
  const { isOboOrAccountCentric } = useCurrentAccount();
  const fullUrl = getFullImageUrl(issuerEntity?.logo_url);

  const watchlistButtonProps = useWatchlistButton({
    company: issuerEntity,
  });

  return (
    <S.Header>
      {isLoading ? (
        <LogoSkeleton />
      ) : (
        <>
          <S.LogoContainer>
            <CompanyLogo url={fullUrl} size="lg" />
            <Heading variant="h1">{issuerEntity?.name}</Heading>
          </S.LogoContainer>
          {isOboOrAccountCentric ? (
            <WatchlistButton color="success" {...watchlistButtonProps} />
          ) : null}
        </>
      )}
    </S.Header>
  );
};
