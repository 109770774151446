import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  CbAccountType,
  useBrokerageFirmAccountCreate,
  useEmailSend,
} from '@npm/data-access';

import { useCurrentRole } from '../../auth/user/role/hooks/useCurrentRole';

import type { NewAccountFormValues } from './NewAccountDrawer.types';

export const useCreatePersonAccount = () => {
  const role = useCurrentRole();

  const { withShowApiErrorMiddleware } = useAlerts();

  const { execute: createPersonAcc, isLoading: isCreatePersonAccLoading } =
    useBrokerageFirmAccountCreate();
  const { execute: sendEmail, isLoading: isSendingEmail } = useEmailSend();

  const createPersonAccount = async (fields: NewAccountFormValues) => {
    let result;

    try {
      result = await withShowApiErrorMiddleware(createPersonAcc)({
        brokerageFirmId: role.subject?.id,
        brokerageFirmsAccountCreateRequestContract: {
          external_id: fields.id,
          first: fields.first,
          middle: fields.middle,
          last: fields.last,
          email: fields.email,
          user_account_role_type: fields.role,
          account_type: CbAccountType.items.PersonAccount,
          skip_okta: !!fields.body || !!fields.subject,
        },
      });
    } catch (error) {
      console.error(error);
      throw error;
    }

    if (fields.subject) {
      try {
        await withShowApiErrorMiddleware(sendEmail)({
          emailCreateRequestContract: {
            email_subject: fields.subject,
            email_body: fields.body,
            email_type: 'portal_invite',
            from: `${role?.subject?.name.toLowerCase()}@npm.com`,
            to: fields.email,
            user_id: result?.user_id,
          },
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    return { result };
  };

  return {
    createPersonAccount,
    isCreatePersonAccLoading,
    isSendingEmail,
  };
};
