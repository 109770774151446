import {
  type CbLabelComponent,
  type IconProps,
  type LabelVariant,
} from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  accredited_investor: 'accredited_investor',
  not_available: 'not_available',
  qualified_institutional_buyer: 'qualified_institutional_buyer',
  qualified_purchaser: 'qualified_purchaser',
  qualified_client: 'qualified_client',
} as const;

const LabelMap: Record<keyof typeof Items, LabelVariant> = {
  accredited_investor: 'success',
  not_available: 'error',
  qualified_institutional_buyer: 'success',
  qualified_purchaser: 'success',
  qualified_client: 'success',
};

export const CbAccreditationLevel = {
  code: Codebooks.ACCREDITATION_LEVEL,
  items: Items,
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem,
    iconProps?: IconProps
  ) => {
    if (codebookItem?.code === undefined || codebookItem?.name === undefined) {
      return null;
    }

    return Component({
      variant: LabelMap[codebookItem?.code as keyof typeof Items],
      label: codebookItem?.name,
      iconProps,
    });
  },
};
