/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IssuancesUnitIndex } from '../model';
// @ts-ignore
import { IssuancesUnitShowAggregate } from '../model';
/**
 * UnitIssuancesApi - axios parameter creator
 * @export
 */
export const UnitIssuancesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get unit issuance with ocf_id
         * @param {string} issuerNpmGuid 
         * @param {string} ocfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitIssuance: async (issuerNpmGuid: string, ocfId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerNpmGuid' is not null or undefined
            assertParamExists('getUnitIssuance', 'issuerNpmGuid', issuerNpmGuid)
            // verify required parameter 'ocfId' is not null or undefined
            assertParamExists('getUnitIssuance', 'ocfId', ocfId)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_npm_guid}/issuances/units/{ocf_id}`)
                .replace(`{${"issuer_npm_guid"}}`, encodeURIComponent(String(issuerNpmGuid)))
                .replace(`{${"ocf_id"}}`, encodeURIComponent(String(ocfId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all unit issuances
         * @param {string} issuerNpmGuid 
         * @param {string} [stakeholderOcfId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnitIssuances: async (issuerNpmGuid: string, stakeholderOcfId?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerNpmGuid' is not null or undefined
            assertParamExists('listUnitIssuances', 'issuerNpmGuid', issuerNpmGuid)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_npm_guid}/issuances/units`)
                .replace(`{${"issuer_npm_guid"}}`, encodeURIComponent(String(issuerNpmGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (stakeholderOcfId !== undefined) {
                localVarQueryParameter['stakeholder_ocf_id'] = stakeholderOcfId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnitIssuancesApi - functional programming interface
 * @export
 */
export const UnitIssuancesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnitIssuancesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get unit issuance with ocf_id
         * @param {string} issuerNpmGuid 
         * @param {string} ocfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnitIssuance(issuerNpmGuid: string, ocfId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuancesUnitShowAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnitIssuance(issuerNpmGuid, ocfId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all unit issuances
         * @param {string} issuerNpmGuid 
         * @param {string} [stakeholderOcfId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUnitIssuances(issuerNpmGuid: string, stakeholderOcfId?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuancesUnitIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUnitIssuances(issuerNpmGuid, stakeholderOcfId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnitIssuancesApi - factory interface
 * @export
 */
export const UnitIssuancesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnitIssuancesApiFp(configuration)
    return {
        /**
         * 
         * @summary get unit issuance with ocf_id
         * @param {string} issuerNpmGuid 
         * @param {string} ocfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitIssuance(issuerNpmGuid: string, ocfId: string, options?: any): AxiosPromise<IssuancesUnitShowAggregate> {
            return localVarFp.getUnitIssuance(issuerNpmGuid, ocfId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all unit issuances
         * @param {string} issuerNpmGuid 
         * @param {string} [stakeholderOcfId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnitIssuances(issuerNpmGuid: string, stakeholderOcfId?: string, page?: number, size?: number, options?: any): AxiosPromise<IssuancesUnitIndex> {
            return localVarFp.listUnitIssuances(issuerNpmGuid, stakeholderOcfId, page, size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getUnitIssuance operation in UnitIssuancesApi.
 * @export
 * @interface UnitIssuancesApiGetUnitIssuanceRequest
 */
export interface UnitIssuancesApiGetUnitIssuanceRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitIssuancesApiGetUnitIssuance
     */
    readonly issuerNpmGuid: string

    /**
     * 
     * @type {string}
     * @memberof UnitIssuancesApiGetUnitIssuance
     */
    readonly ocfId: string
}

/**
 * Request parameters for listUnitIssuances operation in UnitIssuancesApi.
 * @export
 * @interface UnitIssuancesApiListUnitIssuancesRequest
 */
export interface UnitIssuancesApiListUnitIssuancesRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitIssuancesApiListUnitIssuances
     */
    readonly issuerNpmGuid: string

    /**
     * 
     * @type {string}
     * @memberof UnitIssuancesApiListUnitIssuances
     */
    readonly stakeholderOcfId?: string

    /**
     * 
     * @type {number}
     * @memberof UnitIssuancesApiListUnitIssuances
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof UnitIssuancesApiListUnitIssuances
     */
    readonly size?: number
}

/**
 * UnitIssuancesApi - object-oriented interface
 * @export
 * @class UnitIssuancesApi
 * @extends {BaseAPI}
 */
export class UnitIssuancesApi extends BaseAPI {
    /**
     * 
     * @summary get unit issuance with ocf_id
     * @param {UnitIssuancesApiGetUnitIssuanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitIssuancesApi
     */
    public getUnitIssuance(requestParameters: UnitIssuancesApiGetUnitIssuanceRequest, options?: AxiosRequestConfig) {
        return UnitIssuancesApiFp(this.configuration).getUnitIssuance(requestParameters.issuerNpmGuid, requestParameters.ocfId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all unit issuances
     * @param {UnitIssuancesApiListUnitIssuancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitIssuancesApi
     */
    public listUnitIssuances(requestParameters: UnitIssuancesApiListUnitIssuancesRequest, options?: AxiosRequestConfig) {
        return UnitIssuancesApiFp(this.configuration).listUnitIssuances(requestParameters.issuerNpmGuid, requestParameters.stakeholderOcfId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }
}

export type GetUnitIssuanceErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetUnitIssuanceErrorCodes = [
];

export type ListUnitIssuancesErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const ListUnitIssuancesErrorCodes = [
    404,
];


