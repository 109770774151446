import React, { type ComponentProps } from 'react';

import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { CbSubjectType, useAccountShow } from '@npm/data-access';

import { useCurrentAccount, useObo } from '../../../auth/user/role';
import { useCurrentRole } from '../../../auth/user/role/hooks/useCurrentRole';
import { BrokerageAccountSearch, UserRoleSearch } from '../../smartSelects';
import { type FilterItemProps } from '../FilterPanel';

type Props = FilterItemProps &
  ComponentProps<typeof UserRoleSearch | typeof BrokerageAccountSearch>;

export const AccountFilter = ({
  name,
  onFilterSubmit,
  value,
  ...rest
}: Props) => {
  const role = useCurrentRole();
  const { isObo } = useObo();
  const { accountId } = useCurrentAccount();

  const brokerageFirmId =
    (role?.subject_type?.code === CbSubjectType.items.BROKERAGE_FIRM ||
      // there is bug on BE returning wrong code
      role?.subject_type?.code === 'BrokerageFirm') &&
    role?.subject?.id;

  // for default values when filter is disabled (account-centric / obo)
  const { data } = useAccountShow(
    { id: accountId?.toString() },
    {
      queryConfig: {
        enabled: !!accountId,
      },
    }
  );

  if (!isObo && brokerageFirmId)
    return (
      <BrokerageAccountSearch
        brokerageFirmId={brokerageFirmId}
        onSelect={() => {
          onFilterSubmit?.(name);
        }}
        onClear={() => onFilterSubmit?.(name)}
        value={value}
        {...rest}
      />
    );

  return (
    <Tooltip
      title={
        accountId
          ? isObo
            ? 'You are currently acting on behalf this account'
            : 'Use the account switcher to see results for All or a different account'
          : undefined
      }
    >
      <UserRoleSearch
        defaultUserRole={
          accountId && {
            // this solves inconsistencies between account types
            id: data?.id,
            subject: {
              brokerage_firm_name: data?.brokerage_firm?.name,
              ...data,
            },
          }
        }
        // disabled selection if account centric or OBO
        disabled={!!accountId || isObo}
        value={accountId ? data?.id : value}
        onSelect={() => {
          onFilterSubmit?.(name);
        }}
        onClear={() => onFilterSubmit?.(name)}
        {...rest}
      />
    </Tooltip>
  );
};
