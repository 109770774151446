import { Codebooks } from './codebooks.types';

const Items = {
  Common: 'COMMON',
  Preferred: 'PREFERRED',
} as const;

export type CbStockClassCode = typeof Items[keyof typeof Items];

export const CbStockClass = {
  code: Codebooks.STOCK_CLASS,
  items: Items,
};
