import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  type Account,
  CbTaxDocument,
  useAccountShow,
  useOrganizationShow,
  useOrganizationUpdate,
} from '@npm/data-access';

import { FormFields } from '../Form/Form.types';

export const useEntityTaxIdForm = (
  account: Account,
  { onUpdate }: { onUpdate?: () => void }
) => {
  const organizationId = account?.organization_id;

  const { withShowApiErrorMiddleware } = useAlerts();
  const [form] = Form.useForm();

  const { isLoading, data } = useOrganizationShow(
    {
      id: organizationId,
    },
    { queryConfig: { enabled: !!organizationId } }
  );

  const { isLoading: isAccountLoading, data: accountData } = useAccountShow(
    {
      id: account?.id?.toString(),
    },
    { queryConfig: { enabled: !!account } }
  );

  const { execute, isLoading: isUpdating } = useOrganizationUpdate();

  const handleSubmit = async () => {
    let values;

    try {
      values = await form.validateFields();
    } catch (e) {
      return console.error('Failed:', e);
    }

    try {
      await withShowApiErrorMiddleware(execute)({
        id: organizationId,
        organizationUpdateRequestContract: {
          tax_id: values[FormFields.INPUT_KEY],
          tax_id_exempt: values[FormFields.CHECKBOX_KEY] === true,
        },
      });

      onUpdate?.();
    } catch (e) {
      console.error(e);
    }
  };

  return {
    form,
    data,
    handleSubmit,
    isLoading: isLoading || isAccountLoading,
    isUpdating,
    isExemptHidden:
      accountData?.tax_document?.code === CbTaxDocument.items.DOCUMENT_W9,
  };
};
