import React, { type ComponentProps } from 'react';
import { useTheme } from 'styled-components';

import { formatQuantity } from '../../../utils/formatters';
import { Flex } from '../../atoms/common';
import { Icon } from '../../atoms/Icon';
import { Text } from '../../atoms/Typography';

type Props = {
  change: number;
  style?: React.CSSProperties;
  iconSize?: ComponentProps<typeof Icon>['size'];
  textSize?: ComponentProps<typeof Text>['size'];
  maximumFractionDigits?: number;
};

export const PercentageChange = ({
  change,
  style,
  iconSize,
  textSize,
  maximumFractionDigits = 2,
}: Props) => {
  const theme = useTheme();

  if (!change) {
    return <Text size={textSize || 'sm'}>--</Text>;
  }

  return (
    <Flex align="center" style={style}>
      <Icon
        name={change < 0 ? 'arrow-narrow-45-down' : 'arrow-narrow-45-up'}
        size={iconSize || 'xs'}
        color={theme.color[change < 0 ? 'error' : 'success'].typography.primary}
      />
      <Text size={textSize || 'sm'} color={change < 0 ? 'error' : 'success'}>
        {formatQuantity(Math.abs(change), { maximumFractionDigits })}%
      </Text>
    </Flex>
  );
};
