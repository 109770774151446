import React from 'react';
import { type ModalProps } from 'antd/lib/modal';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  DATE_FORMATS,
  formatDate,
  getTimezoneShort,
  truncate,
} from '@npm/core/ui/utils/formatters';
import {
  type AssetTypeCode,
  type DocumentSimple,
  type EventAggregate,
  type Holding,
  type NegotiationShowHolding,
  CbAssetType,
  CbHoldingState,
  CbOwnerType,
} from '@npm/data-access';

import { AccountName } from '../account/components/AccountName';

import { HoldingStatus } from './components/HoldingStatus';
import { type HoldingAssetTypeKey } from './Form/HoldingForm.types';
import { type HoldingItem, type HoldingItemKey } from './Holdings.types';

import * as S from './Holdings.styles';

const EMPTY_VALUE = '--';

const getExtraHoldingColumns = (
  type: HoldingAssetTypeKey
): HoldingItemKey[] => {
  const {
    PreferredStock,
    CommonStock,
    RestrictedStockUnit,
    Unit,
    Option,
    Warrant,
  } = CbAssetType.items;

  switch (type) {
    case PreferredStock:
      return ['series', 'acquisition_date', 'cost_basis'];
    case CommonStock:
      return ['class', 'acquisition_date', 'cost_basis'];
    case Unit:
      return ['class', 'acquisition_date', 'cost_basis'];
    case RestrictedStockUnit:
      return ['class', 'grant_date', 'cost_basis'];
    case Option:
      return [
        'grant_type',
        'grant_date',
        'plan',
        'strike_price',
        'expiration_date',
      ];
    case Warrant:
      return ['grant_date', 'series', 'strike_price', 'expiration_date'];
    default:
      return [];
  }
};

const renderCertificateNumber = (
  id: number,
  certificateNumber: string | undefined,
  onEditHolding?: (id) => void
) =>
  certificateNumber ? (
    <span data-cy={CypressDataIds.Holdings.Properties.UniqueId}>
      {certificateNumber}
    </span>
  ) : onEditHolding ? (
    <S.TextLink
      onClick={e => {
        e.stopPropagation();
        onEditHolding(id);
      }}
    >
      + Add
    </S.TextLink>
  ) : (
    EMPTY_VALUE
  );

export const getAddHoldingButtonText = (holding?: Holding) => {
  const { isOption, isWarrant, isRSU } = getHoldingType(holding);

  if (isOption || isWarrant || isRSU) {
    return '+ Add Grant ID';
  }

  return '+ Add Certificate ID';
};

export const getIsHoldingAttributeVisible = (
  type: HoldingAssetTypeKey,
  attribute: HoldingItemKey
) => getExtraHoldingColumns(type).includes(attribute);

type HoldingItemArgs = {
  onClick?: (doc?: DocumentSimple) => void;
  onShowInstructions?: (props: ModalProps) => void;
  onEditHolding?: (id: number) => void;
  getTooltip?: (holding: Holding) => string | null;
  statusAlign?: React.CSSProperties['alignItems'];
};

export const getHoldingItem = (
  item: HoldingItemKey
): ((args?: HoldingItemArgs) => HoldingItem) => {
  const items: Record<HoldingItemKey, (args?: HoldingItemArgs) => HoldingItem> =
    {
      issuer_entity: () => ({
        title: 'Company',
        key: 'issuer_entity',
        render: (_, { issuer_entity }) => {
          return (
            <CompanyLogo
              url={issuer_entity?.logo_url}
              size="sm"
              name={issuer_entity?.name}
            />
          );
        },
      }),
      issuance: () => ({
        title: 'Issuance',
        key: 'issuance',
        render: (_, { issuance }) => issuance?.name,
      }),
      account: () => ({
        title: 'Account',
        key: 'account',
        render: (_, { account }) => <AccountName account={account} />,
      }),
      accountWithoutLink: () => ({
        title: 'Account',
        key: 'accountWithoutLink',
        render: (_, { account }) => (
          <AccountName withoutLink account={account} />
        ),
      }),
      asset: () => ({
        title: 'Asset Type',
        key: 'asset',
        render: (_, { asset }) => asset?.type?.name,
      }),
      unique_id: ({ onEditHolding }) => ({
        title: 'Unique ID',
        key: 'certificate_number',
        width: 150,
        render: (_, { id, certificate_number }) =>
          renderCertificateNumber(id, certificate_number, onEditHolding),
      }),
      quantity: () => ({
        title: 'Quantity',
        key: 'quantity',
        render: (_, { quantity }) => <Text.Quantity value={quantity} />,
      }),

      vested_qty: () => ({
        title: 'Vested Quantity',
        key: 'vested_qty',
        // showing 0 as "0", not "--"
        render: (_, { vested_qty }) => (
          <Text.Quantity value={vested_qty || '0'} />
        ),
      }),

      pre_verified: () => ({
        title: 'Pre-Verified',
        key: 'pre_verified',
        render: (_, { pre_verified }) =>
          pre_verified ? (
            <Flex align="center" gap="xs">
              <Icon name="check" size="xs" color="info" />
              <Text size="sm" colorVariant="primary">
                Yes
              </Text>
            </Flex>
          ) : (
            <Text size="sm" colorVariant="tertiary">
              No
            </Text>
          ),
      }),

      state: ({ onShowInstructions, getTooltip, statusAlign } = {}) => ({
        title: 'Status',
        key: 'state',
        render: (_, holding) => (
          <HoldingStatus
            holding={holding}
            onShowInstructions={onShowInstructions}
            getTooltip={getTooltip}
            align={statusAlign}
          />
        ),
      }),

      registered_name: () => ({
        title: 'Registered Name',
        key: 'registered_name',
        render: (_, { registered_name }) => registered_name,
      }),

      proof_of_ownership_document: ({ onClick, onEditHolding }) => ({
        title: 'Proof of Ownership',
        className: 'no-text-transform',
        key: 'proof_of_ownership_document',
        render: (_, { id, proof_of_ownership_document, state }) =>
          proof_of_ownership_document ? (
            <S.PdfWrapper>
              <Icon name="paperclip" size="xs" />
              <Text
                size="sm"
                onClick={() => {
                  onClick(proof_of_ownership_document);
                }}
              >
                {truncate(proof_of_ownership_document.display_name, 50)}
              </Text>
            </S.PdfWrapper>
          ) : state?.code === CbHoldingState.items.verified ||
            !onEditHolding ? (
            EMPTY_VALUE
          ) : (
            <S.TextLink onClick={() => onEditHolding(id)}>+ Add</S.TextLink>
          ),
      }),

      grant_type: () => ({
        title: 'Grant Type',
        key: 'grant_type',
        render: (_, { grant_type }) => grant_type?.name,
      }),

      grant_date: () => ({
        title: 'Grant Date',
        key: 'grant_date',
        render: (_, { grant_date }) =>
          formatDate(grant_date, { dateFormat: DATE_FORMATS.DATE }) ||
          EMPTY_VALUE,
      }),

      plan: () => ({
        title: 'Plan',
        key: 'plan',
        render: (_, { asset }) => asset?.plan || EMPTY_VALUE,
      }),

      class: () => ({
        title: 'Class',
        key: 'class',
        render: (_, { asset }) => asset?.series || EMPTY_VALUE,
      }),

      series: () => ({
        title: 'Series',
        key: 'series',
        render: (_, { asset }) => asset?.series || EMPTY_VALUE,
      }),

      cost_basis: () => ({
        title: 'Cost Basis',
        key: 'cost_basis',
        render: (_, { cost_basis, issuance }) =>
          cost_basis ? (
            <Text.Price
              value={cost_basis}
              formatOptions={{ long: true }}
              currency={issuance.denomination?.name ?? 'USD'}
            />
          ) : (
            EMPTY_VALUE
          ),
      }),

      strike_price: () => ({
        title: 'Strike Price',
        key: 'strike_price',
        render: (_, { asset, issuance }) =>
          asset?.strike_price ? (
            <Text.Price
              value={asset?.strike_price}
              formatOptions={{ long: true }}
              currency={issuance.denomination?.name ?? 'USD'}
            />
          ) : (
            EMPTY_VALUE
          ),
      }),

      expiration_date: () => ({
        title: 'Expiration Date',
        key: 'expiration_date',
        render: (_, { expiration_date }) =>
          formatDate(expiration_date, { dateFormat: DATE_FORMATS.DATE }) ||
          EMPTY_VALUE,
      }),

      acquisition_date: () => ({
        title: 'Acquisition Date',
        key: 'acquisition_date',
        render: (_, { acquisition_date }) =>
          formatDate(acquisition_date, { dateFormat: DATE_FORMATS.DATE }) ||
          EMPTY_VALUE,
      }),

      offer_price: () => ({
        title: 'Offer Price',
        key: 'offer_price',
        render: (_, { asset, issuance }) =>
          asset?.offer_price ? (
            <Text.Price
              value={asset?.offer_price}
              formatOptions={{ long: true }}
              currency={issuance.denomination?.name ?? 'USD'}
            />
          ) : (
            EMPTY_VALUE
          ),
      }),

      remaining_quantity: () => ({
        title: 'Remaining Quantity',
        key: 'remaining_quantity',
        render: (_, { remaining_quantity }) => (
          <Text.Quantity value={remaining_quantity} />
        ),
        titleInfo:
          'Outstanding Vested Quantity that is eligible to be sold and is not associated with a live order or matched transaction.',
      }),

      created_at: () => ({
        title: `Created At (${getTimezoneShort()})`,
        key: 'created_at',
        render: (_, { created_at }) =>
          created_at ? formatDate(created_at) : EMPTY_VALUE,
      }),

      updated_at: () => ({
        title: `Updated At (${getTimezoneShort()})`,
        key: 'updated_at',
        render: (_, { updated_at }) =>
          updated_at ? formatDate(updated_at) : EMPTY_VALUE,
      }),
    };

  return items[item];
};

export const getDisabledHoldingActionsTooltip = ({
  state,
}: Partial<Holding>) => {
  if (state.code === CbHoldingState.items.verified)
    return 'Verified holdings cannot be modified.';

  return null;
};
export const getDisabledDeleteActionTooltip = (holding: Partial<Holding>) => {
  if (holding.order_item_count)
    return 'Holdings with open orders cannot be deleted.';

  return null;
};

export const getHoldingType = (holding: Holding) => {
  const assetType = holding?.asset?.type?.code;
  const isWarrant = assetType === CbAssetType.items.Warrant;
  const isOption = assetType === CbAssetType.items.Option;
  const isPreferred = assetType === CbAssetType.items.PreferredStock;
  const isCommonStock = assetType === CbAssetType.items.CommonStock;
  const isUnit = assetType === CbAssetType.items.Unit;
  const isRSU = assetType === CbAssetType.items.RestrictedStockUnit;

  const isIneligible =
    holding?.asset?.eligible !== undefined && holding?.asset?.eligible !== true;

  return {
    isWarrant,
    isOption,
    isPreferred,
    isCommonStock,
    isUnit,
    isRSU,
    isIneligible,
  };
};

const getHoldingAssetType = (holding: Holding): HoldingAssetTypeKey =>
  holding?.asset?.type?.code;

export const getIsHoldingEligible = (holding: Holding) =>
  holding?.asset?.eligible === undefined || holding?.asset?.eligible === true;

export const getIsOrderHoldingExercisable = (
  holding: NegotiationShowHolding,
  event: EventAggregate
) =>
  (getHoldingAssetType(holding) === CbAssetType.items.Option ||
    getHoldingAssetType(holding) === CbAssetType.items.Warrant) &&
  event?.type?.code === CbOwnerType.items.TenderOffer &&
  event?.order_settings?.exercise_and_hold === true;

export const isSpvHolding = (assetType: AssetTypeCode) => {
  return (
    assetType === CbAssetType.items.SingleLayerSpv ||
    assetType === CbAssetType.items.DoubleLayerSpv
  );
};

export const getIndividualInvestorHoldingStateTooltip = (holding: Holding) => {
  if (
    holding.state?.code === CbHoldingState.items.pre_verified ||
    (holding.state?.code === CbHoldingState.items.pending &&
      holding.pre_verified)
  ) {
    return 'Your holding has been pre-verified by the NPM team and is ready to be used to engage with live bids.';
  }
  if (holding.state?.code === CbHoldingState.items.pending) {
    return 'This holding for a proposed transfer requires review before it can progress.';
  }
  if (holding.state?.code === CbHoldingState.items.verified) {
    return 'This holding has been verified and no other action is required.';
  }
  if (holding.state?.code === CbHoldingState.items.needs_verification) {
    return 'Requires additional information before being verified by the company. Additional details may have been sent for you to review to update your holding information.';
  }

  return null;
};
