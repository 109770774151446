import styled from 'styled-components';

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 200px;
  background-color: ${({ theme }) => theme.color.general.layout.two};
  border-radius: 50%;
`;

export const IconTextWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const RightPlugWrapper = styled.div`
  color: ${({ theme }) => theme.color.general.typography.secondary};
`;
