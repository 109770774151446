import React, { type ComponentProps, useMemo } from 'react';

import { Select } from '@npm/core/ui/components/atoms/Select';
import { onPopupScroll } from '@npm/core/ui/components/atoms/Select/Select.utils';
import { useIssuerEntitySectorIndexInfinite } from '@npm/data-access';

type Props = ComponentProps<typeof Select>;

export const IssuerEntitySectorsSelect = (props: Props) => {
  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    error,
  } = useIssuerEntitySectorIndexInfinite({}, { onError: Select.onError });

  const options = useMemo(() => {
    if (!data) return null;

    const issuerEntitySectors = data.pages.flatMap(
      page => page.issuer_entity_sectors
    );

    return [
      ...issuerEntitySectors.map(sector => ({
        label: sector,
        value: sector,
      })),
    ];
  }, [data]);

  return (
    <Select
      allowClear
      loading={isLoading}
      infiniteLoading={isFetchingNextPage}
      onPopupScroll={e =>
        onPopupScroll(e, hasNextPage && !isFetchingNextPage && fetchNextPage)
      }
      placeholder={options ? `Select (${options.length})` : 'Select'}
      options={options}
      error={error}
      {...props}
    />
  );
};
