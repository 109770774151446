import styled from 'styled-components';

import { Select } from '@npm/core/ui/components/atoms/Select';
import { SmallTextStyles } from '@npm/core/ui/components/atoms/Typography';

export const StyledSelect = styled(Select)`
  && .ant-select-dropdown {
    z-index: ${({ theme }) => theme.zIndex.drawer};
  }

  .rc-virtual-list {
    .ant-select-item.stock-class {
      min-height: 34px;
      .ant-select-item-option-content {
        ${SmallTextStyles};
        color: ${({ theme }) => theme.color.general.typography.secondary};
      }
    }
  }
`;
