import styled from 'styled-components';

export const FILTER_PANEL_HORIZONTAL_PADDING = 'md';

export const FixedWrapper = styled.div<{ $isShownInsideDrawer: boolean }>`
  z-index: ${({ theme }) => theme.zIndex.aboveDrawer};
  position: sticky;
  left: 0;
  top: 0;
  background: ${({ theme, $isShownInsideDrawer }) =>
    $isShownInsideDrawer
      ? theme.color.general.layout.zero
      : theme.color.general.layout.one};
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing[FILTER_PANEL_HORIZONTAL_PADDING]}px;
  padding-top: ${({ theme }) => theme.spacing.sm}px;
  max-height: 100vh;
`;
