import styled from 'styled-components';

export const AccountNameWrapper = styled.div`
  display: flex;
  align-items: center;

  & > :not(:only-child):first-child {
    margin-right: ${({ theme }) => theme.spacing.xs}px;
  }
`;

export const AccountLog = styled.div<{ $hasSmallSpacing?: boolean }>`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: ${({ theme, $hasSmallSpacing }) =>
      $hasSmallSpacing ? theme.spacing.xs / 2 : theme.spacing.xs}px;
  }
`;
