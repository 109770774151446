import { sortBy } from 'lodash';

import {
  type CodebookIndex,
  type VenusApi,
  CbAssetType,
} from '@npm/data-access';

export type AssetTypeSelectOption = {
  label: string;
  value: string;
  className?: string;
};

// missing in swagger so added just here
const CbStockClass = {
  items: {
    Common: 'Common',
    Preferred: 'Preferred',
  },
} as const;

const STOCK_CLASS_ASSET_TYPE_MAPPING = {
  [CbStockClass.items.Common]: CbAssetType.items.CommonStock,
  [CbStockClass.items.Preferred]: CbAssetType.items.PreferredStock,
};

// we assume that the format is always the same "Class X Common Stock" or "Series X Preferred Stock"
export const getClassFromStockClassName = (name: string) => {
  const parts = name?.split(' ');
  return parts?.length > 1 ? parts[1] : name;
};

const addAssetTypeToStockClassValue = (
  value: string,
  stockClass: keyof typeof CbStockClass.items
) =>
  `${STOCK_CLASS_ASSET_TYPE_MAPPING[CbStockClass.items[stockClass]]}-${value}`;

export const getAssetTypeFromStockClassValue = (name: string) => {
  const parts = name?.split('-');
  return parts?.[0] ? CbAssetType.items[parts[0]] : name;
};

export const getIsOptionVenusStockClass = (name: string) => {
  return !Object.keys(CbAssetType.items)?.find(v => v === name);
};

const getStockClassOptions = (
  type: keyof typeof CbStockClass.items,
  stockClassesData: VenusApi.StockClassIndex
) => {
  const stockClasses =
    stockClassesData?.stock_classes
      .filter(
        sc =>
          !!sc.name &&
          ((type === CbStockClass.items.Common &&
            sc.name.toLowerCase().includes('common')) ||
            (type === CbStockClass.items.Preferred &&
              sc.name.toLowerCase().includes('preferred')))
      )
      .map(({ name }) => ({
        name,
        code: getClassFromStockClassName(name),
      })) || [];

  return stockClasses
    .map(({ name }) => ({
      label: name,
      // basically it's always the same value, we need to strip
      // the unique part (original value) before sending to backend (to send only assetType)
      value: addAssetTypeToStockClassValue(name, type),
      className: 'stock-class',
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const getCommonAndPreferredAssetOptions = (
  data: CodebookIndex['codebooks'],
  stockClassesData?: VenusApi.StockClassIndex,
  includeClassOptions = true
) => {
  if (!data) return [];

  const commonStockCodebook = data.find(
    c => c.code === CbAssetType.items.CommonStock
  );
  const preferredStockCodebook = data.find(
    c => c.code === CbAssetType.items.PreferredStock
  );

  const commonStockOptions =
    stockClassesData && includeClassOptions
      ? getStockClassOptions(CbStockClass.items.Common, stockClassesData)
      : [];
  const preferredStockOptions =
    stockClassesData && includeClassOptions
      ? getStockClassOptions(CbStockClass.items.Preferred, stockClassesData)
      : [];

  return [
    {
      label: commonStockCodebook.name,
      value: commonStockCodebook.code,
    },
    ...commonStockOptions,
    {
      label: preferredStockCodebook.name,
      value: preferredStockCodebook.code,
    },
    ...preferredStockOptions,
  ];
};

// get asset options for the rest of the codebooks (option, RSU, unit, warrant...)
export const getRestAssetOptions = (data: CodebookIndex['codebooks']) => {
  if (!data) return [];

  const restCodebooks = data.filter(
    c =>
      c.code !== CbAssetType.items.PreferredStock &&
      c.code !== CbAssetType.items.CommonStock &&
      CbAssetType.getAttrByName(c, 'show_in_combo_box').value === 'true'
  );

  return restCodebooks.map(({ name, code }) => ({
    label: name,
    value: code,
  }));
};

const individualSortOrder = [
  CbAssetType.items.CommonStock,
  CbAssetType.items.Option,
  CbAssetType.items.RestrictedStockUnit,
  CbAssetType.items.Unit,
  CbAssetType.items.PreferredStock,
  CbAssetType.items.Warrant,
  CbAssetType.items.SingleLayerSpv,
  CbAssetType.items.DoubleLayerSpv,
];

export const sortAssetTypeOptions = (
  options: AssetTypeSelectOption[],
  isIndividual: boolean
): AssetTypeSelectOption[] => {
  if (!isIndividual) return options;
  const sortOrder = individualSortOrder;

  return sortBy(options, [
    option => {
      const optionIndex = sortOrder.indexOf(
        getAssetTypeFromStockClassValue(option.value)
      );
      return optionIndex === -1 ? sortOrder.length : optionIndex;
    },
    option => {
      return option.className ? 1 : -1;
    },
  ]);
};
