import { type FC } from 'react';
import { type BreadcrumbProps, Breadcrumb as BreadcrumbBase } from 'antd';
import type BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import type BreadcrumbSeparator from 'antd/lib/breadcrumb/BreadcrumbSeparator';
import styled from 'styled-components';

// [Antd Typings] Ant'd internal types are not exported, need to re-type.
type AntdTypeBreadcrumb = FC<BreadcrumbProps> & {
  Item: typeof BreadcrumbItem;
  Separator: typeof BreadcrumbSeparator;
};

export const Breadcrumb = styled<AntdTypeBreadcrumb>(BreadcrumbBase)`
  white-space: nowrap;

  .ant-breadcrumb-separator {
    color: ${({ theme }) => theme.color.info.typography.primary};
  }
  .ant-breadcrumb-link button {
    display: inline-flex;
  }
`;

export const Prefix = styled.div`
  display: flex;
  align-items: baseline;

  & > svg {
    align-self: center;
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }
`;
