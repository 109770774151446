import { Codebooks } from './codebooks.types';

const Items = {
  read: 'read',
  unread: 'unread',
} as const;

export const CbNotificationState = {
  code: Codebooks.NOTIFICATION_STATE,
  items: Items,
};
