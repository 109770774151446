/* eslint-disable sonarjs/no-duplicate-string */
import { type pathMetadataList } from '@npm/core/ui/services/pathMetadata';

type PathMetadata = typeof secondMarketGeneralMetadata;

export const registerSecondMarketGeneralMetadata = (metadata: PathMetadata) => {
  secondMarketGeneralMetadata.forEach(m => {
    metadata.push(m);
  });
};

export const secondMarketGeneralMetadata = [
  {
    pathRegex: /.*second-market\/company-data.*/,
    metadata: {
      screenId: 'NFE-1046',
      screenName: 'SecondMarket - Company Data',
    },
  },
  {
    pathRegex: /.*second-market\/market-feed.*/,
    metadata: {
      screenId: 'NFE-4072',
      screenName: 'SecondMarket - Market Feed',
    },
  },
  {
    pathRegex: /.*second-market\/negotiations\/d*.*/,
    metadata: {
      screenId: 'NFE-522',
      screenName: 'OBO SecondMarket - Negotiation Tracker',
    },
  },
  {
    pathRegex:
      /.*second-market\/negotiations.*[?&](?:oboAccountId|oboUserId).*/,
    metadata: {
      screenId: 'NFE-516',
      screenName: 'OBO SecondMarket - Negotiations',
    },
  },
  {
    pathRegex: /.*second-market\/negotiations.*/,
    metadata: {
      screenId: 'NFE-532',
      screenName: 'SecondMarket - Negotiations',
    },
  },
  {
    pathRegex: /.*second-market\/client-orders\/d*.*/,
    metadata: {
      screenId: 'NFE-1381',
      screenName: 'SecondMarket - Client Inventory - Order Detail',
    },
  },
  {
    pathRegex: /.*second-market\/client-orders.*/,
    metadata: {
      screenId: 'NFE-531',
      screenName: 'SecondMarket - Client Inventory',
    },
  },
  {
    pathRegex: /.*second-market\/investor\/client-orders.*/,
    metadata: {
      screenId: 'NFE-512',
      screenName: 'SecondMarket - Investor - IOIs',
    },
  },
  {
    pathRegex: /.*second-market\/investor\/client-orders\/d*.*/,
    metadata: {
      screenId: 'NFE-1381',
      screenName: 'SecondMarket - Investor - IOIs - Order Detail',
    },
  },
  {
    pathRegex: /.*second-market\/investor\/live-programs.*/,
    metadata: {
      screenId: 'NFE-500',
      screenName: 'SecondMarket - Investor - Live programs',
    },
  },
  {
    pathRegex: /.*second-market\/investor\/dashboard.*/,
    metadata: {
      screenId: 'NFE-4644',
      screenName: 'SecondMarket - Individual Dashboard',
    },
  },
  {
    pathRegex: /.*second-market\/investor\/sell-indication.*/,
    metadata: {
      screenId: 'NFE-4677',
      screenName: 'SecondMarket - Sell Indication',
    },
  },
  {
    pathRegex: /.*second-market\/investor.*[?&]companyId=/,
    metadata: {
      screenId: 'NFE-519',
      screenName: 'SecondMarket - Dashboard - Company Overview',
    },
  },
  {
    pathRegex: /.*second-market\/investor.*/,
    metadata: {
      screenId: 'NFE-490',
      screenName: 'SecondMarket - Dashboard',
    },
  },
  {
    pathRegex: /.*second-market\/sponsored-investor\/market-feed.*/,
    metadata: {
      screenId: 'NFE-4407',
      screenName: 'SecondMarket - Sponsored Investor - Market Feed',
    },
  },
  {
    pathRegex: /.*second-market\/sponsored-investor\/orders.*/,
    metadata: {
      screenId: 'NFE-4523',
      screenName: 'SecondMarket - Sponsored Investor - Orders',
    },
  },
  {
    pathRegex: /.*second-market\/sponsored-investor\/negotiations.*/,
    metadata: {
      screenId: 'NFE-4587',
      screenName: 'SecondMarket - Sponsored Investor - Negotiations',
    },
  },
] as typeof pathMetadataList;
