import { useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { type Accreditation } from '@npm/data-access';

import { QualifiedClientModal } from '../QualifiedClient';
import { QualifiedInstitutionalBuyerModal } from '../QualifiedInstitutionalBuyer';
import { QualifiedPurchaserModal } from '../QualifiedPurchaser/QualifiedPurchaserModal';

export const useFollowUpQuestionsComponents = ({
  data,
  showQPButton,
  showQIBButton,
  showQCButton,
}: {
  data: Accreditation;
  showQPButton: boolean;
  showQIBButton: boolean;
  showQCButton: boolean;
}) => {
  const [isInstitutionalBuyerModalOpen, setIsInstitutionalBuyerModalOpen] =
    useState(false);
  const [isPurchaserModalOpen, setIsPurchaserModalOpen] = useState(false);
  const [isQualifiedClientModalOpen, setIsQualifiedClientModalOpen] =
    useState(false);

  const openInstitutionalBuyerModal = () =>
    setIsInstitutionalBuyerModalOpen(true);

  const closeInstitutionalBuyerModal = () =>
    setIsInstitutionalBuyerModalOpen(false);

  const openPurchaserModal = () => setIsPurchaserModalOpen(true);
  const closePurchaserModal = () => setIsPurchaserModalOpen(false);

  const openQualifiedClientModal = () => setIsQualifiedClientModalOpen(true);
  const closeQualifiedClientModal = () => setIsQualifiedClientModalOpen(false);

  const modals = (
    <>
      <QualifiedPurchaserModal
        accreditationId={data?.id}
        accountId={data?.account_id}
        closeModal={closePurchaserModal}
        isOpen={isPurchaserModalOpen}
      />
      <QualifiedInstitutionalBuyerModal
        isOpen={isInstitutionalBuyerModalOpen}
        closeModal={closeInstitutionalBuyerModal}
        accreditationId={data?.id}
        accountId={data?.account_id}
      />
      <QualifiedClientModal
        isOpen={isQualifiedClientModalOpen}
        closeModal={closeQualifiedClientModal}
        accreditationId={data?.id}
        accountId={data?.account_id}
      />
    </>
  );

  const buttons = (
    <Flex direction="column" justify="space-between" gap="md">
      {showQPButton && (
        <Button
          block
          variant="outline"
          icon={<Icon name="plus" />}
          onClick={openPurchaserModal}
        >
          Add Qualified Purchaser Information
        </Button>
      )}
      {showQIBButton && (
        <Button
          variant="outline"
          icon={<Icon name="plus" />}
          block
          onClick={openInstitutionalBuyerModal}
        >
          Add Qualified Institutional Buyer Information
        </Button>
      )}
      {showQCButton && (
        <Button
          variant="outline"
          icon={<Icon name="plus" />}
          block
          onClick={openQualifiedClientModal}
        >
          Qualified Client Status
        </Button>
      )}
    </Flex>
  );

  return { openInstitutionalBuyerModal, openPurchaserModal, modals, buttons };
};
