import { type ComponentProps } from 'react';

import { InlineInputNumber } from '@npm/core/ui/components/atoms/Input';

import { type OrderSizeType } from '../order.types';

type Props = {
  sizeType: OrderSizeType;
} & ComponentProps<typeof InlineInputNumber>;

export const OrderSizeInput = ({ sizeType, ...rest }: Props) => {
  return (
    <InlineInputNumber
      placeholder={sizeType === 'Shares' ? '0' : '0.00'}
      precision={sizeType === 'Shares' ? 0 : undefined}
      inputMode={sizeType === 'Shares' ? 'numeric' : 'decimal'}
      addonAfter={sizeType}
      currency={sizeType === 'USD' ? 'USD' : undefined}
      {...rest}
    />
  );
};
