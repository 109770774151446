/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  OrderItemApi,
  OrderItemIndexErrorCodes,
  OrderItemIndexErrorTypes,
  OrderItemsAuditsIndexErrorCodes,
  OrderItemsAuditsIndexErrorTypes,
  OrderItemsDownloadIndexErrorCodes,
  OrderItemsDownloadIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new OrderItemApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type OrderItemIndexMethod = OrderItemApi['orderItemIndex'];
export type OrderItemIndexResponseType = MethodResult<OrderItemIndexMethod>;

export const useOrderItemIndex = (
  variables: Parameters<OrderItemIndexMethod>[0],
  config?: UseQueryConfig<
    OrderItemIndexMethod,
    OrderItemIndexErrorTypes
  >
) => {
  return useQuery<
    OrderItemIndexMethod,
    OrderItemIndexErrorTypes
  >(
    (...args) => api.orderItemIndex(...args),
    OrderItemIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrderItemIndex'],
        ...apiQueryConfig.OrderItemIndex,
        ...config?.queryConfig
      },
    },
    'OrderItemIndex',
  );
};

export const useOrderItemIndexInfinite = (
  variables: Parameters<OrderItemIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    OrderItemIndexMethod,
    OrderItemIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    OrderItemIndexMethod,
    OrderItemIndexErrorTypes
  >(
    (...args) => api.orderItemIndex(...args),
    OrderItemIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrderItemIndex', 'OrderItemIndexInfinite'],
        ...apiQueryConfig.OrderItemIndex,
        ...config?.queryConfig,
      },
    },
    'OrderItemIndex',
  );
};

export const useOrderItemIndexLazy = (baseOptions?: {
  variables?: Parameters<OrderItemIndexMethod>[0],
  config?: UseQueryConfig<
    OrderItemIndexMethod,
    OrderItemIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    OrderItemIndexMethod,
    OrderItemIndexErrorTypes
  >(
    (...args) => api.orderItemIndex(...args),
    OrderItemIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['OrderItemIndex'],
        ...apiQueryConfig.OrderItemIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'OrderItemIndex',
  );
};


export type OrderItemsAuditsIndexMethod = OrderItemApi['orderItemsAuditsIndex'];
export type OrderItemsAuditsIndexResponseType = MethodResult<OrderItemsAuditsIndexMethod>;

export const useOrderItemsAuditsIndex = (
  variables: Parameters<OrderItemsAuditsIndexMethod>[0],
  config?: UseQueryConfig<
    OrderItemsAuditsIndexMethod,
    OrderItemsAuditsIndexErrorTypes
  >
) => {
  return useQuery<
    OrderItemsAuditsIndexMethod,
    OrderItemsAuditsIndexErrorTypes
  >(
    (...args) => api.orderItemsAuditsIndex(...args),
    OrderItemsAuditsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrderItemsAuditsIndex'],
        ...apiQueryConfig.OrderItemsAuditsIndex,
        ...config?.queryConfig
      },
    },
    'OrderItemsAuditsIndex',
  );
};

export const useOrderItemsAuditsIndexInfinite = (
  variables: Parameters<OrderItemsAuditsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    OrderItemsAuditsIndexMethod,
    OrderItemsAuditsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    OrderItemsAuditsIndexMethod,
    OrderItemsAuditsIndexErrorTypes
  >(
    (...args) => api.orderItemsAuditsIndex(...args),
    OrderItemsAuditsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrderItemsAuditsIndex', 'OrderItemsAuditsIndexInfinite'],
        ...apiQueryConfig.OrderItemsAuditsIndex,
        ...config?.queryConfig,
      },
    },
    'OrderItemsAuditsIndex',
  );
};

export const useOrderItemsAuditsIndexLazy = (baseOptions?: {
  variables?: Parameters<OrderItemsAuditsIndexMethod>[0],
  config?: UseQueryConfig<
    OrderItemsAuditsIndexMethod,
    OrderItemsAuditsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    OrderItemsAuditsIndexMethod,
    OrderItemsAuditsIndexErrorTypes
  >(
    (...args) => api.orderItemsAuditsIndex(...args),
    OrderItemsAuditsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['OrderItemsAuditsIndex'],
        ...apiQueryConfig.OrderItemsAuditsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'OrderItemsAuditsIndex',
  );
};


export type OrderItemsDownloadIndexMethod = OrderItemApi['orderItemsDownloadIndex'];
export type OrderItemsDownloadIndexResponseType = MethodResult<OrderItemsDownloadIndexMethod>;

export const useOrderItemsDownloadIndex = (
  variables: Parameters<OrderItemsDownloadIndexMethod>[0],
  config?: UseQueryConfig<
    OrderItemsDownloadIndexMethod,
    OrderItemsDownloadIndexErrorTypes
  >
) => {
  return useQuery<
    OrderItemsDownloadIndexMethod,
    OrderItemsDownloadIndexErrorTypes
  >(
    (...args) => api.orderItemsDownloadIndex(...args),
    OrderItemsDownloadIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrderItemsDownloadIndex'],
        ...apiQueryConfig.OrderItemsDownloadIndex,
        ...config?.queryConfig
      },
    },
    'OrderItemsDownloadIndex',
  );
};

export const useOrderItemsDownloadIndexInfinite = (
  variables: Parameters<OrderItemsDownloadIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    OrderItemsDownloadIndexMethod,
    OrderItemsDownloadIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    OrderItemsDownloadIndexMethod,
    OrderItemsDownloadIndexErrorTypes
  >(
    (...args) => api.orderItemsDownloadIndex(...args),
    OrderItemsDownloadIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrderItemsDownloadIndex', 'OrderItemsDownloadIndexInfinite'],
        ...apiQueryConfig.OrderItemsDownloadIndex,
        ...config?.queryConfig,
      },
    },
    'OrderItemsDownloadIndex',
  );
};

export const useOrderItemsDownloadIndexLazy = (baseOptions?: {
  variables?: Parameters<OrderItemsDownloadIndexMethod>[0],
  config?: UseQueryConfig<
    OrderItemsDownloadIndexMethod,
    OrderItemsDownloadIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    OrderItemsDownloadIndexMethod,
    OrderItemsDownloadIndexErrorTypes
  >(
    (...args) => api.orderItemsDownloadIndex(...args),
    OrderItemsDownloadIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['OrderItemsDownloadIndex'],
        ...apiQueryConfig.OrderItemsDownloadIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'OrderItemsDownloadIndex',
  );
};

