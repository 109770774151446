import { type TooltipProps } from 'antd';

export type TooltipSize = 'sm' | 'md' | 'lg';

export type Props = TooltipProps & { size?: TooltipSize; disabled?: boolean };

export enum TooltipClassName {
  SM = 'tooltip-sm',
  MD = 'tooltip-md',
  LG = 'tooltip-lg',
}
