import { type CbLabelComponent, type LabelVariant } from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  overview: 'overview',
  acknowledgement: 'acknowledgement',
  confirmation: 'confirmation',
  faq: 'faq',
  preview: 'preview',
  review: 'review',
  incomplete: 'incomplete',
  pending: 'pending',
  submitted: 'submitted',
  settled: 'settled',
  exercise_cost_payment: 'exercise_cost_payment',
  general: 'general',
} as const;

type Keys = keyof typeof Items;

const LabelMap: Record<Keys, LabelVariant> = {
  overview: 'general',
  acknowledgement: 'general',
  confirmation: 'warning',
  faq: 'general',
  preview: 'general',
  review: 'warning',
  incomplete: 'error',
  pending: 'general',
  submitted: 'success',
  settled: 'success',
  exercise_cost_payment: 'general',
  general: 'general',
};

export const CbEventMessageType = {
  code: Codebooks.EVENT_MESSAGE_TYPE,
  items: Items,
  labels: LabelMap,
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem | undefined
  ) =>
    Component({
      variant: LabelMap[(codebookItem?.code as Keys) ?? 'none'],
      label: codebookItem?.name,
    }),
};
