import { useEffect } from 'react';
import { type LocationDescriptor } from 'history';

import { eventBus } from '../../../utils/eventBus';

import { useHistory } from './useHistory';

export type HistoryEvent = {
  type: 'push' | 'replace';
  location: LocationDescriptor<unknown>;
};
export const useHistoryEventListener = () => {
  const history = useHistory();

  useEffect(() => {
    return eventBus.on<HistoryEvent>('HISTORY_EVENT', args => {
      if (args.type === 'push') {
        history.push(args.location);
      } else {
        history.replace(args.location);
      }
    });
  }, [history?.location]);
};
