import React from 'react';

import {
  type Props,
  type TooltipSize,
  TooltipClassName,
} from './Tooltip.types';

import * as S from './Tooltip.styles';

export const Tooltip = ({
  children,
  size = 'md',
  disabled = false,
  ...props
}: Props) => {
  const className: Record<TooltipSize, string> = {
    sm: TooltipClassName.SM,
    md: TooltipClassName.MD,
    lg: TooltipClassName.LG,
  };

  return disabled ? (
    <>{children}</>
  ) : (
    <>
      <S.TooltipGlobalStyles />
      <S.StyledTooltip overlayClassName={className[size]} {...props}>
        {children}
      </S.StyledTooltip>
    </>
  );
};
