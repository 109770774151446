import { Label } from '@npm/core/ui/components/atoms/Label';
import { CypressDataIds } from '@npm/core/ui/constants';
import { truncate } from '@npm/core/ui/utils/formatters';
import { type AssetAggregate, type Holding } from '@npm/data-access';

import { LabelContainer } from './HoldingCard.styles';

export const HoldingCardHeaderLabel = ({
  certificate_number,
  asset,
  wrap,
  idMaxLength = 16,
}: Pick<Holding, 'certificate_number'> & {
  asset?: AssetAggregate;
  wrap?: boolean;
  idMaxLength?: number;
}) => (
  <LabelContainer $wrap={wrap}>
    {asset && (
      <Label variant="info" round>
        {asset?.type?.name}
      </Label>
    )}
    {certificate_number ? (
      <Label
        variant="info"
        round
        data-cy={CypressDataIds.Holdings.Properties.UniqueId}
      >
        {truncate(certificate_number, idMaxLength)}
      </Label>
    ) : null}
  </LabelContainer>
);
