import React, {
  type ComponentProps,
  type ReactElement,
  type ReactNode,
} from 'react';

import { Margin } from '../../atoms/common';
import { Text } from '../../atoms/Typography';

import * as S from './TextWithBottomInfo.styles';

type BottomInfo = {
  leftInfo?: React.ReactNode;
  rightInfo?:
    | { rightText: React.ReactNode; rightExtra?: React.ReactNode }
    | string;
  separator?: string;
};

type Props = {
  children: ReactNode;
  bottomInfo?: BottomInfo;
  extraInfo?: ReactElement;
} & ComponentProps<typeof Text>;

export const TextWithBottomInfo = ({
  children,
  bottomInfo,
  extraInfo,
  size = 'sm',
  color = 'general',
  colorVariant = 'primary',
  ...rest
}: Props) => {
  const { rightInfo, leftInfo, separator = '-' } = bottomInfo || {};
  const { rightText, rightExtra } =
    (typeof rightInfo === 'string'
      ? { rightText: rightInfo, rightExtra: undefined }
      : rightInfo) || {};

  return (
    <S.Container>
      <Text {...rest} size={size} color={color} colorVariant={colorVariant}>
        {children}
      </Text>
      {(leftInfo || rightInfo) && (
        <Text size="xs">
          <Margin right="xs" as="span" style={{ display: 'flex' }}>
            {leftInfo}
            {rightInfo && (
              <>
                &nbsp;{separator}&nbsp;{rightText}
              </>
            )}
          </Margin>
          {rightExtra && (
            <Text size="xs" color="info" as="span">
              {rightExtra}
            </Text>
          )}
        </Text>
      )}
      {extraInfo}
    </S.Container>
  );
};
