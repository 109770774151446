/* eslint-disable no-template-curly-in-string */

/**
 * This file is not the nicest one, but it is on purpose.
 * It has the same exports as ./config.ts which is being replaced by this one during production build.
 * Why we have here only strings with the format ${ENV_NAME} is because our docker is build as ENV independent docker image.
 * On docker start all ENVs are replaced by their correct values.
 * And for that replacement we need to have specific format that envsubst command understand.
 */

export const APP_CFG_ENVIRONMENT = '${NX_ENVIRONMENT}';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const APP_CFG_IS_PRODUCTION = APP_CFG_ENVIRONMENT === 'PROD';

export const APP_CFG_METADATA_IN_BROWSER_TITLE =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  '${NX_METADATA_IN_BROWSER_TITLE}';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const APP_CFG_JIRA_BADGE_ENABLED = '${NX_JIRA_BADGE_ENABLED}';

export const APP_CFG_APP_URL = '${NX_APP_URL}';
export const APP_CFG_API_URL = '${NX_API_URL}';

export const APP_CFG_CAP_TABLE_API_URL = '${NX_CAP_TABLE_API_URL}';

export const APP_CFG_TAG = '${NX_APP_TAG}';

export const APP_CFG_SHA = '${NX_APP_SHA}';

export const APP_CFG_SECURED_DOCUMENT_VIEW_ID =
  '${NX_SECURED_DOCUMENT_VIEW_ID}';
export const APP_CFG_SECURED_DOCUMENT_VIEW_PASSWORD =
  '${NX_SECURED_DOCUMENT_VIEW_PASSWORD}';
