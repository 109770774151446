import { useMemo } from 'react';

import { getAxisRoundedMaxValue } from '@npm/core/ui/components/atoms/Charts/utils/getAxisRoundedMaxValue';
import { type CompanyPricingApi, type VenusApi } from '@npm/data-access';

import { type CompanyOverviewMaxValues } from './TapeDPricing.types';

type Props = {
  isLoading: boolean;
  tapeDData: CompanyPricingApi.IntrinsicValueIndex;
  bidOfferData: CompanyPricingApi.HistoricalPriceIndex;
  primaryRoundsData: VenusApi.PrimaryOcfValuationIndex;
  valuations409Data: VenusApi.FairMarketValueIndex;
  movingAverageData: CompanyPricingApi.MovingAveragePrices;
  mtmData: CompanyPricingApi.MarkToMarketIndex;
};

export const useCompanyOverviewMaximumValues = ({
  isLoading,
  tapeDData,
  bidOfferData,
  primaryRoundsData,
  valuations409Data,
  movingAverageData,
  mtmData,
}: Props): CompanyOverviewMaxValues => {
  const isNotReady =
    isLoading ||
    !tapeDData?.intrinsic_values ||
    !bidOfferData?.historical_prices ||
    !primaryRoundsData?.primary_rounds ||
    !valuations409Data['409as'] ||
    !movingAverageData?.prices;

  const maximumPPS = useMemo(() => {
    if (isNotReady) {
      return undefined;
    }

    return Math.max(
      ...(tapeDData?.intrinsic_values?.map(value => value.high_estimate) ?? []),
      ...(bidOfferData?.historical_prices?.aggregated_bids?.map(
        value => value.price_per_share
      ) ?? []),
      ...(bidOfferData?.historical_prices?.aggregated_asks?.map(
        value => value.price_per_share
      ) ?? []),
      ...(bidOfferData?.historical_prices?.aggregated_trades?.map(
        value => value.price_per_share
      ) ?? []),
      ...(primaryRoundsData?.primary_rounds?.map(
        value => value.price_per_share
      ) ?? []),
      ...(valuations409Data?.['409as']?.map(value => value.price_per_share) ??
        []),
      ...(movingAverageData?.prices?.map(value => value.moving_average) ?? [])
      // TODO: add after mutual funds chart
      //...(mtmData?.mark_to_markets?.map(value => value.price_per_share) ?? [])
    );
  }, [
    tapeDData,
    bidOfferData,
    primaryRoundsData,
    valuations409Data,
    movingAverageData,
    mtmData,
  ]);

  const maximumValuation = useMemo(() => {
    if (isNotReady) {
      return undefined;
    }

    return Math.max(
      ...(tapeDData?.intrinsic_values?.map(
        value => value.high_implied_valuation
      ) ?? []),
      ...(bidOfferData?.historical_prices?.aggregated_bids?.map(
        value => value.implied_valuation
      ) ?? []),
      ...(bidOfferData?.historical_prices?.aggregated_asks?.map(
        value => value.implied_valuation
      ) ?? []),
      ...(bidOfferData?.historical_prices?.aggregated_trades?.map(
        value => value.implied_valuation
      ) ?? []),
      ...(primaryRoundsData?.primary_rounds?.map(value => value.valuation) ??
        []),
      ...(movingAverageData?.prices?.map(value => value.implied_valuation) ??
        [])
      // TODO: add after mutual funds chart
      //...(mtmData?.mark_to_markets?.map(value => value.price_per_share) ?? [])
    );
  }, [
    tapeDData,
    bidOfferData,
    primaryRoundsData,
    valuations409Data,
    movingAverageData,
    mtmData,
  ]);

  return {
    maximumPPS:
      maximumPPS !== undefined ? getAxisRoundedMaxValue(maximumPPS) : undefined,
    maximumValuation:
      maximumValuation !== undefined
        ? getAxisRoundedMaxValue(maximumValuation)
        : undefined,
  };
};
