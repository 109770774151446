import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { PercentageChange } from '@npm/core/ui/components/molecules/PercentageChange';

type Props = {
  price: number;
  change?: number;
  multiplyChange?: boolean;
  impliedValuation: number;
  align?: 'flex-start' | 'flex-end';
};

export const CompanyOverviewPriceDisplay = ({
  price,
  change,
  impliedValuation,
  multiplyChange = false,
  align = 'flex-start',
}: Props) => {
  return (
    <Flex direction="column" align={align}>
      <Flex>
        <Text.Price
          value={price}
          formatOptions={{
            long: true,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }}
        />
        {!!change && (
          <PercentageChange
            change={multiplyChange ? change * 100 : change}
            maximumFractionDigits={0}
          />
        )}
      </Flex>
      {!!impliedValuation && (
        <Text size="xs" colorVariant="tertiary" as="span">
          Implied Val. <Text.Price size="xs" value={impliedValuation} compact />
        </Text>
      )}
    </Flex>
  );
};
