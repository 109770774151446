import { Form } from '@npm/core/ui/components/atoms/Form';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useTotalRecords } from '@npm/core/ui/hooks/useTotalRecords';
import {
  type AccountApiBrokerageFirmAccountIndexRequest,
  useBrokerageFirmAccountIndex,
} from '@npm/data-access';

import { FilterPanel } from '../../../../../../../filters/filters/FilterPanel';

import { getAccountsFilterItems } from './SelectAccountFilter.config';

export type AccountsFilterFormValues = Pick<
  AccountApiBrokerageFirmAccountIndexRequest,
  'search' | 'accountType' | 'userStatus'
>;

type Props = {
  variables?: AccountApiBrokerageFirmAccountIndexRequest;
  handleSubmit: (values: AccountsFilterFormValues) => void;
  totalActiveFilters: number;
  initialFilterValues?: Partial<AccountsFilterFormValues>;
};

export const SelectAccountFilter = ({
  handleSubmit,
  initialFilterValues,
  variables,
  totalActiveFilters,
}: Props) => {
  const { isSmTablet, isMobile } = useBreakpoints();
  const [form] = Form.useForm<AccountsFilterFormValues>();

  const { data } = useBrokerageFirmAccountIndex(variables);
  const totalRecords = useTotalRecords(data);

  return (
    <FilterPanel
      defaultColumnProps={{
        xs: 24,
        sm: 12,
        md: 8,
        lg: 8,
      }}
      totalRecordBackground="two"
      isExpandable={isSmTablet || isMobile}
      form={form}
      filters={getAccountsFilterItems(isSmTablet, isMobile)}
      handleSubmit={handleSubmit}
      totalRecords={totalRecords}
      initialFilterValues={initialFilterValues}
      totalActiveFilters={totalActiveFilters}
    />
  );
};
