/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalInvestmentCreateRequestContract } from '../model';
// @ts-ignore
import { InternalInvestmentIndex } from '../model';
// @ts-ignore
import { InternalInvestmentShow } from '../model';
// @ts-ignore
import { InternalInvestmentUpdateRequestContract } from '../model';
// @ts-ignore
import { InvestmentIndex } from '../model';
// @ts-ignore
import { InvestmentTotalFinancing } from '../model';
/**
 * InvestmentsApi - axios parameter creator
 * @export
 */
export const InvestmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get investments summed by month
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvestmentsIndex: async (companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyInvestmentsIndex', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/companies/{company_id}/investments`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all investments
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {boolean} [primary] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalAllInvestmentIndex: async (page?: number, size?: number, primary?: boolean, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/internal/investments`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (primary !== undefined) {
                localVarQueryParameter['primary'] = primary;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create an Investment
         * @param {InternalInvestmentCreateRequestContract} [internalInvestmentCreateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateInvestment: async (internalInvestmentCreateRequestContract?: InternalInvestmentCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/internal/investments`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalInvestmentCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete an investment by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDeleteInvestment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDeleteInvestment', 'id', id)
            const localVarPath = changeUrl(`/api/internal/investments/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get investments for a company
         * @param {string} companyId 
         * @param {string} [date] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalInvestmentIndex: async (companyId: string, date?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalInvestmentIndex', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/investments`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get an investment by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowInvestment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalShowInvestment', 'id', id)
            const localVarPath = changeUrl(`/api/internal/investments/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update an investment
         * @param {number} id 
         * @param {InternalInvestmentUpdateRequestContract} [internalInvestmentUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateInvestment: async (id: number, internalInvestmentUpdateRequestContract?: InternalInvestmentUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateInvestment', 'id', id)
            const localVarPath = changeUrl(`/api/internal/investments/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalInvestmentUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get investments summed by month
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investmentTotalFinancing: async (startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/total_financing`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentsApi - functional programming interface
 * @export
 */
export const InvestmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get investments summed by month
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyInvestmentsIndex(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyInvestmentsIndex(companyId, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all investments
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {boolean} [primary] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalAllInvestmentIndex(page?: number, size?: number, primary?: boolean, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalInvestmentIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalAllInvestmentIndex(page, size, primary, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create an Investment
         * @param {InternalInvestmentCreateRequestContract} [internalInvestmentCreateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateInvestment(internalInvestmentCreateRequestContract?: InternalInvestmentCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalInvestmentShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateInvestment(internalInvestmentCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete an investment by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDeleteInvestment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDeleteInvestment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get investments for a company
         * @param {string} companyId 
         * @param {string} [date] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalInvestmentIndex(companyId: string, date?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalInvestmentIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalInvestmentIndex(companyId, date, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get an investment by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalShowInvestment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalInvestmentShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalShowInvestment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update an investment
         * @param {number} id 
         * @param {InternalInvestmentUpdateRequestContract} [internalInvestmentUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateInvestment(id: number, internalInvestmentUpdateRequestContract?: InternalInvestmentUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalInvestmentShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateInvestment(id, internalInvestmentUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get investments summed by month
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investmentTotalFinancing(startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentTotalFinancing>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investmentTotalFinancing(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentsApi - factory interface
 * @export
 */
export const InvestmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentsApiFp(configuration)
    return {
        /**
         * 
         * @summary get investments summed by month
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvestmentsIndex(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InvestmentIndex> {
            return localVarFp.companyInvestmentsIndex(companyId, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all investments
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {boolean} [primary] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalAllInvestmentIndex(page?: number, size?: number, primary?: boolean, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalInvestmentIndex> {
            return localVarFp.internalAllInvestmentIndex(page, size, primary, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create an Investment
         * @param {InternalInvestmentCreateRequestContract} [internalInvestmentCreateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateInvestment(internalInvestmentCreateRequestContract?: InternalInvestmentCreateRequestContract, options?: any): AxiosPromise<InternalInvestmentShow> {
            return localVarFp.internalCreateInvestment(internalInvestmentCreateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete an investment by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDeleteInvestment(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDeleteInvestment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get investments for a company
         * @param {string} companyId 
         * @param {string} [date] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalInvestmentIndex(companyId: string, date?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalInvestmentIndex> {
            return localVarFp.internalInvestmentIndex(companyId, date, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get an investment by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowInvestment(id: number, options?: any): AxiosPromise<InternalInvestmentShow> {
            return localVarFp.internalShowInvestment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update an investment
         * @param {number} id 
         * @param {InternalInvestmentUpdateRequestContract} [internalInvestmentUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateInvestment(id: number, internalInvestmentUpdateRequestContract?: InternalInvestmentUpdateRequestContract, options?: any): AxiosPromise<InternalInvestmentShow> {
            return localVarFp.internalUpdateInvestment(id, internalInvestmentUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get investments summed by month
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investmentTotalFinancing(startDate?: string, endDate?: string, options?: any): AxiosPromise<InvestmentTotalFinancing> {
            return localVarFp.investmentTotalFinancing(startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for companyInvestmentsIndex operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiCompanyInvestmentsIndexRequest
 */
export interface InvestmentsApiCompanyInvestmentsIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentsApiCompanyInvestmentsIndex
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof InvestmentsApiCompanyInvestmentsIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof InvestmentsApiCompanyInvestmentsIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalAllInvestmentIndex operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiInternalAllInvestmentIndexRequest
 */
export interface InvestmentsApiInternalAllInvestmentIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof InvestmentsApiInternalAllInvestmentIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof InvestmentsApiInternalAllInvestmentIndex
     */
    readonly size?: number

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentsApiInternalAllInvestmentIndex
     */
    readonly primary?: boolean

    /**
     * 
     * @type {string}
     * @memberof InvestmentsApiInternalAllInvestmentIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof InvestmentsApiInternalAllInvestmentIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalCreateInvestment operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiInternalCreateInvestmentRequest
 */
export interface InvestmentsApiInternalCreateInvestmentRequest {
    /**
     * 
     * @type {InternalInvestmentCreateRequestContract}
     * @memberof InvestmentsApiInternalCreateInvestment
     */
    readonly internalInvestmentCreateRequestContract?: InternalInvestmentCreateRequestContract
}

/**
 * Request parameters for internalDeleteInvestment operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiInternalDeleteInvestmentRequest
 */
export interface InvestmentsApiInternalDeleteInvestmentRequest {
    /**
     * 
     * @type {number}
     * @memberof InvestmentsApiInternalDeleteInvestment
     */
    readonly id: number
}

/**
 * Request parameters for internalInvestmentIndex operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiInternalInvestmentIndexRequest
 */
export interface InvestmentsApiInternalInvestmentIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentsApiInternalInvestmentIndex
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof InvestmentsApiInternalInvestmentIndex
     */
    readonly date?: string

    /**
     * 
     * @type {string}
     * @memberof InvestmentsApiInternalInvestmentIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof InvestmentsApiInternalInvestmentIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalShowInvestment operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiInternalShowInvestmentRequest
 */
export interface InvestmentsApiInternalShowInvestmentRequest {
    /**
     * 
     * @type {number}
     * @memberof InvestmentsApiInternalShowInvestment
     */
    readonly id: number
}

/**
 * Request parameters for internalUpdateInvestment operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiInternalUpdateInvestmentRequest
 */
export interface InvestmentsApiInternalUpdateInvestmentRequest {
    /**
     * 
     * @type {number}
     * @memberof InvestmentsApiInternalUpdateInvestment
     */
    readonly id: number

    /**
     * 
     * @type {InternalInvestmentUpdateRequestContract}
     * @memberof InvestmentsApiInternalUpdateInvestment
     */
    readonly internalInvestmentUpdateRequestContract?: InternalInvestmentUpdateRequestContract
}

/**
 * Request parameters for investmentTotalFinancing operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiInvestmentTotalFinancingRequest
 */
export interface InvestmentsApiInvestmentTotalFinancingRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentsApiInvestmentTotalFinancing
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof InvestmentsApiInvestmentTotalFinancing
     */
    readonly endDate?: string
}

/**
 * InvestmentsApi - object-oriented interface
 * @export
 * @class InvestmentsApi
 * @extends {BaseAPI}
 */
export class InvestmentsApi extends BaseAPI {
    /**
     * 
     * @summary get investments summed by month
     * @param {InvestmentsApiCompanyInvestmentsIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public companyInvestmentsIndex(requestParameters: InvestmentsApiCompanyInvestmentsIndexRequest, options?: AxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).companyInvestmentsIndex(requestParameters.companyId, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all investments
     * @param {InvestmentsApiInternalAllInvestmentIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public internalAllInvestmentIndex(requestParameters: InvestmentsApiInternalAllInvestmentIndexRequest = {}, options?: AxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).internalAllInvestmentIndex(requestParameters.page, requestParameters.size, requestParameters.primary, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create an Investment
     * @param {InvestmentsApiInternalCreateInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public internalCreateInvestment(requestParameters: InvestmentsApiInternalCreateInvestmentRequest = {}, options?: AxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).internalCreateInvestment(requestParameters.internalInvestmentCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete an investment by ID
     * @param {InvestmentsApiInternalDeleteInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public internalDeleteInvestment(requestParameters: InvestmentsApiInternalDeleteInvestmentRequest, options?: AxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).internalDeleteInvestment(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get investments for a company
     * @param {InvestmentsApiInternalInvestmentIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public internalInvestmentIndex(requestParameters: InvestmentsApiInternalInvestmentIndexRequest, options?: AxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).internalInvestmentIndex(requestParameters.companyId, requestParameters.date, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get an investment by ID
     * @param {InvestmentsApiInternalShowInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public internalShowInvestment(requestParameters: InvestmentsApiInternalShowInvestmentRequest, options?: AxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).internalShowInvestment(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update an investment
     * @param {InvestmentsApiInternalUpdateInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public internalUpdateInvestment(requestParameters: InvestmentsApiInternalUpdateInvestmentRequest, options?: AxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).internalUpdateInvestment(requestParameters.id, requestParameters.internalInvestmentUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get investments summed by month
     * @param {InvestmentsApiInvestmentTotalFinancingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public investmentTotalFinancing(requestParameters: InvestmentsApiInvestmentTotalFinancingRequest = {}, options?: AxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).investmentTotalFinancing(requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }
}

export type CompanyInvestmentsIndexErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompanyInvestmentsIndexErrorCodes = [
    404,
];

export type InternalAllInvestmentIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalAllInvestmentIndexErrorCodes = [
    401,
];

export type InternalCreateInvestmentErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalCreateInvestmentErrorCodes = [
    422,
];

export type InternalDeleteInvestmentErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalDeleteInvestmentErrorCodes = [
    404,
];

export type InternalInvestmentIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalInvestmentIndexErrorCodes = [
    401,
    404,
];

export type InternalShowInvestmentErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalShowInvestmentErrorCodes = [
    404,
];

export type InternalUpdateInvestmentErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalUpdateInvestmentErrorCodes = [
    404,
];

export type InvestmentTotalFinancingErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InvestmentTotalFinancingErrorCodes = [
];


