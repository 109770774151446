import styled from 'styled-components';

import { useBreakpoint } from '../../../utils/style';

export const Container = styled.div`
  ${({ theme }) => useBreakpoint(theme).mobile`
     display: flex;
     flex-wrap: wrap;
    `}
`;
