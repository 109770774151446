import { useLocation } from '../../components/molecules/Link';

import { type PathMetadata } from './pathMetadata.types';

export const pathMetadataList: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pathRegex: any;
  metadata: {
    screenId: string;
    screenName: string;
  };
}[] = []; // Reassigned in apps/webapp/src/App.tsx

const getPathMetaData = (
  path: string,
  queryString: string
): PathMetadata['metadata'] => {
  const pathWithQueryString = `${path}${queryString}`;
  // eslint-disable-next-line sonarjs/no-empty-collection
  const pathMetadata = pathMetadataList.find(pathMetadata =>
    pathMetadata.pathRegex.test(pathWithQueryString)
  );
  return pathMetadata
    ? pathMetadata.metadata
    : { screenId: '', screenName: '' };
};

export const usePathMetadata = () => {
  const location = useLocation();
  return getPathMetaData(location.pathname, location.search);
};
