/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  TransactionsApi,
  TransactionsIndexErrorCodes,
  TransactionsIndexErrorTypes,
  TransactionShowErrorCodes,
  TransactionShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new TransactionsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type TransactionsIndexMethod = TransactionsApi['transactionsIndex'];
export type TransactionsIndexResponseType = MethodResult<TransactionsIndexMethod>;

export const useTransactionsIndex = (
  variables: Parameters<TransactionsIndexMethod>[0],
  config?: UseQueryConfig<
    TransactionsIndexMethod,
    TransactionsIndexErrorTypes
  >
) => {
  return useQuery<
    TransactionsIndexMethod,
    TransactionsIndexErrorTypes
  >(
    (...args) => api.transactionsIndex(...args),
    TransactionsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TransactionsIndex'],
        ...apiQueryConfig.TransactionsIndex,
        ...config?.queryConfig
      },
    },
    'TransactionsIndex',
  );
};

export const useTransactionsIndexInfinite = (
  variables: Parameters<TransactionsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    TransactionsIndexMethod,
    TransactionsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    TransactionsIndexMethod,
    TransactionsIndexErrorTypes
  >(
    (...args) => api.transactionsIndex(...args),
    TransactionsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TransactionsIndex', 'TransactionsIndexInfinite'],
        ...apiQueryConfig.TransactionsIndex,
        ...config?.queryConfig,
      },
    },
    'TransactionsIndex',
  );
};

export const useTransactionsIndexLazy = (baseOptions?: {
  variables?: Parameters<TransactionsIndexMethod>[0],
  config?: UseQueryConfig<
    TransactionsIndexMethod,
    TransactionsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    TransactionsIndexMethod,
    TransactionsIndexErrorTypes
  >(
    (...args) => api.transactionsIndex(...args),
    TransactionsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['TransactionsIndex'],
        ...apiQueryConfig.TransactionsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'TransactionsIndex',
  );
};


export type TransactionShowMethod = TransactionsApi['transactionShow'];
export type TransactionShowResponseType = MethodResult<TransactionShowMethod>;

export const useTransactionShow = (
  variables: Parameters<TransactionShowMethod>[0],
  config?: UseQueryConfig<
    TransactionShowMethod,
    TransactionShowErrorTypes
  >
) => {
  return useQuery<
    TransactionShowMethod,
    TransactionShowErrorTypes
  >(
    (...args) => api.transactionShow(...args),
    TransactionShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TransactionShow'],
        ...apiQueryConfig.TransactionShow,
        ...config?.queryConfig
      },
    },
    'TransactionShow',
  );
};

export const useTransactionShowInfinite = (
  variables: Parameters<TransactionShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    TransactionShowMethod,
    TransactionShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    TransactionShowMethod,
    TransactionShowErrorTypes
  >(
    (...args) => api.transactionShow(...args),
    TransactionShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TransactionShow', 'TransactionShowInfinite'],
        ...apiQueryConfig.TransactionShow,
        ...config?.queryConfig,
      },
    },
    'TransactionShow',
  );
};

export const useTransactionShowLazy = (baseOptions?: {
  variables?: Parameters<TransactionShowMethod>[0],
  config?: UseQueryConfig<
    TransactionShowMethod,
    TransactionShowErrorTypes
  >
}) => {
  return useLazyQuery<
    TransactionShowMethod,
    TransactionShowErrorTypes
  >(
    (...args) => api.transactionShow(...args),
    TransactionShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['TransactionShow'],
        ...apiQueryConfig.TransactionShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'TransactionShow',
  );
};

