/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalPersonCreateRequestContract } from '../model';
// @ts-ignore
import { InternalPersonIndex } from '../model';
// @ts-ignore
import { InternalPersonShow } from '../model';
// @ts-ignore
import { InternalPersonUpdateRequestContract } from '../model';
/**
 * PeopleApi - axios parameter creator
 * @export
 */
export const PeopleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a person
         * @param {InternalPersonCreateRequestContract} internalPersonCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPeopleCreate: async (internalPersonCreateRequestContract: InternalPersonCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'internalPersonCreateRequestContract' is not null or undefined
            assertParamExists('internalPeopleCreate', 'internalPersonCreateRequestContract', internalPersonCreateRequestContract)
            const localVarPath = changeUrl(`/api/internal/people`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalPersonCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a person
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPeopleDelete: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPeopleDelete', 'id', id)
            const localVarPath = changeUrl(`/api/internal/people/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a list of people
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPeopleIndex: async (search?: string, page?: number, size?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/internal/people`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get information for a person
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPeopleShow: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPeopleShow', 'id', id)
            const localVarPath = changeUrl(`/api/internal/people/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a person
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalPersonUpdateRequestContract} [internalPersonUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPeopleUpdate: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalPersonUpdateRequestContract?: InternalPersonUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPeopleUpdate', 'id', id)
            const localVarPath = changeUrl(`/api/internal/people/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalPersonUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PeopleApi - functional programming interface
 * @export
 */
export const PeopleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PeopleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a person
         * @param {InternalPersonCreateRequestContract} internalPersonCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPeopleCreate(internalPersonCreateRequestContract: InternalPersonCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalPersonShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPeopleCreate(internalPersonCreateRequestContract, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a person
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPeopleDelete(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPeopleDelete(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a list of people
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPeopleIndex(search?: string, page?: number, size?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalPersonIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPeopleIndex(search, page, size, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get information for a person
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPeopleShow(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalPersonShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPeopleShow(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a person
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalPersonUpdateRequestContract} [internalPersonUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPeopleUpdate(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalPersonUpdateRequestContract?: InternalPersonUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalPersonShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPeopleUpdate(id, xNPMAuthorization, xSubscriptionPlan, internalPersonUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PeopleApi - factory interface
 * @export
 */
export const PeopleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PeopleApiFp(configuration)
    return {
        /**
         * 
         * @summary create a person
         * @param {InternalPersonCreateRequestContract} internalPersonCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPeopleCreate(internalPersonCreateRequestContract: InternalPersonCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalPersonShow> {
            return localVarFp.internalPeopleCreate(internalPersonCreateRequestContract, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a person
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPeopleDelete(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalPeopleDelete(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a list of people
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPeopleIndex(search?: string, page?: number, size?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalPersonIndex> {
            return localVarFp.internalPeopleIndex(search, page, size, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get information for a person
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPeopleShow(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalPersonShow> {
            return localVarFp.internalPeopleShow(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a person
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalPersonUpdateRequestContract} [internalPersonUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPeopleUpdate(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalPersonUpdateRequestContract?: InternalPersonUpdateRequestContract, options?: any): AxiosPromise<InternalPersonShow> {
            return localVarFp.internalPeopleUpdate(id, xNPMAuthorization, xSubscriptionPlan, internalPersonUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for internalPeopleCreate operation in PeopleApi.
 * @export
 * @interface PeopleApiInternalPeopleCreateRequest
 */
export interface PeopleApiInternalPeopleCreateRequest {
    /**
     * 
     * @type {InternalPersonCreateRequestContract}
     * @memberof PeopleApiInternalPeopleCreate
     */
    readonly internalPersonCreateRequestContract: InternalPersonCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof PeopleApiInternalPeopleCreate
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof PeopleApiInternalPeopleCreate
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalPeopleDelete operation in PeopleApi.
 * @export
 * @interface PeopleApiInternalPeopleDeleteRequest
 */
export interface PeopleApiInternalPeopleDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof PeopleApiInternalPeopleDelete
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof PeopleApiInternalPeopleDelete
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof PeopleApiInternalPeopleDelete
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalPeopleIndex operation in PeopleApi.
 * @export
 * @interface PeopleApiInternalPeopleIndexRequest
 */
export interface PeopleApiInternalPeopleIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof PeopleApiInternalPeopleIndex
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof PeopleApiInternalPeopleIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PeopleApiInternalPeopleIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof PeopleApiInternalPeopleIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof PeopleApiInternalPeopleIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalPeopleShow operation in PeopleApi.
 * @export
 * @interface PeopleApiInternalPeopleShowRequest
 */
export interface PeopleApiInternalPeopleShowRequest {
    /**
     * 
     * @type {number}
     * @memberof PeopleApiInternalPeopleShow
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof PeopleApiInternalPeopleShow
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof PeopleApiInternalPeopleShow
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalPeopleUpdate operation in PeopleApi.
 * @export
 * @interface PeopleApiInternalPeopleUpdateRequest
 */
export interface PeopleApiInternalPeopleUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof PeopleApiInternalPeopleUpdate
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof PeopleApiInternalPeopleUpdate
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof PeopleApiInternalPeopleUpdate
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {InternalPersonUpdateRequestContract}
     * @memberof PeopleApiInternalPeopleUpdate
     */
    readonly internalPersonUpdateRequestContract?: InternalPersonUpdateRequestContract
}

/**
 * PeopleApi - object-oriented interface
 * @export
 * @class PeopleApi
 * @extends {BaseAPI}
 */
export class PeopleApi extends BaseAPI {
    /**
     * 
     * @summary create a person
     * @param {PeopleApiInternalPeopleCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeopleApi
     */
    public internalPeopleCreate(requestParameters: PeopleApiInternalPeopleCreateRequest, options?: AxiosRequestConfig) {
        return PeopleApiFp(this.configuration).internalPeopleCreate(requestParameters.internalPersonCreateRequestContract, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a person
     * @param {PeopleApiInternalPeopleDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeopleApi
     */
    public internalPeopleDelete(requestParameters: PeopleApiInternalPeopleDeleteRequest, options?: AxiosRequestConfig) {
        return PeopleApiFp(this.configuration).internalPeopleDelete(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a list of people
     * @param {PeopleApiInternalPeopleIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeopleApi
     */
    public internalPeopleIndex(requestParameters: PeopleApiInternalPeopleIndexRequest = {}, options?: AxiosRequestConfig) {
        return PeopleApiFp(this.configuration).internalPeopleIndex(requestParameters.search, requestParameters.page, requestParameters.size, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get information for a person
     * @param {PeopleApiInternalPeopleShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeopleApi
     */
    public internalPeopleShow(requestParameters: PeopleApiInternalPeopleShowRequest, options?: AxiosRequestConfig) {
        return PeopleApiFp(this.configuration).internalPeopleShow(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a person
     * @param {PeopleApiInternalPeopleUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeopleApi
     */
    public internalPeopleUpdate(requestParameters: PeopleApiInternalPeopleUpdateRequest, options?: AxiosRequestConfig) {
        return PeopleApiFp(this.configuration).internalPeopleUpdate(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.internalPersonUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InternalPeopleCreateErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalPeopleCreateErrorCodes = [
    401,
];

export type InternalPeopleDeleteErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalPeopleDeleteErrorCodes = [
];

export type InternalPeopleIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalPeopleIndexErrorCodes = [
    401,
];

export type InternalPeopleShowErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalPeopleShowErrorCodes = [
    401,
    404,
];

export type InternalPeopleUpdateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalPeopleUpdateErrorCodes = [
];


