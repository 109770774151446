import { ActionItemsCount } from '../ActionItemsCount';

import * as S from './IconWithActionItemsCount.styles';

type Props = {
  icon: React.ReactNode;
  count: number | undefined;
};

export const IconWithActionItemsCount = ({ icon, count }: Props) => {
  return count ? (
    <S.IconWrapper>
      {icon}
      <S.AbsoluteWrapper>
        <ActionItemsCount count={count} />
      </S.AbsoluteWrapper>
    </S.IconWrapper>
  ) : (
    <>{icon}</>
  );
};
