import styled, { css } from 'styled-components';

import { Heading2Styles, Text } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoint } from '@npm/core/ui/utils/style';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.lg / 2}px;
    gap: ${theme.spacing.xs}px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    ${() =>
      useBreakpoint(theme).mobile`${css`
        border-bottom: 0;
        padding: ${theme.spacing.sm}px;
      `}`};
  `}
`;

export const ArrowIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  margin-left: ${({ theme }) => theme.spacing.xs}px;
  transition: opacity 0.2s ease-in-out;

  ${({ theme }) =>
    useBreakpoint(theme).tablet`${css`
      color: ${theme.color.info.typography.primary};
      margin-left: 0;
      opacity: 1;
    `}`};
`;

const valueStyles = css`
  ${Heading2Styles};
  transition: color 0.2s ease-in-out;
`;

export const PriceValue = styled(Text.Price)`
  ${valueStyles};
`;

export const QuantityValue = styled(Text.Quantity)`
  ${valueStyles};
`;

export const Item = styled.div<{
  $isSelected?: boolean;
  $isClickable?: boolean;
}>`
  ${({ theme, $isClickable }) => css`
    position: relative;
    min-width: 170px;
    flex: 1;
    border-radius: ${theme.borderRadius.md}px;
    padding: ${theme.spacing.lg / 2}px;
    gap: ${theme.spacing.xs}px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid transparent;

    &:not(:first-child):before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 85%;
      transform: translateY(-50%);
      background-color: ${theme.color.general.borders.primary};
    }

    ${$isClickable &&
    css`
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: ${theme.color.general.layout.two};
        border: 1px solid ${theme.color.general.borders.primary};
        box-shadow: ${theme.shadow.level2};

        &:not(:first-child):before {
          display: none;
        }
      }

      &:hover ${ArrowIconWrapper} {
        opacity: 1;
        color: ${theme.color.info.typography.primary};
      }

      &:hover ${PriceValue}, &:hover ${QuantityValue} {
        color: ${theme.color.info.typography.primary};
      }
    `};

    ${() =>
      useBreakpoint(theme).mobile`${css`
        max-width: unset;
        padding: ${theme.spacing.sm}px;
      `}`};

    ${() =>
      useBreakpoint(theme).smTablet`${css`
        &:not(:first-child):before {
          display: none;
        }
      `}`};
  `}
`;

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xs}px;

    > svg {
      color: ${theme.color.info.typography.primary};
      margin-top: -1px;
    }
  `}
`;
