import { useEffect } from 'react';

import {
  CbAccountType,
  CbWorkstationType,
  useAccountIndex,
} from '@npm/data-access';

import { useCurrentWorkstation } from '../../role';
import { useUserContextStore } from '../store';

export const useDisabledRoleSwitcher = (
  reason?: string,
  condition?: boolean
) => {
  const setRoleSwitcher = useUserContextStore(store => store.setRoleSwitcher);

  useEffect(() => {
    if (condition === false) {
      return undefined;
    }

    setRoleSwitcher({ disabled: true, tooltipText: reason });
    return () => {
      setRoleSwitcher({ disabled: false });
    };
  }, [setRoleSwitcher, condition, reason]);
};

export const useNumberOfInvestorAccounts = () => {
  const workstation = useCurrentWorkstation();

  const { data: accountData } = useAccountIndex(
    {
      accountType: [
        CbAccountType.items.OrganizationAccount,
        CbAccountType.items.PersonAccount,
      ],
      page: 1,
      size: 0,
    },
    {
      // use specific roles, to always have the same number, even for account centric mode
      roles: {
        workstation: 'investor',
        acrossAccount: true,
      },
      onError: err => {
        // ignoring error, because this is only for badge numbers
        console.warn(err);
      },
      queryConfig: {
        enabled: workstation?.type?.code === CbWorkstationType.items.investor,
      },
    }
  );

  return accountData?.pagination?.total_records;
};
