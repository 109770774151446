import React from 'react';

import { Flex, Margin } from '../../../atoms/common';
import { Heading } from '../../../atoms/Typography';

type Props = {
  title: string | null | undefined;
  onBreadcrumbClick: () => void;
};

export const DrawerBreadcrumbs = ({ title, onBreadcrumbClick }: Props) => {
  return (
    <>
      {title ? (
        <Flex gap="sm">
          <Heading
            variant="h2"
            onClick={onBreadcrumbClick}
            style={{ whiteSpace: 'nowrap' }}
          >
            {title}
          </Heading>
          <Margin
            left="xs"
            right="xs"
            style={{ alignItems: 'center', display: 'flex' }}
          >
            /
          </Margin>
        </Flex>
      ) : null}
    </>
  );
};
