import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { PercentageChange } from '@npm/core/ui/components/molecules/PercentageChange';
import type { ExtendedColumnType } from '@npm/core/ui/components/molecules/Table';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';

import { type PrimaryValuation } from '../../PrimaryRounds.types';

export const getPrimaryRoundsTableColumns = (
  isMobile: boolean,
  onOpenDocumentClick: (filingId: number) => void,
  filingIdLoading: number | null
): ExtendedColumnType<PrimaryValuation>[] => [
  {
    title: 'Date',
    key: 'date',
    render: (_, { investment_date }) =>
      formatDate(investment_date, { dateFormat: DATE_FORMATS.DATE }),
  },
  {
    title: 'Round Name',
    key: 'roundName',
    render: (_, { round_name }) => {
      return <Text>{round_name}</Text>;
    },
  },
  {
    title: 'PPS',
    key: 'pps',
    render: (_, { price_per_share }) => {
      return (
        <Text.Price
          value={price_per_share}
          formatOptions={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          currencyAsSymbol
        />
      );
    },
  },
  {
    title: 'Est. Valuation',
    key: 'estValuation',
    render: (_, { valuation }) => {
      return (
        <Text.Price
          value={valuation}
          formatOptions={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          currencyAsSymbol
          compact
        />
      );
    },
  },
  {
    title: 'Valuation Change',
    key: 'valuationChange',
    render: (_, { valuationChange }) => {
      return (
        <PercentageChange change={valuationChange} maximumFractionDigits={1} />
      );
    },
  },
  {
    key: 'actions',
    title: '',
    render: (_, { filing_id }) => (
      <Button
        variant="text"
        size="md"
        icon={<Icon name="file" />}
        onClick={() => onOpenDocumentClick(filing_id)}
        loading={filingIdLoading === filing_id}
        block
      >
        {isMobile ? undefined : 'View Document'}
      </Button>
    ),
    width: isMobile ? 60 : 160,
  },
];
