/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  TermsOfServiceApi,
  TermsOfServiceShowErrorCodes,
  TermsOfServiceShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new TermsOfServiceApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type TermsOfServiceShowMethod = TermsOfServiceApi['termsOfServiceShow'];
export type TermsOfServiceShowResponseType = MethodResult<TermsOfServiceShowMethod>;

export const useTermsOfServiceShow = (
  variables: Parameters<TermsOfServiceShowMethod>[0],
  config?: UseQueryConfig<
    TermsOfServiceShowMethod,
    TermsOfServiceShowErrorTypes
  >
) => {
  return useQuery<
    TermsOfServiceShowMethod,
    TermsOfServiceShowErrorTypes
  >(
    (...args) => api.termsOfServiceShow(...args),
    TermsOfServiceShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TermsOfServiceShow'],
        ...apiQueryConfig.TermsOfServiceShow,
        ...config?.queryConfig
      },
    },
    'TermsOfServiceShow',
  );
};

export const useTermsOfServiceShowInfinite = (
  variables: Parameters<TermsOfServiceShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    TermsOfServiceShowMethod,
    TermsOfServiceShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    TermsOfServiceShowMethod,
    TermsOfServiceShowErrorTypes
  >(
    (...args) => api.termsOfServiceShow(...args),
    TermsOfServiceShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TermsOfServiceShow', 'TermsOfServiceShowInfinite'],
        ...apiQueryConfig.TermsOfServiceShow,
        ...config?.queryConfig,
      },
    },
    'TermsOfServiceShow',
  );
};

export const useTermsOfServiceShowLazy = (baseOptions?: {
  variables?: Parameters<TermsOfServiceShowMethod>[0],
  config?: UseQueryConfig<
    TermsOfServiceShowMethod,
    TermsOfServiceShowErrorTypes
  >
}) => {
  return useLazyQuery<
    TermsOfServiceShowMethod,
    TermsOfServiceShowErrorTypes
  >(
    (...args) => api.termsOfServiceShow(...args),
    TermsOfServiceShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['TermsOfServiceShow'],
        ...apiQueryConfig.TermsOfServiceShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'TermsOfServiceShow',
  );
};

