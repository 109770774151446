import { type LinkProps } from 'react-router-dom';

import type { useCurrentRoute } from '../../../hooks/useCurrentRoute';
import { pageHistoryService } from '../../../services/pageHistory/pageHistory.service';
import { usePreservableQueryParamsStore } from '../../../services/paths';
import {
  buildQueryParamsString,
  transformQueryStringToObj,
} from '../../../utils/url';

import type { useLocation } from './useLocation';

const getCleanedSearchString = (searchObj: Record<string, string | number>) => {
  const cleanedObj = { ...searchObj };

  if (searchObj['allAccounts'] === '1') {
    delete cleanedObj.roleId;
  }

  return cleanedObj;
};

// target location aka LocationDescriptor can be a string, object or function
// https://v5.reactrouter.com/web/api/Link
// this function returns a modified LocationDescriptor OBJECT with our preservable params
export const getLocationDescriptorObject = (
  to: LinkProps['to'],
  currentLocation: ReturnType<typeof useLocation>
) => {
  let pathname: string | undefined;
  let search: string | undefined;
  let state: unknown;
  const handleLocationStringOrObj = (location: LinkProps['to']) => {
    if (typeof location === 'string') {
      pathname = location.split('?')[0];
      const preservableParams = pathname
        ? usePreservableQueryParamsStore
            .getState()
            .getPreservableQueryParams(pathname)
        : {};

      const qp = transformQueryStringToObj(location.split('?')[1]) || {};

      search = buildQueryParamsString(
        getCleanedSearchString({
          // eslint-disable-next-line no-prototype-builtins
          ...(qp.hasOwnProperty('roleId') || qp.hasOwnProperty('allAccounts')
            ? {}
            : preservableParams),
          ...qp,
        })
      );
    } else if (typeof location === 'object') {
      pathname = location.pathname;
      state = location.state;
      const preservableParams = pathname
        ? usePreservableQueryParamsStore
            .getState()
            .getPreservableQueryParams(pathname)
        : {};

      const qp = transformQueryStringToObj(location.search) || {};
      search = buildQueryParamsString(
        getCleanedSearchString({
          // eslint-disable-next-line no-prototype-builtins
          ...(qp.hasOwnProperty('roleId') || qp.hasOwnProperty('allAccounts')
            ? {}
            : preservableParams),
          ...qp,
        })
      );
    }
  };

  if (typeof to === 'function') {
    // get target location as string or object
    const location = to(currentLocation);
    handleLocationStringOrObj(location);

    return {
      ...(typeof location === 'object' && location),
      pathname,
      search,
    };
  } else {
    handleLocationStringOrObj(to);

    return {
      ...(typeof to === 'object' && { ...to }),
      pathname,
      search,
      state,
    };
  }
};

export const getNewStateWithPageHistory = (
  descriptor: ReturnType<typeof getLocationDescriptorObject>,
  pageTitle: string | null,
  location: ReturnType<typeof useLocation>,
  currentRoute: ReturnType<typeof useCurrentRoute>
) => {
  return {
    ...((descriptor.state as unknown as {}) || {}),
    ...{
      pageHistory: pageHistoryService.getNewVersion(
        location,
        currentRoute,
        descriptor,
        pageTitle
      ),
    },
  };
};
