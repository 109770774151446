import { type ComponentProps } from 'react';
import { type FormInstance } from 'antd';

import { type AutoScrollingForm } from '@npm/core/ui/components/organisms/AutoScrollingForm';
import { CbAccreditationQuestion } from '@npm/data-access';

import { type StructuredAccreditationFormData } from '../../Accreditation.types';
import { AutoScrollingQuestion } from '../AutoScrollingQuestion';

export const getFormConfig: (
  accData: StructuredAccreditationFormData[],
  form: FormInstance
) => Pick<
  ComponentProps<typeof AutoScrollingForm>,
  'questions'
>['questions'] = accData =>
  accData.map(({ labels, question }) => ({
    title: question.name,
    name: question.code,
    subTitle: CbAccreditationQuestion.getAttributeValueByName(
      question,
      'subtext'
    ),
    children: ({ onQuestionAnswered }) => (
      <AutoScrollingQuestion
        onQuestionAnswered={onQuestionAnswered}
        labels={labels}
      />
    ),
  }));
