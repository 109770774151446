import { useState } from 'react';

import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';

import { useZendesk, ZendeskCustomFieldName } from '../../app/zendesk';

import { RequestCoverageButton } from './RequestCoverageButton';
import { RequestCoverageModal } from './RequestCoverageModal';

type Props = {
  searchTerm: string;
};

export const useRequestCompanyCoverage = ({ searchTerm }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyNameForCoverageRequest, setCompanyNameForCoverageRequest] =
    useState('');

  const { showAlert } = useAlerts();
  const { createCustomerRequest } = useZendesk();

  // Introduce a new state variable to store the names of companies that have been requested for Zendesk, in order to prevent duplicate requests.
  const [requestedCompanies, setRequestedCompanies] = useState<Set<string>>(
    new Set()
  );

  const onRequestCoverageClick = async (companyName: string) => {
    const { success, message } = await createCustomerRequest(
      'company_coverage_request',
      `I would like to request coverage for ${companyName}`,
      {
        [ZendeskCustomFieldName.CompanyName]: companyName,
      }
    );
    if (success) {
      setRequestedCompanies(prevCompanies =>
        new Set(prevCompanies).add(companyName)
      );

      Notification.success({
        message: `We have successfully received your request for coverage of ${companyName}.`,
      });
    } else {
      showAlert(message, {
        type: 'error',
      });
    }
  };

  const handleSubmit = async () => {
    await onRequestCoverageClick(companyNameForCoverageRequest);
    setIsModalOpen(false);
  };

  const requestCoverageButtonComponent = (
    <RequestCoverageButton
      searchTerm={searchTerm}
      requestedCompanies={requestedCompanies}
      setCompanyNameForCoverageRequest={setCompanyNameForCoverageRequest}
      setIsModalOpen={setIsModalOpen}
    />
  );

  const requestCoverageModalComponent = (
    <RequestCoverageModal
      isOpen={isModalOpen}
      searchTerm={companyNameForCoverageRequest}
      onSubmit={handleSubmit}
      onCancel={() => setIsModalOpen(false)}
    />
  );

  const requestCoverageAreaComponent = (
    <NoDataArea
      outlined
      iconColor="info"
      heading={`"${searchTerm}" is not available yet`}
      title="Submit a coverage request, and we'll inform you once we partner with this company."
      onClick={handleSubmit}
      onClickText="Request Coverage"
      onClickIcon={null}
      verticalPadding="xxl"
    />
  );

  return {
    requestCoverageButtonComponent,
    requestCoverageModalComponent,
    requestCoverageAreaComponent,
  };
};
