import { Codebooks } from './codebooks.types';

const Items = {
  buyer: 'buyer',
  seller: 'seller',
} as const;

export const CbLastTransactedAction = {
  code: Codebooks.LAST_TRANSACTED_ACTION,
  items: Items,
};
