import { type QueryKey } from '../generated';

// Cache keys related to submission
export const SUBMISSION_QUERY_KEYS: QueryKey[] = [
  'SubmissionIndex',
  'SubmissionShow',
  'EventSubmissionShow',
  'EventSubmissionIndex',
];

// Cache keys related to second market orders
export const SM_ORDER_QUERY_KEYS: QueryKey[] = [
  'SecondmarketSubmissionIndex',
  'SecondmarketOrderItemIndex',
  'SecondmarketOrderItemShow',
  'SecondmarketBidAskItemIndex',
  'IssuerEntityCompanyStatistics',
  'ActionCounterShow',
  'InvestorOrderItemShow',
  'OrderItemShow',
];

export const SM_NEGOTIATION_QUERY_KEYS: QueryKey[] = [
  'NegotiationIndex',
  'NegotiationShow',
  'InvestorNegotiationIndex',
  'InvestorNegotiationStatisticsIndex',
  'NegotiationStatisticsIndex',
];
