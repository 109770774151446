import React, { useMemo } from 'react';

import { useBreakpoints } from '../../../../hooks/useBreakpoints';
import { type routeKeys, type RouteTitleProps } from '../../../../routes';
import { truncate } from '../../../../utils/formatters';
import { Icon } from '../../../atoms/Icon';
import { Text } from '../../../atoms/Typography';
import { Link } from '../../Link';

import * as S from './Breadcrumbs.styles';

type Props = {
  lastLevelOnly?: boolean;
  lastLevelText?: string;
  items: {
    title?: string | ((props?: RouteTitleProps) => React.ReactNode);
    path: routeKeys | string;
    search?: string;
    state?: unknown;
  }[];
};

export const Breadcrumbs = ({ items, lastLevelOnly, lastLevelText }: Props) => {
  const { isMobile } = useBreakpoints();

  const getTitle = title =>
    typeof title === 'function' ? title({ raw: true }) : truncate(title);

  const routesToShow = useMemo(() => {
    if (!items?.length) return [];

    return lastLevelOnly || isMobile
      ? [
          {
            ...items[items.length - 1],
            title: lastLevelOnly
              ? lastLevelText
              : items[items.length - 1]?.title,
          },
        ]
      : items.length > 2
      ? [items[0], items[items.length - 2], items[items.length - 1]]
      : items;
  }, [lastLevelOnly, isMobile, items]);

  const renderBackTo = title => {
    const _title = getTitle(title);

    if (!_title) {
      return null;
    }

    return (
      <S.Prefix>
        <Icon name="chevron-left" size="xs" />
        {!lastLevelText && routesToShow.length === 1 ? 'Back to ' : null}
        {_title}
      </S.Prefix>
    );
  };

  return (
    <S.Breadcrumb>
      {routesToShow.map(
        ({ title, path, search, state }, i) =>
          !!title && (
            <S.Breadcrumb.Item key={i}>
              <Link
                to={{
                  pathname: path,
                  search,
                  state,
                }}
              >
                <Text as="button" size="sm">
                  {i === 0 ? renderBackTo(title) : getTitle(title)}
                </Text>
              </Link>
            </S.Breadcrumb.Item>
          )
      )}
    </S.Breadcrumb>
  );
};
