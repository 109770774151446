import { Codebooks } from './codebooks.types';

const Items = {
  Buyer: 'buyer',
  Seller: 'seller',
  Spouse: 'spouse',
  Issuer: 'issuer',
} as const;

export const CbAgreementDocumentSigner = {
  code: Codebooks.AGREEMENT_DOCUMENT_SIGNER,
  items: Items,
};
