import styled from 'styled-components';

import { Text } from '@npm/core/ui/components/atoms/Typography/Text';

export const Container = styled(Text)`
  padding-top: ${({ theme }) => theme.spacing.lg}px;
  padding-bottom: ${({ theme }) => theme.spacing.lg}px;
`;
export const DateText = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing.md}px;
  color: ${({ theme }) => theme.color.general.typography.tertiary};
`;
