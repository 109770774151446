import styled from 'styled-components';

import { type ThemeSpacing } from '../../../atoms/common';

export const List = styled.div<{ $gap?: ThemeSpacing }>`
  display: flex;
  flex-direction: row;
  ${({ $gap, theme }) => ($gap ? `gap: ${theme.spacing[$gap]}px` : null)};
  width: 100%;
`;

export const ScrollableContainer = styled.div`
  overflow: auto hidden;
`;

export const Container = styled.div`
  position: relative;
`;
