export type UIEvent = 'REQUEST_SUPPORT' | 'HISTORY_EVENT';

export const eventBus = {
  on: <T = unknown>(event: UIEvent, callback: (arg: T) => void) => {
    const fn = (e: Event) => callback((e as CustomEvent<T>).detail);
    document.addEventListener(event, fn);
    return () => document.removeEventListener(event, fn);
  },
  dispatch: <T = unknown>(event: UIEvent, data?: T) => {
    return document.dispatchEvent(new CustomEvent<T>(event, { detail: data }));
  },
};
