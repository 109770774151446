import { flatMap } from 'lodash';
import { type QueryParamConfig } from 'use-query-params';

import { Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import {
  type StepDefinition,
  type WizardStore,
} from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { type OnboardingStatus, CbOnboardingStatus } from '@npm/data-access';

import {
  type OnboardingSectionConfig,
  type OnboardingSectionItemWithSubsteps,
  type PostOnboardingContext,
} from './PostOnboarding.types';

export const getFlattenedOnboardingWizardSteps = (
  sectionsConfig: OnboardingSectionConfig[]
) => {
  const flattenedSteps: Wizard.StepDefinition[] = flatMap(
    sectionsConfig,
    section =>
      flatMap(section.items, item => (item.substeps ? item.substeps : item))
  );

  return flattenedSteps;
};

export const isOnboardingStepIncomplete = (stepStatus: string) => {
  return (
    stepStatus === CbOnboardingStatus.items.incomplete ||
    stepStatus === CbOnboardingStatus.items.warning ||
    stepStatus === undefined
  );
};

export const isOnboardingStepInWarningState = (
  stepKey: keyof typeof CbOnboardingStatus.steps,
  onboardingStatus: OnboardingStatus
) => {
  const stepStatus = onboardingStatus[stepKey]?.code;
  return stepStatus === CbOnboardingStatus.items.warning;
};

export const findNextIncompleteOnboardingStep = ({
  steps,
  onboardingStatus,
  startIndex,
}: {
  steps: WizardStore<PostOnboardingContext>['steps'];
  onboardingStatus: OnboardingStatus;
  startIndex: number;
}) => {
  const findNextIncompleteStep = (startIndex: number) =>
    Wizard.findNextIncompleteStep(steps, startIndex, step =>
      isOnboardingStepIncomplete(onboardingStatus[step.key]?.code)
    );

  // find the next incomplete step, starting from the determined index
  const nextIncompleteStep = findNextIncompleteStep(startIndex);

  // if no incomplete step found, check the steps that were skipped if startIndex > -1
  if (nextIncompleteStep === -1 && startIndex > -1) {
    return findNextIncompleteStep(-1);
  }

  return nextIncompleteStep;
};

/**
 * filters out onboarding steps that are not included in the response of `/api/accounts/{id}/onboarding-status`
 */
export const filterOnboardingSectionsConfig = (
  sectionsConfig: OnboardingSectionConfig[],
  onboardingStatus: OnboardingStatus
) => {
  return sectionsConfig.reduce((acc: OnboardingSectionConfig[], section) => {
    const filteredItems: OnboardingSectionConfig['items'] =
      section.items.reduce((filtered, item) => {
        if (item.substeps) {
          const filteredSubsteps = item.substeps.filter(
            ({ key }) => !!onboardingStatus?.[key]
          );
          if (filteredSubsteps.length > 0) {
            filtered.push({
              ...item,
              substeps: filteredSubsteps,
            } as OnboardingSectionItemWithSubsteps);
          }
        } else if (onboardingStatus?.[item.key]) {
          filtered.push(item);
        }
        return filtered;
      }, []);

    if (filteredItems.length > 0) {
      acc.push({ ...section, items: filteredItems });
    }

    return acc;
  }, []);
};

export const calculateSectionProgress = (
  sectionItems: OnboardingSectionConfig['items'],
  onboardingStatusData: OnboardingStatus
) => {
  const isStepComplete = (stepOrSubstep: { key: string }) =>
    !isOnboardingStepIncomplete(onboardingStatusData[stepOrSubstep.key]?.code);

  const totalSteps = sectionItems.reduce((acc, item) => {
    return item.substeps ? acc + item.substeps.length : acc + 1;
  }, 0);

  const completedSteps = sectionItems.reduce((acc, item) => {
    return item.substeps
      ? acc + item.substeps.filter(isStepComplete).length
      : acc + (isStepComplete(item) ? 1 : 0);
  }, 0);

  return (completedSteps / totalSteps) * 100;
};

export const getActiveStepQueryParamConfig: (
  steps: StepDefinition[]
) => QueryParamConfig<number> = steps => {
  return {
    encode: (value: number) =>
      typeof value === 'number' ? String(value) : value,
    decode: (value: string) => {
      if (!value) return undefined;
      const parsedNumber = Number(value);

      if (isNaN(parsedNumber)) {
        return steps.findIndex(s => s.key === value);
      }
      return parsedNumber;
    },
  };
};
