import React from 'react';

import { Loader } from '@npm/core/ui/components/atoms/Loader';

import * as S from './ItemButton.styles';

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  isLoading?: boolean;
  readOnly?: boolean;
  ['data-cy']?: string;
};

export const ItemButton = ({
  children,
  onClick,
  readOnly = false,
  isLoading,
  ...rest
}: Props) => {
  return (
    <S.ItemButton
      onClick={onClick}
      {...rest}
      $isReadOnly={readOnly}
      $isLoading={isLoading}
    >
      {children}
      {isLoading && (
        <S.LoadingSpinnerWrapper>
          <Loader size="default" />
        </S.LoadingSpinnerWrapper>
      )}
    </S.ItemButton>
  );
};
