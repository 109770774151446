import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import { CbDocumentType } from '@npm/data-access';
import { type DocumentAggregate } from '@npm/data-access';

import { DocumentPreview } from '../../../documents/DocumentPreview';
import { FORM_FIELDS_CONFIG } from '../Form/GovernmentIDForm.constants';

type Data = {
  label: string;
  value: string | JSX.Element;
};

export const prepareReadOnlyData = (
  documentData: DocumentAggregate,
  isDesktop: boolean
) => {
  if (documentData) {
    const {
      category,
      date_of_birth,
      properties: {
        number,
        state,
        expiration_date,
        nationality,
        issuing_country,
      },
    } = documentData;

    let data: Data[] = [
      {
        label: FORM_FIELDS_CONFIG.category.label,
        value: CbDocumentType.getLabel(category.code),
      },
    ];

    if (category.code === CbDocumentType.items.drivers_license) {
      data = [
        ...data,
        {
          label: FORM_FIELDS_CONFIG.numberLicense.label,
          value: number,
        },
        {
          label: FORM_FIELDS_CONFIG.state.label,
          value: state.name,
        },
      ];
    }

    if (category.code === CbDocumentType.items.passport) {
      data = [
        ...data,
        {
          label: FORM_FIELDS_CONFIG.numberPassport.label,
          value: number,
        },
        {
          label: FORM_FIELDS_CONFIG.issuingCountry.label,
          value: issuing_country.name,
        },
        {
          label: FORM_FIELDS_CONFIG.nationality.label,
          value: nationality.name,
        },
      ];
    }

    data = [
      ...data,
      {
        label: FORM_FIELDS_CONFIG.expirationDate.label,
        value: formatDate(expiration_date, { dateFormat: DATE_FORMATS.DATE }),
      },
      {
        label: FORM_FIELDS_CONFIG.dateOfBirth.label,
        value: formatDate(date_of_birth, { dateFormat: DATE_FORMATS.DATE }),
      },
      {
        label: 'Government Issued Photo',
        value: (
          <DocumentPreview
            document={documentData}
            labelMaxCharacters={isDesktop ? 32 : 16}
          />
        ),
      },
    ];

    return data;
  }
  return [];
};
