/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalInvestorCreateRequestContract } from '../model';
// @ts-ignore
import { InternalInvestorIndex } from '../model';
// @ts-ignore
import { InternalInvestorShow } from '../model';
// @ts-ignore
import { InternalInvestorUpdateRequestContract } from '../model';
/**
 * InvestorsApi - axios parameter creator
 * @export
 */
export const InvestorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get Investors in a Company
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompanyInvestorsIndex: async (companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalCompanyInvestorsIndex', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/investors`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete an investor
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDeleteInvestor: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDeleteInvestor', 'id', id)
            const localVarPath = changeUrl(`/api/internal/investors/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create an investor
         * @param {number} investmentId 
         * @param {InternalInvestorCreateRequestContract} internalInvestorCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalInvestorsCreate: async (investmentId: number, internalInvestorCreateRequestContract: InternalInvestorCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentId' is not null or undefined
            assertParamExists('internalInvestorsCreate', 'investmentId', investmentId)
            // verify required parameter 'internalInvestorCreateRequestContract' is not null or undefined
            assertParamExists('internalInvestorsCreate', 'internalInvestorCreateRequestContract', internalInvestorCreateRequestContract)
            const localVarPath = changeUrl(`/api/internal/investments/{investment_id}/investors`)
                .replace(`{${"investment_id"}}`, encodeURIComponent(String(investmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalInvestorCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get Investors for an Investment
         * @param {number} investmentId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalInvestorsIndex: async (investmentId: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentId' is not null or undefined
            assertParamExists('internalInvestorsIndex', 'investmentId', investmentId)
            const localVarPath = changeUrl(`/api/internal/investments/{investment_id}/investors`)
                .replace(`{${"investment_id"}}`, encodeURIComponent(String(investmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get an investor
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalInvestorsShow: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalInvestorsShow', 'id', id)
            const localVarPath = changeUrl(`/api/internal/investors/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update an investor
         * @param {number} id 
         * @param {InternalInvestorUpdateRequestContract} internalInvestorUpdateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateInvestor: async (id: number, internalInvestorUpdateRequestContract: InternalInvestorUpdateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateInvestor', 'id', id)
            // verify required parameter 'internalInvestorUpdateRequestContract' is not null or undefined
            assertParamExists('internalUpdateInvestor', 'internalInvestorUpdateRequestContract', internalInvestorUpdateRequestContract)
            const localVarPath = changeUrl(`/api/internal/investors/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalInvestorUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary bulk upload investors and firms
         * @param {string} file 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUploadInvestors: async (file: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('internalUploadInvestors', 'file', file)
            const localVarPath = changeUrl(`/api/internal/investors/upload`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (includeField) {
                localVarFormParams.append('include_field', JSON.stringify(includeField));
            }

    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestorsApi - functional programming interface
 * @export
 */
export const InvestorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get Investors in a Company
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCompanyInvestorsIndex(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalInvestorIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCompanyInvestorsIndex(companyId, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete an investor
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDeleteInvestor(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDeleteInvestor(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create an investor
         * @param {number} investmentId 
         * @param {InternalInvestorCreateRequestContract} internalInvestorCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalInvestorsCreate(investmentId: number, internalInvestorCreateRequestContract: InternalInvestorCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalInvestorShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalInvestorsCreate(investmentId, internalInvestorCreateRequestContract, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get Investors for an Investment
         * @param {number} investmentId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalInvestorsIndex(investmentId: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalInvestorIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalInvestorsIndex(investmentId, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get an investor
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalInvestorsShow(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalInvestorShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalInvestorsShow(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update an investor
         * @param {number} id 
         * @param {InternalInvestorUpdateRequestContract} internalInvestorUpdateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateInvestor(id: number, internalInvestorUpdateRequestContract: InternalInvestorUpdateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateInvestor(id, internalInvestorUpdateRequestContract, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary bulk upload investors and firms
         * @param {string} file 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUploadInvestors(file: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUploadInvestors(file, xNPMAuthorization, xSubscriptionPlan, includeField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestorsApi - factory interface
 * @export
 */
export const InvestorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestorsApiFp(configuration)
    return {
        /**
         * 
         * @summary get Investors in a Company
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompanyInvestorsIndex(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalInvestorIndex> {
            return localVarFp.internalCompanyInvestorsIndex(companyId, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete an investor
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDeleteInvestor(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalDeleteInvestor(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create an investor
         * @param {number} investmentId 
         * @param {InternalInvestorCreateRequestContract} internalInvestorCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalInvestorsCreate(investmentId: number, internalInvestorCreateRequestContract: InternalInvestorCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalInvestorShow> {
            return localVarFp.internalInvestorsCreate(investmentId, internalInvestorCreateRequestContract, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get Investors for an Investment
         * @param {number} investmentId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalInvestorsIndex(investmentId: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalInvestorIndex> {
            return localVarFp.internalInvestorsIndex(investmentId, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get an investor
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalInvestorsShow(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalInvestorShow> {
            return localVarFp.internalInvestorsShow(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update an investor
         * @param {number} id 
         * @param {InternalInvestorUpdateRequestContract} internalInvestorUpdateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateInvestor(id: number, internalInvestorUpdateRequestContract: InternalInvestorUpdateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalUpdateInvestor(id, internalInvestorUpdateRequestContract, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary bulk upload investors and firms
         * @param {string} file 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUploadInvestors(file: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.internalUploadInvestors(file, xNPMAuthorization, xSubscriptionPlan, includeField, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for internalCompanyInvestorsIndex operation in InvestorsApi.
 * @export
 * @interface InvestorsApiInternalCompanyInvestorsIndexRequest
 */
export interface InvestorsApiInternalCompanyInvestorsIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalCompanyInvestorsIndex
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalCompanyInvestorsIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalCompanyInvestorsIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalDeleteInvestor operation in InvestorsApi.
 * @export
 * @interface InvestorsApiInternalDeleteInvestorRequest
 */
export interface InvestorsApiInternalDeleteInvestorRequest {
    /**
     * 
     * @type {number}
     * @memberof InvestorsApiInternalDeleteInvestor
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalDeleteInvestor
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalDeleteInvestor
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalInvestorsCreate operation in InvestorsApi.
 * @export
 * @interface InvestorsApiInternalInvestorsCreateRequest
 */
export interface InvestorsApiInternalInvestorsCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof InvestorsApiInternalInvestorsCreate
     */
    readonly investmentId: number

    /**
     * 
     * @type {InternalInvestorCreateRequestContract}
     * @memberof InvestorsApiInternalInvestorsCreate
     */
    readonly internalInvestorCreateRequestContract: InternalInvestorCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalInvestorsCreate
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalInvestorsCreate
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalInvestorsIndex operation in InvestorsApi.
 * @export
 * @interface InvestorsApiInternalInvestorsIndexRequest
 */
export interface InvestorsApiInternalInvestorsIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof InvestorsApiInternalInvestorsIndex
     */
    readonly investmentId: number

    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalInvestorsIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalInvestorsIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalInvestorsShow operation in InvestorsApi.
 * @export
 * @interface InvestorsApiInternalInvestorsShowRequest
 */
export interface InvestorsApiInternalInvestorsShowRequest {
    /**
     * 
     * @type {number}
     * @memberof InvestorsApiInternalInvestorsShow
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalInvestorsShow
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalInvestorsShow
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalUpdateInvestor operation in InvestorsApi.
 * @export
 * @interface InvestorsApiInternalUpdateInvestorRequest
 */
export interface InvestorsApiInternalUpdateInvestorRequest {
    /**
     * 
     * @type {number}
     * @memberof InvestorsApiInternalUpdateInvestor
     */
    readonly id: number

    /**
     * 
     * @type {InternalInvestorUpdateRequestContract}
     * @memberof InvestorsApiInternalUpdateInvestor
     */
    readonly internalInvestorUpdateRequestContract: InternalInvestorUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalUpdateInvestor
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalUpdateInvestor
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalUploadInvestors operation in InvestorsApi.
 * @export
 * @interface InvestorsApiInternalUploadInvestorsRequest
 */
export interface InvestorsApiInternalUploadInvestorsRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalUploadInvestors
     */
    readonly file: string

    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalUploadInvestors
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof InvestorsApiInternalUploadInvestors
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestorsApiInternalUploadInvestors
     */
    readonly includeField?: Array<string>
}

/**
 * InvestorsApi - object-oriented interface
 * @export
 * @class InvestorsApi
 * @extends {BaseAPI}
 */
export class InvestorsApi extends BaseAPI {
    /**
     * 
     * @summary get Investors in a Company
     * @param {InvestorsApiInternalCompanyInvestorsIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApi
     */
    public internalCompanyInvestorsIndex(requestParameters: InvestorsApiInternalCompanyInvestorsIndexRequest, options?: AxiosRequestConfig) {
        return InvestorsApiFp(this.configuration).internalCompanyInvestorsIndex(requestParameters.companyId, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete an investor
     * @param {InvestorsApiInternalDeleteInvestorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApi
     */
    public internalDeleteInvestor(requestParameters: InvestorsApiInternalDeleteInvestorRequest, options?: AxiosRequestConfig) {
        return InvestorsApiFp(this.configuration).internalDeleteInvestor(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create an investor
     * @param {InvestorsApiInternalInvestorsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApi
     */
    public internalInvestorsCreate(requestParameters: InvestorsApiInternalInvestorsCreateRequest, options?: AxiosRequestConfig) {
        return InvestorsApiFp(this.configuration).internalInvestorsCreate(requestParameters.investmentId, requestParameters.internalInvestorCreateRequestContract, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get Investors for an Investment
     * @param {InvestorsApiInternalInvestorsIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApi
     */
    public internalInvestorsIndex(requestParameters: InvestorsApiInternalInvestorsIndexRequest, options?: AxiosRequestConfig) {
        return InvestorsApiFp(this.configuration).internalInvestorsIndex(requestParameters.investmentId, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get an investor
     * @param {InvestorsApiInternalInvestorsShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApi
     */
    public internalInvestorsShow(requestParameters: InvestorsApiInternalInvestorsShowRequest, options?: AxiosRequestConfig) {
        return InvestorsApiFp(this.configuration).internalInvestorsShow(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update an investor
     * @param {InvestorsApiInternalUpdateInvestorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApi
     */
    public internalUpdateInvestor(requestParameters: InvestorsApiInternalUpdateInvestorRequest, options?: AxiosRequestConfig) {
        return InvestorsApiFp(this.configuration).internalUpdateInvestor(requestParameters.id, requestParameters.internalInvestorUpdateRequestContract, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary bulk upload investors and firms
     * @param {InvestorsApiInternalUploadInvestorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestorsApi
     */
    public internalUploadInvestors(requestParameters: InvestorsApiInternalUploadInvestorsRequest, options?: AxiosRequestConfig) {
        return InvestorsApiFp(this.configuration).internalUploadInvestors(requestParameters.file, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.includeField, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InternalCompanyInvestorsIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalCompanyInvestorsIndexErrorCodes = [
    401,
    404,
];

export type InternalDeleteInvestorErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalDeleteInvestorErrorCodes = [
    404,
];

export type InternalInvestorsCreateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalInvestorsCreateErrorCodes = [
];

export type InternalInvestorsIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalInvestorsIndexErrorCodes = [
    401,
    404,
];

export type InternalInvestorsShowErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalInvestorsShowErrorCodes = [
    404,
];

export type InternalUpdateInvestorErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalUpdateInvestorErrorCodes = [
    404,
];

export type InternalUploadInvestorsErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalUploadInvestorsErrorCodes = [
];


