import React, { type ComponentProps } from 'react';

import { type IconProps } from '@npm/utils';

import { SquareIcon } from '../../atoms/Icon/SquareIcon';
import { Label } from '../../atoms/Label';
import { Text } from '../../atoms/Typography';

import * as S from './DrawerSection.styles';

type ChipProps = {
  color: ComponentProps<typeof Label>['variant'];
  content: ComponentProps<typeof Label>['content'];
};

type Props = {
  iconName?: IconProps['name'];
  title: string | React.ReactNode;
  showDot?: boolean;
  subtitle?: string;
  informationalChip?: React.ReactElement | ChipProps;
  content?: React.ReactNode;
  boxedContent?: React.ReactNode;
  headerAction?: React.ReactNode;
};

export const DrawerSection = ({
  iconName,
  title,
  subtitle,
  showDot = true,
  informationalChip,
  content,
  boxedContent,
  headerAction,
}: Props) => {
  return (
    <>
      <S.DrawerSectionHeaderWrapper>
        <S.TitleWrapper>
          {iconName && <SquareIcon iconName={iconName} />}
          <Text weight="bold" colorVariant="primary">
            {title}
          </Text>
          {Boolean(subtitle) && (
            <Text size="sm" colorVariant="secondary">
              {showDot && <>•&nbsp;&nbsp;</>}
              {subtitle}
            </Text>
          )}
          {!!informationalChip && (
            <S.InformationalChipWrapper>
              {React.isValidElement(informationalChip) ? (
                informationalChip
              ) : (
                <Label variant={(informationalChip as ChipProps).color} round>
                  {(informationalChip as ChipProps).content}
                </Label>
              )}
            </S.InformationalChipWrapper>
          )}
        </S.TitleWrapper>
        {!!headerAction && headerAction}
      </S.DrawerSectionHeaderWrapper>
      {Boolean(content) && content}
      {Boolean(boxedContent) && (
        <S.BoxedContentWrapper>{boxedContent}</S.BoxedContentWrapper>
      )}
    </>
  );
};
