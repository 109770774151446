import React, { type ComponentProps, useMemo } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type Codebook, type VenusApi } from '@npm/data-access';

import { SectionStats } from '../../components/SectionStats';
import { type StatItem } from '../../components/SectionStats/StatItem';

import { getMethodologyConfig, getStatSubTitle } from './CompanyStats.utils';

type Props = {
  data: VenusApi.FinancialActivityIndex;
  isLoading?: boolean;
  denomination?: Codebook;
  onItemClick: (investmentId: number) => void;
};

export const CompanyStats = ({
  data,
  isLoading,
  denomination,
  onItemClick,
}: Props) => {
  const totalRaiseAmount = useMemo(() => {
    return data?.financial_activity?.reduce(
      (acc, activity) => acc + (activity.investment_amount ?? 0),
      0
    );
  }, [data]);

  const lastRound = useMemo(() => {
    return data?.financial_activity?.find(
      activity =>
        !activity.unclassified &&
        (activity.price_per_share || activity.valuation)
    );
  }, [data]);

  const methodologyConfig = getMethodologyConfig(lastRound?.methodology);

  const lastRaise = useMemo(() => {
    return data?.financial_activity?.find(
      activity => !activity.unclassified && activity.investment_amount
    );
  }, [data]);

  const items: ComponentProps<typeof StatItem>[] = [
    {
      key: 'pps-valuation',
      title: 'Last Round PPS • Valuation',
      iconName: 'hand-dollar-up',
      value: [lastRound?.price_per_share, lastRound?.valuation],
      valueFormatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      subTitle: getStatSubTitle(lastRound),
      tooltipInfo: methodologyConfig && (
        <Flex direction="column" gap="md">
          <Flex>
            <Text size="sm">Valuation Methodology: </Text>
            <Text size="sm" weight="bold">
              {methodologyConfig.title}
            </Text>
          </Flex>
          <Text size="sm">{methodologyConfig.tooltipText}</Text>
        </Flex>
      ),
      onClick: lastRound ? () => onItemClick(lastRound.id) : undefined,
    },
    {
      key: 'latestCapitalRaise',
      title: 'Latest Capital Raise',
      iconName: 'chart-bar',
      value: lastRaise?.investment_amount,
      subTitle: getStatSubTitle(lastRaise),
      onClick: lastRaise ? () => onItemClick(lastRaise.id) : undefined,
    },
    {
      key: 'totalCapitalRaised',
      title: 'Total Capital Raised',
      iconName: 'currency-dollar',
      tooltipInfo: (
        <>
          <Text size="sm" weight="bold" as="span">
            Total Capital Raised
          </Text>{' '}
          is sourced from priced rounds
        </>
      ),
      value: totalRaiseAmount,
    },
  ];

  return (
    <SectionStats
      items={items}
      isLoading={isLoading}
      denomination={denomination}
    />
  );
};
