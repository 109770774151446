import { Col, Row } from 'antd';
import { useTheme } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Image } from '@npm/core/ui/components/atoms/Image';
import { Heading } from '@npm/core/ui/components/atoms/Typography/Heading';
import { Text } from '@npm/core/ui/components/atoms/Typography/Text';

import { useUnlockPremium } from '../../../../auth/user/components';

import { getCompanyDataAsset } from './BasicPageLayout.config';
import { useBasicPageLayoutConfig } from './BasicPageLayout.hooks';
import {
  type BasicPageLayoutCardType,
  type BasicPageLayoutCardWithTwoImagesType,
} from './BasicPageLayout.types';
import {
  type BasicPageMainCardProps,
  BasicPageMainCard,
} from './BasicPageMainCard';
import { PremiumFeatureLabel } from './PremiumFeatureLabel';

import * as S from './BasicPageLayout.styles';

type ExtraMainCardConfig = {
  mobileImage?: {
    size?: string;
    position?: string;
  };
  titleMaxWidth?: string;
};

type ExtraFooterCardConfig = {
  titleMaxWidth?: string;
};

export type BasicPageLayoutInnerProps = {
  mainCard: BasicPageLayoutCardType &
    ExtraMainCardConfig &
    Pick<BasicPageMainCardProps, 'showUpgradeButton'>;
  footerCard: BasicPageLayoutCardWithTwoImagesType & ExtraFooterCardConfig;
  middleCards: BasicPageLayoutCardType[];
  readMoreModal?: BasicPageMainCardProps['readMoreModal'];
};

export const BasicPageLayoutInner = ({
  mainCard,
  middleCards,
  footerCard,
  readMoreModal,
}: BasicPageLayoutInnerProps) => {
  const requestPremiumMembership = useUnlockPremium();

  const theme = useTheme();
  const { layoutConfig, imagesSuffix, isMobile } = useBasicPageLayoutConfig();

  const config = {
    ...layoutConfig,
    mainBgImagePosition:
      (isMobile ? mainCard.mobileImage?.position : undefined) ??
      layoutConfig.mainBgImagePosition,
    mainBgImageSize:
      (isMobile ? mainCard.mobileImage?.size : undefined) ??
      layoutConfig.mainBgImageSize,
  };

  return (
    <Flex direction="column" gap={config.mainGap} align="stretch">
      <BasicPageMainCard
        requestPremiumMembership={requestPremiumMembership}
        layoutConfig={config}
        {...mainCard}
        imagesSuffix={imagesSuffix}
        readMoreModal={readMoreModal}
      />
      <S.ShrinkedRow
        gutter={theme.spacing[config.cardsGap]}
        justify="start"
        align="stretch"
        wrap
      >
        {middleCards.map(card => (
          <S.CardCol span={config.cardsColSpan} key={card.title}>
            <S.MiddleCard>
              <Image
                src={getCompanyDataAsset(card.imagePrefix, imagesSuffix)}
                preview={false}
              />
              <S.CardContentWrapper>
                <Flex direction="column" gap="lg">
                  <PremiumFeatureLabel />
                  <Heading variant="h1">{card.title}</Heading>
                  {typeof card.description === 'string' ? (
                    <Text size="sm">{card.description}</Text>
                  ) : (
                    card.description
                  )}
                </Flex>
              </S.CardContentWrapper>
            </S.MiddleCard>
          </S.CardCol>
        ))}
      </S.ShrinkedRow>
      <S.FooterCardWithBackground
        src={
          !isMobile
            ? getCompanyDataAsset('footer-background-gradient', imagesSuffix)
            : getCompanyDataAsset(footerCard.image.mobilePrefix, imagesSuffix)
        }
        size={config.footerBgImageSize}
        position={config.footerBgImagePosition}
      >
        {!isMobile && (
          <>
            <S.FooterImage
              src={getCompanyDataAsset(
                footerCard.image.left.prefix,
                imagesSuffix
              )}
              $position={footerCard.image.left.position}
            />
            <S.FooterImage
              src={getCompanyDataAsset(
                footerCard.image.right.prefix,
                imagesSuffix
              )}
              $position={footerCard.image.right.position}
            />
          </>
        )}
        <Row justify="center">
          <Col xs={24} sm={10}>
            <Flex align="center" direction="column" gap="lg">
              <Heading
                textAlign="center"
                variant="h0"
                style={{ maxWidth: footerCard.titleMaxWidth }}
              >
                {footerCard.title}
              </Heading>
              {typeof footerCard.description === 'string' ? (
                <Text size="sm">{footerCard.description}</Text>
              ) : (
                footerCard.description
              )}
              <Button onClick={requestPremiumMembership}>Request Trial</Button>
            </Flex>
          </Col>
        </Row>
      </S.FooterCardWithBackground>
    </Flex>
  );
};
