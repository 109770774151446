import Big from 'big.js';

export const calculateEstimatedGrossPrice = (
  price: number,
  amount: number
): number | string => {
  if (!!price && !!amount) {
    return Number(new Big(price).times(amount));
  }
  return '--';
};
