/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Company-Pricing API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HistoricalPriceIndex } from '../model';
/**
 * HistoricalPricesApi - axios parameter creator
 * @export
 */
export const HistoricalPricesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get historical prices for a company
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        historicalPricesIndex: async (companyId: string, page?: number, size?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('historicalPricesIndex', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/companies/{company_id}/historical_prices`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HistoricalPricesApi - functional programming interface
 * @export
 */
export const HistoricalPricesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HistoricalPricesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get historical prices for a company
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async historicalPricesIndex(companyId: string, page?: number, size?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoricalPriceIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.historicalPricesIndex(companyId, page, size, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HistoricalPricesApi - factory interface
 * @export
 */
export const HistoricalPricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HistoricalPricesApiFp(configuration)
    return {
        /**
         * 
         * @summary get historical prices for a company
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        historicalPricesIndex(companyId: string, page?: number, size?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<HistoricalPriceIndex> {
            return localVarFp.historicalPricesIndex(companyId, page, size, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for historicalPricesIndex operation in HistoricalPricesApi.
 * @export
 * @interface HistoricalPricesApiHistoricalPricesIndexRequest
 */
export interface HistoricalPricesApiHistoricalPricesIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof HistoricalPricesApiHistoricalPricesIndex
     */
    readonly companyId: string

    /**
     * 
     * @type {number}
     * @memberof HistoricalPricesApiHistoricalPricesIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof HistoricalPricesApiHistoricalPricesIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof HistoricalPricesApiHistoricalPricesIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof HistoricalPricesApiHistoricalPricesIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * HistoricalPricesApi - object-oriented interface
 * @export
 * @class HistoricalPricesApi
 * @extends {BaseAPI}
 */
export class HistoricalPricesApi extends BaseAPI {
    /**
     * 
     * @summary get historical prices for a company
     * @param {HistoricalPricesApiHistoricalPricesIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalPricesApi
     */
    public historicalPricesIndex(requestParameters: HistoricalPricesApiHistoricalPricesIndexRequest, options?: AxiosRequestConfig) {
        return HistoricalPricesApiFp(this.configuration).historicalPricesIndex(requestParameters.companyId, requestParameters.page, requestParameters.size, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }
}

export type HistoricalPricesIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const HistoricalPricesIndexErrorCodes = [
    401,
];


