import { Codebooks } from './codebooks.types';

const Items = {
  all_time: 'all_time',
  this_month: 'this_month',
  last_six_months: 'last_six_months',
  last_year: 'last_year',
} as const;

export const CbLastLogin = {
  code: Codebooks.LAST_LOGIN,
  items: Items,
};
