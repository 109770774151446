import styled from 'styled-components';

import { TextCurrency } from '@npm/core/ui/components/atoms/Typography';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sm}px;
  margin: 0;
  color: ${({ theme }) => theme.color.general.typography.tertiary};
  background-color: ${({ theme }) => theme.color.general.layout.zero};
  font-size: ${({ theme }) => theme.typography.size.xxs}px;
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  border-bottom: none;
  padding: ${({ theme }) => theme.spacing.sm}px;

  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.xs}px;
  }
`;

export const Total = styled.div`
  display: flex;
  gap: 0 ${({ theme }) => theme.spacing.xs}px;
  font-size: ${({ theme }) => theme.typography.size.xxs}px;
  margin-top: 2px;
`;

export const LeftCol = styled.div`
  text-align: right;
  flex: 1;

  ${Total} {
    justify-content: end;
  }
`;

export const RightCol = styled.div`
  text-align: left;
  flex: 1;

  ${Total} {
    flex-direction: row-reverse;
    justify-content: start;
  }
`;

export const RightColCentered = styled(RightCol)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LeftColCentered = styled(LeftCol)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Divider = styled.div`
  flex: 0 0 1px;
  background-color: ${({ theme }) => theme.color.general.borders.secondary};
`;

export const Currency = styled(TextCurrency)`
  margin: 0;
`;
