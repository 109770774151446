import React from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';

import {
  type WithSupportTicketType,
  ZendeskContactButton,
} from '../../../app/zendesk';
import {
  IndividualTaxIdForm,
  TaxIdReadOnly,
  useIndividualTaxIdForm,
} from '../../../onboardingAndSubmissionSteps/TaxId';
import { type PostOnboardingContext } from '../../PostOnboarding.types';
import { StepContainer } from '../../StepContainer';

const { StepState } = Wizard;

type Props = {
  stepProps: Wizard.StepProps;
  personId: number;
} & WithSupportTicketType;

export const IndividualTaxIdPostOnboarding = ({
  stepProps,
  personId,
  zendeskTicketGroupId,
}: Props) => {
  const { stepState } = useWizardStore(
    (s: WizardStore<PostOnboardingContext>) => ({
      stepState: s.activeStepState,
    })
  );

  const { form, data, isLoading, isUpdating, handleSubmit, isExemptHidden } =
    useIndividualTaxIdForm(personId, {
      onUpdate: () => {
        Notification.success({
          message: 'You have updated your Account Individual Tax ID',
        });
        stepProps.handleStepComplete();
      },
    });

  return (
    <StepContainer
      stepProps={stepProps}
      isLoading={isLoading}
      isUpdating={isUpdating}
      handleSubmit={handleSubmit}
      zendeskTicketGroupId={zendeskTicketGroupId}
    >
      {stepState === StepState.EDIT && (
        <IndividualTaxIdForm
          form={form}
          isExemptHidden={isExemptHidden}
          handleValuesChange={() => stepProps.setFormHasUnsavedChanges(true)}
        />
      )}
      {stepState === StepState.FILLED && (
        <>
          <Margin bottom="lg">
            <Alert
              type="info"
              message={
                <div>
                  If you have entered the wrong information,{' '}
                  <ZendeskContactButton
                    zendeskTicketGroupId={zendeskTicketGroupId}
                  >
                    please contact us
                  </ZendeskContactButton>
                </div>
              }
              closable
            />
          </Margin>
          <TaxIdReadOnly
            taxId={data?.tax_id}
            exempt={data?.tax_id_exempt}
            isIndividual
          />
        </>
      )}
    </StepContainer>
  );
};
