/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Stakeholder } from '../model';
// @ts-ignore
import { StakeholderCreateRequestContract } from '../model';
// @ts-ignore
import { StakeholderIndex } from '../model';
/**
 * StakeholdersApi - axios parameter creator
 * @export
 */
export const StakeholdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates a stakeholder under the given issuer_id
         * @param {string} issuerId 
         * @param {StakeholderCreateRequestContract} stakeholderCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakeholderCreate: async (issuerId: string, stakeholderCreateRequestContract: StakeholderCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('stakeholderCreate', 'issuerId', issuerId)
            // verify required parameter 'stakeholderCreateRequestContract' is not null or undefined
            assertParamExists('stakeholderCreate', 'stakeholderCreateRequestContract', stakeholderCreateRequestContract)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/stakeholders`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stakeholderCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lists all stakeholders at a given issuer
         * @param {string} issuerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakeholderIndex: async (issuerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('stakeholderIndex', 'issuerId', issuerId)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/stakeholders`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary individual stakeholder by id and stakeholder party id
         * @param {string} issuerId 
         * @param {string} stakeholderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakeholderShow: async (issuerId: string, stakeholderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('stakeholderShow', 'issuerId', issuerId)
            // verify required parameter 'stakeholderId' is not null or undefined
            assertParamExists('stakeholderShow', 'stakeholderId', stakeholderId)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/stakeholders/{stakeholder_id}`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)))
                .replace(`{${"stakeholder_id"}}`, encodeURIComponent(String(stakeholderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StakeholdersApi - functional programming interface
 * @export
 */
export const StakeholdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StakeholdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates a stakeholder under the given issuer_id
         * @param {string} issuerId 
         * @param {StakeholderCreateRequestContract} stakeholderCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stakeholderCreate(issuerId: string, stakeholderCreateRequestContract: StakeholderCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stakeholderCreate(issuerId, stakeholderCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lists all stakeholders at a given issuer
         * @param {string} issuerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stakeholderIndex(issuerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StakeholderIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stakeholderIndex(issuerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary individual stakeholder by id and stakeholder party id
         * @param {string} issuerId 
         * @param {string} stakeholderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stakeholderShow(issuerId: string, stakeholderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Stakeholder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stakeholderShow(issuerId, stakeholderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StakeholdersApi - factory interface
 * @export
 */
export const StakeholdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StakeholdersApiFp(configuration)
    return {
        /**
         * 
         * @summary creates a stakeholder under the given issuer_id
         * @param {string} issuerId 
         * @param {StakeholderCreateRequestContract} stakeholderCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakeholderCreate(issuerId: string, stakeholderCreateRequestContract: StakeholderCreateRequestContract, options?: any): AxiosPromise<void> {
            return localVarFp.stakeholderCreate(issuerId, stakeholderCreateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lists all stakeholders at a given issuer
         * @param {string} issuerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakeholderIndex(issuerId: string, options?: any): AxiosPromise<StakeholderIndex> {
            return localVarFp.stakeholderIndex(issuerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary individual stakeholder by id and stakeholder party id
         * @param {string} issuerId 
         * @param {string} stakeholderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakeholderShow(issuerId: string, stakeholderId: string, options?: any): AxiosPromise<Stakeholder> {
            return localVarFp.stakeholderShow(issuerId, stakeholderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for stakeholderCreate operation in StakeholdersApi.
 * @export
 * @interface StakeholdersApiStakeholderCreateRequest
 */
export interface StakeholdersApiStakeholderCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof StakeholdersApiStakeholderCreate
     */
    readonly issuerId: string

    /**
     * 
     * @type {StakeholderCreateRequestContract}
     * @memberof StakeholdersApiStakeholderCreate
     */
    readonly stakeholderCreateRequestContract: StakeholderCreateRequestContract
}

/**
 * Request parameters for stakeholderIndex operation in StakeholdersApi.
 * @export
 * @interface StakeholdersApiStakeholderIndexRequest
 */
export interface StakeholdersApiStakeholderIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof StakeholdersApiStakeholderIndex
     */
    readonly issuerId: string
}

/**
 * Request parameters for stakeholderShow operation in StakeholdersApi.
 * @export
 * @interface StakeholdersApiStakeholderShowRequest
 */
export interface StakeholdersApiStakeholderShowRequest {
    /**
     * 
     * @type {string}
     * @memberof StakeholdersApiStakeholderShow
     */
    readonly issuerId: string

    /**
     * 
     * @type {string}
     * @memberof StakeholdersApiStakeholderShow
     */
    readonly stakeholderId: string
}

/**
 * StakeholdersApi - object-oriented interface
 * @export
 * @class StakeholdersApi
 * @extends {BaseAPI}
 */
export class StakeholdersApi extends BaseAPI {
    /**
     * 
     * @summary creates a stakeholder under the given issuer_id
     * @param {StakeholdersApiStakeholderCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StakeholdersApi
     */
    public stakeholderCreate(requestParameters: StakeholdersApiStakeholderCreateRequest, options?: AxiosRequestConfig) {
        return StakeholdersApiFp(this.configuration).stakeholderCreate(requestParameters.issuerId, requestParameters.stakeholderCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lists all stakeholders at a given issuer
     * @param {StakeholdersApiStakeholderIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StakeholdersApi
     */
    public stakeholderIndex(requestParameters: StakeholdersApiStakeholderIndexRequest, options?: AxiosRequestConfig) {
        return StakeholdersApiFp(this.configuration).stakeholderIndex(requestParameters.issuerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary individual stakeholder by id and stakeholder party id
     * @param {StakeholdersApiStakeholderShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StakeholdersApi
     */
    public stakeholderShow(requestParameters: StakeholdersApiStakeholderShowRequest, options?: AxiosRequestConfig) {
        return StakeholdersApiFp(this.configuration).stakeholderShow(requestParameters.issuerId, requestParameters.stakeholderId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type StakeholderCreateErrorTypes =
    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const StakeholderCreateErrorCodes = [
    422,
];

export type StakeholderIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const StakeholderIndexErrorCodes = [
];

export type StakeholderShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const StakeholderShowErrorCodes = [
];


