/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DataSyncSuccess } from '../model';
/**
 * FolderDocumentsCreateSyncApi - axios parameter creator
 * @export
 */
export const FolderDocumentsCreateSyncApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary triggers data sync for folder where the document asside when created
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderDocumentsCreateSyncShow: async (documentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('folderDocumentsCreateSyncShow', 'documentId', documentId)
            const localVarPath = changeUrl(`/api/folder-documents/{document_id}/create-syncs`)
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary triggers data sync for folder where the document asside when it is deleted
         * @param {number} documentId 
         * @param {string} folderId 
         * @param {string} documentOwnerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderDocumentsDestroySyncShow: async (documentId: number, folderId: string, documentOwnerType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('folderDocumentsDestroySyncShow', 'documentId', documentId)
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('folderDocumentsDestroySyncShow', 'folderId', folderId)
            // verify required parameter 'documentOwnerType' is not null or undefined
            assertParamExists('folderDocumentsDestroySyncShow', 'documentOwnerType', documentOwnerType)
            const localVarPath = changeUrl(`/api/folder-documents/{document_id}/destroy-syncs`)
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (folderId !== undefined) {
                localVarQueryParameter['folder_id'] = folderId;
            }

            if (documentOwnerType !== undefined) {
                localVarQueryParameter['document_owner_type'] = documentOwnerType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FolderDocumentsCreateSyncApi - functional programming interface
 * @export
 */
export const FolderDocumentsCreateSyncApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FolderDocumentsCreateSyncApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary triggers data sync for folder where the document asside when created
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderDocumentsCreateSyncShow(documentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSyncSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderDocumentsCreateSyncShow(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary triggers data sync for folder where the document asside when it is deleted
         * @param {number} documentId 
         * @param {string} folderId 
         * @param {string} documentOwnerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderDocumentsDestroySyncShow(documentId: number, folderId: string, documentOwnerType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSyncSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderDocumentsDestroySyncShow(documentId, folderId, documentOwnerType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FolderDocumentsCreateSyncApi - factory interface
 * @export
 */
export const FolderDocumentsCreateSyncApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FolderDocumentsCreateSyncApiFp(configuration)
    return {
        /**
         * 
         * @summary triggers data sync for folder where the document asside when created
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderDocumentsCreateSyncShow(documentId: number, options?: any): AxiosPromise<DataSyncSuccess> {
            return localVarFp.folderDocumentsCreateSyncShow(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary triggers data sync for folder where the document asside when it is deleted
         * @param {number} documentId 
         * @param {string} folderId 
         * @param {string} documentOwnerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderDocumentsDestroySyncShow(documentId: number, folderId: string, documentOwnerType: string, options?: any): AxiosPromise<DataSyncSuccess> {
            return localVarFp.folderDocumentsDestroySyncShow(documentId, folderId, documentOwnerType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for folderDocumentsCreateSyncShow operation in FolderDocumentsCreateSyncApi.
 * @export
 * @interface FolderDocumentsCreateSyncApiFolderDocumentsCreateSyncShowRequest
 */
export interface FolderDocumentsCreateSyncApiFolderDocumentsCreateSyncShowRequest {
    /**
     * 
     * @type {number}
     * @memberof FolderDocumentsCreateSyncApiFolderDocumentsCreateSyncShow
     */
    readonly documentId: number
}

/**
 * Request parameters for folderDocumentsDestroySyncShow operation in FolderDocumentsCreateSyncApi.
 * @export
 * @interface FolderDocumentsCreateSyncApiFolderDocumentsDestroySyncShowRequest
 */
export interface FolderDocumentsCreateSyncApiFolderDocumentsDestroySyncShowRequest {
    /**
     * 
     * @type {number}
     * @memberof FolderDocumentsCreateSyncApiFolderDocumentsDestroySyncShow
     */
    readonly documentId: number

    /**
     * 
     * @type {string}
     * @memberof FolderDocumentsCreateSyncApiFolderDocumentsDestroySyncShow
     */
    readonly folderId: string

    /**
     * 
     * @type {string}
     * @memberof FolderDocumentsCreateSyncApiFolderDocumentsDestroySyncShow
     */
    readonly documentOwnerType: string
}

/**
 * FolderDocumentsCreateSyncApi - object-oriented interface
 * @export
 * @class FolderDocumentsCreateSyncApi
 * @extends {BaseAPI}
 */
export class FolderDocumentsCreateSyncApi extends BaseAPI {
    /**
     * 
     * @summary triggers data sync for folder where the document asside when created
     * @param {FolderDocumentsCreateSyncApiFolderDocumentsCreateSyncShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderDocumentsCreateSyncApi
     */
    public folderDocumentsCreateSyncShow(requestParameters: FolderDocumentsCreateSyncApiFolderDocumentsCreateSyncShowRequest, options?: AxiosRequestConfig) {
        return FolderDocumentsCreateSyncApiFp(this.configuration).folderDocumentsCreateSyncShow(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary triggers data sync for folder where the document asside when it is deleted
     * @param {FolderDocumentsCreateSyncApiFolderDocumentsDestroySyncShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderDocumentsCreateSyncApi
     */
    public folderDocumentsDestroySyncShow(requestParameters: FolderDocumentsCreateSyncApiFolderDocumentsDestroySyncShowRequest, options?: AxiosRequestConfig) {
        return FolderDocumentsCreateSyncApiFp(this.configuration).folderDocumentsDestroySyncShow(requestParameters.documentId, requestParameters.folderId, requestParameters.documentOwnerType, options).then((request) => request(this.axios, this.basePath));
    }
}

export type FolderDocumentsCreateSyncShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const FolderDocumentsCreateSyncShowErrorCodes = [
];

export type FolderDocumentsDestroySyncShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const FolderDocumentsDestroySyncShowErrorCodes = [
];


