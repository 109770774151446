import { useEffect } from 'react';
import { Form } from 'antd';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { InlineInputNumber } from '@npm/core/ui/components/atoms/Input';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { DrawerSection } from '@npm/core/ui/components/molecules/DrawerSection';
import {
  MAXIMUM_PRICE_FRACTION_DIGITS,
  MINIMUM_PRICE_FRACTION_DIGITS,
} from '@npm/core/ui/utils/formatters';
import {
  type IssuerEntityAggregate,
  type VisibilityCode,
  CbVisibility,
} from '@npm/data-access';

import {
  type OrderSizeType,
  getReversedOrderSizeType,
  OrderSizeInput,
  OrderSizeToggle,
  reverseOrderSize,
} from '../../../../order';

import { EstLastPreferredPPS } from './EstLastPreferredPPS';
import {
  getMinimumQuantityRules,
  getPpsRules,
  getQuantityRules,
} from './OrderSize.utils';

import * as S from './OrderSize.styles';

type FormValues = {
  minimumQuantity: number;
  quantity: number;
  pricePerShare: number;
};

type Props = {
  activeAction: 'buy' | 'sell';
  sizeType: OrderSizeType;
  onSizeTypeChange: () => void;
  company: IssuerEntityAggregate;
  visibility: VisibilityCode | undefined;
  remainingQuantity?: number;
  showSizeExtraRow?: boolean;
  showHeader?: boolean;
  showMinimumQuantity?: boolean;
};

export const OrderSize = ({
  activeAction,
  sizeType,
  onSizeTypeChange,
  company,
  visibility,
  remainingQuantity,
  showSizeExtraRow = true,
  showHeader = true,
  showMinimumQuantity = true,
}: Props) => {
  const form = Form.useFormInstance<FormValues>();
  const minimumQuantity = Form.useWatch('minimumQuantity', form);
  const quantity = Form.useWatch('quantity', form);
  const pricePerShare = Form.useWatch('pricePerShare', form);

  const isPricePerShareRequired = visibility === CbVisibility.items.external;

  const reverseSizeType = getReversedOrderSizeType(sizeType);

  useEffect(() => {
    // Revalidate quantity and minimumQuantity if sizeType has changed
    // because the rules depend on sizeType
    if (minimumQuantity) {
      form.validateFields(['minimumQuantity']);
    }
    if (quantity) {
      form.validateFields(['quantity']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeType]);

  return (
    <DrawerSection
      iconName={showHeader ? 'clipboard-text' : undefined}
      title={showHeader ? 'Order Terms' : ''}
      headerAction={
        <OrderSizeToggle
          sizeType={sizeType}
          onChange={() => onSizeTypeChange()}
        />
      }
      content={
        <>
          <FormItem
            name="quantity"
            rules={getQuantityRules(
              activeAction,
              sizeType,
              true,
              pricePerShare,
              remainingQuantity
            )}
            marginBottom="sm"
          >
            <OrderSizeInput sizeType={sizeType} label="Size" required={true} />
          </FormItem>
          {showMinimumQuantity && (
            <FormItem
              name="minimumQuantity"
              dependencies={['quantity']}
              rules={getMinimumQuantityRules(form, sizeType, false)}
              marginBottom="sm"
            >
              <OrderSizeInput
                sizeType={sizeType}
                label="Min. Size"
                required={false}
              />
            </FormItem>
          )}
          <FormItem
            name="pricePerShare"
            rules={getPpsRules(isPricePerShareRequired)}
            extra={
              company?.venus_id != null && (
                <EstLastPreferredPPS
                  venusId={company.venus_id}
                  inputValue={pricePerShare}
                />
              )
            }
            marginBottom="sm"
          >
            <InlineInputNumber
              label="Price Per Share"
              placeholder="0.00"
              currency="USD"
              required={isPricePerShareRequired}
            />
          </FormItem>
          {showSizeExtraRow && (
            <Margin top="lg" bottom="lg">
              <S.DataRow justify="space-between" align="flex-end">
                <Text size="sm" colorVariant="secondary">
                  Size ({reverseSizeType})
                </Text>
                <Text.Quantity
                  value={[
                    reverseOrderSize(
                      reverseSizeType,
                      minimumQuantity,
                      pricePerShare
                    ),
                    reverseOrderSize(reverseSizeType, quantity, pricePerShare),
                  ]}
                  unit={reverseSizeType}
                  formatOptions={{
                    maximumFractionDigits:
                      reverseSizeType === 'Shares'
                        ? 0
                        : MAXIMUM_PRICE_FRACTION_DIGITS,
                    minimumFractionDigits:
                      reverseSizeType === 'Shares'
                        ? 0
                        : MINIMUM_PRICE_FRACTION_DIGITS,
                  }}
                  size="sm"
                />
              </S.DataRow>
            </Margin>
          )}
        </>
      }
    />
  );
};
