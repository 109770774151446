import React, { Fragment } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { ErrorSkeleton } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { InfiniteScroll } from '@npm/core/ui/components/atoms/InfiniteScroll';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { useNotificationIndexInfinite } from '@npm/data-access';

import { NotificationCard } from './components/NotificationCard/NotificationCard';

type Props = {
  notificationCategory: string | undefined;
};

export const NotificationList = withErrorBoundary(
  ({ notificationCategory }: Props) => {
    const { data, isFetching, fetchNextPage, hasNextPage } =
      useNotificationIndexInfinite({
        notificationCategory,
        page: 1,
        size: 10,
        sortByNewest: true,
      });

    if (data?.pages?.[0] && !data.pages[0].pagination?.size) {
      return <NoDataArea title="You have 0 notifications" />;
    }

    return (
      <InfiniteScroll
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        isFetching={isFetching}
        loader={<Skeleton.Base />}
      >
        <Flex justify="flex-start" direction="column">
          {data?.pages.map(page => (
            <Fragment key={page.pagination.page}>
              {page.notifications?.map(notification => (
                <NotificationCard
                  key={notification?.id}
                  notification={notification}
                />
              ))}
            </Fragment>
          ))}
        </Flex>
      </InfiniteScroll>
    );
  },
  {
    FallbackComponent: ErrorSkeleton,
  }
);
