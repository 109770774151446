export const ANIMATION_SETTINGS = {
  initial: { opacity: 0, y: 50 },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: -50,
    height: 0,
    transition: {
      duration: 0.3,
    },
  },
  transition: { duration: 0.5, ease: [0.25, 0.1, 0.25, 1] },
};
