import React from 'react';

import { ReadOnly } from '@npm/core/ui/components/molecules/ReadOnly';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type OrganizationAggregate } from '@npm/data-access';

import { prepareReadonlyData } from './FormationDocumentReadOnly.utils';

type Props = {
  data: OrganizationAggregate;
  accountName: string;
};

export const FormationDocumentReadOnly = ({ data, accountName }: Props) => {
  const { isDesktop } = useBreakpoints();

  return <ReadOnly items={prepareReadonlyData(data, accountName, isDesktop)} />;
};
