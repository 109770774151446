import React, { type FC } from 'react';

import { VALIDATION_RULES } from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { getFullName, parseDateToString } from '@npm/core/ui/utils/formatters';

import { useUserContextStore } from '../../../auth/user/context';

import * as S from './SignatureFormContent.styles';

type Props = {
  variant: 'entity' | 'individual';
};
export const SignatureFormContent: FC<Props> = ({ variant }) => {
  const user = useUserContextStore(store => store.user);

  const fullLegalName = getFullName(
    user?.person?.first,
    user?.person?.last,
    user?.person?.middle
  );

  return (
    <>
      <Text size="sm" colorVariant="primary" marginBottom="lg">
        I understand that The Nasdaq Private Market, LLC (&quot;NPM&quot;) and
        Company will be relying on the accuracy and completeness of my responses
        to the foregoing questions and I represent, warrant and covenant to NPM
        and the Company as follows:
      </Text>
      <Text size="sm" colorVariant="primary" as="ol" marginBottom="lg">
        <li>
          The answers to the previous questions are complete and correct and may
          be relied upon by NPM and the Company in determining whether I am
          qualified to view and purchase securities in transactions that exempt
          from registration under the U.S. Securities Act of 1933; and
        </li>
        <li>
          I will comply with other applicable regulatory requirements; and will
          notify NPM immediately of any material change in any statement made
          herein or any event resulting in the omission of any statement
          required to be made herein.
        </li>
      </Text>
      <Text size="sm" colorVariant="primary" marginBottom="xl">
        I further acknowledge that any information submitted to NPM herein may
        be shared with the Company.
      </Text>
      <S.Wrapper>
        <S.FormItem
          name="signature"
          label="Signature of Authorized Person Acting on Account’s Behalf"
          rules={[
            VALIDATION_RULES.required(),
            VALIDATION_RULES.matchSpecificValue(
              fullLegalName,
              `Signature must match your full legal name "${fullLegalName}"`
            ),
          ]}
          validateTrigger="onSubmit"
          extra={`Your full legal name. Example "${fullLegalName}"`}
        >
          <Input placeholder="Signature" data-cy="authorizedPerson" />
        </S.FormItem>
        {variant === 'entity' && (
          <S.FormItem
            name="entity_rep_title"
            label="Title of Authorized Person"
            rules={[VALIDATION_RULES.required()]}
            extra="Example “Chief Executive Officer”"
          >
            <Input placeholder="Title" data-cy="title" />
          </S.FormItem>
        )}
      </S.Wrapper>
      <Text size="sm" colorVariant="primary">
        Dated {parseDateToString(new Date())}
      </Text>
    </>
  );
};
