import React, { useContext, useMemo, useState } from 'react';

import { UnlockPremiumModal } from './UnlockPremiumModal';

type UnlockPremiumContextType = {
  requestPremiumMembership: () => void;
};

export const UnlockPremiumContext =
  React.createContext<UnlockPremiumContextType>({
    requestPremiumMembership: null,
  });

type Props = {
  children: React.ReactElement;
  companyName?: string | undefined;
};

export const UnlockPremiumContextProvider = ({
  children,
  companyName,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const value = useMemo(() => {
    return {
      requestPremiumMembership: () => setIsModalOpen(true),
    };
  }, []);

  return (
    <UnlockPremiumContext.Provider value={value}>
      <UnlockPremiumModal
        open={isModalOpen}
        companyName={companyName}
        onCancel={() => setIsModalOpen(false)}
      />
      {children}
    </UnlockPremiumContext.Provider>
  );
};

export const useUnlockPremium = () => {
  const context = useContext(UnlockPremiumContext);
  if (context === undefined) {
    throw new Error(
      'useUnlockPremium must be used within a UnlockPremiumContext'
    );
  }
  return context?.requestPremiumMembership;
};
