import React from 'react';

import { Label } from '@npm/core/ui/components/atoms/Label';

export const InvestorOrStakeholderLabel = ({
  isInvestor,
}: {
  isInvestor: boolean;
}) => {
  return (
    <Label variant={isInvestor ? 'success' : 'error'} round>
      {isInvestor ? 'Investor' : 'Stakeholder'}
    </Label>
  );
};
