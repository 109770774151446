/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Mark to Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActionStatusResult } from '../model';
// @ts-ignore
import { EquityAssetCreateRequestContract } from '../model';
// @ts-ignore
import { EquityAssetIndex } from '../model';
// @ts-ignore
import { EquityAssetUpdateRequestContract } from '../model';
/**
 * EquityAssetsApi - axios parameter creator
 * @export
 */
export const EquityAssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list all equity assets for a specific company
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equityAssetCompaniesIndex: async (companyId: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('equityAssetCompaniesIndex', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/companies/{company_id}/equity_assets`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an equity asset
         * @param {EquityAssetCreateRequestContract} equityAssetCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equityAssetCreate: async (equityAssetCreateRequestContract: EquityAssetCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equityAssetCreateRequestContract' is not null or undefined
            assertParamExists('equityAssetCreate', 'equityAssetCreateRequestContract', equityAssetCreateRequestContract)
            const localVarPath = changeUrl(`/api/equity_assets/`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equityAssetCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all equity assets for a specific issuer
         * @param {number} issuerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equityAssetIssuersIndex: async (issuerId: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('equityAssetIssuersIndex', 'issuerId', issuerId)
            const localVarPath = changeUrl(`/api/issuers/{issuer_id}/equity_assets`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an equity asset
         * @param {number} equityAssetId 
         * @param {EquityAssetUpdateRequestContract} equityAssetUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equityAssetUpdate: async (equityAssetId: number, equityAssetUpdateRequestContract: EquityAssetUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equityAssetId' is not null or undefined
            assertParamExists('equityAssetUpdate', 'equityAssetId', equityAssetId)
            // verify required parameter 'equityAssetUpdateRequestContract' is not null or undefined
            assertParamExists('equityAssetUpdate', 'equityAssetUpdateRequestContract', equityAssetUpdateRequestContract)
            const localVarPath = changeUrl(`/api/equity_assets/{equity_asset_id}`)
                .replace(`{${"equity_asset_id"}}`, encodeURIComponent(String(equityAssetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equityAssetUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquityAssetsApi - functional programming interface
 * @export
 */
export const EquityAssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquityAssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary list all equity assets for a specific company
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equityAssetCompaniesIndex(companyId: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquityAssetIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equityAssetCompaniesIndex(companyId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an equity asset
         * @param {EquityAssetCreateRequestContract} equityAssetCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equityAssetCreate(equityAssetCreateRequestContract: EquityAssetCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equityAssetCreate(equityAssetCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all equity assets for a specific issuer
         * @param {number} issuerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equityAssetIssuersIndex(issuerId: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquityAssetIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equityAssetIssuersIndex(issuerId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an equity asset
         * @param {number} equityAssetId 
         * @param {EquityAssetUpdateRequestContract} equityAssetUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equityAssetUpdate(equityAssetId: number, equityAssetUpdateRequestContract: EquityAssetUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equityAssetUpdate(equityAssetId, equityAssetUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EquityAssetsApi - factory interface
 * @export
 */
export const EquityAssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquityAssetsApiFp(configuration)
    return {
        /**
         * 
         * @summary list all equity assets for a specific company
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equityAssetCompaniesIndex(companyId: number, page?: number, size?: number, options?: any): AxiosPromise<EquityAssetIndex> {
            return localVarFp.equityAssetCompaniesIndex(companyId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an equity asset
         * @param {EquityAssetCreateRequestContract} equityAssetCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equityAssetCreate(equityAssetCreateRequestContract: EquityAssetCreateRequestContract, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.equityAssetCreate(equityAssetCreateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all equity assets for a specific issuer
         * @param {number} issuerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equityAssetIssuersIndex(issuerId: number, page?: number, size?: number, options?: any): AxiosPromise<EquityAssetIndex> {
            return localVarFp.equityAssetIssuersIndex(issuerId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an equity asset
         * @param {number} equityAssetId 
         * @param {EquityAssetUpdateRequestContract} equityAssetUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equityAssetUpdate(equityAssetId: number, equityAssetUpdateRequestContract: EquityAssetUpdateRequestContract, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.equityAssetUpdate(equityAssetId, equityAssetUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for equityAssetCompaniesIndex operation in EquityAssetsApi.
 * @export
 * @interface EquityAssetsApiEquityAssetCompaniesIndexRequest
 */
export interface EquityAssetsApiEquityAssetCompaniesIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof EquityAssetsApiEquityAssetCompaniesIndex
     */
    readonly companyId: number

    /**
     * 
     * @type {number}
     * @memberof EquityAssetsApiEquityAssetCompaniesIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof EquityAssetsApiEquityAssetCompaniesIndex
     */
    readonly size?: number
}

/**
 * Request parameters for equityAssetCreate operation in EquityAssetsApi.
 * @export
 * @interface EquityAssetsApiEquityAssetCreateRequest
 */
export interface EquityAssetsApiEquityAssetCreateRequest {
    /**
     * 
     * @type {EquityAssetCreateRequestContract}
     * @memberof EquityAssetsApiEquityAssetCreate
     */
    readonly equityAssetCreateRequestContract: EquityAssetCreateRequestContract
}

/**
 * Request parameters for equityAssetIssuersIndex operation in EquityAssetsApi.
 * @export
 * @interface EquityAssetsApiEquityAssetIssuersIndexRequest
 */
export interface EquityAssetsApiEquityAssetIssuersIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof EquityAssetsApiEquityAssetIssuersIndex
     */
    readonly issuerId: number

    /**
     * 
     * @type {number}
     * @memberof EquityAssetsApiEquityAssetIssuersIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof EquityAssetsApiEquityAssetIssuersIndex
     */
    readonly size?: number
}

/**
 * Request parameters for equityAssetUpdate operation in EquityAssetsApi.
 * @export
 * @interface EquityAssetsApiEquityAssetUpdateRequest
 */
export interface EquityAssetsApiEquityAssetUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof EquityAssetsApiEquityAssetUpdate
     */
    readonly equityAssetId: number

    /**
     * 
     * @type {EquityAssetUpdateRequestContract}
     * @memberof EquityAssetsApiEquityAssetUpdate
     */
    readonly equityAssetUpdateRequestContract: EquityAssetUpdateRequestContract
}

/**
 * EquityAssetsApi - object-oriented interface
 * @export
 * @class EquityAssetsApi
 * @extends {BaseAPI}
 */
export class EquityAssetsApi extends BaseAPI {
    /**
     * 
     * @summary list all equity assets for a specific company
     * @param {EquityAssetsApiEquityAssetCompaniesIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquityAssetsApi
     */
    public equityAssetCompaniesIndex(requestParameters: EquityAssetsApiEquityAssetCompaniesIndexRequest, options?: AxiosRequestConfig) {
        return EquityAssetsApiFp(this.configuration).equityAssetCompaniesIndex(requestParameters.companyId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an equity asset
     * @param {EquityAssetsApiEquityAssetCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquityAssetsApi
     */
    public equityAssetCreate(requestParameters: EquityAssetsApiEquityAssetCreateRequest, options?: AxiosRequestConfig) {
        return EquityAssetsApiFp(this.configuration).equityAssetCreate(requestParameters.equityAssetCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all equity assets for a specific issuer
     * @param {EquityAssetsApiEquityAssetIssuersIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquityAssetsApi
     */
    public equityAssetIssuersIndex(requestParameters: EquityAssetsApiEquityAssetIssuersIndexRequest, options?: AxiosRequestConfig) {
        return EquityAssetsApiFp(this.configuration).equityAssetIssuersIndex(requestParameters.issuerId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an equity asset
     * @param {EquityAssetsApiEquityAssetUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquityAssetsApi
     */
    public equityAssetUpdate(requestParameters: EquityAssetsApiEquityAssetUpdateRequest, options?: AxiosRequestConfig) {
        return EquityAssetsApiFp(this.configuration).equityAssetUpdate(requestParameters.equityAssetId, requestParameters.equityAssetUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type EquityAssetCompaniesIndexErrorTypes =

    | {
        status: 404;
        data: EquityAssetIndex;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EquityAssetCompaniesIndexErrorCodes = [
    404,
];

export type EquityAssetCreateErrorTypes =

    | {
        status: 409;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EquityAssetCreateErrorCodes = [
    409,
    422,
];

export type EquityAssetIssuersIndexErrorTypes =

    | {
        status: 404;
        data: EquityAssetIndex;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EquityAssetIssuersIndexErrorCodes = [
    404,
];

export type EquityAssetUpdateErrorTypes =

    | {
        status: 404;
        data: EquityAssetIndex;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EquityAssetUpdateErrorCodes = [
    404,
    422,
];


