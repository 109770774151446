import React, { type ComponentProps, type ReactNode, useState } from 'react';

import { Icon } from '../../atoms/Icon';
import { Text } from '../../atoms/Typography';

import * as S from './ShowMore.styles';

export type ButtonVariant = 'inline' | 'blockWithChevron';

type Props = {
  renderCollapsed: ReactNode;
  renderExpanded: ReactNode;
  collapsible?: boolean;
  defaultExpanded?: boolean;
  buttonVariant?: ButtonVariant;
};

export const ShowMore = ({
  renderCollapsed,
  renderExpanded,
  collapsible = true,
  defaultExpanded = false,
  buttonVariant = 'inline',
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const getShowMoreButton = () => {
    const textProps: Partial<ComponentProps<typeof Text>> = {
      onClick: () => setIsExpanded(v => !v),
      size: 'sm',
      color: 'info',
    };

    if (!collapsible) return null;

    return isExpanded ? (
      <Text {...textProps}>
        {buttonVariant === 'blockWithChevron' && (
          <Icon name="chevron-up" size="xs" />
        )}{' '}
        Show Less
      </Text>
    ) : (
      <Text {...textProps}>
        {buttonVariant === 'blockWithChevron' && (
          <Icon name="chevron-down" size="xs" />
        )}
        Show More
      </Text>
    );
  };

  return (
    <>
      {isExpanded || !collapsible ? renderExpanded : renderCollapsed}
      <S.Wrapper $variant={buttonVariant}>{getShowMoreButton()}</S.Wrapper>
    </>
  );
};
