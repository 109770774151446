import React, { type ComponentProps } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { GetStarted as BaseGetStarted } from '@npm/core/ui/components/molecules/GetStarted';

import { DisabledTextForBroker } from '../../DisabledTextForBroker';

export const IndividualAccreditationGetStartedContent = () => {
  return (
    <>
      <Text colorVariant="secondary">
        Investors in private company securities must be, at minimum, an
        accredited investor within the meaning of Rule 501 of Regulation D.
        Please complete this step to establish this account’s status as an
        accredited investor.
      </Text>
      <Text colorVariant="secondary">
        <Text weight="bold" colorVariant="secondary" as="span">
          Note:
        </Text>{' '}
        This step is not required if you are participating in a company
        sponsored tender offer.
      </Text>
    </>
  );
};

export const IndividualAccreditationGetStarted = ({
  onGetStarted,
  isActingAsBroker,
}: ComponentProps<typeof BaseGetStarted> & {
  isActingAsBroker?: boolean;
}) => {
  return (
    <BaseGetStarted
      title="Accredited Investor Status"
      onGetStarted={!isActingAsBroker ? onGetStarted : null}
      content={
        !isActingAsBroker ? (
          <IndividualAccreditationGetStartedContent />
        ) : (
          <DisabledTextForBroker />
        )
      }
    />
  );
};
