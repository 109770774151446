import { type IssuerEntityAggregate, type VenusApi } from '@npm/data-access';

import type { CompanyDataSectionKey } from '../../CompanyOverview.types';
import { NotCoveredBanner } from '../NotCoveredBanner';

type Props = {
  sectionKey: CompanyDataSectionKey;
  heading?: React.ReactNode;
  renderContent: () => React.ReactNode;
  hasData: boolean;
  issuerEntity: IssuerEntityAggregate | undefined;
  companyProfile: VenusApi.CompanyProfile | undefined;
};

export const CoveredSection = ({
  sectionKey,
  heading,
  renderContent,
  hasData,
  issuerEntity,
  companyProfile,
}: Props) => {
  if (!companyProfile || (!hasData && companyProfile.covered)) {
    return null;
  }

  return (
    <div id={sectionKey}>
      {heading}
      {hasData && companyProfile.covered ? (
        renderContent()
      ) : (
        <NotCoveredBanner
          issuerEntity={issuerEntity}
          companyProfile={companyProfile}
        />
      )}
    </div>
  );
};
