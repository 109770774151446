import React, { useState } from 'react';
import { type InputNumberProps } from 'antd';

import {
  formatNumToAddComma,
  MINIMUM_PRICE_FRACTION_DIGITS,
  parseFormattedNumberWithComma,
} from '../../../utils/formatters';
import { TextCurrency } from '../Typography';

import { getSupportedInputMode } from './InputNumber';

import * as S from './InlineInputNumber.styles';

type Props = InputNumberProps & {
  label?: React.ReactNode;
  currency?: string;
  readOnly?: boolean;
};

const LONG_ADDON_TEXT_WIDTH = 56;

const getAddonAfterWidth = (text: string | undefined) => {
  if (!text) return undefined;
  if (text?.length === 6) return LONG_ADDON_TEXT_WIDTH;
  if (text?.length > 6) return text.length * 8;
  return undefined;
};

export const InlineInputNumber = ({
  label,
  currency,
  prefix,
  placeholder = currency ? '0.00' : '0',
  inputMode = placeholder === '0.00' ? 'decimal' : 'numeric',
  required,
  onFocus,
  onBlur,
  readOnly,
  addonAfter,
  value,
  ...props
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const priceFormatter = v =>
    formatNumToAddComma(v, {
      minimumFractionDigits: MINIMUM_PRICE_FRACTION_DIGITS,
    });

  const isBlurredPrice = currency && !isFocused;

  const addonAfterText = typeof addonAfter === 'string' ? addonAfter : currency;

  return (
    <S.StyledInputNumber
      // force re-render so the formatter function is updated
      value={isFocused ? value?.toString() : value}
      controls={false}
      formatter={value => {
        if (value == null) return '';
        return isBlurredPrice
          ? priceFormatter(value)
          : formatNumToAddComma(value);
      }}
      parser={parseFormattedNumberWithComma}
      prefix={
        <>
          {label ?? prefix}
          {required === false && (
            <S.SecondaryLabel>(Optional)</S.SecondaryLabel>
          )}
        </>
      }
      type="text"
      pattern="[0-9,.]*"
      required={required}
      inputMode={getSupportedInputMode(inputMode)}
      $addonAfterOverrideWidth={getAddonAfterWidth(addonAfterText)}
      addonAfter={
        addonAfterText && (
          <S.CurrencyAddon className={'npm-input-number-currency-addon'}>
            <TextCurrency>{addonAfterText}</TextCurrency>
          </S.CurrencyAddon>
        )
      }
      placeholder={placeholder}
      onFocus={e => {
        setIsFocused(true);
        onFocus?.(e);
      }}
      onBlur={e => {
        setIsFocused(false);
        onBlur?.(e);
      }}
      $readOnly={readOnly}
      readOnly={readOnly}
      data-dd-action-name={`Number Input - ${label}`}
      {...props}
    />
  );
};
