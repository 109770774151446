// "contains an invalid value(s): 409as. Allowed options: tape_d_price, bids_or_asks, primary_rounds, fmvs, mtms, financing_activity, issued_shares, waterfall, deal_terms, source_documents, profile_data"
export enum DataAvailabilities {
  TapeDPrice = 'tape_d_price',
  BidsOrAsks = 'bids_or_asks',
  PrimaryRounds = 'primary_rounds',
  Four09AValuations = 'fmvs',
  MutualFundMarks = 'mtms',
  FinancingActivity = 'financing_activity',
  IssuedShares = 'issued_shares',
  Waterfall = 'waterfall',
  DealTerms = 'deal_terms',
  SourceDocuments = 'source_documents',
  Profile = 'profile_data',
}

export const dataAvailabilityLabels = {
  [DataAvailabilities.TapeDPrice]: 'Tape D™ Price',
  [DataAvailabilities.BidsOrAsks]: 'Historical Bids/Asks',
  [DataAvailabilities.PrimaryRounds]: 'Primary Rounds',
  [DataAvailabilities.Four09AValuations]: '409A Valuations',
  [DataAvailabilities.MutualFundMarks]: 'Mutual Fund Marks',
  [DataAvailabilities.FinancingActivity]: 'Financing History',
  [DataAvailabilities.IssuedShares]: 'Issued Shares',
  [DataAvailabilities.Waterfall]: 'Waterfall Analysis',
  [DataAvailabilities.DealTerms]: 'Deal Terms',
  [DataAvailabilities.SourceDocuments]: 'Source Documents',
  [DataAvailabilities.Profile]: 'Profile',
};

export const dataAvailabilitiesGroupings = [
  [
    DataAvailabilities.TapeDPrice,
    DataAvailabilities.BidsOrAsks,
    DataAvailabilities.PrimaryRounds,
    DataAvailabilities.Four09AValuations,
    DataAvailabilities.MutualFundMarks,
  ],
  [DataAvailabilities.FinancingActivity],
  [
    DataAvailabilities.IssuedShares,
    DataAvailabilities.Waterfall,
    DataAvailabilities.DealTerms,
  ],
  [DataAvailabilities.SourceDocuments],
  [DataAvailabilities.Profile],
];
