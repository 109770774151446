import React from 'react';
import { createPortal } from 'react-dom';

import * as S from './ActionBar.styles';

// TODO: this can be removed once StoreLoader is removed (NFE-262)
// this is used in places (i.e. post-onboarding)
// where we want to avoid the empty space between the times
// when one ActionBar is unmounted and another one is mounted
export const ActionBarPlaceholder: React.FC = () => {
  const root = document.getElementById('root');
  if (!root) return null;
  return createPortal(<S.Placeholder />, root);
};
