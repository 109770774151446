import { type RoleType, CbRoleType } from '@npm/data-access';

import { PERMISSION_TO_USER_ROLE_MAP } from './permissions.config';
import type {
  PermissionContext,
  PermissionType,
  RbacFn,
} from './permissions.types';

export const isUserPermitted = (permission: PermissionType, role: RoleType) => {
  // TODO: if !role, it means user is in investor WS, all-accounts mode
  // clarify what the permissions should be if user is in all-accounts mode (apply 'ALL_ACCOUNTS' permissions for now)
  return !!PERMISSION_TO_USER_ROLE_MAP[permission]?.includes(
    role || CbRoleType.items.ALL_ACCOUNTS
  );
};
export const performChecks = (
  permissionsChecks: RbacFn[],
  userContext: PermissionContext
) => permissionsChecks.every(check => check(userContext));
