import styled from 'styled-components';

export const CompanyContainer = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing.sm}px;
  align-items: center;

  && .ant-skeleton-paragraph {
    height: 18px; // fine-tuning vertical alignment
  }

  & > *:first-child {
    margin-right: 6px;
  }
`;
