import { type InfiniteData } from '@tanstack/react-query';

import { usePreviousValueIfNewIsUndefined } from './usePreviousValueIfNewIsUndefined';

type Data = {
  pagination?: {
    page?: number;
    size?: number;
    total_records?: number;
  };
};
export const useTotalRecords = <T extends Data>(
  data: T | InfiniteData<T> | undefined
) => {
  return usePreviousValueIfNewIsUndefined(
    (data as T)?.pagination?.total_records ??
      (data as InfiniteData<T>)?.pages?.[0]?.pagination?.total_records
  );
};
