import { type ComponentProps } from 'react';
import { type DefaultTheme } from 'styled-components';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { PageHeader } from '@npm/core/ui/components/molecules/PageHeader';

import { useUserContextStore } from '../../auth/user/context';
import type { OrderSizeType } from '../order.types';

import * as S from './OrderSizeToggle.styles';

export type Props = {
  sizeType: OrderSizeType;
  onChange: (sizeType: OrderSizeType) => void;
  disabled?: boolean;
  textProps?: Pick<
    ComponentProps<typeof Text>,
    'color' | 'colorVariant' | 'size'
  >;
  horizontalPadding?: keyof DefaultTheme['spacing'];
};

export const OrderSizeToggle = ({
  sizeType,
  onChange,
  disabled = false,
  textProps = { color: 'info', colorVariant: 'primary', size: 'sm' },
  horizontalPadding,
}: Props) => {
  return (
    <S.ToggleButton
      variant="text"
      onClick={() => {
        onChange?.(sizeType === 'Shares' ? 'USD' : 'Shares');
      }}
      disabled={disabled}
      icon={<Icon name="arrows-exchange-horizontal" size="md" />}
      $horizontalPadding={horizontalPadding}
      size="sm"
    >
      <Text
        {...textProps}
        {...(disabled ? { color: 'general', colorVariant: 'muted' } : {})}
      >
        Size ({sizeType})
      </Text>
    </S.ToggleButton>
  );
};

export const OrderSizeToggleGlobal = (
  props: Pick<Props, 'textProps' | 'disabled' | 'horizontalPadding'>
) => {
  const sizeType = useUserContextStore(store => store.sizeType);
  const toggleSizeType = useUserContextStore(store => store.toggleSizeType);

  return (
    <OrderSizeToggle
      sizeType={sizeType}
      onChange={() => toggleSizeType()}
      {...props}
    />
  );
};

export const OrderSizeTogglePageHeader = (
  props: Pick<Props, 'textProps' | 'disabled'>
) => {
  const sizeType = useUserContextStore(store => store.sizeType);
  const toggleSizeType = useUserContextStore(store => store.toggleSizeType);

  return (
    <PageHeader.Button
      variant="outline"
      onClick={toggleSizeType}
      icon={<Icon name="arrows-exchange-horizontal" size="md" />}
      disabled={props.disabled === true}
      style={{
        margin: 2, // reserved for box-shadow when active
      }}
    >
      Size ({sizeType})
    </PageHeader.Button>
  );
};
