import { type ComponentProps } from 'react';
import { Grid } from 'antd';
import { type Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { type Modal } from '@npm/core/ui/components/molecules/Modal';
import {
  type ExtendedColumnType,
  TABLE_BREAKPOINTS,
} from '@npm/core/ui/components/molecules/Table';
import {
  type AccountSimple,
  type DocumentSimple,
  type Holding,
} from '@npm/data-access';

import { usePermissions } from '../../../auth/permissions/usePermissions';
import { getEventInfo } from '../../../program/program.utils';
import { getHoldingItem, isSpvHolding } from '../../Holdings.utils';
import { type HoldingsList } from '../HoldingsList';

import {
  EXTRA_EXPANDED_ROWS,
  getEditButtons,
  getOfferButton,
  getStatusChangeButtons,
} from './HoldingsTable.utils';

export type HoldingColumnType = ExtendedColumnType<
  Holding & { accountWithoutLink: AccountSimple }
>;

export const useHoldingColumns = ({
  config,
  onDocumentClick,
  openModal,
}: {
  onDocumentClick: (doc: DocumentSimple) => void;
  openModal?: (props?: ComponentProps<typeof Modal>) => void;
  config?: ComponentProps<typeof HoldingsList>['config'];
}): HoldingColumnType[] => {
  const breakpoint = Grid.useBreakpoint();

  const { canWrite, BRO_ACCOUNT_MANAGER } = usePermissions();
  const canEditHoldings = canWrite || BRO_ACCOUNT_MANAGER;

  if (config?.event) {
    return getSellHoldingsColumns(config, onDocumentClick);
  } else {
    return getHoldingColumns(breakpoint, !canEditHoldings, {
      onDocumentClick,
      config,
      openModal,
    });
  }
};

export const getHoldingColumns = (
  _breakpoint: Partial<Record<Breakpoint, boolean>>,
  notAuthorized: boolean,
  {
    onDocumentClick,
    openModal,
    config = {},
  }: {
    onDocumentClick: (doc: DocumentSimple) => void;
    openModal?: (props?: ComponentProps<typeof Modal>) => void;
    config?: ComponentProps<typeof HoldingsList>['config'];
  }
): HoldingColumnType[] => {
  const {
    onStatusChangeButtonClick,
    accountWithoutLink,
    showIssuance,
    showAccount,
    showVerifiedOwnership = true,
    showProofOfOwnership = true,
    showStatus = true,
    showRemainingQuantity = false,
    showPreVerified = false,
    showTimestamps = true,
    onEditClick,
    onDeleteClick,
    onEnterIOIClick,
    getHoldingStateTooltip,
  } = config;

  return [
    {
      ...getHoldingItem('unique_id')({
        onEditHolding: onEditClick,
      }),
      responsive: TABLE_BREAKPOINTS.MOBILE,
    },
    {
      ...getHoldingItem('issuer_entity')(),
      responsive: TABLE_BREAKPOINTS.MOBILE,
      hidden: showIssuance,
    },
    {
      ...getHoldingItem(
        accountWithoutLink ? 'accountWithoutLink' : 'account'
      )(),
      responsive: TABLE_BREAKPOINTS.TABLET_SM,
      hidden: !showAccount,
    },
    {
      ...getHoldingItem('asset')(),
      responsive: onStatusChangeButtonClick
        ? TABLE_BREAKPOINTS.TABLET
        : TABLE_BREAKPOINTS.DESKTOP_SM,
    },
    {
      ...getHoldingItem('quantity')(),
      responsive: TABLE_BREAKPOINTS.DESKTOP,
    },
    {
      ...getHoldingItem('remaining_quantity')(),
      responsive: TABLE_BREAKPOINTS.TABLET_SM,
      hidden: !showRemainingQuantity,
    },
    {
      ...getHoldingItem('vested_qty')(),
      responsive: TABLE_BREAKPOINTS.DESKTOP,
    },
    {
      ...getHoldingItem('registered_name')(),
      responsive: showPreVerified
        ? TABLE_BREAKPOINTS.NONE
        : TABLE_BREAKPOINTS.DESKTOP_XL,
    },
    {
      ...getHoldingItem('pre_verified')(),
      responsive: TABLE_BREAKPOINTS.DESKTOP,
      hidden: !showPreVerified,
    },
    {
      ...getHoldingItem('state')({
        onShowInstructions: openModal,
        getTooltip: holding => getHoldingStateTooltip?.(holding),
        statusAlign: 'flex-end',
      }),
      hidden: !showVerifiedOwnership || !showStatus,
      responsive: TABLE_BREAKPOINTS.MOBILE,
      className: 'state',
    },
    {
      ...getHoldingItem('proof_of_ownership_document')({
        onClick: onDocumentClick,
        onEditHolding: onEditClick,
      }),
      responsive: TABLE_BREAKPOINTS.NONE,
      hidden: (_, { asset }) =>
        isSpvHolding(asset?.type?.code) || !showProofOfOwnership,
    },
    {
      // The same thing as above, but with a different title for SPV holdings
      ...getHoldingItem('proof_of_ownership_document')({
        onClick: onDocumentClick,
        onEditHolding: onEditClick,
      }),
      title: 'Subscription Agreement',
      responsive: TABLE_BREAKPOINTS.NONE,
      hidden: (_, { asset }) =>
        !isSpvHolding(asset?.type?.code) || !showProofOfOwnership,
    },
    getEditButtons(onEditClick, onDeleteClick, onEnterIOIClick, notAuthorized),
    getStatusChangeButtons(onStatusChangeButtonClick, onEditClick),
    ...EXTRA_EXPANDED_ROWS,
    {
      ...getHoldingItem('issuance')(),
      responsive: TABLE_BREAKPOINTS.NONE,
      hidden: !showIssuance,
    },
    {
      ...getHoldingItem('created_at')(),
      responsive: TABLE_BREAKPOINTS.NONE,
      hidden: !showTimestamps,
    },
    {
      ...getHoldingItem('updated_at')(),
      responsive: TABLE_BREAKPOINTS.NONE,
      hidden: !showTimestamps,
    },
  ] satisfies HoldingColumnType[];
};

export const getSellHoldingsColumns = (
  {
    onOfferClick,
    event,
    orders,
    showAssetType,
    showProofOfOwnership = true,
    showVerifiedOwnership = true,
    showRemainingQuantity = false,
    showStatus = true,
    onEditClick,
  }: ComponentProps<typeof HoldingsList>['config'] = {},
  onDocumentClick: (doc: DocumentSimple) => void
): HoldingColumnType[] => {
  const isTender = getEventInfo(event).isTender;

  return [
    {
      ...getHoldingItem('unique_id')({
        onEditHolding: onEditClick,
      }),
      responsive: TABLE_BREAKPOINTS.MOBILE,
    },
    {
      ...getHoldingItem('asset')(),
      responsive: TABLE_BREAKPOINTS.DESKTOP,
      hidden: !showAssetType,
    },
    {
      ...getHoldingItem('quantity')(),
      responsive: TABLE_BREAKPOINTS.MOBILE,
    },
    {
      ...getHoldingItem('remaining_quantity')(),
      responsive: TABLE_BREAKPOINTS.TABLET_SM,
      hidden: event.order_settings?.multiple_workflow
        ? false
        : !showRemainingQuantity,
    },
    {
      ...getHoldingItem('vested_qty')(),
      responsive: TABLE_BREAKPOINTS.MOBILE,
    },
    {
      ...getHoldingItem('offer_price')(),
      hidden: !isTender,
      responsive: TABLE_BREAKPOINTS.TABLET_SM,
    },
    {
      ...getHoldingItem('registered_name')(),
      responsive: TABLE_BREAKPOINTS.DESKTOP_XL,
    },
    {
      ...getHoldingItem('proof_of_ownership_document')({
        onClick: onDocumentClick,
        onEditHolding: onEditClick,
      }),
      responsive: TABLE_BREAKPOINTS.DESKTOP_XL,
      width: 250,
      hidden: !showProofOfOwnership,
    },
    {
      ...getHoldingItem('state')(),
      responsive: TABLE_BREAKPOINTS.MOBILE,
      className: 'state',
      hidden: !showVerifiedOwnership || !showStatus,
    },
    getOfferButton({
      onOfferClick,
      event,
      orders,
    }),
    ...EXTRA_EXPANDED_ROWS,
  ] as HoldingColumnType[];
};
