import {
  type AgreementIndex,
  type Holding,
  type MatchIndex,
  CbAgreementDocumentSigner,
  CbHoldingState,
  CbMatchState,
} from '@npm/data-access';

export const transactionAgreementState = {
  awaitingBuyerToSign: 'awaitingBuyerToSign',
  awaitingSellerToSign: 'awaitingSellerToSign',
  pendingIssuerReview: 'pendingIssuerReview',
  pendingIssuerHoldingVerification: 'pendingIssuerHoldingVerification',
  holdingNeedsCorrection: 'holdingNeedsCorrection',
  rofrBlocked: 'rofrBlocked',
} as const;

export type TransactionAgreementState = keyof typeof transactionAgreementState;

export const getTransactionAgreementState = (
  matches: MatchIndex,
  signedAgreementsData?: AgreementIndex,
  holdingData?: Holding
) => {
  const signedAsSeller = signedAgreementsData?.agreements?.find(agreement =>
    agreement?.signers?.find(
      signer =>
        signer?.type?.code === CbAgreementDocumentSigner.items.Seller &&
        signer?.signed
    )
  );

  const signedAsBuyer = signedAgreementsData?.agreements?.find(agreement =>
    agreement?.signers?.find(
      signer =>
        signer?.type?.code === CbAgreementDocumentSigner.items.Buyer &&
        signer?.signed
    )
  );

  if (
    matches?.matches[0]?.state?.code === CbMatchState.items.pending &&
    !!signedAsSeller
  ) {
    return transactionAgreementState.awaitingBuyerToSign;
  }

  if (
    matches?.matches[0]?.state?.code === CbMatchState.items.pending &&
    !!signedAsBuyer
  ) {
    return transactionAgreementState.awaitingSellerToSign;
  }

  if (
    matches?.matches[0]?.state?.code ===
    CbMatchState.items.pending_issuer_review
  ) {
    return transactionAgreementState.pendingIssuerReview;
  }

  if (
    matches?.matches[0]?.state?.code === CbMatchState.items.verify_holding &&
    holdingData?.state?.code !== CbHoldingState.items.needs_verification
  ) {
    return transactionAgreementState.pendingIssuerHoldingVerification;
  }

  if (
    matches?.matches[0]?.state?.code === CbMatchState.items.verify_holding &&
    holdingData?.state?.code === CbHoldingState.items.needs_verification
  ) {
    return transactionAgreementState.holdingNeedsCorrection;
  }

  if (matches?.matches[0]?.state?.code === CbMatchState.items.rofr_blocked) {
    return transactionAgreementState.rofrBlocked;
  }

  return null;
};
