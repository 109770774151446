/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Job } from '../model';
/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary job by ID
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminJobShow: async (jobId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('adminJobShow', 'jobId', jobId)
            const localVarPath = changeUrl(`/api/admin/jobs/{job_id}`)
                .replace(`{${"job_id"}}`, encodeURIComponent(String(jobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary job by ID
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobShow: async (jobId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('jobShow', 'jobId', jobId)
            const localVarPath = changeUrl(`/api/jobs/{job_id}`)
                .replace(`{${"job_id"}}`, encodeURIComponent(String(jobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary job by ID
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminJobShow(jobId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Job>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminJobShow(jobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary job by ID
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobShow(jobId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Job>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobShow(jobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobsApiFp(configuration)
    return {
        /**
         * 
         * @summary job by ID
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminJobShow(jobId: string, options?: any): AxiosPromise<Job> {
            return localVarFp.adminJobShow(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary job by ID
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobShow(jobId: string, options?: any): AxiosPromise<Job> {
            return localVarFp.jobShow(jobId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminJobShow operation in JobsApi.
 * @export
 * @interface JobsApiAdminJobShowRequest
 */
export interface JobsApiAdminJobShowRequest {
    /**
     * 
     * @type {string}
     * @memberof JobsApiAdminJobShow
     */
    readonly jobId: string
}

/**
 * Request parameters for jobShow operation in JobsApi.
 * @export
 * @interface JobsApiJobShowRequest
 */
export interface JobsApiJobShowRequest {
    /**
     * 
     * @type {string}
     * @memberof JobsApiJobShow
     */
    readonly jobId: string
}

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
    /**
     * 
     * @summary job by ID
     * @param {JobsApiAdminJobShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public adminJobShow(requestParameters: JobsApiAdminJobShowRequest, options?: AxiosRequestConfig) {
        return JobsApiFp(this.configuration).adminJobShow(requestParameters.jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary job by ID
     * @param {JobsApiJobShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public jobShow(requestParameters: JobsApiJobShowRequest, options?: AxiosRequestConfig) {
        return JobsApiFp(this.configuration).jobShow(requestParameters.jobId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AdminJobShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AdminJobShowErrorCodes = [
];

export type JobShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const JobShowErrorCodes = [
];


