import React, { type ComponentProps } from 'react';

import { usePagination } from '@npm/core/ui/hooks/pagination/usePagination';
import {
  type HoldingApiHoldingIndexRequest,
  useHoldingIndex,
} from '@npm/data-access';

import { HoldingsTable } from './HoldingsTable';

type Props = {
  secondmarket: boolean | undefined;
  variables: HoldingApiHoldingIndexRequest;
  setVariables: React.Dispatch<
    React.SetStateAction<HoldingApiHoldingIndexRequest>
  >;
  isFilterApplied?: boolean;
} & Omit<
  ComponentProps<typeof HoldingsTable>,
  'pagination' | 'onChange' | 'holdings' | 'isLoading'
>;

export const HoldingsTableContainer = ({
  secondmarket,
  variables,
  setVariables,
  isFilterApplied,
  ...props
}: Props) => {
  const { data, isFetching } = useHoldingIndex({ ...variables, secondmarket });

  const { updatePaginationVariables, pagination } = usePagination('holdings', {
    variables,
    setVariables,
    paginationData: data?.pagination,
  });

  return (
    <HoldingsTable
      {...props}
      holdings={data?.holdings}
      isLoading={isFetching}
      pagination={pagination}
      isFilterApplied={isFilterApplied}
      onChange={updatePaginationVariables}
    />
  );
};
