import { useMemo } from 'react';

import type { UserRoleAggregate } from '@npm/data-access';

import { useUserRole } from '../context/userRole.context';
import { type UserRoleDefinition } from '../userRole.types';

export const useCurrentRole = () => {
  const userRole = useUserRole();

  return useMemo(() => {
    const subRole = userRole?.subRole as unknown as UserRoleDefinition;
    return subRole as UserRoleAggregate;
  }, [userRole]);
};
