import React from 'react';
import { type SwitchProps } from 'antd';

import { Text } from '../Typography';

import * as S from './Switch.styles';

type Props = Omit<SwitchProps, 'title'> & {
  titleAfter?: boolean;
  title?: React.ReactNode;
  id?: string; // id is needed if title is provided
};

export const Switch = ({ title, titleAfter = false, id, ...rest }: Props) => {
  if (title) {
    return (
      <S.Container>
        {!titleAfter && (
          <Text as="label" htmlFor={id}>
            {title}
          </Text>
        )}
        <S.Switch id={id} {...rest} />
        {titleAfter && (
          <Text as="label" htmlFor={id}>
            {title}
          </Text>
        )}
      </S.Container>
    );
  }
  return <S.Switch {...rest} />;
};
