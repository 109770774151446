import { Flex } from '@npm/core/ui/components/atoms/common';

import * as S from './CompanyDataSectionLoader.styles';

export const CompanyDataSectionLegendLoader = () => {
  return (
    <Flex align={'center'} gap={'sm'}>
      <S.LegendAvatarLoader active paragraph={false} />
      <S.LegendTextLoader active paragraph={false} />
    </Flex>
  );
};
