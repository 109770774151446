/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalCompanyAssignmentCreateRequestContract } from '../model';
// @ts-ignore
import { InternalCompanyAssignmentDestroyRequestContract } from '../model';
// @ts-ignore
import { InternalCompanyAssignmentShow } from '../model';
// @ts-ignore
import { InternalTaskAssigneeCreateRequestContract } from '../model';
// @ts-ignore
import { InternalTaskAssigneeIndex } from '../model';
// @ts-ignore
import { TaskAssignee } from '../model';
/**
 * AssignmentApi - axios parameter creator
 * @export
 */
export const AssignmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list company assignees
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyAssignees: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyAssignees', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/task_assignees`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary assign a company to a user
         * @param {InternalCompanyAssignmentCreateRequestContract} internalCompanyAssignmentCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyAssignmentCreate: async (internalCompanyAssignmentCreateRequestContract: InternalCompanyAssignmentCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'internalCompanyAssignmentCreateRequestContract' is not null or undefined
            assertParamExists('companyAssignmentCreate', 'internalCompanyAssignmentCreateRequestContract', internalCompanyAssignmentCreateRequestContract)
            const localVarPath = changeUrl(`/api/internal/company_assignments`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalCompanyAssignmentCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary unassign a company from a user
         * @param {InternalCompanyAssignmentDestroyRequestContract} internalCompanyAssignmentDestroyRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyAssignmentDestroy: async (internalCompanyAssignmentDestroyRequestContract: InternalCompanyAssignmentDestroyRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'internalCompanyAssignmentDestroyRequestContract' is not null or undefined
            assertParamExists('companyAssignmentDestroy', 'internalCompanyAssignmentDestroyRequestContract', internalCompanyAssignmentDestroyRequestContract)
            const localVarPath = changeUrl(`/api/internal/company_assignments/unassign`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalCompanyAssignmentDestroyRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list task assignees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskAssigneeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/internal/task_assignees/`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary details of an assignee
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskAssigneeShow: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taskAssigneeShow', 'id', id)
            const localVarPath = changeUrl(`/api/internal/task_assignees/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create upsert existing assignee
         * @param {InternalTaskAssigneeCreateRequestContract} [internalTaskAssigneeCreateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskAssignmentCreate: async (internalTaskAssigneeCreateRequestContract?: InternalTaskAssigneeCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/internal/task_assignees/`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalTaskAssigneeCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssignmentApi - functional programming interface
 * @export
 */
export const AssignmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssignmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary list company assignees
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyAssignees(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTaskAssigneeIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyAssignees(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary assign a company to a user
         * @param {InternalCompanyAssignmentCreateRequestContract} internalCompanyAssignmentCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyAssignmentCreate(internalCompanyAssignmentCreateRequestContract: InternalCompanyAssignmentCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCompanyAssignmentShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyAssignmentCreate(internalCompanyAssignmentCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary unassign a company from a user
         * @param {InternalCompanyAssignmentDestroyRequestContract} internalCompanyAssignmentDestroyRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyAssignmentDestroy(internalCompanyAssignmentDestroyRequestContract: InternalCompanyAssignmentDestroyRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyAssignmentDestroy(internalCompanyAssignmentDestroyRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list task assignees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskAssigneeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTaskAssigneeIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskAssigneeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary details of an assignee
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskAssigneeShow(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskAssignee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskAssigneeShow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create upsert existing assignee
         * @param {InternalTaskAssigneeCreateRequestContract} [internalTaskAssigneeCreateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskAssignmentCreate(internalTaskAssigneeCreateRequestContract?: InternalTaskAssigneeCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskAssignee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskAssignmentCreate(internalTaskAssigneeCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssignmentApi - factory interface
 * @export
 */
export const AssignmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssignmentApiFp(configuration)
    return {
        /**
         * 
         * @summary list company assignees
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyAssignees(companyId: number, options?: any): AxiosPromise<InternalTaskAssigneeIndex> {
            return localVarFp.companyAssignees(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary assign a company to a user
         * @param {InternalCompanyAssignmentCreateRequestContract} internalCompanyAssignmentCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyAssignmentCreate(internalCompanyAssignmentCreateRequestContract: InternalCompanyAssignmentCreateRequestContract, options?: any): AxiosPromise<InternalCompanyAssignmentShow> {
            return localVarFp.companyAssignmentCreate(internalCompanyAssignmentCreateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary unassign a company from a user
         * @param {InternalCompanyAssignmentDestroyRequestContract} internalCompanyAssignmentDestroyRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyAssignmentDestroy(internalCompanyAssignmentDestroyRequestContract: InternalCompanyAssignmentDestroyRequestContract, options?: any): AxiosPromise<void> {
            return localVarFp.companyAssignmentDestroy(internalCompanyAssignmentDestroyRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list task assignees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskAssigneeList(options?: any): AxiosPromise<InternalTaskAssigneeIndex> {
            return localVarFp.taskAssigneeList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary details of an assignee
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskAssigneeShow(id: number, options?: any): AxiosPromise<TaskAssignee> {
            return localVarFp.taskAssigneeShow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create upsert existing assignee
         * @param {InternalTaskAssigneeCreateRequestContract} [internalTaskAssigneeCreateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskAssignmentCreate(internalTaskAssigneeCreateRequestContract?: InternalTaskAssigneeCreateRequestContract, options?: any): AxiosPromise<TaskAssignee> {
            return localVarFp.taskAssignmentCreate(internalTaskAssigneeCreateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for companyAssignees operation in AssignmentApi.
 * @export
 * @interface AssignmentApiCompanyAssigneesRequest
 */
export interface AssignmentApiCompanyAssigneesRequest {
    /**
     * 
     * @type {number}
     * @memberof AssignmentApiCompanyAssignees
     */
    readonly companyId: number
}

/**
 * Request parameters for companyAssignmentCreate operation in AssignmentApi.
 * @export
 * @interface AssignmentApiCompanyAssignmentCreateRequest
 */
export interface AssignmentApiCompanyAssignmentCreateRequest {
    /**
     * 
     * @type {InternalCompanyAssignmentCreateRequestContract}
     * @memberof AssignmentApiCompanyAssignmentCreate
     */
    readonly internalCompanyAssignmentCreateRequestContract: InternalCompanyAssignmentCreateRequestContract
}

/**
 * Request parameters for companyAssignmentDestroy operation in AssignmentApi.
 * @export
 * @interface AssignmentApiCompanyAssignmentDestroyRequest
 */
export interface AssignmentApiCompanyAssignmentDestroyRequest {
    /**
     * 
     * @type {InternalCompanyAssignmentDestroyRequestContract}
     * @memberof AssignmentApiCompanyAssignmentDestroy
     */
    readonly internalCompanyAssignmentDestroyRequestContract: InternalCompanyAssignmentDestroyRequestContract
}

/**
 * Request parameters for taskAssigneeShow operation in AssignmentApi.
 * @export
 * @interface AssignmentApiTaskAssigneeShowRequest
 */
export interface AssignmentApiTaskAssigneeShowRequest {
    /**
     * 
     * @type {number}
     * @memberof AssignmentApiTaskAssigneeShow
     */
    readonly id: number
}

/**
 * Request parameters for taskAssignmentCreate operation in AssignmentApi.
 * @export
 * @interface AssignmentApiTaskAssignmentCreateRequest
 */
export interface AssignmentApiTaskAssignmentCreateRequest {
    /**
     * 
     * @type {InternalTaskAssigneeCreateRequestContract}
     * @memberof AssignmentApiTaskAssignmentCreate
     */
    readonly internalTaskAssigneeCreateRequestContract?: InternalTaskAssigneeCreateRequestContract
}

/**
 * AssignmentApi - object-oriented interface
 * @export
 * @class AssignmentApi
 * @extends {BaseAPI}
 */
export class AssignmentApi extends BaseAPI {
    /**
     * 
     * @summary list company assignees
     * @param {AssignmentApiCompanyAssigneesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public companyAssignees(requestParameters: AssignmentApiCompanyAssigneesRequest, options?: AxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).companyAssignees(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary assign a company to a user
     * @param {AssignmentApiCompanyAssignmentCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public companyAssignmentCreate(requestParameters: AssignmentApiCompanyAssignmentCreateRequest, options?: AxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).companyAssignmentCreate(requestParameters.internalCompanyAssignmentCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary unassign a company from a user
     * @param {AssignmentApiCompanyAssignmentDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public companyAssignmentDestroy(requestParameters: AssignmentApiCompanyAssignmentDestroyRequest, options?: AxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).companyAssignmentDestroy(requestParameters.internalCompanyAssignmentDestroyRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list task assignees
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public taskAssigneeList(options?: AxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).taskAssigneeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary details of an assignee
     * @param {AssignmentApiTaskAssigneeShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public taskAssigneeShow(requestParameters: AssignmentApiTaskAssigneeShowRequest, options?: AxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).taskAssigneeShow(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create upsert existing assignee
     * @param {AssignmentApiTaskAssignmentCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public taskAssignmentCreate(requestParameters: AssignmentApiTaskAssignmentCreateRequest = {}, options?: AxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).taskAssignmentCreate(requestParameters.internalTaskAssigneeCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type CompanyAssigneesErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompanyAssigneesErrorCodes = [
    401,
];

export type CompanyAssignmentCreateErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 422;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompanyAssignmentCreateErrorCodes = [
    401,
    422,
];

export type CompanyAssignmentDestroyErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompanyAssignmentDestroyErrorCodes = [
    401,
];

export type TaskAssigneeListErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const TaskAssigneeListErrorCodes = [
];

export type TaskAssigneeShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const TaskAssigneeShowErrorCodes = [
];

export type TaskAssignmentCreateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const TaskAssignmentCreateErrorCodes = [
];


