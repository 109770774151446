import styled from 'styled-components';

import { centerFlex } from '../../../../../utils/style';

export const Container = styled.div<{ opacity: number }>`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  ${centerFlex};
  background-color: ${({ theme }) => theme.color.general.layout.one};
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  color: ${({ theme }) => theme.color.general.typography.primary};
  padding: ${({ theme }) =>
    `${theme.spacing.sm - 1}px ${theme.spacing.sm}px ${
      theme.spacing.sm + 1
    }px ${theme.spacing.sm}px`};
  border-radius: 100%;
  box-shadow: ${({ theme }) => theme.shadow.level3};

  transition: opacity 0.3s ease;
  ${({ opacity }) =>
    `opacity: ${opacity}; ${opacity === 0 ? 'pointer-events: none;' : ''}`}
`;
