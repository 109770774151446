import create from 'zustand';

import { ZENDESK_PUBLIC_KEY } from './zendesk.env';

export type ZendeskStore = {
  isEnabled: boolean;
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  unreadMessageCount: number;
  setUnreadMessageCount: (value: number) => void;
  isInitialized: boolean;
  setInitialized: () => void;
};

export const createZendeskStore = create<ZendeskStore>((set, get) => ({
  isEnabled: !!ZENDESK_PUBLIC_KEY,
  isOpen: false,
  setOpen: value => {
    set({ isOpen: value });
  },
  unreadMessageCount: 0,
  setUnreadMessageCount: (value: number) => {
    set({
      unreadMessageCount: value,
    });
  },
  isInitialized: false,
  setInitialized: () => {
    set({ ...get(), isInitialized: true });
  },
}));
