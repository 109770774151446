/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  OrderItemsApi,
  OrderItemShowErrorCodes,
  OrderItemShowErrorTypes,
  OrderItemDestroyErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new OrderItemsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type OrderItemShowMethod = OrderItemsApi['orderItemShow'];
export type OrderItemShowResponseType = MethodResult<OrderItemShowMethod>;

export const useOrderItemShow = (
  variables: Parameters<OrderItemShowMethod>[0],
  config?: UseQueryConfig<
    OrderItemShowMethod,
    OrderItemShowErrorTypes
  >
) => {
  return useQuery<
    OrderItemShowMethod,
    OrderItemShowErrorTypes
  >(
    (...args) => api.orderItemShow(...args),
    OrderItemShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrderItemShow'],
        ...apiQueryConfig.OrderItemShow,
        ...config?.queryConfig
      },
    },
    'OrderItemShow',
  );
};

export const useOrderItemShowInfinite = (
  variables: Parameters<OrderItemShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    OrderItemShowMethod,
    OrderItemShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    OrderItemShowMethod,
    OrderItemShowErrorTypes
  >(
    (...args) => api.orderItemShow(...args),
    OrderItemShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrderItemShow', 'OrderItemShowInfinite'],
        ...apiQueryConfig.OrderItemShow,
        ...config?.queryConfig,
      },
    },
    'OrderItemShow',
  );
};

export const useOrderItemShowLazy = (baseOptions?: {
  variables?: Parameters<OrderItemShowMethod>[0],
  config?: UseQueryConfig<
    OrderItemShowMethod,
    OrderItemShowErrorTypes
  >
}) => {
  return useLazyQuery<
    OrderItemShowMethod,
    OrderItemShowErrorTypes
  >(
    (...args) => api.orderItemShow(...args),
    OrderItemShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['OrderItemShow'],
        ...apiQueryConfig.OrderItemShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'OrderItemShow',
  );
};


export type OrderItemDestroyMethod = OrderItemsApi['orderItemDestroy'];

export const useOrderItemDestroy = () => {
  return useMutation<OrderItemDestroyMethod>(
    (...args) => api.orderItemDestroy(...args),
    OrderItemDestroyErrorCodes,
    'OrderItemDestroy',
    '/api/order-items/{order_item_id}',
    'delete'
  );
};
