import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';

import { NoDataArea } from '../OrderPlacementForm.styles';

type Props = { onClick: () => void; isObo: boolean };

export const InsufficientPermission = ({ onClick, isObo }: Props) => {
  return (
    <NoDataArea
      outlined
      iconColor="error"
      heading="Insufficient Permission"
      title="It seems that the selected account does not have the necessary permissions to place an order. Please, in order to proceed select an account with ‘Admin & Signer’ or ‘Admin’ role."
      actionButton={
        isObo ? (
          <Button onClick={onClick} block>
            Switch Account
          </Button>
        ) : null
      }
    />
  );
};
