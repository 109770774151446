import styled, { css } from 'styled-components';

export const CardContent = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.sm}px;
    border-radius: ${theme.borderRadius.md}px;
    background-color: ${theme.color.general.layout.zero};
  `}
`;

export const CardRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding-bottom: ${theme.spacing.sm}px;
    gap: ${theme.spacing.sm}px;

    & > * {
      flex: 1;
    }
  `}
`;
