import { Codebooks } from './codebooks.types';

const Items = {
  basic: 'basic',
  trial: 'trial',
  premium: 'premium',
} as const;

export const CbSubscriptionPlan = {
  code: Codebooks.SUBSCRIPTION_PLAN,
  items: Items,
};
