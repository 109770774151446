/* eslint-disable no-template-curly-in-string */

/**
 * This file is not the nicest one, but it is on purpose.
 * It has the same exports as ./config.ts which is being replaced by this one during production build.
 * Why we have here only strings with the format ${ENV_NAME} is because our docker is build as ENV independent docker image.
 * On docker start all ENVs are replaced by their correct values.
 * And for that replacement we need to have specific format that envsubst command understand.
 */

export const ZENDESK_PUBLIC_KEY = '${NX_ZENDESK_PUBLIC_KEY}';
export const ZENDESK_API_URL = '${NX_ZENDESK_API_URL}';
export const ZENDESK_FIELD_EMAIL = parseInt('${NX_ZENDESK_FIELD_EMAIL}');
export const ZENDESK_FIELD_ACCOUNT_EXTERNAL_ID = parseInt(
  '${NX_ZENDESK_FIELD_ACCOUNT_EXTERNAL_ID}'
);
export const ZENDESK_FIELD_ACCOUNT_NAME = parseInt(
  '${NX_ZENDESK_FIELD_ACCOUNT_NAME}'
);
export const ZENDESK_FIELD_PAGE_URL = parseInt('${NX_ZENDESK_FIELD_PAGE_URL}');
export const ZENDESK_FIELD_WORKSTATION = parseInt(
  '${NX_ZENDESK_FIELD_WORKSTATION}'
);
export const ZENDESK_FIELD_SIZE_USD = parseInt('${NX_ZENDESK_FIELD_SIZE_USD}');
export const ZENDESK_FIELD_SIZE_SHARES = parseInt(
  '${NX_ZENDESK_FIELD_SIZE_SHARES}'
);
export const ZENDESK_FIELD_SIDE = parseInt('${NX_ZENDESK_FIELD_SIDE}');
export const ZENDESK_FIELD_PRICE_PER_SHARE = parseInt(
  '${NX_ZENDESK_FIELD_PRICE_PER_SHARE}'
);
export const ZENDESK_FIELD_COMPANY = parseInt('${NX_ZENDESK_FIELD_COMPANY}');
