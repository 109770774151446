import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { useHistory } from '@npm/core/ui/components/molecules/Link';

export const NotFoundContent = () => {
  const history = useHistory();

  return (
    <Button onClick={() => history.push('/')} variant="outline" blockOnMobile>
      Back Home
    </Button>
  );
};
