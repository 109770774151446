/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IssuancesStockIndex } from '../model';
// @ts-ignore
import { IssuancesStockShowAggregate } from '../model';
/**
 * StockIssuancesApi - axios parameter creator
 * @export
 */
export const StockIssuancesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get stock issuance with ocf_id
         * @param {string} issuerNpmGuid 
         * @param {string} ocfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockIssuance: async (issuerNpmGuid: string, ocfId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerNpmGuid' is not null or undefined
            assertParamExists('getStockIssuance', 'issuerNpmGuid', issuerNpmGuid)
            // verify required parameter 'ocfId' is not null or undefined
            assertParamExists('getStockIssuance', 'ocfId', ocfId)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_npm_guid}/issuances/stocks/{ocf_id}`)
                .replace(`{${"issuer_npm_guid"}}`, encodeURIComponent(String(issuerNpmGuid)))
                .replace(`{${"ocf_id"}}`, encodeURIComponent(String(ocfId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all stock issuances
         * @param {string} issuerNpmGuid 
         * @param {string} [stakeholderOcfId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStockIssuances: async (issuerNpmGuid: string, stakeholderOcfId?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerNpmGuid' is not null or undefined
            assertParamExists('listStockIssuances', 'issuerNpmGuid', issuerNpmGuid)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_npm_guid}/issuances/stocks`)
                .replace(`{${"issuer_npm_guid"}}`, encodeURIComponent(String(issuerNpmGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (stakeholderOcfId !== undefined) {
                localVarQueryParameter['stakeholder_ocf_id'] = stakeholderOcfId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockIssuancesApi - functional programming interface
 * @export
 */
export const StockIssuancesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockIssuancesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get stock issuance with ocf_id
         * @param {string} issuerNpmGuid 
         * @param {string} ocfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStockIssuance(issuerNpmGuid: string, ocfId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuancesStockShowAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStockIssuance(issuerNpmGuid, ocfId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all stock issuances
         * @param {string} issuerNpmGuid 
         * @param {string} [stakeholderOcfId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStockIssuances(issuerNpmGuid: string, stakeholderOcfId?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuancesStockIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStockIssuances(issuerNpmGuid, stakeholderOcfId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StockIssuancesApi - factory interface
 * @export
 */
export const StockIssuancesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockIssuancesApiFp(configuration)
    return {
        /**
         * 
         * @summary get stock issuance with ocf_id
         * @param {string} issuerNpmGuid 
         * @param {string} ocfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockIssuance(issuerNpmGuid: string, ocfId: string, options?: any): AxiosPromise<IssuancesStockShowAggregate> {
            return localVarFp.getStockIssuance(issuerNpmGuid, ocfId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all stock issuances
         * @param {string} issuerNpmGuid 
         * @param {string} [stakeholderOcfId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStockIssuances(issuerNpmGuid: string, stakeholderOcfId?: string, page?: number, size?: number, options?: any): AxiosPromise<IssuancesStockIndex> {
            return localVarFp.listStockIssuances(issuerNpmGuid, stakeholderOcfId, page, size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getStockIssuance operation in StockIssuancesApi.
 * @export
 * @interface StockIssuancesApiGetStockIssuanceRequest
 */
export interface StockIssuancesApiGetStockIssuanceRequest {
    /**
     * 
     * @type {string}
     * @memberof StockIssuancesApiGetStockIssuance
     */
    readonly issuerNpmGuid: string

    /**
     * 
     * @type {string}
     * @memberof StockIssuancesApiGetStockIssuance
     */
    readonly ocfId: string
}

/**
 * Request parameters for listStockIssuances operation in StockIssuancesApi.
 * @export
 * @interface StockIssuancesApiListStockIssuancesRequest
 */
export interface StockIssuancesApiListStockIssuancesRequest {
    /**
     * 
     * @type {string}
     * @memberof StockIssuancesApiListStockIssuances
     */
    readonly issuerNpmGuid: string

    /**
     * 
     * @type {string}
     * @memberof StockIssuancesApiListStockIssuances
     */
    readonly stakeholderOcfId?: string

    /**
     * 
     * @type {number}
     * @memberof StockIssuancesApiListStockIssuances
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof StockIssuancesApiListStockIssuances
     */
    readonly size?: number
}

/**
 * StockIssuancesApi - object-oriented interface
 * @export
 * @class StockIssuancesApi
 * @extends {BaseAPI}
 */
export class StockIssuancesApi extends BaseAPI {
    /**
     * 
     * @summary get stock issuance with ocf_id
     * @param {StockIssuancesApiGetStockIssuanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockIssuancesApi
     */
    public getStockIssuance(requestParameters: StockIssuancesApiGetStockIssuanceRequest, options?: AxiosRequestConfig) {
        return StockIssuancesApiFp(this.configuration).getStockIssuance(requestParameters.issuerNpmGuid, requestParameters.ocfId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all stock issuances
     * @param {StockIssuancesApiListStockIssuancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockIssuancesApi
     */
    public listStockIssuances(requestParameters: StockIssuancesApiListStockIssuancesRequest, options?: AxiosRequestConfig) {
        return StockIssuancesApiFp(this.configuration).listStockIssuances(requestParameters.issuerNpmGuid, requestParameters.stakeholderOcfId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }
}

export type GetStockIssuanceErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetStockIssuanceErrorCodes = [
];

export type ListStockIssuancesErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const ListStockIssuancesErrorCodes = [
    404,
];


