import React from 'react';

import { FullScreenLoading } from '@npm/core/ui/components/molecules/FullScreenLoading';

import { useInitialLoadingStore } from './initialLoading.store';

export const withInitialLoading = <T,>(Component: React.ComponentType<T>) => {
  return (hocProps: T) => {
    const isLoading = useInitialLoadingStore(store => store.isLoading);
    const loadingText = useInitialLoadingStore(store => store.loadingText);

    return (
      <>
        {isLoading && <FullScreenLoading loadingText={loadingText} />}
        <Component {...hocProps} />
      </>
    );
  };
};
