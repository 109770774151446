import React, { useState } from 'react';
import { Form } from 'antd';
import { Radio } from 'antd';
import { sanitize } from 'dompurify';
import { useTheme } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { ButtonCard } from '@npm/core/ui/components/atoms/ButtonCard';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { ProgressBar } from '@npm/core/ui/components/atoms/ProgressBar';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { WysiwygContent } from '@npm/core/ui/components/atoms/WysiwygContent';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { CbAccreditationLevel } from '@npm/data-access';

import { SignatureFormContent } from '../../components/SignatureModal';

import { type InfoModalType, InfoModal } from './InfoModal';
import { useQualifiedClientQuestionnaireForm } from './QualifiedClientQuestionnaireForm.hooks';

import { LabelFormatter } from './QualifiedClientModal.styles';

enum ModalSteps {
  QUESTION = 'qualified_client_question',
  SIGNATURE = 'signature',
}

const qualifiedClientModalSteps: ModalSteps[] = [
  ModalSteps.QUESTION,
  ModalSteps.SIGNATURE,
];

export type Props = {
  accreditationId: number;
  accountId: number;
  isOpen: boolean;
  closeModal: () => void;
};

export const QualifiedClientModal: React.FC<Props> = ({
  accreditationId,
  accountId,
  isOpen,
  closeModal,
}) => {
  const theme = useTheme();
  const { isMobile } = useBreakpoints();

  const { form, handleSubmit, data, isUpdating } =
    useQualifiedClientQuestionnaireForm(accreditationId, accountId, () => {
      closeModal();
      Notification.success({
        message: 'You have updated your Sophistication Level',
      });
    });

  const [activeStep, setActiveStep] = useState(ModalSteps.QUESTION);
  const [infoModalType, setInfoModalType] = useState<InfoModalType>();

  return (
    <Modal
      size="md"
      isFullScreen={isMobile}
      fullScreenProps={{
        isFooterFixed: true,
        noHeader: false,
        onClose: closeModal,
        titleHeadingProps: {
          style: { marginBottom: 0 },
        },
        icon: <Icon name="x-close" size="md" />,
      }}
      title="Qualified Client Questionnaire"
      open={isOpen}
      width={650}
      onCancel={closeModal}
      footer={
        <Flex
          justify="space-between"
          direction={isMobile ? 'column-reverse' : 'row'}
          gap={isMobile ? 'sm' : 'md'}
          style={isMobile ? { padding: theme.spacing.md } : undefined}
        >
          {activeStep === ModalSteps.SIGNATURE ? (
            <>
              <Button
                variant="text"
                icon={<Icon name="chevron-left" />}
                onClick={() => {
                  setActiveStep(ModalSteps.QUESTION);
                }}
              >
                Previous Question
              </Button>
              <Button
                variant="default"
                onClick={handleSubmit}
                loading={isUpdating}
              >
                Sign off Accreditation Statement
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="text"
                icon={<Icon name="x-close" />}
                onClick={closeModal}
              >
                Back to Accreditation
              </Button>
              <Button
                variant="default"
                onClick={() => {
                  setActiveStep(ModalSteps.SIGNATURE);
                }}
              >
                Submit & Sign electronically
              </Button>
            </>
          )}
        </Flex>
      }
    >
      <ProgressBar
        title={
          activeStep === ModalSteps.SIGNATURE
            ? 'Electronic Signature'
            : 'Describe your organization'
        }
        currentStep={qualifiedClientModalSteps.indexOf(activeStep) + 1}
        totalSteps={qualifiedClientModalSteps.length}
      />
      <Form form={form}>
        {activeStep === ModalSteps.QUESTION && (
          <>
            <Form.Item name={CbAccreditationLevel.items.qualified_client}>
              <Radio.Group>
                <Flex direction="column" gap="sm">
                  <Heading variant="h3" marginBottom="md">
                    The purpose of this questionnaire is to obtain information
                    relating to whether you are a &quot;Qualified Client&quot;
                    within the meaning of Rule 205-3(d)(1) of the Investment
                    Advisers Act of 1940. By checking the appropriate box, you
                    represent and warrant:
                  </Heading>
                  {data?.codebooks?.map(item => (
                    <ButtonCard
                      variant={'radio'}
                      label={
                        <LabelFormatter>
                          <WysiwygContent
                            dangerouslySetInnerHTML={{
                              __html: sanitize(item.name ?? '', {
                                RETURN_TRUSTED_TYPE: true,
                              }),
                            }}
                          />
                        </LabelFormatter>
                      }
                      value={item.code}
                      key={item.code}
                    />
                  ))}
                </Flex>
              </Radio.Group>
            </Form.Item>
            <Flex gap="md" direction={isMobile ? 'column' : 'row'}>
              <Button
                variant="text"
                onClick={() => setInfoModalType('qualifiedClient')}
                icon={<Icon name="info-circle" />}
                block
              >
                Learn more about Qualified Client
              </Button>
              <Button
                variant="text"
                onClick={() => setInfoModalType('lookThrough')}
                icon={<Icon name="info-circle" />}
                block
              >
                Learn more about Look-Through Entities
              </Button>
            </Flex>
          </>
        )}
        {activeStep === ModalSteps.SIGNATURE && (
          <SignatureFormContent variant={'entity'} />
        )}
        <InfoModal
          type={infoModalType}
          isOpen={!!infoModalType}
          onClose={() => setInfoModalType(undefined)}
        />
      </Form>
    </Modal>
  );
};
