import { type ComponentProps } from 'react';

import { type Modal } from '@npm/core/ui/components/molecules/Modal';
import { type TableVerticalRowType } from '@npm/core/ui/components/molecules/TableVertical';
import { type DocumentSimple, type Holding } from '@npm/data-access';

import {
  getHoldingItem,
  getIsHoldingAttributeVisible,
  isSpvHolding,
} from '../Holdings.utils';
import { type HoldingsList } from '../List';

const NUMBER_OF_ROWS_TO_SHOW = 3;

export const getHoldingRows = ({
  onDocumentClick,
  openModal,
  config,
}: {
  onDocumentClick?: (doc: DocumentSimple) => void;
  openModal?: (props?: ComponentProps<typeof Modal>) => void;
  config: ComponentProps<typeof HoldingsList>['config'];
}): TableVerticalRowType<Holding>[] => {
  const rows: TableVerticalRowType<Holding>[] = [
    {
      ...getHoldingItem(
        config.accountWithoutLink ? 'accountWithoutLink' : 'account'
      )(),
      hidden: !config.showAccount,
    },
    {
      ...getHoldingItem('offer_price')(),
      hidden: !config.showOfferPrice,
    },
    {
      ...getHoldingItem('strike_price')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'strike_price'),
    },
    {
      ...getHoldingItem('quantity')(),
      hidden: !config.showQuantity,
    },
    {
      ...getHoldingItem('vested_qty')(),
      hidden: !config.showVestedQuantity,
    },
    {
      ...getHoldingItem('remaining_quantity')(),
      hidden: !config.showRemainingQuantity,
    },
    {
      ...getHoldingItem('acquisition_date')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'acquisition_date'),
    },
    {
      ...getHoldingItem('cost_basis')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'cost_basis'),
    },
    {
      ...getHoldingItem('pre_verified')(),
      hidden: !config.showPreVerified,
    },
    {
      ...getHoldingItem('state')({
        onShowInstructions: openModal,
        getTooltip: holding => config.getHoldingStateTooltip?.(holding),
      }),
      hidden: !config.showVerifiedOwnership || !config.showStatus,
    },
    {
      ...getHoldingItem('registered_name')(),
    },
    {
      ...getHoldingItem('class')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'class') ||
        !config.showClass,
    },
    {
      ...getHoldingItem('proof_of_ownership_document')({
        onClick: onDocumentClick,
      }),
      hidden: (_, { asset }) =>
        isSpvHolding(asset?.type?.code) || !config.showProofOfOwnership,
    },
    {
      // The same thing as above, but with a different title for SPV holdings
      ...getHoldingItem('proof_of_ownership_document')({
        onClick: onDocumentClick,
      }),
      title: 'Subscription Agreement',
      hidden: (_, { asset }) =>
        !isSpvHolding(asset?.type?.code) || !config.showProofOfOwnership,
    },
    {
      ...getHoldingItem('grant_type')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'grant_type'),
    },
    {
      ...getHoldingItem('grant_date')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'grant_date'),
    },
    {
      ...getHoldingItem('plan')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'plan'),
    },
    {
      ...getHoldingItem('series')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'series'),
    },
    {
      ...getHoldingItem('expiration_date')(),
      hidden: (_, { asset }) =>
        !getIsHoldingAttributeVisible(asset?.type?.code, 'expiration_date'),
    },
    {
      ...getHoldingItem('issuance')(),
      hidden: !config.showIssuance,
    },
    {
      ...getHoldingItem('created_at')(),
      hidden: !config.showTimestamps,
    },
    {
      ...getHoldingItem('updated_at')(),
      hidden: !config.showTimestamps,
    },
  ];

  return rows.map(item => ({
    ...item,
    collapsed: (_, index) =>
      index >= (config?.numberOfRowsToShow ?? NUMBER_OF_ROWS_TO_SHOW),
  }));
};
