import React from 'react';
import { type ColorGroup, type DefaultTheme } from 'styled-components';

import { type IconProps } from '@npm/utils';

import { CypressDataIds } from '../../../constants';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Loader } from '../Loader';
import { Heading, Text } from '../Typography';

import * as S from './NoDataArea.styles';

type Props = {
  heading?: string | React.ReactNode;
  title?: React.ReactNode;
  withIcon?: boolean;
  icon?: React.ReactNode;
  iconName?: IconProps['name'];
  iconColor?: ColorGroup;
  onClick?: () => void;
  onClickText?: string;
  onClickIcon?: React.ReactNode;
  actionButton?: React.ReactElement<typeof Button> | null;
  loading?: boolean;
  loader?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  outlined?: boolean;
  verticalPadding?: keyof DefaultTheme['spacing'] | number;
};

export const NoDataArea = ({
  title,
  heading,
  onClick,
  onClickText,
  onClickIcon = <Icon name="plus" size="xs" />,
  actionButton = null,
  withIcon = true,
  icon,
  iconName = 'warning',
  iconColor,
  loading,
  loader = <Loader />,
  className,
  style,
  outlined = false,
  verticalPadding = 80,
}: Props) => {
  return (
    <S.Wrapper
      $outlined={outlined}
      className={className}
      style={style}
      data-cy={CypressDataIds.Components.NoDataArea.Container}
      $verticalPadding={verticalPadding}
    >
      {loading ? (
        loader
      ) : (
        <>
          {withIcon
            ? icon || (
                <S.IconWrap $color={iconColor ?? 'error'}>
                  <Icon name={iconName} size="sm" />
                </S.IconWrap>
              )
            : null}
          {heading && (
            <Heading variant="h2" marginBottom={title ? 'sm' : 'md'}>
              {heading}
            </Heading>
          )}
          {title && (
            <S.TextWrapper>
              <Text size="sm" as="div">
                {title}
              </Text>
            </S.TextWrapper>
          )}
          {(onClick || actionButton) && (
            <S.ButtonWrapper>
              {onClick && (
                <Button
                  onClick={onClick}
                  icon={onClickIcon}
                  data-cy={CypressDataIds.Components.NoDataArea.ActionButton}
                  blockOnMobile
                >
                  {onClickText}
                </Button>
              )}
              {actionButton}
            </S.ButtonWrapper>
          )}
        </>
      )}
    </S.Wrapper>
  );
};
