import { Codebooks } from './codebooks.types';

const Items = {
  beneficialOwner: 'beneficial_owner',
  controlPerson: 'control_person',
  both: 'beneficial_owner, control_person',
} as const;

export const CbBeneficialOwnerRole = {
  code: Codebooks.BENEFICIAL_OWNER_ROLE,
  items: Items,
};
