/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  ContactInformationApi,
  OrganizationContactInformationCreateErrorCodes,
  OrganizationContactInformationUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new ContactInformationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type OrganizationContactInformationCreateMethod = ContactInformationApi['organizationContactInformationCreate'];

export const useOrganizationContactInformationCreate = () => {
  return useMutation<OrganizationContactInformationCreateMethod>(
    (...args) => api.organizationContactInformationCreate(...args),
    OrganizationContactInformationCreateErrorCodes,
    'OrganizationContactInformationCreate',
    '/api/contact-informations',
    'post'
  );
};

export type OrganizationContactInformationUpdateMethod = ContactInformationApi['organizationContactInformationUpdate'];

export const useOrganizationContactInformationUpdate = () => {
  return useMutation<OrganizationContactInformationUpdateMethod>(
    (...args) => api.organizationContactInformationUpdate(...args),
    OrganizationContactInformationUpdateErrorCodes,
    'OrganizationContactInformationUpdate',
    '/api/contact-informations/{id}',
    'put'
  );
};
