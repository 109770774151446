import React from 'react';
import { matchPath, useLocation } from 'react-router';

import { Helmet } from '@npm/core/ui/components/atoms/Helmet';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useCurrentRoute } from '@npm/core/ui/hooks/useCurrentRoute';
import { type RouteTitleProps } from '@npm/core/ui/routes/router.types';
import { truncate } from '@npm/core/ui/utils/formatters';

export const OrderPageTitle = ({ raw }: RouteTitleProps) => {
  const { isMobile } = useBreakpoints();
  const location = useLocation();
  const currentRoute = useCurrentRoute();

  const match = matchPath(location.pathname, {
    path: currentRoute.route.path,
    exact: true,
    strict: false,
  });

  const { orderId } = match.params as { orderId: string };

  const title = `Order #${orderId}`;

  if (raw) {
    return <>{isMobile ? truncate(title) : title}</>;
  }

  return (
    <>
      <Helmet pageTitle={title}></Helmet>
      {title}
    </>
  );
};
