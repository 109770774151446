import styled from 'styled-components';

import { useBreakpoint } from '@npm/core/ui/utils/style';

export const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
    flex-direction: column;
  `}
`;
