import styled from 'styled-components';

import { useBreakpoint } from '../../../../../utils/style';
import { Card as CardBase } from '../../../../molecules/Card';

export const Card = styled(CardBase).attrs({ noContentPadding: true })`
  display: flex;
  padding: ${({ theme }) => theme.spacing.md}px;

  max-width: 730px;

  ${({ theme }) => useBreakpoint(theme).smDesktop`
    max-width: 626px;
  `}

  ${({ theme }) => useBreakpoint(theme).smTablet`
    max-width: revert;
  `}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;

  background-color: ${({ theme }) => theme.color.general.layout.two};
  color: ${({ theme }) => theme.color.info.typography.primary};
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
`;

export const CardContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xl}px;

  padding: ${({ theme }) => theme.spacing.md}px;

  ${({ theme }) => useBreakpoint(theme).smTablet`
    flex-direction: column;
  `}
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}px;
`;

export const ContentButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}px;

  ${({ theme }) => useBreakpoint(theme).smTablet`
    flex-direction: column;
  `}
`;
