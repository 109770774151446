import { Codebooks } from './codebooks.types';

const Items = {
  StockClassConversionRatioAdjustment:
    'TX_STOCK_CLASS_CONVERSION_RATIO_ADJUSTMENT',
  StockClassAuthorizedSharesAdjustment:
    'TX_STOCK_CLASS_AUTHORIZED_SHARES_ADJUSTMENT',
  StockClassSplit: 'TX_STOCK_CLASS_SPLIT',
  StockPlanPoolAdjustment: 'TX_STOCK_PLAN_POOL_ADJUSTMENT',
  ConvertibleAcceptance: 'TX_CONVERTIBLE_ACCEPTANCE',
  ConvertibleCancellation: 'TX_CONVERTIBLE_CANCELLATION',
  ConvertibleConversion: 'TX_CONVERTIBLE_CONVERSION',
  ConvertibleIssuance: 'TX_CONVERTIBLE_ISSUANCE',
  ConvertibleRetraction: 'TX_CONVERTIBLE_RETRACTION',
  ConvertibleTransfer: 'TX_CONVERTIBLE_TRANSFER',
  PlanSecurityAcceptance: 'TX_PLAN_SECURITY_ACCEPTANCE',
  PlanSecurityCancellation: 'TX_PLAN_SECURITY_CANCELLATION',
  PlanSecurityExercise: 'TX_PLAN_SECURITY_EXERCISE',
  PlanSecurityIssuance: 'TX_PLAN_SECURITY_ISSUANCE',
  PlanSecurityRelease: 'TX_PLAN_SECURITY_RELEASE',
  PlanSecurityRetraction: 'TX_PLAN_SECURITY_RETRACTION',
  PlanSecurityTransfer: 'TX_PLAN_SECURITY_TRANSFER',
  StockAcceptance: 'TX_STOCK_ACCEPTANCE',
  StockCancellation: 'TX_STOCK_CANCELLATION',
  StockConversion: 'TX_STOCK_CONVERSION',
  StockIssuance: 'TX_STOCK_ISSUANCE',
  StockReissuance: 'TX_STOCK_REISSUANCE',
  StockRepurchase: 'TX_STOCK_REPURCHASE',
  StockRetraction: 'TX_STOCK_RETRACTION',
  StockTransfer: 'TX_STOCK_TRANSFER',
  WarrantAcceptance: 'TX_WARRANT_ACCEPTANCE',
  WarrantCancellation: 'TX_WARRANT_CANCELLATION',
  WarrantExercise: 'TX_WARRANT_EXERCISE',
  WarrantIssuance: 'TX_WARRANT_ISSUANCE',
  WarrantRetraction: 'TX_WARRANT_RETRACTION',
  WarrantTransfer: 'TX_WARRANT_TRANSFER',
  VestingStart: 'TX_VESTING_START',
  VestingEvent: 'TX_VESTING_EVENT',
  VestingAcceleration: 'TX_VESTING_ACCELERATION',
} as const;

export type CbTransactionTypeCode = typeof Items[keyof typeof Items];

export const CbTransactionType = {
  code: Codebooks.TRANSACTION_TYPE,
  items: Items,
};
