import { useCallback } from 'react';
import { type ChartOptions } from 'chart.js';
import { merge } from 'lodash';
import { useTheme } from 'styled-components';

import { useCrosshair } from './useCrosshair';

export const useChartOptions = () => {
  const theme = useTheme();
  const crosshair = useCrosshair();

  return useCallback(
    (options: ChartOptions<'line'>) => {
      const defaultOptions: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        showLine: true,

        interaction: {
          mode: 'index',
          intersect: false,
        },

        color: theme.color.general.typography.secondary,
        borderColor: theme.color.general.borders.primary,
        backgroundColor: theme.color.general.layout.one,

        scales: {
          y: {
            ticks: {
              // y-axis labels
              color: theme.color.general.typography.secondary,
            },
            grid: {
              // horizontal grid lines
              color: theme.color.general.borders.primary,
            },
            border: {
              // outer border
              color: theme.color.general.borders.primary,
            },
          },
          x: {
            ticks: {
              // x-axis labels
              color: theme.color.general.typography.secondary,
            },
            grid: {
              // vertical grid lines
              color: theme.color.general.borders.primary,
            },
            border: {
              // outer border
              color: theme.color.general.borders.primary,
            },
          },
        },

        plugins: {
          ...crosshair,
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      };
      return merge(defaultOptions, options);
    },
    [theme, crosshair]
  );
};
