import type React from 'react';

const SCROLL_END_THRESHOLD = 5;

export const onPopupScroll = async (
  e: React.UIEvent,
  onReachedEnd: () => void
) => {
  const { scrollTop, offsetHeight, scrollHeight } = e.target as HTMLElement;

  const reachedEnd =
    scrollHeight - (scrollTop + offsetHeight) < SCROLL_END_THRESHOLD;

  if (reachedEnd) {
    onReachedEnd && onReachedEnd();
  }
};
