import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { WatchlistDataIds } from '@npm/core/ui/constants';
import { CbWorkstationType } from '@npm/data-access';

import {
  useCurrentAccount,
  useCurrentWorkstation,
} from '../../../../auth/user/role';

type Props = {
  isFilterApplied?: boolean;
  onAdd: () => void;
};

export const NoDataAreaWatchlist = ({ isFilterApplied, onAdd }: Props) => {
  const workstation = useCurrentWorkstation();
  const { isOboOrAccountCentric } = useCurrentAccount();
  const isInvWS = workstation.type?.code === CbWorkstationType.items.investor;

  const getTitleText = () => {
    if (isFilterApplied) {
      return 'There are no results matching your search criteria';
    }

    if (isOboOrAccountCentric) {
      return "Account that you are acting on behalf of doesn't have this company in the watchlist";
    }

    if (isInvWS) {
      return 'There are no companies in a Watchlist';
    }

    return 'No accounts have this company in their watchlist';
  };

  return (
    <NoDataArea
      iconColor="error"
      title={getTitleText()}
      {...(!isFilterApplied && {
        actionButton: (
          <Button
            icon={<Icon name="plus" size="xs" />}
            onClick={onAdd}
            data-cy={WatchlistDataIds.Drawer.OpenButton}
          >
            Add to Watchlist
          </Button>
        ),
      })}
    />
  );
};
