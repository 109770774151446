import { Layout as AntLayout } from 'antd';
import { type Content, type Footer, type Header } from 'antd/lib/layout/layout';
import type Sider from 'antd/lib/layout/Sider';
import styled from 'styled-components';

import { useBreakpoint } from '@npm/core/ui/utils/style';

type ExternalLayoutType = {
  Header: typeof Header;
  Footer: typeof Footer;
  Content: typeof Content;
  Sider: typeof Sider;
} & typeof AntLayout;

export const Layout = styled<ExternalLayoutType>(AntLayout)`
  && {
    flex-direction: column;
  }

  &,
  .ant-layout {
    background-color: inherit;
  }
`;

export const OuterContainer = styled.div`
  width: 100%;
  min-width: 0;
`;

export const InnerContainer = styled.div`
  flex-grow: 1;
  min-width: 0;
  position: relative;

  ${({ theme }) => useBreakpoint(theme).tablet`
      display: flex;
      flex-direction: column;
      flex: auto;
  `}
`;

export const ContentPanel = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: ${({ theme }) => theme.spacing.sm}px;
  `}
`;
