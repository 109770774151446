import { type CbLabelComponent, type LabelVariant } from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  investor: 'investor',
  participant: 'participant',
  stakeholder: 'stakeholder',
} as const;

type Keys = keyof typeof Items;

const LabelMap: Record<Keys, LabelVariant> = {
  investor: 'success',
  participant: 'info',
  stakeholder: 'error',
};

export const CbEventRole = {
  code: Codebooks.EVENT_ROLE,
  items: Items,
  labels: LabelMap,
  getCbLabel: (codebookItem: CodebookItem) =>
    LabelMap[(codebookItem?.code as Keys) ?? 'none'],
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem,
    isRound = false
  ) =>
    Component({
      variant: LabelMap[(codebookItem?.code as Keys) ?? 'none'],
      label: codebookItem?.name,
      round: isRound,
    }),
  getSystemLabel: (Component: CbLabelComponent, isRound = false) =>
    Component({
      variant: 'info',
      label: 'System',
      round: isRound,
    }),
};
