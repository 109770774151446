import React, { memo, useCallback, useEffect } from 'react';

import { TextPriceContextProvider } from '@npm/core/ui/components/atoms/Typography/Text';
import { PageTitle } from '@npm/core/ui/components/molecules/PageHeader/PageTitle/PageTitle';
import { DarkModeSwitcher } from '@npm/core/ui/components/organisms/DarkModeSwitcher';
import {
  Content,
  SidebarLayout,
} from '@npm/core/ui/components/organisms/Layout';
import { useCurrentRoute } from '@npm/core/ui/hooks/useCurrentRoute';
import { usePreservableQueryParamsStore } from '@npm/core/ui/services/paths';
import {
  RoleSwitcher,
  WorkstationSwitcher,
} from '@npm/features/auth/user/context';
import { OboButton } from '@npm/features/auth/user/context/Obo/OboButton';
import { useObo } from '@npm/features/auth/user/role';
import { useLogoRedirectLink } from '@npm/features/logo';
import { CompanySelectDrawerProvider } from '@npm/features/second-market/company-select/CompanySelectDrawer';
import { OrderPlacementDrawerProvider } from '@npm/features/second-market/drawers/OrderEntryDrawer';

import { SecondMarketContainer } from './SecondMarketContainer';
import { SecondMarketMenu } from './SecondMarketMenu';

type Props = {
  children: React.ReactNode;
};

export const SecondMarketLayout = memo(function SecondMarketLayout({
  children,
}: Props) {
  const currentRoute = useCurrentRoute();
  const isTopLevelPage = currentRoute?.parents.length === 0;
  const logoLinkUrl = useLogoRedirectLink();

  const { isObo } = useObo();

  const setPreservableQueryParams = usePreservableQueryParamsStore(
    x => x.setPreservableQueryParams
  );

  useEffect(() => {
    // reset preservable params on SM leave
    return () => {
      setPreservableQueryParams('second-market', {});
    };
  }, []);

  const content = (
    <Content showPageHeader={false} fullWidth={true}>
      {children}
    </Content>
  );

  const renderMenu = useCallback(
    (isSidebarCollapsed: boolean) => (
      <>
        <WorkstationSwitcher isCollapsed={isSidebarCollapsed} />
        {isObo ? (
          <OboButton isCollapsed={isSidebarCollapsed} />
        ) : (
          <RoleSwitcher isCollapsed={isSidebarCollapsed} />
        )}
        <SecondMarketMenu />
        <DarkModeSwitcher isCollapsed={isSidebarCollapsed} />
      </>
    ),
    [isObo]
  );

  return (
    <TextPriceContextProvider value={{ currencyAsSymbol: true }}>
      <SidebarLayout
        renderMenu={renderMenu}
        showHamburgerMenu={!currentRoute?.route?.layout?.noHamburgerMenu}
        collapsible
        logoLinkUrl={logoLinkUrl}
      >
        {isTopLevelPage ? (
          <OrderPlacementDrawerProvider>
            <CompanySelectDrawerProvider>
              <SecondMarketContainer>{content}</SecondMarketContainer>
            </CompanySelectDrawerProvider>
          </OrderPlacementDrawerProvider>
        ) : (
          content
        )}
        <PageTitle />
      </SidebarLayout>
    </TextPriceContextProvider>
  );
});
