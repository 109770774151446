import {
  type ComponentProps,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import {
  type ApiError,
  type ErrorCode,
  DEFAULT_ERROR_MESSAGE,
  processApiError,
} from '../../../services/errors/defaultErrorHandler';
import { eventBus } from '../../../utils/eventBus';
import { type Alert } from '../../atoms/Alert';
import { Text } from '../../atoms/Typography';
import { useLocation } from '../Link';

import { useAlertsContext } from './AlertContainer.context';

const ERROR_CODES_TO_SHOW: ErrorCode[] = [
  'validation_error',
  'record_invalid',
  'data_busy_error',
];

// TODO: add support modal once available
const DefaultStaticMessage = () => (
  <Text size="sm">
    Please try again. If the error persists, please,{' '}
    <Text
      size="sm"
      onClick={() => eventBus.dispatch('REQUEST_SUPPORT', 'api_request_error')}
    >
      {' '}
      contact our support
    </Text>
    .
  </Text>
);

const RefreshStaticMessage = () => (
  <Text size="sm">
    Please try to{' '}
    <Text size="sm" as="span" onClick={() => window.location.reload()}>
      reload
    </Text>
    . If the error persists, please,{' '}
    <Text
      size="sm"
      onClick={() => eventBus.dispatch('REQUEST_SUPPORT', 'api_request_error')}
    >
      {' '}
      contact our support
    </Text>
    .
  </Text>
);

const getApiErrorStaticMessage = (
  error: ReturnType<typeof processApiError>,
  customStaticMessage?: ReactNode
) => {
  if (error.code === 'data_busy_error') {
    return <RefreshStaticMessage />;
  }

  return ERROR_CODES_TO_SHOW.includes(error.code as string)
    ? undefined
    : customStaticMessage;
};

export const useAlerts = ({
  containerId: hookContainerId,
}: { containerId?: string } = {}) => {
  const { alertContainers, dispatch } = useAlertsContext();

  const showAlert = (
    message: string,
    {
      containerId,
      type = 'error',
      staticMessage,
    }: {
      containerId?: string;
      type?: ComponentProps<typeof Alert>['type'];
      staticMessage?: ReactNode;
    } = {}
  ) => {
    dispatch({
      type: 'ADD_ALERT',
      alert: {
        expandable: false,
        type,
        message: message,
        ...(staticMessage && { description: staticMessage }),
      },
      containerId,
    });
  };

  const showApiError = (
    error: ApiError,
    {
      containerId,
      staticMessage = <DefaultStaticMessage />,
    }: { containerId?: string; staticMessage?: ReactNode } = {}
  ) => {
    const processedError = processApiError(error);

    showAlert(
      processedError.code && ERROR_CODES_TO_SHOW.includes(processedError.code)
        ? processedError.message
        : DEFAULT_ERROR_MESSAGE,
      {
        containerId,
        type: 'error',
        staticMessage: getApiErrorStaticMessage(processedError, staticMessage),
      }
    );
  };

  const getLatestAlertContainerId = () => {
    return alertContainers.length > 0
      ? alertContainers[alertContainers.length - 1]?.id
      : undefined;
  };

  const clearAlerts = useCallback(
    (containerId?: string) => {
      dispatch({ type: 'CLEAR_ALERTS', containerId });
    },
    [dispatch]
  );

  const { search, pathname } = useLocation();
  // Clear alerts whenever the URL query parameters or the location pathname change
  useEffect(clearAlerts, [pathname, search, clearAlerts]);

  const alerts = useMemo(() => {
    const container = alertContainers.find(
      instance => instance.id === hookContainerId
    );
    return container ? container.alerts : [];
  }, [alertContainers, hookContainerId]);

  // wrap the execute of any useMutation hook to handle the error and show it as an Alert
  const withShowApiErrorMiddleware = useCallback(
    <TArgs extends unknown[], TResult>(
      func?: (...args: TArgs) => Promise<TResult>,
      { containerId }: { containerId?: string } = {}
    ) => {
      return async (...args: TArgs): Promise<TResult | undefined> => {
        const alertContainerId = containerId ?? getLatestAlertContainerId();
        clearAlerts(alertContainerId);
        try {
          return await func?.(...args);
        } catch (apiError) {
          showApiError(apiError, { containerId: alertContainerId });
          throw apiError;
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alertContainers]
  );

  return {
    alerts,
    showAlert,
    showApiError,
    getLatestAlertContainerId,
    clearAlerts,
    withShowApiErrorMiddleware,
  };
};
