import { Codebooks } from './codebooks.types';

const Items = {
  accept: 'accept',
  counter: 'counter',
  hold: 'hold',
  reject: 'reject',
  cancel: 'cancel',
  initial: 'initial',
} as const;

export const CbNegotiationAction = {
  code: Codebooks.NEGOTIATION_ACTION,
  items: Items,
};
