import React, { type ComponentProps, useState } from 'react';
import { type FormInstance, type FormProps } from 'antd';

import { VALIDATION_RULES } from '@npm/core/ui/components/atoms/FormItem';
import { InputFormItem } from '@npm/core/ui/components/atoms/FormItem';
import { Switch } from '@npm/core/ui/components/atoms/Switch';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { CypressDataIds } from '@npm/core/ui/constants';

import { type FilesForm } from '../FormationDocument.types';

import { FileUploadSection } from './FileUploadSection';

import * as S from './FormationDocumentForm.styles';

type Props = {
  accountName: string;
  form: FormInstance<FilesForm>;
  headingsVariant?: ComponentProps<typeof Heading>['variant'];
  showMainTitle?: boolean;
  handleValuesChange?: FormProps['onValuesChange'];
};

const tooltipContent = (
  <>
    <Heading variant="h3" marginBottom="sm">
      What is this?
    </Heading>
    <Text size="sm">
      Switch this ON only if the Formation Document provided grants you
      authority to transact on behalf of the entity. Switch OFF and upload a
      document below that evidences your authorization to sign on behalf of the
      entity.
    </Text>
  </>
);

export const FormationDocumentForm = ({
  accountName,
  form,
  headingsVariant = 'h1',
  handleValuesChange,
}: Props) => {
  const [showLoa, setShowLoa] = useState(false);

  const onSwitchChange = (checked: boolean) => {
    setShowLoa(!showLoa);
    if (!checked) {
      form.resetFields(['loaDoc']);
    }
  };

  return (
    <S.StyledForm form={form} onValuesChange={handleValuesChange}>
      <S.UploadItemsWrapper>
        <Card>
          <Heading variant={headingsVariant} marginBottom="md">
            Formation Document
          </Heading>
          <InputFormItem
            name="constituentDoc"
            rules={[VALIDATION_RULES.required()]}
            validateTrigger="onSubmit"
          >
            <FileUploadSection helpText="Example: Certificate of Registration, Partnership Agreement." />
          </InputFormItem>{' '}
        </Card>
        {showLoa && (
          <Card>
            <Heading variant={headingsVariant} marginBottom="md">
              Proof of Authorization
            </Heading>
            <InputFormItem name="loaDoc" rules={[VALIDATION_RULES.required()]}>
              <FileUploadSection helpText="Example: Letter of Authorization, Certificate of Incumbency" />
            </InputFormItem>
          </Card>
        )}
      </S.UploadItemsWrapper>
      <S.SwitchWrapper>
        <InputFormItem
          initialValue={true}
          name="authorized"
          valuePropName="checked"
          validateTrigger="onSubmit"
        >
          <Switch
            id="formation-form-authorized-switch"
            data-cy={
              CypressDataIds.PostOnboarding.FormationDocument
                .AuthorizedRadioButton
            }
            onChange={onSwitchChange}
          />
        </InputFormItem>
        <S.SwitchLabelText>
          The above document shows I am authorized to transact on behalf of{' '}
          {accountName}
          <span onClick={e => e.preventDefault()}>
            <S.SwitchTooltip
              placement="bottom"
              overlay={tooltipContent}
              title="test"
            >
              What is this?
            </S.SwitchTooltip>
          </span>
        </S.SwitchLabelText>
      </S.SwitchWrapper>
    </S.StyledForm>
  );
};
