import styled from 'styled-components';

import { Button } from '../../../../atoms/Button';

export const BackButton = styled(Button)`
  span,
  svg {
    color: ${({ theme }) => theme.color.general.typography.primary};
  }

  & > div {
    margin-right: 10px;
  }
`;
