import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import styled from 'styled-components';

import { CardBase } from '@npm/core/ui/components/atoms/CardBase';
import {
  formatAxisNumber,
  showStepTooltip,
  useChartOptions,
} from '@npm/core/ui/components/atoms/Charts';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';

import { useCompanyOverviewChartFilter } from '../../../CompanyOverview.hooks';
import type { CompanyOverviewChartSeries } from '../../../CompanyOverview.types';
import { formatMonthAndYearForChartLabel } from '../../../CompanyOverview.utils';
import { CompanyOverviewChartFilters } from '../../../components/CompanyOverviewChartFilters/CompanyOverviewChartFilters';
import { type CompanyOverviewMaxValues } from '../../../TapeDPricing/TapeDPricing.types';
import { sortPrimaryRoundsData } from '../PrimaryRoundsTable/PrimaryRounds.utils';

import { PrimaryRoundsChartTooltip } from './PrimaryRoundsChartTooltip/PrimaryRoundsChartTooltip';
import {
  usePrimaryRoundDataset,
  usePrimaryRoundDateLimits,
} from './PrimaryRoundsChart.hooks';
import {
  type PrimaryRoundsChartDataPoint,
  type PrimaryRoundValue,
} from './PrimaryRoundsChart.types';
import {
  getPrimaryRoundsChartTooltip,
  PRIMARY_ROUNDS_CHART_HTML_ID,
} from './PrimaryRoundsChart.utils';

import { TooltipStyles } from '@npm/core/ui/components/atoms/Charts/styles/Tooltip.styles';

type Props = {
  data: PrimaryRoundValue[];
  yAxisMaxValues: CompanyOverviewMaxValues;
};

export const PrimaryRoundsChart = ({ data, yAxisMaxValues }: Props) => {
  const [activeTooltipIndex, setActiveTooltipIndex] = useState<
    number | undefined
  >(undefined);

  // Dark-mode context used to fix issues with switching between dark and light mode
  const { isDarkMode } = useDarkModeContext();

  const sortedData = useMemo(() => {
    return sortPrimaryRoundsData(data);
  }, [data]);

  const [series, setSeries] = useState<CompanyOverviewChartSeries>('PPS');

  const [minDate, maxDate] = usePrimaryRoundDateLimits(data);

  const yAxisMaxValue =
    series === 'PPS'
      ? yAxisMaxValues.maximumPPS
      : yAxisMaxValues.maximumValuation;

  const {
    setCurrentPage,
    currentMinDate,
    currentMaxDate,
    currentPage,
    pageCount,
    range,
    setRange,
  } = useCompanyOverviewChartFilter(minDate, maxDate);

  const labels = useMemo(() => {
    return sortedData.map(({ investment_date }) => investment_date);
  }, [sortedData]);

  const dataset = usePrimaryRoundDataset(sortedData, {
    series,
    currentMinDate,
    currentMaxDate,
  });

  const options = useChartOptions();

  const canvasRef = useRef<HTMLCanvasElement>();

  const chartRef =
    useRef<Chart<'line', PrimaryRoundsChartDataPoint[], string>>();

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  };

  const resetTooltip = () => {
    if (!chartRef.current) return;

    getPrimaryRoundsChartTooltip(chartRef.current?.canvas).style.opacity = '0';
    chartRef.current.update('none');
  };

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    chartRef.current = new Chart<'line', PrimaryRoundsChartDataPoint[], string>(
      ctx,
      {
        type: 'line',
        data: {
          labels,
          datasets: [dataset],
        },
        options: options({
          scales: {
            y: {
              max: yAxisMaxValue,
              grace: '10%',
              beginAtZero: true,
              ticks: {
                callback: value =>
                  `$${formatAxisNumber(value, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}`,
              },
            },
            x: {
              type: 'time',
              min: currentMinDate.getTime(),
              max: currentMaxDate.getTime(),
              time: {
                unit: 'month',
                tooltipFormat: 'MMM dd',
              },
              ticks: {
                source: 'auto',
                autoSkip: true,
                callback: formatMonthAndYearForChartLabel,
              },
            },
          },
          interaction: {
            mode: 'segment',
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              position: 'followCursor',
              callbacks: {
                title: () => ' '.repeat(75),
                label: () => '',
                beforeBody: () => '\n\n\n',
                afterBody: () => ' ',
                footer: () => '',
              },
              external: function (context) {
                showStepTooltip({
                  context,
                  canvas: canvasRef.current,
                  getTooltipElement: getPrimaryRoundsChartTooltip,
                  onIndexChanged: setActiveTooltipIndex,
                });
              },
            },
          },
        }),
      }
    );

    chartRef.current.update('none');

    return () => destroyChart();
  }, [
    labels,
    dataset,
    isDarkMode,
    options,
    currentMinDate,
    currentMaxDate,
    yAxisMaxValue,
  ]);

  return (
    <CardBase noContentPadding={true}>
      <Flex direction="column" gap="xs">
        <CompanyOverviewChartFilters
          series={series}
          onChangeSeries={setSeries}
          range={range}
          onChangeRange={range => {
            setRange(range);
            resetTooltip();
          }}
          pagination={{
            page: currentPage,
            totalPages: pageCount,
            onPageChange: val => {
              setCurrentPage(val);
              resetTooltip();
            },
          }}
        />
        <Body>
          <Container>
            <canvas ref={canvasRef} />
            <div
              id={PRIMARY_ROUNDS_CHART_HTML_ID}
              className="html-chart-tooltip"
              style={{
                opacity: activeTooltipIndex !== undefined ? 0.9 : 0,
              }}
            >
              <PrimaryRoundsChartTooltip
                value={dataset.data[activeTooltipIndex]?.raw}
              />
            </div>
          </Container>
        </Body>
      </Flex>
    </CardBase>
  );
};

// Padding must be set outside the relative container to ensure the tooltip is positioned correctly
const Body = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.md}px
    ${({ theme }) => theme.spacing.md}px;
`;

const Container = styled.div`
  position: relative;
  ${TooltipStyles};
  height: 400px;
  width: 100%;
`;
