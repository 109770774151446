/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  OrderItemExpirationSyncsApi,
  OrderItemExpirationSyncsShowErrorCodes,
  OrderItemExpirationSyncsShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new OrderItemExpirationSyncsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type OrderItemExpirationSyncsShowMethod = OrderItemExpirationSyncsApi['orderItemExpirationSyncsShow'];
export type OrderItemExpirationSyncsShowResponseType = MethodResult<OrderItemExpirationSyncsShowMethod>;

export const useOrderItemExpirationSyncsShow = (
  variables: Parameters<OrderItemExpirationSyncsShowMethod>[0],
  config?: UseQueryConfig<
    OrderItemExpirationSyncsShowMethod,
    OrderItemExpirationSyncsShowErrorTypes
  >
) => {
  return useQuery<
    OrderItemExpirationSyncsShowMethod,
    OrderItemExpirationSyncsShowErrorTypes
  >(
    (...args) => api.orderItemExpirationSyncsShow(...args),
    OrderItemExpirationSyncsShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrderItemExpirationSyncsShow'],
        ...apiQueryConfig.OrderItemExpirationSyncsShow,
        ...config?.queryConfig
      },
    },
    'OrderItemExpirationSyncsShow',
  );
};

export const useOrderItemExpirationSyncsShowInfinite = (
  variables: Parameters<OrderItemExpirationSyncsShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    OrderItemExpirationSyncsShowMethod,
    OrderItemExpirationSyncsShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    OrderItemExpirationSyncsShowMethod,
    OrderItemExpirationSyncsShowErrorTypes
  >(
    (...args) => api.orderItemExpirationSyncsShow(...args),
    OrderItemExpirationSyncsShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrderItemExpirationSyncsShow', 'OrderItemExpirationSyncsShowInfinite'],
        ...apiQueryConfig.OrderItemExpirationSyncsShow,
        ...config?.queryConfig,
      },
    },
    'OrderItemExpirationSyncsShow',
  );
};

export const useOrderItemExpirationSyncsShowLazy = (baseOptions?: {
  variables?: Parameters<OrderItemExpirationSyncsShowMethod>[0],
  config?: UseQueryConfig<
    OrderItemExpirationSyncsShowMethod,
    OrderItemExpirationSyncsShowErrorTypes
  >
}) => {
  return useLazyQuery<
    OrderItemExpirationSyncsShowMethod,
    OrderItemExpirationSyncsShowErrorTypes
  >(
    (...args) => api.orderItemExpirationSyncsShow(...args),
    OrderItemExpirationSyncsShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['OrderItemExpirationSyncsShow'],
        ...apiQueryConfig.OrderItemExpirationSyncsShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'OrderItemExpirationSyncsShow',
  );
};

