import React from 'react';
import { type FormInstance } from 'antd';

import { Button } from '@npm/core/ui/components/atoms/Button';
import {
  InputFormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Grid } from '@npm/core/ui/components/atoms/Grid';
import { Input } from '@npm/core/ui/components/atoms/Input';

import { getGridConfig } from './PersonInfoForm.constants';

import * as S from './PersonInfoForm.styles';

type Props = {
  form: FormInstance;
  includeEmailField?: boolean;
  defaultColumnCount?: 1 | 2;
  onNext?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
};

const LoadingDataSkeleton = () => (
  <>
    {[...Array(3)].map((_, index) => (
      <S.Skeleton key={index} paragraph={{ rows: 1 }} title={false} />
    ))}
  </>
);

export const PersonInfoForm = ({
  form,
  includeEmailField = false,
  defaultColumnCount = 2,
  onNext,
  isLoading,
  isDisabled,
}: Props) => {
  const handleNext = async () => {
    try {
      await form.validateFields(['first', 'last']);
      onNext();
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) {
    return (
      <S.LoadingWrapper>
        <LoadingDataSkeleton />
      </S.LoadingWrapper>
    );
  }

  return (
    <S.Wrapper data-cy="individual-information-form">
      <Grid
        gap={getGridConfig(defaultColumnCount).gap}
        cols={getGridConfig(defaultColumnCount).cols}
        areas={getGridConfig(defaultColumnCount).areas}
      >
        <Grid.GridItem areaName="first">
          <InputFormItem
            name="first"
            label="First Name"
            labelPosition="top"
            rules={[VALIDATION_RULES.required('First name')]}
          >
            <Input placeholder="First Name" disabled={isDisabled} />
          </InputFormItem>
        </Grid.GridItem>
        <Grid.GridItem areaName="middle">
          <InputFormItem
            name="middle"
            label="Middle Name"
            requiredMark="optional"
          >
            <Input placeholder="Middle Name" disabled={isDisabled} />
          </InputFormItem>
        </Grid.GridItem>
        <Grid.GridItem areaName="last">
          <InputFormItem
            name="last"
            label="Last Name"
            labelPosition="top"
            rules={[VALIDATION_RULES.required('Last name')]}
          >
            <Input placeholder="Last Name" disabled={isDisabled} />
          </InputFormItem>
        </Grid.GridItem>
        {includeEmailField && (
          <Grid.GridItem areaName="email">
            <InputFormItem
              name="email"
              label="Email"
              rules={[
                VALIDATION_RULES.email(),
                VALIDATION_RULES.required('Email'),
              ]}
              validateTrigger="onBlur"
            >
              <Input placeholder="Email" />
            </InputFormItem>
          </Grid.GridItem>
        )}
      </Grid>
      {onNext && (
        <Button
          variant="outline"
          onClick={handleNext}
          data-cy="next-button-to-contact-info"
        >
          Next
        </Button>
      )}
    </S.Wrapper>
  );
};
