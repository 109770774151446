import { RBAC } from '@npm/core/ui/models/permissions';
import { type brokerageWorkstationRouteKeys } from '@npm/core/ui/routes';
import { componentLoader } from '@npm/core/ui/routes/componentLoader';
import { type RouteDefinition } from '@npm/core/ui/routes/router.types';

const { isBroNotReadOnly } = RBAC.brokerage;

type RouteDef = RouteDefinition & {
  path: brokerageWorkstationRouteKeys;
  subRoutes?: RouteDef[];
};

const workstationPrefix = 'brokerage-workstation';

export const registerRoutes = (desc: RouteDefinition[]) => {
  routes.forEach(r => {
    desc.push(r);
  });
};

export const routes: RouteDef[] = [
  {
    path: `/${workstationPrefix}/client-watchlist`,
    component: componentLoader(() => import(`../pages/client-watchlist`)),
    title: 'Client Watchlist',
    pageHistory: {
      isRoot: true,
    },
    permissionsChecks: [isBroNotReadOnly],
  },
  {
    path: `/${workstationPrefix}/entities`,
    component: componentLoader(
      () => import(`libs/workstations/brokerage/src/pages/entities`)
    ),
    pageHistory: {
      isRoot: true,
    },
    title: 'Entities',
    permissionsChecks: [isBroNotReadOnly],
    subRoutes: [
      {
        path: `/${workstationPrefix}/entities/:accountId`,
        component: componentLoader(
          () => import(`libs/workstations/brokerage/src/pages/entities/detail`)
        ),
        permissionsChecks: [isBroNotReadOnly],
      },
      {
        path: `/${workstationPrefix}/entities/:accountId/onboarding`,
        mobileMenuType: 'basic',
        layout: { noLayout: true },
        component: componentLoader(
          () =>
            import(
              `libs/workstations/brokerage/src/pages/onboarding/Onboarding`
            )
        ),
        permissionsChecks: [isBroNotReadOnly],
      },
    ],
  },
  {
    path: `/${workstationPrefix}/individuals`,
    component: componentLoader(
      () => import(`libs/workstations/brokerage/src/pages/individuals`)
    ),
    pageHistory: {
      isRoot: true,
    },
    title: 'Individuals',
    permissionsChecks: [isBroNotReadOnly],
    subRoutes: [
      {
        path: `/${workstationPrefix}/individuals/:accountId`,
        component: componentLoader(
          () =>
            import(`libs/workstations/brokerage/src/pages/individuals/detail`)
        ),
        permissionsChecks: [isBroNotReadOnly],
      },
      {
        path: `/${workstationPrefix}/individuals/:accountId/onboarding`,
        mobileMenuType: 'basic',
        layout: { noLayout: true },
        component: componentLoader(
          () =>
            import(
              `libs/workstations/brokerage/src/pages/onboarding/Onboarding`
            )
        ),
        permissionsChecks: [isBroNotReadOnly],
      },
    ],
  },
  {
    path: `/${workstationPrefix}/persons`,
    component: componentLoader(
      () => import(`libs/workstations/brokerage/src/pages/persons`)
    ),
    pageHistory: {
      isRoot: true,
    },
    title: 'Account Representatives',
    permissionsChecks: [isBroNotReadOnly],
    subRoutes: [
      {
        path: `/${workstationPrefix}/persons/:personId`,
        component: componentLoader(
          () => import(`libs/workstations/brokerage/src/pages/persons/detail`)
        ),
        title: 'User Detail',
        permissionsChecks: [isBroNotReadOnly],
      },
    ],
  },
  {
    path: `/${workstationPrefix}/holdings`,
    component: componentLoader(
      () => import(`libs/workstations/brokerage/src/pages/holdings`)
    ),
    pageHistory: {
      isRoot: true,
    },
    title: 'Holdings',
  },
  {
    path: `/${workstationPrefix}/administrators`,
    component: componentLoader(
      () => import(`libs/workstations/brokerage/src/pages/administrators`)
    ),
    pageHistory: {
      isRoot: true,
    },
    title: 'Administrators',
  },
  {
    path: `/${workstationPrefix}`,
    component: componentLoader(() => import(`../pages`)),
    pageHistory: {
      isRoot: true,
    },
  },
  {
    path: `/${workstationPrefix}/matches`,
    component: componentLoader(
      () => import(`libs/workstations/brokerage/src/pages/matches`)
    ),
    title: 'Matches',
    pageHistory: {
      isRoot: true,
    },
    permissionsChecks: [isBroNotReadOnly],
  },
];
