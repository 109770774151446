import { Col, Row } from 'antd';
import styled, { css } from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { CardWithBackground } from '@npm/core/ui/components/molecules/CardWithBackground';
import { useBreakpoint } from '@npm/core/ui/utils/style';

import { type BasicPageLayoutBreakpointPositionsType } from './BasicPageLayout.types';

export const MainCardContentWrapper = styled(Flex)`
  width: ${({ theme }) => `${2 * theme.spacing.xxxl + 590}px`};
  padding: ${({ theme }) => `${theme.spacing.xxxl}px`};

  ${({ theme }) => useBreakpoint(theme).tablet`
    width: 368px;
    padding: ${theme.spacing.xxl}px;
  `}

  ${({ theme }) => useBreakpoint(theme).mobile`
    width: 100%;
    padding: ${theme.spacing.xxxl * 3}px ${theme.spacing.md}px ${
    theme.spacing.lg
  }px ${theme.spacing.lg}px;
  `}
`;

export const ShrinkedRow = styled(Row)`
  width: 1200px;
  max-width: 100%;
  align-self: center;

  row-gap: ${({ gutter }) => gutter}px;

  ${({ theme }) => useBreakpoint(theme).smTablet`
    width: 100%;
  `}

  & .card-content {
    padding: 0;
  }
`;

export const CardCol = styled(Col)`
  align-self: stretch;

  ${({ theme }) => useBreakpoint(theme).smTablet`
    padding: 0!important;
  `}
`;

export const MiddleCard = styled(Card)`
  height: 100%;
`;

export const CardContentWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.xxl}px;
  min-height: 340px;
`;

export const FooterCardWithBackground = styled(CardWithBackground)`
  & .card-content {
    min-height: 300px;
    justify-content: center;
    position: relative;

    ${({ theme }) => useBreakpoint(theme).mobile`
      min-height: 427px;
      justify-content: flex-start;
      padding: ${theme.spacing.xxl}px;
  `}
  }
`;

export const FooterImage = styled.img<{
  $position: BasicPageLayoutBreakpointPositionsType;
}>`
  position: absolute;
  height: 100%;
  ${({ $position, theme }) => css`
    left: ${$position.desktop.x};
    ${useBreakpoint(theme).tablet`
      left: ${$position.tablet.x};
    `}
  `}
`;
