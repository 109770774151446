import React, { useMemo } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';

import { type ReportingPeriodFilterItem } from './ReportingPeriodFilter.types';
import { getReportingPeriodFilterOptions } from './ReportingPeriodFilter.utils';

type Props = {
  selectedReportingPeriod: ReportingPeriodFilterItem | null;
  onSelect: (item: ReportingPeriodFilterItem) => void;
};

export const ReportingPeriodFilter = ({
  selectedReportingPeriod,
  onSelect,
}: Props) => {
  const dropdownItems = useMemo(() => {
    const reportingPeriods = getReportingPeriodFilterOptions();

    const baseDropdownItems = [
      ...reportingPeriods.map(item => ({
        label: item.label,
        onClick: () => onSelect(item),
      })),
    ];

    return selectedReportingPeriod
      ? [
          { label: 'All Reporting Periods', onClick: () => onSelect(null) },
          ...baseDropdownItems,
        ]
      : baseDropdownItems;
  }, [selectedReportingPeriod, onSelect]);

  return (
    <Dropdown
      fixedPosition
      menuProps={{
        selectable: true,
        style: {
          maxHeight: '219px',
          overflowY: 'auto',
        },
      }}
      placement="bottomRight"
      items={dropdownItems}
    >
      <Button
        size="sm"
        variant="text"
        iconPosition="right"
        icon={<Icon name="chevron-down" />}
      >
        {selectedReportingPeriod?.label || 'Select Reporting Period'}
      </Button>
    </Dropdown>
  );
};
