import styled from 'styled-components';

import { useBreakpoint } from '../../../../../../utils/style';

const HEADING_HEIGHT = 64;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEADING_HEIGHT}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
    margin-bottom: ${theme.spacing.md}px;

      h1 {
        display: none;
      }
  `}
`;
