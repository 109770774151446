import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';

import { StyledCompanyCard } from './CompanyCard.styles';

export const CompanyCardSkeleton = () => {
  return (
    <StyledCompanyCard
      header={{
        title: (
          <Flex align="flex-start" gap="sm">
            <Skeleton.Avatar size={28} />
            <Skeleton.Base
              title={{ width: 60, style: { marginTop: 0 } }}
              paragraph={false}
            />
          </Flex>
        ),
      }}
    >
      <Skeleton.Base title={false} paragraph={{ rows: 4, width: '100%' }} />
    </StyledCompanyCard>
  );
};
