import styled from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';

export const TextButton = styled(Button).attrs({ variant: 'text' })`
  && {
    padding: 0;
    max-width: 100%;
  }

  > svg {
    flex-shrink: 0;
  }

  > span {
    max-width: 100%;
    flex-grow: 0;
    white-space: pre-line;
    text-align: left;
  }
`;
