// eslint-disable-next-line simple-import-sort/imports
import './trustedTypes';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { DarkModeProvider } from '@npm/core/ui/context/DarkModeContext/DarkModeContext';
import { SecurityWrapper } from '@npm/okta';

import { App } from './App';
import { AppHeader } from './AppHeader';
import { DatadogService } from '@npm/utils';

DatadogService.init();

ReactDOM.render(
  <div className="application">
    <BrowserRouter>
      <AppHeader />
      <SecurityWrapper>
        <DarkModeProvider>
          <App />
        </DarkModeProvider>
      </SecurityWrapper>
    </BrowserRouter>
  </div>,
  document.getElementById('root')
);
