import React from 'react';
import { useTheme } from 'styled-components';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { ShowMore } from '@npm/core/ui/components/molecules/ShowMore';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type IssuerEntityAggregate, type VenusApi } from '@npm/data-access';

import { CompanyHeading } from '../CompanyHeading';
import { Classifications } from '../CorporateProfile/components/Classifications';
import { DescriptionWithBaseInfoSkeleton } from '../skeletons';

import { BaseInfo } from './components';

type Props = {
  companyProfile: VenusApi.CompanyProfile | undefined;
  issuerEntity: IssuerEntityAggregate | undefined;
  isLoading?: boolean;
};

export const DescriptionWithBaseInfo = ({
  companyProfile,
  issuerEntity,
  isLoading,
}: Props) => {
  const { spacing } = useTheme();
  const { isMobile, isSmTablet } = useBreakpoints();

  const baseContent = (
    <>
      <Text size="sm">{companyProfile?.description}</Text>
      <Divider
        marginTop={isMobile || isSmTablet ? spacing.sm : spacing.xxl / 2}
        marginBottom={0}
      />
      <BaseInfo companyProfile={companyProfile} />
    </>
  );

  const mobileContent = (
    <ShowMore
      renderCollapsed={baseContent}
      renderExpanded={
        <>
          {baseContent}
          <Divider
            marginTop={isSmTablet ? spacing.sm : spacing.xs}
            marginBottom={spacing.sm}
          />
          <Text size="sm" as="h4" marginBottom="xs">
            Classifications
          </Text>
          <Margin bottom="sm">
            <Classifications companyProfile={companyProfile} />
          </Margin>
        </>
      }
      buttonVariant="blockWithChevron"
    />
  );

  const desktopContent = (
    <>
      {baseContent}
      <Divider
        marginBottom={isMobile || isSmTablet ? spacing.sm : spacing.xxl / 2}
        marginTop={0}
      />
      <Text size="sm" as="h4" marginBottom="xs">
        Classifications
      </Text>
      <Classifications companyProfile={companyProfile} />
    </>
  );

  return (
    <>
      <CompanyHeading issuerEntity={issuerEntity} isLoading={isLoading} />
      {isLoading ? (
        <DescriptionWithBaseInfoSkeleton />
      ) : companyProfile ? (
        isMobile || isSmTablet ? (
          mobileContent
        ) : (
          desktopContent
        )
      ) : (
        <NoDataArea title={'No Data'} />
      )}
    </>
  );
};
