import { type CbLabelComponent, type LabelVariant } from '@npm/utils';
import { capitalize } from '@npm/utils';

import { type CodebookItem } from './codebooks.types';

const Items = {
  order_update: 'order_update',
  order_update_max_quantity_info: 'order_update_max_quantity_info',
  hold: 'hold',
  cancel: 'cancel',
  rejected: 'rejected',
  accept: 'accept',
  counter: 'counter',
  initial: 'initial', // TODO: this is not in swagger
} as const;

type Keys = keyof typeof Items;

const LabelMap: Record<Keys, LabelVariant> = {
  order_update: 'info',
  order_update_max_quantity_info: 'info',
  hold: 'general',
  cancel: 'general',
  rejected: 'error',
  accept: 'success',
  counter: 'warning',
  initial: 'general',
}; // TODO: wrong naming compared to swagger

export const CbNegotiationActionStatus = {
  code: 'NEGOTIATION_ACTION_STATUS',
  items: Items,
  labelMap: LabelMap,
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem,
    round = false
  ) =>
    Component({
      variant: LabelMap[(codebookItem?.code as Keys) ?? 'none'],
      label: capitalize(codebookItem?.name),
      round,
    }),
};
