import React from 'react';
import { type AlertProps } from 'antd';

import { Icon } from '../Icon';

import * as S from './Banner.styles';

export type BannerProps = Omit<AlertProps, 'banner'>;

export const Banner = ({
  type = 'info',
  showIcon = true,
  icon = <Icon name="circle" size="xxs" />,
  ...props
}: BannerProps) => {
  return (
    <S.Alert
      {...props}
      banner
      closeIcon={<Icon name="x-close" size="xxs" />}
      type={type}
      showIcon={showIcon}
      icon={showIcon ? <S.IconWrap>{icon}</S.IconWrap> : null}
    />
  );
};
