import styled from 'styled-components';

import { centerFlex, useBreakpoint } from '../../../utils/style';

export const ContentWrapper = styled.div`
  ${centerFlex}
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.sm * 10}px;
  text-align: center;

  ${({ theme }) => useBreakpoint(theme).mobile`
        padding: ${theme.spacing.md * 3}px ${theme.spacing.md}px;
    `}
`;
