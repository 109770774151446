import { useEffect, useState } from 'react';

import { useUserContextStore } from '../auth/user/context';
import { useUserRole } from '../auth/user/role';
import { useGetWorkstationLandingPage } from '../workstations/workstationLandingPage.hooks';

export const useLogoRedirectLink = () => {
  const { workstation } = useUserRole();
  const context = useUserContextStore();

  const getWorkstationLandingPage = useGetWorkstationLandingPage();

  const [landingPageUrl, setLandingPageUrl] = useState('');

  useEffect(() => {
    getWorkstationLandingPage(
      workstation.type.code,
      context,
      context.user?.active_role
    ).then(result => setLandingPageUrl(result));
  }, []);

  return landingPageUrl;
};
