import { type CompanyOverviewChartSeries } from '../../../CompanyOverview.types';

import {
  type PrimaryRoundsChartDataPoint,
  type PrimaryRoundValue,
} from './PrimaryRoundsChart.types';

export const convertPrimaryRoundsToDataSet = (
  data: PrimaryRoundValue[],
  series: CompanyOverviewChartSeries
): {
  primaryRoundsData: PrimaryRoundsChartDataPoint[];
} => {
  if (!data?.length) {
    return {
      primaryRoundsData: [],
    };
  }

  const isPPS = series === 'PPS';

  return {
    primaryRoundsData: data.map(value => ({
      x: value.investment_date,
      y: isPPS ? value.price_per_share : value.valuation,
      raw: value,
    })),
  };
};

export const PRIMARY_ROUNDS_CHART_HTML_ID = 'primary-rounds-chart';

export const getPrimaryRoundsChartTooltip = (canvas: HTMLCanvasElement) => {
  return canvas.parentNode.querySelector(
    `#${PRIMARY_ROUNDS_CHART_HTML_ID}`
  ) as HTMLElement;
};
