import { useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { handleValidationError } from '@npm/core/ui/utils/form';
import {
  getApiErrorCode,
  usePersonAccreditationCreate,
  usePersonAccreditationShow,
} from '@npm/data-access';

import {
  type AccreditationFormFields,
  type SignatureFormFields,
} from '../Accreditation.types';

export const useIndividualAccreditationForm = (
  personId: number | undefined,
  { onUpdate }: { onUpdate?: () => void }
) => {
  const handleError = useErrorHandler();

  const [form] = Form.useForm<AccreditationFormFields>();
  const [signatureForm] = Form.useForm<SignatureFormFields>();
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);

  const { withShowApiErrorMiddleware } = useAlerts();

  const { data: accreditationData, isLoading } = usePersonAccreditationShow(
    { personId },
    {
      queryConfig: {
        enabled: !!personId,
        onError: e => {
          if (getApiErrorCode(e) !== 404) {
            handleError(e);
          }
        },
      },
    }
  );

  const { execute, isLoading: isUpdating } = usePersonAccreditationCreate();

  const handleOpenSignModal = async () => {
    try {
      await form.validateFields();
      setIsSignatureModalOpen(true);
    } catch (e) {
      handleValidationError(form, e, true);
    }
  };

  const handleSubmit = async () => {
    let values: AccreditationFormFields;
    let signValues: SignatureFormFields;

    try {
      values = await form.validateFields();
      signValues = await signatureForm.validateFields();
    } catch (e) {
      return console.error('Failed:', e);
    }

    try {
      await withShowApiErrorMiddleware(execute)({
        personId,
        personsAccreditationCreateRequestContract: {
          answers: Object.values(values).flatMap(val =>
            val ? [JSON.parse(val)] : []
          ),
          signature: signValues.signature,
        },
      });

      onUpdate();
      setIsSignatureModalOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    form,
    signatureForm,
    data: accreditationData,
    handleOpenSignModal,
    closeSignModal: () => setIsSignatureModalOpen(false),
    handleSubmit,
    isLoading,
    isUpdating,
    isSignatureModalOpen: isSignatureModalOpen,
  };
};
