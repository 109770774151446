import { useWizard } from 'react-use-wizard';

import { useBreakpoints } from '../../../../../hooks/useBreakpoints';
import { useWizardStore } from '../../Wizard.store';
import { WizardStoreActionTypes } from '../../Wizard.types';
import { updateHistoryState } from '../../Wizard.utils';

export const useHandleSidebarItemClick = (
  handleCloseFormAttempt?: (callback?: () => void) => void
) => {
  const { isMobile, isTablet } = useBreakpoints();
  const { goToStep } = useWizard();
  const dispatch = useWizardStore(s => s.dispatch);

  return (stepIndex: number) => {
    const handleGoToStep = () => {
      if (isMobile || isTablet) {
        updateHistoryState('push', { isStepperMobileOpen: false });
        dispatch({
          type: WizardStoreActionTypes.TOGGLE_STEPPER_MOBILE_OPEN,
        });
      }
      goToStep(stepIndex);
    };
    handleCloseFormAttempt
      ? handleCloseFormAttempt(handleGoToStep)
      : handleGoToStep();
  };
};
