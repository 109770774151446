import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useLayoutConfig } from '@npm/core/ui/components/organisms/Layout';
import { CypressDataIds } from '@npm/core/ui/constants';

import { useOrderPlacementDrawerContext } from '../OrderPlacementDrawer/OrderPlacementDrawer.context';

import * as S from './PlaceAnOrderButton.styles';

type Props = {
  onClick: () => void;
};

export const PlaceAnOrderButton = ({ onClick }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { obo, isInvestor }] = useOrderPlacementDrawerContext();

  const { isMobile, isTablet } = useLayoutConfig();
  const block = isMobile || isTablet;

  if (isInvestor || obo) {
    return (
      <Button
        data-cy={CypressDataIds.OrderEntryDrawer.Open}
        icon={<Icon name="arrows-opposite" />}
        onClick={onClick}
        block={block}
      >
        {isInvestor ? 'Enter IOI' : 'Place an Order'}
      </Button>
    );
  } else {
    return (
      <S.TradeButton
        block={isMobile || isTablet}
        onClick={onClick}
        icon={<Icon name="arrows-opposite" />}
      >
        Trade
      </S.TradeButton>
    );
  }
};
