import React from 'react';

import * as S from './ColumnsContainer.styles';

type Props = {
  columns: React.ReactNode[];
  className?: string;
};

export const ColumnsContainer = ({ columns, className }: Props) => {
  return (
    <S.Container className={className}>
      {columns.map((col, idx) => (
        <S.Column key={idx}>{col}</S.Column>
      ))}
    </S.Container>
  );
};
