import {
  type NegotiationIndexItemAggregate,
  type SecondmarketInvestorOrderItemShow,
  CbNegotiationStatus,
} from '@npm/data-access';

type Props = {
  negotiations: NegotiationIndexItemAggregate[];
  orderItem: SecondmarketInvestorOrderItemShow;
  isMyOrder: boolean;
};

export const shouldShowBuySellMoreCta = ({
  negotiations,
  orderItem,
  isMyOrder,
}: Props) => {
  const hasInProgressNegotiation = negotiations.some(
    negotiation =>
      negotiation?.state?.code === CbNegotiationStatus.items.in_progress
  );

  if (hasInProgressNegotiation || isMyOrder) {
    return false;
  }

  return orderItem?.remaining_quantity > 0;
};
