import { type StepDefinition, type WizardHistoryState } from './Wizard.types';

export const findNextIncompleteStep = (
  steps: StepDefinition[],
  currentIndex: number,
  isIncomplete: (step: StepDefinition) => boolean
) => {
  for (let i = currentIndex + 1; i < steps.length; i++) {
    const step = steps[i];
    if (step && isIncomplete(step)) {
      return i;
    }
  }

  return -1;
};

export const updateHistoryState = (
  type: 'push' | 'replace',
  state: Partial<WizardHistoryState>
) => {
  if (type === 'push') {
    window.history.pushState({ ...window.history.state, ...state }, '');
  } else {
    window.history.replaceState({ ...window.history.state, ...state }, '');
  }
};
