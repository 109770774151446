import { type QueryClient } from '@tanstack/react-query';

import { type Workstation } from '@npm/data-access';

export const invalidateWorkstationCache = (
  nextWorkstation: Workstation,
  queryClient: QueryClient
) => {
  const keys = queryClient
    .getQueryCache()
    .findAll(
      ['UserRoleIndex', {}, { workstation: nextWorkstation.type?.code }],
      { exact: false }
    )
    .map(q => q.queryKey);

  keys.forEach(key => {
    queryClient.invalidateQueries({
      queryKey: key,
    });
  });
};
