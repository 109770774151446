import { useEffect } from 'react';

const handleTabFocus = (e: globalThis.KeyboardEvent) => {
  if (e.key === 'Tab') {
    document.body.classList.add('tab-focus');
  }
};

const handleMouseClick = () => {
  document.body.classList.remove('tab-focus');
};

export const useTabFocusHandler = () => {
  useEffect(() => {
    document.body.addEventListener('keydown', handleTabFocus);
    document.body.addEventListener('mousedown', handleMouseClick);

    return () => {
      document.body.removeEventListener('keydown', handleTabFocus);
      document.body.removeEventListener('mousedown', handleMouseClick);
    };
  }, []);
};
