import type * as React from 'react';
import { InputNumber } from 'antd';
import styled, { css } from 'styled-components';

import {
  InputTextStyles,
  Text,
  TextCurrencyStyles,
  TextNumberStyles,
} from '../Typography';

export const INPUT_HEIGHT = 52;

export const SMALL_INPUT_HEIGHT = 40;

const ADDON_AFTER_WIDTH = 33;
export const StyledInputNumber = styled(InputNumber)<{
  addonAfter?: React.ReactNode;
  $addonAfterOverrideWidth?: number;
  $readOnly?: boolean;
}>`
  width: 100%;

  &.ant-input-number,
  &.ant-input-number-affix-wrapper,
  .ant-input-number-affix-wrapper {
    background-color: ${({ theme }) => theme.color.general.layout.two};
    border-color: ${({ theme }) => theme.color.general.borders.secondary};
  }

  &.ant-input-number {
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    height: ${INPUT_HEIGHT}px;

    ${({ theme, addonAfter, $addonAfterOverrideWidth }) => css`
      padding: ${theme.spacing.sm}px ${theme.spacing.lg / 2}px;
      padding-right: ${addonAfter
        ? theme.spacing.xs + ($addonAfterOverrideWidth ?? ADDON_AFTER_WIDTH)
        : theme.spacing.lg / 2}px;
    `} & .ant-input-number-input-wrap {
      & input {
        text-align: right;
        ${TextNumberStyles};
        line-height: unset;
        padding: 0;
        height: auto;
      }
    }

    & .ant-input-number-input::placeholder {
      color: ${({ theme }) => theme.color.general.typography.tertiary};
    }

    & .ant-input-number-handler-wrap {
      display: none;
    }
  }

  &.ant-input-number-affix-wrapper,
  .ant-input-number-affix-wrapper {
    border-radius: ${({ theme }) => theme.borderRadius.md}px;

    & > .ant-input-number-prefix {
      ${InputTextStyles};
      color: ${({ theme }) =>
        theme.color.general.typography.primary} !important;
    }

    &.ant-input-number-input {
      border-radius: 0;
    }
  }

  &.ant-input-number:hover,
  &.ant-input-number-affix-wrapper:hover,
  .ant-input-number-affix-wrapper:hover {
    border-color: ${({ theme }) => theme.color.info.borders.primary};
  }

  &.ant-input-number-affix-wrapper .ant-input-number:hover,
  .ant-input-number-affix-wrapper .ant-input-number:hover {
    background-color: transparent;
  }

  &.ant-input-number-focused,
  &.ant-input-number-affix-wrapper-focused,
  .ant-input-number-affix-wrapper-focused {
    background-color: ${({ theme }) => theme.color.general.layout.one};
    border-color: ${({ theme }) => theme.color.info.borders.primary};
    box-shadow: ${({ theme }) => theme.shadow.level1},
      0 0 0 1px ${({ theme }) => theme.color.info.borders.primary};
  }

  &.ant-input-number-status-error:not(.ant-input-number-disabled):not(
      .ant-input-number-borderless
    ).ant-input-number,
  &.ant-input-number-affix-wrapper-status-error:not(
      .ant-input-number-affix-wrapper-disabled
    ):not(
      .ant-input-number-affix-wrapper-borderless
    ).ant-input-number-affix-wrapper,
  .ant-input-number-affix-wrapper-status-error:not(
      .ant-input-number-affix-wrapper-disabled
    ):not(
      .ant-input-number-affix-wrapper-borderless
    ).ant-input-number-affix-wrapper,
  &.ant-input-number-status-warning:not(.ant-input-number-disabled):not(
      .ant-input-number-borderless
    ).ant-input-number,
  &.ant-input-number-affix-wrapper-status-warning:not(
      .ant-input-number-affix-wrapper-disabled
    ):not(
      .ant-input-number-affix-wrapper-borderless
    ).ant-input-number-affix-wrapper,
  .ant-input-number-affix-wrapper-status-warning:not(
      .ant-input-number-affix-wrapper-disabled
    ):not(
      .ant-input-number-affix-wrapper-borderless
    ).ant-input-number-affix-wrapper {
    border-color: ${({ theme }) => theme.color.error.typography.primary};
    background-color: ${({ theme }) => theme.color.general.layout.two};

    &.ant-input-number-focused,
    &.ant-input-number-affix-wrapper-focused,
    .ant-input-number-affix-wrapper-focused {
      box-shadow: ${({ theme }) => theme.shadow.level1},
        0 0 0 1px ${({ theme }) => theme.color.error.typography.primary};
    }
  }

  /* DISABLED */

  &.ant-input-number-disabled,
  &.ant-input-number-affix-wrapper-disabled,
  .ant-input-number-affix-wrapper-disabled {
    background-color: ${({ theme }) => theme.color.general.layout.two};
    border-color: ${({ theme }) => theme.color.general.borders.primary};

    & > .ant-input-number-prefix {
      color: ${({ theme }) =>
        theme.color.general.typography.tertiary} !important;
    }

    &,
    & input {
      color: ${({ theme }) => theme.color.general.typography.muted} !important;
    }

    &:hover {
      border-color: ${({ theme }) => theme.color.general.borders.primary};
    }
  }

  ${({ $readOnly, theme }) =>
    $readOnly &&
    css`
      &.ant-input-number,
      &.ant-input-number-affix-wrapper,
      .ant-input-number-affix-wrapper {
        background-color: ${theme.color.general.backgrounds.muted};
        border-color: ${theme.color.general.borders.secondary};
        color: ${theme.color.general.typography.secondary};

        & > .ant-input-number-prefix {
          color: ${theme.color.general.typography.secondary} !important;
        }

        &,
        & input,
        & input::placeholder {
          color: ${theme.color.general.typography.secondary} !important;
        }

        &:hover {
          border-color: ${theme.color.general.borders.secondary};
        }
      }
    `};

  /* ADDON AFTER */

  .ant-input-number-group-addon {
    position: absolute;
    background: transparent;
    right: 0;
    top: 0;
    bottom: 0;
    width: ${({ $addonAfterOverrideWidth }) =>
      $addonAfterOverrideWidth ?? ADDON_AFTER_WIDTH}px;
    padding: ${({ theme }) => theme.spacing.sm}px 0;
    padding-right: ${({ theme }) => theme.spacing.lg / 2}px;
    display: flex;
    align-items: flex-end;
    // has to be bigger than 0, smaller than drawer (10)
    z-index: ${({ theme }) => theme.zIndex.base + 1};
    border: 0;
  }

  .ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child),
  .ant-input-number-group
    > .ant-input-number-affix-wrapper:not(:last-child)
    .ant-input-number {
    border-top-right-radius: ${({ theme }) => theme.borderRadius.md}px;
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.md}px;
  }
`;
export const CurrencyAddon = styled.div`
  padding-bottom: 7px; // to align it with the input  & > span {
  ${TextCurrencyStyles};
  margin-left: 0;
`;
export const SecondaryLabel = styled(Text).attrs({
  size: 'sm',
  as: 'span',
})`
  color: ${({ theme }) => theme.color.general.typography.secondary};
  margin-left: ${({ theme }) => theme.spacing.sm}px;
`;
