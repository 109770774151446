import { Codebooks } from './codebooks.types';

const Items = {
  NSO: 'NSO',
  ISO: 'ISO',
  INTL: 'INTL',
} as const;

export type CbOptionTypeCode = typeof Items[keyof typeof Items];

export const CbOptionType = {
  code: Codebooks.OPTION_TYPE,
  items: Items,
};
