import styled from 'styled-components';

import { INPUT_HEIGHT } from '../../Input/styles';

export const SearchPlaceholder = styled.span.attrs({ class: 'ant-input' })`
  display: inline-flex;
  align-items: center;
  height: calc(${INPUT_HEIGHT}px - 2px);
  gap: ${({ theme }) => theme.spacing.sm}px;
`;
