/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CodebookOrString } from '../model';
// @ts-ignore
import { DestroySuccess } from '../model';
// @ts-ignore
import { DocumentAggregate } from '../model';
// @ts-ignore
import { DocumentIndex } from '../model';
// @ts-ignore
import { DocumentUpdateRequestContract } from '../model';
// @ts-ignore
import { ErrorValidation } from '../model';
/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates a document
         * @param {File} file 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {Array<string>} [includeField] 
         * @param {string} [displayName] 
         * @param {number} [folderId] 
         * @param {CodebookOrString} [category] 
         * @param {CodebookOrString} [ownerType] 
         * @param {number} [ownerId] 
         * @param {boolean} [downloadable] 
         * @param {boolean} [watermark] 
         * @param {CodebookOrString} [state] 
         * @param {string} [number] 
         * @param {string} [expirationDate] 
         * @param {string} [dateOfBirth] 
         * @param {CodebookOrString} [issuingCountry] 
         * @param {CodebookOrString} [signature] 
         * @param {CodebookOrString} [nationality] 
         * @param {Array<CodebookOrString>} [signers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCreate: async (file: File, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, includeField?: Array<string>, displayName?: string, folderId?: number, category?: CodebookOrString, ownerType?: CodebookOrString, ownerId?: number, downloadable?: boolean, watermark?: boolean, state?: CodebookOrString, number?: string, expirationDate?: string, dateOfBirth?: string, issuingCountry?: CodebookOrString, signature?: CodebookOrString, nationality?: CodebookOrString, signers?: Array<CodebookOrString>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('documentCreate', 'file', file)
            const localVarPath = changeUrl(`/api/documents`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }

            if (includeField) {
                localVarFormParams.append('include_field', JSON.stringify(includeField));
            }

    
            if (displayName !== undefined) { 
                localVarFormParams.append('display_name', displayName as any);
            }
    
            if (folderId !== undefined) { 
                localVarFormParams.append('folder_id', folderId as any);
            }
    
            if (category !== undefined) { 
                localVarFormParams.append('category', typeof category === 'string' ? category : JSON.stringify(category));
            }
    
            if (ownerType !== undefined) { 
                localVarFormParams.append('owner_type', typeof ownerType === 'string' ? ownerType : JSON.stringify(ownerType));
            }
    
            if (ownerId !== undefined) { 
                localVarFormParams.append('owner_id', ownerId as any);
            }
    
            if (downloadable !== undefined) { 
                localVarFormParams.append('downloadable', downloadable as any);
            }
    
            if (watermark !== undefined) { 
                localVarFormParams.append('watermark', watermark as any);
            }
    
            if (state !== undefined) { 
                localVarFormParams.append('state', typeof state === 'string' ? state : JSON.stringify(state));
            }
    
            if (number !== undefined) { 
                localVarFormParams.append('number', number as any);
            }
    
            if (expirationDate !== undefined) { 
                localVarFormParams.append('expiration_date', expirationDate as any);
            }
    
            if (dateOfBirth !== undefined) { 
                localVarFormParams.append('date_of_birth', dateOfBirth as any);
            }
    
            if (issuingCountry !== undefined) { 
                localVarFormParams.append('issuing_country', typeof issuingCountry === 'string' ? issuingCountry : JSON.stringify(issuingCountry));
            }
    
            if (signature !== undefined) { 
                localVarFormParams.append('signature', typeof signature === 'string' ? signature : JSON.stringify(signature));
            }
    
            if (nationality !== undefined) { 
                localVarFormParams.append('nationality', typeof nationality === 'string' ? nationality : JSON.stringify(nationality));
            }
                if (signers) {
                localVarFormParams.append('signers', JSON.stringify(signers));
            }

    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a document by id
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentDestroy: async (id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentDestroy', 'id', id)
            const localVarPath = changeUrl(`/api/documents/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloads the document specified
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentDownload: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentDownload', 'id', id)
            const localVarPath = changeUrl(`/api/documents/{id}/download`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list documents
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [ownerId] 
         * @param {string} [ownerType] 
         * @param {string} [type] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentIndex: async (page?: number, size?: number, ownerId?: number, ownerType?: string, type?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/documents`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['owner_id'] = ownerId;
            }

            if (ownerType !== undefined) {
                localVarQueryParameter['owner_type'] = ownerType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary preview the document specified (only available in non-production environments)
         * @param {number} id 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentPreview: async (id: number, range?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentPreview', 'id', id)
            const localVarPath = changeUrl(`/api/documents/{id}/preview_download`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (range !== undefined && range !== null) {
                localVarHeaderParameter['Range'] = String(range);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieve a document by id
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentShow: async (id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentShow', 'id', id)
            const localVarPath = changeUrl(`/api/documents/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a document by id
         * @param {number} id 
         * @param {DocumentUpdateRequestContract} documentUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentUpdate: async (id: number, documentUpdateRequestContract: DocumentUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentUpdate', 'id', id)
            // verify required parameter 'documentUpdateRequestContract' is not null or undefined
            assertParamExists('documentUpdate', 'documentUpdateRequestContract', documentUpdateRequestContract)
            const localVarPath = changeUrl(`/api/documents/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates a document
         * @param {File} file 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {Array<string>} [includeField] 
         * @param {string} [displayName] 
         * @param {number} [folderId] 
         * @param {CodebookOrString} [category] 
         * @param {CodebookOrString} [ownerType] 
         * @param {number} [ownerId] 
         * @param {boolean} [downloadable] 
         * @param {boolean} [watermark] 
         * @param {CodebookOrString} [state] 
         * @param {string} [number] 
         * @param {string} [expirationDate] 
         * @param {string} [dateOfBirth] 
         * @param {CodebookOrString} [issuingCountry] 
         * @param {CodebookOrString} [signature] 
         * @param {CodebookOrString} [nationality] 
         * @param {Array<CodebookOrString>} [signers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentCreate(file: File, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, includeField?: Array<string>, displayName?: string, folderId?: number, category?: CodebookOrString, ownerType?: CodebookOrString, ownerId?: number, downloadable?: boolean, watermark?: boolean, state?: CodebookOrString, number?: string, expirationDate?: string, dateOfBirth?: string, issuingCountry?: CodebookOrString, signature?: CodebookOrString, nationality?: CodebookOrString, signers?: Array<CodebookOrString>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentCreate(file, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, includeField, displayName, folderId, category, ownerType, ownerId, downloadable, watermark, state, number, expirationDate, dateOfBirth, issuingCountry, signature, nationality, signers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a document by id
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentDestroy(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroySuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentDestroy(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary downloads the document specified
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentDownload(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentDownload(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list documents
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [ownerId] 
         * @param {string} [ownerType] 
         * @param {string} [type] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentIndex(page?: number, size?: number, ownerId?: number, ownerType?: string, type?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentIndex(page, size, ownerId, ownerType, type, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary preview the document specified (only available in non-production environments)
         * @param {number} id 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentPreview(id: number, range?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentPreview(id, range, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary retrieve a document by id
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentShow(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a document by id
         * @param {number} id 
         * @param {DocumentUpdateRequestContract} documentUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentUpdate(id: number, documentUpdateRequestContract: DocumentUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentUpdate(id, documentUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * 
         * @summary creates a document
         * @param {File} file 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {Array<string>} [includeField] 
         * @param {string} [displayName] 
         * @param {number} [folderId] 
         * @param {CodebookOrString} [category] 
         * @param {CodebookOrString} [ownerType] 
         * @param {number} [ownerId] 
         * @param {boolean} [downloadable] 
         * @param {boolean} [watermark] 
         * @param {CodebookOrString} [state] 
         * @param {string} [number] 
         * @param {string} [expirationDate] 
         * @param {string} [dateOfBirth] 
         * @param {CodebookOrString} [issuingCountry] 
         * @param {CodebookOrString} [signature] 
         * @param {CodebookOrString} [nationality] 
         * @param {Array<CodebookOrString>} [signers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCreate(file: File, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, includeField?: Array<string>, displayName?: string, folderId?: number, category?: CodebookOrString, ownerType?: CodebookOrString, ownerId?: number, downloadable?: boolean, watermark?: boolean, state?: CodebookOrString, number?: string, expirationDate?: string, dateOfBirth?: string, issuingCountry?: CodebookOrString, signature?: CodebookOrString, nationality?: CodebookOrString, signers?: Array<CodebookOrString>, options?: any): AxiosPromise<DocumentAggregate> {
            return localVarFp.documentCreate(file, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, includeField, displayName, folderId, category, ownerType, ownerId, downloadable, watermark, state, number, expirationDate, dateOfBirth, issuingCountry, signature, nationality, signers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a document by id
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentDestroy(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<DestroySuccess> {
            return localVarFp.documentDestroy(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary downloads the document specified
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentDownload(id: number, options?: any): AxiosPromise<File> {
            return localVarFp.documentDownload(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list documents
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [ownerId] 
         * @param {string} [ownerType] 
         * @param {string} [type] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentIndex(page?: number, size?: number, ownerId?: number, ownerType?: string, type?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<DocumentIndex> {
            return localVarFp.documentIndex(page, size, ownerId, ownerType, type, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary preview the document specified (only available in non-production environments)
         * @param {number} id 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentPreview(id: number, range?: string, options?: any): AxiosPromise<string> {
            return localVarFp.documentPreview(id, range, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieve a document by id
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentShow(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<DocumentAggregate> {
            return localVarFp.documentShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a document by id
         * @param {number} id 
         * @param {DocumentUpdateRequestContract} documentUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentUpdate(id: number, documentUpdateRequestContract: DocumentUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<DocumentAggregate> {
            return localVarFp.documentUpdate(id, documentUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for documentCreate operation in DocumentApi.
 * @export
 * @interface DocumentApiDocumentCreateRequest
 */
export interface DocumentApiDocumentCreateRequest {
    /**
     * 
     * @type {File}
     * @memberof DocumentApiDocumentCreate
     */
    readonly file: File

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentCreate
     */
    readonly xUserRoleId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentApiDocumentCreate
     */
    readonly includeField?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentCreate
     */
    readonly displayName?: string

    /**
     * 
     * @type {number}
     * @memberof DocumentApiDocumentCreate
     */
    readonly folderId?: number

    /**
     * 
     * @type {CodebookOrString}
     * @memberof DocumentApiDocumentCreate
     */
    readonly category?: CodebookOrString

    /**
     * 
     * @type {CodebookOrString}
     * @memberof DocumentApiDocumentCreate
     */
    readonly ownerType?: CodebookOrString

    /**
     * 
     * @type {number}
     * @memberof DocumentApiDocumentCreate
     */
    readonly ownerId?: number

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiDocumentCreate
     */
    readonly downloadable?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiDocumentCreate
     */
    readonly watermark?: boolean

    /**
     * 
     * @type {CodebookOrString}
     * @memberof DocumentApiDocumentCreate
     */
    readonly state?: CodebookOrString

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentCreate
     */
    readonly number?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentCreate
     */
    readonly expirationDate?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentCreate
     */
    readonly dateOfBirth?: string

    /**
     * 
     * @type {CodebookOrString}
     * @memberof DocumentApiDocumentCreate
     */
    readonly issuingCountry?: CodebookOrString

    /**
     * 
     * @type {CodebookOrString}
     * @memberof DocumentApiDocumentCreate
     */
    readonly signature?: CodebookOrString

    /**
     * 
     * @type {CodebookOrString}
     * @memberof DocumentApiDocumentCreate
     */
    readonly nationality?: CodebookOrString

    /**
     * 
     * @type {Array<CodebookOrString>}
     * @memberof DocumentApiDocumentCreate
     */
    readonly signers?: Array<CodebookOrString>
}

/**
 * Request parameters for documentDestroy operation in DocumentApi.
 * @export
 * @interface DocumentApiDocumentDestroyRequest
 */
export interface DocumentApiDocumentDestroyRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentApiDocumentDestroy
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentDestroy
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentDestroy
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentDestroy
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentDestroy
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentDestroy
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentDestroy
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for documentDownload operation in DocumentApi.
 * @export
 * @interface DocumentApiDocumentDownloadRequest
 */
export interface DocumentApiDocumentDownloadRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentApiDocumentDownload
     */
    readonly id: number
}

/**
 * Request parameters for documentIndex operation in DocumentApi.
 * @export
 * @interface DocumentApiDocumentIndexRequest
 */
export interface DocumentApiDocumentIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentApiDocumentIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DocumentApiDocumentIndex
     */
    readonly size?: number

    /**
     * 
     * @type {number}
     * @memberof DocumentApiDocumentIndex
     */
    readonly ownerId?: number

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentIndex
     */
    readonly ownerType?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentIndex
     */
    readonly type?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for documentPreview operation in DocumentApi.
 * @export
 * @interface DocumentApiDocumentPreviewRequest
 */
export interface DocumentApiDocumentPreviewRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentApiDocumentPreview
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentPreview
     */
    readonly range?: string
}

/**
 * Request parameters for documentShow operation in DocumentApi.
 * @export
 * @interface DocumentApiDocumentShowRequest
 */
export interface DocumentApiDocumentShowRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentApiDocumentShow
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for documentUpdate operation in DocumentApi.
 * @export
 * @interface DocumentApiDocumentUpdateRequest
 */
export interface DocumentApiDocumentUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentApiDocumentUpdate
     */
    readonly id: number

    /**
     * 
     * @type {DocumentUpdateRequestContract}
     * @memberof DocumentApiDocumentUpdate
     */
    readonly documentUpdateRequestContract: DocumentUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiDocumentUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * 
     * @summary creates a document
     * @param {DocumentApiDocumentCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentCreate(requestParameters: DocumentApiDocumentCreateRequest, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentCreate(requestParameters.file, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, requestParameters.includeField, requestParameters.displayName, requestParameters.folderId, requestParameters.category, requestParameters.ownerType, requestParameters.ownerId, requestParameters.downloadable, requestParameters.watermark, requestParameters.state, requestParameters.number, requestParameters.expirationDate, requestParameters.dateOfBirth, requestParameters.issuingCountry, requestParameters.signature, requestParameters.nationality, requestParameters.signers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a document by id
     * @param {DocumentApiDocumentDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentDestroy(requestParameters: DocumentApiDocumentDestroyRequest, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentDestroy(requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary downloads the document specified
     * @param {DocumentApiDocumentDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentDownload(requestParameters: DocumentApiDocumentDownloadRequest, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentDownload(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list documents
     * @param {DocumentApiDocumentIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentIndex(requestParameters: DocumentApiDocumentIndexRequest = {}, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentIndex(requestParameters.page, requestParameters.size, requestParameters.ownerId, requestParameters.ownerType, requestParameters.type, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary preview the document specified (only available in non-production environments)
     * @param {DocumentApiDocumentPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentPreview(requestParameters: DocumentApiDocumentPreviewRequest, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentPreview(requestParameters.id, requestParameters.range, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieve a document by id
     * @param {DocumentApiDocumentShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentShow(requestParameters: DocumentApiDocumentShowRequest, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentShow(requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a document by id
     * @param {DocumentApiDocumentUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentUpdate(requestParameters: DocumentApiDocumentUpdateRequest, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentUpdate(requestParameters.id, requestParameters.documentUpdateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type DocumentCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const DocumentCreateErrorCodes = [
    401,
    422,
];

export type DocumentDestroyErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const DocumentDestroyErrorCodes = [
    401,
];

export type DocumentDownloadErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const DocumentDownloadErrorCodes = [
    401,
];

export type DocumentIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const DocumentIndexErrorCodes = [
    401,
];

export type DocumentPreviewErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 443;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const DocumentPreviewErrorCodes = [
    401,
    443,
];

export type DocumentShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const DocumentShowErrorCodes = [
    401,
];

export type DocumentUpdateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const DocumentUpdateErrorCodes = [
    401,
];


