import React from 'react';
import { useTheme } from 'styled-components';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Heading } from '@npm/core/ui/components/atoms/Typography';

import { type OboModal } from '../OboModal';

import * as S from './TitleWithTooltip.styles';

type Props = {
  variant: React.ComponentProps<typeof OboModal>['variant'];
};

export const TitleWithTooltip = ({ variant }: Props) => {
  const theme = useTheme();

  return (
    <Heading variant="h3">
      {variant === 'regular'
        ? 'Act on behalf of an account'
        : 'Trade on behalf of an account'}{' '}
      <Tooltip
        title={
          variant === 'regular'
            ? 'In order to be able to trade you must be acting on behalf of one of your client accounts.'
            : 'In order to be able to access Negotiations you must be acting on behalf of one of your client accounts. '
        }
        placement="bottom"
      >
        <S.TooltipIconWrapper>
          <Icon
            name="info-circle"
            size="xs"
            color={theme.color.info.typography.primary}
          />
        </S.TooltipIconWrapper>
      </Tooltip>
    </Heading>
  );
};
