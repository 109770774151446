/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalStockClassIndex } from '../model';
// @ts-ignore
import { InternalStockClassShow } from '../model';
// @ts-ignore
import { InternalStockClassUpdateAggregate } from '../model';
// @ts-ignore
import { InternalStockClassUpdateRequestContract } from '../model';
// @ts-ignore
import { StockClassIndex } from '../model';
/**
 * StockClassesApi - axios parameter creator
 * @export
 */
export const StockClassesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get all the stocks classes for a company
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyStockClasses: async (companyId: string, page?: number, size?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyStockClasses', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/companies/{company_id}/stock_classes`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all StockClasses
         * @param {boolean} [current] 
         * @param {string} [date] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalAllStockClassesIndex: async (current?: boolean, date?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/internal/stock_classes`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (current !== undefined) {
                localVarQueryParameter['current'] = current;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary remove a StockClass
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalStockClassRemove: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalStockClassRemove', 'id', id)
            const localVarPath = changeUrl(`/api/internal/stock_classes/{id}/remove`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a single StockClass
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalStockClassShow: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalStockClassShow', 'id', id)
            const localVarPath = changeUrl(`/api/internal/stock_classes/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a StockClass
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalStockClassUpdateRequestContract} [internalStockClassUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalStockClassUpdate: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalStockClassUpdateRequestContract?: InternalStockClassUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalStockClassUpdate', 'id', id)
            const localVarPath = changeUrl(`/api/internal/stock_classes/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalStockClassUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get StockClasses for a company
         * @param {string} companyId 
         * @param {boolean} [current] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalStockClassesIndex: async (companyId: string, current?: boolean, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalStockClassesIndex', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/stock_classes`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (current !== undefined) {
                localVarQueryParameter['current'] = current;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockClassesApi - functional programming interface
 * @export
 */
export const StockClassesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockClassesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get all the stocks classes for a company
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyStockClasses(companyId: string, page?: number, size?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockClassIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyStockClasses(companyId, page, size, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all StockClasses
         * @param {boolean} [current] 
         * @param {string} [date] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalAllStockClassesIndex(current?: boolean, date?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalStockClassIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalAllStockClassesIndex(current, date, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary remove a StockClass
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalStockClassRemove(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalStockClassRemove(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a single StockClass
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalStockClassShow(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalStockClassShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalStockClassShow(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a StockClass
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalStockClassUpdateRequestContract} [internalStockClassUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalStockClassUpdate(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalStockClassUpdateRequestContract?: InternalStockClassUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalStockClassUpdateAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalStockClassUpdate(id, xNPMAuthorization, xSubscriptionPlan, internalStockClassUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get StockClasses for a company
         * @param {string} companyId 
         * @param {boolean} [current] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalStockClassesIndex(companyId: string, current?: boolean, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalStockClassIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalStockClassesIndex(companyId, current, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StockClassesApi - factory interface
 * @export
 */
export const StockClassesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockClassesApiFp(configuration)
    return {
        /**
         * 
         * @summary get all the stocks classes for a company
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyStockClasses(companyId: string, page?: number, size?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<StockClassIndex> {
            return localVarFp.companyStockClasses(companyId, page, size, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all StockClasses
         * @param {boolean} [current] 
         * @param {string} [date] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalAllStockClassesIndex(current?: boolean, date?: string, page?: number, size?: number, options?: any): AxiosPromise<InternalStockClassIndex> {
            return localVarFp.internalAllStockClassesIndex(current, date, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary remove a StockClass
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalStockClassRemove(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalStockClassRemove(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a single StockClass
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalStockClassShow(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalStockClassShow> {
            return localVarFp.internalStockClassShow(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a StockClass
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalStockClassUpdateRequestContract} [internalStockClassUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalStockClassUpdate(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalStockClassUpdateRequestContract?: InternalStockClassUpdateRequestContract, options?: any): AxiosPromise<InternalStockClassUpdateAggregate> {
            return localVarFp.internalStockClassUpdate(id, xNPMAuthorization, xSubscriptionPlan, internalStockClassUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get StockClasses for a company
         * @param {string} companyId 
         * @param {boolean} [current] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalStockClassesIndex(companyId: string, current?: boolean, date?: string, options?: any): AxiosPromise<InternalStockClassIndex> {
            return localVarFp.internalStockClassesIndex(companyId, current, date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for companyStockClasses operation in StockClassesApi.
 * @export
 * @interface StockClassesApiCompanyStockClassesRequest
 */
export interface StockClassesApiCompanyStockClassesRequest {
    /**
     * 
     * @type {string}
     * @memberof StockClassesApiCompanyStockClasses
     */
    readonly companyId: string

    /**
     * 
     * @type {number}
     * @memberof StockClassesApiCompanyStockClasses
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof StockClassesApiCompanyStockClasses
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiCompanyStockClasses
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiCompanyStockClasses
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalAllStockClassesIndex operation in StockClassesApi.
 * @export
 * @interface StockClassesApiInternalAllStockClassesIndexRequest
 */
export interface StockClassesApiInternalAllStockClassesIndexRequest {
    /**
     * 
     * @type {boolean}
     * @memberof StockClassesApiInternalAllStockClassesIndex
     */
    readonly current?: boolean

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiInternalAllStockClassesIndex
     */
    readonly date?: string

    /**
     * 
     * @type {number}
     * @memberof StockClassesApiInternalAllStockClassesIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof StockClassesApiInternalAllStockClassesIndex
     */
    readonly size?: number
}

/**
 * Request parameters for internalStockClassRemove operation in StockClassesApi.
 * @export
 * @interface StockClassesApiInternalStockClassRemoveRequest
 */
export interface StockClassesApiInternalStockClassRemoveRequest {
    /**
     * 
     * @type {number}
     * @memberof StockClassesApiInternalStockClassRemove
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiInternalStockClassRemove
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiInternalStockClassRemove
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalStockClassShow operation in StockClassesApi.
 * @export
 * @interface StockClassesApiInternalStockClassShowRequest
 */
export interface StockClassesApiInternalStockClassShowRequest {
    /**
     * 
     * @type {number}
     * @memberof StockClassesApiInternalStockClassShow
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiInternalStockClassShow
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiInternalStockClassShow
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalStockClassUpdate operation in StockClassesApi.
 * @export
 * @interface StockClassesApiInternalStockClassUpdateRequest
 */
export interface StockClassesApiInternalStockClassUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof StockClassesApiInternalStockClassUpdate
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiInternalStockClassUpdate
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiInternalStockClassUpdate
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {InternalStockClassUpdateRequestContract}
     * @memberof StockClassesApiInternalStockClassUpdate
     */
    readonly internalStockClassUpdateRequestContract?: InternalStockClassUpdateRequestContract
}

/**
 * Request parameters for internalStockClassesIndex operation in StockClassesApi.
 * @export
 * @interface StockClassesApiInternalStockClassesIndexRequest
 */
export interface StockClassesApiInternalStockClassesIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof StockClassesApiInternalStockClassesIndex
     */
    readonly companyId: string

    /**
     * 
     * @type {boolean}
     * @memberof StockClassesApiInternalStockClassesIndex
     */
    readonly current?: boolean

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiInternalStockClassesIndex
     */
    readonly date?: string
}

/**
 * StockClassesApi - object-oriented interface
 * @export
 * @class StockClassesApi
 * @extends {BaseAPI}
 */
export class StockClassesApi extends BaseAPI {
    /**
     * 
     * @summary get all the stocks classes for a company
     * @param {StockClassesApiCompanyStockClassesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockClassesApi
     */
    public companyStockClasses(requestParameters: StockClassesApiCompanyStockClassesRequest, options?: AxiosRequestConfig) {
        return StockClassesApiFp(this.configuration).companyStockClasses(requestParameters.companyId, requestParameters.page, requestParameters.size, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all StockClasses
     * @param {StockClassesApiInternalAllStockClassesIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockClassesApi
     */
    public internalAllStockClassesIndex(requestParameters: StockClassesApiInternalAllStockClassesIndexRequest = {}, options?: AxiosRequestConfig) {
        return StockClassesApiFp(this.configuration).internalAllStockClassesIndex(requestParameters.current, requestParameters.date, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary remove a StockClass
     * @param {StockClassesApiInternalStockClassRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockClassesApi
     */
    public internalStockClassRemove(requestParameters: StockClassesApiInternalStockClassRemoveRequest, options?: AxiosRequestConfig) {
        return StockClassesApiFp(this.configuration).internalStockClassRemove(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a single StockClass
     * @param {StockClassesApiInternalStockClassShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockClassesApi
     */
    public internalStockClassShow(requestParameters: StockClassesApiInternalStockClassShowRequest, options?: AxiosRequestConfig) {
        return StockClassesApiFp(this.configuration).internalStockClassShow(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a StockClass
     * @param {StockClassesApiInternalStockClassUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockClassesApi
     */
    public internalStockClassUpdate(requestParameters: StockClassesApiInternalStockClassUpdateRequest, options?: AxiosRequestConfig) {
        return StockClassesApiFp(this.configuration).internalStockClassUpdate(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.internalStockClassUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get StockClasses for a company
     * @param {StockClassesApiInternalStockClassesIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockClassesApi
     */
    public internalStockClassesIndex(requestParameters: StockClassesApiInternalStockClassesIndexRequest, options?: AxiosRequestConfig) {
        return StockClassesApiFp(this.configuration).internalStockClassesIndex(requestParameters.companyId, requestParameters.current, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }
}

export type CompanyStockClassesErrorTypes =

    | {
        status: 404;
        data: StockClassIndex;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompanyStockClassesErrorCodes = [
    404,
];

export type InternalAllStockClassesIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalAllStockClassesIndexErrorCodes = [
    401,
];

export type InternalStockClassRemoveErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalStockClassRemoveErrorCodes = [
    401,
    404,
];

export type InternalStockClassShowErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalStockClassShowErrorCodes = [
    401,
    404,
];

export type InternalStockClassUpdateErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalStockClassUpdateErrorCodes = [
    401,
    404,
];

export type InternalStockClassesIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalStockClassesIndexErrorCodes = [
    401,
    404,
];


