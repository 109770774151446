import React from 'react';

import { Icon } from '../../Icon';

import * as S from './SearchPlaceholder.styles';

type Props = {
  placeholder?: React.ReactNode;
  showIcon?: boolean;
};

export const SearchPlaceholder = ({ placeholder, showIcon = true }: Props) => {
  return (
    <S.SearchPlaceholder>
      {showIcon && <Icon name="search" size="sm" />}
      {placeholder}
    </S.SearchPlaceholder>
  );
};
