import { type IconNames } from '@npm/utils';

import { Codebooks } from './codebooks.types';

const items = {
  historical: 'historical',
} as const;

export type OrderSourceCode = keyof typeof items;

const iconMap: Record<OrderSourceCode, IconNames> = {
  historical: 'history',
};

export const CbOrderSource = {
  code: Codebooks.ORDER_SOURCE,
  items,
  iconMap,
};
