import React from 'react';
import { Col, Row } from 'antd';
import { useTheme } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { downloadBlob } from '@npm/core/ui/utils/apiHelper';
import { truncateFilename } from '@npm/core/ui/utils/formatters';
import {
  type DocumentAggregate,
  useDocumentDownloadLazy,
} from '@npm/data-access';

import { DocumentIcon } from '../DocumentIcon/DocumentIcon';
import { DocumentViewerModal, useDocumentViewer } from '../DocumentViewer';

import * as S from './DocumentPreview.styles';

type Props = {
  document: DocumentAggregate;
  labelMaxCharacters?: number;
};

export const DocumentPreview = ({ document, labelMaxCharacters }: Props) => {
  const theme = useTheme();
  const { isMobile } = useBreakpoints();

  const { documentViewerModalProps, showNpmDocument } = useDocumentViewer();

  const [executeDownload] = useDocumentDownloadLazy({
    config: {
      axiosConfig: { responseType: 'blob' },
    },
  });

  const handleDownload = async () => {
    try {
      const data = await executeDownload({ variables: { id: document.id } });
      downloadBlob(data, document.display_name);
    } catch (e) {
      console.error(e);
    }
  };

  const handleShow = () => showNpmDocument(document);

  if (isMobile) {
    return (
      <>
        <Row style={{ gap: theme.spacing.sm }}>
          <Button
            size="sm"
            variant={'outline'}
            title={'test'}
            icon={<Icon name="eye" />}
            onClick={handleShow}
          />

          {document.downloadable && (
            <Button
              size="sm"
              variant={'outline'}
              title={'test'}
              icon={<Icon name="download" />}
              onClick={handleDownload}
            />
          )}
        </Row>
        <DocumentViewerModal {...documentViewerModalProps} />
      </>
    );
  }

  return (
    <Row gutter={[theme.spacing.xs, theme.spacing.xs]}>
      <Col>
        <S.TextButton
          size="sm"
          icon={<DocumentIcon content_type={document.content_type} />}
          onClick={handleShow}
        >
          {truncateFilename(document.display_name, labelMaxCharacters || 45)}
        </S.TextButton>
        <DocumentViewerModal {...documentViewerModalProps} />
      </Col>
      {document.downloadable && (
        <Col>
          <S.TextButton
            size="sm"
            icon={<Icon name="download" size="xs" />}
            onClick={handleDownload}
          >
            Download
          </S.TextButton>
        </Col>
      )}
    </Row>
  );
};
