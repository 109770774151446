import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { type FormInstance } from 'antd';

import { getErrorFieldsNames } from '@npm/core/ui/components/atoms/Form/Form.utils';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { useCollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import {
  handleApiValidationError,
  handleValidationError,
} from '@npm/core/ui/utils/form';
import { parseStringToDate } from '@npm/core/ui/utils/formatters';
import {
  type DocumentApiDocumentCreateRequest,
  type HoldingApiHoldingShowRequest,
  type HoldingApiHoldingUpdateRequest,
  CbDocumentType,
  CbOwnerType,
  useDocumentCreate,
  useHoldingShow,
  useHoldingUpdate,
  useIssuerEntityHoldingCreate,
} from '@npm/data-access';

import { useObo } from '../../auth/user/role';
import { useCurrentRole } from '../../auth/user/role/hooks/useCurrentRole';
import { type OboDefinition } from '../../auth/user/role/userRole.types';

import { type HoldingCreateForm } from './HoldingForm.types';
import { prepareCreateRequestParams } from './HoldingForm.utils';

export type HoldingFormHookArgs = {
  form: FormInstance<HoldingCreateForm>;
  id?: HoldingApiHoldingShowRequest['id'];
  onUpdate?: (data: { id: number }) => void;
  onAddHoldingUploadFailed?: (id: number) => void;
  oboOverride?: OboDefinition;
};

export const useHoldingForm = ({
  form,
  id,
  onUpdate,
  onAddHoldingUploadFailed,
  oboOverride,
}: HoldingFormHookArgs) => {
  const role = useCurrentRole();
  const { oboAccount: globalOboAccount } = useObo();
  const oboAccount = oboOverride?.account ?? globalOboAccount;

  const { setActiveKeys, ...args } = useCollapsibleFormSection({});

  const currentRegisteredName = oboAccount?.name || role?.subject?.name;

  const { showAlert, clearAlerts, withShowApiErrorMiddleware } = useAlerts();

  const queryClient = useQueryClient();

  const { data, isFetching: isLoading } = useHoldingShow(
    { id },
    {
      queryConfig: { enabled: Boolean(id) },
    }
  );

  const { execute: create, isLoading: isCreating } =
    useIssuerEntityHoldingCreate();

  const { execute: update, isLoading: isUpdating } = useHoldingUpdate();

  const { execute: createDocument, isLoading: isUploading } =
    useDocumentCreate();

  const uploadProofOfOwnership = async (
    doc: File,
    ownerId: DocumentApiDocumentCreateRequest['ownerId']
  ) => {
    await withShowApiErrorMiddleware(createDocument)({
      file: doc,
      category: CbDocumentType.items.proof_of_ownership_document,
      ownerType: CbOwnerType.items.Holding,
      ownerId,
      displayName: doc.name,
    });
  };

  const createHolding = async (
    params: HoldingApiHoldingUpdateRequest,
    proof_of_ownership_document: File
  ) => {
    const result = await withShowApiErrorMiddleware(create)(params);

    try {
      if (proof_of_ownership_document?.name) {
        await uploadProofOfOwnership(proof_of_ownership_document, result.id);
      }

      // invalidating manually because of proof of ownership upload which needs to happen after creating the holding
      await queryClient.invalidateQueries({ queryKey: ['HoldingIndex'] });

      onUpdate?.(result);

      Notification.success({
        message: 'New holding has been successfully added.',
      });

      form.resetFields();
      form.setFieldsValue({
        registered_name: currentRegisteredName,
      });
    } catch (e) {
      // if holdings is created successfully but upload fails, stay in the drawer
      // change it to "edit" mode and show success alert inside the drawer
      onAddHoldingUploadFailed(result.id);
      showAlert('New holding has been successfully added.', {
        type: 'success',
      });

      console.error(e);
    }
  };

  const updateHolding = async (
    params: HoldingApiHoldingUpdateRequest,
    proof_of_ownership_document: File
  ) => {
    if (proof_of_ownership_document?.name) {
      await uploadProofOfOwnership(proof_of_ownership_document, id);
    }

    const result = await withShowApiErrorMiddleware(update)(
      { ...params, id },
      { nullValuesHandling: 'KEEP_ALL' }
    );

    onUpdate?.(result);

    Notification.success({
      message: 'The holding has been successfully edited.',
    });
  };

  const submit = async () => {
    let values: HoldingCreateForm;

    clearAlerts();

    try {
      values = await form.validateFields();
    } catch (e) {
      const errorFields = getErrorFieldsNames(e);
      setActiveKeys(activeKeys => [...activeKeys, ...errorFields]);
      return handleValidationError(form, e);
    }

    try {
      const { proof_of_ownership_document, ...holdingValues } = values;
      const requestParams: HoldingApiHoldingUpdateRequest = {
        ...prepareCreateRequestParams(holdingValues),
        ...(oboOverride && {
          xOboAccountId: oboOverride.account?.id?.toString(),
          xOboUserId: oboOverride.representative?.user_id?.toString(),
        }),
      };

      if (id) {
        await updateHolding(requestParams, proof_of_ownership_document);
      } else {
        await createHolding(requestParams, proof_of_ownership_document);
      }
    } catch (e) {
      handleApiValidationError(form, e);
      console.error(e);
    }
  };

  // load initial form data if available
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        type: data.asset.type?.code,
        underlying_holding_type: data.underlying_holding_type?.code,
        series: data.asset.series,
        plan: data.asset.plan,
        strike_price: data.asset.strike_price,
        grant_type: data.grant_type?.code,
        acquisition_date:
          data.acquisition_date && parseStringToDate(data.acquisition_date),
        expiration_date:
          data.expiration_date && parseStringToDate(data.expiration_date),
        grant_date: data.grant_date && parseStringToDate(data.grant_date),
        registered_name: data.registered_name,
        id: data.issuer_entity?.id,
      });
    }
  }, [data]);

  return {
    form,
    data,
    submit,
    isLoading,
    isUpdating: isCreating || isUpdating || isUploading,
    collapsibleSectionArgs: { setActiveKeys, ...args },
  };
};
