import React, { memo, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import { useOnClickOutside } from 'usehooks-ts';

import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { LogoShort } from '../../atoms/Logo';
import { Link, useLocation } from '../../molecules/Link';

import { useSidebarStore } from './Sidebar/Sidebar.store';
import { HamburgerMenu } from './HamburgerMenu';
import { useLayoutStore } from './Layout.store';
import { Layout, Sidebar, SidebarTopContainer, useLayoutConfig } from '.';

import { SIDEBAR_COLLAPSED_ITEM_WIDTH } from './Sidebar/Sidebar.styles';

type Props = {
  children: React.ReactNode;
  renderMenu: (isSidebarCollapsed: boolean) => React.ReactNode;
  mobileMenuType?: React.ComponentProps<typeof HamburgerMenu>['mobileMenuType'];
  showHamburgerMenu?: boolean;
  collapsible?: boolean;
  logoLinkUrl: string;
};

export const SidebarLayout = memo(function InvestorLayout({
  children,
  renderMenu,
  mobileMenuType,
  showHamburgerMenu = true,
  collapsible,
  logoLinkUrl,
}: Props) {
  const theme = useTheme();
  const { isMobile, isTablet } = useLayoutConfig();
  const { isXlDesktop } = useBreakpoints();
  const layoutStoreCollapsible = useLayoutStore(
    store => store.isSidebarCollapsible
  );

  const isHamburgerMenu = (isMobile || isTablet) && showHamburgerMenu;
  const isSidebarCollapsible =
    ((collapsible ?? layoutStoreCollapsible) || !isXlDesktop) &&
    !isHamburgerMenu;

  const { isSidebarCollapsed, setIsSidebarCollapsed } = useSidebarStore(
    store => ({
      isSidebarCollapsed: store.isCollapsed,
      setIsSidebarCollapsed: store.setIsCollapsed,
    })
  );

  const logoRendered = (
    <LogoShort
      width={isSidebarCollapsed ? SIDEBAR_COLLAPSED_ITEM_WIDTH : null}
      height={null}
      style={{
        marginLeft: isSidebarCollapsed ? 0 : theme.spacing.md,
        marginTop: isSidebarCollapsed ? theme.spacing.xs : 0,
      }}
    />
  );

  if (!isSidebarCollapsible && isSidebarCollapsed) {
    setIsSidebarCollapsed(false);
  }
  const sidebarRef = useRef(null);
  useOnClickOutside(sidebarRef, () => setIsSidebarCollapsed(true));

  const location = useLocation();

  useEffect(() => {
    if (isSidebarCollapsible && !isSidebarCollapsed) {
      setIsSidebarCollapsed(true);
    }
  }, [isSidebarCollapsible, location.pathname]);

  return (
    <Layout>
      {(isMobile || isTablet) && showHamburgerMenu ? (
        <HamburgerMenu
          key="menu-mobile"
          mobileMenuType={mobileMenuType}
          logoLinkUrl={logoLinkUrl}
        >
          {renderMenu(false)}
        </HamburgerMenu>
      ) : (
        <div ref={sidebarRef}>
          <Sidebar
            collapsible={isSidebarCollapsible}
            collapsed={isSidebarCollapsed}
            style={{ zIndex: theme.zIndex.aboveDrawer }}
            onMouseEnter={() => setIsSidebarCollapsed(false)}
            onMouseLeave={() => setIsSidebarCollapsed(true)}
          >
            <SidebarTopContainer $isCollapsed={isSidebarCollapsed}>
              <Link to={logoLinkUrl}>{logoRendered}</Link>
            </SidebarTopContainer>
            {renderMenu(isSidebarCollapsed)}
          </Sidebar>
        </div>
      )}
      {children}
    </Layout>
  );
});
