import { useMemo } from 'react';

import { useUserRole } from '../context/userRole.context';

export const useCurrentBrokerageFirmId = () => {
  const userRole = useUserRole();

  return useMemo(() => {
    return userRole?.workstationType === 'brokerage'
      ? userRole?.subRole?.subject?.id
      : null;
  }, [userRole]);
};
