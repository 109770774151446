import { type ReactNode } from 'react';

import * as S from './UnsupportedResolutionScreen.styles';

type Props = {
  children?: ReactNode;
  content: ReactNode;
  isSupported: boolean;
};

export const UnsupportedResolutionScreen = ({
  children,
  content,
  isSupported,
}: Props) => {
  if (isSupported) {
    return <>{children}</>;
  }

  return (
    <S.Container>
      <S.Wrapper>{content}</S.Wrapper>
    </S.Container>
  );
};
