import { useBreakpoints } from '../../../hooks/useBreakpoints';

import { type StatisticsProps } from './Statistics.types';
import { StatisticsDesktop } from './StatisticsDesktop';
import { StatisticsMobile } from './StatisticsMobile';

export const Statistics = ({
  gutter = ['md', 'sm'],
  ...rest
}: StatisticsProps) => {
  const { isTablet, isMobile } = useBreakpoints();
  return isTablet || isMobile ? (
    <StatisticsMobile gutter={gutter} {...rest} />
  ) : (
    <StatisticsDesktop gutter={gutter} {...rest} />
  );
};
