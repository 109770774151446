import React from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { ShowMore } from '@npm/core/ui/components/molecules/ShowMore';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type VenusApi } from '@npm/data-access';

import {
  DESCRIPTION_COLLAPSED_LENGTH,
  DESCRIPTION_COLLAPSED_LENGTH_MOBILE,
} from '../../../CompanyOverview.constants';

type Props = {
  companyProfile?: VenusApi.CompanyProfile;
  defaultExpanded?: boolean;
};

export const Description = ({
  companyProfile,
  defaultExpanded = false,
}: Props) => {
  const { isMobile } = useBreakpoints();

  const collapsedLength = isMobile
    ? DESCRIPTION_COLLAPSED_LENGTH_MOBILE
    : DESCRIPTION_COLLAPSED_LENGTH;

  return (
    <div>
      <ShowMore
        defaultExpanded={defaultExpanded}
        renderCollapsed={
          <Text size="sm" as="span">
            {companyProfile?.description?.substring(0, collapsedLength)}...{' '}
          </Text>
        }
        renderExpanded={<Text size="sm">{companyProfile?.description}</Text>}
        collapsible={companyProfile?.description?.length > collapsedLength}
      />
    </div>
  );
};
