import type { MtmValue } from '../MutualFundMarksChart.types';
import { isCommonAssetType } from '../MutualFundMarksChart.utils';

const hasArrayMultipleUniqueValues = (values: string[]) => {
  return new Set(values).size > 1;
};

export const getMtmTooltipContentValues = (values: MtmValue[]) => {
  const value = values[0];

  const isCommon = isCommonAssetType(value.mutual_fund_asset_name);

  const parentName = hasArrayMultipleUniqueValues(
    values.map(item => item.mutual_fund_parent_name)
  )
    ? 'Multiple'
    : values[0].mutual_fund_parent_name;

  const assetTypeName = hasArrayMultipleUniqueValues(
    values.map(item => item.mutual_fund_asset_name)
  )
    ? isCommon
      ? 'Common'
      : 'Preferred'
    : values[0].mutual_fund_asset_name;

  const seriesName = hasArrayMultipleUniqueValues(
    values.map(item => item.mutual_fund_series_name)
  )
    ? 'Multiple'
    : values[0].mutual_fund_series_name;

  return {
    isCommon,
    reportingPeriod: value.reporting_period,
    pps: value.price_per_share,
    impliedValuation: value.implied_valuation,
    discountOrPremium: value.discount_or_premium,
    assetTypeName,
    parentName,
    seriesName,
  };
};
