import styled from 'styled-components';

import { useBreakpoint } from '../../../../utils/style';
import { ColumnsContainer } from '../../../atoms/ColumnsContainer';
import { TableVertical as TableVerticalBase } from '../../TableVertical';

export const Container = styled.div<{
  $variant?: 'primary' | 'secondary' | 'compact';
}>`
  background: ${({ theme, $variant }) =>
    $variant === 'secondary'
      ? theme.color.info.backgrounds.secondaryDefault
      : theme.color.general.layout.two};
`;

export const PaddedColumnsContainer = styled(ColumnsContainer)`
  padding: 12px 0;

  > div > div {
    margin: -12px 0;
  }

  ${({ theme }) => useBreakpoint(theme).tablet`
      padding: 0;
      > div > div {
        margin: 0;
      }
  `}
`;

export const TableVertical = styled(TableVerticalBase)<{
  $variant?: 'primary' | 'secondary' | 'compact';
}>`
  padding: 0
    ${({ theme, $variant }) =>
      $variant === 'secondary' ? 0 : theme.spacing.md}px;
  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: 0 ${theme.spacing.sm}px;
  `}
`;
