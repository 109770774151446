/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  FilingsPDFApi,
  FilingPDFShowErrorCodes,
  FilingPDFShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new FilingsPDFApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type FilingPDFShowMethod = FilingsPDFApi['filingPDFShow'];
export type FilingPDFShowResponseType = MethodResult<FilingPDFShowMethod>;

export const useFilingPDFShow = (
  variables: Parameters<FilingPDFShowMethod>[0],
  config?: UseQueryConfig<
    FilingPDFShowMethod,
    FilingPDFShowErrorTypes
  >
) => {
  return useQuery<
    FilingPDFShowMethod,
    FilingPDFShowErrorTypes
  >(
    (...args) => api.filingPDFShow(...args),
    FilingPDFShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FilingPDFShow'],
        ...apiQueryConfig.FilingPDFShow,
        ...config?.queryConfig
      },
    },
    'FilingPDFShow',
  );
};

export const useFilingPDFShowInfinite = (
  variables: Parameters<FilingPDFShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    FilingPDFShowMethod,
    FilingPDFShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    FilingPDFShowMethod,
    FilingPDFShowErrorTypes
  >(
    (...args) => api.filingPDFShow(...args),
    FilingPDFShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FilingPDFShow', 'FilingPDFShowInfinite'],
        ...apiQueryConfig.FilingPDFShow,
        ...config?.queryConfig,
      },
    },
    'FilingPDFShow',
  );
};

export const useFilingPDFShowLazy = (baseOptions?: {
  variables?: Parameters<FilingPDFShowMethod>[0],
  config?: UseQueryConfig<
    FilingPDFShowMethod,
    FilingPDFShowErrorTypes
  >
}) => {
  return useLazyQuery<
    FilingPDFShowMethod,
    FilingPDFShowErrorTypes
  >(
    (...args) => api.filingPDFShow(...args),
    FilingPDFShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['FilingPDFShow'],
        ...apiQueryConfig.FilingPDFShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'FilingPDFShow',
  );
};

