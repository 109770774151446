import styled from 'styled-components';

export const LineIcon = styled.div`
  width: ${({ theme }) => theme.icon.size.sm}px;
  height: 1px;
  background-color: ${({ theme }) => theme.color.general.typography.tertiary};
`;

export const PointIcon = styled.div<{ $color: 'info' | 'success' }>`
  width: ${({ theme }) => theme.icon.size.xxs / 2}px;
  height: ${({ theme }) => theme.icon.size.xxs / 2}px;
  border-radius: 50%;
  border: 1px solid
    ${({ theme, $color }) => theme.color[$color].typography.primary};
`;
