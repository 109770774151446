import React, { type ComponentProps } from 'react';

import { CodebooksCheckbox } from '../../smartSelects/CodebooksCheckbox';
import { type FilterItemProps } from '../FilterPanel';

type Props = FilterItemProps & ComponentProps<typeof CodebooksCheckbox>;

export const CodebooksCheckboxFilter = ({
  name,
  codebook,
  onFilterSubmit,
  onChange,
  ...rest
}: Props) => {
  return (
    <CodebooksCheckbox
      codebook={codebook}
      onChange={(...args) => {
        onChange(...args);
        onFilterSubmit?.(name);
      }}
      {...rest}
    />
  );
};
