import { Progress } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.md}px;
`;

export const ProgressRing = styled(Progress)`
  .ant-progress-circle-trail {
    stroke: ${({ theme }) => theme.color.general.borders.primary};
  }

  .ant-progress-text {
    display: none;
  }
`;
