import React from 'react';
import { useTheme } from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';

import { ProgressBar } from '@npm/core/ui/components/atoms/ProgressBar';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { AccountBadge } from '@npm/core/ui/components/molecules/AccountBadge';
import { FullScreenLoading } from '@npm/core/ui/components/molecules/FullScreenLoading';
import { Disclosure } from '@npm/core/ui/components/organisms/WizardNew/Disclosure';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { WizardWrapper } from '@npm/core/ui/components/organisms/WizardNew/WizardWrapper';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import { useCurrentAccount } from '../auth/user/role';

import { usePostOnboardingContext } from './hooks';
import { EXIT_ROUTE_KEY } from './PostOnboarding.constants';
import { type PostOnboardingContext } from './PostOnboarding.types';
import { getActiveStepQueryParamConfig } from './PostOnboarding.utils';
import { Sidebar } from './Sidebar';

import * as S from './PostOnboardingWizardWrapper.styles';

export const ACTIVE_STEP_QUERY_PARAM_KEY = 'activeStep';

export const PostOnboardingWizardWrapper: React.FC = ({ children }) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useBreakpoints();

  const { isStepperMobileOpen, activeSectionProps, account, steps } =
    useWizardStore((s: WizardStore<PostOnboardingContext>) => ({
      isStepperMobileOpen: s.isStepperMobileOpen,
      activeSectionProps: s.data.activeSectionProps,
      account: s.data.account,
      steps: s.steps,
    }));
  const { accountId } = useCurrentAccount();

  const [exitRoute = '/investor-workstation'] = useQueryParam(
    EXIT_ROUTE_KEY,
    StringParam
  );

  // handle return to a specific step from outside of the app (i.e. docusign - agreements steps)
  const [activeStepQueryParam] = useQueryParam(
    'activeStep',
    getActiveStepQueryParamConfig(steps)
  );

  const {
    isContextLoading,
    sectionsConfig,
    successModals,
    firstIncompleteStep,
  } = usePostOnboardingContext(accountId);

  if (isContextLoading) {
    return <FullScreenLoading loadingTitle="Loading Account Onboarding..." />;
  }

  return (
    <>
      {!(isMobile || isTablet) && <S.Background />}
      <WizardWrapper
        wizardLayoutProps={{
          title: 'Onboarding',
          titleExtra: <AccountBadge account={account} />,
          exitRoute,
          renderSidebar: props => (
            <Sidebar {...props} sidebarConfig={sectionsConfig} />
          ),
          subHeader:
            !isStepperMobileOpen && (isMobile || isTablet) ? (
              <ProgressBar
                title={activeSectionProps?.activeSection.title}
                currentStep={activeSectionProps?.stepIndex}
                totalSteps={activeSectionProps?.totalSteps}
                stepBackground={theme.color.general.layout.two}
              />
            ) : (
              <Text size="sm" colorVariant="tertiary">
                {activeSectionProps?.activeSection?.title}
              </Text>
            ),
          isSubHeaderAffixed: false,
          expandablePanel: <Disclosure />,
        }}
        startIndex={activeStepQueryParam ?? firstIncompleteStep}
      >
        {children}
      </WizardWrapper>
      {successModals}
    </>
  );
};
