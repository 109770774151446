import type React from 'react';
import { type PaginationProps } from 'antd';

export type Pagination = { page?: number; size?: number };

export type Props<T extends Pagination> = {
  variables: T;
  setVariables: React.Dispatch<React.SetStateAction<T>>;
  paginationData?: Pagination & { total_records?: number };
  resetQueryOnUnmount?: boolean;
};

export type PaginationQueryParamReturnType = {
  query: number | undefined;
  setQuery: (value: number | undefined) => void;
  resetQuery: () => void;
};

export type PaginationReturnType = {
  pagination: PaginationProps;
  updatePaginationVariables: (pagination: PaginationProps) => void;
} & PaginationQueryParamReturnType;

export const paginationQueryKeys = [
  'accounts',
  'accountsCompanies',
  'accountsList',
  'admins',
  'agreementsSigned',
  'agreementsToSign',
  'authorizedSigners',
  'childOrders',
  'clientOrders',
  'capTable',
  'companies',
  'companiesOverviews',
  'dataRooms',
  'holdings',
  'iois',
  'issEntityAccounts',
  'matches',
  'marketOrders',
  'mutualFunds',
  'ndas',
  'negotiations',
  'oboAccounts',
  'orders',
  'permissionAccounts',
  'personOverview',
  'submissions',
  'users',
  'watchlist',
  'finActivities',
  'overviewUsers',
  'entityInvestorPortfolio',
] as const;

export type PaginationQueryKey = typeof paginationQueryKeys[number];
