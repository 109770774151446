import styled from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import {
  Drawer as BaseDrawer,
  DRAWER_CONTENT_ID,
  DRAWER_HEADER_CLASSNAME,
} from '@npm/core/ui/components/organisms/Drawer';
import { useBreakpoint } from '@npm/core/ui/utils/style';

import { DRAWER_HEADER_HEIGHT } from '@npm/core/ui/components/organisms/Drawer/Drawer.styles';

export const Drawer = styled(BaseDrawer)`
  #${DRAWER_CONTENT_ID} {
    padding-top: ${() => DRAWER_HEADER_HEIGHT}px;
    padding-right: 0;
    padding-left: 0;

    ${({ theme }) => useBreakpoint(theme).mobile`
      padding: 0;
    `}
  }

  & .${DRAWER_HEADER_CLASSNAME} {
    padding: 0 ${({ theme }) => theme.spacing.xs}px;
  }
`;

export const NoPaddingButton = styled(Button)`
  && {
    padding: 0;
  }
`;
