import React, { memo, useMemo } from 'react';

import {
  Navigation,
  NavigationMenu,
} from '@npm/core/ui/components/organisms/Layout';
import { useSidebarStore } from '@npm/core/ui/components/organisms/Layout/Sidebar/Sidebar.store';
import { filterMenuItemsByFeatureFlag } from '@npm/core/ui/routes';
import { useFeatureFlags } from '@npm/features/app/featureFlags/featureFlags.context';
import { useFeatureFlagMenuItem } from '@npm/features/app/featureFlags/Menu/useFeatureFlagMenuItem';
import { useHelpMenuItem } from '@npm/features/app/zendesk/menu/useHelpMenuItem';
import { useUserContextStore } from '@npm/features/auth/user/context';
import { useUserMenuItems } from '@npm/features/auth/user/menu/menuItems';
import { useObo, useUserRole } from '@npm/features/auth/user/role';
import { NotificationDrawer } from '@npm/features/notifications';
import { useNotificationMenuItem } from '@npm/features/notifications/NotificationMenuItem/useNotificationMenuItem';

import { getBrokerageSidebarItems } from './config/brokerage-workstation';

export const BrokerageMenu = memo(function BrokerageMenu() {
  const userContext = useUserContextStore(store => store);
  const userRole = useUserRole();
  const { oboAccount, oboRepresentative } = useObo();

  const { helpMenuItem, handleHelpMenuItemClick } = useHelpMenuItem();
  const userMenuItems = useUserMenuItems();

  const { featureFlagMenuItem, handleFeatureFlagMenuItemClick } =
    useFeatureFlagMenuItem();

  const isCollapsed = useSidebarStore(store => store.isCollapsed);

  const {
    notificationMenuItem,
    handleNotificationMenuItemClick,
    notificationDrawerProps,
  } = useNotificationMenuItem();

  const { isEnabled } = useFeatureFlags();

  const menuItems = useMemo(
    () =>
      filterMenuItemsByFeatureFlag(
        isEnabled,
        getBrokerageSidebarItems(
          userContext,
          userRole,
          {
            representative: oboRepresentative,
            account: oboAccount,
          },
          notificationMenuItem
        )
      ),
    [
      userContext,
      userRole,
      oboAccount,
      oboRepresentative,
      isEnabled,
      notificationMenuItem,
    ]
  );

  return (
    <NavigationMenu $isCollapsed={isCollapsed}>
      <Navigation
        menuItems={menuItems}
        handleClick={key => handleNotificationMenuItemClick(key)}
      />
      <Navigation
        menuItems={[featureFlagMenuItem, helpMenuItem, ...userMenuItems].filter(
          Boolean
        )}
        handleClick={key =>
          handleHelpMenuItemClick(key) || handleFeatureFlagMenuItemClick(key)
        }
      />
      <NotificationDrawer {...notificationDrawerProps} />
    </NavigationMenu>
  );
});
