/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EntityInvestorPortfolioIndex } from '../model';
/**
 * EntityInvestorPortfolioApi - axios parameter creator
 * @export
 */
export const EntityInvestorPortfolioApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves a list of issuers the current authorized user portfolio is associated with
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {boolean} [unverifiedHoldings] 
         * @param {boolean} [includeSpvs] 
         * @param {boolean} [watchlistOnly] 
         * @param {boolean} [portfolioOnly] 
         * @param {boolean} [watchlistHide] 
         * @param {number} [watchlistAccountId] 
         * @param {string} [sector] 
         * @param {string} [investor] 
         * @param {string} [totalFunding] 
         * @param {string} [latestDealSize] 
         * @param {string} [companyActive] 
         * @param {string} [latestValuation] 
         * @param {string} [lastFunded] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityInvestorPortfolioIndex: async (page?: number, size?: number, search?: string, unverifiedHoldings?: boolean, includeSpvs?: boolean, watchlistOnly?: boolean, portfolioOnly?: boolean, watchlistHide?: boolean, watchlistAccountId?: number, sector?: string, investor?: string, totalFunding?: string, latestDealSize?: string, companyActive?: string, latestValuation?: string, lastFunded?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/entity-investor-portfolio`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (unverifiedHoldings !== undefined) {
                localVarQueryParameter['unverified_holdings'] = unverifiedHoldings;
            }

            if (includeSpvs !== undefined) {
                localVarQueryParameter['include_spvs'] = includeSpvs;
            }

            if (watchlistOnly !== undefined) {
                localVarQueryParameter['watchlist_only'] = watchlistOnly;
            }

            if (portfolioOnly !== undefined) {
                localVarQueryParameter['portfolio_only'] = portfolioOnly;
            }

            if (watchlistHide !== undefined) {
                localVarQueryParameter['watchlist_hide'] = watchlistHide;
            }

            if (watchlistAccountId !== undefined) {
                localVarQueryParameter['watchlist_account_id'] = watchlistAccountId;
            }

            if (sector !== undefined) {
                localVarQueryParameter['sector'] = sector;
            }

            if (investor !== undefined) {
                localVarQueryParameter['investor'] = investor;
            }

            if (totalFunding !== undefined) {
                localVarQueryParameter['total_funding'] = totalFunding;
            }

            if (latestDealSize !== undefined) {
                localVarQueryParameter['latest_deal_size'] = latestDealSize;
            }

            if (companyActive !== undefined) {
                localVarQueryParameter['company_active'] = companyActive;
            }

            if (latestValuation !== undefined) {
                localVarQueryParameter['latest_valuation'] = latestValuation;
            }

            if (lastFunded !== undefined) {
                localVarQueryParameter['last_funded'] = lastFunded;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EntityInvestorPortfolioApi - functional programming interface
 * @export
 */
export const EntityInvestorPortfolioApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EntityInvestorPortfolioApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves a list of issuers the current authorized user portfolio is associated with
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {boolean} [unverifiedHoldings] 
         * @param {boolean} [includeSpvs] 
         * @param {boolean} [watchlistOnly] 
         * @param {boolean} [portfolioOnly] 
         * @param {boolean} [watchlistHide] 
         * @param {number} [watchlistAccountId] 
         * @param {string} [sector] 
         * @param {string} [investor] 
         * @param {string} [totalFunding] 
         * @param {string} [latestDealSize] 
         * @param {string} [companyActive] 
         * @param {string} [latestValuation] 
         * @param {string} [lastFunded] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityInvestorPortfolioIndex(page?: number, size?: number, search?: string, unverifiedHoldings?: boolean, includeSpvs?: boolean, watchlistOnly?: boolean, portfolioOnly?: boolean, watchlistHide?: boolean, watchlistAccountId?: number, sector?: string, investor?: string, totalFunding?: string, latestDealSize?: string, companyActive?: string, latestValuation?: string, lastFunded?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityInvestorPortfolioIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entityInvestorPortfolioIndex(page, size, search, unverifiedHoldings, includeSpvs, watchlistOnly, portfolioOnly, watchlistHide, watchlistAccountId, sector, investor, totalFunding, latestDealSize, companyActive, latestValuation, lastFunded, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EntityInvestorPortfolioApi - factory interface
 * @export
 */
export const EntityInvestorPortfolioApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EntityInvestorPortfolioApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves a list of issuers the current authorized user portfolio is associated with
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {boolean} [unverifiedHoldings] 
         * @param {boolean} [includeSpvs] 
         * @param {boolean} [watchlistOnly] 
         * @param {boolean} [portfolioOnly] 
         * @param {boolean} [watchlistHide] 
         * @param {number} [watchlistAccountId] 
         * @param {string} [sector] 
         * @param {string} [investor] 
         * @param {string} [totalFunding] 
         * @param {string} [latestDealSize] 
         * @param {string} [companyActive] 
         * @param {string} [latestValuation] 
         * @param {string} [lastFunded] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityInvestorPortfolioIndex(page?: number, size?: number, search?: string, unverifiedHoldings?: boolean, includeSpvs?: boolean, watchlistOnly?: boolean, portfolioOnly?: boolean, watchlistHide?: boolean, watchlistAccountId?: number, sector?: string, investor?: string, totalFunding?: string, latestDealSize?: string, companyActive?: string, latestValuation?: string, lastFunded?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<EntityInvestorPortfolioIndex> {
            return localVarFp.entityInvestorPortfolioIndex(page, size, search, unverifiedHoldings, includeSpvs, watchlistOnly, portfolioOnly, watchlistHide, watchlistAccountId, sector, investor, totalFunding, latestDealSize, companyActive, latestValuation, lastFunded, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for entityInvestorPortfolioIndex operation in EntityInvestorPortfolioApi.
 * @export
 * @interface EntityInvestorPortfolioApiEntityInvestorPortfolioIndexRequest
 */
export interface EntityInvestorPortfolioApiEntityInvestorPortfolioIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly unverifiedHoldings?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly includeSpvs?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly watchlistOnly?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly portfolioOnly?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly watchlistHide?: boolean

    /**
     * 
     * @type {number}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly watchlistAccountId?: number

    /**
     * 
     * @type {string}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly sector?: string

    /**
     * 
     * @type {string}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly investor?: string

    /**
     * 
     * @type {string}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly totalFunding?: string

    /**
     * 
     * @type {string}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly latestDealSize?: string

    /**
     * 
     * @type {string}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly companyActive?: string

    /**
     * 
     * @type {string}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly latestValuation?: string

    /**
     * 
     * @type {string}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly lastFunded?: string

    /**
     * 
     * @type {string}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof EntityInvestorPortfolioApiEntityInvestorPortfolioIndex
     */
    readonly xUserRoleId?: string
}

/**
 * EntityInvestorPortfolioApi - object-oriented interface
 * @export
 * @class EntityInvestorPortfolioApi
 * @extends {BaseAPI}
 */
export class EntityInvestorPortfolioApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves a list of issuers the current authorized user portfolio is associated with
     * @param {EntityInvestorPortfolioApiEntityInvestorPortfolioIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityInvestorPortfolioApi
     */
    public entityInvestorPortfolioIndex(requestParameters: EntityInvestorPortfolioApiEntityInvestorPortfolioIndexRequest = {}, options?: AxiosRequestConfig) {
        return EntityInvestorPortfolioApiFp(this.configuration).entityInvestorPortfolioIndex(requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.unverifiedHoldings, requestParameters.includeSpvs, requestParameters.watchlistOnly, requestParameters.portfolioOnly, requestParameters.watchlistHide, requestParameters.watchlistAccountId, requestParameters.sector, requestParameters.investor, requestParameters.totalFunding, requestParameters.latestDealSize, requestParameters.companyActive, requestParameters.latestValuation, requestParameters.lastFunded, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type EntityInvestorPortfolioIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EntityInvestorPortfolioIndexErrorCodes = [
    401,
];


