import create from 'zustand';

interface SidebarStore {
  open: boolean;
  isCollapsed: boolean;
  setOpen: (open: boolean) => void;
  resetOpen: () => void;
  setIsCollapsed: (isCollapsed: boolean) => void;
}

export const useSidebarStore = create<SidebarStore>(set => ({
  open: true,
  setOpen: open => set({ open: open }),
  resetOpen: () => set({ open: true }),

  isCollapsed: true,
  setIsCollapsed: isCollapsed => set({ isCollapsed: isCollapsed }),
}));
