/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  RoutingBrokerApi,
  BrokerageFirmRoutingBrokerIndexErrorCodes,
  BrokerageFirmRoutingBrokerIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new RoutingBrokerApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type BrokerageFirmRoutingBrokerIndexMethod = RoutingBrokerApi['brokerageFirmRoutingBrokerIndex'];
export type BrokerageFirmRoutingBrokerIndexResponseType = MethodResult<BrokerageFirmRoutingBrokerIndexMethod>;

export const useBrokerageFirmRoutingBrokerIndex = (
  variables: Parameters<BrokerageFirmRoutingBrokerIndexMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmRoutingBrokerIndexMethod,
    BrokerageFirmRoutingBrokerIndexErrorTypes
  >
) => {
  return useQuery<
    BrokerageFirmRoutingBrokerIndexMethod,
    BrokerageFirmRoutingBrokerIndexErrorTypes
  >(
    (...args) => api.brokerageFirmRoutingBrokerIndex(...args),
    BrokerageFirmRoutingBrokerIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmRoutingBrokerIndex'],
        ...apiQueryConfig.BrokerageFirmRoutingBrokerIndex,
        ...config?.queryConfig
      },
    },
    'BrokerageFirmRoutingBrokerIndex',
  );
};

export const useBrokerageFirmRoutingBrokerIndexInfinite = (
  variables: Parameters<BrokerageFirmRoutingBrokerIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    BrokerageFirmRoutingBrokerIndexMethod,
    BrokerageFirmRoutingBrokerIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    BrokerageFirmRoutingBrokerIndexMethod,
    BrokerageFirmRoutingBrokerIndexErrorTypes
  >(
    (...args) => api.brokerageFirmRoutingBrokerIndex(...args),
    BrokerageFirmRoutingBrokerIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmRoutingBrokerIndex', 'BrokerageFirmRoutingBrokerIndexInfinite'],
        ...apiQueryConfig.BrokerageFirmRoutingBrokerIndex,
        ...config?.queryConfig,
      },
    },
    'BrokerageFirmRoutingBrokerIndex',
  );
};

export const useBrokerageFirmRoutingBrokerIndexLazy = (baseOptions?: {
  variables?: Parameters<BrokerageFirmRoutingBrokerIndexMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmRoutingBrokerIndexMethod,
    BrokerageFirmRoutingBrokerIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    BrokerageFirmRoutingBrokerIndexMethod,
    BrokerageFirmRoutingBrokerIndexErrorTypes
  >(
    (...args) => api.brokerageFirmRoutingBrokerIndex(...args),
    BrokerageFirmRoutingBrokerIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BrokerageFirmRoutingBrokerIndex'],
        ...apiQueryConfig.BrokerageFirmRoutingBrokerIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BrokerageFirmRoutingBrokerIndex',
  );
};

