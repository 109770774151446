import React, { type ComponentProps } from 'react';

import { Card } from '@npm/core/ui/components/molecules/Card';
import { type Codebook } from '@npm/data-access';

import { StatItem } from './StatItem';

import * as S from './SectionStats.styles';

type Props = {
  items: ComponentProps<typeof StatItem>[];
  isLoading?: boolean;
  denomination?: Codebook | false;
};

export const SectionStats = ({ items, isLoading, denomination }: Props) => {
  return (
    <Card noContentPadding variant="secondMarket">
      <S.Container>
        {items.map(({ key, ...rest }) => (
          <StatItem
            {...rest}
            key={key}
            isLoading={isLoading}
            denomination={denomination}
          />
        ))}
      </S.Container>
    </Card>
  );
};
