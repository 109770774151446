import styled from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';

export const FiltersRow = styled(Flex).attrs({
  align: 'center',
  justify: 'space-between',
  gap: 'xs',
})`
  padding-right: ${({ theme }) => theme.spacing.md}px;
`;

export const DotsFlex = styled(Flex).attrs({
  gap: 'sm',
  align: 'center',
  justify: 'center',
})`
  margin-right: ${({ theme }) => theme.spacing.md}px;
`;
