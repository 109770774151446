import React from 'react';

import { getPageSize, Table } from '@npm/core/ui/components/molecules/Table';
import { usePagination } from '@npm/core/ui/hooks/pagination/usePagination';
import { type Codebook, VenusApi } from '@npm/data-access';

import { usePremiumPlan } from '../../../../auth/user/role';
import { FILTER_QUERY_KEY, usePersistedFilters } from '../../../../filters';

import { getCapTableColumns } from './CapTable.columns';

import * as S from './CapTable.styles';

type Props = {
  venusCompanyId?: number;
  companyName?: string;
  denomination?: Codebook;
  isLoading?: boolean;
};

export const CapTable = ({
  venusCompanyId,
  companyName,
  denomination,
  isLoading,
}: Props) => {
  const { isPremiumContentUnlocked } = usePremiumPlan();

  const [{ variables, setVariables }] =
    usePersistedFilters<VenusApi.StockClassesApiCompanyStockClassesRequest>(
      FILTER_QUERY_KEY.capTable,
      {
        page: 1,
        size: getPageSize(),
        companyId: venusCompanyId?.toString(),
      },
      { resetQueryOnUnmount: false }
    );

  const { data, isFetching } = VenusApi.useCompanyStockClasses(variables, {
    queryConfig: { enabled: !!variables.companyId },
  });

  const { pagination, updatePaginationVariables } =
    usePagination<VenusApi.StockClassesApiCompanyStockClassesRequest>(
      'capTable',
      {
        paginationData: data?.pagination,
        variables,
        setVariables,
      }
    );

  return (
    <S.StyledCard
      variant="secondMarket"
      header={{
        title: 'Capitalization Table',
      }}
      noContentPadding
    >
      <Table<VenusApi.CapTableStockClass>
        outlined={false}
        columns={getCapTableColumns(companyName, denomination)}
        dataSource={data?.stock_classes}
        loading={isLoading || isFetching}
        expandable={{
          showExpandColumn: isPremiumContentUnlocked,
          rowExpandable: () => isPremiumContentUnlocked,
        }}
        pagination={pagination}
        onChange={updatePaginationVariables}
        showExpandedContentInColumns
      />
    </S.StyledCard>
  );
};
