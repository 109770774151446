import { AutoComplete } from 'antd';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const MULTI_SELECT_MAX_WIDTH = 400;

export const Wrap = styled.div`
  position: relative;
`;

export const StyledAutoComplete = styled(AutoComplete)`
  max-width: ${MULTI_SELECT_MAX_WIDTH}px;

  .ant-select-selection-placeholder {
    z-index: ${({ theme }) => theme.zIndex.dropdown};
    inset: 0;
    display: flex;
    align-items: center;
    padding: 0;
    font-size: ${({ theme }) => theme.typography.size.sm}px;
  }
`;

export const SelectedChipsWrap = styled.div`
  margin-top: ${({ theme }) => theme.spacing.lg}px;
  max-width: 100%;
`;

export const ChipWrap = styled(motion.div)`
  display: inline-block;
`;
