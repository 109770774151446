/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  PublicComparablesApi,
  InternalAllPublicComparablesIndexErrorCodes,
  InternalAllPublicComparablesIndexErrorTypes,
  InternalPublicComparablesIndexErrorCodes,
  InternalPublicComparablesIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new PublicComparablesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalAllPublicComparablesIndexMethod = PublicComparablesApi['internalAllPublicComparablesIndex'];
export type InternalAllPublicComparablesIndexResponseType = MethodResult<InternalAllPublicComparablesIndexMethod>;

export const useInternalAllPublicComparablesIndex = (
  variables: Parameters<InternalAllPublicComparablesIndexMethod>[0],
  config?: UseQueryConfig<
    InternalAllPublicComparablesIndexMethod,
    InternalAllPublicComparablesIndexErrorTypes
  >
) => {
  return useQuery<
    InternalAllPublicComparablesIndexMethod,
    InternalAllPublicComparablesIndexErrorTypes
  >(
    (...args) => api.internalAllPublicComparablesIndex(...args),
    InternalAllPublicComparablesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalAllPublicComparablesIndex'],
        ...apiQueryConfig.InternalAllPublicComparablesIndex,
        ...config?.queryConfig
      },
    },
    'InternalAllPublicComparablesIndex',
  );
};

export const useInternalAllPublicComparablesIndexInfinite = (
  variables: Parameters<InternalAllPublicComparablesIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalAllPublicComparablesIndexMethod,
    InternalAllPublicComparablesIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalAllPublicComparablesIndexMethod,
    InternalAllPublicComparablesIndexErrorTypes
  >(
    (...args) => api.internalAllPublicComparablesIndex(...args),
    InternalAllPublicComparablesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalAllPublicComparablesIndex', 'InternalAllPublicComparablesIndexInfinite'],
        ...apiQueryConfig.InternalAllPublicComparablesIndex,
        ...config?.queryConfig,
      },
    },
    'InternalAllPublicComparablesIndex',
  );
};

export const useInternalAllPublicComparablesIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalAllPublicComparablesIndexMethod>[0],
  config?: UseQueryConfig<
    InternalAllPublicComparablesIndexMethod,
    InternalAllPublicComparablesIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalAllPublicComparablesIndexMethod,
    InternalAllPublicComparablesIndexErrorTypes
  >(
    (...args) => api.internalAllPublicComparablesIndex(...args),
    InternalAllPublicComparablesIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalAllPublicComparablesIndex'],
        ...apiQueryConfig.InternalAllPublicComparablesIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalAllPublicComparablesIndex',
  );
};


export type InternalPublicComparablesIndexMethod = PublicComparablesApi['internalPublicComparablesIndex'];
export type InternalPublicComparablesIndexResponseType = MethodResult<InternalPublicComparablesIndexMethod>;

export const useInternalPublicComparablesIndex = (
  variables: Parameters<InternalPublicComparablesIndexMethod>[0],
  config?: UseQueryConfig<
    InternalPublicComparablesIndexMethod,
    InternalPublicComparablesIndexErrorTypes
  >
) => {
  return useQuery<
    InternalPublicComparablesIndexMethod,
    InternalPublicComparablesIndexErrorTypes
  >(
    (...args) => api.internalPublicComparablesIndex(...args),
    InternalPublicComparablesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalPublicComparablesIndex'],
        ...apiQueryConfig.InternalPublicComparablesIndex,
        ...config?.queryConfig
      },
    },
    'InternalPublicComparablesIndex',
  );
};

export const useInternalPublicComparablesIndexInfinite = (
  variables: Parameters<InternalPublicComparablesIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalPublicComparablesIndexMethod,
    InternalPublicComparablesIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalPublicComparablesIndexMethod,
    InternalPublicComparablesIndexErrorTypes
  >(
    (...args) => api.internalPublicComparablesIndex(...args),
    InternalPublicComparablesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalPublicComparablesIndex', 'InternalPublicComparablesIndexInfinite'],
        ...apiQueryConfig.InternalPublicComparablesIndex,
        ...config?.queryConfig,
      },
    },
    'InternalPublicComparablesIndex',
  );
};

export const useInternalPublicComparablesIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalPublicComparablesIndexMethod>[0],
  config?: UseQueryConfig<
    InternalPublicComparablesIndexMethod,
    InternalPublicComparablesIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalPublicComparablesIndexMethod,
    InternalPublicComparablesIndexErrorTypes
  >(
    (...args) => api.internalPublicComparablesIndex(...args),
    InternalPublicComparablesIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalPublicComparablesIndex'],
        ...apiQueryConfig.InternalPublicComparablesIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalPublicComparablesIndex',
  );
};

