import React from 'react';
import { type FormInstance } from 'antd';
import { type FormProps } from 'antd/es/form/Form';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Checkbox } from '@npm/core/ui/components/atoms/Checkbox';
import {
  InputFormItem as FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import { ADD_AUTHORIZED_SIGNER_FORM_FIELDS_ENUM } from './AddAuthorizedSignerForm.types';

import * as S from './AddAuthorizedSignerForm.styles';

type Props = {
  form: FormInstance;
  onSubmit: () => Promise<void>;
  isLoading: boolean;
  signerAvailable: boolean;
  showButtons?: boolean;
  handleValuesChange?: FormProps['onValuesChange'];
};

export const AddAuthorizedSignerForm = ({
  form,
  onSubmit,
  signerAvailable,
  showButtons = true,
  handleValuesChange,
}: Props) => {
  return (
    <S.Form form={form} onValuesChange={handleValuesChange}>
      <S.FormWrap>
        <FormItem
          name={ADD_AUTHORIZED_SIGNER_FORM_FIELDS_ENUM.FIRST}
          label="First Name"
          labelPosition="top"
          rules={[VALIDATION_RULES.required('First name')]}
        >
          <Input placeholder="First Name" />
        </FormItem>
        <FormItem
          name={ADD_AUTHORIZED_SIGNER_FORM_FIELDS_ENUM.MIDDLE}
          label="Middle Name"
          requiredMark="optional"
        >
          <Input placeholder="Middle Name" />
        </FormItem>
        <FormItem
          name={ADD_AUTHORIZED_SIGNER_FORM_FIELDS_ENUM.LAST}
          label="Last Name"
          labelPosition="top"
          rules={[VALIDATION_RULES.required('Last name')]}
        >
          <Input placeholder="Last Name" />
        </FormItem>
        <FormItem
          name={ADD_AUTHORIZED_SIGNER_FORM_FIELDS_ENUM.EMAIL}
          label="Email"
          labelPosition="top"
          rules={[VALIDATION_RULES.required('Email')]}
        >
          <Input placeholder="Email" />
        </FormItem>
        <S.CheckboxWrap>
          <FormItem
            name={ADD_AUTHORIZED_SIGNER_FORM_FIELDS_ENUM.ACCEPT_DISCLAIMER}
            valuePropName="checked"
            rules={[
              VALIDATION_RULES.disclaimerAccepted(
                'Read and accept before saving'
              ),
            ]}
          >
            <Checkbox
              label={
                <Text size="sm" colorVariant="primary">
                  After clicking “Add Signer” the user will have access to this
                  account and will be able to sign documents on its behalf.
                </Text>
              }
            />
          </FormItem>
        </S.CheckboxWrap>
      </S.FormWrap>
      {showButtons && (
        <S.ButtonsWrap>
          <Button variant="outline" onClick={onSubmit}>
            {signerAvailable ? 'Add another authorized signer' : 'Add Signer'}
          </Button>
        </S.ButtonsWrap>
      )}
    </S.Form>
  );
};
