import { Radio } from 'antd';
import styled from 'styled-components';

export const RadioSwitchWrapper = styled(Radio.Group).attrs({
  size: 'small',
})`
  background-color: ${({ theme }) => theme.color.general.layout.two};
  padding: ${({ theme }) => theme.spacing.xs / 2}px;
  border-radius: 6px; // No theme value for this, needed to match the border radius and padding of the inner item

  .ant-radio-button-wrapper {
    background-color: transparent;
    border-color: transparent;
    color: ${({ theme }) => theme.color.general.typography.tertiary};
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    border-width: 1px;

    &.ant-radio-button-wrapper-checked {
      border-color: ${({ theme }) => theme.color.general.borders.primary};
      background-color: ${({ theme }) => theme.color.general.layout.zero};
      color: ${({ theme }) => theme.color.general.typography.primary};
    }

    &:not(:first-child):before {
      content: none;
    }

    &:hover {
      background-color: ${({ theme }) =>
        theme.color.info.backgrounds.secondaryDefault};
      border-color: ${({ theme }) => theme.color.general.layout.two};
    }

    &-checked:not(
        [class*=' ant-radio-button-wrapper-disabled']
      ).ant-radio-button-wrapper:first-child {
      border-right-color: ${({ theme }) => theme.color.general.borders.primary};
    }
  }
`;
