import { Codebooks } from './codebooks.types';

const Items = {
  'aba-routing': 'aba-routing',
  'swift-code': 'swift-code',
} as const;

export const CbIntermediaryRoutingType = {
  code: Codebooks.INTERMEDIARY_ROUTING_TYPE,
  items: Items,
};
