import React, { type ComponentProps } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { GetStarted as BaseGetStarted } from '@npm/core/ui/components/molecules/GetStarted';

export const IndividualTaxIdGetStartedContent = (
  props: Partial<React.ComponentProps<typeof Text>>
) => {
  return (
    <Text colorVariant="secondary" {...props}>
      Enter the tax identification number of this account’s representative.
    </Text>
  );
};

export const IndividualTaxIdGetStarted = ({
  onGetStarted,
}: ComponentProps<typeof BaseGetStarted>) => {
  return (
    <BaseGetStarted
      onGetStarted={onGetStarted}
      title="Individual Tax ID"
      content={<IndividualTaxIdGetStartedContent />}
    />
  );
};
