import React from 'react';

import { type ButtonVariant } from '@npm/core/ui/components/atoms/Button/Button.types';
import { FileUpload } from '@npm/core/ui/components/atoms/FileUpload';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { CypressDataIds } from '@npm/core/ui/constants';

type Props = {
  helpText: string;
  onChange?: (value?: File) => void;
  buttonVariant?: ButtonVariant;
};

export const FileUploadSection = ({ onChange, helpText }: Props) => {
  return (
    <div>
      <Text size="xs" marginBottom="sm">
        The file must be a PDF, JPG, or PNG. <br />
        {helpText}
      </Text>
      <FileUpload
        label="Upload file (Max: 1)"
        data-cy={CypressDataIds.PostOnboarding.FormationDocument.UploadButton}
        onChange={onChange}
        buttonVariant="outline"
      />
    </div>
  );
};
