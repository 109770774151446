/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  WarrantIssuancesApi,
  ListWarrantIssuancesErrorCodes,
  ListWarrantIssuancesErrorTypes,
  GetWarrantIssuanceErrorCodes,
  GetWarrantIssuanceErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new WarrantIssuancesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type ListWarrantIssuancesMethod = WarrantIssuancesApi['listWarrantIssuances'];
export type ListWarrantIssuancesResponseType = MethodResult<ListWarrantIssuancesMethod>;

export const useListWarrantIssuances = (
  variables: Parameters<ListWarrantIssuancesMethod>[0],
  config?: UseQueryConfig<
    ListWarrantIssuancesMethod,
    ListWarrantIssuancesErrorTypes
  >
) => {
  return useQuery<
    ListWarrantIssuancesMethod,
    ListWarrantIssuancesErrorTypes
  >(
    (...args) => api.listWarrantIssuances(...args),
    ListWarrantIssuancesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListWarrantIssuances'],
        ...apiQueryConfig.ListWarrantIssuances,
        ...config?.queryConfig
      },
    },
    'ListWarrantIssuances',
  );
};

export const useListWarrantIssuancesInfinite = (
  variables: Parameters<ListWarrantIssuancesMethod>[0],
  config?: UseInfiniteQueryConfig<
    ListWarrantIssuancesMethod,
    ListWarrantIssuancesErrorTypes
  >
) => {
  return useInfiniteQuery<
    ListWarrantIssuancesMethod,
    ListWarrantIssuancesErrorTypes
  >(
    (...args) => api.listWarrantIssuances(...args),
    ListWarrantIssuancesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListWarrantIssuances', 'ListWarrantIssuancesInfinite'],
        ...apiQueryConfig.ListWarrantIssuances,
        ...config?.queryConfig,
      },
    },
    'ListWarrantIssuances',
  );
};

export const useListWarrantIssuancesLazy = (baseOptions?: {
  variables?: Parameters<ListWarrantIssuancesMethod>[0],
  config?: UseQueryConfig<
    ListWarrantIssuancesMethod,
    ListWarrantIssuancesErrorTypes
  >
}) => {
  return useLazyQuery<
    ListWarrantIssuancesMethod,
    ListWarrantIssuancesErrorTypes
  >(
    (...args) => api.listWarrantIssuances(...args),
    ListWarrantIssuancesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['ListWarrantIssuances'],
        ...apiQueryConfig.ListWarrantIssuances,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'ListWarrantIssuances',
  );
};


export type GetWarrantIssuanceMethod = WarrantIssuancesApi['getWarrantIssuance'];
export type GetWarrantIssuanceResponseType = MethodResult<GetWarrantIssuanceMethod>;

export const useGetWarrantIssuance = (
  variables: Parameters<GetWarrantIssuanceMethod>[0],
  config?: UseQueryConfig<
    GetWarrantIssuanceMethod,
    GetWarrantIssuanceErrorTypes
  >
) => {
  return useQuery<
    GetWarrantIssuanceMethod,
    GetWarrantIssuanceErrorTypes
  >(
    (...args) => api.getWarrantIssuance(...args),
    GetWarrantIssuanceErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetWarrantIssuance'],
        ...apiQueryConfig.GetWarrantIssuance,
        ...config?.queryConfig
      },
    },
    'GetWarrantIssuance',
  );
};

export const useGetWarrantIssuanceInfinite = (
  variables: Parameters<GetWarrantIssuanceMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetWarrantIssuanceMethod,
    GetWarrantIssuanceErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetWarrantIssuanceMethod,
    GetWarrantIssuanceErrorTypes
  >(
    (...args) => api.getWarrantIssuance(...args),
    GetWarrantIssuanceErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetWarrantIssuance', 'GetWarrantIssuanceInfinite'],
        ...apiQueryConfig.GetWarrantIssuance,
        ...config?.queryConfig,
      },
    },
    'GetWarrantIssuance',
  );
};

export const useGetWarrantIssuanceLazy = (baseOptions?: {
  variables?: Parameters<GetWarrantIssuanceMethod>[0],
  config?: UseQueryConfig<
    GetWarrantIssuanceMethod,
    GetWarrantIssuanceErrorTypes
  >
}) => {
  return useLazyQuery<
    GetWarrantIssuanceMethod,
    GetWarrantIssuanceErrorTypes
  >(
    (...args) => api.getWarrantIssuance(...args),
    GetWarrantIssuanceErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetWarrantIssuance'],
        ...apiQueryConfig.GetWarrantIssuance,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetWarrantIssuance',
  );
};

