/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  NegotiationApi,
  NegotiationIndexErrorCodes,
  NegotiationIndexErrorTypes,
  NegotiationCreateErrorCodes,
  NegotiationShowErrorCodes,
  NegotiationShowErrorTypes,
  NegotiationUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new NegotiationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type NegotiationIndexMethod = NegotiationApi['negotiationIndex'];
export type NegotiationIndexResponseType = MethodResult<NegotiationIndexMethod>;

export const useNegotiationIndex = (
  variables: Parameters<NegotiationIndexMethod>[0],
  config?: UseQueryConfig<
    NegotiationIndexMethod,
    NegotiationIndexErrorTypes
  >
) => {
  return useQuery<
    NegotiationIndexMethod,
    NegotiationIndexErrorTypes
  >(
    (...args) => api.negotiationIndex(...args),
    NegotiationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NegotiationIndex'],
        ...apiQueryConfig.NegotiationIndex,
        ...config?.queryConfig
      },
    },
    'NegotiationIndex',
  );
};

export const useNegotiationIndexInfinite = (
  variables: Parameters<NegotiationIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    NegotiationIndexMethod,
    NegotiationIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    NegotiationIndexMethod,
    NegotiationIndexErrorTypes
  >(
    (...args) => api.negotiationIndex(...args),
    NegotiationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NegotiationIndex', 'NegotiationIndexInfinite'],
        ...apiQueryConfig.NegotiationIndex,
        ...config?.queryConfig,
      },
    },
    'NegotiationIndex',
  );
};

export const useNegotiationIndexLazy = (baseOptions?: {
  variables?: Parameters<NegotiationIndexMethod>[0],
  config?: UseQueryConfig<
    NegotiationIndexMethod,
    NegotiationIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    NegotiationIndexMethod,
    NegotiationIndexErrorTypes
  >(
    (...args) => api.negotiationIndex(...args),
    NegotiationIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['NegotiationIndex'],
        ...apiQueryConfig.NegotiationIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'NegotiationIndex',
  );
};


export type NegotiationCreateMethod = NegotiationApi['negotiationCreate'];

export const useNegotiationCreate = () => {
  return useMutation<NegotiationCreateMethod>(
    (...args) => api.negotiationCreate(...args),
    NegotiationCreateErrorCodes,
    'NegotiationCreate',
    '/api/negotiations',
    'post'
  );
};

export type NegotiationShowMethod = NegotiationApi['negotiationShow'];
export type NegotiationShowResponseType = MethodResult<NegotiationShowMethod>;

export const useNegotiationShow = (
  variables: Parameters<NegotiationShowMethod>[0],
  config?: UseQueryConfig<
    NegotiationShowMethod,
    NegotiationShowErrorTypes
  >
) => {
  return useQuery<
    NegotiationShowMethod,
    NegotiationShowErrorTypes
  >(
    (...args) => api.negotiationShow(...args),
    NegotiationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NegotiationShow'],
        ...apiQueryConfig.NegotiationShow,
        ...config?.queryConfig
      },
    },
    'NegotiationShow',
  );
};

export const useNegotiationShowInfinite = (
  variables: Parameters<NegotiationShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    NegotiationShowMethod,
    NegotiationShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    NegotiationShowMethod,
    NegotiationShowErrorTypes
  >(
    (...args) => api.negotiationShow(...args),
    NegotiationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NegotiationShow', 'NegotiationShowInfinite'],
        ...apiQueryConfig.NegotiationShow,
        ...config?.queryConfig,
      },
    },
    'NegotiationShow',
  );
};

export const useNegotiationShowLazy = (baseOptions?: {
  variables?: Parameters<NegotiationShowMethod>[0],
  config?: UseQueryConfig<
    NegotiationShowMethod,
    NegotiationShowErrorTypes
  >
}) => {
  return useLazyQuery<
    NegotiationShowMethod,
    NegotiationShowErrorTypes
  >(
    (...args) => api.negotiationShow(...args),
    NegotiationShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['NegotiationShow'],
        ...apiQueryConfig.NegotiationShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'NegotiationShow',
  );
};


export type NegotiationUpdateMethod = NegotiationApi['negotiationUpdate'];

export const useNegotiationUpdate = () => {
  return useMutation<NegotiationUpdateMethod>(
    (...args) => api.negotiationUpdate(...args),
    NegotiationUpdateErrorCodes,
    'NegotiationUpdate',
    '/api/negotiations/{id}',
    'put'
  );
};
