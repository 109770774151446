/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BrokerageFirmIndex } from '../model';
/**
 * BrokerageFirmApi - axios parameter creator
 * @export
 */
export const BrokerageFirmApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary lists all brokerage firms
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {boolean} [enableClientSignup] only returns brokerage firms what a client can signup against
         * @param {boolean} [userSpecificCounts] returns person_account_count and organization_account_counts specific to the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerageFirmIndex: async (page?: number, size?: number, enableClientSignup?: boolean, userSpecificCounts?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/brokerage-firms`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (enableClientSignup !== undefined) {
                localVarQueryParameter['enable_client_signup'] = enableClientSignup;
            }

            if (userSpecificCounts !== undefined) {
                localVarQueryParameter['user_specific_counts'] = userSpecificCounts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrokerageFirmApi - functional programming interface
 * @export
 */
export const BrokerageFirmApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrokerageFirmApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary lists all brokerage firms
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {boolean} [enableClientSignup] only returns brokerage firms what a client can signup against
         * @param {boolean} [userSpecificCounts] returns person_account_count and organization_account_counts specific to the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerageFirmIndex(page?: number, size?: number, enableClientSignup?: boolean, userSpecificCounts?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrokerageFirmIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerageFirmIndex(page, size, enableClientSignup, userSpecificCounts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrokerageFirmApi - factory interface
 * @export
 */
export const BrokerageFirmApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrokerageFirmApiFp(configuration)
    return {
        /**
         * 
         * @summary lists all brokerage firms
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {boolean} [enableClientSignup] only returns brokerage firms what a client can signup against
         * @param {boolean} [userSpecificCounts] returns person_account_count and organization_account_counts specific to the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerageFirmIndex(page?: number, size?: number, enableClientSignup?: boolean, userSpecificCounts?: boolean, options?: any): AxiosPromise<BrokerageFirmIndex> {
            return localVarFp.brokerageFirmIndex(page, size, enableClientSignup, userSpecificCounts, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for brokerageFirmIndex operation in BrokerageFirmApi.
 * @export
 * @interface BrokerageFirmApiBrokerageFirmIndexRequest
 */
export interface BrokerageFirmApiBrokerageFirmIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof BrokerageFirmApiBrokerageFirmIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BrokerageFirmApiBrokerageFirmIndex
     */
    readonly size?: number

    /**
     * only returns brokerage firms what a client can signup against
     * @type {boolean}
     * @memberof BrokerageFirmApiBrokerageFirmIndex
     */
    readonly enableClientSignup?: boolean

    /**
     * returns person_account_count and organization_account_counts specific to the user
     * @type {boolean}
     * @memberof BrokerageFirmApiBrokerageFirmIndex
     */
    readonly userSpecificCounts?: boolean
}

/**
 * BrokerageFirmApi - object-oriented interface
 * @export
 * @class BrokerageFirmApi
 * @extends {BaseAPI}
 */
export class BrokerageFirmApi extends BaseAPI {
    /**
     * 
     * @summary lists all brokerage firms
     * @param {BrokerageFirmApiBrokerageFirmIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerageFirmApi
     */
    public brokerageFirmIndex(requestParameters: BrokerageFirmApiBrokerageFirmIndexRequest = {}, options?: AxiosRequestConfig) {
        return BrokerageFirmApiFp(this.configuration).brokerageFirmIndex(requestParameters.page, requestParameters.size, requestParameters.enableClientSignup, requestParameters.userSpecificCounts, options).then((request) => request(this.axios, this.basePath));
    }
}

export type BrokerageFirmIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BrokerageFirmIndexErrorCodes = [
    401,
];


