import React from 'react';
import { type FormInstance, Form } from 'antd';

import { CypressDataIds } from '@npm/core/ui/constants';

import * as S from './index';
import { SignatureFormContent } from './SignatureFormContent';
import {
  SIGNATURE_MODAL_MAX_HEIGHT,
  SIGNATURE_MODAL_WIDTH,
} from './SignatureModal.constants';

type Props = {
  variant: 'entity' | 'individual';
  form: FormInstance;
  isOpen: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  isLoading: boolean;
};

const ModalFooter = ({
  handleSubmit,
  handleCancel,
  isLoading,
}: Pick<Props, 'handleSubmit' | 'handleCancel' | 'isLoading'>) => (
  <S.FooterWrapper>
    <S.AcceptButton
      onClick={handleSubmit}
      data-cy={CypressDataIds.Onboarding.Accreditation.SignAgreementButton}
      disabled={isLoading}
      loading={isLoading}
      blockOnMobile
    >
      Sign off Accreditation Statement
    </S.AcceptButton>
    <S.BackArrowBtn onClick={handleCancel} disabled={isLoading} blockOnMobile>
      Review information
    </S.BackArrowBtn>
  </S.FooterWrapper>
);

export const SignatureModal = ({
  variant,
  form,
  isOpen,
  handleSubmit,
  handleCancel,
  isLoading,
}: Props) => {
  return (
    <S.Modal
      destroyOnClose={true}
      data-cy="sign-modal"
      $maxHeight={SIGNATURE_MODAL_MAX_HEIGHT}
      width={SIGNATURE_MODAL_WIDTH}
      open={isOpen}
      title="Electronic Signature"
      footer={
        <ModalFooter
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          isLoading={isLoading}
        />
      }
      onCancel={handleCancel}
    >
      <Form form={form}>
        <SignatureFormContent variant={variant} />
      </Form>
    </S.Modal>
  );
};
