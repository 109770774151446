/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  InvestorNegotiationApi,
  InvestorNegotiationIndexErrorCodes,
  InvestorNegotiationIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new InvestorNegotiationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InvestorNegotiationIndexMethod = InvestorNegotiationApi['investorNegotiationIndex'];
export type InvestorNegotiationIndexResponseType = MethodResult<InvestorNegotiationIndexMethod>;

export const useInvestorNegotiationIndex = (
  variables: Parameters<InvestorNegotiationIndexMethod>[0],
  config?: UseQueryConfig<
    InvestorNegotiationIndexMethod,
    InvestorNegotiationIndexErrorTypes
  >
) => {
  return useQuery<
    InvestorNegotiationIndexMethod,
    InvestorNegotiationIndexErrorTypes
  >(
    (...args) => api.investorNegotiationIndex(...args),
    InvestorNegotiationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorNegotiationIndex'],
        ...apiQueryConfig.InvestorNegotiationIndex,
        ...config?.queryConfig
      },
    },
    'InvestorNegotiationIndex',
  );
};

export const useInvestorNegotiationIndexInfinite = (
  variables: Parameters<InvestorNegotiationIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InvestorNegotiationIndexMethod,
    InvestorNegotiationIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InvestorNegotiationIndexMethod,
    InvestorNegotiationIndexErrorTypes
  >(
    (...args) => api.investorNegotiationIndex(...args),
    InvestorNegotiationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorNegotiationIndex', 'InvestorNegotiationIndexInfinite'],
        ...apiQueryConfig.InvestorNegotiationIndex,
        ...config?.queryConfig,
      },
    },
    'InvestorNegotiationIndex',
  );
};

export const useInvestorNegotiationIndexLazy = (baseOptions?: {
  variables?: Parameters<InvestorNegotiationIndexMethod>[0],
  config?: UseQueryConfig<
    InvestorNegotiationIndexMethod,
    InvestorNegotiationIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InvestorNegotiationIndexMethod,
    InvestorNegotiationIndexErrorTypes
  >(
    (...args) => api.investorNegotiationIndex(...args),
    InvestorNegotiationIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InvestorNegotiationIndex'],
        ...apiQueryConfig.InvestorNegotiationIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InvestorNegotiationIndex',
  );
};

