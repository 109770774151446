import { format as formatDate } from 'date-fns';

import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { DateTimePickerInput } from '@npm/core/ui/components/atoms/Input';
import { CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import { DATE_FORMATS, getTimezoneShort } from '@npm/core/ui/utils/formatters';
import { type OrderSourceCode, CbOrderSource } from '@npm/data-access';

type Props = {
  value: Date | undefined;
  source: OrderSourceCode | undefined;
  onItemChange: (value: Date) => void;
  key: string;
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const ReceivedAtFormSection = ({
  value,
  source,
  onItemChange,
  ...props
}: Props) => {
  return (
    <CollapsibleFormSection.Item
      label={`Received (${getTimezoneShort()})`}
      preview={getReceivedAtPreview(value, source)}
      optional
      {...props}
    >
      <FormItem name="orderReceivedDate" marginBottom="sm">
        <DateTimePickerInput
          max={new Date()}
          defaultTime="endOfDay"
          allowClear
        />
      </FormItem>
    </CollapsibleFormSection.Item>
  );
};

export const getReceivedAtPreview = (
  value: Date | undefined,
  source: OrderSourceCode | undefined
) =>
  value
    ? formatDate(value, DATE_FORMATS.FULL)
    : source === CbOrderSource.items.historical
    ? ''
    : 'Now';
