import React, { type ComponentProps } from 'react';

import { CypressDataIds } from '@npm/core/ui/constants';
import { Codebooks } from '@npm/data-access';

import {
  CodebooksSelectFilter,
  SearchFilter,
} from '../../../../../../../filters';
import { type FilterPanel } from '../../../../../../../filters/filters/FilterPanel';

import { ActiveCompanyFilter } from './ActiveCompanyFilter';
import type { AccountsFilterFormValues } from './SelectAccountFilter';

export const getAccountsFilterItems = (
  isSmTablet: boolean,
  isMobile: boolean
): ComponentProps<typeof FilterPanel<AccountsFilterFormValues>>['filters'] => [
  {
    name: 'search',
    label: 'Search',
    render: props => (
      <SearchFilter
        placeholder="By Account or ID"
        data-cy={CypressDataIds.OboModal.SelectAccount.SearchInput}
        {...props}
      />
    ),
  },
  {
    name: 'accountType',
    label: 'Account type',
    render: props => (
      <CodebooksSelectFilter
        mode="multiple"
        codebook={Codebooks.ACCOUNT_TYPE}
        {...props}
      />
    ),
  },
  {
    render: props => <ActiveCompanyFilter {...props} />,
    name: 'userStatus',
    label: isSmTablet || isMobile ? null : <span>&nbsp;</span>,
  },
];
