import { type ComponentProps } from 'react';
import styled, { type DefaultTheme, css } from 'styled-components';

import { getFocusBorderStyles } from '../../../../utils/style';
import { type TypographyStyledComponentProps } from '../typography.types';

import { type Text as TextComponent } from './Text';
import { type TextSize, type TextWeight } from './Text.types';

import {
  applyFontSizeShrinkCss,
  BodyTextBoldVariationSettings,
  BodyTextStyles,
  ExtraSmallTextStyles,
  SmallTextBoldVariationSettings,
  SmallTextStyles,
  XXSmallTextStyles,
} from '../typography.styles';

const sizesMap = {
  default: BodyTextStyles,
  sm: SmallTextStyles,
  xs: ExtraSmallTextStyles,
  xxs: XXSmallTextStyles,
};

const getFontSize = (size: TextSize, theme: DefaultTheme) => {
  return size === 'default'
    ? theme.typography.size.md
    : theme.typography.size[size];
};

export const Text = styled.p<
  {
    $size: TextSize;
    $weight: TextWeight;
    $blur: ComponentProps<typeof TextComponent>['blur'];
    $textWrap?: ComponentProps<typeof TextComponent>['textWrap'];
    $valueLength?: number;
    $textAlign?: React.CSSProperties['textAlign'];
    $lineClamp?: number;
  } & TypographyStyledComponentProps
>`
  ${({ $size }) => sizesMap[$size]};

  ${({ $size, $weight }) =>
    $size === 'default' && $weight === 'bold' && BodyTextBoldVariationSettings}

  ${({ $size, $weight }) =>
    $size === 'sm' && $weight === 'bold' && SmallTextBoldVariationSettings}

  ${({ $size, $weight }) =>
    ($size === 'xs' || $size === 'xxs') &&
    $weight === 'bold' &&
    SmallTextBoldVariationSettings}

  ${({ $blur }) => `
    ${$blur ? 'filter: blur(3px);' : ''}
    ${$blur ? 'user-select: none;' : ''}
  `}

  ${({ $textWrap }) =>
    $textWrap &&
    css`
      text-wrap: ${$textWrap};
    `}


  ${({ $textAlign }) =>
    $textAlign &&
    css`
      text-align: ${$textAlign};
    `}

  ${({ $lineClamp }) =>
    $lineClamp &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${$lineClamp};
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}

  body.tab-focus ${getFocusBorderStyles()};

  // override size with shrinked size based on text value length
  ${({ $valueLength, $size, theme }) =>
    $valueLength &&
    applyFontSizeShrinkCss($valueLength, getFontSize($size, theme))}
`;
