import { useMemo } from 'react';

import { Tabs } from '@npm/core/ui/components/atoms/Tabs';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { type CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import { Codebooks } from '@npm/data-access';
import {
  type BrokerageFirmAggregate,
  CbOrderEntryType,
  CbVisibility,
  useCodebook,
} from '@npm/data-access';

type Props = {
  value?: string | undefined;
  onChange?: (value: string) => void;
  validateOption: (option: string) => { disabled: boolean; warning?: string };
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const VisibilitySelect = ({
  value,
  onChange,
  validateOption,
}: Props) => {
  const { data: visibilityData } = useCodebook({
    type: Codebooks.VISIBILITY,
  });

  const sortedOptions = useMemo(
    () => [...(visibilityData?.codebooks ?? [])].reverse(),
    [visibilityData]
  );

  if (!visibilityData) return null;

  return (
    <Tabs
      variant="secondary"
      activeKey={value}
      onChange={onChange}
      tabBarStyle={{
        margin: 0,
      }}
      hideMoreButton
    >
      {sortedOptions.map(({ code, name }) => {
        const { disabled, warning } = validateOption(code);

        return (
          <Tabs.TabPane
            key={code}
            tab={<Tooltip title={warning}>{name}</Tooltip>}
            disabled={disabled}
          />
        );
      })}
    </Tabs>
  );
};

/**
 * Only for new orders!
 */
export const validateVisibilityOption = (
  option: string,
  orderType: string,
  brokerageFirm: Pick<BrokerageFirmAggregate, 'access_ats_i'> | undefined,
  isAllowedToExternalize: boolean
) => {
  let warning: string | undefined;
  if (option === CbVisibility.items.external) {
    if (!isAllowedToExternalize) {
      warning = 'You are not authorized to externalize orders';
    } else if (
      orderType === CbOrderEntryType.items.soft_ioi &&
      !brokerageFirm?.access_ats_i
    ) {
      warning = 'Cannot externalize an IOI order';
    }
  }

  return { disabled: !!warning, warning };
};
