import styled from 'styled-components';

import { centerFlex } from '../../../../utils/style';
import { type StepperItemSize } from '../StepperItem';

const ICON_DIMENSIONS_SM = 22;
const ICON_DIMENSIONS_MD = 32;

export const Square = styled.div<{
  $size: StepperItemSize;
  $isActive: boolean;
  $hasBackground?: boolean;
}>`
  ${centerFlex}
  flex-shrink: 0;
  width: ${({ $size }) =>
    $size === 'sm' ? ICON_DIMENSIONS_SM : ICON_DIMENSIONS_MD}px;
  height: ${({ $size }) =>
    $size === 'sm' ? ICON_DIMENSIONS_SM : ICON_DIMENSIONS_MD}px;
  margin-right: ${({ theme, $size }) =>
    $size === 'sm' ? theme.spacing.sm : theme.spacing.md}px;
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  background-color: ${({ theme, $hasBackground = true }) =>
    $hasBackground ? theme.color.general.layout.two : 'transparent'};
`;
