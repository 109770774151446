import React, { type ComponentProps, useMemo } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { type CompanyPricingApi } from '@npm/data-access';

import { type useDocumentViewer } from '../../../../../../documents/DocumentViewer';

type Props = {
  mtm?: CompanyPricingApi.Mtm;
  showDocumentPreview?: ReturnType<
    typeof useDocumentViewer
  >['showDocumentPreview'];
};

export const MutualFundMarksFilingsDropdown = ({
  mtm,
  showDocumentPreview,
}: Props) => {
  const { filing_link, mtm_filing_id } = mtm;

  const dropdownItems: ComponentProps<typeof Dropdown>['items'] =
    useMemo(() => {
      const viewPdf = () => {
        showDocumentPreview({
          // MTM proxy URL
          type: 'with-path',
          path: `/api/mtm/filing_pdf/${mtm_filing_id}`,
        });
      };

      return [
        {
          label: 'View Original Filing on EDGAR',
          onClick: () => {
            window.open(filing_link, '_blank');
          },
        },
        {
          label: mtm_filing_id
            ? 'View Filing PDF'
            : 'No PDF available, filing exceeds size limit',
          onClick: mtm_filing_id ? viewPdf : null,
        },
      ];
    }, [mtm_filing_id, filing_link, showDocumentPreview]);

  return (
    <Dropdown items={dropdownItems}>
      <Button
        variant="outline"
        size="md"
        icon={<Icon name="dots-vertical" size="xs" />}
        style={{ marginLeft: 'auto' }}
      />
    </Dropdown>
  );
};
