import { type ComponentProps } from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { Table } from '@npm/core/ui/components/molecules/Table';
import {
  type AccountsBeneficialOwnerShow,
  useAccountBeneficialOwnershipAssessmentUpdate,
} from '@npm/data-access';

import { type BeneficialOwnersExemptions } from '../../BeneficialOwnership.types';

type Props = {
  owners: AccountsBeneficialOwnerShow[];
  exemptions: BeneficialOwnersExemptions;
  accountId: number;
  completeStep?: () => void;
} & Pick<ComponentProps<typeof Modal>, 'onCancel' | 'open'>;

export const ConfirmDialog = ({
  owners,
  exemptions,
  accountId,
  completeStep,
  ...rest
}: Props) => {
  const { withShowApiErrorMiddleware } = useAlerts();
  const { execute, isLoading } =
    useAccountBeneficialOwnershipAssessmentUpdate();

  const handleOk = async () => {
    try {
      await withShowApiErrorMiddleware(execute)({
        id: accountId,
        accountsBeneficialOwnershipAssessmentUpdateRequestContract: {
          account_id: accountId,
          finalized: true,
          ...exemptions,
        },
      });
      completeStep?.();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      onOk={handleOk}
      okButtonProps={{ loading: isLoading }}
      title="Confirmation"
      size="md"
      cancelText="Cancel"
      {...rest}
    >
      <Margin bottom="md" left="sm" right="sm">
        <Text size="sm" colorVariant="secondary">
          The following people will be submitted as the complete list of
          beneficial owners and/or control person. Once submitted, entries can
          no longer be added.
        </Text>
      </Margin>
      <Table<AccountsBeneficialOwnerShow>
        dataSource={owners}
        columns={[
          {
            title: 'Full Name',
            render: ({ first, middle, last }) => (
              <>
                {first} {middle} {last}
              </>
            ),
          },
          {
            title: 'Role',
            render: ({ role }) => role.name,
          },
        ]}
      />
    </Modal>
  );
};
