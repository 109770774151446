import { useEffect } from 'react';

export const useResizeEffect = (onResize: () => void, deps: unknown[]) => {
  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  // we need to call the listener whenever its dependencies change
  // so that it can handle the size when all refs are valid
  useEffect(() => {
    onResize();
  }, [...deps]);
};
