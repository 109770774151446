import styled from 'styled-components';

import { Heading3Styles } from '@npm/core/ui/components/atoms/Typography';
import { Modal as BaseModal } from '@npm/core/ui/components/molecules/Modal';
import { getVh, useBreakpoint } from '@npm/core/ui/utils/style';

export const Modal = styled(BaseModal)`
  && {
    width: 98vw !important;
    min-width: 98vw !important;
    ${getVh(98)}
    margin: 0;
    padding: 0;
  }

  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-modal-body {
    flex: 1;
    padding: 0;
  }

  .ant-modal-header .ant-modal-title {
    ${Heading3Styles};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  }

  iframe {
    border: none;
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
      flex-direction: column-reverse;
  `}
`;
