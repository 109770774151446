import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorSkeleton } from '../../atoms/ErrorSkeleton';
import { useConfirmOnLeave } from '../../molecules/Link';
import { DiscardModal } from '../DiscardModal';

import { WizardLayout } from './Layout';
import { useWizardHistoryState } from './Wizard.hooks';

type Props = {
  wizardLayoutProps: Omit<React.ComponentProps<typeof WizardLayout>, 'step'>;
  startIndex?: number;
};

type ChildProps = {
  handleCloseFormAttempt?: (callback?: (() => void) | undefined) => void;
  setFormHasUnsavedChanges?: (value: boolean) => void;
};

export const WizardWrapper: React.FC<Props> = ({
  children,
  wizardLayoutProps,
  startIndex,
}) => {
  useWizardHistoryState(startIndex);
  const { setHasUnsavedChanges, discardModalProps, onCloseAttempt } =
    useConfirmOnLeave();

  const cloneChildrenWithProps = () => {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) return child;

      const extraProps: ChildProps = {
        handleCloseFormAttempt: onCloseAttempt,
        setFormHasUnsavedChanges: setHasUnsavedChanges,
      };

      return React.cloneElement(child, extraProps);
    });
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorSkeleton}>
      <WizardLayout
        {...wizardLayoutProps}
        handleCloseFormAttempt={onCloseAttempt}
      >
        {cloneChildrenWithProps()}
      </WizardLayout>
      <DiscardModal {...discardModalProps}>
        You are trying to exit an unsaved form, do you want to discard the
        changes?
      </DiscardModal>
    </ErrorBoundary>
  );
};
