import { APP_CFG_API_URL } from '@npm/config';

const FULL_URL_REGEX = /^((http(s)?):\/\/)|(\/public)|(data:image\/)/i;
export const getFullImageUrl = (url: string | undefined | null) => {
  if (!url) {
    return url;
  }
  if (FULL_URL_REGEX.test(url)) {
    return url;
  }
  return `${APP_CFG_API_URL}${url}`;
};
