import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import { useZendesk } from '../../widget/zendesk.hooks';

import * as S from './HelpButton.styles';

export const HelpButtonTopbarItem = () => {
  return (
    <S.TopbarButtonWrapper>
      <HelpButton />
    </S.TopbarButtonWrapper>
  );
};

export const HelpButton = () => {
  const { toggle, unreadMessageCount, isEnabled } = useZendesk();

  return (
    <Tooltip
      title={!isEnabled ? 'Online helpdesk is not available right now.' : null}
    >
      <S.HelpLink onClick={isEnabled && toggle}>
        {unreadMessageCount > 0 ? (
          <Tooltip title="You have unread messages from our helpdesk">
            <S.Badge>{unreadMessageCount}</S.Badge>
          </Tooltip>
        ) : (
          <Icon name="lifebuoy" size="sm" />
        )}
        <Text size="sm" colorVariant="primary">
          Help
        </Text>
      </S.HelpLink>
    </Tooltip>
  );
};
