import { type ComponentProps } from 'react';

import { GetStarted as BaseGetStarted } from '@npm/core/ui/components/molecules/GetStarted';

import * as S from './BeneficialOwnershipGetStarted.styles';

export const BeneficialOwnershipGetStarted = ({
  onGetStarted,
}: ComponentProps<typeof BaseGetStarted>) => {
  return (
    <BaseGetStarted
      wide
      onGetStarted={onGetStarted}
      title="Beneficial Ownership Assessment"
      content={
        <S.TextContent>
          To help the government fight the funding of terrorism and money
          laundering activities, federal law requires all financial institutions
          to obtain, verify, and record information that identifies each
          customer.
          <br />
          <br />
          FinCEN’s Customer Due Diligence Rule requires us to identify and
          verify the identities of the beneficial owners of certain types of
          legal entities. Beneficial ownership is determined under two prongs:
          (i) ownership and (ii) control.
          <br />
          <br />
          Depending on your legal entity type you may be required to identify -{' '}
          <ol>
            <li>
              Ownership Prong – each individual (natural person), if any, who,
              directly or indirectly owns 25% or more of the equity interests of
              this legal entity.
            </li>
            <li>
              Control Prong – one individual (natural person) with significant
              responsibility to control or manage this legal entity (e.g., Chief
              Executive Officer, President, Chief Financial Officer, Chief
              Operating Officer, Managing Member, General Partner).
            </li>
          </ol>
        </S.TextContent>
      }
    />
  );
};
