import { Codebooks } from './codebooks.types';

const Items = {
  Institution: 'INSTITUTION',
  Individual: 'INDIVIDUAL',
} as const;

export type CbStakeholderTypeCode = typeof Items[keyof typeof Items];

export const CbStakeholderType = {
  code: Codebooks.STAKEHOLDER,
  items: Items,
};
