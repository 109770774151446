/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  MarkToMarketsApi,
  MTMsErrorCodes,
  MTMsErrorTypes,
  MovingAveragePricesErrorCodes,
  MovingAveragePricesErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new MarkToMarketsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type MTMsMethod = MarkToMarketsApi['mTMs'];
export type MTMsResponseType = MethodResult<MTMsMethod>;

export const useMTMs = (
  variables: Parameters<MTMsMethod>[0],
  config?: UseQueryConfig<
    MTMsMethod,
    MTMsErrorTypes
  >
) => {
  return useQuery<
    MTMsMethod,
    MTMsErrorTypes
  >(
    (...args) => api.mTMs(...args),
    MTMsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MTMs'],
        ...apiQueryConfig.MTMs,
        ...config?.queryConfig
      },
    },
    'MTMs',
  );
};

export const useMTMsInfinite = (
  variables: Parameters<MTMsMethod>[0],
  config?: UseInfiniteQueryConfig<
    MTMsMethod,
    MTMsErrorTypes
  >
) => {
  return useInfiniteQuery<
    MTMsMethod,
    MTMsErrorTypes
  >(
    (...args) => api.mTMs(...args),
    MTMsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MTMs', 'MTMsInfinite'],
        ...apiQueryConfig.MTMs,
        ...config?.queryConfig,
      },
    },
    'MTMs',
  );
};

export const useMTMsLazy = (baseOptions?: {
  variables?: Parameters<MTMsMethod>[0],
  config?: UseQueryConfig<
    MTMsMethod,
    MTMsErrorTypes
  >
}) => {
  return useLazyQuery<
    MTMsMethod,
    MTMsErrorTypes
  >(
    (...args) => api.mTMs(...args),
    MTMsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MTMs'],
        ...apiQueryConfig.MTMs,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MTMs',
  );
};


export type MovingAveragePricesMethod = MarkToMarketsApi['movingAveragePrices'];
export type MovingAveragePricesResponseType = MethodResult<MovingAveragePricesMethod>;

export const useMovingAveragePrices = (
  variables: Parameters<MovingAveragePricesMethod>[0],
  config?: UseQueryConfig<
    MovingAveragePricesMethod,
    MovingAveragePricesErrorTypes
  >
) => {
  return useQuery<
    MovingAveragePricesMethod,
    MovingAveragePricesErrorTypes
  >(
    (...args) => api.movingAveragePrices(...args),
    MovingAveragePricesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MovingAveragePrices'],
        ...apiQueryConfig.MovingAveragePrices,
        ...config?.queryConfig
      },
    },
    'MovingAveragePrices',
  );
};

export const useMovingAveragePricesInfinite = (
  variables: Parameters<MovingAveragePricesMethod>[0],
  config?: UseInfiniteQueryConfig<
    MovingAveragePricesMethod,
    MovingAveragePricesErrorTypes
  >
) => {
  return useInfiniteQuery<
    MovingAveragePricesMethod,
    MovingAveragePricesErrorTypes
  >(
    (...args) => api.movingAveragePrices(...args),
    MovingAveragePricesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MovingAveragePrices', 'MovingAveragePricesInfinite'],
        ...apiQueryConfig.MovingAveragePrices,
        ...config?.queryConfig,
      },
    },
    'MovingAveragePrices',
  );
};

export const useMovingAveragePricesLazy = (baseOptions?: {
  variables?: Parameters<MovingAveragePricesMethod>[0],
  config?: UseQueryConfig<
    MovingAveragePricesMethod,
    MovingAveragePricesErrorTypes
  >
}) => {
  return useLazyQuery<
    MovingAveragePricesMethod,
    MovingAveragePricesErrorTypes
  >(
    (...args) => api.movingAveragePrices(...args),
    MovingAveragePricesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MovingAveragePrices'],
        ...apiQueryConfig.MovingAveragePrices,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MovingAveragePrices',
  );
};

