import { Codebooks } from './codebooks.types';

const items = {
  direct: 'direct',
  forward: 'forward',
  spv: 'spv',
} as const;

export type SubmissionStructureCode = keyof typeof items;

export const CbSubmissionStructure = {
  code: Codebooks.SUBMISSION_STRUCTURE,
  items,
};
