/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DestroySuccess } from '../model';
// @ts-ignore
import { ErrorValidation } from '../model';
// @ts-ignore
import { UserRoleAggregate } from '../model';
// @ts-ignore
import { UserRoleCreateRequestContract } from '../model';
// @ts-ignore
import { UserRoleIndex } from '../model';
// @ts-ignore
import { UserRoleUpdateRequestContract } from '../model';
/**
 * UserRoleApi - axios parameter creator
 * @export
 */
export const UserRoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a new user role for the provided account
         * @param {UserRoleCreateRequestContract} userRoleCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleCreate: async (userRoleCreateRequestContract: UserRoleCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRoleCreateRequestContract' is not null or undefined
            assertParamExists('userRoleCreate', 'userRoleCreateRequestContract', userRoleCreateRequestContract)
            const localVarPath = changeUrl(`/api/user-roles`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRoleCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete the user roles
         * @param {number} [userId] 
         * @param {number} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleDestroy: async (userId?: number, accountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/user-roles`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all user-roles and details for the authorized user
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [eventId] 
         * @param {number} [accountId] 
         * @param {number} [issuerEntityId] 
         * @param {boolean} [taxForm] 
         * @param {Array<string>} [userRoleStatus] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleIndex: async (page?: number, size?: number, search?: string, eventId?: number, accountId?: number, issuerEntityId?: number, taxForm?: boolean, userRoleStatus?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/user-roles`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (eventId !== undefined) {
                localVarQueryParameter['event_id'] = eventId;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }

            if (taxForm !== undefined) {
                localVarQueryParameter['tax_form'] = taxForm;
            }

            if (userRoleStatus) {
                localVarQueryParameter['user_role_status'] = userRoleStatus.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary shows a user-role
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleShow: async (id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userRoleShow', 'id', id)
            const localVarPath = changeUrl(`/api/user-roles/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates the active role for the provided account
         * @param {UserRoleUpdateRequestContract} userRoleUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleUpdate: async (userRoleUpdateRequestContract: UserRoleUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRoleUpdateRequestContract' is not null or undefined
            assertParamExists('userRoleUpdate', 'userRoleUpdateRequestContract', userRoleUpdateRequestContract)
            const localVarPath = changeUrl(`/api/user-roles`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRoleUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRoleApi - functional programming interface
 * @export
 */
export const UserRoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a new user role for the provided account
         * @param {UserRoleCreateRequestContract} userRoleCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRoleCreate(userRoleCreateRequestContract: UserRoleCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoleAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRoleCreate(userRoleCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete the user roles
         * @param {number} [userId] 
         * @param {number} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRoleDestroy(userId?: number, accountId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroySuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRoleDestroy(userId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all user-roles and details for the authorized user
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [eventId] 
         * @param {number} [accountId] 
         * @param {number} [issuerEntityId] 
         * @param {boolean} [taxForm] 
         * @param {Array<string>} [userRoleStatus] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRoleIndex(page?: number, size?: number, search?: string, eventId?: number, accountId?: number, issuerEntityId?: number, taxForm?: boolean, userRoleStatus?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoleIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRoleIndex(page, size, search, eventId, accountId, issuerEntityId, taxForm, userRoleStatus, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary shows a user-role
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRoleShow(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoleAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRoleShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates the active role for the provided account
         * @param {UserRoleUpdateRequestContract} userRoleUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRoleUpdate(userRoleUpdateRequestContract: UserRoleUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoleAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRoleUpdate(userRoleUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserRoleApi - factory interface
 * @export
 */
export const UserRoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRoleApiFp(configuration)
    return {
        /**
         * 
         * @summary create a new user role for the provided account
         * @param {UserRoleCreateRequestContract} userRoleCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleCreate(userRoleCreateRequestContract: UserRoleCreateRequestContract, options?: any): AxiosPromise<UserRoleAggregate> {
            return localVarFp.userRoleCreate(userRoleCreateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete the user roles
         * @param {number} [userId] 
         * @param {number} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleDestroy(userId?: number, accountId?: number, options?: any): AxiosPromise<DestroySuccess> {
            return localVarFp.userRoleDestroy(userId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all user-roles and details for the authorized user
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [eventId] 
         * @param {number} [accountId] 
         * @param {number} [issuerEntityId] 
         * @param {boolean} [taxForm] 
         * @param {Array<string>} [userRoleStatus] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleIndex(page?: number, size?: number, search?: string, eventId?: number, accountId?: number, issuerEntityId?: number, taxForm?: boolean, userRoleStatus?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<UserRoleIndex> {
            return localVarFp.userRoleIndex(page, size, search, eventId, accountId, issuerEntityId, taxForm, userRoleStatus, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary shows a user-role
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleShow(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<UserRoleAggregate> {
            return localVarFp.userRoleShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates the active role for the provided account
         * @param {UserRoleUpdateRequestContract} userRoleUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleUpdate(userRoleUpdateRequestContract: UserRoleUpdateRequestContract, options?: any): AxiosPromise<UserRoleAggregate> {
            return localVarFp.userRoleUpdate(userRoleUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for userRoleCreate operation in UserRoleApi.
 * @export
 * @interface UserRoleApiUserRoleCreateRequest
 */
export interface UserRoleApiUserRoleCreateRequest {
    /**
     * 
     * @type {UserRoleCreateRequestContract}
     * @memberof UserRoleApiUserRoleCreate
     */
    readonly userRoleCreateRequestContract: UserRoleCreateRequestContract
}

/**
 * Request parameters for userRoleDestroy operation in UserRoleApi.
 * @export
 * @interface UserRoleApiUserRoleDestroyRequest
 */
export interface UserRoleApiUserRoleDestroyRequest {
    /**
     * 
     * @type {number}
     * @memberof UserRoleApiUserRoleDestroy
     */
    readonly userId?: number

    /**
     * 
     * @type {number}
     * @memberof UserRoleApiUserRoleDestroy
     */
    readonly accountId?: number
}

/**
 * Request parameters for userRoleIndex operation in UserRoleApi.
 * @export
 * @interface UserRoleApiUserRoleIndexRequest
 */
export interface UserRoleApiUserRoleIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof UserRoleApiUserRoleIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof UserRoleApiUserRoleIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleIndex
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof UserRoleApiUserRoleIndex
     */
    readonly eventId?: number

    /**
     * 
     * @type {number}
     * @memberof UserRoleApiUserRoleIndex
     */
    readonly accountId?: number

    /**
     * 
     * @type {number}
     * @memberof UserRoleApiUserRoleIndex
     */
    readonly issuerEntityId?: number

    /**
     * 
     * @type {boolean}
     * @memberof UserRoleApiUserRoleIndex
     */
    readonly taxForm?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof UserRoleApiUserRoleIndex
     */
    readonly userRoleStatus?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for userRoleShow operation in UserRoleApi.
 * @export
 * @interface UserRoleApiUserRoleShowRequest
 */
export interface UserRoleApiUserRoleShowRequest {
    /**
     * 
     * @type {number}
     * @memberof UserRoleApiUserRoleShow
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for userRoleUpdate operation in UserRoleApi.
 * @export
 * @interface UserRoleApiUserRoleUpdateRequest
 */
export interface UserRoleApiUserRoleUpdateRequest {
    /**
     * 
     * @type {UserRoleUpdateRequestContract}
     * @memberof UserRoleApiUserRoleUpdate
     */
    readonly userRoleUpdateRequestContract: UserRoleUpdateRequestContract
}

/**
 * UserRoleApi - object-oriented interface
 * @export
 * @class UserRoleApi
 * @extends {BaseAPI}
 */
export class UserRoleApi extends BaseAPI {
    /**
     * 
     * @summary create a new user role for the provided account
     * @param {UserRoleApiUserRoleCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApi
     */
    public userRoleCreate(requestParameters: UserRoleApiUserRoleCreateRequest, options?: AxiosRequestConfig) {
        return UserRoleApiFp(this.configuration).userRoleCreate(requestParameters.userRoleCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete the user roles
     * @param {UserRoleApiUserRoleDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApi
     */
    public userRoleDestroy(requestParameters: UserRoleApiUserRoleDestroyRequest = {}, options?: AxiosRequestConfig) {
        return UserRoleApiFp(this.configuration).userRoleDestroy(requestParameters.userId, requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all user-roles and details for the authorized user
     * @param {UserRoleApiUserRoleIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApi
     */
    public userRoleIndex(requestParameters: UserRoleApiUserRoleIndexRequest = {}, options?: AxiosRequestConfig) {
        return UserRoleApiFp(this.configuration).userRoleIndex(requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.eventId, requestParameters.accountId, requestParameters.issuerEntityId, requestParameters.taxForm, requestParameters.userRoleStatus, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary shows a user-role
     * @param {UserRoleApiUserRoleShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApi
     */
    public userRoleShow(requestParameters: UserRoleApiUserRoleShowRequest, options?: AxiosRequestConfig) {
        return UserRoleApiFp(this.configuration).userRoleShow(requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates the active role for the provided account
     * @param {UserRoleApiUserRoleUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApi
     */
    public userRoleUpdate(requestParameters: UserRoleApiUserRoleUpdateRequest, options?: AxiosRequestConfig) {
        return UserRoleApiFp(this.configuration).userRoleUpdate(requestParameters.userRoleUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type UserRoleCreateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const UserRoleCreateErrorCodes = [
];

export type UserRoleDestroyErrorTypes =

    | {
        status: 422;
        data: DestroySuccess;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const UserRoleDestroyErrorCodes = [
    422,
];

export type UserRoleIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const UserRoleIndexErrorCodes = [
    401,
    422,
];

export type UserRoleShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const UserRoleShowErrorCodes = [
];

export type UserRoleUpdateErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const UserRoleUpdateErrorCodes = [
    422,
];


