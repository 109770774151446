/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  NotificationApi,
  NotificationIndexErrorCodes,
  NotificationIndexErrorTypes,
  NotificationBulkUpdateErrorCodes,
  NotificationShowErrorCodes,
  NotificationShowErrorTypes,
  NotificationUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new NotificationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type NotificationIndexMethod = NotificationApi['notificationIndex'];
export type NotificationIndexResponseType = MethodResult<NotificationIndexMethod>;

export const useNotificationIndex = (
  variables: Parameters<NotificationIndexMethod>[0],
  config?: UseQueryConfig<
    NotificationIndexMethod,
    NotificationIndexErrorTypes
  >
) => {
  return useQuery<
    NotificationIndexMethod,
    NotificationIndexErrorTypes
  >(
    (...args) => api.notificationIndex(...args),
    NotificationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NotificationIndex'],
        ...apiQueryConfig.NotificationIndex,
        ...config?.queryConfig
      },
    },
    'NotificationIndex',
  );
};

export const useNotificationIndexInfinite = (
  variables: Parameters<NotificationIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    NotificationIndexMethod,
    NotificationIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    NotificationIndexMethod,
    NotificationIndexErrorTypes
  >(
    (...args) => api.notificationIndex(...args),
    NotificationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NotificationIndex', 'NotificationIndexInfinite'],
        ...apiQueryConfig.NotificationIndex,
        ...config?.queryConfig,
      },
    },
    'NotificationIndex',
  );
};

export const useNotificationIndexLazy = (baseOptions?: {
  variables?: Parameters<NotificationIndexMethod>[0],
  config?: UseQueryConfig<
    NotificationIndexMethod,
    NotificationIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    NotificationIndexMethod,
    NotificationIndexErrorTypes
  >(
    (...args) => api.notificationIndex(...args),
    NotificationIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['NotificationIndex'],
        ...apiQueryConfig.NotificationIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'NotificationIndex',
  );
};


export type NotificationBulkUpdateMethod = NotificationApi['notificationBulkUpdate'];

export const useNotificationBulkUpdate = () => {
  return useMutation<NotificationBulkUpdateMethod>(
    (...args) => api.notificationBulkUpdate(...args),
    NotificationBulkUpdateErrorCodes,
    'NotificationBulkUpdate',
    '/api/notifications/bulk_update',
    'patch'
  );
};

export type NotificationShowMethod = NotificationApi['notificationShow'];
export type NotificationShowResponseType = MethodResult<NotificationShowMethod>;

export const useNotificationShow = (
  variables: Parameters<NotificationShowMethod>[0],
  config?: UseQueryConfig<
    NotificationShowMethod,
    NotificationShowErrorTypes
  >
) => {
  return useQuery<
    NotificationShowMethod,
    NotificationShowErrorTypes
  >(
    (...args) => api.notificationShow(...args),
    NotificationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NotificationShow'],
        ...apiQueryConfig.NotificationShow,
        ...config?.queryConfig
      },
    },
    'NotificationShow',
  );
};

export const useNotificationShowInfinite = (
  variables: Parameters<NotificationShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    NotificationShowMethod,
    NotificationShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    NotificationShowMethod,
    NotificationShowErrorTypes
  >(
    (...args) => api.notificationShow(...args),
    NotificationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NotificationShow', 'NotificationShowInfinite'],
        ...apiQueryConfig.NotificationShow,
        ...config?.queryConfig,
      },
    },
    'NotificationShow',
  );
};

export const useNotificationShowLazy = (baseOptions?: {
  variables?: Parameters<NotificationShowMethod>[0],
  config?: UseQueryConfig<
    NotificationShowMethod,
    NotificationShowErrorTypes
  >
}) => {
  return useLazyQuery<
    NotificationShowMethod,
    NotificationShowErrorTypes
  >(
    (...args) => api.notificationShow(...args),
    NotificationShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['NotificationShow'],
        ...apiQueryConfig.NotificationShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'NotificationShow',
  );
};


export type NotificationUpdateMethod = NotificationApi['notificationUpdate'];

export const useNotificationUpdate = () => {
  return useMutation<NotificationUpdateMethod>(
    (...args) => api.notificationUpdate(...args),
    NotificationUpdateErrorCodes,
    'NotificationUpdate',
    '/api/notifications/{id}',
    'put'
  );
};
