import styled from 'styled-components';

import { Card as CardBase } from '@npm/core/ui/components/molecules/Card';

export const Card = styled(CardBase)`
  .card-content {
    padding: 0;

    & * {
      text-align: center;
    }
  }

  background-image: ${({ theme }) =>
    theme.isDarkMode
      ? "url('/public/assets/company-data/not-covered-bg-dark.png')"
      : "url('/public/assets/company-data/not-covered-bg-light.png')"};
  background-position: center;
  background-size: cover;
`;
