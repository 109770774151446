import { type Account } from '@npm/data-access';

import { useCurrentPersonId } from '../../auth/user/role/hooks/useCurrentPersonId';

export const useGetPostOnboardingPersonId = () => {
  const personIdForEntityOnboarding = useCurrentPersonId();

  return (account: Account | undefined) => {
    return account?.type?.code === 'PersonAccount'
      ? account?.person_id
      : personIdForEntityOnboarding;
  };
};
