import React, { type ComponentProps, useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { useAccountBeneficialOwnershipAssessmentDestroy } from '@npm/data-access';

import { ConfirmModal } from './ConfirmModal';

type Props = ComponentProps<typeof Button> & {
  accountId: number;
  accountName: string;
  onReset?: () => void;
};

export const ResetButton = ({
  accountId,
  accountName,
  onClick,
  onReset: afterReset,
  ...rest
}: Props) => {
  const { withShowApiErrorMiddleware } = useAlerts();
  const [form] = Form.useForm();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { execute, isLoading } =
    useAccountBeneficialOwnershipAssessmentDestroy();
  const reset = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onClick?.(event);
    setConfirmModalOpen(true);
  };

  const onReset = async () => {
    try {
      await withShowApiErrorMiddleware(execute)({ id: accountId });
      setConfirmModalOpen(false);
      afterReset?.();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Button {...rest} onClick={reset} color="error" variant="outline">
        Reset
      </Button>

      <ConfirmModal
        accountName={accountName}
        form={form}
        okButtonProps={{ loading: isLoading }}
        onOk={onReset}
        open={confirmModalOpen}
        onCancel={() => setConfirmModalOpen(false)}
      />
    </>
  );
};
