import styled from 'styled-components';

import { centerFlex, useBreakpoint } from '../../../utils/style';
import { BodyTextStyles } from '../../atoms/Typography';

const GET_STARTED_TEXT_MAX_WIDTH = 400;
const GET_STARTED_TEXT_MAX_WIDTH_WIDE = 600;

export const Wrap = styled.div`
  ${centerFlex};
  flex-direction: column;
  text-align: center;
  min-height: 100%;
`;

export const IconWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md}px;

  svg {
    color: ${({ theme }) => theme.color.info.typography.primary};
  }
`;

export const Paragraphs = styled.div<{ $withBullets: boolean; $wide: boolean }>`
  max-width: ${({ $wide }) =>
    $wide ? GET_STARTED_TEXT_MAX_WIDTH_WIDE : GET_STARTED_TEXT_MAX_WIDTH}px;

  &&& {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;

    & > *:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.xs}px;
    }
  }

  &&,
  && > * {
    ${BodyTextStyles}
    color: ${({ theme }) => theme.color.general.typography.secondary};
    text-align: center;

    ${({ $withBullets }) =>
      $withBullets &&
      `
      text-align: left;
    `}

    ${({ theme }) => useBreakpoint(theme).tablet`
      text-align: center;

      & > *:before {
        display: none;
      }
    `}
  }

  ${({ theme }) => useBreakpoint(theme).tablet`
    width: 100%;

    && {
      margin-bottom: ${theme.spacing.lg / 2}px;
    }
  `}

  ${({ $withBullets }) =>
    $withBullets &&
    `
      & > *:before {
        content: '• ';
      }
    `}
`;
