/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AgreementHistoryApi,
  AgreementHistoryIndexErrorCodes,
  AgreementHistoryIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AgreementHistoryApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AgreementHistoryIndexMethod = AgreementHistoryApi['agreementHistoryIndex'];
export type AgreementHistoryIndexResponseType = MethodResult<AgreementHistoryIndexMethod>;

export const useAgreementHistoryIndex = (
  variables: Parameters<AgreementHistoryIndexMethod>[0],
  config?: UseQueryConfig<
    AgreementHistoryIndexMethod,
    AgreementHistoryIndexErrorTypes
  >
) => {
  return useQuery<
    AgreementHistoryIndexMethod,
    AgreementHistoryIndexErrorTypes
  >(
    (...args) => api.agreementHistoryIndex(...args),
    AgreementHistoryIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AgreementHistoryIndex'],
        ...apiQueryConfig.AgreementHistoryIndex,
        ...config?.queryConfig
      },
    },
    'AgreementHistoryIndex',
  );
};

export const useAgreementHistoryIndexInfinite = (
  variables: Parameters<AgreementHistoryIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AgreementHistoryIndexMethod,
    AgreementHistoryIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AgreementHistoryIndexMethod,
    AgreementHistoryIndexErrorTypes
  >(
    (...args) => api.agreementHistoryIndex(...args),
    AgreementHistoryIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AgreementHistoryIndex', 'AgreementHistoryIndexInfinite'],
        ...apiQueryConfig.AgreementHistoryIndex,
        ...config?.queryConfig,
      },
    },
    'AgreementHistoryIndex',
  );
};

export const useAgreementHistoryIndexLazy = (baseOptions?: {
  variables?: Parameters<AgreementHistoryIndexMethod>[0],
  config?: UseQueryConfig<
    AgreementHistoryIndexMethod,
    AgreementHistoryIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AgreementHistoryIndexMethod,
    AgreementHistoryIndexErrorTypes
  >(
    (...args) => api.agreementHistoryIndex(...args),
    AgreementHistoryIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AgreementHistoryIndex'],
        ...apiQueryConfig.AgreementHistoryIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AgreementHistoryIndex',
  );
};

