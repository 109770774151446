import { useState } from 'react';

import type { OrderSizeType } from '../order.types';

export const useOrderSizeToggle = (defaultSizeType?: OrderSizeType) => {
  const [sizeType, setSizeType] = useState<OrderSizeType>(
    defaultSizeType ?? 'Shares'
  );

  const toggleSizeType = () => {
    setSizeType(sizeType => (sizeType === 'Shares' ? 'USD' : 'Shares'));
  };

  return {
    sizeType,
    updateSizeType: setSizeType,
    toggleSizeType,
  };
};
