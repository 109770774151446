import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { useAccountBeneficialOwnerDestroy } from '@npm/data-access';

type Props = {
  name: string;
  id: number;
  accountId: number;
  open: boolean;
  onCancel: () => void;
};

export const RemoveOwnerConfirm = ({
  name,
  id,
  accountId,
  open,
  onCancel,
}: Props) => {
  const { withShowApiErrorMiddleware } = useAlerts();
  const { execute, isLoading: removeLoading } =
    useAccountBeneficialOwnerDestroy();

  const removeOwner = async () => {
    try {
      await withShowApiErrorMiddleware(execute)({ id, accountId });
      onCancel();
    } catch (err) {
      console.error(err);
    }
  };

  const handleCancel = () => {
    if (!removeLoading) {
      onCancel();
    }
  };

  return (
    <Modal
      size="md"
      title={`Remove ${name}`}
      okText="Remove"
      cancelText="Cancel"
      okButtonProps={{ color: 'error', loading: removeLoading }}
      cancelButtonProps={{ disabled: removeLoading }}
      onOk={removeOwner}
      open={open}
      onCancel={handleCancel}
    >
      <Text>Removing this user will delete any associated information.</Text>
    </Modal>
  );
};
