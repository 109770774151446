import {
  type RoleType,
  CbAccountSponsorshipType,
  CbAccountType,
  CbWorkstationType,
} from '@npm/data-access';

import { type RbacFn } from './permissions.types';
import { isUserPermitted } from './permissions.utils';

// with exception for 'isUserAccountCentric', 'isUserInAllAccounts', 'isInvAoboOrAccountCentric'
export const RBAC = {
  investor: {
    isUserAccountCentric: userContext =>
      userContext.workstation?.type?.code ===
        CbWorkstationType.items.investor && userContext.getIsAccountCentric(),
    isUserInAllAccounts: userContext =>
      userContext.workstation?.type?.code ===
        CbWorkstationType.items.investor && !userContext.getIsAccountCentric(),
    isInvMenuAgreements: userContext =>
      isUserPermitted(
        'INV_MENU_AGREEMENTS',
        userContext.role?.role_name?.code as RoleType
      ),
    isInvMenuNda: userContext =>
      isUserPermitted(
        'INV_MENU_NDA',
        userContext.role?.role_name?.code as RoleType
      ),
    isInvAgreementsSign: userContext =>
      isUserPermitted(
        'INV_AGREEMENTS_SIGN',
        userContext.role?.role_name?.code as RoleType
      ),
    isInvMenuOnboarding: userContext =>
      isUserPermitted(
        'INV_MENU_ONBOARDING',
        userContext.role?.role_name?.code as RoleType
      ) &&
      (!userContext.isSpouseOrGuestRolesOnly || !!userContext.obo),
    isInvMenuOther: userContext =>
      isUserPermitted(
        'INV_MENU_OTHER',
        userContext.role?.role_name?.code as RoleType
      ) &&
      (!userContext.isSpouseRolesOnly || !!userContext.obo),
    isInvWrite: userContext =>
      isUserPermitted(
        'INV_WRITE',
        userContext.role?.role_name?.code as RoleType
      ),
    isInvAobo: userContext =>
      isUserPermitted(
        'INV_AOBO',
        userContext.role?.role_name?.code as RoleType
      ),
    isInvAoboOrAccountCentric: userContext =>
      isUserPermitted(
        'INV_AOBO',
        userContext.role?.role_name?.code as RoleType
      ) ||
      (userContext.workstation?.type?.code ===
        CbWorkstationType.items.investor &&
        userContext.getIsAccountCentric()),
    isInvNotAobo: userContext =>
      userContext.workstation?.type?.code ===
        CbWorkstationType.items.investor && !userContext.obo,
    isUserNotAnonymous: userContext =>
      userContext?.role?.subject?.type?.code !==
        CbAccountType.items.Anonymous &&
      userContext?.obo?.account?.type?.code !== CbAccountType.items.Anonymous,
    isSponsoredInvestor: userContext =>
      CbAccountSponsorshipType.isSponsoredAccount(userContext?.sponsorshipType),
    isSponsoredInvestorLevel2: userContext =>
      CbAccountSponsorshipType.isSponsoredAccountLevel2(
        userContext?.sponsorshipType
      ),
    isSponsoredInvestorLevel3: userContext =>
      CbAccountSponsorshipType.isSponsoredAccountLevel3(
        userContext?.sponsorshipType
      ),
    isRegularInvestor: userContext => !userContext?.sponsorshipType,
    isIndividualNpmsInvestor: userContext =>
      !!userContext?.isIndividualNpmsInvestor,
    isEntityNpmsInvestor: userContext => !!userContext?.isEntityNpmsInvestor,
    isInvididualNotNpmsInvestor: userContext =>
      !!userContext.investorAccounts &&
      !userContext?.isEntityNpmsInvestor &&
      !userContext?.isIndividualNpmsInvestor &&
      !userContext.investorAccounts.some(
        role =>
          role?.subject?.type?.code === CbAccountType.items.OrganizationAccount
      ),
    isNpmSettlementInvestor: userContext =>
      !!userContext?.isNpmSettlementInvestor,
    isEntityNotNpmsInvestor: userContext =>
      !!userContext.investorAccounts &&
      !userContext?.isEntityNpmsInvestor &&
      !userContext?.isIndividualNpmsInvestor &&
      userContext.investorAccounts.some(
        role =>
          role?.subject?.type?.code === CbAccountType.items.OrganizationAccount
      ),
    hasActiveEvents: userContext => !userContext?.hasActiveEvents,
  },
  issuer: {
    isIssMenuAgreements: userContext =>
      isUserPermitted(
        'ISS_MENU_AGREEMENTS',
        userContext.role?.role_name?.code as RoleType
      ),
    isIssAgreementsSign: userContext =>
      isUserPermitted(
        'ISS_AGREEMENTS_SIGN',
        userContext.role?.role_name?.code as RoleType
      ),
    isIssMenuPrograms: userContext =>
      isUserPermitted(
        'ISS_MENU_PROGRAMS',
        userContext.role?.role_name?.code as RoleType
      ),
    isIssDataRoomUpload: userContext =>
      isUserPermitted(
        'ISS_DATAROOM_UPLOAD',
        userContext.role?.role_name?.code as RoleType
      ),
    isIssWrite: userContext =>
      isUserPermitted(
        'ISS_WRITE',
        userContext.role?.role_name?.code as RoleType
      ),
    isIssMenuOther: userContext =>
      isUserPermitted(
        'ISS_MENU_OTHER',
        userContext.role?.role_name?.code as RoleType
      ),
  },
  brokerage: {
    isBroObo: userContext =>
      isUserPermitted('BRO_OBO', userContext.role?.role_name?.code as RoleType),
    isBroMenuOther: userContext =>
      isUserPermitted(
        'BRO_MENU_OTHER',
        userContext.role?.role_name?.code as RoleType
      ),
    isBroExternalizeOrders: userContext =>
      isUserPermitted(
        'BRO_EXTERNALIZE_ORDERS',
        userContext.role?.role_name?.code as RoleType
      ),
    isBroEditOrders: userContext =>
      isUserPermitted(
        'BRO_EDIT_ORDERS',
        userContext.role?.role_name?.code as RoleType
      ),
    isBroNegotiate: userContext =>
      isUserPermitted(
        'BRO_NEGOTIATE',
        userContext.role?.role_name?.code as RoleType
      ),
    isBroReadOnly: userContext =>
      isUserPermitted(
        'BRO_READ_ONLY',
        userContext.role?.role_name?.code as RoleType
      ),
    isBroNotReadOnly: userContext =>
      !isUserPermitted(
        'BRO_READ_ONLY',
        userContext.role?.role_name?.code as RoleType
      ),
    isBroAccountManager: userContext =>
      isUserPermitted(
        'BRO_ACCOUNT_MANAGER',
        userContext.role?.role_name?.code as RoleType
      ),
    isBroNotAccountManager: userContext =>
      !isUserPermitted(
        'BRO_ACCOUNT_MANAGER',
        userContext.role?.role_name?.code as RoleType
      ),
  },
} satisfies Record<'investor' | 'issuer' | 'brokerage', Record<string, RbacFn>>;
