import React from 'react';

import { type TypographyItemProps } from '../typography.types';
import { getAdditionalProps } from '../typography.utils';

import * as S from './Heading.styles';

type Props = TypographyItemProps & {
  variant: 'h0' | 'h1' | 'h2' | 'h3';
  textAlign?: 'left' | 'center' | 'right';
};

export const Heading = ({
  children,
  variant,
  as,
  marginBottom,
  color,
  colorVariant,
  onClick,
  href,
  target,
  textAlign,
  className,
  style,
}: Props) => {
  return (
    <S.Heading
      style={style}
      as={as || (variant === 'h0' ? 'h1' : variant)}
      className={className}
      $variant={variant}
      $marginBottom={marginBottom}
      $color={color}
      $colorVariant={colorVariant}
      $textAlign={textAlign}
      {...getAdditionalProps({ onClick, href, target })}
    >
      {children}
    </S.Heading>
  );
};
