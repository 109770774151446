/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActionStatusResult } from '../model';
// @ts-ignore
import { Url } from '../model';
/**
 * ProofOfOwnershipApi - axios parameter creator
 * @export
 */
export const ProofOfOwnershipApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary uploads proof of ownership
         * @param {File} file 
         * @param {string} securityNpmGuid 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proofOfOwnershipCreate: async (file: File, securityNpmGuid: string, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('proofOfOwnershipCreate', 'file', file)
            // verify required parameter 'securityNpmGuid' is not null or undefined
            assertParamExists('proofOfOwnershipCreate', 'securityNpmGuid', securityNpmGuid)
            const localVarPath = changeUrl(`/api/investor/proof_of_ownerships`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (securityNpmGuid !== undefined) { 
                localVarFormParams.append('security_npm_guid', securityNpmGuid as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloads proof of ownership
         * @param {number} id 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proofOfOwnershipDownload: async (id: number, xWorkstation?: string, xNPMUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proofOfOwnershipDownload', 'id', id)
            const localVarPath = changeUrl(`/api/investor/proof_of_ownerships/{id}/download`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProofOfOwnershipApi - functional programming interface
 * @export
 */
export const ProofOfOwnershipApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProofOfOwnershipApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary uploads proof of ownership
         * @param {File} file 
         * @param {string} securityNpmGuid 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proofOfOwnershipCreate(file: File, securityNpmGuid: string, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proofOfOwnershipCreate(file, securityNpmGuid, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary downloads proof of ownership
         * @param {number} id 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proofOfOwnershipDownload(id: number, xWorkstation?: string, xNPMUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Url>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proofOfOwnershipDownload(id, xWorkstation, xNPMUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProofOfOwnershipApi - factory interface
 * @export
 */
export const ProofOfOwnershipApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProofOfOwnershipApiFp(configuration)
    return {
        /**
         * 
         * @summary uploads proof of ownership
         * @param {File} file 
         * @param {string} securityNpmGuid 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proofOfOwnershipCreate(file: File, securityNpmGuid: string, xWorkstation?: string, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.proofOfOwnershipCreate(file, securityNpmGuid, xWorkstation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary downloads proof of ownership
         * @param {number} id 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proofOfOwnershipDownload(id: number, xWorkstation?: string, xNPMUser?: string, options?: any): AxiosPromise<Url> {
            return localVarFp.proofOfOwnershipDownload(id, xWorkstation, xNPMUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for proofOfOwnershipCreate operation in ProofOfOwnershipApi.
 * @export
 * @interface ProofOfOwnershipApiProofOfOwnershipCreateRequest
 */
export interface ProofOfOwnershipApiProofOfOwnershipCreateRequest {
    /**
     * 
     * @type {File}
     * @memberof ProofOfOwnershipApiProofOfOwnershipCreate
     */
    readonly file: File

    /**
     * 
     * @type {string}
     * @memberof ProofOfOwnershipApiProofOfOwnershipCreate
     */
    readonly securityNpmGuid: string

    /**
     * 
     * @type {string}
     * @memberof ProofOfOwnershipApiProofOfOwnershipCreate
     */
    readonly xWorkstation?: string
}

/**
 * Request parameters for proofOfOwnershipDownload operation in ProofOfOwnershipApi.
 * @export
 * @interface ProofOfOwnershipApiProofOfOwnershipDownloadRequest
 */
export interface ProofOfOwnershipApiProofOfOwnershipDownloadRequest {
    /**
     * 
     * @type {number}
     * @memberof ProofOfOwnershipApiProofOfOwnershipDownload
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof ProofOfOwnershipApiProofOfOwnershipDownload
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof ProofOfOwnershipApiProofOfOwnershipDownload
     */
    readonly xNPMUser?: string
}

/**
 * ProofOfOwnershipApi - object-oriented interface
 * @export
 * @class ProofOfOwnershipApi
 * @extends {BaseAPI}
 */
export class ProofOfOwnershipApi extends BaseAPI {
    /**
     * 
     * @summary uploads proof of ownership
     * @param {ProofOfOwnershipApiProofOfOwnershipCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProofOfOwnershipApi
     */
    public proofOfOwnershipCreate(requestParameters: ProofOfOwnershipApiProofOfOwnershipCreateRequest, options?: AxiosRequestConfig) {
        return ProofOfOwnershipApiFp(this.configuration).proofOfOwnershipCreate(requestParameters.file, requestParameters.securityNpmGuid, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary downloads proof of ownership
     * @param {ProofOfOwnershipApiProofOfOwnershipDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProofOfOwnershipApi
     */
    public proofOfOwnershipDownload(requestParameters: ProofOfOwnershipApiProofOfOwnershipDownloadRequest, options?: AxiosRequestConfig) {
        return ProofOfOwnershipApiFp(this.configuration).proofOfOwnershipDownload(requestParameters.id, requestParameters.xWorkstation, requestParameters.xNPMUser, options).then((request) => request(this.axios, this.basePath));
    }
}

export type ProofOfOwnershipCreateErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const ProofOfOwnershipCreateErrorCodes = [
    422,
];

export type ProofOfOwnershipDownloadErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const ProofOfOwnershipDownloadErrorCodes = [
    404,
];


