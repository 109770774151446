import { type ComponentProps } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import {
  type TextWeight,
  Text,
} from '@npm/core/ui/components/atoms/Typography';
import { type CodebookItem, CbAccountType } from '@npm/data-access';

type Props = {
  name?: string;
  externalId?: string;
  brokerageFirmName?: string;
  type?: CodebookItem;
  isActive?: boolean;
  weight?: TextWeight;
};

export const Name = ({
  name,
  externalId,
  brokerageFirmName,
  type,
  isActive,
  weight,
}: Props) => {
  const isForeignAccount = !externalId || !brokerageFirmName;
  const isAnonymousAccount = type?.code === CbAccountType.items.Anonymous;
  const isRoutingBroker = !name?.length;
  const isSponsoredClient = false;

  const generalTextProps: Omit<ComponentProps<typeof Text>, 'children'> = {
    size: 'sm',
    color: isActive ? 'info' : 'general',
    colorVariant: 'primary',
    weight,
  };

  if (isForeignAccount) {
    return (
      <Flex gap="xs" align="center">
        <Text size="xs" color="general" colorVariant="tertiary">
          <Flex align="center" justify="center" as="span">
            <Icon name="eye-off" size="xs" />
          </Flex>
        </Text>
        <Text blur size="xs" colorVariant="secondary">
          Anonymous
        </Text>
      </Flex>
    );
  }

  if (isAnonymousAccount) {
    return <Text {...generalTextProps}>Anonymous</Text>;
  }

  if (isSponsoredClient) {
    return <Text {...generalTextProps}>Sponsored Client</Text>;
  }

  if (isRoutingBroker) {
    return <Text {...generalTextProps}>Routing</Text>;
  }

  return <Text {...generalTextProps}>{name}</Text>;
};
