import { type OrderBuySellCode, CbOrderBuySell } from '@npm/data-access';

export const getOpportunityLabels = (
  orderSide: OrderBuySellCode,
  isMyOrder: boolean
) => {
  const buyOrSell = isMyOrder
    ? orderSide === CbOrderBuySell.items.sell
      ? 'Sell'
      : 'Buy'
    : orderSide === CbOrderBuySell.items.sell
    ? 'Buy'
    : 'Sell';

  const topBarTitle = isMyOrder
    ? orderSide === CbOrderBuySell.items.sell
      ? 'My Offer'
      : 'My Bid'
    : orderSide === CbOrderBuySell.items.sell
    ? "Seller's Offer Details"
    : "Buyer's Bid Details";

  return {
    topBar: {
      title: topBarTitle,
    },
    sidebar: {
      opportunitesLabel: `My ${buyOrSell} Opportunities`,
    },
    main: {
      title: `My ${buyOrSell} Opportunity`,
    },
  };
};
