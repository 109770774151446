import styled from 'styled-components';

export const LineIcon = styled.div<{ $color: string }>`
  width: 8px;
  height: 2px;
  background: ${({ $color }) => $color};
`;

export const CircleIcon = styled.div<{ $color: string }>`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  border: 2px solid ${({ $color }) => $color};
`;
