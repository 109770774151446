/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  OrderTotalsApi,
  EventOrderTotalsErrorCodes,
  EventOrderTotalsErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new OrderTotalsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type EventOrderTotalsMethod = OrderTotalsApi['eventOrderTotals'];
export type EventOrderTotalsResponseType = MethodResult<EventOrderTotalsMethod>;

export const useEventOrderTotals = (
  variables: Parameters<EventOrderTotalsMethod>[0],
  config?: UseQueryConfig<
    EventOrderTotalsMethod,
    EventOrderTotalsErrorTypes
  >
) => {
  return useQuery<
    EventOrderTotalsMethod,
    EventOrderTotalsErrorTypes
  >(
    (...args) => api.eventOrderTotals(...args),
    EventOrderTotalsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventOrderTotals'],
        ...apiQueryConfig.EventOrderTotals,
        ...config?.queryConfig
      },
    },
    'EventOrderTotals',
  );
};

export const useEventOrderTotalsInfinite = (
  variables: Parameters<EventOrderTotalsMethod>[0],
  config?: UseInfiniteQueryConfig<
    EventOrderTotalsMethod,
    EventOrderTotalsErrorTypes
  >
) => {
  return useInfiniteQuery<
    EventOrderTotalsMethod,
    EventOrderTotalsErrorTypes
  >(
    (...args) => api.eventOrderTotals(...args),
    EventOrderTotalsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventOrderTotals', 'EventOrderTotalsInfinite'],
        ...apiQueryConfig.EventOrderTotals,
        ...config?.queryConfig,
      },
    },
    'EventOrderTotals',
  );
};

export const useEventOrderTotalsLazy = (baseOptions?: {
  variables?: Parameters<EventOrderTotalsMethod>[0],
  config?: UseQueryConfig<
    EventOrderTotalsMethod,
    EventOrderTotalsErrorTypes
  >
}) => {
  return useLazyQuery<
    EventOrderTotalsMethod,
    EventOrderTotalsErrorTypes
  >(
    (...args) => api.eventOrderTotals(...args),
    EventOrderTotalsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EventOrderTotals'],
        ...apiQueryConfig.EventOrderTotals,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EventOrderTotals',
  );
};

