import styled from 'styled-components';

import {
  InputFormItem as FormItemBase,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { RadioGroup as BaseRadioGroup } from '@npm/core/ui/components/atoms/Radio';
import { useBreakpoint } from '@npm/core/ui/utils/style';

export const FlexWrap = styled.div<{ $isColumn?: boolean }>`
  margin-bottom: ${({ theme }) => theme.spacing.sm}px;

  & > * {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`;

export const SectionSpacer = styled.div<{
  $size?: 'xxl' | 'xl' | 'lg' | 'md' | 'sm';
}>`
  margin-top: ${({ theme, $size = 'xxl' }) => theme.spacing[$size]}px;
  padding-top: ${({ theme, $size = 'xxl' }) => theme.spacing[$size]}px;
`;

export const FormItem = styled(FormItemBase).attrs(
  ({ required = true, rules = [] }) => ({
    labelPosition: 'top',
    rules: required ? [VALIDATION_RULES.required(), ...rules] : rules,
  })
)`
  width: 100%;

  ${({ theme }) => useBreakpoint(theme).mobile`
      margin-bottom: ${theme.spacing.md}px;
  `}
`;

export const RadioGroup = styled(BaseRadioGroup)`
  display: flex;
  flex-direction: column;
`;
