import { Codebooks } from './codebooks.types';

const Items = {
  Complete: 'complete',
  InProgress: 'in_progress',
  Incomplete: 'incomplete',
} as const;

export const CbMatchTimelineStatus = {
  code: Codebooks.MATCH_TIMELINE_STATUS,
  items: Items,
};
