import React, { type ComponentProps } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { GetStarted as BaseGetStarted } from '@npm/core/ui/components/molecules/GetStarted';

export const EntityTaxIdGetStartedContent = () => {
  return (
    <Text colorVariant="secondary">
      Enter the tax identification number of this account.
    </Text>
  );
};

export const EntityTaxIdGetStarted = ({
  onGetStarted,
}: ComponentProps<typeof BaseGetStarted>) => {
  return (
    <BaseGetStarted
      onGetStarted={onGetStarted}
      title="Entity Tax ID"
      content={<EntityTaxIdGetStartedContent />}
    />
  );
};
