import styled from 'styled-components';

export const Container = styled.div<{ $hasSmallSpacing?: boolean }>`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: ${({ theme, $hasSmallSpacing }) =>
      $hasSmallSpacing ? theme.spacing.xs / 2 : theme.spacing.xs}px;
  }
`;
