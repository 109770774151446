const tablet = {
  mainGap: 'xxl',
  cardsGap: 'md',
  cardsColSpan: 12,
  mainCardShowUpgradeButton: false,
  mainBgImagePosition: '50px 50%',
  mainBgImageSize: 'cover',
  footerBgImageSize: 'cover',
  footerBgImagePosition: 'center',
} as const;

export const basicPageLayoutConfig = {
  desktop: {
    mainGap: 'xxxl',
    cardsGap: 'lg',
    cardsColSpan: 8,
    mainCardShowUpgradeButton: true,
    mainBgImagePosition: '400px 50%',
    mainBgImageSize: 'cover',
    footerBgImageSize: 'cover',
    footerBgImagePosition: 'center',
  },
  tablet,
  smTablet: {
    ...tablet,
    cardsGap: 'lg',
    cardsColSpan: 24,
  },
  mobile: {
    mainGap: 'lg',
    cardsGap: 'lg',
    cardsColSpan: 24,
    mainCardShowUpgradeButton: false,
    mainBgImagePosition: '60% 0',
    mainBgImageSize: '660px 230px',
    footerBgImageSize: 'cover',
    footerBgImagePosition: 'center',
  },
} as const;

export const getCompanyDataAsset = (prefix: string, suffix: string) =>
  `/public/assets/company-data/${prefix}-${suffix}.png`;
