import {
  format,
  formatDistanceToNow,
  intervalToDuration,
  parse,
  parseISO,
} from 'date-fns';
import {
  formatInTimeZone,
  getTimezoneOffset,
  utcToZonedTime,
} from 'date-fns-tz';

// Don't add anything that's not in Design System
export const DATE_FORMATS = {
  FULL: 'MMM d, yyyy h:mm a',
  DATE: 'MMM d, yyyy',
  TIME: 'h:mm a',
  YEAR: 'yyyy',
  MONTH_AND_DAY: 'MMM do',
  MONTH_AND_YEAR: 'MMM yyyy',
  QUARTER_AND_YEAR: 'QQQ, yyyy',
  DATE_NUMERIC: 'yyyy-MM-dd', // mainly for parsing
};

const isValidTimeZone = timeZone => {
  try {
    Intl.DateTimeFormat(undefined, { timeZone });
    return true;
  } catch (e) {
    return false;
  }
};

function isValidLocale(locale) {
  try {
    Intl.DateTimeFormat(locale);
    return true;
  } catch (e) {
    return false;
  }
}

// Determine the user's time zone or default to New York
const LOCALE_OPTIONS = {
  timeZone: isValidTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : 'America/New_York',
  locale: isValidLocale(Intl.DateTimeFormat().resolvedOptions().locale)
    ? Intl.DateTimeFormat().resolvedOptions().locale
    : 'en-US',
};

export const getTimezoneShort = (date?: Date) => {
  return (date ?? new Date())
    .toLocaleDateString(LOCALE_OPTIONS.locale, {
      day: '2-digit',
      timeZoneName: 'short',
    })
    .slice(4);
};

export const formatDate = (
  utcDate: string,
  options?: {
    dateFormat?: string;
    timeZone?: string;
    locale?: Locale;
    withTimeZone?: boolean;
  }
) => {
  if (!utcDate) {
    return utcDate;
  }
  let date = parseISO(utcDate);
  if (options?.timeZone) {
    date = utcToZonedTime(utcDate, options.timeZone);
  }
  const formated = formatInTimeZone(
    date,
    LOCALE_OPTIONS.timeZone,
    options?.dateFormat || DATE_FORMATS.FULL,
    {
      ...options,
    }
  );
  if (options?.withTimeZone) {
    return `${formated} (${getTimezoneShort(date)})`;
  }
  return formated;
};

export const formatStringDateToTime = (date: string) => {
  const dateISO = parseISO(date);
  return format(dateISO, DATE_FORMATS.TIME);
};

export const parseDateToString = (
  date: Date,
  dateFormat = DATE_FORMATS.DATE
): string => {
  if (dateFormat === DATE_FORMATS.DATE_NUMERIC) {
    return formatInTimeZone(date, LOCALE_OPTIONS.timeZone, dateFormat);
  }

  return format(date, dateFormat);
};

export const parseStringToDate = (date: string) =>
  parse(date, DATE_FORMATS.DATE_NUMERIC, new Date());

export const formatStringDate = (date: string) =>
  formatDate(date, { dateFormat: DATE_FORMATS.DATE });

/*
 * This function returns to the right of first non-zero duration with minimum of 3 values (e.g. 1d 2h 3m)
 * In the case of having only minute or hours left it adds padding of zeroes to the left (e.g. 0d 0h 3m)
 * (excluding seconds)
 *
 * @param withLeadingZero - adds an option to add a zero before numbers smaller than 10 (default true)
 */
export const getDurations = (
  start: Date | number,
  end: Date | number,
  withLeadingZero = true
) => {
  const duration = Object.entries(intervalToDuration({ start, end }));
  // to remove seconds from the duration
  duration.pop();
  const firstNonZeroIndex = duration.findIndex(([, value]) => value > 0);
  const filtered = duration.splice(firstNonZeroIndex).map(([key, value]) => ({
    text: key,
    time: value < 10 && withLeadingZero ? `0${value}` : value.toString(),
  }));
  if (filtered.length < 3) {
    for (let i = 0; i <= 3 - filtered.length; i++) {
      filtered.unshift({
        text: i === 0 ? 'days' : 'hours',
        time: withLeadingZero ? '00' : '0',
      });
    }
  }
  return filtered;
};

export const getLocalTimezoneOffset = () =>
  getTimezoneOffset(LOCALE_OPTIONS.timeZone) / 60 / 60 / 1000;

export const getDateInTimeAgoFormat = (date: string | Date) => {
  if (!date) return '';

  if (typeof date === 'string') {
    return formatDistanceToNow(new Date(date), { addSuffix: true });
  }
  return formatDistanceToNow(date, { addSuffix: true });
};
