import React, { type ComponentProps } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';

export const LeaveOboModal = (modalProps: ComponentProps<typeof Modal>) => {
  const handleContinue = () => {
    modalProps.onOk?.(null);
  };

  return (
    <Modal
      {...modalProps}
      onOk={handleContinue}
      title="Switch to Broker role"
      cancelText="Close"
      okText="Continue"
      width={594}
    >
      <Text colorVariant="secondary">
        You are about to leave{' '}
        <Text as="span" colorVariant="primary">
          “Act on behalf of an account”
        </Text>{' '}
        role and transition to{' '}
        <Text as="span" colorVariant="primary">
          “Broker”
        </Text>{' '}
        role
      </Text>
    </Modal>
  );
};
