import {
  type CodebookItem,
  type CodebookShow,
  CbRoleType,
} from '@npm/data-access';

interface UserAccountRoleType {
  label: string;
  value: string;
  description: string;
}

export const getCbUserAccountRoleTypeOptions = (
  items: CodebookItem[] | CodebookShow[]
): UserAccountRoleType[] => {
  return items.map(item => ({
    label: item.name,
    value: item.code,
    description: CbRoleType.getAttributeValueByName(item, 'description'),
  }));
};
