export enum FormFields {
  FIRST = 'first',
  MIDDLE = 'middle',
  LAST = 'last',
  EMAIL = 'email',
  HAS_SPOUSE = 'has_spouse',
}

export type SpousalForm = {
  [FormFields.HAS_SPOUSE]: boolean;
  [FormFields.FIRST]: string;
  [FormFields.MIDDLE]: string;
  [FormFields.LAST]: string;
  [FormFields.EMAIL]: string;
};
