import styled from 'styled-components';

import { StyledButton } from '../../../atoms/Button/Button.styles';

export const TableActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.sm}px;

  > ${StyledButton} {
    width: 100%;
  }
`;
