import { Codebooks } from './codebooks.types';

const Items = {
  account: 'account',
  administer: 'administer',
  observer: 'observer',
} as const;

export const CbUserRoleTypes = {
  code: Codebooks.USER_ROLE_TYPE,
  items: Items,
};
