import styled from 'styled-components';

export const Container = styled.div<{ $isOutlined?: boolean }>`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.size.xs}px;

  & > a {
    line-height: initial;
    display: flex;
    align-items: center;
    margin-left: ${({ theme }) => theme.spacing.xs}px;
  }
`;

export const LogoContainer = styled.div`
  position: relative;

  &:hover {
    > a {
      display: flex;
    }
  }

  > a {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    // -1 because somehow img overflows a bit on some resolutions
    border-radius: ${({ theme }) => theme.borderRadius.md - 1}px;
    background-color: ${({ theme }) =>
      theme.color.general.layout.two}E6; // 90% transparency
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;
