/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  SignerApi,
  AccountSignerIndexErrorCodes,
  AccountSignerIndexErrorTypes,
  AccountSignerCreateErrorCodes,
  AccountSignerShowErrorCodes,
  AccountSignerShowErrorTypes,
  AccountSignerUpdateErrorCodes,
  AccountSignerDeleteErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new SignerApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountSignerIndexMethod = SignerApi['accountSignerIndex'];
export type AccountSignerIndexResponseType = MethodResult<AccountSignerIndexMethod>;

export const useAccountSignerIndex = (
  variables: Parameters<AccountSignerIndexMethod>[0],
  config?: UseQueryConfig<
    AccountSignerIndexMethod,
    AccountSignerIndexErrorTypes
  >
) => {
  return useQuery<
    AccountSignerIndexMethod,
    AccountSignerIndexErrorTypes
  >(
    (...args) => api.accountSignerIndex(...args),
    AccountSignerIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountSignerIndex'],
        ...apiQueryConfig.AccountSignerIndex,
        ...config?.queryConfig
      },
    },
    'AccountSignerIndex',
  );
};

export const useAccountSignerIndexInfinite = (
  variables: Parameters<AccountSignerIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountSignerIndexMethod,
    AccountSignerIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountSignerIndexMethod,
    AccountSignerIndexErrorTypes
  >(
    (...args) => api.accountSignerIndex(...args),
    AccountSignerIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountSignerIndex', 'AccountSignerIndexInfinite'],
        ...apiQueryConfig.AccountSignerIndex,
        ...config?.queryConfig,
      },
    },
    'AccountSignerIndex',
  );
};

export const useAccountSignerIndexLazy = (baseOptions?: {
  variables?: Parameters<AccountSignerIndexMethod>[0],
  config?: UseQueryConfig<
    AccountSignerIndexMethod,
    AccountSignerIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountSignerIndexMethod,
    AccountSignerIndexErrorTypes
  >(
    (...args) => api.accountSignerIndex(...args),
    AccountSignerIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountSignerIndex'],
        ...apiQueryConfig.AccountSignerIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountSignerIndex',
  );
};


export type AccountSignerCreateMethod = SignerApi['accountSignerCreate'];

export const useAccountSignerCreate = () => {
  return useMutation<AccountSignerCreateMethod>(
    (...args) => api.accountSignerCreate(...args),
    AccountSignerCreateErrorCodes,
    'AccountSignerCreate',
    '/api/accounts/{account_id}/signers',
    'post'
  );
};

export type AccountSignerShowMethod = SignerApi['accountSignerShow'];
export type AccountSignerShowResponseType = MethodResult<AccountSignerShowMethod>;

export const useAccountSignerShow = (
  variables: Parameters<AccountSignerShowMethod>[0],
  config?: UseQueryConfig<
    AccountSignerShowMethod,
    AccountSignerShowErrorTypes
  >
) => {
  return useQuery<
    AccountSignerShowMethod,
    AccountSignerShowErrorTypes
  >(
    (...args) => api.accountSignerShow(...args),
    AccountSignerShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountSignerShow'],
        ...apiQueryConfig.AccountSignerShow,
        ...config?.queryConfig
      },
    },
    'AccountSignerShow',
  );
};

export const useAccountSignerShowInfinite = (
  variables: Parameters<AccountSignerShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountSignerShowMethod,
    AccountSignerShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountSignerShowMethod,
    AccountSignerShowErrorTypes
  >(
    (...args) => api.accountSignerShow(...args),
    AccountSignerShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountSignerShow', 'AccountSignerShowInfinite'],
        ...apiQueryConfig.AccountSignerShow,
        ...config?.queryConfig,
      },
    },
    'AccountSignerShow',
  );
};

export const useAccountSignerShowLazy = (baseOptions?: {
  variables?: Parameters<AccountSignerShowMethod>[0],
  config?: UseQueryConfig<
    AccountSignerShowMethod,
    AccountSignerShowErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountSignerShowMethod,
    AccountSignerShowErrorTypes
  >(
    (...args) => api.accountSignerShow(...args),
    AccountSignerShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountSignerShow'],
        ...apiQueryConfig.AccountSignerShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountSignerShow',
  );
};


export type AccountSignerUpdateMethod = SignerApi['accountSignerUpdate'];

export const useAccountSignerUpdate = () => {
  return useMutation<AccountSignerUpdateMethod>(
    (...args) => api.accountSignerUpdate(...args),
    AccountSignerUpdateErrorCodes,
    'AccountSignerUpdate',
    '/api/accounts/{account_id}/signers/{id}',
    'put'
  );
};

export type AccountSignerDeleteMethod = SignerApi['accountSignerDelete'];

export const useAccountSignerDelete = () => {
  return useMutation<AccountSignerDeleteMethod>(
    (...args) => api.accountSignerDelete(...args),
    AccountSignerDeleteErrorCodes,
    'AccountSignerDelete',
    '/api/accounts/{account_id}/signers/{id}',
    'delete'
  );
};
