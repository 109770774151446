import { type ForwardRefExoticComponent } from 'react';
import { Layout } from 'antd';
import { type BasicProps } from 'antd/lib/layout/layout';
import styled, { type DefaultTheme } from 'styled-components';

export const APP_HEADER_HEIGHT = 70;
export const APP_HEADER_HEIGHT_MOBILE = 48;
export const APP_HEADER_MARGIN = 0;

export const MENU_ITEM_HEIGHT = 48;
export const SUBMENU_ITEM_HEIGHT = 34;

export const Wrapper = styled.div<{
  $bg: keyof DefaultTheme['color']['general']['layout'] | null;
  $withFlex: boolean;
}>`
  width: 100%;
  min-height: 100%;
  position: relative;
  background: ${({ $bg, theme }) =>
    $bg ? theme.color.general.layout[$bg] : theme.color.general.layout.zero};
  ${({ $withFlex }) =>
    $withFlex &&
    `
    display: flex;
    flex-direction: column;
  `}
`;

// [Antd Typings] Ant'd internal types are not exported, need to re-type.
type AntdTypeLayoutInternal = ForwardRefExoticComponent<
  BasicProps & React.RefAttributes<HTMLElement>
>;

interface AntdTypeLayout extends AntdTypeLayoutInternal {
  Header: AntdTypeLayoutInternal;
  Footer: AntdTypeLayoutInternal;
  Content: AntdTypeLayoutInternal;
}

export const WrapperContent = styled<AntdTypeLayout>(Layout)<{
  $noPadding: boolean;
}>`
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: ${({ $noPadding, theme }) =>
    $noPadding === true ? 0 : theme.spacing.xs / 2}px;
  background-color: transparent;
  flex: 1 1 0;
  display: flex;
`;
