import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  min-width: 50px;
  width: 100%;
`;

export const Text = styled.span`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: clip;
`;

export const HiddenText = styled(Text)`
  position: absolute;
  visibility: hidden;
`;
