import { Flex } from '../../atoms/common';
import { Text } from '../../atoms/Typography';
import { type CompanyDescription, CompanyTag } from '../CompanyTag/CompanyTag';

import * as S from './CompanyTagList.styles';

type Props = {
  companies: CompanyDescription[];
  showMax?: number;
};

export const CompanyTagList = ({ companies, showMax = 3 }: Props) => {
  const companiesToShow = companies.slice(0, showMax);
  const remaining = companies.length - showMax;
  return (
    <Flex direction="row" $wrap gap="sm">
      {companiesToShow.map((company, index) => (
        <CompanyTag key={index} company={company} />
      ))}

      {remaining > 0 && (
        <S.Tag>
          <Text>+{remaining}</Text>
        </S.Tag>
      )}
    </Flex>
  );
};
