export enum OptInStep {
  ACCOUNT_TYPE_SELECTION = 'account-type-selection',
  SIGNATURE = 'signature',
}

export enum OptInMode {
  COPY_ACCOUNT = 'copy',
  CREATE_ACCOUNT = 'create',
}

export enum OptInEntityColorVariant {
  INDIVIDUAL = 'warning',
  INSTITUTION = 'info',
}

export enum OptInIcon {
  INDIVIDUAL = 'user',
  INSTITUTION = 'building-bank',
}
