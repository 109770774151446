import { ReadOnly } from '@npm/core/ui/components/molecules/ReadOnly';
import { type Signer } from '@npm/data-access';

import { prepareReadonlyData } from './SpousalInformationReadOnly.utils';

type Props = {
  spouse: Signer;
  onUpdate?: () => void;
};

export const SpousalInformationReadOnly = ({ spouse, onUpdate }: Props) => {
  return (
    <ReadOnly
      items={prepareReadonlyData(spouse)}
      onUpdate={onUpdate}
      updateButtonText="Update Spousal Information"
    />
  );
};
