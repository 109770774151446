import React, { useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';

import { Text } from '../Typography';

import {
  getTruncatedText,
  splitByBulletOrEllipsis,
} from './MiddleEllipsis.utils';

import * as S from './MiddleEllipsis.styles';

type Props = {
  text: string;
  secondaryText?: string;
  suffix?: React.ReactNode;
};

export const MiddleEllipsis = React.memo(function MiddleEllipsis({
  text,
  secondaryText,
  suffix,
}: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const textContainerRef = useRef<HTMLSpanElement | null>(null);
  const textInnerRef = useRef<HTMLSpanElement | null>(null);
  const [truncatedText, setTruncatedText] = useState('');
  const [width, setWidth] = useState<number | null>(null);

  // if container width changes
  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const throttledResizeObserverCallback = throttle(entries => {
      for (const entry of entries) {
        setWidth(Math.round(entry.contentRect.width));
      }
    }, 100);

    const resizeObserver = new ResizeObserver(throttledResizeObserverCallback);

    resizeObserver.observe(containerRef.current);
    return () => {
      throttledResizeObserverCallback.cancel();
      resizeObserver.disconnect();
    };
  }, []);

  // recalculate if width or text changes
  useEffect(() => {
    if (!width) {
      return;
    }

    const newText = getTruncatedText(
      text + (secondaryText ? '• ' + secondaryText : ''),
      textContainerRef.current,
      textInnerRef.current,
      width
    );

    setTruncatedText(newText);
  }, [text, secondaryText, width]);

  const [part1, part2] = splitByBulletOrEllipsis(truncatedText, secondaryText);

  // we truncate text in hidden container so there is not jumping/flickering
  return (
    <S.Container ref={containerRef}>
      <S.Text>
        {part1}
        {part2 && <Text colorVariant="tertiary">{part2}</Text>}
        {truncatedText && suffix}
      </S.Text>
      <S.HiddenText ref={textContainerRef}>
        <span ref={textInnerRef} />
        {suffix}
      </S.HiddenText>
    </S.Container>
  );
});
