import React from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { ShowMore } from '@npm/core/ui/components/molecules/ShowMore';
import { type VenusApi } from '@npm/data-access';

type Props = {
  companyProfile: VenusApi.CompanyProfile | undefined;
  collapseAfter?: number;
};

export const Executives = ({ companyProfile, collapseAfter = 3 }: Props) => {
  const base = companyProfile?.managers?.slice(0, collapseAfter) || [];
  const rest = companyProfile?.managers?.slice(collapseAfter) || [];

  if (!base.length) return <Text size="sm">No Data</Text>;

  const renderManager = (manager: VenusApi.Manager, i: number) => (
    <Text key={i} size="sm" colorVariant="primary">
      {manager.full_name} –
      {manager.title ? (
        <Text size="sm" colorVariant="tertiary" as="span">
          {' '}
          {manager.title}
        </Text>
      ) : null}
    </Text>
  );

  return (
    <>
      {base.map((manager, i) => renderManager(manager, i))}
      <ShowMore
        renderCollapsed={
          <Text as="span" size="sm" colorVariant="tertiary">
            (+{rest.length}){' '}
          </Text>
        }
        renderExpanded={rest.map((manager, i) => renderManager(manager, i))}
        collapsible={rest.length > 0}
      />
    </>
  );
};
