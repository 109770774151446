import {
  type AccountsBeneficialOwnerCreateRequestContract,
  type CodebookOrString,
  type ContactInformationUpdateRequestContract,
} from '@npm/data-access';

export type BeneficialOwnersExemptions = {
  entity_exemption: CodebookOrString;
  owner_exemption: CodebookOrString;
};

export type BeneficialOwnersIndividual = Omit<
  AccountsBeneficialOwnerCreateRequestContract,
  'include_field'
>;

export type IndividualCreateRequest = BeneficialOwnersIndividual &
  ContactInformationUpdateRequestContract;

export type FromGetStartedFuncs = {
  onGetStarted?: (action: BoaFromGetStartedAction) => void;
};

export enum BoaFromGetStartedAction {
  Complete = 'Complete',
  Individual = 'Individual',
  GovernmentIssuedId = 'GovernmentIssuedId',
}
