import React, { useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useOnClickOutside } from 'usehooks-ts';

import { rollUp } from '../../../utils/animations';
import { Icon } from '../Icon';

import * as S from './ExpandablePanel.styles';

export const EXPANDABLE_PANEL_HEADER_CLASS = 'expandable-panel-header';

type Props = {
  header: React.ReactNode;
  children: React.ReactNode;
};

export const ExpandablePanel = ({ header, children }: Props) => {
  // no children, no expansion...
  const isExpandable = !!children;
  const containerRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClickOutside = () => {
    if (isExpanded) {
      setIsExpanded(false);
    }
  };

  useOnClickOutside(containerRef, handleClickOutside);

  return (
    <S.Container ref={containerRef}>
      <S.Header
        // target header styles directly using class if necessary
        className={EXPANDABLE_PANEL_HEADER_CLASS}
        onClick={() => setIsExpanded(!isExpanded)}
        $isExpanded={isExpanded}
      >
        {isExpandable &&
          (isExpanded ? (
            <Icon name="chevron-down" size="xs" />
          ) : (
            <Icon name="chevron-up" size="xs" />
          ))}
        {header}
      </S.Header>
      {isExpandable && (
        <AnimatePresence>
          {isExpanded && (
            <motion.div key="content" {...rollUp}>
              <S.Content>
                <S.Inner>{children}</S.Inner>
              </S.Content>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </S.Container>
  );
};
