import styled from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > *:last-child {
    flex-shrink: 0;
  }
`;

export const Container = styled.div<{
  $omitStyles: boolean;
  $showRepresentative: boolean;
}>`
  ${({ $omitStyles, theme }) =>
    !$omitStyles &&
    `
    min-height: ${({ $showRepresentative }) =>
      $showRepresentative
        ? '94px' // for skeleton to not jump (if no representative)
        : 'fit-content'};
    background-color: ${theme.color.general.layout.two};
    padding: ${theme.spacing.lg / 2}px;
    border: 1px solid ${theme.color.general.borders.primary};
    border-radius: ${theme.borderRadius.md}px;
`}
`;

export const AccountSwitcher = styled(Button)`
  &&& {
    padding: 0px;
    height: fit-content;

    :active {
      box-shadow: none;
    }

    svg {
      color: ${({ theme }) => theme.color.general.typography.secondary};
    }
  }
`;
