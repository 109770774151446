/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminAuditIndex } from '../model';
/**
 * AuditApi - axios parameter creator
 * @export
 */
export const AuditApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list audit logs for a resource
         * @param {string} itemType 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [itemId] 
         * @param {string} [npmGuid] For item_type: Security
         * @param {number} [securityId] For item_type: Mark
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditIndex: async (itemType: string, page?: number, size?: number, itemId?: number, npmGuid?: string, securityId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemType' is not null or undefined
            assertParamExists('auditIndex', 'itemType', itemType)
            const localVarPath = changeUrl(`/api/admin/audits`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (itemType !== undefined) {
                localVarQueryParameter['item_type'] = itemType;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (npmGuid !== undefined) {
                localVarQueryParameter['npm_guid'] = npmGuid;
            }

            if (securityId !== undefined) {
                localVarQueryParameter['security_id'] = securityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditApi - functional programming interface
 * @export
 */
export const AuditApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary list audit logs for a resource
         * @param {string} itemType 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [itemId] 
         * @param {string} [npmGuid] For item_type: Security
         * @param {number} [securityId] For item_type: Mark
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditIndex(itemType: string, page?: number, size?: number, itemId?: number, npmGuid?: string, securityId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAuditIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditIndex(itemType, page, size, itemId, npmGuid, securityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditApi - factory interface
 * @export
 */
export const AuditApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditApiFp(configuration)
    return {
        /**
         * 
         * @summary list audit logs for a resource
         * @param {string} itemType 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [itemId] 
         * @param {string} [npmGuid] For item_type: Security
         * @param {number} [securityId] For item_type: Mark
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditIndex(itemType: string, page?: number, size?: number, itemId?: number, npmGuid?: string, securityId?: number, options?: any): AxiosPromise<AdminAuditIndex> {
            return localVarFp.auditIndex(itemType, page, size, itemId, npmGuid, securityId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for auditIndex operation in AuditApi.
 * @export
 * @interface AuditApiAuditIndexRequest
 */
export interface AuditApiAuditIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof AuditApiAuditIndex
     */
    readonly itemType: string

    /**
     * 
     * @type {number}
     * @memberof AuditApiAuditIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AuditApiAuditIndex
     */
    readonly size?: number

    /**
     * 
     * @type {number}
     * @memberof AuditApiAuditIndex
     */
    readonly itemId?: number

    /**
     * For item_type: Security
     * @type {string}
     * @memberof AuditApiAuditIndex
     */
    readonly npmGuid?: string

    /**
     * For item_type: Mark
     * @type {number}
     * @memberof AuditApiAuditIndex
     */
    readonly securityId?: number
}

/**
 * AuditApi - object-oriented interface
 * @export
 * @class AuditApi
 * @extends {BaseAPI}
 */
export class AuditApi extends BaseAPI {
    /**
     * 
     * @summary list audit logs for a resource
     * @param {AuditApiAuditIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    public auditIndex(requestParameters: AuditApiAuditIndexRequest, options?: AxiosRequestConfig) {
        return AuditApiFp(this.configuration).auditIndex(requestParameters.itemType, requestParameters.page, requestParameters.size, requestParameters.itemId, requestParameters.npmGuid, requestParameters.securityId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AuditIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AuditIndexErrorCodes = [
];


