import { Icon } from '../../../../atoms/Icon';

import * as S from './ScrollIndicator.styles';

type Props = {
  scrollLeft: number;
  maxScrollDistance: number;
  onClick: () => void;
};

export const ScrollIndicator = ({
  scrollLeft,
  maxScrollDistance,
  onClick,
}: Props) => {
  const getOpacity = () => {
    const calculatedOpacity = 1 - scrollLeft / maxScrollDistance;
    return calculatedOpacity < 0 ? 0 : calculatedOpacity;
  };

  return (
    <S.Container as="button" onClick={onClick} opacity={getOpacity()}>
      <Icon name="chevron-right" size="xs" />
    </S.Container>
  );
};
