/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountsBuyerSurveyCreateRequestContract } from '../model';
// @ts-ignore
import { AccountsBuyerSurveyUpdateRequestContract } from '../model';
// @ts-ignore
import { BuyerSurvey } from '../model';
// @ts-ignore
import { DestroySuccess } from '../model';
// @ts-ignore
import { ErrorValidation } from '../model';
/**
 * BuyerSurveyApi - axios parameter creator
 * @export
 */
export const BuyerSurveyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create buyer survey (or update existing)
         * @param {number} accountId 
         * @param {AccountsBuyerSurveyCreateRequestContract} accountsBuyerSurveyCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerSurveyCreate: async (accountId: number, accountsBuyerSurveyCreateRequestContract: AccountsBuyerSurveyCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('buyerSurveyCreate', 'accountId', accountId)
            // verify required parameter 'accountsBuyerSurveyCreateRequestContract' is not null or undefined
            assertParamExists('buyerSurveyCreate', 'accountsBuyerSurveyCreateRequestContract', accountsBuyerSurveyCreateRequestContract)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/buyer-surveys`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountsBuyerSurveyCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete specified buyer survey
         * @param {number} accountId 
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerSurveyDestroy: async (accountId: number, id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('buyerSurveyDestroy', 'accountId', accountId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('buyerSurveyDestroy', 'id', id)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/buyer-surveys/{id}`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary show account buyer survey or blank object
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerSurveyList: async (accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('buyerSurveyList', 'accountId', accountId)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/buyer-surveys`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary show specified buyer survey
         * @param {number} accountId 
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerSurveyShow: async (accountId: number, id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('buyerSurveyShow', 'accountId', accountId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('buyerSurveyShow', 'id', id)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/buyer-surveys/{id}`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates specified buyer survey
         * @param {number} accountId 
         * @param {number} id 
         * @param {AccountsBuyerSurveyUpdateRequestContract} accountsBuyerSurveyUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerSurveyUpdate: async (accountId: number, id: number, accountsBuyerSurveyUpdateRequestContract: AccountsBuyerSurveyUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('buyerSurveyUpdate', 'accountId', accountId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('buyerSurveyUpdate', 'id', id)
            // verify required parameter 'accountsBuyerSurveyUpdateRequestContract' is not null or undefined
            assertParamExists('buyerSurveyUpdate', 'accountsBuyerSurveyUpdateRequestContract', accountsBuyerSurveyUpdateRequestContract)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/buyer-surveys/{id}`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountsBuyerSurveyUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuyerSurveyApi - functional programming interface
 * @export
 */
export const BuyerSurveyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuyerSurveyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create buyer survey (or update existing)
         * @param {number} accountId 
         * @param {AccountsBuyerSurveyCreateRequestContract} accountsBuyerSurveyCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerSurveyCreate(accountId: number, accountsBuyerSurveyCreateRequestContract: AccountsBuyerSurveyCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerSurveyCreate(accountId, accountsBuyerSurveyCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete specified buyer survey
         * @param {number} accountId 
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerSurveyDestroy(accountId: number, id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroySuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerSurveyDestroy(accountId, id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary show account buyer survey or blank object
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerSurveyList(accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerSurveyList(accountId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary show specified buyer survey
         * @param {number} accountId 
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerSurveyShow(accountId: number, id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerSurveyShow(accountId, id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates specified buyer survey
         * @param {number} accountId 
         * @param {number} id 
         * @param {AccountsBuyerSurveyUpdateRequestContract} accountsBuyerSurveyUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerSurveyUpdate(accountId: number, id: number, accountsBuyerSurveyUpdateRequestContract: AccountsBuyerSurveyUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerSurveyUpdate(accountId, id, accountsBuyerSurveyUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuyerSurveyApi - factory interface
 * @export
 */
export const BuyerSurveyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuyerSurveyApiFp(configuration)
    return {
        /**
         * 
         * @summary create buyer survey (or update existing)
         * @param {number} accountId 
         * @param {AccountsBuyerSurveyCreateRequestContract} accountsBuyerSurveyCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerSurveyCreate(accountId: number, accountsBuyerSurveyCreateRequestContract: AccountsBuyerSurveyCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<BuyerSurvey> {
            return localVarFp.buyerSurveyCreate(accountId, accountsBuyerSurveyCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete specified buyer survey
         * @param {number} accountId 
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerSurveyDestroy(accountId: number, id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<DestroySuccess> {
            return localVarFp.buyerSurveyDestroy(accountId, id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary show account buyer survey or blank object
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerSurveyList(accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<BuyerSurvey> {
            return localVarFp.buyerSurveyList(accountId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary show specified buyer survey
         * @param {number} accountId 
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerSurveyShow(accountId: number, id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<BuyerSurvey> {
            return localVarFp.buyerSurveyShow(accountId, id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates specified buyer survey
         * @param {number} accountId 
         * @param {number} id 
         * @param {AccountsBuyerSurveyUpdateRequestContract} accountsBuyerSurveyUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerSurveyUpdate(accountId: number, id: number, accountsBuyerSurveyUpdateRequestContract: AccountsBuyerSurveyUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<BuyerSurvey> {
            return localVarFp.buyerSurveyUpdate(accountId, id, accountsBuyerSurveyUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for buyerSurveyCreate operation in BuyerSurveyApi.
 * @export
 * @interface BuyerSurveyApiBuyerSurveyCreateRequest
 */
export interface BuyerSurveyApiBuyerSurveyCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof BuyerSurveyApiBuyerSurveyCreate
     */
    readonly accountId: number

    /**
     * 
     * @type {AccountsBuyerSurveyCreateRequestContract}
     * @memberof BuyerSurveyApiBuyerSurveyCreate
     */
    readonly accountsBuyerSurveyCreateRequestContract: AccountsBuyerSurveyCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for buyerSurveyDestroy operation in BuyerSurveyApi.
 * @export
 * @interface BuyerSurveyApiBuyerSurveyDestroyRequest
 */
export interface BuyerSurveyApiBuyerSurveyDestroyRequest {
    /**
     * 
     * @type {number}
     * @memberof BuyerSurveyApiBuyerSurveyDestroy
     */
    readonly accountId: number

    /**
     * 
     * @type {number}
     * @memberof BuyerSurveyApiBuyerSurveyDestroy
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyDestroy
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyDestroy
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyDestroy
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyDestroy
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyDestroy
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyDestroy
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for buyerSurveyList operation in BuyerSurveyApi.
 * @export
 * @interface BuyerSurveyApiBuyerSurveyListRequest
 */
export interface BuyerSurveyApiBuyerSurveyListRequest {
    /**
     * 
     * @type {number}
     * @memberof BuyerSurveyApiBuyerSurveyList
     */
    readonly accountId: number

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyList
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyList
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyList
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyList
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyList
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyList
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for buyerSurveyShow operation in BuyerSurveyApi.
 * @export
 * @interface BuyerSurveyApiBuyerSurveyShowRequest
 */
export interface BuyerSurveyApiBuyerSurveyShowRequest {
    /**
     * 
     * @type {number}
     * @memberof BuyerSurveyApiBuyerSurveyShow
     */
    readonly accountId: number

    /**
     * 
     * @type {number}
     * @memberof BuyerSurveyApiBuyerSurveyShow
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for buyerSurveyUpdate operation in BuyerSurveyApi.
 * @export
 * @interface BuyerSurveyApiBuyerSurveyUpdateRequest
 */
export interface BuyerSurveyApiBuyerSurveyUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof BuyerSurveyApiBuyerSurveyUpdate
     */
    readonly accountId: number

    /**
     * 
     * @type {number}
     * @memberof BuyerSurveyApiBuyerSurveyUpdate
     */
    readonly id: number

    /**
     * 
     * @type {AccountsBuyerSurveyUpdateRequestContract}
     * @memberof BuyerSurveyApiBuyerSurveyUpdate
     */
    readonly accountsBuyerSurveyUpdateRequestContract: AccountsBuyerSurveyUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BuyerSurveyApiBuyerSurveyUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * BuyerSurveyApi - object-oriented interface
 * @export
 * @class BuyerSurveyApi
 * @extends {BaseAPI}
 */
export class BuyerSurveyApi extends BaseAPI {
    /**
     * 
     * @summary create buyer survey (or update existing)
     * @param {BuyerSurveyApiBuyerSurveyCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerSurveyApi
     */
    public buyerSurveyCreate(requestParameters: BuyerSurveyApiBuyerSurveyCreateRequest, options?: AxiosRequestConfig) {
        return BuyerSurveyApiFp(this.configuration).buyerSurveyCreate(requestParameters.accountId, requestParameters.accountsBuyerSurveyCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete specified buyer survey
     * @param {BuyerSurveyApiBuyerSurveyDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerSurveyApi
     */
    public buyerSurveyDestroy(requestParameters: BuyerSurveyApiBuyerSurveyDestroyRequest, options?: AxiosRequestConfig) {
        return BuyerSurveyApiFp(this.configuration).buyerSurveyDestroy(requestParameters.accountId, requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary show account buyer survey or blank object
     * @param {BuyerSurveyApiBuyerSurveyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerSurveyApi
     */
    public buyerSurveyList(requestParameters: BuyerSurveyApiBuyerSurveyListRequest, options?: AxiosRequestConfig) {
        return BuyerSurveyApiFp(this.configuration).buyerSurveyList(requestParameters.accountId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary show specified buyer survey
     * @param {BuyerSurveyApiBuyerSurveyShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerSurveyApi
     */
    public buyerSurveyShow(requestParameters: BuyerSurveyApiBuyerSurveyShowRequest, options?: AxiosRequestConfig) {
        return BuyerSurveyApiFp(this.configuration).buyerSurveyShow(requestParameters.accountId, requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates specified buyer survey
     * @param {BuyerSurveyApiBuyerSurveyUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerSurveyApi
     */
    public buyerSurveyUpdate(requestParameters: BuyerSurveyApiBuyerSurveyUpdateRequest, options?: AxiosRequestConfig) {
        return BuyerSurveyApiFp(this.configuration).buyerSurveyUpdate(requestParameters.accountId, requestParameters.id, requestParameters.accountsBuyerSurveyUpdateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type BuyerSurveyCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BuyerSurveyCreateErrorCodes = [
    401,
    404,
];

export type BuyerSurveyDestroyErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BuyerSurveyDestroyErrorCodes = [
    401,
    404,
];

export type BuyerSurveyListErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BuyerSurveyListErrorCodes = [
    401,
    404,
];

export type BuyerSurveyShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BuyerSurveyShowErrorCodes = [
    401,
    404,
];

export type BuyerSurveyUpdateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BuyerSurveyUpdateErrorCodes = [
    401,
    404,
    422,
];


