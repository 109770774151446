import { memo } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { formatPrice, formatStringDate } from '@npm/core/ui/utils/formatters';

import * as S from './Valuations409AChart.styles';

type Props = {
  date?: string;
  pricePerShare?: number;
};

export const ValuationsChartTooltip = memo(function ValuationsChartTooltip({
  date,
  pricePerShare,
}: Props) {
  if (!date) return null;

  return (
    <S.TooltipCard>
      <S.TooltipHeader>
        <S.TooltipLabel>409A Valuation</S.TooltipLabel>
        <Text size="xxs" as="span" align="end">
          Filing Date
          <br />
          {formatStringDate(date)}
        </Text>
      </S.TooltipHeader>
      <S.TooltipValueDisplay>
        <Text size="sm">Price Per Share</Text>
        <Text colorVariant="primary">${formatPrice(pricePerShare)}</Text>
      </S.TooltipValueDisplay>
    </S.TooltipCard>
  );
});
