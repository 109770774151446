import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { CbBeneficialOwnerExemption } from '@npm/data-access';

import { InfoTooltip } from '../InfoTooltip';

import { ExemptionQuestion } from './ExemptionQuestion';

type Props = {
  onChange?: (value: string) => void;
  accountName: string;
};

export const OwnerExemptionQuestion = ({ onChange, accountName }: Props) => {
  return (
    <ExemptionQuestion
      codebookCode={CbBeneficialOwnerExemption.code}
      onChange={onChange}
      heading={`Is ${accountName} exempt from the beneficial ownership requirement under one of the categories listed below?`}
      selectLabel={
        <Flex gap="sm" align="center">
          Select applicable category
          <InfoTooltip
            title={`If ${accountName} is one of the categories listed, it is exempt from providing beneficial ownership information pursuant to 31 C.F.R. 1010.230(e)(2), but it is required to provide control prong information`}
          />
        </Flex>
      }
      checkboxLabel={`None of the above is applicable to ${accountName}`}
    />
  );
};
