import styled from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;

  > * {
    flex: 1;
    width: 100%;
  }
`;
