import { useDarkModeContext } from '../../../../context/DarkModeContext';
import { useBreakpoints } from '../../../../hooks/useBreakpoints';

const LOGO_DARK = '/public/assets/logo-short-dark.svg';
const LOGO_LIGHT = '/public/assets/logo-short-light.svg';

export const Logo = () => {
  const { isDarkMode } = useDarkModeContext();
  const { isMobile } = useBreakpoints();

  return (
    <img
      src={isDarkMode ? LOGO_DARK : LOGO_LIGHT}
      alt="NPM logo"
      height={isMobile ? 38 : 46}
      width={isMobile ? 100 : 124}
    />
  );
};
