import { unescape } from 'lodash';

export const getAddressFields = (
  address: string,
  country: string,
  state: string
) => {
  const fieldsToParse = ['street-address', 'locality', 'postal-code'];

  // converts html entities (like &amp;) to their actual characters
  const decodedAddress = unescape(address);

  const [address_line_1, city, zip] = fieldsToParse.map(field => {
    const searchedString = field + '">';
    const searchedStringIndex = decodedAddress.indexOf(searchedString);

    if (searchedStringIndex === -1) {
      return '';
    }

    const leftSide = decodedAddress.substring(
      searchedStringIndex + searchedString.length
    );

    return leftSide.substring(0, leftSide.indexOf('<'));
  });

  return {
    address_line_1,
    city,
    state,
    zip,
    country,
  };
};
