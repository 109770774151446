/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * EventReportApi - axios parameter creator
 * @export
 */
export const EventReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary generates the specified report by type
         * @param {number} eventId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventReportShow: async (eventId: number, type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('eventReportShow', 'eventId', eventId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('eventReportShow', 'type', type)
            const localVarPath = changeUrl(`/api/events/{event_id}/report`)
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventReportApi - functional programming interface
 * @export
 */
export const EventReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary generates the specified report by type
         * @param {number} eventId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventReportShow(eventId: number, type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventReportShow(eventId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventReportApi - factory interface
 * @export
 */
export const EventReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventReportApiFp(configuration)
    return {
        /**
         * 
         * @summary generates the specified report by type
         * @param {number} eventId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventReportShow(eventId: number, type: string, options?: any): AxiosPromise<File> {
            return localVarFp.eventReportShow(eventId, type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for eventReportShow operation in EventReportApi.
 * @export
 * @interface EventReportApiEventReportShowRequest
 */
export interface EventReportApiEventReportShowRequest {
    /**
     * 
     * @type {number}
     * @memberof EventReportApiEventReportShow
     */
    readonly eventId: number

    /**
     * 
     * @type {string}
     * @memberof EventReportApiEventReportShow
     */
    readonly type: string
}

/**
 * EventReportApi - object-oriented interface
 * @export
 * @class EventReportApi
 * @extends {BaseAPI}
 */
export class EventReportApi extends BaseAPI {
    /**
     * 
     * @summary generates the specified report by type
     * @param {EventReportApiEventReportShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventReportApi
     */
    public eventReportShow(requestParameters: EventReportApiEventReportShowRequest, options?: AxiosRequestConfig) {
        return EventReportApiFp(this.configuration).eventReportShow(requestParameters.eventId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }
}

export type EventReportShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 442;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EventReportShowErrorCodes = [
    401,
    442,
];


