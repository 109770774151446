/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  IssuerEntitySectorApi,
  IssuerEntitySectorIndexErrorCodes,
  IssuerEntitySectorIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new IssuerEntitySectorApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type IssuerEntitySectorIndexMethod = IssuerEntitySectorApi['issuerEntitySectorIndex'];
export type IssuerEntitySectorIndexResponseType = MethodResult<IssuerEntitySectorIndexMethod>;

export const useIssuerEntitySectorIndex = (
  variables: Parameters<IssuerEntitySectorIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerEntitySectorIndexMethod,
    IssuerEntitySectorIndexErrorTypes
  >
) => {
  return useQuery<
    IssuerEntitySectorIndexMethod,
    IssuerEntitySectorIndexErrorTypes
  >(
    (...args) => api.issuerEntitySectorIndex(...args),
    IssuerEntitySectorIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntitySectorIndex'],
        ...apiQueryConfig.IssuerEntitySectorIndex,
        ...config?.queryConfig
      },
    },
    'IssuerEntitySectorIndex',
  );
};

export const useIssuerEntitySectorIndexInfinite = (
  variables: Parameters<IssuerEntitySectorIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    IssuerEntitySectorIndexMethod,
    IssuerEntitySectorIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    IssuerEntitySectorIndexMethod,
    IssuerEntitySectorIndexErrorTypes
  >(
    (...args) => api.issuerEntitySectorIndex(...args),
    IssuerEntitySectorIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntitySectorIndex', 'IssuerEntitySectorIndexInfinite'],
        ...apiQueryConfig.IssuerEntitySectorIndex,
        ...config?.queryConfig,
      },
    },
    'IssuerEntitySectorIndex',
  );
};

export const useIssuerEntitySectorIndexLazy = (baseOptions?: {
  variables?: Parameters<IssuerEntitySectorIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerEntitySectorIndexMethod,
    IssuerEntitySectorIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    IssuerEntitySectorIndexMethod,
    IssuerEntitySectorIndexErrorTypes
  >(
    (...args) => api.issuerEntitySectorIndex(...args),
    IssuerEntitySectorIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IssuerEntitySectorIndex'],
        ...apiQueryConfig.IssuerEntitySectorIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IssuerEntitySectorIndex',
  );
};

