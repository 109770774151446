import styled from 'styled-components';

import { centerFlex } from '../../../../../utils/style';

export type ActionItemsCountSize = 'sm' | 'md' | 'lg';

const SIZE_MAP: Record<ActionItemsCountSize, number> = {
  sm: 20,
  md: 24,
  lg: 28,
};

export const CountWrapper = styled.div<{ $size: ActionItemsCountSize }>`
  ${centerFlex};
  background: ${({ theme }) => theme.color.info.typography.primary};
  min-width: ${({ $size }) => SIZE_MAP[$size]}px;
  height: ${({ $size }) => SIZE_MAP[$size]}px;
  padding: 0 ${({ theme }) => theme.spacing.xs}px;
  border: 2px solid ${({ theme }) => theme.color.general.layout.zero};
  border-radius: ${({ theme }) => theme.borderRadius.lg * 2}px;
`;
