import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  CbDocumentType,
  CbOwnerType,
  useDocumentCreate,
} from '@npm/data-access';

import { type OnboardingAgreementType } from '../../OnboardingAgreements.types';

import { type OnboardingAgreementUploadFormType } from './OnboardingAgreementUploadForm';

export const useOnboardingAgreementUploadForm = (
  type: OnboardingAgreementType,
  accountId: number,
  { onUpdate }: { onUpdate?: () => void }
) => {
  const [form] = Form.useForm<OnboardingAgreementUploadFormType>();
  const { withShowApiErrorMiddleware } = useAlerts();

  const { execute, isLoading } = useDocumentCreate();

  const handleSubmit = async () => {
    let values: OnboardingAgreementUploadFormType;

    try {
      values = await form.validateFields();
    } catch (e) {
      console.error(e);
      return;
    }

    try {
      await withShowApiErrorMiddleware(execute)({
        file: values.file,
        displayName: values.file.name,
        category:
          type === 'marketplace_agreement'
            ? CbDocumentType.items.signed_marketplace_agreement_document
            : CbDocumentType.items.signed_paying_agent_service_document,
        ownerType: CbOwnerType.items.Account,
        ownerId: accountId,
      });

      form.resetFields();
      onUpdate?.();
    } catch (e) {
      console.error(e);
    }
  };

  return {
    form,
    handleSubmit,
    isLoading,
  };
};
