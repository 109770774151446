import { Select } from '@npm/core/ui/components/atoms/Select';
import {
  type IssuerEntityApiIssuerEntityFiltersIndexRequest,
  type IssuerEntityApiIssuerEntityIndexRequest,
  useIssuerEntityFiltersIndexInfinite,
  useIssuerEntityIndexInfinite,
} from '@npm/data-access';

export const useIssuerEntities = ({
  variables,
  resourceType,
  searchTerm,
}: {
  variables?: IssuerEntityApiIssuerEntityIndexRequest;
  resourceType?: IssuerEntityApiIssuerEntityFiltersIndexRequest['resourceType'];
  searchTerm?: string;
}) => {
  const generalQuery = useIssuerEntityIndexInfinite(
    {
      ...(searchTerm ? { ...variables, search: searchTerm } : variables),
    },
    { onError: Select.onError, queryConfig: { enabled: !resourceType } }
  );

  // Possibly in the future we will only need this query
  const resourceQuery = useIssuerEntityFiltersIndexInfinite(
    {
      ...(searchTerm ? { ...variables, search: searchTerm } : variables),
      resourceType,
    },
    { onError: Select.onError, queryConfig: { enabled: !!resourceType } }
  );

  return resourceType ? resourceQuery : generalQuery;
};
