import React, { type ComponentProps } from 'react';

import { ButtonCardQuestion } from '@npm/core/ui/components/molecules/ButtonCardQuestion';
import { MultiSelectQuestion } from '@npm/core/ui/components/molecules/MultiSelectQuestion';
import { type AutoScrollingForm } from '@npm/core/ui/components/organisms/AutoScrollingForm';
import {
  type BuyerSurveyCodebookItem,
  type BuyerSurveyQuestionCodebookItem,
  type Sector,
  CbBuyerSurveyQuestion,
} from '@npm/data-access';

export const prepareFormConfig = (
  questions: BuyerSurveyQuestionCodebookItem[],
  allAnswers: BuyerSurveyCodebookItem[],
  sectors: Sector[],
  sectorsLoading?: boolean
): Pick<ComponentProps<typeof AutoScrollingForm>, 'questions'>['questions'] => {
  return questions.map(({ code, name }, idx, arr) => {
    const answers =
      code === 'sectors'
        ? sectors
        : CbBuyerSurveyQuestion.getAnswersByQuestion(code, allAnswers);
    const questionType = CbBuyerSurveyQuestion.questionTypes[code];

    if (questionType === 'multiselect') {
      return {
        title: name,
        name: code,
        children: ({ onQuestionAnswered, isDisabled }) => (
          <MultiSelectQuestion
            name={code}
            options={answers?.map((a: Sector) => ({
              label: a.name,
              value: a.name,
            }))}
            rules={CbBuyerSurveyQuestion.getValidationRules(code)}
            isLoading={sectorsLoading}
            subtitle="Your desired sectors"
            isQuestionLast={idx + 1 === arr.length}
            {...{ onQuestionAnswered, isDisabled }}
          />
        ),
      };
    }

    return {
      title: name,
      name: code,
      children: ({ form, onQuestionAnswered, isDisabled }) => {
        return (
          <ButtonCardQuestion
            columns={3}
            rules={CbBuyerSurveyQuestion.getValidationRules(code)}
            name={code}
            options={answers.map((a: BuyerSurveyCodebookItem) => {
              return {
                name: a.code,
                label: a.name,
                tooltip: {
                  text: CbBuyerSurveyQuestion.getHelpAttributeValue(a),
                },
              };
            })}
            variant={questionType}
            isQuestionLast={idx + 1 === arr.length}
            {...{ form, onQuestionAnswered, isDisabled }}
          />
        );
      },
    };
  });
};
