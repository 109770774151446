import React from 'react';

import { Button } from '../../atoms/Button';
import { Icon } from '../../atoms/Icon';
import { Heading } from '../../atoms/Typography';

import * as S from './GetStarted.styles';

type Props = {
  onGetStarted?: () => void;
  title?: string;
  content?: React.ReactNode;
  withBullets?: boolean;
  testId?: string;
  wide?: boolean;
  buttonText?: string;
  buttonIcon?: React.ComponentProps<typeof Icon>['name'];
  buttonLoading?: boolean;
};

export const GetStarted = ({
  onGetStarted,
  title,
  content,
  withBullets = false,
  testId = 'get-started-button',
  wide = false,
  buttonText,
  buttonIcon,
  buttonLoading,
}: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    onGetStarted?.();
  };

  return (
    <S.Wrap>
      <S.IconWrap>
        <Icon name="info-circle" />
      </S.IconWrap>
      <Heading variant="h2" marginBottom="md">
        {title}
      </Heading>
      <S.Paragraphs $wide={wide} $withBullets={withBullets}>
        {content}
      </S.Paragraphs>
      {!!onGetStarted && (
        <Button
          onClick={handleClick}
          variant="outline"
          loading={buttonLoading}
          icon={buttonIcon ? <Icon name={buttonIcon} /> : undefined}
          blockOnMobile
          data-cy={testId}
        >
          {buttonText || 'Get Started'}
        </Button>
      )}
    </S.Wrap>
  );
};
