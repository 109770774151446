import React from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';

export const Alerts = () => {
  return (
    <Flex direction="column" gap="sm">
      <Alert
        message=" To help the government fight the funding of terrorism and money
          laundering activities, federal law requires all financial institutions
          to obtain, verify, and record information that identifies each
          customer."
        description="FinCEN’s Customer Due Diligence Rule requires us to identify and
          verify the identities of the beneficial owners of certain types of
          legal entities. Beneficial ownership is determined under two prongs:
          (i) ownership and (ii) control."
      />
      <Alert
        message="Depending on your legal entity type you may be required to identify any of the following:"
        description={
          <ul>
            <Text size="sm" as="li" marginBottom="sm">
              Ownership Prong – each individual (natural person), if any, who,
              directly or indirectly owns 25% or more of the equity interests of
              this legal entity.
            </Text>
            <Text size="sm" as="li">
              Control Prong – one individual (natural person) with significant
              responsibility to control or manage this legal entity (e.g., Chief
              Executive Officer, President, Chief Financial Officer, Chief
              Operating Officer, Managing Member, General Partner).
            </Text>
          </ul>
        }
      />
    </Flex>
  );
};
