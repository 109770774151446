import { CbAccountType, CbRoleType } from '@npm/data-access';
import {
  type Account,
  type BrokerageFirmsAccountCreateRequestContract,
  useBrokerageFirmAccountCreate,
  useUserRoleCreate,
} from '@npm/data-access';

export type AccountCreate = BrokerageFirmsAccountCreateRequestContract & {
  anonymous: boolean;
};

export const useCreateOrganizationAccount = (
  brokerageFirmId: number,
  createUserRole = true
) => {
  const { execute: accountExecute, isLoading: accountIsLoading } =
    useBrokerageFirmAccountCreate();
  const { execute: userRoleExecute, isLoading: userRoleLoading } =
    useUserRoleCreate();

  const execute = async (values: AccountCreate) => {
    const account: Account = await accountExecute({
      brokerageFirmId,
      brokerageFirmsAccountCreateRequestContract: {
        ...values,
        account_type: values.anonymous
          ? CbAccountType.items.Anonymous
          : CbAccountType.items.OrganizationAccount,
      },
    });

    if (createUserRole) {
      await userRoleExecute({
        userRoleCreateRequestContract: {
          account_id: account?.id,
          role_type:
            values.user_account_role_type || CbRoleType.items.ADMIN_AND_SIGNER,
          new_user: {
            first: values.first,
            middle: values.middle,
            last: values.last,
            email: values.email,
            skip_okta: values.skip_okta,
          },
        },
      });
    }

    return account;
  };

  return { execute, isLoading: accountIsLoading || userRoleLoading };
};
