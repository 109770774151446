import { type Breakpoint } from 'antd/es/_util/responsiveObserve';

type Breakpoints =
  | 'NONE'
  | 'MOBILE'
  | 'TABLET_SM'
  | 'TABLET'
  | 'DESKTOP_SM'
  | 'DESKTOP'
  | 'DESKTOP_XL';

export const TABLE_BREAKPOINTS: Record<Breakpoints, Breakpoint[]> = {
  // never expand
  NONE: [],
  // 480px;
  MOBILE: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
  // 576px;
  TABLET_SM: ['sm', 'md', 'lg', 'xl', 'xxl'],
  // 768px;
  TABLET: ['md', 'lg', 'xl', 'xxl'],
  // 992px;
  DESKTOP_SM: ['lg', 'xl', 'xxl'],
  // 1200px;
  DESKTOP: ['xl', 'xxl'],
  // 1600px;
  DESKTOP_XL: ['xxl'],
};

// 128px + 8px (left padding) + 16px (right padding)
export const TABLE_ACTIONS_COLUMN_WIDTH = '152px';

// 40px + 4px (left padding) + 8px (right padding)
export const TABLE_ACTIONS_COLUMN_WIDTH_SMALL = '52px';

// 40px + 40px + 8px (gap) + 4px (left padding) + 8px (right padding)
export const TABLE_ACTIONS_COLUMN_WIDTH_MEDIUM = '100px';
