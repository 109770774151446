import styled from 'styled-components';

import { Text } from '@npm/core/ui/components/atoms/Typography';

import { TooltipStyles } from '@npm/core/ui/components/atoms/Charts/styles/Tooltip.styles';

export const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;
`;

export const Body = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacing.md}px;
  padding-top: ${({ theme }) => theme.spacing.xs}px;
  height: 400px;
`;

export const ChartContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  ${TooltipStyles}
`;

export const TooltipCard = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.sm}px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.sm}px;
  flex-shrink: 0;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  background: ${({ theme }) => theme.color.general.layout.two};
  box-shadow: ${({ theme }) => theme.shadow.level3};
`;

export const TooltipHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.sm}px;
  gap: ${({ theme }) => theme.spacing.xs}px;
  width: 100%;
`;

export const TooltipLabel = styled(Text).attrs({
  weight: 'bold',
})`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TooltipRow = styled.div<{ $gap?: 'sm' | 'md' }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme, $gap = 'sm' }) => theme.spacing[$gap]}px;
  width: 100%;
`;

export const TooltipCol = styled.div`
  flex-grow: 1;
  flex-basis: 50px;
  width: 50%;
`;

export const TooltipValueDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing.sm}px;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  background: ${({ theme }) => theme.color.general.layout.one};
`;

export const Legend = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: ${({ theme }) => theme.spacing.md}px;
  left: ${({ theme }) => theme.spacing.lg * 2}px;
  padding: 0 ${({ theme }) => theme.spacing.xs}px;
  background: ${({ theme }) => theme.color.info.backgrounds.secondaryDefault};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  gap: ${({ theme }) => theme.spacing.xs}px;
`;

export const LegendSquare = styled.div`
  width: 8px;
  height: 8px;
  background: ${({ theme }) => theme.color.info.backgrounds.primaryDefault};
`;
