export const paginationItemRender = (current, type, originalElement) => {
  if (type === 'page') {
    return (
      <span data-dd-action-name={`Page ${current} Button`}>
        {originalElement}
      </span>
    );
  }

  if (type === 'next') {
    return (
      <span data-dd-action-name={`Next Page Button`}>{originalElement}</span>
    );
  }

  if (type === 'prev') {
    return (
      <span data-dd-action-name={`Previous Page Button`}>
        {originalElement}
      </span>
    );
  }

  return originalElement;
};
