import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { truncate } from '@npm/core/ui/utils/formatters';
import { type Holding } from '@npm/data-access';

import { type HoldingSelect } from './HoldingSelect';

type Props = {
  holding: Holding;
  labelBasePropName: React.ComponentProps<
    typeof HoldingSelect
  >['labelBasePropName'];
};

export const SelectedHoldingOption = ({
  holding,
  labelBasePropName,
}: Props) => {
  return (
    <Flex align="center">
      <Margin right="sm">
        {holding.asset?.type[labelBasePropName]}
        {holding.underlying_holding_type
          ? ` - ${holding.underlying_holding_type.name}`
          : ''}
      </Margin>
      <Label variant="info" round>
        {truncate(holding.certificate_number ?? 'N/A', 20)}
      </Label>
    </Flex>
  );
};
