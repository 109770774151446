import { useEffect } from 'react';
import { type FormInstance } from 'antd';

/**
 *
 * Simple utility to sync query params (filters) with form values.
 * @TODO: Remove this later in favour of more elegant solution.
 * https://nasdaq-private-market.atlassian.net/browse/NFE-4617
 */
export const useFilterFormSync = <T extends object>(
  values: T,
  form: FormInstance<T>
) => {
  useEffect(() => {
    form.setFields(
      Object.entries(values).map(([key, val]) => ({
        name: key,
        value: val,
      }))
    );
  }, [values]);
};
