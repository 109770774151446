import React from 'react';
import Form, { useForm } from 'antd/es/form/Form';

import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { CharLimitHelper, TextArea } from '@npm/core/ui/components/atoms/Input';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { Modal, useModal } from '@npm/core/ui/components/molecules/Modal';
import { SuccessModal } from '@npm/core/ui/components/organisms/SuccessModal';
import { handleValidationError } from '@npm/core/ui/utils/form';

import { type ZendeskTicketGroupID, useZendesk } from '../widget';
import { ZENDESK_DESCRIPTION_MAX_LENGTH } from '../widget/zendesk.utils';

type Props = {
  open: boolean;
  onCancel: () => void;
  zendeskTicketGroupId: ZendeskTicketGroupID;
};

export const ZendeskReportIssueModal = ({
  open,
  onCancel,
  zendeskTicketGroupId,
}: Props) => {
  const [form] = useForm<{ description: string }>();

  const [openSuccessModal, successModalProps] = useModal();
  const { createCustomerRequest } = useZendesk();
  const { showAlert } = useAlerts();

  const handleZendeskRequest = async (values: { description: string }) => {
    try {
      await form.validateFields();
    } catch (err) {
      handleValidationError(form, err);
      return;
    }

    const { success, message } = await createCustomerRequest(
      zendeskTicketGroupId,
      values.description
    );
    if (success) {
      openSuccessModal();
      form.resetFields();
      onCancel();
    } else {
      showAlert(message, {
        type: 'error',
      });
    }
  };
  return (
    <>
      <Modal
        open={open}
        title="Report Issue"
        onOk={() => form.submit()}
        onCancel={onCancel}
        okText="Submit"
      >
        <Form form={form} onFinish={handleZendeskRequest}>
          <FormItem
            name="description"
            label="Describe the problem"
            rules={[
              VALIDATION_RULES.max(ZENDESK_DESCRIPTION_MAX_LENGTH),
              VALIDATION_RULES.required(),
            ]}
            extra={
              <CharLimitHelper
                fieldName="description"
                limit={ZENDESK_DESCRIPTION_MAX_LENGTH}
              />
            }
            marginBottom="sm"
          >
            <TextArea placeholder="Describe the problem" />
          </FormItem>
        </Form>
      </Modal>
      <SuccessModal
        {...successModalProps}
        icon="circle-check"
        title="Issue Reported"
        subTitle="Thank you for your help"
      />
    </>
  );
};
