/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Mark to Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActionStatusResult } from '../model';
// @ts-ignore
import { ListPendingMtms } from '../model';
// @ts-ignore
import { MarkToMarketIndex } from '../model';
// @ts-ignore
import { MarkToMarketUpdateRequestContract } from '../model';
// @ts-ignore
import { MovingAveragePrices } from '../model';
// @ts-ignore
import { MtmStatList } from '../model';
/**
 * MarkToMarketsApi - axios parameter creator
 * @export
 */
export const MarkToMarketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list all equity asset mark to markets for a specific company
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [state] 
         * @param {boolean} [includeAmended] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [mutualFundParentName] 
         * @param {string} [assetName] 
         * @param {string} [repPdDate] 
         * @param {string} [repPdDateStart] 
         * @param {string} [repPdDateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketCompanyEquityAssetsIndex: async (companyId: number, page?: number, size?: number, state?: string, includeAmended?: boolean, xNPMAuthorization?: string, xSubscriptionPlan?: string, mutualFundParentName?: string, assetName?: string, repPdDate?: string, repPdDateStart?: string, repPdDateEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('markToMarketCompanyEquityAssetsIndex', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/companies/{company_id}/mark_to_markets`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (includeAmended !== undefined) {
                localVarQueryParameter['include_amended'] = includeAmended;
            }

            if (mutualFundParentName !== undefined) {
                localVarQueryParameter['mutual_fund_parent_name'] = mutualFundParentName;
            }

            if (assetName !== undefined) {
                localVarQueryParameter['asset_name'] = assetName;
            }

            if (repPdDate !== undefined) {
                localVarQueryParameter['rep_pd_date'] = repPdDate;
            }

            if (repPdDateStart !== undefined) {
                localVarQueryParameter['rep_pd_date_start'] = repPdDateStart;
            }

            if (repPdDateEnd !== undefined) {
                localVarQueryParameter['rep_pd_date_end'] = repPdDateEnd;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pending mtms by issuer
         * @param {string} assetType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketGetPending: async (assetType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetType' is not null or undefined
            assertParamExists('markToMarketGetPending', 'assetType', assetType)
            const localVarPath = changeUrl(`/api/admin/pending-mtms-by-issuer`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (assetType !== undefined) {
                localVarQueryParameter['asset_type'] = assetType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all equity asset mark to markets for a specific issuer
         * @param {number} issuerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [state] 
         * @param {boolean} [includeAmended] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketIssuerEquityAssetsIndex: async (issuerId: number, page?: number, size?: number, state?: string, includeAmended?: boolean, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('markToMarketIssuerEquityAssetsIndex', 'issuerId', issuerId)
            const localVarPath = changeUrl(`/api/issuers/{issuer_id}/mark_to_markets`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (includeAmended !== undefined) {
                localVarQueryParameter['include_amended'] = includeAmended;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns Company 90-Day Moving average prices
         * @param {number} companyId 
         * @param {number} [months] 
         * @param {string} [endDate] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketMovingAveragePrices: async (companyId: number, months?: number, endDate?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('markToMarketMovingAveragePrices', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/companies/{company_id}/moving_average_prices`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (months !== undefined) {
                localVarQueryParameter['months'] = months;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all equity asset mark to markets for a specific mutual fund
         * @param {number} mutualFundId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [state] 
         * @param {boolean} [includeAmended] 
         * @param {string} [sortBy] filed_at OR rep_pd_date
         * @param {string} [sortDir] asc OR desc
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketMutualFundEquityAssetsIndex: async (mutualFundId: number, page?: number, size?: number, state?: string, includeAmended?: boolean, sortBy?: string, sortDir?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mutualFundId' is not null or undefined
            assertParamExists('markToMarketMutualFundEquityAssetsIndex', 'mutualFundId', mutualFundId)
            const localVarPath = changeUrl(`/api/mutual_funds/{mutual_fund_id}/mark_to_markets`)
                .replace(`{${"mutual_fund_id"}}`, encodeURIComponent(String(mutualFundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (includeAmended !== undefined) {
                localVarQueryParameter['include_amended'] = includeAmended;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sort_dir'] = sortDir;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary quarterly stats
         * @param {string} companyIds Comma-delimited Venus IDs and/or NPM GUIDs
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketQuarterlyStatsIndex: async (companyIds: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyIds' is not null or undefined
            assertParamExists('markToMarketQuarterlyStatsIndex', 'companyIds', companyIds)
            const localVarPath = changeUrl(`/api/stats/quarterly`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyIds !== undefined) {
                localVarQueryParameter['company_ids'] = companyIds;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary quarterly stats
         * @param {string} companyId company_id
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketQuarterlyStatsIndexByCompany: async (companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('markToMarketQuarterlyStatsIndexByCompany', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/companies/{company_id}/stats/quarterly`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a mark to market
         * @param {number} markToMarketId 
         * @param {MarkToMarketUpdateRequestContract} markToMarketUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketUpdate: async (markToMarketId: number, markToMarketUpdateRequestContract: MarkToMarketUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'markToMarketId' is not null or undefined
            assertParamExists('markToMarketUpdate', 'markToMarketId', markToMarketId)
            // verify required parameter 'markToMarketUpdateRequestContract' is not null or undefined
            assertParamExists('markToMarketUpdate', 'markToMarketUpdateRequestContract', markToMarketUpdateRequestContract)
            const localVarPath = changeUrl(`/api/mark_to_markets/{mark_to_market_id}`)
                .replace(`{${"mark_to_market_id"}}`, encodeURIComponent(String(markToMarketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markToMarketUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarkToMarketsApi - functional programming interface
 * @export
 */
export const MarkToMarketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarkToMarketsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary list all equity asset mark to markets for a specific company
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [state] 
         * @param {boolean} [includeAmended] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [mutualFundParentName] 
         * @param {string} [assetName] 
         * @param {string} [repPdDate] 
         * @param {string} [repPdDateStart] 
         * @param {string} [repPdDateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markToMarketCompanyEquityAssetsIndex(companyId: number, page?: number, size?: number, state?: string, includeAmended?: boolean, xNPMAuthorization?: string, xSubscriptionPlan?: string, mutualFundParentName?: string, assetName?: string, repPdDate?: string, repPdDateStart?: string, repPdDateEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkToMarketIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markToMarketCompanyEquityAssetsIndex(companyId, page, size, state, includeAmended, xNPMAuthorization, xSubscriptionPlan, mutualFundParentName, assetName, repPdDate, repPdDateStart, repPdDateEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get pending mtms by issuer
         * @param {string} assetType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markToMarketGetPending(assetType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPendingMtms>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markToMarketGetPending(assetType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all equity asset mark to markets for a specific issuer
         * @param {number} issuerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [state] 
         * @param {boolean} [includeAmended] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markToMarketIssuerEquityAssetsIndex(issuerId: number, page?: number, size?: number, state?: string, includeAmended?: boolean, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkToMarketIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markToMarketIssuerEquityAssetsIndex(issuerId, page, size, state, includeAmended, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns Company 90-Day Moving average prices
         * @param {number} companyId 
         * @param {number} [months] 
         * @param {string} [endDate] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markToMarketMovingAveragePrices(companyId: number, months?: number, endDate?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MovingAveragePrices>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markToMarketMovingAveragePrices(companyId, months, endDate, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all equity asset mark to markets for a specific mutual fund
         * @param {number} mutualFundId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [state] 
         * @param {boolean} [includeAmended] 
         * @param {string} [sortBy] filed_at OR rep_pd_date
         * @param {string} [sortDir] asc OR desc
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markToMarketMutualFundEquityAssetsIndex(mutualFundId: number, page?: number, size?: number, state?: string, includeAmended?: boolean, sortBy?: string, sortDir?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkToMarketIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markToMarketMutualFundEquityAssetsIndex(mutualFundId, page, size, state, includeAmended, sortBy, sortDir, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary quarterly stats
         * @param {string} companyIds Comma-delimited Venus IDs and/or NPM GUIDs
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markToMarketQuarterlyStatsIndex(companyIds: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MtmStatList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markToMarketQuarterlyStatsIndex(companyIds, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary quarterly stats
         * @param {string} companyId company_id
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markToMarketQuarterlyStatsIndexByCompany(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MtmStatList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markToMarketQuarterlyStatsIndexByCompany(companyId, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a mark to market
         * @param {number} markToMarketId 
         * @param {MarkToMarketUpdateRequestContract} markToMarketUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markToMarketUpdate(markToMarketId: number, markToMarketUpdateRequestContract: MarkToMarketUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markToMarketUpdate(markToMarketId, markToMarketUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarkToMarketsApi - factory interface
 * @export
 */
export const MarkToMarketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarkToMarketsApiFp(configuration)
    return {
        /**
         * 
         * @summary list all equity asset mark to markets for a specific company
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [state] 
         * @param {boolean} [includeAmended] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [mutualFundParentName] 
         * @param {string} [assetName] 
         * @param {string} [repPdDate] 
         * @param {string} [repPdDateStart] 
         * @param {string} [repPdDateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketCompanyEquityAssetsIndex(companyId: number, page?: number, size?: number, state?: string, includeAmended?: boolean, xNPMAuthorization?: string, xSubscriptionPlan?: string, mutualFundParentName?: string, assetName?: string, repPdDate?: string, repPdDateStart?: string, repPdDateEnd?: string, options?: any): AxiosPromise<MarkToMarketIndex> {
            return localVarFp.markToMarketCompanyEquityAssetsIndex(companyId, page, size, state, includeAmended, xNPMAuthorization, xSubscriptionPlan, mutualFundParentName, assetName, repPdDate, repPdDateStart, repPdDateEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pending mtms by issuer
         * @param {string} assetType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketGetPending(assetType: string, options?: any): AxiosPromise<ListPendingMtms> {
            return localVarFp.markToMarketGetPending(assetType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all equity asset mark to markets for a specific issuer
         * @param {number} issuerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [state] 
         * @param {boolean} [includeAmended] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketIssuerEquityAssetsIndex(issuerId: number, page?: number, size?: number, state?: string, includeAmended?: boolean, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<MarkToMarketIndex> {
            return localVarFp.markToMarketIssuerEquityAssetsIndex(issuerId, page, size, state, includeAmended, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns Company 90-Day Moving average prices
         * @param {number} companyId 
         * @param {number} [months] 
         * @param {string} [endDate] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketMovingAveragePrices(companyId: number, months?: number, endDate?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<MovingAveragePrices> {
            return localVarFp.markToMarketMovingAveragePrices(companyId, months, endDate, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all equity asset mark to markets for a specific mutual fund
         * @param {number} mutualFundId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [state] 
         * @param {boolean} [includeAmended] 
         * @param {string} [sortBy] filed_at OR rep_pd_date
         * @param {string} [sortDir] asc OR desc
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketMutualFundEquityAssetsIndex(mutualFundId: number, page?: number, size?: number, state?: string, includeAmended?: boolean, sortBy?: string, sortDir?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<MarkToMarketIndex> {
            return localVarFp.markToMarketMutualFundEquityAssetsIndex(mutualFundId, page, size, state, includeAmended, sortBy, sortDir, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary quarterly stats
         * @param {string} companyIds Comma-delimited Venus IDs and/or NPM GUIDs
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketQuarterlyStatsIndex(companyIds: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<MtmStatList> {
            return localVarFp.markToMarketQuarterlyStatsIndex(companyIds, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary quarterly stats
         * @param {string} companyId company_id
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketQuarterlyStatsIndexByCompany(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<MtmStatList> {
            return localVarFp.markToMarketQuarterlyStatsIndexByCompany(companyId, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a mark to market
         * @param {number} markToMarketId 
         * @param {MarkToMarketUpdateRequestContract} markToMarketUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markToMarketUpdate(markToMarketId: number, markToMarketUpdateRequestContract: MarkToMarketUpdateRequestContract, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.markToMarketUpdate(markToMarketId, markToMarketUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for markToMarketCompanyEquityAssetsIndex operation in MarkToMarketsApi.
 * @export
 * @interface MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndexRequest
 */
export interface MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndex
     */
    readonly companyId: number

    /**
     * 
     * @type {number}
     * @memberof MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndex
     */
    readonly state?: string

    /**
     * 
     * @type {boolean}
     * @memberof MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndex
     */
    readonly includeAmended?: boolean

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndex
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndex
     */
    readonly mutualFundParentName?: string

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndex
     */
    readonly assetName?: string

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndex
     */
    readonly repPdDate?: string

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndex
     */
    readonly repPdDateStart?: string

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndex
     */
    readonly repPdDateEnd?: string
}

/**
 * Request parameters for markToMarketGetPending operation in MarkToMarketsApi.
 * @export
 * @interface MarkToMarketsApiMarkToMarketGetPendingRequest
 */
export interface MarkToMarketsApiMarkToMarketGetPendingRequest {
    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketGetPending
     */
    readonly assetType: string
}

/**
 * Request parameters for markToMarketIssuerEquityAssetsIndex operation in MarkToMarketsApi.
 * @export
 * @interface MarkToMarketsApiMarkToMarketIssuerEquityAssetsIndexRequest
 */
export interface MarkToMarketsApiMarkToMarketIssuerEquityAssetsIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof MarkToMarketsApiMarkToMarketIssuerEquityAssetsIndex
     */
    readonly issuerId: number

    /**
     * 
     * @type {number}
     * @memberof MarkToMarketsApiMarkToMarketIssuerEquityAssetsIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MarkToMarketsApiMarkToMarketIssuerEquityAssetsIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketIssuerEquityAssetsIndex
     */
    readonly state?: string

    /**
     * 
     * @type {boolean}
     * @memberof MarkToMarketsApiMarkToMarketIssuerEquityAssetsIndex
     */
    readonly includeAmended?: boolean

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketIssuerEquityAssetsIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketIssuerEquityAssetsIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for markToMarketMovingAveragePrices operation in MarkToMarketsApi.
 * @export
 * @interface MarkToMarketsApiMarkToMarketMovingAveragePricesRequest
 */
export interface MarkToMarketsApiMarkToMarketMovingAveragePricesRequest {
    /**
     * 
     * @type {number}
     * @memberof MarkToMarketsApiMarkToMarketMovingAveragePrices
     */
    readonly companyId: number

    /**
     * 
     * @type {number}
     * @memberof MarkToMarketsApiMarkToMarketMovingAveragePrices
     */
    readonly months?: number

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketMovingAveragePrices
     */
    readonly endDate?: string

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketMovingAveragePrices
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketMovingAveragePrices
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for markToMarketMutualFundEquityAssetsIndex operation in MarkToMarketsApi.
 * @export
 * @interface MarkToMarketsApiMarkToMarketMutualFundEquityAssetsIndexRequest
 */
export interface MarkToMarketsApiMarkToMarketMutualFundEquityAssetsIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof MarkToMarketsApiMarkToMarketMutualFundEquityAssetsIndex
     */
    readonly mutualFundId: number

    /**
     * 
     * @type {number}
     * @memberof MarkToMarketsApiMarkToMarketMutualFundEquityAssetsIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MarkToMarketsApiMarkToMarketMutualFundEquityAssetsIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketMutualFundEquityAssetsIndex
     */
    readonly state?: string

    /**
     * 
     * @type {boolean}
     * @memberof MarkToMarketsApiMarkToMarketMutualFundEquityAssetsIndex
     */
    readonly includeAmended?: boolean

    /**
     * filed_at OR rep_pd_date
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketMutualFundEquityAssetsIndex
     */
    readonly sortBy?: string

    /**
     * asc OR desc
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketMutualFundEquityAssetsIndex
     */
    readonly sortDir?: string

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketMutualFundEquityAssetsIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketMutualFundEquityAssetsIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for markToMarketQuarterlyStatsIndex operation in MarkToMarketsApi.
 * @export
 * @interface MarkToMarketsApiMarkToMarketQuarterlyStatsIndexRequest
 */
export interface MarkToMarketsApiMarkToMarketQuarterlyStatsIndexRequest {
    /**
     * Comma-delimited Venus IDs and/or NPM GUIDs
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketQuarterlyStatsIndex
     */
    readonly companyIds: string

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketQuarterlyStatsIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketQuarterlyStatsIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for markToMarketQuarterlyStatsIndexByCompany operation in MarkToMarketsApi.
 * @export
 * @interface MarkToMarketsApiMarkToMarketQuarterlyStatsIndexByCompanyRequest
 */
export interface MarkToMarketsApiMarkToMarketQuarterlyStatsIndexByCompanyRequest {
    /**
     * company_id
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketQuarterlyStatsIndexByCompany
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketQuarterlyStatsIndexByCompany
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof MarkToMarketsApiMarkToMarketQuarterlyStatsIndexByCompany
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for markToMarketUpdate operation in MarkToMarketsApi.
 * @export
 * @interface MarkToMarketsApiMarkToMarketUpdateRequest
 */
export interface MarkToMarketsApiMarkToMarketUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof MarkToMarketsApiMarkToMarketUpdate
     */
    readonly markToMarketId: number

    /**
     * 
     * @type {MarkToMarketUpdateRequestContract}
     * @memberof MarkToMarketsApiMarkToMarketUpdate
     */
    readonly markToMarketUpdateRequestContract: MarkToMarketUpdateRequestContract
}

/**
 * MarkToMarketsApi - object-oriented interface
 * @export
 * @class MarkToMarketsApi
 * @extends {BaseAPI}
 */
export class MarkToMarketsApi extends BaseAPI {
    /**
     * 
     * @summary list all equity asset mark to markets for a specific company
     * @param {MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarkToMarketsApi
     */
    public markToMarketCompanyEquityAssetsIndex(requestParameters: MarkToMarketsApiMarkToMarketCompanyEquityAssetsIndexRequest, options?: AxiosRequestConfig) {
        return MarkToMarketsApiFp(this.configuration).markToMarketCompanyEquityAssetsIndex(requestParameters.companyId, requestParameters.page, requestParameters.size, requestParameters.state, requestParameters.includeAmended, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.mutualFundParentName, requestParameters.assetName, requestParameters.repPdDate, requestParameters.repPdDateStart, requestParameters.repPdDateEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pending mtms by issuer
     * @param {MarkToMarketsApiMarkToMarketGetPendingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarkToMarketsApi
     */
    public markToMarketGetPending(requestParameters: MarkToMarketsApiMarkToMarketGetPendingRequest, options?: AxiosRequestConfig) {
        return MarkToMarketsApiFp(this.configuration).markToMarketGetPending(requestParameters.assetType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all equity asset mark to markets for a specific issuer
     * @param {MarkToMarketsApiMarkToMarketIssuerEquityAssetsIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarkToMarketsApi
     */
    public markToMarketIssuerEquityAssetsIndex(requestParameters: MarkToMarketsApiMarkToMarketIssuerEquityAssetsIndexRequest, options?: AxiosRequestConfig) {
        return MarkToMarketsApiFp(this.configuration).markToMarketIssuerEquityAssetsIndex(requestParameters.issuerId, requestParameters.page, requestParameters.size, requestParameters.state, requestParameters.includeAmended, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns Company 90-Day Moving average prices
     * @param {MarkToMarketsApiMarkToMarketMovingAveragePricesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarkToMarketsApi
     */
    public markToMarketMovingAveragePrices(requestParameters: MarkToMarketsApiMarkToMarketMovingAveragePricesRequest, options?: AxiosRequestConfig) {
        return MarkToMarketsApiFp(this.configuration).markToMarketMovingAveragePrices(requestParameters.companyId, requestParameters.months, requestParameters.endDate, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all equity asset mark to markets for a specific mutual fund
     * @param {MarkToMarketsApiMarkToMarketMutualFundEquityAssetsIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarkToMarketsApi
     */
    public markToMarketMutualFundEquityAssetsIndex(requestParameters: MarkToMarketsApiMarkToMarketMutualFundEquityAssetsIndexRequest, options?: AxiosRequestConfig) {
        return MarkToMarketsApiFp(this.configuration).markToMarketMutualFundEquityAssetsIndex(requestParameters.mutualFundId, requestParameters.page, requestParameters.size, requestParameters.state, requestParameters.includeAmended, requestParameters.sortBy, requestParameters.sortDir, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary quarterly stats
     * @param {MarkToMarketsApiMarkToMarketQuarterlyStatsIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarkToMarketsApi
     */
    public markToMarketQuarterlyStatsIndex(requestParameters: MarkToMarketsApiMarkToMarketQuarterlyStatsIndexRequest, options?: AxiosRequestConfig) {
        return MarkToMarketsApiFp(this.configuration).markToMarketQuarterlyStatsIndex(requestParameters.companyIds, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary quarterly stats
     * @param {MarkToMarketsApiMarkToMarketQuarterlyStatsIndexByCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarkToMarketsApi
     */
    public markToMarketQuarterlyStatsIndexByCompany(requestParameters: MarkToMarketsApiMarkToMarketQuarterlyStatsIndexByCompanyRequest, options?: AxiosRequestConfig) {
        return MarkToMarketsApiFp(this.configuration).markToMarketQuarterlyStatsIndexByCompany(requestParameters.companyId, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a mark to market
     * @param {MarkToMarketsApiMarkToMarketUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarkToMarketsApi
     */
    public markToMarketUpdate(requestParameters: MarkToMarketsApiMarkToMarketUpdateRequest, options?: AxiosRequestConfig) {
        return MarkToMarketsApiFp(this.configuration).markToMarketUpdate(requestParameters.markToMarketId, requestParameters.markToMarketUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type MarkToMarketCompanyEquityAssetsIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MarkToMarketCompanyEquityAssetsIndexErrorCodes = [
];

export type MarkToMarketGetPendingErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MarkToMarketGetPendingErrorCodes = [
];

export type MarkToMarketIssuerEquityAssetsIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MarkToMarketIssuerEquityAssetsIndexErrorCodes = [
];

export type MarkToMarketMovingAveragePricesErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MarkToMarketMovingAveragePricesErrorCodes = [
    401,
    422,
];

export type MarkToMarketMutualFundEquityAssetsIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MarkToMarketMutualFundEquityAssetsIndexErrorCodes = [
];

export type MarkToMarketQuarterlyStatsIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MarkToMarketQuarterlyStatsIndexErrorCodes = [
];

export type MarkToMarketQuarterlyStatsIndexByCompanyErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MarkToMarketQuarterlyStatsIndexByCompanyErrorCodes = [
];

export type MarkToMarketUpdateErrorTypes =

    | {
        status: 404;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MarkToMarketUpdateErrorCodes = [
    404,
    422,
];


