import Big from 'big.js';

import { type SecondmarketOrderItemShow, CbAssetType } from '@npm/data-access';

import { staticValues } from './staticValues';

export const calculateEstimatedNetProceeds = (
  price: number | null,
  amount: number | null,
  isEntityNpmsInvestor: boolean,
  orderItem: SecondmarketOrderItemShow
): number | string => {
  if (!!price && !!amount) {
    const executionFee = new Big(
      isEntityNpmsInvestor
        ? staticValues?.entity?.executionFee || 0
        : staticValues?.individual?.executionFee || 0
    );

    const shouldCalculateExerciseCosts =
      orderItem?.holding?.asset?.type?.code === CbAssetType.items.Option &&
      !orderItem?.exercise_costs;

    const exerciseCosts = shouldCalculateExerciseCosts
      ? amount * orderItem?.holding?.asset?.strike_price
      : orderItem?.exercise_costs;

    const result = new Big(price)
      .mul(amount)
      .div(100)
      .mul(new Big(100).minus(executionFee))
      .minus(exerciseCosts || 0)
      .minus(orderItem?.tax_withholdings || 0)
      .minus(orderItem?.other_withholdings || 0);

    if (result.lt(0)) {
      return '--';
    }

    return result.toNumber();
  }

  return '--';
};
