import React, { type ComponentProps } from 'react';

import { GetStarted as BaseGetStarted } from '@npm/core/ui/components/molecules/GetStarted';

type Props = {
  accountName: string;
} & ComponentProps<typeof BaseGetStarted>;

export const AuthorizedSignersGetStarted = ({
  accountName,
  onGetStarted,
}: Props) => {
  return (
    <BaseGetStarted
      onGetStarted={onGetStarted}
      title="Authorized Signer"
      content={
        <>
          <p>
            The representative(s) listed can sign on behalf of {accountName}
          </p>
          <p>
            To be added as an authorized signer for this account, an individual
            must have the legal authority to bind the individual or entity
            identified in this account’s name.
          </p>
        </>
      }
      withBullets
      testId="signer-get-started-button"
    />
  );
};
