export const DEFAULT_SUPPORTED_CONTENT_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
];

export const DEFAULT_SUPPORTED_FILE_EXTENSIONS = [
  '.png',
  '.jpg',
  '.jpeg',
  '.pdf',
];
