import { type ErrorObj } from './ErrorSkeleton';

export const getDefaultTitle = (error: ErrorObj) => {
  switch (error?.status ?? error?.originalStatus) {
    case 404:
      return 'Not Found Error';
    case 403:
      return 'Unauthorized Error';
    case 443:
      return 'Data are not ready';
    case 428:
      return "We can't load the data";
    default:
      return 'Unexpected Error';
  }
};

export const showStatusCode = (error: ErrorObj) => {
  return (error?.status ?? error?.originalStatus) !== 443;
};

export const getDefaultSubtitle = (error: ErrorObj) => {
  switch (
    error?.status != null && error?.status > 0
      ? error?.status
      : error?.originalStatus
  ) {
    case 422:
    case 423:
      return error?.data?.error_message ?? 'Something went wrong';
    case 443:
      return (
        error?.data?.error_message ?? 'Data are being prepared at this moment'
      );
    case 428:
      return '';
    default:
      return 'Something went wrong';
  }
};

export const isAutoReloadEnabled = (error: ErrorObj) => {
  return (error?.status ?? error?.originalStatus) === 443;
};

export const isAuthError = (
  error: ErrorObj,
  statusCode: number | undefined
) => {
  return (
    error.name === 'AuthSdkError' ||
    (statusCode === 422 &&
      error.data?.error_message?.toLowerCase().includes('user role not found'))
  );
};
