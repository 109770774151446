import React from 'react';
import { createPortal } from 'react-dom';

import { CypressDataIds } from '../../../constants';
import { Heading, Text } from '../../atoms/Typography';

import { Logo } from './Logo';

import * as S from './FullScreenLoading.styles';

type Props = {
  loadingTitle?: string;
  loadingText?: string;
  fitToViewport?: boolean;
  isPositionAbsolute?: boolean;
};

export const FullScreenLoading = ({
  loadingTitle,
  loadingText,
  fitToViewport = false,
  isPositionAbsolute = false,
}: Props) => (
  <S.Wrapper
    $fitToViewport={fitToViewport}
    $isPositionAbsolute={isPositionAbsolute}
    data-cy={CypressDataIds.Loading.FullScreenLoading}
  >
    <S.CenteredContentWrapper>
      <S.LoaderWrapper>
        <S.Loader height="auto" />
      </S.LoaderWrapper>
      <Heading variant="h1" marginBottom="sm">
        {loadingTitle || 'App is loading...'}
      </Heading>
      <Text size="sm">
        {loadingText || 'Please wait, this can take a few seconds'}
      </Text>
    </S.CenteredContentWrapper>
    <S.LogoWrapper>
      <Logo />
    </S.LogoWrapper>
  </S.Wrapper>
);

export const FullScreenLoadingWithPortal = (
  props: Pick<Props, 'loadingText' | 'loadingTitle'>
) => {
  const body = document.querySelector('body');
  if (!body) return null;
  return createPortal(
    <FullScreenLoading isPositionAbsolute {...props} />,
    body
  );
};
