import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useQueryParam } from 'use-query-params';

import { APP_CFG_API_URL } from '@npm/config';

const ACTIVATION_KEY = 'key';

export const withRedirectToActivationPage = <
  T extends React.JSX.IntrinsicAttributes
>(
  Component: React.ComponentType<T>
) => {
  return (hocProps: T) => {
    const [checked, setChecked] = useState(false);
    const [activationKey] = useQueryParam(ACTIVATION_KEY);
    const hasActivationKey = !!activationKey;

    useEffect(() => {
      if (!hasActivationKey) {
        setChecked(true);
        return;
      }

      axios({
        method: 'post',
        url: `${APP_CFG_API_URL}/api/user/verify`,
        data: {
          key: activationKey,
        },
      }).then(
        response => {
          const typedResponse = response.data as unknown as {
            activation_link?: string;
          };

          if (typedResponse?.activation_link) {
            window.location.href = typedResponse.activation_link;
          } else {
            // Remove key from redirect URI as we will not need it anymore.
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.delete(ACTIVATION_KEY);

            setChecked(true);
          }
        },
        error => {
          // no need to check it, user will end up on login page
          console.error('User verify endpoint failed', error);

          // Remove key from redirect URI as we will not need it anymore.
          const currentUrl = new URL(window.location.href);
          currentUrl.searchParams.delete(ACTIVATION_KEY);

          setChecked(true);
        }
      );
    }, [hasActivationKey, activationKey]);

    if (!checked) {
      return null;
    }

    return <Component {...hocProps} />;
  };
};
