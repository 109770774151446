import create from 'zustand';

export enum InitialLoadingPart {
  User = 'user',
  UserRoles = 'user-roles',
  InvestorAccounts = 'investor-accounts',
  FeatureFlags = 'feature-flags',
}

const INITIAL_LOADING_SEQUENCE: InitialLoadingPart[] = [
  InitialLoadingPart.User,
  InitialLoadingPart.UserRoles,
  InitialLoadingPart.InvestorAccounts,
  InitialLoadingPart.FeatureFlags,
];
const LOADING_TEXT: Record<InitialLoadingPart, string> = {
  [InitialLoadingPart.User]: 'Loading user data',
  [InitialLoadingPart.UserRoles]: 'Loading user data',
  [InitialLoadingPart.InvestorAccounts]: 'Loading user data',
  [InitialLoadingPart.FeatureFlags]: 'Loading user data',
};

interface InitialLoadingStore {
  isLoading: boolean;
  loadingText: string;
  finishLoadingPart: (loadingPart: InitialLoadingPart) => void;
}

export const useInitialLoadingStore = create<InitialLoadingStore>(
  (set, get) => ({
    isLoading: true,
    loadingText: LOADING_TEXT[INITIAL_LOADING_SEQUENCE[0]],
    // this is now relying on the fact that we're using HOC to load the data (withUser > withUserRoles > app)
    // if this were to change, correct approach would be:
    // - add all steps to store (into loadingSteps)
    // - remove step once it is finished (message should be always the first loadingStep message)
    // - once the array is empty, set isLoading to false
    finishLoadingPart: appLoadingPart => {
      const finishedLoadingPartIndex =
        INITIAL_LOADING_SEQUENCE.indexOf(appLoadingPart);
      const nextLoadingPart =
        INITIAL_LOADING_SEQUENCE[finishedLoadingPartIndex + 1];

      if (!nextLoadingPart) {
        return set({ ...get(), isLoading: false });
      }

      set({
        ...get(),
        loadingText: LOADING_TEXT[nextLoadingPart],
      });
    },
  })
);
