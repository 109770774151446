import { type PointerEvent } from 'react';

type HandleApplePencilClickProps = {
  e: PointerEvent<HTMLDivElement>;
  onChange: (id: string) => void;
};

const getId = (target: HTMLElement): string | undefined => {
  const id = target?.id;
  return id?.split('-tab-')[1];
};

export const handleApplePencilClick = ({
  e,
  onChange,
}: HandleApplePencilClickProps) => {
  if (e.pointerType !== 'pen') return;

  const target = e.target as HTMLElement;
  let strippedId = getId(target); // if user press tab background

  if (!strippedId) {
    strippedId = getId(target.parentNode as HTMLElement); // if user press text inside tab
  }

  if (strippedId) {
    onChange(strippedId);
  }
};
