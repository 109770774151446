import { type DefaultTheme } from 'styled-components';

export const getBidsColor = (theme: DefaultTheme) => {
  return theme.color.success.typography.primary;
};

export const getOffersColor = (theme: DefaultTheme) => {
  return theme.color.error.typography.primary;
};

export const getTradesColor = () => {
  return '#9147F0';
};
