import { useMemo } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import {
  type MenuItem,
  type MenuItemGroup,
} from '@npm/core/ui/components/organisms/Layout';
import { useOktaUserClaims } from '@npm/okta';

import { useUserContextStore } from '../context';

export const simpleLogoutItem = {
  label: 'Logout',
  key: '/logout',
  icon: <Icon name="logout" size="xs" />,
};

export const useUserMenuItems = () => {
  const user = useUserContextStore(store => store.user);
  const getFullName = useUserContextStore(store => store.getFullName);

  // Fallback when user store is not available (e.g. the terms and conditions page)
  const oktaUserClaims = useOktaUserClaims();

  return useMemo<(MenuItem | MenuItemGroup)[]>(
    () => [
      {
        key: 'user',
        label: (
          <span data-dd-privacy="mask">
            {getFullName() || oktaUserClaims?.name}
            <br />
            {user?.person?.email || oktaUserClaims?.email}
          </span>
        ),
        icon: <Icon name="user-circle" size="xs" />,
        disabled: true,
      },
      {
        label: (
          <Flex align="center" justify="space-between">
            <span>Logout</span>
          </Flex>
        ),
        title: 'Logout',
        key: '/logout',
        icon: <Icon name="logout" size="xs" />,
      },
    ],
    [
      getFullName,
      oktaUserClaims?.email,
      oktaUserClaims?.name,
      user?.person?.email,
    ]
  );
};
