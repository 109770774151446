import React from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import {
  type MenuItem,
  type MenuItemGroup,
} from '@npm/core/ui/components/organisms/Layout';
import { type PermissionContext, RBAC } from '@npm/core/ui/models/permissions';
import {
  type UserContextStore,
  filterMenuItems,
} from '@npm/features/auth/user/context';
import type { UserRoleContextType } from '@npm/features/auth/user/role/userRole.types';

import { HoldingsTitleWithPreVerifyCount } from '../../HoldingsTitleWithPreVerifyCount';

const {
  isBroReadOnly,
  isBroNotReadOnly,
  isBroAccountManager,
  isBroNotAccountManager,
} = RBAC.brokerage;

const workstationPrefix = 'brokerage-workstation';

export const getBrokerageSidebarItems: (
  userContext: UserContextStore,
  userRole: UserRoleContextType | null,
  obo: PermissionContext['obo'] | null,
  notificationMenuItem: MenuItemGroup
) => (MenuItem | MenuItemGroup)[] = (
  userContext,
  userRole,
  obo,
  notificationMenuItem
) => {
  return filterMenuItems(
    [
      {
        label: 'SecondMarket',
        key: '/second-market',
        icon: <Icon name="arrows-opposite" />,
        permissionsChecks: [isBroNotAccountManager],
      },
      ...(notificationMenuItem ? [notificationMenuItem] : []),
      {
        label: 'Client Watchlist',
        key: `/${workstationPrefix}/client-watchlist`,
        icon: <Icon name="star" />,
        permissionsChecks: [isBroNotReadOnly, isBroNotAccountManager],
      },
      {
        label: <HoldingsTitleWithPreVerifyCount />,
        key: `/${workstationPrefix}/holdings`,
        icon: <Icon name="chart-pie" />,
        permissionsChecks: [isBroAccountManager], // Move holdings to the top level for Account Manager
      },
      {
        label: 'General',
        key: 'general',
        icon: <Icon name="world" />,
        permissionsChecks: [isBroNotReadOnly],
        children: [
          {
            label: 'Matches',
            key: `/${workstationPrefix}/matches`,
          },
          {
            label: 'Administrators',
            key: `/${workstationPrefix}/administrators`,
          },
        ],
      },
      {
        label: 'Client Accounts',
        key: 'client-accounts',
        icon: <Icon name="building-bank" />,
        permissionsChecks: [isBroNotReadOnly],
        children: [
          {
            label: 'Entities',
            key: `/${workstationPrefix}/entities`,
          },
          {
            label: 'Individuals',
            key: `/${workstationPrefix}/individuals`,
          },
          {
            label: 'Account Representatives',
            key: `/${workstationPrefix}/persons`,
          },
          {
            label: 'Holdings',
            key: `/${workstationPrefix}/holdings`,
            permissionsChecks: [isBroNotAccountManager],
          },
        ],
      },
      {
        label: 'Administrators',
        key: `/${workstationPrefix}/administrators`,
        icon: <Icon name="users" />,
        permissionsChecks: [isBroReadOnly],
      },
    ],
    userContext,
    userRole,
    obo
  );
};
