/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CompanyQuarterlyIntrinsicValuesApi,
  CompanyQuarterlyIntrinsicValuesErrorCodes,
  CompanyQuarterlyIntrinsicValuesErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CompanyQuarterlyIntrinsicValuesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type CompanyQuarterlyIntrinsicValuesMethod = CompanyQuarterlyIntrinsicValuesApi['companyQuarterlyIntrinsicValues'];
export type CompanyQuarterlyIntrinsicValuesResponseType = MethodResult<CompanyQuarterlyIntrinsicValuesMethod>;

export const useCompanyQuarterlyIntrinsicValues = (
  variables: Parameters<CompanyQuarterlyIntrinsicValuesMethod>[0],
  config?: UseQueryConfig<
    CompanyQuarterlyIntrinsicValuesMethod,
    CompanyQuarterlyIntrinsicValuesErrorTypes
  >
) => {
  return useQuery<
    CompanyQuarterlyIntrinsicValuesMethod,
    CompanyQuarterlyIntrinsicValuesErrorTypes
  >(
    (...args) => api.companyQuarterlyIntrinsicValues(...args),
    CompanyQuarterlyIntrinsicValuesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyQuarterlyIntrinsicValues'],
        ...apiQueryConfig.CompanyQuarterlyIntrinsicValues,
        ...config?.queryConfig
      },
    },
    'CompanyQuarterlyIntrinsicValues',
  );
};

export const useCompanyQuarterlyIntrinsicValuesInfinite = (
  variables: Parameters<CompanyQuarterlyIntrinsicValuesMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanyQuarterlyIntrinsicValuesMethod,
    CompanyQuarterlyIntrinsicValuesErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanyQuarterlyIntrinsicValuesMethod,
    CompanyQuarterlyIntrinsicValuesErrorTypes
  >(
    (...args) => api.companyQuarterlyIntrinsicValues(...args),
    CompanyQuarterlyIntrinsicValuesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyQuarterlyIntrinsicValues', 'CompanyQuarterlyIntrinsicValuesInfinite'],
        ...apiQueryConfig.CompanyQuarterlyIntrinsicValues,
        ...config?.queryConfig,
      },
    },
    'CompanyQuarterlyIntrinsicValues',
  );
};

export const useCompanyQuarterlyIntrinsicValuesLazy = (baseOptions?: {
  variables?: Parameters<CompanyQuarterlyIntrinsicValuesMethod>[0],
  config?: UseQueryConfig<
    CompanyQuarterlyIntrinsicValuesMethod,
    CompanyQuarterlyIntrinsicValuesErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanyQuarterlyIntrinsicValuesMethod,
    CompanyQuarterlyIntrinsicValuesErrorTypes
  >(
    (...args) => api.companyQuarterlyIntrinsicValues(...args),
    CompanyQuarterlyIntrinsicValuesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanyQuarterlyIntrinsicValues'],
        ...apiQueryConfig.CompanyQuarterlyIntrinsicValues,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanyQuarterlyIntrinsicValues',
  );
};

