/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActionStatusResult } from '../model';
// @ts-ignore
import { AssetMarkCreateRequestContract } from '../model';
// @ts-ignore
import { AssetMarkIndex } from '../model';
// @ts-ignore
import { AssetMarkUpdateRequestContract } from '../model';
/**
 * AssetMarkApi - axios parameter creator
 * @export
 */
export const AssetMarkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create marks for holdings valued by asset type
         * @param {AssetMarkCreateRequestContract} assetMarkCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetMarkCreate: async (assetMarkCreateRequestContract: AssetMarkCreateRequestContract, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetMarkCreateRequestContract' is not null or undefined
            assertParamExists('assetMarkCreate', 'assetMarkCreateRequestContract', assetMarkCreateRequestContract)
            const localVarPath = changeUrl(`/api/investor/asset_marks`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetMarkCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary remove asset mark
         * @param {string} reportingDate 
         * @param {string} assetClassSlug 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetMarkDelete: async (reportingDate: string, assetClassSlug: string, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportingDate' is not null or undefined
            assertParamExists('assetMarkDelete', 'reportingDate', reportingDate)
            // verify required parameter 'assetClassSlug' is not null or undefined
            assertParamExists('assetMarkDelete', 'assetClassSlug', assetClassSlug)
            const localVarPath = changeUrl(`/api/investor/asset_marks/{asset_class_slug}`)
                .replace(`{${"asset_class_slug"}}`, encodeURIComponent(String(assetClassSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (reportingDate !== undefined) {
                localVarQueryParameter['reporting_date'] = (reportingDate as any instanceof Date) ?
                    (reportingDate as any).toISOString().substr(0,10) :
                    reportingDate;
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list the marked values for assets
         * @param {string} [issuerGuid] 
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetMarkIndex: async (issuerGuid?: string, xWorkstation?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/investor/asset_marks`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (issuerGuid !== undefined) {
                localVarQueryParameter['issuer_guid'] = issuerGuid;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates marks by asset type
         * @param {string} assetClassSlug 
         * @param {AssetMarkUpdateRequestContract} assetMarkUpdateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetMarkUpdate: async (assetClassSlug: string, assetMarkUpdateRequestContract: AssetMarkUpdateRequestContract, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClassSlug' is not null or undefined
            assertParamExists('assetMarkUpdate', 'assetClassSlug', assetClassSlug)
            // verify required parameter 'assetMarkUpdateRequestContract' is not null or undefined
            assertParamExists('assetMarkUpdate', 'assetMarkUpdateRequestContract', assetMarkUpdateRequestContract)
            const localVarPath = changeUrl(`/api/investor/asset_marks/{asset_class_slug}`)
                .replace(`{${"asset_class_slug"}}`, encodeURIComponent(String(assetClassSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetMarkUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetMarkApi - functional programming interface
 * @export
 */
export const AssetMarkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetMarkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create marks for holdings valued by asset type
         * @param {AssetMarkCreateRequestContract} assetMarkCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetMarkCreate(assetMarkCreateRequestContract: AssetMarkCreateRequestContract, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetMarkCreate(assetMarkCreateRequestContract, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary remove asset mark
         * @param {string} reportingDate 
         * @param {string} assetClassSlug 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetMarkDelete(reportingDate: string, assetClassSlug: string, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetMarkDelete(reportingDate, assetClassSlug, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list the marked values for assets
         * @param {string} [issuerGuid] 
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetMarkIndex(issuerGuid?: string, xWorkstation?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetMarkIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetMarkIndex(issuerGuid, xWorkstation, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates marks by asset type
         * @param {string} assetClassSlug 
         * @param {AssetMarkUpdateRequestContract} assetMarkUpdateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetMarkUpdate(assetClassSlug: string, assetMarkUpdateRequestContract: AssetMarkUpdateRequestContract, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetMarkUpdate(assetClassSlug, assetMarkUpdateRequestContract, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetMarkApi - factory interface
 * @export
 */
export const AssetMarkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetMarkApiFp(configuration)
    return {
        /**
         * 
         * @summary create marks for holdings valued by asset type
         * @param {AssetMarkCreateRequestContract} assetMarkCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetMarkCreate(assetMarkCreateRequestContract: AssetMarkCreateRequestContract, xWorkstation?: string, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.assetMarkCreate(assetMarkCreateRequestContract, xWorkstation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary remove asset mark
         * @param {string} reportingDate 
         * @param {string} assetClassSlug 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetMarkDelete(reportingDate: string, assetClassSlug: string, xWorkstation?: string, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.assetMarkDelete(reportingDate, assetClassSlug, xWorkstation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list the marked values for assets
         * @param {string} [issuerGuid] 
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetMarkIndex(issuerGuid?: string, xWorkstation?: string, page?: number, size?: number, options?: any): AxiosPromise<AssetMarkIndex> {
            return localVarFp.assetMarkIndex(issuerGuid, xWorkstation, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates marks by asset type
         * @param {string} assetClassSlug 
         * @param {AssetMarkUpdateRequestContract} assetMarkUpdateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetMarkUpdate(assetClassSlug: string, assetMarkUpdateRequestContract: AssetMarkUpdateRequestContract, xWorkstation?: string, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.assetMarkUpdate(assetClassSlug, assetMarkUpdateRequestContract, xWorkstation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assetMarkCreate operation in AssetMarkApi.
 * @export
 * @interface AssetMarkApiAssetMarkCreateRequest
 */
export interface AssetMarkApiAssetMarkCreateRequest {
    /**
     * 
     * @type {AssetMarkCreateRequestContract}
     * @memberof AssetMarkApiAssetMarkCreate
     */
    readonly assetMarkCreateRequestContract: AssetMarkCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof AssetMarkApiAssetMarkCreate
     */
    readonly xWorkstation?: string
}

/**
 * Request parameters for assetMarkDelete operation in AssetMarkApi.
 * @export
 * @interface AssetMarkApiAssetMarkDeleteRequest
 */
export interface AssetMarkApiAssetMarkDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetMarkApiAssetMarkDelete
     */
    readonly reportingDate: string

    /**
     * 
     * @type {string}
     * @memberof AssetMarkApiAssetMarkDelete
     */
    readonly assetClassSlug: string

    /**
     * 
     * @type {string}
     * @memberof AssetMarkApiAssetMarkDelete
     */
    readonly xWorkstation?: string
}

/**
 * Request parameters for assetMarkIndex operation in AssetMarkApi.
 * @export
 * @interface AssetMarkApiAssetMarkIndexRequest
 */
export interface AssetMarkApiAssetMarkIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetMarkApiAssetMarkIndex
     */
    readonly issuerGuid?: string

    /**
     * 
     * @type {string}
     * @memberof AssetMarkApiAssetMarkIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {number}
     * @memberof AssetMarkApiAssetMarkIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AssetMarkApiAssetMarkIndex
     */
    readonly size?: number
}

/**
 * Request parameters for assetMarkUpdate operation in AssetMarkApi.
 * @export
 * @interface AssetMarkApiAssetMarkUpdateRequest
 */
export interface AssetMarkApiAssetMarkUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetMarkApiAssetMarkUpdate
     */
    readonly assetClassSlug: string

    /**
     * 
     * @type {AssetMarkUpdateRequestContract}
     * @memberof AssetMarkApiAssetMarkUpdate
     */
    readonly assetMarkUpdateRequestContract: AssetMarkUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof AssetMarkApiAssetMarkUpdate
     */
    readonly xWorkstation?: string
}

/**
 * AssetMarkApi - object-oriented interface
 * @export
 * @class AssetMarkApi
 * @extends {BaseAPI}
 */
export class AssetMarkApi extends BaseAPI {
    /**
     * 
     * @summary create marks for holdings valued by asset type
     * @param {AssetMarkApiAssetMarkCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetMarkApi
     */
    public assetMarkCreate(requestParameters: AssetMarkApiAssetMarkCreateRequest, options?: AxiosRequestConfig) {
        return AssetMarkApiFp(this.configuration).assetMarkCreate(requestParameters.assetMarkCreateRequestContract, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary remove asset mark
     * @param {AssetMarkApiAssetMarkDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetMarkApi
     */
    public assetMarkDelete(requestParameters: AssetMarkApiAssetMarkDeleteRequest, options?: AxiosRequestConfig) {
        return AssetMarkApiFp(this.configuration).assetMarkDelete(requestParameters.reportingDate, requestParameters.assetClassSlug, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list the marked values for assets
     * @param {AssetMarkApiAssetMarkIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetMarkApi
     */
    public assetMarkIndex(requestParameters: AssetMarkApiAssetMarkIndexRequest = {}, options?: AxiosRequestConfig) {
        return AssetMarkApiFp(this.configuration).assetMarkIndex(requestParameters.issuerGuid, requestParameters.xWorkstation, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates marks by asset type
     * @param {AssetMarkApiAssetMarkUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetMarkApi
     */
    public assetMarkUpdate(requestParameters: AssetMarkApiAssetMarkUpdateRequest, options?: AxiosRequestConfig) {
        return AssetMarkApiFp(this.configuration).assetMarkUpdate(requestParameters.assetClassSlug, requestParameters.assetMarkUpdateRequestContract, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AssetMarkCreateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AssetMarkCreateErrorCodes = [
];

export type AssetMarkDeleteErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AssetMarkDeleteErrorCodes = [
];

export type AssetMarkIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AssetMarkIndexErrorCodes = [
];

export type AssetMarkUpdateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AssetMarkUpdateErrorCodes = [
];


