import { type StepDefinition } from './Wizard.types';

export const calculateProgress = (index: number, stepCount: number) => {
  return Math.round((index * 100) / stepCount);
};

export const findNextStep = <T>(
  steps: StepDefinition<T>[],
  currentIndex: number,
  contextData?: T
) => {
  for (let i = currentIndex + 1; i < steps.length; i++) {
    const step = steps[i];
    if (step && (!step.rule || step.rule(contextData))) {
      return i;
    }
  }

  return -1;
};

export const findPrevStep = <T>(
  steps: StepDefinition<T>[],
  currentIndex: number,
  context?: T
) => {
  for (let i = currentIndex - 1; i >= 0; i--) {
    const step = steps[i];
    if (step && (!step.rule || step.rule(context))) {
      return i;
    }
  }

  return -1;
};

export const findNextStepAndSubstep = <T>(
  steps: StepDefinition<T>[],
  currentIndex: number,
  currentSubstepIndex?: number
) => {
  const nextStepIndex = Math.min(
    findNextStep(steps, currentIndex),
    steps.length - 1
  );
  const currentStepDefinition = steps[currentIndex];
  const currentStepSubstepsCount = currentStepDefinition?.substeps?.length;
  const nextStepDefinition = steps[nextStepIndex];
  const nextStepSubstepsCount = nextStepDefinition?.substeps?.length;

  let nextStep: { index: number; definition: StepDefinition<T> | undefined };
  let nextSubstep: { index: number; definition: StepDefinition<T> } | undefined;

  if (
    currentStepSubstepsCount &&
    currentSubstepIndex != null &&
    currentSubstepIndex + 1 < currentStepSubstepsCount
  ) {
    nextStep = {
      index: currentIndex,
      definition: currentStepDefinition,
    };

    const nextSubstepIndex = currentSubstepIndex + 1;
    const nextSubstepDefinition =
      currentStepDefinition.substeps?.[nextSubstepIndex];

    nextSubstep = nextSubstepDefinition && {
      index: nextSubstepIndex,
      definition: nextSubstepDefinition,
    };
  } else {
    nextStep = { index: nextStepIndex, definition: nextStepDefinition };

    const nextSubstepIndex = 0;
    const nextSubstepDefinition =
      nextStepDefinition?.substeps?.[nextSubstepIndex];

    if (nextStepSubstepsCount && nextSubstepDefinition) {
      nextSubstep = { index: 0, definition: nextSubstepDefinition };
    }
  }

  return { nextStep, nextSubstep };
};

export const findPrevStepAndSubstep = <T>(
  steps: StepDefinition<T>[],
  currentIndex: number,
  currentSubstepIndex?: number
) => {
  const prevStepIndex = Math.max(findPrevStep(steps, currentIndex), 0);
  const currentStepDefinition = steps[currentIndex];
  const currentStepSubstepsCount = currentStepDefinition?.substeps?.length;
  const prevStepDefinition = steps[prevStepIndex];
  const prevStepSubstepsCount = prevStepDefinition?.substeps?.length;

  let prevStep: { index: number; definition: StepDefinition<T> | undefined };
  let prevSubstep: { index: number; definition: StepDefinition<T> } | undefined;

  if (
    currentStepSubstepsCount &&
    currentSubstepIndex != null &&
    currentSubstepIndex > 0
  ) {
    prevStep = {
      index: currentIndex,
      definition: currentStepDefinition,
    };

    const prevSubstepIndex = currentSubstepIndex - 1;
    const prevSubstepDefinition =
      steps[currentIndex]?.substeps?.[prevSubstepIndex];

    prevSubstep = prevSubstepDefinition && {
      index: currentSubstepIndex - 1,
      definition: prevSubstepDefinition,
    };
  } else {
    prevStep = { index: prevStepIndex, definition: prevStepDefinition };

    if (prevStepSubstepsCount) {
      const prevSubstepIndex = prevStepSubstepsCount - 1;
      const prevSubstepDefinition =
        prevStepDefinition?.substeps?.[prevSubstepIndex];
      prevSubstep = prevSubstepDefinition && {
        index: prevStepSubstepsCount - 1,
        definition: prevSubstepDefinition,
      };
    }
  }

  return { prevStep, prevSubstep };
};

export const findNextIncompleteStep = <T>(
  steps: StepDefinition<T>[],
  currentIndex: number,
  context: T,
  isIncomplete: (step: StepDefinition<T>, context: T) => boolean
) => {
  for (let i = currentIndex + 1; i < steps.length; i++) {
    const step = steps[i];
    if (step && isIncomplete(step, context)) {
      return i;
    }
  }

  return -1;
};

export const getStepKeys = <T>(
  steps: StepDefinition<T>[],
  step: string | number,
  substep?: string | number
) => {
  const stepKey = typeof step === 'string' ? step : steps[step]?.key;
  const substepKey =
    typeof substep === 'string'
      ? substep
      : steps[step]?.substeps?.[substep ?? -1]?.key;

  return { stepKey, substepKey };
};
