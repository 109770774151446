import React, { useState } from 'react';
import { Popover } from 'antd';
import { useTheme } from 'styled-components';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import { BadgeCircle } from '../../styles';
import * as S from './FilterDropdown.styles';

type Props = {
  totalActiveFilters: number;
  overlayStyle?: React.CSSProperties;
};

export const FilterDropdown: React.FC<Props> = ({
  children,
  totalActiveFilters,
  overlayStyle,
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const content = children;

  const renderBadge = () => (
    <>
      <BadgeCircle>
        <Text size="xs" weight="bold">
          {totalActiveFilters}
        </Text>
      </BadgeCircle>{' '}
      Filters
    </>
  );

  return (
    <>
      <S.FilterDropdownStyles />
      <Popover
        overlayClassName="npm-filter-dropdown"
        content={content}
        trigger="click"
        showArrow={false}
        placement="bottomLeft"
        onOpenChange={v => setIsOpen(v)}
        overlayStyle={{
          zIndex: theme.zIndex.fixed - 1, // Below fixed headers
          ...overlayStyle,
        }}
      >
        <S.DropdownButton
          size="md"
          variant="outline"
          icon={
            totalActiveFilters && !isOpen ? undefined : (
              <Icon name={isOpen ? 'x-close' : 'filter'} />
            )
          }
          $isOpened={isOpen}
        >
          {totalActiveFilters && !isOpen
            ? renderBadge()
            : isOpen
            ? 'Hide'
            : 'Filters'}
        </S.DropdownButton>
      </Popover>
    </>
  );
};
