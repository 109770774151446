import { useLayoutConfig } from '../components/organisms/Layout';

import { useBreakpoints } from './useBreakpoints';

export const SM_HEADER_DESKTOP_HEIGHT = 60;
export const SM_HEADER_TABLET_HEIGHT = 124;
export const SM_HEADER_MOBILE_HEIGHT = 112;
export const SM_COMPANY_CAROUSEL_HEIGHT = 56;

export const useSMHeaderHeight = (): number => {
  const { isMobile, isTablet, isSmDesktop } = useBreakpoints();
  const { header } = useLayoutConfig();

  return (
    header.height +
    (isMobile
      ? SM_HEADER_MOBILE_HEIGHT
      : isTablet || isSmDesktop
      ? SM_HEADER_TABLET_HEIGHT
      : SM_HEADER_DESKTOP_HEIGHT)
  );
};
