import { Codebooks } from './codebooks.types';

const Items = {
  // Upcoming
  upcoming: 'upcoming',
  // Active
  preview: 'preview',
  overview: 'overview',
  review: 'review',
  // Expired
  // null
} as const;

export const CbEventPhase = {
  code: Codebooks.EVENT_PHASE,
  items: Items,
};
