import { Codebooks } from './codebooks.types';

const Items = {
  Home: 'HOME',
  Mobile: 'MOBILE',
  Business: 'BUSINESS',
  Other: 'OTHER',
} as const;

export type CbPhoneTypeCode = typeof Items[keyof typeof Items];

export const CbPhoneType = {
  code: Codebooks.PHONE,
  items: Items,
};
