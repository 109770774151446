import { type ComponentProps } from 'react';

import { Icon } from '../../atoms/Icon';

import * as S from './IconCircle.styles';

type Props = ComponentProps<typeof Icon>;

export const IconCircle = (props: Props) => {
  return (
    <S.Circle>
      <Icon {...props} />
    </S.Circle>
  );
};
