import React, { type FC } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';

import { useUserContextStore } from '../../../auth/user/context';
import {
  SpousalInformationForm,
  SpousalInformationReadOnly,
  useSpousalInformationForm,
} from '../../../onboardingAndSubmissionSteps/SpousalInformation';
import { type PostOnboardingContext } from '../../PostOnboarding.types';
import { StepContainer } from '../../StepContainer';

const { StepState } = Wizard;

type Props = {
  stepProps: Wizard.StepProps;
};

export const SpousalInformationPostOnboarding: FC<Props> = ({ stepProps }) => {
  const user = useUserContextStore(s => s.user);

  const { account, onboardingStatus, stepState } = useWizardStore(
    (s: WizardStore<PostOnboardingContext>) => ({
      account: s.data.account,
      onboardingStatus: s.data.onboardingStatus,
      stepState: s.activeStepState,
    })
  );

  const { form, handleSubmit, data, isLoading, isUpdating } =
    useSpousalInformationForm(account, {
      // setting spouse email to user email causes the role to be removed,
      // cache invalidation causes a 403 error
      // -> do not invalidate + redirect to accounts page
      skipCacheInvalidation: spouseEmail => spouseEmail === user.email,
      onUpdate: spouseEmail => {
        Notification.success({
          message: 'You have updated your Stakeholder Spousal Information',
        });
        stepProps.handleStepComplete();
        if (spouseEmail === user.email) {
          // use `window.location` to reload the page
          window.location.replace(
            '/investor-workstation/accounts?allAccounts=1'
          );
        }
      },
      isCompleted:
        onboardingStatus.spouse_information_state?.code === 'completed',
    });

  return (
    <StepContainer
      stepProps={stepProps}
      isLoading={isLoading}
      isUpdating={isUpdating}
      handleSubmit={handleSubmit}
    >
      {(stepState === StepState.EDIT ||
        (stepState === StepState.FILLED && data?.has_spouse)) && (
        <Margin bottom="xl">
          <Alert
            message="Your spouse may be requested to sign documentation to complete your
        order"
          />
        </Margin>
      )}
      {stepState === StepState.EDIT && (
        <SpousalInformationForm
          form={form}
          account={account}
          handleValuesChange={() => stepProps.setFormHasUnsavedChanges(true)}
        />
      )}
      {stepState === StepState.FILLED && (
        <SpousalInformationReadOnly spouse={data} />
      )}
    </StepContainer>
  );
};
