import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type AccountIndex, CbAccountType } from '@npm/data-access';
import { useUserContextStore } from '@npm/features/auth/user/context';

import { EntitySelectionCard } from '../components/EntitySelectionCard/EntitySelectionCard';
import { OptInEntityColorVariant, OptInIcon, OptInStep } from '../OptIn.enums';

import * as S from './OptInEntitySelection.styles';

const entityType = CbAccountType.items.OrganizationAccount;
const individualType = CbAccountType.items.PersonAccount;

type Props = {
  value?: string | undefined;
  onChange?: (value: string | undefined) => void;
  eligibleEntities: AccountIndex;
  setOptInStep: (step: OptInStep) => void;
};

export const OptInEntitySelection = ({
  onChange,
  eligibleEntities,
  setOptInStep,
}: Props) => {
  const { isMobile, isTablet } = useBreakpoints();
  const { isDarkMode } = useDarkModeContext();

  const getFullName = useUserContextStore(store => store.getFullName);

  const hasEligibleIndividualEntities = eligibleEntities?.accounts?.some(
    account => account.type.code === individualType
  );
  const hasEligibleInstitutionalEntities = eligibleEntities?.accounts?.some(
    account => account.type.code === entityType
  );

  return (
    <>
      <S.TitleContainer>
        <Heading variant="h1">
          SecondMarket Now Available. NPM’s Trading Marketplace.
        </Heading>
        <Text colorVariant="tertiary">
          Create or link an account to gain access to Marketplace.
        </Text>
      </S.TitleContainer>
      <Margin bottom={'sm'} />

      <Flex direction={isMobile || isTablet ? 'column' : 'row'} gap={'lg'}>
        <EntitySelectionCard
          icon={OptInIcon.INDIVIDUAL}
          colorVariant={OptInEntityColorVariant.INDIVIDUAL}
          title="I’m an Individual / Employee"
          introText="A leader in a Company Liquidity. Now Available in a Marketplace."
          buttonText={
            hasEligibleIndividualEntities
              ? `Link as [${getFullName()}]`
              : 'Create Individual Account'
          }
          image={`/public/assets/optin/${
            isDarkMode ? 'dark' : 'light'
          }/ind-graphics.svg`}
          onClick={() => {
            onChange?.(individualType);
            setOptInStep(OptInStep.SIGNATURE);
          }}
        />
        <EntitySelectionCard
          icon={OptInIcon.INSTITUTION}
          colorVariant={OptInEntityColorVariant.INSTITUTION}
          title="I’m an Institutional Entity"
          introText="Connect your institution with market intelligence and discreet trading."
          buttonText={
            hasEligibleInstitutionalEntities
              ? 'Link Entity Account'
              : 'Create Entity Account'
          }
          image={`/public/assets/optin/${
            isDarkMode ? 'dark' : 'light'
          }/inst-graphics.svg`}
          onClick={() => {
            onChange?.(entityType);
            setOptInStep(OptInStep.SIGNATURE);
          }}
        />
      </Flex>
    </>
  );
};
