import React, { type FC } from 'react';
import { type DefaultTheme } from 'styled-components';

import { CypressDataIds } from '../../../constants';
import { Button } from '../../atoms/Button';
import { Text } from '../../atoms/Typography';

import * as S from './ReadOnly.styles';

type Props = {
  items: {
    label: string;
    value: string | React.ReactNode;
    displayRow?: boolean;
    maskInDatadog?: boolean;
  }[];
  onUpdate?: () => void;
  updateButtonText?: string;
  contentMarginBottom?: number | keyof DefaultTheme['spacing'];
};

export const ReadOnly: FC<Props> = ({
  items,
  onUpdate,
  updateButtonText = 'Update',
  contentMarginBottom,
}) => {
  return (
    <div>
      <S.Content $marginBottom={contentMarginBottom}>
        {items?.map(
          ({ label, value, displayRow, maskInDatadog }) =>
            displayRow !== false && (
              <S.DataItem key={label}>
                <Text size="sm">{label}</Text>
                <Text
                  size="sm"
                  colorVariant="primary"
                  as="div"
                  {...(maskInDatadog && { 'data-dd-privacy': 'mask' })}
                >
                  {value}
                </Text>
              </S.DataItem>
            )
        )}
      </S.Content>
      {onUpdate && (
        <Button
          variant="outline"
          onClick={onUpdate}
          blockOnMobile
          data-cy={CypressDataIds.ReadOnly.UpdateButton}
        >
          {updateButtonText}
        </Button>
      )}
    </div>
  );
};
