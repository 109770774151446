import styled from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';

export const HoldingDetailsWrapper = styled(Flex).attrs({
  align: 'center',
  gap: 'xs',
})`
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  background: ${({ theme }) => theme.color.general.layout.one};
  padding: ${({ theme }) => theme.spacing.xs}px;
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
`;

export const Seperator = styled.div`
  color: ${({ theme }) => theme.color.general.typography.tertiary};
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.general.typography.muted};
`;
