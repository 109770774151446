import { Pagination as AntPagination } from 'antd';
import styled, { css } from 'styled-components';

import { centerFlex, useBreakpoint } from '../../../utils/style';
import { SmallTextStyles, XXSmallTextStyles } from '../Typography';

const PAGINATION_ELEMENT_SIZE = 32;

export const paginationCSS = css`
  :not(.ant-pagination-disabled) {
    .ant-pagination-jump-next {
      .ant-pagination-item-link,
      .ant-pagination-item-container,
      .ant-pagination-item-link-icon {
        width: 100%;
        height: 100%;
      }

      .ant-pagination-item-link-icon {
        ${centerFlex}
      }

      .ant-pagination-item-ellipsis {
        color: ${({ theme }) => theme.color.general.typography.primary};
      }
    }

    .ant-pagination-item,
    .ant-pagination-item-link,
    .ant-select > .ant-select-selector,
    .ant-select-arrow {
      a,
      .ant-select-selection-item,
      .anticon,
      .ant-pagination-item-ellipsis {
        ${centerFlex}
        ${SmallTextStyles}
        height: 100%;
        color: ${({ theme }) => theme.color.general.typography.primary};
      }

      svg {
        ${centerFlex}
        ${XXSmallTextStyles}
      }

      border-color: ${({ theme }) => theme.color.general.borders.secondary};
      background: ${({ theme }) => theme.color.general.layout.two};
      font-family: inherit;
    }

    a.ant-pagination-item-link {
      ${centerFlex};
      height: 100%;
      background: none;
    }

    .ant-pagination-item-active {
      a {
        color: ${({ theme }) => theme.color.general.typography.primary};
      }

      border: 2px solid ${({ theme }) => theme.color.info.typography.primary};
      background: ${({ theme }) => theme.color.general.layout.one};
    }
  }

  &.ant-pagination.ant-pagination-mini {
    .ant-pagination-item,
    .ant-pagination-options,
    .ant-pagination-prev,
    .ant-pagination-next {
      height: ${PAGINATION_ELEMENT_SIZE}px;
      width: ${PAGINATION_ELEMENT_SIZE}px;
      margin-right: ${({ theme }) => theme.spacing.sm}px;
    }

    .ant-select-selector,
    .ant-pagination-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-select-selector {
      height: ${PAGINATION_ELEMENT_SIZE}px;
      padding: 0 ${({ theme }) => theme.spacing.md}px;
    }
  }

  .ant-pagination-item,
  .ant-pagination-item-link,
  .ant-pagination-item-active,
  .ant-select > .ant-select-selector {
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  }

  &.ant-pagination-disabled {
    .ant-pagination-item,
    .ant-pagination-item-link,
    .ant-select > .ant-select-selector {
      a,
      .ant-select-selection-item,
      .anticon,
      .ant-pagination-item-ellipsis {
        color: ${({ theme }) => theme.color.general.borders.secondary};
      }

      border-color: ${({ theme }) => theme.color.general.borders.secondary};
      background: ${({ theme }) => theme.color.general.layout.zero};
    }
  }

  .ant-pagination-next {
    margin-right: ${({ theme }) => theme.spacing.md}px;
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    flex-flow: wrap;

    &.ant-pagination.ant-pagination-mini .ant-pagination-options {
      margin: ${({ theme }) => theme.spacing.md}px 0 0;
      width: 100%;
    }
  `}

  .ant-pagination-options {
    display: inline-block;
    margin-left: 0;
  }
`;

export const StyledPagination = styled(AntPagination)`
  ${paginationCSS};
`;
