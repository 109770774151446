import React, { type ComponentProps } from 'react';

import { GetStarted as BaseGetStarted } from '@npm/core/ui/components/molecules/GetStarted';

import { DisabledTextForBroker } from '../../DisabledTextForBroker';

export const EntityAccreditationGetStarted = ({
  onGetStarted,
  isActingAsBroker,
}: ComponentProps<typeof BaseGetStarted> & {
  isActingAsBroker?: boolean;
}) => {
  return (
    <BaseGetStarted
      title="Accredited Investor Status"
      onGetStarted={!isActingAsBroker ? onGetStarted : null}
      content={
        !isActingAsBroker ? (
          <>
            Enter the requested information to establish this account’s status
            as an accredited investor within the meaning of Rule 501 of
            Regulation D under the Securities Act of 1933, as amended.
          </>
        ) : (
          <DisabledTextForBroker />
        )
      }
    />
  );
};
