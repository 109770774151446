import React, { useState } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useConfirmOnLeave } from '@npm/core/ui/components/molecules/Link';
import { DiscardModal } from '@npm/core/ui/components/organisms/DiscardModal';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { type Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import {
  type WizardStore,
  StepState,
  WizardStoreActionTypes,
} from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { CypressDataIds } from '@npm/core/ui/constants';

import {
  AddBankAccountCta,
  BankAccountInformationForm,
  BankAccountInformationReadOnly,
  useBankAccountInformationForm,
} from '../../../onboardingAndSubmissionSteps/BankAccountInformation';
import { type PostOnboardingContext } from '../../PostOnboarding.types';
import { StepContainer } from '../../StepContainer';

type Props = {
  stepProps: Wizard.StepProps;
};

export const BankAccountInformationPostOnboarding = ({ stepProps }: Props) => {
  const { setHasUnsavedChanges, onCloseAttempt, discardModalProps } =
    useConfirmOnLeave();
  const { account, stepState, dispatch } = useWizardStore(
    (s: WizardStore<PostOnboardingContext>) => ({
      account: s.data.account,
      stepState: s.activeStepState,
      dispatch: s.dispatch,
    })
  );
  const [isFormOpen, setIsFormOpen] = useState(false);

  const { form, handleSubmit, data, isLoading, isUpdating } =
    useBankAccountInformationForm(account, {
      onUpdate: () => {
        setHasUnsavedChanges(false);
        dispatch({
          type: WizardStoreActionTypes.UPDATE_ACTIVE_STEP_STATE,
          payload: StepState.FILLED,
        });
        setIsFormOpen(false);
        Notification.success({
          message: 'You have updated your Stakeholder Bank Account Information',
        });
        stepProps.handleStepComplete();
      },
    });

  const handleCloseConfirm = () => {
    setHasUnsavedChanges(false);
    setIsFormOpen(false);
  };

  return (
    <StepContainer
      stepProps={stepProps}
      handleEdit={() => setIsFormOpen(true)}
      isLoading={isLoading}
      isUpdating={isUpdating}
      updateButtonProps={{
        children: 'Add new bank account',
        icon: 'plus',
        hideButton: data?.pagination?.total_records === 0,
      }}
      data-cy={CypressDataIds.PostOnboarding.BankAccountInformation.Step}
    >
      <Margin bottom="lg">
        <Alert message="Please refer to the relevant transaction documents for information about what payment instructions are required." />
      </Margin>
      {stepState === StepState.EDIT && (
        <AddBankAccountCta onClick={() => setIsFormOpen(true)} />
      )}
      {stepState === StepState.FILLED && (
        <BankAccountInformationReadOnly data={data?.payment_informations} />
      )}
      <Drawer
        title="Add Bank Account"
        open={isFormOpen}
        onSubmit={handleSubmit}
        buttonText="Submit"
        onClose={() => onCloseAttempt(handleCloseConfirm)}
        isDisabled={isLoading || isUpdating}
        buttonLoading={isUpdating}
        isLoading={isLoading}
        isFullHeight
        buttonDataCy={CypressDataIds.Drawer.SubmitButton}
      >
        <BankAccountInformationForm
          form={form}
          account={account}
          handleValuesChange={() => setHasUnsavedChanges(true)}
        />
      </Drawer>
      <DiscardModal {...discardModalProps}>
        You have not saved your changes. <br /> Please make sure you have added
        a bank account before proceeding.
      </DiscardModal>
    </StepContainer>
  );
};
