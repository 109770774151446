import styled, { css } from 'styled-components';

import { BUTTON_ACTIVE_SHADOW } from '@npm/core/ui/components/atoms/Button';
import { Tabs } from '@npm/core/ui/components/atoms/Tabs';
import { useBreakpoint } from '@npm/core/ui/utils/style';

import { WatchlistButton } from '../../../../../watchlist';

export const borderWithShadowCSS = css`
  box-shadow: 0 0 0 2px ${({ theme }) => theme.color.success.borders.primary},
    ${BUTTON_ACTIVE_SHADOW};
`;
export const WatchlistButtonStyled = styled(WatchlistButton)<{
  $isActive?: boolean;
}>`
  ${({ theme, $isActive }) => css`
    && {
      ${$isActive && borderWithShadowCSS};
      ${useBreakpoint(theme).mobile`${css`
        // exception
        height: 48px;
        width: 48px;
      `}`}
    }
  `}
`;

export const DropdownSelectContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}px;

  && > .ant-btn {
    min-width: auto;
  }
`;

export const FilterTabs = styled(Tabs)`
  position: relative;
  margin-left: -${({ theme }) => theme.spacing.sm}px;

  &&
    .ant-tabs-nav
    .ant-tabs-tab
    .ant-tabs-tab-btn:not(
      .ant-btn-disabled,
      .ant-btn-loading,
      :disabled
    ):hover {
    background-color: transparent;
  }
`;
