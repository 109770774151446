import create from 'zustand';

interface HelmetStore {
  extraValues: { [id: string]: string };
  pageTitle: string | null;

  setExtraValues(val: { [id: string]: string });

  setPageTitle: (val: string | null) => void;
}

export const useHelmetStore = create<HelmetStore>(set => ({
  extraValues: {},
  pageTitle: null,
  setExtraValues: val => {
    set({ extraValues: val || {} });
  },
  setPageTitle: val => {
    set({ pageTitle: val });
  },
}));
