import { type pathMetadataList } from '@npm/core/ui/services/pathMetadata';

type PathMetadata = typeof issuerProgramsMetadata;

export const registerIssuerProgramsMetadata = (metadata: PathMetadata) => {
  issuerProgramsMetadata.forEach(m => {
    metadata.push(m);
  });
};

export const issuerProgramsMetadata = [
  {
    pathRegex:
      /.*issuer-workstation\/programs\/active-programs\/\d*.*tab=dashboard.*/,
    metadata: {
      screenId: 'NFE-582',
      screenName: 'Program - Dashboard',
    },
  },
  {
    pathRegex:
      /.*issuer-workstation\/programs\/active-programs\/\d*.*tab=reports.*/,
    metadata: {
      screenId: 'NFE-583',
      screenName: 'Program - Reports',
    },
  },
  {
    pathRegex:
      /.*issuer-workstation\/programs\/active-programs\/\d*.*tab=permissioned_accounts.*/,
    metadata: {
      screenId: 'NFE-584',
      screenName: 'Program - Permissioned Accounts',
    },
  },
  {
    pathRegex:
      /.*issuer-workstation\/programs\/active-programs\/\d*.*tab=submissions.*/,
    metadata: {
      screenId: 'NFE-586',
      screenName: 'Program - Submissions',
    },
  },
  {
    pathRegex:
      /.*issuer-workstation\/programs\/active-programs\/\d*\/submissions\/\d*.*tab=overview.*/,
    metadata: {
      screenId: 'NFE-587',
      screenName: 'Program - Submissions - Overview',
    },
  },
  {
    pathRegex:
      /.*issuer-workstation\/programs\/active-programs\/\d*.*tab=orders.*/,
    metadata: {
      screenId: 'NFE-591',
      screenName: 'Program - Orders',
    },
  },
  {
    pathRegex:
      /.*issuer-workstation\/programs\/active-programs\/\d*.*tab=matches.*/,
    metadata: {
      screenId: 'NFE-593',
      screenName: 'Program - Matches',
    },
  },
  {
    pathRegex:
      /.*issuer-workstation\/programs\/active-programs\/\d*.*tab=documents.*/,
    metadata: {
      screenId: 'NFE-595',
      screenName: 'Program - Documents',
    },
  },
  {
    pathRegex:
      /.*issuer-workstation\/programs\/active-programs\/\d*\/*.*?tab=messages.*/,
    metadata: {
      screenId: 'NFE-596',
      screenName: 'Program - Messages',
    },
  },
  {
    pathRegex:
      /.*issuer-workstation\/programs\/active-programs\/\d*\/*.*?tab=admins.*/,
    metadata: {
      screenId: 'NFE-597',
      screenName: 'Program - Admins',
    },
  },
] as typeof pathMetadataList;
