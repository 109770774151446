export type WhitelistedAnswerCodes =
  | 'org_type'
  | 'qualified_purchaser'
  | 'qualified_institutional_buyer'
  | 'qualified_client';

export const whitelistedAnswerCodes = new Set<WhitelistedAnswerCodes>([
  'org_type',
  'qualified_purchaser',
  'qualified_institutional_buyer',
  'qualified_client',
]);
