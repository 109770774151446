import { type Dispatch } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { useWatchlistDestroy } from '@npm/data-access';

export type IssuerEntity = {
  issuerEntityId: number;
  accountId: number;
  companyName?: string;
};

type Params = IssuerEntity & {
  onComplete: Dispatch<React.SetStateAction<'add' | 'edit' | 'delete'>>;
};

export const useDeleteConfirm = ({
  onComplete,
  issuerEntityId,
  accountId,
  companyName,
}: Params) => {
  const { withShowApiErrorMiddleware } = useAlerts();
  const queryClient = useQueryClient();
  const { execute } = useWatchlistDestroy();

  const onDelete = async () => {
    try {
      await withShowApiErrorMiddleware(execute)(
        {
          issuerEntityId,
          watchlistAccountId: accountId,
        },
        {
          onComplete: () => {
            queryClient.invalidateQueries({
              queryKey: ['IssuerEntityIndex'],
            });
            onComplete(null);
          },
        }
      );
      Notification.success({
        message: `${companyName} was removed from your watchlist.`,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return { onDelete };
};
