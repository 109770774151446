import React from 'react';

import { ListItem } from './ListItem';

interface Props {
  listItems: string[];
}

export const CheckList = ({ listItems }: Props) => {
  return (
    <div>
      {listItems.map((listItem, index) => (
        <ListItem title={listItem} key={`${index}-${listItem}`} />
      ))}
    </div>
  );
};
