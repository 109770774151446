import { type ComponentProps } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import { useUnlockPremium } from '../../../auth/user/components';
import { useBasicPageLayoutConfig } from '../components/BasicPageLayout/BasicPageLayout.hooks';
import { BasicPageMainCard } from '../components/BasicPageLayout/BasicPageMainCard';

const labelProps: ComponentProps<typeof Label> = {
  variant: 'info',
  icon: <Icon name="check" />,
};

const mainCard = {
  imagePrefix: 'source-documents-main-card-basic',
  title: 'Comprehensive Source Documents',
  description: (
    <Flex direction="column" gap="lg">
      <Text size="sm">
        Premium Data offers access to over 170,000 federal and state filings,
        including:
      </Text>
      <Flex direction="column" gap="xs">
        <Flex direction="row" gap="sm" $wrap>
          <Label {...labelProps}>Certificates of Incorporation (COIs)</Label>
          <Label {...labelProps}>Form Ds</Label>
        </Flex>
        <Flex direction="row" gap="sm" $wrap>
          <Label {...labelProps}>Annual Reports</Label>
          <Label {...labelProps}>LOENs</Label>
          <Label {...labelProps}>S-1 Filings</Label>
          <Label {...labelProps}>And More...</Label>
        </Flex>
      </Flex>
      <Text size="sm">
        Get detailed information with the Source Documents library. Streamline
        your investment process, saving time and effort by accessing all
        critical documents in one place. Empower your research team with
        reliable data and uncover the insights you need to stay ahead in the
        private market.
      </Text>
    </Flex>
  ),
};

export const SourceDocumentsBasicInner = () => {
  const { isMobile, isDesktop, isTablet, imagesSuffix, layoutConfig } =
    useBasicPageLayoutConfig();
  const requestPremiumMembership = useUnlockPremium();
  const config = {
    ...layoutConfig,
    ...(isMobile && { mainBgImageSize: '437px 230px' }),
    ...(isDesktop && { mainBgImagePosition: '500px 50%' }),
    ...(isTablet && { mainBgImagePosition: '70px 50%' }),
    mainCardShowUpgradeButton: false,
  };

  return (
    <BasicPageMainCard
      {...mainCard}
      imagesSuffix={imagesSuffix}
      requestPremiumMembership={requestPremiumMembership}
      layoutConfig={config}
    />
  );
};
