import styled from 'styled-components';

import { InputFormItem as FormItemBase } from '@npm/core/ui/components/atoms/FormItem';

export const FormItem = styled(FormItemBase)`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`;
