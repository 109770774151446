import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import {
  type OptInEntityColorVariant,
  type OptInIcon,
} from '../../OptIn.enums';

import * as S from './EntitySelectionCard.styles';

const MAX_MOBILE_WIDTH = 222;

interface Props {
  icon: OptInIcon;
  colorVariant: OptInEntityColorVariant;
  title: string;
  introText: string;
  buttonText: string;
  onClick: () => void;
  image: string;
}

export const EntitySelectionCard = ({
  icon,
  colorVariant,
  title,
  introText,
  buttonText,
  image,
  onClick,
}: Props) => {
  const { isDarkMode } = useDarkModeContext();
  const { isMobile } = useBreakpoints();

  return (
    <S.Card $backgroundVariant={colorVariant} $isDarkMode={isDarkMode}>
      <Margin bottom="xxl" />
      <Heading variant="h0" as="span" color={colorVariant} marginBottom="md">
        {title}
      </Heading>
      <Heading variant="h3" as="span" marginBottom="md">
        {introText}
      </Heading>
      <Flex justify="center">
        <Button
          title={buttonText}
          onClick={onClick}
          variant="default"
          color={colorVariant}
          size="lg"
          icon={<Icon name={icon} size="sm" />}
        >
          {buttonText}
        </Button>
      </Flex>
      <Margin bottom="sm" />
      <Text size="xs" colorVariant="tertiary">
        Click here to opt-in for access
      </Text>
      <S.ImageContainer justify="center">
        <img alt="" src={image} width={isMobile ? MAX_MOBILE_WIDTH : 'auto'} />
      </S.ImageContainer>
    </S.Card>
  );
};
