import { Select as AntSelect } from 'antd';
import styled, { css } from 'styled-components';

import { centerFlex, useBreakpoint } from '../../../utils/style';
import { InputTextStyles, SmallTextStyles } from '../Typography';

import {
  HeightStyle,
  INPUT_HEIGHT,
  INPUT_MOBILE_HEIGHT,
} from '../Input/styles';
/* Dropdown is rendered as an absolute-positioned element elsewehere */
export const StyledDropdown = styled.div`
  > .rc-virtual-list {
    width: 100%;
    background-color: ${({ theme }) => theme.color.general.layout.two};

    .ant-select-item {
      color: ${({ theme }) => theme.color.general.typography.primary};

      min-height: 40px; // height of the option, not actual input
      &.ant-select-item-option-active {
        background-color: ${({ theme }) => theme.color.info.borders.secondary};
      }
    }

    .ant-select-item-option-disabled {
      color: ${({ theme }) => theme.color.general.typography.secondary};
    }

    .ant-select-item-option-selected .ant-select-item-option-state {
      color: ${({ theme }) => theme.color.info.typography.primary};
      line-height: 16px;
    }

    .ant-select-item-option-content {
      ${InputTextStyles};
    }

    & .ant-empty-description {
      color: ${({ theme }) => theme.color.general.typography.primary};
    }
  }
`;
const multipleAndTagModeStyles = css<{ showSearch?: boolean }>`
  // smaller padding because of tags
  .ant-select-selector {
    padding: ${({ theme }) => `6px ${theme.spacing.lg / 2}px `};
  }

  .ant-select-selection-overflow {
    max-width: calc(100% - ${({ theme }) => theme.spacing.lg}px);
  }

  .ant-select-selection-overflow-item-suffix {
    flex: ${({ showSearch }) => (showSearch ? '1 0 50px' : '0 1 1px')};

    .ant-select-selection-search {
      /* margin-inline-start: -2px; */
      width: 100% !important;

      .ant-select-selection-search-input {
        font-family: inherit;
        cursor: text;
      }
    }
  }

  .ant-select-selection-item {
    background-color: ${({ theme }) =>
      theme.color.info.backgrounds.primaryDefault};
    color: ${({ theme }) => theme.color.grey_0};
    height: 24px;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    padding-inline-start: ${({ theme }) => theme.spacing.sm}px;
    padding-inline-end: ${({ theme }) => theme.spacing.sm}px;
    border: 1px solid
      ${({ theme }) => theme.color.info.backgrounds.primaryDefault};
    margin-right: ${({ theme }) => theme.spacing.xs}px;
    display: inline-flex;
    align-items: center;

    .ant-select-selection-item-content {
      ${SmallTextStyles};
      color: ${({ theme }) => theme.color.grey_0};
    }

    .ant-select-selection-item-remove {
      color: ${({ theme }) => theme.color.grey_0};
      margin-left: ${({ theme }) => theme.spacing.xs}px;
    }
  }

  .ant-select-selection-overflow-item:first-child {
    .ant-select-selection-search {
      margin-inline-start: -2px !important;
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${({ theme }) =>
      theme.color.info.backgrounds.secondaryDefault};
  }
`;

export const StyledSelect = styled(AntSelect)<{
  $isSearchVariant: boolean;
  $readOnly?: boolean;
  $showDropdownBelowInput?: boolean;
  $isMobile?: boolean;
}>`
  ${InputTextStyles};
  && {
    // Fix for Chrome autofill background color
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
      ${HeightStyle}
    }

    .ant-select-dropdown {
      z-index: ${({ theme }) => theme.zIndex.dropdown};
      box-shadow: ${({ theme }) => theme.shadow.level2};
    }

    .ant-select-selector {
      display: flex;
      align-items: center;
      border-radius: ${({ theme }) => theme.borderRadius.sm}px;
      border-color: ${({ theme }) => theme.color.general.borders.secondary};
      background-color: ${({ theme }) => theme.color.general.layout.two};
      padding: ${({ theme }) =>
        `${theme.spacing.sm}px ${theme.spacing.lg / 2}px `};
      color: ${({ theme }) => theme.color.general.typography.primary};
      ${HeightStyle}
    }

    .ant-select-arrow {
      transition: transform 0.2s ease-in-out;
      color: ${({ theme }) => theme.color.general.typography.secondary};
      right: ${({ theme }) => theme.spacing.lg / 2}px;
      width: 24px;
      height: 14px;
    }

    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      input {
      height: ${INPUT_HEIGHT}px;
    }

    .ant-select-selection-placeholder {
      color: ${({ theme }) => theme.color.general.typography.secondary};
    }

    /** Selected item */

    .ant-select-selection-item {
      color: ${({ theme }) => theme.color.general.typography.primary};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ant-select-selection-item:has(> div) {
      // If the label is not just text e.g. icon + text in CompanySearch
      display: flex;
    }

    /** Arrow when open */

    &.ant-select-open .ant-select-arrow {
      transform: rotate(180deg);
      color: ${({ theme }) => theme.color.info.borders.primary};
    }

    &.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      // 2px -> border top and bottom
      height: calc(${INPUT_HEIGHT}px - 2px);
      ${({ theme }) => useBreakpoint(theme).mobile`
          height: calc(${INPUT_MOBILE_HEIGHT}px - 2px);
      `}
    }

    /** Hover */

    &.ant-select-open:not(.ant-select-disabled),
    &:not(.ant-select-disabled):hover {
      .ant-select-selector {
        border-color: ${({ theme }) => theme.color.info.borders.primary};
      }
    }

    /** Focused */

    &.ant-select-open:not(.ant-select-disabled),
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      border-color: ${({ theme }) => theme.color.info.borders.primary};
      background-color: ${({ theme }) => theme.color.general.layout.one};
      box-shadow: ${({ theme }) => theme.shadow.level1},
        0 0 0 1px ${({ theme }) => theme.color.info.borders.primary};
    }

    /* Selector container: default state */

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      ${HeightStyle}
    }

    /* Selector container: error state */

    &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
        .ant-select-customize-input
      ) {
      .ant-select-selector {
        background-color: ${({ theme }) => theme.color.general.layout.two};
        border-color: ${({ theme }) =>
          theme.color.error.typography.primary} !important;
      }

      &.ant-select-open > .ant-select-selector,
      &.ant-select-focused > .ant-select-selector {
        border-color: ${({ theme }) => theme.color.error.typography.primary};
        box-shadow: ${({ theme }) => theme.shadow.level1},
          0 0 0 1px ${({ theme }) => theme.color.error.typography.primary};
      }
    }

    /* Selector container: disabled state */

    &.ant-select-disabled.ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-color: ${({ theme }) => theme.color.general.borders.primary};
        background-color: ${({ theme }) => theme.color.general.layout.two};
      }

      .ant-select-selection-item {
        color: ${({ theme }) => theme.color.general.typography.muted};
      }
    }

    :is(.ant-select.ant-select-disabled) .ant-select-selection-placeholder {
      color: ${({ theme }) => theme.color.general.typography.muted};
    }

    :is(.ant-select.ant-select-disabled) .ant-select-arrow {
      color: ${({ theme }) => theme.color.general.typography.muted};
    }

    /* Read only state */
    ${({ $readOnly, theme }) =>
      $readOnly &&
      css`
        &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background-color: ${theme.color.general.backgrounds.muted};
          border-color: ${theme.color.general.borders.secondary};
          color: ${theme.color.general.typography.secondary};
        }

        .ant-select-selection-item {
          color: ${theme.color.general.typography.secondary};
        }

        &.ant-select-focused .ant-select-selector {
          background-color: ${theme.color.general.backgrounds.muted} !important;
          border-color: ${theme.color.general.borders.secondary} !important;
        }

        .ant-select-selection-placeholder {
          color: ${theme.color.general.typography.secondary};
        }

        .ant-select-arrow {
          color: ${theme.color.general.typography.muted};
        }
      `};

    /* Clear icon */

    .ant-select-clear {
      color: ${({ theme }) => theme.color.general.typography.secondary};
      width: 40px;
      height: 40px;
      margin-top: -20px;
      margin-right: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      background-color: transparent;
      svg {
        background-color: ${({ theme }) => theme.color.general.layout.two};
        border-radius: 20px;
      }
    }

    ${({ $showDropdownBelowInput, $isMobile }) =>
      $showDropdownBelowInput &&
      css`
        .ant-select-dropdown {
          top: calc(
            100% + ${$isMobile ? INPUT_MOBILE_HEIGHT : INPUT_HEIGHT}px
          ) !important;
          overflow: hidden;
        }
      `}

    ${({ mode }) => mode && multipleAndTagModeStyles};

    & .ant-empty-description {
      color: ${({ theme }) => theme.color.general.typography.primary};
    }
  }

  /* async variant css */
  ${({ $isSearchVariant }) =>
    $isSearchVariant &&
    `
      &&.ant-select-open .ant-select-arrow {
        transform: unset;
      }

      && .ant-select-arrow {
        width: 19px;
      }

      && .ant-select-clear {
        opacity: 1;
      }
      && .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-right: 24px; // so there is gap between content and suffix icon
      }
  `}
`;

export const LoadingWrap = styled.div`
  ${centerFlex};
  height: 64px;
`;

export const ExtraContainer = styled.div.attrs({
  className: 'ant-select-item',
})`
  display: flex;
  cursor: pointer;

  :hover {
    svg,
    p {
      color: ${({ theme }) => theme.color.info.backgrounds.primaryHover};
    }
  }

  svg {
    color: ${({ theme }) => theme.color.info.typography.primary};
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }

  p {
    padding-top: 3px; // fine-tuning
  }
`;
