import Lottie from 'lottie-react';
import styled from 'styled-components';

import animationData from './assets/background.json';

export const Background = styled(Lottie).attrs({
  animationData,
  loop: true,
})`
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(35%);
`;
