import React, { type ComponentProps } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { GetStarted as BaseGetStarted } from '@npm/core/ui/components/molecules/GetStarted';

export const SpousalInformationGetStartedContent = () => {
  return (
    <Text colorVariant="secondary">
      Your spouse may be requested to sign documentation to complete your order.
    </Text>
  );
};

export const SpousalInformationGetStarted = ({
  onGetStarted,
}: ComponentProps<typeof BaseGetStarted>) => {
  return (
    <BaseGetStarted
      onGetStarted={onGetStarted}
      title="Spousal Information"
      content={<SpousalInformationGetStartedContent />}
    />
  );
};
