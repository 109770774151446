/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocusignSigning } from '../model';
// @ts-ignore
import { MarketplaceAgreementSigningCreateRequestContract } from '../model';
/**
 * MarketplaceAgreementSigningApi - axios parameter creator
 * @export
 */
export const MarketplaceAgreementSigningApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates a docusign envelope and returns the url
         * @param {MarketplaceAgreementSigningCreateRequestContract} marketplaceAgreementSigningCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceAgreementSigning: async (marketplaceAgreementSigningCreateRequestContract: MarketplaceAgreementSigningCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketplaceAgreementSigningCreateRequestContract' is not null or undefined
            assertParamExists('marketplaceAgreementSigning', 'marketplaceAgreementSigningCreateRequestContract', marketplaceAgreementSigningCreateRequestContract)
            const localVarPath = changeUrl(`/api/marketplace-agreement-signing`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketplaceAgreementSigningCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a result from the callback with the signing_token
         * @param {number} onboardingAgreementId 
         * @param {number} accountId 
         * @param {string} envelopeId 
         * @param {string} [event] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceAgreementSigningComplete: async (onboardingAgreementId: number, accountId: number, envelopeId: string, event?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'onboardingAgreementId' is not null or undefined
            assertParamExists('marketplaceAgreementSigningComplete', 'onboardingAgreementId', onboardingAgreementId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('marketplaceAgreementSigningComplete', 'accountId', accountId)
            // verify required parameter 'envelopeId' is not null or undefined
            assertParamExists('marketplaceAgreementSigningComplete', 'envelopeId', envelopeId)
            const localVarPath = changeUrl(`/api/marketplace-agreement-signing/{onboarding_agreement_id}`)
                .replace(`{${"onboarding_agreement_id"}}`, encodeURIComponent(String(onboardingAgreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (envelopeId !== undefined) {
                localVarQueryParameter['envelope_id'] = envelopeId;
            }

            if (event !== undefined) {
                localVarQueryParameter['event'] = event;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketplaceAgreementSigningApi - functional programming interface
 * @export
 */
export const MarketplaceAgreementSigningApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketplaceAgreementSigningApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates a docusign envelope and returns the url
         * @param {MarketplaceAgreementSigningCreateRequestContract} marketplaceAgreementSigningCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketplaceAgreementSigning(marketplaceAgreementSigningCreateRequestContract: MarketplaceAgreementSigningCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocusignSigning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketplaceAgreementSigning(marketplaceAgreementSigningCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a result from the callback with the signing_token
         * @param {number} onboardingAgreementId 
         * @param {number} accountId 
         * @param {string} envelopeId 
         * @param {string} [event] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketplaceAgreementSigningComplete(onboardingAgreementId: number, accountId: number, envelopeId: string, event?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketplaceAgreementSigningComplete(onboardingAgreementId, accountId, envelopeId, event, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketplaceAgreementSigningApi - factory interface
 * @export
 */
export const MarketplaceAgreementSigningApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketplaceAgreementSigningApiFp(configuration)
    return {
        /**
         * 
         * @summary creates a docusign envelope and returns the url
         * @param {MarketplaceAgreementSigningCreateRequestContract} marketplaceAgreementSigningCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceAgreementSigning(marketplaceAgreementSigningCreateRequestContract: MarketplaceAgreementSigningCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<DocusignSigning> {
            return localVarFp.marketplaceAgreementSigning(marketplaceAgreementSigningCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a result from the callback with the signing_token
         * @param {number} onboardingAgreementId 
         * @param {number} accountId 
         * @param {string} envelopeId 
         * @param {string} [event] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceAgreementSigningComplete(onboardingAgreementId: number, accountId: number, envelopeId: string, event?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.marketplaceAgreementSigningComplete(onboardingAgreementId, accountId, envelopeId, event, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for marketplaceAgreementSigning operation in MarketplaceAgreementSigningApi.
 * @export
 * @interface MarketplaceAgreementSigningApiMarketplaceAgreementSigningRequest
 */
export interface MarketplaceAgreementSigningApiMarketplaceAgreementSigningRequest {
    /**
     * 
     * @type {MarketplaceAgreementSigningCreateRequestContract}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigning
     */
    readonly marketplaceAgreementSigningCreateRequestContract: MarketplaceAgreementSigningCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigning
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigning
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigning
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigning
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigning
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigning
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for marketplaceAgreementSigningComplete operation in MarketplaceAgreementSigningApi.
 * @export
 * @interface MarketplaceAgreementSigningApiMarketplaceAgreementSigningCompleteRequest
 */
export interface MarketplaceAgreementSigningApiMarketplaceAgreementSigningCompleteRequest {
    /**
     * 
     * @type {number}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigningComplete
     */
    readonly onboardingAgreementId: number

    /**
     * 
     * @type {number}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigningComplete
     */
    readonly accountId: number

    /**
     * 
     * @type {string}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigningComplete
     */
    readonly envelopeId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigningComplete
     */
    readonly event?: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigningComplete
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigningComplete
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigningComplete
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigningComplete
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigningComplete
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceAgreementSigningApiMarketplaceAgreementSigningComplete
     */
    readonly xUserRoleId?: string
}

/**
 * MarketplaceAgreementSigningApi - object-oriented interface
 * @export
 * @class MarketplaceAgreementSigningApi
 * @extends {BaseAPI}
 */
export class MarketplaceAgreementSigningApi extends BaseAPI {
    /**
     * 
     * @summary creates a docusign envelope and returns the url
     * @param {MarketplaceAgreementSigningApiMarketplaceAgreementSigningRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceAgreementSigningApi
     */
    public marketplaceAgreementSigning(requestParameters: MarketplaceAgreementSigningApiMarketplaceAgreementSigningRequest, options?: AxiosRequestConfig) {
        return MarketplaceAgreementSigningApiFp(this.configuration).marketplaceAgreementSigning(requestParameters.marketplaceAgreementSigningCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a result from the callback with the signing_token
     * @param {MarketplaceAgreementSigningApiMarketplaceAgreementSigningCompleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceAgreementSigningApi
     */
    public marketplaceAgreementSigningComplete(requestParameters: MarketplaceAgreementSigningApiMarketplaceAgreementSigningCompleteRequest, options?: AxiosRequestConfig) {
        return MarketplaceAgreementSigningApiFp(this.configuration).marketplaceAgreementSigningComplete(requestParameters.onboardingAgreementId, requestParameters.accountId, requestParameters.envelopeId, requestParameters.event, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type MarketplaceAgreementSigningErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 423;
        data: DocusignSigning;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MarketplaceAgreementSigningErrorCodes = [
    401,
    423,
];

export type MarketplaceAgreementSigningCompleteErrorTypes =
    | {
        status: 302;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MarketplaceAgreementSigningCompleteErrorCodes = [
    302,
];


