import styled from 'styled-components';

export const BulletPointsTitle = styled.b`
  color: ${({ theme }) => theme.color.general.typography.negative};
`;

export const BulletPointsList = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: ${({ theme }) => theme.spacing.md}px;
`;
export const BulletPointsListItem = styled.li``;
