import styled from 'styled-components';

export const IconBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  background-color: ${({ theme }) =>
    theme.color.info.backgrounds.secondaryHover};

  svg {
    color: ${({ theme }) => theme.color.info.typography.primary};
  }
`;
