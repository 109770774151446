import { createContext, useContext } from 'react';

type TextPriceContextType = {
  currencyAsSymbol: boolean;
};

const TextPriceContext = createContext<TextPriceContextType>({
  currencyAsSymbol: false,
});

export const useTextPriceContext = () => {
  return useContext(TextPriceContext);
};

export const TextPriceContextProvider = TextPriceContext.Provider;
