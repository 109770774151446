import React from 'react';

import { Text } from '../../../../atoms/Typography';

import * as S from './ActionItemsCount.styles';
import { type ActionItemsCountSize } from './ActionItemsCount.styles';

type Props = {
  count: number | undefined;
  size?: ActionItemsCountSize;
};

export const ActionItemsCount = ({ count, size = 'sm' }: Props) => {
  if (!count) {
    return null;
  }

  return (
    <S.CountWrapper $size={size}>
      <Text size="xs" weight="bold" color="general" colorVariant="negative">
        {count >= 100 ? '99+' : count}
      </Text>
    </S.CountWrapper>
  );
};
