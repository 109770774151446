import React, { useMemo, useState } from 'react';
import { get } from 'lodash';
import { type CSSProperties } from 'styled-components';

import { CypressDataIds } from '../../../constants';
import { type Button } from '../../atoms/Button';
import { Icon } from '../../atoms/Icon';
import { NoDataArea } from '../../atoms/NoDataArea';
import { Tooltip } from '../../atoms/Tooltip';
import { Text } from '../../atoms/Typography';
import { type ColumnType } from '../Table';

import * as S from './TableVertical.styles';

export type TableVerticalRowType<RecordType = unknown> = Pick<
  ColumnType<RecordType>,
  'key' | 'title' | 'render'
> & {
  titleInfo?: React.ReactNode | string;
  hidden?: ((value: unknown, data: RecordType) => boolean) | boolean;
  collapsed?: ((data?: RecordType, index?: number) => boolean) | boolean;
  // configuration to move rows to the up or bottom (positive/negative number)
  priority?: number;
  topBorder?: boolean;
  flexDirection?: 'row' | 'column';
  // Determines the display of the TitleContainer element. Defaults to display: flex
  titleContainerDisplay?: 'inline' | 'flex';
  // The as prop of the title <Text> component. Defaults to null
  titleTextAs?: null | 'span';
};

type Props<RecordType = unknown> = {
  rows: TableVerticalRowType<RecordType>[];
  data: RecordType;
  isLoading?: boolean;
  noDataTitle?: string;
  noDataActionButton?: React.ReactElement<typeof Button>;
  borderVariant?: 'primary' | 'secondary';
  className?: string;
  titleAlign?: CSSProperties['alignItems'];
  bordered?: boolean;
};

export const TableVertical = <T,>({
  rows,
  data,
  isLoading = false,
  noDataTitle,
  noDataActionButton,
  borderVariant = 'primary',
  className,
  titleAlign = 'center',
  bordered = false,
}: Props<T>) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const sortedRows = useMemo(
    () => rows?.sort((a, b) => (b.priority || 0) - (a.priority || 0)),
    [rows]
  );

  const availableRows = useMemo(
    () =>
      sortedRows?.filter(({ key, hidden }) =>
        typeof hidden === 'function'
          ? !hidden(key == null ? undefined : get(data, key), data)
          : !hidden ?? true
      ),
    [sortedRows, data]
  );

  const visibleRows = useMemo(
    () =>
      isExpanded
        ? availableRows
        : availableRows?.filter(
            ({ collapsed }, index) =>
              !(typeof collapsed === 'function'
                ? collapsed(data, index)
                : collapsed)
          ),
    [isExpanded, availableRows, data]
  );
  const isExpandable =
    isExpanded || visibleRows?.length < availableRows?.length;

  const renderDefault = (value => value) as TableVerticalRowType<T>['render'];

  if (!isLoading && !data && noDataTitle) {
    return (
      <S.Wrapper
        $bordered={bordered}
        $borderVariant={borderVariant}
        className={className}
      >
        <NoDataArea title={noDataTitle} actionButton={noDataActionButton} />
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper
      $bordered={bordered}
      $borderVariant={borderVariant}
      className={className}
    >
      {visibleRows?.map(
        (
          {
            key,
            title,
            titleInfo,
            render = renderDefault,
            topBorder = true,
            flexDirection = 'row',
            titleContainerDisplay = 'flex',
            titleTextAs = null,
          },
          index
        ) => (
          <S.Row
            key={key}
            $borderTop={topBorder}
            $borderVariant={borderVariant}
            $flexDirection={flexDirection}
            className="table-vertical-row"
          >
            {title && (
              <S.TitleContainer
                $titleAlign={titleAlign}
                $titleContainerDisplay={titleContainerDisplay}
              >
                <Text size="sm" {...(titleTextAs ? { as: titleTextAs } : {})}>
                  {title}
                </Text>
                {titleInfo && (
                  <Tooltip title={titleInfo}>
                    <S.InfoContainer>
                      <Icon name="info-circle" size="xs" />
                    </S.InfoContainer>
                  </Tooltip>
                )}
              </S.TitleContainer>
            )}
            {isLoading ? (
              <S.SkeletonBase paragraph={{ rows: 1 }} title={false} />
            ) : (
              <Text size="sm" colorVariant="primary" as="div">
                {render?.(
                  key == null ? undefined : get(data, key),
                  data,
                  index
                ) || '--'}
              </Text>
            )}
          </S.Row>
        )
      )}
      {isExpandable && (
        <S.Row $borderVariant={borderVariant}>
          <Text
            size="sm"
            color="info"
            colorVariant="primary"
            marginBottom={0}
            onClick={() => setIsExpanded(!isExpanded)}
            data-cy={CypressDataIds.Card.ShowMore}
          >
            {isExpanded ? 'Show Less' : 'Show More'}
          </Text>
        </S.Row>
      )}
    </S.Wrapper>
  );
};
