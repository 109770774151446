import styled from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { useBreakpoint } from '@npm/core/ui/utils/style/breakpoints';

export { Form } from '../../../../../../account/CreateNewAccount/NewAccountDrawer.styles';

export const ContentWrapper = styled.div`
  max-height: 80vh;

  ${({ theme }) => useBreakpoint(theme).mobile`
    max-height: 100%;
  `}
`;

export const BackButton = styled(Button)`
  && {
    padding: 0;
    margin-top: -${({ theme }) => theme.spacing.md}px;
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }
`;
