import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { type ColumnType } from '@npm/core/ui/components/molecules/Table';
import { type UnpackArrayType } from '@npm/core/ui/utils/unpack.type';
import {
  type AccountForBrokerage,
  type BrokerageFirmAccountIndexResponseType,
  CbAccreditationState,
  CbBackgroundCheckState,
} from '@npm/data-access';

import { AccountName } from '../../../../../../../account/components/AccountName';
import { RepresentativeCellItem } from '../RepresentativeCellItem';

export const getColumns: (
  selectedAccount: AccountForBrokerage,
  goToRepresentativeStep: () => void
) => ColumnType<
  UnpackArrayType<BrokerageFirmAccountIndexResponseType, 'accounts'>
>[] = (selectedAccount, goToRepresentativeStep) => [
  {
    title: 'Account',
    key: 'account',
    width: '45%',
    render: (_, account) => <AccountName account={account} isActive={false} />,
  },
  {
    title: 'Account Representative',
    key: 'account',
    render: (_, account) => {
      const isRowSelected = selectedAccount?.id === account.id;

      return (
        <RepresentativeCellItem
          account={account}
          isActive={isRowSelected}
          handleSwitchRepresentativeClick={goToRepresentativeStep}
        />
      );
    },
  },
  {
    title: 'AML/KYC',
    key: 'background_check_state',
    width: '15%',
    render: (_, account) =>
      CbBackgroundCheckState.getLabel(CbLabel, account.background_check_state),
  },
  {
    title: 'Accreditation',
    key: 'accreditation_state',
    width: '15%',
    render: (_, account) =>
      CbAccreditationState.getLabel(CbLabel, account.accreditation_state),
  },
];
