/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FilingGroupIndex } from '../model';
/**
 * FilingGroupsApi - axios parameter creator
 * @export
 */
export const FilingGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary lists all filing and grouped by what transactions they impact
         * @param {string} issuerId 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filingGroupsIndex: async (issuerId: string, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('filingGroupsIndex', 'issuerId', issuerId)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/filing_groups`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilingGroupsApi - functional programming interface
 * @export
 */
export const FilingGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilingGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary lists all filing and grouped by what transactions they impact
         * @param {string} issuerId 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filingGroupsIndex(issuerId: string, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilingGroupIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filingGroupsIndex(issuerId, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilingGroupsApi - factory interface
 * @export
 */
export const FilingGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilingGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary lists all filing and grouped by what transactions they impact
         * @param {string} issuerId 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filingGroupsIndex(issuerId: string, xWorkstation?: string, options?: any): AxiosPromise<FilingGroupIndex> {
            return localVarFp.filingGroupsIndex(issuerId, xWorkstation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for filingGroupsIndex operation in FilingGroupsApi.
 * @export
 * @interface FilingGroupsApiFilingGroupsIndexRequest
 */
export interface FilingGroupsApiFilingGroupsIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof FilingGroupsApiFilingGroupsIndex
     */
    readonly issuerId: string

    /**
     * 
     * @type {string}
     * @memberof FilingGroupsApiFilingGroupsIndex
     */
    readonly xWorkstation?: string
}

/**
 * FilingGroupsApi - object-oriented interface
 * @export
 * @class FilingGroupsApi
 * @extends {BaseAPI}
 */
export class FilingGroupsApi extends BaseAPI {
    /**
     * 
     * @summary lists all filing and grouped by what transactions they impact
     * @param {FilingGroupsApiFilingGroupsIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingGroupsApi
     */
    public filingGroupsIndex(requestParameters: FilingGroupsApiFilingGroupsIndexRequest, options?: AxiosRequestConfig) {
        return FilingGroupsApiFp(this.configuration).filingGroupsIndex(requestParameters.issuerId, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }
}

export type FilingGroupsIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const FilingGroupsIndexErrorCodes = [
];


