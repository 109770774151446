import React from 'react';
import { Grid } from 'antd';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { type AccountsOnboardingAgreementShow } from '@npm/data-access';

import {
  DocumentViewerModal,
  useDocumentViewer,
} from '../../../../documents/DocumentViewer';
import { ONBOARDING_AGREEMENTS_TEXT_CONTENT } from '../../OnboardingAgreements.config';
import { type OnboardingAgreementType } from '../../OnboardingAgreements.types';

import { getOnboardingAgreementColumns } from './OnboardingAgreementReadOnly.config';

import * as S from './OnboardingAgreementReadOnly.styles';

const { useBreakpoint } = Grid;

type Props = {
  type: OnboardingAgreementType;
  data: AccountsOnboardingAgreementShow;
  handleReplaceButtonClick?: () => void;
};

export const OnboardingAgreementReadOnly = ({
  type,
  data,
  handleReplaceButtonClick,
}: Props) => {
  const breakpoint = useBreakpoint();
  const { documentViewerModalProps, showNpmDocument } = useDocumentViewer();

  if (!data) return null;

  return (
    <>
      <Margin bottom="xl">
        <S.Table
          columns={getOnboardingAgreementColumns(
            type,
            showNpmDocument,
            handleReplaceButtonClick
          )}
          dataSource={[data]}
          expandable={{
            showExpandColumn: !breakpoint.sm,
            rowExpandable: () => !breakpoint.sm,
          }}
        />
      </Margin>
      <Alert
        type="success"
        message={ONBOARDING_AGREEMENTS_TEXT_CONTENT.read_only[type]}
      />
      <DocumentViewerModal {...documentViewerModalProps} />
    </>
  );
};
