import { type IconProps } from '@npm/utils';

import { Icon } from '../Icon';

import * as S from './SquareIcon.styles';

type Props = {
  iconName: IconProps['name'];
};

export const SquareIcon = ({ iconName }: Props) => {
  return (
    <S.SquareIconWrapper>
      <Icon name={iconName} size={16} />
    </S.SquareIconWrapper>
  );
};
