import { Codebooks } from './codebooks.types';

const items = {
  obo_create_order: 'obo_create_order',
  ioi: 'ioi',
  firm: 'firm',
} as const;

export type AttestationRuleType = keyof typeof items;

export const CbAttestationRule = {
  code: Codebooks.ATTESTATION_RULE,
  items: items,
};
