/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  RepresentativeApi,
  AccountRepresentativeIndexErrorCodes,
  AccountRepresentativeIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new RepresentativeApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountRepresentativeIndexMethod = RepresentativeApi['accountRepresentativeIndex'];
export type AccountRepresentativeIndexResponseType = MethodResult<AccountRepresentativeIndexMethod>;

export const useAccountRepresentativeIndex = (
  variables: Parameters<AccountRepresentativeIndexMethod>[0],
  config?: UseQueryConfig<
    AccountRepresentativeIndexMethod,
    AccountRepresentativeIndexErrorTypes
  >
) => {
  return useQuery<
    AccountRepresentativeIndexMethod,
    AccountRepresentativeIndexErrorTypes
  >(
    (...args) => api.accountRepresentativeIndex(...args),
    AccountRepresentativeIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountRepresentativeIndex'],
        ...apiQueryConfig.AccountRepresentativeIndex,
        ...config?.queryConfig
      },
    },
    'AccountRepresentativeIndex',
  );
};

export const useAccountRepresentativeIndexInfinite = (
  variables: Parameters<AccountRepresentativeIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountRepresentativeIndexMethod,
    AccountRepresentativeIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountRepresentativeIndexMethod,
    AccountRepresentativeIndexErrorTypes
  >(
    (...args) => api.accountRepresentativeIndex(...args),
    AccountRepresentativeIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountRepresentativeIndex', 'AccountRepresentativeIndexInfinite'],
        ...apiQueryConfig.AccountRepresentativeIndex,
        ...config?.queryConfig,
      },
    },
    'AccountRepresentativeIndex',
  );
};

export const useAccountRepresentativeIndexLazy = (baseOptions?: {
  variables?: Parameters<AccountRepresentativeIndexMethod>[0],
  config?: UseQueryConfig<
    AccountRepresentativeIndexMethod,
    AccountRepresentativeIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountRepresentativeIndexMethod,
    AccountRepresentativeIndexErrorTypes
  >(
    (...args) => api.accountRepresentativeIndex(...args),
    AccountRepresentativeIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountRepresentativeIndex'],
        ...apiQueryConfig.AccountRepresentativeIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountRepresentativeIndex',
  );
};

