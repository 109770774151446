import styled from 'styled-components';

import { useBreakpoint } from '../../../utils/style';
import { SmallTextStyles } from '../Typography';

export const EXPANDABLE_PANEL_HEADER_HEIGHT = 30;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 522px;
  min-width: min(100%, 400px);

  ${({ theme }) => useBreakpoint(theme).tablet`
    width: 100%;
  `}
`;

const noBottomBorderRadius = `
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const Header = styled.button<{ $isExpanded: boolean }>`
  ${SmallTextStyles};
  color: ${({ theme }) => theme.color.general.typography.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: ${EXPANDABLE_PANEL_HEADER_HEIGHT}px;
  border-radius: ${({ theme }) => theme.borderRadius.md}px;

  ${({ $isExpanded }) => $isExpanded && noBottomBorderRadius}

  ${({ theme }) => useBreakpoint(theme).tablet`
    ${noBottomBorderRadius}
  `}

  ${({ theme }) => useBreakpoint(theme).mobile`
    margin-left: 0px;
    width: 100%;
  `}

  ${({ theme }) => `
    padding: ${theme.spacing.xs}px ${theme.spacing.md}px;
    gap: ${theme.spacing.sm}px;
    background-color: ${theme.color.info.backgrounds.secondaryHover};
  `}
`;

export const Content = styled.div`
  width: 100%;
  max-height: 60vh;
  overflow: auto;
  box-shadow: ${({ theme }) => theme.shadow.level2};

  ${({ theme }) => `
    color: ${theme.color.general.typography.primary};
    border-radius: 0px 0px ${theme.spacing.xs}px ${theme.spacing.xs}px;
    background-color: ${theme.color.general.layout.two};
    padding: ${theme.spacing.sm}px;
  `}
`;

/**
 * There is a bug in the motion lib that requires to have two nested paddings
 * to animate smoothly: https://github.com/framer/motion/issues/368
 */
export const Inner = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing.sm}px;
  `}
`;
