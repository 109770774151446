import type { ComponentProps } from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type Link, useLocation } from '@npm/core/ui/components/molecules/Link';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type IconProps } from '@npm/utils';

import * as S from './TabsLink.styles';

type Props = {
  isDisabled?: boolean;
  extra?: string;
  iconName?: IconProps['name'];
  tooltip?: React.ReactNode;
} & ComponentProps<typeof Link>;

// this is what NavLink from react-router-dom does, just using our Link
export const TabsLink = ({
  to,
  children,
  isDisabled,
  extra,
  iconName,
  tooltip,
  ...props
}: Props) => {
  const { isMobile } = useBreakpoints();
  const { pathname } = useLocation();
  const isActive =
    (typeof to === 'string' && pathname === to.split('?')[0]) ||
    (to as unknown as { pathname?: string })?.pathname === pathname;

  if (isDisabled) {
    return (
      <Tooltip title={tooltip}>
        <S.DisabledLink>
          <S.TextFlex size="default" colorVariant="tertiary">
            <Icon name="lock" size="xs" />
            {children}
          </S.TextFlex>
          {extra && (
            <Text size="xs" colorVariant="tertiary">
              {extra}
            </Text>
          )}
        </S.DisabledLink>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltip} disabled={isDisabled}>
      <S.Link
        to={to}
        className={isActive ? 'active' : isDisabled ? 'disabled' : ''}
        {...props}
      >
        <S.TextFlex
          size="default"
          colorVariant={
            isActive ? 'primary' : isDisabled ? 'tertiary' : 'secondary'
          }
        >
          {iconName && (
            <Icon
              name={isDisabled ? 'lock' : iconName}
              size={isMobile ? 'md' : 'xs'}
            />
          )}
          {/* show icon only on mobile */}
          {isMobile && iconName ? null : children}
        </S.TextFlex>
        {extra && <Text size="xs">{extra}</Text>}
      </S.Link>
    </Tooltip>
  );
};
