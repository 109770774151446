import styled, { css } from 'styled-components';

import { useBreakpoint } from '@npm/core/ui/utils/style';

export const ContainerDesktop = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.color.general.layout.one};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing.sm}px ${theme.spacing.md}px;
    border-top: 1px solid ${theme.color.general.borders.primary};
    gap: ${({ theme }) => theme.spacing.sm}px;
  `}
`;

export const ContainerMobile = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.sm}px;
    & > *:first-child {
      margin-top: ${theme.spacing.sm}px;
      margin-bottom: ${theme.spacing.md}px;
    }
  `}

  .card-content {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const InnerBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  gap: ${({ theme }) => theme.spacing.sm}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
      overflow-x: scroll;
      overflow-y: hidden;
  `}

  &&& .ant-tabs-nav {
    margin: 0;
  }
`;
