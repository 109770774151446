import React, { useCallback, useState } from 'react';

import { maskCharacter } from '../../../utils/maskCharacters';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Text } from '../Typography';

import * as S from './ValueVisibilityToggler.styles';

type Props = {
  value: string;
  mask: string;
  visibleCharsNumber: number;
  isVisible?: boolean;
  maskAnchor?: 'start' | 'end';
};

export const ValueVisibilityToggler = ({
  value,
  mask,
  visibleCharsNumber,
  isVisible = false,
  maskAnchor = 'end',
}: Props) => {
  const [isValueVisible, setIsValueVisible] = useState(isVisible);

  const handleToggleVisibility = useCallback(() => {
    setIsValueVisible(!isValueVisible);
  }, [isValueVisible]);

  return (
    <S.Wrapper>
      <Button
        variant="text"
        size="sm"
        icon={
          isValueVisible ? (
            <Icon name="eye-off" size="xs" />
          ) : (
            <Icon name="eye" size="xs" />
          )
        }
        onClick={handleToggleVisibility}
      />
      <Text size="sm" colorVariant="primary">
        {isValueVisible
          ? value
          : maskCharacter(value, mask, visibleCharsNumber, maskAnchor)}
      </Text>
    </S.Wrapper>
  );
};
