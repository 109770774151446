import TextAreaAntd from 'antd/es/input/TextArea';
import styled, { css } from 'styled-components';

import { disabledCSS, errorStatusCSS, inputCSS, readOnlyCSS } from './styles';

export const StyledTextArea = styled(TextAreaAntd)<{ $readOnly?: boolean }>`
  &.ant-input {
    ${inputCSS};
    height: 100px;
  }

  &.ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input {
    ${errorStatusCSS};
  }

  &.ant-input-status-warning:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input {
    ${errorStatusCSS};
  }

  &.ant-input-disabled {
    ${disabledCSS};
  }

  ${({ $readOnly, theme }) =>
    $readOnly &&
    css`
      &.ant-input {
        ${readOnlyCSS};

        &.ant-input:hover {
          border-color: ${theme.color.general.borders.secondary};
        }
      }
    `}
`;
