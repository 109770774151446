import { useMemo } from 'react';
import { NumberParam, useQueryParams } from 'use-query-params';

import { PAGE_SIZE_HARD_LIMIT } from '@npm/core/ui/components/molecules/Table';
import {
  useAccountRepresentativeIndex,
  useAccountShow,
} from '@npm/data-access';

export const useLoadOboData = () => {
  const [queryParams] = useQueryParams({
    roleId: NumberParam,
    oboAccountId: NumberParam,
    oboUserId: NumberParam,
  });

  const isObo =
    !!queryParams.oboAccountId &&
    !!queryParams.oboUserId &&
    !!queryParams.roleId;

  const { data: oboAccount, isLoading: oboAccountLoading } = useAccountShow(
    { id: queryParams.oboAccountId?.toString() },
    {
      queryConfig: {
        enabled: isObo,
      },
      // using directly query params as they contain the most updated values which doesn't need to be set in default axios instance yet
      roles: {
        workstation: 'brokerage',
        roleId: queryParams?.roleId,
        oboAccountId: queryParams?.oboAccountId,
        oboUserId: queryParams?.oboUserId,
      },
    }
  );

  const { data: oboRepresentatives, isLoading: oboRepresentativesLoading } =
    useAccountRepresentativeIndex(
      {
        accountId: queryParams.oboAccountId,
        size: PAGE_SIZE_HARD_LIMIT,
      },
      {
        queryConfig: {
          enabled: isObo,
        },
        // setting roles as the new role HTTP headers doesn't need to be set yet
        roles: {
          workstation: 'brokerage',
          roleId: queryParams?.roleId,
          oboAccountId: queryParams?.oboAccountId,
          oboUserId: queryParams?.oboUserId,
        },
      }
    );

  return useMemo(() => {
    return {
      isLoading: isObo && (oboAccountLoading || oboRepresentativesLoading),
      obo:
        isObo && !(oboAccountLoading || oboRepresentativesLoading)
          ? {
              account: oboAccount,
              representative: oboRepresentatives?.representatives?.find(
                r => r.user_id === queryParams.oboUserId
              ),
            }
          : null,
    };
  }, [
    isObo,
    oboAccountLoading,
    oboRepresentativesLoading,
    queryParams.oboAccountId,
    queryParams.oboUserId,
  ]);
};
