import React from 'react';

import { componentLoader } from '@npm/core/ui/routes/componentLoader';
import { type RouteDefinition } from '@npm/core/ui/routes/router.types';
import { type globalRouteKeys } from '@npm/core/ui/routes/routes.global';
import AcivationPage from '@npm/features/auth/activation/ActivationPage';
import InvitationPage from '@npm/features/auth/invitation/InvitationPage';
import LogoutPage from '@npm/features/auth/logout/LogoutPage';
import SignUpPage from '@npm/features/auth/signup/SignUpPage';
import { ErrorPath } from '@npm/features/routes/components/ErrorPath/ErrorPath';

import AboutPage from '../pages/about';

type RouteDef = RouteDefinition & {
  path: globalRouteKeys;
};

export const registerRoutes = (desc: RouteDefinition[]) => {
  routes.forEach(r => {
    desc.push(r);
  });
};

export const routes: RouteDef[] = [
  {
    path: `/about`,
    needUser: false,
    needLogin: false,
    component: () => <AboutPage />,
    mobileMenuType: 'basic',
  },
  {
    path: `/403`,
    needUser: false,
    needLogin: false,
    component: () => <ErrorPath heading="Unauthorized" status="403" />,
    mobileMenuType: 'basic',
  },
  {
    path: `/404`,
    needUser: false,
    needLogin: false,
    component: () => (
      <ErrorPath
        heading="Error"
        title="Sorry, the page you visited does not exist."
        status="404"
      />
    ),
    mobileMenuType: 'basic',
  },
  {
    path: '/logout',
    needUser: false,
    needLogin: false,
    component: () => <LogoutPage />,
    mobileMenuType: 'basic',
  },
  {
    path: '/signup',
    needUser: false,
    needLogin: false,
    component: () => <SignUpPage />,
    mobileMenuType: 'basic',
  },
  {
    // This page is used when user clicks on "Activate Account" link in Okta sign-in page.
    // User is asked for email and activation email is sent via `/api/user/activate`.
    path: '/activate',
    needUser: false,
    needLogin: false,
    component: () => <AcivationPage />,
    mobileMenuType: 'basic',
  },
  {
    // This page is used when user clicks on invitation link.
    // This page checks user via `/api/user/verify` and redirects to Okta's sign-in or setup page.
    path: '/invitation',
    needUser: false,
    needLogin: false,
    component: () => <InvitationPage />,
    mobileMenuType: 'basic',
  },
  {
    path: `/agreements/interim`,
    component: componentLoader(
      () => import(`@npm/features/agreements/AgreementsInterim`)
    ),
  },
];
