import { Tooltip as AntdTooltip } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

import { useBreakpoint } from '../../../utils/style';

import { TooltipClassName } from './Tooltip.types';

export const StyledTooltip = styled(AntdTooltip)``;

export const TooltipGlobalStyles = createGlobalStyle`
  .${TooltipClassName.SM} {
    &.ant-tooltip {
      max-width: 220px;
    }

    .ant-tooltip-inner {
      padding: ${({ theme }) => theme.spacing.sm}px;
      font-size: ${({ theme }) => theme.typography.size.xs}px;

      ${({ theme }) => useBreakpoint(theme).mobile`
        font-size: ${({ theme }) => theme.typography.size.xxs}px;
      `}
    }
  }

  .${TooltipClassName.MD} {
     &.ant-tooltip {
      max-width: 260px;
    }

    .ant-tooltip-inner {
      padding: ${({ theme }) => theme.spacing.md}px;
      font-size: ${({ theme }) => theme.typography.size.sm}px;


      ${({ theme }) => useBreakpoint(theme).mobile`
        font-size: ${({ theme }) => theme.typography.size.xs}px;
      `}
    }
  }

  .${TooltipClassName.LG} {
    &.ant-tooltip {
      max-width: 350px;
    }

    .ant-tooltip-inner {
      padding: ${({ theme }) => theme.spacing.md}px;
      font-size: ${({ theme }) => theme.typography.size.sm}px;


      ${({ theme }) => useBreakpoint(theme).mobile`
        font-size: ${({ theme }) => theme.typography.size.xs}px;
      `}
    }
  }

  .ant-tooltip-inner {
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    font-size: ${({ theme }) => theme.typography.size.sm}px;

    h1, h2, h3, h4, h5, h6 {
      color: ${({ theme }) =>
        theme.color.general.typography.negative} !important;
      margin-bottom: ${({ theme }) => theme.spacing.sm}px;
    }

    &, p, span {
      color: ${({ theme }) => theme.color.general.typography.muted} !important;
      white-space: pre-wrap;
    }
  }

  .ant-tooltip-inner, .ant-tooltip-arrow-content::before {
    background: ${({ theme }) => theme.color.general.layout.three};
  }

  .ant-form-item-label > label .ant-form-item-tooltip {
    color: ${({ theme }) => theme.color.info.typography.primary};
    line-height: ${({ theme }) => theme.icon.size.xs}px;

    svg {
      height: ${({ theme }) => theme.icon.size.xs}px;
      width: ${({ theme }) => theme.icon.size.xs}px;
    }
  }
`;

export const TooltipInfoIcon = styled.span`
  margin-left: ${({ theme }) => theme.spacing.xs}px;
  height: 16px;
  display: block;

  svg {
    color: ${({ theme }) => theme.color.info.typography.primary};
    cursor: pointer;
  }
`;
