import { useEffect } from 'react';

import { useSidebarStore } from './Sidebar.store';

export const useNoSidebar = () => {
  const setVisible = useSidebarStore(store => store.setOpen);
  const resetVisible = useSidebarStore(store => store.resetOpen);

  useEffect(() => {
    setVisible(false);
    return () => {
      resetVisible();
    };
  }, []);
};
