import React from 'react';
import { useTheme } from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { getFullName } from '@npm/core/ui/utils/formatters';
import { type RepresentativeAggregate } from '@npm/data-access';

import * as S from './RepresentativeButton.styles';

type Props = {
  onClick: () => void | null;
  representative: RepresentativeAggregate | undefined;
};

export const RepresentativeButton = ({ onClick, representative }: Props) => {
  const theme = useTheme();

  const handleClick = e => {
    e.stopPropagation();
    onClick?.();
  };

  return (
    <S.Button onClick={handleClick} data-dd-action-name="Representative Button">
      {onClick && (
        <S.IconWrapper>
          <Icon
            name="refresh"
            size="xs"
            color={theme.color.info.typography.primary}
          />
        </S.IconWrapper>
      )}
      <Flex direction="column" align="flex-start">
        <Text size="xs" colorVariant="tertiary">
          Representative
        </Text>
        <Text size="xs" colorVariant="primary" data-dd-privacy="mask">
          {getFullName(
            representative?.first,
            representative?.last,
            representative?.middle
          )}{' '}
          <Text size="xs" as="span">
            {representative?.email}
          </Text>
        </Text>
      </Flex>
    </S.Button>
  );
};
