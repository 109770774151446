import React from 'react';

import { Label } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import { getActionText } from '../SelectNegotiationAccount.utils';

export const ActionLabel = ({ canTakeAction }: { canTakeAction: boolean }) => {
  return canTakeAction ? (
    <Text size="sm" color="info">
      {getActionText(canTakeAction)}
    </Text>
  ) : (
    <Label variant="general">{getActionText(canTakeAction)}</Label>
  );
};
