import { useEffect, useState } from 'react';

import { eventBus } from '@npm/core/ui/utils/eventBus';

import { type ZendeskTicketGroupID } from '../widget';

import { ZendeskSupportModal } from './ZendeskSupportModal';

export const ZendeskContactSupport = () => {
  const [openModal, setOpenModal] = useState(false);
  const [zendeskTicketGroupId, setTicketGroupId] =
    useState<ZendeskTicketGroupID>();

  useEffect(() => {
    return eventBus.on<ZendeskTicketGroupID>(
      'REQUEST_SUPPORT',
      zendeskTicketGroupId => {
        setOpenModal(true);
        setTicketGroupId(zendeskTicketGroupId);
      }
    );
  }, []);

  return (
    <ZendeskSupportModal
      isOpen={openModal}
      key={`zendesk-support-modal-${openModal}`}
      zendeskTicketGroupId={zendeskTicketGroupId}
      onCancel={() => setOpenModal(false)}
    />
  );
};
