import React, { memo, useMemo } from 'react';

import {
  Navigation,
  NavigationMenu,
} from '@npm/core/ui/components/organisms/Layout';
import { useSidebarStore } from '@npm/core/ui/components/organisms/Layout/Sidebar/Sidebar.store';
import { filterMenuItemsByFeatureFlag } from '@npm/core/ui/routes/router.utils';
import { CbActionCounterType, useActionCounterShow } from '@npm/data-access';
import { useFeatureFlags } from '@npm/features/app/featureFlags/featureFlags.context';
import { useFeatureFlagMenuItem } from '@npm/features/app/featureFlags/Menu/useFeatureFlagMenuItem';
import { useHelpMenuItem } from '@npm/features/app/zendesk/menu/useHelpMenuItem';
import { useUserContextStore } from '@npm/features/auth/user/context';
import { useUserMenuItems } from '@npm/features/auth/user/menu/menuItems';
import { useUserRole } from '@npm/features/auth/user/role';
import { truthyFilter } from '@npm/utils';

import { getIssuerSidebarItems } from './config/issuer-workstation';

export const IssuerMenu = memo(function IssuerMenu() {
  const userContext = useUserContextStore(store => store);
  const userRole = useUserRole();
  const { helpMenuItem, handleHelpMenuItemClick } = useHelpMenuItem();
  const userMenuItems = useUserMenuItems();
  const { holding, match, agreement, event } = CbActionCounterType.items;
  const { isEnabled } = useFeatureFlags();
  const { data: counterData } = useActionCounterShow({
    category: [holding, match, agreement, event],
  });
  const { featureFlagMenuItem, handleFeatureFlagMenuItemClick } =
    useFeatureFlagMenuItem();
  const isCollapsed = useSidebarStore(store => store.isCollapsed);

  const menuItems = useMemo(
    () =>
      filterMenuItemsByFeatureFlag(
        isEnabled,
        getIssuerSidebarItems(userContext, userRole, counterData, isCollapsed)
      ),
    [userContext, userRole, counterData, isCollapsed]
  );

  return (
    <NavigationMenu $isCollapsed={isCollapsed}>
      <Navigation menuItems={menuItems} />
      <Navigation
        menuItems={[featureFlagMenuItem, helpMenuItem, ...userMenuItems].filter(
          truthyFilter
        )}
        handleClick={key =>
          handleHelpMenuItemClick(key) || handleFeatureFlagMenuItemClick(key)
        }
      />
    </NavigationMenu>
  );
});
