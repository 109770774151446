import { Form } from 'antd';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import {
  CollapsibleFormSection,
  useCollapsibleFormSection,
} from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import { DrawerSection } from '@npm/core/ui/components/molecules/DrawerSection';
import {
  type BrokerageFirmAggregate,
  type OrderSourceCode,
  CbOrderEntryType,
  CbOrderSource,
  CbVisibility,
} from '@npm/data-access';

import {
  AssetTypeFormSection,
  DataRoomFormSection,
  DateValidationAlerts,
  ExpirationFormSection,
  NoteFormSection,
  PlacedAtFormSection,
  PublishedAtFormSection,
  ReceivedAtFormSection,
  ROFRFormSection,
  StateFormSection,
  StructureFormSection,
  validateDateFields,
} from '../../../../components/inputs';
import { OrderTypeFormSection } from '../../../../components/inputs/OrderTypeFormSection';
import type { Action, FormValues } from '../..';

import { VisibilityFormSection } from './VisibilityFormSection';

type Props = {
  activeAction: Action;
  assetTypes: { label: string; value: string }[];
  source: OrderSourceCode | undefined;
  brokerageFirm: BrokerageFirmAggregate | undefined;
  isObo: boolean;
  isIndividualAccountSelected: boolean;
};

export const OrderPreferencesSection = ({
  activeAction,
  assetTypes,
  source,
  brokerageFirm,
  isObo,
  isIndividualAccountSelected,
}: Props) => {
  const form = Form.useFormInstance<FormValues>();
  const values = Form.useWatch([], form) ?? ({} as FormValues);

  const { activeKeys, handleItemChange, handleSectionChange } =
    useCollapsibleFormSection({});

  return (
    <DrawerSection
      iconName="adjust"
      title="Order Details"
      content={
        <Flex direction="column" gap="md">
          <CollapsibleFormSection
            activeKey={activeKeys}
            onChange={handleSectionChange}
          >
            <OrderTypeFormSection
              key="orderType"
              value={values.orderType}
              initialValue={CbOrderEntryType.items.ioi}
              onItemChange={value => {
                if (
                  value === CbOrderEntryType.items.soft_ioi &&
                  !brokerageFirm?.access_ats_i
                ) {
                  form.resetFields(['visibility', 'atsPool']);
                }
                handleItemChange('orderType');
              }}
              isObo={isObo}
              hidden={isIndividualAccountSelected && !isObo}
            />
            <Divider marginBottom={0} />

            <VisibilityFormSection
              key="visibility"
              value={values}
              hidden={!isObo}
              orderType={values.orderType}
              brokerageFirm={brokerageFirm}
            />
            {isObo && <Divider marginBottom={0} />}

            {activeAction === 'buy' && (
              <>
                <AssetTypeFormSection
                  value={values.assetType}
                  options={assetTypes}
                  onItemChange={() => handleItemChange('assetType')}
                  key="assetType"
                />
                <Divider marginBottom={0} />
              </>
            )}

            <StructureFormSection
              value={values}
              allowFees={isObo}
              onItemChange={() => handleItemChange('structure')}
              isSelectDisabled={activeAction === 'sell'} // sell orders have fixed structure depending on the holding asset type
              key="structure"
            />
            <Divider marginBottom={0} />

            {isObo ? (
              <>
                <ReceivedAtFormSection
                  value={values.orderReceivedDate}
                  source={source}
                  onItemChange={value => {
                    handleItemChange('orderReceivedDate');
                    if (source === CbOrderSource.items.historical) {
                      form.setFieldValue('placedAtDate', value);
                    }
                  }}
                  key="orderReceivedDate"
                />
                <Divider marginBottom={0} />
                {source === CbOrderSource.items.historical && (
                  <>
                    <PlacedAtFormSection
                      value={values.placedAtDate}
                      onItemChange={() => handleItemChange('placedAtDate')}
                      key="placedAtDate"
                    />
                    <Divider marginBottom={0} />
                  </>
                )}
                {source === CbOrderSource.items.historical &&
                  values.visibility === CbVisibility.items.external && (
                    <>
                      <PublishedAtFormSection
                        value={values.orderPublishedDate}
                        onItemChange={() =>
                          handleItemChange('orderPublishedDate')
                        }
                        key="orderPublishedDate"
                      />
                      <Divider marginBottom={0} />
                    </>
                  )}
                <ExpirationFormSection
                  value={values}
                  source={source}
                  visibility={values.visibility}
                  state={values.state}
                  onItemChange={() => handleItemChange('expiration')}
                  key="expiration"
                />
                <Divider marginBottom={0} />
                {source === CbOrderSource.items.historical && (
                  <>
                    <StateFormSection
                      value={values.state}
                      onItemChange={() => handleItemChange('state')}
                      key="state"
                    />
                    <Divider marginBottom={0} />
                  </>
                )}
              </>
            ) : (
              <FormItem name="goodTillCancelled" hidden />
            )}

            {isObo && values.buySell === 'sell' && (
              <>
                <DataRoomFormSection
                  value={values.dataRoom}
                  key="dataRoom"
                  onItemChange={() => handleItemChange('dataRoom')}
                />
                <Divider marginBottom={0} />
                <ROFRFormSection
                  value={values.rofr}
                  onItemChange={() => handleItemChange('rofr')}
                  key="rofr"
                />
                <Divider marginBottom={0} />
              </>
            )}

            <NoteFormSection value={values.note} key="note" />
          </CollapsibleFormSection>

          <DateValidationAlerts errors={validateDateFields(values)} />
        </Flex>
      }
    />
  );
};
