/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalCikIndex } from '../model';
/**
 * CiksApi - axios parameter creator
 * @export
 */
export const CiksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get ciks by company_id
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalIndexCik: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalIndexCik', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/ciks`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CiksApi - functional programming interface
 * @export
 */
export const CiksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CiksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get ciks by company_id
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalIndexCik(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCikIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalIndexCik(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CiksApi - factory interface
 * @export
 */
export const CiksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CiksApiFp(configuration)
    return {
        /**
         * 
         * @summary get ciks by company_id
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalIndexCik(companyId: string, options?: any): AxiosPromise<InternalCikIndex> {
            return localVarFp.internalIndexCik(companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for internalIndexCik operation in CiksApi.
 * @export
 * @interface CiksApiInternalIndexCikRequest
 */
export interface CiksApiInternalIndexCikRequest {
    /**
     * 
     * @type {string}
     * @memberof CiksApiInternalIndexCik
     */
    readonly companyId: string
}

/**
 * CiksApi - object-oriented interface
 * @export
 * @class CiksApi
 * @extends {BaseAPI}
 */
export class CiksApi extends BaseAPI {
    /**
     * 
     * @summary get ciks by company_id
     * @param {CiksApiInternalIndexCikRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CiksApi
     */
    public internalIndexCik(requestParameters: CiksApiInternalIndexCikRequest, options?: AxiosRequestConfig) {
        return CiksApiFp(this.configuration).internalIndexCik(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InternalIndexCikErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalIndexCikErrorCodes = [
    401,
    404,
];


