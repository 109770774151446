import { type FC } from 'react';
import { type CollapseProps, Collapse } from 'antd';
import styled from 'styled-components';

import { Text } from '../../atoms/Typography';

export const Container = styled.div`
  position: relative;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.md}px;

  & > *:first-child {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
`;

// [Antd Typings] Ant'd internal types are not exported, need to re-type.
export const CollapseWrapper = styled<FC<CollapseProps>>(Collapse)`
  & {
    background-color: unset;
    border: none;
  }

  && .ant-collapse-header {
    padding: ${({ theme }) => theme.spacing.sm}px 0px;
    color: ${({ theme }) => theme.color.info.typography.primary};
    align-items: center;

    .ant-collapse-header-text {
      width: 100%;
    }
  }

  && .ant-collapse-item,
  && .ant-collapse-content {
    border: none;
    background-color: unset;
  }

  && .ant-collapse-content-box {
    padding: 0;
  }

  && .ant-select-dropdown {
    z-index: ${({ theme }) => theme.zIndex.fixed + 1};
  }
`;

export const ValuePreview = styled(Text)`
  align-items: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
