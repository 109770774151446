import styled from 'styled-components';

import { AUTO_SCROLL_FORM_QUESTION_CLASS } from '@npm/core/ui/components/organisms/AutoScrollingForm/QuestionCard/QuestionCard';
import {
  preserveHorizontalFocusBorder,
  preserveVerticalFocusBorder,
  useBreakpoint,
} from '@npm/core/ui/utils/style';

export const StepContainer = styled.div`
  height: 100%;
  max-width: 100%;
  ${preserveHorizontalFocusBorder}
  ${preserveVerticalFocusBorder}
  padding-bottom: ${({ theme }) => theme.spacing.xxl * 2}px;

  ${({ theme }) => useBreakpoint(theme).tablet`
    padding-bottom: ${theme.spacing.lg}px;
  `}
`;

export const TopContentWrapper = styled.div`
  max-width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`;

export const ChildrenWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;

  .${AUTO_SCROLL_FORM_QUESTION_CLASS} {
    padding-top: ${({ theme }) => theme.spacing.xl}px;

    &:not(:last-child) {
      padding-bottom: ${({ theme }) => theme.spacing.xl * 4}px;
    }

    ${({ theme }) => useBreakpoint(theme).tablet`
      padding-top: ${theme.spacing.md}px;

    &:not(:last-child) {
      padding-bottom: ${theme.spacing.md * 4}px;
    }
  `}
  }
`;
