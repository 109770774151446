import React, { forwardRef } from 'react';

import { type CardBaseProps } from './CardBase.types';

import * as S from './CardBase.styles';
import { ContentLoading } from './CardBase.styles';

export const CardBase = forwardRef<HTMLDivElement, CardBaseProps>(
  (
    {
      header,
      footer,
      children,
      hideContent,
      isLoading,
      noContentPadding,
      dataCy,
      flex,
      outlined = true,
      fullHeight,
      variant = 'default',
      background = variant === 'compact' ? 'two' : 'one',
      ...rest
    },
    ref
  ) => {
    return (
      <S.Wrapper
        data-cy={dataCy}
        $outlined={outlined}
        ref={ref}
        $flex={flex}
        $fullHeight={fullHeight}
        $variant={variant}
        $bgColour={background}
        $hasFooter={!!footer}
        {...rest}
      >
        <S.Header data-cy={dataCy && `${dataCy}-header`}>{header}</S.Header>
        {!hideContent && (
          <>
            {children && (
              <S.Container className="card-content-container">
                <S.Content
                  $variant={variant}
                  $noContentPadding={noContentPadding}
                  $hasFooter={!!footer}
                  $hasHeader={!!header}
                >
                  {isLoading ? <ContentLoading /> : children}
                </S.Content>
              </S.Container>
            )}
            {footer && (
              <S.Footer $variant={variant} className="card-footer">
                {isLoading ? <S.FooterContentLoading /> : footer}
              </S.Footer>
            )}
          </>
        )}
      </S.Wrapper>
    );
  }
);

CardBase.displayName = 'CardBase';
