import React, { type ComponentProps } from 'react';

import { type Holding } from '@npm/data-access';

import {
  DocumentViewerModal,
  useDocumentViewer,
} from '../../../../documents/DocumentViewer';

import * as S from './HoldingCard.styles';

export type Props = {
  holding: Holding;
  header?: ComponentProps<typeof S.SecondMarketHoldingCard>['header'];
};

export const HoldingCard = ({ holding, header = false }: Props) => {
  const { documentViewerModalProps, showNpmDocument } = useDocumentViewer();

  return (
    <>
      <S.SecondMarketHoldingCard
        holding={holding}
        onDocumentClick={showNpmDocument}
        header={header}
        variant="compact"
      />
      <DocumentViewerModal {...documentViewerModalProps} />
    </>
  );
};
