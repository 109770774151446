import { type CompanyPricingApi } from '@npm/data-access';

import { type MutualFundFilterValues } from './MutualFundMarks.types';

export const useMutualFundFilterValues = (
  marksToMarket: CompanyPricingApi.Mtm[] | undefined
): MutualFundFilterValues => {
  if (!marksToMarket) return { parents: [], assetTypes: [] };

  const parents = new Set<string>();

  marksToMarket.forEach(mtm => {
    parents.add(mtm.mutual_fund_parent_name);
  });

  return {
    parents: Array.from(parents).filter(value => value.length > 0),
    assetTypes: ['Common', 'Preferred'],
  };
};
