/* eslint-disable no-restricted-imports */
import { useLocation as useReactRouterLocation } from 'react-router';
import {
  type LinkProps,
  useHistory as useReactRouterHistory,
} from 'react-router-dom';

import { useCurrentRoute } from '../../../hooks/useCurrentRoute';
import { useHelmetStore } from '../../atoms/Helmet';

import {
  getLocationDescriptorObject,
  getNewStateWithPageHistory,
} from './Link.utils';

// hook returning modified push and replace functions
// from react-router, preserving the specified query params
export const useHistory = () => {
  const history = useReactRouterHistory();
  const location = useReactRouterLocation();
  const pageTitle = useHelmetStore(x => x.pageTitle);
  const currentRoute = useCurrentRoute();

  const push = (to: LinkProps['to']) => {
    const locationDescriptor = getLocationDescriptorObject(to, location);

    // if current and next url matches, do not push
    if (
      history.createHref(history.location) ===
      history.createHref(locationDescriptor)
    ) {
      return;
    }

    history.push(
      locationDescriptor,
      getNewStateWithPageHistory(
        locationDescriptor,
        pageTitle,
        {
          ...location,
          search: window.location.search,
        },
        currentRoute
      )
    );
  };

  const replace = (to: LinkProps['to']) => {
    history.replace(getLocationDescriptorObject(to, location), location?.state);
  };

  return {
    ...history,
    push,
    replace,
  };
};

export type History = ReturnType<typeof useHistory>;
