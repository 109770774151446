import React from 'react';
import { type ModalProps } from 'antd';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

type Props = {
  onSubmit: () => Promise<void>;
  isSubmitting: boolean;
} & ModalProps;

// this feature is available only inside BRO workstation -> we might want to move the component accordingly
// (keeping it here for now as it is used in common/features/AccountDetail/EntityAccreditation)
export const ResetAccreditationModal = ({
  onSubmit,
  isSubmitting,
  open,
  onCancel,
}: Props) => {
  const { isMobile } = useBreakpoints();

  return (
    <Modal
      title="Reset Accreditation Status"
      size="md"
      open={open}
      footer={
        <>
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            data-cy={CypressDataIds.Accreditation.ResetConfirmationDialog}
            onClick={async e => {
              await onSubmit();
              onCancel(e);
            }}
            color="error"
            blockOnMobile
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Reset
          </Button>
        </>
      }
      onCancel={onCancel}
      closable={!isMobile}
      onOk={onCancel}
    >
      <Text colorVariant="secondary">
        Are you sure you want to reset this accreditation?
      </Text>
    </Modal>
  );
};
