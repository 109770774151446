import { useLocation } from '@npm/core/ui/components/molecules/Link';
import { useCurrentRoute } from '@npm/core/ui/hooks/useCurrentRoute';
import { CbRoleType } from '@npm/data-access';
import { type UserRoleAggregate } from '@npm/data-access';

import { LANDING_PAGE_CONFIG } from '../../../../../workstations/workstationLandingPage.utils';

export const useAccountSwitchRedirect = () => {
  const location = useLocation();
  const currentRoute = useCurrentRoute();
  const { route, parents } = currentRoute || {};

  return (nextAccount: UserRoleAggregate | null) => {
    // switching into spouse account -> very limited access
    // (needs to be redirected to a permitted route)
    if (nextAccount?.role_name?.code === CbRoleType.items.SPOUSE) {
      return LANDING_PAGE_CONFIG.investorSpouse;
    }

    // switching account while being in onboarding flow (initial or post-onboarding)
    const onboarding = location.pathname.includes('onboarding');
    if (onboarding) {
      return LANDING_PAGE_CONFIG.otherInvestor;
    }

    // switching account while being in local scope of a program, submission...
    if (route?.isLocalScope) {
      const topParentRoute = parents?.[0];
      return (
        (!topParentRoute?.isLocalScope ? topParentRoute?.path : false) ||
        LANDING_PAGE_CONFIG.otherInvestor
      );
    }

    // sending nextAccount as parameter === moving to account-centric mode
    if (route.isAllAccountsOnly && nextAccount) {
      return LANDING_PAGE_CONFIG.otherInvestor;
    }

    // nextAccount not present as parameter or null === moving to all-accounts mode
    if (route.isAccountCentricOnly && !nextAccount) {
      return LANDING_PAGE_CONFIG.otherInvestor;
    }

    return null;
  };
};
