import { Col, Row } from 'antd';
import { useTheme } from 'styled-components';

import { DrawerSection } from '@npm/core/ui/components/molecules/DrawerSection';
import {
  type BrokerageFirmAggregate,
  type Codebook,
  type Holding,
  type OrderSourceCode,
  CbOrderEntryType,
} from '@npm/data-access';

import { YourRoleDrawerSection } from '../../../../../auth/user/context/YourRoleDrawerSection';
import { type OrderSizeType } from '../../../../../order';
import { HoldingCard } from '../../../components/HoldingCard';
import { canSelectATSPool } from '../../../utils/order';
import type { Action, FormValues } from '../..';

import { NextStepsSection } from './NextStepsSection';
import { OrderDetailsSection } from './OrderDetailsSection';

import { OrderPlacementConfirmationContainer } from './OrderPlacementConfirmation.styles';

type Props = {
  values: FormValues;
  errorMessage?: string;
  activeAction: Action;
  holding: Holding;
  orderEntryTypes: Codebook[];
  orderSizeType: OrderSizeType;
  brokerageFirm: BrokerageFirmAggregate | undefined;
  source: OrderSourceCode | undefined;
  showNextStepsSection?: boolean;
};

export const OrderPlacementConfirmation = ({
  values,
  errorMessage,
  activeAction,
  holding,
  orderEntryTypes,
  orderSizeType,
  brokerageFirm,
  source,
  showNextStepsSection,
}: Props) => {
  const theme = useTheme();

  const orderType =
    orderEntryTypes.find(({ code }) => code === values?.orderType)?.name ??
    values?.orderType;

  return (
    <OrderPlacementConfirmationContainer>
      <Row gutter={[theme.spacing.md, theme.spacing.lg]}>
        <Col span={24} style={{ paddingTop: theme.spacing.sm }}>
          <YourRoleDrawerSection
            accountId={values?.accountId}
            transactionRole={
              activeAction === 'buy' ? 'Investor' : 'Stakeholder'
            }
          />
        </Col>
        <Col span={24}>
          <OrderDetailsSection
            values={{
              ...values,
              orderType: values?.orderType || CbOrderEntryType.items.ioi,
              assetType:
                activeAction === 'buy'
                  ? values?.assetType
                  : holding?.asset?.type?.code,
            }}
            source={source}
            errorMessage={errorMessage}
            activeAction={activeAction}
            orderSizeType={orderSizeType}
            showATSPool={
              brokerageFirm &&
              canSelectATSPool(values?.visibility, brokerageFirm)
            }
            brokerageFirm={brokerageFirm}
          />
        </Col>
        {holding && (
          <Col span={24}>
            <DrawerSection
              iconName="info-circle"
              title="Your Holding"
              content={<HoldingCard holding={holding} header={true} />}
            />
          </Col>
        )}
        {showNextStepsSection && (
          <Col span={24}>
            <NextStepsSection orderType={orderType} orderSide={activeAction} />
          </Col>
        )}
      </Row>
    </OrderPlacementConfirmationContainer>
  );
};
