/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EventsPermissionedAccountIndex } from '../model';
/**
 * PermissionedAccountApi - axios parameter creator
 * @export
 */
export const PermissionedAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary permissioned accounts for specified event
         * @param {number} eventId 
         * @param {string} [search] Searches [name, external_id, entity_type, groups.name]. Incompatible with other filters.
         * @param {string} [eventPermissionedAccountUserStatus] Filter option, incompatible with other filters.
         * @param {Array<string>} [orderStates] Filter option, incompatible with other filters except for \&#39;additional_requirements\&#39;.
         * @param {Array<string>} [additionalRequirements] Filter option, only applicable when \&#39;pending\&#39; is an order_states filter.
         * @param {Array<string>} [matchedAccounts] Filter option, only applicable when a match activity exists.
         * @param {boolean} [findWithSubmissions] 
         * @param {number} [accountId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionedAccountIndex: async (eventId: number, search?: string, eventPermissionedAccountUserStatus?: string, orderStates?: Array<string>, additionalRequirements?: Array<string>, matchedAccounts?: Array<string>, findWithSubmissions?: boolean, accountId?: number, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('permissionedAccountIndex', 'eventId', eventId)
            const localVarPath = changeUrl(`/api/events/{event_id}/permissioned-accounts`)
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (eventPermissionedAccountUserStatus !== undefined) {
                localVarQueryParameter['event_permissioned_account_user_status'] = eventPermissionedAccountUserStatus;
            }

            if (orderStates) {
                localVarQueryParameter['order_states'] = orderStates.join(COLLECTION_FORMATS.csv);
            }

            if (additionalRequirements) {
                localVarQueryParameter['additional_requirements'] = additionalRequirements.join(COLLECTION_FORMATS.csv);
            }

            if (matchedAccounts) {
                localVarQueryParameter['matched_accounts'] = matchedAccounts.join(COLLECTION_FORMATS.csv);
            }

            if (findWithSubmissions !== undefined) {
                localVarQueryParameter['find_with_submissions'] = findWithSubmissions;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionedAccountApi - functional programming interface
 * @export
 */
export const PermissionedAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermissionedAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary permissioned accounts for specified event
         * @param {number} eventId 
         * @param {string} [search] Searches [name, external_id, entity_type, groups.name]. Incompatible with other filters.
         * @param {string} [eventPermissionedAccountUserStatus] Filter option, incompatible with other filters.
         * @param {Array<string>} [orderStates] Filter option, incompatible with other filters except for \&#39;additional_requirements\&#39;.
         * @param {Array<string>} [additionalRequirements] Filter option, only applicable when \&#39;pending\&#39; is an order_states filter.
         * @param {Array<string>} [matchedAccounts] Filter option, only applicable when a match activity exists.
         * @param {boolean} [findWithSubmissions] 
         * @param {number} [accountId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionedAccountIndex(eventId: number, search?: string, eventPermissionedAccountUserStatus?: string, orderStates?: Array<string>, additionalRequirements?: Array<string>, matchedAccounts?: Array<string>, findWithSubmissions?: boolean, accountId?: number, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsPermissionedAccountIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionedAccountIndex(eventId, search, eventPermissionedAccountUserStatus, orderStates, additionalRequirements, matchedAccounts, findWithSubmissions, accountId, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PermissionedAccountApi - factory interface
 * @export
 */
export const PermissionedAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermissionedAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary permissioned accounts for specified event
         * @param {number} eventId 
         * @param {string} [search] Searches [name, external_id, entity_type, groups.name]. Incompatible with other filters.
         * @param {string} [eventPermissionedAccountUserStatus] Filter option, incompatible with other filters.
         * @param {Array<string>} [orderStates] Filter option, incompatible with other filters except for \&#39;additional_requirements\&#39;.
         * @param {Array<string>} [additionalRequirements] Filter option, only applicable when \&#39;pending\&#39; is an order_states filter.
         * @param {Array<string>} [matchedAccounts] Filter option, only applicable when a match activity exists.
         * @param {boolean} [findWithSubmissions] 
         * @param {number} [accountId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionedAccountIndex(eventId: number, search?: string, eventPermissionedAccountUserStatus?: string, orderStates?: Array<string>, additionalRequirements?: Array<string>, matchedAccounts?: Array<string>, findWithSubmissions?: boolean, accountId?: number, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<EventsPermissionedAccountIndex> {
            return localVarFp.permissionedAccountIndex(eventId, search, eventPermissionedAccountUserStatus, orderStates, additionalRequirements, matchedAccounts, findWithSubmissions, accountId, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for permissionedAccountIndex operation in PermissionedAccountApi.
 * @export
 * @interface PermissionedAccountApiPermissionedAccountIndexRequest
 */
export interface PermissionedAccountApiPermissionedAccountIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly eventId: number

    /**
     * Searches [name, external_id, entity_type, groups.name]. Incompatible with other filters.
     * @type {string}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly search?: string

    /**
     * Filter option, incompatible with other filters.
     * @type {string}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly eventPermissionedAccountUserStatus?: string

    /**
     * Filter option, incompatible with other filters except for \&#39;additional_requirements\&#39;.
     * @type {Array<string>}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly orderStates?: Array<string>

    /**
     * Filter option, only applicable when \&#39;pending\&#39; is an order_states filter.
     * @type {Array<string>}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly additionalRequirements?: Array<string>

    /**
     * Filter option, only applicable when a match activity exists.
     * @type {Array<string>}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly matchedAccounts?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly findWithSubmissions?: boolean

    /**
     * 
     * @type {number}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly accountId?: number

    /**
     * 
     * @type {number}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof PermissionedAccountApiPermissionedAccountIndex
     */
    readonly xUserRoleId?: string
}

/**
 * PermissionedAccountApi - object-oriented interface
 * @export
 * @class PermissionedAccountApi
 * @extends {BaseAPI}
 */
export class PermissionedAccountApi extends BaseAPI {
    /**
     * 
     * @summary permissioned accounts for specified event
     * @param {PermissionedAccountApiPermissionedAccountIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionedAccountApi
     */
    public permissionedAccountIndex(requestParameters: PermissionedAccountApiPermissionedAccountIndexRequest, options?: AxiosRequestConfig) {
        return PermissionedAccountApiFp(this.configuration).permissionedAccountIndex(requestParameters.eventId, requestParameters.search, requestParameters.eventPermissionedAccountUserStatus, requestParameters.orderStates, requestParameters.additionalRequirements, requestParameters.matchedAccounts, requestParameters.findWithSubmissions, requestParameters.accountId, requestParameters.page, requestParameters.size, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type PermissionedAccountIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PermissionedAccountIndexErrorCodes = [
    401,
];


