import React from 'react';
import { Radio } from 'antd';

import { ButtonCard } from '@npm/core/ui/components/atoms/ButtonCard';
import { Flex, InfoIconWrap } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import type { CodebookItem } from '@npm/data-access';

type PurchaserModalQuestionnaireFormProps = {
  questionnaireOptions: CodebookItem[];
};

export const QualifiedInstitutionalBuyerQuestionnaireFormContent: React.FC<
  PurchaserModalQuestionnaireFormProps
> = ({ questionnaireOptions }) => (
  <Flex direction="column" gap="lg">
    <Flex gap="sm">
      <Heading variant="h3">
        One of the following entities, acting for its own account or the
        accounts of other qualified institutional buyers (“QIB”), that in the
        aggregate owns and invests on a discretionary basis at least $100
        million in eligible securities of issuers that are not affiliated with
        the entity:
      </Heading>
      <Tooltip title="The purpose of this questionnaire is to obtain information relating to whether you are a “Qualified Institutional Buyer“ as defined in section (a) of Rule 144A of the Securities Act of 1933 (the “Act”), as amended.">
        <InfoIconWrap>
          <Icon name="info-circle" />
        </InfoIconWrap>
      </Tooltip>
    </Flex>
    <Form.Item name={'qualified_institutional_buyer'}>
      <Radio.Group>
        <Flex direction="column" gap="sm">
          {questionnaireOptions?.map(codebookItem => (
            <ButtonCard
              key={codebookItem.code}
              variant="radio"
              label={codebookItem.name}
              value={codebookItem.code}
            />
          ))}
        </Flex>
      </Radio.Group>
    </Form.Item>
  </Flex>
);
