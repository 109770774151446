import { useTheme } from 'styled-components';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';

type Props = {
  isOpen: boolean;
  searchTerm: string;
  onSubmit: () => void;
  onCancel?: () => void;
};
export const REQUEST_COVERAGE_MODAL_WIDTH = 471;

export const RequestCoverageModal = ({
  isOpen,
  searchTerm,
  onSubmit,
  onCancel,
}: Props) => {
  const theme = useTheme();

  return (
    <Modal
      width={REQUEST_COVERAGE_MODAL_WIDTH}
      title={`Request Coverage for "${searchTerm}"`}
      open={isOpen}
      okText={'Submit'}
      cancelText={'Cancel'}
      onOk={onSubmit}
      onCancel={onCancel}
      bodyStyle={{
        padding: theme.spacing.lg,
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <Text colorVariant={'secondary'}>
        Please click on Submit to request the company coverage and we’ll take a
        look.
      </Text>
    </Modal>
  );
};
