import styled from 'styled-components';

import { Radio as RadioBase } from '@npm/core/ui/components/atoms/Radio';

export const RadioButton = styled(RadioBase)`
  .ant-radio {
    visibility: hidden;
  }
  color: ${({ theme }) => theme.color.info.typography.primary} !important;
`;
