export const WIZARD_CONTENT_WRAPPER_ID = 'wizard-content-wrapper';
export const WIZARD_CONTENT_ID = 'wizard-content';
export const WIZARD_WRAPPER_ID = 'wizard-wrapper';
export const WIZARD_SIDEBAR_ID = 'wizard-sidebar-wrapper';
export const WIZARD_PROGRESS_WRAPPER_ID = 'wizard-progress-wrapper';
export const CONTENT_PADDING = '0 16px';
export const CONTENT_PADDING_MOBILE = '0 8px';
export const FOOTER_HEIGHT = '72px';
export const FOOTER_HEIGHT_MOBILE = '64px';
export const SIDEBAR_WIDTH = '350px';
export const HEADER_HEIGHT = '62px';
export const HEADER_HEIGHT_MOBILE = '48px';
