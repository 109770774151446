import React from 'react';
import { useTheme } from 'styled-components';

import { Icon } from '../../../atoms/Icon';
import { Text } from '../../../atoms/Typography';

import * as S from './ListItem.styles';

interface Props {
  title: string;
}

export const ListItem = ({ title }: Props) => {
  const theme = useTheme();
  return (
    <S.Flex direction="row" align="center" gap="md">
      <Icon
        name="check"
        color={theme.color.success.typography.primary}
        size="xxs"
      />
      <Text size="sm">{title}</Text>
    </S.Flex>
  );
};
