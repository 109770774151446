export const maskCharacter = (
  value: string | null | undefined,
  mask: string,
  visibleCharsNumber: number,
  maskAnchor: 'start' | 'end' = 'end'
) => {
  const absVisibleCharsNumber = Math.abs(visibleCharsNumber);
  if (value === null) {
    return null;
  }
  if (value === undefined) {
    return undefined;
  }

  const charsToMask = value.length - absVisibleCharsNumber;

  if (charsToMask <= 0) {
    return value;
  }

  if (value.length <= charsToMask) {
    return value.replace(/./g, mask);
  }

  let maskedValue: string;
  if (maskAnchor === 'start') {
    // Covers all characters except the last n
    maskedValue = value
      .slice(-absVisibleCharsNumber)
      .padStart(value.length, mask);
  } else {
    // Covers all characters except the first n
    const lastNCharsToMaskRe = new RegExp(`.{${charsToMask}}$`);
    maskedValue = value.replace(lastNCharsToMaskRe, mask.repeat(charsToMask));
  }

  return maskedValue;
};
