import React, { useEffect, useRef } from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { useAccountShow } from '@npm/data-access';

import { type OboModalState } from '../../../OboModal.types';
import { SelectAccountLoadingCard } from '../../SelectAccount/SelectAccountCards/SelectAccountLoadingCard';
import { type SelectNegotiationAccountListProps } from '../SelectNegotiationAccount.types';

import { SelectAccountCardLabels } from './SelectAccountCardLabels';
import { SelectNegotiationAccountCard } from './SelectNegotiationAccountCard';

type Props = {
  handleComplete: (nextOboState: OboModalState) => void;
} & SelectNegotiationAccountListProps;

export const SelectNegotiationAccountCards = ({
  accounts,
  actionFlags,
  selectedAccount,
  handleSelect,
  handleComplete,
  goToRepresentativeStep,
}: Props) => {
  const selectedItemRef = useRef<HTMLDivElement>();

  const investor = accounts?.investorAccountId;
  const stakeholder = accounts?.stakeholderAccountId;
  const needToChooseInvestorOrStakeholder = !!investor && !!stakeholder;

  const { data: investorAccount, isFetching: isInvestorAccountFetching } =
    useAccountShow(
      {
        id: accounts?.investorAccountId?.toString(),
      },
      {
        queryConfig: {
          enabled: !!investor,
        },
      }
    );

  const { data: stakeholderAccount, isFetching: isStakeholderAccountFetching } =
    useAccountShow(
      {
        id: accounts?.stakeholderAccountId?.toString(),
      },
      {
        queryConfig: {
          enabled: !!stakeholder,
        },
      }
    );

  useEffect(() => {
    if (selectedAccount) {
      selectedItemRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <>
      {accounts?.investorAccountId && (
        <Margin
          ref={
            accounts?.investorAccountId === selectedAccount?.id
              ? selectedItemRef
              : null
          }
          bottom="sm"
        >
          {isInvestorAccountFetching ? (
            <SelectAccountLoadingCard />
          ) : (
            investorAccount && (
              <SelectNegotiationAccountCard
                account={investorAccount}
                topContent={
                  needToChooseInvestorOrStakeholder ? (
                    <SelectAccountCardLabels
                      isInvestor
                      actionFlags={actionFlags}
                    />
                  ) : null
                }
                goToRepresentativeStep={goToRepresentativeStep}
                handleSelect={handleSelect}
                handleComplete={handleComplete}
              />
            )
          )}
        </Margin>
      )}
      {accounts?.stakeholderAccountId && (
        <div
          ref={
            accounts?.stakeholderAccountId === selectedAccount?.id
              ? selectedItemRef
              : null
          }
        >
          {isStakeholderAccountFetching ? (
            <SelectAccountLoadingCard />
          ) : (
            stakeholderAccount && (
              <SelectNegotiationAccountCard
                account={stakeholderAccount}
                topContent={
                  needToChooseInvestorOrStakeholder ? (
                    <SelectAccountCardLabels
                      isInvestor={false}
                      actionFlags={actionFlags}
                    />
                  ) : null
                }
                handleSelect={handleSelect}
                handleComplete={handleComplete}
                goToRepresentativeStep={goToRepresentativeStep}
              />
            )
          )}
        </div>
      )}
    </>
  );
};
