import React from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type VenusApi } from '@npm/data-access';

type Props = {
  companyProfile: VenusApi.CompanyProfile | undefined;
};

export const Classifications = ({ companyProfile }: Props) => {
  const renderClassification = (label: string, value: string) =>
    value ? (
      <Text size="sm" colorVariant="primary">
        <Text as="span" size="sm" colorVariant="tertiary">
          {label}:{' '}
        </Text>
        {value}
      </Text>
    ) : null;

  return (
    <>
      {renderClassification('NPM', companyProfile?.industry)}
      {renderClassification(
        'SIC',
        companyProfile?.sic_codes?.map(code => code.label).join(', ')
      )}
      {renderClassification(
        'NAICS',
        companyProfile?.naic_codes?.map(code => code.label).join(', ')
      )}
    </>
  );
};
