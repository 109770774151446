import styled from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';

export const TooltipIconWrapper = styled(Flex)`
  display: inline-flex;
  vertical-align: middle;
  margin-top: ${({ theme }) => -theme.spacing.xs / 2}px;
  margin-left: ${({ theme }) => theme.spacing.xs}px;
`;
