type AvailableLabelConfigOptions = {
  availableCount: number;
  totalCount: number;
  premiumLock: boolean;
};

export const getAvailableLabelConfig = ({
  availableCount,
  totalCount,
  premiumLock,
}: AvailableLabelConfigOptions) => {
  if (!availableCount)
    return {
      iconName: 'x-close',
      label: 'Unavailable',
      variant: 'disabled',
    } as const;

  const availableLabel = `Available${
    totalCount > 1 ? ` (${availableCount}/${totalCount})` : ''
  }`;

  if (premiumLock)
    return {
      iconName: 'lock',
      label: availableLabel,
      variant: 'general',
      showUnlock: true,
    } as const;

  return {
    iconName: 'check',
    label: availableLabel,
    variant: 'info',
  } as const;
};
