import styled, { type DefaultTheme } from 'styled-components';

export const IssueSwitcherSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.sm}px;
  border-radius: ${({ theme }) =>
    `${theme.borderRadius.md}px ${theme.borderRadius.md}px 0 0`};
  background-color: ${({ theme }) => theme.color.general.layout.one};
  border-top: 1px solid ${({ theme }) => theme.color.general.borders.secondary};
  border-right: 1px solid
    ${({ theme }) => theme.color.general.borders.secondary};
  border-left: 1px solid ${({ theme }) => theme.color.general.borders.secondary};
  box-shadow: ${({ theme }) => theme.shadow.level2};
`;

export const getMenuStyle = (
  theme: DefaultTheme,
  isOverlayHeader: boolean
) => ({
  maxHeight: 300,
  overflow: 'auto',
  borderTop: isOverlayHeader
    ? undefined
    : `1px solid ${theme.color.general.borders.secondary}`,
  borderRight: `1px solid ${theme.color.general.borders.secondary}`,
  borderBottom: `1px solid ${theme.color.general.borders.secondary}`,
  borderLeft: `1px solid ${theme.color.general.borders.secondary}`,

  borderRadius: isOverlayHeader
    ? `0 0 ${theme.borderRadius.md}px ${theme.borderRadius.md}px`
    : `${theme.borderRadius.md}px`,
});
