export const PlugRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="37"
      viewBox="0 0 63 37"
      fill="none"
    >
      <path
        d="M20.7276 9.70709H1.83745C0.823103 9.70709 0 8.88398 0 7.87151C0 6.85716 0.823103 6.03406 1.83745 6.03406H20.7276C21.7419 6.03406 22.5631 6.85716 22.5631 7.87151C22.5631 8.88398 21.7419 9.70709 20.7276 9.70709Z"
        fill="currentColor"
      />
      <path
        d="M20.7276 30.1328H1.83745C0.823103 30.1328 0 29.3116 0 28.2972C0 27.2829 0.823103 26.4617 1.83745 26.4617H20.7276C21.7419 26.4617 22.5631 27.2848 22.5631 28.2972C22.5631 29.3097 21.7419 30.1328 20.7276 30.1328Z"
        fill="currentColor"
      />
      <path
        d="M23.4686 34.7465H37.9807L37.9807 1.41922H23.4686L23.4686 34.7465Z"
        fill="#607D94"
      />
      <path
        d="M37.981 34.7472L51.0156 22.7869V13.3802L37.981 1.41992V34.7472Z"
        fill="#4F697D"
      />
      <path
        d="M69.9789 20.9099L51.0156 22.6518V13.3801L69.9789 15.122C70.427 15.1632 70.7701 15.5382 70.7701 15.9882V20.0437C70.7701 20.4937 70.427 20.8687 69.9789 20.9099Z"
        fill="#4F697D"
      />
      <path
        d="M62.125 14.4001L64.0506 14.5764V21.4556L62.125 21.6318V14.4001Z"
        fill="#1E3545"
      />
      <path
        d="M57.7786 14.0009L59.706 14.179V21.855L57.7786 22.0313V14.0009Z"
        fill="#1E3545"
      />
      <path
        d="M53.4343 13.6014L55.3599 13.7796V22.2525L53.4343 22.4306V13.6014Z"
        fill="#1E3545"
      />
      <path
        d="M20.9807 36.1664L23.4669 34.7471V1.41983L20.9807 0.000488281V36.1664Z"
        fill="#4F697D"
      />
      <path
        d="M16.1438 36.1659H20.9812L20.9812 -1.90735e-05H16.1438L16.1438 36.1659Z"
        fill="#607D94"
      />
      <path
        d="M27.003 25.2505C26.4836 25.2505 26.0618 24.8286 26.0618 24.3093V11.8577C26.0618 11.3384 26.4836 10.9165 27.003 10.9165C27.5224 10.9165 27.9442 11.3384 27.9442 11.8577V24.3111C27.9423 24.8286 27.5224 25.2505 27.003 25.2505Z"
        fill="#1E3545"
      />
      <path
        d="M30.7662 25.2505C30.2468 25.2505 29.825 24.8286 29.825 24.3093V11.8577C29.825 11.3384 30.2468 10.9165 30.7662 10.9165C31.2855 10.9165 31.7074 11.3384 31.7074 11.8577V24.3111C31.7055 24.8286 31.2855 25.2505 30.7662 25.2505Z"
        fill="#1E3545"
      />
      <path
        d="M34.5274 25.2505C34.008 25.2505 33.5862 24.8286 33.5862 24.3093V11.8577C33.5862 11.3384 34.008 10.9165 34.5274 10.9165C35.0468 10.9165 35.4686 11.3384 35.4686 11.8577V24.3111C35.4686 24.8286 35.0468 25.2505 34.5274 25.2505Z"
        fill="#1E3545"
      />
    </svg>
  );
};
