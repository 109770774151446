import { Codebooks } from './codebooks.types';

const Items = {
  Marketplace: 'marketplace',
  Program: 'program',
} as const;

export const CbOrderEntryCategory = {
  code: Codebooks.ORDER_ENTRY_CATEGORY,
  items: Items,
};
