import { matchPath } from 'react-router';

import { type MenuItem } from '../components/organisms/Layout';
import { type FeatureFlagDefinition } from '../services/featureFlags/featureFlags.types';

import { type RouteDefinition } from './router.types';

export const findRoute = (routes: RouteDefinition[], pathname: string) => {
  return findRouteRecursive([], routes, pathname);
};

const findRouteRecursive = (
  parents: RouteDefinition[],
  routes: RouteDefinition[],
  pathname: string
): { parents: RouteDefinition[]; route: RouteDefinition } | null => {
  for (const route of routes) {
    if (
      matchPath(pathname, {
        path: route.path,
        exact: true,
      })
    ) {
      return {
        parents,
        route,
      };
    }
    if (route.subRoutes) {
      const routeDef = findRouteRecursive(
        [...parents, route],
        route.subRoutes,
        pathname
      );
      if (routeDef) {
        return routeDef;
      }
    }
  }
  return null;
};

export const filterRoutesByFeatureFlag = <
  T extends Pick<RouteDefinition, 'featureFlag' | 'subRoutes'>
>(
  isEnabled: (featureFlag: FeatureFlagDefinition) => boolean,
  items: T[]
) => {
  return items
    .map(i => {
      return {
        ...i,
        subRoutes: i?.subRoutes
          ? filterRoutesByFeatureFlag(isEnabled, i.subRoutes)
          : i?.subRoutes,
      };
    })
    .filter(i => !i.featureFlag || isEnabled(i.featureFlag));
};

export const filterMenuItemsByFeatureFlag = <
  T extends {
    featureFlag?: FeatureFlagDefinition | undefined;
    children?: MenuItem[];
  }
>(
  isEnabled: (featureFlag: FeatureFlagDefinition) => boolean,
  items: T[]
) => {
  return items
    .map(i => {
      return {
        ...i,
        children: i?.children
          ? filterMenuItemsByFeatureFlag(isEnabled, i.children)
          : i?.children,
      };
    })
    .filter(i => {
      return !i.featureFlag || isEnabled(i.featureFlag);
    });
};

export const getAllRoutesRecursively = (routes: RouteDefinition[]) => {
  const result: RouteDefinition[] = [];
  routes.forEach(route => {
    result.push(route);
    if (route.subRoutes?.length)
      result.push(...getAllRoutesRecursively(route.subRoutes));
  });
  return result;
};
