import styled from 'styled-components';

import { NoDataArea as NoDataAreaBase } from '@npm/core/ui/components/atoms/NoDataArea';

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.general.borders.secondary};
`;

export const OrderPlacementFormContainer = styled.div`
  label {
    color: ${({ theme }) => theme.color.general.typography.primary};
  }
`;

export const NoDataArea = styled(NoDataAreaBase)`
  margin-top: ${({ theme }) => theme.spacing.lg}px;
`;
