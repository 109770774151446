import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { useTheme } from 'styled-components';

import {
  CrosshairPlugin,
  formatAxisNumber,
  useChartOptions,
} from '@npm/core/ui/components/atoms/Charts';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type VenusApi } from '@npm/data-access';

import { useCompanyOverviewChartFilter } from '../../CompanyOverview.hooks';
import { CompanyOverviewChartFilters } from '../../components/CompanyOverviewChartFilters/CompanyOverviewChartFilters';

import {
  prepareDataset,
  showChartTooltip,
  TOOLTIP_HEIGHT,
  TOOLTIP_WIDTH,
  useMinAndMaxDate,
} from './FinancingHistoryChart.utils';
import { FinancingHistoryChartTooltip } from './FinancingHistoryChartTooltip';

import * as S from './FinancingHistoryChart.styles';

export const CHART_ID = 'financing-history-chart';

type Props = {
  data: VenusApi.FinancialActivity[];
};

export const FinancingHistoryChart = memo(function ValuationsChart({
  data,
}: Props) {
  const theme = useTheme();
  const { isDarkMode } = useDarkModeContext();
  const { isMobile } = useBreakpoints();

  const [minDate, maxDate] = useMinAndMaxDate(data);

  const {
    setCurrentPage,
    currentMinDate,
    currentMaxDate,
    currentPage,
    pageCount,
    range,
    setRange,
  } = useCompanyOverviewChartFilter(minDate, maxDate);

  const [hoverIndex, setHoverIndex] = useState<number>();

  const dataset = useMemo(
    () => prepareDataset(data, currentMinDate, currentMaxDate),
    [data, currentMinDate, currentMaxDate]
  );

  const canvasRef = useRef<HTMLCanvasElement>();
  const chartRef = useRef<Chart<'line' | 'bar', (string | number)[]>>();

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  };

  const options = useChartOptions();

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    chartRef.current = new Chart(ctx, {
      type: 'bar',
      options: options({
        scales: {
          y: {
            min: 0,
            ticks: {
              maxTicksLimit: 7,
              callback: value => formatAxisNumber(value),
              color: theme.color.general.typography.secondary,
            },
          },
          x: {
            type: 'category',
            ticks: {
              minRotation: isMobile ? 30 : undefined,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            external: function (context) {
              showChartTooltip(context, setHoverIndex);
            },
            callbacks: {
              title: () => TOOLTIP_WIDTH,
              label: () => '',
              beforeBody: () => TOOLTIP_HEIGHT,
              afterBody: () => ' ',
              footer: () => '',
            },
          },
        },
      }),
      data: {
        labels: dataset.map(row => row.x),
        datasets: [
          {
            data: dataset.map(row => row.y),
            stepped: true,
            borderColor: theme.color.yellow_40,
            backgroundColor: theme.color.info.backgrounds.primaryDefault,
            hoverBackgroundColor: theme.color.info.borders.primary,
          },
        ],
      },
      plugins: [CrosshairPlugin],
    });

    return () => destroyChart();
  }, [theme, isDarkMode, dataset, options, isMobile]);

  return (
    <Card noContentPadding>
      <S.Header>
        <CompanyOverviewChartFilters
          range={range}
          onChangeRange={range => {
            setRange(range);
          }}
          pagination={{
            page: currentPage,
            totalPages: pageCount,
            onPageChange: val => {
              setCurrentPage(val);
              setHoverIndex(undefined);
            },
          }}
        />
      </S.Header>
      <S.Body>
        <S.ChartContainer>
          <canvas ref={canvasRef}></canvas>
          <div id={CHART_ID} className="html-chart-legend">
            <FinancingHistoryChartTooltip
              activity={dataset[hoverIndex]?.activity}
            />
          </div>
          <S.Legend>
            <S.LegendSquare />
            Investment Amount
          </S.Legend>
        </S.ChartContainer>
      </S.Body>
    </Card>
  );
});
