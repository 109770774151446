import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { handleValidationError } from '@npm/core/ui/utils/form';
import {
  CbAccountType,
  useAccountShow,
  useAccountUpdate,
  useOrganizationShow,
  usePersonShow,
} from '@npm/data-access';

import { type TaxInformationFormType } from './TaxInformation.types';

export const useTaxInformationForm = (
  accountId: number,
  { onUpdate }: { onUpdate?: () => void }
) => {
  const [form] = Form.useForm();
  const { withShowApiErrorMiddleware } = useAlerts();

  const { data, isLoading } = useAccountShow(
    { id: accountId?.toString() },
    { queryConfig: { enabled: !!accountId } }
  );

  const isPersonAccount =
    data?.type?.code === CbAccountType.items.PersonAccount;

  const { data: personData, isFetching: isPersonLoading } = usePersonShow(
    { id: data?.person_id },
    {
      queryConfig: {
        enabled: !!data?.person_id && isPersonAccount,
      },
    }
  );

  const { data: orgData, isFetching: isOrgLoading } = useOrganizationShow(
    { id: data?.organization_id },
    {
      queryConfig: {
        enabled: !!data?.organization_id && !isPersonAccount,
      },
    }
  );

  const { execute, isLoading: isUpdating } = useAccountUpdate();

  const handleSubmit = async () => {
    let values: TaxInformationFormType;
    try {
      values = await form.validateFields();
    } catch (error) {
      handleValidationError(form, error, false);
      return;
    }

    try {
      await withShowApiErrorMiddleware(execute)({
        id: accountId,
        accountUpdateRequestContract: {
          tax_document: { code: values.code },
        },
      });

      onUpdate?.();
    } catch (error) {
      console.error(error);
    }
  };

  return {
    form,
    data,
    handleSubmit,
    isLoading: isLoading || isOrgLoading || isPersonLoading,
    isUpdating,
    isW9Disabled: isPersonAccount
      ? personData?.tax_id_exempt
      : orgData?.tax_id_exempt,
  };
};
