import { useMemo, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { reduce } from 'lodash';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { handleValidationError } from '@npm/core/ui/utils/form';
import {
  type Answer,
  getApiErrorCode,
  useEntityAccountAccreditationCreate,
  useEntityAccountAccreditationShow,
} from '@npm/data-access';

import {
  type AccreditationFormFields,
  type SignatureFormFields,
} from '../Accreditation.types';

import {
  type WhitelistedAnswerCodes,
  whitelistedAnswerCodes,
} from './EntityAccreditation.types';

export const useEntityAccreditationForm = (
  accountId: number,
  { onUpdate }: { onUpdate?: () => void }
) => {
  const handleError = useErrorHandler();

  const [form] = Form.useForm<AccreditationFormFields>();
  const [signatureForm] = Form.useForm<SignatureFormFields>();
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);

  const { withShowApiErrorMiddleware } = useAlerts();

  const { data: accreditationData, isLoading } =
    useEntityAccountAccreditationShow(
      { accountId },
      {
        queryConfig: {
          enabled: !!accountId,
          onError: e => {
            if (getApiErrorCode(e) !== 404) {
              handleError(e);
            }
          },
        },
      }
    );

  const filteredAnswers = useMemo<
    Partial<Record<WhitelistedAnswerCodes, Answer>>
  >(() => {
    const visibleAnswers = accreditationData?.answers?.filter(ans =>
      whitelistedAnswerCodes.has(ans.field?.code as WhitelistedAnswerCodes)
    );

    return reduce(
      visibleAnswers,
      (acc, ans) => {
        return {
          ...acc,
          [ans.field?.code]: ans,
        };
      },
      {}
    );
  }, [accreditationData]);

  const { execute, isLoading: isUpdating } =
    useEntityAccountAccreditationCreate();

  const handleOpenSignModal = async () => {
    try {
      await form.validateFields();
      setIsSignatureModalOpen(true);
    } catch (e) {
      handleValidationError(form, e, true);
    }
  };

  const handleSubmit = async () => {
    let values: AccreditationFormFields;
    let signValues: SignatureFormFields;

    try {
      values = await form.validateFields();
      signValues = await signatureForm.validateFields();
    } catch (e) {
      return console.error('Failed:', e);
    }

    try {
      await withShowApiErrorMiddleware(execute)({
        accountId,
        accountsAccreditationCreateRequestContract: {
          answers: [
            ...Object.entries(values)
              .filter(([, value]) => !!value)
              .map(([key, value]) => ({
                field: {
                  code: key,
                },
                answer: {
                  code: value,
                },
              })),
            // TODO: `entity_rep_title` and `other_type` are text inputs, shouldn't be sent as codebooks -> solve with BE
            {
              field: {
                book: 'ACCREDITATION_LABEL',
                code: 'entity_rep_title',
                name: 'Title of Authorized Person',
              },
              answer: {
                book: null,
                code: signValues.entity_rep_title,
                name: signValues.entity_rep_title,
              },
            },
          ],
          signature: signValues.signature,
        },
      });

      onUpdate();
      setIsSignatureModalOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    form,
    signatureForm,
    data: accreditationData,
    filteredAnswers,
    handleOpenSignModal,
    closeSignModal: () => setIsSignatureModalOpen(false),
    handleSubmit,
    isLoading,
    isUpdating,
    isSignatureModalOpen: isSignatureModalOpen,
  };
};
