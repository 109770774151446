import React from 'react';

import { Button } from '../Button';
import { Icon } from '../Icon';
import { Text } from '../Typography';

type Props = {
  error: unknown; // Reserved just in case we ever want to show some details.
  refetch?: () => void;
};

export const SelectError = ({
  refetch = () => window.location.reload(),
}: Props) => {
  return (
    <>
      <Text size="sm" marginBottom="md">
        We were not able to load results for your request. If the error
        persists, please,{' '}
        <Text size="sm" onClick={() => null}>
          {/* TODO: NFE-1147 */}
          contact our support
        </Text>
        .
      </Text>
      {refetch && (
        <Button
          variant="outline"
          size="md"
          icon={<Icon name="refresh" />}
          onClick={refetch}
        >
          Refresh
        </Button>
      )}
    </>
  );
};
