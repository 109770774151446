import { type WebViewerInstance } from '@pdftron/webviewer';

import {
  APP_CFG_API_URL,
  APP_CFG_SECURED_DOCUMENT_VIEW_ID,
  APP_CFG_SECURED_DOCUMENT_VIEW_PASSWORD,
} from '@npm/config';
import { getAxiosInstance } from '@npm/data-access';
import { oktaAuth } from '@npm/okta';

import { type DocumentWithUrl } from './DocumentViewer.types';

export const loadFromUrl = async (
  instance: WebViewerInstance,
  document: DocumentWithUrl
) => {
  instance.UI.loadDocument(document.url, {
    filename: document.filename,
    onError: err => {
      console.error(err);
    },
  });
};

export const loadSecuredFile = (instance: WebViewerInstance, path: string) => {
  const axiosInstance = getAxiosInstance();
  const headers = axiosInstance.defaults.headers;

  const token = oktaAuth.getAccessToken();
  const options = {
    customHeaders: {
      Authorization: `Bearer ${token.toString()}`,
      ...(headers['X-Workstation'] && {
        'X-Workstation': headers['X-Workstation'],
      }),
      ...(headers['X-User-Role-Id'] && {
        'X-User-Role-Id': headers['X-User-Role-Id'],
      }),
      ...(headers['X-Across-Accounts'] && {
        'X-Across-Accounts': headers['X-Across-Accounts'],
      }),
      ...(headers['X-Obo-Account-Id'] && {
        'X-Obo-Account-Id': headers['X-Obo-Account-Id'],
      }),
      ...(headers['X-Obo-User-Id'] && {
        'X-Obo-User-Id': headers['X-Obo-User-Id'],
      }),
    },
  };

  instance.UI.loadDocument(`${APP_CFG_API_URL}${path}`, {
    ...options,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - customHandlerId is not in the types
    customHandlerId: APP_CFG_SECURED_DOCUMENT_VIEW_ID,
    password: APP_CFG_SECURED_DOCUMENT_VIEW_PASSWORD,
    onError: err => {
      console.error(err);
    },
  });
};
