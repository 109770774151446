/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  NoteApi,
  NoteIndexErrorCodes,
  NoteIndexErrorTypes,
  NoteCreateErrorCodes,
  NoteShowErrorCodes,
  NoteShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new NoteApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type NoteIndexMethod = NoteApi['noteIndex'];
export type NoteIndexResponseType = MethodResult<NoteIndexMethod>;

export const useNoteIndex = (
  variables: Parameters<NoteIndexMethod>[0],
  config?: UseQueryConfig<
    NoteIndexMethod,
    NoteIndexErrorTypes
  >
) => {
  return useQuery<
    NoteIndexMethod,
    NoteIndexErrorTypes
  >(
    (...args) => api.noteIndex(...args),
    NoteIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NoteIndex'],
        ...apiQueryConfig.NoteIndex,
        ...config?.queryConfig
      },
    },
    'NoteIndex',
  );
};

export const useNoteIndexInfinite = (
  variables: Parameters<NoteIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    NoteIndexMethod,
    NoteIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    NoteIndexMethod,
    NoteIndexErrorTypes
  >(
    (...args) => api.noteIndex(...args),
    NoteIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NoteIndex', 'NoteIndexInfinite'],
        ...apiQueryConfig.NoteIndex,
        ...config?.queryConfig,
      },
    },
    'NoteIndex',
  );
};

export const useNoteIndexLazy = (baseOptions?: {
  variables?: Parameters<NoteIndexMethod>[0],
  config?: UseQueryConfig<
    NoteIndexMethod,
    NoteIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    NoteIndexMethod,
    NoteIndexErrorTypes
  >(
    (...args) => api.noteIndex(...args),
    NoteIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['NoteIndex'],
        ...apiQueryConfig.NoteIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'NoteIndex',
  );
};


export type NoteCreateMethod = NoteApi['noteCreate'];

export const useNoteCreate = () => {
  return useMutation<NoteCreateMethod>(
    (...args) => api.noteCreate(...args),
    NoteCreateErrorCodes,
    'NoteCreate',
    '/api/notes',
    'post'
  );
};

export type NoteShowMethod = NoteApi['noteShow'];
export type NoteShowResponseType = MethodResult<NoteShowMethod>;

export const useNoteShow = (
  variables: Parameters<NoteShowMethod>[0],
  config?: UseQueryConfig<
    NoteShowMethod,
    NoteShowErrorTypes
  >
) => {
  return useQuery<
    NoteShowMethod,
    NoteShowErrorTypes
  >(
    (...args) => api.noteShow(...args),
    NoteShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NoteShow'],
        ...apiQueryConfig.NoteShow,
        ...config?.queryConfig
      },
    },
    'NoteShow',
  );
};

export const useNoteShowInfinite = (
  variables: Parameters<NoteShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    NoteShowMethod,
    NoteShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    NoteShowMethod,
    NoteShowErrorTypes
  >(
    (...args) => api.noteShow(...args),
    NoteShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NoteShow', 'NoteShowInfinite'],
        ...apiQueryConfig.NoteShow,
        ...config?.queryConfig,
      },
    },
    'NoteShow',
  );
};

export const useNoteShowLazy = (baseOptions?: {
  variables?: Parameters<NoteShowMethod>[0],
  config?: UseQueryConfig<
    NoteShowMethod,
    NoteShowErrorTypes
  >
}) => {
  return useLazyQuery<
    NoteShowMethod,
    NoteShowErrorTypes
  >(
    (...args) => api.noteShow(...args),
    NoteShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['NoteShow'],
        ...apiQueryConfig.NoteShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'NoteShow',
  );
};

