import React from 'react';

import { ExpandablePanel } from '../../../atoms/ExpandablePanel';
import { Icon } from '../../../atoms/Icon';
import { Text } from '../../../atoms/Typography';

import * as S from './Disclosure.styles';

export const Disclosure = () => {
  return (
    <S.Wrapper>
      <ExpandablePanel header="Please read this important legal disclosure">
        <Text size="sm" marginBottom="md">
          Securities related services are offered through NPM Securities, LLC, a
          registered broker-dealer and alternative trading system, which is a
          member FINRA/SIPC
        </Text>
        <S.Link
          href="https://www.nasdaqprivatemarket.com/disclosures-disclaimer/"
          target="_blank"
          rel="noreferrer"
        >
          <Icon name="external-link" size="xs" />
          Read More
        </S.Link>
      </ExpandablePanel>
    </S.Wrapper>
  );
};
