import * as S from './Drawer.styles';

type Props = {
  children: React.ReactNode;
};

export const DrawerSectionWithBackground = ({ children }: Props) => {
  return (
    <S.DrawerSectionBackgroundContainer>
      {children}
    </S.DrawerSectionBackgroundContainer>
  );
};
