import styled, { type ColorGroup } from 'styled-components';

import {
  applyFontSizeShrinkCss,
  Heading1Styles,
  Text,
  TextNumberStyles,
} from '../../atoms/Typography';

import { type BigNumberValueProps } from './BigNumberValue';

export const IconWrapper = styled.span<{
  $helpIconColor: ColorGroup;
}>`
  color: ${({ theme, $helpIconColor }) =>
    theme.color[$helpIconColor].typography.primary};
  cursor: help;
  vertical-align: middle;
`;

export const BigText = styled(Text)<{
  $variant: BigNumberValueProps['variant'];
  $valueLength?: number;
}>`
  ${({ $variant }) => ($variant === 'xl' ? TextNumberStyles : Heading1Styles)};
  ${({ $valueLength, theme }) =>
    $valueLength &&
    applyFontSizeShrinkCss($valueLength, theme.typography.size.xxl)};
`;
