import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import {
  type ColumnType,
  Table,
} from '@npm/core/ui/components/molecules/Table';
import { type Accreditation } from '@npm/data-access';

import {
  DocumentViewerModal,
  useDocumentViewer,
} from '../../../../documents/DocumentViewer';

import { UploadedDocumentViewAlert } from './Alert';

const getTableColumns = (
  brokerageFirmName: string,
  openDocumentViewer: (id?: number) => void
): ColumnType<Accreditation>[] => [
  {
    key: 'name',
    title: 'Name',
    render: (_, { documents }) => (
      <div style={{ wordBreak: 'break-all' }}>
        {documents?.[0]?.display_name}
      </div>
    ),
  },
  {
    key: 'broker',
    title: 'Broker',
    render: () => brokerageFirmName,
  },
  {
    key: 'open-document',
    title: '',
    render: (_, { documents }) => (
      <Flex justify="flex-end" gap="sm">
        <Button
          variant="outline"
          icon={<Icon name="external-link" />}
          onClick={() => openDocumentViewer(documents?.[0]?.id)}
        >
          Open
        </Button>
      </Flex>
    ),
  },
];

type Props = {
  data: Accreditation;
  brokerageFirmName: string;
};

export const UploadedDocumentView = ({ data, brokerageFirmName }: Props) => {
  const { documentViewerModalProps, showNpmDocument } = useDocumentViewer();

  return (
    <>
      <Table
        columns={getTableColumns(brokerageFirmName, showNpmDocument)}
        dataSource={[data]}
      />
      <DocumentViewerModal {...documentViewerModalProps} />
    </>
  );
};

UploadedDocumentView.Alert = UploadedDocumentViewAlert;
