import { useMemo } from 'react';
import { type TimePickerProps, Col, Row } from 'antd';
import { endOfDay, startOfDay } from 'date-fns';
import moment from 'moment';
import { useTheme } from 'styled-components';

import { useBreakpoints } from '../../../../hooks/useBreakpoints';
import { Icon } from '../../Icon';
import { DatePickerInput } from '../DatePickerInput';

import { getDisabledTime } from './DateTimePickerInput.utils';

import { Panel, TimePicker } from './DateTimePickerInput.styles';

type Props = {
  value?: Date | null | undefined;
  onChange?: (value: Date | null | undefined) => void;
  defaultTime?: 'endOfDay' | 'startOfDay';
  min?: Date;
  max?: Date;
  allowClear?: boolean;
  disabled?: boolean;
};

export const DateTimePickerInput = ({
  value,
  onChange,
  allowClear,
  defaultTime,
  min,
  max,
  disabled,
}: Props) => {
  const theme = useTheme();

  const { isMobile, isTablet } = useBreakpoints();
  const inputReadOnly = isMobile || isTablet;

  const handleDateChange = (value: Date | null | undefined) => {
    if (!value) {
      onChange?.(null);
      return;
    }
    let defaulted = value;
    if (defaultTime === 'endOfDay') {
      defaulted = endOfDay(value);
    }
    if (defaultTime === 'startOfDay') {
      defaulted = startOfDay(value);
    }
    if (min && defaulted < min) {
      defaulted = min;
    }
    if (max && defaulted > max) {
      defaulted = max;
    }
    onChange?.(defaulted);
  };

  const disabledTime: TimePickerProps['disabledTime'] = useMemo(
    () => getDisabledTime(value, min, max),
    [value, min, max]
  );

  const handleTimeSelect = (timeValue: moment.Moment) => {
    if (!timeValue) {
      onChange?.(null);
      return;
    }
    if (value) {
      timeValue.set({
        year: value.getFullYear(),
        month: value.getMonth(),
        date: value.getDate(),
      });
    }
    let defaulted = timeValue.toDate();
    if (min && defaulted < min) {
      defaulted = min;
    }
    if (max && defaulted > max) {
      defaulted = max;
    }
    onChange?.(defaulted);
  };

  return (
    <Row gutter={[theme.spacing.sm, theme.spacing.sm]}>
      <Col span={12}>
        <DatePickerInput
          value={value}
          onChange={handleDateChange}
          allowClear={allowClear}
          disabledDate={date =>
            (!!max && date > endOfDay(max)) || (!!min && date < startOfDay(min))
          }
          inputReadOnly={inputReadOnly}
          disabled={disabled}
        />
      </Col>
      <Col span={12}>
        <TimePicker
          value={value && moment(value)}
          onChange={handleTimeSelect}
          format={['hh:mm A', 'h:m a']}
          placeholder="hh:mm AM/PM"
          panelRender={panel => <Panel>{panel}</Panel>}
          suffixIcon={<Icon name="clock" />}
          use12Hours
          inputReadOnly={inputReadOnly}
          showNow={false}
          allowClear={false}
          disabledTime={disabledTime}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};
