import { type Dispatch, useMemo, useState } from 'react';
import {
  type UrlUpdateType,
  StringParam,
  useQueryParams,
} from 'use-query-params';

import { useHistoryStore } from '../../../molecules/Link';
import {
  type ContextAction,
  type OnNextPayload,
  type WizardContext,
  ContextActionType,
} from '../Wizard.types';
import {
  findNextStepAndSubstep,
  findPrevStepAndSubstep,
  getStepKeys,
} from '../Wizard.utils';

type Props<T> = {
  context: WizardContext<T>;
  updateContext: Dispatch<ContextAction<T>>;
  onComplete?: () => void;
  onNext?: (payload?: OnNextPayload<T>) => Promise<void> | void;
};

export const useGoToStepInWizard = <T>({
  context,
  updateContext,
  onComplete,
  onNext,
}: Props<T>) => {
  const [isNavigating, setIsNavigating] = useState(false);
  const isNavigationBlocked = useHistoryStore(store => store.isBlocked);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_queryParams, setQueryParams] = useQueryParams({
    step: StringParam,
    substep: StringParam,
  });

  const { nextStep, nextSubstep } = useMemo(() => {
    return findNextStepAndSubstep(
      context.steps,
      context.stepIndex,
      context.substepIndex
    );
  }, [context.steps, context.stepIndex, context.substepIndex]);

  const next = async (payload?: OnNextPayload<unknown>) => {
    setIsNavigating(true);

    await onNext?.(payload?.attributes);

    updateContext?.({
      type: ContextActionType.MOVE_NEXT,
      config: {
        onComplete,
      },
    });

    if (
      payload?.context &&
      // avoid storing onClick Event in context
      payload?.constructor?.name !== 'SyntheticBaseEvent'
    ) {
      updateContext?.({
        type: ContextActionType.UPDATE_DATA,
        payload: payload?.context,
      });
    }

    if (nextStep?.definition) {
      setQueryParams({
        step: nextStep.definition.key,
        substep: nextSubstep?.definition?.key || undefined,
      });
    }

    setIsNavigating(false);
  };

  const back = () => {
    setIsNavigating(true);

    const { prevStep, prevSubstep } = findPrevStepAndSubstep(
      context.steps,
      context.stepIndex,
      context.substepIndex
    );

    setQueryParams({
      step: prevStep?.definition?.key || undefined,
      substep: prevSubstep?.definition?.key || undefined,
    });

    if (!isNavigationBlocked) {
      // If navigation is blocked, we still want to setQueryParams,
      // which will trigger the discard modal. However, updating the context
      // would cause the wizard to move immidiately without the discard modal.
      updateContext?.({
        type: ContextActionType.MOVE_BACK,
      });
    }

    setIsNavigating(false);
  };

  const goToStep = (
    step: string | number,
    substep?: string | number,
    urlUpdateType?: UrlUpdateType
  ) => {
    updateContext?.({
      type: ContextActionType.UPDATE_STEP_INDEX,
      payload: {
        step,
        substep,
      },
      config: {
        onComplete,
      },
    });

    const { stepKey, substepKey } = getStepKeys(context.steps, step, substep);

    setQueryParams(
      {
        step: stepKey,
        substep: substepKey,
      },
      urlUpdateType
    );
  };

  const resetQueryParams = () => {
    setQueryParams({ step: undefined, substep: undefined });
  };

  return {
    next,
    back,
    isNavigating,
    goToStep,
    resetQueryParams,
    nextStep,
    nextSubstep,
  };
};
