import { type CbLabelComponent, type LabelVariant } from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  active: 'active',
  pending: 'pending',
} as const;

type Keys = keyof typeof Items;

const LabelMap: Record<Keys, LabelVariant> = {
  active: 'success',
  pending: 'warning',
};

export const CbUserStatus = {
  code: Codebooks.USER_STATUS,
  items: Items,
  getLabel: (Component: CbLabelComponent, codebookItem: CodebookItem) =>
    Component({
      variant: LabelMap[(codebookItem?.code as Keys) ?? 'none'],
      label: codebookItem?.name,
    }),
};
