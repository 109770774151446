import styled from 'styled-components';

import { Button } from '../../atoms/Button';

export const MobileButton = styled(Button)`
  display: flex;
  alignself: flex-start;
  width: fit-content;
  margin-bottom: ${({ theme }) => theme.spacing.xxl}px;
`;
