import React, { type ComponentProps, useMemo } from 'react';

import { Select } from '@npm/core/ui/components/atoms/Select';
import {
  type CodebookItems,
  type CodebookShow,
  useCodebook,
} from '@npm/data-access';

type Props = {
  codebook: CodebookItems;
  prefix?: string;
  filterOptions?: (
    value: CodebookShow,
    index: number,
    array: CodebookShow[]
  ) => boolean;
} & ComponentProps<typeof Select>;

export const CodebooksSelect = ({
  codebook,
  prefix,
  filterOptions,
  showSearch = false,
  ...selectProps
}: Props) => {
  const { data, isLoading, error } = useCodebook(
    {
      type: codebook,
      prefix,
    },
    { queryConfig: { enabled: !!codebook }, onError: Select.onError }
  );

  const options = useMemo(() => {
    if (!data) return null;

    let codebooks = data.codebooks;
    if (filterOptions) {
      codebooks = codebooks.filter(filterOptions);
    }

    return [
      ...codebooks.map(({ name, code }) => ({
        label: name,
        value: code,
      })),
    ];
  }, [data, filterOptions]);

  return (
    <Select
      allowClear
      loading={isLoading}
      placeholder={options ? `Select (${options.length})` : 'Select'}
      options={options}
      error={error}
      showSearch={showSearch}
      {...selectProps}
    />
  );
};
