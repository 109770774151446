import { getDay, parseISO } from 'date-fns';

import { type CompanyOverviewChartSeries } from '../../../CompanyOverview.types';
import { type TapeDValue } from '../../TapeD.types';

export const convertTapeDToChartData = (
  data: TapeDValue[],
  series: CompanyOverviewChartSeries
): {
  midEstimateData: { x: string; y: number; raw: { data: TapeDValue } }[];
  lowEstimateData: { x: string; y: number; raw: { data: TapeDValue } }[];
  highEstimateData: { x: string; y: number; raw: { data: TapeDValue } }[];
  valuationData: { x: string; y: number; raw: { data: TapeDValue } }[];
} => {
  if (!data?.length) {
    return {
      midEstimateData: [],
      lowEstimateData: [],
      highEstimateData: [],
      valuationData: [],
    };
  }

  const isPPS = series === 'PPS';

  const midEstimateData = data.map(value => ({
    x: value.date,
    y: isPPS ? value.mid_estimate : value.mid_implied_valuation,
    raw: {
      data: value,
    },
  }));

  const lowEstimateData = data.map(value => ({
    x: value.date,
    y: isPPS ? value.low_estimate : value.low_implied_valuation,
    raw: {
      data: value,
    },
  }));

  const highEstimateData = data.map(value => ({
    x: value.date,
    y: isPPS ? value.high_estimate : value.high_implied_valuation,
    raw: {
      data: value,
    },
  }));

  const valuationData = data.map(value => ({
    x: value.date,
    y: isPPS ? value.last_round_price : value.last_round_valuation,
    raw: {
      data: value,
    },
  }));

  return {
    midEstimateData,
    lowEstimateData,
    highEstimateData,
    valuationData,
  };
};

export const TAPE_D_CHART_HTML_ID = 'tape-d-chart';

export const getTapeDChartTooltip = (canvas: HTMLCanvasElement) => {
  return canvas.parentNode.querySelector(
    `#${TAPE_D_CHART_HTML_ID}`
  ) as HTMLElement;
};

export const showTapeDChartTooltip = (
  context: {
    canvas: HTMLCanvasElement;
  },
  selectedPoint: {
    x: number;
    y: number;
  }
) => {
  const { canvas } = context;

  const tooltipEl = getTapeDChartTooltip(canvas);

  const PADDING = 20; //px
  const chartRect = canvas.getBoundingClientRect();
  // pre-set dimensions for the tooltip
  const tooltipRect = {
    width: 250,
    height: 140,
  };

  // position tooltip relative to the point
  let x = selectedPoint.x;
  let y = selectedPoint.y;

  const x1 = x + tooltipRect.width;
  let y1 = y + tooltipRect.height;

  // if tooltip is outside the chart (x-axis), reposition it
  if (x1 > chartRect.width) {
    x = x - (x1 - chartRect.width) - PADDING;
  }

  // if the current point, where the tooltip is shown, is in the bottom half of the chart, show the tooltip above the point instead of below
  if (y > chartRect.height / 2) {
    y = y - tooltipRect.height - PADDING;
    y1 = y + tooltipRect.height;
  }

  // if tooltip is outside the chart (y-axis), reposition it
  if (y1 > chartRect.height) {
    y = y - (y1 - chartRect.height) - PADDING;
  }

  tooltipEl.style.opacity = '95%';
  tooltipEl.style.display = 'block';
  tooltipEl.style.position = 'absolute';

  tooltipEl.style.left = 0 + 'px';
  tooltipEl.style.top = y + PADDING + 'px';

  tooltipEl.style.width = tooltipRect.width + 'px';
  tooltipEl.style.height = tooltipRect.height + 'px';

  tooltipEl.style.transform = `translateX(${x}px)`;
  tooltipEl.style.transition = 'all .1s ease';
};

export const getLatestWeekday = (data: TapeDValue[]) => {
  if (!data.length) return null;

  const latestDate = parseISO(data[data.length - 1].date);
  return getDay(latestDate);
};
