import React, { Fragment } from 'react';

import { InfiniteScroll } from '@npm/core/ui/components/atoms/InfiniteScroll';
import { useWatchlistIndexInfinite } from '@npm/data-access';

import { type WatchlistConfig } from '../../../useWatchlistConfig.hooks';
import {
  type ActionConfigProps,
  type BaseConfig,
} from '../../WatchlistList.types';
import { NoDataAreaWatchlist } from '../NoDataAreaWatchlist';

import { WatchlistCard } from './components/WatchlistCard';

type Props = {
  rows: WatchlistConfig;
  isCompanyCentric?: boolean;
  isSecondMarket?: boolean;
} & BaseConfig &
  ActionConfigProps;

export const WatchlistCards = ({
  rows,
  variables,
  isFilterApplied,
  onAddToWatchlist,
  onEditClick,
  onDeleteClick,
  isCompanyCentric,
  isSecondMarket,
}: Props) => {
  const { data, isFetching, fetchNextPage, hasNextPage } =
    useWatchlistIndexInfinite(variables);

  const totalRecords = data?.pages?.[0]?.pagination?.total_records;

  const companyLinkVariant = isCompanyCentric ? 'logo' : 'icon';
  const companyIconVariant = isCompanyCentric
    ? 'external-link'
    : 'chevron-right';

  return (
    <Fragment>
      <InfiniteScroll
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        isFetching={isFetching}
        loader={
          <WatchlistCard
            isLoading
            rows={rows}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
          />
        }
      >
        {data?.pages.map(page => (
          <Fragment key={page.pagination.page}>
            {page.watchlists.map(watchlist => (
              <WatchlistCard
                key={watchlist?.id}
                rows={rows}
                watchlist={watchlist}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                header={{
                  companyLogoProps: {
                    linkProps: {
                      variant: isSecondMarket ? companyLinkVariant : 'icon',
                      iconVariant: isSecondMarket
                        ? companyIconVariant
                        : 'external-link',
                      afterPrefixPath: '/company-data',
                    },
                  },
                }}
              />
            ))}
          </Fragment>
        ))}
      </InfiniteScroll>
      {!isFetching && !totalRecords && (
        <NoDataAreaWatchlist
          isFilterApplied={isFilterApplied}
          onAdd={onAddToWatchlist}
        />
      )}
    </Fragment>
  );
};
