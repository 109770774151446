import { type CodebookApiCodebookRequest } from '../generated';

type CapTableCodebooks = CodebookApiCodebookRequest['type'];

export type CapTableType = {
  [key in CapTableCodebooks]: key;
};

export const Codebooks: Partial<CapTableType> = {
  STAKEHOLDER_RELATIONSHIP: 'STAKEHOLDER_RELATIONSHIP',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  STAKEHOLDER: 'STAKEHOLDER',
  ADDRESS: 'ADDRESS',
  ROUNDING: 'ROUNDING',
  STOCK_CLASS: 'STOCK_CLASS',
  COMPENSATION_TYPE: 'COMPENSATION_TYPE',
  OPTION_TYPE: 'OPTION_TYPE',
  TERMINATION_REASON: 'TERMINATION_REASON',
  TERMINATION_PERIOD: 'TERMINATION_PERIOD',
  TRANSACTION_TYPE: 'TRANSACTION_TYPE',
  VESTING_TRIGGER_TYPE: 'VESTING_TRIGGER_TYPE',
  US_STATES: 'US_STATES',
  COUNTRY: 'COUNTRY',
  SUBREGION: 'SUBREGION',
  CURRENCY: 'CURRENCY',
  FILING_SOURCE: 'FILING_SOURCE',
  SECURITY_TYPE: 'SECURITY_TYPE',
  JOB_STATUS: 'JOB_STATUS',
};

interface Attribute {
  name?: string;
  value?: string;
}

export interface CodebookItem {
  book: keyof typeof Codebooks;
  code: string;
  name: string;
  attributes?: Array<Attribute>;
}
