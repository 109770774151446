import { type RowKey } from './Table.types';

const PAGE_LENGTH_STORAGE_KEY = 'NPM_PAGE_L';
export const DEFAULT_TABLE_PAGE_SIZE = 20;
export const DEFAULT_CARDS_PAGE_SIZE = 5;

export const PAGE_SIZE_HARD_LIMIT = 1000; // i.e. pagination disabled, get all records

export const getRowKey = <T>(record?: T, key: RowKey<T> = 'id') => {
  if (!record) {
    return null;
  }

  if (typeof key === 'string') {
    return record[key];
  }

  return key?.(record);
};

const getTablePageSize = () => {
  const defaultPageSize = parseInt(
    window.localStorage.getItem(PAGE_LENGTH_STORAGE_KEY) ?? ''
  );

  if (
    !defaultPageSize ||
    isNaN(defaultPageSize) ||
    defaultPageSize < 5 // the minimum page size
  ) {
    return DEFAULT_TABLE_PAGE_SIZE;
  }

  return defaultPageSize;
};
export const getPageSize = (type: 'table' | 'cards' = 'table'): number => {
  if (type === 'cards') {
    return DEFAULT_CARDS_PAGE_SIZE;
  }

  return getTablePageSize();
};

export const savePageSize = (size: number | undefined): void => {
  if (size) {
    window.localStorage.setItem(PAGE_LENGTH_STORAGE_KEY, size.toString());
  }
};
