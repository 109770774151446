import React, { memo, useEffect } from 'react';

import { useCurrentRoute } from '../../../../hooks/useCurrentRoute';
import { Button } from '../../../atoms/Button';
import { Dropdown } from '../../../atoms/Dropdown';
import { Icon } from '../../../atoms/Icon';
import { LogoShort } from '../../../atoms/Logo';
import { Link, useLocation } from '../../../molecules/Link';
import { DrawerEnum, useActiveDrawerStore, useDrawer } from '../../Drawer';
import { useLayoutConfig } from '../Layout.hooks';

import { HamburgerButton } from './HamburgerButton';

import * as S from './HamburgerMenu.styles';

const DRAWER_WIDTH_TABLET = 360;

type Props = {
  children?: React.ReactNode;
  footerChildren?: React.ReactNode;
  mobileMenuType?: 'full' | 'basic' | null;
  logoLinkUrl?: string;
};

const HamburgerButtonComponent = ({
  children,
  footerChildren,
  mobileMenuType,
  logoLinkUrl,
}: Props) => {
  const layoutConfig = useLayoutConfig();
  const { isMobile, isTablet } = layoutConfig;
  const currentRoute = useCurrentRoute();
  const { pathname } = useLocation();
  const type = mobileMenuType || currentRoute?.route.mobileMenuType || 'full';

  const { openDrawer, closeDrawer, toggleDrawer, isOpen } = useDrawer();
  const { activeDrawerKey, closeDrawer: closeActiveDrawer } =
    useActiveDrawerStore();
  const isMenuOpen =
    isOpen &&
    activeDrawerKey !== DrawerEnum.ROLE_SWITCHER &&
    activeDrawerKey !== DrawerEnum.OBO_SELECTION &&
    activeDrawerKey !== DrawerEnum.OPT_IN;

  const handleHamburgerButtonClick = () => {
    if (
      activeDrawerKey === DrawerEnum.OBO_SELECTION ||
      activeDrawerKey === DrawerEnum.OPT_IN
    ) {
      closeActiveDrawer();
      openDrawer();
      return;
    }

    toggleDrawer();
  };

  useEffect(() => {
    // close menu when the location changes
    closeDrawer();
  }, [pathname]);

  const logoShortRendered = <LogoShort width={71} />;

  if (type === 'full') {
    return (
      <>
        <HamburgerButton
          isOpen={isMenuOpen}
          handleClick={handleHamburgerButtonClick}
        />
        <S.Drawer
          width={isMobile && !isTablet ? '100%' : `${DRAWER_WIDTH_TABLET}px`}
          placement="right"
          open={isMenuOpen}
          onClose={closeDrawer}
          push={false}
          footer={footerChildren}
          $layoutConfig={layoutConfig}
        >
          <S.DrawerHeader>
            {logoLinkUrl ? (
              <Link to={logoLinkUrl}>{logoShortRendered}</Link>
            ) : (
              logoShortRendered
            )}
            <Button
              variant="text"
              size="sm"
              icon={<Icon name="x-close" />}
              onClick={closeDrawer}
            />
          </S.DrawerHeader>
          {children}
        </S.Drawer>
      </>
    );
  }

  const basicMenuOverlay = (
    <S.OverlayWrapper onClick={handleHamburgerButtonClick}>
      {children}
    </S.OverlayWrapper>
  );

  return (
    <Dropdown
      overlay={basicMenuOverlay}
      onOpenChange={isOpen => (isOpen ? openDrawer() : closeDrawer())}
      zIndex="aboveAll"
    >
      <S.BurgerDropdownWrapper
        onClick={handleHamburgerButtonClick}
        $layoutConfig={layoutConfig}
      >
        <HamburgerButton isOpen={isOpen} />
      </S.BurgerDropdownWrapper>
    </Dropdown>
  );
};

export const HamburgerMenu = memo(HamburgerButtonComponent);
