export const buildQueryParamsString = (
  paramsObj: Record<string, string | number>,
  preserveExistingParams = false
) => {
  const existingParamsObj = transformQueryStringToObj(window.location.search);
  const newParamsObj = {
    ...(preserveExistingParams && { ...existingParamsObj }),
    ...paramsObj,
  };

  // remove null values
  Object.keys(newParamsObj).forEach(key => {
    if (newParamsObj[key] === null || newParamsObj[key] === undefined) {
      delete newParamsObj[key];
    }
  });

  const params = new URLSearchParams(newParamsObj);
  return '?' + params.toString();
};
export const transformQueryStringToObj = (queryString: string | undefined) => {
  if (!queryString?.length) {
    return {};
  }

  const searchParams = new URLSearchParams(queryString);

  let paramsObj = {};
  for (const [key, value] of searchParams.entries()) {
    paramsObj = { ...paramsObj, [key]: value };
  }

  return paramsObj;
};

export const getUrlParamValue: (
  param: string
) => string | null | undefined = param => {
  const queryString = window.location.search;
  if (!queryString?.length) {
    return undefined;
  }

  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(param);
};

export const addHttpsIfMissing = (
  url: string | undefined
): string | undefined => {
  if (!url) return url;
  if (!/^https?:\/\//i.test(url)) {
    return 'https://' + url;
  }
  return url;
};

export const isValidUrl = (url?: string): boolean => {
  if (!url) return false;

  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};
