import React from 'react';

import { useDarkModeContext } from '../../../context/DarkModeContext';
import { Flex } from '../../atoms/common';
import { DarkModeSwitch } from '../../molecules/DarkModeSwitch';

import * as S from './DarkModeSwitcher.styles';

type Props = {
  isCollapsed: boolean;
};

export const DarkModeSwitcher = ({ isCollapsed }: Props) => {
  const { isDarkMode } = useDarkModeContext();

  const children = (
    <S.DarkModeSwitcherInnerWrapper>
      <Flex align="center" gap="sm" justify="space-between">
        <Flex align="center">
          <S.DarkModeSwitcherIcon
            name={isDarkMode ? 'moon-unfilled' : 'sun-filled'}
          />
          <S.DarkModeSwitcherLabel>Theme</S.DarkModeSwitcherLabel>
        </Flex>

        <S.DarkModeSwitcherSwitchWrapper>
          <DarkModeSwitch />
        </S.DarkModeSwitcherSwitchWrapper>
      </Flex>
    </S.DarkModeSwitcherInnerWrapper>
  );

  const switchIcon = (
    <S.DarkModeSwitcherInnerWrapper>
      <Flex align="center" gap="sm" justify="space-between">
        <Flex align="center">
          <S.DarkModeSwitcherIcon
            name={isDarkMode ? 'moon-unfilled' : 'sun-filled'}
          />
        </Flex>
      </Flex>
    </S.DarkModeSwitcherInnerWrapper>
  );
  return (
    <S.DarkModeSwitcherWrapper>
      {!isCollapsed ? children : switchIcon}
    </S.DarkModeSwitcherWrapper>
  );
};
