import React from 'react';
import { type LabeledValue } from 'antd/es/select';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { CbLabel, Label } from '@npm/core/ui/components/atoms/Label';
import { Select } from '@npm/core/ui/components/atoms/Select';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { formatNumToAddComma, truncate } from '@npm/core/ui/utils/formatters';
import { type Holding, CbHoldingState } from '@npm/data-access';

import { type HoldingSelect } from './HoldingSelect';

export const renderHoldingOption = (
  option: LabeledValue & { holding: Holding },
  labelBasePropName: React.ComponentProps<
    typeof HoldingSelect
  >['labelBasePropName'],
  showStatus?: boolean
) => (
  <Select.Option key={option.value} value={option.value} label={option.label}>
    <Text size="sm" colorVariant="primary" marginBottom="xs">
      {option.holding.asset?.type?.[labelBasePropName]}
      {option.holding.underlying_holding_type
        ? ` - ${option.holding.underlying_holding_type.name}`
        : ''}
    </Text>
    <Flex gap="xs">
      <Label variant="info" round>
        {truncate(option.holding.certificate_number ?? 'N/A')}
      </Label>
      {!!option.holding.quantity && (
        <Label>QTY {formatNumToAddComma(option.holding.quantity)}</Label>
      )}
      {showStatus && option.holding.state && (
        <div style={{ marginLeft: 'auto' }}>
          {CbHoldingState.getLabel(CbLabel, option.holding.state, true)}
        </div>
      )}
    </Flex>
  </Select.Option>
);
