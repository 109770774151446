import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocalStorage, useMediaQuery } from 'usehooks-ts';

/* eslint-disable react/no-multi-comp */

type DarkModeContextType = {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
};

export const DarkModeContext = createContext<DarkModeContextType>({
  isDarkMode: true,
  toggleDarkMode: () => {
    // ignore
    console.warn('[DarkModeContext] This should not happen!');
  },
});

const COLOR_SCHEME_QUERY = '(prefers-color-scheme: dark)';
const LOCAL_STORAGE_THEME_KEY = 'theme';

enum ThemeType {
  LIGHT = 'light',
  DARK = 'dark',
  AUTO = 'auto',
}

/**
 * Hook which automatically reads theme from localstorage.
 * If not set, it reads system theme and listens to changes.
 */
const useDarkMode = () => {
  const isDarkOs = useMediaQuery(COLOR_SCHEME_QUERY);

  const [mode, setMode] = useLocalStorage(
    LOCAL_STORAGE_THEME_KEY,
    ThemeType.AUTO,
    {
      serializer: (value: ThemeType) => value,
      deserializer: (value: unknown) => {
        // If something weird in localstorage, fallback to default.
        const isValid = Object.values(ThemeType).includes(value as ThemeType);
        return isValid ? (value as ThemeType) : ThemeType.AUTO;
      },
    }
  );

  const isDarkMode =
    mode === ThemeType.AUTO ? isDarkOs : mode === ThemeType.DARK;

  const toggle = () => {
    setMode(currMode => {
      // If in automatic mode, switch to manual and use opposite of current system.
      // This ensures that next time user visits, this option will be persisted.
      // We currently don't allow switching back to "AUTO" mode.
      if (currMode === ThemeType.AUTO) {
        return isDarkOs ? ThemeType.LIGHT : ThemeType.DARK;
      }

      // If in manual mode, change from one to other.
      return currMode === ThemeType.DARK ? ThemeType.LIGHT : ThemeType.DARK;
    });
  };

  return { isDarkMode, toggle };
};

export const DarkModeProvider: React.FC = ({ children }) => {
  const { isDarkMode, toggle } = useDarkMode();

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode: toggle }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const StorybookDarkModeProvider: React.FC<{ defaultValue: boolean }> = ({
  children,
  defaultValue,
}) => {
  const [isDarkMode, setIsDarkMode] = useState(defaultValue);

  const toggleDarkMode = () => {
    setIsDarkMode(currentValue => !currentValue);
  };

  useEffect(() => {
    setIsDarkMode(defaultValue);
  }, [defaultValue]);

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkModeContext = () => useContext(DarkModeContext);
