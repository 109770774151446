import { startCase } from 'lodash';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  CommonStock: 'CommonStock',
  PreferredStock: 'PreferredStock',
  Unit: 'Unit',
  RestrictedStockUnit: 'RestrictedStockUnit',
  Option: 'Option',
  Warrant: 'Warrant',
  AnyShareClass: 'AnyShareClass',
  SingleLayerSpv: 'SingleLayerSpv',
  DoubleLayerSpv: 'DoubleLayerSpv',
  Blend: 'Blend',
} as const;

const shortLabelsByCode = {
  CommonStock: 'CS',
  PreferredStock: 'PS',
  Option: 'OPT',
  Warrant: 'WAR',
  Unit: 'U',
  RestrictedStockUnit: 'RSU',
  AnyShareClass: 'Any Share Class',
} as const;

type AssetTypeAttributes = 'abbreviation' | 'show_in_combo_box';

export type AssetTypeCode = keyof typeof Items;

export const CbAssetType = {
  code: Codebooks.ASSET_TYPE,
  items: Items,
  getAttrByName: (item: CodebookItem, name: AssetTypeAttributes) =>
    item.attributes?.find(q => q.name === name),
  getLabel: (code: AssetTypeCode) => startCase(code),
  getShortLabel: (code: AssetTypeCode) =>
    shortLabelsByCode[code] ?? code?.replace(/[^A-Z]/g, ''),
};
