import { Codebooks } from './codebooks.types';

const items = {
  yes: 'yes',
  no: 'no',
  complete_later: 'complete_later',
} as const;

export type AffiliateDisclosureType = keyof typeof items;

export const CbAffiliateDisclosure = {
  code: Codebooks.AFFILIATE_DISCLOSURE,
  items: items,
};
