import {
  type BasicPageLayoutProps,
  BasicPageLayout,
} from '../components/BasicPageLayout';

export type CapTableBasicProps = {
  companyName: string;
};

const mainCard: BasicPageLayoutProps['mainCard'] = {
  imagePrefix: 'cap-table-main-card-basic',
  title: 'Unlock Detailed Cap Table Analytics',
  description: `It's crucial to understand how companies are structured early in the investment process. With comprehensive cap table analytics from NPM, you can get answers more easily before diving into the heavy lifting during due diligence.`,
  mobileImage: {
    size: '830px 230px',
    position: '55% 0px',
  },
};

const footerCard: BasicPageLayoutProps['footerCard'] = {
  image: {
    left: {
      prefix: 'cap-table-footer-card-basic-left',
      position: {
        tablet: {
          x: '-350px',
        },
        desktop: {
          x: '-200px',
        },
      },
    },
    right: {
      prefix: 'cap-table-footer-card-basic-right',
      position: {
        tablet: {
          x: '60%',
        },
        desktop: {
          x: '65%',
        },
      },
    },
    mobilePrefix: 'cap-table-footer-card-basic-mobile',
  },
  title: 'Stay Ahead of the Private Market',
  description: 'Get access to Premium Data by NPM',
};

const middleCards: BasicPageLayoutProps['middleCards'] = [
  {
    imagePrefix: 'issued-shares-basic',
    title: 'Issued Shares',
    description:
      'Tape D™ provides clarity on the share structure of private companies by distinguishing Authorized and Issued Share Counts. Identifying this difference enables you to calculate the Estimated Fully Diluted Shares Outstanding. By leveraging regulatory filings, NPM accurately reflects how companies are structured, creating more accurate valuation calculations for private companies.',
  },
  {
    imagePrefix: 'waterfall-basic',
    title: 'Waterfall Scenario Analysis',
    description:
      'Evaluate potential exit scenarios effortlessly with a downloadable Excel model. This tool lets you simulate different distribution outcomes, automatically accounting for key deal terms such as participation (e.g., full, capped, non-participating), seniority, liquidity preferences, and conversion prices. Waterfall Scenario Analysis helps you make more informed decisions by helping you understand the potential distribution price per share under various exit conditions.',
  },
  {
    imagePrefix: 'deal-terms-basic',
    title: 'Deal Terms',
    description:
      'Access a comprehensive database of over 1.4 million detailed deal terms extracted from regulatory documents. The dataset includes specifics on seniority, liquidation preferences, voting rights, and other key provisions. Easily compare the nuances across different deals without sifting through filings. Save time and gain insights with detailed information on each investment round.',
  },
];

export const CapTableBasic = ({ companyName }: CapTableBasicProps) => {
  return (
    <BasicPageLayout
      companyName={companyName}
      mainCard={mainCard}
      footerCard={footerCard}
      middleCards={middleCards}
    />
  );
};
