/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  OrderBookShowApi,
  EventOrderBookShowErrorCodes,
  EventOrderBookShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new OrderBookShowApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type EventOrderBookShowMethod = OrderBookShowApi['eventOrderBookShow'];
export type EventOrderBookShowResponseType = MethodResult<EventOrderBookShowMethod>;

export const useEventOrderBookShow = (
  variables: Parameters<EventOrderBookShowMethod>[0],
  config?: UseQueryConfig<
    EventOrderBookShowMethod,
    EventOrderBookShowErrorTypes
  >
) => {
  return useQuery<
    EventOrderBookShowMethod,
    EventOrderBookShowErrorTypes
  >(
    (...args) => api.eventOrderBookShow(...args),
    EventOrderBookShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventOrderBookShow'],
        ...apiQueryConfig.EventOrderBookShow,
        ...config?.queryConfig
      },
    },
    'EventOrderBookShow',
  );
};

export const useEventOrderBookShowInfinite = (
  variables: Parameters<EventOrderBookShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    EventOrderBookShowMethod,
    EventOrderBookShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    EventOrderBookShowMethod,
    EventOrderBookShowErrorTypes
  >(
    (...args) => api.eventOrderBookShow(...args),
    EventOrderBookShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventOrderBookShow', 'EventOrderBookShowInfinite'],
        ...apiQueryConfig.EventOrderBookShow,
        ...config?.queryConfig,
      },
    },
    'EventOrderBookShow',
  );
};

export const useEventOrderBookShowLazy = (baseOptions?: {
  variables?: Parameters<EventOrderBookShowMethod>[0],
  config?: UseQueryConfig<
    EventOrderBookShowMethod,
    EventOrderBookShowErrorTypes
  >
}) => {
  return useLazyQuery<
    EventOrderBookShowMethod,
    EventOrderBookShowErrorTypes
  >(
    (...args) => api.eventOrderBookShow(...args),
    EventOrderBookShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EventOrderBookShow'],
        ...apiQueryConfig.EventOrderBookShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EventOrderBookShow',
  );
};

