/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  EventMessageApi,
  EventEventMessageSingularErrorCodes,
  EventEventMessageSingularErrorTypes,
  EventEventMessageShowErrorCodes,
  EventEventMessageShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new EventMessageApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type EventEventMessageSingularMethod = EventMessageApi['eventEventMessageSingular'];
export type EventEventMessageSingularResponseType = MethodResult<EventEventMessageSingularMethod>;

export const useEventEventMessageSingular = (
  variables: Parameters<EventEventMessageSingularMethod>[0],
  config?: UseQueryConfig<
    EventEventMessageSingularMethod,
    EventEventMessageSingularErrorTypes
  >
) => {
  return useQuery<
    EventEventMessageSingularMethod,
    EventEventMessageSingularErrorTypes
  >(
    (...args) => api.eventEventMessageSingular(...args),
    EventEventMessageSingularErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventEventMessageSingular'],
        ...apiQueryConfig.EventEventMessageSingular,
        ...config?.queryConfig
      },
    },
    'EventEventMessageSingular',
  );
};

export const useEventEventMessageSingularInfinite = (
  variables: Parameters<EventEventMessageSingularMethod>[0],
  config?: UseInfiniteQueryConfig<
    EventEventMessageSingularMethod,
    EventEventMessageSingularErrorTypes
  >
) => {
  return useInfiniteQuery<
    EventEventMessageSingularMethod,
    EventEventMessageSingularErrorTypes
  >(
    (...args) => api.eventEventMessageSingular(...args),
    EventEventMessageSingularErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventEventMessageSingular', 'EventEventMessageSingularInfinite'],
        ...apiQueryConfig.EventEventMessageSingular,
        ...config?.queryConfig,
      },
    },
    'EventEventMessageSingular',
  );
};

export const useEventEventMessageSingularLazy = (baseOptions?: {
  variables?: Parameters<EventEventMessageSingularMethod>[0],
  config?: UseQueryConfig<
    EventEventMessageSingularMethod,
    EventEventMessageSingularErrorTypes
  >
}) => {
  return useLazyQuery<
    EventEventMessageSingularMethod,
    EventEventMessageSingularErrorTypes
  >(
    (...args) => api.eventEventMessageSingular(...args),
    EventEventMessageSingularErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EventEventMessageSingular'],
        ...apiQueryConfig.EventEventMessageSingular,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EventEventMessageSingular',
  );
};


export type EventEventMessageShowMethod = EventMessageApi['eventEventMessageShow'];
export type EventEventMessageShowResponseType = MethodResult<EventEventMessageShowMethod>;

export const useEventEventMessageShow = (
  variables: Parameters<EventEventMessageShowMethod>[0],
  config?: UseQueryConfig<
    EventEventMessageShowMethod,
    EventEventMessageShowErrorTypes
  >
) => {
  return useQuery<
    EventEventMessageShowMethod,
    EventEventMessageShowErrorTypes
  >(
    (...args) => api.eventEventMessageShow(...args),
    EventEventMessageShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventEventMessageShow'],
        ...apiQueryConfig.EventEventMessageShow,
        ...config?.queryConfig
      },
    },
    'EventEventMessageShow',
  );
};

export const useEventEventMessageShowInfinite = (
  variables: Parameters<EventEventMessageShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    EventEventMessageShowMethod,
    EventEventMessageShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    EventEventMessageShowMethod,
    EventEventMessageShowErrorTypes
  >(
    (...args) => api.eventEventMessageShow(...args),
    EventEventMessageShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventEventMessageShow', 'EventEventMessageShowInfinite'],
        ...apiQueryConfig.EventEventMessageShow,
        ...config?.queryConfig,
      },
    },
    'EventEventMessageShow',
  );
};

export const useEventEventMessageShowLazy = (baseOptions?: {
  variables?: Parameters<EventEventMessageShowMethod>[0],
  config?: UseQueryConfig<
    EventEventMessageShowMethod,
    EventEventMessageShowErrorTypes
  >
}) => {
  return useLazyQuery<
    EventEventMessageShowMethod,
    EventEventMessageShowErrorTypes
  >(
    (...args) => api.eventEventMessageShow(...args),
    EventEventMessageShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EventEventMessageShow'],
        ...apiQueryConfig.EventEventMessageShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EventEventMessageShow',
  );
};

