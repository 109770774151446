/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  OrganizationApi,
  OrganizationShowErrorCodes,
  OrganizationShowErrorTypes,
  OrganizationUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new OrganizationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type OrganizationShowMethod = OrganizationApi['organizationShow'];
export type OrganizationShowResponseType = MethodResult<OrganizationShowMethod>;

export const useOrganizationShow = (
  variables: Parameters<OrganizationShowMethod>[0],
  config?: UseQueryConfig<
    OrganizationShowMethod,
    OrganizationShowErrorTypes
  >
) => {
  return useQuery<
    OrganizationShowMethod,
    OrganizationShowErrorTypes
  >(
    (...args) => api.organizationShow(...args),
    OrganizationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrganizationShow'],
        ...apiQueryConfig.OrganizationShow,
        ...config?.queryConfig
      },
    },
    'OrganizationShow',
  );
};

export const useOrganizationShowInfinite = (
  variables: Parameters<OrganizationShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    OrganizationShowMethod,
    OrganizationShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    OrganizationShowMethod,
    OrganizationShowErrorTypes
  >(
    (...args) => api.organizationShow(...args),
    OrganizationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrganizationShow', 'OrganizationShowInfinite'],
        ...apiQueryConfig.OrganizationShow,
        ...config?.queryConfig,
      },
    },
    'OrganizationShow',
  );
};

export const useOrganizationShowLazy = (baseOptions?: {
  variables?: Parameters<OrganizationShowMethod>[0],
  config?: UseQueryConfig<
    OrganizationShowMethod,
    OrganizationShowErrorTypes
  >
}) => {
  return useLazyQuery<
    OrganizationShowMethod,
    OrganizationShowErrorTypes
  >(
    (...args) => api.organizationShow(...args),
    OrganizationShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['OrganizationShow'],
        ...apiQueryConfig.OrganizationShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'OrganizationShow',
  );
};


export type OrganizationUpdateMethod = OrganizationApi['organizationUpdate'];

export const useOrganizationUpdate = () => {
  return useMutation<OrganizationUpdateMethod>(
    (...args) => api.organizationUpdate(...args),
    OrganizationUpdateErrorCodes,
    'OrganizationUpdate',
    '/api/organizations/{id}',
    'put'
  );
};
