/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalCompanyAliasCreateRequestContract } from '../model';
// @ts-ignore
import { InternalCompanyAliasIndex } from '../model';
// @ts-ignore
import { InternalCompanyAliasShow } from '../model';
// @ts-ignore
import { InternalCompanyAliasUpdateRequestContract } from '../model';
/**
 * CompanyAliasesApi - axios parameter creator
 * @export
 */
export const CompanyAliasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a CompanyAlias
         * @param {InternalCompanyAliasCreateRequestContract} internalCompanyAliasCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompanyAliasCreate: async (internalCompanyAliasCreateRequestContract: InternalCompanyAliasCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'internalCompanyAliasCreateRequestContract' is not null or undefined
            assertParamExists('internalCompanyAliasCreate', 'internalCompanyAliasCreateRequestContract', internalCompanyAliasCreateRequestContract)
            const localVarPath = changeUrl(`/api/internal/company_aliases`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalCompanyAliasCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get company_aliases for a company
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompanyAliasIndex: async (companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalCompanyAliasIndex', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/company_aliases`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a Company Alias
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDeleteCompanyAlias: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDeleteCompanyAlias', 'id', id)
            const localVarPath = changeUrl(`/api/internal/company_aliases/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get information for a Company Alias
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowCompanyAlias: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalShowCompanyAlias', 'id', id)
            const localVarPath = changeUrl(`/api/internal/company_aliases/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a Company Alias
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalCompanyAliasUpdateRequestContract} [internalCompanyAliasUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateCompanyAlias: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalCompanyAliasUpdateRequestContract?: InternalCompanyAliasUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateCompanyAlias', 'id', id)
            const localVarPath = changeUrl(`/api/internal/company_aliases/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalCompanyAliasUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyAliasesApi - functional programming interface
 * @export
 */
export const CompanyAliasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyAliasesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a CompanyAlias
         * @param {InternalCompanyAliasCreateRequestContract} internalCompanyAliasCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCompanyAliasCreate(internalCompanyAliasCreateRequestContract: InternalCompanyAliasCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCompanyAliasCreate(internalCompanyAliasCreateRequestContract, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get company_aliases for a company
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCompanyAliasIndex(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCompanyAliasIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCompanyAliasIndex(companyId, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a Company Alias
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDeleteCompanyAlias(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDeleteCompanyAlias(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get information for a Company Alias
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalShowCompanyAlias(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCompanyAliasShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalShowCompanyAlias(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a Company Alias
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalCompanyAliasUpdateRequestContract} [internalCompanyAliasUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateCompanyAlias(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalCompanyAliasUpdateRequestContract?: InternalCompanyAliasUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateCompanyAlias(id, xNPMAuthorization, xSubscriptionPlan, internalCompanyAliasUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyAliasesApi - factory interface
 * @export
 */
export const CompanyAliasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyAliasesApiFp(configuration)
    return {
        /**
         * 
         * @summary create a CompanyAlias
         * @param {InternalCompanyAliasCreateRequestContract} internalCompanyAliasCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompanyAliasCreate(internalCompanyAliasCreateRequestContract: InternalCompanyAliasCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalCompanyAliasCreate(internalCompanyAliasCreateRequestContract, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get company_aliases for a company
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompanyAliasIndex(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalCompanyAliasIndex> {
            return localVarFp.internalCompanyAliasIndex(companyId, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a Company Alias
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDeleteCompanyAlias(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalDeleteCompanyAlias(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get information for a Company Alias
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowCompanyAlias(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalCompanyAliasShow> {
            return localVarFp.internalShowCompanyAlias(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a Company Alias
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalCompanyAliasUpdateRequestContract} [internalCompanyAliasUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateCompanyAlias(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalCompanyAliasUpdateRequestContract?: InternalCompanyAliasUpdateRequestContract, options?: any): AxiosPromise<void> {
            return localVarFp.internalUpdateCompanyAlias(id, xNPMAuthorization, xSubscriptionPlan, internalCompanyAliasUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for internalCompanyAliasCreate operation in CompanyAliasesApi.
 * @export
 * @interface CompanyAliasesApiInternalCompanyAliasCreateRequest
 */
export interface CompanyAliasesApiInternalCompanyAliasCreateRequest {
    /**
     * 
     * @type {InternalCompanyAliasCreateRequestContract}
     * @memberof CompanyAliasesApiInternalCompanyAliasCreate
     */
    readonly internalCompanyAliasCreateRequestContract: InternalCompanyAliasCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof CompanyAliasesApiInternalCompanyAliasCreate
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompanyAliasesApiInternalCompanyAliasCreate
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalCompanyAliasIndex operation in CompanyAliasesApi.
 * @export
 * @interface CompanyAliasesApiInternalCompanyAliasIndexRequest
 */
export interface CompanyAliasesApiInternalCompanyAliasIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof CompanyAliasesApiInternalCompanyAliasIndex
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof CompanyAliasesApiInternalCompanyAliasIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompanyAliasesApiInternalCompanyAliasIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalDeleteCompanyAlias operation in CompanyAliasesApi.
 * @export
 * @interface CompanyAliasesApiInternalDeleteCompanyAliasRequest
 */
export interface CompanyAliasesApiInternalDeleteCompanyAliasRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyAliasesApiInternalDeleteCompanyAlias
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof CompanyAliasesApiInternalDeleteCompanyAlias
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompanyAliasesApiInternalDeleteCompanyAlias
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalShowCompanyAlias operation in CompanyAliasesApi.
 * @export
 * @interface CompanyAliasesApiInternalShowCompanyAliasRequest
 */
export interface CompanyAliasesApiInternalShowCompanyAliasRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyAliasesApiInternalShowCompanyAlias
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof CompanyAliasesApiInternalShowCompanyAlias
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompanyAliasesApiInternalShowCompanyAlias
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalUpdateCompanyAlias operation in CompanyAliasesApi.
 * @export
 * @interface CompanyAliasesApiInternalUpdateCompanyAliasRequest
 */
export interface CompanyAliasesApiInternalUpdateCompanyAliasRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyAliasesApiInternalUpdateCompanyAlias
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof CompanyAliasesApiInternalUpdateCompanyAlias
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompanyAliasesApiInternalUpdateCompanyAlias
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {InternalCompanyAliasUpdateRequestContract}
     * @memberof CompanyAliasesApiInternalUpdateCompanyAlias
     */
    readonly internalCompanyAliasUpdateRequestContract?: InternalCompanyAliasUpdateRequestContract
}

/**
 * CompanyAliasesApi - object-oriented interface
 * @export
 * @class CompanyAliasesApi
 * @extends {BaseAPI}
 */
export class CompanyAliasesApi extends BaseAPI {
    /**
     * 
     * @summary create a CompanyAlias
     * @param {CompanyAliasesApiInternalCompanyAliasCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyAliasesApi
     */
    public internalCompanyAliasCreate(requestParameters: CompanyAliasesApiInternalCompanyAliasCreateRequest, options?: AxiosRequestConfig) {
        return CompanyAliasesApiFp(this.configuration).internalCompanyAliasCreate(requestParameters.internalCompanyAliasCreateRequestContract, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get company_aliases for a company
     * @param {CompanyAliasesApiInternalCompanyAliasIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyAliasesApi
     */
    public internalCompanyAliasIndex(requestParameters: CompanyAliasesApiInternalCompanyAliasIndexRequest, options?: AxiosRequestConfig) {
        return CompanyAliasesApiFp(this.configuration).internalCompanyAliasIndex(requestParameters.companyId, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a Company Alias
     * @param {CompanyAliasesApiInternalDeleteCompanyAliasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyAliasesApi
     */
    public internalDeleteCompanyAlias(requestParameters: CompanyAliasesApiInternalDeleteCompanyAliasRequest, options?: AxiosRequestConfig) {
        return CompanyAliasesApiFp(this.configuration).internalDeleteCompanyAlias(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get information for a Company Alias
     * @param {CompanyAliasesApiInternalShowCompanyAliasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyAliasesApi
     */
    public internalShowCompanyAlias(requestParameters: CompanyAliasesApiInternalShowCompanyAliasRequest, options?: AxiosRequestConfig) {
        return CompanyAliasesApiFp(this.configuration).internalShowCompanyAlias(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a Company Alias
     * @param {CompanyAliasesApiInternalUpdateCompanyAliasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyAliasesApi
     */
    public internalUpdateCompanyAlias(requestParameters: CompanyAliasesApiInternalUpdateCompanyAliasRequest, options?: AxiosRequestConfig) {
        return CompanyAliasesApiFp(this.configuration).internalUpdateCompanyAlias(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.internalCompanyAliasUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InternalCompanyAliasCreateErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalCompanyAliasCreateErrorCodes = [
    401,
];

export type InternalCompanyAliasIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalCompanyAliasIndexErrorCodes = [
    401,
    404,
];

export type InternalDeleteCompanyAliasErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalDeleteCompanyAliasErrorCodes = [
    401,
    404,
];

export type InternalShowCompanyAliasErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalShowCompanyAliasErrorCodes = [
    401,
    404,
];

export type InternalUpdateCompanyAliasErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalUpdateCompanyAliasErrorCodes = [
    401,
    404,
];


