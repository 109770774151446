import { Codebooks } from './codebooks.types';

const Items = {
  holding_verification: 'holding_verification',
  order_amount: 'order_amount',
  bid_schedule: 'bid_schedule',
  legal_information: 'legal_information',
  manage_holdings: 'manage_holdings',
  identity_verification: 'identity_verification',
  payment_instructions: 'payment_instructions',
  document_signing: 'document_signing',
  accreditation: 'accreditation',
  add_signers: 'add_signers',
  tax_document: 'tax_document',
  confirmation: 'confirmation',
  stock_certificate: 'stock_certificate',
  entity_information: 'entity_information',
  entity_legal_information: 'entity_legal_information',
  exercise_by_check: 'exercise_by_check',
  fund_investment: 'fund_investment',
  holding_review: 'holding_review',
  identity_verification_offline: 'identity_verification_offline',
  indication_of_interest: 'indication_of_interest',
  order_filled: 'order_filled',
  sell_indication_of_interest: 'sell_indication_of_interest',
  spousal_signature: 'spousal_signature',
  submitted: 'submitted',
} as const;

export const CbEventSubmissionStep = {
  code: Codebooks.EVENT_SUBMISSION_STEP,
  items: Items,
};
