import React, { type ComponentProps } from 'react';
import { type FormInstance, type FormProps } from 'antd';
import { useTheme } from 'styled-components';

import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { AutoScrollingForm } from '@npm/core/ui/components/organisms/AutoScrollingForm';

import { PersonInfoForm } from '../../../users/CreateNewUser/PersonInfoForm';
import { ContactInformationForm } from '../index';

import { autoscrollQuestions } from './IndividualInformation.config';
import { type IndividualInfoFormType } from './IndividualInformation.types';

type Props = ComponentProps<typeof ContactInformationForm> & {
  form: FormInstance<IndividualInfoFormType>;
  withAutoscroll?: boolean;
  scrollEnabled?: boolean;
  scrollableParentId?: string; // used for AutoscrollingForm
  handleValuesChange?: FormProps['onValuesChange'];
};

export const IndividualInformationForm = ({
  form,
  initialCountryCode,
  isLoading,
  scrollEnabled,
  scrollableParentId,
  withAutoscroll = true,
  isFilled = false,
  handleValuesChange,
}: Props) => {
  const theme = useTheme();

  return withAutoscroll ? (
    <AutoScrollingForm
      questions={autoscrollQuestions(
        isLoading,
        form,
        initialCountryCode,
        isFilled
      )}
      form={form}
      scrollEnabled={scrollEnabled}
      scrollableParentId={scrollableParentId}
      validateTrigger="onSubmit"
      onValuesChange={handleValuesChange}
    />
  ) : (
    <Form
      form={form}
      validateTrigger="onSubmit"
      onValuesChange={handleValuesChange}
    >
      <PersonInfoForm form={form} isLoading={isLoading} />
      <Divider marginBottom={theme.spacing.lg} marginTop={theme.spacing.lg} />
      <ContactInformationForm
        form={form}
        isLoading={isLoading}
        initialCountryCode={initialCountryCode}
        isFilled={isFilled}
      />
    </Form>
  );
};
