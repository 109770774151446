import { useState } from 'react';

export const useCollapsibleFormSection = ({
  onSectionChange,
}: {
  onSectionChange?: () => void;
}) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const handleSectionChange = (keys: string[]) => {
    onSectionChange?.();
    setActiveKeys(keys);
  };

  const handleItemChange = (key: string) => {
    setActiveKeys(currentKeys => currentKeys.filter(k => k !== key));
  };

  return {
    activeKeys,
    setActiveKeys,
    handleSectionChange,
    handleItemChange,
  };
};
