import { Text } from '@npm/core/ui/components/atoms/Typography';

import * as S from './SuccessMessage.styles';

type Props = {
  onBack: () => void;
};

export const SuccessMessage = ({ onBack }: Props) => {
  return (
    <S.Container>
      <S.Heading variant="h3" marginBottom="md">
        Thank you!
      </S.Heading>
      <Text size="sm" marginBottom="lg">
        An activation email was sent to the address provided
      </Text>
      <S.FullWidthButton onClick={onBack}>Back to Sign In</S.FullWidthButton>
    </S.Container>
  );
};
