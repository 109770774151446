/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AssignmentApi,
  CompanyAssigneesErrorCodes,
  CompanyAssigneesErrorTypes,
  CompanyAssignmentCreateErrorCodes,
  CompanyAssignmentDestroyErrorCodes,
  TaskAssigneeShowErrorCodes,
  TaskAssigneeShowErrorTypes,
  TaskAssigneeListErrorCodes,
  TaskAssigneeListErrorTypes,
  TaskAssignmentCreateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AssignmentApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type CompanyAssigneesMethod = AssignmentApi['companyAssignees'];
export type CompanyAssigneesResponseType = MethodResult<CompanyAssigneesMethod>;

export const useCompanyAssignees = (
  variables: Parameters<CompanyAssigneesMethod>[0],
  config?: UseQueryConfig<
    CompanyAssigneesMethod,
    CompanyAssigneesErrorTypes
  >
) => {
  return useQuery<
    CompanyAssigneesMethod,
    CompanyAssigneesErrorTypes
  >(
    (...args) => api.companyAssignees(...args),
    CompanyAssigneesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyAssignees'],
        ...apiQueryConfig.CompanyAssignees,
        ...config?.queryConfig
      },
    },
    'CompanyAssignees',
  );
};

export const useCompanyAssigneesInfinite = (
  variables: Parameters<CompanyAssigneesMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanyAssigneesMethod,
    CompanyAssigneesErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanyAssigneesMethod,
    CompanyAssigneesErrorTypes
  >(
    (...args) => api.companyAssignees(...args),
    CompanyAssigneesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyAssignees', 'CompanyAssigneesInfinite'],
        ...apiQueryConfig.CompanyAssignees,
        ...config?.queryConfig,
      },
    },
    'CompanyAssignees',
  );
};

export const useCompanyAssigneesLazy = (baseOptions?: {
  variables?: Parameters<CompanyAssigneesMethod>[0],
  config?: UseQueryConfig<
    CompanyAssigneesMethod,
    CompanyAssigneesErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanyAssigneesMethod,
    CompanyAssigneesErrorTypes
  >(
    (...args) => api.companyAssignees(...args),
    CompanyAssigneesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanyAssignees'],
        ...apiQueryConfig.CompanyAssignees,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanyAssignees',
  );
};


export type CompanyAssignmentCreateMethod = AssignmentApi['companyAssignmentCreate'];

export const useCompanyAssignmentCreate = () => {
  return useMutation<CompanyAssignmentCreateMethod>(
    (...args) => api.companyAssignmentCreate(...args),
    CompanyAssignmentCreateErrorCodes,
    'CompanyAssignmentCreate',
    '/api/internal/company_assignments',
    'post'
  );
};

export type CompanyAssignmentDestroyMethod = AssignmentApi['companyAssignmentDestroy'];

export const useCompanyAssignmentDestroy = () => {
  return useMutation<CompanyAssignmentDestroyMethod>(
    (...args) => api.companyAssignmentDestroy(...args),
    CompanyAssignmentDestroyErrorCodes,
    'CompanyAssignmentDestroy',
    '/api/internal/company_assignments/unassign',
    'post'
  );
};

export type TaskAssigneeShowMethod = AssignmentApi['taskAssigneeShow'];
export type TaskAssigneeShowResponseType = MethodResult<TaskAssigneeShowMethod>;

export const useTaskAssigneeShow = (
  variables: Parameters<TaskAssigneeShowMethod>[0],
  config?: UseQueryConfig<
    TaskAssigneeShowMethod,
    TaskAssigneeShowErrorTypes
  >
) => {
  return useQuery<
    TaskAssigneeShowMethod,
    TaskAssigneeShowErrorTypes
  >(
    (...args) => api.taskAssigneeShow(...args),
    TaskAssigneeShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TaskAssigneeShow'],
        ...apiQueryConfig.TaskAssigneeShow,
        ...config?.queryConfig
      },
    },
    'TaskAssigneeShow',
  );
};

export const useTaskAssigneeShowInfinite = (
  variables: Parameters<TaskAssigneeShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    TaskAssigneeShowMethod,
    TaskAssigneeShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    TaskAssigneeShowMethod,
    TaskAssigneeShowErrorTypes
  >(
    (...args) => api.taskAssigneeShow(...args),
    TaskAssigneeShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TaskAssigneeShow', 'TaskAssigneeShowInfinite'],
        ...apiQueryConfig.TaskAssigneeShow,
        ...config?.queryConfig,
      },
    },
    'TaskAssigneeShow',
  );
};

export const useTaskAssigneeShowLazy = (baseOptions?: {
  variables?: Parameters<TaskAssigneeShowMethod>[0],
  config?: UseQueryConfig<
    TaskAssigneeShowMethod,
    TaskAssigneeShowErrorTypes
  >
}) => {
  return useLazyQuery<
    TaskAssigneeShowMethod,
    TaskAssigneeShowErrorTypes
  >(
    (...args) => api.taskAssigneeShow(...args),
    TaskAssigneeShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['TaskAssigneeShow'],
        ...apiQueryConfig.TaskAssigneeShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'TaskAssigneeShow',
  );
};


export type TaskAssigneeListMethod = AssignmentApi['taskAssigneeList'];
export type TaskAssigneeListResponseType = MethodResult<TaskAssigneeListMethod>;

export const useTaskAssigneeList = (
  variables: Parameters<TaskAssigneeListMethod>[0],
  config?: UseQueryConfig<
    TaskAssigneeListMethod,
    TaskAssigneeListErrorTypes
  >
) => {
  return useQuery<
    TaskAssigneeListMethod,
    TaskAssigneeListErrorTypes
  >(
    (...args) => api.taskAssigneeList(...args),
    TaskAssigneeListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TaskAssigneeList'],
        ...apiQueryConfig.TaskAssigneeList,
        ...config?.queryConfig
      },
    },
    'TaskAssigneeList',
  );
};

export const useTaskAssigneeListInfinite = (
  variables: Parameters<TaskAssigneeListMethod>[0],
  config?: UseInfiniteQueryConfig<
    TaskAssigneeListMethod,
    TaskAssigneeListErrorTypes
  >
) => {
  return useInfiniteQuery<
    TaskAssigneeListMethod,
    TaskAssigneeListErrorTypes
  >(
    (...args) => api.taskAssigneeList(...args),
    TaskAssigneeListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TaskAssigneeList', 'TaskAssigneeListInfinite'],
        ...apiQueryConfig.TaskAssigneeList,
        ...config?.queryConfig,
      },
    },
    'TaskAssigneeList',
  );
};

export const useTaskAssigneeListLazy = (baseOptions?: {
  variables?: Parameters<TaskAssigneeListMethod>[0],
  config?: UseQueryConfig<
    TaskAssigneeListMethod,
    TaskAssigneeListErrorTypes
  >
}) => {
  return useLazyQuery<
    TaskAssigneeListMethod,
    TaskAssigneeListErrorTypes
  >(
    (...args) => api.taskAssigneeList(...args),
    TaskAssigneeListErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['TaskAssigneeList'],
        ...apiQueryConfig.TaskAssigneeList,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'TaskAssigneeList',
  );
};


export type TaskAssignmentCreateMethod = AssignmentApi['taskAssignmentCreate'];

export const useTaskAssignmentCreate = () => {
  return useMutation<TaskAssignmentCreateMethod>(
    (...args) => api.taskAssignmentCreate(...args),
    TaskAssignmentCreateErrorCodes,
    'TaskAssignmentCreate',
    '/api/internal/task_assignees/',
    'post'
  );
};
