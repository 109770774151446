/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EventsOrderItemIndex } from '../model';
/**
 * EventOrderItemApi - axios parameter creator
 * @export
 */
export const EventOrderItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list order items for the specified
         * @param {number} eventId 
         * @param {number} [accountId] 
         * @param {Array<string>} [state] 
         * @param {Array<string>} [assetType] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {Array<'asset' | 'matches' | 'holding'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventOrderItemIndex: async (eventId: number, accountId?: number, state?: Array<string>, assetType?: Array<string>, search?: string, page?: number, size?: number, includeField?: Array<'asset' | 'matches' | 'holding'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('eventOrderItemIndex', 'eventId', eventId)
            const localVarPath = changeUrl(`/api/events/{event_id}/order-items`)
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (state) {
                localVarQueryParameter['state'] = state.join(COLLECTION_FORMATS.csv);
            }

            if (assetType) {
                localVarQueryParameter['asset_type'] = assetType.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventOrderItemApi - functional programming interface
 * @export
 */
export const EventOrderItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventOrderItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary list order items for the specified
         * @param {number} eventId 
         * @param {number} [accountId] 
         * @param {Array<string>} [state] 
         * @param {Array<string>} [assetType] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {Array<'asset' | 'matches' | 'holding'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventOrderItemIndex(eventId: number, accountId?: number, state?: Array<string>, assetType?: Array<string>, search?: string, page?: number, size?: number, includeField?: Array<'asset' | 'matches' | 'holding'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsOrderItemIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventOrderItemIndex(eventId, accountId, state, assetType, search, page, size, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventOrderItemApi - factory interface
 * @export
 */
export const EventOrderItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventOrderItemApiFp(configuration)
    return {
        /**
         * 
         * @summary list order items for the specified
         * @param {number} eventId 
         * @param {number} [accountId] 
         * @param {Array<string>} [state] 
         * @param {Array<string>} [assetType] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {Array<'asset' | 'matches' | 'holding'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventOrderItemIndex(eventId: number, accountId?: number, state?: Array<string>, assetType?: Array<string>, search?: string, page?: number, size?: number, includeField?: Array<'asset' | 'matches' | 'holding'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<EventsOrderItemIndex> {
            return localVarFp.eventOrderItemIndex(eventId, accountId, state, assetType, search, page, size, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for eventOrderItemIndex operation in EventOrderItemApi.
 * @export
 * @interface EventOrderItemApiEventOrderItemIndexRequest
 */
export interface EventOrderItemApiEventOrderItemIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof EventOrderItemApiEventOrderItemIndex
     */
    readonly eventId: number

    /**
     * 
     * @type {number}
     * @memberof EventOrderItemApiEventOrderItemIndex
     */
    readonly accountId?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof EventOrderItemApiEventOrderItemIndex
     */
    readonly state?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof EventOrderItemApiEventOrderItemIndex
     */
    readonly assetType?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof EventOrderItemApiEventOrderItemIndex
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof EventOrderItemApiEventOrderItemIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof EventOrderItemApiEventOrderItemIndex
     */
    readonly size?: number

    /**
     * 
     * @type {Array<'asset' | 'matches' | 'holding'>}
     * @memberof EventOrderItemApiEventOrderItemIndex
     */
    readonly includeField?: Array<'asset' | 'matches' | 'holding'>

    /**
     * 
     * @type {string}
     * @memberof EventOrderItemApiEventOrderItemIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof EventOrderItemApiEventOrderItemIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof EventOrderItemApiEventOrderItemIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof EventOrderItemApiEventOrderItemIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof EventOrderItemApiEventOrderItemIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof EventOrderItemApiEventOrderItemIndex
     */
    readonly xUserRoleId?: string
}

/**
 * EventOrderItemApi - object-oriented interface
 * @export
 * @class EventOrderItemApi
 * @extends {BaseAPI}
 */
export class EventOrderItemApi extends BaseAPI {
    /**
     * 
     * @summary list order items for the specified
     * @param {EventOrderItemApiEventOrderItemIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventOrderItemApi
     */
    public eventOrderItemIndex(requestParameters: EventOrderItemApiEventOrderItemIndexRequest, options?: AxiosRequestConfig) {
        return EventOrderItemApiFp(this.configuration).eventOrderItemIndex(requestParameters.eventId, requestParameters.accountId, requestParameters.state, requestParameters.assetType, requestParameters.search, requestParameters.page, requestParameters.size, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type EventOrderItemIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EventOrderItemIndexErrorCodes = [
    401,
];


