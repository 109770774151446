import { Codebooks } from './codebooks.types';

const Items = {
  expired_password: 'expired_password',
  not_login: 'not_login',
  password_changed: 'password_changed',
  successful: 'successful',
} as const;

export const CbLoginType = {
  code: Codebooks.LOGIN_TYPE,
  items: Items,
};
