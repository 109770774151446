import styled from 'styled-components';

export const Wrapper = styled.div`
  width: auto;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.general.layout.one};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.sm}px 0;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;
