import { type VenusApi } from '@npm/data-access';
import { type LabelVariant } from '@npm/utils';

type TypeFilter = {
  key: string;
  title: string;
  filter: (filing: VenusApi.SimpleFiling) => boolean;
};

export const typeFilters: TypeFilter[] = [
  {
    key: 'all',
    title: 'All',
    filter: () => true,
  },
  {
    key: 'capital-structure',
    title: 'Capital Structure',
    filter: filing => filing.type === 'Capital Structure',
  },
  {
    key: 'issued shares',
    title: 'Issued Shares',
    filter: filing => filing.type === 'Issued Shares',
  },
  {
    key: '409a',
    title: '409A',
    filter: filing => filing.type === '409A',
  },
  {
    key: 'ipo',
    title: 'IPO',
    filter: filing => filing.type === 'IPO',
  },
  {
    key: 'alternative-investment',
    title: 'Alternative Investment',
    filter: filing => filing.type === 'Alternative Investment',
  },
];

export const typeFiltersByKey = typeFilters.reduce<Record<string, TypeFilter>>(
  (acc, cur) => {
    acc[cur.key] = cur;
    return acc;
  },
  {}
);

export const labelVariantByType: Record<string, LabelVariant> = {
  'Capital Structure': 'general',
  'Issued Shares': 'info',
  '409A': 'general',
  IPO: 'warning',
  'Alternative Investment': 'general',
};
