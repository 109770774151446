import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { CardBase } from '@npm/core/ui/components/atoms/CardBase';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { Table } from '@npm/core/ui/components/molecules/Table';
import { type CompanyPricingApi } from '@npm/data-access';

import { sortArrayByDateField } from '../../../CompanyOverview.utils';
import { ChartLineIcon } from '../../../components/ChartLineIcon/ChartLineIcon';
import {
  TableScrollIndicator,
  useScrollIndicatorClick,
} from '../../../components/TableScrollIndicator';
import { type TapeDValue } from '../../TapeD.types';
import { getTapeDColor } from '../../TapeD.utils';

import { TAPE_D_TABLE_COLUMNS } from './TapeDTable.columns';

type Props = {
  items: CompanyPricingApi.IntrinsicValueIndex['intrinsic_values'];
};

const TABLE_MAX_HEIGHT = 275;
const TABLE_ID = 'tape-d-table';

export const TapeDTable = ({ items }: Props) => {
  const theme = useTheme();

  const data = useMemo(() => sortArrayByDateField(items, 'desc'), [items]);
  const showScrollIndicator = data?.length > 5;

  const { tableProps, scrollIndicatorProps } =
    useScrollIndicatorClick<TapeDValue>(
      TABLE_ID,
      TABLE_MAX_HEIGHT,
      showScrollIndicator
    );

  const header = (
    <Margin left={'md'} top={'sm'} bottom={'sm'}>
      <Flex direction="row" gap={'sm'} align={'center'}>
        <ChartLineIcon color={getTapeDColor(theme)} />
        <Heading variant="h2">
          Tape D Price{' '}
          <Text colorVariant="tertiary" as="span">
            ({data.length})
          </Text>
        </Heading>
      </Flex>
    </Margin>
  );

  return (
    <CardBase
      noContentPadding={true}
      header={header}
      style={{ position: 'relative' }}
    >
      <Table<TapeDValue>
        id={TABLE_ID}
        disablePagination={true}
        outlined={false}
        dataSource={data}
        columns={TAPE_D_TABLE_COLUMNS}
        noDataArea={<NoDataArea title={'No prices'} />}
        {...tableProps}
      />
      {showScrollIndicator && (
        <TableScrollIndicator {...scrollIndicatorProps} />
      )}
    </CardBase>
  );
};
