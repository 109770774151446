import React from 'react';

import { Helmet } from '@npm/core/ui/components/atoms/Helmet';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { ParamNotFoundError, useParams } from '@npm/core/ui/hooks/useParams';
import { type RouteTitleProps } from '@npm/core/ui/routes/router.types';
import { truncate } from '@npm/core/ui/utils/formatters';
import { useEventShow } from '@npm/data-access';

export const ProgramDetailPageTitle = ({ raw }: RouteTitleProps) => {
  const { isMobile } = useBreakpoints();
  const { programId } = useParams<{ programId: string }>();

  if (programId == null) throw new ParamNotFoundError('Program ID is required');

  const { data, isLoading } = useEventShow({
    id: parseInt(programId),
    includeField: [
      'assets',
      'issuance',
      'issuer_entity',
      'submission_statistics',
      'settings',
      'order_settings',
    ],
  });

  if (raw && isLoading) return <>...</>;
  if (isLoading) return <Helmet pageTitle={null} />;

  const title = data?.name ?? `Program #${programId}`;

  if (raw) {
    return <>{isMobile ? truncate(title) : title}</>;
  }
  return <Helmet pageTitle={title} />;
};
