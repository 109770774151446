import styled from 'styled-components';

import { useBreakpoint } from '../../../../utils/style';

import darkBg from './assets/empty-page-bg-dark.png';
import lightBg from './assets/empty-page-bg-light.png';

export const Background = styled.div<{
  $isDarkMode: boolean;
  $offsetTop: number;
}>`
  position: fixed;
  background-image: url(${({ $isDarkMode }) =>
    $isDarkMode ? darkBg : lightBg});
  background-position: left bottom;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  top: ${({ $offsetTop }) => $offsetTop}px;
  right: 0;
  width: 30%;

  ${({ theme }) => useBreakpoint(theme).smDesktop`
      transform: translateX(25%);
  `}

  ${({ theme }) => useBreakpoint(theme).tablet`
      transform: translateX(75%);
  `}

  ${({ theme }) => useBreakpoint(theme).smTablet`
      display: none;
  `}

  // fine-tune larger screens
  @media screen and (min-width: 2000px) {
    background-position: right bottom;
    background-size: contain;
  }
`;
