import { type OrganizationAggregate } from '@npm/data-access';

import { DocumentPreview } from '../../../documents/DocumentPreview';

export const prepareReadonlyData = (
  data: OrganizationAggregate,
  accountName: string,
  isDesktop: boolean
) => {
  const constituentDocument = data?.constituent_document;
  const isAuthorized = !data?.loa_needed;
  const loaDocument = data?.loa_docs?.[0]?.loa_doc;

  const rows = [];

  if (constituentDocument) {
    rows.push(
      {
        label: 'Formation Document',
        value: (
          <DocumentPreview
            document={data?.constituent_document}
            labelMaxCharacters={isDesktop ? 32 : 16}
          />
        ),
      },
      {
        label: `The above document shows I am authorized to transact on behalf of ${accountName}`,
        value: !isAuthorized ? 'No' : 'Yes',
      }
    );
  }

  if (!isAuthorized && loaDocument) {
    rows.push({
      label: 'Proof of Authorization',
      value: (
        <DocumentPreview
          document={data?.loa_docs?.[0]?.loa_doc}
          labelMaxCharacters={isDesktop ? 32 : 16}
        />
      ),
    });
  }

  return rows;
};
