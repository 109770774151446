import { Codebooks } from './codebooks.types';

const Items = {
  active: 'active',
  inactive: 'inactive',
} as const;

export const CbUserRoleStatus = {
  code: Codebooks.USER_ROLE_STATUS,
  items: Items,
};
