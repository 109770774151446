import { sanitize } from 'dompurify';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import {
  EMPTY_VALUE,
  Heading,
  Text,
} from '@npm/core/ui/components/atoms/Typography';
import { WysiwygContent } from '@npm/core/ui/components/atoms/WysiwygContent';
import { type Answer } from '@npm/data-access';

import { type WhitelistedAnswerCodes } from '../EntityAccreditation.types';

import * as S from '@npm/core/ui/components/molecules/ReadOnly/ReadOnly.styles';

type Props = {
  answer: Answer;
  accreditationStatusTitle: string;
  status?: 'success' | 'error';
};

const codeToCustomLabelMap = new Map<string, string>([
  ['org_type', 'Accredited Investor'],
  ['not_available', 'Not Accredited'],
  ['qualified_purchaser', 'Qualified Purchaser'],
  ['qualified_institutional_buyer', 'Qualified Institutional Buyer'],
  ['qualified_client', 'Qualified Client'],
]);

export const AccreditationDataItem = ({
  answer,
  accreditationStatusTitle,
  status = 'success',
}: Props) => {
  return (
    <Flex direction="column" gap="md">
      <Heading variant="h3">{accreditationStatusTitle}</Heading>
      <S.Content>
        <S.DataItem>
          <Text size="sm">Your Sophistication Level</Text>
          <Flex justify="space-between" align="center">
            <Label
              variant={status}
              icon={
                <Icon name={status === 'error' ? 'info-circle' : 'check'} />
              }
            >
              {codeToCustomLabelMap.get(
                answer.field?.code as WhitelistedAnswerCodes
              )}
            </Label>
          </Flex>
        </S.DataItem>
        {answer.answer && (
          <S.DataItem key={'selected'}>
            <Text size="sm">You Selected</Text>
            <Text size="sm" colorVariant="primary" as="div">
              <WysiwygContent
                dangerouslySetInnerHTML={{
                  __html: sanitize(answer.answer?.name ?? EMPTY_VALUE, {
                    RETURN_TRUSTED_TYPE: true,
                  }),
                }}
              />
            </Text>
          </S.DataItem>
        )}
      </S.Content>
    </Flex>
  );
};
