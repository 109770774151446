import styled from 'styled-components';

export const Circle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: ${({ theme }) => theme.spacing.xs}px;
  border-radius: 100%;
  background-color: ${({ theme }) =>
    theme.color.component.icon.backgrounds.circle};

  svg {
    color: ${({ theme }) => theme.color.info.typography.primary};
  }
`;
