import React, { type ComponentProps, Fragment } from 'react';
import { type InfiniteData } from '@tanstack/react-query';
import { type ColorGroup } from 'styled-components';

import { InfiniteScroll } from '@npm/core/ui/components/atoms/InfiniteScroll';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { CypressDataIds } from '@npm/core/ui/constants';
import { type HoldingIndex } from '@npm/data-access';

import { usePermissions } from '../../../auth/permissions/usePermissions';
import { HoldingCard } from '../../Card';

import * as S from './HoldingsCards.styles';

type Props = {
  data?: InfiniteData<HoldingIndex>;
  isFetching: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFilterApplied?: boolean;
  noDataText?: string;
  noDataIconColor?: ColorGroup;
} & Omit<ComponentProps<typeof HoldingCard>, 'holding'>;

export const HoldingsCards = ({
  data,
  isFetching,
  fetchNextPage,
  hasNextPage,
  isFilterApplied,
  noDataText,
  noDataIconColor,
  ...rest
}: Props) => {
  const { canWrite, BRO_ACCOUNT_MANAGER } = usePermissions();
  const canEditHoldings = canWrite || BRO_ACCOUNT_MANAGER;

  const totalRecords = data?.pages?.[0]?.pagination?.total_records;

  return (
    <S.Layout data-cy={CypressDataIds.Holdings.Cards.Container}>
      <InfiniteScroll
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        isFetching={isFetching}
        loader={<HoldingCard isLoading />}
      >
        {data?.pages.map(page => (
          <Fragment key={page.pagination.page}>
            {page.holdings.map(holding => (
              <HoldingCard
                key={holding.id}
                holding={holding}
                notAuthorized={!canEditHoldings}
                {...rest}
              />
            ))}
          </Fragment>
        ))}
      </InfiniteScroll>
      {!isFetching && !totalRecords && (
        <NoDataArea
          outlined
          title={
            isFilterApplied
              ? "We couldn't find any results with your search criteria, please, try with different attributes."
              : noDataText || 'No Holdings found.'
          }
          iconColor={noDataIconColor}
        />
      )}
    </S.Layout>
  );
};
