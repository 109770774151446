export interface Step {
  label: string;
  codebookName?: string;
  tooltip?: string;
}

export const stepsEntity: Step[] = [
  {
    label: 'Entity Information',
  },
  {
    label: 'Contact Information',
    codebookName: 'entity_information_state',
  },
  {
    label: 'Account’s Taxpayer Status',
    codebookName: 'tax_information_state',
  },
  {
    label: 'Entity Tax ID',
    codebookName: 'entity_tax_state',
  },
  {
    label: 'Formation document',
    codebookName: 'entity_formation_document_state',
  },
  {
    label: 'Authorized Signer',
    codebookName: 'authorized_signer_state',
  },
  {
    label: 'Beneficial Ownership Assessment',
    codebookName: 'beneficial_ownership_assessment_state',
  },
  {
    label: 'Representative Information',
    codebookName: 'personal_information_state',
  },
];

export const stepsIndividual: Step[] = [
  {
    label: 'Personal Information',
    tooltip:
      'We will use your existing AML/KYC data to make your onboarding process even more seamless.',
  },
  {
    label: 'Contact Information',
    codebookName: 'personal_information_state',
  },
  {
    label: 'Tax Information',
    codebookName: 'tax_information_state',
  },
  {
    label: 'Spousal Information',
    codebookName: 'spouse_information_state',
  },
  {
    label: 'Identity Verification',
    codebookName: 'identity_verification',
  },
  {
    label: 'Authorized Signer',
    codebookName: 'authorized_signer_state',
  },
];
