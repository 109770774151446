/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { NdaUser } from '../model';
// @ts-ignore
import { NdaUserCreateRequestContract } from '../model';
// @ts-ignore
import { NdaUserIndex } from '../model';
/**
 * NdaUserApi - axios parameter creator
 * @export
 */
export const NdaUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates a nda_user and generates pdf
         * @param {NdaUserCreateRequestContract} ndaUserCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ndaUserCreate: async (ndaUserCreateRequestContract: NdaUserCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ndaUserCreateRequestContract' is not null or undefined
            assertParamExists('ndaUserCreate', 'ndaUserCreateRequestContract', ndaUserCreateRequestContract)
            const localVarPath = changeUrl(`/api/nda-users`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ndaUserCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list nda_users for the current user
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [ownerId] 
         * @param {string} [ownerType] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ndaUserIndex: async (page?: number, size?: number, ownerId?: number, ownerType?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/nda-users`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['owner_id'] = ownerId;
            }

            if (ownerType !== undefined) {
                localVarQueryParameter['owner_type'] = ownerType;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns a nda_user if the latest nda is signed
         * @param {number} ownerId 
         * @param {string} ownerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ndaUserShow: async (ownerId: number, ownerType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ownerId' is not null or undefined
            assertParamExists('ndaUserShow', 'ownerId', ownerId)
            // verify required parameter 'ownerType' is not null or undefined
            assertParamExists('ndaUserShow', 'ownerType', ownerType)
            const localVarPath = changeUrl(`/api/nda-user`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ownerId !== undefined) {
                localVarQueryParameter['owner_id'] = ownerId;
            }

            if (ownerType !== undefined) {
                localVarQueryParameter['owner_type'] = ownerType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NdaUserApi - functional programming interface
 * @export
 */
export const NdaUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NdaUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates a nda_user and generates pdf
         * @param {NdaUserCreateRequestContract} ndaUserCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ndaUserCreate(ndaUserCreateRequestContract: NdaUserCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NdaUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ndaUserCreate(ndaUserCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list nda_users for the current user
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [ownerId] 
         * @param {string} [ownerType] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ndaUserIndex(page?: number, size?: number, ownerId?: number, ownerType?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NdaUserIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ndaUserIndex(page, size, ownerId, ownerType, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns a nda_user if the latest nda is signed
         * @param {number} ownerId 
         * @param {string} ownerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ndaUserShow(ownerId: number, ownerType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NdaUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ndaUserShow(ownerId, ownerType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NdaUserApi - factory interface
 * @export
 */
export const NdaUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NdaUserApiFp(configuration)
    return {
        /**
         * 
         * @summary creates a nda_user and generates pdf
         * @param {NdaUserCreateRequestContract} ndaUserCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ndaUserCreate(ndaUserCreateRequestContract: NdaUserCreateRequestContract, options?: any): AxiosPromise<NdaUser> {
            return localVarFp.ndaUserCreate(ndaUserCreateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list nda_users for the current user
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [ownerId] 
         * @param {string} [ownerType] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ndaUserIndex(page?: number, size?: number, ownerId?: number, ownerType?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<NdaUserIndex> {
            return localVarFp.ndaUserIndex(page, size, ownerId, ownerType, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns a nda_user if the latest nda is signed
         * @param {number} ownerId 
         * @param {string} ownerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ndaUserShow(ownerId: number, ownerType: string, options?: any): AxiosPromise<NdaUser> {
            return localVarFp.ndaUserShow(ownerId, ownerType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for ndaUserCreate operation in NdaUserApi.
 * @export
 * @interface NdaUserApiNdaUserCreateRequest
 */
export interface NdaUserApiNdaUserCreateRequest {
    /**
     * 
     * @type {NdaUserCreateRequestContract}
     * @memberof NdaUserApiNdaUserCreate
     */
    readonly ndaUserCreateRequestContract: NdaUserCreateRequestContract
}

/**
 * Request parameters for ndaUserIndex operation in NdaUserApi.
 * @export
 * @interface NdaUserApiNdaUserIndexRequest
 */
export interface NdaUserApiNdaUserIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof NdaUserApiNdaUserIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof NdaUserApiNdaUserIndex
     */
    readonly size?: number

    /**
     * 
     * @type {number}
     * @memberof NdaUserApiNdaUserIndex
     */
    readonly ownerId?: number

    /**
     * 
     * @type {string}
     * @memberof NdaUserApiNdaUserIndex
     */
    readonly ownerType?: string

    /**
     * 
     * @type {string}
     * @memberof NdaUserApiNdaUserIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof NdaUserApiNdaUserIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof NdaUserApiNdaUserIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof NdaUserApiNdaUserIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof NdaUserApiNdaUserIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof NdaUserApiNdaUserIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for ndaUserShow operation in NdaUserApi.
 * @export
 * @interface NdaUserApiNdaUserShowRequest
 */
export interface NdaUserApiNdaUserShowRequest {
    /**
     * 
     * @type {number}
     * @memberof NdaUserApiNdaUserShow
     */
    readonly ownerId: number

    /**
     * 
     * @type {string}
     * @memberof NdaUserApiNdaUserShow
     */
    readonly ownerType: string
}

/**
 * NdaUserApi - object-oriented interface
 * @export
 * @class NdaUserApi
 * @extends {BaseAPI}
 */
export class NdaUserApi extends BaseAPI {
    /**
     * 
     * @summary creates a nda_user and generates pdf
     * @param {NdaUserApiNdaUserCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NdaUserApi
     */
    public ndaUserCreate(requestParameters: NdaUserApiNdaUserCreateRequest, options?: AxiosRequestConfig) {
        return NdaUserApiFp(this.configuration).ndaUserCreate(requestParameters.ndaUserCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list nda_users for the current user
     * @param {NdaUserApiNdaUserIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NdaUserApi
     */
    public ndaUserIndex(requestParameters: NdaUserApiNdaUserIndexRequest = {}, options?: AxiosRequestConfig) {
        return NdaUserApiFp(this.configuration).ndaUserIndex(requestParameters.page, requestParameters.size, requestParameters.ownerId, requestParameters.ownerType, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns a nda_user if the latest nda is signed
     * @param {NdaUserApiNdaUserShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NdaUserApi
     */
    public ndaUserShow(requestParameters: NdaUserApiNdaUserShowRequest, options?: AxiosRequestConfig) {
        return NdaUserApiFp(this.configuration).ndaUserShow(requestParameters.ownerId, requestParameters.ownerType, options).then((request) => request(this.axios, this.basePath));
    }
}

export type NdaUserCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const NdaUserCreateErrorCodes = [
    401,
];

export type NdaUserIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const NdaUserIndexErrorCodes = [
    401,
];

export type NdaUserShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const NdaUserShowErrorCodes = [
    401,
];


