import styled, { createGlobalStyle, css } from 'styled-components';

import { useBreakpoint } from '../../../../utils/style';
import { BodyTextStyles, SmallTextStyles } from '../../../atoms/Typography';

import { MENU_ITEM_HEIGHT, SUBMENU_ITEM_HEIGHT } from '../Layout.styles';

const withActiveIndicator = css`
  ::after {
    content: '';
    position: absolute;
    right: 0;
    width: 2px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background: ${({ theme }) => theme.color.info.borders.primary};
    transform: unset !important;
    opacity: unset !important;
  }
`;

export const NavigationWrapper = styled.div<{ $isCollapsed: boolean }>`
  ${({ theme, $isCollapsed }) => css`
    --menu-margin-left: ${theme.spacing.lg / 2}px;
    margin-top: ${({ theme }) => theme.spacing.md}px;

    &&& .ant-menu {
      background: ${theme.color.general.layout.zero};

      .ant-menu-item,
      .ant-menu-submenu-title {
        ${$isCollapsed && `overflow: visible;`}

        .ant-menu-title-content {
          ${$isCollapsed && `display: none;`}
        }
      }

      // parent menu
      .ant-menu-submenu {
        // if some child item selected and parent menu closed (or menu collapsed)
        &.ant-menu-submenu-selected:not(.ant-menu-submenu-open)
          .ant-menu-submenu-title {
          background: ${theme.color.general.layout.two};

          svg:first-child {
            color: ${theme.color.info.typography.primary};
          }

          .ant-menu-title-content {
            color: ${theme.color.general.typography.primary};
          }

          ${withActiveIndicator}
        }

        &.ant-menu-submenu-active {
          .ant-menu-submenu-title {
            background: ${theme.color.general.layout.two};
            svg:first-child {
              color: ${theme.color.general.typography.secondary};
            }
          }
        }

        // parent menu item
        .ant-menu-submenu-title {
          ${SmallTextStyles};
          height: ${MENU_ITEM_HEIGHT}px;
          border-radius: ${theme.borderRadius.lg}px;
          // padding-left is defined as inlineIndent prop
          padding-right: ${theme.spacing.md}px;

          ${() =>
            useBreakpoint(theme).mobile`${css`
              ${BodyTextStyles}
            `}`};

          &:hover {
            background: ${theme.color.info.backgrounds.secondaryHover};

            svg:first-child {
              color: ${theme.color.general.typography.secondary};
            }

            // prevent hover effect on mobile while scrolling
            ${() =>
              useBreakpoint(theme).mobile`${css`
                background: transparent;
              `}`};
          }

          .ant-menu-title-content {
            margin-left: var(--menu-margin-left);
          }

          // left icon
          svg:first-child {
            width: 16px;
            height: 16px;
            min-width: 16px;
            color: ${theme.color.general.typography.tertiary};
          }

          //chevron icon
          svg:last-child {
            color: ${theme.color.general.typography.secondary};
          }
        }
      }

      // menu item
      .ant-menu-item {
        ${SmallTextStyles};
        height: ${SUBMENU_ITEM_HEIGHT}px;
        border-radius: ${theme.borderRadius.lg}px;

        ${() =>
          useBreakpoint(theme).mobile`${css`
            ${BodyTextStyles}
          `}`};

        // menu item icon
        svg:first-child {
          width: 16px;
          height: 16px;
          min-width: 16px;
          color: ${theme.color.general.typography.tertiary};
        }

        ::after {
          border: none;
        }

        &:hover:not(.ant-menu-item-disabled),
        &:active:not(.ant-menu-item-disabled) {
          background: ${theme.color.info.backgrounds.secondaryHover};

          // icon of root menu items with no children
          svg:first-child {
            color: ${theme.color.general.typography.secondary};
          }

          .ant-menu-title-content {
            color: ${theme.color.general.typography.primary};
          }

          // prevent hover effect on mobile while scrolling
          ${() =>
            useBreakpoint(theme).mobile`${css`
              background: transparent;
              .ant-menu-title-content {
                color: ${theme.color.general.typography.secondary};
              }
            `}`};
        }

        &.ant-menu-item-disabled {
          &:hover,
          &:active {
            background: transparent;
          }
        }

        // selected menu item
        &.ant-menu-item-selected {
          background: ${theme.color.general.layout.two};

          .ant-menu-title-content {
            color: ${theme.color.general.typography.primary};
          }

          svg:first-child {
            color: ${theme.color.info.typography.primary};
          }

          ${withActiveIndicator}
        }

        .ant-menu-title-content {
          color: ${theme.color.general.typography.secondary};
          margin-left: var(--menu-margin-left);
        }
      }
      // collapsed menu
      &.ant-menu-inline-collapsed {
        // root menu item with no children
        &.ant-menu-root > .ant-menu-item {
          display: flex;
          align-items: center;
          height: ${MENU_ITEM_HEIGHT}px;
          border-radius: ${theme.borderRadius.lg}px;
        }

        .ant-menu-submenu {
          .ant-menu-submenu-title {
            display: flex;
            align-items: center;
          }
        }
      }

      // not collapsed only
      &:not(.ant-menu-inline-collapsed) {
        // root menu item with no children
        &.ant-menu-root > .ant-menu-item {
          height: ${MENU_ITEM_HEIGHT}px;
          border-radius: ${theme.borderRadius.lg}px;

          .ant-menu-title-content {
            color: ${theme.color.general.typography.primary};
          }
        }

        .ant-menu-submenu {
          padding-bottom: ${theme.spacing.lg / 4}px;
        }
      }
    }
  `}

  .ant-menu-item,
  .ant-menu-submenu-title {
    // support AntD Icon
    svg[data-icon] {
      margin-right: 2px;
    }
  }

  .ant-menu-sub > .ant-menu-item {
    white-space: pre-wrap !important;
  }
`;

export const MenuPopup = createGlobalStyle<{ $zIndex?: number }>`
  ${({ theme }) => css`
    // child menu popup (of collapsed menu)
    .ant-menu-submenu-popup > .ant-menu {
      background: ${theme.color.general.layout.one};
      border: 1px solid ${theme.color.general.borders.secondary};
      border-radius: ${theme.borderRadius.md}px;
      margin-left: 5px;
      box-shadow: unset;

      .ant-menu-item {
        margin: 0;
        padding: 0 ${theme.spacing.lg / 2}px;
        height: ${SUBMENU_ITEM_HEIGHT}px;
        line-height: ${SUBMENU_ITEM_HEIGHT}px;

        &.ant-menu-item-selected {
          background: ${theme.color.general.layout.two};
          .ant-menu-title-content {
            color: ${theme.color.general.typography.primary};
          }

          ${withActiveIndicator};
        }

        &:hover {
          background: ${theme.color.info.backgrounds.secondaryHover};
          .ant-menu-title-content {
            color: ${theme.color.general.typography.primary};
          }
        }

        .ant-menu-title-content {
          color: ${theme.color.general.typography.secondary};
        }
      }
    }
  `}
`;

export const NavigationButtonWrapper = styled.div`
  width: 100%;

  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  padding: 0 ${({ theme }) => theme.spacing.sm}px;

  svg {
    width: 14px;
    height: 14px;
  }

  & > .ant-btn {
    width: 100%;
    font-size: ${({ theme }) => theme.typography.size.sm}px;
    line-height: ${({ theme }) => theme.typography.height.sm}px;
  }
`;
