import {
  type CodebookAttribute,
  type CodebookItem,
  Codebooks,
} from './codebooks.types';

interface TaxDocumentAttribute extends CodebookAttribute {
  name: 'entity' | 'individual' | 'help';
}
export interface TaxDocumentCodebookItem extends CodebookItem {
  attributes: TaxDocumentAttribute[];
}

const Items = {
  DOCUMENT_W9: 'document_w9',
  DOCUMENT_W8BEN: 'document_w8ben',
  DOCUMENT_W8BEN_E: 'document_w8ben_e',
  DOCUMENT_W8IMY: 'document_w8imy',
} as const;

export const CbTaxDocument = {
  code: Codebooks.TAX_DOCUMENT,
  items: Items,
};
