import React, { type ComponentProps } from 'react';
import { type FormInstance } from 'antd';
import { type FormProps } from 'antd/es/form/Form';

import { Form } from '@npm/core/ui/components/atoms/Form';

import { ContactInformationForm } from '../index';

import { type EntityInfoFormType } from './EntityInformation.types';

type Props = ComponentProps<typeof ContactInformationForm> & {
  form: FormInstance<EntityInfoFormType>;
  handleValuesChange?: FormProps['onValuesChange'];
};

export const EntityInformationForm = ({
  form,
  initialCountryCode,
  isLoading,
  isFilled,
  handleValuesChange,
}: Props) => {
  return (
    <Form
      form={form}
      validateTrigger="onSubmit"
      onValuesChange={handleValuesChange}
    >
      <ContactInformationForm
        form={form}
        initialCountryCode={initialCountryCode}
        isLoading={isLoading}
        isFilled={isFilled}
      />
    </Form>
  );
};
