import React, { type ComponentProps, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { useBreakpoints } from '../../../../hooks/useBreakpoints';
import { BottomNavigation } from '../../../atoms/BottomNavigation';
import { Button } from '../../../atoms/Button';
import { Tooltip } from '../../../atoms/Tooltip';
import { useLocation } from '../../Link';
import { PAGE_HEADER_BUTTON_CONTAINER_ID } from '../PageHeader.constants';

import * as S from './PageHeaderButton.styles';

type Props = {
  tooltip?: React.ReactNode;
} & ComponentProps<typeof Button>;

export const PageHeaderButton: React.FC<Props> = props => {
  const { isMobile, isTablet } = useBreakpoints();
  const [container, setContainer] = useState<HTMLElement | null>(null);

  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById(PAGE_HEADER_BUTTON_CONTAINER_ID);

      setContainer(element);

      return () => clearTimeout(timer);
    }, 1);
  }, [location.pathname]);

  if (isMobile || isTablet) {
    return (
      <BottomNavigation
        onNext={props.onClick}
        nextButtonProps={props}
        hideOnScrollDown
      />
    );
  }

  if (!container) return <></>;

  return createPortal(
    <S.Container>
      <Tooltip title={props.tooltip}>
        <Button blockOnMobile {...props} />
      </Tooltip>
    </S.Container>,
    container
  );
};
