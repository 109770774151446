export const getFullName = (
  first: string | undefined,
  last: string | undefined,
  middle?: string
) => {
  if (!first && !last && !middle) return '';
  return [first, middle, last]
    .map(val => val?.trim())
    .filter(Boolean)
    .join(' ');
};
