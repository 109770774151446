type RemoveEmptyValuesOptions = {
  removeEmptyString?: boolean;
  removeEmptyArray?: boolean;
};
export const removeEmptyValues = <T>(
  obj: T,
  options: RemoveEmptyValuesOptions = {}
): T => {
  if (!obj) {
    return obj;
  }
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (
      (options.removeEmptyString && value === '') ||
      (options.removeEmptyArray && Array.isArray(value) && !value.length) ||
      value === null ||
      value === undefined
    ) {
      return acc;
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {} as T);
};
