import React, { type ComponentProps } from 'react';

import { WorkstationAccountSearch } from '../../smartSelects/WorkstationAccountSearch';
import { type FilterItemProps } from '../FilterPanel';

type Props = FilterItemProps & ComponentProps<typeof WorkstationAccountSearch>;

// Used in ISS - non-event tracking
export const WorkstationAccountFilter = ({
  name,
  onFilterSubmit,
  ...rest
}: Props) => {
  return (
    <WorkstationAccountSearch
      onSelect={() => {
        onFilterSubmit?.(name);
      }}
      onClear={() => onFilterSubmit?.(name)}
      {...rest}
    />
  );
};
