import React from 'react';

import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';

import { type StatusProps } from './ProgramCard.types';

import * as S from './ProgramCard.styles';

type LoadingProgramProps = { status: StatusProps['status'] };

export const LoadingProgramCard = ({ status }: LoadingProgramProps) => (
  <S.Card>
    <S.Header>
      <Skeleton.Avatar size={22} />
      <Skeleton.Base title={false} paragraph={S.FullWidthSkeletonStyle} />
    </S.Header>
    <S.Content>
      <Skeleton.Base title={false} paragraph={S.CommonSkeletonStyle} />
      <S.Status status={status}>
        <Skeleton.Base title={false} paragraph={S.FullWidthSkeletonStyle} />
      </S.Status>
      <div>
        <S.DataWrapper>
          {[...Array(5)].map((_, index) => (
            <React.Fragment key={index}>
              {/* TODO: NPM-313 create component  */}
              <S.DataPair key={index}>
                <Skeleton.Base
                  title={false}
                  paragraph={S.FullWidthSkeletonStyle}
                />
              </S.DataPair>
            </React.Fragment>
          ))}
        </S.DataWrapper>
      </div>
      <Skeleton.Button style={S.ButtonSkeletonStyle} />
    </S.Content>{' '}
  </S.Card>
);
