import { Layout as LayoutBase } from 'antd';
import styled, { css } from 'styled-components';

import { useBreakpoint } from '../../../../utils/style';
import { LAYOUT_CONTENT_BOTTOM_SPACING_PX } from '../Layout.constants';

const { Content } = LayoutBase;

export const Wrapper = styled(Content)<{ $noBottomPadding: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  background: transparent;
  flex: 1;
  ${({ $noBottomPadding }) =>
    $noBottomPadding
      ? null
      : `padding-bottom: ${LAYOUT_CONTENT_BOTTOM_SPACING_PX}px;`};
`;

export const WrapperPageHeader = styled.div<{
  $fullWidth: boolean;
  $maxWidth: string;
  $noPadding: boolean;
}>`
  ${({ $fullWidth, $maxWidth }) =>
    $fullWidth
      ? null
      : `
      margin: auto;
      max-width: ${$maxWidth}px;
  `}
  padding: 0 ${({ $noPadding, theme }) =>
    $noPadding ? 0 : theme.spacing.md}px;
  ${({ $noPadding, theme }) => (useBreakpoint(theme).mobile ||
    useBreakpoint(theme).tablet)`
    padding: 0 ${$noPadding ? 0 : theme.spacing.sm}px;
  `};
  width: 100%;
`;

export const WrapperContent = styled.div<{
  $fullWidth: boolean;
  $noPadding: boolean;
  $maxWidth: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ $fullWidth, $maxWidth }) =>
    $fullWidth
      ? null
      : `
      margin: auto;
      max-width: ${$maxWidth}px;
  `}
  flex: 1;
  min-height: 0;
  width: 100%;
  padding: 0 ${({ $noPadding, theme }) => ($noPadding ? 0 : theme.spacing.md)}px;
  ${({ $noPadding, theme }) => (useBreakpoint(theme).mobile ||
    useBreakpoint(theme).tablet)`
    padding: 0 ${$noPadding ? 0 : theme.spacing.sm}px;
  `};

  background: transparent;
`;

export const ChildrenWrapper = styled.div<{
  $noPadding: boolean;
}>`
  height: 100%;
  background: transparent;
  position: relative;
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    ${({ $noPadding, theme }) => css`
      ${!$noPadding &&
      `margin-bottom: ${theme.spacing.md}px;
        ${() => useBreakpoint(theme).mobile`
          margin-bottom: ${theme.spacing.sm}px;
        `}
      `}
    `}
  }
`;
