import { type DefaultTheme } from 'styled-components';
import create from 'zustand';

export type MaxWidthValues = '1920' | '1440';

interface LayoutStore {
  background: keyof DefaultTheme['color']['general']['layout'] | null;
  noPadding: boolean;
  layoutFlex: boolean;
  noBottomPadding: boolean;
  fullWidth: boolean;
  maxWidth: MaxWidthValues;
  headerHeight: number;
  isSidebarCollapsible: boolean;
  setBackground: (bg: keyof DefaultTheme['color']['general']['layout']) => void;
  resetBackground: () => void;
  setFullWidth: () => void;
  resetMaxWidth: () => void;
  setMaxWidth: (width: MaxWidthValues) => void;
  resetFullWidth: () => void;
  setNoPadding: () => void;
  resetNoPadding: () => void;
  setLayoutFlex: () => void;
  resetLayoutFlex: () => void;
  setNoBottomPadding: () => void;
  resetNoBottomPadding: () => void;
  setHeaderHeight: (headerHeight: number) => void;
  setIsSidebarCollapsible: (isCollapsible: boolean) => void;
}

export const useLayoutStore = create<LayoutStore>(set => ({
  background: null,
  maxWidth: '1440',
  fullWidth: false,
  noPadding: false,
  noBottomPadding: false,
  layoutFlex: false,
  headerHeight: 0,
  isSidebarCollapsible: false,
  setBackground: bg => set({ background: bg }),
  resetBackground: () => set({ background: null }),
  setFullWidth: () => set({ fullWidth: true }),
  resetFullWidth: () => set({ fullWidth: false }),
  setNoPadding: () => set({ noPadding: true }),
  resetNoPadding: () => set({ noPadding: false }),
  setMaxWidth: width => set({ maxWidth: width }),
  resetMaxWidth: () => set({ maxWidth: '1440' }),
  setNoBottomPadding: () => set({ noBottomPadding: true }),
  resetNoBottomPadding: () => set({ noBottomPadding: false }),
  setLayoutFlex: () => set({ layoutFlex: true }),
  resetLayoutFlex: () => set({ layoutFlex: false }),
  setHeaderHeight: headerHeight => set({ headerHeight }),
  setIsSidebarCollapsible: isSidebarCollapsible =>
    set({ isSidebarCollapsible }),
}));
