import React, { useEffect, useRef, useState } from 'react';
import WebViewer, { type WebViewerInstance } from '@pdftron/webviewer';
import { useTheme } from 'styled-components';

import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';

import { type Document } from './DocumentViewer.types';
import { WEB_VIEWER_KEY } from './Webviewer.env';
import { loadFromUrl, loadSecuredFile } from './WebviewerWrapper.utils';

type Props = {
  document: Document;
  mode?: 'full' | 'simple';
  handleClose: () => void;
};

export const WebviewerWrapper = ({
  document,
  mode = 'simple',
  handleClose,
}: Props) => {
  const viewer = useRef(null);
  const { isDarkMode } = useDarkModeContext();
  const [instance, setInstance] = useState<WebViewerInstance | null>(null);
  const theme = useTheme();

  useEffect(() => {
    WebViewer(
      {
        path: '/public/webviewer',
        css: '/public/webviewer/override/stylesheet.css',
        licenseKey: WEB_VIEWER_KEY,
        isReadOnly: true,
        fullAPI: false,
        extension: 'xod',
        streaming: true,
        disabledElements: [
          'menuButton',
          'printButton',
          'downloadButton',
          'ribbons',
          'selectToolButton',
          'toggleNotesButton',
          'viewControlsButton',
          'panToolButton',
          ...(mode === 'simple' ? ['searchButton', 'leftPanelButton'] : []),
        ],
      },
      viewer.current
    ).then(instance => {
      const style = instance.UI.iframeWindow.document.documentElement.style;
      instance.UI.iframeWindow.document.documentElement.className = `${instance.UI.iframeWindow.document.documentElement.className} ${mode}`;

      // Remove replace text from right click context menu
      instance.UI.contextMenuPopup.update([]);

      instance.UI.disableFeatures([
        instance.UI.Feature.Annotations,
        instance.UI.Feature.Measurement,
        instance.UI.Feature.TextSelection,
      ]);

      style.setProperty(`--blue-1`, theme.color.blue_10);
      style.setProperty(`--blue-2`, theme.color.blue_20);
      style.setProperty(`--blue-3`, theme.color.blue_40);
      style.setProperty(`--blue-4`, theme.color.blue_50);
      style.setProperty(`--blue-5`, theme.color.blue_60);
      style.setProperty(`--blue-6`, theme.color.blue_80);

      style.setProperty(`--gray-1`, theme.color.grey_10);
      style.setProperty(`--gray-2`, theme.color.grey_20);
      style.setProperty(`--gray-3`, theme.color.grey_20);
      style.setProperty(`--gray-4`, theme.color.grey_20);
      style.setProperty(`--gray-5`, theme.color.grey_40);
      style.setProperty(`--gray-6`, theme.color.grey_50);
      style.setProperty(`--gray-7`, theme.color.grey_60);
      style.setProperty(`--gray-8`, theme.color.grey_80);
      style.setProperty(`--gray-9`, theme.color.grey_80);
      style.setProperty(`--gray-10`, theme.color.grey_90);
      style.setProperty(`--gray-11`, theme.color.grey_90);
      style.setProperty(`--gray-12`, theme.color.grey_100);

      setInstance(instance);

      // you can now call WebViewer APIs here...
    });
  }, []);

  useEffect(() => {
    if (instance && document) {
      const style = instance.UI.iframeWindow.document.documentElement.style;

      style.setProperty(
        '--view-header-background',
        theme.color.general.layout.two
      );
      style.setProperty(
        '--document-background-color',
        theme.color.general.layout.zero
      );
      style.setProperty(
        '--component-background',
        theme.color.general.layout.two
      );
      style.setProperty('--panel-background', theme.color.general.layout.two);
      style.setProperty(
        '--toggle-zoom-overlay-background',
        theme.color.general.layout.zero
      );
      style.setProperty(
        '--main-text-color',
        theme.color.general.typography.primary
      );
      style.setProperty('--text-color', theme.color.general.typography.primary);
      style.setProperty(
        '--view-header-button-active',
        theme.color.info.backgrounds.secondaryDefault
      );
      style.setProperty(
        '--view-header-button-hover',
        theme.color.info.backgrounds.secondaryHover
      );
      style.setProperty('--border', theme.color.general.borders.secondary);
      style.setProperty('--divider', theme.color.general.borders.secondary);
      style.setProperty(
        '--popup-button-active',
        theme.color.info.backgrounds.secondaryDefault
      );
      style.setProperty(
        '--popup-button-hover',
        theme.color.info.backgrounds.secondaryHover
      );
      style.setProperty(
        '--icon-color-primary',
        theme.color.general.typography.primary
      );
      style.setProperty(
        '--icon-color-secondary',
        theme.color.general.typography.secondary
      );
      style.setProperty(
        '--slider-filled',
        theme.color.general.typography.secondary
      );

      if (isDarkMode) {
        instance.UI.setTheme('dark');
      } else {
        instance.UI.setTheme('light');
      }

      // Copy & paste disable
      instance.UI.disableFeatures([instance.UI.Feature.Copy]);

      const errorHandler = function (event) {
        // Do something with error. eg. instance.showErrorMessage('An error has occurred')

        const errorResponse = event?.detail?.serverResponse;
        if (errorResponse?.status === 443) {
          instance.UI.displayErrorMessage(
            'The data is not ready yet. Please try again later.'
          );
        }
      };

      const visibilityChangedHandler = function (event) {
        if (
          event?.detail?.element === 'errorModal' &&
          event?.detail?.isVisible === false
        ) {
          // error modal was closed by client
          handleClose();
        }
      };

      instance.UI.addEventListener(
        instance.UI.Events.VISIBILITY_CHANGED,
        visibilityChangedHandler
      );

      instance.UI.addEventListener(instance.UI.Events.LOAD_ERROR, errorHandler);

      if (document.type === 'with-npm-id') {
        loadSecuredFile(
          instance,
          `/api/documents/${document.id}/preview_download`
        );
      } else if (document.type === 'with-url' && document.url) {
        loadFromUrl(instance, document);
      } else if (document.type === 'with-path') {
        loadSecuredFile(instance, document.path);
      } else if (document.type === 'with-binary-data' && document.data) {
        // load from byte[]
        const blob = new Blob([document.data], { type: document.contentType });
        instance.UI.loadDocument(blob, {
          filename: document.name,
        });
      }

      return () => {
        instance.UI.removeEventListener(
          instance.UI.Events.LOAD_ERROR,
          errorHandler
        );

        instance.UI.removeEventListener(
          instance.UI.Events.VISIBILITY_CHANGED,
          visibilityChangedHandler
        );
      };
    }
  }, [document, isDarkMode, instance]);

  return (
    <div className="webviewer" ref={viewer} style={{ height: '100%' }}></div>
  );
};
