import type { NumberFormatOptions } from '../../../../utils/formatters';

export const formatAxisNumber = (
  vol: number | string,
  formatOptions?: NumberFormatOptions
): string => {
  return Intl.NumberFormat('en', {
    notation: 'compact',
    ...formatOptions,
  }).format(Number(vol));
};
