import React, { type ComponentProps } from 'react';

import { CompanySearch } from '../../smartSelects';
import { type FilterItemProps } from '../FilterPanel';

type Props = FilterItemProps & ComponentProps<typeof CompanySearch>;

export const IssuerEntityFilter = ({
  name,
  onFilterSubmit,
  ...rest
}: Props) => {
  return (
    <CompanySearch
      onSelect={() => {
        onFilterSubmit?.(name);
      }}
      onClear={() => onFilterSubmit?.(name)}
      {...rest}
    />
  );
};
