import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { getDateInTimeAgoFormat } from '@npm/core/ui/utils/formatters';
import {
  type NotificationIndexResponseType,
  CbNotificationCategory,
  useNotificationUpdate,
} from '@npm/data-access';

import { BlueCircle } from './components/BlueCircle';

import * as S from './NotificationCard.styles';

type Props = {
  notification: NotificationIndexResponseType['notifications'][number];
};

export const NotificationCard = ({ notification }: Props) => {
  const history = useHistory();
  const read = notification.read;

  const { isLoading, execute } = useNotificationUpdate();

  const handleClick = async () => {
    if (!read) {
      try {
        await execute({
          id: notification.id,
          notificationUpdateRequestContract: {
            mark_as_read: true,
          },
        });
      } catch (e) {
        // ignore error
        console.warn(e);
      }
    }

    if (notification.url) {
      let url = notification.url;
      if (url.startsWith('http')) {
        // Temporary solution before BE removes the domain from the url
        // Solution removes the domain from the url
        try {
          const decodedUrl = new URL(notification.url);
          url = decodedUrl.pathname + decodedUrl.search + decodedUrl.hash;
        } catch (e) {
          // ignore incorrect url
          console.error(`Invalid URL ${notification.url}`);
          return;
        }
      }

      history.push(url);
    }
  };

  return (
    <S.Wrapper
      direction="row"
      justify="space-between"
      align="center"
      read={read}
      onClick={isLoading ? undefined : handleClick}
    >
      <Flex gap="xs" direction="column">
        <Text
          size="sm"
          weight={read ? 'default' : 'bold'}
          colorVariant={read ? 'secondary' : 'primary'}
        >
          {notification.message}
        </Text>

        <Flex direction="row" align="center" gap="xs">
          {!read ? <BlueCircle /> : null}
          <Text size="sm" colorVariant={read ? 'tertiary' : 'primary'}>
            {getDateInTimeAgoFormat(notification.created_at)}
          </Text>
        </Flex>
      </Flex>
      {CbNotificationCategory.getLabel(CbLabel, notification.category)}
    </S.Wrapper>
  );
};
