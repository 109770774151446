import styled from 'styled-components';

import { Label as BaseLabel } from '@npm/core/ui/components/atoms/Label';

export const Label = styled(BaseLabel)`
  &&& {
    display: flex;
    border-color: ${({ theme }) => theme.color.general.borders.secondary};
  }
`;
