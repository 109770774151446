import React, { type ComponentProps } from 'react';

import { CodebooksSelect } from '../../smartSelects';
import { type FilterItemProps } from '../FilterPanel';

type Props = FilterItemProps & ComponentProps<typeof CodebooksSelect>;

export const CodebooksSelectFilter = ({
  name,
  codebook,
  onFilterSubmit,
  onChange,
  mode,
  ...rest
}: Props) => {
  return (
    <CodebooksSelect
      codebook={codebook}
      onChange={(...args) => {
        // for mode "multiple" pass undefined instead of empty array
        const value =
          mode === 'multiple' && !args[0]?.length ? undefined : args[0];
        const option = args[1];
        onChange?.(value, option);
        onFilterSubmit?.(name);
      }}
      mode={mode}
      {...rest}
    />
  );
};
