import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';

export const CompanyCardsNoDataArea = ({
  isFilterApplied,
  className,
}: {
  isFilterApplied?: boolean;
  className?: string;
}) => {
  return (
    <NoDataArea
      outlined
      title={
        isFilterApplied
          ? "We couldn't find any results with your search criteria, please, try with different attributes."
          : 'There are no Markets available.'
      }
      className={className}
    />
  );
};
