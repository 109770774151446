import React from 'react';

import { Codebooks } from '@npm/data-access';
import { type WatchlistApiWatchlistIndexRequest } from '@npm/data-access';

import {
  type FilterPanelItem,
  AccountFilter,
  CodebooksSelectFilter,
  DateStringSelectFilter,
  IssuerEntityFilter,
} from '../../../filters';

export type WatchlistIndexFilterParams = Pick<
  WatchlistApiWatchlistIndexRequest,
  'orderBuySell' | 'createdAfter' | 'issuerEntityId' | 'accountId'
>;

type WatchlistFilterPanelItem = FilterPanelItem<WatchlistIndexFilterParams>;

export const getWatchlistIndexFilters = (
  showCompanySearch: boolean,
  showAccountSearch: boolean
): WatchlistFilterPanelItem[] => [
  ...(showAccountSearch
    ? [
        {
          name: 'accountId',
          label: 'Account',
          render: props => <AccountFilter {...props} />,
        } as WatchlistFilterPanelItem,
      ]
    : []),
  ...(showCompanySearch
    ? [
        {
          name: 'issuerEntityId',
          label: 'Company',
          render: props => <IssuerEntityFilter {...props} />,
        } as WatchlistFilterPanelItem,
      ]
    : []),
  {
    name: 'orderBuySell',
    label: 'Side',
    render: props => (
      <CodebooksSelectFilter codebook={Codebooks.ORDER_BUY_SELL} {...props} />
    ),
  },
  {
    name: 'createdAfter',
    label: 'Created At',
    render: props => (
      <DateStringSelectFilter
        options={[
          'today',
          'lastWeek',
          'last2Weeks',
          'lastMonth',
          'last6Months',
          'last1Year',
        ]}
        {...props}
      />
    ),
  },
];
