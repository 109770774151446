import { Form } from 'antd';

import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  CbAccreditationLevel,
  CbQualifiedInstitutionalBuyerType,
  InstitutionalBuyerAssets,
  useCodebook,
  useEntityAccountAccreditationUpdate,
} from '@npm/data-access';

import type { QualifiedInstitutionalBuyerQuestionnaireFormFields } from '../../Accreditation.types';

export const useQualifiedInstitutionalBuyerQuestionnaireForm = (
  accreditationId: number,
  accountId: number,
  onUpdate: () => void
) => {
  const [form] =
    Form.useForm<QualifiedInstitutionalBuyerQuestionnaireFormFields>();
  const { withShowApiErrorMiddleware } = useAlerts();

  const { execute: updateAccreditation, isLoading: isAccreditationUpdating } =
    useEntityAccountAccreditationUpdate();

  const { data: institutionalBuyerOptionsData } = useCodebook({
    type: CbQualifiedInstitutionalBuyerType.code,
  });

  const data = CbQualifiedInstitutionalBuyerType.groupByAttributes(
    institutionalBuyerOptionsData?.codebooks || [],
    [
      InstitutionalBuyerAssets.AT_LEAST_100_MILLION,
      InstitutionalBuyerAssets.LESS_THAN_100_MILLION,
    ]
  );

  const handleSubmit = async () => {
    let values: QualifiedInstitutionalBuyerQuestionnaireFormFields;

    try {
      values = await form.validateFields([
        CbAccreditationLevel.items.qualified_institutional_buyer,
        'qualified_institutional_buyer_assets',
        'signature',
        'entity_rep_title',
      ]);
    } catch (e) {
      return console.error('Validation error:', e);
    }

    try {
      await withShowApiErrorMiddleware(updateAccreditation)({
        accountId,
        id: accreditationId,
        accountsAccreditationUpdateRequestContract: {
          answers: [
            {
              field: {
                code: 'qualified_institutional_buyer_assets',
              },
              answer: {
                code: values.qualified_institutional_buyer_assets,
              },
            },
            {
              field: {
                code: 'qualified_institutional_buyer',
              },
              answer: {
                code: values.qualified_institutional_buyer,
              },
            },
          ],
          signature: values.signature,
        },
      });
    } catch (e) {
      console.error(e);
    }

    onUpdate();
  };

  return {
    isUpdating: isAccreditationUpdating,
    handleSubmit,
    form,
    data,
  };
};
