/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalFirmCreateRequestContract } from '../model';
// @ts-ignore
import { InternalFirmIndex } from '../model';
// @ts-ignore
import { InternalFirmShow } from '../model';
// @ts-ignore
import { InternalFirmUpdateRequestContract } from '../model';
/**
 * FirmsApi - axios parameter creator
 * @export
 */
export const FirmsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a firm
         * @param {InternalFirmCreateRequestContract} internalFirmCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFirmsCreate: async (internalFirmCreateRequestContract: InternalFirmCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'internalFirmCreateRequestContract' is not null or undefined
            assertParamExists('internalFirmsCreate', 'internalFirmCreateRequestContract', internalFirmCreateRequestContract)
            const localVarPath = changeUrl(`/api/internal/firms`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalFirmCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a firm
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFirmsDelete: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalFirmsDelete', 'id', id)
            const localVarPath = changeUrl(`/api/internal/firms/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a list of firms
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFirmsIndex: async (search?: string, page?: number, size?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/internal/firms`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get information for a firm
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFirmsShow: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalFirmsShow', 'id', id)
            const localVarPath = changeUrl(`/api/internal/firms/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a firm
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalFirmUpdateRequestContract} [internalFirmUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFirmsUpdate: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalFirmUpdateRequestContract?: InternalFirmUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalFirmsUpdate', 'id', id)
            const localVarPath = changeUrl(`/api/internal/firms/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalFirmUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FirmsApi - functional programming interface
 * @export
 */
export const FirmsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FirmsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a firm
         * @param {InternalFirmCreateRequestContract} internalFirmCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalFirmsCreate(internalFirmCreateRequestContract: InternalFirmCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalFirmShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalFirmsCreate(internalFirmCreateRequestContract, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a firm
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalFirmsDelete(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalFirmsDelete(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a list of firms
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalFirmsIndex(search?: string, page?: number, size?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalFirmIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalFirmsIndex(search, page, size, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get information for a firm
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalFirmsShow(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalFirmShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalFirmsShow(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a firm
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalFirmUpdateRequestContract} [internalFirmUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalFirmsUpdate(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalFirmUpdateRequestContract?: InternalFirmUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalFirmShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalFirmsUpdate(id, xNPMAuthorization, xSubscriptionPlan, internalFirmUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FirmsApi - factory interface
 * @export
 */
export const FirmsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FirmsApiFp(configuration)
    return {
        /**
         * 
         * @summary create a firm
         * @param {InternalFirmCreateRequestContract} internalFirmCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFirmsCreate(internalFirmCreateRequestContract: InternalFirmCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalFirmShow> {
            return localVarFp.internalFirmsCreate(internalFirmCreateRequestContract, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a firm
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFirmsDelete(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalFirmsDelete(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a list of firms
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFirmsIndex(search?: string, page?: number, size?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalFirmIndex> {
            return localVarFp.internalFirmsIndex(search, page, size, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get information for a firm
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFirmsShow(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalFirmShow> {
            return localVarFp.internalFirmsShow(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a firm
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalFirmUpdateRequestContract} [internalFirmUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFirmsUpdate(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalFirmUpdateRequestContract?: InternalFirmUpdateRequestContract, options?: any): AxiosPromise<InternalFirmShow> {
            return localVarFp.internalFirmsUpdate(id, xNPMAuthorization, xSubscriptionPlan, internalFirmUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for internalFirmsCreate operation in FirmsApi.
 * @export
 * @interface FirmsApiInternalFirmsCreateRequest
 */
export interface FirmsApiInternalFirmsCreateRequest {
    /**
     * 
     * @type {InternalFirmCreateRequestContract}
     * @memberof FirmsApiInternalFirmsCreate
     */
    readonly internalFirmCreateRequestContract: InternalFirmCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof FirmsApiInternalFirmsCreate
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FirmsApiInternalFirmsCreate
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalFirmsDelete operation in FirmsApi.
 * @export
 * @interface FirmsApiInternalFirmsDeleteRequest
 */
export interface FirmsApiInternalFirmsDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof FirmsApiInternalFirmsDelete
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FirmsApiInternalFirmsDelete
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FirmsApiInternalFirmsDelete
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalFirmsIndex operation in FirmsApi.
 * @export
 * @interface FirmsApiInternalFirmsIndexRequest
 */
export interface FirmsApiInternalFirmsIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof FirmsApiInternalFirmsIndex
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof FirmsApiInternalFirmsIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof FirmsApiInternalFirmsIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof FirmsApiInternalFirmsIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FirmsApiInternalFirmsIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalFirmsShow operation in FirmsApi.
 * @export
 * @interface FirmsApiInternalFirmsShowRequest
 */
export interface FirmsApiInternalFirmsShowRequest {
    /**
     * 
     * @type {number}
     * @memberof FirmsApiInternalFirmsShow
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FirmsApiInternalFirmsShow
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FirmsApiInternalFirmsShow
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalFirmsUpdate operation in FirmsApi.
 * @export
 * @interface FirmsApiInternalFirmsUpdateRequest
 */
export interface FirmsApiInternalFirmsUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof FirmsApiInternalFirmsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FirmsApiInternalFirmsUpdate
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FirmsApiInternalFirmsUpdate
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {InternalFirmUpdateRequestContract}
     * @memberof FirmsApiInternalFirmsUpdate
     */
    readonly internalFirmUpdateRequestContract?: InternalFirmUpdateRequestContract
}

/**
 * FirmsApi - object-oriented interface
 * @export
 * @class FirmsApi
 * @extends {BaseAPI}
 */
export class FirmsApi extends BaseAPI {
    /**
     * 
     * @summary create a firm
     * @param {FirmsApiInternalFirmsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmsApi
     */
    public internalFirmsCreate(requestParameters: FirmsApiInternalFirmsCreateRequest, options?: AxiosRequestConfig) {
        return FirmsApiFp(this.configuration).internalFirmsCreate(requestParameters.internalFirmCreateRequestContract, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a firm
     * @param {FirmsApiInternalFirmsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmsApi
     */
    public internalFirmsDelete(requestParameters: FirmsApiInternalFirmsDeleteRequest, options?: AxiosRequestConfig) {
        return FirmsApiFp(this.configuration).internalFirmsDelete(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a list of firms
     * @param {FirmsApiInternalFirmsIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmsApi
     */
    public internalFirmsIndex(requestParameters: FirmsApiInternalFirmsIndexRequest = {}, options?: AxiosRequestConfig) {
        return FirmsApiFp(this.configuration).internalFirmsIndex(requestParameters.search, requestParameters.page, requestParameters.size, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get information for a firm
     * @param {FirmsApiInternalFirmsShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmsApi
     */
    public internalFirmsShow(requestParameters: FirmsApiInternalFirmsShowRequest, options?: AxiosRequestConfig) {
        return FirmsApiFp(this.configuration).internalFirmsShow(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a firm
     * @param {FirmsApiInternalFirmsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmsApi
     */
    public internalFirmsUpdate(requestParameters: FirmsApiInternalFirmsUpdateRequest, options?: AxiosRequestConfig) {
        return FirmsApiFp(this.configuration).internalFirmsUpdate(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.internalFirmUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InternalFirmsCreateErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalFirmsCreateErrorCodes = [
    401,
];

export type InternalFirmsDeleteErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalFirmsDeleteErrorCodes = [
];

export type InternalFirmsIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalFirmsIndexErrorCodes = [
    401,
];

export type InternalFirmsShowErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalFirmsShowErrorCodes = [
    401,
    404,
];

export type InternalFirmsUpdateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalFirmsUpdateErrorCodes = [
];


