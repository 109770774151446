/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AssetMarkApi,
  AssetMarkIndexErrorCodes,
  AssetMarkIndexErrorTypes,
  AssetMarkCreateErrorCodes,
  AssetMarkUpdateErrorCodes,
  AssetMarkDeleteErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AssetMarkApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AssetMarkIndexMethod = AssetMarkApi['assetMarkIndex'];
export type AssetMarkIndexResponseType = MethodResult<AssetMarkIndexMethod>;

export const useAssetMarkIndex = (
  variables: Parameters<AssetMarkIndexMethod>[0],
  config?: UseQueryConfig<
    AssetMarkIndexMethod,
    AssetMarkIndexErrorTypes
  >
) => {
  return useQuery<
    AssetMarkIndexMethod,
    AssetMarkIndexErrorTypes
  >(
    (...args) => api.assetMarkIndex(...args),
    AssetMarkIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AssetMarkIndex'],
        ...apiQueryConfig.AssetMarkIndex,
        ...config?.queryConfig
      },
    },
    'AssetMarkIndex',
  );
};

export const useAssetMarkIndexInfinite = (
  variables: Parameters<AssetMarkIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AssetMarkIndexMethod,
    AssetMarkIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AssetMarkIndexMethod,
    AssetMarkIndexErrorTypes
  >(
    (...args) => api.assetMarkIndex(...args),
    AssetMarkIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AssetMarkIndex', 'AssetMarkIndexInfinite'],
        ...apiQueryConfig.AssetMarkIndex,
        ...config?.queryConfig,
      },
    },
    'AssetMarkIndex',
  );
};

export const useAssetMarkIndexLazy = (baseOptions?: {
  variables?: Parameters<AssetMarkIndexMethod>[0],
  config?: UseQueryConfig<
    AssetMarkIndexMethod,
    AssetMarkIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AssetMarkIndexMethod,
    AssetMarkIndexErrorTypes
  >(
    (...args) => api.assetMarkIndex(...args),
    AssetMarkIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AssetMarkIndex'],
        ...apiQueryConfig.AssetMarkIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AssetMarkIndex',
  );
};


export type AssetMarkCreateMethod = AssetMarkApi['assetMarkCreate'];

export const useAssetMarkCreate = () => {
  return useMutation<AssetMarkCreateMethod>(
    (...args) => api.assetMarkCreate(...args),
    AssetMarkCreateErrorCodes,
    'AssetMarkCreate',
    '/api/investor/asset_marks',
    'post'
  );
};

export type AssetMarkUpdateMethod = AssetMarkApi['assetMarkUpdate'];

export const useAssetMarkUpdate = () => {
  return useMutation<AssetMarkUpdateMethod>(
    (...args) => api.assetMarkUpdate(...args),
    AssetMarkUpdateErrorCodes,
    'AssetMarkUpdate',
    '/api/investor/asset_marks/{asset_class_slug}',
    'patch'
  );
};

export type AssetMarkDeleteMethod = AssetMarkApi['assetMarkDelete'];

export const useAssetMarkDelete = () => {
  return useMutation<AssetMarkDeleteMethod>(
    (...args) => api.assetMarkDelete(...args),
    AssetMarkDeleteErrorCodes,
    'AssetMarkDelete',
    '/api/investor/asset_marks/{asset_class_slug}',
    'delete'
  );
};
