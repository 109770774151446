/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Mark to Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * FilingsPDFApi - axios parameter creator
 * @export
 */
export const FilingsPDFApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary redirect user to a pre-signed AWS url of Filing\'s pdf
         * @param {number} id ID of a Filing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filingPDFShow: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filingPDFShow', 'id', id)
            const localVarPath = changeUrl(`/api/filing_pdf/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilingsPDFApi - functional programming interface
 * @export
 */
export const FilingsPDFApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilingsPDFApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary redirect user to a pre-signed AWS url of Filing\'s pdf
         * @param {number} id ID of a Filing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filingPDFShow(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filingPDFShow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilingsPDFApi - factory interface
 * @export
 */
export const FilingsPDFApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilingsPDFApiFp(configuration)
    return {
        /**
         * 
         * @summary redirect user to a pre-signed AWS url of Filing\'s pdf
         * @param {number} id ID of a Filing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filingPDFShow(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.filingPDFShow(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for filingPDFShow operation in FilingsPDFApi.
 * @export
 * @interface FilingsPDFApiFilingPDFShowRequest
 */
export interface FilingsPDFApiFilingPDFShowRequest {
    /**
     * ID of a Filing
     * @type {number}
     * @memberof FilingsPDFApiFilingPDFShow
     */
    readonly id: number
}

/**
 * FilingsPDFApi - object-oriented interface
 * @export
 * @class FilingsPDFApi
 * @extends {BaseAPI}
 */
export class FilingsPDFApi extends BaseAPI {
    /**
     * 
     * @summary redirect user to a pre-signed AWS url of Filing\'s pdf
     * @param {FilingsPDFApiFilingPDFShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsPDFApi
     */
    public filingPDFShow(requestParameters: FilingsPDFApiFilingPDFShowRequest, options?: AxiosRequestConfig) {
        return FilingsPDFApiFp(this.configuration).filingPDFShow(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

export type FilingPDFShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const FilingPDFShowErrorCodes = [
];


