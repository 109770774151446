/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EventsReportIndex } from '../model';
/**
 * EventReportsApi - axios parameter creator
 * @export
 */
export const EventReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary lists the reports available for this event base on the user (defaults to login user if no user id is passed)
         * @param {number} eventId 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventReportList: async (eventId: number, userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('eventReportList', 'eventId', eventId)
            const localVarPath = changeUrl(`/api/events/{event_id}/reports`)
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventReportsApi - functional programming interface
 * @export
 */
export const EventReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary lists the reports available for this event base on the user (defaults to login user if no user id is passed)
         * @param {number} eventId 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventReportList(eventId: number, userId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsReportIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventReportList(eventId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventReportsApi - factory interface
 * @export
 */
export const EventReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary lists the reports available for this event base on the user (defaults to login user if no user id is passed)
         * @param {number} eventId 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventReportList(eventId: number, userId?: number, options?: any): AxiosPromise<EventsReportIndex> {
            return localVarFp.eventReportList(eventId, userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for eventReportList operation in EventReportsApi.
 * @export
 * @interface EventReportsApiEventReportListRequest
 */
export interface EventReportsApiEventReportListRequest {
    /**
     * 
     * @type {number}
     * @memberof EventReportsApiEventReportList
     */
    readonly eventId: number

    /**
     * 
     * @type {number}
     * @memberof EventReportsApiEventReportList
     */
    readonly userId?: number
}

/**
 * EventReportsApi - object-oriented interface
 * @export
 * @class EventReportsApi
 * @extends {BaseAPI}
 */
export class EventReportsApi extends BaseAPI {
    /**
     * 
     * @summary lists the reports available for this event base on the user (defaults to login user if no user id is passed)
     * @param {EventReportsApiEventReportListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventReportsApi
     */
    public eventReportList(requestParameters: EventReportsApiEventReportListRequest, options?: AxiosRequestConfig) {
        return EventReportsApiFp(this.configuration).eventReportList(requestParameters.eventId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type EventReportListErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 442;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EventReportListErrorCodes = [
    401,
    442,
];


