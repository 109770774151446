import styled from 'styled-components';

import { ItemButton as BaseItemButton } from '../../../../../../context/ItemButton/ItemButton.styles';

export const ItemButton = styled(BaseItemButton)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${({ theme }) => theme.color.general.layout.two};
  margin-bottom: ${({ theme }) => theme.spacing.sm}px;
`;

export const PairWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm}px;
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;

  & > div {
    width: unset;
    max-width: 50%;

    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.lg}px;
    }
  }
`;
