/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Mark to Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Issuer } from '../model';
/**
 * IssuersApi - axios parameter creator
 * @export
 */
export const IssuersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get a specific issuer
         * @param {number} issuerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerShow: async (issuerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('issuerShow', 'issuerId', issuerId)
            const localVarPath = changeUrl(`/api/issuers/{issuer_id}`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IssuersApi - functional programming interface
 * @export
 */
export const IssuersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IssuersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get a specific issuer
         * @param {number} issuerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerShow(issuerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Issuer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerShow(issuerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IssuersApi - factory interface
 * @export
 */
export const IssuersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IssuersApiFp(configuration)
    return {
        /**
         * 
         * @summary get a specific issuer
         * @param {number} issuerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerShow(issuerId: number, options?: any): AxiosPromise<Issuer> {
            return localVarFp.issuerShow(issuerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for issuerShow operation in IssuersApi.
 * @export
 * @interface IssuersApiIssuerShowRequest
 */
export interface IssuersApiIssuerShowRequest {
    /**
     * 
     * @type {number}
     * @memberof IssuersApiIssuerShow
     */
    readonly issuerId: number
}

/**
 * IssuersApi - object-oriented interface
 * @export
 * @class IssuersApi
 * @extends {BaseAPI}
 */
export class IssuersApi extends BaseAPI {
    /**
     * 
     * @summary get a specific issuer
     * @param {IssuersApiIssuerShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuersApi
     */
    public issuerShow(requestParameters: IssuersApiIssuerShowRequest, options?: AxiosRequestConfig) {
        return IssuersApiFp(this.configuration).issuerShow(requestParameters.issuerId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type IssuerShowErrorTypes =

    | {
        status: 404;
        data: Issuer;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerShowErrorCodes = [
    404,
];


