import styled, { css } from 'styled-components';

import { centerFlex, useBreakpoint } from '../../../../../utils/style';

export const CONTENT_WIDTH = 1024;
export const CONTENT_WIDTH_WIDE = 1200;
// TODO: we're missing breakpoint desktop - 800px
// padding should be 80px 40px - https://www.figma.com/file/pUlbbnBz01FwMDHt9ATv9E/Design-System?node-id=7954%3A83359&t=9xGyW826bm6dsPxe-4
const CONTENT_PADDING_DESKTOP = '80px 112px';
const CONTENT_PADDING_SM_DESKTOP = '80px 96px';
const CONTENT_PADDING_TABLET = '120px 40px';
const CONTENT_PADDING_MOBILE = '40px 16px';

export const PageContainer = styled.div<{ $hasAutoScroll: boolean }>`
  width: 100%;
  min-height: 100%;
  height: ${({ $hasAutoScroll }) => ($hasAutoScroll ? 'auto' : '100%')};
  background: ${({ theme }) => theme.color.general.layout.one};
`;

export const contentPaddingStyles = css<{
  $isWide?: boolean;
  $hasVerticalPadding?: boolean;
}>`
  padding: ${CONTENT_PADDING_DESKTOP};

  ${({ theme }) => useBreakpoint(theme).smDesktop`
    padding: ${CONTENT_PADDING_SM_DESKTOP};
  `}

  ${({ theme }) => useBreakpoint(theme).tablet`
    padding: ${CONTENT_PADDING_TABLET};
  `}

  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: ${CONTENT_PADDING_MOBILE};
  `}

  ${({ theme, $isWide }) =>
    $isWide &&
    `
    && {
      padding: ${theme.spacing.md}px;
    }`}

  ${({ theme, $isWide }) =>
    $isWide &&
    useBreakpoint(theme).mobile`
    && {
      padding: 0 0 ${theme.spacing.md}px;
    }
  `}

  ${({ $hasVerticalPadding = true }) =>
    !$hasVerticalPadding &&
    `
      && {
        padding-top: 0;
        padding-bottom: 0;
      }
  `}
`;

export const Content = styled.div<{
  $isWide: boolean;
  $distanceFromTop: number;
  $hasVerticalPadding: boolean;
  $hasAutoScroll: boolean;
  $fullWidth?: boolean;
  $hideOverflow?: boolean;
  $centerContent?: boolean;
}>`
  position: relative;
  width: ${({ $isWide, $fullWidth }) =>
    `${$fullWidth ? '100%' : $isWide ? CONTENT_WIDTH_WIDE : CONTENT_WIDTH}px`};
  max-width: 100%;
  min-height: 100%;
  height: auto;
  margin: 0 auto;
  background: ${({ theme, $isWide }) =>
    $isWide ? theme.color.transparent : theme.color.general.layout.one};

  ${contentPaddingStyles}

  ${({ $hasAutoScroll }) =>
    $hasAutoScroll &&
    `
    padding-top: 0;
  `}

  ${({ $centerContent }) =>
    $centerContent &&
    `
    ${centerFlex}
    flex-direction: column;
  `}
`;
