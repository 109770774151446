import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { InlineInputNumber } from '@npm/core/ui/components/atoms/Input';
import { Switch } from '@npm/core/ui/components/atoms/Switch';
import { CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import { Codebooks } from '@npm/data-access';
import {
  type SubmissionStructureCode,
  CbSubmissionStructure,
  useCodebook,
} from '@npm/data-access';

import { CodebooksSelect } from '../../../../filters';
import { getStructurePreview } from '../../../../order/OrderData';

type Props = {
  value: {
    structure?: SubmissionStructureCode;
    hasSpvStructureFee?: boolean;
    spvManagementFee?: number;
    spvCarriedInterestFee?: number;
    hasNonSpvStructureFee?: boolean;
    structurePercentageFee?: number;
  };
  allowFees?: boolean;
  onItemChange?: () => void;
  isSelectDisabled?: boolean;
  key: string;
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const StructureFormSection = ({
  value: {
    structure,
    hasSpvStructureFee,
    spvManagementFee,
    spvCarriedInterestFee,
    hasNonSpvStructureFee,
    structurePercentageFee,
  },
  allowFees = true,
  onItemChange,
  isSelectDisabled,
  ...props
}: Props) => {
  const { data: structureData } = useCodebook({
    type: Codebooks.SUBMISSION_STRUCTURE,
  });

  return (
    <CollapsibleFormSection.Item
      label="Structure"
      preview={getStructurePreview({
        structure,
        structureCodebooks: structureData?.codebooks ?? [],
        hasSpvStructureFee,
        spvManagementFee,
        spvCarriedInterestFee,
        hasNonSpvStructureFee,
        structurePercentageFee,
        showFees: allowFees,
      })}
      {...props}
    >
      <FormItem name="structure" marginBottom="sm">
        <CodebooksSelect
          codebook={CbSubmissionStructure.code}
          allowClear={false}
          onChange={allowFees ? undefined : onItemChange}
          disabled={isSelectDisabled}
        />
      </FormItem>
      {allowFees &&
        (structure === CbSubmissionStructure.items.spv ? (
          <>
            <FormItem
              name="hasSpvStructureFee"
              valuePropName="checked"
              marginBottom="sm"
            >
              <Switch title="Fee" titleAfter />
            </FormItem>
            {hasSpvStructureFee && (
              <>
                <FormItem name="spvManagementFee" marginBottom="sm">
                  <InlineInputNumber
                    label="Management Fee"
                    placeholder="0"
                    inputMode="decimal"
                    addonAfter="%"
                  />
                </FormItem>
                <FormItem name="spvCarriedInterestFee" marginBottom="sm">
                  <InlineInputNumber
                    label="Carried Interest"
                    placeholder="0"
                    inputMode="decimal"
                    addonAfter="%"
                  />
                </FormItem>
              </>
            )}
          </>
        ) : (
          <>
            <FormItem
              name="hasNonSpvStructureFee"
              valuePropName="checked"
              marginBottom="sm"
            >
              <Switch title="Fee" titleAfter />
            </FormItem>
            {hasNonSpvStructureFee && (
              <FormItem name="structurePercentageFee" marginBottom="sm">
                <InlineInputNumber
                  label="Fee (of Size)"
                  placeholder="0"
                  inputMode="decimal"
                  addonAfter="%"
                />
              </FormItem>
            )}
          </>
        ))}
    </CollapsibleFormSection.Item>
  );
};
