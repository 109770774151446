import { Divider as AntDivider } from 'antd';
import styled, { type Variant } from 'styled-components';

export const Divider = styled(AntDivider)<{
  $variant: Variant;
  $marginBottom?: number;
  $marginTop?: number;
}>`
  background-color: ${({ theme, $variant }) =>
    theme.color.general.borders[$variant]};
  margin: ${({ $marginBottom, $marginTop, theme }) =>
    `${$marginTop || 0}px 0 ${$marginBottom ?? theme.spacing.md}px 0`};
  ${({ type }) =>
    type === 'vertical' &&
    `
    height: unset;
    align-self: stretch;
  `}
`;
