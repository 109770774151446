import React, { Fragment, useEffect, useRef } from 'react';

import { InfiniteScroll } from '@npm/core/ui/components/atoms/InfiniteScroll';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useBrokerageFirmAccountIndexInfinite } from '@npm/data-access';

import { type OboModalState } from '../../../OboModal.types';
import { type SelectAccountListProps } from '../SelectAccount.types';

import { SelectAccountCard } from './SelectAccountCard';
import { SelectAccountLoadingCard } from './SelectAccountLoadingCard';

type Props = {
  handleComplete: (nextOboState: OboModalState) => void;
} & SelectAccountListProps;

export const SelectAccountCards = ({
  variables,
  selectedAccount,
  goToRepresentativeStep,
  handleComplete,
  handleSelect,
}: Props) => {
  const { data, isFetching, fetchNextPage, hasNextPage } =
    useBrokerageFirmAccountIndexInfinite(variables);
  const selectedItemRef = useRef<HTMLDivElement>();

  const totalRecords = data?.pages[0]?.pagination?.total_records;

  useEffect(() => {
    if (selectedAccount) {
      selectedItemRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // wait for the variables to be set in the context when coming from step 2
  }, [variables]);

  return (
    <div data-cy={CypressDataIds.OboModal.SelectAccount.Cards}>
      <InfiniteScroll
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        isFetching={isFetching}
        loader={<SelectAccountLoadingCard />}
      >
        {data?.pages?.map(page => (
          <Fragment key={page.pagination.page}>
            {page.accounts?.map(a => (
              <div
                key={a.id}
                ref={a.id === selectedAccount?.id ? selectedItemRef : null}
              >
                <SelectAccountCard
                  account={a}
                  isSelected={a.id === selectedAccount?.id}
                  goToRepresentativeStep={goToRepresentativeStep}
                  handleComplete={handleComplete}
                  handleSelect={handleSelect}
                />
              </div>
            ))}
          </Fragment>
        ))}
      </InfiniteScroll>
      {!isFetching && totalRecords === 0 && <NoDataArea title="No data" />}
    </div>
  );
};
