import { type FC, useState } from 'react';
import { type FormInstance } from 'antd';
import { type CheckboxChangeEvent } from 'antd/es/checkbox';
import { type FormProps, useWatch } from 'antd/lib/form/Form';

import { Checkbox } from '@npm/core/ui/components/atoms/Checkbox';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { FormattableInput } from '@npm/core/ui/components/atoms/FormattableInput';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  formatIndividualTaxId,
  parsedTaxIdValidator,
  parseFormattedTaxId,
} from '@npm/core/ui/utils/formatters';

import { FormFields } from './Form.types';

import * as S from './Form.styles';

type Props = {
  form: FormInstance;
  compact?: boolean;
  customExtraMsg?: string;
  isExemptHidden?: boolean;
  handleValuesChange?: FormProps['onValuesChange'];
};

export const IndividualTaxIdForm: FC<Props> = ({
  form,
  compact = false,
  customExtraMsg,
  isExemptHidden = false,
  handleValuesChange,
}) => {
  const { isMobile, isTablet } = useBreakpoints();
  const [inputFocused, setInputFocused] = useState(false);
  const isExemptChecked = useWatch([FormFields.CHECKBOX_KEY], form);

  const onChecked = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;

    if (checked) {
      form.resetFields([FormFields.INPUT_KEY]);
    }
  };

  return (
    <S.Wrap>
      <Form form={form} onValuesChange={handleValuesChange}>
        <Tooltip
          size="lg"
          title="You are required to provide a U.S. Tax ID in order to complete your submission. This information will be used to populate transaction documentation and to satisfy any applicable regulatory requirements. If you do not have a U.S. Tax ID, please click the checkbox next to 'I do not have a U.S. Tax ID'."
          {...(isMobile || isTablet ? { open: inputFocused } : {})}
        >
          <S.FormItem
            $compact={compact}
            labelPosition="top"
            label="U.S Tax ID"
            name={FormFields.INPUT_KEY}
            rules={
              isExemptChecked
                ? undefined
                : [
                    { required: true, message: 'Field is required' },
                    {
                      validator: parsedTaxIdValidator,
                      message: 'Please enter a valid Tax ID',
                    },
                  ]
            }
            extra={
              customExtraMsg ??
              'U.S. SSN (Social Security Number) or ITIN (Individual Tax Identification Number)'
            }
            validateTrigger="onSubmit"
          >
            <FormattableInput
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              disabled={isExemptChecked}
              placeholder="000-00-0000"
              formatter={formatIndividualTaxId}
              parser={parseFormattedTaxId}
            />
          </S.FormItem>
        </Tooltip>
        {!isExemptHidden && (
          <S.FormItem
            labelPosition="top"
            name={FormFields.CHECKBOX_KEY}
            valuePropName="checked"
          >
            <Checkbox
              onChange={onChecked}
              defaultChecked={false}
              label="I do not have a U.S. Tax ID"
            />
          </S.FormItem>
        )}
      </Form>
    </S.Wrap>
  );
};
