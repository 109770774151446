import styled, { css } from 'styled-components';

import { centerSelfAbsolute } from '../../../../utils/style';

const pulsatingCircleCommonStyles = css`
  content: '';
  ${centerSelfAbsolute}
  inset: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
  transform-origin: center center;
`;

export const Dot = styled.div<{ $color: 'info' | 'success' }>`
  position: relative;
  margin: 0 ${({ theme }) => theme.spacing.xs}px;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ theme, $color }) => theme.color[$color].typography.primary};

  &:after {
    ${pulsatingCircleCommonStyles}
    background: inherit;
  }

  &:before {
    ${pulsatingCircleCommonStyles}
    border: 0.5px solid ${({ theme }) =>
      theme.color.success.typography.primary};
    background: transparent;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2.5);
      opacity: 0;
    }
  }
`;
