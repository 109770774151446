import { NoDataArea as NoDataAreaBase } from '@npm/core/ui/components/atoms/NoDataArea';

type Props = {
  isAnyFilterApplied: boolean;
  title?: string;
};

export const NoDataArea = ({ isAnyFilterApplied, title }: Props) => {
  return (
    <NoDataAreaBase
      title={
        isAnyFilterApplied
          ? "We couldn't find any results with your search criteria, please, try with different attributes."
          : title ??
            'You have no eligible account that can be used for accessing this program'
      }
      iconColor="warning"
    />
  );
};
