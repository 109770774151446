import { type ChartType, type Plugin } from 'chart.js';

type Options = {
  isEnabledForDataset: (index: number) => boolean;
  isStepDashed: (index: number) => boolean;
  borderColor?: string;
};

declare module 'chart.js' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    dashedLine?: Options;
  }
}

/**
 * This plugin overwrites the default lines of a stepped line chart with dashed lines.
 * Horizontal lines are dashed when isStepDashed returns true for the index.
 * Connecting vertical lines are drawn as solid lines.
 */
export const DashedLinePlugin: Plugin = {
  id: 'dashedLine',
  beforeDatasetDraw: (chart, { index }, options) => {
    try {
      options = options as unknown as Options; // retype to avoid TS error
      if (!options?.isEnabledForDataset?.(index) || !options?.isStepDashed) {
        // Do nothing if not enabled for this dataset
        return;
      }

      const { ctx } = chart;
      const dataset = chart.data.datasets[index];
      const meta = chart.getDatasetMeta(index);
      const yScale = chart.scales.y;
      const xScale = chart.scales.x;

      if (dataset && xScale && yScale) {
        // Draw the lines on the canvas
        const stepWidth = xScale.width / dataset.data.length;

        ctx.save();

        ctx.strokeStyle =
          options.borderColor ??
          (typeof dataset.borderColor === 'string'
            ? dataset.borderColor
            : 'rgba(0, 0, 0, 0.1)');

        ctx.lineWidth = 1;

        const activeData = meta.data.filter(
          x => (x as unknown as { skip: true })?.skip !== true
        );

        meta.data.forEach((element, i) => {
          // For each data point, draw the dashed line
          const x = xScale.getPixelForValue(i);
          const xStart = x;
          const xEnd =
            element === activeData[activeData.length - 1]
              ? x + stepWidth / 2
              : x + stepWidth;
          const y = yScale.getPixelForValue(dataset.data[i] as number);

          if (options.isStepDashed(i)) {
            ctx.setLineDash([5, 5]);
          }
          ctx.beginPath();
          ctx.moveTo(xStart, y);
          ctx.lineTo(xEnd, y);
          ctx.stroke();
          ctx.setLineDash([]);

          if (i > 0) {
            // Draw the vertical line to connect this point to the previous one
            const prevY = yScale.getPixelForValue(
              dataset.data[i - 1] as number
            );
            ctx.beginPath();
            ctx.moveTo(xStart, prevY);
            ctx.lineTo(xStart, y);
            ctx.stroke();
          }
        });

        ctx.restore();

        // Prevent the default line from being drawn
        dataset.borderWidth = 0;
      }
    } catch (error) {
      console.log(error);
    }
  },
};
