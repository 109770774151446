import React, { type ComponentProps } from 'react';
import { useWizard } from 'react-use-wizard';

import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import {
  type StepProps,
  type WizardStore,
  StepState,
  WizardStoreActionTypes,
} from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { WizardStep } from '@npm/core/ui/components/organisms/WizardNew/WizardStep';
import { CbOnboardingStatus } from '@npm/data-access';

import { type PostOnboardingContext } from '../../postOnboarding/PostOnboarding.types';
import { findNextIncompleteOnboardingStep } from '../PostOnboarding.utils';

type Props = ComponentProps<typeof WizardStep>;

export const PostOnboardingWizardStep = ({
  step,
  ...wizardStepProps
}: Props) => {
  const { activeStep, goToStep } = useWizard();
  const { steps, onboardingStatus, dispatch } = useWizardStore(
    (s: WizardStore<PostOnboardingContext>) => ({
      steps: s.steps,
      onboardingStatus: s.data.onboardingStatus,
      dispatch: s.dispatch,
    })
  );

  const handleStepComplete: StepProps['handleStepComplete'] = () => {
    const nextIncompleteStep = findNextIncompleteOnboardingStep({
      steps,
      onboardingStatus,
      startIndex: activeStep,
    });

    if (nextIncompleteStep > -1 && nextIncompleteStep !== activeStep) {
      goToStep(nextIncompleteStep);
    } else {
      // if all steps are completed, stay on the same step and show filled state
      dispatch({
        type: WizardStoreActionTypes.UPDATE_ACTIVE_STEP_STATE,
        payload: StepState.FILLED,
      });
    }
  };

  return (
    <WizardStep
      {...wizardStepProps}
      step={step}
      initialStepState={
        onboardingStatus?.[step.key]?.code ===
        CbOnboardingStatus.items.completed
          ? StepState.FILLED
          : StepState.EDIT
      }
      handleStepComplete={handleStepComplete}
    />
  );
};
