import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useWizard } from 'react-use-wizard';
import { Affix } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';

import { Margin } from '../../../../atoms/common';
import { ErrorSkeleton } from '../../../../atoms/ErrorSkeleton';
import { WIZARD_BODY_ID } from '../WizardLayout';

import { ANIMATION_SETTINGS } from './Content.config';

import * as S from '../WizardLayout.styles';

type Props = {
  children: React.ReactNode;
  header?: React.ReactNode;
  isHeaderAffixed?: boolean;
  sidebarContent?: React.ReactNode;
  wide?: boolean;
};

export const Content = ({
  children,
  header,
  isHeaderAffixed = true,
  sidebarContent,
  wide = false,
}: Props) => {
  const { activeStep } = useWizard();

  return (
    <>
      <S.Sidebar>{sidebarContent}</S.Sidebar>
      <ErrorBoundary FallbackComponent={ErrorSkeleton}>
        <S.Body id={WIZARD_BODY_ID}>
          <AnimatePresence exitBeforeEnter>
            <motion.div key={activeStep} {...ANIMATION_SETTINGS}>
              {header &&
                (isHeaderAffixed ? (
                  <Affix
                    target={() => document.getElementById(WIZARD_BODY_ID)}
                    offsetTop={-1}
                  >
                    <S.SubHeader $noBorder>{header}</S.SubHeader>
                  </Affix>
                ) : (
                  <Margin bottom="md">{header}</Margin>
                ))}

              <S.InnerBody $wide={wide}>{children}</S.InnerBody>
            </motion.div>
          </AnimatePresence>
        </S.Body>
      </ErrorBoundary>
    </>
  );
};
