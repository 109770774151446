import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { type Codebook, CbAccreditationLevel } from '@npm/data-access';

type Props = {
  level: Codebook;
  isFailed: boolean;
};

export const AccreditedInvestorStatus = ({ level, isFailed }: Props) => {
  return (
    <Flex justify="space-between" align="center">
      <Heading variant="h3" marginBottom={0}>
        Accredited Investor Status
      </Heading>
      {CbAccreditationLevel.getLabel(CbLabel, level, {
        name: isFailed ? 'warning' : 'check',
      })}
    </Flex>
  );
};
