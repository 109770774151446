import { css } from 'styled-components';

import { useBreakpoint } from '../../../utils/style';
import { InputTextStyles } from '../Typography';

export const INPUT_HEIGHT = 42;
export const INPUT_MOBILE_HEIGHT = 48; // ============================================
export const HeightStyle = css`
  min-height: ${INPUT_HEIGHT}px;
  ${({ theme }) => useBreakpoint(theme).mobile`
      min-height: ${INPUT_MOBILE_HEIGHT}px;
  `}
`; // ============================================// Common style for input fields
export const inputCSS = css`
  &,
  &.ant-input-affix-wrapper,
  &.ant-input-affix-wrapper .ant-input {
    ${InputTextStyles};
    ${HeightStyle};
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    border-color: ${({ theme }) => theme.color.general.borders.secondary};
    background-color: ${({ theme }) => theme.color.general.layout.two};
    padding: ${({ theme }) =>
      `${theme.spacing.sm}px ${theme.spacing.lg / 2}px `};

    &::placeholder {
      color: ${({ theme }) => theme.color.general.typography.tertiary};
    }
  }

  &.ant-input-affix-wrapper {
    padding: 0;

    .ant-input {
      // this is to cover difference when border is on input vs on affix-wrapper
      min-height: ${INPUT_HEIGHT - 2}px;
      ${({ theme }) => useBreakpoint(theme).mobile`
        min-height: ${INPUT_MOBILE_HEIGHT - 2}px;
      `}
    }

    .ant-input-suffix {
      margin-left: 0;
      ${InputTextStyles};
      color: ${({ theme }) => theme.color.general.typography.secondary};
      padding-right: ${({ theme }) => `${theme.spacing.lg / 2}px `};
    }

    .ant-input-prefix {
      margin-right: 0;
      ${InputTextStyles};
      color: ${({ theme }) => theme.color.general.typography.primary};
      padding-left: ${({ theme }) => `${theme.spacing.lg / 2}px `};
    }
  }

  &.ant-input-affix-wrapper-focused,
  &.ant-input-affix-wrapper-focused .ant-input {
    background-color: ${({ theme }) =>
      theme.color.general.layout.one}; !important;
  }

  &:focus,
  &.ant-picker-focused,
  &.ant-input-affix-wrapper-focused,
  &.ant-input-number-focused {
    border-color: ${({ theme }) => theme.color.info.borders.primary};
    background-color: ${({ theme }) => theme.color.general.layout.one};
    box-shadow: ${({ theme }) => theme.shadow.level1},
      0 0 0 1px ${({ theme }) => theme.color.info.borders.primary};
  }

  &.ant-input:hover,
  &.ant-input-affix-wrapper:hover,
  &.ant-input-affix-wrapper:hover .ant-input {
    border-color: ${({ theme }) => theme.color.info.borders.primary};
    background-color: ${({ theme }) =>
      theme.color.general.layout.one}; !important;
  }
`; // Adjustments for error status
export const errorStatusCSS = css`
  background-color: ${({ theme }) => theme.color.general.layout.two};
  border: 2px solid ${({ theme }) => theme.color.error.typography.primary};

  &&:hover {
    background-color: ${({ theme }) => theme.color.general.layout.one};
    border-color: ${({ theme }) => theme.color.error.typography.primary};
  }

  &:focus,
  &.ant-picker-focused,
  &.ant-input-affix-wrapper-focused,
  &.ant-input-number-focused {
    box-shadow: ${({ theme }) => theme.shadow.level1},
      0 0 0 1px ${({ theme }) => theme.color.error.typography.primary};
    background-color: ${({ theme }) =>
      theme.color.general.layout.one}; !important;
  }
`; // Disabled input field
export const disabledCSS = css`
  &&,
  && input {
    background-color: ${({ theme }) => theme.color.general.layout.two};
    border-color: ${({ theme }) => theme.color.general.borders.primary};
    color: ${({ theme }) => theme.color.general.typography.muted};
  }

  &&::placeholder,
  && input::placeholder {
    color: ${({ theme }) => theme.color.general.typography.muted};
  }

  &&,
  && svg {
    color: ${({ theme }) => theme.color.general.typography.muted};
  }

  &&:hover,
  &&:hover input {
    background-color: ${({ theme }) => theme.color.general.layout.two};
    border-color: ${({ theme }) => theme.color.general.borders.primary};
  }
`;
export const readOnlyCSS = css`
  &&,
  && input {
    background-color: ${({ theme }) => theme.color.general.backgrounds.muted};
    border-color: ${({ theme }) => theme.color.general.borders.secondary};
    color: ${({ theme }) => theme.color.general.typography.secondary};

    &::placeholder {
      color: ${({ theme }) => theme.color.general.typography.secondary};
    }
  }

  &&:hover,
  &&:hover input {
    background-color: ${({ theme }) => theme.color.general.backgrounds.muted};
    border-color: ${({ theme }) => theme.color.general.borders.secondary};
  }

  && svg {
    color: ${({ theme }) => theme.color.general.typography.muted};
  }
`;
