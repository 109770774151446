export const institutionBenefits = [
  'Benchmark your Portfolio',
  'View Portfolio and Watchlist activity',
  'Post to Marketplace | Negotiate with counterparty',
  'Settle Trades',
  'Access financing activity + cap-table data,and more',
];

export const individualBenefits = [
  'Post your Offer',
  'Accept Bids | Negotiate with buyers',
  'Settle Trades',
  'Get Paid',
];
