import styled from 'styled-components';

import { Button as BaseButton } from '@npm/core/ui/components/atoms/Button';
import { InputFormItem as FormItemBase } from '@npm/core/ui/components/atoms/FormItem';
import { Skeleton as SkeletonBase } from '@npm/core/ui/components/atoms/Skeleton';
import { useBreakpoint } from '@npm/core/ui/utils/style';

export const Row = styled.div<{ $direction?: 'horizontal' | 'vertical' }>`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  gap: ${({ theme }) => theme.spacing.md}px;
  flex-direction: ${({ $direction = 'horizontal' }) =>
    $direction === 'horizontal' ? 'row' : 'column'};

  ${({ theme }) => useBreakpoint(theme).mobile`
    flex-direction: column;
    margin-bottom: 0;
    gap: 0;
  `}
`;

export const FormItem = styled(FormItemBase)<{
  $direction?: 'horizontal' | 'vertical';
}>`
  width: ${({ $direction = 'horizontal' }) =>
    $direction === 'horizontal' ? '50%' : '100%'};

  ${({ theme, $direction }) =>
    $direction === 'horizontal' && `margin-bottom: ${theme.spacing.md}px;`}

  ${({ theme }) => useBreakpoint(theme).mobile`
      width: 100%;
      margin-bottom: ${theme.spacing.md}px;
  `}

  & > .ant-form-item-label {
    margin-right: 0;
  }

  & > .ant-form-item-label > label {
    margin-bottom: ${({ theme }) => theme.spacing.xs / 2}px;

    .ant-form-item-optional {
      color: ${({ theme }) => theme.color.general.typography.secondary};
      text-transform: capitalize;
    }
  }

  .ant-input-status-success:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input {
    border-color: ${({ theme }) => theme.color.general.borders.primary};
  }

  .ant-form-item-explain-success,
  .ant-form-item-explain {
    color: ${({ theme }) => theme.color.general.typography.secondary};
  }

  &:only-child {
    padding-right: ${({ theme }) => theme.spacing.sm}px;
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    &:only-child {
      padding-right: 0;
    }
  `}
`;

export const Button = styled(BaseButton)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`;

export const Skeleton = styled(SkeletonBase.Base)`
  .ant-skeleton-content .ant-skeleton-paragraph {
    margin: ${({ theme }) => theme.spacing.xs}px 0;

    & > li {
      width: 100% !important;
    }
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing.md}px;
  }
`;

export const AddressFormWrap = styled.div<{ $isOpen: boolean }>`
  ${({ $isOpen }) =>
    !$isOpen &&
    `

  display: none;`}
`;
