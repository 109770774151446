import React, { memo, useCallback } from 'react';

import { APP_CFG_JIRA_BADGE_ENABLED } from '@npm/config';

import { usePathMetadata } from '../../../services/pathMetadata';

import * as S from './JiraBadge.styles';

export const JiraBadge = memo(() => {
  const pathMetadata = usePathMetadata();

  const openLink = useCallback(() => {
    window.open(
      `https://nasdaq-private-market.atlassian.net/browse/${pathMetadata.screenId}`,
      'blank'
    );
  }, [pathMetadata]);

  return APP_CFG_JIRA_BADGE_ENABLED === 'true' ? (
    <S.Wrapper>
      <S.Img alt="jira" src="/public/assets/jira.png" onClick={openLink} />
    </S.Wrapper>
  ) : null;
});
