import React from 'react';

// TODO: VR-001: Move to features?
export const formatContactInformation = (contactInformation?: {
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state?: { name?: string };
  zip?: string;
  country?: { name?: string };
}) => {
  if (!contactInformation) {
    return '--';
  }

  const { address_line_1, address_line_2, city, state, zip, country } =
    contactInformation;

  return (
    <>
      {address_line_1}, {address_line_2 && <>{address_line_2}, </>}
      {city}
      {state?.name ? `, ${state.name}` : ''}
      {zip ? `, ${zip}` : ''}
      {country?.name ? `, ${country.name}` : ''}
    </>
  );
};
