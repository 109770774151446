/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  EquityAssetsApi,
  EquityAssetCompaniesIndexErrorCodes,
  EquityAssetCompaniesIndexErrorTypes,
  EquityAssetIssuersIndexErrorCodes,
  EquityAssetIssuersIndexErrorTypes,
  EquityAssetUpdateErrorCodes,
  EquityAssetCreateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new EquityAssetsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type EquityAssetCompaniesIndexMethod = EquityAssetsApi['equityAssetCompaniesIndex'];
export type EquityAssetCompaniesIndexResponseType = MethodResult<EquityAssetCompaniesIndexMethod>;

export const useEquityAssetCompaniesIndex = (
  variables: Parameters<EquityAssetCompaniesIndexMethod>[0],
  config?: UseQueryConfig<
    EquityAssetCompaniesIndexMethod,
    EquityAssetCompaniesIndexErrorTypes
  >
) => {
  return useQuery<
    EquityAssetCompaniesIndexMethod,
    EquityAssetCompaniesIndexErrorTypes
  >(
    (...args) => api.equityAssetCompaniesIndex(...args),
    EquityAssetCompaniesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EquityAssetCompaniesIndex'],
        ...apiQueryConfig.EquityAssetCompaniesIndex,
        ...config?.queryConfig
      },
    },
    'EquityAssetCompaniesIndex',
  );
};

export const useEquityAssetCompaniesIndexInfinite = (
  variables: Parameters<EquityAssetCompaniesIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    EquityAssetCompaniesIndexMethod,
    EquityAssetCompaniesIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    EquityAssetCompaniesIndexMethod,
    EquityAssetCompaniesIndexErrorTypes
  >(
    (...args) => api.equityAssetCompaniesIndex(...args),
    EquityAssetCompaniesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EquityAssetCompaniesIndex', 'EquityAssetCompaniesIndexInfinite'],
        ...apiQueryConfig.EquityAssetCompaniesIndex,
        ...config?.queryConfig,
      },
    },
    'EquityAssetCompaniesIndex',
  );
};

export const useEquityAssetCompaniesIndexLazy = (baseOptions?: {
  variables?: Parameters<EquityAssetCompaniesIndexMethod>[0],
  config?: UseQueryConfig<
    EquityAssetCompaniesIndexMethod,
    EquityAssetCompaniesIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    EquityAssetCompaniesIndexMethod,
    EquityAssetCompaniesIndexErrorTypes
  >(
    (...args) => api.equityAssetCompaniesIndex(...args),
    EquityAssetCompaniesIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EquityAssetCompaniesIndex'],
        ...apiQueryConfig.EquityAssetCompaniesIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EquityAssetCompaniesIndex',
  );
};


export type EquityAssetIssuersIndexMethod = EquityAssetsApi['equityAssetIssuersIndex'];
export type EquityAssetIssuersIndexResponseType = MethodResult<EquityAssetIssuersIndexMethod>;

export const useEquityAssetIssuersIndex = (
  variables: Parameters<EquityAssetIssuersIndexMethod>[0],
  config?: UseQueryConfig<
    EquityAssetIssuersIndexMethod,
    EquityAssetIssuersIndexErrorTypes
  >
) => {
  return useQuery<
    EquityAssetIssuersIndexMethod,
    EquityAssetIssuersIndexErrorTypes
  >(
    (...args) => api.equityAssetIssuersIndex(...args),
    EquityAssetIssuersIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EquityAssetIssuersIndex'],
        ...apiQueryConfig.EquityAssetIssuersIndex,
        ...config?.queryConfig
      },
    },
    'EquityAssetIssuersIndex',
  );
};

export const useEquityAssetIssuersIndexInfinite = (
  variables: Parameters<EquityAssetIssuersIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    EquityAssetIssuersIndexMethod,
    EquityAssetIssuersIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    EquityAssetIssuersIndexMethod,
    EquityAssetIssuersIndexErrorTypes
  >(
    (...args) => api.equityAssetIssuersIndex(...args),
    EquityAssetIssuersIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EquityAssetIssuersIndex', 'EquityAssetIssuersIndexInfinite'],
        ...apiQueryConfig.EquityAssetIssuersIndex,
        ...config?.queryConfig,
      },
    },
    'EquityAssetIssuersIndex',
  );
};

export const useEquityAssetIssuersIndexLazy = (baseOptions?: {
  variables?: Parameters<EquityAssetIssuersIndexMethod>[0],
  config?: UseQueryConfig<
    EquityAssetIssuersIndexMethod,
    EquityAssetIssuersIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    EquityAssetIssuersIndexMethod,
    EquityAssetIssuersIndexErrorTypes
  >(
    (...args) => api.equityAssetIssuersIndex(...args),
    EquityAssetIssuersIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EquityAssetIssuersIndex'],
        ...apiQueryConfig.EquityAssetIssuersIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EquityAssetIssuersIndex',
  );
};


export type EquityAssetUpdateMethod = EquityAssetsApi['equityAssetUpdate'];

export const useEquityAssetUpdate = () => {
  return useMutation<EquityAssetUpdateMethod>(
    (...args) => api.equityAssetUpdate(...args),
    EquityAssetUpdateErrorCodes,
    'EquityAssetUpdate',
    '/api/equity_assets/{equity_asset_id}',
    'put'
  );
};

export type EquityAssetCreateMethod = EquityAssetsApi['equityAssetCreate'];

export const useEquityAssetCreate = () => {
  return useMutation<EquityAssetCreateMethod>(
    (...args) => api.equityAssetCreate(...args),
    EquityAssetCreateErrorCodes,
    'EquityAssetCreate',
    '/api/equity_assets/',
    'post'
  );
};
