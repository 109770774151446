import styled from 'styled-components';

import { ellipsisTextOverflow } from '../../../utils/style';
import { Text } from '../../atoms/Typography';

export const Badge = styled.div`
  display: flex;
  overflow: hidden;
  gap: ${({ theme }) => theme.spacing.xs}px;
  padding: ${({ theme }) => `${theme.spacing.sm}px ${theme.spacing.md}px`};
  background-color: ${({ theme }) => theme.color.general.layout.one};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
`;

export const AccountName = styled(Text)`
  ${ellipsisTextOverflow}
`;
