import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography/Text';

import { type NegotiationActionFlags } from '../../../../OboModal.types';
import { InvestorOrStakeholderLabel } from '../../InvestorOrStakeholderLabel';
import { getActionText } from '../../SelectNegotiationAccount.utils';

export const SelectAccountCardLabels = ({
  isInvestor,
  actionFlags: { userCanActAsInvestor, userCanActAsStakeholder },
}: {
  isInvestor: boolean;
  actionFlags: NegotiationActionFlags;
}) => {
  return (
    <Flex justify="space-between">
      <InvestorOrStakeholderLabel isInvestor={isInvestor} />
      <div>
        <Flex align="center" gap="sm">
          <Text size="xs">Action</Text>
          <Label>
            {getActionText(
              isInvestor ? userCanActAsInvestor : userCanActAsStakeholder
            )}
          </Label>
        </Flex>
      </div>
    </Flex>
  );
};
