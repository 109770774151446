import { type ComponentType, lazy } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadComponent = async <T extends ComponentType<any>>(
  componentImport: () => Promise<{
    default: T;
  }>,
  attemptsLeft
) => {
  try {
    const component = await componentImport();
    window.localStorage.setItem('page-has-been-force-refreshed', 'false');
    return component;
  } catch (error) {
    if (attemptsLeft > 0) {
      // Try to load component 2 times and then fail
      await new Promise(r => setTimeout(r, 500));
      return await loadComponent<T>(componentImport, attemptsLeft - 1);
    }
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    );
    if (!pageHasAlreadyBeenForceRefreshed) {
      // Assuming that the user is not on the latest version of the application.
      // Let's refresh the page immediately.
      window.localStorage.setItem('page-has-been-force-refreshed', 'true');
      return window.location.reload();
    }
    throw error;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const componentLoader = <T extends ComponentType<any>>(
  componentImport: () => Promise<{
    default: T;
  }>
) =>
  lazy<T>(async () => {
    return await loadComponent<T>(componentImport, 2);
  });
