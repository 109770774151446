import styled from 'styled-components';

const CARD_WIDTH = 391;

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: ${({ theme }) => theme.spacing.md}px;
  height: 100%;
`;

export const Content = styled.div`
  flex: 1 1 auto;
`;

export const ProgramWrap = styled.div`
  display: flex;
  flex: 1;
  width: 100%;

  & > div {
    width: 100%;
  }
`;

export const ProgramsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(min(${CARD_WIDTH}px, 100%), 1fr)
  );
  grid-auto-flow: row;
  gap: ${({ theme }) => theme.spacing.md}px;
`;
