export const CompaniesDataSortingOptions = {
  lrValuationHighToLow: {
    label: 'Latest Valuation: High to Low',
    icon: 'sort-descending',
    sort: {
      sortBy: 'lr_valuation',
      sortDirection: 'desc',
    },
  },
  alphabeticalAZ: {
    label: 'Alphabetical: A-Z',
    icon: 'sort-descending',
    sort: {
      sortBy: 'name',
      sortDirection: 'asc',
    },
  },
  alphabeticalZA: {
    label: 'Alphabetical: Z-A',
    icon: 'sort-ascending',
    sort: {
      sortBy: 'name',
      sortDirection: 'desc',
    },
  },
} as const;
