export const getAxisRoundedMaxValue = (value: number) => {
  const magnitude = Math.pow(10, Math.floor(Math.log10(value)));
  const scaledValue = value / magnitude;
  const roundedScaledValue = Math.ceil(scaledValue);
  const finalValue = roundedScaledValue * magnitude;

  // if the initial max value is not at least 5% smaller than the final value, increase the final value by 20% to add an extra tick on the y axis (= some extra padding at the top)
  if (value >= finalValue * 0.95) {
    return finalValue * 1.2;
  } else {
    return finalValue;
  }
};
