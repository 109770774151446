import styled from 'styled-components';

import { Card as CardBase } from '@npm/core/ui/components/molecules/Card';
import { useBreakpoint } from '@npm/core/ui/utils/style';

export const Card = styled(CardBase)`
  margin: 16px;

  &&& .card-header-content {
    padding-left: ${({ theme }) => theme.spacing.md}px;
  }

  .card-content > *:first-child {
    ${({ theme }) => useBreakpoint(theme).mobile`
        padding: 0 8px;
    `}
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    .card-header-content {
      flex-direction: column;
      align-items: flex-start;
      gap: ${({ theme }) => theme.spacing.md}px;

      .card-header-content-right {
        align-self: flex-end;
      }
    }
  `}
`;
