import React, { type ComponentProps, useEffect, useRef } from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { GetStarted } from '@npm/core/ui/components/molecules/GetStarted';
import { Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';

import {
  DisabledTextForBroker,
  EntityAccreditationForm,
  SignatureModal,
} from '../../../accreditation';
import { useEntityAccreditationForm } from '../../../accreditation';
import { whitelistedAnswerCodes } from '../../../accreditation/EntityAccreditation/EntityAccreditation.types';
import {
  EntityAccreditationReadOnly,
  useFollowUpQuestionsComponents,
} from '../../../accreditation/EntityAccreditation/ReadOnly';
import { useCurrentWorkstation, useObo } from '../../../auth/user/role';
import { type PostOnboardingContext } from '../../PostOnboarding.types';
import { StepContainer } from '../../StepContainer';

const { StepState } = Wizard;

type Props = {
  stepProps: Wizard.StepProps;
} & Pick<
  ComponentProps<typeof EntityAccreditationReadOnly>,
  'zendeskTicketGroupId'
>;

export const EntityAccreditationPostOnboarding = ({
  stepProps,
  zendeskTicketGroupId,
}: Props) => {
  const { account, stepState } = useWizardStore(
    (s: WizardStore<PostOnboardingContext>) => ({
      account: s.data.account,
      stepState: s.activeStepState,
    })
  );
  const { isObo } = useObo();
  const workstation = useCurrentWorkstation();
  const isActingAsBroker = isObo || workstation?.type?.code === 'brokerage';
  const isAccreditationUpdated = useRef(false);

  const {
    form,
    signatureForm,
    isLoading,
    isUpdating,
    handleSubmit,
    handleOpenSignModal,
    isSignatureModalOpen,
    closeSignModal,
    data,
    filteredAnswers,
  } = useEntityAccreditationForm(account?.id, {
    onUpdate: () => {
      Notification.success({
        message: 'You have updated your Accredited Investor Status',
      });
      stepProps.setFormHasUnsavedChanges(false);
      isAccreditationUpdated.current = true;
    },
  });
  const showDocument = !!data?.documents?.length;

  const { modals, buttons } = useFollowUpQuestionsComponents({
    data,
    showQPButton: !filteredAnswers.qualified_purchaser,
    showQIBButton: !filteredAnswers.qualified_institutional_buyer,
    showQCButton: !filteredAnswers.qualified_client,
  });

  const orgTypeValue = Form.useWatch('org_type', form);

  useEffect(() => {
    const isAllAnswersFilled = Array.from(whitelistedAnswerCodes).every(
      code => !!filteredAnswers[code]
    );
    if (isAccreditationUpdated.current && isAllAnswersFilled) {
      stepProps.handleStepComplete();
    }
  }, [filteredAnswers]);

  const formComponent = isActingAsBroker ? (
    <GetStarted content={<DisabledTextForBroker />} />
  ) : (
    <EntityAccreditationForm
      form={form}
      handleValuesChange={() => stepProps.setFormHasUnsavedChanges(true)}
      handleOpenSignModal={handleOpenSignModal}
    />
  );

  return (
    <>
      <StepContainer
        stepProps={stepProps}
        isLoading={isLoading}
        handleSubmit={isActingAsBroker ? null : handleOpenSignModal}
        disableSubmitButton={!orgTypeValue}
      >
        {stepState === StepState.FILLED && (
          <>
            <EntityAccreditationReadOnly
              data={data}
              answers={filteredAnswers}
              brokerageFirmName={account?.brokerage_firm?.name}
              zendeskTicketGroupId={zendeskTicketGroupId}
            />
            {!isActingAsBroker && !showDocument && buttons}
          </>
        )}
        {stepState === StepState.EDIT && formComponent}
      </StepContainer>
      {modals}
      <SignatureModal
        variant="entity"
        form={signatureForm}
        isOpen={isSignatureModalOpen}
        handleSubmit={handleSubmit}
        handleCancel={closeSignModal}
        isLoading={isLoading || isUpdating}
      />
    </>
  );
};
