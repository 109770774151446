import styled from 'styled-components';

export const NavigationMenu = styled.div<{ $isCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ $isCollapsed }) => $isCollapsed && `overflow: visible;`}
`;
