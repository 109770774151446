import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Checkbox } from '@npm/core/ui/components/atoms/Checkbox';
import { Form } from '@npm/core/ui/components/atoms/Form';

import { ALTERNATE_BENEFICIARY_ENUM } from '../BankAccountInformation.types';

import * as S from './BankAccountInformationForm.styles';

export const useAlternateBeneficiary = (
  allowAlternateBeneficiary: boolean,
  checkboxFieldName = ALTERNATE_BENEFICIARY_ENUM
) => {
  const isChecked = Form.useWatch(checkboxFieldName);

  return {
    isBeneficiaryEditEnabled: isChecked && allowAlternateBeneficiary,
    checkboxComponent: allowAlternateBeneficiary ? (
      <>
        <S.SectionSpacer $size="sm">
          <S.FormItem
            name={checkboxFieldName}
            initialValue={false}
            valuePropName="checked"
          >
            <Checkbox label="I would like to change the name on the account" />
          </S.FormItem>
        </S.SectionSpacer>

        {isChecked && (
          <S.SectionSpacer $size="sm">
            <Alert message="Please note that if you fund an account that is not in your name, you will be contacted by Nasdaq Private Market to produce additional documentation to comply with applicable legal and regulatory requirements." />
          </S.SectionSpacer>
        )}
      </>
    ) : null,
  };
};
