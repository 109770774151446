/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountsBackgroundCheckShowAggregate } from '../model';
// @ts-ignore
import { BackgroundCheckIndex } from '../model';
// @ts-ignore
import { BackgroundCheckShowAggregate } from '../model';
// @ts-ignore
import { BackgroundCheckUpdateRequestContract } from '../model';
// @ts-ignore
import { BrokerageFirmsBackgroundCheckIndex } from '../model';
// @ts-ignore
import { BrokerageFirmsBackgroundChecksStatisticShow } from '../model';
// @ts-ignore
import { ErrorValidation } from '../model';
/**
 * BackgroundCheckApi - axios parameter creator
 * @export
 */
export const BackgroundCheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary returns the latest background check for the account (returns 404 if account has no background check)
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBackgroundCheckShow: async (accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountBackgroundCheckShow', 'accountId', accountId)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/background-check`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns the background check by id for the account (returns 404 if account has not background check for that id)
         * @param {number} accountId 
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBackgroundCheckShowById: async (accountId: number, id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountBackgroundCheckShowById', 'accountId', accountId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountBackgroundCheckShowById', 'id', id)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/background-checks/{id}`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves background checks
         * @param {string} [brokerageFirmId] 
         * @param {string} [ownerType] 
         * @param {string} [ownerId] 
         * @param {Array<string>} [backgroundCheckState] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundCheckIndex: async (brokerageFirmId?: string, ownerType?: string, ownerId?: string, backgroundCheckState?: Array<string>, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/background-checks`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (brokerageFirmId !== undefined) {
                localVarQueryParameter['brokerage_firm_id'] = brokerageFirmId;
            }

            if (ownerType !== undefined) {
                localVarQueryParameter['owner_type'] = ownerType;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['owner_id'] = ownerId;
            }

            if (backgroundCheckState) {
                localVarQueryParameter['background_check_state'] = backgroundCheckState;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns the background check by id
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundCheckShowById: async (id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backgroundCheckShowById', 'id', id)
            const localVarPath = changeUrl(`/api/background-checks/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves latest background check
         * @param {string} [brokerageFirmId] 
         * @param {string} [ownerType] 
         * @param {string} [ownerId] 
         * @param {Array<string>} [backgroundCheckState] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundCheckShowLatest: async (brokerageFirmId?: string, ownerType?: string, ownerId?: string, backgroundCheckState?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/background-check`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (brokerageFirmId !== undefined) {
                localVarQueryParameter['brokerage_firm_id'] = brokerageFirmId;
            }

            if (ownerType !== undefined) {
                localVarQueryParameter['owner_type'] = ownerType;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['owner_id'] = ownerId;
            }

            if (backgroundCheckState) {
                localVarQueryParameter['background_check_state'] = backgroundCheckState;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the background check specified in the path
         * @param {number} id 
         * @param {BackgroundCheckUpdateRequestContract} backgroundCheckUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundCheckUpdate: async (id: number, backgroundCheckUpdateRequestContract: BackgroundCheckUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backgroundCheckUpdate', 'id', id)
            // verify required parameter 'backgroundCheckUpdateRequestContract' is not null or undefined
            assertParamExists('backgroundCheckUpdate', 'backgroundCheckUpdateRequestContract', backgroundCheckUpdateRequestContract)
            const localVarPath = changeUrl(`/api/background-checks/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backgroundCheckUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves account with background checks associated with the brokerage firm specified in the path
         * @param {string} brokerageFirmId 
         * @param {string} accountType 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerageFirmBackgroundCheckIndex: async (brokerageFirmId: string, accountType: string, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brokerageFirmId' is not null or undefined
            assertParamExists('brokerageFirmBackgroundCheckIndex', 'brokerageFirmId', brokerageFirmId)
            // verify required parameter 'accountType' is not null or undefined
            assertParamExists('brokerageFirmBackgroundCheckIndex', 'accountType', accountType)
            const localVarPath = changeUrl(`/api/brokerage-firms/{brokerage_firm_id}/background-checks/`)
                .replace(`{${"brokerage_firm_id"}}`, encodeURIComponent(String(brokerageFirmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountType !== undefined) {
                localVarQueryParameter['account_type'] = accountType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves background check statistics associated with the brokerage firm specified in the path
         * @param {string} brokerageFirmId 
         * @param {string} accountType 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerageFirmBackgroundCheckStatisticShow: async (brokerageFirmId: string, accountType: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brokerageFirmId' is not null or undefined
            assertParamExists('brokerageFirmBackgroundCheckStatisticShow', 'brokerageFirmId', brokerageFirmId)
            // verify required parameter 'accountType' is not null or undefined
            assertParamExists('brokerageFirmBackgroundCheckStatisticShow', 'accountType', accountType)
            const localVarPath = changeUrl(`/api/brokerage-firms/{brokerage_firm_id}/background-checks/statistics`)
                .replace(`{${"brokerage_firm_id"}}`, encodeURIComponent(String(brokerageFirmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountType !== undefined) {
                localVarQueryParameter['account_type'] = accountType;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackgroundCheckApi - functional programming interface
 * @export
 */
export const BackgroundCheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BackgroundCheckApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary returns the latest background check for the account (returns 404 if account has no background check)
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountBackgroundCheckShow(accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsBackgroundCheckShowAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountBackgroundCheckShow(accountId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns the background check by id for the account (returns 404 if account has not background check for that id)
         * @param {number} accountId 
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountBackgroundCheckShowById(accountId: number, id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsBackgroundCheckShowAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountBackgroundCheckShowById(accountId, id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves background checks
         * @param {string} [brokerageFirmId] 
         * @param {string} [ownerType] 
         * @param {string} [ownerId] 
         * @param {Array<string>} [backgroundCheckState] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backgroundCheckIndex(brokerageFirmId?: string, ownerType?: string, ownerId?: string, backgroundCheckState?: Array<string>, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackgroundCheckIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backgroundCheckIndex(brokerageFirmId, ownerType, ownerId, backgroundCheckState, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns the background check by id
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backgroundCheckShowById(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackgroundCheckShowAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backgroundCheckShowById(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves latest background check
         * @param {string} [brokerageFirmId] 
         * @param {string} [ownerType] 
         * @param {string} [ownerId] 
         * @param {Array<string>} [backgroundCheckState] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backgroundCheckShowLatest(brokerageFirmId?: string, ownerType?: string, ownerId?: string, backgroundCheckState?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackgroundCheckShowAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backgroundCheckShowLatest(brokerageFirmId, ownerType, ownerId, backgroundCheckState, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the background check specified in the path
         * @param {number} id 
         * @param {BackgroundCheckUpdateRequestContract} backgroundCheckUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backgroundCheckUpdate(id: number, backgroundCheckUpdateRequestContract: BackgroundCheckUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackgroundCheckShowAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backgroundCheckUpdate(id, backgroundCheckUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves account with background checks associated with the brokerage firm specified in the path
         * @param {string} brokerageFirmId 
         * @param {string} accountType 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerageFirmBackgroundCheckIndex(brokerageFirmId: string, accountType: string, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrokerageFirmsBackgroundCheckIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerageFirmBackgroundCheckIndex(brokerageFirmId, accountType, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves background check statistics associated with the brokerage firm specified in the path
         * @param {string} brokerageFirmId 
         * @param {string} accountType 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerageFirmBackgroundCheckStatisticShow(brokerageFirmId: string, accountType: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrokerageFirmsBackgroundChecksStatisticShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerageFirmBackgroundCheckStatisticShow(brokerageFirmId, accountType, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BackgroundCheckApi - factory interface
 * @export
 */
export const BackgroundCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BackgroundCheckApiFp(configuration)
    return {
        /**
         * 
         * @summary returns the latest background check for the account (returns 404 if account has no background check)
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBackgroundCheckShow(accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AccountsBackgroundCheckShowAggregate> {
            return localVarFp.accountBackgroundCheckShow(accountId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns the background check by id for the account (returns 404 if account has not background check for that id)
         * @param {number} accountId 
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBackgroundCheckShowById(accountId: number, id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AccountsBackgroundCheckShowAggregate> {
            return localVarFp.accountBackgroundCheckShowById(accountId, id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves background checks
         * @param {string} [brokerageFirmId] 
         * @param {string} [ownerType] 
         * @param {string} [ownerId] 
         * @param {Array<string>} [backgroundCheckState] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundCheckIndex(brokerageFirmId?: string, ownerType?: string, ownerId?: string, backgroundCheckState?: Array<string>, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<BackgroundCheckIndex> {
            return localVarFp.backgroundCheckIndex(brokerageFirmId, ownerType, ownerId, backgroundCheckState, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns the background check by id
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundCheckShowById(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<BackgroundCheckShowAggregate> {
            return localVarFp.backgroundCheckShowById(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves latest background check
         * @param {string} [brokerageFirmId] 
         * @param {string} [ownerType] 
         * @param {string} [ownerId] 
         * @param {Array<string>} [backgroundCheckState] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundCheckShowLatest(brokerageFirmId?: string, ownerType?: string, ownerId?: string, backgroundCheckState?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<BackgroundCheckShowAggregate> {
            return localVarFp.backgroundCheckShowLatest(brokerageFirmId, ownerType, ownerId, backgroundCheckState, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the background check specified in the path
         * @param {number} id 
         * @param {BackgroundCheckUpdateRequestContract} backgroundCheckUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundCheckUpdate(id: number, backgroundCheckUpdateRequestContract: BackgroundCheckUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<BackgroundCheckShowAggregate> {
            return localVarFp.backgroundCheckUpdate(id, backgroundCheckUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves account with background checks associated with the brokerage firm specified in the path
         * @param {string} brokerageFirmId 
         * @param {string} accountType 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerageFirmBackgroundCheckIndex(brokerageFirmId: string, accountType: string, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<BrokerageFirmsBackgroundCheckIndex> {
            return localVarFp.brokerageFirmBackgroundCheckIndex(brokerageFirmId, accountType, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves background check statistics associated with the brokerage firm specified in the path
         * @param {string} brokerageFirmId 
         * @param {string} accountType 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerageFirmBackgroundCheckStatisticShow(brokerageFirmId: string, accountType: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<BrokerageFirmsBackgroundChecksStatisticShow> {
            return localVarFp.brokerageFirmBackgroundCheckStatisticShow(brokerageFirmId, accountType, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for accountBackgroundCheckShow operation in BackgroundCheckApi.
 * @export
 * @interface BackgroundCheckApiAccountBackgroundCheckShowRequest
 */
export interface BackgroundCheckApiAccountBackgroundCheckShowRequest {
    /**
     * 
     * @type {number}
     * @memberof BackgroundCheckApiAccountBackgroundCheckShow
     */
    readonly accountId: number

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiAccountBackgroundCheckShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiAccountBackgroundCheckShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiAccountBackgroundCheckShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiAccountBackgroundCheckShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiAccountBackgroundCheckShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiAccountBackgroundCheckShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for accountBackgroundCheckShowById operation in BackgroundCheckApi.
 * @export
 * @interface BackgroundCheckApiAccountBackgroundCheckShowByIdRequest
 */
export interface BackgroundCheckApiAccountBackgroundCheckShowByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof BackgroundCheckApiAccountBackgroundCheckShowById
     */
    readonly accountId: number

    /**
     * 
     * @type {number}
     * @memberof BackgroundCheckApiAccountBackgroundCheckShowById
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiAccountBackgroundCheckShowById
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiAccountBackgroundCheckShowById
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiAccountBackgroundCheckShowById
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiAccountBackgroundCheckShowById
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiAccountBackgroundCheckShowById
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiAccountBackgroundCheckShowById
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for backgroundCheckIndex operation in BackgroundCheckApi.
 * @export
 * @interface BackgroundCheckApiBackgroundCheckIndexRequest
 */
export interface BackgroundCheckApiBackgroundCheckIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckIndex
     */
    readonly brokerageFirmId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckIndex
     */
    readonly ownerType?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckIndex
     */
    readonly ownerId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof BackgroundCheckApiBackgroundCheckIndex
     */
    readonly backgroundCheckState?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof BackgroundCheckApiBackgroundCheckIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BackgroundCheckApiBackgroundCheckIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for backgroundCheckShowById operation in BackgroundCheckApi.
 * @export
 * @interface BackgroundCheckApiBackgroundCheckShowByIdRequest
 */
export interface BackgroundCheckApiBackgroundCheckShowByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof BackgroundCheckApiBackgroundCheckShowById
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckShowById
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckShowById
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckShowById
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckShowById
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckShowById
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckShowById
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for backgroundCheckShowLatest operation in BackgroundCheckApi.
 * @export
 * @interface BackgroundCheckApiBackgroundCheckShowLatestRequest
 */
export interface BackgroundCheckApiBackgroundCheckShowLatestRequest {
    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckShowLatest
     */
    readonly brokerageFirmId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckShowLatest
     */
    readonly ownerType?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckShowLatest
     */
    readonly ownerId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof BackgroundCheckApiBackgroundCheckShowLatest
     */
    readonly backgroundCheckState?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckShowLatest
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckShowLatest
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckShowLatest
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckShowLatest
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckShowLatest
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckShowLatest
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for backgroundCheckUpdate operation in BackgroundCheckApi.
 * @export
 * @interface BackgroundCheckApiBackgroundCheckUpdateRequest
 */
export interface BackgroundCheckApiBackgroundCheckUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof BackgroundCheckApiBackgroundCheckUpdate
     */
    readonly id: number

    /**
     * 
     * @type {BackgroundCheckUpdateRequestContract}
     * @memberof BackgroundCheckApiBackgroundCheckUpdate
     */
    readonly backgroundCheckUpdateRequestContract: BackgroundCheckUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBackgroundCheckUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for brokerageFirmBackgroundCheckIndex operation in BackgroundCheckApi.
 * @export
 * @interface BackgroundCheckApiBrokerageFirmBackgroundCheckIndexRequest
 */
export interface BackgroundCheckApiBrokerageFirmBackgroundCheckIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckIndex
     */
    readonly brokerageFirmId: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckIndex
     */
    readonly accountType: string

    /**
     * 
     * @type {number}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for brokerageFirmBackgroundCheckStatisticShow operation in BackgroundCheckApi.
 * @export
 * @interface BackgroundCheckApiBrokerageFirmBackgroundCheckStatisticShowRequest
 */
export interface BackgroundCheckApiBrokerageFirmBackgroundCheckStatisticShowRequest {
    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckStatisticShow
     */
    readonly brokerageFirmId: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckStatisticShow
     */
    readonly accountType: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckStatisticShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckStatisticShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckStatisticShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckStatisticShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckStatisticShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BackgroundCheckApiBrokerageFirmBackgroundCheckStatisticShow
     */
    readonly xUserRoleId?: string
}

/**
 * BackgroundCheckApi - object-oriented interface
 * @export
 * @class BackgroundCheckApi
 * @extends {BaseAPI}
 */
export class BackgroundCheckApi extends BaseAPI {
    /**
     * 
     * @summary returns the latest background check for the account (returns 404 if account has no background check)
     * @param {BackgroundCheckApiAccountBackgroundCheckShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackgroundCheckApi
     */
    public accountBackgroundCheckShow(requestParameters: BackgroundCheckApiAccountBackgroundCheckShowRequest, options?: AxiosRequestConfig) {
        return BackgroundCheckApiFp(this.configuration).accountBackgroundCheckShow(requestParameters.accountId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns the background check by id for the account (returns 404 if account has not background check for that id)
     * @param {BackgroundCheckApiAccountBackgroundCheckShowByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackgroundCheckApi
     */
    public accountBackgroundCheckShowById(requestParameters: BackgroundCheckApiAccountBackgroundCheckShowByIdRequest, options?: AxiosRequestConfig) {
        return BackgroundCheckApiFp(this.configuration).accountBackgroundCheckShowById(requestParameters.accountId, requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves background checks
     * @param {BackgroundCheckApiBackgroundCheckIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackgroundCheckApi
     */
    public backgroundCheckIndex(requestParameters: BackgroundCheckApiBackgroundCheckIndexRequest = {}, options?: AxiosRequestConfig) {
        return BackgroundCheckApiFp(this.configuration).backgroundCheckIndex(requestParameters.brokerageFirmId, requestParameters.ownerType, requestParameters.ownerId, requestParameters.backgroundCheckState, requestParameters.page, requestParameters.size, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns the background check by id
     * @param {BackgroundCheckApiBackgroundCheckShowByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackgroundCheckApi
     */
    public backgroundCheckShowById(requestParameters: BackgroundCheckApiBackgroundCheckShowByIdRequest, options?: AxiosRequestConfig) {
        return BackgroundCheckApiFp(this.configuration).backgroundCheckShowById(requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves latest background check
     * @param {BackgroundCheckApiBackgroundCheckShowLatestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackgroundCheckApi
     */
    public backgroundCheckShowLatest(requestParameters: BackgroundCheckApiBackgroundCheckShowLatestRequest = {}, options?: AxiosRequestConfig) {
        return BackgroundCheckApiFp(this.configuration).backgroundCheckShowLatest(requestParameters.brokerageFirmId, requestParameters.ownerType, requestParameters.ownerId, requestParameters.backgroundCheckState, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the background check specified in the path
     * @param {BackgroundCheckApiBackgroundCheckUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackgroundCheckApi
     */
    public backgroundCheckUpdate(requestParameters: BackgroundCheckApiBackgroundCheckUpdateRequest, options?: AxiosRequestConfig) {
        return BackgroundCheckApiFp(this.configuration).backgroundCheckUpdate(requestParameters.id, requestParameters.backgroundCheckUpdateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves account with background checks associated with the brokerage firm specified in the path
     * @param {BackgroundCheckApiBrokerageFirmBackgroundCheckIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackgroundCheckApi
     */
    public brokerageFirmBackgroundCheckIndex(requestParameters: BackgroundCheckApiBrokerageFirmBackgroundCheckIndexRequest, options?: AxiosRequestConfig) {
        return BackgroundCheckApiFp(this.configuration).brokerageFirmBackgroundCheckIndex(requestParameters.brokerageFirmId, requestParameters.accountType, requestParameters.page, requestParameters.size, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves background check statistics associated with the brokerage firm specified in the path
     * @param {BackgroundCheckApiBrokerageFirmBackgroundCheckStatisticShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackgroundCheckApi
     */
    public brokerageFirmBackgroundCheckStatisticShow(requestParameters: BackgroundCheckApiBrokerageFirmBackgroundCheckStatisticShowRequest, options?: AxiosRequestConfig) {
        return BackgroundCheckApiFp(this.configuration).brokerageFirmBackgroundCheckStatisticShow(requestParameters.brokerageFirmId, requestParameters.accountType, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AccountBackgroundCheckShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountBackgroundCheckShowErrorCodes = [
    401,
];

export type AccountBackgroundCheckShowByIdErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountBackgroundCheckShowByIdErrorCodes = [
    401,
];

export type BackgroundCheckIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BackgroundCheckIndexErrorCodes = [
    401,
    422,
];

export type BackgroundCheckShowByIdErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BackgroundCheckShowByIdErrorCodes = [
];

export type BackgroundCheckShowLatestErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BackgroundCheckShowLatestErrorCodes = [
    401,
    422,
];

export type BackgroundCheckUpdateErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BackgroundCheckUpdateErrorCodes = [
    404,
];

export type BrokerageFirmBackgroundCheckIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BrokerageFirmBackgroundCheckIndexErrorCodes = [
    401,
    422,
];

export type BrokerageFirmBackgroundCheckStatisticShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BrokerageFirmBackgroundCheckStatisticShowErrorCodes = [
    401,
    422,
];


