import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { PercentageChange } from '@npm/core/ui/components/molecules/PercentageChange';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';

import { type MovingAverageValue } from '../MutualFundMarksChart.types';

import * as S from './MovingAverageTooltip.styles';

type Props = {
  value: MovingAverageValue;
};

export const MovingAverageTooltip = ({ value }: Props) => {
  return (
    <Flex direction={'column'} gap={'sm'}>
      <Flex align={'center'} justify={'space-between'}>
        <Text size={'sm'} weight={'bold'} colorVariant={'primary'}>
          90-Day Moving Avg.
        </Text>
        <Text size={'xs'} colorVariant={'secondary'}>
          {formatDate(value.date, {
            dateFormat: DATE_FORMATS.DATE,
          })}
        </Text>
      </Flex>

      <S.PriceWrapper>
        <Text size={'xs'} colorVariant={'secondary'}>
          Price Per Share
        </Text>
        <Flex align={'center'}>
          <Text.Price
            value={value.moving_average}
            currencyAsSymbol
            formatOptions={{
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          {value.discount_or_premium && (
            <PercentageChange
              change={value.discount_or_premium}
              textSize={'xs'}
              iconSize={'xs'}
            />
          )}
        </Flex>
        {value.implied_valuation && (
          <Flex align={'center'} gap={'xs'}>
            <Text size={'xxs'} colorVariant={'tertiary'}>
              Implied Val.
            </Text>
            <Text.Price
              size={'xxs'}
              value={value.implied_valuation}
              currencyAsSymbol
              compact
            />
          </Flex>
        )}
      </S.PriceWrapper>
    </Flex>
  );
};
