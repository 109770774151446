import { useTheme } from 'styled-components';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type CompanyDescription } from '@npm/core/ui/components/molecules/CompanyTag';
import { CompanyTagList } from '@npm/core/ui/components/molecules/CompanyTagList';

type Props = {
  watchlist: CompanyDescription[];
};

export const CompaniesTagsInvestor = ({ watchlist }: Props) => {
  const theme = useTheme();

  return (
    <>
      <Text
        marginBottom={theme.spacing.sm}
        color="general"
        size="sm"
        style={{ marginTop: theme.spacing.lg }}
      >
        We are going to import the following holdings in:
      </Text>
      <CompanyTagList companies={watchlist} showMax={3} />
    </>
  );
};
