import { Codebooks } from './codebooks.types';

const Items = {
  none: 'none',
  financial_institution: 'financial_institution',
  us_authority: 'us_authority',
  public_company: 'public_company',
  majority_owned: 'majority_owned',
  issuer_of_registered_securities: 'issuer_of_registered_securities',
  public_accounting_firm: 'public_accounting_firm',
  bank_holding_company: 'bank_holding_company',
  insurance_company: 'insurance_company',
  foreign_financial_institution: 'foreign_financial_institution',
  non_us_department: 'non_us_department',
  legal_entity_private_banking: 'legal_entity_private_banking',
  pooled_investment_vehicle: 'pooled_investment_vehicle',
};

export const CbBeneficialOwnerEntityExemption = {
  code: Codebooks.BENEFICIAL_OWNER_ENTITY_EXEMPTION,
  items: Items,
};
