import styled from 'styled-components';

import { centerFlex, useBreakpoint } from '../../../utils/style';
import { Text } from '../Typography';

export const Container = styled.div`
  .ant-upload {
    width: 100%;
    .ant-btn {
      width: 100%;
    }
  }
`;
export const FileName = styled(Text).attrs({
  size: 'sm',
  color: 'info',
})`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ErrorMsg = styled(Text).attrs({
  size: 'sm',
  color: 'error',
})`
  width: 100%;
`;

export const TrashButton = styled.button`
  ${centerFlex};
`;

export const BottomContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sm}px;
  display: flex;
  align-items: center;
`;

export const PaperClipWrapper = styled.div<{ $error: boolean }>`
  ${centerFlex};
  margin-right: ${({ theme }) => theme.spacing.sm}px;
  color: ${({ $error, theme }) =>
    $error
      ? theme.color.error.typography.primary
      : theme.color.general.typography.secondary};
`;
