type PathMetadata = typeof brokerageGeneralMetadata;

export const registerBrokerageGeneralMetadata = (metadata: PathMetadata) => {
  brokerageGeneralMetadata.forEach(m => {
    metadata.push(m);
  });
};

export const brokerageGeneralMetadata = [
  {
    pathRegex: /.*brokerage-workstation\/client-watchlist.*/,
    metadata: {
      screenId: 'NFE-4095',
      screenName: 'Client Watchlist',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/holdings.*/,
    metadata: {
      screenId: 'NFE-552',
      screenName: 'Accounts - Holdings',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/individuals\/.*tab=overview.*/,
    metadata: {
      screenId: 'NFE-537',
      screenName: 'Accounts - Individuals/Entities - Overview',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/individuals\/.*tab=dataRooms.*/,
    metadata: {
      screenId: 'NFE-542',
      screenName: 'Accounts - Individuals/Entities - Data Room',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/individuals\/.*tab=onboarding.*/,
    metadata: {
      screenId: 'NFE-544',
      screenName: 'Accounts - Individuals/Entities - Onboarding',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/entities\/.*tab=onboarding.*/,
    metadata: {
      screenId: 'NFE-544',
      screenName: 'Accounts - Individuals/Entities - Onboarding',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/entities\/.*tab=dataRooms.*/,
    metadata: {
      screenId: 'NFE-542',
      screenName: 'Accounts - Individuals/Entities - Data Room',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/entities\/.*tab=overview.*/,
    metadata: {
      screenId: 'NFE-537',
      screenName: 'Accounts - Individuals/Entities - Overview',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/entities\/.*tab=backgroundCheck.*/,
    metadata: {
      screenId: 'NFE-548',
      screenName: 'Accounts - Entity - Background Check',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/entities\/.*tab=watchlist.*/,
    metadata: {
      screenId: 'NFE-545',
      screenName: 'Accounts - Individuals/Entities - Watchlist',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/entities\/.*tab=accreditation.*/,
    metadata: {
      screenId: 'NFE-549',
      screenName: 'Accounts - Entities - Accreditation',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/individuals.*/,
    metadata: {
      screenId: 'NFE-536',
      screenName: 'Accounts - List of Individual Accounts',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/administrators.*/,
    metadata: {
      screenId: 'NFE-559',
      screenName: 'Onboarding - Administrators',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/entities.*/,
    metadata: {
      screenId: 'NFE-546',
      screenName: 'Accounts - List of Entity Accounts',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/persons\/\d\.*/,
    metadata: {
      screenId: 'NFE-551',
      screenName: 'Accounts - Users Detail',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/persons.*/,
    metadata: {
      screenId: 'NFE-550',
      screenName: 'Accounts - Users',
    },
  },
  {
    pathRegex: /.*brokerage-workstation\/matches.*/,
    metadata: {
      screenId: 'NFE-533',
      screenName: 'Matches',
    },
  },
];
