import { type ComponentProps, useCallback, useState } from 'react';

import { type Modal } from './Modal';

type Props = ComponentProps<typeof Modal>;

export const useModal = (
  onConfirm?: () => void
): [(props?: Props) => void, Props] => {
  const [open, setOpen] = useState(false);

  // use to open modal with props passed via openModal
  const [dynamicProps, setDynamicProps] = useState<Props | undefined>({
    children: '',
  });
  const onOk = useCallback(() => {
    onConfirm?.();
    setOpen(false);
  }, [onConfirm, setOpen]);

  const openModal = useCallback((props?: Props) => {
    setOpen(true);
    setDynamicProps(props);
  }, []);

  return [
    openModal,
    { open, onCancel: () => setOpen(false), onOk, ...dynamicProps },
  ];
};
