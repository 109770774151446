import { Codebooks } from './codebooks.types';

const Items = {
  event: 'event',
  holding: 'holding',
  match: 'match',
  agreement: 'agreement',
  nda: 'nda',
  negotiation: 'negotiation',
  notification: 'notification',
} as const;

export const CbActionCounterType = {
  code: Codebooks.ACTION_COUNTER_TYPE,
  items: Items,
};
