import { Codebooks } from './codebooks.types';

const Items = {
  Option: 'OPTION',
  RSU: 'RSU',
} as const;

export type CbCompensationTypeCode = typeof Items[keyof typeof Items];

export const CbCompensationType = {
  code: Codebooks.COMPENSATION_TYPE,
  items: Items,
};
