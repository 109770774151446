import { useEffect } from 'react';
import { type DefaultTheme } from 'styled-components';

import { usePageHeaderStore } from './PageHeader.store';

export const useCustomPageHeader = () => {
  const setShowDefault = usePageHeaderStore(store => store.setShowDefault);

  useEffect(() => {
    setShowDefault(false);

    return () => {
      setShowDefault(true);
    };
  }, []);
};

export const useCustomPageHeaderBackground = (
  bg: keyof DefaultTheme['color']['general']['layout']
) => {
  const setBg = usePageHeaderStore(store => store.setBackground);
  const resetBg = usePageHeaderStore(store => store.resetBackground);

  useEffect(() => {
    setBg(bg);
    return () => {
      resetBg();
    };
  }, []);
};

export const useCustomPageHeaderPadding = (
  padding: keyof DefaultTheme['spacing']
) => {
  const setPadding = usePageHeaderStore(store => store.setPadding);
  const resetPadding = usePageHeaderStore(store => store.resetPadding);

  useEffect(() => {
    setPadding(padding);
    return () => {
      resetPadding();
    };
  }, []);
};
