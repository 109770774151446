import { ZendeskAPI } from 'react-zendesk';

import { ticketGroupConfigMap } from './zendesk.config';
import {
  ZENDESK_FIELD_ACCOUNT_EXTERNAL_ID,
  ZENDESK_FIELD_ACCOUNT_NAME,
  ZENDESK_FIELD_COMPANY,
  ZENDESK_FIELD_EMAIL,
  ZENDESK_FIELD_PAGE_URL,
  ZENDESK_FIELD_PRICE_PER_SHARE,
  ZENDESK_FIELD_SIDE,
  ZENDESK_FIELD_SIZE_SHARES,
  ZENDESK_FIELD_SIZE_USD,
  ZENDESK_FIELD_WORKSTATION,
} from './zendesk.env';
import {
  type ZendeskTicketGroupID,
  ZendeskCustomFieldName,
} from './zendesk.types';

// These are custom fields we created on Zendesk to add extra info to user tickets
// the numbers are the field unique IDs of the corresponding custom field on our Zendesk account
export const customFieldNameToIdMapping: Record<
  ZendeskCustomFieldName,
  number
> = {
  [ZendeskCustomFieldName.Email]: ZENDESK_FIELD_EMAIL,
  [ZendeskCustomFieldName.Workstation]: ZENDESK_FIELD_WORKSTATION,
  [ZendeskCustomFieldName.PageUrl]: ZENDESK_FIELD_PAGE_URL,
  [ZendeskCustomFieldName.AccountName]: ZENDESK_FIELD_ACCOUNT_NAME,
  [ZendeskCustomFieldName.AccountExternalId]: ZENDESK_FIELD_ACCOUNT_EXTERNAL_ID,
  [ZendeskCustomFieldName.SizeShares]: ZENDESK_FIELD_SIZE_SHARES,
  [ZendeskCustomFieldName.Side]: ZENDESK_FIELD_SIDE,
  [ZendeskCustomFieldName.CompanyName]: ZENDESK_FIELD_COMPANY,
  [ZendeskCustomFieldName.SizeUsd]: ZENDESK_FIELD_SIZE_USD,
  [ZendeskCustomFieldName.PricePerShare]: ZENDESK_FIELD_PRICE_PER_SHARE,
};

export const setCustomField = (
  fieldName: ZendeskCustomFieldName,
  value: string
) => {
  ZendeskAPI('messenger:set', 'conversationFields', [
    { id: customFieldNameToIdMapping[fieldName], value },
  ]);
};

export const createZendeskMessengerHandler = (
  event: 'open' | 'close' | 'unreadMessages',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler: (...args: any) => void
) => {
  ZendeskAPI('messenger:on', event, handler);
  return () => {
    ZendeskAPI('messenger:off', event, handler);
  };
};

export const getCustomerRequestSubject = (
  zendeskTicketGroupId: ZendeskTicketGroupID
) => {
  if (zendeskTicketGroupId === 'page_error') {
    return 'Page Error Support Request';
  }
  if (zendeskTicketGroupId === 'api_request_error') {
    return 'API Error Support Request';
  }
  if (zendeskTicketGroupId === 'data_plan_inquiry') {
    return 'Data Plan Inquiry';
  }
  if (zendeskTicketGroupId === 'company_coverage_request') {
    return 'Company Coverage Request';
  }
  return 'Customer Support Request';
};

// Todo: Remove once Zendesk Proxy API becomes available
export const getEncodedCustomerRequestAuthToken = (
  customerEmail: string,
  jwt: string
) => {
  return btoa(`${customerEmail}/token:${jwt}`);
};

export const getTicketCustomFields = (
  email: string,
  pageUrl: string,
  workstation: string,
  account: { name?: string; external_id?: string },
  extraFields?: {
    [ZendeskCustomFieldName.SizeShares]?: number;
    [ZendeskCustomFieldName.Side]?: string;
    [ZendeskCustomFieldName.CompanyName]?: string;
    [ZendeskCustomFieldName.SizeUsd]?: number;
    [ZendeskCustomFieldName.PricePerShare]?: number;
  }
) => [
  {
    id: customFieldNameToIdMapping.Email,
    value: email,
  },
  {
    id: customFieldNameToIdMapping.PageUrl,
    value: pageUrl,
  },
  {
    id: customFieldNameToIdMapping.Workstation,
    value: workstation,
  },
  {
    id: customFieldNameToIdMapping.AccountName,
    value: account.name,
  },
  {
    id: customFieldNameToIdMapping.AccountExternalId,
    value: account.external_id,
  },
  ...(extraFields
    ? [
        extraFields[ZendeskCustomFieldName.SizeShares]
          ? {
              id: customFieldNameToIdMapping.SizeShares,
              value: extraFields[ZendeskCustomFieldName.SizeShares]?.toString(),
            }
          : null,
        extraFields[ZendeskCustomFieldName.Side]
          ? {
              id: customFieldNameToIdMapping.Side,
              value: extraFields[ZendeskCustomFieldName.Side],
            }
          : null,
        extraFields[ZendeskCustomFieldName.CompanyName]
          ? {
              id: customFieldNameToIdMapping.CompanyName,
              value: extraFields[ZendeskCustomFieldName.CompanyName],
            }
          : null,
        extraFields[ZendeskCustomFieldName.SizeUsd]
          ? {
              id: customFieldNameToIdMapping.SizeUsd,
              value: extraFields[ZendeskCustomFieldName.SizeUsd]?.toString(),
            }
          : null,
        extraFields[ZendeskCustomFieldName.PricePerShare]
          ? {
              id: customFieldNameToIdMapping.PricePerShare,
              value:
                extraFields[ZendeskCustomFieldName.PricePerShare]?.toString(),
            }
          : null,
      ].filter(Boolean)
    : []),
];

export const getTicketGroupConfig = (
  zendeskTicketGroupId: ZendeskTicketGroupID
) => {
  return ticketGroupConfigMap[zendeskTicketGroupId];
};

export const getPageNameFromRoutePath = (pathname: string) =>
  pathname.match(/[^/][a-z-]+(?=\/\d*$|$)/i)?.[0];

export const getParsedPageName = (pathname = '') => {
  try {
    if (!pathname.length) return pathname;
    const pageName = getPageNameFromRoutePath(pathname);
    if (!pageName?.length) return pathname;
    const capitalizedFirstLetter = pageName[0].toUpperCase();

    // replace dashes with space
    const pageNameWithoutDashes = pageName.replace(/-/g, ' ');
    return capitalizedFirstLetter + pageNameWithoutDashes.slice(1);
  } catch {
    return '';
  }
};

export const getTicketTags = (
  zendeskTicketGroupId: ZendeskTicketGroupID,
  pathname: string
) => {
  const tags: string[] = [];
  const pageName = getPageNameFromRoutePath(pathname);
  const pageNameAsTagSuffix = pageName
    ?.toLowerCase()
    .replace(/ /g, '_')
    .replace(/-/g, '_');
  const configTagSuffixes = getTicketGroupConfig(zendeskTicketGroupId).tags;

  const addTag = (suffix: string) => {
    tags.push(`npm_${suffix}`);
  };

  if (
    ['page_error', 'api_request_error'].includes(zendeskTicketGroupId) &&
    pageNameAsTagSuffix
  ) {
    // for page error tickets page name has
    // to be added here because it is dynamic
    addTag(pageNameAsTagSuffix);
  }

  configTagSuffixes.forEach(addTag);

  return tags;
};

export const ZENDESK_DESCRIPTION_MAX_LENGTH = 150;
