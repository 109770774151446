import React from 'react';

import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { Text } from '../../atoms/Typography';

import * as S from './TwoRowsCell.styles';

export const TwoRowsCell = ({
  firstRow,
  secondRowText,
  secondRowExtra,
}: {
  firstRow: React.ReactNode;
  secondRowText: string;
  secondRowExtra?: string;
}) => {
  const { isMobile } = useBreakpoints();
  return (
    <S.Container>
      {firstRow}
      {isMobile && (
        <Text as="span" size={'sm'} colorVariant="secondary">
          &nbsp;•&nbsp;
        </Text>
      )}
      <Text size={isMobile ? 'sm' : 'xs'} colorVariant="primary">
        {secondRowText}
        {secondRowExtra && (
          <Text
            as="span"
            size={isMobile ? 'sm' : 'xs'}
            colorVariant="secondary"
          >
            &nbsp;•&nbsp;{secondRowExtra}
          </Text>
        )}
      </Text>
    </S.Container>
  );
};
