import { Flex } from '../../../../atoms/common';
import { ActionItemsCount } from '../ActionItemsCount';

type Props = {
  label: React.ReactNode;
  count: number | undefined;
};

export const LabelWithActionItemsCount = ({ label, count }: Props) =>
  count ? (
    <Flex justify="space-between" align="center">
      {label}
      <ActionItemsCount count={count} />
    </Flex>
  ) : (
    <>{label}</>
  );
