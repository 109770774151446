import { pick } from 'lodash';

import { type RepresentativeAggregate, type User } from '@npm/data-access';

export const getRepresentativeObjectFromUser: (
  user?: User
) => RepresentativeAggregate = user => {
  return {
    user_id: user?.id,
    person_id: user?.person?.id,
    ...pick(user?.person, [
      'first',
      'middle',
      'last',
      'email',
      'last_login_at',
    ]),
  };
};
