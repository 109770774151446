import { useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useWizard } from 'react-use-wizard';

import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import {
  type WizardStore,
  WizardStoreActionTypes,
} from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import {
  type AccountOnboardingStatusShowErrorTypes,
  type AccountShowAggregate,
  type AccountShowErrorTypes,
  type Workstation,
  CbAccountType,
  CbWorkstationType,
  getApiErrorCode,
  useAccountOnboardingStatusShow,
  useAccountShow,
} from '@npm/data-access';

import { useCurrentWorkstation, useObo } from '../../auth/user/role';
import { getEntityOnboardingSections } from '../EntityOnboarding.config';
import { getIndividualOnboardingSections } from '../IndividualOnboarding.config';
import {
  type OnboardingSectionConfig,
  type PostOnboardingContext,
} from '../PostOnboarding.types';
import {
  findNextIncompleteOnboardingStep,
  getFlattenedOnboardingWizardSteps,
} from '../PostOnboarding.utils';

import { useCompletedOnboardingSectionModals } from './useCompletedOnboardingSectionModals';
import { useGetPostOnboardingPersonId } from './useGetPostOnboardingPersonId';

// include `user_id` in the OnboardingStatusShow request
// only in OBO mode or in BRO's individual account onboarding section (entity onboarding can include multiple reps)
const getUserIdForOnboardingStatus = (
  workstation: Workstation | undefined,
  oboUserId: number | null,
  account: AccountShowAggregate | undefined
) => {
  let userId = null;

  if (oboUserId) {
    userId = oboUserId;
  } else if (
    account?.type?.code === CbAccountType.items.PersonAccount &&
    workstation?.type?.code === CbWorkstationType.items.brokerage
  ) {
    userId = account.user?.id;
  }

  return userId;
};

export const usePostOnboardingContext = (accountId: number) => {
  const [isContextLoading, setIsContextLoading] = useState(true);
  const { activeStep } = useWizard();
  const { dispatch, steps } = useWizardStore(
    (s: WizardStore<PostOnboardingContext>) => ({
      dispatch: s.dispatch,
      steps: s.steps,
    })
  );
  const workstation = useCurrentWorkstation();
  const history = useHistory();
  const handleError = useErrorHandler();
  const getPersonId = useGetPostOnboardingPersonId();
  const { oboUserId } = useObo();
  const [sectionsConfig, setSectionsConfig] = useState<
    OnboardingSectionConfig[]
  >([]);
  const [firstIncompleteStep, setFirstIncompleteStep] = useState<number>(null);

  const { handleOnboardingStatusUpdate, renderModals } =
    useCompletedOnboardingSectionModals();

  const onError = (
    err: AccountOnboardingStatusShowErrorTypes | AccountShowErrorTypes
  ) => {
    if (getApiErrorCode(err) === 404) {
      history.replace('/404');
    } else {
      handleError(err);
    }
  };

  const { data: account } = useAccountShow(
    {
      id: accountId?.toString(),
    },
    {
      onComplete: data => {
        dispatch({
          type: WizardStoreActionTypes.UPDATE_DATA,
          payload: {
            account: data,
          },
        });
      },
      onError,
      queryConfig: {
        enabled: !!accountId,
      },
    }
  );

  useAccountOnboardingStatusShow(
    {
      id: accountId,
      userId: getUserIdForOnboardingStatus(workstation, oboUserId, account),
    },
    {
      onComplete: data => {
        const isIndividual =
          account?.type?.code === CbAccountType.items.PersonAccount;

        const onboardingSectionsConfig = sectionsConfig.length
          ? sectionsConfig
          : isIndividual
          ? getIndividualOnboardingSections({
              personId: getPersonId(account),
              onboardingStatus: data,
            })
          : getEntityOnboardingSections({
              personId: getPersonId(account),
              onboardingStatus: data,
            });

        dispatch({
          type: WizardStoreActionTypes.UPDATE_DATA,
          payload: { onboardingStatus: data },
        });

        if (!sectionsConfig.length) {
          const steps = getFlattenedOnboardingWizardSteps(
            onboardingSectionsConfig
          );

          dispatch({
            type: WizardStoreActionTypes.UPDATE_STEPS,
            payload: steps,
          });

          setSectionsConfig(onboardingSectionsConfig);

          const firstIncompleteStepIndex = findNextIncompleteOnboardingStep({
            steps,
            onboardingStatus: data,
            startIndex: -1,
          });

          setFirstIncompleteStep(firstIncompleteStepIndex);
        }

        setIsContextLoading(false);
        handleOnboardingStatusUpdate(onboardingSectionsConfig, data);
      },
      onError,
      queryConfig: {
        enabled: !!account,
      },
    }
  );

  useEffect(() => {
    if ((steps && sectionsConfig.length === 0) || activeStep === undefined) {
      return;
    }

    const activeStepKey = steps[activeStep || 0].key;
    const { activeSection, isSubstep, substepsTotal, substepIndex } =
      sectionsConfig.reduce(
        (acc, section) => {
          const foundItem = section.items.find(({ key, substeps }) => {
            const substep = substeps?.find(({ key }) => key === activeStepKey);
            return key === activeStepKey || substep;
          });

          if (foundItem) {
            acc.activeSection = section;
            acc.isSubstep = !!foundItem.substeps;
            acc.substepsTotal = foundItem.substeps
              ? foundItem.substeps.length
              : 0;
            if (acc.isSubstep) {
              acc.substepIndex = foundItem.substeps.findIndex(
                ({ key }) => key === activeStepKey
              );
            }
            return acc;
          }
          return acc;
        },
        {
          activeSection: null,
          isSubstep: false,
          substepsTotal: 0,
          substepIndex: 0,
        }
      );

    const flattenedSectionSteps = getFlattenedOnboardingWizardSteps([
      activeSection,
    ]);

    dispatch({
      type: WizardStoreActionTypes.UPDATE_DATA,
      payload: {
        activeSectionProps: {
          activeSection,
          stepIndex:
            flattenedSectionSteps.findIndex(
              step => step.key === activeStepKey
            ) + 1,
          totalSteps: flattenedSectionSteps.length,
          isSubstep,
          totalStepSubsteps: substepsTotal,
          substepIndex,
        },
      },
    });
  }, [activeStep, steps, sectionsConfig]);

  return {
    isContextLoading,
    sectionsConfig,
    successModals: renderModals(account?.id),
    firstIncompleteStep,
  };
};
