/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  UnitIssuancesApi,
  ListUnitIssuancesErrorCodes,
  ListUnitIssuancesErrorTypes,
  GetUnitIssuanceErrorCodes,
  GetUnitIssuanceErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new UnitIssuancesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type ListUnitIssuancesMethod = UnitIssuancesApi['listUnitIssuances'];
export type ListUnitIssuancesResponseType = MethodResult<ListUnitIssuancesMethod>;

export const useListUnitIssuances = (
  variables: Parameters<ListUnitIssuancesMethod>[0],
  config?: UseQueryConfig<
    ListUnitIssuancesMethod,
    ListUnitIssuancesErrorTypes
  >
) => {
  return useQuery<
    ListUnitIssuancesMethod,
    ListUnitIssuancesErrorTypes
  >(
    (...args) => api.listUnitIssuances(...args),
    ListUnitIssuancesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListUnitIssuances'],
        ...apiQueryConfig.ListUnitIssuances,
        ...config?.queryConfig
      },
    },
    'ListUnitIssuances',
  );
};

export const useListUnitIssuancesInfinite = (
  variables: Parameters<ListUnitIssuancesMethod>[0],
  config?: UseInfiniteQueryConfig<
    ListUnitIssuancesMethod,
    ListUnitIssuancesErrorTypes
  >
) => {
  return useInfiniteQuery<
    ListUnitIssuancesMethod,
    ListUnitIssuancesErrorTypes
  >(
    (...args) => api.listUnitIssuances(...args),
    ListUnitIssuancesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListUnitIssuances', 'ListUnitIssuancesInfinite'],
        ...apiQueryConfig.ListUnitIssuances,
        ...config?.queryConfig,
      },
    },
    'ListUnitIssuances',
  );
};

export const useListUnitIssuancesLazy = (baseOptions?: {
  variables?: Parameters<ListUnitIssuancesMethod>[0],
  config?: UseQueryConfig<
    ListUnitIssuancesMethod,
    ListUnitIssuancesErrorTypes
  >
}) => {
  return useLazyQuery<
    ListUnitIssuancesMethod,
    ListUnitIssuancesErrorTypes
  >(
    (...args) => api.listUnitIssuances(...args),
    ListUnitIssuancesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['ListUnitIssuances'],
        ...apiQueryConfig.ListUnitIssuances,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'ListUnitIssuances',
  );
};


export type GetUnitIssuanceMethod = UnitIssuancesApi['getUnitIssuance'];
export type GetUnitIssuanceResponseType = MethodResult<GetUnitIssuanceMethod>;

export const useGetUnitIssuance = (
  variables: Parameters<GetUnitIssuanceMethod>[0],
  config?: UseQueryConfig<
    GetUnitIssuanceMethod,
    GetUnitIssuanceErrorTypes
  >
) => {
  return useQuery<
    GetUnitIssuanceMethod,
    GetUnitIssuanceErrorTypes
  >(
    (...args) => api.getUnitIssuance(...args),
    GetUnitIssuanceErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetUnitIssuance'],
        ...apiQueryConfig.GetUnitIssuance,
        ...config?.queryConfig
      },
    },
    'GetUnitIssuance',
  );
};

export const useGetUnitIssuanceInfinite = (
  variables: Parameters<GetUnitIssuanceMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetUnitIssuanceMethod,
    GetUnitIssuanceErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetUnitIssuanceMethod,
    GetUnitIssuanceErrorTypes
  >(
    (...args) => api.getUnitIssuance(...args),
    GetUnitIssuanceErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetUnitIssuance', 'GetUnitIssuanceInfinite'],
        ...apiQueryConfig.GetUnitIssuance,
        ...config?.queryConfig,
      },
    },
    'GetUnitIssuance',
  );
};

export const useGetUnitIssuanceLazy = (baseOptions?: {
  variables?: Parameters<GetUnitIssuanceMethod>[0],
  config?: UseQueryConfig<
    GetUnitIssuanceMethod,
    GetUnitIssuanceErrorTypes
  >
}) => {
  return useLazyQuery<
    GetUnitIssuanceMethod,
    GetUnitIssuanceErrorTypes
  >(
    (...args) => api.getUnitIssuance(...args),
    GetUnitIssuanceErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetUnitIssuance'],
        ...apiQueryConfig.GetUnitIssuance,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetUnitIssuance',
  );
};

