import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { PercentageChange } from '@npm/core/ui/components/molecules/PercentageChange';
import {
  DATE_FORMATS,
  formatDate,
  shorten,
} from '@npm/core/ui/utils/formatters';

import { type MtmValue } from '../MutualFundMarksChart.types';

import { getMtmTooltipContentValues } from './MtmTooltip.utils';

import * as S from './MtmTooltip.styles';

type Props = {
  values: MtmValue[] | undefined;
};

export const MtmTooltip = ({ values }: Props) => {
  if (!values?.length) {
    return null;
  }

  const {
    isCommon,
    reportingPeriod,
    pps,
    impliedValuation,
    discountOrPremium,
    assetTypeName,
    parentName,
    seriesName,
  } = getMtmTooltipContentValues(values);

  return (
    <Flex direction={'column'} gap={'sm'}>
      <Flex align={'center'} justify={'space-between'}>
        <Text
          size={'sm'}
          weight={'bold'}
          color={isCommon ? 'info' : 'success'}
          colorVariant={'primary'}
        >
          {assetTypeName}
        </Text>
        <Text size={'xs'} colorVariant={'secondary'}>
          {formatDate(reportingPeriod, {
            dateFormat: DATE_FORMATS.DATE,
          })}
        </Text>
      </Flex>

      <S.PriceWrapper>
        <Text size={'xs'} colorVariant={'secondary'}>
          Price Per Share
        </Text>
        <Flex align={'center'}>
          <Text.Price
            value={pps}
            currencyAsSymbol
            formatOptions={{
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          {!!discountOrPremium && (
            <PercentageChange
              change={discountOrPremium}
              textSize={'xs'}
              iconSize={'xs'}
            />
          )}
        </Flex>
        {impliedValuation && (
          <Flex align={'center'} gap={'xs'}>
            <Text size={'xxs'} colorVariant={'tertiary'}>
              Implied Val.
            </Text>
            <Text.Price
              size={'xxs'}
              value={impliedValuation}
              currencyAsSymbol
              compact
            />
          </Flex>
        )}
      </S.PriceWrapper>

      <S.Footer>
        <Flex direction={'column'}>
          <Text size={'xs'} colorVariant={'tertiary'}>
            Parent Entity
          </Text>
          <Text size={'xs'} colorVariant={'primary'}>
            {shorten(parentName, 20)}
          </Text>
        </Flex>

        <Flex direction={'column'}>
          <Text size={'xs'} colorVariant={'tertiary'}>
            Mutual Fund
          </Text>
          <Text size={'xs'} colorVariant={'primary'}>
            {shorten(seriesName, 20)}
          </Text>
        </Flex>
      </S.Footer>
    </Flex>
  );
};
