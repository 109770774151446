import { type FormInstance } from 'antd';
import { omitBy } from 'lodash';
import { parse, stringify } from 'query-string';

import { type FilterQueryKey, type FiltersObject } from './FilterPanel.types';

// @TODO: Remove as it's unused
export const getTouchedFieldsKeys = <T>(
  form: FormInstance<T>,
  initialValues: T
) => {
  const currentValues = form.getFieldsValue();
  return Object.keys(currentValues).filter(key => {
    // for Switches (undefined on mount but false after on/off)
    if (initialValues?.[key] === undefined && currentValues[key] === false)
      return false;
    return initialValues?.[key] !== currentValues[key];
  });
};

export const clearEmptyValues = (obj: FiltersObject) => {
  return omitBy(obj, value => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }

    return value === undefined || value === null || value === '';
  });
};

// @TODO: Remove as it's unused
export const getActiveFiltersCount = (obj: FiltersObject) => {
  return Object.values(clearEmptyValues(obj)).length;
};

export const encodeFilters = <T extends FiltersObject>(obj: Partial<T>) => {
  const filteredObj = clearEmptyValues(obj);

  if (Object.keys(filteredObj).length === 0) return undefined;

  return stringify(filteredObj, { arrayFormat: 'bracket' });
};

export const decodeFilters = <T extends FiltersObject>(
  queryString: string
): Partial<T> => {
  return parse(queryString, {
    parseBooleans: true,
    parseNumbers: true,
    arrayFormat: 'bracket',
  }) as Partial<T>;
};

export const getFiltersQueryString = <T extends FiltersObject>(
  key: FilterQueryKey,
  obj: Partial<T>
) => {
  const encodedFilters = encodeFilters(obj);
  return encodedFilters ? `${key}=${encodedFilters}` : '';
};
