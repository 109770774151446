/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  HoldingApi,
  HoldingIndexErrorCodes,
  HoldingIndexErrorTypes,
  HoldingShowErrorCodes,
  HoldingShowErrorTypes,
  HoldingUpdateErrorCodes,
  HoldingDestroyErrorCodes,
  HoldingStatusUpdateErrorCodes,
  IssuerEntityHoldingCreateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new HoldingApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type HoldingIndexMethod = HoldingApi['holdingIndex'];
export type HoldingIndexResponseType = MethodResult<HoldingIndexMethod>;

export const useHoldingIndex = (
  variables: Parameters<HoldingIndexMethod>[0],
  config?: UseQueryConfig<
    HoldingIndexMethod,
    HoldingIndexErrorTypes
  >
) => {
  return useQuery<
    HoldingIndexMethod,
    HoldingIndexErrorTypes
  >(
    (...args) => api.holdingIndex(...args),
    HoldingIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['HoldingIndex'],
        ...apiQueryConfig.HoldingIndex,
        ...config?.queryConfig
      },
    },
    'HoldingIndex',
  );
};

export const useHoldingIndexInfinite = (
  variables: Parameters<HoldingIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    HoldingIndexMethod,
    HoldingIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    HoldingIndexMethod,
    HoldingIndexErrorTypes
  >(
    (...args) => api.holdingIndex(...args),
    HoldingIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['HoldingIndex', 'HoldingIndexInfinite'],
        ...apiQueryConfig.HoldingIndex,
        ...config?.queryConfig,
      },
    },
    'HoldingIndex',
  );
};

export const useHoldingIndexLazy = (baseOptions?: {
  variables?: Parameters<HoldingIndexMethod>[0],
  config?: UseQueryConfig<
    HoldingIndexMethod,
    HoldingIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    HoldingIndexMethod,
    HoldingIndexErrorTypes
  >(
    (...args) => api.holdingIndex(...args),
    HoldingIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['HoldingIndex'],
        ...apiQueryConfig.HoldingIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'HoldingIndex',
  );
};


export type HoldingShowMethod = HoldingApi['holdingShow'];
export type HoldingShowResponseType = MethodResult<HoldingShowMethod>;

export const useHoldingShow = (
  variables: Parameters<HoldingShowMethod>[0],
  config?: UseQueryConfig<
    HoldingShowMethod,
    HoldingShowErrorTypes
  >
) => {
  return useQuery<
    HoldingShowMethod,
    HoldingShowErrorTypes
  >(
    (...args) => api.holdingShow(...args),
    HoldingShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['HoldingShow'],
        ...apiQueryConfig.HoldingShow,
        ...config?.queryConfig
      },
    },
    'HoldingShow',
  );
};

export const useHoldingShowInfinite = (
  variables: Parameters<HoldingShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    HoldingShowMethod,
    HoldingShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    HoldingShowMethod,
    HoldingShowErrorTypes
  >(
    (...args) => api.holdingShow(...args),
    HoldingShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['HoldingShow', 'HoldingShowInfinite'],
        ...apiQueryConfig.HoldingShow,
        ...config?.queryConfig,
      },
    },
    'HoldingShow',
  );
};

export const useHoldingShowLazy = (baseOptions?: {
  variables?: Parameters<HoldingShowMethod>[0],
  config?: UseQueryConfig<
    HoldingShowMethod,
    HoldingShowErrorTypes
  >
}) => {
  return useLazyQuery<
    HoldingShowMethod,
    HoldingShowErrorTypes
  >(
    (...args) => api.holdingShow(...args),
    HoldingShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['HoldingShow'],
        ...apiQueryConfig.HoldingShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'HoldingShow',
  );
};


export type HoldingUpdateMethod = HoldingApi['holdingUpdate'];

export const useHoldingUpdate = () => {
  return useMutation<HoldingUpdateMethod>(
    (...args) => api.holdingUpdate(...args),
    HoldingUpdateErrorCodes,
    'HoldingUpdate',
    '/api/holdings/{id}',
    'put'
  );
};

export type HoldingDestroyMethod = HoldingApi['holdingDestroy'];

export const useHoldingDestroy = () => {
  return useMutation<HoldingDestroyMethod>(
    (...args) => api.holdingDestroy(...args),
    HoldingDestroyErrorCodes,
    'HoldingDestroy',
    '/api/holdings/{id}',
    'delete'
  );
};

export type HoldingStatusUpdateMethod = HoldingApi['holdingStatusUpdate'];

export const useHoldingStatusUpdate = () => {
  return useMutation<HoldingStatusUpdateMethod>(
    (...args) => api.holdingStatusUpdate(...args),
    HoldingStatusUpdateErrorCodes,
    'HoldingStatusUpdate',
    '/api/holdings/{id}/status',
    'put'
  );
};

export type IssuerEntityHoldingCreateMethod = HoldingApi['issuerEntityHoldingCreate'];

export const useIssuerEntityHoldingCreate = () => {
  return useMutation<IssuerEntityHoldingCreateMethod>(
    (...args) => api.issuerEntityHoldingCreate(...args),
    IssuerEntityHoldingCreateErrorCodes,
    'IssuerEntityHoldingCreate',
    '/api/issuer-entities/{id}/holdings',
    'post'
  );
};
