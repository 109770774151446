import { useForm } from 'antd/lib/form/Form';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Checkbox } from '@npm/core/ui/components/atoms/Checkbox';
import { VALIDATION_RULES } from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { CypressDataIds } from '@npm/core/ui/constants';
import { handleValidationError } from '@npm/core/ui/utils/form';
import {
  DATE_FORMATS,
  getFullName,
  parseDateToString,
} from '@npm/core/ui/utils/formatters';
import { type Person } from '@npm/data-access';

import {
  ConfirmationSection,
  Form,
  InputFormItem,
  ModalFooter,
} from './AcknowledgementModal.styles';

export type Values = {
  legalConfirmationA: boolean;
  legalConfirmationB: boolean;
  signature: string;
};

type Props = {
  isOpen: boolean;
  onSubmit: (options: { signature: string; onComplete: () => void }) => void;
  onCancel: () => void;
  isLoading: boolean;
  person: Person;
};

export const AcknowledgementModal = ({
  isOpen,
  onSubmit,
  onCancel,
  isLoading,
  person,
}: Props) => {
  const [form] = useForm<Values>();

  const handleSubmit = async () => {
    let values: Values;

    try {
      values = await form.validateFields();
    } catch (err) {
      handleValidationError(form, err);
      return;
    }

    onSubmit({
      signature: values.signature,
      onComplete: () => {
        form.resetFields();
      },
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const fullLegalName = getFullName(person.first, person.last, person.middle);

  return (
    <Modal
      title="15c2-11 Acknowledgement and Representation"
      data-cy="OrderPlacement-AcknowledementModal"
      open={isOpen}
      onCancel={handleCancel}
      destroyOnClose={true}
      size="md"
      footer={
        <ModalFooter>
          <Button
            onClick={handleSubmit}
            loading={isLoading}
            blockOnMobile
            data-cy={
              CypressDataIds.OrderEntryDrawer.AcknowledgementModal.Submit
            }
          >
            Submit
          </Button>
          <Button
            variant="text"
            onClick={handleCancel}
            disabled={isLoading}
            blockOnMobile
          >
            Cancel
          </Button>
        </ModalFooter>
      }
    >
      <Text size="sm" marginBottom="lg">
        SEC Rule 15c2-11 requires that, before a broker or dealer publishes any
        quote for a security in any quotation medium, it must gather, review,
        and retain certain information regarding an issuer, unless an exception
        exists. One such exception to the rule is the “Unsolicited Customer
        Order” exception. An Unsolicited Customer Order is defined as an
        Exclusive IOI or Firm Order that is submitted (i) solely on behalf of a
        customer that is not a broker or dealer, (ii) represents the customer’s
        indication of interest; and (iii) does not involve the solicitation of
        the customer’s interest. In addition, to satisfy the Unsolicited
        Customer Order exception the submission of the [IOI/order] is not,
        directly or indirectly, on behalf of a customer that is a company
        insider (as defined in 17 CFR § 240.15c2-11(e)(1)) or affiliate (as
        defined in 17 CFR § 230.144(a)(1)).
      </Text>

      <Form form={form}>
        <ConfirmationSection>
          <InputFormItem
            data-cy={
              CypressDataIds.OrderEntryDrawer.AcknowledgementModal
                .LegalConfirmationA
            }
            name="legalConfirmationA"
            valuePropName="checked"
            rules={[
              VALIDATION_RULES.matchSpecificValue(
                true,
                'You have to confirm the legal obligation'
              ),
            ]}
          >
            <Checkbox label="I represent that the submission of this [IOI/order] is submitted (i) solely on behalf of a customer that is not a broker or dealer, (ii) represents the customer’s indication of interest; and (iii) does not involve the solicitation of the customer’s interest." />
          </InputFormItem>

          <InputFormItem
            data-cy={
              CypressDataIds.OrderEntryDrawer.AcknowledgementModal
                .LegalConfirmationB
            }
            name="legalConfirmationB"
            valuePropName="checked"
            rules={[
              VALIDATION_RULES.matchSpecificValue(
                true,
                'You have to confirm the legal obligation'
              ),
            ]}
          >
            <Checkbox label="I represent that the submission of this IOI/order is not, directly or indirectly, on behalf of a customer that is a company insider or affiliate." />
          </InputFormItem>
        </ConfirmationSection>

        <InputFormItem
          validateTrigger="onBlur"
          validateFirst
          name="signature"
          label="Signature"
          rules={[
            VALIDATION_RULES.matchSpecificValue(
              fullLegalName,
              `Signature must match your full legal name "${fullLegalName}"`
            ),
          ]}
          extra={`Your full legal name "${fullLegalName}"`}
        >
          <Input
            placeholder="Your full legal name"
            data-cy={
              CypressDataIds.OrderEntryDrawer.AcknowledgementModal.Signature
            }
          />
        </InputFormItem>
      </Form>

      <Text size="sm">
        Dated {parseDateToString(new Date(), DATE_FORMATS.FULL)}
      </Text>
    </Modal>
  );
};
