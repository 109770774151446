import { Codebooks } from './codebooks.types';

const items = {
  SHARES: 'SHARES',
  USD: 'USD',
} as const;

export type OrderItemUnitCode = keyof typeof items;

export const CbOrderItemUnit = {
  code: Codebooks.ORDER_ITEM_UNIT,
  items,
};
