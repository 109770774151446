import styled from 'styled-components';

import { Divider as BaseDivider } from '@npm/core/ui/components/atoms/Divider';
import { Tabs } from '@npm/core/ui/components/atoms/Tabs';
import { useBreakpoint } from '@npm/core/ui/utils/style';

export const TabsContainer = styled.div`
  display: flex;
  height: 100%;
  max-width: 100%;

  ${({ theme }) => useBreakpoint(theme).smDesktop`
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
  `}
`;

export const Divider = styled(BaseDivider)`
  height: 32px;
  transform: translateY(50%);
  margin: 0 ${({ theme }) => theme.spacing.md}px;
`;

const badgeCommonStyles = `
  color: inherit;
  border-color: inherit;
`;

export const TotalRecordsBadge = styled(Tabs.TotalRecordsBadge)`
  ${badgeCommonStyles}
`;

export const TabsBadge = styled(Tabs.Badge)`
  ${badgeCommonStyles}
`;
