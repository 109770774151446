import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTheme } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { hasOnlySpecificProperties } from '@npm/core/ui/components/atoms/Form';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  type useConfirmOnLeave,
  useHistory,
} from '@npm/core/ui/components/molecules/Link';
import { DiscardModal } from '@npm/core/ui/components/organisms/DiscardModal';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { CypressDataIds } from '@npm/core/ui/constants';
import { handleValidationError } from '@npm/core/ui/utils/form';
import {
  type AffiliateDisclosureType,
  type AttestationRuleType,
  type Holding,
  type OrderSourceCode,
  CbATSPool,
  CbAttestationRule,
  CbOrderSource,
  CbVisibility,
  Codebooks,
  useAttestationShow,
  useCodebook,
  useSecondmarketSubmissionCreate,
} from '@npm/data-access';

import { useUserContextStore } from '../../../../auth/user/context';
import { YourRoleDrawerSection } from '../../../../auth/user/context/YourRoleDrawerSection';
import { useCurrentWorkstation } from '../../../../auth/user/role';
import { type OboDefinition } from '../../../../auth/user/role/userRole.types';
import { useHoldingSelectData } from '../../../../filters';
import { HoldingDrawer, useHoldingDrawer } from '../../../../holdings';
import {
  useAllowedAssetTypeOptions,
  useOrderSizeToggle,
} from '../../../../order';
import { buildClientOrderDetailUrl } from '../../../utils/routes';
import { AcknowledgementModal } from '../../components/AcknowledgementModal';
import { AffiliateDisclosureWithSignatureModal } from '../../components/AffiliateDisclosureWithSignatureModal';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { validateDateFields } from '../../components/inputs';
import { getMinimumNotionalValueErrorMessage } from '../../utils/minNotionalValue';
import { isAccountGuestRole, isAccountNotAccredited } from '../../utils/order';

import { OrderPlacementConfirmation } from './OrderPlacementConfirmation';
import type { FormValues, Step } from './OrderPlacementDrawer.types';
import { validateMinimumNotionalValue } from './OrderPlacementDrawer.utils';
import {
  getSubmitPayload,
  useAccount,
  useDrawerStorage,
  useIssuerEntity,
} from './OrderPlacementDrawer.utils';
import { OrderPlacementForm } from './OrderPlacementForm';

import * as S from './OrderPlacementDrawer.styles';
import {
  DrawerFooter,
  DrawerTitleContainer,
} from './OrderPlacementDrawer.styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  confirmOnLeaveProps: ReturnType<typeof useConfirmOnLeave>;
  isInvestor: boolean;
  onAccountSwitch?: () => void;
  onSuccess?: () => void;
  source?: OrderSourceCode;
  initialValues?: {
    buySell?: 'buy' | 'sell';
    issuerEntityId?: number;
  };
  disabledFields?: React.ComponentProps<
    typeof OrderPlacementForm
  >['disabledFields'];
  obo: OboDefinition | null;
  setOboOverride?: React.Dispatch<React.SetStateAction<OboDefinition>>;
};

export const OrderPlacementDrawer = ({
  isOpen,
  onClose,
  source,
  initialValues,
  disabledFields,
  onAccountSwitch,
  isInvestor,
  obo,
  setOboOverride,
  confirmOnLeaveProps: {
    onCloseAttempt,
    setHasUnsavedChanges,
    discardModalProps,
  },
}: Props) => {
  const history = useHistory();
  const theme = useTheme();
  const { sizeType, updateSizeType, toggleSizeType } = useOrderSizeToggle(
    initialValues?.buySell === 'sell' ? 'Shares' : 'USD'
  );
  const redoActionRef = useRef(false);
  const [selectedHolding, setSelectedHolding] = useState<Holding>();
  const [signature, setSignature] = useState<string>(null);

  const [form] = useForm<FormValues>();
  const [previewValues, setPreviewValues] = useState<FormValues>(); // Available only after the form is validated

  // Specify explicitly because there are many drawers & modals in this component
  const alertContainerId = `order-placement-drawer-${source ?? 'new'}`;
  const { withShowApiErrorMiddleware } = useAlerts();

  const activeAction =
    Form.useWatch('buySell', form) ??
    previewValues?.buySell ??
    initialValues?.buySell;

  const {
    preselectedIssuerEntityId,
    issuerEntityId,
    issuerEntity: selectedIssuerEntity,
    isLoading: isSelectedIssuerEntityLoading,
  } = useIssuerEntity({ form, previewValues, isOpen });

  const {
    accountId,
    account: selectedAccount,
    isLoading: isAccountLoading,
  } = useAccount({ form, previewValues, obo });

  useEffect(() => {
    form.resetFields(['holdingId']);
    setSelectedHolding(undefined);
  }, [form, activeAction, issuerEntityId, accountId]);

  const {
    getFormData,
    getFormSizeType,
    setFormData,
    setFormSizeType,
    clearSavedData,
  } = useDrawerStorage();

  useEffect(() => {
    if (!isOpen) return;
    const savedFormData = getFormData();
    if (savedFormData) {
      form.setFieldsValue(savedFormData);
      updateSizeType(getFormSizeType());
      clearSavedData();
    } else if (initialValues) {
      form.setFieldsValue(initialValues);
      if (initialValues.buySell) {
        updateSizeType(initialValues.buySell === 'sell' ? 'Shares' : 'USD');
      }
    } else {
      form.setFieldValue('buySell', 'buy');
    }
  }, [isOpen]);

  const [activeStep, setActiveStep] = useState<Step>('form');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleStateReset = (
    preserveAccount?: boolean,
    preserveIssuerEntity?: boolean
  ) => {
    form.resetFields();

    if (preserveAccount) {
      form.setFieldValue('accountId', previewValues?.accountId);
    } else {
      setOboOverride?.(null);
    }

    if (preserveIssuerEntity) {
      form.setFieldValue('issuerEntityId', previewValues?.issuerEntityId);
    }

    setHasUnsavedChanges(false);
    setSignature(null);
    clearSavedData();
    form.setFieldsValue(initialValues);
    setPreviewValues(undefined);
    setActiveStep('form');
    setSelectedHolding(undefined);
    redoActionRef.current = false;
  };

  const workstation = useCurrentWorkstation();
  const user = useUserContextStore(store => store.user);
  const isSponsoredInvestor = useUserContextStore(
    store => !!store.sponsorshipType
  );

  const handlePreview = async () => {
    try {
      const formValues = await form.validateFields();
      if (validateDateFields(formValues).length) return;
      setPreviewValues(formValues);
      setActiveStep('confirmation');
    } catch (err) {
      handleValidationError(form, err);
    }
  };

  const hasValidMinimumNotionalValue = useMemo(() => {
    if (activeStep !== 'confirmation') return undefined;
    return validateMinimumNotionalValue({
      sizeType,
      size: previewValues?.quantity,
      minimumSize: previewValues?.minimumQuantity,
      pricePerShare: previewValues?.pricePerShare,
    });
  }, [previewValues, activeStep, sizeType]);

  const handleSubmit = async () => {
    if (!hasValidMinimumNotionalValue) return;

    if (
      !!obo &&
      previewValues?.visibility === CbVisibility.items.external &&
      previewValues?.atsPool === CbATSPool.items.ats_sm &&
      source !== CbOrderSource.items.historical &&
      !signature
    ) {
      setActiveStep('acknowledgement');
    } else if (isInvestor) {
      setActiveStep('affiliateDisclosure');
    } else {
      handlePlaceOrder();
    }
  };

  const { execute: createSubmission, isLoading: isCreateSubmissionLoading } =
    useSecondmarketSubmissionCreate();

  const handlePlaceOrder = async (options?: {
    onComplete?: () => void;
    signature?: string;
    affiliateDisclosure?: AffiliateDisclosureType;
  }) => {
    if (activeStep === 'acknowledgement') {
      setActiveStep('confirmation');
      setSignature(options?.signature);
    }

    const secondmarketSubmissionCreateRequestContract = getSubmitPayload(
      previewValues,
      !!obo,
      sizeType,
      source,
      options?.signature ?? signature,
      selectedHolding,
      options?.affiliateDisclosure
    );

    try {
      await withShowApiErrorMiddleware(createSubmission, {
        containerId: alertContainerId,
      })(
        {
          secondmarketSubmissionCreateRequestContract,
          ...(obo && {
            xOboAccountId: obo.account?.id?.toString(),
            xOboUserId: obo.representative?.user_id?.toString(),
          }),
        },
        {
          onComplete: ({ items }) => {
            if (options?.onComplete) options.onComplete();
            Notification.success({
              key: 'order-placed',
              message: (
                <S.NotificationContainer>
                  {obo
                    ? 'Order successfully placed in '
                    : 'IOI successfully placed in '}
                  {selectedIssuerEntity?.name}
                  <span
                    style={{
                      color: theme.color.info.typography.primary,
                    }}
                    onClick={() => {
                      history.push(
                        buildClientOrderDetailUrl({
                          orderItemId: items[0]?.id,
                          workstation,
                          isObo: !!obo,
                          isSponsoredInvestor,
                        })
                      );
                      Notification.close('order-placed');
                    }}
                  >
                    View {obo ? 'Order' : 'IOI'}
                  </span>
                </S.NotificationContainer>
              ),
            });
            if (!redoActionRef.current) {
              setHasUnsavedChanges(false);
              onClose();
            }
            handleStateReset(
              redoActionRef.current,
              !!preselectedIssuerEntityId
            );
          },
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleCloseConfirm = () => {
    handleStateReset();
    onClose();
  };

  const handleCloseAttempt = (e?: unknown, trigger?: 'mask' | 'button') => {
    if (activeStep === 'form') {
      onCloseAttempt(handleCloseConfirm);
    } else if (activeStep === 'confirmation') {
      if (trigger === 'mask') {
        onCloseAttempt(handleCloseConfirm);
      } else {
        setActiveStep('form');
      }
    } else if (activeStep === 'acknowledgement') {
      setActiveStep('confirmation');
    } else if (activeStep === 'affiliateDisclosure') {
      setActiveStep('confirmation');
    }
  };

  const { data: assetTypeData } = useCodebook({
    type: Codebooks.ASSET_TYPE,
  });

  const allowedAssetTypeOptions = useAllowedAssetTypeOptions(
    assetTypeData?.codebooks ?? []
  );
  const { data: orderEntryTypeData } = useCodebook({
    type: 'ORDER_ENTRY_TYPE',
  });

  const handleSetSelectedHolding = (holding: Holding) => {
    setSelectedHolding(holding);
    if (form.isFieldTouched('quantity')) {
      form.validateFields(['quantity']);
    }
  };

  const [{ openAddHoldingDrawer }, holdingDrawerProps] = useHoldingDrawer({
    preselectedIssuerEntityId: issuerEntityId,
    preselectedAccountId: accountId,
  });

  // check available holdings (remaining_quantity > 0)
  const { availableHoldingsCount, isLoading: areHoldingsLoading } =
    useHoldingSelectData({
      secondmarket: true,
      includeSpvs: true,
      includePortfolioHoldings: true,
      accountId,
      issuerEntityId,
      oboOverride: obo,
    });

  const orderTypeValue =
    Form.useWatch('orderType', form) ?? previewValues?.orderType;
  const orderTypeLabel = useMemo(() => {
    const orderType = orderEntryTypeData?.codebooks?.find(
      i => i.code === orderTypeValue
    );
    return orderType?.name ?? '';
  }, [orderTypeValue, orderEntryTypeData]);

  const attestationRule = obo
    ? CbAttestationRule.items.obo_create_order
    : (orderTypeValue as AttestationRuleType);

  const { data: attestationData } = useAttestationShow(
    {
      attestationRule,
    },
    {
      queryConfig: {
        enabled: activeStep === 'confirmation' && !!attestationRule,
      },
    }
  );
  const noHoldingsFound = !availableHoldingsCount;
  const submitLoading = isCreateSubmissionLoading && !redoActionRef.current;
  const submitAndCreateLoading =
    isCreateSubmissionLoading && redoActionRef.current;

  const isLoading =
    isAccountLoading || isSelectedIssuerEntityLoading || areHoldingsLoading;

  const drawerTitle = (
    <DrawerTitleContainer>
      {activeStep === 'confirmation'
        ? 'Confirm Order'
        : obo
        ? source === CbOrderSource.items.historical
          ? 'Enter Historical Order'
          : 'Order Entry'
        : 'Enter Indication of Interest'}
      {activeStep === 'confirmation' ? null : (
        <Label variant={activeAction === 'buy' ? 'success' : 'error'} round>
          {activeAction === 'buy' ? 'Buy' : 'Sell'}
        </Label>
      )}
    </DrawerTitleContainer>
  );

  const isMissingRequiredIds = !accountId || !issuerEntityId;
  const isSellWithNoHoldings = activeAction === 'sell' && noHoldingsFound;
  const isGuestRoleAndNotObo =
    !obo && isAccountGuestRole(selectedAccount, user?.email);
  const isBuyNotAccreditedAndNotHistorical =
    activeAction === 'buy' &&
    source !== CbOrderSource.items.historical &&
    isAccountNotAccredited(selectedAccount);

  const hideButtons =
    activeStep === 'form' &&
    (isMissingRequiredIds ||
      isSellWithNoHoldings ||
      isGuestRoleAndNotObo ||
      isBuyNotAccreditedAndNotHistorical);

  return (
    <>
      <Drawer
        open={isOpen}
        title={drawerTitle}
        data-cy={CypressDataIds.OrderEntryDrawer.Drawer}
        onClose={handleCloseAttempt}
        alertContainerId={alertContainerId}
        {...(activeStep === 'confirmation' && {
          isFooterFixed: true,
          footerHeight: 200,
        })}
        footer={
          hideButtons ? undefined : (
            <DrawerFooter>
              {activeStep === 'form' && (
                <Button
                  onClick={handlePreview}
                  data-cy={CypressDataIds.OrderEntryDrawer.Preview}
                >
                  Preview {orderTypeLabel}
                </Button>
              )}
              {activeStep === 'confirmation' && (
                <>
                  {showConfirmDialog && !isInvestor && (
                    <ConfirmDialog
                      setShowConfirm={setShowConfirmDialog}
                      handleConfirmation={() => {
                        handleSubmit();
                        setShowConfirmDialog(false);
                      }}
                      label="I reviewed the order details and I am ready to submit it"
                      offsetBottom={160}
                      reviewTerms={attestationData?.message}
                    />
                  )}
                  <Button
                    data-cy={CypressDataIds.OrderEntryDrawer.Submit}
                    onClick={() =>
                      isInvestor ? handleSubmit() : setShowConfirmDialog(true)
                    }
                    loading={submitLoading}
                    disabled={
                      !hasValidMinimumNotionalValue || submitAndCreateLoading
                    }
                  >
                    Submit Order
                  </Button>
                  <Button
                    color="info"
                    variant="outline"
                    onClick={() => {
                      redoActionRef.current = true;
                      setShowConfirmDialog(true);
                    }}
                    loading={submitAndCreateLoading}
                    disabled={!hasValidMinimumNotionalValue || submitLoading}
                  >
                    Submit & Create Another Order
                  </Button>
                  <Button
                    onClick={handleCloseAttempt}
                    variant="text"
                    icon={<Icon name="arrow-narrow-left" />}
                  >
                    Back
                  </Button>
                </>
              )}
            </DrawerFooter>
          )
        }
      >
        {activeStep === 'form' ? (
          <>
            {!!obo && (
              <Margin bottom={activeStep === 'form' ? 'md' : 'lg'} top="sm">
                <YourRoleDrawerSection
                  showTitle={false}
                  onAccountSwitch={
                    disabledFields?.includes('accountId')
                      ? undefined
                      : () => {
                          setFormData(
                            activeStep === 'form'
                              ? form.getFieldsValue()
                              : previewValues
                          );
                          setFormSizeType(sizeType);
                          onAccountSwitch();
                        }
                  }
                  accountId={accountId}
                  isObo
                />
              </Margin>
            )}
            <OrderPlacementForm
              form={form}
              disabledFields={disabledFields}
              onValuesChange={(_, vals) => {
                if (
                  hasOnlySpecificProperties(vals, [
                    'issuerEntityId',
                    'accountId',
                    'buySell',
                  ])
                ) {
                  return;
                }
                setHasUnsavedChanges(true);
              }}
              company={selectedIssuerEntity}
              activeAction={activeAction}
              source={source}
              selectedHolding={selectedHolding}
              setSelectedHolding={handleSetSelectedHolding}
              openAddHoldingDrawer={openAddHoldingDrawer}
              allowedAssetTypeOptions={allowedAssetTypeOptions}
              noHoldingsFound={noHoldingsFound}
              holdingsLoading={isLoading}
              orderSizeType={sizeType}
              updateSizeType={updateSizeType}
              toggleSizeType={toggleSizeType}
              onAccountSwitch={onAccountSwitch}
              brokerageFirm={selectedAccount?.brokerage_firm}
              isInvestor={isInvestor}
              obo={obo}
              selectedAccount={selectedAccount}
            />
          </>
        ) : (
          <OrderPlacementConfirmation
            values={previewValues}
            errorMessage={
              hasValidMinimumNotionalValue === false
                ? getMinimumNotionalValueErrorMessage()
                : undefined
            }
            activeAction={activeAction}
            holding={selectedHolding}
            orderEntryTypes={orderEntryTypeData?.codebooks || []}
            orderSizeType={sizeType}
            brokerageFirm={selectedAccount?.brokerage_firm}
            source={source}
            showNextStepsSection={isInvestor}
          />
        )}
      </Drawer>
      <HoldingDrawer
        {...holdingDrawerProps}
        initialIssuerEntity={selectedIssuerEntity}
        disabledIssuerEntitySelect
        disabledAccountSelect
        backToRoute="Order Entry"
        onSuccess={holding => {
          if (holding) {
            form.setFieldsValue({ holdingId: holding.id });
          }
        }}
        oboOverride={obo}
      />
      {!!obo && (
        <AcknowledgementModal
          isOpen={activeStep === 'acknowledgement'}
          onSubmit={handlePlaceOrder}
          onCancel={handleCloseAttempt}
          isLoading={isCreateSubmissionLoading}
          person={user.person}
        />
      )}
      {isInvestor && (
        <AffiliateDisclosureWithSignatureModal
          isOpen={activeStep === 'affiliateDisclosure'}
          onCancel={handleCloseAttempt}
          onSubmit={handlePlaceOrder}
          reviewTerms={attestationData?.message}
        />
      )}
      <DiscardModal cancelText={'Keep editing'} {...discardModalProps} />
    </>
  );
};
