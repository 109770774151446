import React, { useEffect } from 'react';
import { type FormInstance, type FormProps } from 'antd/es/form/Form';

import { Text } from '@npm/core/ui/components/atoms/Typography';

import { UserRolesFormItem } from '../../../../../../../users/CreateNewUser/Form/UserRolesFormItem';
import { PersonInfoForm } from '../../../../../../../users/CreateNewUser/PersonInfoForm';
import { useUserContextStore } from '../../../../../context';
import { type RepresentativeFormFields } from '../CreateNewAccount.types';

import * as S from '../CreateNewAccountModal.styles';

type Props = {
  form: FormInstance<RepresentativeFormFields>;
  isAnonymousAccount?: boolean;
} & FormProps;

export const AddRepresentative = ({
  form,
  isAnonymousAccount = false,
  ...formProps
}: Props) => {
  const user = useUserContextStore(store => store.user);

  useEffect(() => {
    form.setFieldsValue({
      first: user?.person?.first,
      middle: user?.person?.middle,
      last: user?.person?.last,
      email: user?.person?.email,
    });
  }, [form, user]);

  return (
    <S.Form form={form} {...formProps}>
      <Text size="sm">Representative information</Text>
      <PersonInfoForm form={form} defaultColumnCount={2} includeEmailField />
      {!isAnonymousAccount && (
        <UserRolesFormItem
          label={<Text size="sm">Representative Role</Text>}
          includeLimitedAccessRole={false}
        />
      )}
    </S.Form>
  );
};
