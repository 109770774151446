import { Codebooks } from './codebooks.types';

const Items = {
  finalized: 'finalized',
  completed: 'completed',
  started: 'started',
  not_started: 'not_started',
} as const;

export const CbBeneficialOwnershipAssessmentStatus = {
  code: Codebooks.BENEFICIAL_OWNERSHIP_ASSESSMENT_STATUS,
  items: Items,
};
