/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActionCounter } from '../model';
// @ts-ignore
import { ErrorValidation } from '../model';
/**
 * ActionCounterApi - axios parameter creator
 * @export
 */
export const ActionCounterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Shows counter of action items
         * @param {number} [issuerEntityId] 
         * @param {Array<string>} [category] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionCounterShow: async (issuerEntityId?: number, category?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/action-counter`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }

            if (category) {
                localVarQueryParameter['category'] = category.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionCounterApi - functional programming interface
 * @export
 */
export const ActionCounterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionCounterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Shows counter of action items
         * @param {number} [issuerEntityId] 
         * @param {Array<string>} [category] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionCounterShow(issuerEntityId?: number, category?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionCounter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionCounterShow(issuerEntityId, category, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionCounterApi - factory interface
 * @export
 */
export const ActionCounterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionCounterApiFp(configuration)
    return {
        /**
         * 
         * @summary Shows counter of action items
         * @param {number} [issuerEntityId] 
         * @param {Array<string>} [category] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionCounterShow(issuerEntityId?: number, category?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<ActionCounter> {
            return localVarFp.actionCounterShow(issuerEntityId, category, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for actionCounterShow operation in ActionCounterApi.
 * @export
 * @interface ActionCounterApiActionCounterShowRequest
 */
export interface ActionCounterApiActionCounterShowRequest {
    /**
     * 
     * @type {number}
     * @memberof ActionCounterApiActionCounterShow
     */
    readonly issuerEntityId?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof ActionCounterApiActionCounterShow
     */
    readonly category?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ActionCounterApiActionCounterShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof ActionCounterApiActionCounterShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof ActionCounterApiActionCounterShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof ActionCounterApiActionCounterShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof ActionCounterApiActionCounterShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof ActionCounterApiActionCounterShow
     */
    readonly xUserRoleId?: string
}

/**
 * ActionCounterApi - object-oriented interface
 * @export
 * @class ActionCounterApi
 * @extends {BaseAPI}
 */
export class ActionCounterApi extends BaseAPI {
    /**
     * 
     * @summary Shows counter of action items
     * @param {ActionCounterApiActionCounterShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionCounterApi
     */
    public actionCounterShow(requestParameters: ActionCounterApiActionCounterShowRequest = {}, options?: AxiosRequestConfig) {
        return ActionCounterApiFp(this.configuration).actionCounterShow(requestParameters.issuerEntityId, requestParameters.category, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type ActionCounterShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const ActionCounterShowErrorCodes = [
    401,
    422,
];


