import React from 'react';

import * as S from './Steps.styles';

export type StepItemVariant = 'default' | 'active' | 'complete' | 'disabled';

export type StepItem = {
  label: string;
  variant?: StepItemVariant | undefined;
  icon?: React.ReactNode;
};

type Props = {
  items: StepItem[];
};

export const Steps = ({ items }: Props) => {
  return (
    <S.StepsWrapper>
      {items.map((item, index) => (
        <S.StepItem key={index} className={item?.variant}>
          <S.StepItemLabel size="sm" className={item?.variant}>
            {!!item.icon && <S.StepItemIcon>{item.icon}</S.StepItemIcon>}
            {item.label}
          </S.StepItemLabel>
        </S.StepItem>
      ))}
    </S.StepsWrapper>
  );
};
