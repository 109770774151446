import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { useTheme } from 'styled-components';

import {
  formatAxisNumber,
  showStepTooltip,
  useChartOptions,
} from '@npm/core/ui/components/atoms/Charts';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import {
  getRangeAsNumber,
  useCompanyOverviewChartFilter,
} from '../../CompanyOverview.hooks';
import { formatMonthAndYearForChartLabel } from '../../CompanyOverview.utils';
import { CompanyOverviewChartFilters } from '../../components/CompanyOverviewChartFilters/CompanyOverviewChartFilters';
import { type CompanyOverviewMaxValues } from '../../TapeDPricing/TapeDPricing.types';

import { useValuations409ADataSet } from './Valuations409AChart.hooks';
import {
  type Valuation409ADataPoint,
  type Valuation409ADataValue,
} from './Valuations409AChart.types';
import {
  getTooltip,
  sortValuations409AData,
  TOOLTIP_HEIGHT,
  TOOLTIP_WIDTH,
  useValuations409AMinAndMaxDate,
} from './Valuations409AChart.utils';
import { ValuationsChartTooltip } from './Valuations409AChartTooltip';

import * as S from './Valuations409AChart.styles';

export const CHART_ID = 'valuations-chart';

type Props = {
  data: Valuation409ADataValue[];
  yAxisMaxValues: CompanyOverviewMaxValues;
};

export const Valuations409AChart = memo(function ValuationsChart({
  data,
  yAxisMaxValues,
}: Props) {
  const theme = useTheme();
  const { isDarkMode } = useDarkModeContext();
  const { isMobile } = useBreakpoints();

  const [minDate, maxDate] = useValuations409AMinAndMaxDate(data);

  const {
    setCurrentPage,
    currentMinDate,
    currentMaxDate,
    currentPage,
    pageCount,
    range,
    setRange,
  } = useCompanyOverviewChartFilter(minDate, maxDate);

  const currentMinDateValue = currentMinDate?.valueOf();
  const currentMaxDateValue = currentMaxDate?.valueOf();

  const [hoverIndex, setHoverIndex] = useState<number>();

  const sortedData = useMemo(() => {
    return sortValuations409AData(data, 'asc');
  }, [data]);

  const dataset = useValuations409ADataSet(sortedData, {
    currentMinDate,
    currentMaxDate,
  });

  const canvasRef = useRef<HTMLCanvasElement>();
  const chartRef = useRef<Chart<'line' | 'bar', Valuation409ADataPoint[]>>();

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  };

  const options = useChartOptions();

  const yAxisMaxValue = yAxisMaxValues.maximumPPS;

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    chartRef.current = new Chart(ctx, {
      type: 'line',
      options: options({
        scales: {
          x: {
            min: currentMinDateValue,
            max: currentMaxDateValue,
            type: 'time',
            ticks: {
              maxTicksLimit: getRangeAsNumber(range),
              minRotation: isMobile ? 30 : undefined,
              callback: formatMonthAndYearForChartLabel,
            },
            time: {
              unit: 'month',
            },
          },
          y: {
            beginAtZero: true,
            max: yAxisMaxValue,
            ticks: {
              callback: value =>
                `$${formatAxisNumber(value, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}`,
            },
          },
        },
        interaction: {
          mode: 'segment',
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            position: 'followCursor',
            external: function (context) {
              showStepTooltip({
                context,
                canvas: canvasRef.current,
                onIndexChanged: setHoverIndex,
                getTooltipElement: getTooltip,
              });
            },
            callbacks: {
              title: () => TOOLTIP_WIDTH,
              label: () => '',
              beforeBody: () => TOOLTIP_HEIGHT,
              afterBody: () => ' ',
              footer: () => '',
            },
          },
        },
      }),
      data: {
        datasets: [dataset],
      },
    });

    return () => destroyChart();
  }, [
    theme,
    isDarkMode,
    dataset,
    options,
    currentMinDateValue,
    currentMaxDateValue,
    range,
    isMobile,
    yAxisMaxValue,
  ]);

  return (
    <Card noContentPadding>
      <S.Header>
        <CompanyOverviewChartFilters
          range={range}
          onChangeRange={range => {
            setRange(range);
          }}
          pagination={{
            page: currentPage,
            totalPages: pageCount,
            onPageChange: val => {
              setCurrentPage(val);
              setHoverIndex(undefined);
            },
          }}
        />
      </S.Header>
      <S.Body>
        <S.ChartContainer>
          <canvas ref={canvasRef}></canvas>
          <div id={CHART_ID} className="html-chart-legend">
            <ValuationsChartTooltip
              date={dataset.data[hoverIndex]?.raw?.filing_date?.toString()}
              pricePerShare={dataset.data[hoverIndex]?.raw?.price_per_share}
            />
          </div>
        </S.ChartContainer>
      </S.Body>
    </Card>
  );
});
