import React from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import * as S from './styles';

type Props = {
  instructions?: string;
};

export const InstructionsAlert = ({ instructions }: Props) => {
  if (!instructions) return null;
  return (
    <S.InstructionsWrapper>
      <Alert
        expandable={false}
        type="error"
        description={
          <div>
            <Text size="sm" weight="bold">
              Instructions
            </Text>
            {instructions}
          </div>
        }
        message={'This holding has been marked as needing correction.'}
      />
    </S.InstructionsWrapper>
  );
};
