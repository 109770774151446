import { EMPTY_VALUE } from '@npm/core/ui/components/atoms/Typography';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import { type VenusApi } from '@npm/data-access';

type EstValuationTooltipType = {
  title: string;
  tooltipText: string;
};

export const getMethodologyConfig = (
  methodology: string
): EstValuationTooltipType => {
  switch (methodology) {
    case 'stated':
      return {
        title: 'Stated',
        tooltipText:
          'This calculation is derived from State and Federal filings.',
      };
    case 'calculated':
    case 'assumed':
      return {
        title: 'Calculated',
        tooltipText:
          'This calculation is derived from State and Federal filings, Media, or PR articles.',
      };
    case 'alternative valuation':
      return {
        title: 'PR',
        tooltipText:
          'This figure is derived from a company issued Press Release',
      };
    default:
      return null;
  }
};

export const getStatSubTitle = (
  lastClassifiedActivity: VenusApi.FinancialActivity | undefined
) => {
  if (lastClassifiedActivity?.date && lastClassifiedActivity?.name) {
    return `${formatDate(lastClassifiedActivity.date, {
      dateFormat: DATE_FORMATS.DATE,
    })} • ${lastClassifiedActivity.name}`;
  }
  if (lastClassifiedActivity?.date) {
    return formatDate(lastClassifiedActivity.date, {
      dateFormat: DATE_FORMATS.DATE,
    });
  }
  if (lastClassifiedActivity?.name) {
    return lastClassifiedActivity.name;
  }
  return EMPTY_VALUE;
};
