import { Icon } from '@npm/core/ui/components/atoms/Icon';
import {
  type StepItem,
  type StepItemVariant,
} from '@npm/core/ui/components/atoms/Steps';
import { type OrderBuySellCode } from '@npm/data-access';

import {
  type OpportunityStates,
  opportunityState,
} from '../../../Opportunities.types';

const getStepVariant = (
  state: OpportunityStates,
  activeStates: OpportunityStates[],
  completeStates: OpportunityStates[],
  disabledStates: OpportunityStates[]
): StepItemVariant => {
  if (activeStates.includes(state)) {
    return 'active';
  }
  if (completeStates.includes(state)) {
    return 'complete';
  }
  if (disabledStates.includes(state)) {
    return 'disabled';
  }
  return 'default';
};

const getStepIcon = (variant: StepItemVariant) => {
  if (variant === 'complete') {
    return <Icon name="check" size="xxs" />;
  }

  if (variant === 'disabled') {
    return <Icon name="x-close" size="xxs" />;
  }

  return null;
};

export const getStepsItems = (
  state: OpportunityStates,
  orderSide: OrderBuySellCode
): StepItem[] =>
  [
    {
      label: 'Match',
      variant: getStepVariant(
        state,
        [opportunityState.match],
        [
          opportunityState.transfer,
          opportunityState.getPaid,
          opportunityState.complete,
          opportunityState.rofrRejected,
        ],
        [opportunityState.rejected]
      ),
    },
    {
      label: 'Transfer',
      variant: getStepVariant(
        state,
        [opportunityState.transfer],
        [opportunityState.getPaid, opportunityState.complete],
        [opportunityState.rofrRejected]
      ),
    },
    {
      label: orderSide === 'buy' ? 'Get Shares' : 'Get Paid',
      variant: getStepVariant(
        state,
        [opportunityState.getPaid],
        [opportunityState.complete],
        []
      ),
    },
  ].map(item => ({
    ...item,
    icon: getStepIcon(item.variant),
  }));
