import { type OrderItemUnitCode, CbOrderItemUnit } from '@npm/data-access';

import { type OrderSizeType } from '../order.types';

export const getApplicableSizeType = (
  defaultSizeType: OrderSizeType,
  pricePerShare: number | undefined,
  unit: OrderItemUnitCode | undefined
) => {
  if (!pricePerShare && unit) {
    return unit === CbOrderItemUnit.items.SHARES ? 'Shares' : unit;
  }
  return defaultSizeType;
};
