import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import { type OboModal } from '../OboModal';

import * as S from './FooterButtons.styles';

type Props = {
  variant: React.ComponentProps<typeof OboModal>['variant'];
  step: 'account' | 'representative';
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onNext: () => void;
  onBack?: () => void;
  isNextDisabled: boolean;
  openCreateAccountModal: () => void;
  mustAddRepresentative: boolean;
};

export const FooterButtons = ({
  variant,
  step,
  onCancel,
  onNext,
  onBack,
  isNextDisabled,
  mustAddRepresentative,
  openCreateAccountModal,
}: Props) => {
  const { isMobile } = useBreakpoints();
  const showCreateNewAccountButton =
    step === 'account' && variant !== 'negotiation';

  return (
    <S.Wrapper>
      {showCreateNewAccountButton && (
        <Button
          icon={<Icon name="plus" />}
          variant="outline"
          onClick={openCreateAccountModal}
          blockOnMobile
        >
          Create New Account
        </Button>
      )}
      {step === 'representative' && (
        <Button
          icon={<Icon name="chevron-left" />}
          variant="outline"
          onClick={onBack}
          blockOnMobile
        >
          Back to Accounts
        </Button>
      )}
      <div>
        <Button variant="text" onClick={onCancel} blockOnMobile>
          Cancel
        </Button>
        {!isMobile && (
          <Button
            onClick={onNext}
            disabled={isNextDisabled}
            data-cy={CypressDataIds.OboModal.ConfirmButton}
          >
            {mustAddRepresentative ? 'Create Representative' : 'Continue'}
          </Button>
        )}
      </div>
    </S.Wrapper>
  );
};
