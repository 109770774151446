import { Codebooks } from './codebooks.types';

const Items = {
  WIRE_INFORMATION: 'WireInformation',
} as const;

export const CbPaymentInformationType = {
  code: Codebooks.PAYMENT_INFORMATION_TYPE,
  items: Items,
};
