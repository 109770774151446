import { InputNumber } from 'antd';
import styled from 'styled-components';

import { disabledCSS, errorStatusCSS, inputCSS } from './styles';

export const StyledInputNumber = styled(InputNumber)`
  &.ant-input-number {
    ${inputCSS};
    width: 100%;
    & input {
      padding: 0;
      height: auto;
    }
  }
  &.ant-input-number-group-wrapper {
    width: 100%;
    .ant-input-number-group-addon {
      border-color: ${({ theme }) => theme.color.general.borders.secondary};
      background-color: ${({ theme }) => theme.color.general.layout.two};
      padding: ${({ theme }) =>
        `${theme.spacing.sm}px ${theme.spacing.lg / 2}px`};
      color: ${({ theme }) => theme.color.general.typography.secondary};
    }
  }
  &.ant-input-number-status-error:not(.ant-input-number-disabled):not(
      .ant-input-number-borderless
    ).ant-input-number {
    ${errorStatusCSS};
  }
  &.ant-input-number-status-warning:not(.ant-input-number-disabled):not(
      .ant-input-number-borderless
    ).ant-input-number {
    ${errorStatusCSS};
  }
  &.ant-input-number-disabled {
    ${disabledCSS};
  }
`;
