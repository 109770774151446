import { type InfiniteData, type QueryClient } from '@tanstack/react-query';

import { type IssuerEntityAggregate } from '@npm/data-access';

export const updateWatchlistCache = (
  isWatched: boolean,
  companyId: number,
  queryClient: QueryClient
) => {
  // optimistically update cached company select list
  try {
    queryClient.setQueriesData<
      InfiniteData<{ issuer_entities: IssuerEntityAggregate[] }>
    >(['IssuerEntityIndex'], cachedData => {
      if (!cachedData) return undefined;

      return {
        ...cachedData,
        pages: [
          ...cachedData.pages.map(page => ({
            ...page,
            issuer_entities: page.issuer_entities.map(c =>
              c.id === companyId ? { ...c, watchlist: isWatched } : c
            ),
          })),
        ],
      };
    });
    // eslint-disable-next-line no-empty
  } catch {}

  // invalidates the company select list
  queryClient
    .refetchQueries({
      queryKey: ['IssuerEntityIndex'],
    })
    .then();

  // optimistically update selected company watchlist button
  try {
    queryClient.setQueriesData<IssuerEntityAggregate>(
      ['IssuerEntityShow', { id: companyId }],
      cachedData => {
        if (!cachedData) return undefined;

        return {
          ...cachedData,
          watchlist: isWatched,
        };
      }
    );
    // eslint-disable-next-line no-empty
  } catch {}

  // invalidates company overview
  queryClient
    .refetchQueries({
      queryKey: ['IssuerEntityShow'],
    })
    .then();

  // this invalidates only market feed watchlist tab (both count and table)
  const keys = queryClient
    .getQueryCache()
    .findAll(
      ['SecondmarketOrderItemIndex', { findUserAccountWatchlist: true }],
      { exact: false }
    )
    .map(q => q.queryKey);

  keys.forEach(key => {
    queryClient.invalidateQueries({
      queryKey: key,
    });
  });
};
