export const formatIndividualTaxId = (taxId: string) => {
  // format is XXX-XX-XXXX
  return taxId
    ?.toString()
    .replace(/[^0123456789-]/g, '') // no chars except digits and hyphens
    .replace(/^(\d{3})(\d)/g, '$1-$2') // first hyphen
    .replace(/^(\d{3}-\d{2})(\d)/g, '$1-$2') // second hyphen
    .substring(0, 11); // max length
};
export const parseFormattedTaxId = (taxId: string) => {
  return taxId.replace(/-*/g, '').substring(0, 9);
};
export const validateParsedTaxId = (taxId: string) => {
  return taxId && /^(\d{9})$/.test(taxId);
}; // validator for antd
export const parsedTaxIdValidator = (_, value) =>
  validateParsedTaxId(value) ? Promise.resolve() : Promise.reject();
export const formatEntityTaxId = (taxId: string) => {
  // format is XX-XXXXXX
  return taxId
    ?.toString()
    .replace(/[^0123456789-]/g, '') // no chars except digits and hyphens
    .replace(/^(\d{2})(\d)/g, '$1-$2') // first hyphen
    .substring(0, 10); // max length
};
