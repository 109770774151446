import { type CbLabelComponent, type LabelVariant } from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  incomplete: 'incomplete',
  warning: 'warning',
  completed: 'completed',
  not_applicable: 'not_applicable',
} as const;

type Keys = keyof typeof Items;

const labelMap: Record<Keys, LabelVariant> = {
  incomplete: 'warning',
  warning: 'warning',
  completed: 'success',
  not_applicable: 'disabled',
};

const onboardingStepKeyToTitleMap: Record<
  keyof typeof OnboardingSteps,
  string
> = {
  personal_information_state: 'Contact Information',
  authorized_signer_state: 'Authorized Signer',
  individual_tax_state: 'Individual Tax ID',
  representative_photo_state: 'Government Issued ID',
  tax_information_state: 'Tax Information',
  bank_account_state: 'Bank Information',
  accredited_investor_state: 'Accreditation',
  entity_information_state: 'Contact Information',
  entity_tax_state: 'Entity Tax ID',
  entity_formation_document_state: 'Formation Document',
  buyer_survey_state: 'Buyer Survey',
  spouse_information_state: 'Spousal Information',
  marketplace_agreement_state: 'Marketplace Agreement',
  paying_agent_agreement_state: 'Paying Agent Services Agreement',
  beneficial_ownership_assessment_state: 'Beneficial Ownership Assessment',
};

const OnboardingSteps = {
  personal_information_state: 'personal_information_state',
  authorized_signer_state: 'authorized_signer_state',
  individual_tax_state: 'individual_tax_state',
  representative_photo_state: 'representative_photo_state',
  tax_information_state: 'tax_information_state',
  bank_account_state: 'bank_account_state',
  accredited_investor_state: 'accredited_investor_state',
  entity_information_state: 'entity_information_state',
  entity_tax_state: 'entity_tax_state',
  entity_formation_document_state: 'entity_formation_document_state',
  buyer_survey_state: 'buyer_survey_state',
  spouse_information_state: 'spouse_information_state',
  marketplace_agreement_state: 'marketplace_agreement_state',
  paying_agent_agreement_state: 'paying_agent_agreement_state',
  beneficial_ownership_assessment_state:
    'beneficial_ownership_assessment_state',
};

export const CbOnboardingStatus = {
  code: Codebooks.ONBOARDING_STATUS,
  items: Items,
  steps: OnboardingSteps,
  titleMap: onboardingStepKeyToTitleMap,
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem | undefined
  ) =>
    Component({
      variant: labelMap[(codebookItem?.code as Keys) ?? 'none'],
      label: codebookItem?.name,
    }),
};
