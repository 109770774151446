import styled from 'styled-components';

export const Wrapper = styled.div`
  width: auto;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.general.layout.zero};
  display: grid;
  padding: ${({ theme }) => theme.spacing.lg / 4}px
    ${({ theme }) => theme.spacing.sm}px;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.sm}px;
`;
