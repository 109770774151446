/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  PortfolioExtractApi,
  PortfolioExtractAndMatchErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new PortfolioExtractApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type PortfolioExtractAndMatchMethod = PortfolioExtractApi['portfolioExtractAndMatch'];

export const usePortfolioExtractAndMatch = () => {
  return useMutation<PortfolioExtractAndMatchMethod>(
    (...args) => api.portfolioExtractAndMatch(...args),
    PortfolioExtractAndMatchErrorCodes,
    'PortfolioExtractAndMatch',
    '/api/investor/upload_and_extract_portfolio',
    'post'
  );
};
