import React from 'react';
import { type PaginationProps, Grid } from 'antd';

import { type AccountsSignerIndex } from '@npm/data-access';

import { useUserContextStore } from '../../../auth/user/context';
import { useCurrentRole } from '../../../auth/user/role/hooks/useCurrentRole';

import { getSignerColumns } from './ReadOnly.config';

import * as S from './ReadOnly.styles';

const { useBreakpoint } = Grid;

type Props = {
  data: AccountsSignerIndex;
  accountName?: string;
  paginationData: [PaginationProps, (pagination: PaginationProps) => void];
  applyErrorStyles?: boolean;
};

export const AuthorizedSignersReadOnly = ({
  data,
  accountName,
  paginationData,
  applyErrorStyles = false,
}: Props) => {
  const breakpoint = useBreakpoint();
  const role = useCurrentRole();
  const [pagination, updatePagination] = paginationData;

  return (
    <>
      <S.Table
        columns={getSignerColumns()}
        dataSource={data?.signers}
        onChange={updatePagination}
        pagination={pagination}
        data-cy="signers-table"
        data-dd-privacy="mask"
        expandable={{
          showExpandColumn: !breakpoint.lg,
          rowExpandable: () => !breakpoint.lg,
        }}
      />

      {(!data?.signers || data?.signers?.length === 0) && (
        <S.NoDataTitle $hasError={applyErrorStyles}>
          No Authorized Signer added yet
        </S.NoDataTitle>
      )}

      <S.Disclaimer>
        Please note that the signers above can sign on behalf of{' '}
        {accountName ?? role?.subject?.name}
      </S.Disclaimer>
    </>
  );
};
