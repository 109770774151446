import React, { useState } from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  DrawerEnum,
  useActiveDrawerStore,
} from '@npm/core/ui/components/organisms/Drawer';
import { CypressDataIds } from '@npm/core/ui/constants';

import { OboModal } from '../../../obo';
import { leaveObo } from '../../../role/context/userRole.helpers';
import { AccountInfoDropdown } from '../../AccountInfo/AccountInfoDropdown';

import { ActionRow } from '../../AccountInfo/AccountInfoDropdown.styles';

export const OboDropdown = ({ children }: { children: React.ReactNode }) => {
  const [overlayOpen, setOverlayOpen] = useState(false);

  const { openDrawer, closeDrawer, isOpen } = useActiveDrawerStore();

  const handleLeaveObo = async () => {
    try {
      leaveObo();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <AccountInfoDropdown
        open={overlayOpen}
        onOpenChange={open => setOverlayOpen(open)}
        actions={
          <>
            <ActionRow>
              <Icon name="refresh" size="xs" />
              <button
                onClick={() => {
                  openDrawer(DrawerEnum.OBO_SELECTION);
                  setOverlayOpen(false);
                }}
                onTouchEnd={() => {
                  openDrawer(DrawerEnum.OBO_SELECTION);
                  setOverlayOpen(false);
                }}
              >
                <Text
                  size="sm"
                  colorVariant="primary"
                  marginBottom="xs"
                  as="span"
                >
                  Switch account
                </Text>
              </button>
            </ActionRow>
            <ActionRow>
              <Icon name="logout" size="xs" />
              <button
                onClick={handleLeaveObo}
                onTouchEnd={handleLeaveObo}
                data-cy={CypressDataIds.OboModal.OboExitButton}
              >
                <Text
                  size="sm"
                  colorVariant="primary"
                  marginBottom="xs"
                  as="span"
                >
                  Exit on behalf of mode
                </Text>
              </button>
            </ActionRow>
          </>
        }
      >
        {children}
      </AccountInfoDropdown>
      <OboModal
        open={isOpen(DrawerEnum.OBO_SELECTION)}
        onCancel={() => closeDrawer()}
      />
    </>
  );
};
