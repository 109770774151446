import { OktaAuth } from '@okta/okta-auth-js';

import { APP_CFG_APP_URL } from '@npm/config';

import { OKTA_CLIENT_ID, OKTA_ISSUER } from './env';

const oktaConfig = {
  issuer: OKTA_ISSUER,
  clientId: OKTA_CLIENT_ID,
  redirectUri: `${APP_CFG_APP_URL}/auth/auth/oktaoauth/callback`,
  pkce: false,
  cookies: {
    secure: false,
  },
};

export const oktaAuth = new OktaAuth(oktaConfig);
