import styled from 'styled-components';

import { type ThemeSpacing } from '../../../atoms/common';

export const Container = styled.div<{ gap?: ThemeSpacing }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100%;
  ${({ theme, gap }) => (gap ? `gap: ${theme.spacing[gap]}px` : null)};
`;
