type PathMetadata = typeof preplatformGeneralMetadata;

export const registerPreplatformGeneralMetadata = (metadata: PathMetadata) => {
  preplatformGeneralMetadata.forEach(m => {
    metadata.push(m);
  });
};

export const preplatformGeneralMetadata = [
  {
    pathRegex: /.*preplatform*/,
    metadata: {
      screenId: 'NFE-4373',
      screenName: 'Preplatform',
    },
  },
];
