import styled, { css } from 'styled-components';

export const WidgetMask = styled.div<{ $show: boolean }>`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.aboveAll};

  ${({ $show }) =>
    !$show &&
    css`
      display: none;
    `}
`;
