import styled from 'styled-components';

const drawerTitleHeight = 24;

export const DrawerTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  height: ${drawerTitleHeight}px;
  padding-right: ${({ theme }) => theme.spacing.sm}px;
`;

export const DrawerFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md}px;
  padding: ${({ theme }) => theme.spacing.lg}px;
`;

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  // Notifications don't have access to theme somehow
  span {
    margin-right: 8px;
    cursor: pointer;
    :hover {
      color: #0177da; // same for dark/light - theme.color.info.backgrounds.primaryHover
    }
  }
`;
