import styled, { css } from 'styled-components';

import { Card as CardBase } from '@npm/core/ui/components/molecules/Card';

import { OptInEntityColorVariant } from '../../OptIn.enums';

const CARD_WIDTH = 500;

export const Card = styled(CardBase)<{
  $backgroundVariant: OptInEntityColorVariant;
}>`
  ${({ $backgroundVariant }) => {
    switch ($backgroundVariant) {
      case OptInEntityColorVariant.INSTITUTION:
        return css`
          background-image: url(/public/assets/optin/bg-benefits-institution.png);
        `;
      case OptInEntityColorVariant.INDIVIDUAL:
        return css`
          background-image: url(/public/assets/optin/bg-benefits-individual.png);
          background-size: cover;
        `;
    }
  }}
  background-position: bottom right;
  background-repeat: no-repeat;
  width: ${CARD_WIDTH}px;
  padding: ${({ theme }) => theme.spacing.lg}px;
`;
