import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router';

import { Loader } from '@npm/core/ui/components/atoms/Loader';
import {
  filterRoutesByFeatureFlag,
  getAllRoutesRecursively,
} from '@npm/core/ui/routes/index';
import { useFeatureFlags } from '@npm/features/app/featureFlags/featureFlags.context';
import { useCurrentWorkstation } from '@npm/features/auth/user/role';
import { ErrorPath } from '@npm/features/routes/components/ErrorPath';
import { ProtectedRoute } from '@npm/features/routes/components/ProtectedRoute';

import { SecondMarketLayout } from '../components/Layout';

import { routes } from './routes.secondMarket';

export const SecondMarketRouter = () => {
  const { isEnabled } = useFeatureFlags();
  const workstation = useCurrentWorkstation();

  const allRoutes = useMemo(() => {
    return getAllRoutesRecursively(
      filterRoutesByFeatureFlag(isEnabled, routes)
    );
  }, []);

  if (!workstation) {
    // In rare cases, when entering or existing OBO, workstation can be undefined for a moment
    return <Loader />;
  }

  return (
    <Switch>
      <SecondMarketLayout>
        {allRoutes.map(({ needLogin, ...route }) => {
          return needLogin !== false ? (
            <ProtectedRoute
              key={route.path}
              path={route.path}
              component={route.component}
              permissionsChecks={route.permissionsChecks}
              exact
            />
          ) : (
            <Route
              key={route.path}
              path={route.path}
              component={route.component}
              exact
            />
          );
        })}
      </SecondMarketLayout>
      <Route>
        <ErrorPath
          heading="Error"
          title="Sorry, the page you visited does not exist."
          status="404"
        />
      </Route>
    </Switch>
  );
};
