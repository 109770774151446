import { type ReactNode } from 'react';

import { useBreakpoints } from '../../../hooks/useBreakpoints';

import { UnsupportedResolutionContent } from './UnsupportedResolutionContent';
import { UnsupportedResolutionScreen } from './UnsupportedResolutionScreen';

type Props = {
  children?: ReactNode;
};

const HEADER_TEXT = 'Unsupported resolution';

export const UnsupportedResolution = ({ children }: Props) => {
  const { isMobile } = useBreakpoints();
  const isLandscape = window.innerWidth > window.innerHeight && isMobile;
  const isTooSmall = window.innerWidth < 320;
  const isSupported = !(isLandscape || isTooSmall);

  const content = isTooSmall ? (
    <UnsupportedResolutionContent
      name="small-screen"
      heading={HEADER_TEXT}
      message="It looks like window of your browser is too small. Please expand your browser window to access this website."
    />
  ) : (
    <UnsupportedResolutionContent
      name="rotated-screen"
      heading={HEADER_TEXT}
      message="It looks like your mobile is in landscape mode. Please rotate your phone to portrait mode to access this website."
    />
  );

  return (
    <UnsupportedResolutionScreen content={content} isSupported={isSupported}>
      {children}
    </UnsupportedResolutionScreen>
  );
};
