import React from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  DATE_FORMATS,
  formatDate,
  truncate,
} from '@npm/core/ui/utils/formatters';
import { addHttpsIfMissing } from '@npm/core/ui/utils/url';
import { type VenusApi } from '@npm/data-access';
import { type IconProps } from '@npm/utils';

import * as S from './BaseInfo.styles';

type Props = {
  companyProfile: VenusApi.CompanyProfile | undefined;
  className?: string;
};

type Item = {
  key: string;
  title: string;
  value: string;
  iconName: IconProps['name'];
  href?: string;
};

const getBaseInfoItems: (
  companyProfile: VenusApi.CompanyProfile | undefined
) => Item[] = companyProfile => [
  {
    key: 'name',
    title: 'Legal Name',
    value: companyProfile?.name,
    iconName: 'info-circle',
  },
  {
    key: 'founding_date',
    title: 'Founded',
    value: formatDate(companyProfile?.founding_date, {
      dateFormat: DATE_FORMATS.YEAR,
    }),
    iconName: 'building',
  },
  {
    key: 'location',
    title: 'Location',
    value:
      companyProfile && `${companyProfile?.city}, ${companyProfile?.state}`,
    iconName: 'map-pin',
  },
  {
    key: 'website',
    title: 'Website',
    value: truncate(
      companyProfile?.website
        .replace('https://', '')
        .replace('www.', '')
        .replace('http://', ''),
      24
    ),
    iconName: 'world',
    href: addHttpsIfMissing(companyProfile?.website),
  },
];

export const BaseInfo = ({ companyProfile, className }: Props) => {
  const { isMobile } = useBreakpoints();

  return (
    <S.Wrapper className={className}>
      {getBaseInfoItems(companyProfile).map(
        ({ key, title, value, iconName, href }) => (
          <S.Item key={key}>
            <S.IconWrapper>
              <Icon name={iconName} size={isMobile ? 'xxs' : 'xs'} />
            </S.IconWrapper>
            <div>
              <Text size="xs">{title}</Text>
              <Text
                size="sm"
                colorVariant="primary"
                {...(href && {
                  href,
                  target: '_blank',
                })}
              >
                {value}
              </Text>
            </div>
          </S.Item>
        )
      )}
    </S.Wrapper>
  );
};
