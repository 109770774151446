/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPartnerCapTablePartnerUploadIndex } from '../model';
// @ts-ignore
import { AdminPartnerCapTablePartnerUploadShow } from '../model';
/**
 * CapTablePartnerUploadApi - axios parameter creator
 * @export
 */
export const CapTablePartnerUploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary lists all partner uploaded cap table files for an issuer
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'CAP_TABLE_PARTNER_UPLOAD_STATUS.uploaded' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.imported' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.failed_validation'} [status] 
         * @param {'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT'} [sortBy] defaults to created_at
         * @param {'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC'} [sortDirection] defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        capTablePartnerUploadsList: async (issuerId: string, xWorkstation?: string, page?: number, size?: number, status?: 'CAP_TABLE_PARTNER_UPLOAD_STATUS.uploaded' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.imported' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.failed_validation', sortBy?: 'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT', sortDirection?: 'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('capTablePartnerUploadsList', 'issuerId', issuerId)
            const localVarPath = changeUrl(`/api/admin/partner/issuers/{issuer_id}/cap_table_partner_uploads`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sort_direction'] = sortDirection;
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary shows details of a partner uploaded cap table file and all associated file versions
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {number} uploadId An auto-incremented id of a cap table partner upload.
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapTablePartnerUploads: async (issuerId: string, uploadId: number, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('getCapTablePartnerUploads', 'issuerId', issuerId)
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('getCapTablePartnerUploads', 'uploadId', uploadId)
            const localVarPath = changeUrl(`/api/admin/partner/issuers/{issuer_id}/cap_table_partner_uploads/{upload_id}`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)))
                .replace(`{${"upload_id"}}`, encodeURIComponent(String(uploadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CapTablePartnerUploadApi - functional programming interface
 * @export
 */
export const CapTablePartnerUploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CapTablePartnerUploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary lists all partner uploaded cap table files for an issuer
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'CAP_TABLE_PARTNER_UPLOAD_STATUS.uploaded' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.imported' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.failed_validation'} [status] 
         * @param {'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT'} [sortBy] defaults to created_at
         * @param {'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC'} [sortDirection] defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async capTablePartnerUploadsList(issuerId: string, xWorkstation?: string, page?: number, size?: number, status?: 'CAP_TABLE_PARTNER_UPLOAD_STATUS.uploaded' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.imported' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.failed_validation', sortBy?: 'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT', sortDirection?: 'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPartnerCapTablePartnerUploadIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.capTablePartnerUploadsList(issuerId, xWorkstation, page, size, status, sortBy, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary shows details of a partner uploaded cap table file and all associated file versions
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {number} uploadId An auto-incremented id of a cap table partner upload.
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCapTablePartnerUploads(issuerId: string, uploadId: number, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPartnerCapTablePartnerUploadShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCapTablePartnerUploads(issuerId, uploadId, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CapTablePartnerUploadApi - factory interface
 * @export
 */
export const CapTablePartnerUploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CapTablePartnerUploadApiFp(configuration)
    return {
        /**
         * 
         * @summary lists all partner uploaded cap table files for an issuer
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'CAP_TABLE_PARTNER_UPLOAD_STATUS.uploaded' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.imported' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.failed_validation'} [status] 
         * @param {'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT'} [sortBy] defaults to created_at
         * @param {'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC'} [sortDirection] defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        capTablePartnerUploadsList(issuerId: string, xWorkstation?: string, page?: number, size?: number, status?: 'CAP_TABLE_PARTNER_UPLOAD_STATUS.uploaded' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.imported' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.failed_validation', sortBy?: 'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT', sortDirection?: 'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC', options?: any): AxiosPromise<AdminPartnerCapTablePartnerUploadIndex> {
            return localVarFp.capTablePartnerUploadsList(issuerId, xWorkstation, page, size, status, sortBy, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary shows details of a partner uploaded cap table file and all associated file versions
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {number} uploadId An auto-incremented id of a cap table partner upload.
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapTablePartnerUploads(issuerId: string, uploadId: number, xWorkstation?: string, options?: any): AxiosPromise<AdminPartnerCapTablePartnerUploadShow> {
            return localVarFp.getCapTablePartnerUploads(issuerId, uploadId, xWorkstation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for capTablePartnerUploadsList operation in CapTablePartnerUploadApi.
 * @export
 * @interface CapTablePartnerUploadApiCapTablePartnerUploadsListRequest
 */
export interface CapTablePartnerUploadApiCapTablePartnerUploadsListRequest {
    /**
     * An NPM GUID of an issuer.
     * @type {string}
     * @memberof CapTablePartnerUploadApiCapTablePartnerUploadsList
     */
    readonly issuerId: string

    /**
     * 
     * @type {string}
     * @memberof CapTablePartnerUploadApiCapTablePartnerUploadsList
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {number}
     * @memberof CapTablePartnerUploadApiCapTablePartnerUploadsList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CapTablePartnerUploadApiCapTablePartnerUploadsList
     */
    readonly size?: number

    /**
     * 
     * @type {'CAP_TABLE_PARTNER_UPLOAD_STATUS.uploaded' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.imported' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.failed_validation'}
     * @memberof CapTablePartnerUploadApiCapTablePartnerUploadsList
     */
    readonly status?: 'CAP_TABLE_PARTNER_UPLOAD_STATUS.uploaded' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.imported' | 'CAP_TABLE_PARTNER_UPLOAD_STATUS.failed_validation'

    /**
     * defaults to created_at
     * @type {'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT'}
     * @memberof CapTablePartnerUploadApiCapTablePartnerUploadsList
     */
    readonly sortBy?: 'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT'

    /**
     * defaults to desc
     * @type {'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC'}
     * @memberof CapTablePartnerUploadApiCapTablePartnerUploadsList
     */
    readonly sortDirection?: 'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC'
}

/**
 * Request parameters for getCapTablePartnerUploads operation in CapTablePartnerUploadApi.
 * @export
 * @interface CapTablePartnerUploadApiGetCapTablePartnerUploadsRequest
 */
export interface CapTablePartnerUploadApiGetCapTablePartnerUploadsRequest {
    /**
     * An NPM GUID of an issuer.
     * @type {string}
     * @memberof CapTablePartnerUploadApiGetCapTablePartnerUploads
     */
    readonly issuerId: string

    /**
     * An auto-incremented id of a cap table partner upload.
     * @type {number}
     * @memberof CapTablePartnerUploadApiGetCapTablePartnerUploads
     */
    readonly uploadId: number

    /**
     * 
     * @type {string}
     * @memberof CapTablePartnerUploadApiGetCapTablePartnerUploads
     */
    readonly xWorkstation?: string
}

/**
 * CapTablePartnerUploadApi - object-oriented interface
 * @export
 * @class CapTablePartnerUploadApi
 * @extends {BaseAPI}
 */
export class CapTablePartnerUploadApi extends BaseAPI {
    /**
     * 
     * @summary lists all partner uploaded cap table files for an issuer
     * @param {CapTablePartnerUploadApiCapTablePartnerUploadsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CapTablePartnerUploadApi
     */
    public capTablePartnerUploadsList(requestParameters: CapTablePartnerUploadApiCapTablePartnerUploadsListRequest, options?: AxiosRequestConfig) {
        return CapTablePartnerUploadApiFp(this.configuration).capTablePartnerUploadsList(requestParameters.issuerId, requestParameters.xWorkstation, requestParameters.page, requestParameters.size, requestParameters.status, requestParameters.sortBy, requestParameters.sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary shows details of a partner uploaded cap table file and all associated file versions
     * @param {CapTablePartnerUploadApiGetCapTablePartnerUploadsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CapTablePartnerUploadApi
     */
    public getCapTablePartnerUploads(requestParameters: CapTablePartnerUploadApiGetCapTablePartnerUploadsRequest, options?: AxiosRequestConfig) {
        return CapTablePartnerUploadApiFp(this.configuration).getCapTablePartnerUploads(requestParameters.issuerId, requestParameters.uploadId, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }
}

export type CapTablePartnerUploadsListErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CapTablePartnerUploadsListErrorCodes = [
];

export type GetCapTablePartnerUploadsErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetCapTablePartnerUploadsErrorCodes = [
    404,
];


