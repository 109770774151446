import styled from 'styled-components';

import { Text } from '@npm/core/ui/components/atoms/Typography';

import { getValuations409AColor } from '../Valuations409A.utils';

import { TooltipStyles } from '@npm/core/ui/components/atoms/Charts/styles/Tooltip.styles';

export const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;
`;

export const Body = styled.div`
  height: 400px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md}px;
  padding-top: ${({ theme }) => theme.spacing.xs}px;
`;

export const ChartContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  ${TooltipStyles}
`;

export const TooltipCard = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.sm}px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.sm}px;
  flex-shrink: 0;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  background: ${({ theme }) => theme.color.general.layout.two};
  box-shadow: ${({ theme }) => theme.shadow.level3};
`;

export const TooltipHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.sm}px;
  gap: ${({ theme }) => theme.spacing.xs}px;
  width: 100%;
`;

export const TooltipLabel = styled(Text).attrs({
  size: 'default',
  weight: 'bold',
})`
  color: ${({ theme }) => getValuations409AColor(theme)}!important;
`;

export const TooltipValueDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing.sm}px;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  background: ${({ theme }) => theme.color.general.layout.one};
`;
