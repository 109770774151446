import { Codebooks } from './codebooks.types';

const Items = {
  Stock: 'STOCK',
  Option: 'OPTION',
  RSU: 'RSU',
  Unit: 'UNIT',
  Warrant: 'WARRANT',
  Convertible: 'CONVERTIBLE',
  SL_SPV: 'SL_SPV',
  DL_SPV: 'DL_SPV',
} as const;

export type CbSecurityTypeCode = typeof Items[keyof typeof Items];

export const CbSecurityType = {
  code: Codebooks.SECURITY_TYPE,
  items: Items,
};
