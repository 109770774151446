import { type RouteDefinition } from '@npm/core/ui/routes';

export const ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE: RouteDefinition['roles']['overrideFn'] =
  ({ oboAccountId, oboUserId, allAccounts }) => {
    if ((!!oboAccountId && !!oboUserId) || allAccounts === true) {
      return {
        wasOverridden: false,
      };
    }
    return {
      newRole: {
        allAccounts: true,
        roleId: undefined,
        subjectId: undefined,
        oboAccountId: undefined,
        oboUserId: undefined,
        oboPersonId: undefined,
      },
      wasOverridden: true,
    };
  };
