import { useState } from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import { usePremiumPlan } from '../../role';
import { useUnlockPremium } from '../UnlockPremiumModal';

import * as S from './PremiumLockButton.styles';

type Props = {
  children?: React.ReactElement;
  width?: string;
  showDash?: boolean;
};

export const PremiumLockButton = ({ children, width, showDash }: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  const { isPremiumContentUnlocked } = usePremiumPlan();
  const unlockPremium = useUnlockPremium();

  const { isDesktop } = useBreakpoints();

  if (isPremiumContentUnlocked) return children || null;

  if (showDash) {
    return <Text colorVariant="muted">—</Text>;
  }

  return (
    <S.PremiumLockButton
      $hovered={isHovered}
      $width={width}
      variant="text"
      iconPosition="left"
      icon={<Icon name="lock" />}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      size="sm"
      onClick={unlockPremium}
    >
      {isDesktop && (
        <S.PremiumLockButtonContent $hovered={isHovered}>
          Unlock
        </S.PremiumLockButtonContent>
      )}
    </S.PremiumLockButton>
  );
};
