import React from 'react';
import { ThemeProvider } from 'styled-components';

import { lightTheme } from '@npm/core/ui/themes';

import { OktaSignUp } from './OktaSignUp';

export const SignUpPage = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <OktaSignUp />
    </ThemeProvider>
  );
};

export default SignUpPage;
