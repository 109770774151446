import { Codebooks } from './codebooks.types';

const Items = {
  Legal: 'LEGAL',
  Contact: 'CONTACT',
  Other: 'OTHER',
} as const;

export type CbAddressTypeCode = typeof Items[keyof typeof Items];

export const CbAddressType = {
  code: Codebooks.ADDRESS,
  items: Items,
};
