import React from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { getApiErrorCode, useUserShowCurrent } from '@npm/data-access';

import { useUserContextStore } from '../../auth/user/context';

export const withPerson = <T,>(Component: React.ComponentType<T>) => {
  return (hocProps: T) => {
    const handleError = useErrorHandler();

    const user = useUserContextStore(store => store.user);
    const setPerson = useUserContextStore(store => store.setPerson);

    useUserShowCurrent(
      {},
      {
        onComplete: data => {
          setPerson(data.person);
        },
        onError: e => {
          // ignoring 441 because it's handled in initTOSHandling
          if (getApiErrorCode(e) !== 441) {
            handleError(e);
          }
        },
        queryConfig: {
          enabled: !!user?.id,
        },
      }
    );

    return <Component {...hocProps} />;
  };
};
