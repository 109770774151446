import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';

import { usePremiumPlan } from '../../role/hooks/usePremiumPlan';
import { useUnlockPremium } from '../UnlockPremiumModal';

import * as S from './styles';

type Props = {
  children: React.ReactElement;
  style?: React.CSSProperties;
};

export const PremiumOverlay = ({ style, children }: Props) => {
  const { isPremiumContentUnlocked } = usePremiumPlan();
  const unlockPremiumAccount = useUnlockPremium();

  if (isPremiumContentUnlocked) return children;

  const overlay = (
    <S.Container style={style}>
      <S.UnlockModal>
        <div>
          <Icon name="lock" />
        </div>
        <div>
          <Heading variant="h2">Available on Premium</Heading>
          <Text colorVariant="secondary">
            Click below to learn more about our data <br /> offering plans.
          </Text>
        </div>
        <Button onClick={() => unlockPremiumAccount()}>Learn more</Button>
      </S.UnlockModal>
    </S.Container>
  );

  return (
    <>
      {children}
      <S.BlurOverlay>{overlay}</S.BlurOverlay>
    </>
  );
};
