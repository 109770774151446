import styled from 'styled-components';

import { centerFlex } from '@npm/core/ui/utils/style';

export { Heading } from '../OktaForm/OktaForm.styles';
export { FullWidthButton } from '../OktaSignUp.styles';

export const Container = styled.div`
  ${centerFlex};
  flex-direction: column;
  text-align: center;
`;
