import { Codebooks } from './codebooks.types';

const Items = {
  BoardMember: 'BOARD_MEMBER',
  Advisor: 'ADVISOR',
  Consultant: 'CONSULTANT',
  Employee: 'EMPLOYEE',
  ExAdvisor: 'EX_ADVISOR',
  ExConsultant: 'EX_CONSULTANT',
  ExEmployee: 'EX_EMPLOYEE',
  Executive: 'EXECUTIVE',
  Founder: 'FOUNDER',
  Investor: 'INVESTOR',
  NonUsEmployee: 'NON_US_EMPLOYEE',
  Officer: 'OFFICER',
  Other: 'OTHER',
} as const;

export type CbStakeholderRelationshipTypeCode =
  typeof Items[keyof typeof Items];

export const CbStakeholderRelationshipType = {
  code: Codebooks.STAKEHOLDER_RELATIONSHIP,
  items: Items,
};
