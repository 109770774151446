import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import {
  CbWorkstationType,
  useAccountBeneficialOwnersIndex,
} from '@npm/data-access';

import { useUserContextStore } from '../../../auth/user/context';
import { useCurrentWorkstation } from '../../../auth/user/role';
import { type BeneficialOwnersExemptions } from '../BeneficialOwnership.types';
import { ExemptAnswer } from '../ExemptAnswer';
import { useExemptAnswerData } from '../Form/ExemptionComplete/ExemptionComplete.utils';
import { OwnersTable } from '../OwnersTable';
import { ResetButton } from '../ResetButton';

type Props = {
  accountName: string;
  accountId: number;
  exemptions: BeneficialOwnersExemptions;
};

export const BeneficialOwnershipReadOnly = ({
  accountName,
  accountId,
  exemptions,
}: Props) => {
  const { entityExemption, ownerExemption } = useExemptAnswerData(exemptions);
  const workstation = useCurrentWorkstation();
  const { data, isLoading } = useAccountBeneficialOwnersIndex({
    accountId,
  });

  return (
    <>
      <Heading marginBottom="xl" variant="h2">
        Beneficial Ownership Assessment
      </Heading>

      <ExemptAnswer
        text={`Is ${accountName} excluded from the definition of “legal entity” customer under one of the categories listed below?`}
        answer={entityExemption}
        tooltipText={`${accountName} is one of the categories of exempt person (31 CFR 1020.315(2) – (5) ) for purposes of currency transaction reporting obligations`}
      />

      {ownerExemption && (
        <ExemptAnswer
          margin={false}
          text={`Is ${accountName} exempt from the beneficial ownership requirement under one of the categories listed below?`}
          answer={ownerExemption}
        />
      )}

      <Divider marginBottom={30} />

      {data?.beneficial_owners?.length > 0 && (
        <Alert
          message="Beneficial Owners Information"
          description="Identity Verification for each beneficial owner will be in a 'Pending' state until we complete our internal review. Once our review is completed, the Identity Verification state will reflect 'Passed'."
        />
      )}

      <OwnersTable
        readOnly
        accountId={accountId}
        owners={data?.beneficial_owners}
        isLoading={isLoading}
      />

      <Margin top="lg">
        {workstation?.type?.code === CbWorkstationType.items.brokerage ? (
          <ResetButton accountId={accountId} accountName={accountName} />
        ) : null}
      </Margin>
    </>
  );
};
