import {
  type CbLabelComponent,
  type IconNames,
  type IconProps,
  type LabelVariant,
} from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  none: 'none',
  pending: 'pending',
  failed: 'failed',
  passed: 'passed',
  expired: 'expired',
  more_info_needed: 'more_info_needed',
  not_started: 'not_started',
} as const;

type Keys = keyof typeof Items;

const LabelMap: Record<Keys, LabelVariant> = {
  none: 'general',
  expired: 'general',
  pending: 'warning',
  passed: 'success',
  more_info_needed: 'info',
  failed: 'error',
  not_started: 'general',
};

const LabelIconMap: Record<Keys, IconNames> = {
  none: 'info-circle',
  expired: 'warning',
  pending: 'history',
  passed: 'check',
  more_info_needed: 'info-circle',
  failed: 'warning',
  not_started: 'info-circle',
};

export const CbAccreditationState = {
  code: Codebooks.ACCREDITATION_STATE,
  items: Items,
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem | undefined,
    iconProps?: IconProps
  ) =>
    Component({
      variant: LabelMap[(codebookItem?.code as Keys) ?? 'none'],
      label: codebookItem?.name,
      iconProps: {
        name: LabelIconMap[(codebookItem?.code as Keys) ?? 'info-circle'],
        ...iconProps,
      },
    }),
};
