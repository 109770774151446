/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  BrokerageFirmApi,
  BrokerageFirmIndexErrorCodes,
  BrokerageFirmIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new BrokerageFirmApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type BrokerageFirmIndexMethod = BrokerageFirmApi['brokerageFirmIndex'];
export type BrokerageFirmIndexResponseType = MethodResult<BrokerageFirmIndexMethod>;

export const useBrokerageFirmIndex = (
  variables: Parameters<BrokerageFirmIndexMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmIndexMethod,
    BrokerageFirmIndexErrorTypes
  >
) => {
  return useQuery<
    BrokerageFirmIndexMethod,
    BrokerageFirmIndexErrorTypes
  >(
    (...args) => api.brokerageFirmIndex(...args),
    BrokerageFirmIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmIndex'],
        ...apiQueryConfig.BrokerageFirmIndex,
        ...config?.queryConfig
      },
    },
    'BrokerageFirmIndex',
  );
};

export const useBrokerageFirmIndexInfinite = (
  variables: Parameters<BrokerageFirmIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    BrokerageFirmIndexMethod,
    BrokerageFirmIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    BrokerageFirmIndexMethod,
    BrokerageFirmIndexErrorTypes
  >(
    (...args) => api.brokerageFirmIndex(...args),
    BrokerageFirmIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmIndex', 'BrokerageFirmIndexInfinite'],
        ...apiQueryConfig.BrokerageFirmIndex,
        ...config?.queryConfig,
      },
    },
    'BrokerageFirmIndex',
  );
};

export const useBrokerageFirmIndexLazy = (baseOptions?: {
  variables?: Parameters<BrokerageFirmIndexMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmIndexMethod,
    BrokerageFirmIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    BrokerageFirmIndexMethod,
    BrokerageFirmIndexErrorTypes
  >(
    (...args) => api.brokerageFirmIndex(...args),
    BrokerageFirmIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BrokerageFirmIndex'],
        ...apiQueryConfig.BrokerageFirmIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BrokerageFirmIndex',
  );
};

