const OPACITY_20 = '40'; // 20%
const OPACITY_05 = '0D'; // 5%

// create linear gradient used for area under line series
export const areaGradientStyles = (
  ctx: CanvasRenderingContext2D,
  primary: string
) => {
  const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.clientHeight);
  gradient.addColorStop(0, `${primary}${OPACITY_20}`);
  gradient.addColorStop(1, `${primary}${OPACITY_05}`);
  return gradient;
};
