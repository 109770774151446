import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';

import {
  filterRoutesByFeatureFlag,
  getAllRoutesRecursively,
} from '@npm/core/ui/routes/router.utils';
import { useFeatureFlags } from '@npm/features/app/featureFlags/featureFlags.context';
import { ErrorPath } from '@npm/features/routes/components/ErrorPath';
import { ProtectedRoute } from '@npm/features/routes/components/ProtectedRoute';

import { routes } from './routes.preplatform';

export const PreplatformRouter = () => {
  const { isEnabled } = useFeatureFlags();

  const allRoutes = useMemo(() => {
    return getAllRoutesRecursively(
      filterRoutesByFeatureFlag(isEnabled, routes)
    );
  }, []);

  const { authState } = useOktaAuth();

  if (authState?.isAuthenticated) {
    return <Redirect to={'/'} />;
  }

  return (
    <Switch>
      {allRoutes.map(route => {
        return route.needLogin !== false ? (
          <ProtectedRoute
            key={route.path}
            path={route.path}
            component={route.component}
            permissionsChecks={route.permissionsChecks}
            exact
          />
        ) : (
          <Route
            key={route.path}
            path={route.path}
            component={route.component}
            exact
          />
        );
      })}
      <Route>
        <ErrorPath
          heading="Error"
          title="Sorry, the page you visited does not exist."
          status="404"
        />
      </Route>
    </Switch>
  );
};
