import { type ComponentProps } from 'react';

import { VenusApi } from '@npm/data-access';

import { SectionStats } from '../../components/SectionStats';
import { type StatItem } from '../../components/SectionStats/StatItem';

type Props = {
  companyName: string | undefined;
  venusCompanyId?: number;
};

export const CapTableStats = ({ companyName, venusCompanyId }: Props) => {
  const { data, isFetching } = VenusApi.useCompanyCapTableMetadata(
    { id: venusCompanyId?.toString() },
    { queryConfig: { enabled: !!venusCompanyId } }
  );

  const items: ComponentProps<typeof StatItem>[] = [
    {
      key: 'total_issued_shares',
      title: 'Total Issued Shares',
      iconName: 'hand-check',
      value: data?.total_issued_shares,
      denomination: false,
      isPremium: true,
    },
    {
      key: 'option_pool',
      title: 'Option Pool',
      tooltipInfo: `This estimate is based on NPM analysis of ${companyName}'s public filings and its proprietary research.`,
      iconName: 'chart-pie',
      value: data?.option_pool,
      denomination: false,
      isPremium: true,
    },
    {
      key: 'est_fully_diluted_shares',
      title: 'Est Fully Diluted Shares',
      iconName: 'clipboard-text',
      value: data?.est_fully_diluted_shares,
      denomination: false,
      isPremium: true,
    },
  ];

  return (
    <SectionStats items={items} isLoading={isFetching} denomination={false} />
  );
};
