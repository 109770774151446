/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalOcfValuationIndex } from '../model';
// @ts-ignore
import { InternalOcfValuationShow } from '../model';
/**
 * OcfValuationsApi - axios parameter creator
 * @export
 */
export const OcfValuationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete an ocf_valuation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyOcfValuation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyOcfValuation', 'id', id)
            const localVarPath = changeUrl(`/api/internal/ocf_valuations/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all ocf_valuations
         * @param {string} [date] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {boolean} [primary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalIndexAllOcfValuation: async (date?: string, page?: number, size?: number, primary?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/internal/ocf_valuations`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (primary !== undefined) {
                localVarQueryParameter['primary'] = primary;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get ocf_valuations by company_id
         * @param {string} companyId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalIndexOcfValuation: async (companyId: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalIndexOcfValuation', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/ocf_valuations`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary return an ocf_valuation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowOcfValuation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalShowOcfValuation', 'id', id)
            const localVarPath = changeUrl(`/api/internal/ocf_valuations/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OcfValuationsApi - functional programming interface
 * @export
 */
export const OcfValuationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OcfValuationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete an ocf_valuation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyOcfValuation(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyOcfValuation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all ocf_valuations
         * @param {string} [date] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {boolean} [primary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalIndexAllOcfValuation(date?: string, page?: number, size?: number, primary?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalOcfValuationIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalIndexAllOcfValuation(date, page, size, primary, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get ocf_valuations by company_id
         * @param {string} companyId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalIndexOcfValuation(companyId: string, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalOcfValuationIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalIndexOcfValuation(companyId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary return an ocf_valuation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalShowOcfValuation(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalOcfValuationShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalShowOcfValuation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OcfValuationsApi - factory interface
 * @export
 */
export const OcfValuationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OcfValuationsApiFp(configuration)
    return {
        /**
         * 
         * @summary delete an ocf_valuation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyOcfValuation(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyOcfValuation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all ocf_valuations
         * @param {string} [date] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {boolean} [primary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalIndexAllOcfValuation(date?: string, page?: number, size?: number, primary?: boolean, options?: any): AxiosPromise<InternalOcfValuationIndex> {
            return localVarFp.internalIndexAllOcfValuation(date, page, size, primary, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get ocf_valuations by company_id
         * @param {string} companyId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalIndexOcfValuation(companyId: string, date?: string, options?: any): AxiosPromise<InternalOcfValuationIndex> {
            return localVarFp.internalIndexOcfValuation(companyId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary return an ocf_valuation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowOcfValuation(id: number, options?: any): AxiosPromise<InternalOcfValuationShow> {
            return localVarFp.internalShowOcfValuation(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for internalDestroyOcfValuation operation in OcfValuationsApi.
 * @export
 * @interface OcfValuationsApiInternalDestroyOcfValuationRequest
 */
export interface OcfValuationsApiInternalDestroyOcfValuationRequest {
    /**
     * 
     * @type {number}
     * @memberof OcfValuationsApiInternalDestroyOcfValuation
     */
    readonly id: number
}

/**
 * Request parameters for internalIndexAllOcfValuation operation in OcfValuationsApi.
 * @export
 * @interface OcfValuationsApiInternalIndexAllOcfValuationRequest
 */
export interface OcfValuationsApiInternalIndexAllOcfValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof OcfValuationsApiInternalIndexAllOcfValuation
     */
    readonly date?: string

    /**
     * 
     * @type {number}
     * @memberof OcfValuationsApiInternalIndexAllOcfValuation
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof OcfValuationsApiInternalIndexAllOcfValuation
     */
    readonly size?: number

    /**
     * 
     * @type {boolean}
     * @memberof OcfValuationsApiInternalIndexAllOcfValuation
     */
    readonly primary?: boolean
}

/**
 * Request parameters for internalIndexOcfValuation operation in OcfValuationsApi.
 * @export
 * @interface OcfValuationsApiInternalIndexOcfValuationRequest
 */
export interface OcfValuationsApiInternalIndexOcfValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof OcfValuationsApiInternalIndexOcfValuation
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof OcfValuationsApiInternalIndexOcfValuation
     */
    readonly date?: string
}

/**
 * Request parameters for internalShowOcfValuation operation in OcfValuationsApi.
 * @export
 * @interface OcfValuationsApiInternalShowOcfValuationRequest
 */
export interface OcfValuationsApiInternalShowOcfValuationRequest {
    /**
     * 
     * @type {number}
     * @memberof OcfValuationsApiInternalShowOcfValuation
     */
    readonly id: number
}

/**
 * OcfValuationsApi - object-oriented interface
 * @export
 * @class OcfValuationsApi
 * @extends {BaseAPI}
 */
export class OcfValuationsApi extends BaseAPI {
    /**
     * 
     * @summary delete an ocf_valuation
     * @param {OcfValuationsApiInternalDestroyOcfValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcfValuationsApi
     */
    public internalDestroyOcfValuation(requestParameters: OcfValuationsApiInternalDestroyOcfValuationRequest, options?: AxiosRequestConfig) {
        return OcfValuationsApiFp(this.configuration).internalDestroyOcfValuation(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all ocf_valuations
     * @param {OcfValuationsApiInternalIndexAllOcfValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcfValuationsApi
     */
    public internalIndexAllOcfValuation(requestParameters: OcfValuationsApiInternalIndexAllOcfValuationRequest = {}, options?: AxiosRequestConfig) {
        return OcfValuationsApiFp(this.configuration).internalIndexAllOcfValuation(requestParameters.date, requestParameters.page, requestParameters.size, requestParameters.primary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get ocf_valuations by company_id
     * @param {OcfValuationsApiInternalIndexOcfValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcfValuationsApi
     */
    public internalIndexOcfValuation(requestParameters: OcfValuationsApiInternalIndexOcfValuationRequest, options?: AxiosRequestConfig) {
        return OcfValuationsApiFp(this.configuration).internalIndexOcfValuation(requestParameters.companyId, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary return an ocf_valuation
     * @param {OcfValuationsApiInternalShowOcfValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcfValuationsApi
     */
    public internalShowOcfValuation(requestParameters: OcfValuationsApiInternalShowOcfValuationRequest, options?: AxiosRequestConfig) {
        return OcfValuationsApiFp(this.configuration).internalShowOcfValuation(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InternalDestroyOcfValuationErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalDestroyOcfValuationErrorCodes = [
];

export type InternalIndexAllOcfValuationErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalIndexAllOcfValuationErrorCodes = [
    401,
];

export type InternalIndexOcfValuationErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalIndexOcfValuationErrorCodes = [
    401,
    404,
];

export type InternalShowOcfValuationErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalShowOcfValuationErrorCodes = [
    404,
];


