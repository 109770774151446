import { Icon } from '@npm/core/ui/components/atoms/Icon';

import * as S from './IconBox.styles';

export const IconBox = () => {
  return (
    <S.IconWrapper>
      <Icon name="badge" size="xl" />
    </S.IconWrapper>
  );
};
