import {
  type Codebook,
  type NegotiationIndexItemAggregate,
  type NegotiationShowAggregate,
  CbMatchState,
  CbNegotiationStatus,
} from '@npm/data-access';

import {
  type OpportunityStates,
  opportunityState,
} from '../Opportunities.types';

export const getOpportunityState = (
  negotiation: NegotiationIndexItemAggregate | NegotiationShowAggregate,
  matchState: Codebook | undefined,
  hasTransactionConfirmationDocs: boolean | undefined,
  isRofrBlocked?: boolean
): OpportunityStates => {
  if (isRofrBlocked || matchState?.code === CbMatchState.items.rofr_blocked) {
    return opportunityState.rofrRejected;
  }

  if (negotiation?.state?.code === CbNegotiationStatus.items.in_progress) {
    return opportunityState.match;
  }

  if (negotiation?.state?.code === CbNegotiationStatus.items.rejected) {
    return opportunityState.rejected;
  }

  if (
    negotiation?.state?.code === CbNegotiationStatus.items.in_contract &&
    matchState?.code !== CbMatchState.items.funded &&
    matchState?.code !== CbMatchState.items.wired &&
    matchState?.code !== CbMatchState.items.ready_to_settle
  ) {
    return opportunityState.transfer;
  }

  if (
    (matchState?.code === CbMatchState.items.funded ||
      matchState?.code === CbMatchState.items.wired) &&
    hasTransactionConfirmationDocs
  ) {
    return opportunityState.complete;
  }

  if (
    matchState?.code === CbMatchState.items.funded ||
    matchState?.code === CbMatchState.items.wired ||
    matchState?.code === CbMatchState.items.ready_to_settle
  ) {
    return opportunityState.getPaid;
  }

  return null;
};
