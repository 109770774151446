import { endOfQuarter, startOfQuarter, subQuarters } from 'date-fns';

import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';

import { type ReportingPeriodFilterItem } from './ReportingPeriodFilter.types';

export const getReportingPeriodFilterOptions =
  (): ReportingPeriodFilterItem[] => {
    const items: ReportingPeriodFilterItem[] = [];

    const now = new Date();

    for (let i = 0; i < 8; i++) {
      const quarterStart = startOfQuarter(subQuarters(now, i));
      const quarterEnd = endOfQuarter(quarterStart);

      const quarter = formatDate(quarterStart.toISOString(), {
        dateFormat: DATE_FORMATS.QUARTER_AND_YEAR,
      });

      const label = `${quarter} (${formatDate(quarterStart.toISOString(), {
        dateFormat: DATE_FORMATS.MONTH_AND_DAY,
      })} - ${formatDate(quarterEnd.toISOString(), {
        dateFormat: DATE_FORMATS.MONTH_AND_DAY,
      })})`;

      items.push({
        label,
        startDate: quarterStart.toISOString(),
        endDate: quarterEnd.toISOString(),
      });
    }

    return items;
  };
