import { Fragment } from 'react';
import { type InfiniteData } from '@tanstack/react-query';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { InfiniteScroll } from '@npm/core/ui/components/atoms/InfiniteScroll';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { type EventIndex, CbWorkstationType } from '@npm/data-access';

import { useCurrentWorkstation } from '../../auth/user/role';
import { ProgramCard } from '../ProgramCard';
import { LoadingProgramCard } from '../ProgramCard/LoadingProgramCard';
import { type ProgramProps } from '../ProgramCard/ProgramCard.types';

import * as S from './ProgramsList.styles';

export const PROGRAMS_LIST_ELEMENT_ID = 'programs-list-scroll-id';

type CardProps = Omit<ProgramProps, 'event' | 'isLoading'>;

type Props = {
  data: InfiniteData<EventIndex> | undefined;
  isFetching: boolean;
  hasMore: boolean | undefined;
  fetchMore: () => void;
  noDataText: string;
  cardProps?: CardProps;
  showAccessProgramInfo?: boolean;
};

export const ProgramsList = ({
  data,
  isFetching,
  hasMore,
  fetchMore,
  noDataText,
  cardProps,
  showAccessProgramInfo = true,
}: Props) => {
  const workstation = useCurrentWorkstation();
  const isIssuerWS = workstation?.type?.code === CbWorkstationType.items.issuer;

  return (
    <S.Container>
      {data?.pages?.[0]?.pagination?.total_records > 0 &&
        showAccessProgramInfo && (
          <Alert
            message={`You can access multiple programs in this portal. Click ${
              isIssuerWS ? 'Dashboard' : 'Access'
            } to navigate to the appropriate program`}
            type="info"
            showIcon
            closable
          />
        )}
      <S.Content id={PROGRAMS_LIST_ELEMENT_ID}>
        {data &&
          !isFetching &&
          data.pages[0].pagination?.total_records === 0 && (
            <NoDataArea outlined title={noDataText} />
          )}
        <S.ProgramsGrid>
          <InfiniteScroll
            loadMore={fetchMore}
            hasMore={hasMore}
            isFetching={isFetching}
            loader={
              <S.ProgramWrap>
                <LoadingProgramCard status="live" />
              </S.ProgramWrap>
            }
            loaderCount={3}
            mobileLoaderCount={2}
          >
            {data?.pages.map(page => (
              <Fragment key={page?.pagination?.page}>
                {page.events?.map(event => (
                  <S.ProgramWrap key={event.id}>
                    <ProgramCard {...cardProps} event={event} />
                  </S.ProgramWrap>
                ))}
              </Fragment>
            ))}
          </InfiniteScroll>
        </S.ProgramsGrid>
      </S.Content>
    </S.Container>
  );
};
