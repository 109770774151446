import React from 'react';

import { Button } from '../../../atoms/Button';
import { useHistory } from '../../../molecules/Link';

import * as S from './Navigation.styles';

type Prop = {
  title: string;
  url: string;
  icon?: React.ReactNode;
};

export const NavigationButton = ({ title, url, icon }: Prop) => {
  const history = useHistory();

  return (
    <S.NavigationButtonWrapper>
      <Button onClick={() => history.push(url)} icon={icon} block>
        {title}
      </Button>
    </S.NavigationButtonWrapper>
  );
};
