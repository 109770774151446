import styled from 'styled-components';

import { centerFlex } from '../../../utils/style';

import { type ButtonVariant } from './ShowMore';

export const Wrapper = styled.div<{ $variant: ButtonVariant }>`
  display: inline-flex;

  ${({ $variant, theme }) =>
    $variant === 'blockWithChevron' &&
    `
    height: ${theme.spacing.lg}px;
    ${centerFlex}

    svg {
        vertical-align: middle;
        margin-top: -1px;
        margin-right: ${theme.spacing.xs}px;
    }
  `}
`;
