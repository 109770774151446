import React, { type ComponentProps } from 'react';

import { Select } from '@npm/core/ui/components/atoms/Select';

import { type FilterItemProps, type FilterPanel } from '../../../../../filters';
import { type MutualFundFilterValues } from '../../MutualFundMarks.types';

import { type MutualFundMarksFilterFormValues } from './MutualFundMarksFilter.types';

export const getMutualFundMarksFilterItems = (
  filterValues: MutualFundFilterValues
): ComponentProps<
  typeof FilterPanel<MutualFundMarksFilterFormValues>
>['filters'] => [
  {
    name: 'parentName',
    label: 'Parent Entity',
    render: props => getTextSelect({ ...props, options: filterValues.parents }),
  },
  {
    name: 'assetType',
    label: 'Asset Type',
    render: props =>
      getTextSelect({ ...props, options: filterValues.assetTypes }),
  },
];

type TextSelectProps = {
  options: string[];
} & FilterItemProps;

const getTextSelect = ({ name, onFilterSubmit, options }: TextSelectProps) => {
  return (
    <Select
      variant={'select'}
      style={{ width: '100%' }}
      placeholder={`All (${options.length})`}
      allowClear
      onSelect={() => {
        onFilterSubmit?.(name);
      }}
      onClear={() => onFilterSubmit?.(name)}
    >
      {options.map(option => (
        <Select.Option key={option} value={option}>
          {option}
        </Select.Option>
      ))}
    </Select>
  );
};
