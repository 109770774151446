import Big from 'big.js';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type EventAggregate,
  type FeeConfiguration,
  CbOwnerType,
} from '@npm/data-access';

type Props = {
  event: EventAggregate;
  feeConfiguration: FeeConfiguration | undefined;
};

export const ProgramFees = ({
  event: { type, issuance, issuer_entity },
  feeConfiguration,
}: Props) => {
  const {
    percentage_buyer_fee,
    percentage_seller_fee,
    seller_account_flat_fee,
    seller_event_split_fee_max,
    seller_event_split_fee,
    seller_per_share_fee,
  } = feeConfiguration ?? {};

  const currency =
    issuance?.denomination?.name ?? issuer_entity?.denomination?.name ?? 'USD';

  if (type.code === CbOwnerType.items.DutchAuctionBuy) {
    if (!percentage_buyer_fee) return <>--</>;
    return <>{percentage_buyer_fee}% from Gross Purch. Price</>;
  }

  if (type.code === CbOwnerType.items.DutchAuctionSell) {
    if (!percentage_seller_fee) return <>--</>;
    return <>{percentage_seller_fee}% from Gross Proceeds</>;
  }

  if (type.code === CbOwnerType.items.TenderOffer) {
    const nonTradingFees = new Big(seller_account_flat_fee ?? 0)
      .add(seller_event_split_fee_max ?? seller_event_split_fee ?? 0)
      .toNumber();

    if (nonTradingFees === 0 && !seller_per_share_fee) {
      return <>--</>;
    }

    return (
      <>
        {!!nonTradingFees && (
          <Text.Price value={nonTradingFees} currency={currency} />
        )}
        {!!nonTradingFees && !!seller_per_share_fee && (
          <>
            {' +'}
            <br />
          </>
        )}
        {!!seller_per_share_fee && (
          <>
            <Text.Price value={seller_per_share_fee} currency={currency} />
            /share
          </>
        )}
      </>
    );
  }

  return <>--</>;
};
