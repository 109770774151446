import React from 'react';

import { useDarkModeContext } from '../../../context/DarkModeContext';

const LOGO_DARK = '/public/assets/logo-dark.svg';
const LOGO_LIGHT = '/public/assets/logo-light.svg';

export const Logo = () => {
  const { isDarkMode } = useDarkModeContext();
  return (
    <img
      src={isDarkMode ? LOGO_DARK : LOGO_LIGHT}
      alt="NPM logo"
      height={27}
      width={195}
    />
  );
};
