import { useEffect, useMemo } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { type DefaultTheme } from 'styled-components';

import { useBreakpoints } from '../../../hooks/useBreakpoints';

import type { MaxWidthValues } from './Layout.store';
import { useLayoutStore } from './Layout.store';

import { APP_HEADER_MARGIN } from './Layout.styles';

export const useCustomLayoutBackground = (
  bg: keyof DefaultTheme['color']['general']['layout']
) => {
  const setBg = useLayoutStore(store => store.setBackground);
  const resetBg = useLayoutStore(store => store.resetBackground);

  useEffect(() => {
    setBg(bg);
    return () => {
      resetBg();
    };
  }, []);
};

export const useCustomLayoutMaxWidth = (maxWidth: MaxWidthValues) => {
  const setMaxWidth = useLayoutStore(store => store.setMaxWidth);
  const resetMaxWidth = useLayoutStore(store => store.resetMaxWidth);

  useEffect(() => {
    setMaxWidth(maxWidth);
    return () => {
      resetMaxWidth();
    };
  }, []);
};

export const useFullWidthLayout = () => {
  const setFullWidth = useLayoutStore(store => store.setFullWidth);
  const resetFullWidth = useLayoutStore(store => store.resetFullWidth);

  useEffect(() => {
    setFullWidth();
    return () => {
      resetFullWidth();
    };
  }, []);
};

export const useNoPaddingLayout = () => {
  const setNoPadding = useLayoutStore(store => store.setNoPadding);
  const resetNoPadding = useLayoutStore(store => store.resetNoPadding);

  useEffect(() => {
    setNoPadding();
    return () => {
      resetNoPadding();
    };
  }, []);
};

export const useNoBottomPaddingLayout = () => {
  const setBottomPadding = useLayoutStore(store => store.setNoBottomPadding);
  const resetNoBottomPadding = useLayoutStore(
    store => store.resetNoBottomPadding
  );

  useEffect(() => {
    setBottomPadding();
    return () => {
      resetNoBottomPadding();
    };
  });
};

export const useLayoutFlex = () => {
  const setLayoutFlex = useLayoutStore(store => store.setLayoutFlex);
  const resetLayoutFlex = useLayoutStore(store => store.resetLayoutFlex);

  useEffect(() => {
    setLayoutFlex();
    return () => {
      resetLayoutFlex();
    };
  }, []);
};

export const useLayoutConfig = (): {
  isMobile: boolean;
  isTablet: boolean;
  header: {
    height: number;
    margin: number;
  };
} => {
  const { isMobile: isMobileBreakpoint, isTablet: isTableBreakpoint } =
    useBreakpoints();
  const headerHeight = useLayoutStore(store => store.headerHeight);

  const isMb = isMobile || isMobileBreakpoint;
  const isTb = isTablet || isTableBreakpoint;

  return useMemo(() => {
    return {
      isMobile: isMb,
      isTablet: isTb,
      header: {
        height: headerHeight,
        margin: APP_HEADER_MARGIN,
      },
    };
  }, [isMb, isTb, headerHeight]);
};
