import { Select } from '@npm/core/ui/components/atoms/Select';
import { VenusApi } from '@npm/data-access';

import { type FilterPanelItem } from '../../../filters';
import {
  DataAvailabilities,
  dataAvailabilityLabels,
} from '../CompaniesData.types';

import { type CompaniesDataFilterFormValues } from './CompaniesDataFilter.types';

const { useCompaniesOverviewsSectors } = VenusApi;

type GetCompaniesDataConfigOptions = {
  sectorQuery: ReturnType<typeof useCompaniesOverviewsSectors>;
};

export const getCompaniesDataFilters = (
  options: GetCompaniesDataConfigOptions
) =>
  [
    {
      name: 'dataAvailabilities',
      label: 'Available Data Category',
      alwaysVisible: true,
      render: props => {
        return (
          <Select
            {...props}
            allowClear
            mode="multiple"
            options={Object.values(DataAvailabilities).map(availability => ({
              label: dataAvailabilityLabels[availability],
              value: availability,
            }))}
            onChange={value => props.onFilterSubmit?.(value)}
          />
        );
      },
    },
    {
      name: 'sectors',
      label: 'Sectors',
      alwaysVisible: true,
      render: props => (
        <Select
          {...props}
          allowClear
          mode="multiple"
          loading={options.sectorQuery.isLoading}
          onChange={value => props.onFilterSubmit?.(value)}
          options={options.sectorQuery.data?.sectors?.map(sector => ({
            label: sector.name,
            value: sector.name,
          }))}
        />
      ),
    },
  ] satisfies FilterPanelItem<CompaniesDataFilterFormValues>[];
