import styled from 'styled-components';

import { FormItem as FormItemBase } from '@npm/core/ui/components/atoms/FormItem';
import { Input as InputBase } from '@npm/core/ui/components/atoms/Input';
import {
  BodyTextBoldVariationSettings,
  Heading as HeadingBase,
  Text as TextBase,
} from '@npm/core/ui/components/atoms/Typography';
import { centerFlex } from '@npm/core/ui/utils/style';

import { paddingLg } from '../OktaSignUp.styles';

export { FullWidthButton } from '../OktaSignUp.styles';

export const Header = styled.div`
  ${centerFlex};
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  text-align: center;
`;

export const SubmitBtnContainer = styled.div`
  ${({ theme }) => paddingLg(theme, 'top')};
`;

export const BottomLinks = styled.div`
  cursor: pointer;
  ${({ theme }) => paddingLg(theme, 'top')};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm}px;
`;

export const FormItem = styled(FormItemBase).attrs({
  labelPosition: 'top',
})`
  ${({ theme }) => paddingLg(theme, 'bottom')};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Input = styled(InputBase)`
  min-height: 40px !important;
`;

export const Heading = styled(HeadingBase)`
  color: ${({ theme }) => theme.color.general.typography.secondary};
  ${BodyTextBoldVariationSettings};
`;

export const TextLink = styled(TextBase)`
  color: ${({ theme }) => theme.color.general.typography.secondary} !important;
  text-decoration: underline;
`;
