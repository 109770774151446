import { format } from 'date-fns';

export const downloadBlob = (blob: Blob, fileName: string) => {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;
  a.click();
};
export const downloadText = (response, prefix = 'LOG_') => {
  const time = format(new Date(), 'MMM_dd_yyyy_hh_mm_aaa');
  const fileName = prefix + time;
  const blob = new Blob([response], { type: 'text/csv' });
  downloadBlob(blob, fileName);
};
