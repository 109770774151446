/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalOptionPlanIndex } from '../model';
// @ts-ignore
import { InternalOptionPlanShow } from '../model';
// @ts-ignore
import { InternalOptionPlanUpdateRequestContract } from '../model';
/**
 * OptionPlansApi - axios parameter creator
 * @export
 */
export const OptionPlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get option_plans for a company
         * @param {string} companyId 
         * @param {string} [date] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalOptionPlanIndex: async (companyId: string, date?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalOptionPlanIndex', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/option_plans`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get an option_plan by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowOptionPlan: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalShowOptionPlan', 'id', id)
            const localVarPath = changeUrl(`/api/internal/option_plans/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update an option_plan
         * @param {number} id 
         * @param {InternalOptionPlanUpdateRequestContract} [internalOptionPlanUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateOptionPlan: async (id: number, internalOptionPlanUpdateRequestContract?: InternalOptionPlanUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateOptionPlan', 'id', id)
            const localVarPath = changeUrl(`/api/internal/option_plans/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalOptionPlanUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete an option_plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionPlansDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('optionPlansDelete', 'id', id)
            const localVarPath = changeUrl(`/api/option_plans/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OptionPlansApi - functional programming interface
 * @export
 */
export const OptionPlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OptionPlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get option_plans for a company
         * @param {string} companyId 
         * @param {string} [date] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalOptionPlanIndex(companyId: string, date?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalOptionPlanIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalOptionPlanIndex(companyId, date, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get an option_plan by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalShowOptionPlan(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalOptionPlanShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalShowOptionPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update an option_plan
         * @param {number} id 
         * @param {InternalOptionPlanUpdateRequestContract} [internalOptionPlanUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateOptionPlan(id: number, internalOptionPlanUpdateRequestContract?: InternalOptionPlanUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalOptionPlanShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateOptionPlan(id, internalOptionPlanUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete an option_plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionPlansDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionPlansDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OptionPlansApi - factory interface
 * @export
 */
export const OptionPlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OptionPlansApiFp(configuration)
    return {
        /**
         * 
         * @summary get option_plans for a company
         * @param {string} companyId 
         * @param {string} [date] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalOptionPlanIndex(companyId: string, date?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalOptionPlanIndex> {
            return localVarFp.internalOptionPlanIndex(companyId, date, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get an option_plan by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowOptionPlan(id: number, options?: any): AxiosPromise<InternalOptionPlanShow> {
            return localVarFp.internalShowOptionPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update an option_plan
         * @param {number} id 
         * @param {InternalOptionPlanUpdateRequestContract} [internalOptionPlanUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateOptionPlan(id: number, internalOptionPlanUpdateRequestContract?: InternalOptionPlanUpdateRequestContract, options?: any): AxiosPromise<InternalOptionPlanShow> {
            return localVarFp.internalUpdateOptionPlan(id, internalOptionPlanUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete an option_plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionPlansDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.optionPlansDelete(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for internalOptionPlanIndex operation in OptionPlansApi.
 * @export
 * @interface OptionPlansApiInternalOptionPlanIndexRequest
 */
export interface OptionPlansApiInternalOptionPlanIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof OptionPlansApiInternalOptionPlanIndex
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof OptionPlansApiInternalOptionPlanIndex
     */
    readonly date?: string

    /**
     * 
     * @type {string}
     * @memberof OptionPlansApiInternalOptionPlanIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof OptionPlansApiInternalOptionPlanIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalShowOptionPlan operation in OptionPlansApi.
 * @export
 * @interface OptionPlansApiInternalShowOptionPlanRequest
 */
export interface OptionPlansApiInternalShowOptionPlanRequest {
    /**
     * 
     * @type {number}
     * @memberof OptionPlansApiInternalShowOptionPlan
     */
    readonly id: number
}

/**
 * Request parameters for internalUpdateOptionPlan operation in OptionPlansApi.
 * @export
 * @interface OptionPlansApiInternalUpdateOptionPlanRequest
 */
export interface OptionPlansApiInternalUpdateOptionPlanRequest {
    /**
     * 
     * @type {number}
     * @memberof OptionPlansApiInternalUpdateOptionPlan
     */
    readonly id: number

    /**
     * 
     * @type {InternalOptionPlanUpdateRequestContract}
     * @memberof OptionPlansApiInternalUpdateOptionPlan
     */
    readonly internalOptionPlanUpdateRequestContract?: InternalOptionPlanUpdateRequestContract
}

/**
 * Request parameters for optionPlansDelete operation in OptionPlansApi.
 * @export
 * @interface OptionPlansApiOptionPlansDeleteRequest
 */
export interface OptionPlansApiOptionPlansDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof OptionPlansApiOptionPlansDelete
     */
    readonly id: number
}

/**
 * OptionPlansApi - object-oriented interface
 * @export
 * @class OptionPlansApi
 * @extends {BaseAPI}
 */
export class OptionPlansApi extends BaseAPI {
    /**
     * 
     * @summary get option_plans for a company
     * @param {OptionPlansApiInternalOptionPlanIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionPlansApi
     */
    public internalOptionPlanIndex(requestParameters: OptionPlansApiInternalOptionPlanIndexRequest, options?: AxiosRequestConfig) {
        return OptionPlansApiFp(this.configuration).internalOptionPlanIndex(requestParameters.companyId, requestParameters.date, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get an option_plan by ID
     * @param {OptionPlansApiInternalShowOptionPlanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionPlansApi
     */
    public internalShowOptionPlan(requestParameters: OptionPlansApiInternalShowOptionPlanRequest, options?: AxiosRequestConfig) {
        return OptionPlansApiFp(this.configuration).internalShowOptionPlan(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update an option_plan
     * @param {OptionPlansApiInternalUpdateOptionPlanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionPlansApi
     */
    public internalUpdateOptionPlan(requestParameters: OptionPlansApiInternalUpdateOptionPlanRequest, options?: AxiosRequestConfig) {
        return OptionPlansApiFp(this.configuration).internalUpdateOptionPlan(requestParameters.id, requestParameters.internalOptionPlanUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete an option_plan
     * @param {OptionPlansApiOptionPlansDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionPlansApi
     */
    public optionPlansDelete(requestParameters: OptionPlansApiOptionPlansDeleteRequest, options?: AxiosRequestConfig) {
        return OptionPlansApiFp(this.configuration).optionPlansDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InternalOptionPlanIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalOptionPlanIndexErrorCodes = [
    401,
    404,
];

export type InternalShowOptionPlanErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalShowOptionPlanErrorCodes = [
    404,
];

export type InternalUpdateOptionPlanErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalUpdateOptionPlanErrorCodes = [
    404,
];

export type OptionPlansDeleteErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const OptionPlansDeleteErrorCodes = [
    401,
    404,
];


