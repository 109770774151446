import {
  TableCellDivider,
  TableHeaderCellDivider,
} from './TableComponents.styles';

const HEADER_DIVIDER = (
  <TableHeaderCellDivider type="vertical" colorVariant="secondary" />
);
const DIVIDER = <TableCellDivider type="vertical" colorVariant="secondary" />;

export const TableDividerColumn = {
  title: HEADER_DIVIDER,
  render() {
    return DIVIDER;
  },
};
