import React, { type ComponentProps, Fragment, useState } from 'react';
import { type FormInstance } from 'antd/es/form/Form';

import { Chip } from '@npm/core/ui/components/atoms/Chip';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { Image } from '@npm/core/ui/components/atoms/Image';
import { InfiniteScroll } from '@npm/core/ui/components/atoms/InfiniteScroll';
import { useTotalRecords } from '@npm/core/ui/hooks/useTotalRecords';
import { getFullImageUrl } from '@npm/core/ui/utils/path/Path.utils';
import {
  type IssuerEntityAggregate,
  type IssuerEntityApiIssuerEntityIndexRequest,
  type WatchlistCreateRequestContract,
  useIssuerEntityIndexInfinite,
} from '@npm/data-access';

import { CompanyCardSkeleton } from '../../../../company';
import { useRequestCompanyCoverage } from '../../../../company/RequestCompanyCoverage';
import { SearchFilter } from '../../../../filters';

import { CompanyCardWithForm } from './CompanyCardWithForm';

import * as S from './CompanyCards.styles';

type FilterValues = Pick<IssuerEntityApiIssuerEntityIndexRequest, 'search'>;

type Props = {
  formsRef: React.MutableRefObject<
    Record<number, FormInstance<WatchlistCreateRequestContract>>
  >;
  formsValues: Record<number, WatchlistCreateRequestContract>;
  selectedCompanies: IssuerEntityAggregate[];
  onSelect: (company: IssuerEntityAggregate) => void;
  companyCardProps?: Partial<ComponentProps<typeof CompanyCardWithForm>>;
  onFilterSubmit?: () => void;
};

export const CompanyCards = ({
  formsRef,
  formsValues,
  selectedCompanies = [],
  onSelect,
  companyCardProps,
  onFilterSubmit,
}: Props) => {
  const [searchForm] = Form.useForm<FilterValues>();
  const [search, setSearch] = useState('');

  const { requestCoverageAreaComponent } = useRequestCompanyCoverage({
    searchTerm: search,
  });

  const { data, isFetching, isRefetching, fetchNextPage, hasNextPage } =
    useIssuerEntityIndexInfinite({
      search,
      unverifiedHoldings: true,
      watchlistHide: true,
    });

  const totalRecords = useTotalRecords(data);

  const isSelectedCompany = (company: IssuerEntityAggregate) =>
    selectedCompanies?.some(c => c.id === company.id);

  // Don't show loader when refetching
  // In cases where refetch leads to data being removed it looks weird to show loader.
  const isFetchingOnly = isFetching && !isRefetching;

  const handleSubmit = () => {
    onFilterSubmit?.();
    setSearch(searchForm.getFieldValue('search'));
  };

  return (
    <>
      <Margin bottom="sm">
        <Form form={searchForm}>
          <FormItem name="search">
            <SearchFilter onFilterSubmit={handleSubmit} />
          </FormItem>
        </Form>
      </Margin>
      {selectedCompanies.length
        ? selectedCompanies.map(c => (
            <Chip
              key={c.id}
              icon={
                <Image
                  width={24}
                  height={24}
                  preview={false}
                  src={getFullImageUrl(c.logo_url)}
                />
              }
              $type="logo"
              closable
              onClose={() => onSelect(c)}
            >
              {c.name}
            </Chip>
          ))
        : null}
      <S.CardsWrapper>
        <InfiniteScroll
          loadMore={fetchNextPage}
          hasMore={hasNextPage}
          isFetching={isFetchingOnly}
          loader={<CompanyCardSkeleton />}
        >
          {data?.pages?.map(page => (
            <Fragment key={page.pagination.page}>
              {page.issuer_entities?.map(company => {
                return (
                  <CompanyCardWithForm
                    key={company.id}
                    formRef={formInstance =>
                      (formsRef.current[company.id] = formInstance)
                    }
                    company={company}
                    isSelected={isSelectedCompany(company)}
                    onSelect={() => {
                      onSelect?.(company);
                    }}
                    initialValues={formsValues[company.id]}
                    {...companyCardProps}
                  />
                );
              })}
            </Fragment>
          ))}
        </InfiniteScroll>
        {!isFetchingOnly && !totalRecords && requestCoverageAreaComponent}
      </S.CardsWrapper>
    </>
  );
};
