import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useIssuerEntityForMarketFeedIndex } from '@npm/data-access';
import { useCompanySelect } from '@npm/features/second-market/company-select/CompanySelectDrawer';

import { CompanyCard } from './components/CompanyCard';

import {
  CarouselCointainer,
  CarouselSlide,
  CompanySelectButton,
  CompanySelectButtonWrapper,
} from './CompanyCarousel.styles';

type Props = {
  toggleCompanySelectDrawer: () => void;
};

export const CompanyCarousel = ({ toggleCompanySelectDrawer }: Props) => {
  const { data } = useIssuerEntityForMarketFeedIndex({});
  const { isMobile, isXlDesktop } = useBreakpoints();
  const { onCompanySelect } = useCompanySelect();

  return (
    <CarouselCointainer isDesktop={isXlDesktop}>
      {!isXlDesktop && (
        <CompanySelectButtonWrapper>
          <CompanySelectButton
            variant="outline"
            size="md"
            onClick={toggleCompanySelectDrawer}
            icon={isMobile ? <Icon name="list" /> : null}
          >
            {isMobile ? null : (
              <>
                View All Markets <Icon name="chevron-right" />
              </>
            )}
          </CompanySelectButton>
        </CompanySelectButtonWrapper>
      )}
      <CarouselSlide>
        {new Array(8)
          .fill(null)
          .map(i =>
            data?.issuer_entities?.map(company => (
              <CompanyCard
                key={`${i}-${company?.id}`}
                company={company}
                onCompanySelect={onCompanySelect}
              />
            ))
          )}
      </CarouselSlide>
    </CarouselCointainer>
  );
};
