import styled from 'styled-components';

import { centerFlex, centerSelfAbsolute, getVh } from '../../../utils/style';
import { Loader as BaseLoader } from '../../atoms/Loader';

export const Wrapper = styled.div<{
  $fitToViewport: boolean;
  $isPositionAbsolute: boolean;
}>`
  position: relative;
  ${({ $fitToViewport }) => getVh($fitToViewport ? 90 : 100)}
  ${centerFlex}
  flex-direction: column;
  background: ${({ theme }) => theme.color.general.layout.zero};
  padding-bottom: 48px;

  ${({ theme, $isPositionAbsolute }) =>
    $isPositionAbsolute &&
    `
  position: absolute;
  inset: 0;
  z-index: ${theme.zIndex.aboveAll};
  `}
`;

export const CenteredContentWrapper = styled.div`
  ${centerFlex}
  ${centerSelfAbsolute}
  flex-direction: column;
  text-align: center;
`;

export const LoaderWrapper = styled.div`
  ${centerFlex};
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.general.layout.two};
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`;

export const Loader = styled(BaseLoader)`
  width: auto;
`;

export const LogoWrapper = styled.div`
  margin-top: auto;
`;
