import {
  type MutableRefObject,
  type ReactElement,
  type RefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { type TabPaneProps } from 'antd';

export const useOverflowedTabs = (
  containerRef: MutableRefObject<HTMLElement> | RefObject<HTMLElement>,
  tabPanes: ReactElement<TabPaneProps>[]
) => {
  // based on conditional rendering, sometimes "false" comes
  const validTabPanes = useMemo(
    () => tabPanes.filter(tb => tb?.props),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabPanes?.map(v => v?.props?.tab).join()]
  );

  const [tabs, setTabs] = useState<
    {
      key: string;
      name: string;
      visible: boolean;
    }[]
  >([]);

  const handleIntersect = entries => {
    entries.forEach(entry => {
      const tabName = entry.target.textContent;
      setTabs(prevTabs =>
        prevTabs.map(tab => {
          if (tab.name === tabName) {
            return {
              ...tab,
              visible: entry.isIntersecting,
            };
          } else {
            return tab;
          }
        })
      );
    });
  };

  useEffect(() => {
    // reset observers if number of tabs changes
    setTabs(
      validTabPanes.map(tabPane => ({
        key: tabPane.key as string,
        name: tabPane.props?.tab as string,
        visible: true,
      }))
    );

    const observer = new IntersectionObserver(handleIntersect, {
      threshold: 1,
    });

    const DOMtabs = containerRef.current?.querySelectorAll('.ant-tabs-tab');

    DOMtabs?.forEach(t => {
      observer.observe(t);
    });

    return () => {
      DOMtabs?.forEach(t => {
        observer.unobserve(t);
      });
    };
  }, [containerRef, validTabPanes]);

  return tabs.filter(tab => !tab.visible);
};
