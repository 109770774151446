import styled from 'styled-components';

export const ProgressStepContainer = styled.div`
  position: relative;
  height: 5px;

  & > div {
    height: 100%;
    position: absolute;
    border-radius: 2px;
  }
`;

export const ProgressStepPercent = styled.div<{ $percent?: number }>`
  width: ${({ $percent }) => $percent}%;
  background-color: ${({ theme }) => theme.color.info.borders.primary};
  z-index: ${({ theme }) => theme.zIndex.dropdown};
`;

export const ProgressStepBackground = styled.div<{
  $backgroundColor?: string;
}>`
  width: 100%;
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ?? theme.color.info.backgrounds.secondaryDefault};
  z-index: ${({ theme }) => theme.zIndex.base};
`;

export const ProgressStep = styled.div`
  width: 100%;
  height: 5px;
`;
