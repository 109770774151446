/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  OnboardingStatusApi,
  AccountOnboardingStatusShowErrorCodes,
  AccountOnboardingStatusShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new OnboardingStatusApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountOnboardingStatusShowMethod = OnboardingStatusApi['accountOnboardingStatusShow'];
export type AccountOnboardingStatusShowResponseType = MethodResult<AccountOnboardingStatusShowMethod>;

export const useAccountOnboardingStatusShow = (
  variables: Parameters<AccountOnboardingStatusShowMethod>[0],
  config?: UseQueryConfig<
    AccountOnboardingStatusShowMethod,
    AccountOnboardingStatusShowErrorTypes
  >
) => {
  return useQuery<
    AccountOnboardingStatusShowMethod,
    AccountOnboardingStatusShowErrorTypes
  >(
    (...args) => api.accountOnboardingStatusShow(...args),
    AccountOnboardingStatusShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountOnboardingStatusShow'],
        ...apiQueryConfig.AccountOnboardingStatusShow,
        ...config?.queryConfig
      },
    },
    'AccountOnboardingStatusShow',
  );
};

export const useAccountOnboardingStatusShowInfinite = (
  variables: Parameters<AccountOnboardingStatusShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountOnboardingStatusShowMethod,
    AccountOnboardingStatusShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountOnboardingStatusShowMethod,
    AccountOnboardingStatusShowErrorTypes
  >(
    (...args) => api.accountOnboardingStatusShow(...args),
    AccountOnboardingStatusShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountOnboardingStatusShow', 'AccountOnboardingStatusShowInfinite'],
        ...apiQueryConfig.AccountOnboardingStatusShow,
        ...config?.queryConfig,
      },
    },
    'AccountOnboardingStatusShow',
  );
};

export const useAccountOnboardingStatusShowLazy = (baseOptions?: {
  variables?: Parameters<AccountOnboardingStatusShowMethod>[0],
  config?: UseQueryConfig<
    AccountOnboardingStatusShowMethod,
    AccountOnboardingStatusShowErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountOnboardingStatusShowMethod,
    AccountOnboardingStatusShowErrorTypes
  >(
    (...args) => api.accountOnboardingStatusShow(...args),
    AccountOnboardingStatusShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountOnboardingStatusShow'],
        ...apiQueryConfig.AccountOnboardingStatusShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountOnboardingStatusShow',
  );
};

