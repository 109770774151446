import styled from 'styled-components';

export const HelpLink = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm}px;

  &:hover > * {
    color: ${({ theme }) => theme.color.info.typography.primary};
  }

  svg {
    color: ${({ theme }) => theme.color.general.typography.tertiary};
  }
`;

export const Badge = styled.span<{
  size?: 'default' | 'sm';
}>`
  text-align: center;
  padding: 0 ${({ theme }) => theme.spacing.xs / 2}px;
  border: 1px solid ${({ theme }) => theme.color.info.borders.primary};
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  line-height: 18px; // custom value to reflect Figma accurately
  color: ${({ theme }) => theme.color.info.typography.primary};
  min-width: 22px;

  ${({ theme, size }) =>
    size === 'sm' &&
    `
      padding: 0 1px;
      line-height: 12px;
      font-size: ${theme.typography.size.xxs}px !important;
  `}
`;

export const TopbarButtonWrapper = styled.div`
  height: 100%;

  button {
    padding: 0 ${({ theme }) => theme.spacing.lg}px;
    height: 100%;

    body.tab-focus &:focus {
      background: ${({ theme }) =>
        theme.color.info.backgrounds.secondaryDefault};
    }
  }
`;
