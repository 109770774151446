const DEFAULT_CURRENT_STEP_FILL = 0.2;

/**
 * Returns how filled a specific progress step should be (0-100).
 *
 * Accepts a decimal to override the default value of 20%.
 *
 * examples:
 *   currentStep = 1   => Fills 20% of the first bar.
 *   currentStep = 2   => Fills one whole bar and 20% of the second one.
 *   currentStep = 3.5 => Fills the first two bars and 50% of the third one.
 */
export const getCurrentStepCompletion = (
  currentStep: number,
  ithStep: number
) => {
  const computedStep = currentStep - 1;
  if (ithStep < Math.floor(computedStep)) return 100;
  if (ithStep > Math.floor(computedStep)) return 0;
  return (computedStep % 1 || DEFAULT_CURRENT_STEP_FILL) * 100;
};
