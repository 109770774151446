/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IssuerEntitiesTransactionStatisticShow } from '../model';
/**
 * TransactionStatisticsApi - axios parameter creator
 * @export
 */
export const TransactionStatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary retrieves transaction statistics
         * @param {number} issuerEntityId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionStatisticShow: async (issuerEntityId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerEntityId' is not null or undefined
            assertParamExists('transactionStatisticShow', 'issuerEntityId', issuerEntityId)
            const localVarPath = changeUrl(`/api/issuer-entities/{issuer_entity_id}/transaction_statistics`)
                .replace(`{${"issuer_entity_id"}}`, encodeURIComponent(String(issuerEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionStatisticsApi - functional programming interface
 * @export
 */
export const TransactionStatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionStatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary retrieves transaction statistics
         * @param {number} issuerEntityId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionStatisticShow(issuerEntityId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuerEntitiesTransactionStatisticShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionStatisticShow(issuerEntityId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionStatisticsApi - factory interface
 * @export
 */
export const TransactionStatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionStatisticsApiFp(configuration)
    return {
        /**
         * 
         * @summary retrieves transaction statistics
         * @param {number} issuerEntityId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionStatisticShow(issuerEntityId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<IssuerEntitiesTransactionStatisticShow> {
            return localVarFp.transactionStatisticShow(issuerEntityId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for transactionStatisticShow operation in TransactionStatisticsApi.
 * @export
 * @interface TransactionStatisticsApiTransactionStatisticShowRequest
 */
export interface TransactionStatisticsApiTransactionStatisticShowRequest {
    /**
     * 
     * @type {number}
     * @memberof TransactionStatisticsApiTransactionStatisticShow
     */
    readonly issuerEntityId: number

    /**
     * 
     * @type {string}
     * @memberof TransactionStatisticsApiTransactionStatisticShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionStatisticsApiTransactionStatisticShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionStatisticsApiTransactionStatisticShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionStatisticsApiTransactionStatisticShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionStatisticsApiTransactionStatisticShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionStatisticsApiTransactionStatisticShow
     */
    readonly xUserRoleId?: string
}

/**
 * TransactionStatisticsApi - object-oriented interface
 * @export
 * @class TransactionStatisticsApi
 * @extends {BaseAPI}
 */
export class TransactionStatisticsApi extends BaseAPI {
    /**
     * 
     * @summary retrieves transaction statistics
     * @param {TransactionStatisticsApiTransactionStatisticShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionStatisticsApi
     */
    public transactionStatisticShow(requestParameters: TransactionStatisticsApiTransactionStatisticShowRequest, options?: AxiosRequestConfig) {
        return TransactionStatisticsApiFp(this.configuration).transactionStatisticShow(requestParameters.issuerEntityId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type TransactionStatisticShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const TransactionStatisticShowErrorCodes = [
    401,
];


