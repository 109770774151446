import styled, { css } from 'styled-components';

import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import {
  Sidebar,
  SIDEBAR_COLLAPSED_WIDTH,
} from '@npm/core/ui/components/organisms/Layout';
import { useBreakpoint } from '@npm/core/ui/utils/style';

import { CompanyCards } from '../../../company';

const COMPANY_SIDEBAR_WIDTH = 400 + 16; // 400 + left padding

export const CompanySelectDrawer = styled(Drawer)<{
  $hasCollapsedSidebar?: boolean;
  open?: boolean;
}>`
  top: 0;
  height: 100vh;
  z-index: ${({ theme }) => theme.zIndex.drawer};

  ${({ theme, open, $hasCollapsedSidebar }) => useBreakpoint(theme).desktop`
    margin-left: ${
      open && $hasCollapsedSidebar ? SIDEBAR_COLLAPSED_WIDTH : 0
    }px;
  `}

  && > .ant-drawer-content-wrapper {
    box-shadow: unset;
  }

  .ant-drawer-body {
    > div {
      background-color: ${({ theme }) => theme.color.general.layout.zero};
    }
    .drawer-content {
      border-right: 1px solid
        ${({ theme }) => theme.color.general.borders.primary};
      background-color: ${({ theme }) => theme.color.general.layout.zero};
      padding: 0;
    }
  }
`;

export const CompanySelectSidebar = styled(Sidebar).attrs({
  noPrint: false,
  width: COMPANY_SIDEBAR_WIDTH,
})<{ $topPosition: number }>`
  ${({ theme, $topPosition }) => css`
    top: ${$topPosition}px;
    height: calc(100vh - ${$topPosition}px);
    min-height: calc(100vh - ${$topPosition}px);
    box-shadow: none;
    padding: ${theme.spacing.md}px;
    padding-right: 0;

    &.ant-layout-sider {
      border-right: 0;
    }

    .ant-layout-sider-children {
      overflow: auto;
      border: 1px solid ${theme.color.general.borders.primary};
      border-radius: ${theme.borderRadius.lg}px;
      padding: 0;
      background: ${theme.color.general.layout.one};
    }
  `}
`;

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    & > form {
      position: sticky;
      background: ${theme.color.general.layout.zero};
      top: 0;
      padding: ${theme.spacing.md}px ${theme.spacing.md}px ${theme.spacing.sm}px;
      z-index: ${theme.zIndex.fixed};

      ${({ theme }) => useBreakpoint(theme).mobile`
          padding: ${theme.spacing.sm}px;
       `}
    }

    & > * {
      transition: background 300ms ease;
    }

    &.attention-highlight {
      & > * {
        background: ${theme.color.general.backgrounds.muted};
      }
    }
  `}
`;

export const CompanyCardsStyled = styled(CompanyCards)`
  padding: ${({ theme }) => theme.spacing.md}px;
  padding-top: ${({ theme }) => theme.spacing.sm}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
        padding: ${theme.spacing.sm}px;
        padding-top: 2px;
        `}// 2px for highlighted box-shadow
`;

export const SelectedCompanyWrapper = styled.div`
  // 2px for highlighted box-shadow
  padding: 0 ${({ theme }) => theme.spacing.md + 2}px;
  padding-top: 2px;

  ${({ theme }) => useBreakpoint(theme).mobile`
        padding:  ${theme.spacing.sm + 2}px;
        padding-bottom: ${theme.spacing.sm}px;
       `}
`;
