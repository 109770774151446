import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Helmet } from 'react-helmet';

import { GOOGLE_PLACES_API_KEY } from './AppHeader.env';

export const AppHeader = () => {
  const googlePlacesApiKey = GOOGLE_PLACES_API_KEY;

  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/public/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/public/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/public/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/public/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/public/favicon/safari-pinned-tab.svg"
        color="#0c436b"
      />
      <meta name="msapplication-TileColor" content="#0c436b" />
      <script
        type="text/javascript"
        src={`https://maps.googleapis.com/maps/api/js?key=${googlePlacesApiKey}&libraries=places`}
      ></script>
    </Helmet>
  );
};
