import React, { type ComponentProps } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { GetStarted as BaseGetStarted } from '@npm/core/ui/components/molecules/GetStarted';

export const ContactInformationGetStartedContent = (
  props: Partial<React.ComponentProps<typeof Text>>
) => {
  return (
    <Text colorVariant="secondary" {...props}>
      Enter physical address and phone number.
      <br />
      Do not provide a P.O. box, “in care of” address or mailbox drop location.
    </Text>
  );
};

export const ContactInformationGetStarted = ({
  title = 'Contact Information',
  onGetStarted,
}: ComponentProps<typeof BaseGetStarted>) => {
  return (
    <BaseGetStarted
      onGetStarted={onGetStarted}
      title={title}
      content={<ContactInformationGetStartedContent />}
      withBullets
    />
  );
};
