import React from 'react';
import { type ButtonProps as AntButtonProps } from 'antd/lib/button';

import { type IconProps } from '@npm/utils';

import { Loader } from '../Loader';

import {
  type ButtonColor,
  type ButtonSize,
  type ButtonVariant,
  type IconPosition,
} from './Button.types';

import { StyledButton } from './Button.styles';

export type ButtonProps = Omit<
  AntButtonProps,
  'size' | 'danger' | 'ghost' | 'shape' | 'type'
> & {
  icon?: React.ReactNode;
  iconPosition?: IconPosition;
  iconSize?: IconProps['size'];
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  blockOnMobile?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  stopPropagation?: boolean;
  'data-cy'?: string;
};

export const Button = ({
  icon,
  iconPosition = 'left',
  iconSize = 'xs',
  variant = 'default',
  color = 'info',
  size = 'lg',
  blockOnMobile = false,
  onClick,
  disabled,
  loading,
  children,
  stopPropagation = true,
  ...props
}: ButtonProps) => (
  <StyledButton
    $variant={variant}
    $buttonColor={color}
    $size={size}
    $iconPosition={iconPosition}
    $iconOnly={!children && !!icon}
    $hasIcon={!!icon}
    $blockOnMobile={blockOnMobile}
    $iconSize={iconSize}
    disabled={disabled || !!loading}
    loading={loading}
    icon={loading && icon ? <Loader size="small" /> : icon}
    onClick={e => {
      onClick?.(e);
      stopPropagation && e.stopPropagation();
    }}
    {...props}
    className={`${props.className} button-${color}`}
    // solution for displaying Tooltip on a disabled Button
    // https://github.com/react-component/tooltip/issues/18#issuecomment-405152748
    {...(disabled && { href: '' })}
  >
    {children}
  </StyledButton>
);
