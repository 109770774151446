import React, { type Component, forwardRef, useMemo, useState } from 'react';
import { type PickerProps } from 'antd/lib/date-picker/generatePicker';
import { v4 as uuid } from 'uuid';

import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { Icon } from '../Icon';

import * as S from './DatePickerInput.styles';

const INPUT_FORMAT = 'MM/DD/Y';

type Props = PickerProps<Date> & {
  showTime?: PickerProps<Date>;
};

export const DatePickerInput = forwardRef<Component<Props>, Props>(
  function DatePickerInput(props: Props, ref) {
    const { isMobile, isTablet } = useBreakpoints();
    const isSmall = isMobile || isTablet;

    const inputReadOnly = props.inputReadOnly ?? isSmall;

    const [isOpened, setIsOpened] = useState(false);

    // resetting state of the panel on every open change
    const panelKey = useMemo(() => (isOpened ? uuid() : ''), [isOpened]);

    return (
      <S.StyledDatePicker
        suffixIcon={<Icon name="calendar" />}
        placeholder={inputReadOnly ? 'Select' : 'MM/DD/YYYY'}
        inputReadOnly={inputReadOnly}
        defaultPickerValue={props.value ?? undefined}
        onOpenChange={open => {
          setIsOpened(open);
        }}
        format={INPUT_FORMAT}
        panelRender={panel => (
          <S.StyledPanel
            key={panelKey}
            $showTime={'showTime' in props && !!props.showTime}
          >
            {panel}
          </S.StyledPanel>
        )}
        onKeyDown={e => {
          // this is to prevent editing the input while datepicker panel is closed
          // but still focused (basically just backspace is an issue).
          // first backspace only opens the panel
          if (e.key === 'Backspace' && !isOpened) {
            e.preventDefault();
          }
        }}
        // this is to make sure the datepicker is always at fixed position as user scrolls
        allowClear={props.allowClear}
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        ref={ref}
        {...props}
      />
    );
  }
);
