/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountsAccreditationCreateRequestContract } from '../model';
// @ts-ignore
import { AccountsAccreditationUpdateRequestContract } from '../model';
// @ts-ignore
import { Accreditation } from '../model';
/**
 * EntityAccountAccreditationApi - axios parameter creator
 * @export
 */
export const EntityAccountAccreditationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates the accreditation for the entity
         * @param {number} accountId 
         * @param {AccountsAccreditationCreateRequestContract} accountsAccreditationCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityAccountAccreditationCreate: async (accountId: number, accountsAccreditationCreateRequestContract: AccountsAccreditationCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('entityAccountAccreditationCreate', 'accountId', accountId)
            // verify required parameter 'accountsAccreditationCreateRequestContract' is not null or undefined
            assertParamExists('entityAccountAccreditationCreate', 'accountsAccreditationCreateRequestContract', accountsAccreditationCreateRequestContract)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/accreditation`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountsAccreditationCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletes an accreditation for the account
         * @param {number} id 
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityAccountAccreditationDelete: async (id: number, accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('entityAccountAccreditationDelete', 'id', id)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('entityAccountAccreditationDelete', 'accountId', accountId)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/accreditations/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns the latest accreditation for the account
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityAccountAccreditationShow: async (accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('entityAccountAccreditationShow', 'accountId', accountId)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/accreditation`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns the accreditation with the given id for the entity account
         * @param {number} accountId 
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityAccountAccreditationShowById: async (accountId: number, id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('entityAccountAccreditationShowById', 'accountId', accountId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('entityAccountAccreditationShowById', 'id', id)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/accreditations/{id}`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates an accreditation for the account
         * @param {number} id 
         * @param {number} accountId 
         * @param {AccountsAccreditationUpdateRequestContract} accountsAccreditationUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityAccountAccreditationUpdate: async (id: number, accountId: number, accountsAccreditationUpdateRequestContract: AccountsAccreditationUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('entityAccountAccreditationUpdate', 'id', id)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('entityAccountAccreditationUpdate', 'accountId', accountId)
            // verify required parameter 'accountsAccreditationUpdateRequestContract' is not null or undefined
            assertParamExists('entityAccountAccreditationUpdate', 'accountsAccreditationUpdateRequestContract', accountsAccreditationUpdateRequestContract)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/accreditations/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountsAccreditationUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EntityAccountAccreditationApi - functional programming interface
 * @export
 */
export const EntityAccountAccreditationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EntityAccountAccreditationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates the accreditation for the entity
         * @param {number} accountId 
         * @param {AccountsAccreditationCreateRequestContract} accountsAccreditationCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityAccountAccreditationCreate(accountId: number, accountsAccreditationCreateRequestContract: AccountsAccreditationCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Accreditation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entityAccountAccreditationCreate(accountId, accountsAccreditationCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deletes an accreditation for the account
         * @param {number} id 
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityAccountAccreditationDelete(id: number, accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entityAccountAccreditationDelete(id, accountId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns the latest accreditation for the account
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityAccountAccreditationShow(accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Accreditation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entityAccountAccreditationShow(accountId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns the accreditation with the given id for the entity account
         * @param {number} accountId 
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityAccountAccreditationShowById(accountId: number, id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Accreditation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entityAccountAccreditationShowById(accountId, id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates an accreditation for the account
         * @param {number} id 
         * @param {number} accountId 
         * @param {AccountsAccreditationUpdateRequestContract} accountsAccreditationUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityAccountAccreditationUpdate(id: number, accountId: number, accountsAccreditationUpdateRequestContract: AccountsAccreditationUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entityAccountAccreditationUpdate(id, accountId, accountsAccreditationUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EntityAccountAccreditationApi - factory interface
 * @export
 */
export const EntityAccountAccreditationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EntityAccountAccreditationApiFp(configuration)
    return {
        /**
         * 
         * @summary creates the accreditation for the entity
         * @param {number} accountId 
         * @param {AccountsAccreditationCreateRequestContract} accountsAccreditationCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityAccountAccreditationCreate(accountId: number, accountsAccreditationCreateRequestContract: AccountsAccreditationCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Accreditation> {
            return localVarFp.entityAccountAccreditationCreate(accountId, accountsAccreditationCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deletes an accreditation for the account
         * @param {number} id 
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityAccountAccreditationDelete(id: number, accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.entityAccountAccreditationDelete(id, accountId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns the latest accreditation for the account
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityAccountAccreditationShow(accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Accreditation> {
            return localVarFp.entityAccountAccreditationShow(accountId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns the accreditation with the given id for the entity account
         * @param {number} accountId 
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityAccountAccreditationShowById(accountId: number, id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Accreditation> {
            return localVarFp.entityAccountAccreditationShowById(accountId, id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates an accreditation for the account
         * @param {number} id 
         * @param {number} accountId 
         * @param {AccountsAccreditationUpdateRequestContract} accountsAccreditationUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityAccountAccreditationUpdate(id: number, accountId: number, accountsAccreditationUpdateRequestContract: AccountsAccreditationUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.entityAccountAccreditationUpdate(id, accountId, accountsAccreditationUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for entityAccountAccreditationCreate operation in EntityAccountAccreditationApi.
 * @export
 * @interface EntityAccountAccreditationApiEntityAccountAccreditationCreateRequest
 */
export interface EntityAccountAccreditationApiEntityAccountAccreditationCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationCreate
     */
    readonly accountId: number

    /**
     * 
     * @type {AccountsAccreditationCreateRequestContract}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationCreate
     */
    readonly accountsAccreditationCreateRequestContract: AccountsAccreditationCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for entityAccountAccreditationDelete operation in EntityAccountAccreditationApi.
 * @export
 * @interface EntityAccountAccreditationApiEntityAccountAccreditationDeleteRequest
 */
export interface EntityAccountAccreditationApiEntityAccountAccreditationDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationDelete
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationDelete
     */
    readonly accountId: number

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationDelete
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationDelete
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationDelete
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationDelete
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationDelete
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationDelete
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for entityAccountAccreditationShow operation in EntityAccountAccreditationApi.
 * @export
 * @interface EntityAccountAccreditationApiEntityAccountAccreditationShowRequest
 */
export interface EntityAccountAccreditationApiEntityAccountAccreditationShowRequest {
    /**
     * 
     * @type {number}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationShow
     */
    readonly accountId: number

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for entityAccountAccreditationShowById operation in EntityAccountAccreditationApi.
 * @export
 * @interface EntityAccountAccreditationApiEntityAccountAccreditationShowByIdRequest
 */
export interface EntityAccountAccreditationApiEntityAccountAccreditationShowByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationShowById
     */
    readonly accountId: number

    /**
     * 
     * @type {number}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationShowById
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationShowById
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationShowById
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationShowById
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationShowById
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationShowById
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationShowById
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for entityAccountAccreditationUpdate operation in EntityAccountAccreditationApi.
 * @export
 * @interface EntityAccountAccreditationApiEntityAccountAccreditationUpdateRequest
 */
export interface EntityAccountAccreditationApiEntityAccountAccreditationUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationUpdate
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationUpdate
     */
    readonly accountId: number

    /**
     * 
     * @type {AccountsAccreditationUpdateRequestContract}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationUpdate
     */
    readonly accountsAccreditationUpdateRequestContract: AccountsAccreditationUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof EntityAccountAccreditationApiEntityAccountAccreditationUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * EntityAccountAccreditationApi - object-oriented interface
 * @export
 * @class EntityAccountAccreditationApi
 * @extends {BaseAPI}
 */
export class EntityAccountAccreditationApi extends BaseAPI {
    /**
     * 
     * @summary creates the accreditation for the entity
     * @param {EntityAccountAccreditationApiEntityAccountAccreditationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityAccountAccreditationApi
     */
    public entityAccountAccreditationCreate(requestParameters: EntityAccountAccreditationApiEntityAccountAccreditationCreateRequest, options?: AxiosRequestConfig) {
        return EntityAccountAccreditationApiFp(this.configuration).entityAccountAccreditationCreate(requestParameters.accountId, requestParameters.accountsAccreditationCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deletes an accreditation for the account
     * @param {EntityAccountAccreditationApiEntityAccountAccreditationDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityAccountAccreditationApi
     */
    public entityAccountAccreditationDelete(requestParameters: EntityAccountAccreditationApiEntityAccountAccreditationDeleteRequest, options?: AxiosRequestConfig) {
        return EntityAccountAccreditationApiFp(this.configuration).entityAccountAccreditationDelete(requestParameters.id, requestParameters.accountId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns the latest accreditation for the account
     * @param {EntityAccountAccreditationApiEntityAccountAccreditationShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityAccountAccreditationApi
     */
    public entityAccountAccreditationShow(requestParameters: EntityAccountAccreditationApiEntityAccountAccreditationShowRequest, options?: AxiosRequestConfig) {
        return EntityAccountAccreditationApiFp(this.configuration).entityAccountAccreditationShow(requestParameters.accountId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns the accreditation with the given id for the entity account
     * @param {EntityAccountAccreditationApiEntityAccountAccreditationShowByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityAccountAccreditationApi
     */
    public entityAccountAccreditationShowById(requestParameters: EntityAccountAccreditationApiEntityAccountAccreditationShowByIdRequest, options?: AxiosRequestConfig) {
        return EntityAccountAccreditationApiFp(this.configuration).entityAccountAccreditationShowById(requestParameters.accountId, requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates an accreditation for the account
     * @param {EntityAccountAccreditationApiEntityAccountAccreditationUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityAccountAccreditationApi
     */
    public entityAccountAccreditationUpdate(requestParameters: EntityAccountAccreditationApiEntityAccountAccreditationUpdateRequest, options?: AxiosRequestConfig) {
        return EntityAccountAccreditationApiFp(this.configuration).entityAccountAccreditationUpdate(requestParameters.id, requestParameters.accountId, requestParameters.accountsAccreditationUpdateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type EntityAccountAccreditationCreateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EntityAccountAccreditationCreateErrorCodes = [
];

export type EntityAccountAccreditationDeleteErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EntityAccountAccreditationDeleteErrorCodes = [
];

export type EntityAccountAccreditationShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EntityAccountAccreditationShowErrorCodes = [
    401,
];

export type EntityAccountAccreditationShowByIdErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EntityAccountAccreditationShowByIdErrorCodes = [
    401,
    404,
];

export type EntityAccountAccreditationUpdateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EntityAccountAccreditationUpdateErrorCodes = [
];


