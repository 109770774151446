/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Company-Pricing API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { QuarterlyIntrinsicValues } from '../model';
/**
 * CompanyQuarterlyIntrinsicValuesApi - axios parameter creator
 * @export
 */
export const CompanyQuarterlyIntrinsicValuesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get intrinsic values of a company
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyQuarterlyIntrinsicValues: async (companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyQuarterlyIntrinsicValues', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/companies/{company_id}/intrinsic_values/quarterly`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyQuarterlyIntrinsicValuesApi - functional programming interface
 * @export
 */
export const CompanyQuarterlyIntrinsicValuesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyQuarterlyIntrinsicValuesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get intrinsic values of a company
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyQuarterlyIntrinsicValues(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuarterlyIntrinsicValues>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyQuarterlyIntrinsicValues(companyId, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyQuarterlyIntrinsicValuesApi - factory interface
 * @export
 */
export const CompanyQuarterlyIntrinsicValuesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyQuarterlyIntrinsicValuesApiFp(configuration)
    return {
        /**
         * 
         * @summary get intrinsic values of a company
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyQuarterlyIntrinsicValues(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<QuarterlyIntrinsicValues> {
            return localVarFp.companyQuarterlyIntrinsicValues(companyId, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for companyQuarterlyIntrinsicValues operation in CompanyQuarterlyIntrinsicValuesApi.
 * @export
 * @interface CompanyQuarterlyIntrinsicValuesApiCompanyQuarterlyIntrinsicValuesRequest
 */
export interface CompanyQuarterlyIntrinsicValuesApiCompanyQuarterlyIntrinsicValuesRequest {
    /**
     * 
     * @type {string}
     * @memberof CompanyQuarterlyIntrinsicValuesApiCompanyQuarterlyIntrinsicValues
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof CompanyQuarterlyIntrinsicValuesApiCompanyQuarterlyIntrinsicValues
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompanyQuarterlyIntrinsicValuesApiCompanyQuarterlyIntrinsicValues
     */
    readonly xSubscriptionPlan?: string
}

/**
 * CompanyQuarterlyIntrinsicValuesApi - object-oriented interface
 * @export
 * @class CompanyQuarterlyIntrinsicValuesApi
 * @extends {BaseAPI}
 */
export class CompanyQuarterlyIntrinsicValuesApi extends BaseAPI {
    /**
     * 
     * @summary get intrinsic values of a company
     * @param {CompanyQuarterlyIntrinsicValuesApiCompanyQuarterlyIntrinsicValuesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyQuarterlyIntrinsicValuesApi
     */
    public companyQuarterlyIntrinsicValues(requestParameters: CompanyQuarterlyIntrinsicValuesApiCompanyQuarterlyIntrinsicValuesRequest, options?: AxiosRequestConfig) {
        return CompanyQuarterlyIntrinsicValuesApiFp(this.configuration).companyQuarterlyIntrinsicValues(requestParameters.companyId, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }
}

export type CompanyQuarterlyIntrinsicValuesErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompanyQuarterlyIntrinsicValuesErrorCodes = [
    401,
];


