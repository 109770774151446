import { StatisticCard } from '../../StatisticCard';
import { type StatisticsProps } from '../Statistics.types';

import * as S from './StatisticsDesktop.styles';

export const StatisticsDesktop = ({
  config,
  isLoading,
  gutter,
  outlined,
}: StatisticsProps) => {
  // keep the div, solve layout issue on safari https://nasdaq-private-market.atlassian.net/browse/NFE-2635
  return (
    <div>
      <S.Container gap={gutter?.[0]}>
        {config.map((stat, i) => (
          <StatisticCard
            outlined={outlined}
            loading={isLoading}
            {...stat}
            key={stat.key ?? i}
          />
        ))}
      </S.Container>
    </div>
  );
};
