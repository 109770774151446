const OPACITY_30 = '4D'; // 30%

export const mediumHoverPoints = (primary: string) => ({
  borderColor: primary,
  borderWidth: 2,

  pointRadius: 4,
  pointBorderWidth: 0,
  pointBackgroundColor: primary,

  pointHoverRadius: 5,
  pointHoverBorderWidth: 10,
  pointHoverBackgroundColor: primary,
  pointHoverBorderColor: `${primary}${OPACITY_30}`,
});
