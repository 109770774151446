import React from 'react';

import { type OboModalState } from '../../../OboModal.types';
import { NoRepresentativeCta } from '../NoRepresentativeCta';
import { type SelectRepresentativeListProps } from '../SelectRepresentative.types';

import { SelectRepresentativeCard } from './SelectRepresentativeCard';
import { SelectRepresentativeLoadingCard } from './SelectRepresentativeLoadingCard';

type Props = {
  oboModalState: OboModalState;
  handleComplete: (nextOboState: OboModalState) => void;
} & SelectRepresentativeListProps;

export const SelectRepresentativeCards = ({
  data,
  isLoading,
  goBack,
  handleComplete,
  oboModalState,
}: Props) => {
  return (
    <>
      {data?.map(r => (
        <SelectRepresentativeCard
          key={r.user_id}
          selectedAccount={oboModalState.account}
          isSelected={oboModalState.representative?.user_id === r.user_id}
          representative={r}
          handleComplete={handleComplete}
        />
      ))}
      {isLoading && <SelectRepresentativeLoadingCard />}
      {!isLoading && !data?.length && <NoRepresentativeCta onClick={goBack} />}
    </>
  );
};
