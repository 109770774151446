import React from 'react';

import * as S from './Grid.styles';

type GridResponsiveKeys = ['default', 'mobile', 'tablet', 'smDesktop'];
type GridConfigKeys = ['count', 'size'];

export type GridConfig = Partial<
  Record<
    GridResponsiveKeys[number],
    Partial<Record<GridConfigKeys[number], string | number>>
  >
>;

export type GridComponentProps = {
  cols?: GridConfig;
  rows?: GridConfig;
  areas?: Partial<Record<GridResponsiveKeys[number], string>>;
  gap?: Partial<Record<GridResponsiveKeys[number], string>>;
  children: React.ReactNode;
  testId?: string;
  className?: string;
};

type GridItemProps = {
  children: React.ReactNode;
  areaName?: string;
  justifySelf?: 'start' | 'end' | 'center' | 'stretch';
};

export const GridItem = ({
  areaName,
  justifySelf,
  children,
}: GridItemProps) => {
  return (
    <S.GridItem $areaName={areaName} $justifySelf={justifySelf}>
      {children}
    </S.GridItem>
  );
};

export const Grid = ({
  cols,
  rows,
  areas,
  gap,
  children,
  testId,
  className,
}: GridComponentProps) => {
  return (
    <S.Grid
      cols={cols}
      areas={areas}
      rows={rows}
      gap={gap}
      data-cy={testId}
      className={className}
    >
      {children}
    </S.Grid>
  );
};

Grid.GridItem = GridItem;
