import { formatAsCompactNumber } from '@npm/core/ui/utils/formatters';
import { type WatchlistIndex } from '@npm/data-access';

export const getMinimumSizeRange = (data: WatchlistIndex) => {
  if (!data || data.minimum_size_range.length === 0) return '0';
  if (data.minimum_size_range[0] === data.minimum_size_range[1])
    return data.minimum_size_range[0];

  return `${formatAsCompactNumber(
    data.minimum_size_range[0]
  )} - ${formatAsCompactNumber(data.minimum_size_range[1])}`;
};

export const getTotalStats = (
  data: WatchlistIndex,
  isCompanySelected: boolean
) => {
  const baseTitle = 'Total Watchlist';
  if (!data || data.watchlists?.length === 0)
    return { title: baseTitle, count: 0 };

  const totalWatchlist = data.pagination?.total_records;
  const distinctIssuerCount = data.distinct_issuer_entity_count;

  if (totalWatchlist === distinctIssuerCount)
    return { title: baseTitle, count: totalWatchlist };

  return {
    title: isCompanySelected ? baseTitle : `${baseTitle} / Unique Companies`,
    count: isCompanySelected
      ? totalWatchlist
      : `${totalWatchlist} / ${distinctIssuerCount}`,
  };
};
