/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * VenusFilingApi - axios parameter creator
 * @export
 */
export const VenusFilingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary preview the filing specified
         * @param {number} venusId 
         * @param {number} filingId 
         * @param {number} issuerEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venusFiling: async (venusId: number, filingId: number, issuerEntityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venusId' is not null or undefined
            assertParamExists('venusFiling', 'venusId', venusId)
            // verify required parameter 'filingId' is not null or undefined
            assertParamExists('venusFiling', 'filingId', filingId)
            // verify required parameter 'issuerEntityId' is not null or undefined
            assertParamExists('venusFiling', 'issuerEntityId', issuerEntityId)
            const localVarPath = changeUrl(`/api/venus/{venus_id}/filings/{filing_id}`)
                .replace(`{${"venus_id"}}`, encodeURIComponent(String(venusId)))
                .replace(`{${"filing_id"}}`, encodeURIComponent(String(filingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary preview the filing specified
         * @param {number} venusId 
         * @param {number} filingId 
         * @param {number} issuerEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venusFilingPreviewXod: async (venusId: number, filingId: number, issuerEntityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venusId' is not null or undefined
            assertParamExists('venusFilingPreviewXod', 'venusId', venusId)
            // verify required parameter 'filingId' is not null or undefined
            assertParamExists('venusFilingPreviewXod', 'filingId', filingId)
            // verify required parameter 'issuerEntityId' is not null or undefined
            assertParamExists('venusFilingPreviewXod', 'issuerEntityId', issuerEntityId)
            const localVarPath = changeUrl(`/api/venus/{venus_id}/filing_previews/{filing_id}`)
                .replace(`{${"venus_id"}}`, encodeURIComponent(String(venusId)))
                .replace(`{${"filing_id"}}`, encodeURIComponent(String(filingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VenusFilingApi - functional programming interface
 * @export
 */
export const VenusFilingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VenusFilingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary preview the filing specified
         * @param {number} venusId 
         * @param {number} filingId 
         * @param {number} issuerEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venusFiling(venusId: number, filingId: number, issuerEntityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venusFiling(venusId, filingId, issuerEntityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary preview the filing specified
         * @param {number} venusId 
         * @param {number} filingId 
         * @param {number} issuerEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venusFilingPreviewXod(venusId: number, filingId: number, issuerEntityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venusFilingPreviewXod(venusId, filingId, issuerEntityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VenusFilingApi - factory interface
 * @export
 */
export const VenusFilingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VenusFilingApiFp(configuration)
    return {
        /**
         * 
         * @summary preview the filing specified
         * @param {number} venusId 
         * @param {number} filingId 
         * @param {number} issuerEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venusFiling(venusId: number, filingId: number, issuerEntityId: number, options?: any): AxiosPromise<string> {
            return localVarFp.venusFiling(venusId, filingId, issuerEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary preview the filing specified
         * @param {number} venusId 
         * @param {number} filingId 
         * @param {number} issuerEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venusFilingPreviewXod(venusId: number, filingId: number, issuerEntityId: number, options?: any): AxiosPromise<string> {
            return localVarFp.venusFilingPreviewXod(venusId, filingId, issuerEntityId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for venusFiling operation in VenusFilingApi.
 * @export
 * @interface VenusFilingApiVenusFilingRequest
 */
export interface VenusFilingApiVenusFilingRequest {
    /**
     * 
     * @type {number}
     * @memberof VenusFilingApiVenusFiling
     */
    readonly venusId: number

    /**
     * 
     * @type {number}
     * @memberof VenusFilingApiVenusFiling
     */
    readonly filingId: number

    /**
     * 
     * @type {number}
     * @memberof VenusFilingApiVenusFiling
     */
    readonly issuerEntityId: number
}

/**
 * Request parameters for venusFilingPreviewXod operation in VenusFilingApi.
 * @export
 * @interface VenusFilingApiVenusFilingPreviewXodRequest
 */
export interface VenusFilingApiVenusFilingPreviewXodRequest {
    /**
     * 
     * @type {number}
     * @memberof VenusFilingApiVenusFilingPreviewXod
     */
    readonly venusId: number

    /**
     * 
     * @type {number}
     * @memberof VenusFilingApiVenusFilingPreviewXod
     */
    readonly filingId: number

    /**
     * 
     * @type {number}
     * @memberof VenusFilingApiVenusFilingPreviewXod
     */
    readonly issuerEntityId: number
}

/**
 * VenusFilingApi - object-oriented interface
 * @export
 * @class VenusFilingApi
 * @extends {BaseAPI}
 */
export class VenusFilingApi extends BaseAPI {
    /**
     * 
     * @summary preview the filing specified
     * @param {VenusFilingApiVenusFilingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenusFilingApi
     */
    public venusFiling(requestParameters: VenusFilingApiVenusFilingRequest, options?: AxiosRequestConfig) {
        return VenusFilingApiFp(this.configuration).venusFiling(requestParameters.venusId, requestParameters.filingId, requestParameters.issuerEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary preview the filing specified
     * @param {VenusFilingApiVenusFilingPreviewXodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenusFilingApi
     */
    public venusFilingPreviewXod(requestParameters: VenusFilingApiVenusFilingPreviewXodRequest, options?: AxiosRequestConfig) {
        return VenusFilingApiFp(this.configuration).venusFilingPreviewXod(requestParameters.venusId, requestParameters.filingId, requestParameters.issuerEntityId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type VenusFilingErrorTypes =

    | {
        status: 302;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const VenusFilingErrorCodes = [
    302,
];

export type VenusFilingPreviewXodErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const VenusFilingPreviewXodErrorCodes = [
];


