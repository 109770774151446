export const PlugLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="37"
      viewBox="0 0 55 37"
      fill="none"
    >
      <path
        d="M46.9662 1.42004L32.4541 1.42004L32.4541 34.7473L46.9662 34.7473L46.9662 1.42004Z"
        fill="#607D94"
      />
      <path
        d="M32.4541 1.41946L19.4194 13.3798L19.4194 22.7864L32.4541 34.7467L32.4541 1.41946Z"
        fill="#4F697D"
      />
      <path
        d="M0.456186 15.2566L19.4194 13.5147L19.4194 22.7864L0.456186 21.0445C0.00807174 21.0033 -0.335043 20.6283 -0.335043 20.1783L-0.335044 16.1228C-0.335044 15.6728 0.00807124 15.2978 0.456186 15.2566Z"
        fill="#4F697D"
      />
      <path
        d="M3.96631 14.934L3.96631 21.3669L2.04074 21.1907L2.04074 15.1102L3.96631 14.934Z"
        fill="#1E3545"
      />
      <path
        d="M8.3103 21.7664L6.38473 21.5901L6.38473 14.7109L8.3103 14.5347L8.3103 21.7664Z"
        fill="#1E3545"
      />
      <path
        d="M12.6565 22.1656L10.729 21.9875L10.729 14.3115L12.6565 14.1352L12.6565 22.1656Z"
        fill="#1E3545"
      />
      <path
        d="M17.0007 22.5651L15.0752 22.3869L15.0752 13.914L17.0007 13.7359L17.0007 22.5651Z"
        fill="#1E3545"
      />
      <path
        d="M49.4546 0.000102997L46.9684 1.41944L46.9684 34.7467L49.4546 36.166L49.4546 0.000102997Z"
        fill="#4F697D"
      />
      <path
        d="M54.291 0.000609929L49.4536 0.000610352L49.4536 36.1665L54.291 36.1665L54.291 0.000609929Z"
        fill="#607D94"
      />
      <path
        d="M43.4318 10.916C43.9512 10.916 44.373 11.3379 44.373 11.8572L44.373 24.3088C44.373 24.8281 43.9512 25.25 43.4318 25.25C42.9125 25.25 42.4906 24.8281 42.4906 24.3088L42.4906 11.8554C42.4925 11.3379 42.9125 10.916 43.4318 10.916Z"
        fill="#1E3545"
      />
      <path
        d="M39.6689 10.916C40.1882 10.916 40.6101 11.3379 40.6101 11.8572L40.6101 24.3088C40.6101 24.8281 40.1882 25.25 39.6689 25.25C39.1495 25.25 38.7277 24.8281 38.7277 24.3088L38.7277 11.8554C38.7295 11.3379 39.1495 10.916 39.6689 10.916Z"
        fill="#1E3545"
      />
      <path
        d="M35.9076 10.916C36.427 10.916 36.8489 11.3379 36.8489 11.8572L36.8489 24.3088C36.8489 24.8281 36.427 25.25 35.9077 25.25C35.3883 25.25 34.9664 24.8281 34.9664 24.3088L34.9664 11.8554C34.9664 11.3379 35.3883 10.916 35.9076 10.916Z"
        fill="#1E3545"
      />
    </svg>
  );
};
