import { Codebooks } from './codebooks.types';

const Items = {
  PersonAccount: 'PersonAccount',
  OrganizationAccount: 'OrganizationAccount',
  Anonymous: 'AnonymousAccount',
} as const;

export type AccountType = typeof Items[keyof typeof Items];

export const CbAccountType = {
  code: Codebooks.ACCOUNT_TYPE,
  items: Items,
};
