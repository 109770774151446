/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EventsOrderBookShow } from '../model';
/**
 * OrderBookShowApi - axios parameter creator
 * @export
 */
export const OrderBookShowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary show order book for specified event
         * @param {number} eventId 
         * @param {number} [sellPage] 
         * @param {number} [buyPage] 
         * @param {number} [tradePage] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventOrderBookShow: async (eventId: number, sellPage?: number, buyPage?: number, tradePage?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('eventOrderBookShow', 'eventId', eventId)
            const localVarPath = changeUrl(`/api/events/{event_id}/order-book`)
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sellPage !== undefined) {
                localVarQueryParameter['sell_page'] = sellPage;
            }

            if (buyPage !== undefined) {
                localVarQueryParameter['buy_page'] = buyPage;
            }

            if (tradePage !== undefined) {
                localVarQueryParameter['trade_page'] = tradePage;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderBookShowApi - functional programming interface
 * @export
 */
export const OrderBookShowApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderBookShowApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary show order book for specified event
         * @param {number} eventId 
         * @param {number} [sellPage] 
         * @param {number} [buyPage] 
         * @param {number} [tradePage] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventOrderBookShow(eventId: number, sellPage?: number, buyPage?: number, tradePage?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsOrderBookShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventOrderBookShow(eventId, sellPage, buyPage, tradePage, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderBookShowApi - factory interface
 * @export
 */
export const OrderBookShowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderBookShowApiFp(configuration)
    return {
        /**
         * 
         * @summary show order book for specified event
         * @param {number} eventId 
         * @param {number} [sellPage] 
         * @param {number} [buyPage] 
         * @param {number} [tradePage] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventOrderBookShow(eventId: number, sellPage?: number, buyPage?: number, tradePage?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<EventsOrderBookShow> {
            return localVarFp.eventOrderBookShow(eventId, sellPage, buyPage, tradePage, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for eventOrderBookShow operation in OrderBookShowApi.
 * @export
 * @interface OrderBookShowApiEventOrderBookShowRequest
 */
export interface OrderBookShowApiEventOrderBookShowRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderBookShowApiEventOrderBookShow
     */
    readonly eventId: number

    /**
     * 
     * @type {number}
     * @memberof OrderBookShowApiEventOrderBookShow
     */
    readonly sellPage?: number

    /**
     * 
     * @type {number}
     * @memberof OrderBookShowApiEventOrderBookShow
     */
    readonly buyPage?: number

    /**
     * 
     * @type {number}
     * @memberof OrderBookShowApiEventOrderBookShow
     */
    readonly tradePage?: number

    /**
     * 
     * @type {string}
     * @memberof OrderBookShowApiEventOrderBookShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof OrderBookShowApiEventOrderBookShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof OrderBookShowApiEventOrderBookShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof OrderBookShowApiEventOrderBookShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof OrderBookShowApiEventOrderBookShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof OrderBookShowApiEventOrderBookShow
     */
    readonly xUserRoleId?: string
}

/**
 * OrderBookShowApi - object-oriented interface
 * @export
 * @class OrderBookShowApi
 * @extends {BaseAPI}
 */
export class OrderBookShowApi extends BaseAPI {
    /**
     * 
     * @summary show order book for specified event
     * @param {OrderBookShowApiEventOrderBookShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderBookShowApi
     */
    public eventOrderBookShow(requestParameters: OrderBookShowApiEventOrderBookShowRequest, options?: AxiosRequestConfig) {
        return OrderBookShowApiFp(this.configuration).eventOrderBookShow(requestParameters.eventId, requestParameters.sellPage, requestParameters.buyPage, requestParameters.tradePage, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type EventOrderBookShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EventOrderBookShowErrorCodes = [
    401,
];


