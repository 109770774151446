/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  PeopleApi,
  InternalPeopleIndexErrorCodes,
  InternalPeopleIndexErrorTypes,
  InternalPeopleCreateErrorCodes,
  InternalPeopleShowErrorCodes,
  InternalPeopleShowErrorTypes,
  InternalPeopleUpdateErrorCodes,
  InternalPeopleDeleteErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new PeopleApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalPeopleIndexMethod = PeopleApi['internalPeopleIndex'];
export type InternalPeopleIndexResponseType = MethodResult<InternalPeopleIndexMethod>;

export const useInternalPeopleIndex = (
  variables: Parameters<InternalPeopleIndexMethod>[0],
  config?: UseQueryConfig<
    InternalPeopleIndexMethod,
    InternalPeopleIndexErrorTypes
  >
) => {
  return useQuery<
    InternalPeopleIndexMethod,
    InternalPeopleIndexErrorTypes
  >(
    (...args) => api.internalPeopleIndex(...args),
    InternalPeopleIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalPeopleIndex'],
        ...apiQueryConfig.InternalPeopleIndex,
        ...config?.queryConfig
      },
    },
    'InternalPeopleIndex',
  );
};

export const useInternalPeopleIndexInfinite = (
  variables: Parameters<InternalPeopleIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalPeopleIndexMethod,
    InternalPeopleIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalPeopleIndexMethod,
    InternalPeopleIndexErrorTypes
  >(
    (...args) => api.internalPeopleIndex(...args),
    InternalPeopleIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalPeopleIndex', 'InternalPeopleIndexInfinite'],
        ...apiQueryConfig.InternalPeopleIndex,
        ...config?.queryConfig,
      },
    },
    'InternalPeopleIndex',
  );
};

export const useInternalPeopleIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalPeopleIndexMethod>[0],
  config?: UseQueryConfig<
    InternalPeopleIndexMethod,
    InternalPeopleIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalPeopleIndexMethod,
    InternalPeopleIndexErrorTypes
  >(
    (...args) => api.internalPeopleIndex(...args),
    InternalPeopleIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalPeopleIndex'],
        ...apiQueryConfig.InternalPeopleIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalPeopleIndex',
  );
};


export type InternalPeopleCreateMethod = PeopleApi['internalPeopleCreate'];

export const useInternalPeopleCreate = () => {
  return useMutation<InternalPeopleCreateMethod>(
    (...args) => api.internalPeopleCreate(...args),
    InternalPeopleCreateErrorCodes,
    'InternalPeopleCreate',
    '/api/internal/people',
    'post'
  );
};

export type InternalPeopleShowMethod = PeopleApi['internalPeopleShow'];
export type InternalPeopleShowResponseType = MethodResult<InternalPeopleShowMethod>;

export const useInternalPeopleShow = (
  variables: Parameters<InternalPeopleShowMethod>[0],
  config?: UseQueryConfig<
    InternalPeopleShowMethod,
    InternalPeopleShowErrorTypes
  >
) => {
  return useQuery<
    InternalPeopleShowMethod,
    InternalPeopleShowErrorTypes
  >(
    (...args) => api.internalPeopleShow(...args),
    InternalPeopleShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalPeopleShow'],
        ...apiQueryConfig.InternalPeopleShow,
        ...config?.queryConfig
      },
    },
    'InternalPeopleShow',
  );
};

export const useInternalPeopleShowInfinite = (
  variables: Parameters<InternalPeopleShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalPeopleShowMethod,
    InternalPeopleShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalPeopleShowMethod,
    InternalPeopleShowErrorTypes
  >(
    (...args) => api.internalPeopleShow(...args),
    InternalPeopleShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalPeopleShow', 'InternalPeopleShowInfinite'],
        ...apiQueryConfig.InternalPeopleShow,
        ...config?.queryConfig,
      },
    },
    'InternalPeopleShow',
  );
};

export const useInternalPeopleShowLazy = (baseOptions?: {
  variables?: Parameters<InternalPeopleShowMethod>[0],
  config?: UseQueryConfig<
    InternalPeopleShowMethod,
    InternalPeopleShowErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalPeopleShowMethod,
    InternalPeopleShowErrorTypes
  >(
    (...args) => api.internalPeopleShow(...args),
    InternalPeopleShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalPeopleShow'],
        ...apiQueryConfig.InternalPeopleShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalPeopleShow',
  );
};


export type InternalPeopleUpdateMethod = PeopleApi['internalPeopleUpdate'];

export const useInternalPeopleUpdate = () => {
  return useMutation<InternalPeopleUpdateMethod>(
    (...args) => api.internalPeopleUpdate(...args),
    InternalPeopleUpdateErrorCodes,
    'InternalPeopleUpdate',
    '/api/internal/people/{id}',
    'put'
  );
};

export type InternalPeopleDeleteMethod = PeopleApi['internalPeopleDelete'];

export const useInternalPeopleDelete = () => {
  return useMutation<InternalPeopleDeleteMethod>(
    (...args) => api.internalPeopleDelete(...args),
    InternalPeopleDeleteErrorCodes,
    'InternalPeopleDelete',
    '/api/internal/people/{id}',
    'delete'
  );
};
