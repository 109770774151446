import React from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';

const MESSAGE =
  'Please indicate the authorized signatory for this account. To be added as an authorized signer for this account, an individual must have the legal authority to bind the individual or entity identified in the NPM Platform account name.';

export const AuthorizedSignersAlert = () => {
  return (
    <Margin bottom="lg">
      <Alert type="info" showIcon message={MESSAGE} />
    </Margin>
  );
};
