import styled, { css } from 'styled-components';

import { InputFormItem as FormItemBase } from '@npm/core/ui/components/atoms/FormItem';
import { RadioGroup as BaseRadioGroup } from '@npm/core/ui/components/atoms/Radio';
import { useBreakpoint } from '@npm/core/ui/utils/style';

const COLUMN_MAX_WIDTH = 390;
const SAMPLE_IMAGE_WIDTH = 95;

const ITEM_CSS = css`
  flex: 1;
  max-width: ${COLUMN_MAX_WIDTH}px;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.md}px;
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    max-width: unset;
    width: 100%
  `}
`;

export const FormItem = styled(FormItemBase).attrs({
  labelPosition: 'top',
})<{
  $layout: 'horizontal' | 'vertical';
}>`
  & {
    ${ITEM_CSS};

    ${({ $layout }) =>
      $layout === 'vertical' &&
      `
        &:not(:last-child) {
          margin-right: 0;
        }
      `}
  }

  && .ant-picker-date-panel,
  .ant-picker-year-panel,
  .ant-picker-month-panel {
    width: 100%;
    max-width: ${COLUMN_MAX_WIDTH}px;
  }
`;

export const Item = styled.div`
  ${ITEM_CSS};
`;

export const RadioGroup = styled(BaseRadioGroup)`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.xs}px 0
    ${({ theme }) => theme.spacing.sm}px 0;

  & .ant-radio-wrapper {
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }
`;

export const SampleImageSection = styled(Item)`
  display: flex;
  align-items: flex-start;
`;

export const SampleImage = styled.img.attrs({ alt: 'Sample image' })`
  width: ${SAMPLE_IMAGE_WIDTH}px;
  margin-right: ${({ theme }) => theme.spacing.lg / 2}px;
`;
