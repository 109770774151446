import React, { type ComponentProps } from 'react';

import { GetStarted as BaseGetStarted } from '@npm/core/ui/components/molecules/GetStarted';

export const BankAccountInformationGetStarted = ({
  onGetStarted,
}: ComponentProps<typeof BaseGetStarted>) => {
  return (
    <BaseGetStarted
      onGetStarted={onGetStarted}
      withBullets
      title="Bank Account Information"
      content={
        <>
          <p>
            Enter payment instructions for a bank account held under the same
            name as this NPM Platform account.
          </p>
          <p>
            Please refer to the relevant transaction documents for information
            about what payment instructions are required.
          </p>
        </>
      }
    />
  );
};
