import styled from 'styled-components';

import { Text } from '@npm/core/ui/components/atoms/Typography';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.color.general.layout.zero};
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  padding: ${({ theme }) => theme.spacing.md}px;
`;

export const Price = styled(Text.Price).attrs({
  colorVariant: 'primary',
})`
  font-size: ${({ theme }) => theme.typography.size.xxl}px;
`;

export const EmptyPrice = styled(Text).attrs({
  colorVariant: 'primary',
})`
  font-size: ${({ theme }) => theme.typography.size.xxl}px;
`;
