import styled from 'styled-components';

export const Container = styled.div`
  & > div:first-child {
    padding-top: 0;
  }
`;

export const UploadDescription = styled.div`
  padding-top: ${({ theme }) => theme.spacing.sm}px;
`;
