import { type ContactInformation } from '@npm/data-access';

type ContactInformationInitialValues = Omit<
  ContactInformation,
  'country' | 'state'
> & {
  country?: string;
  state?: string;
};

export const getContactInformationInitialValues = (
  data: ContactInformation | undefined
): ContactInformationInitialValues => {
  if (!data) {
    return {};
  }
  return { ...data, country: data?.country?.code, state: data?.state?.code };
};
