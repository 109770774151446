/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContactInformation } from '../model';
// @ts-ignore
import { ContactInformationCreateRequestContract } from '../model';
// @ts-ignore
import { ContactInformationUpdateRequestContract } from '../model';
// @ts-ignore
import { ErrorValidation } from '../model';
/**
 * ContactInformationApi - axios parameter creator
 * @export
 */
export const ContactInformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates or updates the contact information for the organization
         * @param {ContactInformationCreateRequestContract} contactInformationCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationContactInformationCreate: async (contactInformationCreateRequestContract: ContactInformationCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactInformationCreateRequestContract' is not null or undefined
            assertParamExists('organizationContactInformationCreate', 'contactInformationCreateRequestContract', contactInformationCreateRequestContract)
            const localVarPath = changeUrl(`/api/contact-informations`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactInformationCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates the contact information for the organization with the given id
         * @param {number} id 
         * @param {ContactInformationUpdateRequestContract} contactInformationUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationContactInformationUpdate: async (id: number, contactInformationUpdateRequestContract: ContactInformationUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationContactInformationUpdate', 'id', id)
            // verify required parameter 'contactInformationUpdateRequestContract' is not null or undefined
            assertParamExists('organizationContactInformationUpdate', 'contactInformationUpdateRequestContract', contactInformationUpdateRequestContract)
            const localVarPath = changeUrl(`/api/contact-informations/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactInformationUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactInformationApi - functional programming interface
 * @export
 */
export const ContactInformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactInformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates or updates the contact information for the organization
         * @param {ContactInformationCreateRequestContract} contactInformationCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationContactInformationCreate(contactInformationCreateRequestContract: ContactInformationCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationContactInformationCreate(contactInformationCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates the contact information for the organization with the given id
         * @param {number} id 
         * @param {ContactInformationUpdateRequestContract} contactInformationUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationContactInformationUpdate(id: number, contactInformationUpdateRequestContract: ContactInformationUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationContactInformationUpdate(id, contactInformationUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactInformationApi - factory interface
 * @export
 */
export const ContactInformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactInformationApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates or updates the contact information for the organization
         * @param {ContactInformationCreateRequestContract} contactInformationCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationContactInformationCreate(contactInformationCreateRequestContract: ContactInformationCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<ContactInformation> {
            return localVarFp.organizationContactInformationCreate(contactInformationCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates the contact information for the organization with the given id
         * @param {number} id 
         * @param {ContactInformationUpdateRequestContract} contactInformationUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationContactInformationUpdate(id: number, contactInformationUpdateRequestContract: ContactInformationUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<ContactInformation> {
            return localVarFp.organizationContactInformationUpdate(id, contactInformationUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for organizationContactInformationCreate operation in ContactInformationApi.
 * @export
 * @interface ContactInformationApiOrganizationContactInformationCreateRequest
 */
export interface ContactInformationApiOrganizationContactInformationCreateRequest {
    /**
     * 
     * @type {ContactInformationCreateRequestContract}
     * @memberof ContactInformationApiOrganizationContactInformationCreate
     */
    readonly contactInformationCreateRequestContract: ContactInformationCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof ContactInformationApiOrganizationContactInformationCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof ContactInformationApiOrganizationContactInformationCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof ContactInformationApiOrganizationContactInformationCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof ContactInformationApiOrganizationContactInformationCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof ContactInformationApiOrganizationContactInformationCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof ContactInformationApiOrganizationContactInformationCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for organizationContactInformationUpdate operation in ContactInformationApi.
 * @export
 * @interface ContactInformationApiOrganizationContactInformationUpdateRequest
 */
export interface ContactInformationApiOrganizationContactInformationUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof ContactInformationApiOrganizationContactInformationUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ContactInformationUpdateRequestContract}
     * @memberof ContactInformationApiOrganizationContactInformationUpdate
     */
    readonly contactInformationUpdateRequestContract: ContactInformationUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof ContactInformationApiOrganizationContactInformationUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof ContactInformationApiOrganizationContactInformationUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof ContactInformationApiOrganizationContactInformationUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof ContactInformationApiOrganizationContactInformationUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof ContactInformationApiOrganizationContactInformationUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof ContactInformationApiOrganizationContactInformationUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * ContactInformationApi - object-oriented interface
 * @export
 * @class ContactInformationApi
 * @extends {BaseAPI}
 */
export class ContactInformationApi extends BaseAPI {
    /**
     * 
     * @summary Creates or updates the contact information for the organization
     * @param {ContactInformationApiOrganizationContactInformationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactInformationApi
     */
    public organizationContactInformationCreate(requestParameters: ContactInformationApiOrganizationContactInformationCreateRequest, options?: AxiosRequestConfig) {
        return ContactInformationApiFp(this.configuration).organizationContactInformationCreate(requestParameters.contactInformationCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates the contact information for the organization with the given id
     * @param {ContactInformationApiOrganizationContactInformationUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactInformationApi
     */
    public organizationContactInformationUpdate(requestParameters: ContactInformationApiOrganizationContactInformationUpdateRequest, options?: AxiosRequestConfig) {
        return ContactInformationApiFp(this.configuration).organizationContactInformationUpdate(requestParameters.id, requestParameters.contactInformationUpdateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type OrganizationContactInformationCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const OrganizationContactInformationCreateErrorCodes = [
    401,
    422,
];

export type OrganizationContactInformationUpdateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const OrganizationContactInformationUpdateErrorCodes = [
    401,
    422,
];


