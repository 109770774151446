import React from 'react';

import { RBAC } from '@npm/core/ui/models/permissions';
import { componentLoader } from '@npm/core/ui/routes/componentLoader';
import { type issuerWorkstationRouteKeys } from '@npm/core/ui/routes/index';
import { type RouteDefinition } from '@npm/core/ui/routes/router.types';
import {
  OrderPageTitle,
  SubmissionPageTitle,
} from '@npm/features/routes/components/pageTitle';

import { MatchDetailTitle } from '../features/marketplace/MatchesIssuer/matchDetail/detail/MatchDetail.title';
import { RofrManageTitle } from '../features/marketplace/MatchesIssuer/matchDetail/rofrManage/RofrManage.title';
import { ActiveProgramsTitle } from '../features/programs/ActivePrograms';
import { ExpiredProgramsTitle } from '../features/programs/ExpiredPrograms/ExpiredProgramsTitle';
import { ProgramDetailPageTitle } from '../features/programs/ProgramDetail/PageTitle';
import { UpcomingProgramsTitle } from '../features/programs/UpcomingPrograms/UpcomingProgramsTitle';

type RouteDef = RouteDefinition & {
  path: issuerWorkstationRouteKeys;
};

const workstationPrefix = 'issuer-workstation';
const { isIssMenuPrograms, isIssMenuOther, isIssMenuAgreements } = RBAC.issuer;

export const registerRoutes = (desc: RouteDefinition[]) => {
  routes.forEach(r => {
    desc.push(r);
  });
};

export const routes: RouteDef[] = [
  {
    path: `/${workstationPrefix}/programs/upcoming-programs`,
    component: componentLoader(
      () => import(`../pages/programs/upcoming-programs`)
    ),
    pageHistory: {
      isRoot: true,
    },
    title: props => <UpcomingProgramsTitle {...props} />,
    permissionsChecks: [isIssMenuPrograms],
    subRoutes: [
      {
        path: `/${workstationPrefix}/programs/upcoming-programs/:programId`,
        component: componentLoader(() => import(`../pages/programs/detail`)),
        title: props => <ProgramDetailPageTitle {...props} />,
        isLocalScope: true,
        permissionsChecks: [isIssMenuPrograms],
      },
    ],
  },
  {
    path: `/${workstationPrefix}/programs/active-programs`,
    component: componentLoader(
      () => import(`../pages/programs/active-programs`)
    ),
    pageHistory: {
      isRoot: true,
    },
    title: props => <ActiveProgramsTitle {...props} />,
    permissionsChecks: [isIssMenuPrograms],
    subRoutes: [
      {
        path: `/${workstationPrefix}/programs/active-programs/:programId`,
        component: componentLoader(() => import(`../pages/programs/detail`)),
        title: props => <ProgramDetailPageTitle {...props} />,
        isLocalScope: true,
        permissionsChecks: [isIssMenuPrograms],
        subRoutes: [
          {
            path: `/${workstationPrefix}/programs/active-programs/:programId/submissions/:submissionId`,
            component: componentLoader(
              () => import(`../pages/programs/submission-detail`)
            ),
            title: props => <SubmissionPageTitle {...props} />,
            isLocalScope: true,
            permissionsChecks: [isIssMenuPrograms],
            subRoutes: [
              {
                path: `/${workstationPrefix}/programs/active-programs/:programId/submissions/:submissionId/orders/:orderId`,
                component: componentLoader(
                  () => import(`../pages/programs/order-detail`)
                ),
                title: props => <OrderPageTitle {...props} />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: `/${workstationPrefix}/programs/expired-programs`,
    component: componentLoader(
      () => import(`../pages/programs/expired-programs`)
    ),
    pageHistory: {
      isRoot: true,
    },
    title: props => <ExpiredProgramsTitle {...props} />,
    permissionsChecks: [isIssMenuPrograms],
    subRoutes: [
      {
        path: `/${workstationPrefix}/programs/expired-programs/:programId`,
        component: componentLoader(() => import(`../pages/programs/detail`)),
        title: props => <ProgramDetailPageTitle {...props} />,
        isLocalScope: true,
        permissionsChecks: [isIssMenuPrograms],
        subRoutes: [
          {
            path: `/${workstationPrefix}/programs/expired-programs/:programId/submissions/:submissionId`,
            component: componentLoader(
              () => import(`../pages/programs/submission-detail`)
            ),
            title: props => <SubmissionPageTitle {...props} />,
            isLocalScope: true,
            permissionsChecks: [isIssMenuPrograms],
            subRoutes: [
              {
                path: `/${workstationPrefix}/programs/expired-programs/:programId/submissions/:submissionId/orders/:orderId`,
                component: componentLoader(
                  () => import(`../pages/programs/order-detail`)
                ),
                title: props => <OrderPageTitle {...props} />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: `/${workstationPrefix}/accounts/:accountId`,
    component: componentLoader(() => import(`../pages/accounts`)),
    isLocalScope: true,
    permissionsChecks: [isIssMenuPrograms],
    pageHistory: {
      isRoot: false,
    },
  },
  {
    path: `/${workstationPrefix}/marketplace/unverified-holdings`,
    component: componentLoader(() => import(`../pages/unverified-holdings`)),
    pageHeaderProps: {
      title: 'Unverified Holdings',
      tooltip:
        'Holdings that have been added in connection with a transaction. Unverified Holdings must be verified by the Issuer prior to approving a transaction.',
    },
    permissionsChecks: [isIssMenuOther],
    pageHistory: {
      isRoot: true,
    },
  },
  {
    path: `/${workstationPrefix}/marketplace/holdings`,
    component: componentLoader(() => import(`../pages/holdings`)),
    pageHeaderProps: {
      title: 'Verified Holdings',
      tooltip:
        'Holdings that have been added or verified by the Issuer or are associated with an Issuer sponsored transaction.',
    },
    permissionsChecks: [isIssMenuOther],
    pageHistory: {
      isRoot: true,
    },
  },
  {
    path: `/${workstationPrefix}/marketplace/matches`,
    component: componentLoader(() => import(`../pages/matches`)),
    pageHeaderProps: {
      title: 'Matches',
      tooltip:
        'All the Matches for your Company on the NPM Platform are sorted by issuer action required and then by most recently updated from top to bottom.',
    },
    permissionsChecks: [isIssMenuOther],
    pageHistory: {
      isRoot: true,
    },
    subRoutes: [
      {
        path: `/${workstationPrefix}/marketplace/matches/:matchId`,
        component: componentLoader(
          () => import(`../pages/matches/matchDetail`)
        ),
        title: () => <MatchDetailTitle />,
        isLocalScope: true,
        subRoutes: [
          {
            path: `/${workstationPrefix}/marketplace/matches/:matchId/:action`,
            component: componentLoader(
              () => import(`../pages/matches/rofrManage`)
            ),
            title: () => <RofrManageTitle />,
            isLocalScope: true,
          },
        ],
      },
    ],
  },
  {
    path: `/${workstationPrefix}/marketplace/agreements`,
    component: componentLoader(() => import(`../pages/agreements`)),
    pageHeaderProps: {
      title: 'Agreements',
      tooltip:
        'All transaction documents for your Company on the NPM Platform.',
    },
    permissionsChecks: [isIssMenuAgreements],
    pageHistory: {
      isRoot: true,
    },
  },
  {
    path: `/${workstationPrefix}/general/accounts`,
    component: componentLoader(() => import(`../pages/general/accounts`)),
    title: 'Accounts',
    permissionsChecks: [isIssMenuOther],
    pageHistory: {
      isRoot: true,
    },
  },
  {
    path: `/${workstationPrefix}/general/dashboard`,
    title: 'Dashboard',
    component: componentLoader(() => import(`../pages/general/dashboard`)),
    pageHistory: {
      isRoot: true,
    },
  },
  {
    path: `/${workstationPrefix}`,
    component: componentLoader(() => import(`../pages`)),
  },
  {
    path: `/${workstationPrefix}/agreements/interim`,
    component: componentLoader(
      () => import(`@npm/features/agreements/AgreementsInterim`)
    ),
  },
];
