import React, { type ComponentProps } from 'react';

import { getFullName } from '@npm/core/ui/utils/formatters';
import {
  type Account,
  type CodebookItem,
  type QueryUserRoles,
  type RepresentativeAggregate,
  type UserRoleAggregate,
  useAccountShow,
} from '@npm/data-access';

import { AccountPanel } from '../../../../account/components/AccountPanel';
import { type OboDefinition } from '../../../user/role/userRole.types';
import { useObo } from '../../role';
import { useCurrentRole } from '../../role/hooks/useCurrentRole';
import { useUserContextStore } from '../store';

type Props = {
  accountId?: number;
  accountPanelProps?: Partial<ComponentProps<typeof AccountPanel>>;
  roles?: QueryUserRoles;
};

const parseObo = (
  oboAccount: OboDefinition['account'],
  oboRepresentative: OboDefinition['representative']
) => ({
  accountName: oboAccount?.name,
  brokerageFirmName: oboAccount?.brokerage_firm?.name,
  accountExternalId: oboAccount?.external_id,
  accountType: oboAccount.type?.name,
  representativeName: getRepresentativeName(oboRepresentative),
  representativeRole: getRepresentativeRole(oboRepresentative),
});

const parseRole = (role: UserRoleAggregate, representativeName: string) => ({
  accountName: role?.subject?.name,
  brokerageFirmName: role?.subject?.brokerage_firm_name,
  accountExternalId: role?.subject?.external_id,
  accountType: role?.subject?.type?.name,
  representativeName,
  representativeRole: role?.role_name?.name,
});

const parseAccount = (account: Account) => ({
  accountName: account?.name,
  accountExternalId: account?.external_id,
  accountType: account?.type.name,
  brokerageFirmName: account?.brokerage_firm?.name,
});

export const AccountInfo = (props: Props) => {
  // override currently selected role / obo with any account by accountId
  const { data: account } = useAccountShow(
    { id: props.accountId?.toString() },
    {
      queryConfig: {
        enabled: !!props.accountId,
      },
      roles: props.roles,
    }
  );

  const { isObo, oboAccount, oboRepresentative } = useObo();

  const role = useCurrentRole();
  const getFullName = useUserContextStore(store => store.getFullName);

  const getAccountInfo = (): ComponentProps<typeof AccountPanel> => {
    if (props.accountId) return parseAccount(account);

    return isObo
      ? parseObo(oboAccount, oboRepresentative)
      : parseRole(role, getFullName());
  };

  return <AccountPanel {...getAccountInfo()} {...props.accountPanelProps} />;
};

function getRepresentativeName(oboRepresentative: RepresentativeAggregate) {
  return getFullName(
    oboRepresentative?.first,
    oboRepresentative?.last,
    oboRepresentative?.middle
  );
}

function getRepresentativeRole(oboRepresentative: RepresentativeAggregate) {
  return (oboRepresentative?.access_as as unknown as CodebookItem)?.name;
}
