import { PAGINATION_QUERY_SUFFIX } from './usePagination';
import {
  type PaginationQueryKey,
  paginationQueryKeys,
} from './usePagination.types';

export const isPaginationQueryKey = (
  key: PaginationQueryKey | string
): key is PaginationQueryKey => {
  return paginationQueryKeys.includes(key as PaginationQueryKey);
};

export const getPaginationQueryKey = (url: string) => {
  const parsedUrl = new URL(url);
  const queryParams = parsedUrl.searchParams;

  for (const [key, value] of queryParams.entries()) {
    if (key.endsWith(PAGINATION_QUERY_SUFFIX) && /^\d+$/.test(value)) {
      const value = key.replace(PAGINATION_QUERY_SUFFIX, '');
      return isPaginationQueryKey(value) ? value : null;
    }
  }

  return null;
};
