import React, { type ComponentProps } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';

type Props = Omit<ComponentProps<typeof Modal>, 'children'>;

export const ModalHowTo = (props: Props) => {
  return (
    <Modal
      title={'How to digitize your photo ID'}
      okText="Understood"
      size="md"
      {...props}
    >
      <Text size="sm" weight="bold" colorVariant="primary">
        Smart Phone
      </Text>
      <Text size="sm" marginBottom="md">
        Place your ID on a white background and photograph it from above with
        your phone&apos;s camera. Email the photo to yourself and then save it
        to your computer.
      </Text>
      <Text size="sm" weight="bold" colorVariant="primary">
        Digital Camera
      </Text>
      <Text size="sm" marginBottom="md">
        Place your ID on a white background and photograph it from above with
        your digital camera. Move your image to your computer using the cable
        provided by the camera manufacturer.
      </Text>
      <Text size="sm" weight="bold" colorVariant="primary">
        Scanner
      </Text>
      <Text size="sm" marginBottom="md">
        Place your ID face-down on the glass of your scanner. Close the lid and
        then follow the manufacturer’s instructions to scan the ID.
      </Text>
    </Modal>
  );
};
