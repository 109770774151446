/* eslint-disable no-restricted-imports */
import React, { useMemo } from 'react';
import { type LinkProps, Link as ReactRouterLink } from 'react-router-dom';

import { useCurrentRoute } from '../../../hooks/useCurrentRoute';
import { usePreservableQueryParamsStore } from '../../../services/paths';
import { useHelmetStore } from '../../atoms/Helmet';

import {
  getLocationDescriptorObject,
  getNewStateWithPageHistory,
} from './Link.utils';
import { useLocation } from './useLocation';

// this is a Link component, which preserves
// the specified query params, which we want
// to keep in the url
export const Link = ({ to, children, ...props }: LinkProps) => {
  const location = useLocation();
  const currentRoute = useCurrentRoute();
  const pageTitle = useHelmetStore(x => x.pageTitle);

  // this is here to ensure that url will be refreshed if role values changes
  const preservableParams = usePreservableQueryParamsStore(
    x => x.preservableQueryParams
  );

  const enhancedTo = useMemo(() => {
    const descriptor = getLocationDescriptorObject(to, location);
    descriptor.state = getNewStateWithPageHistory(
      descriptor,
      pageTitle,
      location,
      currentRoute
    );
    return descriptor;
  }, [to, location, pageTitle, currentRoute, preservableParams]);

  return (
    <ReactRouterLink {...props} to={enhancedTo}>
      {children}
    </ReactRouterLink>
  );
};
