import React from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { matchPath, useLocation } from 'react-router';
import { Skeleton } from 'antd';

import { Helmet } from '@npm/core/ui/components/atoms/Helmet';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useCurrentRoute } from '@npm/core/ui/hooks/useCurrentRoute';
import { type RouteTitleProps } from '@npm/core/ui/routes/router.types';
import { truncate } from '@npm/core/ui/utils/formatters';
import { useEventShow } from '@npm/data-access';

export const EventPageTitle = ({ raw }: RouteTitleProps) => {
  const { isMobile } = useBreakpoints();
  const location = useLocation();
  const currentRoute = useCurrentRoute();
  const handleError = useErrorHandler();

  const match = matchPath(location.pathname, {
    path: currentRoute.route.path,
    exact: true,
    strict: false,
  });

  const { programId } = match.params as { programId: string };

  // TODO: probably set to cache-only? once react-query setup
  const { data, isLoading } = useEventShow(
    {
      id: Number(programId),
      includeField: [
        'assets',
        'issuance',
        'issuer_entity',
        'submission_statistics',
        'settings',
        'order_settings',
      ],
    },
    {
      onError: error => {
        console.error(error);
        // Ignore
      },
    }
  );

  const title = data?.name;

  // for breadcrumbs
  if (raw) {
    return <>{isLoading ? '...' : isMobile ? truncate(title) : title}</>;
  }

  return isLoading ? (
    <Skeleton paragraph={{ rows: 1 }} title={false} active />
  ) : (
    <>
      <Helmet pageTitle={title}></Helmet>
      {title}
    </>
  );
};
