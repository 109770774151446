/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Notification } from '../model';
// @ts-ignore
import { NotificationBulk } from '../model';
// @ts-ignore
import { NotificationIndex } from '../model';
// @ts-ignore
import { NotificationUpdateRequestContract } from '../model';
/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary update all notifications for current user
         * @param {NotificationUpdateRequestContract} notificationUpdateRequestContract 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [notificationState] filters notifications by read or unread
         * @param {string} [notificationCategory] filters notifications by category type
         * @param {string} [createdAfter] 
         * @param {boolean} [sortByNewest] orders notifications from most recent to least recent
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationBulkUpdate: async (notificationUpdateRequestContract: NotificationUpdateRequestContract, page?: number, size?: number, notificationState?: string, notificationCategory?: string, createdAfter?: string, sortByNewest?: boolean, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationUpdateRequestContract' is not null or undefined
            assertParamExists('notificationBulkUpdate', 'notificationUpdateRequestContract', notificationUpdateRequestContract)
            const localVarPath = changeUrl(`/api/notifications/bulk_update`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (notificationState !== undefined) {
                localVarQueryParameter['notification_state'] = notificationState;
            }

            if (notificationCategory !== undefined) {
                localVarQueryParameter['notification_category'] = notificationCategory;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['created_after'] = createdAfter;
            }

            if (sortByNewest !== undefined) {
                localVarQueryParameter['sort_by_newest'] = sortByNewest;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list notifications
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [notificationState] filters notifications by read or unread
         * @param {string} [notificationCategory] filters notifications by category type
         * @param {string} [createdAfter] 
         * @param {boolean} [sortByNewest] orders notifications from most recent to least recent
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationIndex: async (page?: number, size?: number, notificationState?: string, notificationCategory?: string, createdAfter?: string, sortByNewest?: boolean, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/notifications`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (notificationState !== undefined) {
                localVarQueryParameter['notification_state'] = notificationState;
            }

            if (notificationCategory !== undefined) {
                localVarQueryParameter['notification_category'] = notificationCategory;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['created_after'] = createdAfter;
            }

            if (sortByNewest !== undefined) {
                localVarQueryParameter['sort_by_newest'] = sortByNewest;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary show a notification
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationShow: async (id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationShow', 'id', id)
            const localVarPath = changeUrl(`/api/notifications/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a notification
         * @param {number} id 
         * @param {NotificationUpdateRequestContract} notificationUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationUpdate: async (id: number, notificationUpdateRequestContract: NotificationUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationUpdate', 'id', id)
            // verify required parameter 'notificationUpdateRequestContract' is not null or undefined
            assertParamExists('notificationUpdate', 'notificationUpdateRequestContract', notificationUpdateRequestContract)
            const localVarPath = changeUrl(`/api/notifications/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary update all notifications for current user
         * @param {NotificationUpdateRequestContract} notificationUpdateRequestContract 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [notificationState] filters notifications by read or unread
         * @param {string} [notificationCategory] filters notifications by category type
         * @param {string} [createdAfter] 
         * @param {boolean} [sortByNewest] orders notifications from most recent to least recent
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationBulkUpdate(notificationUpdateRequestContract: NotificationUpdateRequestContract, page?: number, size?: number, notificationState?: string, notificationCategory?: string, createdAfter?: string, sortByNewest?: boolean, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationBulk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationBulkUpdate(notificationUpdateRequestContract, page, size, notificationState, notificationCategory, createdAfter, sortByNewest, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list notifications
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [notificationState] filters notifications by read or unread
         * @param {string} [notificationCategory] filters notifications by category type
         * @param {string} [createdAfter] 
         * @param {boolean} [sortByNewest] orders notifications from most recent to least recent
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationIndex(page?: number, size?: number, notificationState?: string, notificationCategory?: string, createdAfter?: string, sortByNewest?: boolean, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationIndex(page, size, notificationState, notificationCategory, createdAfter, sortByNewest, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary show a notification
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationShow(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a notification
         * @param {number} id 
         * @param {NotificationUpdateRequestContract} notificationUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationUpdate(id: number, notificationUpdateRequestContract: NotificationUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationUpdate(id, notificationUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @summary update all notifications for current user
         * @param {NotificationUpdateRequestContract} notificationUpdateRequestContract 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [notificationState] filters notifications by read or unread
         * @param {string} [notificationCategory] filters notifications by category type
         * @param {string} [createdAfter] 
         * @param {boolean} [sortByNewest] orders notifications from most recent to least recent
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationBulkUpdate(notificationUpdateRequestContract: NotificationUpdateRequestContract, page?: number, size?: number, notificationState?: string, notificationCategory?: string, createdAfter?: string, sortByNewest?: boolean, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<NotificationBulk> {
            return localVarFp.notificationBulkUpdate(notificationUpdateRequestContract, page, size, notificationState, notificationCategory, createdAfter, sortByNewest, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list notifications
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [notificationState] filters notifications by read or unread
         * @param {string} [notificationCategory] filters notifications by category type
         * @param {string} [createdAfter] 
         * @param {boolean} [sortByNewest] orders notifications from most recent to least recent
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationIndex(page?: number, size?: number, notificationState?: string, notificationCategory?: string, createdAfter?: string, sortByNewest?: boolean, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<NotificationIndex> {
            return localVarFp.notificationIndex(page, size, notificationState, notificationCategory, createdAfter, sortByNewest, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary show a notification
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationShow(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Notification> {
            return localVarFp.notificationShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a notification
         * @param {number} id 
         * @param {NotificationUpdateRequestContract} notificationUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationUpdate(id: number, notificationUpdateRequestContract: NotificationUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Notification> {
            return localVarFp.notificationUpdate(id, notificationUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for notificationBulkUpdate operation in NotificationApi.
 * @export
 * @interface NotificationApiNotificationBulkUpdateRequest
 */
export interface NotificationApiNotificationBulkUpdateRequest {
    /**
     * 
     * @type {NotificationUpdateRequestContract}
     * @memberof NotificationApiNotificationBulkUpdate
     */
    readonly notificationUpdateRequestContract: NotificationUpdateRequestContract

    /**
     * 
     * @type {number}
     * @memberof NotificationApiNotificationBulkUpdate
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof NotificationApiNotificationBulkUpdate
     */
    readonly size?: number

    /**
     * filters notifications by read or unread
     * @type {string}
     * @memberof NotificationApiNotificationBulkUpdate
     */
    readonly notificationState?: string

    /**
     * filters notifications by category type
     * @type {string}
     * @memberof NotificationApiNotificationBulkUpdate
     */
    readonly notificationCategory?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationBulkUpdate
     */
    readonly createdAfter?: string

    /**
     * orders notifications from most recent to least recent
     * @type {boolean}
     * @memberof NotificationApiNotificationBulkUpdate
     */
    readonly sortByNewest?: boolean

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationBulkUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationBulkUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationBulkUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationBulkUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationBulkUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationBulkUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for notificationIndex operation in NotificationApi.
 * @export
 * @interface NotificationApiNotificationIndexRequest
 */
export interface NotificationApiNotificationIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof NotificationApiNotificationIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof NotificationApiNotificationIndex
     */
    readonly size?: number

    /**
     * filters notifications by read or unread
     * @type {string}
     * @memberof NotificationApiNotificationIndex
     */
    readonly notificationState?: string

    /**
     * filters notifications by category type
     * @type {string}
     * @memberof NotificationApiNotificationIndex
     */
    readonly notificationCategory?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationIndex
     */
    readonly createdAfter?: string

    /**
     * orders notifications from most recent to least recent
     * @type {boolean}
     * @memberof NotificationApiNotificationIndex
     */
    readonly sortByNewest?: boolean

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for notificationShow operation in NotificationApi.
 * @export
 * @interface NotificationApiNotificationShowRequest
 */
export interface NotificationApiNotificationShowRequest {
    /**
     * 
     * @type {number}
     * @memberof NotificationApiNotificationShow
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for notificationUpdate operation in NotificationApi.
 * @export
 * @interface NotificationApiNotificationUpdateRequest
 */
export interface NotificationApiNotificationUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof NotificationApiNotificationUpdate
     */
    readonly id: number

    /**
     * 
     * @type {NotificationUpdateRequestContract}
     * @memberof NotificationApiNotificationUpdate
     */
    readonly notificationUpdateRequestContract: NotificationUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @summary update all notifications for current user
     * @param {NotificationApiNotificationBulkUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationBulkUpdate(requestParameters: NotificationApiNotificationBulkUpdateRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).notificationBulkUpdate(requestParameters.notificationUpdateRequestContract, requestParameters.page, requestParameters.size, requestParameters.notificationState, requestParameters.notificationCategory, requestParameters.createdAfter, requestParameters.sortByNewest, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list notifications
     * @param {NotificationApiNotificationIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationIndex(requestParameters: NotificationApiNotificationIndexRequest = {}, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).notificationIndex(requestParameters.page, requestParameters.size, requestParameters.notificationState, requestParameters.notificationCategory, requestParameters.createdAfter, requestParameters.sortByNewest, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary show a notification
     * @param {NotificationApiNotificationShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationShow(requestParameters: NotificationApiNotificationShowRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).notificationShow(requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a notification
     * @param {NotificationApiNotificationUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationUpdate(requestParameters: NotificationApiNotificationUpdateRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).notificationUpdate(requestParameters.id, requestParameters.notificationUpdateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type NotificationBulkUpdateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const NotificationBulkUpdateErrorCodes = [
];

export type NotificationIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const NotificationIndexErrorCodes = [
];

export type NotificationShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const NotificationShowErrorCodes = [
    401,
];

export type NotificationUpdateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const NotificationUpdateErrorCodes = [
    401,
];


