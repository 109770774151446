import { isSameDay } from 'date-fns';
import { range } from 'lodash';

export const getDisabledTime = (
  value: Date | null | undefined,
  min: Date | undefined,
  max: Date | undefined
) => {
  if (!value) return undefined;
  if (!min && !max) return undefined;
  if (min && isSameDay(min, value)) {
    return () => ({
      disabledHours: () => range(0, min.getHours()),
      disabledMinutes: selectedHour => {
        if (selectedHour === min.getHours()) {
          return range(0, min.getMinutes());
        }
        return [];
      },
    });
  }
  if (max && isSameDay(value, max)) {
    return () => ({
      disabledHours: () => range(max.getHours() + 1, 24),
      disabledMinutes: selectedHour => {
        if (selectedHour === max.getHours()) {
          return range(max.getMinutes() + 1, 60);
        }
        return [];
      },
    });
  }
  return undefined;
};
