import { Fragment } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type Codebook,
  type EventSubmissionStatisticsInner,
  CbOwnerType,
} from '@npm/data-access';

import * as S from './ProgramCard.styles';

type Props = {
  statistics: EventSubmissionStatisticsInner[];
  ownerType: Codebook;
};

export const SubmissionStatistics = ({ statistics, ownerType }: Props) => {
  const isInformational =
    ownerType.code === CbOwnerType.items.InformationalEvent;

  const categoryKeys = isInformational
    ? new Set(['incomplete', 'pending', 'submitted'])
    : new Set(['pending', 'submitted', 'settled']);

  const filteredCategories =
    statistics?.filter(stat => categoryKeys.has(stat.submission_state?.code)) ||
    [];

  return statistics ? (
    <S.OrdersData>
      {filteredCategories.map((category, i) => (
        <Fragment key={i}>
          <Text size="sm" color="general" colorVariant="secondary">
            {category.submission_state?.name}
          </Text>
          <Text size="sm" color="general" colorVariant="primary">
            {category.count || 0}
          </Text>
        </Fragment>
      ))}
    </S.OrdersData>
  ) : (
    <Text size="sm" color="general" colorVariant="primary">
      --
    </Text>
  );
};
