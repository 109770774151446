import styled from 'styled-components';

export const Tooltip = styled.div<{
  $width: number;
  $height: number;
  $left: number;
  $top: number;
}>`
  gap: ${({ theme }) => theme.spacing.xs}px;

  width: ${({ $width }) => `${$width}px`};
  height: ${({ $height }) => `${$height}px`};
  left: ${({ $left }) => `${$left}px`};
  top: ${({ $top }) => `${$top}px`};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xs}px
    ${({ theme }) => theme.spacing.sm}px;
  gap: ${({ theme }) => theme.spacing.sm}px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 ${({ theme }) => theme.spacing.sm}px;
  background: ${({ theme }) => theme.color.general.layout.zero};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;

  & > div:not(:last-child) {
    border-bottom: 1px solid
      ${({ theme }) => theme.color.general.borders.primary};
  }
`;
