import React, { type ComponentProps } from 'react';

import { Modal, useModal } from '@npm/core/ui/components/molecules/Modal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import {
  DocumentViewerModal,
  useDocumentViewer,
} from '../../documents/DocumentViewer';
import { type HoldingCard } from '../Card/HoldingCard';

import { HoldingsCardsContainer } from './Cards';
import { HoldingsTableContainer } from './Table';

type TableProps = ComponentProps<typeof HoldingsTableContainer>;
type CardsProps = Omit<ComponentProps<typeof HoldingsCardsContainer>, 'header'>;
type CardHeaderConfig = Pick<
  ComponentProps<typeof HoldingCard>,
  'header'
>['header'];

type Props = { secondmarket: boolean | undefined } & TableProps &
  CardsProps & { cardHeader?: CardHeaderConfig };

export const HoldingsList = ({ cardHeader, ...props }: Props) => {
  const { isMobile } = useBreakpoints();
  const { documentViewerModalProps, showNpmDocument } = useDocumentViewer();
  const [openModal, modalProps] = useModal();

  const additionalProps: Pick<Props, 'onDocumentClick' | 'openModal'> = {
    onDocumentClick: showNpmDocument,
    openModal,
  };

  return (
    <>
      {isMobile ? (
        <HoldingsCardsContainer
          {...props}
          {...additionalProps}
          header={cardHeader}
        />
      ) : (
        <HoldingsTableContainer {...props} {...additionalProps} />
      )}
      <DocumentViewerModal {...documentViewerModalProps} />
      <Modal okText="Close" {...modalProps} />
    </>
  );
};
