/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  WorkstationApi,
  WorkstationIndexErrorCodes,
  WorkstationIndexErrorTypes,
  WorkstationCreateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new WorkstationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type WorkstationIndexMethod = WorkstationApi['workstationIndex'];
export type WorkstationIndexResponseType = MethodResult<WorkstationIndexMethod>;

export const useWorkstationIndex = (
  variables: Parameters<WorkstationIndexMethod>[0],
  config?: UseQueryConfig<
    WorkstationIndexMethod,
    WorkstationIndexErrorTypes
  >
) => {
  return useQuery<
    WorkstationIndexMethod,
    WorkstationIndexErrorTypes
  >(
    (...args) => api.workstationIndex(...args),
    WorkstationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['WorkstationIndex'],
        ...apiQueryConfig.WorkstationIndex,
        ...config?.queryConfig
      },
    },
    'WorkstationIndex',
  );
};

export const useWorkstationIndexInfinite = (
  variables: Parameters<WorkstationIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    WorkstationIndexMethod,
    WorkstationIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    WorkstationIndexMethod,
    WorkstationIndexErrorTypes
  >(
    (...args) => api.workstationIndex(...args),
    WorkstationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['WorkstationIndex', 'WorkstationIndexInfinite'],
        ...apiQueryConfig.WorkstationIndex,
        ...config?.queryConfig,
      },
    },
    'WorkstationIndex',
  );
};

export const useWorkstationIndexLazy = (baseOptions?: {
  variables?: Parameters<WorkstationIndexMethod>[0],
  config?: UseQueryConfig<
    WorkstationIndexMethod,
    WorkstationIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    WorkstationIndexMethod,
    WorkstationIndexErrorTypes
  >(
    (...args) => api.workstationIndex(...args),
    WorkstationIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['WorkstationIndex'],
        ...apiQueryConfig.WorkstationIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'WorkstationIndex',
  );
};


export type WorkstationCreateMethod = WorkstationApi['workstationCreate'];

export const useWorkstationCreate = () => {
  return useMutation<WorkstationCreateMethod>(
    (...args) => api.workstationCreate(...args),
    WorkstationCreateErrorCodes,
    'WorkstationCreate',
    '/api/workstations',
    'post'
  );
};
