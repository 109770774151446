import { type ComponentProps } from 'react';
import styled from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { useBreakpoint } from '@npm/core/ui/utils/style';

type FlexProps = ComponentProps<typeof Flex>;

interface StepsWrapperProps extends FlexProps {
  fullWidth?: boolean;
}

const OPPORTUNITY_ITEM_WIDTH_TABLET = 312;
const OPPORTUNITY_ITEM_WIDTH_MOBILE = 270;

export const MobileCarousel = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md}px;
`;

export const StepsWrapper = styled(Flex).attrs<StepsWrapperProps>({
  gap: 'sm',
  direction: 'column',
})<StepsWrapperProps>`
  width: 100%;
  background-color: ${({ theme }) => theme.color.general.layout.two};
  padding: ${({ theme }) => theme.spacing.sm}px;
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  border: 2px solid ${({ theme }) => theme.color.general.backgrounds.muted};
  cursor: pointer;

  &.active {
    border: 2px solid ${({ theme }) => theme.color.blue_40};
  }

  ${({ theme }) => useBreakpoint(theme).desktop`
    width: ${OPPORTUNITY_ITEM_WIDTH_TABLET}px;
    min-width: ${OPPORTUNITY_ITEM_WIDTH_TABLET}px;
  `}

  ${({ theme }) => useBreakpoint(theme).smTablet`
    min-width: ${OPPORTUNITY_ITEM_WIDTH_TABLET}px;
  `}

  ${({ theme, fullWidth }) => useBreakpoint(theme).mobile`
    min-width: ${fullWidth ? '100%' : `${OPPORTUNITY_ITEM_WIDTH_MOBILE}px`};
  `}
`;

export const ResultWrapper = styled(Flex).attrs({
  justify: 'center',
  align: 'baseline',
})`
  flex-grow: 0;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm}px;
  background-color: ${({ theme }) => theme.color.general.layout.zero};
  text-align: center;

  & span,
  & div {
    flex: 1;
    flex-grow: 0;
  }
`;

export const ActionLabelWrapper = styled.div`
  margin-left: auto;
`;

export const Operator = styled.div`
  && {
    flex: 0 0 20px;
  }
`;
