import React from 'react';

import { useCurrentRoute } from '../../../../hooks/useCurrentRoute';
import { Helmet } from '../../../atoms/Helmet';

type Props = {
  title?: string;
};
export const PageTitle = ({ title }: Props) => {
  const currentRoute = useCurrentRoute();
  const { title: routeTitle, pageHeaderProps } = currentRoute?.route ?? {};

  const isRenderFunction = routeTitle && typeof routeTitle === 'function';

  const defaultTitle = !isRenderFunction
    ? (routeTitle as string) || pageHeaderProps?.title
    : null;

  return (
    <Helmet pageTitle={title || defaultTitle || null}>
      {isRenderFunction ? routeTitle() : null}
    </Helmet>
  );
};
