import { type UIEvent, useRef, useState } from 'react';

import { useResizeEffect } from '../../../../hooks/useResizeEffect';
import { StatisticCard } from '../../StatisticCard';
import { type StatisticsProps } from '../Statistics.types';

import { ScrollIndicator } from './ScrollIndicator';

import * as S from './StatisticsMobile.styles';

export const StatisticsMobile = ({
  gutter,
  config,
  outlined,
}: StatisticsProps) => {
  const [showIndicator, setShowIndicator] = useState<boolean>();
  const scrollableElementRef = useRef<HTMLDivElement>(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollLeft } = e.currentTarget;
    setScrollLeft(scrollLeft);
  };

  const scrollIndicatorClick = () => {
    scrollableElementRef?.current?.scrollBy({
      left: 200,
      behavior: 'smooth',
    });
  };

  useResizeEffect(() => {
    const currentElement = scrollableElementRef.current;
    if (currentElement) {
      const { scrollWidth, clientWidth } = currentElement;
      setShowIndicator(scrollWidth > clientWidth);
    }
  }, [scrollableElementRef]);

  return (
    <S.Container>
      <S.ScrollableContainer ref={scrollableElementRef} onScroll={handleScroll}>
        <S.List $gap={gutter?.[0]}>
          {config.map((stat, i) => (
            <StatisticCard
              outlined={outlined}
              withTextWrap={false}
              {...stat}
              key={stat.key || i}
            />
          ))}
        </S.List>

        {showIndicator && scrollableElementRef.current && (
          <ScrollIndicator
            onClick={scrollIndicatorClick}
            maxScrollDistance={
              scrollableElementRef.current.scrollWidth -
              scrollableElementRef.current.clientWidth
            }
            scrollLeft={scrollLeft}
          />
        )}
      </S.ScrollableContainer>
    </S.Container>
  );
};
