import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { CardBase } from '@npm/core/ui/components/atoms/CardBase';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { Table } from '@npm/core/ui/components/molecules/Table';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type VenusApi } from '@npm/data-access';

import {
  DocumentViewerModal,
  useDocumentViewer,
} from '../../../../../documents/DocumentViewer';
import { useHandleFilingClick } from '../../../CompanyOverview.hooks';
import { PointLineIcon } from '../../../components/PointLineIcon';
import {
  TableScrollIndicator,
  useScrollIndicatorClick,
} from '../../../components/TableScrollIndicator';
import { type PrimaryValuation } from '../../PrimaryRounds.types';
import { getPrimaryRoundsColor } from '../../PrimaryRounds.utils';

import { transformPrimaryRoundsForTable } from './PrimaryRounds.utils';
import { getPrimaryRoundsTableColumns } from './PrimaryRoundsTable.columns';

type Props = {
  items: VenusApi.PrimaryOcfValuationShow[];
  venusCompanyId: number | undefined;
  issuerEntityId: number | undefined;
};

const TABLE_ID = 'primary-rounds-table';
const TABLE_MAX_HEIGHT = 275;

export const PrimaryRoundsTable = ({
  items,
  venusCompanyId,
  issuerEntityId,
}: Props) => {
  const theme = useTheme();

  const { isMobile } = useBreakpoints();

  const data = useMemo(() => transformPrimaryRoundsForTable(items), [items]);
  const showScrollIndicator = data.length > 5;

  const { documentViewerModalProps, showDocumentPreview } = useDocumentViewer();

  const { tableProps, scrollIndicatorProps } =
    useScrollIndicatorClick<PrimaryValuation>(
      TABLE_ID,
      TABLE_MAX_HEIGHT,
      showScrollIndicator
    );

  const { handleFilingClick, filingIdLoading } = useHandleFilingClick(
    venusCompanyId,
    issuerEntityId,
    showDocumentPreview
  );

  const header = (
    <Margin left={'md'} top={'sm'} bottom={'sm'}>
      <Flex direction="row" gap={'sm'} align={'center'}>
        <PointLineIcon color={getPrimaryRoundsColor(theme)} />
        <Heading variant="h2">
          Primary Rounds{' '}
          <Text colorVariant="tertiary" as="span">
            ({data.length})
          </Text>
        </Heading>
      </Flex>
    </Margin>
  );

  return (
    <CardBase
      noContentPadding={true}
      header={header}
      style={{ position: 'relative' }}
    >
      <Table
        id={TABLE_ID}
        disablePagination={true}
        outlined={false}
        dataSource={data}
        columns={getPrimaryRoundsTableColumns(
          isMobile,
          handleFilingClick,
          filingIdLoading
        )}
        noDataArea={<NoDataArea title={'No primary rounds'} />}
        {...tableProps}
      />
      {showScrollIndicator && (
        <TableScrollIndicator {...scrollIndicatorProps} />
      )}
      <DocumentViewerModal {...documentViewerModalProps} />
    </CardBase>
  );
};
