import styled, { css } from 'styled-components';

import { getFocusBorderStyles } from '../../../utils/style';
import {
  Heading1Styles,
  Heading2Styles,
  Heading3Styles,
  SmallTextBoldVariationSettings,
  SmallTextStyles,
} from '../Typography';

export const WysiwygStyles = css`
  &, div, p {
    color: ${({ theme }) => theme.color.general.typography.primary} !important;
    ${SmallTextStyles}
  }

  h1 {
    ${Heading1Styles};
  }

  h2 {
    ${Heading2Styles};
  }

  h3 {
    ${Heading3Styles};
  }

  h1,
  h2,
  h3 {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }

  b,
  strong {
    ${SmallTextBoldVariationSettings};
  }

  blockquote {
    margin: 0;
    padding-left: ${({ theme }) => theme.spacing.lg}px;
    border-left: 3px solid ${({ theme }) =>
      theme.color.info.typography.primary};
    color: ${({ theme }) => theme.color.general.typography.secondary};
  }

  ul,
  ol {
    color: ${({ theme }) => theme.color.general.typography.primary};
    padding-inline-start: ${({ theme }) => theme.spacing.lg}px;
  }

  a {
    body.tab-focus ${getFocusBorderStyles()};
  }
`;

export const WysiwygContent = styled.div`
  ${WysiwygStyles};
`;
