import React, { memo, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorSkeleton } from '../../../atoms/ErrorSkeleton';
import { Skeleton } from '../../../atoms/Skeleton';
import { AlertContainer } from '../../../molecules/AlertContainer';
import { useLocation } from '../../../molecules/Link';
import { PageHeader } from '../../../molecules/PageHeader';
import { usePageHeaderStore } from '../../../molecules/PageHeader/PageHeader.store';
import {
  LAYOUT_CONTENT_WRAPPER_ID,
  LAYOUT_PAGE_HEADER_CONTAINER_ID,
} from '../Layout.constants';
import { useLayoutStore } from '../Layout.store';

import * as S from './Content.styles';

type Props = {
  children: React.ReactNode;
  showPageHeader?: boolean;
  fullWidth?: boolean;
  noPadding?: boolean;
};
export const Content = memo(
  ({
    children,
    showPageHeader = true,
    fullWidth = false,
    noPadding: noPaddingProp = false,
  }: Props) => {
    const location = useLocation();

    const showDefaultPageHeader = usePageHeaderStore(
      store => store.showDefault
    );
    const noPaddingStore = useLayoutStore(store => store.noPadding);
    const maxWidth = useLayoutStore(store => store.maxWidth);
    const fullWidthLayout = useLayoutStore(store => store.fullWidth);
    const noBottomPadding = useLayoutStore(store => store.noBottomPadding);

    return (
      <S.Wrapper
        id={LAYOUT_CONTENT_WRAPPER_ID}
        $noBottomPadding={noBottomPadding}
      >
        <S.WrapperPageHeader
          $maxWidth={maxWidth}
          id={LAYOUT_PAGE_HEADER_CONTAINER_ID}
          $fullWidth={fullWidth || fullWidthLayout}
          $noPadding={noPaddingProp || noPaddingStore}
        >
          {showPageHeader && showDefaultPageHeader ? (
            <PageHeader getContainer={null} isDefault={true} />
          ) : null}
        </S.WrapperPageHeader>
        <S.WrapperContent
          $maxWidth={maxWidth}
          $fullWidth={fullWidth || fullWidthLayout}
          $noPadding={noPaddingProp || noPaddingStore}
        >
          <Suspense fallback={<Skeleton.Base />}>
            {/* use location as a key so the error is cleared on location change */}
            <ErrorBoundary
              FallbackComponent={ErrorSkeleton}
              key={location.pathname}
            >
              <AlertContainer />
              <S.ChildrenWrapper $noPadding={noPaddingProp || noPaddingStore}>
                {children}
              </S.ChildrenWrapper>
            </ErrorBoundary>
          </Suspense>
        </S.WrapperContent>
      </S.Wrapper>
    );
  }
);
