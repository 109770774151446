import styled from 'styled-components';

import { useBreakpoint } from '@npm/core/ui/utils/style';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    margin-left: auto;
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    flex-direction: column;
    gap: ${theme.spacing.sm}px;
    align-items: stretch;
    padding: ${theme.spacing.md}px;

    & > div {
      flex-direction: column-reverse;
      width: 100%;
    }
  `}
`;
