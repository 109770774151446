/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalTenderOfferIndex } from '../model';
// @ts-ignore
import { InternalTenderOfferUpdateRequestContract } from '../model';
/**
 * TenderOffersApi - axios parameter creator
 * @export
 */
export const TenderOffersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get tender_offers for a company
         * @param {string} companyId 
         * @param {string} [date] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompanyTenderOffersIndex: async (companyId: string, date?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalCompanyTenderOffersIndex', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/tender_offers`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary remove a TenderOffer
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalRemoveTenderOffer: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalRemoveTenderOffer', 'id', id)
            const localVarPath = changeUrl(`/api/internal/tender_offers/{id}/remove`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieve a TenderOffer
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowTenderOffer: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalShowTenderOffer', 'id', id)
            const localVarPath = changeUrl(`/api/internal/tender_offers/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all tender_offers
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalTenderOffersIndex: async (page?: number, size?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/internal/tender_offers`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a TenderOffer
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalTenderOfferUpdateRequestContract} [internalTenderOfferUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateTenderOffer: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalTenderOfferUpdateRequestContract?: InternalTenderOfferUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateTenderOffer', 'id', id)
            const localVarPath = changeUrl(`/api/internal/tender_offers/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalTenderOfferUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenderOffersApi - functional programming interface
 * @export
 */
export const TenderOffersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenderOffersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get tender_offers for a company
         * @param {string} companyId 
         * @param {string} [date] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCompanyTenderOffersIndex(companyId: string, date?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTenderOfferIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCompanyTenderOffersIndex(companyId, date, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary remove a TenderOffer
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalRemoveTenderOffer(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalRemoveTenderOffer(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary retrieve a TenderOffer
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalShowTenderOffer(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalShowTenderOffer(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all tender_offers
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalTenderOffersIndex(page?: number, size?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTenderOfferIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalTenderOffersIndex(page, size, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a TenderOffer
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalTenderOfferUpdateRequestContract} [internalTenderOfferUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateTenderOffer(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalTenderOfferUpdateRequestContract?: InternalTenderOfferUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateTenderOffer(id, xNPMAuthorization, xSubscriptionPlan, internalTenderOfferUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenderOffersApi - factory interface
 * @export
 */
export const TenderOffersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenderOffersApiFp(configuration)
    return {
        /**
         * 
         * @summary get tender_offers for a company
         * @param {string} companyId 
         * @param {string} [date] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompanyTenderOffersIndex(companyId: string, date?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalTenderOfferIndex> {
            return localVarFp.internalCompanyTenderOffersIndex(companyId, date, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary remove a TenderOffer
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalRemoveTenderOffer(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalRemoveTenderOffer(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieve a TenderOffer
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowTenderOffer(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalShowTenderOffer(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all tender_offers
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalTenderOffersIndex(page?: number, size?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalTenderOfferIndex> {
            return localVarFp.internalTenderOffersIndex(page, size, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a TenderOffer
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalTenderOfferUpdateRequestContract} [internalTenderOfferUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateTenderOffer(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalTenderOfferUpdateRequestContract?: InternalTenderOfferUpdateRequestContract, options?: any): AxiosPromise<void> {
            return localVarFp.internalUpdateTenderOffer(id, xNPMAuthorization, xSubscriptionPlan, internalTenderOfferUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for internalCompanyTenderOffersIndex operation in TenderOffersApi.
 * @export
 * @interface TenderOffersApiInternalCompanyTenderOffersIndexRequest
 */
export interface TenderOffersApiInternalCompanyTenderOffersIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof TenderOffersApiInternalCompanyTenderOffersIndex
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof TenderOffersApiInternalCompanyTenderOffersIndex
     */
    readonly date?: string

    /**
     * 
     * @type {string}
     * @memberof TenderOffersApiInternalCompanyTenderOffersIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof TenderOffersApiInternalCompanyTenderOffersIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalRemoveTenderOffer operation in TenderOffersApi.
 * @export
 * @interface TenderOffersApiInternalRemoveTenderOfferRequest
 */
export interface TenderOffersApiInternalRemoveTenderOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof TenderOffersApiInternalRemoveTenderOffer
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof TenderOffersApiInternalRemoveTenderOffer
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof TenderOffersApiInternalRemoveTenderOffer
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalShowTenderOffer operation in TenderOffersApi.
 * @export
 * @interface TenderOffersApiInternalShowTenderOfferRequest
 */
export interface TenderOffersApiInternalShowTenderOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof TenderOffersApiInternalShowTenderOffer
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof TenderOffersApiInternalShowTenderOffer
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof TenderOffersApiInternalShowTenderOffer
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalTenderOffersIndex operation in TenderOffersApi.
 * @export
 * @interface TenderOffersApiInternalTenderOffersIndexRequest
 */
export interface TenderOffersApiInternalTenderOffersIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof TenderOffersApiInternalTenderOffersIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TenderOffersApiInternalTenderOffersIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof TenderOffersApiInternalTenderOffersIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof TenderOffersApiInternalTenderOffersIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalUpdateTenderOffer operation in TenderOffersApi.
 * @export
 * @interface TenderOffersApiInternalUpdateTenderOfferRequest
 */
export interface TenderOffersApiInternalUpdateTenderOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof TenderOffersApiInternalUpdateTenderOffer
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof TenderOffersApiInternalUpdateTenderOffer
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof TenderOffersApiInternalUpdateTenderOffer
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {InternalTenderOfferUpdateRequestContract}
     * @memberof TenderOffersApiInternalUpdateTenderOffer
     */
    readonly internalTenderOfferUpdateRequestContract?: InternalTenderOfferUpdateRequestContract
}

/**
 * TenderOffersApi - object-oriented interface
 * @export
 * @class TenderOffersApi
 * @extends {BaseAPI}
 */
export class TenderOffersApi extends BaseAPI {
    /**
     * 
     * @summary get tender_offers for a company
     * @param {TenderOffersApiInternalCompanyTenderOffersIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenderOffersApi
     */
    public internalCompanyTenderOffersIndex(requestParameters: TenderOffersApiInternalCompanyTenderOffersIndexRequest, options?: AxiosRequestConfig) {
        return TenderOffersApiFp(this.configuration).internalCompanyTenderOffersIndex(requestParameters.companyId, requestParameters.date, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary remove a TenderOffer
     * @param {TenderOffersApiInternalRemoveTenderOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenderOffersApi
     */
    public internalRemoveTenderOffer(requestParameters: TenderOffersApiInternalRemoveTenderOfferRequest, options?: AxiosRequestConfig) {
        return TenderOffersApiFp(this.configuration).internalRemoveTenderOffer(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieve a TenderOffer
     * @param {TenderOffersApiInternalShowTenderOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenderOffersApi
     */
    public internalShowTenderOffer(requestParameters: TenderOffersApiInternalShowTenderOfferRequest, options?: AxiosRequestConfig) {
        return TenderOffersApiFp(this.configuration).internalShowTenderOffer(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all tender_offers
     * @param {TenderOffersApiInternalTenderOffersIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenderOffersApi
     */
    public internalTenderOffersIndex(requestParameters: TenderOffersApiInternalTenderOffersIndexRequest = {}, options?: AxiosRequestConfig) {
        return TenderOffersApiFp(this.configuration).internalTenderOffersIndex(requestParameters.page, requestParameters.size, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a TenderOffer
     * @param {TenderOffersApiInternalUpdateTenderOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenderOffersApi
     */
    public internalUpdateTenderOffer(requestParameters: TenderOffersApiInternalUpdateTenderOfferRequest, options?: AxiosRequestConfig) {
        return TenderOffersApiFp(this.configuration).internalUpdateTenderOffer(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.internalTenderOfferUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InternalCompanyTenderOffersIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalCompanyTenderOffersIndexErrorCodes = [
    401,
    404,
];

export type InternalRemoveTenderOfferErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalRemoveTenderOfferErrorCodes = [
    401,
    404,
];

export type InternalShowTenderOfferErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalShowTenderOfferErrorCodes = [
    401,
    404,
];

export type InternalTenderOffersIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalTenderOffersIndexErrorCodes = [
    401,
];

export type InternalUpdateTenderOfferErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalUpdateTenderOfferErrorCodes = [
    401,
    404,
];


