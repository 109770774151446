import React from 'react';

import { FullScreenLoading } from '@npm/core/ui/components/molecules/FullScreenLoading';

// redirect from root page is handled inside withUserRoles hoc
const IndexPage = () => {
  return <FullScreenLoading />;
};

export default IndexPage;
