/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HashMapValue } from '../model';
/**
 * MarketingDataApi - axios parameter creator
 * @export
 */
export const MarketingDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary saves marketing data for 30 secs
         * @param {{ [key: string]: HashMapValue; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingDataCreate: async (requestBody: { [key: string]: HashMapValue; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('marketingDataCreate', 'requestBody', requestBody)
            const localVarPath = changeUrl(`/api/public/marketing-data`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the marketing data for guid
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingDataShow: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('marketingDataShow', 'guid', guid)
            const localVarPath = changeUrl(`/api/public/marketing-data/{guid}`)
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketingDataApi - functional programming interface
 * @export
 */
export const MarketingDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketingDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary saves marketing data for 30 secs
         * @param {{ [key: string]: HashMapValue; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketingDataCreate(requestBody: { [key: string]: HashMapValue; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: HashMapValue; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketingDataCreate(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the marketing data for guid
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketingDataShow(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: HashMapValue; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketingDataShow(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketingDataApi - factory interface
 * @export
 */
export const MarketingDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketingDataApiFp(configuration)
    return {
        /**
         * 
         * @summary saves marketing data for 30 secs
         * @param {{ [key: string]: HashMapValue; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingDataCreate(requestBody: { [key: string]: HashMapValue; }, options?: any): AxiosPromise<{ [key: string]: HashMapValue; }> {
            return localVarFp.marketingDataCreate(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the marketing data for guid
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingDataShow(guid: string, options?: any): AxiosPromise<{ [key: string]: HashMapValue; }> {
            return localVarFp.marketingDataShow(guid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for marketingDataCreate operation in MarketingDataApi.
 * @export
 * @interface MarketingDataApiMarketingDataCreateRequest
 */
export interface MarketingDataApiMarketingDataCreateRequest {
    /**
     * 
     * @type {{ [key: string]: HashMapValue; }}
     * @memberof MarketingDataApiMarketingDataCreate
     */
    readonly requestBody: { [key: string]: HashMapValue; }
}

/**
 * Request parameters for marketingDataShow operation in MarketingDataApi.
 * @export
 * @interface MarketingDataApiMarketingDataShowRequest
 */
export interface MarketingDataApiMarketingDataShowRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketingDataApiMarketingDataShow
     */
    readonly guid: string
}

/**
 * MarketingDataApi - object-oriented interface
 * @export
 * @class MarketingDataApi
 * @extends {BaseAPI}
 */
export class MarketingDataApi extends BaseAPI {
    /**
     * 
     * @summary saves marketing data for 30 secs
     * @param {MarketingDataApiMarketingDataCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingDataApi
     */
    public marketingDataCreate(requestParameters: MarketingDataApiMarketingDataCreateRequest, options?: AxiosRequestConfig) {
        return MarketingDataApiFp(this.configuration).marketingDataCreate(requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the marketing data for guid
     * @param {MarketingDataApiMarketingDataShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingDataApi
     */
    public marketingDataShow(requestParameters: MarketingDataApiMarketingDataShowRequest, options?: AxiosRequestConfig) {
        return MarketingDataApiFp(this.configuration).marketingDataShow(requestParameters.guid, options).then((request) => request(this.axios, this.basePath));
    }
}

export type MarketingDataCreateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MarketingDataCreateErrorCodes = [
];

export type MarketingDataShowErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MarketingDataShowErrorCodes = [
    404,
];


