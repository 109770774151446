import React from 'react';

import { Button } from '../../../../../atoms/Button';
import { Icon } from '../../../../../atoms/Icon';
import { Heading } from '../../../../../atoms/Typography';

import * as S from './HeadingWithAction.styles';

type Props = {
  title: string;
  onClick: () => void;
  onClickText: string;
};

export const HeadingWithAction = ({ title, onClick, onClickText }: Props) => {
  return (
    <S.Container>
      <Heading variant="h3" as="h1">
        {title}
      </Heading>
      <Button
        onClick={onClick}
        icon={<Icon name="plus" size="xs" />}
        blockOnMobile
      >
        {onClickText}
      </Button>
    </S.Container>
  );
};
