/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountsBeneficialOwnerCreateRequestContract } from '../model';
// @ts-ignore
import { AccountsBeneficialOwnerIndex } from '../model';
// @ts-ignore
import { AccountsBeneficialOwnerShow } from '../model';
// @ts-ignore
import { AccountsBeneficialOwnerUpdateRequestContract } from '../model';
// @ts-ignore
import { DestroySuccess } from '../model';
/**
 * BeneficialOwnerApi - axios parameter creator
 * @export
 */
export const BeneficialOwnerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create the beneficial owner
         * @param {number} accountId 
         * @param {AccountsBeneficialOwnerCreateRequestContract} accountsBeneficialOwnerCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBeneficialOwnerCreate: async (accountId: number, accountsBeneficialOwnerCreateRequestContract: AccountsBeneficialOwnerCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountBeneficialOwnerCreate', 'accountId', accountId)
            // verify required parameter 'accountsBeneficialOwnerCreateRequestContract' is not null or undefined
            assertParamExists('accountBeneficialOwnerCreate', 'accountsBeneficialOwnerCreateRequestContract', accountsBeneficialOwnerCreateRequestContract)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/beneficial-owners`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountsBeneficialOwnerCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete the beneficial owner
         * @param {number} accountId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBeneficialOwnerDestroy: async (accountId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountBeneficialOwnerDestroy', 'accountId', accountId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountBeneficialOwnerDestroy', 'id', id)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/beneficial-owners/{id}`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the beneficial owner with tax id
         * @param {number} accountId 
         * @param {number} id 
         * @param {AccountsBeneficialOwnerUpdateRequestContract} accountsBeneficialOwnerUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBeneficialOwnerUpdateTaxId: async (accountId: number, id: number, accountsBeneficialOwnerUpdateRequestContract: AccountsBeneficialOwnerUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountBeneficialOwnerUpdateTaxId', 'accountId', accountId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountBeneficialOwnerUpdateTaxId', 'id', id)
            // verify required parameter 'accountsBeneficialOwnerUpdateRequestContract' is not null or undefined
            assertParamExists('accountBeneficialOwnerUpdateTaxId', 'accountsBeneficialOwnerUpdateRequestContract', accountsBeneficialOwnerUpdateRequestContract)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/beneficial-owners/{id}`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountsBeneficialOwnerUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns beneficial owners for an account
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBeneficialOwnersIndex: async (accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountBeneficialOwnersIndex', 'accountId', accountId)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/beneficial-owners`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BeneficialOwnerApi - functional programming interface
 * @export
 */
export const BeneficialOwnerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BeneficialOwnerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create the beneficial owner
         * @param {number} accountId 
         * @param {AccountsBeneficialOwnerCreateRequestContract} accountsBeneficialOwnerCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountBeneficialOwnerCreate(accountId: number, accountsBeneficialOwnerCreateRequestContract: AccountsBeneficialOwnerCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsBeneficialOwnerShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountBeneficialOwnerCreate(accountId, accountsBeneficialOwnerCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete the beneficial owner
         * @param {number} accountId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountBeneficialOwnerDestroy(accountId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroySuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountBeneficialOwnerDestroy(accountId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the beneficial owner with tax id
         * @param {number} accountId 
         * @param {number} id 
         * @param {AccountsBeneficialOwnerUpdateRequestContract} accountsBeneficialOwnerUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountBeneficialOwnerUpdateTaxId(accountId: number, id: number, accountsBeneficialOwnerUpdateRequestContract: AccountsBeneficialOwnerUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsBeneficialOwnerShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountBeneficialOwnerUpdateTaxId(accountId, id, accountsBeneficialOwnerUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns beneficial owners for an account
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountBeneficialOwnersIndex(accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsBeneficialOwnerIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountBeneficialOwnersIndex(accountId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BeneficialOwnerApi - factory interface
 * @export
 */
export const BeneficialOwnerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BeneficialOwnerApiFp(configuration)
    return {
        /**
         * 
         * @summary create the beneficial owner
         * @param {number} accountId 
         * @param {AccountsBeneficialOwnerCreateRequestContract} accountsBeneficialOwnerCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBeneficialOwnerCreate(accountId: number, accountsBeneficialOwnerCreateRequestContract: AccountsBeneficialOwnerCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AccountsBeneficialOwnerShow> {
            return localVarFp.accountBeneficialOwnerCreate(accountId, accountsBeneficialOwnerCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete the beneficial owner
         * @param {number} accountId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBeneficialOwnerDestroy(accountId: number, id: number, options?: any): AxiosPromise<DestroySuccess> {
            return localVarFp.accountBeneficialOwnerDestroy(accountId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the beneficial owner with tax id
         * @param {number} accountId 
         * @param {number} id 
         * @param {AccountsBeneficialOwnerUpdateRequestContract} accountsBeneficialOwnerUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBeneficialOwnerUpdateTaxId(accountId: number, id: number, accountsBeneficialOwnerUpdateRequestContract: AccountsBeneficialOwnerUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AccountsBeneficialOwnerShow> {
            return localVarFp.accountBeneficialOwnerUpdateTaxId(accountId, id, accountsBeneficialOwnerUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns beneficial owners for an account
         * @param {number} accountId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountBeneficialOwnersIndex(accountId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AccountsBeneficialOwnerIndex> {
            return localVarFp.accountBeneficialOwnersIndex(accountId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for accountBeneficialOwnerCreate operation in BeneficialOwnerApi.
 * @export
 * @interface BeneficialOwnerApiAccountBeneficialOwnerCreateRequest
 */
export interface BeneficialOwnerApiAccountBeneficialOwnerCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerCreate
     */
    readonly accountId: number

    /**
     * 
     * @type {AccountsBeneficialOwnerCreateRequestContract}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerCreate
     */
    readonly accountsBeneficialOwnerCreateRequestContract: AccountsBeneficialOwnerCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for accountBeneficialOwnerDestroy operation in BeneficialOwnerApi.
 * @export
 * @interface BeneficialOwnerApiAccountBeneficialOwnerDestroyRequest
 */
export interface BeneficialOwnerApiAccountBeneficialOwnerDestroyRequest {
    /**
     * 
     * @type {number}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerDestroy
     */
    readonly accountId: number

    /**
     * 
     * @type {number}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerDestroy
     */
    readonly id: number
}

/**
 * Request parameters for accountBeneficialOwnerUpdateTaxId operation in BeneficialOwnerApi.
 * @export
 * @interface BeneficialOwnerApiAccountBeneficialOwnerUpdateTaxIdRequest
 */
export interface BeneficialOwnerApiAccountBeneficialOwnerUpdateTaxIdRequest {
    /**
     * 
     * @type {number}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerUpdateTaxId
     */
    readonly accountId: number

    /**
     * 
     * @type {number}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerUpdateTaxId
     */
    readonly id: number

    /**
     * 
     * @type {AccountsBeneficialOwnerUpdateRequestContract}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerUpdateTaxId
     */
    readonly accountsBeneficialOwnerUpdateRequestContract: AccountsBeneficialOwnerUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerUpdateTaxId
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerUpdateTaxId
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerUpdateTaxId
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerUpdateTaxId
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerUpdateTaxId
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnerUpdateTaxId
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for accountBeneficialOwnersIndex operation in BeneficialOwnerApi.
 * @export
 * @interface BeneficialOwnerApiAccountBeneficialOwnersIndexRequest
 */
export interface BeneficialOwnerApiAccountBeneficialOwnersIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnersIndex
     */
    readonly accountId: number

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnersIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnersIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnersIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnersIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnersIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnerApiAccountBeneficialOwnersIndex
     */
    readonly xUserRoleId?: string
}

/**
 * BeneficialOwnerApi - object-oriented interface
 * @export
 * @class BeneficialOwnerApi
 * @extends {BaseAPI}
 */
export class BeneficialOwnerApi extends BaseAPI {
    /**
     * 
     * @summary create the beneficial owner
     * @param {BeneficialOwnerApiAccountBeneficialOwnerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeneficialOwnerApi
     */
    public accountBeneficialOwnerCreate(requestParameters: BeneficialOwnerApiAccountBeneficialOwnerCreateRequest, options?: AxiosRequestConfig) {
        return BeneficialOwnerApiFp(this.configuration).accountBeneficialOwnerCreate(requestParameters.accountId, requestParameters.accountsBeneficialOwnerCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete the beneficial owner
     * @param {BeneficialOwnerApiAccountBeneficialOwnerDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeneficialOwnerApi
     */
    public accountBeneficialOwnerDestroy(requestParameters: BeneficialOwnerApiAccountBeneficialOwnerDestroyRequest, options?: AxiosRequestConfig) {
        return BeneficialOwnerApiFp(this.configuration).accountBeneficialOwnerDestroy(requestParameters.accountId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the beneficial owner with tax id
     * @param {BeneficialOwnerApiAccountBeneficialOwnerUpdateTaxIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeneficialOwnerApi
     */
    public accountBeneficialOwnerUpdateTaxId(requestParameters: BeneficialOwnerApiAccountBeneficialOwnerUpdateTaxIdRequest, options?: AxiosRequestConfig) {
        return BeneficialOwnerApiFp(this.configuration).accountBeneficialOwnerUpdateTaxId(requestParameters.accountId, requestParameters.id, requestParameters.accountsBeneficialOwnerUpdateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns beneficial owners for an account
     * @param {BeneficialOwnerApiAccountBeneficialOwnersIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeneficialOwnerApi
     */
    public accountBeneficialOwnersIndex(requestParameters: BeneficialOwnerApiAccountBeneficialOwnersIndexRequest, options?: AxiosRequestConfig) {
        return BeneficialOwnerApiFp(this.configuration).accountBeneficialOwnersIndex(requestParameters.accountId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AccountBeneficialOwnerCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountBeneficialOwnerCreateErrorCodes = [
    401,
];

export type AccountBeneficialOwnerDestroyErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountBeneficialOwnerDestroyErrorCodes = [
    401,
];

export type AccountBeneficialOwnerUpdateTaxIdErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountBeneficialOwnerUpdateTaxIdErrorCodes = [
    401,
];

export type AccountBeneficialOwnersIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountBeneficialOwnersIndexErrorCodes = [
    401,
    404,
];


