import { UnlockPremiumContextProvider } from '../../../auth/user/components';

import { SourceDocumentsBasicInner } from './SourceDocumentsBasicInner';

export type SourceDocumentsBasicProps = {
  companyName: string;
};

export const SourceDocumentsBasic = ({
  companyName,
}: SourceDocumentsBasicProps) => {
  return (
    <UnlockPremiumContextProvider companyName={companyName}>
      <SourceDocumentsBasicInner />
    </UnlockPremiumContextProvider>
  );
};
