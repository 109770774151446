export const EDITABLE_ONBOARDING_STEPS = [
  'personal_information_state',
  'entity_information_state',
  'authorized_signer_state',
  'bank_account_state',
  'buyer_survey_state',
  'spouse_information_state',
];

export const EXIT_ROUTE_KEY = 'exitRoute';
