import styled from 'styled-components';

import { Divider } from '../../atoms/Divider';

export const CheckWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.success.typography.primary};
`;

export const TableHeaderCellDivider = styled(Divider)`
  height: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const TableCellDivider = styled(Divider)`
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
