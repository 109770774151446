/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminActivateIssuerMapRequestContract } from '../model';
// @ts-ignore
import { AdminActivePartnerIndex } from '../model';
// @ts-ignore
import { AdminCapTableVersionIndex } from '../model';
// @ts-ignore
import { AdminIssuerOnboardingTracker } from '../model';
// @ts-ignore
import { AdminPartnerAdminIndex } from '../model';
// @ts-ignore
import { AdminPartnerAdminShow } from '../model';
// @ts-ignore
import { AdminPartnerAdminUpdateRequestContract } from '../model';
// @ts-ignore
import { Job } from '../model';
// @ts-ignore
import { PartnerTransferIndex } from '../model';
/**
 * AdminPartnerApi - axios parameter creator
 * @export
 */
export const AdminPartnerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary activate and assign issuer for partner
         * @param {AdminActivateIssuerMapRequestContract} adminActivateIssuerMapRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAndAssign: async (adminActivateIssuerMapRequestContract: AdminActivateIssuerMapRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminActivateIssuerMapRequestContract' is not null or undefined
            assertParamExists('activateAndAssign', 'adminActivateIssuerMapRequestContract', adminActivateIssuerMapRequestContract)
            const localVarPath = changeUrl(`/api/admin/partner/activate_and_assign`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminActivateIssuerMapRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list an entity
         * @param {'PartnerActivity' | 'PartnerIssuerMap' | 'IssuerOnboardingTracker'} entity 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [status] 
         * @param {string} [partnerIssuerId] 
         * @param {string} [partnerName] 
         * @param {string} [email] 
         * @param {string} [externalId] 
         * @param {number} [issuerId] 
         * @param {number} [userId] 
         * @param {boolean} [active] 
         * @param {string} [associationQuery] Allows DSL-like querying based on models associated with the specified entity.
         * @param {'created_at' | 'updated_at'} [sortBy] defaults to updated_at
         * @param {'asc' | 'desc'} [sortDirection] defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityIndex: async (entity: 'PartnerActivity' | 'PartnerIssuerMap' | 'IssuerOnboardingTracker', page?: number, size?: number, status?: string, partnerIssuerId?: string, partnerName?: string, email?: string, externalId?: string, issuerId?: number, userId?: number, active?: boolean, associationQuery?: string, sortBy?: 'created_at' | 'updated_at', sortDirection?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('entityIndex', 'entity', entity)
            const localVarPath = changeUrl(`/api/admin/partner/{entity}`)
                .replace(`{${"entity"}}`, encodeURIComponent(String(entity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (partnerIssuerId !== undefined) {
                localVarQueryParameter['partner_issuer_id'] = partnerIssuerId;
            }

            if (partnerName !== undefined) {
                localVarQueryParameter['partner_name'] = partnerName;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (externalId !== undefined) {
                localVarQueryParameter['external_id'] = externalId;
            }

            if (issuerId !== undefined) {
                localVarQueryParameter['issuer_id'] = issuerId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (associationQuery !== undefined) {
                localVarQueryParameter['association_query'] = associationQuery;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sort_direction'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update an entity
         * @param {'PartnerIssuerMap'} entity 
         * @param {number} id 
         * @param {AdminPartnerAdminUpdateRequestContract} adminPartnerAdminUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityUpdate: async (entity: 'PartnerIssuerMap', id: number, adminPartnerAdminUpdateRequestContract: AdminPartnerAdminUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('entityUpdate', 'entity', entity)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('entityUpdate', 'id', id)
            // verify required parameter 'adminPartnerAdminUpdateRequestContract' is not null or undefined
            assertParamExists('entityUpdate', 'adminPartnerAdminUpdateRequestContract', adminPartnerAdminUpdateRequestContract)
            const localVarPath = changeUrl(`/api/admin/partner/{entity}/{id}`)
                .replace(`{${"entity"}}`, encodeURIComponent(String(entity)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminPartnerAdminUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary start job to export cap table in CSV format
         * @param {string} issuerId 
         * @param {number} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCsvCapTableVersion: async (issuerId: string, versionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('exportCsvCapTableVersion', 'issuerId', issuerId)
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('exportCsvCapTableVersion', 'versionId', versionId)
            const localVarPath = changeUrl(`/api/admin/partner/issuers/{issuer_id}/cap_table_versions/{version_id}/export_csv`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)))
                .replace(`{${"version_id"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list active partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivePartners: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/admin/partner/active_partners`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list cap table versions
         * @param {string} issuerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'PUBLIC' | 'NPMS_PORTFOLIO' | 'VERIFIED_TRANSFERS' | 'PRIVATE'} [capTableType] 
         * @param {'created_at' | 'updated_at'} [sortBy] defaults to updated_at
         * @param {'asc' | 'desc'} [sortDirection] defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCapTableVersions: async (issuerId: string, page?: number, size?: number, capTableType?: 'PUBLIC' | 'NPMS_PORTFOLIO' | 'VERIFIED_TRANSFERS' | 'PRIVATE', sortBy?: 'created_at' | 'updated_at', sortDirection?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('listCapTableVersions', 'issuerId', issuerId)
            const localVarPath = changeUrl(`/api/admin/partner/issuers/{issuer_id}/cap_table_versions`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (capTableType !== undefined) {
                localVarQueryParameter['cap_table_type'] = capTableType;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sort_direction'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list verified trade transactions
         * @param {string} issuerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVerifiedTrades: async (issuerId: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('listVerifiedTrades', 'issuerId', issuerId)
            const localVarPath = changeUrl(`/api/admin/partner/issuers/{issuer_id}/verified_trades`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloads s3 file content
         * @param {number} s3FileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s3FileDownload: async (s3FileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 's3FileId' is not null or undefined
            assertParamExists('s3FileDownload', 's3FileId', s3FileId)
            const localVarPath = changeUrl(`/api/admin/partner/s3_file/{s3_file_id}/download`)
                .replace(`{${"s3_file_id"}}`, encodeURIComponent(String(s3FileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPartnerApi - functional programming interface
 * @export
 */
export const AdminPartnerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPartnerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary activate and assign issuer for partner
         * @param {AdminActivateIssuerMapRequestContract} adminActivateIssuerMapRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateAndAssign(adminActivateIssuerMapRequestContract: AdminActivateIssuerMapRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminIssuerOnboardingTracker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateAndAssign(adminActivateIssuerMapRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list an entity
         * @param {'PartnerActivity' | 'PartnerIssuerMap' | 'IssuerOnboardingTracker'} entity 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [status] 
         * @param {string} [partnerIssuerId] 
         * @param {string} [partnerName] 
         * @param {string} [email] 
         * @param {string} [externalId] 
         * @param {number} [issuerId] 
         * @param {number} [userId] 
         * @param {boolean} [active] 
         * @param {string} [associationQuery] Allows DSL-like querying based on models associated with the specified entity.
         * @param {'created_at' | 'updated_at'} [sortBy] defaults to updated_at
         * @param {'asc' | 'desc'} [sortDirection] defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityIndex(entity: 'PartnerActivity' | 'PartnerIssuerMap' | 'IssuerOnboardingTracker', page?: number, size?: number, status?: string, partnerIssuerId?: string, partnerName?: string, email?: string, externalId?: string, issuerId?: number, userId?: number, active?: boolean, associationQuery?: string, sortBy?: 'created_at' | 'updated_at', sortDirection?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPartnerAdminIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entityIndex(entity, page, size, status, partnerIssuerId, partnerName, email, externalId, issuerId, userId, active, associationQuery, sortBy, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update an entity
         * @param {'PartnerIssuerMap'} entity 
         * @param {number} id 
         * @param {AdminPartnerAdminUpdateRequestContract} adminPartnerAdminUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityUpdate(entity: 'PartnerIssuerMap', id: number, adminPartnerAdminUpdateRequestContract: AdminPartnerAdminUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPartnerAdminShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entityUpdate(entity, id, adminPartnerAdminUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary start job to export cap table in CSV format
         * @param {string} issuerId 
         * @param {number} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCsvCapTableVersion(issuerId: string, versionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Job>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportCsvCapTableVersion(issuerId, versionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list active partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActivePartners(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminActivePartnerIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActivePartners(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list cap table versions
         * @param {string} issuerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'PUBLIC' | 'NPMS_PORTFOLIO' | 'VERIFIED_TRANSFERS' | 'PRIVATE'} [capTableType] 
         * @param {'created_at' | 'updated_at'} [sortBy] defaults to updated_at
         * @param {'asc' | 'desc'} [sortDirection] defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCapTableVersions(issuerId: string, page?: number, size?: number, capTableType?: 'PUBLIC' | 'NPMS_PORTFOLIO' | 'VERIFIED_TRANSFERS' | 'PRIVATE', sortBy?: 'created_at' | 'updated_at', sortDirection?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCapTableVersionIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCapTableVersions(issuerId, page, size, capTableType, sortBy, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list verified trade transactions
         * @param {string} issuerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVerifiedTrades(issuerId: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerTransferIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVerifiedTrades(issuerId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary downloads s3 file content
         * @param {number} s3FileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async s3FileDownload(s3FileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.s3FileDownload(s3FileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPartnerApi - factory interface
 * @export
 */
export const AdminPartnerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPartnerApiFp(configuration)
    return {
        /**
         * 
         * @summary activate and assign issuer for partner
         * @param {AdminActivateIssuerMapRequestContract} adminActivateIssuerMapRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAndAssign(adminActivateIssuerMapRequestContract: AdminActivateIssuerMapRequestContract, options?: any): AxiosPromise<AdminIssuerOnboardingTracker> {
            return localVarFp.activateAndAssign(adminActivateIssuerMapRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list an entity
         * @param {'PartnerActivity' | 'PartnerIssuerMap' | 'IssuerOnboardingTracker'} entity 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [status] 
         * @param {string} [partnerIssuerId] 
         * @param {string} [partnerName] 
         * @param {string} [email] 
         * @param {string} [externalId] 
         * @param {number} [issuerId] 
         * @param {number} [userId] 
         * @param {boolean} [active] 
         * @param {string} [associationQuery] Allows DSL-like querying based on models associated with the specified entity.
         * @param {'created_at' | 'updated_at'} [sortBy] defaults to updated_at
         * @param {'asc' | 'desc'} [sortDirection] defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityIndex(entity: 'PartnerActivity' | 'PartnerIssuerMap' | 'IssuerOnboardingTracker', page?: number, size?: number, status?: string, partnerIssuerId?: string, partnerName?: string, email?: string, externalId?: string, issuerId?: number, userId?: number, active?: boolean, associationQuery?: string, sortBy?: 'created_at' | 'updated_at', sortDirection?: 'asc' | 'desc', options?: any): AxiosPromise<AdminPartnerAdminIndex> {
            return localVarFp.entityIndex(entity, page, size, status, partnerIssuerId, partnerName, email, externalId, issuerId, userId, active, associationQuery, sortBy, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update an entity
         * @param {'PartnerIssuerMap'} entity 
         * @param {number} id 
         * @param {AdminPartnerAdminUpdateRequestContract} adminPartnerAdminUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityUpdate(entity: 'PartnerIssuerMap', id: number, adminPartnerAdminUpdateRequestContract: AdminPartnerAdminUpdateRequestContract, options?: any): AxiosPromise<AdminPartnerAdminShow> {
            return localVarFp.entityUpdate(entity, id, adminPartnerAdminUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary start job to export cap table in CSV format
         * @param {string} issuerId 
         * @param {number} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCsvCapTableVersion(issuerId: string, versionId: number, options?: any): AxiosPromise<Job> {
            return localVarFp.exportCsvCapTableVersion(issuerId, versionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list active partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivePartners(options?: any): AxiosPromise<AdminActivePartnerIndex> {
            return localVarFp.listActivePartners(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list cap table versions
         * @param {string} issuerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'PUBLIC' | 'NPMS_PORTFOLIO' | 'VERIFIED_TRANSFERS' | 'PRIVATE'} [capTableType] 
         * @param {'created_at' | 'updated_at'} [sortBy] defaults to updated_at
         * @param {'asc' | 'desc'} [sortDirection] defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCapTableVersions(issuerId: string, page?: number, size?: number, capTableType?: 'PUBLIC' | 'NPMS_PORTFOLIO' | 'VERIFIED_TRANSFERS' | 'PRIVATE', sortBy?: 'created_at' | 'updated_at', sortDirection?: 'asc' | 'desc', options?: any): AxiosPromise<AdminCapTableVersionIndex> {
            return localVarFp.listCapTableVersions(issuerId, page, size, capTableType, sortBy, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list verified trade transactions
         * @param {string} issuerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVerifiedTrades(issuerId: string, page?: number, size?: number, options?: any): AxiosPromise<PartnerTransferIndex> {
            return localVarFp.listVerifiedTrades(issuerId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary downloads s3 file content
         * @param {number} s3FileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s3FileDownload(s3FileId: number, options?: any): AxiosPromise<File> {
            return localVarFp.s3FileDownload(s3FileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activateAndAssign operation in AdminPartnerApi.
 * @export
 * @interface AdminPartnerApiActivateAndAssignRequest
 */
export interface AdminPartnerApiActivateAndAssignRequest {
    /**
     * 
     * @type {AdminActivateIssuerMapRequestContract}
     * @memberof AdminPartnerApiActivateAndAssign
     */
    readonly adminActivateIssuerMapRequestContract: AdminActivateIssuerMapRequestContract
}

/**
 * Request parameters for entityIndex operation in AdminPartnerApi.
 * @export
 * @interface AdminPartnerApiEntityIndexRequest
 */
export interface AdminPartnerApiEntityIndexRequest {
    /**
     * 
     * @type {'PartnerActivity' | 'PartnerIssuerMap' | 'IssuerOnboardingTracker'}
     * @memberof AdminPartnerApiEntityIndex
     */
    readonly entity: 'PartnerActivity' | 'PartnerIssuerMap' | 'IssuerOnboardingTracker'

    /**
     * 
     * @type {number}
     * @memberof AdminPartnerApiEntityIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminPartnerApiEntityIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof AdminPartnerApiEntityIndex
     */
    readonly status?: string

    /**
     * 
     * @type {string}
     * @memberof AdminPartnerApiEntityIndex
     */
    readonly partnerIssuerId?: string

    /**
     * 
     * @type {string}
     * @memberof AdminPartnerApiEntityIndex
     */
    readonly partnerName?: string

    /**
     * 
     * @type {string}
     * @memberof AdminPartnerApiEntityIndex
     */
    readonly email?: string

    /**
     * 
     * @type {string}
     * @memberof AdminPartnerApiEntityIndex
     */
    readonly externalId?: string

    /**
     * 
     * @type {number}
     * @memberof AdminPartnerApiEntityIndex
     */
    readonly issuerId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminPartnerApiEntityIndex
     */
    readonly userId?: number

    /**
     * 
     * @type {boolean}
     * @memberof AdminPartnerApiEntityIndex
     */
    readonly active?: boolean

    /**
     * Allows DSL-like querying based on models associated with the specified entity.
     * @type {string}
     * @memberof AdminPartnerApiEntityIndex
     */
    readonly associationQuery?: string

    /**
     * defaults to updated_at
     * @type {'created_at' | 'updated_at'}
     * @memberof AdminPartnerApiEntityIndex
     */
    readonly sortBy?: 'created_at' | 'updated_at'

    /**
     * defaults to desc
     * @type {'asc' | 'desc'}
     * @memberof AdminPartnerApiEntityIndex
     */
    readonly sortDirection?: 'asc' | 'desc'
}

/**
 * Request parameters for entityUpdate operation in AdminPartnerApi.
 * @export
 * @interface AdminPartnerApiEntityUpdateRequest
 */
export interface AdminPartnerApiEntityUpdateRequest {
    /**
     * 
     * @type {'PartnerIssuerMap'}
     * @memberof AdminPartnerApiEntityUpdate
     */
    readonly entity: 'PartnerIssuerMap'

    /**
     * 
     * @type {number}
     * @memberof AdminPartnerApiEntityUpdate
     */
    readonly id: number

    /**
     * 
     * @type {AdminPartnerAdminUpdateRequestContract}
     * @memberof AdminPartnerApiEntityUpdate
     */
    readonly adminPartnerAdminUpdateRequestContract: AdminPartnerAdminUpdateRequestContract
}

/**
 * Request parameters for exportCsvCapTableVersion operation in AdminPartnerApi.
 * @export
 * @interface AdminPartnerApiExportCsvCapTableVersionRequest
 */
export interface AdminPartnerApiExportCsvCapTableVersionRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminPartnerApiExportCsvCapTableVersion
     */
    readonly issuerId: string

    /**
     * 
     * @type {number}
     * @memberof AdminPartnerApiExportCsvCapTableVersion
     */
    readonly versionId: number
}

/**
 * Request parameters for listCapTableVersions operation in AdminPartnerApi.
 * @export
 * @interface AdminPartnerApiListCapTableVersionsRequest
 */
export interface AdminPartnerApiListCapTableVersionsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminPartnerApiListCapTableVersions
     */
    readonly issuerId: string

    /**
     * 
     * @type {number}
     * @memberof AdminPartnerApiListCapTableVersions
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminPartnerApiListCapTableVersions
     */
    readonly size?: number

    /**
     * 
     * @type {'PUBLIC' | 'NPMS_PORTFOLIO' | 'VERIFIED_TRANSFERS' | 'PRIVATE'}
     * @memberof AdminPartnerApiListCapTableVersions
     */
    readonly capTableType?: 'PUBLIC' | 'NPMS_PORTFOLIO' | 'VERIFIED_TRANSFERS' | 'PRIVATE'

    /**
     * defaults to updated_at
     * @type {'created_at' | 'updated_at'}
     * @memberof AdminPartnerApiListCapTableVersions
     */
    readonly sortBy?: 'created_at' | 'updated_at'

    /**
     * defaults to desc
     * @type {'asc' | 'desc'}
     * @memberof AdminPartnerApiListCapTableVersions
     */
    readonly sortDirection?: 'asc' | 'desc'
}

/**
 * Request parameters for listVerifiedTrades operation in AdminPartnerApi.
 * @export
 * @interface AdminPartnerApiListVerifiedTradesRequest
 */
export interface AdminPartnerApiListVerifiedTradesRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminPartnerApiListVerifiedTrades
     */
    readonly issuerId: string

    /**
     * 
     * @type {number}
     * @memberof AdminPartnerApiListVerifiedTrades
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminPartnerApiListVerifiedTrades
     */
    readonly size?: number
}

/**
 * Request parameters for s3FileDownload operation in AdminPartnerApi.
 * @export
 * @interface AdminPartnerApiS3FileDownloadRequest
 */
export interface AdminPartnerApiS3FileDownloadRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminPartnerApiS3FileDownload
     */
    readonly s3FileId: number
}

/**
 * AdminPartnerApi - object-oriented interface
 * @export
 * @class AdminPartnerApi
 * @extends {BaseAPI}
 */
export class AdminPartnerApi extends BaseAPI {
    /**
     * 
     * @summary activate and assign issuer for partner
     * @param {AdminPartnerApiActivateAndAssignRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPartnerApi
     */
    public activateAndAssign(requestParameters: AdminPartnerApiActivateAndAssignRequest, options?: AxiosRequestConfig) {
        return AdminPartnerApiFp(this.configuration).activateAndAssign(requestParameters.adminActivateIssuerMapRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list an entity
     * @param {AdminPartnerApiEntityIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPartnerApi
     */
    public entityIndex(requestParameters: AdminPartnerApiEntityIndexRequest, options?: AxiosRequestConfig) {
        return AdminPartnerApiFp(this.configuration).entityIndex(requestParameters.entity, requestParameters.page, requestParameters.size, requestParameters.status, requestParameters.partnerIssuerId, requestParameters.partnerName, requestParameters.email, requestParameters.externalId, requestParameters.issuerId, requestParameters.userId, requestParameters.active, requestParameters.associationQuery, requestParameters.sortBy, requestParameters.sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update an entity
     * @param {AdminPartnerApiEntityUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPartnerApi
     */
    public entityUpdate(requestParameters: AdminPartnerApiEntityUpdateRequest, options?: AxiosRequestConfig) {
        return AdminPartnerApiFp(this.configuration).entityUpdate(requestParameters.entity, requestParameters.id, requestParameters.adminPartnerAdminUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary start job to export cap table in CSV format
     * @param {AdminPartnerApiExportCsvCapTableVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPartnerApi
     */
    public exportCsvCapTableVersion(requestParameters: AdminPartnerApiExportCsvCapTableVersionRequest, options?: AxiosRequestConfig) {
        return AdminPartnerApiFp(this.configuration).exportCsvCapTableVersion(requestParameters.issuerId, requestParameters.versionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list active partners
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPartnerApi
     */
    public listActivePartners(options?: AxiosRequestConfig) {
        return AdminPartnerApiFp(this.configuration).listActivePartners(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list cap table versions
     * @param {AdminPartnerApiListCapTableVersionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPartnerApi
     */
    public listCapTableVersions(requestParameters: AdminPartnerApiListCapTableVersionsRequest, options?: AxiosRequestConfig) {
        return AdminPartnerApiFp(this.configuration).listCapTableVersions(requestParameters.issuerId, requestParameters.page, requestParameters.size, requestParameters.capTableType, requestParameters.sortBy, requestParameters.sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list verified trade transactions
     * @param {AdminPartnerApiListVerifiedTradesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPartnerApi
     */
    public listVerifiedTrades(requestParameters: AdminPartnerApiListVerifiedTradesRequest, options?: AxiosRequestConfig) {
        return AdminPartnerApiFp(this.configuration).listVerifiedTrades(requestParameters.issuerId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary downloads s3 file content
     * @param {AdminPartnerApiS3FileDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPartnerApi
     */
    public s3FileDownload(requestParameters: AdminPartnerApiS3FileDownloadRequest, options?: AxiosRequestConfig) {
        return AdminPartnerApiFp(this.configuration).s3FileDownload(requestParameters.s3FileId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type ActivateAndAssignErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const ActivateAndAssignErrorCodes = [
    404,
];

export type EntityIndexErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EntityIndexErrorCodes = [
    422,
];

export type EntityUpdateErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EntityUpdateErrorCodes = [
    404,
];

export type ExportCsvCapTableVersionErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const ExportCsvCapTableVersionErrorCodes = [
    404,
];

export type ListActivePartnersErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const ListActivePartnersErrorCodes = [
];

export type ListCapTableVersionsErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const ListCapTableVersionsErrorCodes = [
    404,
];

export type ListVerifiedTradesErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const ListVerifiedTradesErrorCodes = [
    404,
];

export type S3FileDownloadErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const S3FileDownloadErrorCodes = [
    404,
];


