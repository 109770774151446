import { useState } from 'react';
import create from 'zustand';

import { type ActiveDrawerStore } from './Drawer.types';

/* this hook can be used for any case when only 1 drawer should be open at a time,
to use, add new drawer identifier inside Drawer.types.ts */
export const useActiveDrawerStore = create<ActiveDrawerStore>((set, get) => ({
  activeDrawerKey: undefined,
  openDrawer: drawerKey => set({ activeDrawerKey: drawerKey }),
  closeDrawer: () => set({ activeDrawerKey: undefined }),
  toggleDrawer: drawerKey =>
    set({
      activeDrawerKey:
        get().activeDrawerKey === drawerKey ? undefined : drawerKey,
    }),
  isOpen: drawerKey => get().activeDrawerKey === drawerKey,
}));

export const useDrawer = () => {
  const [open, setOpen] = useState(false);

  return {
    openDrawer: () => setOpen(true),
    closeDrawer: () => setOpen(false),
    toggleDrawer: () => setOpen(v => !v),
    isOpen: open,
  };
};
