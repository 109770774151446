import { Text } from '@npm/core/ui/components/atoms/Typography';

type Props = {
  isSponsoredInvestor?: boolean;
};

export const getVisibilityTooltip = ({ isSponsoredInvestor }: Props) => (
  <>
    <Text size="sm" weight="bold" colorVariant="muted">
      Internal
    </Text>
    <Text size="sm" colorVariant="muted" marginBottom="md">
      Inventory that is visible to you and your{' '}
      {isSponsoredInvestor
        ? 'broker.'
        : 'direct client. Date shown is Order Received At.'}
    </Text>
    <Text size="sm" weight="bold" colorVariant="muted">
      ATS-SM
    </Text>
    <Text size="sm" colorVariant="muted" marginBottom="md">
      Inventory that is shared with brokers and sponsored investors. Date shown
      is Order Published At.
    </Text>
    <Text size="sm" weight="bold" colorVariant="muted">
      ATS-I
    </Text>
    <Text size="sm" colorVariant="muted">
      Inventory that is shared with sponsored investors only. Date shown is
      Order Published At.
    </Text>
  </>
);

export const orderTypeTooltip = (
  <>
    <Text size="sm" weight="bold" colorVariant="muted">
      Invite Only
    </Text>
    <Text size="sm" colorVariant="muted" marginBottom="md">
      Reach out to access more information and optional data room
    </Text>
    <Text size="sm" weight="bold" colorVariant="muted">
      IOI
    </Text>
    <Text size="sm" colorVariant="muted" marginBottom="md">
      Indication of interest in our marketplace. You can respond directly on the
      platform.
    </Text>
    <Text size="sm" weight="bold" colorVariant="muted">
      Firm
    </Text>
    <Text size="sm" colorVariant="muted">
      Actionable order that is exclusive to our marketplace. You can respond
      directly on the platform.
    </Text>
  </>
);

export const affiliateDisclosureToolTipText = (
  <>
    <Text colorVariant="primary" as="span" weight="bold" size="sm">
      Company Insider{' '}
    </Text>
    <Text colorVariant="tertiary" as={'span'} size="sm">
      is any officer or director of the Company or shareholder with more than
      10% ownership of the Company and{' '}
    </Text>
    <Text colorVariant="primary" as="span" weight="bold" size="sm">
      Affiliate{' '}
    </Text>
    <Text colorVariant="tertiary" as={'span'} size="sm">
      is a person that has the power to direct the management and policies of
      the Company, whether through the ownership of voting securities, by
      contract, or otherwise.
    </Text>
  </>
);
