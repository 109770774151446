import React from 'react';

import { Switch } from '@npm/core/ui/components/atoms/Switch';
import { CbUserStatus } from '@npm/data-access';

import { type FilterItemProps } from '../../../../../../../filters/filters/FilterPanel';

type Props = FilterItemProps & {
  value?: string[] | string | null;
  onChange?: (value: string[]) => void;
};

export const ActiveCompanyFilter = ({
  value,
  onChange,
  onFilterSubmit,
  name,
  ...rest
}: Props) => {
  return (
    <Switch
      checked={value?.length && value.includes(CbUserStatus.items.active)}
      titleAfter
      title="Active accounts only"
      onChange={checked => {
        onChange(checked ? [CbUserStatus.items.active] : undefined);
        onFilterSubmit?.(name);
      }}
      {...rest}
    />
  );
};
