/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalCompaniesIndustryIndex } from '../model';
/**
 * IndustriesApi - axios parameter creator
 * @export
 */
export const IndustriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list company industries
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyIndustries: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyIndustries', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/industries`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndustriesApi - functional programming interface
 * @export
 */
export const IndustriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IndustriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary list company industries
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyIndustries(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCompaniesIndustryIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyIndustries(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IndustriesApi - factory interface
 * @export
 */
export const IndustriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IndustriesApiFp(configuration)
    return {
        /**
         * 
         * @summary list company industries
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyIndustries(companyId: string, options?: any): AxiosPromise<InternalCompaniesIndustryIndex> {
            return localVarFp.companyIndustries(companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for companyIndustries operation in IndustriesApi.
 * @export
 * @interface IndustriesApiCompanyIndustriesRequest
 */
export interface IndustriesApiCompanyIndustriesRequest {
    /**
     * 
     * @type {string}
     * @memberof IndustriesApiCompanyIndustries
     */
    readonly companyId: string
}

/**
 * IndustriesApi - object-oriented interface
 * @export
 * @class IndustriesApi
 * @extends {BaseAPI}
 */
export class IndustriesApi extends BaseAPI {
    /**
     * 
     * @summary list company industries
     * @param {IndustriesApiCompanyIndustriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndustriesApi
     */
    public companyIndustries(requestParameters: IndustriesApiCompanyIndustriesRequest, options?: AxiosRequestConfig) {
        return IndustriesApiFp(this.configuration).companyIndustries(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type CompanyIndustriesErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompanyIndustriesErrorCodes = [
    401,
];


