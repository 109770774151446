import React, { type ComponentProps } from 'react';

import { Select } from '@npm/core/ui/components/atoms/Select';

import { type FilterItemProps } from '../FilterPanel';

type Props = FilterItemProps &
  Omit<ComponentProps<typeof Select>, 'options' | 'value' | 'onChange'> & {
    value?: boolean;
    onChange?: (value: boolean | undefined) => void;
  };

export const BooleanSelectFilter = ({
  value,
  onChange,
  name,
  onFilterSubmit,
  ...rest
}: Props) => {
  return (
    <Select
      options={[
        { value: 1, label: 'Yes' },
        { value: 0, label: 'No' },
      ]}
      value={value == null ? null : value ? 1 : 0}
      placeholder="Yes/No"
      onChange={value => {
        if (value === 1) {
          onChange?.(true);
        } else if (value === 0) {
          onChange?.(false);
        } else {
          onChange?.(undefined);
        }
      }}
      onSelect={() => {
        onFilterSubmit?.(name);
      }}
      onClear={() => onFilterSubmit?.(name)}
      allowClear
      {...rest}
    />
  );
};
