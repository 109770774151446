import React from 'react';

import { ReadOnly } from '@npm/core/ui/components/molecules/ReadOnly';

import { prepareReadonlyData } from './TaxInformationReadOnly.utils';

type Props = {
  taxDocumentName: string;
};

export const TaxInformationReadOnly = ({ taxDocumentName }: Props) => {
  return <ReadOnly items={prepareReadonlyData(taxDocumentName)} />;
};
