import { type DefaultTheme } from 'styled-components';
import create from 'zustand';

interface PageHeaderStore {
  background: keyof DefaultTheme['color']['general']['layout'] | null;
  padding: keyof DefaultTheme['spacing'] | null;
  showDefault: boolean;

  setShowDefault(val: boolean);

  setBackground: (bg: keyof DefaultTheme['color']['general']['layout']) => void;
  resetBackground: () => void;
  setPadding: (bg: keyof DefaultTheme['spacing']) => void;
  resetPadding: () => void;
}

export const usePageHeaderStore = create<PageHeaderStore>(set => ({
  background: 'zero',
  padding: null,
  showDefault: true,
  setShowDefault: value => set({ showDefault: value }),
  setBackground: bg => set({ background: bg }),
  resetBackground: () => set({ background: 'zero' }),
  setPadding: padding => set({ padding: padding }),
  resetPadding: () => set({ padding: null }),
}));
