import { Codebooks } from './codebooks.types';

const Items = {
  assign: 'assign',
  block: 'block',
  waive: 'waive',
} as const;

export const CbRofrActionType = {
  code: Codebooks.ROFR_ACTION_TYPE,
  items: Items,
};
