import styled from 'styled-components';

import { useBreakpoint } from '../../../utils/style';

export const Layout = styled.div<{ $dense?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ $dense }) => ($dense ? 'flex-start' : 'center')};
`;

export const Header = styled.div<{ $dense?: boolean }>`
  display: flex;
  align-items: ${({ $dense }) => ($dense ? 'center' : 'start')};
  flex-direction: ${({ $dense }) => ($dense ? 'row' : 'column')};
  gap: ${({ theme, $dense }) =>
    $dense ? theme.spacing.sm : theme.spacing.md}px;
`;

export const Content = styled.div<{ $dense?: boolean }>`
  max-width: 460px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme, $dense }) =>
    $dense ? theme.spacing.sm : theme.spacing.md}px;

  padding: ${({ theme, $dense }) =>
    $dense
      ? `${theme.spacing.sm}px ${theme.spacing.xs}px`
      : `${theme.spacing.lg * 2}px ${theme.spacing.md}px`};

  align-items: flex-start;
  ${({ theme }) => useBreakpoint(theme).mobile`
    align-items: stretch;
  `}

  > svg {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }

  && > .ant-btn {
    margin-top: ${({ theme }) => theme.spacing.sm}px;
  }
`;
