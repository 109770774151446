import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type investorWorkstationRouteKeys } from '@npm/core/ui/routes';
import { CbEventStatus, useEventList } from '@npm/data-access';

import { useUserContextStore } from '../../auth/user/context';

import { IconBox } from './IconBox';

type Props = {
  modalProps: React.ComponentProps<typeof Modal>;
  accountId?: number;
};

const getFooterButtonsProps = ({
  closeModal,
  handleRedirect,
  isEntityNpmsInvestor,
  hasLivePrograms,
  isLoading,
  isMobile,
}: {
  closeModal: () => void;
  handleRedirect: (to: investorWorkstationRouteKeys) => void;
  isEntityNpmsInvestor: boolean;
  hasLivePrograms: boolean;
  isLoading: boolean;
  isMobile: boolean;
}): Partial<Props['modalProps']> => {
  if (isLoading) {
    return {
      footer: (
        <Skeleton.Button
          block={isMobile}
          style={{ width: isMobile ? '100%' : '160px' }}
        />
      ),
    };
  }

  const okButtonProps = {
    icon: <Icon name="external-link" size="xs" />,
  };

  const cancelButtonProps = {
    cancelText: 'Review Onboarding',
    onCancel: closeModal,
  };

  if (isEntityNpmsInvestor) {
    return {
      okText: 'Go to Dashboard',
      onOk: () => handleRedirect('/investor-workstation/entity-dashboard'),
      okButtonProps,
      ...cancelButtonProps,
    };
  }

  if (hasLivePrograms) {
    return {
      okText: 'Continue to Live Programs',
      onOk: () =>
        handleRedirect('/investor-workstation/programs/live-programs'),
      okButtonProps,
      ...cancelButtonProps,
    };
  }

  return {
    okText: 'Go Home',
    onOk: () => handleRedirect('/investor-workstation'),
    okButtonProps,
    ...cancelButtonProps,
  };
};

export const OnboardingCompletedModal = ({ accountId, modalProps }: Props) => {
  const { isMobile } = useBreakpoints();
  const history = useHistory();
  const isEntityNpmsInvestor = useUserContextStore(s => s.isEntityNpmsInvestor);

  const { data, isFetching } = useEventList(
    { accountId, eventStatus: CbEventStatus.items.ACTIVE, size: 0 },
    {
      queryConfig: {
        enabled: !!accountId && modalProps.open && !isEntityNpmsInvestor,
      },
    }
  );

  const hasLivePrograms = data?.pagination?.total_records > 0;

  const handleRedirect = (to: investorWorkstationRouteKeys) => {
    history.push(to);
  };

  return (
    <Modal
      {...modalProps}
      {...getFooterButtonsProps({
        closeModal: () => modalProps.onCancel(null),
        handleRedirect,
        isEntityNpmsInvestor,
        hasLivePrograms,
        isLoading: isFetching,
        isMobile,
      })}
    >
      <Flex>
        <IconBox />
        <div>
          <Heading variant="h2" marginBottom="sm">
            Congratulations!
          </Heading>
          <Text size="sm">
            You have successfully completed all Onboarding steps.{' '}
            {isEntityNpmsInvestor
              ? 'Click on "Go to Dashboard" to import your portfolio and/or add companies to your watchlist.'
              : hasLivePrograms
              ? 'Click on "Continue to Live Programs" to participate in an event.'
              : ''}
          </Text>
        </div>
      </Flex>
    </Modal>
  );
};
