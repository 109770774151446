/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  MarksApi,
  InvestorMarkIndexErrorCodes,
  InvestorMarkIndexErrorTypes,
  InvestorMarkCreateErrorCodes,
  InvestorMarkShowErrorCodes,
  InvestorMarkShowErrorTypes,
  InvestorMarkUpdateErrorCodes,
  InvestorMarkDeleteErrorCodes,
  IssuerMarkIndexErrorCodes,
  IssuerMarkIndexErrorTypes,
  IssuerMarkCreateErrorCodes,
  IssuerMarkShowErrorCodes,
  IssuerMarkShowErrorTypes,
  IssuerMarkUpdateErrorCodes,
  IssuerMarkDeleteErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new MarksApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InvestorMarkIndexMethod = MarksApi['investorMarkIndex'];
export type InvestorMarkIndexResponseType = MethodResult<InvestorMarkIndexMethod>;

export const useInvestorMarkIndex = (
  variables: Parameters<InvestorMarkIndexMethod>[0],
  config?: UseQueryConfig<
    InvestorMarkIndexMethod,
    InvestorMarkIndexErrorTypes
  >
) => {
  return useQuery<
    InvestorMarkIndexMethod,
    InvestorMarkIndexErrorTypes
  >(
    (...args) => api.investorMarkIndex(...args),
    InvestorMarkIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorMarkIndex'],
        ...apiQueryConfig.InvestorMarkIndex,
        ...config?.queryConfig
      },
    },
    'InvestorMarkIndex',
  );
};

export const useInvestorMarkIndexInfinite = (
  variables: Parameters<InvestorMarkIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InvestorMarkIndexMethod,
    InvestorMarkIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InvestorMarkIndexMethod,
    InvestorMarkIndexErrorTypes
  >(
    (...args) => api.investorMarkIndex(...args),
    InvestorMarkIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorMarkIndex', 'InvestorMarkIndexInfinite'],
        ...apiQueryConfig.InvestorMarkIndex,
        ...config?.queryConfig,
      },
    },
    'InvestorMarkIndex',
  );
};

export const useInvestorMarkIndexLazy = (baseOptions?: {
  variables?: Parameters<InvestorMarkIndexMethod>[0],
  config?: UseQueryConfig<
    InvestorMarkIndexMethod,
    InvestorMarkIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InvestorMarkIndexMethod,
    InvestorMarkIndexErrorTypes
  >(
    (...args) => api.investorMarkIndex(...args),
    InvestorMarkIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InvestorMarkIndex'],
        ...apiQueryConfig.InvestorMarkIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InvestorMarkIndex',
  );
};


export type InvestorMarkCreateMethod = MarksApi['investorMarkCreate'];

export const useInvestorMarkCreate = () => {
  return useMutation<InvestorMarkCreateMethod>(
    (...args) => api.investorMarkCreate(...args),
    InvestorMarkCreateErrorCodes,
    'InvestorMarkCreate',
    '/api/investor/accounts/{account_id}/companies/{company_id}/securities/{security_id}/marks',
    'post'
  );
};

export type InvestorMarkShowMethod = MarksApi['investorMarkShow'];
export type InvestorMarkShowResponseType = MethodResult<InvestorMarkShowMethod>;

export const useInvestorMarkShow = (
  variables: Parameters<InvestorMarkShowMethod>[0],
  config?: UseQueryConfig<
    InvestorMarkShowMethod,
    InvestorMarkShowErrorTypes
  >
) => {
  return useQuery<
    InvestorMarkShowMethod,
    InvestorMarkShowErrorTypes
  >(
    (...args) => api.investorMarkShow(...args),
    InvestorMarkShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorMarkShow'],
        ...apiQueryConfig.InvestorMarkShow,
        ...config?.queryConfig
      },
    },
    'InvestorMarkShow',
  );
};

export const useInvestorMarkShowInfinite = (
  variables: Parameters<InvestorMarkShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    InvestorMarkShowMethod,
    InvestorMarkShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    InvestorMarkShowMethod,
    InvestorMarkShowErrorTypes
  >(
    (...args) => api.investorMarkShow(...args),
    InvestorMarkShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorMarkShow', 'InvestorMarkShowInfinite'],
        ...apiQueryConfig.InvestorMarkShow,
        ...config?.queryConfig,
      },
    },
    'InvestorMarkShow',
  );
};

export const useInvestorMarkShowLazy = (baseOptions?: {
  variables?: Parameters<InvestorMarkShowMethod>[0],
  config?: UseQueryConfig<
    InvestorMarkShowMethod,
    InvestorMarkShowErrorTypes
  >
}) => {
  return useLazyQuery<
    InvestorMarkShowMethod,
    InvestorMarkShowErrorTypes
  >(
    (...args) => api.investorMarkShow(...args),
    InvestorMarkShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InvestorMarkShow'],
        ...apiQueryConfig.InvestorMarkShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InvestorMarkShow',
  );
};


export type InvestorMarkUpdateMethod = MarksApi['investorMarkUpdate'];

export const useInvestorMarkUpdate = () => {
  return useMutation<InvestorMarkUpdateMethod>(
    (...args) => api.investorMarkUpdate(...args),
    InvestorMarkUpdateErrorCodes,
    'InvestorMarkUpdate',
    '/api/investor/accounts/{account_id}/companies/{company_id}/securities/{security_id}/marks/{id}',
    'patch'
  );
};

export type InvestorMarkDeleteMethod = MarksApi['investorMarkDelete'];

export const useInvestorMarkDelete = () => {
  return useMutation<InvestorMarkDeleteMethod>(
    (...args) => api.investorMarkDelete(...args),
    InvestorMarkDeleteErrorCodes,
    'InvestorMarkDelete',
    '/api/investor/accounts/{account_id}/companies/{company_id}/securities/{security_id}/marks/{id}',
    'delete'
  );
};

export type IssuerMarkIndexMethod = MarksApi['issuerMarkIndex'];
export type IssuerMarkIndexResponseType = MethodResult<IssuerMarkIndexMethod>;

export const useIssuerMarkIndex = (
  variables: Parameters<IssuerMarkIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerMarkIndexMethod,
    IssuerMarkIndexErrorTypes
  >
) => {
  return useQuery<
    IssuerMarkIndexMethod,
    IssuerMarkIndexErrorTypes
  >(
    (...args) => api.issuerMarkIndex(...args),
    IssuerMarkIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerMarkIndex'],
        ...apiQueryConfig.IssuerMarkIndex,
        ...config?.queryConfig
      },
    },
    'IssuerMarkIndex',
  );
};

export const useIssuerMarkIndexInfinite = (
  variables: Parameters<IssuerMarkIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    IssuerMarkIndexMethod,
    IssuerMarkIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    IssuerMarkIndexMethod,
    IssuerMarkIndexErrorTypes
  >(
    (...args) => api.issuerMarkIndex(...args),
    IssuerMarkIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerMarkIndex', 'IssuerMarkIndexInfinite'],
        ...apiQueryConfig.IssuerMarkIndex,
        ...config?.queryConfig,
      },
    },
    'IssuerMarkIndex',
  );
};

export const useIssuerMarkIndexLazy = (baseOptions?: {
  variables?: Parameters<IssuerMarkIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerMarkIndexMethod,
    IssuerMarkIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    IssuerMarkIndexMethod,
    IssuerMarkIndexErrorTypes
  >(
    (...args) => api.issuerMarkIndex(...args),
    IssuerMarkIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IssuerMarkIndex'],
        ...apiQueryConfig.IssuerMarkIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IssuerMarkIndex',
  );
};


export type IssuerMarkCreateMethod = MarksApi['issuerMarkCreate'];

export const useIssuerMarkCreate = () => {
  return useMutation<IssuerMarkCreateMethod>(
    (...args) => api.issuerMarkCreate(...args),
    IssuerMarkCreateErrorCodes,
    'IssuerMarkCreate',
    '/api/admin/issuers/{issuer_id}/securities/{security_npm_guid}/marks',
    'post'
  );
};

export type IssuerMarkShowMethod = MarksApi['issuerMarkShow'];
export type IssuerMarkShowResponseType = MethodResult<IssuerMarkShowMethod>;

export const useIssuerMarkShow = (
  variables: Parameters<IssuerMarkShowMethod>[0],
  config?: UseQueryConfig<
    IssuerMarkShowMethod,
    IssuerMarkShowErrorTypes
  >
) => {
  return useQuery<
    IssuerMarkShowMethod,
    IssuerMarkShowErrorTypes
  >(
    (...args) => api.issuerMarkShow(...args),
    IssuerMarkShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerMarkShow'],
        ...apiQueryConfig.IssuerMarkShow,
        ...config?.queryConfig
      },
    },
    'IssuerMarkShow',
  );
};

export const useIssuerMarkShowInfinite = (
  variables: Parameters<IssuerMarkShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    IssuerMarkShowMethod,
    IssuerMarkShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    IssuerMarkShowMethod,
    IssuerMarkShowErrorTypes
  >(
    (...args) => api.issuerMarkShow(...args),
    IssuerMarkShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerMarkShow', 'IssuerMarkShowInfinite'],
        ...apiQueryConfig.IssuerMarkShow,
        ...config?.queryConfig,
      },
    },
    'IssuerMarkShow',
  );
};

export const useIssuerMarkShowLazy = (baseOptions?: {
  variables?: Parameters<IssuerMarkShowMethod>[0],
  config?: UseQueryConfig<
    IssuerMarkShowMethod,
    IssuerMarkShowErrorTypes
  >
}) => {
  return useLazyQuery<
    IssuerMarkShowMethod,
    IssuerMarkShowErrorTypes
  >(
    (...args) => api.issuerMarkShow(...args),
    IssuerMarkShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IssuerMarkShow'],
        ...apiQueryConfig.IssuerMarkShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IssuerMarkShow',
  );
};


export type IssuerMarkUpdateMethod = MarksApi['issuerMarkUpdate'];

export const useIssuerMarkUpdate = () => {
  return useMutation<IssuerMarkUpdateMethod>(
    (...args) => api.issuerMarkUpdate(...args),
    IssuerMarkUpdateErrorCodes,
    'IssuerMarkUpdate',
    '/api/admin/issuers/{issuer_id}/securities/{security_npm_guid}/marks/{id}',
    'patch'
  );
};

export type IssuerMarkDeleteMethod = MarksApi['issuerMarkDelete'];

export const useIssuerMarkDelete = () => {
  return useMutation<IssuerMarkDeleteMethod>(
    (...args) => api.issuerMarkDelete(...args),
    IssuerMarkDeleteErrorCodes,
    'IssuerMarkDelete',
    '/api/admin/issuers/{issuer_id}/securities/{security_npm_guid}/marks/{id}',
    'delete'
  );
};
