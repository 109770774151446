import { type GridComponentProps } from '@npm/core/ui/components/atoms/Grid';

export const getGridConfig: (
  columnCount: 1 | 2
) => Pick<GridComponentProps, 'cols' | 'areas' | 'gap'> = columnCount => ({
  cols: {
    default: {
      count: columnCount,
      size: '1fr',
    },
    mobile: {
      count: 1,
      size: '1fr',
    },
  },
  areas: {
    default:
      columnCount === 1
        ? '"first" "middle" "last" "email"'
        : "'first middle' 'last email'",
    mobile: '"first" "middle" "last" "email"',
  },
  gap: {
    default: '16px',
  },
});
