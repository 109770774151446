import React from 'react';

import { Helmet } from '@npm/core/ui/components/atoms/Helmet';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { ParamNotFoundError, useParams } from '@npm/core/ui/hooks/useParams';
import { type RouteTitleProps } from '@npm/core/ui/routes/router.types';
import { truncate } from '@npm/core/ui/utils/formatters';

export const MatchDetailTitle = ({ raw }: RouteTitleProps) => {
  const { isMobile } = useBreakpoints();

  const { matchId } = useParams<{ matchId: string }>();
  if (matchId == null) throw new ParamNotFoundError('Match ID is required');

  // for breadcrumbs
  if (raw) {
    return (
      <>{isMobile ? truncate(`Match #${matchId}`) : `Match #${matchId}`}</>
    );
  }

  return (
    <>
      <Helmet pageTitle={`Match #${matchId}`}></Helmet>
      {`Match #${matchId}`}
    </>
  );
};
