import {
  type ZendeskTicketGroupConfig,
  type ZendeskTicketGroupID,
} from './zendesk.types';

const MARKETPLACE_AGREEMENT_TITLE = 'Marketplace agreement';
const PAYING_SERVICES_AGREEMENT_TITLE = 'Paying agent services agreement';
const ACCREDITED_INVESTOR_TITLE = 'Accredited investor status';
const INDIVIDUAL_TAX_ID_TITLE = 'Individual tax ID';
const GOVERNMENT_ISSUED_ID_TITLE = 'Government-issued ID';

export const ticketGroupConfigMap: Record<
  ZendeskTicketGroupID,
  ZendeskTicketGroupConfig
> = {
  onboarding_inqury: {
    title: 'I have a question about my onboarding',
    tags: ['onboarding', 'individual_account'],
    configTitleOnly: true,
  },
  onboarding_individual_identity_verification: {
    title: 'Identity Verification Information',
    tags: [
      'onboarding',
      'individual_account',
      'individual_tax_id',
      'government_issued_id',
    ],
  },
  onboarding_entity_identity_verification: {
    title: 'Identity Verification Information',
    tags: [
      'onboarding',
      'entity_account',
      'entity_tax_id',
      'formation_document',
    ],
  },
  onboarding_entity_accreditation: {
    title: ACCREDITED_INVESTOR_TITLE,
    tags: ['onboarding', 'accredited_investor_status', 'entity_account'],
  },
  onboarding_individual_accreditation: {
    title: ACCREDITED_INVESTOR_TITLE,
    tags: ['onboarding', 'accredited_investor_status', 'individual_account'],
  },
  onboarding_entity_tax_id: {
    title: 'Entity tax ID',
    tags: ['onboarding', 'entity_tax_id', 'entity_account'],
  },
  onboarding_individual_tax_id: {
    title: INDIVIDUAL_TAX_ID_TITLE,
    tags: ['onboarding', 'individual_tax_id', 'individual_account'],
  },
  onboarding_individual_government_id: {
    title: GOVERNMENT_ISSUED_ID_TITLE,
    tags: ['onboarding', 'government_issued_id', 'individual_account'],
  },
  onboarding_entity_marketplace_agreement: {
    title: MARKETPLACE_AGREEMENT_TITLE,
    tags: ['onboarding', 'marketplace_agreement', 'entity_account'],
  },
  onboarding_individual_marketplace_agreement: {
    title: MARKETPLACE_AGREEMENT_TITLE,
    tags: ['onboarding', 'marketplace_agreement', 'individual_account'],
  },
  onboarding_entity_paying_agent_agreement: {
    title: PAYING_SERVICES_AGREEMENT_TITLE,
    tags: ['onboarding', 'paying_agent_services_agreement', 'entity_account'],
  },
  onboarding_individual_paying_agent_agreement: {
    title: PAYING_SERVICES_AGREEMENT_TITLE,
    tags: [
      'onboarding',
      'paying_agent_services_agreement',
      'individual_account',
    ],
  },
  onboarding_entity_formation_document: {
    title: 'Formation document',
    tags: ['onboarding', 'formation_document', 'entity_account'],
  },
  // Post-onboarding
  post_onboarding_entity_accreditation: {
    title: ACCREDITED_INVESTOR_TITLE,
    tags: ['post_onboarding', 'accredited_investor_status', 'entity_account'],
  },
  post_onboarding_individual_accreditation: {
    title: ACCREDITED_INVESTOR_TITLE,
    tags: [
      'post_onboarding',
      'accredited_investor_status',
      'individual_account',
    ],
  },
  post_onboarding_individual_tax_id: {
    title: INDIVIDUAL_TAX_ID_TITLE,
    tags: ['post_onboarding', 'individual_tax_id', 'individual_account'],
  },
  post_onboarding_entity_tax_id: {
    title: 'Entity tax ID',
    tags: ['post_onboarding', 'entity_tax_id', 'entity_account'],
  },
  post_onboarding_entity_government_id: {
    title: GOVERNMENT_ISSUED_ID_TITLE,
    tags: ['post_onboarding', 'government_issued_id', 'entity_account'],
  },
  post_onboarding_individual_government_id: {
    title: GOVERNMENT_ISSUED_ID_TITLE,
    tags: ['post_onboarding', 'government_issued_id', 'individual_account'],
  },
  post_onboarding_entity_formation_document: {
    title: 'Formation document',
    tags: ['post_onboarding', 'formation_document', 'entity_account'],
  },
  post_onboarding_entity_marketplace_agreement: {
    title: MARKETPLACE_AGREEMENT_TITLE,
    tags: ['post_onboarding', 'marketplace_agreement', 'entity_account'],
  },
  post_onboarding_individual_marketplace_agreement: {
    title: MARKETPLACE_AGREEMENT_TITLE,
    tags: ['post_onboarding', 'marketplace_agreement', 'individual_account'],
  },
  post_onboarding_entity_paying_agent_agreement: {
    title: 'PAYING_SERVICES_AGREEMENT_TITLE',
    tags: [
      'post_onboarding',
      'paying_agent_services_agreement',
      'entity_account',
    ],
  },
  post_onboarding_individual_paying_agent_agreement: {
    title: 'PAYING_SERVICES_AGREEMENT_TITLE',
    tags: [
      'post_onboarding',
      'paying_agent_services_agreement',
      'individual_account',
    ],
  },
  post_onboarding_entity_tax_information_state: {
    title: "Account's Taxpayer Status",
    tags: ['post_onboarding', 'tax_information', 'entity_account'],
  },
  post_onboarding_individual_tax_information_state: {
    title: 'Tax Information',
    tags: ['post_onboarding', 'tax_information', 'individual_account'],
  },
  submission_entity_accreditation: {
    title: ACCREDITED_INVESTOR_TITLE,
    tags: ['submission', 'accredited_investor_status', 'entity_account'],
  },
  submission_individual_accreditation: {
    title: ACCREDITED_INVESTOR_TITLE,
    tags: ['submission', 'accredited_investor_status', 'individual_account'],
  },
  submission_entity_tax_id: {
    title: 'Representative tax ID',
    tags: ['submission', 'representative_tax_id', 'entity_account'],
  },
  submission_individual_tax_id: {
    title: INDIVIDUAL_TAX_ID_TITLE,
    tags: ['submission', 'individual_tax_id', 'individual_account'],
  },
  submission_entity_government_id: {
    title: GOVERNMENT_ISSUED_ID_TITLE,
    tags: ['submission', 'government_issued_id', 'entity_account'],
  },
  submission_individual_government_id: {
    title: GOVERNMENT_ISSUED_ID_TITLE,
    tags: ['submission', 'government_issued_id', 'individual_account'],
  },
  page_error: {
    title: 'Page error',
    tags: ['page_error'],
  },
  api_request_error: {
    title: 'Page API error',
    tags: ['api_request_error'],
  },
  data_plan_inquiry: {
    title: 'Data plan inquiry',
    tags: ['company', 'data_plan_inquiry'],
  },
  company_investment_data_table_filings: {
    title: 'Investment data table filings',
    tags: ['company', 'investment_data_table', 'filings'],
  },
  order_entry_accreditation: {
    title: 'Account is not accredited to place a buy order',
    tags: ['accreditation_status', 'order_entry'],
  },
  second_market_market_feed: {
    title: 'Market Feed',
    tags: ['order_entry'],
  },
  second_market_company_overview: {
    title: 'Company Overview',
    tags: ['company', 'company-overview'],
  },
  marketplace_application: {
    title: 'Marketplace Application',
    tags: ['onboarding', 'entity_account'],
  },
  company_coverage_request: {
    title: 'Company Coverage Request',
    tags: ['company'],
  },
  online_submission_progress: {
    title: 'Online Submission Progress',
    tags: ['onboarding', 'individual_account'],
  },
  opportunities_in_contract_rofr_exercised: {
    title: 'ROFR Exercised',
    tags: ['opportunities'],
  },
  submission_under_review: {
    title: 'Submission Under Review',
    tags: ['submission', 'individual_account'],
  },
};
