import React from 'react';
import { type FormItemProps } from 'antd';

import { FORM_FIELDS_CONFIG } from './GovernmentIDForm.constants';

import * as S from './GovernmentIDForm.styles';

type Props = FormItemProps & {
  field: keyof typeof FORM_FIELDS_CONFIG;
  layout: 'vertical' | 'horizontal';
};

export const GovernmentIDFormItem = ({
  field,
  children,
  layout,
  ...rest
}: Props) => {
  return (
    <S.FormItem
      name={FORM_FIELDS_CONFIG[field].name}
      label={FORM_FIELDS_CONFIG[field].label}
      rules={FORM_FIELDS_CONFIG[field].rules}
      $layout={layout}
      {...rest}
    >
      {children}
    </S.FormItem>
  );
};
