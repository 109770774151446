import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  GLOBAL_ADMIN: 'GLOBAL_ADMIN',
  BROKERAGE_ADMIN: 'BROKERAGE_ADMIN',
  BROKER_READ_ONLY: 'BROKER_READ_ONLY',
  ACCOUNT_MANAGER: 'ACCOUNT_MANAGER', // Brokerage Account Manager
  PORTAL_OBSERVER: 'PORTAL_OBSERVER',
  INTERNAL_ADMIN: 'INTERNAL_ADMIN',
  ISSUER_ADMIN: 'ISSUER_ADMIN',
  ISSUER_ADMIN_AND_SIGNER: 'ISSUER_ADMIN_AND_SIGNER',
  ISSUER_OBSERVER: 'ISSUER_OBSERVER',
  ISSUER_OBSERVER_AND_SIGNER: 'ISSUER_OBSERVER_AND_SIGNER',
  ISSUER_EVENT_OBSERVER: 'ISSUER_EVENT_OBSERVER',
  TREASURY: 'TREASURY',
  LIMITED_OBSERVER: 'LIMITED_OBSERVER',
  ACCOUNT_ADMIN: 'ACCOUNT_ADMIN',
  ACCOUNT_GUEST: 'ACCOUNT_GUEST',
  ADMIN_AND_SIGNER: 'ADMIN_AND_SIGNER',
  GUEST_AND_SIGNER: 'GUEST_AND_SIGNER',
  NEW_USER: 'NEW_USER',
  SPOUSE: 'SPOUSE',
  INTERNAL_BROKER: 'INTERNAL_BROKER',
  // frontend-only value
  ALL_ACCOUNTS: 'ALL_ACCOUNTS',
} as const;

export type RoleType = typeof Items[keyof typeof Items];

type AttributeName = 'scope' | 'description';

export const CbRoleType = {
  code: Codebooks.ROLE_TYPE,
  items: Items,
  getCbByCode: (code: string, cbs: CodebookItem[]) =>
    cbs.find(cb => cb.code === code),
  getAttributeValueByName: (item: CodebookItem, name: AttributeName) =>
    item.attributes?.find(q => q.name === name)?.value,
};
