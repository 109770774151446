import type React from 'react';

export type WizardStore<T> = {
  dispatch: (args: WizardStoreAction<T>) => void;
  data: Partial<T>;
  steps: StepDefinition[];
  activeStepState: StepState;
  isStepperMobileOpen: boolean;
};

export enum WizardStoreActionTypes {
  UPDATE_DATA = 'UPDATE_DATA',
  UPDATE_STEPS = 'UPDATE_STEPS',
  UPDATE_ACTIVE_STEP_STATE = 'UPDATE_ACTIVE_STEP_STATE',
  TOGGLE_STEPPER_MOBILE_OPEN = 'TOGGLE_STEPPER_MOBILE_OPEN',
  RESET_CONTEXT = 'RESET_CONTEXT',
}

export type WizardStoreAction<T> =
  | {
      type: WizardStoreActionTypes.UPDATE_DATA;
      payload: Partial<T>;
    }
  | {
      type: WizardStoreActionTypes.UPDATE_STEPS;
      payload: StepDefinition[];
    }
  | {
      type: WizardStoreActionTypes.UPDATE_ACTIVE_STEP_STATE;
      payload: StepState;
    }
  | {
      type: WizardStoreActionTypes.TOGGLE_STEPPER_MOBILE_OPEN;
    }
  | {
      type: WizardStoreActionTypes.RESET_CONTEXT;
    };

export type StepDefinition = {
  key: string;
  title: string;
  component: (props: StepProps) => React.ReactNode;
  titleTooltip?: React.ReactNode;
  description?: string;
  isEditable?: boolean;
};

export type StepProps = {
  step: StepDefinition;
  handleStepComplete?: () => Promise<void> | void;
  setFormHasUnsavedChanges?: (value: boolean) => void;
  handleCloseFormAttempt?: (callback?: () => void) => void;
};

export enum StepState {
  NONE,
  EDIT,
  FILLED,
}

export type WizardHistoryState = {
  isStepperMobileOpen: boolean;
  activeStep: number;
};
