/**
 * ABA Routing Number validation. How it works:
 *
 * We'll start with a routing number like 789456124
 * and make sure its length is nine digits,
 *
 * 7 8 9 4 5 6 1 2 4
 *
 * Then we multiply the first digit by 3, the second by 7, the third by
 * 1, the fourth by 3, the fifth by 7, the sixth by 1, etc., and add them
 * all up.
 *
 * (7 x 3) + (8 x 7) + (9 x 1) +
 * (4 x 3) + (5 x 7) + (6 x 1) +
 * (1 x 3) + (2 x 7) + (4 x 1) = 160
 *
 * If the resulting number is an integer multiple of 10, then the number
 * is valid. To calculate what the checksum digit should be, follow the
 * above algorithm for the first 8 digits.
 */
export const abaFormItemValidationRules = [
  {
    len: 9,
    message: 'Field must have 9 digits',
  },
  {
    pattern: new RegExp(/^\d*$/),
    message: 'Field must only consist of numbers',
  },
  {
    message: 'The provided number must be a valid ABA Routing Number',
    validator: (_, value) => {
      const input: string = value.toString();
      let sum = 0;
      for (let i = 0; i < input.length; i += 3) {
        sum +=
          parseInt(input.charAt(i)) * 3 +
          parseInt(input.charAt(i + 1)) * 7 +
          parseInt(input.charAt(i + 2));
      }
      if (sum !== 0 && sum % 10 === 0) return Promise.resolve();
      else
        return Promise.reject(
          'The resulting sum is NOT an even multiple of ten (0 not valid)'
        );
    },
  },
]; // SWIFT/BIC code = an eight to eleven alphanumeric characters with no dashes or spaces
export const swiftBicCodeFormItemValidationRule = [
  {
    pattern: new RegExp('^[a-zA-Z0-9]{8,11}$'),
    message:
      'The provided value must be a valid SWIFT/BIC code with no dashes or spaces',
  },
];
