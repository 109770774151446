import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

export const useMyOpportunitiesLayout = () => {
  const { isMobile, isSmTablet, isTablet, isSmDesktop } = useBreakpoints();
  const useMobileLayout = isMobile || isSmTablet || isTablet || isSmDesktop;

  return {
    useMobileLayout: useMobileLayout,
  };
};
