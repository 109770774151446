import { CbSubscriptionPlan } from '@npm/data-access';

import { useUserContextStore } from '../../context';

export const usePremiumPlan = () => {
  const plan = useUserContextStore(store => store.user.subscription_plan.code);

  return {
    isBasic: plan === CbSubscriptionPlan.items.basic,
    isTrial: plan === CbSubscriptionPlan.items.trial,
    isPremium: plan === CbSubscriptionPlan.items.premium,
    isPremiumContentUnlocked:
      plan === CbSubscriptionPlan.items.trial ||
      plan === CbSubscriptionPlan.items.premium,
  };
};
