import styled from 'styled-components';

import { Form as FormBase } from '@npm/core/ui/components/atoms/Form';

export const Container = styled.div`
  max-width: 800px;
`;

export const Form = styled(FormBase)`
  max-width: 100%;
`;

export const MaxWidthFull = styled.div`
  max-width: 100%;
`;
