import { Button } from '@npm/core/ui/components/atoms/Button';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal/Modal';

import * as S from './QualifiedClientModal.styles';

export type InfoModalType = 'qualifiedClient' | 'lookThrough';

const config: Record<
  InfoModalType,
  { title: string; content: React.ReactNode }
> = {
  qualifiedClient: {
    title: 'Learn more about Qualified Client',
    content: (
      <>
        <Text size="sm" colorVariant="primary" weight="bold" marginBottom="sm">
          “Qualified Client” means you satisfy one or more of the following
          criteria:
        </Text>
        <Text size="sm">
          <S.OL>
            <li>
              will have at least $1,100,000 under the management of the NPM
              manager or general partner; or
            </li>
            <li>
              have a net worth (together with assets held jointly with a spouse)
              of more than $2,200,000. “Net worth” means total assets (including
              real property, personal property and other assets) in excess of
              total liabilities, but with the following adjustments: (i) “net
              worth” excludes the value of your primary residence; (ii) “net
              worth” excludes the related amount of indebtedness secured by your
              primary residence (such as a mortgage or home equity line of
              credit) up to its present fair market value if such indebtedness
              was either incurred more than 60 days prior to the purchase of a
              fund interest or incurred for the purpose of acquiring your
              primary residence; and (iii) indebtedness secured by your primary
              residence in excess of the value of the residence must be
              considered a liability and deducted from your net worth.
            </li>
          </S.OL>
        </Text>
      </>
    ),
  },
  lookThrough: {
    title: 'Learn more about Look-Through Entities',
    content: (
      <>
        <Text size="sm" colorVariant="primary" weight="bold" marginBottom="sm">
          “Look-Through Entity” means the entity is:
        </Text>
        <Text size="sm">
          <S.OL>
            <li>
              a private investment company that would be an investment company
              under the Investment Company Act but for the exception in Section
              3(c)(1) of the Investment Company Act,
            </li>
            <li>
              an investment company registered under the Investment Company Act;
              or
            </li>
            <li>
              a business development company (as defined in Section 202(a)(22)
              of the Advisers Act)
            </li>
          </S.OL>
        </Text>
      </>
    ),
  },
};

type Props = {
  type: InfoModalType | undefined;
  isOpen: boolean;
  onClose: () => void;
};

export const InfoModal = ({ type, isOpen, onClose }: Props) => {
  return (
    <Modal
      title={config[type]?.title}
      open={isOpen && !!type}
      onCancel={onClose}
      footer={<Button onClick={onClose}>Understood</Button>}
    >
      {config[type]?.content}
    </Modal>
  );
};
