import { Codebooks } from './codebooks.types';

const Items = {
  all_active: 'all_active',
  today: 'today',
  last_7_days: 'last_7_days',
  last_30_days: 'last_30_days',
} as const;

export const CbCompanyActive = {
  code: Codebooks.COMPANY_ACTIVE,
  items: Items,
};
