import { type CodebookItem } from '@npm/data-access';
import {
  CbAccreditationLabel,
  CbAccreditationQuestion,
} from '@npm/data-access';

import { type StructuredAccreditationFormData } from './Accreditation.types';

// get only relevant labels (= "sub-questions")
// for the question group
const getAggregateLabels = (question: CodebookItem, labels: CodebookItem[]) =>
  labels.filter(
    label =>
      CbAccreditationLabel.getAttributeValueByName(label, 'question') ===
      question.code
  );

export const getAnswerSetCodes = (labels: CodebookItem[]) =>
  labels
    .map(label =>
      CbAccreditationLabel.getAttributeValueByName(
        label,
        'answer_set'
      )?.toUpperCase()
    )
    .filter(Boolean);

export const structureFormData = (
  questions: CodebookItem[],
  labels: CodebookItem[],
  type: 'entity' | 'individual'
): [StructuredAccreditationFormData[]] => {
  const data = questions
    .filter(question =>
      CbAccreditationQuestion.getAttributeValueByName(question, type)
    )
    .map(question => {
      const aggLabels = getAggregateLabels(question, labels);

      return {
        question,
        labels: aggLabels,
      };
    });

  return [data];
};

export const getLabelsByQuestionCode = (
  code: string,
  structuredFormData: StructuredAccreditationFormData[]
) => {
  return (structuredFormData || []).find(val => val.question.code === code)
    ?.labels;
};
