import React from 'react';
import type { Variant } from 'styled-components';

import { Text } from '@npm/core/ui/components/atoms/Typography';

import * as S from './Terms.styles';

type Props = {
  colorVariant?: Variant;
};

export const Terms = ({ colorVariant = 'secondary' }: Props) => {
  return (
    <S.TermsContainer>
      <Text size="xxs" colorVariant={colorVariant}>
        ANY INFORMATION RELATING TO COMPANY FINANCING, VALUATION, OR
        CAPITALIZATION INFORMATION IS PROVIDED FOR INFORMATIONAL PURPOSES ONLY
        AND SHOULD BE INDEPENDENTLY VERIFIED BY EACH USER IN CONNECTION WITH ANY
        INVESTMENT DECISION. THE MATERIAL IS BASED IN PART ON INFORMATION FROM
        THIRD-PARTY SOURCES THAT WE BELIEVE TO BE RELIABLE, NOT MATERIALLY
        MISLEADING, IN COMPLIANCE WITH GOVERNING LAWS, REGULATORY GUIDELINES,
        AND INDUSTRY BEST PRACTICES, BUT WHICH HAVE NOT BEEN INDEPENDENTLY
        VERIFIED BY US AND FOR THIS REASON WE DO NOT REPRESENT THAT THE
        INFORMATION IS ACCURATE OR COMPLETE. THE INFORMATION SHOULD NOT BE
        REVIEWED AS TAX, INVESTMENT, LEGAL OR OTHER ADVICE, SOLICITATION OR
        RECOMMENDATION NOR IS IT TO BE RELIED ON IN MAKING AN INVESTMENT OR
        OTHER DECISION. THERE MAY EXIST OTHER MATERIAL NON-PUBLIC INFORMATION
        THAT IMPACTS VALUATION OF ANY COMPANY SHARES. PAST PERFORMANCE IS NOT
        INDICATIVE OF FUTURE RESULTS.
        <br />
        <br />
        NONE OF THE INFORMATION DISPLAYED ON OR DOWNLOADABLE FROM
        WWW.NASDAQPRIVATEMARKET.COM (THE &quot;SITE&quot;) REPRESENTS AN OFFER
        TO BUY OR SELL OR A SOLICITATION OF AN OFFER TO BUY OR SELL ANY
        SECURITY, NOR DOES IT CONSTITUTE AN OFFER TO PROVIDE INVESTMENT ADVICE,
        RECOMMENDATION OR SERVICE.
        <br />
        <br />
        Nasdaq Private Market, LLC IS NOT A REGISTERED EXCHANGE UNDER THE
        SECURITIES EXCHANGE ACT OF 1934; (II) A REGISTERED INVESTMENT ADVISER
        UNDER THE INVESTMENT ADVISERS ACT OF 1940; (III) A FINANCIAL OR TAX
        PLANNER, AND DOES NOT OFFER LEGAL, FINANCIAL, INVESTMENT, OR TAX ADVICE.
        NASDAQ PRIVATE MARKET IS OPERATIONALLY INDEPENDENT AND DISTINCT FROM THE
        NASDAQ STOCK MARKET LLC.
        <br />
        <br />
        SECURITIES-RELATED SERVICES ARE OFFERED THROUGH NPM SECURITIES, LLC
        (&quot;NPMS&quot;), A MEMBER OF FINRA AND SIPC. REGISTERED
        REPRESENTATIVES OF NPMS DO NOT ADVISE ANY PERSON ON THE MERITS OR
        ADVISABILITY OF A PARTICULAR INVESTMENT OR TRANSACTION, OR (II) ASSIST
        IN THE DETERMINATION OF FAIR MARKET VALUE OF ANY SECURITY OR INVESTMENT,
        OR (III) PROVIDE LEGAL, TAX, OR TRANSACTIONAL ADVISORY SERVICES.
        <br />
        <br />
        ALL INVESTMENT OPPORTUNITIES ARE BASED ON INDICATED INTEREST FROM
        SELLERS AND WILL NEED TO BE CONFIRMED. INDICATED INTEREST
        PRICE-PER-SHARE FIGURES DO NOT INCLUDE TRANSACTION FEES OR FEES CHARGED
        BY NPMS. THERE MAY BE CONFLICTS OF INTEREST RELATING TO NASDAQ PRIVATE
        MARKET&apos;S BUSINESS DEALINGS WITH SOME OR ALL OF THE COMPANIES
        REFERENCE HEREIN, INCLUDING POTENTIAL ADVISORY, TRANSACTIONAL AND OTHER
        CONFLICTS OF INTERESTS.
        <br />
        <br />
        INVESTING IN PRIVATE COMPANY STOCKS IS NOT SUITABLE FOR ALL INVESTORS.
        AN INVESTMENT IN PRIVATE COMPANY SECURITIES IS HIGHLY SPECULATIVE AND
        INVOLVES A HIGH DEGREE OF RISK. YOU MUST BE PREPARED TO WITHSTAND A
        TOTAL LOSS OF YOUR INVESTMENT. PRIVATE COMPANY SECURITIES ARE ALSO
        HIGHLY ILLIQUID AND THE IS NO GUARANTEE THAT A MARKET WILL DEVELOP FOR
        SUCH SECURITIES. EACH INVESTMENT ALSO CARRIES ITS OWN SPECIFIC RISKS AND
        INVESTORS SHOULD CONDUCT THEIR OWN, INDEPENDENT DUE DILIGENCE REGARDING
        THE INVESTMENT, INCLUDING OBTAINING ADDITIONAL INFORMATION ABOUT THE
        COMPANY, OPINIONS, FINANCIAL PROJECTIONS AND LEGAL OR INVESTMENT ADVICE.
        ACCORDINGLY, INVESTING IN PRIVATE COMPANY SECURITIES IS APPROPRIATE ONLY
        FOR THOSE INVESTORS WHO CAN TOLERATE A HIGH DEGREE OF RISK AND DO NOT
        REQUIRE A LIQUID INVESTMENT. YOU ARE STRONGLY ENCOURAGED TO COMPLETE
        YOUR OWN INDEPENDENT DUE DILIGENCE BEFORE INVESTING IN PRIVATE COMPANY
        STOCK, INCLUDING OBTAINING ADDITIONAL INFORMATION, OPINIONS, FINANCIAL
        PROJECTIONS, AND LEGAL OR OTHER INVESTMENT ADVICE.
      </Text>
    </S.TermsContainer>
  );
};
