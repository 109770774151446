import { Codebooks } from './codebooks.types';

const Items = {
  CEILING: 'CEILING',
  FLOOR: 'FLOOR',
  NORMAL: 'NORMAL',
} as const;

export type CbRoundingTypeCode = typeof Items[keyof typeof Items];

export const CbRoundingType = {
  code: Codebooks.ROUNDING,
  items: Items,
};
