import { Codebooks } from './codebooks.types';

const Items = {
  internal: 'internal',
  external: 'external',
} as const;

export type VisibilityCode = typeof Items[keyof typeof Items];

export const CbVisibility = {
  code: Codebooks.VISIBILITY,
  items: Items,
};
