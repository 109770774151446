import React, { type ComponentProps } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { CypressDataIds } from '@npm/core/ui/constants';
import { composeDataId } from '@npm/core/ui/utils/cypress';
import { getDisabledActionTooltipText } from '@npm/core/ui/utils/getDisabledActionTooltipText';
import { type Holding, CbHoldingState } from '@npm/data-access';

import { usePermissions } from '../../auth/permissions/usePermissions';
import { type HoldingStatusChangeModal } from '../StatusChangeModal';

import * as S from './styles';

export const StatusChangeButtons = ({
  holding,
  onClick,
  onEditClick,
}: {
  holding: Holding;
  onClick: (args: {
    type: ComponentProps<typeof HoldingStatusChangeModal>['type'];
    id: number;
    certificate_number: string;
  }) => void;
  onEditClick?: (id: number) => void;
}) => {
  const { canWrite, BRO_ACCOUNT_MANAGER } = usePermissions();
  const disabled = !(canWrite || BRO_ACCOUNT_MANAGER);

  const { id, certificate_number, state, can_be_verified, pre_verified } =
    holding;

  const isPending = state.code === CbHoldingState.items.pending;
  const needsVerification =
    state.code === CbHoldingState.items.needs_verification;
  const isVerified = state.code === CbHoldingState.items.verified;

  const disabledTooltipText = getDisabledActionTooltipText(disabled);

  if (isVerified || (BRO_ACCOUNT_MANAGER && pre_verified)) {
    return null;
  }

  const preVerifyButton = (
    <Tooltip title={disabledTooltipText}>
      <Button
        icon={<Icon name="check" />}
        onClick={() => onClick({ type: 'pre-verify', id, certificate_number })}
        disabled={disabled}
      >
        Pre-Verify
      </Button>
    </Tooltip>
  );

  const verifyButton = (
    <Tooltip
      title={
        !can_be_verified ? (
          <>
            This holding is missing certain data-points. Edit holding on behalf
            of the seller or Reject holding with correction instructions.
          </>
        ) : (
          disabledTooltipText
        )
      }
      placement="topRight"
    >
      <Button
        icon={<Icon name="check" />}
        onClick={() => onClick({ type: 'verify', id, certificate_number })}
        disabled={!canWrite || !can_be_verified}
        data-cy={composeDataId(CypressDataIds.Holdings.Button.Verify, id)}
      >
        Verify
      </Button>
    </Tooltip>
  );

  const revertButton = (
    <Tooltip title={disabledTooltipText}>
      <Button
        variant="text"
        icon={<Icon name="history" />}
        onClick={() => onClick({ type: 'revert', id, certificate_number })}
        disabled={disabled}
        data-cy={composeDataId(CypressDataIds.Holdings.Button.Revert, id)}
      >
        Revert to Pending
      </Button>
    </Tooltip>
  );

  const dropdownItems: ComponentProps<typeof Dropdown>['items'] = [
    {
      label: 'Edit Holding',
      icon: <Icon name="edit" />,
      onClick: () => onEditClick?.(holding.id),
      hidden: !onEditClick,
      disabled,
    },
    {
      label: 'Reject',
      icon: <Icon name="x-close" color="error" />,
      onClick: () => onClick({ type: 'reject', id, certificate_number }),
      hidden: needsVerification,
      disabled,
      'data-cy': composeDataId(CypressDataIds.Holdings.Button.Reject, id),
    },
  ];

  if (BRO_ACCOUNT_MANAGER) {
    return (
      <S.ButtonsContainer>
        {isPending && !pre_verified ? (
          <>
            {preVerifyButton}
            <Dropdown items={dropdownItems}>
              <S.DropdownButton
                variant="text"
                icon={<Icon name="dots-vertical" size="xs" />}
              />
            </Dropdown>
          </>
        ) : (
          revertButton
        )}
      </S.ButtonsContainer>
    );
  }

  return (
    <S.ButtonsContainer>
      {isPending ? verifyButton : revertButton}
      <Dropdown items={dropdownItems}>
        <S.DropdownButton
          variant="text"
          icon={<Icon name="dots-vertical" size="xs" />}
        />
      </Dropdown>
    </S.ButtonsContainer>
  );
};
