import styled, { css } from 'styled-components';

import { SmallTextBoldVariationSettings } from '@npm/core/ui/components/atoms/Typography';

const olStyles = css`
  margin: 0;
  padding: 0;
  padding-left: ${({ theme }) => theme.spacing.lg}px;
`;

export const OL = styled.ol`
  ${olStyles}
`;

export const Bold = styled.b`
  ${SmallTextBoldVariationSettings}
`;

export const LabelFormatter = styled.div`
  ol {
    ${olStyles}
  }
`;
