import { Codebooks } from './codebooks.types';

const Items = {
  not_look_through: 'not_look_through',
  look_through: 'look_through',
  not_valid: 'not_valid',
} as const;

export const CbQualifiedClientType = {
  code: Codebooks.QUALIFIED_CLIENT_TYPE,
  items: Items,
};
