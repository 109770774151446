/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  TenderOffersApi,
  InternalTenderOffersIndexErrorCodes,
  InternalTenderOffersIndexErrorTypes,
  InternalCompanyTenderOffersIndexErrorCodes,
  InternalCompanyTenderOffersIndexErrorTypes,
  InternalShowTenderOfferErrorCodes,
  InternalShowTenderOfferErrorTypes,
  InternalUpdateTenderOfferErrorCodes,
  InternalRemoveTenderOfferErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new TenderOffersApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalTenderOffersIndexMethod = TenderOffersApi['internalTenderOffersIndex'];
export type InternalTenderOffersIndexResponseType = MethodResult<InternalTenderOffersIndexMethod>;

export const useInternalTenderOffersIndex = (
  variables: Parameters<InternalTenderOffersIndexMethod>[0],
  config?: UseQueryConfig<
    InternalTenderOffersIndexMethod,
    InternalTenderOffersIndexErrorTypes
  >
) => {
  return useQuery<
    InternalTenderOffersIndexMethod,
    InternalTenderOffersIndexErrorTypes
  >(
    (...args) => api.internalTenderOffersIndex(...args),
    InternalTenderOffersIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalTenderOffersIndex'],
        ...apiQueryConfig.InternalTenderOffersIndex,
        ...config?.queryConfig
      },
    },
    'InternalTenderOffersIndex',
  );
};

export const useInternalTenderOffersIndexInfinite = (
  variables: Parameters<InternalTenderOffersIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalTenderOffersIndexMethod,
    InternalTenderOffersIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalTenderOffersIndexMethod,
    InternalTenderOffersIndexErrorTypes
  >(
    (...args) => api.internalTenderOffersIndex(...args),
    InternalTenderOffersIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalTenderOffersIndex', 'InternalTenderOffersIndexInfinite'],
        ...apiQueryConfig.InternalTenderOffersIndex,
        ...config?.queryConfig,
      },
    },
    'InternalTenderOffersIndex',
  );
};

export const useInternalTenderOffersIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalTenderOffersIndexMethod>[0],
  config?: UseQueryConfig<
    InternalTenderOffersIndexMethod,
    InternalTenderOffersIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalTenderOffersIndexMethod,
    InternalTenderOffersIndexErrorTypes
  >(
    (...args) => api.internalTenderOffersIndex(...args),
    InternalTenderOffersIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalTenderOffersIndex'],
        ...apiQueryConfig.InternalTenderOffersIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalTenderOffersIndex',
  );
};


export type InternalCompanyTenderOffersIndexMethod = TenderOffersApi['internalCompanyTenderOffersIndex'];
export type InternalCompanyTenderOffersIndexResponseType = MethodResult<InternalCompanyTenderOffersIndexMethod>;

export const useInternalCompanyTenderOffersIndex = (
  variables: Parameters<InternalCompanyTenderOffersIndexMethod>[0],
  config?: UseQueryConfig<
    InternalCompanyTenderOffersIndexMethod,
    InternalCompanyTenderOffersIndexErrorTypes
  >
) => {
  return useQuery<
    InternalCompanyTenderOffersIndexMethod,
    InternalCompanyTenderOffersIndexErrorTypes
  >(
    (...args) => api.internalCompanyTenderOffersIndex(...args),
    InternalCompanyTenderOffersIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalCompanyTenderOffersIndex'],
        ...apiQueryConfig.InternalCompanyTenderOffersIndex,
        ...config?.queryConfig
      },
    },
    'InternalCompanyTenderOffersIndex',
  );
};

export const useInternalCompanyTenderOffersIndexInfinite = (
  variables: Parameters<InternalCompanyTenderOffersIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalCompanyTenderOffersIndexMethod,
    InternalCompanyTenderOffersIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalCompanyTenderOffersIndexMethod,
    InternalCompanyTenderOffersIndexErrorTypes
  >(
    (...args) => api.internalCompanyTenderOffersIndex(...args),
    InternalCompanyTenderOffersIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalCompanyTenderOffersIndex', 'InternalCompanyTenderOffersIndexInfinite'],
        ...apiQueryConfig.InternalCompanyTenderOffersIndex,
        ...config?.queryConfig,
      },
    },
    'InternalCompanyTenderOffersIndex',
  );
};

export const useInternalCompanyTenderOffersIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalCompanyTenderOffersIndexMethod>[0],
  config?: UseQueryConfig<
    InternalCompanyTenderOffersIndexMethod,
    InternalCompanyTenderOffersIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalCompanyTenderOffersIndexMethod,
    InternalCompanyTenderOffersIndexErrorTypes
  >(
    (...args) => api.internalCompanyTenderOffersIndex(...args),
    InternalCompanyTenderOffersIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalCompanyTenderOffersIndex'],
        ...apiQueryConfig.InternalCompanyTenderOffersIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalCompanyTenderOffersIndex',
  );
};


export type InternalShowTenderOfferMethod = TenderOffersApi['internalShowTenderOffer'];
export type InternalShowTenderOfferResponseType = MethodResult<InternalShowTenderOfferMethod>;

export const useInternalShowTenderOffer = (
  variables: Parameters<InternalShowTenderOfferMethod>[0],
  config?: UseQueryConfig<
    InternalShowTenderOfferMethod,
    InternalShowTenderOfferErrorTypes
  >
) => {
  return useQuery<
    InternalShowTenderOfferMethod,
    InternalShowTenderOfferErrorTypes
  >(
    (...args) => api.internalShowTenderOffer(...args),
    InternalShowTenderOfferErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowTenderOffer'],
        ...apiQueryConfig.InternalShowTenderOffer,
        ...config?.queryConfig
      },
    },
    'InternalShowTenderOffer',
  );
};

export const useInternalShowTenderOfferInfinite = (
  variables: Parameters<InternalShowTenderOfferMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalShowTenderOfferMethod,
    InternalShowTenderOfferErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalShowTenderOfferMethod,
    InternalShowTenderOfferErrorTypes
  >(
    (...args) => api.internalShowTenderOffer(...args),
    InternalShowTenderOfferErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowTenderOffer', 'InternalShowTenderOfferInfinite'],
        ...apiQueryConfig.InternalShowTenderOffer,
        ...config?.queryConfig,
      },
    },
    'InternalShowTenderOffer',
  );
};

export const useInternalShowTenderOfferLazy = (baseOptions?: {
  variables?: Parameters<InternalShowTenderOfferMethod>[0],
  config?: UseQueryConfig<
    InternalShowTenderOfferMethod,
    InternalShowTenderOfferErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalShowTenderOfferMethod,
    InternalShowTenderOfferErrorTypes
  >(
    (...args) => api.internalShowTenderOffer(...args),
    InternalShowTenderOfferErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalShowTenderOffer'],
        ...apiQueryConfig.InternalShowTenderOffer,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalShowTenderOffer',
  );
};


export type InternalUpdateTenderOfferMethod = TenderOffersApi['internalUpdateTenderOffer'];

export const useInternalUpdateTenderOffer = () => {
  return useMutation<InternalUpdateTenderOfferMethod>(
    (...args) => api.internalUpdateTenderOffer(...args),
    InternalUpdateTenderOfferErrorCodes,
    'InternalUpdateTenderOffer',
    '/api/internal/tender_offers/{id}',
    'put'
  );
};

export type InternalRemoveTenderOfferMethod = TenderOffersApi['internalRemoveTenderOffer'];

export const useInternalRemoveTenderOffer = () => {
  return useMutation<InternalRemoveTenderOfferMethod>(
    (...args) => api.internalRemoveTenderOffer(...args),
    InternalRemoveTenderOfferErrorCodes,
    'InternalRemoveTenderOffer',
    '/api/internal/tender_offers/{id}/remove',
    'post'
  );
};
